/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, 
    
    ImageCropHook, ImageViewer, PopUpModal
} from '../../../components';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import AddNotes from '../../service/details/addNotes'
import Files from '../../files';
import TipInfoInputs from './infoInputs'
/** VIEW-MODELS */
import { objFleetPro, fleetSortOptions } from '../viewModel'
import { fleetProCategory } from '../../../services/enum'
import { storageBucket } from '../../../services/helper';

let categories = [];
//let locations = [];
//let usages = [];
let allFleetUsers = [];
let fleetOptionsDF = [];
//let clientID = null
let fleetProStatus = [];


export default class AddFleetPro extends Component {
    constructor(props) {
        super(props);
        this.refFleetPro = window.firebase.firestore().collection('fleetPro');
        this.mandatoryFields = [];
        //this.childRef = React.createRef();
        this.state = {
            fields: Object.assign({}, objFleetPro),
            oldFleet: Object.assign({}, objFleetPro),
            notesHistory: Object.assign([]),
            errors: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            currentStatus: null,
            isLoading: false,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            addMake: false,
            addType: false,
            addGroup: false,
            addModel: false,
            showNotes: false,
            dealers: [],
            regions: [],
            subregions: [],
            fleetgroups: [],
            imageViewer: { show: false, images: null }
        }

    }

    //#region  LOAD DATA
    async loadFleetPRO(props) {
        const { show, dealersettings, docID, groupUsers } = props;
        const fields = Object.assign({}, objFleetPro);
        if (show === true && docID) {

            const refData = await this.refFleetPro.doc(docID).get();
            this.onStockCollectionUpdate(refData)

            // this.unsubscribeFLTNotes = window.firebase.firestore().collection(`fleetPro/${docID}/notes`)
            //     .onSnapshot(this.onNotesCollectionUpdate);

        }
        else if (show === true && (!docID)) {

            // if (!_.isEmpty(prefillData) && _.isObject(prefillData)) {
            //     console.log('prefillData', prefillData)
            //     for (let [key, value] of Object.entries(prefillData)) {
            //         if (fields.hasOwnProperty(key) && value)
            //             fields[key] = value;
            //
            // }


            // const brands = Object.assign([], dealersettings.client.brands);
            // if (_.isEmpty(fields.make) && brands.length === 1)
            //     fields.make = brands[0].value

            fields.documentID = this.refFleetPro.doc().id;
            fields.category = fleetProCategory.MARKETING;
            fields.oemID = dealersettings.oemID;
            if (dealersettings.isMclaren === true && _.find(dealersettings.allMakeModels, { value: 'McLaren' })) {
                fields.make = 'McLaren';
            }
            //fields.status = stockStatus.AVAILABLE;            

            //clientID = dealersettings.client.id
            this.setState({ fields: fields, oldFleet: fields });

            // this.unsubscribeFLTNotes = window.firebase.firestore().collection(`fleetPro/${fields.documentID}/notes`)
            //     .onSnapshot(this.onNotesCollectionUpdate);
        }

        let setttings = dealersettings.client.fleetPro;

        // if (clientID && dealersettings && dealersettings.group &&
        //     dealersettings.group.clients && dealersettings.group.clients[clientID] && dealersettings.group.clients[clientID].fleetPro)
        //     setttings = dealersettings.group.clients[clientID].fleetPro;

        if (setttings) {

            fleetOptionsDF = [];
            if (setttings.fleetOptionsDF)
                fleetOptionsDF = setttings.fleetOptionsDF;

            fleetProStatus = [];
            setttings.fleetProStatus && setttings.fleetProStatus.filter(e => e.default).forEach(doc => {
                fleetProStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            categories = [];
            setttings.category && setttings.category.forEach(doc => {
                categories.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.fleetPro))
                this.mandatoryFields = setttings.mandatoryFields.fleetPro.split(',');

            if (!_.isEmpty(this.props.mandatoryFields))
                this.mandatoryFields = _.union(this.mandatoryFields, this.props.mandatoryFields);

            const brands = dealersettings.allMakeModels;

            const years = [];
            const makes = [];

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            if (fields.make) {
                const types = [];
                const models = [];
                const groups = [];
                let _models = !_.isEmpty(makes.filter(m => m.value === fields.make)[0]) ? makes.filter(m => m.value === fields.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data, true),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });

            }

            const roleFleetProIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules?.fleetPro === true), function (obj) {
                return obj.documentID;
            })];

            allFleetUsers = [];
            _.uniqBy(groupUsers, 'id').filter(a => Boolean(a.superAdmin) === false && (_.includes(roleFleetProIds, a.roleID))).forEach(rec => {
                allFleetUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    data: rec,
                    name: rec.name
                })
            })

            allFleetUsers = _.uniqBy(allFleetUsers, 'value');

            let _dealers = [];
            if (dealersettings.clients)
                dealersettings.clients.filter(c =>
                    c.moduleSettings
                    && c.moduleSettings.fleetPro
                    && c.moduleSettings.fleetPro.enabled === true
                    && c.moduleSettings.fleetPro.active === true
                ).forEach(rec => {
                    _dealers.push({
                        ...rec,
                        label: rec.name,
                        value: rec.id,
                        data: rec,
                        active: true,
                    })
                })

            let _subregions = [];
            dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                    _subregions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            this.setState({
                makes: makes,
                years: years,
                dealers: _dealers,
                regions: _regions,
                subregions: _subregions,
                fleetgroups: _groups
            }, () => { this.errorOnLoad() });

        }
    }

    onStockCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }
        const { dealersettings } = this.props;
        let makes = !_.isEmpty(this.state.makes) ? this.state.makes : dealersettings.allMakeModels;

        const objData = Object.assign({}, docSnapshot.data());
        const fields = Object.assign({}, objFleetPro);
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }

        if (!fields.documentID)
            fields.documentID = docSnapshot.id;

        if (fields.make) {
            const types = [];
            const models = [];
            const groups = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === fields.make)[0]) ? makes.filter(m => m.value === fields.make)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data, true),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });

        }

        //clientID = fields.clientID ? fields.clientID : null;
        this.setState({ fields: fields, oldFleet: fields, currentStatus: fields.status });
    }

    onNotesCollectionUpdate = (querySnapshot) => {

        let notesHistory = [];
        querySnapshot.forEach((_req) => {
            const req = Object.assign({}, _req.data());
            req.documentID = _req.id;
            notesHistory.push(req);
        });

        notesHistory = _.orderBy(notesHistory, ['addedDate'], ['desc'])

        this.setState({ notesHistory: notesHistory });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadFleetPRO(this.props);

    }

    componentWillUnmount() {
        this.unsubscribeFLTNotes && this.unsubscribeFLTNotes();
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let errorClass = 'input_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);


            let fields = Object.assign({}, this.state.fields);
            for (let [key] of Object.entries(fields)) {
                if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {
                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                        errors[key] = errorClass;
                    }
                }
            }

            this.setState({ errors: errors });

            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

        }

    }
    //#endregion

    //#region CHANGE EVENT STOCK
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {

            const { dealersettings } = this.props;
            let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.fleetProOptions))
                ? dealersettings.client.fleetProOptions :
                Object.assign([], _.uniq(_.union(fleetSortOptions, Object.assign([], (!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(fleetOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
            dynamicDetailList = _.union(dynamicDetailList, ['images'])
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0 && dynamicDetailList.includes(key)) {
                if (objFleetPro.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {

                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {

                    errors[key] = errorClass;
                }
                else
                    errors[key] = '';
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (data.name === 'regionID') {
            state['subregionID'] = ''
            state['groupID'] = ''
            state['clientID'] = ''
        }

        if (data.name === 'subregionID') {
            state['groupID'] = ''
            state['clientID'] = ''
        }

        if (data.name === 'groupID') {
            state['clientID'] = ''
        }

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

    }

    handleOnChange = (e) => {
        let state = Object.assign({}, this.state.fields);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ fields: state }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ fields: state }, () => { this.errorChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (data.name === 'status')
            state.lastStatus = state.status;

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

    }

    onCheckChange = (e, name) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = e.target.checked;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data, true),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data, true),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ fields: state, models: models }, () => { this.errorChange(data.name); });
    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data, true),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data, true),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })


        }

        state['model'] = '';
        this.setState({ fields: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data, true),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            fields: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e, name) => {
        const { checked } = e.target;
        //console.log('handleCheckChange', name, checked)
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    handleCategoryChange = (e, name) => {
        const { checked } = e.target;
        //console.log('handleCheckChange', name, checked)
        let state = Object.assign({}, this.state.fields);
        state['category'] = checked ? name : '';

        this.setState({ fields: state }, () => { this.errorChange('category'); });

    }
    handleSaleTypeChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = value;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleStatusReload = () => {
        let state = Object.assign({}, this.state.fields);
        state.status = this.state.currentStatus;
        this.setState({ fields: state });
    }

    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            imageURL: null,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {
        const { src, id, type, ext } = cropShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            //fields: state,
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = async (e, type, id, title) => {
        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }

            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        let fleetProID = this.state.fields.documentID;
        if (!fleetProID) {
            fleetProID = this.refFleetPro.doc().id;
            //console.log('newly created one saveImageToStorage')
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`/fleetPro/${fleetProID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`/fleetPro/${fleetProID}`).child(uid).getDownloadURL()
                    .then(dataURL => {

                        let state = Object.assign({}, this.state.fields);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        state.documentID = fleetProID;
                        this.setState({ fields: state, imageURL: null })
                    })
            })
    };

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure?`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region DATEPICKER
    // handleDateChange = (val, name) => {
    //     let state = Object.assign({}, this.state.fields);
    //     if (val) {
    //         let date = state[name];
    //         if (date)
    //             state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
    //         else
    //             state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
    //     }
    //     else
    //         state[name] = null;

    //     if (name === 'etaDate' && _.isEmpty(state[name]))
    //         state['isStockArrived'] = false;

    //     if (name === 'stockInDate' && !_.isEmpty(state[name]) && state.status === stockStatus.INPRODUCTION)
    //         state.status = stockStatus.AVAILABLE;

    //     this.setState({ fields: state }, () => { this.errorChange(name); });

    // };

    handleFirebaseDateChange = (val, name) => {
        let fields = Object.assign({}, this.state.fields);
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (fields[name] ? moment.unix(fields[name].seconds).format('HH:mm') : moment().format('HH:mm')))._d;
            fields[name] = window.firebase.firestore.Timestamp.fromDate(moment(val)._d);
        }
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    handleFirebaseTimeChange = (val, name) => {
        let fields = Object.assign({}, this.state.fields);
        fields[name] = window.firebase.firestore.Timestamp.fromDate(moment(val)._d);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onValueChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let fields = Object.assign({}, this.state.fields);
        fields[name] = e.value;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }
    //#endregion

    //#region SAVE INFO
    handleSubmit = async (e) => {
        e.preventDefault();
        let exists = false;
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        const { dealersettings } = this.props;
        let _mandatoryFields = this.mandatoryFields;


        if (fields.status) {
            let _mandStatusFields = dealersettings?.client?.fleetPro?.mandatoryFields?.status;
            let _newStatusMandatoryFields = !_.isEmpty(_mandStatusFields) ? _mandStatusFields.filter(e => e.name === fields.status)[0]?.fleetPro?.split(',') : [];
            if (!_.isEmpty(_newStatusMandatoryFields)) _mandatoryFields = _.union(_mandatoryFields, _newStatusMandatoryFields)
        }

        let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.fleetProOptions))
            ? dealersettings.client.fleetProOptions :
            Object.assign([], _.uniq(_.union(fleetSortOptions, Object.assign([], (!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(fleetOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        dynamicDetailList = _.union(dynamicDetailList, ['images'])
        _mandatoryFields && _mandatoryFields.map(key => {
            if (dynamicDetailList.includes(key)) {
                if (objFleetPro.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        })
        this.setState({ errors: errors });
        //console.log('dynamicDetailList', _mandatoryFields, dynamicDetailList, errors, fields)
        if (formIsValid === true) {
            this.saveInfo(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

            return formIsValid;
        }

    };

    saveInfo = (e) => {
        //console.log('saveInfo');       
        this.setState({ isLoading: true });
        const { dealersettings } = this.props;
        const objData = Object.assign({}, this.state.fields);
        const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
        const _currentDate = window.firebase.firestore.Timestamp.now();
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = _userID;
            objData.addedDate = _currentDate;
        }

        objData.modifiedBy = _userID;
        objData.modifiedDate = _currentDate;
        let fleetProID = objData['documentID'];

        if (objData.groupID) {
            let _dealer = dealersettings.clients.filter(e => e.group === objData.groupID)[0];
            if (!objData.regionID) objData.regionID = _dealer.region;
            if (!objData.subregionID) objData.subregionID = _dealer.subregion;
        }
        else if (objData.subregionID) {
            let _dealer = dealersettings.clients.filter(e => e.subregion === objData.subregionID)[0];
            if (!objData.regionID) objData.regionID = _dealer.region;
        }

        if (!objData.status) objData.status = 'allocated'

        //Remove empty value from object
        //Object.keys(newEventVM).forEach(key => newEventVM[key] === undefined ? delete newEventVM[key] : {});
        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (propName !== 'images')
                    delete objData[propName];
            }
        }

        if (!fleetProID) {
            fleetProID = this.refFleetPro.doc().id;
            //console.log('newly created  stock')
        }

        const refFLTData = this.refFleetPro.doc(fleetProID);
        refFLTData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.setState({ isLoading: false });
            toast.notify('Fleet saved successfully', {
                duration: 2000
            });

            this.props.handleClose(fleetProID, objData);

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };
    //#endregion

    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            fields: {
                ...this.state.fields,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    //#region CHANGE EVENT Notes  
    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions, tradeinID) => {

        if (noteTxt) {
            const { documentID } = this.state.fields;

            const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
            const _currentDate = window.firebase.firestore.Timestamp.now();

            const objData = Object.assign({});
            objData.modifiedBy = _userID;
            objData.modifiedDate = _currentDate;
            objData.lastNote = noteTxt;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: window.firebase.firestore().collection('fleetPro').doc().id,
                notes: noteTxt,
                fleetProID: documentID,
                mentions: Object.assign([], convertedIDs),
                addedBy: _userID,
                addedDate: _currentDate,
                addedFrom: 'web'
            }

            const updateRef = window.firebase.firestore().doc(`fleetPro/${documentID}/notes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                const refFLTData = this.refFleetPro.doc(documentID);
                refFLTData.set(objData, { merge: true }).then((docRef) => {
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });



            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }
    //#endregion 

    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = e.value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(_data.name); });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion

    //#region  ADD VEHICLE MANUAL
    handleAddMakeEnable = () => {
        this.setState({
            addMake: true
        })
        setTimeout(() => {
            let elem = document.getElementById('make')
            elem.focus()
        }, 100)
    }

    handleAddMakeDisable = () => {
        this.setState({
            addMake: false,
            errors: {
                ...this.state.errors,
                ['make']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['make']: ''
            }
        })
    }

    handleAddGroupEnable = () => {
        this.setState({
            addGroup: true
        })
        setTimeout(() => {
            let elem = document.getElementById('group')
            elem.focus()
        }, 100)
    }

    handleAddGroupDisable = () => {
        this.setState({
            addGroup: false,
            errors: {
                ...this.state.errors,
                ['group']: 'input_error'
            },
            stock: {
                ...this.state.stock,
                ['group']: ''
            }
        })
    }

    handleAddTypeEnable = () => {
        this.setState({
            addType: true
        })
        setTimeout(() => {
            let elem = document.getElementById('type')
            elem.focus()
        }, 100)
    }

    handleAddTypeDisable = () => {
        this.setState({
            addType: false,
            errors: {
                ...this.state.errors,
                ['type']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['type']: ''
            }
        })
    }

    handleAddModelEnable = () => {
        this.setState({
            addModel: true
        })
        setTimeout(() => {
            let elem = document.getElementById('model')
            elem.focus()
        }, 100)
    }

    handleAddModelDisable = () => {
        this.setState({
            addModel: false,
            errors: {
                ...this.state.errors,
                ['model']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['model']: ''
            }
        })
    }

    handleVehicleScan = (_type) => {



    }

    //#endregion

    //#region DEALERS/GROUPS/REGIONS
    loadDealers = (isAll) => {
        const { regionIDs, subregionIDs, groupIDs } = this.state.fields;
        const { dealers } = this.state;
        let _dealers = [];
        if (!_.isEmpty(groupIDs)) {
            _dealers = dealers.filter(e => groupIDs.includes(e.group))
        }
        else if (!_.isEmpty(subregionIDs)) {
            _dealers = dealers.filter(e => subregionIDs.includes(e.subregion))
        }
        else if (!_.isEmpty(regionIDs))
            _dealers = dealers.filter(e => regionIDs.includes(e.region))
        else
            _dealers = dealers

        if (isAll && _dealers.length > 1) {
            _dealers = [{ label: 'All', value: 'all' }, ..._dealers];
        }

        return _dealers;
    }

    loadGroups = (isAll) => {
        const { regionIDs, subregionIDs } = this.state.fields;
        const { dealers, fleetgroups } = this.state;
        let _groups = [];
        if (!_.isEmpty(subregionIDs)) {
            _groups = fleetgroups.filter(doc => dealers.some(client => client.group === doc.value && subregionIDs.includes(client.subregion)))
        }
        else if (!_.isEmpty(regionIDs)) {
            _groups = fleetgroups.filter(doc => dealers.some(client => client.group === doc.value && regionIDs.includes(client.region)))
        }
        else
            _groups = fleetgroups

        if (isAll && _groups.length > 1) {
            _groups = [{ label: 'All', value: 'all' }, ..._groups];
        }

        return _groups;
    }

    loadSubRegions = (isAll) => {
        const { regionIDs } = this.state.fields;
        const { dealers, subregions } = this.state;
        let _subregions = [];
        if (!_.isEmpty(regionIDs)) {
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && regionIDs.includes(client.region)))
        }
        else _subregions = subregions

        if (isAll && _subregions.length > 1) {
            _subregions = [{ label: 'All', value: 'all' }, ..._subregions];
        }

        return _subregions;
    }

    loadRegions = (isAll) => {
        const { regions } = this.state;
        let _regions = regions;
        if (isAll && _regions.length > 1) {
            _regions = [{ label: 'All', value: 'all' }, ..._regions];
        }
        return _regions;
    }
    //#endregion

    render() {
        const { clientUsers, dealersettings, enableSale, enableTestDrive, enableLoan, enableServiceLoan, show, groupUsers } = this.props;

        const { addMake, addType, addModel, addGroup, showNotes, notesHistory, isLoading,
            dealers, fleetgroups, regions, subregions } = this.state
        const { documentID, category, images, clientID } = this.state.fields;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.fleetProPermissions)) ? dealersettings.rolePermissions.fleetProPermissions : null);

        const _settings = dealersettings.client.fleetPro;
        const canModifyFleet = true

        let dynamicDetailList = !_.isEmpty(_settings?.sortFleetOptions) ? _settings.sortFleetOptions : (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.fleetProOptions))
            ? dealersettings.client.fleetProOptions :
            Object.assign([], _.uniq(_.union(fleetSortOptions, Object.assign([], (!_.isEmpty(fleetOptionsDF) && !_.isEmpty(fleetOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(fleetOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));


        return (
            documentID
                ?
                <>
                    <Modal show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-fleet-module"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title> <Translate text={this.props.title} />

                            </Modal.Title>
                            {/* {
                                clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                    (<div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{CommonHelper.getOtherDealerName(dealersettings, clientID)}</div></div>)
                                    : (<></>)
                            } */}
                        </Modal.Header>

                        <Modal.Body>
                            <div className={`container-fluid`}>
                                <div className="row">
                                    <div className="pop-layout form-style">
                                        {
                                            !_.isEmpty(categories) ?
                                                <>
                                                    <div className="addstock-pop-head">
                                                        <div className="form-row">

                                                            <div className="form-group col-md-12 mb-3">
                                                                <label ><Translate text={'Category'} /></label>
                                                                {/* <div>
                                                                    {
                                                                        categories.filter(a => a.active).map((data, index) => {
                                                                            return <div key={index} className={`checkbox icheck-success float-left mr-3`}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`${data.value}_${index}`}
                                                                                    name={`${data.value}_${index}`}
                                                                                    className="uncheck-activity"
                                                                                    checked={category === data.value ? true : false}
                                                                                    onChange={(e) => {
                                                                                        this.handleCategoryChange(e, data.value)
                                                                                    }} />
                                                                                <label htmlFor={`${data.value}_${index}`}>{data.label}</label>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div> */}
                                                                <ReactSelect
                                                                    options={categories}
                                                                    name={'category'}
                                                                    onChange={this.handleReactSelectChange}
                                                                    value={category ? category : null}
                                                                    classNamePrefix={`${this.state.errors["category"]} basic-select`}
                                                                    removeClearable={true}
                                                                    isSearchable={true}
                                                                ></ReactSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <></>
                                        }


                                        <div className="modal-add-fleet-middle">
                                            <div className={`form-row`}>

                                                {
                                                    dynamicDetailList && dynamicDetailList.map((info, index) => (
                                                        <TipInfoInputs
                                                            {...this.props}
                                                            key={index}
                                                            type={info}
                                                            formClassName='form-group col-md-4'
                                                            fields={this.state.fields}
                                                            errors={this.state.errors}
                                                            currencySymbol={currencySymbol}
                                                            dealersettings={dealersettings}
                                                            fleetOptionsDF={fleetOptionsDF}
                                                            isComplete={false}
                                                            makes={this.state.makes}
                                                            addMake={addMake}
                                                            groups={this.state.groups}
                                                            addGroup={addGroup}
                                                            types={this.state.types}
                                                            addType={addType}
                                                            models={this.state.models}
                                                            addModel={addModel}
                                                            years={this.state.years}
                                                            fleetProStatus={fleetProStatus}
                                                            allFleetUsers={allFleetUsers}
                                                            dealers={dealers}
                                                            fleetgroups={fleetgroups}
                                                            subregions={subregions}
                                                            regions={regions}
                                                            loadDealers={this.loadDealers}
                                                            loadGroups={this.loadGroups}
                                                            loadSubRegions={this.loadSubRegions}
                                                            loadRegions={this.loadRegions}
                                                            handleAddMakeEnable={this.handleAddMakeEnable}
                                                            handleAddMakeDisable={this.handleAddMakeDisable}
                                                            handleMakeChange={this.handleMakeChange}
                                                            handleAddGroupEnable={this.handleAddGroupEnable}
                                                            handleAddGroupDisable={this.handleAddGroupDisable}
                                                            handleGroupChange={this.handleGroupChange}
                                                            handleAddTypeEnable={this.handleAddTypeEnable}
                                                            handleAddTypeDisable={this.handleAddTypeDisable}
                                                            handleTypeChange={this.handleTypeChange}
                                                            handleAddModelEnable={this.handleAddModelEnable}
                                                            handleAddModelDisable={this.handleAddModelDisable}
                                                            handleOnChange={this.handleOnChange}
                                                            handleVehicleScan={this.handleVehicleScan}
                                                            handleOnCapChange={this.handleOnCapChange}
                                                            handleDateChange={this.handleDateChange}
                                                            handleFirebaseDateChange={this.handleFirebaseDateChange}
                                                            handleFirebaseTimeChange={this.handleFirebaseTimeChange}
                                                            onValueChange={this.onValueChange}
                                                            handleCustomOption={this.handleCustomOption}
                                                            handleReactSelectChange={this.handleReactSelectChange}
                                                            onCurrencyChange={this.onCurrencyChange}
                                                            handleCheckChange={this.handleCheckChange}
                                                            handleOnAreaChange={this.handleOnAreaChange}
                                                            handleDynamicCheckChange={this.handleDynamicCheckChange}
                                                            handleDynamicOnChange={this.handleDynamicOnChange}
                                                            saveDynamicField={this.saveDynamicField}
                                                            cancelDynamicField={this.cancelDynamicField}
                                                            onDynamicNumberChange={this.onDynamicNumberChange}
                                                            handleDynamicDateChange={this.handleDynamicDateChange}
                                                            handleDynamicSelectSave={this.handleDynamicSelectSave}
                                                            handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}
                                                        ></TipInfoInputs>
                                                    ))

                                                }
                                            </div>

                                        </div>

                                        <>

                                            {/* <div className="requirement-pop-inner mt-3">
                                                <div className="linked-activities-title p-0 pb-2"> <Translate text={'Notes History'} />
                                                    <div className=" float-right linked-activities-add">
                                                        <button type="button"
                                                            className="btn btn-primary float-right"
                                                            onClick={(e) => { e.preventDefault(); this.handleNotesOpen(); }}
                                                        >
                                                            <i className="ico icon-add"></i> <Translate text={'addNote'} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    //notesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                    <div className="enquiry-flow mt-0 mb-3">
                                                        {
                                                            !_.isEmpty(notesHistory)
                                                                ?
                                                                <div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
                                                                    <div className="flow-timelinebar"></div>

                                                                    {
                                                                        notesHistory.map((obj, index) => {
                                                                            return (
                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                    <div className="flow-item  note  ">
                                                                                        <div className="flow-item-content">
                                                                                            <div className="flow-text-wrap">
                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                        {
                                                                                                            obj.addedByName
                                                                                                                ?
                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        obj.addedBy
                                                                                                                            ?
                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), obj.addedBy)}</>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="text-center p-2">
                                                                        <p><Translate text={'no notes created'} /> </p>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                    //)
                                                }
                                            </div> */}

                                        </>

                                        <div className="modal-add-fleet-inner">
                                            <div className="modal-add-fleet-section-head"><Translate text={'vehicleImages'} /></div>
                                            <div className="modal-add-fleet-image-wraper">

                                                <div className="modal-add-fleet-item-image upload">
                                                    <label htmlFor="fleet_images">
                                                        <img src={_images.addvehicle} alt="" htmlFor="fleet_images" />
                                                        <input className="fileInput"
                                                            type="file"
                                                            name="fleet_images"
                                                            id="fleet_images"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => this.onSelectFile(e, 'fleetPro', 'images', 'Fleet Image')} />
                                                    </label>
                                                </div>
                                                {!_.isEmpty(images) && images.map((data, index) => (
                                                    <div className="modal-add-fleet-item-image" key={index}>
                                                        {
                                                            canModifyFleet
                                                                ?
                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleImageDel(index);
                                                                }}>
                                                                    <i className="ico icon-delete" ></i>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        <a data-fancy-box={`vehicle_img`}
                                                            href={data}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    imageViewer: {
                                                                        show: true,
                                                                        images: images,
                                                                        index
                                                                    }
                                                                });
                                                            }}>
                                                            <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                        </a>
                                                    </div>
                                                ))}
                                                {
                                                    (this.state.imageURL) ?
                                                        (
                                                            <div className="modal-add-fleet-item-image">
                                                                <div className="img-loader">
                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                    <img src={this.state.imageURL} width="70" height="70" alt="" className="img-object-fit" />
                                                                </a>
                                                            </div>
                                                        )
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>

                                        <>
                                            <div className="modal-add-fleet-inner">
                                                {
                                                    documentID
                                                        ?
                                                        <>
                                                            <div className="enquiry-flow mt-0 mb-3 position-relative">
                                                                <Files
                                                                    fleetProID={documentID}
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    isDeivered={!canModifyFleet}
                                                                    filePath={`fleetPro/${documentID}/files`}
                                                                >
                                                                </Files>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>

                            <button
                                type="button"
                                className="btn btn-primary float-right ml-2"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'save'} />
                            </button>

                            <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleClose();
                                }}
                            ><Translate text={'cancel'} />
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <ImageCropHook
                        cropShow={this.state.cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>

                    <PopUpModal show={showNotes}>
                        <AddNotes
                            show={showNotes}
                            handleClose={this.handleNotesClose}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        />
                    </PopUpModal>

                    <PopUpModal show={this.state.imageViewer.show}>
                        <ImageViewer
                            {...this.state.imageViewer}
                            handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                        />
                    </PopUpModal>
                </>
                :
                <></>


        );
    }
}