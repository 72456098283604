/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { ImageViewer, PopUpModal } from '../../components'

class MarkingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageViewer: { show: false, images: null }
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    bindMArkType = (type, cost, showCost) => {

        if (type === 'MajorScratch') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge major-scratch">S</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'major'} /> <Translate text={'scratch'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }

                </div>
            </>
        }
        else if (type === 'MinorScratch') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge minor-scratch">S</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'minor'} /> <Translate text={'scratch'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MajorRust') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge major-rust">R</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'major'} /> <Translate text={'rust'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MinorRust') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge minor-rust">R</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'minor'} /> <Translate text={'rust'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MajorDent') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge major-dent">D</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'major'} /> <Translate text={'dent'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MinorDent') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge minor-dent">D</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'minor'} /> <Translate text={'dent'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MajorCrack') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge major-crack">C</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'major'} /> <Translate text={'crack'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else if (type === 'MinorCrack') {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge minor-crack">C</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'minor'} /> <Translate text={'crack'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
        else {
            return <>
                <div className="inspection-detail-icon">
                    <div className="marker-badge major-info">i</div>
                </div>
                <div className="inspection-detail-loop-head"><Translate text={'info'} />
                    {
                        showCost
                            ?
                            <div className="float-right inspection-detail-loop-price">
                                <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
        }
    }


    showDamageCost = (marks) => {

        if (!_.isEmpty(marks)) {
            var total = 0;
            for (var i = 0; i < marks.length; i++) {
                total = total + (marks[i].price ? marks[i].price : 0);
            }
            return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />;
        }
        else {
            return <NumberFormat value={0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={this.currencySymbol} />;
        }
    }

    render() {

        const { show, marks, beforeMarks } = this.props;

        return (
            <>

                <Modal
                    id="add-damage-cost"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-add-damage-cost">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" id=""><Translate text={'inspectionDetails'} />

                                {
                                    this.props.showCost
                                        ?
                                        <div className="float-right damage-head"><Translate text={'damageCost'} />
                                            <span className="badge badge-pill badge-damage-cost">{this.showDamageCost(marks)}</span>
                                        </div>
                                        :
                                        <></>
                                }

                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="inspection-detail-view">
                                    {
                                        beforeMarks && beforeMarks.map((mark, index) => {
                                            return <div className="inspection-detail-loop" key={index}>

                                                {this.bindMArkType(mark.markType, mark.price, this.props.showCost)}

                                                {mark.notes
                                                    ?
                                                    <div className="inspection-detail-loop-notes" >
                                                        {mark.notes}
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    (!_.isEmpty(mark.images))
                                                        ?
                                                        <div className="inspection-detail-loop-images">

                                                            {mark.images.map((data, index) => (
                                                                <div className="inspection-detail-image" key={index}>

                                                                    <a data-fancy-box={`inspection_det_img`} 
                                                                    href={data} 
                                                                    onClick={(e) => { e.preventDefault(); 
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: mark.images, 
                                                                                index
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </div>
                                                            ))}

                                                        </div>
                                                        :
                                                        <></>
                                                }


                                            </div>
                                        })
                                    }
                                    {
                                        marks && marks.map((mark, index) => {
                                            return <div className="inspection-detail-loop" key={index}>

                                                {this.bindMArkType(mark.markType, mark.price, this.props.showCost)}

                                                {mark.notes
                                                    ?
                                                    <div className="inspection-detail-loop-notes" >
                                                        {mark.notes}
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    (!_.isEmpty(mark.images))
                                                        ?
                                                        <div className="inspection-detail-loop-images">

                                                            {mark.images.map((data, index) => (
                                                                <div className="inspection-detail-image" key={index}>

                                                                    <a data-fancy-box={`inspection_det_img`} 
                                                                    href={data} 
                                                                    onClick={(e) => { e.preventDefault(); 
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: mark.images, 
                                                                                index
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </div>
                                                            ))}

                                                        </div>
                                                        :
                                                        <></>
                                                }


                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>

                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>


        );
    }
}

export default MarkingInfo;