/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes'
/** COMPONENTS */
import { InputRadio, ImageViewer, PopUpModal } from '../../../components';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
/** VIEW-MODELS */
import { objAttendees } from '../viewModel'
import { firestoreDB } from '../../../services/helper';


//#region  ASSIGN VARIABLES

//#endregion


var excluded = [
    "documentID",
    "displayID",
    "groupID",
    "regionID",
    "oemID",
    "contactID",
    "eventsProID",
    "projectId",
    "customerNumber",
    "addedBy",
    "modifiedBy",
    "addedDate",
    "modifiedDate",
    "modifiedFrom",
    "isDeleted",
    "owner",
    "keywords",
    "terms",
    "mergeID",
    "isDLScan",
    "businessContact",
    "preferredName",
    "phones",
    "emails",
    "contactType",
    "maritalStatus",
    "nationality",
    "language",
    "contactMethod",
    "interests",
    "dependents",
    "visitingCardURL",
    "driverName",
    "isVIP",
    "company",
    "designation",
    "secondaryTitle",
    "secondaryFirstName",
    "secondaryLastName",
    "secondaryPhoneCode",
    "secondaryPhone",
    "secondaryPhones",
    "secondaryEmail",
    "secondaryEmails",
    "secondaryLicenseFrontUrl",
    "secondaryLicenseBackUrl",
    "campaign",
    "banLoanVehicle",
    "origin",
    "marketingOptinEmail",
    "marketingOptinSMS",
    "requirements",
    "guests",
    "dynamicFields",
];

const order = [
    "clientID",
    "userImageURL",
    "tags",
    "title",
    "firstName",
    "middleName",
    "lastName",
    "gender",
    "dob",
    "phoneCode",
    "phone",
    "email",
    "licenseNo",
    "licenseExpiry",
    "licenseState",
    "licenseType",
    "licenseFrontURL",
    "licenseBackURL",
    "address",
    "areaCode",
    "state",
    "country",
    "checkinDate",
    "leadStatus",
    "dietaryPreference",
    "eventSlot",
    "dataLocation",
    "status",
    "testDrives",
    "optinPhone",
    "optinEmail",
    "optinPost",
    "optinSMS",
    "guestOf",
];


let languages = [];
let countries = [];
let states = [];

const MergeAttendee = (props) => {
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [selected, setSelected] = useState({})
    const [selectedDynamic, setSelectedDynamic] = useState({})
    const [viewableKeys, setviewableKeys] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [titles, setTitles] = useState([])

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const _titles = [];


            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            countries = [];
            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            states = [];
            dealersettings.states && dealersettings.states.forEach((doc) => {
                states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            //dummmy load
            setTitles(_titles);

        }

    }, [props.dealersettings])

    useEffect(() => {
        const _viewableKeys = []
        const _selected = {}
        let _selectedDymanic = {}
        const { contactB, contactA, eventSettings } = props;
        //console.log('contactA', contactA)
        //console.log('contactB', contactB)
        let _attendeesDF = Object.assign([], eventSettings?.attendeesDF);
        let dynamicOrder = Object.assign([], _.uniq(_.union(order, Object.assign([], (!_.isEmpty(_attendeesDF) && !_.isEmpty(_attendeesDF.filter(m => m.active === true)) ? Array.prototype.map.call(_attendeesDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
        let _xcluded = [...excluded];

        for (let [key] of Object.entries(objAttendees)) {
            let included = _xcluded.indexOf(key) >= 0 ? false : true;
            let hasValue1 = contactA[key] && (!_.isEmpty(contactA[key]) || contactA[key] === true) ? true : false;
            let hasValue2 = contactB[key] && (!_.isEmpty(contactB[key]) || contactB[key] === true) ? true : false;
            //console.log('setviewableKeys', key, hasValue1, hasValue2, contactA[key], contactB[key])
            if (included && (hasValue1 || hasValue2)) {
                _viewableKeys.push(key);
                _selected[key] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
            }

        }

        if (!_.isEmpty(_attendeesDF) & (!_.isEmpty(contactA.dynamicFields) || !_.isEmpty(contactB.dynamicFields))) {
            _attendeesDF.filter(a => a.active).forEach(rec => {
                let hasValue1 = contactA['dynamicFields'] && contactA.dynamicFields[rec.value] && (!_.isEmpty(contactA.dynamicFields[rec.value]) || contactA.dynamicFields[rec.value] === true) ? true : false;
                let hasValue2 = contactB['dynamicFields'] && contactB.dynamicFields[rec.value] && (!_.isEmpty(contactB.dynamicFields[rec.value]) || contactB.dynamicFields[rec.value] === true) ? true : false;
                if (hasValue1 || hasValue2) {
                    _viewableKeys.push(rec.value);
                    _selectedDymanic[rec.value] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
                }

            })
        }


        _viewableKeys
            .sort(function (x, y) {
                return (dynamicOrder.indexOf(x) < dynamicOrder.indexOf(y) ? -1 : 1);
            })
        setSelected(_selected);
        setSelectedDynamic(_selectedDymanic);
        setviewableKeys(_viewableKeys);
    }, [])

    const convertContactVM = (doc) => {
        const contact = Object.assign({}, doc);

        let dealersettings = props.dealersettings;
        let _setttings = contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            contact.title = CommonHelper.getNameByValue(_setttings.titles, contact.title, '');
            contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '');
            contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '');
        }
        contact.clientID = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
        contact.source = CommonHelper.getNameByValue(props.eventSettings?.source, contact.source, '');
        contact.dietaryPreference = CommonHelper.getNameByValue(props.eventSettings?.dietary, contact.dietaryPreference, '');
        contact.eventSlot = CommonHelper.getNameByValue(props.eventSettings?.slots, contact.eventSlot, '');
        contact.dataLocation = CommonHelper.getNameByValue(props.eventSettings?.dataLocation, contact.dataLocation, '');

        const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
        let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
        contact.leadStatus = !_.isEmpty(doc.leadStatus) ?
            (
                _objCurrentStatus
                    ?
                    <>
                        <span key={doc.leadStatus}
                            className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                    </>
            ) : ('');
        contact.status = (contact.status
            ?
            <div key={contact.status} className={`badge badge-pill badge-events-${contact.status}`}>
                {contact.status}
            </div>
            :
            <></>);

        // if (!_.isEmpty(doc.dynamicFields)) {

        //     props.eventSettings?.attendeesDF && props.eventSettings.attendeesDF.forEach(rec => {
        //         if (rec.type === 'toggle') {
        //             contact[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
        //         }
        //         else if (rec.type === 'price') {
        //             contact[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
        //         }
        //         else if (rec.type === 'date') {
        //             contact[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
        //         }
        //         else if (rec.type === 'monthYear') {
        //             contact[rec.value] = doc.dynamicFields[rec.value] ? moment(doc.dynamicFields[rec.value]).format('MMM YYYY') : '';
        //         }
        //         else if (rec.type === 'multiselect') {
        //             contact[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
        //         }
        //         else {
        //             contact[rec.value] = doc.dynamicFields[rec.value]
        //         }
        //     })
        // }
        return contact
    }

    const handleCheckChange = e => {
        const { name, value } = e.target;
        //console.log('handleCheckChange', name, value)
        let state = Object.assign({}, selected);
        state[name] = parseInt(value);
        setSelected(state);
    };


    const showDataView = (key, value) => {

        if (value || value === false) {
            if (key === 'company') {
                return <>{!_.isEmpty(value) ? value.name : '--'}</>
            }
            else if (key === 'userImageURL') {
                return <>
                    <a data-fancy-box={`mergeContacts`}
                        href={value}
                        onClick={(e) => {
                            e.preventDefault();
                            setImgViewer({
                                show: true,
                                images: value
                            })
                        }}>
                        <img src={value} width={'75px'} className={'rounded-circle img-object-fit'} />
                    </a>
                </>
            }
            else if (key === 'licenseFrontURL' || key === 'licenseBackURL' || key === 'visitingCardURL' || key === 'secondaryLicenseFrontUrl' || key === 'secondaryLicenseBackUrl' || key === 'userSignature') {
                return <>
                    <a data-fancy-box={`mergeContacts`}
                        href={value}
                        onClick={(e) => {
                            e.preventDefault();
                            setImgViewer({
                                show: true,
                                images: value
                            })
                        }}>
                        <img src={value} width={'150px'} className="img-object-fit" />
                    </a>
                </>
            }
            else if (key === 'emails' || key === 'phones' || key === 'secondaryPhones' || key === 'secondaryEmails') {
                return <>
                    {
                        !_.isEmpty(value)
                            ?
                            <>
                                {
                                    value.map((data, idx) => (
                                        data.value
                                            ?
                                            <div key={idx}>
                                                {data.value}{' [' + data.name + ']'}<br />
                                            </div>
                                            :
                                            <>{'--'}</>
                                    ))
                                }
                            </>
                            :
                            <>{'--'}</>
                    }
                </>
            }
            else if (key === 'language') {
                return <>{CommonHelper.getLabelByValue(languages, value, '--')}</>
            }
            else if (key === 'leadStatus') {
                return <>{value ? value : '--'}</>
            }
            else if (key === 'country') {
                return <>{CommonHelper.getLabelByValue(countries, value, '--')}</>
            }
            else if (key === 'state' || key === 'licenseState') {
                return <>{CommonHelper.getLabelByValue(states, value, '--')}</>
            }
            else if (key === 'businessContact' || key === 'isVIP' || key === 'banLoanVehicle' || key === 'optinPhone'
                || key === 'optinSMS' || key === 'optinEmail' || key === 'optinPost' || key === 'gdprDeleted' || key === 'marketingOptinEmail' || key === 'marketingOptinSMS') {
                return <>{value ? 'Yes' : 'No'}</>
            }
            else if (key === 'dob' || key === 'licenseExpiry') {
                return <>{value ? moment(value).format('DD MMM YYYY') : '--'}</>
            }
            else if (key === 'checkinDate') {
                return <>{value ? moment.unix(value.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
            }
            else if (key === 'tags') {
                return <>{
                    !_.isEmpty(value) && value.length > 0 ?
                        (<div className="flow-notes-sub">
                            {
                                value.map((rec, index) => {
                                    return <div key={index} className="badge badge-pill badge-input-white mr-1 text-uppercase">{rec.type}</div>
                                })
                            }
                        </div>)
                        : (<></>)
                }</>
            }
            else if (key === 'testDrives') {
                return <>{
                    !_.isEmpty(value) && value.length > 0 ?
                        (<div className="flow-notes-sub">
                            {
                                value.map((rec, index) => {
                                    return <div key={index} className="badge badge-pill badge-input-white mr-1">{rec.make + ' ' + rec.model}</div>
                                })
                            }
                        </div>)
                        : (<></>)
                }</>
            }
            else {
                return <>{value && !_.isObject(value) ? value : '---'}</>
            }
        }
        else {
            return <>{'--'}</>
        }
    }

    const handleDynamicCheckChange = e => {
        const { name, value } = e.target;
        //console.log('handleCheckChange', name, value)
        let state = Object.assign({}, selectedDynamic);
        state[name] = parseInt(value);
        setSelectedDynamic(state);
    };

    const showDynamicDataView = (key, value, data) => {

        if (value || value === false) {
            if (data.type === 'text' || data.type === 'select') {
                return <>{value ? value : '--'}</>
            }
            else if (data.type === 'number') {
                return <>{_.isNumber(value) ? value : '--'}</>
            }
            else if (data.type === 'multiselect') {
                return <>{!_.isEmpty(value) ? value.join(', ') : '--'}</>
            }
            else if (data.type === 'date') {
                return <>{!_.isEmpty(value) ? moment.unix(value.seconds).format('DD MMM YYYY') : '--'}</>
            }
            else if (data.type === 'monthYear') {
                return <>{!_.isEmpty(value) ? moment(value).format('MMM YYYY') : '--'}</>
            }
            else if (data.type === 'price') {
                return <>{value ? <NumberFormat value={value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</>
            }
            else if (data.type === 'switch' || data.type === 'toggle') {
                return <>{value ? 'Yes' : 'No'}</>
            }
            else {
                return <>{value ? value : '--'}</>
            }
        }
        else {
            return <>{'--'}</>
        }
    }

    const handleMerge = (e) => {
        e.preventDefault();
        setLoading(true);
        const { contactB, contactA, dealersettings } = props;

        let merged = Object.assign({})
        for (let [key, value] of Object.entries(selected)) {
            if (key === 'clientID') {
                merged['contactID'] = (value == 0) ? contactA['contactID'] : contactB['contactID'];
                merged['clientName'] = (value == 0) ? contactA['clientName'] : contactB['clientName'];
                merged['regionID'] = (value == 0) ? contactA['regionID'] : contactB['regionID'];
                merged['groupID'] = (value == 0) ? contactA['groupID'] : contactB['groupID'];
                merged['oemID'] = (value == 0) ? contactA['oemID'] : contactB['oemID'];
            }
            merged[key] = (value == 0) ? contactA[key] : contactB[key];
        }

        let mergedDynamic = Object.assign({}, contactA.dynamicFields)
        for (let [key, value] of Object.entries(selectedDynamic)) {
            mergedDynamic[key] = (value == 0) ? contactA?.dynamicFields?.[key] : (contactB.dynamicFields?.[key] ? contactB.dynamicFields[key] : null);
        }
        Object.keys(mergedDynamic).forEach(key => mergedDynamic[key] === undefined ? delete mergedDynamic[key] : {});
        merged['dynamicFields'] = mergedDynamic;

        // MERGE BOTH ATTENDEE NOTES
        let _notes = Object.assign([], contactA.notes)
        if (!_.isEmpty(contactB.notes)) _notes = [..._notes, ...contactB.notes]
        merged['notes'] = _notes;


        merged.modifiedBy = localStorage.uid;
        merged.modifiedDate = window.firebase.firestore.Timestamp.now();
        merged.modifiedFrom = 'web';

        Object.keys(merged).forEach(key => merged[key] === undefined ? delete merged[key] : {});

        //console.log('merged', merged)
        const batch = firestoreDB(dealersettings).firestore().batch();
        batch.set(firestoreDB(dealersettings).firestore().doc(`eventsProAttendees/${contactA.documentID}`), merged, { merge: true });
        batch.set(firestoreDB(dealersettings).firestore().doc(`eventsProAttendees/${contactB.documentID}`), {
            isDeleted: true,
            mergeID: contactA.documentID,
            modifiedBy: merged.modifiedBy,
            modifiedFrom: 'web',
            modifiedDate: merged.modifiedDate
        }, { merge: true });
        batch.commit()
            .then(async () => {
                setLoading(false);
                toast.notify('Attendee merged successfuly', { duration: 2000 });
                props.handleClose(contactB.documentID);
            });
    }

    let { show, title, contactA, contactB, eventSettings } = props;
    let attendeesDF = Object.assign([], eventSettings?.attendeesDF);
    contactB = convertContactVM(contactB);
    contactA = convertContactVM(contactA);
    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-merge-contact">
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="service-loan-yellow-alert">
                                <div className="alert alert-warning" role="alert">
                                    <div className="service-alert-flex">
                                        <div className="service-alert-flex-data pl-1">
                                            <h4><Translate text={'note'} />: </h4>
                                            <p>- <Translate text={"The 'Attendee 1' will be taken as a primary attendee."} /></p>
                                            <p>- <Translate text={'The merge process cannot be reversed.'} /> </p>
                                            <p>- <Translate text={'Notes from both the attendees, will be added to the primary attendee.'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="merge-contact-layout form-style">
                                <div className="common-table">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="20%"><Translate text={'Property'} /></th>
                                                <th scope="col" className="head-light" width="30%"><Translate text={'attendee'} /> 1</th>
                                                <th scope="col" className="head-light" width="30%"><Translate text={'attendee'} /> 2

                                                </th>
                                                {/* <th scope="col" className="head-light" width="20%">Final</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr key={'displayID'}>
                                                <th scope="row" className="th-active-bg"><Translate text={'contactID'} /></th>
                                                <td>
                                                    {
                                                        (!_.isEmpty(contactA['displayID']))
                                                            ?
                                                            <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{contactA['displayID']}</div></div>
                                                            :
                                                            <></>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (!_.isEmpty(contactB['displayID']))
                                                            ?
                                                            <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{contactB['displayID']}</div></div>
                                                            :
                                                            <></>
                                                    }
                                                </td>

                                            </tr> */}
                                            {
                                                !_.isEmpty(viewableKeys) && viewableKeys.map((key, idx) => {
                                                    if (objAttendees.hasOwnProperty(key)) {
                                                        return <tr key={idx}>
                                                            <th scope="row" className="th-active-bg"><Translate text={key} /> </th>
                                                            <td>
                                                                <div className="merge-contact-list">
                                                                    <div className="radio icheck-success">
                                                                        <InputRadio
                                                                            id={key + '_0'}
                                                                            type="radio"
                                                                            value={0}
                                                                            name={key}
                                                                            checked={selected[key] === 0 ? true : false}
                                                                            onChange={handleCheckChange}
                                                                            disabled={(!_.isEmpty(contactA[key]) || _.isBoolean(contactA[key])) && contactA[key] != contactB[key] ? false : true}
                                                                        />
                                                                        <label htmlFor={key + '_0'}></label>
                                                                    </div>
                                                                    {showDataView(key, contactA[key])}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="merge-contact-list">
                                                                    <div className="radio icheck-success">
                                                                        <InputRadio
                                                                            id={key + '_1'}
                                                                            type="radio"
                                                                            value={1}
                                                                            name={key}
                                                                            checked={selected[key] === 1 ? true : false}
                                                                            onChange={handleCheckChange}
                                                                            disabled={(!_.isEmpty(contactB[key]) || _.isBoolean(contactB[key])) && contactA[key] != contactB[key] ? false : true}
                                                                        />
                                                                        <label htmlFor={key + '_1'}></label>
                                                                    </div>
                                                                    {showDataView(key, contactB[key])}
                                                                </div>
                                                            </td>
                                                            {/* <td >Mark Thomson</td> */}
                                                        </tr>
                                                    }
                                                    else if (!_.isEmpty(attendeesDF) && !_.isEmpty(attendeesDF.filter(m => m.value === key && m.active === true)[0])) {
                                                        return <tr key={idx}>
                                                            {(() => {
                                                                let _dataDynamic = attendeesDF.filter(m => m.value === key && m.active === true)[0]
                                                                return <>
                                                                    <th scope="row" className="th-active-bg">{_dataDynamic.name}</th>
                                                                    <td>
                                                                        <div className="merge-contact-list">
                                                                            <div className="radio icheck-success">
                                                                                <InputRadio
                                                                                    id={key + '_0'}
                                                                                    type="radio"
                                                                                    value={0}
                                                                                    name={key}
                                                                                    checked={selectedDynamic[key] === 0 ? true : false}
                                                                                    onChange={handleDynamicCheckChange}
                                                                                    disabled={!_.isEmpty(contactA.dynamicFields) && !_.isEmpty(contactB.dynamicFields) && (!_.isEmpty(contactA.dynamicFields[key]) || _.isBoolean(contactA.dynamicFields[key]) || _.isNumber(contactA.dynamicFields[key])) && contactA.dynamicFields[key] != contactB.dynamicFields[key] ? false : true}
                                                                                />
                                                                                <label htmlFor={key + '_0'}></label>
                                                                            </div>
                                                                            {showDynamicDataView(key, (contactA.dynamicFields && contactA.dynamicFields[key]), _dataDynamic)}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="merge-contact-list">
                                                                            <div className="radio icheck-success">
                                                                                <InputRadio
                                                                                    id={key + '_1'}
                                                                                    type="radio"
                                                                                    value={1}
                                                                                    name={key}
                                                                                    checked={selectedDynamic[key] === 1 ? true : false}
                                                                                    onChange={handleDynamicCheckChange}
                                                                                    disabled={!_.isEmpty(contactA.dynamicFields) && !_.isEmpty(contactB.dynamicFields) && (!_.isEmpty(contactB.dynamicFields[key]) || _.isBoolean(contactB.dynamicFields[key]) || _.isNumber(contactB.dynamicFields[key])) && contactA.dynamicFields[key] != contactB.dynamicFields[key] ? false : true}
                                                                                />
                                                                                <label htmlFor={key + '_1'}></label>
                                                                            </div>
                                                                            {showDynamicDataView(key, (contactB.dynamicFields && contactB.dynamicFields[key]), _dataDynamic)}
                                                                        </div>
                                                                    </td>
                                                                </>
                                                            })()}
                                                        </tr>
                                                    }
                                                })
                                            }

                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                {
                    props.isReadOnly
                        ?
                        <></>
                        :
                        <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                            <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose(); }}>
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleMerge(e)} >
                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'merge'} />
                            </button>
                        </Modal.Footer>
                }

            </Modal>


            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>



        </>
    );

}
export default MergeAttendee;