import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from '../../dashboard/pipelineCounts'
import PipelinePorscheCounts from '../../dashboardPorsche/pipelineCounts'
import { PopUpModal } from '../../../components'
import EnquiryList from '../../dashboard/enquiryList'
import ActivityList from '../../dashboard/activitiesList'
import { initialLayoutArr, mclarenLayoutArr, porscheLayoutArr } from './viewModel';
import TestDriveList from '../../dashboard/testdriveList'
import TradeInList from '../../dashboard/tradeinList'
import { default as _images } from '../../../images';
import Swal from 'sweetalert2';
import CommonHelper from '../../../services/common';
import DragDropLayout from './dragDropLayout';

const defaulModelState = {
    enquiry: false,
    activity: false,
    testdrive: false,
    tradein: false
}

const DashboardSales = (props) => {
    const [loader, setLoader] = useState(false)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdContacts, setContacts] = useState([]);
    const [selectedPipeline, setPipeline] = useState();
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard ? { value: props.dealersettings.id } : null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [applydateRange, setApplyDateRange] = useState(false)
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState(true)
    const [dashboardFilter, setDashboardFilter] = useState({})

    const [initialPieChartLoad, setInitialPieChartLoad] = useState(true)
    const [pieChartLoader, setPieChartLoader] = useState(false)
    const [pieChartData, setPieChartData] = useState([]);
    const [dealersPieData, setDealersPieData] = useState([])
    const [firstTimePieChartLoaded, setFirstTimePieChartLoaded] = useState(false);

    const [initialBarChartLoad, setInitialBarChartLoad] = useState(true)
    const [barChartLoader, setBarChartLoader] = useState(false)
    const [barChartData, setBarChartData] = useState([]);
    const [dealersBarData, setDealersBarData] = useState([])
    const [firstTimeBarChartLoaded, setFirstTimeBarChartLoaded] = useState(false);

    const [oldDaterange, setOldDaterange] = useState({})

    const isMclaren = !_.isEmpty(props.dealersettings) && props.dealersettings.isMclaren === true ? true : false
    const isPorsche = !_.isEmpty(props.dealersettings) && props.dealersettings.isPorsche === true ? true : false
    const pathPrefix = isMclaren ? 'mcLaren' : isPorsche ? 'porsche' : '';

    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/${pathPrefix}oemDashboardSettings/${localStorage.uid}`);
    const enquiryStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.settings) &&
        props.dealersettings.client.settings.enquiryStatus) ? props.dealersettings.client.settings.enquiryStatus.filter(a => Boolean(a.active)) : []);

    let _dynStatus = enquiryStatus.filter(a => a.value !== 'prospectLost').map(r => { return r.value });
    const allStats = ['new', ..._dynStatus, 'delivery', 'pendingdelivery', 'testdrive', 'tradein'];
    const staticDashboard = isMclaren;

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const inclInboundBuckeinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundBuckeinRpt) ? true : false);

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (staticDashboard === true) {
                setLayout(sortedMclarenLayout(mclarenLayoutArr))
                setLayoutLoader(false)
            }
            else if (isPorsche === true) {
                setLayout(data?.dashboardLayout?.filter(a => a.i !== 'null') || porscheLayoutArr)
                setLayoutLoader(false)
            }
            else if (data && !_.isEmpty(data.dashboardLayout)) {
                setLayout(data.dashboardLayout.filter(a => a.i !== 'null'));
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleShowEnquiryList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Enquiry List')
        setModelShow({
            ...defaulModelState,
            enquiry: true,
            counts
        })
    }

    const handleShowActivityList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Activities List')
        setModelShow({
            ...defaulModelState,
            activity: true,
            counts
        })
    }

    const handleShowTestDriveList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Test Drive List')
        setModelShow({
            ...defaulModelState,
            testdrive: true,
            counts
        })
    }

    const handleShowTradeinList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Trade-In List')
        setModelShow({
            ...defaulModelState,
            tradein: true,
            counts
        })
    }

    const onLayoutChange = async (_layout) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.filter(a => a.i !== 'null').map((rec, index) => {
            return {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
                minW: 4,
                h: (props.dealersettings.isMclaren === true && rec.i === 'DealerTarget' && rec.h < 22) ? 22 : (props.dealersettings.isMclaren === false && rec.i === 'DealerTarget' && rec.h >= 22) ? 11 : (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
                w: (props.dealersettings.isMclaren === true && rec.i === 'DealerTarget' && rec.w < 8) ? 8 : (props.dealersettings.isMclaren === false && rec.i === 'DealerTarget' && rec.w >= 8) ? 4 : (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        if (staticDashboard === true) {
            setLayout(sortedMclarenLayout(mclarenLayoutArr))
        } 
        //else if (isPorsche === true) {
        //    setLayout(porscheLayoutArr)
        //} 
        else {
            setLayout(_dLayout)
        }
        try {
            await userRef.set({
                dashboardLayout: _dLayout,
            }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const sortedMclarenLayout = (mclarenLayoutArr) => {
        let _layout = mclarenLayoutArr.filter(a => a.x >= 0);
        let i = 0;
        let lastX = 0;
        let pipelines = props.pipeline.filter(a => a.value !== 'LeadsBucket');
        mclarenLayoutArr.filter(a => a.x < 0).forEach(rec => {
            let lastY = _.last(_layout).y;
            if (lastX === 0) lastY += 11;
            _layout.push({ ...rec, w: 4, h: 11, x: lastX, y: lastY })
            if (!_.isEmpty(pipelines[i])) {
                _layout.push({ i: `SalesFunnel-${pipelines[i].name}`, w: 4, h: 11, x: lastX + 4, y: lastY })
                _layout.push({ i: `SalesFunnel-${pipelines[i].name}-OVERALL`, w: 4, h: 11, x: lastX + 8, y: lastY })
                lastX = 12;
            } else {
                lastX += 4;
            }
            if (lastX === 12) lastX = 0
            i++;
        })
        return _layout;
    }

    useEffect(() => {
        if (_.isEmpty(props?.dealersettings?.client?.settings))
            return
        try {
            const allDealers = CommonHelper.getAllDealers(props.dealersettings, true, props.dealersettings?.isPorsche)
            let _clientSettings = props.dealersettings.client.settings;
            let _settingsPromise = [];
            allDealers.forEach(rec => {
                _settingsPromise.push(window.firebase.firestore().doc(`clients/${rec}/currentSettings/${rec}`).get());
            })
            Promise.all(_settingsPromise).then(docs => {
                docs.forEach(doc => {
                    if (doc?.data()?.settings) {
                        Object.keys(doc.data().settings).forEach(setting => {
                            _clientSettings = {
                                ..._clientSettings,
                                [setting]: _.isArray(doc.data().settings[setting]) ? _.uniqBy(_.union(_clientSettings[setting], doc.data().settings[setting]), 'value') : _clientSettings[setting]
                            }
                        })
                    }
                })
                props.dealersettings.client.settings = _clientSettings;
            })
        } catch (err) { }
    }, [])
    //console.log(props?.dealersettings?.client?.settings?.origins);
    //#region sameer 02-10-24
    useEffect(() => {
        if (!initialPieChartLoad || !isPorsche) return;

        //console.log(`initialPieChartLoad ${props.id}`, props.pieChartLoader, props.pieChartData)

        setInitialPieChartLoad(false)
        fetchPieChartData();
    }, [initialPieChartLoad])

    useEffect(() => {
        if (!isPorsche)
            return;

        if (initialPieChartLoad)
            return;

        if (!_.isEmpty(dashboardFilter)) {
            if ((!_.isEmpty(oldDaterange) || !_.isEmpty(dashboardFilter?.daterange)) && JSON.stringify(oldDaterange) !== JSON.stringify(dashboardFilter?.daterange)) {
                setInitialPieChartLoad(false);

                //console.log(`dashboardFilter-1 ${props.id}`, pieChartLoader, pieChartData)
                fetchPieChartData()
                setOldDaterange(dashboardFilter?.daterange)
            }
            else {
                //console.log(`dashboardFilter-2 ${props.id}`, pieChartLoader, pieChartData)

                setPieChartData(pieChartData);
                setFirstTimePieChartLoaded(!firstTimePieChartLoaded)
            }
        }
        else {

            //console.log(`dashboardFilter-7 ${props.id}`, pieChartLoader, pieChartData)
            if (dealersPieData) { setPieChartData(dealersPieData); setFirstTimePieChartLoaded(!firstTimePieChartLoaded); }
            else { setInitialPieChartLoad(true) }
        }

    }, [dashboardFilter])



    const fetchPieChartData = async () => {

        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings, null, props.dealersettings?.isPorsche),
            dmsDate: true,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            hideTradein: true,
            hideTestdrive: true,
            hideActivities: true,
            hideWalkin: true,
            scheduleActivities: false,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
        }
        if (dashboardFilter?.daterange?.startDate && dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else if (!_.isEmpty(dateRange)) {
            _filter.startDate = moment(dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        //console.log('fetchPieChartData', _filter)
        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }
        setPieChartLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);

        //console.log('fetchPieChartData-resp', resp.data.data)
        if (resp.data.success) {
            setPieChartLoader(false)
            setPieChartData(resp.data.data);
            setFirstTimePieChartLoaded(!firstTimePieChartLoaded)

            if (_.isEmpty(dashboardFilter)) {
                setDealersPieData(resp.data.data)
            }
        } else {
            //toast.closeAll();
            // Swal.fire('Something went wrong.');
            setPieChartLoader(false)
        }
    }
    //#endregion

    //#region sameer bar chart 02-10-24
    useEffect(() => {
        if (!initialBarChartLoad || !isPorsche) return;

        //console.log(`initialBarChartLoad ${props.id}`, props.barChartLoader, props.barChartData)

        setInitialBarChartLoad(false)
        fetchBarChartData();
    }, [initialBarChartLoad])

    useEffect(() => {
        if (!isPorsche)
            return;

        if (initialBarChartLoad)
            return;

        if (!_.isEmpty(dashboardFilter)) {
            if ((!_.isEmpty(oldDaterange) || !_.isEmpty(dashboardFilter?.daterange)) && JSON.stringify(oldDaterange) !== JSON.stringify(dashboardFilter?.daterange)) {
                setInitialBarChartLoad(false);

                //console.log(`dashboardFilter-1 ${props.id}`, barChartLoader, barChartData)
                fetchBarChartData()
                setOldDaterange(dashboardFilter?.daterange)
            }
            else {
                //console.log(`dashboardFilter-2 ${props.id}`, barChartLoader, barChartData)

                setBarChartData(barChartData);
                setFirstTimeBarChartLoaded(!firstTimeBarChartLoaded)
            }
        }
        else {

            //console.log(`dashboardFilter-7 ${props.id}`, barChartLoader, barChartData)
            if (dealersBarData) { setBarChartData(dealersBarData); setFirstTimeBarChartLoaded(!firstTimeBarChartLoaded); }
            else { setInitialBarChartLoad(true) }
        }

    }, [dashboardFilter])



    const fetchBarChartData = async () => {

        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings, null, props.dealersettings?.isPorsche),
            dmsDate: true,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            // hideTradein: true,
            hideActivities: true,
            // hideWalkin: true,
            scheduleActivities: false,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
        }
        if (dashboardFilter?.daterange?.startDate && dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else {
            _filter.startDate = moment().startOf('year').format('YYYY-MM-DD')
            _filter.endDate = moment().endOf('month').format('YYYY-MM-DD')
        }
        //console.log('fetchBarChartData', _filter)
        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }
        setBarChartLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);

        //console.log('fetchBarChartData-resp', resp.data.data)
        if (resp.data.success) {
            setBarChartLoader(false)
            setBarChartData(resp.data.data);
            setFirstTimeBarChartLoaded(!firstTimeBarChartLoaded)

            if (_.isEmpty(dashboardFilter)) {
                setDealersBarData(resp.data.data)
            }
        } else {
            //toast.closeAll();
            // Swal.fire('Something went wrong.');
            setBarChartLoader(false)
        }
    }
    //#endregion

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    isPorsche
                        ?
                        <PipelinePorscheCounts {...props}
                            handleShowEnquiryList={handleShowEnquiryList}
                            handleShowActivityList={handleShowActivityList}
                            handleShowTestDriveList={handleShowTestDriveList}
                            handleShowTradeinList={handleShowTradeinList}
                            onLayoutChange={onLayoutChange}
                            layout={layout}
                            setLayout={setLayout}
                            dashboardFilter={dashboardFilter}
                            enquiryStatus={enquiryStatus}
                            sortedStats={allStats}
                            dateRange={dateRange}
                            applydateRange={applydateRange}
                            fromOEM={true}
                            setDashboardFilter={(val) => {
                                setDashboardFilter(val)
                                if (val?.daterange) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.startDate.toDate()).startOf('day')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.endDate.toDate()).endOf('day')._d)
                                    })
                                    setApplyDateRange(true)
                                } else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                    })
                                    setApplyDateRange(false)
                                }
                            }}
                        />
                        :
                        <PipelineCounts {...props}
                            handleShowEnquiryList={handleShowEnquiryList}
                            handleShowActivityList={handleShowActivityList}
                            handleShowTestDriveList={handleShowTestDriveList}
                            handleShowTradeinList={handleShowTradeinList}
                            onLayoutChange={onLayoutChange}
                            layout={layout}
                            setLayout={setLayout}
                            dashboardFilter={dashboardFilter}
                            enquiryStatus={enquiryStatus}
                            sortedStats={allStats}
                            dateRange={dateRange}
                            applydateRange={applydateRange}
                            fromOEM={true}
                            setDashboardFilter={(val) => {
                                setDashboardFilter(val)
                                if (val?.daterange) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.startDate.toDate()).startOf('day')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.endDate.toDate()).endOf('day')._d)
                                    })
                                    setApplyDateRange(true)
                                } else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                    })
                                    setApplyDateRange(false)
                                }
                            }}
                        />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {(layoutLoader) ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border loader-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                handleShowEnquiryList={handleShowEnquiryList}
                                handleShowActivityList={handleShowActivityList}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                initialLayout={layout}
                                enquiryStatus={enquiryStatus}
                                isMclaren={isMclaren}
                                dashboardFilter={dashboardFilter}
                                fromOEM={true}
                                staticDashboard={staticDashboard}
                                porscheDashboard={isPorsche}
                                pieChartData={pieChartData}
                                pieChartLoader={pieChartLoader}
                                firstTimePieChartLoaded={firstTimePieChartLoaded}
                                barChartData={barChartData}
                                barChartLoader={barChartLoader}
                                firstTimeBarChartLoaded={firstTimeBarChartLoaded}
                            />
                        )
                    }
                </div>
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={pipelineEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    fromOEM={true}
                    dashboardFilter={dashboardFilter}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={allActivities}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    fromOEM={true}
                    dashboardFilter={dashboardFilter}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTestdrives}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                    fromOEM={true}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.tradein}>
                <TradeInList
                    {...props}
                    show={modelShow.tradein}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTradeins}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                    fromOEM={true}
                ></TradeInList>
            </PopUpModal>
        </div>
    )
}

export default DashboardSales