/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CommonHelper from '../../../services/common';
import { AutoComplete, InputText, InputTextArea, PopUpModal, ReactSelect, InputRadio, Terms, ReactMultiSelect } from '../../../components';
import "../../../styles/attendeeConfirmation.scss";
import _, { pull } from 'lodash'
import AddGuest from './addGuest';
import images from '../../../images';
import Swal from 'sweetalert2';
import { validateEmail } from '../../../services/helper';
import { defaultTitles } from '../../../admin/settings/company/oemSetup/viewModel';
import moment from 'moment';
import { contactResponseCode } from '../../../services/enum';
import Translate from '../../../constants/translate';

const defaultContact =
{
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    dietaryPreference: null,
    preferredRetailer: null,
    areaCode: null,
    country: null,
    state: null,
    ownedVehicle: {
        make: null,
        model: null,
        year: null,
        vinNo: null
    },
    clientID: null,
    optinPhone: true,
    optinEmail: true,
    optinSMS: true,
    optinPost: true,
}

const opts = [
    { active: true, value: 'true', label: <Translate text={'Opt In'} /> },
    { active: true, value: 'false', label: <Translate text={'Opt Out'} /> }
]

const AttendeeConfirmationEOI = props => {
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _cid = params.get('cid');
    const _source = params.get('source');
    const _type = params.get('type');
    const _backurl = params.get('back-url');
    const [firestoreDB, setFirestoreDB] = useState()
    const [submitLoader, setSubmitLoader] = useState(false);
    const [projectId, setProjectId] = useState()
    const [event, setEvent] = useState()
    const [contact, setContact] = useState({ ...defaultContact, status: (_type !== 'eoi' ? 'accepted' : null), })
    const [settings, setSettings] = useState()
    const [client, setClient] = useState()
    const [loader, setLoader] = useState(true);
    const [guestInfo, setGuestInfo] = useState([]);
    const [guestModel, setGuestModel] = useState({ show: false });
    const [contactStatus, setContactStatus] = useState();
    const [clientSettings, setClientSettings] = useState()
    const [notes, setNotes] = useState()
    const [eventDates, setEventDates] = useState([])
    const [eventDate, setEventDate] = useState()
    const [errorFields, setErrorFields] = useState({});
    const [makes, setMakes] = useState()
    const [years, setYears] = useState([])
    const [dealers, setDealers] = useState([])
    const [showTerms, setshowTerms] = useState(false)
    const [timezone, setTimezone] = useState(moment.tz.guess())
    // const [phoneCodes, setPhoneCodes] = useState([]);
    const [mandFields, setMandFields] = useState(["firstName", "lastName", "email"]);
    const [hiddenFields, setHiddenFields] = useState([]);
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [moi, setMOI] = useState()
    const [moiMakes, setMOIMakes] = useState()
    const [moiModels, setMOIModels] = useState()

    useEffect(() => {

        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
            years.push({
                value: i.toString(),
                label: i.toString(),
                active: true,
            });
        }
        setYears(years)


        return () => {
            window.unsubeventsProsettings && window.unsubeventsProsettings();

        }
    }, [])



    useEffect(() => {
        if (loader || _.isEmpty(event))
            return
        let _dates = []
        for (var m = moment.unix(event.startDate.seconds).tz(timezone).startOf('day'); m.isSameOrBefore(moment.unix(event.endDate.seconds).tz(timezone).startOf('day')); m.add(1, 'days')) {
            _dates.push({
                value: m.format('YYYY-MM-DD'),
                label: m.format(settings?.dateFormat || 'DD/MM/YYYY'),
            })
        }
        setEventDates(_dates)
    }, [loader])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        window.unsubeventsProsettings = firestoreDB.firestore().collection(`eventsPro`).doc(props.eventid).collection('settings').doc(props.eventid)
            .onSnapshot(querySnapshot => {
                if (querySnapshot.exists) {
                    setSettings({
                        ...querySnapshot.data(),
                        guest: _type === 'eoi' ? querySnapshot.data().guestEOI : querySnapshot.data().guest,
                        makes: _type === 'eoi' ? querySnapshot.data().makesEOI : querySnapshot.data().makes,
                        slots: querySnapshot?.data()?.slots ? querySnapshot.data().slots.map((r, i) => {
                            return {
                                ...r,
                                sortTime: r?.startTime?.seconds ? moment.unix(r.startTime.seconds).tz(timezone).format('HH:mm') : `${i}`
                            }
                        }) : []
                    });
                }
            })
    }, [firestoreDB])

    useEffect(() => {
        if (props.eventid) {
            window.firebase.firestore().doc(`eventsPro/${props.eventid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(client))
            return
        let category = 'Automotive';
        if (client?.category) {
            category = client?.category;
        }
        try {
            const getFusionEvents = window.firebase.functions().httpsCallable('generic-getData');
            getFusionEvents({
                type: "getAllMakeModels",
                params: JSON.stringify({ category: category })
            }).then(_resp => {
                let _data = _resp?.data?.data;
                setMakes(_data.map(r => { return { ...r, models: r.models ? _.orderBy(JSON.parse(r.models), 'name', 'asc') : [] } }))
            });
        } catch { }
    }, [client])

    useEffect(() => {
        async function getLookUps() {
            const responseCountries = await fetch(window.location.origin + '/lookups/countries.json');
            const countriesjson = await responseCountries.json();

            const responseStates = await fetch(window.location.origin + '/lookups/states.json');
            const statesjson = await responseStates.json();

            setCountries(countriesjson ? countriesjson.map(r => { return { label: r.name, value: r.code } }) : []);
            setStates(statesjson ? statesjson.map(r => { return { ...r, label: r.name, value: r.code } }) : []);
        }
        getLookUps();
    }, [])

    useEffect(() => {
        if (_.isEmpty(event))
            return
        let clientPromise = [];
        if (_.isArray(event?.regionIDs) && event?.regionIDs?.length) {
            for (let k = 0; k < Math.ceil(event.regionIDs.length / 10); k++) {
                clientPromise.push(window.firebase.firestore().collection(`clients`).where('region', 'in', CommonHelper.arrPaginate(event.regionIDs, 10, k + 1)).get())
            }
        } else if (event?.clientIDs?.length) {
            for (let k = 0; k < Math.ceil(event.clientIDs.length / 10); k++) {
                clientPromise.push(window.firebase.firestore().collection(`clients`).where('documentID', 'in', CommonHelper.arrPaginate(event.clientIDs, 10, k + 1)).get())
            }
        } else if (event?.level !== 'individual' && event?.levelID?.length) {
            for (let k = 0; k < Math.ceil(event.levelID.length / 10); k++) {
                clientPromise.push(window.firebase.firestore().collection(`clients`).where(event?.level, 'in', CommonHelper.arrPaginate(event.levelID, 10, k + 1)).get())
            }
        }
        if (clientPromise.length) {
            Promise.all(clientPromise)
                .then(alldocs => {
                    let _dealers = [];
                    alldocs.forEach(docs => {
                        docs.forEach(doc => {
                            _dealers.push({
                                ...doc.data(),
                                label: doc.data().name,
                                value: doc.id
                            })
                        })
                    })
                    setDealers(_.orderBy(_dealers.filter(a => event.oemID ? a.settingsID === event.oemID : true), 'label', 'asc'))
                })
        }
    }, [event])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(props.eventid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(props.eventid).collection('settings').doc(props.eventid).get());
        Promise.all(_promise)
            .then(async docs => {
                if (docs[0]?.exists) {
                    const settingsPromise = [];
                    setEvent({
                        ...docs[0].data(),
                        id: docs[0].id
                    })
                    if (docs[0].data()?.timezone) {
                        setTimezone(docs[0].data()?.timezone)
                    }
                    if (docs[0].data().clientID) {
                        settingsPromise.push(window.firebase.firestore().collection(`clients`).doc(docs[0].data().clientID).get());
                        settingsPromise.push(window.firebase.firestore().doc(`clients/${docs[0].data().clientID}/currentSettings/${docs[0].data().clientID}/eventsPro/${docs[0].data().clientID}`).get());
                    }
                    else if (docs[0].data().oemID) {
                        settingsPromise.push(window.firebase.firestore().collection(`clientSettings`).doc(docs[0].data().oemID).get());
                        settingsPromise.push(window.firebase.firestore().doc(`clientSettings/${docs[0].data().oemID}/currentSettings/${docs[0].data().oemID}/eventsPro/${docs[0].data().oemID}`).get());
                    }

                    const settingsSnap = await Promise.all(settingsPromise);

                    if (settingsSnap[0].exists) {
                        setClient(settingsSnap[0].data());
                    }
                    if (settingsSnap[1].exists) {
                        setClientSettings(settingsSnap[1].data());
                        setMakes(settingsSnap[1].data().brands);
                    }
                }
                if (docs[1]?.exists) {
                    setSettings({
                        ...docs[1].data(),
                        guest: _type === 'eoi' ? docs[1].data().guestEOI : docs[1].data().guest,
                        makes: _type === 'eoi' ? docs[1].data().makesEOI : docs[1].data().makes,
                        slots: docs[1]?.data()?.slots ? docs[1].data().slots.map((r, i) => {
                            return {
                                ...r,
                                sortTime: r?.startTime?.seconds ? moment.unix(r.startTime.seconds).tz(timezone).format('HH:mm') : `${i}`
                            }
                        }) : []
                    })
                    if (docs[1].data()?.mandatoryFields?.[_type]) {
                        setMandFields(docs[1].data()?.mandatoryFields?.[_type].split(','))
                    }
                    if (docs[1].data()?.hiddenFields?.[_type]) {
                        setHiddenFields(docs[1].data()?.hiddenFields?.[_type])
                    }
                    const _moi = _.find(docs[1].data()?.attendeesDF, { mappingKey: "model", saleType: 'New' });
                    setMOI(_moi)
                    setMOIMakes(_moi?.make ? [{ label: _moi.make, value: _moi.make }] : [])
                    setMOIModels(_moi?.subList?.length ? _moi.subList.map(r => { return { label: r, value: r } }) : [])
                }
                setLoader(false)
            })

    }, [firestoreDB]);

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setContact({
                ...contact,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setContact({
                    ...contact,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setContact({
                    ...contact,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setContact({
                ...contact,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleReactSelectChange = (e, data, nested) => {
        if (nested) {
            setContact({
                ...contact,
                [nested]: contact[nested] ? {
                    ...contact[nested],
                    [data.name]: e ? e.value : null
                } : {
                    [data.name]: e ? e.value : null
                }

            })
        }
        else if (data.name === 'optinEmail' || data.name === 'optinPhone' || data.name === 'optinSMS' || data.name === 'optinPost') {
            setContact({
                ...contact,
                [data.name]: e && e.value === 'true' ? true : false
            })
        }
        else {
            setContact({
                ...contact,
                [data.name]: e ? e.value : null
            })
        }

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleOnCapChange = (e, nested) => {
        e.preventDefault()
        const { name, value } = e.target;
        if (nested) {
            setContact({
                ...contact,
                [nested]: contact[nested] ? {
                    ...contact[nested],
                    [name]: value ? _.trim(value.toUpperCase()) : null
                } : {
                    [name]: value ? _.trim(value.toUpperCase()) : null
                }

            })
        }
        else {
            setContact({
                ...contact,
                [name]: value ? _.trim(value.toUpperCase()) : null
            })
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckChange = (e, name) => {
        const { checked } = e.target;

        setContact({
            ...contact,
            [name]: checked
        })
    }

    const handleNotesChange = (e) => {
        e.preventDefault()
        const { value } = e.target;
        setNotes(value)
    }

    const handleSubmit = async (_contact) => {

        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};

        const selectedSlot = _.find(settings?.slots, { value: contact.eventSlot })
        for (let [key] of Object.entries(contact)) {
            if (mandFields.filter(a => a !== 'eventSlot').indexOf(key) >= 0) {
                if (_.isEmpty(contact?.[key]?.toString())) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof contact[key] !== "undefined") {
                        if (!validateEmail(contact[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            } else if (_.isObject(contact[key])) {
                for (let [nestedKey] of Object.entries(contact[key])) {
                    if (mandFields.filter(a => a !== 'eventSlot').indexOf(nestedKey) >= 0) {
                        if (_.isEmpty(contact[key][nestedKey])) {
                            formIsValid = false;
                            errors[nestedKey] = errorClass;

                        }
                    }
                }
            }
        }

        if (mandFields.includes('eventSlot') && contact.status !== 'declined' && settings?.slots) {
            if (_.isEmpty(eventDate)) {
                formIsValid = false;
                errors['eventSlot'] = errorClass;
            }
            else if (_.isEmpty(contact.eventSlot)) {
                Swal.fire('Please select slot.', '', 'error')
                formIsValid = false;
            }
            setErrorFields(errors)
            if (selectedSlot) {
                if (selectedSlot?.available - selectedSlot?.booked <= 0) {
                    Swal.fire('Selected slot is full. Please select different slot', '', 'error')
                    formIsValid = false;
                }
            }
        }
        if (!_.isEmpty(contact.phone)) {
            if (contact.phone.length < 10) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        if (mandFields.includes('phoneOremail')) {
            if (!contact['phone'] && !contact['email']) {
                Swal.fire('Please enter Phone or Email.', '', 'error')
                return;
            }
        }

        if (!_.isEmpty(settings?.customerConsent) && _.isEmpty(_contact?.terms)) {
            setshowTerms(true)
            return false;
        }

        let response = await validateAttendee();
        if (response.success === false) {
            if (response.code === contactResponseCode.PhoneAlreadyExists ||
                response.code === contactResponseCode.EmailAlreadyExists ||
                response.code === contactResponseCode.LicenseAlreadyExists) {
                handleExistingContact(response);
            }
            setSubmitLoader(false)
            return false;
        }
        else {
            let _guestInfo = [];
            if (contact.guest === 'yes' && guestInfo.length === 0) {
                _guestInfo = [{ firstName: 'Guest', documentID: window.firebase.firestore().collection('eventsProAttendees').doc().id }];
            }
            saveAttendee(_contact, _guestInfo);
        }

    }

    const validateAttendee = async () => {
        setSubmitLoader(true)
        const { phone, email } = contact;
        const attendeeRef = firestoreDB.firestore().collection('eventsProAttendees');
        const phone8digit = phone ? phone.replace(/\D/g, '').slice(-8) : '';
        let exists = false;
        let existingContact;

        if (email) {
            const checkEmail = attendeeRef.where('eventsProID', '==', props.eventid).where('keywords', 'array-contains', email.toLowerCase()).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        existingContact = doc.data();
                        if (existingContact.email && existingContact.email.toLowerCase() === email.toLowerCase()) exists = true;
                    });
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkEmail]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with email: ${email}`, data: existingContact, code: 'EmailAlreadyExists' }
            }
        }

        if (phone8digit !== '') {
            const checkPhone8digit = attendeeRef.where('eventsProID', '==', props.eventid).where('keywords', 'array-contains', phone8digit)
                .where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        const contct = doc.data();
                        if (contct.phone && contct.phone.replace(/\D/g, '').slice(-8) === phone8digit) {
                            exists = true;
                            existingContact = contct;
                        }
                    });
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkPhone8digit]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with phone: ${phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
            }
        }


        if (phone) {
            const checkPhone = attendeeRef.where('eventsProID', '==', props.eventid).where('keywords', 'array-contains', phone).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        const contct = doc.data();
                        if (contct.phone && contct.phone.replace(/\D/g, '') === phone) {
                            exists = true;
                            existingContact = contct;
                        }
                    });
                    return exists;
                });
            const [checkQuery] = await Promise.all([checkPhone]);
            if (checkQuery) {
                return { success: false, message: `Contact already exists with phone: ${phone}`, data: existingContact, code: 'PhoneAlreadyExists' }
            }
        }

        return { success: true }
    }

    const handleExistingContact = (response) => {

        let _msg = `You're already registered for the event.`;
        let _title = 'Registered';
        Swal.fire(_title, _msg, 'info')
        return;

    }

    const saveAttendee = (_contact, _guestInfo) => {
        setSubmitLoader(true)
        if (_type === 'eoi') {
            contact.status = 'eoi-response'
        }
        let categories = clientSettings?.categories;
        let category = {};
        if (categories?.length > 0) {
            categories.map(rec => { return category = { ...category, [rec.value]: false } });
        }
        else {
            category = {
                invitation: false
            }
        }
        const _reqObject = {
            attendeeID: firestoreDB.firestore().collection('eventsProAttendees').doc().id,
            attendee: {
                ...contact,
                terms: _contact?.terms || null,
                category,
                projectId: projectId,
                clientID: _cid || null,
                source: _source || null,
                isDeleted: false,
                url: window.location.href
            },
            guestInfo: guestInfo?.length > 0 && contact.status !== 'declined' ? guestInfo : _guestInfo,
            eventsProID: _type === 'eoi' ? `${event.id}-EOI` : event.id,
            projectId: projectId,
            category,
            newAttendee: true
        }
        if (!_.isEmpty(notes)) {
            _reqObject.notes = notes
        }
        const genericSaveData = window.firebase.functions().httpsCallable('eventsPro-registerAttendee');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                setSubmitLoader(false)
                setContactStatus(true)

                if (_type === 'eoi')
                    Swal.fire(`A ${client.name || ''} team member will get back to you in the coming days, so please be on the lookout for a follow-up email in the near future.`, '', 'success')
                else
                    Swal.fire(`Thank you for registering for ${event.title}. A confirmation email will be sent to your email address with your registration details. Please check your inbox/spam folder`, '', 'success')
            }
            else {
                setSubmitLoader(false)
                Swal.fire('Something went wrong.', '', 'error')
            }
        });
    }

    const bindAddress = (place) => {

        let state = Object.assign({}, contact);
        state.address = null;
        state.areaCode = null;
        state.state = null;
        state.suburb = null;
        state.country = null;
        state.street = null;
        let street = '';
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            state['address'] = place.formatted_address;

        if (!_.isEmpty(place.address_components)) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];

                    if (addressType === "postal_code")
                        state['areaCode'] = val;

                    if (addressType === "administrative_area_level_1")
                        state['state'] = val;

                    if (addressType === "locality")
                        state['suburb'] = val;

                    if (addressType === "country") {
                        state['country'] = val;
                    }


                    if (addressType === "street_number" || addressType === "route") {
                        street += ` ${val}`;
                    }

                    // if (addressType === "route") {
                    //     var sublocality_level_1 = val;
                    // }
                }
            }
        }

        if (street) {
            state['street'] = street.trim();
        }
        setContact(state);
    };

    const handleTermsClose = (data) => {
        if (!_.isEmpty(data)) {
            let _contact = {
                ...contact,
                terms: data
            }
            setContact(_contact)
            handleSubmit(_contact);
        }
        setshowTerms(false);
    };

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : _.isEmpty(event) || event.isDeleted || event.status === 'closed' || event.status === 'live' ? (
        <>
            <main role="main">
                <div className="container">
                    <div className="eventclosed-wrap-bg">
                        <div className="eventclosed-wrap eventclosed-position-fix">
                            <div className="eventclosed-center-wrap  mt-4">
                                {
                                    event?.imageURL ? (
                                        <div className="eventclosed-card-image"><img src={event.imageURL} /></div>
                                    ) : (<></>)
                                }
                                {
                                    event?.title ? (
                                        <div className="eventclosed-card-title">{event.title}</div>
                                    ) : (<></>)
                                }
                                <div className="eventclosed-landing-wrap ">
                                    <div className="eventclosed-landing-wrap-head">
                                        {
                                            _.isEmpty(event) ? (
                                                <div className="eventclosed-landing-image"><img src={images.eventClosed} alt="" height="150" /></div>
                                            ) : (<></>)
                                        }
                                        <h2>EVENT REGISTRATION CLOSED</h2>
                                        <p>We're sorry, this event has closed and is no longer accepting reservations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    ) : _.isEmpty(_type) ? (
        <main role="main">
            <div className="container">
                <div className="eventclosed-wrap-bg">
                    <div className="eventclosed-wrap eventclosed-position-fix">
                        <div className="eventclosed-center-wrap  mt-4">
                            <div className="eventclosed-landing-wrap ">
                                <div className="eventclosed-landing-wrap-head">
                                    <div className="eventclosed-landing-image"><img src={images.pagenotFound} alt="" height="150" /></div>
                                    <h2>PAGE NOT FOUND</h2>
                                    <p>The page you requested could not be found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    ) : (
        <main role="main">
            <div className="eventslanding-header">
                <div className="container">
                    <div className="eventslanding-logo-wrap"> <span className="eventslanding-logo-img mr-3">
                        {
                            client?.logoURL
                                ?
                                <img src={client.logoURL} height="60" />
                                :
                                <div className='eventslanding-info-card-title'>{event.title}</div>
                        }
                    </span >
                        <div className="eventslanding-logo-title"> </div>
                    </div >
                </div >
            </div >

            <div className="eventslanding-banner">
                {
                    event.imageURL ? (
                        <img src={event.imageURL} />
                    ) : (<></>)
                }

            </div>


            <div className="container">

                <div className="eventslanding-wrap-bg ">
                    <div className={`eventslanding-wrap ${contactStatus ? 'form-disable' : ''}`}>




                        <div className="eventslanding-question-loop">


                            <div className="eventslanding-info-card">
                                <div className="eventslanding-info-card-title">

                                    <h1>{event.title}</h1>
                                    {
                                        _backurl ? (
                                            <div className="change-event-btnwrap"><button onClick={() => {
                                                history.push(`${_backurl}?type=${_type}`);
                                            }} type="button" className="btn btn-primary float-right"><i className="ico icon-switch"></i> Change Event</button></div>
                                        ) : (<></>)
                                    }
                                </div>
                                <div className="eventslanding-sub-info ">
                                    <div className="float-left mt-1">
                                        <ul className="eventslanding-sub-details">

                                            <li> <i className="ico icon-location"></i> {event.location} </li>
                                            <li> <i className="ico icon-date"></i>{CommonHelper.showCombineDate(event.startDate, event.endDate, timezone)}</li>
                                            <li><i className="ico icon-time"></i>{CommonHelper.showCombineTime(event.startDate, event.endDate, timezone, settings)}</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>
                            <div className="eventslanding-description">
                                {
                                    event.description ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: event.description,
                                            }}
                                        ></div>
                                    ) : (<></>)
                                }
                            </div>

                            {
                                _type === 'eoi' ? (
                                    <div className="eventslanding-title-head"><h2>Request your Experience</h2></div>
                                ) : (<div className="eventslanding-title-head"><h2>Register Your Interest</h2></div>)
                            }
                            {
                                _type !== 'eoi' ? (
                                    <div className="events-radio-buttonwrap">
                                        <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'accepted' ? 'radio-bg-accepted' : ''}`}>
                                            <InputRadio
                                                id="accepted"
                                                type="radio"
                                                value="accepted"
                                                name="status"
                                                checked={contact.status === 'accepted' ? true : false}
                                                onChange={(e) => {
                                                    setContact({
                                                        ...contact,
                                                        'status': e.target.value
                                                    })
                                                }}
                                            />
                                            <label htmlFor="accepted">I can attend</label>
                                        </div>
                                        {/* <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'tentative' ? 'radio-bg-tentative' : ''}`}>
                                            <InputRadio
                                                id="tentative"
                                                type="radio"
                                                value="tentative"
                                                name="status"
                                                checked={contact.status === 'tentative' ? true : false}
                                                onChange={(e) => {
                                                    setContact({
                                                        ...contact,
                                                        'status': e.target.value
                                                    })
                                                }}
                                            />
                                            <label htmlFor="tentative">Tentative</label>
                                        </div> */}
                                        <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'declined' ? 'radio-bg-declined' : ''}`}>
                                            <InputRadio
                                                id="declined"
                                                type="radio"
                                                value="declined"
                                                name="status"
                                                checked={contact.status === 'declined' ? true : false}
                                                onChange={(e) => {
                                                    setContact({
                                                        ...contact,
                                                        'status': e.target.value,
                                                        'eventSlot': null
                                                    })
                                                    setEventDate(null)
                                                }}
                                            />
                                            <label htmlFor="declined">I cannot attend</label>
                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                            <div className='eventslanding-fix'>
                                {
                                    _type !== 'eoi' && contact.status !== 'declined' && settings?.slots && !hiddenFields.includes('eventSlot') ? (
                                        <>
                                            <div className="eventslanding-subheader-info"><i className="ico icon-Calendar"></i> Preferred Date and Time</div>
                                            <div className="eventslanding-field-wraper form-style ">
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        {/* <label >Date</label> */}
                                                        <ReactSelect
                                                            options={eventDates}
                                                            name={"eventSlot"}
                                                            placeholder={'select date'}
                                                            onChange={(e) => {
                                                                setEventDate(e ? e.value : null)
                                                                setErrorFields({
                                                                    ...errorFields,
                                                                    ['eventSlot']: ''
                                                                });
                                                            }}
                                                            value={eventDate}
                                                            classNamePrefix={`${errorFields["eventSlot"]} basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                eventDate && settings?.slots.filter(a => a.date === eventDate).length ? (
                                                    <div className="custom-slot-listwrap mt-2">
                                                        <div className="custom-slot-availablity">Available timeslots on <span className="custom-slot-bluelink">{moment(eventDate).format('dddd,Do MMM YYYY')}</span> </div>
                                                        <div className="custom-slot-list">
                                                            {
                                                                _.orderBy(settings?.slots.filter(a => a.date === eventDate), 'sortTime', 'asc').map(rec => {
                                                                    return <div id={rec.value} key={rec.value} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setContact({
                                                                            ...contact,
                                                                            eventSlot: rec.value
                                                                        })
                                                                    }} className={`custom-slot-loopbox ${rec.value === contact.eventSlot ? 'active' : rec.total && rec.booked === rec.available ? 'inactive form-disable' : ''}`}>{moment.unix(rec.startTime.seconds).tz(timezone).format(`${settings?.timeFormat || 'hh:mm A'}`)} - {moment.unix(rec.endTime.seconds).tz(timezone).format(`${settings?.timeFormat || 'hh:mm A'}`)}
                                                                        {
                                                                            rec?.slotName ? (
                                                                                <span className='dynamic-slot-value-span ml-1'>{rec.slotName}</span>
                                                                            ) : (<></>)
                                                                        }
                                                                        {
                                                                            rec.total && (_.isEmpty(rec.booked) || rec.available !== rec.booked) ? (
                                                                                <div className="custom-slot-counterwrap"><div className="custom-slot-counter">{rec.booked ? rec.booked : 0} / {rec.available}</div></div>
                                                                            ) : (<></>)
                                                                        }
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    moi && !hiddenFields.includes('moi') ? (
                                        <>
                                            <div className="eventslanding-subheader-info"><i className="ico icon-Stock fix-stock-event"></i> Model Of Interest</div>
                                            <div className="eventslanding-field-wraper form-style ">
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <label >Make</label>
                                                        <ReactSelect
                                                            options={moiMakes}
                                                            name={"make"}
                                                            placeholder={'select make'}
                                                            onChange={(e, data) => handleReactSelectChange(e, data, 'dynamicFields')}
                                                            value={contact?.dynamicFields?.make || moiMakes[0]?.value}
                                                            classNamePrefix={`basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-8">
                                                        <label >Model</label>
                                                        <ReactMultiSelect
                                                            options={moiModels}
                                                            name={moi.value}
                                                            placeholder={'select model'}
                                                            onChange={(selectedOptions) => {
                                                                const value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setContact({
                                                                    ...contact,
                                                                    ['dynamicFields']: contact['dynamicFields'] ? {
                                                                        ...contact['dynamicFields'],
                                                                        [moi.value]: value
                                                                    } : {
                                                                        [moi.value]: value
                                                                    }

                                                                })
                                                            }}
                                                            value={contact?.dynamicFields?.[moi.value] || []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (<></>)
                                }
                                <div className="eventslanding-subheader-info"><i className="ico icon-Contacts"></i> Guest Information</div>


                                <div className="eventslanding-field-wraper form-style ">

                                    <div className="form-row">
                                        {
                                            !hiddenFields.includes('title') ? (
                                                <div className="form-group col-md-1">
                                                    <label >Title</label>
                                                    <ReactSelect
                                                        options={defaultTitles.map((doc) => {
                                                            return {
                                                                value: doc.value,
                                                                label: doc.name
                                                            }
                                                        })}
                                                        name={"title"}
                                                        placeholder={'select title'}
                                                        onChange={handleReactSelectChange}
                                                        value={contact.title}
                                                        classNamePrefix={`${errorFields["title"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('firstName') ? (
                                                <div className="form-group col-md-3">
                                                    <label >First Name</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'first name'}
                                                        className={`form-control ${errorFields["firstName"]}`}
                                                        name="firstName"
                                                        onChange={handleOnChange}
                                                        value={contact.firstName}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('middleName') ? (
                                                <div className="form-group col-md-4">
                                                    <label >Middle Name</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'middle name'}
                                                        className={`form-control ${errorFields["middleName"]}`}
                                                        name="middleName"
                                                        onChange={handleOnChange}
                                                        value={contact.middleName}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('lastName') ? (
                                                <div className="form-group col-md-4">
                                                    <label >Last Name</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'last name'}
                                                        className={`form-control ${errorFields["lastName"]}`}
                                                        name="lastName"
                                                        onChange={handleOnChange}
                                                        value={contact.lastName}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('phone') ? (
                                                <div className="form-group col-md-4">
                                                    <label >Phone</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'please enter 10 digits phone number'}
                                                        className={`form-control ${errorFields["phone"]}`}
                                                        name="phone"
                                                        onChange={handleNumberChange}
                                                        value={contact.phone}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('email') ? (
                                                <div className="form-group col-md-8">
                                                    <label >Email</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'email'}
                                                        className={`form-control ${errorFields["email"]}`}
                                                        name="email"
                                                        onChange={handleOnChange}
                                                        value={contact.email}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('address') ? (
                                                <div className="form-group col-md-4">
                                                    <label > Home address</label>
                                                    <AutoComplete
                                                        className={`form-control ${errorFields["address"]}`}
                                                        bindAddress={bindAddress}
                                                        types={['address']}
                                                        value={contact.address || ''}
                                                        placeholder={'search address'}
                                                        onChange={handleOnChange}
                                                        componentRestrictions={{ country: [] }}
                                                        name="address"
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('areaCode') ? (
                                                <div className="form-group col-md-2">
                                                    <label >Zip/postal code</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'zip code'}
                                                        className={`form-control ${errorFields["areaCode"]}`}
                                                        name="areaCode"
                                                        onChange={handleOnChange}
                                                        value={contact.areaCode}
                                                    />
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('country') ? (
                                                <div className="form-group col-md-3">
                                                    <label >Country</label>
                                                    <ReactSelect
                                                        options={countries}
                                                        name={"country"}
                                                        placeholder={'select country'}
                                                        onChange={handleReactSelectChange}
                                                        value={contact.country}
                                                        classNamePrefix={`${errorFields["country"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('state') ? (
                                                <div className="form-group col-md-3">
                                                    <label >State</label>
                                                    <ReactSelect
                                                        options={states.filter(a => contact.country ? a.country_code === contact.country : false)}
                                                        name={"state"}
                                                        placeholder={'select state'}
                                                        onChange={handleReactSelectChange}
                                                        value={contact.state}
                                                        classNamePrefix={`${errorFields["state"]} basic-select`}
                                                    >
                                                    </ReactSelect>

                                                </div>
                                            ) : (<></>)
                                        }

                                    </div>
                                </div>

                                {
                                    _.difference(Object.keys(defaultContact.ownedVehicle), hiddenFields).length === 0 ? (<></>) : (
                                        <>
                                            <div className="eventslanding-subheader-info"><i className="ico icon-Stock fix-stock-event"></i> Owned Vehicle</div>
                                            <div className="eventslanding-field-wraper form-style ">
                                                <div className="form-row">
                                                    {
                                                        !hiddenFields.includes('make') ? (
                                                            <div className="form-group col-md-4">
                                                                <label >Make</label>
                                                                <ReactSelect
                                                                    options={makes ? makes.filter(a => settings?.makes ? settings?.makes.includes(a.value) : true).map((doc) => {
                                                                        return {
                                                                            value: doc.value,
                                                                            label: doc.name
                                                                        }
                                                                    }) : []}
                                                                    name={"make"}
                                                                    placeholder={'select make'}
                                                                    onChange={(e, data) => handleReactSelectChange(e, data, 'ownedVehicle')}
                                                                    value={contact?.ownedVehicle?.make}
                                                                    classNamePrefix={`${errorFields["make"]} basic-select`}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        !hiddenFields.includes('model') ? (
                                                            <div className="form-group col-md-4">
                                                                <label >Model</label>
                                                                <ReactSelect
                                                                    options={_.find(makes, { value: contact?.ownedVehicle?.make })?.models?.map(doc => {
                                                                        return {
                                                                            value: doc.value,
                                                                            label: doc.name
                                                                        }
                                                                    }) || []}
                                                                    name={"model"}
                                                                    placeholder={'select model'}
                                                                    onChange={(e, data) => handleReactSelectChange(e, data, 'ownedVehicle')}
                                                                    value={contact?.ownedVehicle?.model}
                                                                    classNamePrefix={`${errorFields["model"]} basic-select`}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        !hiddenFields.includes('year') ? (
                                                            <div className="form-group col-md-4">
                                                                <label >Year</label>
                                                                <ReactSelect
                                                                    options={years}
                                                                    name={"year"}
                                                                    placeholder={'select year'}
                                                                    onChange={(e, data) => handleReactSelectChange(e, data, 'ownedVehicle')}
                                                                    value={contact?.ownedVehicle?.year}
                                                                    classNamePrefix={`${errorFields["year"]} basic-select`}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        !hiddenFields.includes('vinNo') ? (
                                                            <div className="form-group col-md-4">
                                                                <label>Chassis/VIN</label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'vin no'}
                                                                    className={`form-control ${errorFields["vinNo"]}`}
                                                                    name="vinNo"
                                                                    onChange={(e) => handleOnCapChange(e, 'ownedVehicle')}
                                                                    value={contact?.ownedVehicle?.vinNo}
                                                                />
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                                <div className="eventslanding-subheader-info"><i className="ico icon-activity fix-preference-event"></i>   Marketing Preferences</div>
                                {
                                    !_.isEmpty(settings?.marketingPreferences) && (
                                        <div
                                            className="mt-2"
                                            dangerouslySetInnerHTML={{ __html: settings.marketingPreferences }} />
                                    )
                                }
                                <div className="eventslanding-field-wraper form-style ">
                                    <div className='form-row mt-2'>
                                        {
                                            !hiddenFields.includes('optinEmail') ? (
                                                <div className="form-group col-md-2">
                                                    {/* <ReactSelect
                                                    options={opts}
                                                    name={"optinEmail"}
                                                    placeholder={'select opt in email'}
                                                    onChange={handleReactSelectChange}
                                                    value={contact.optinEmail ? 'true' : 'false'}
                                                    classNamePrefix={`${errorFields["optinEmail"]} basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect> */}
                                                    <div className={`checkbox icheck-success float-left mr-3 `}>
                                                        <input
                                                            type="checkbox"
                                                            id="optinEmail"
                                                            name="optinEmail"
                                                            className="uncheck-activity"
                                                            checked={contact.optinEmail}
                                                            onChange={(e) => {
                                                                handleCheckChange(e, 'optinEmail')
                                                            }} />
                                                        <label htmlFor="optinEmail"><Translate text={'optinEmail'} /></label>
                                                    </div>

                                                </div>
                                            ) : (<></>)
                                        }

                                        {
                                            !hiddenFields.includes('optinSMS') ? (
                                                <div className="form-group col-md-2">
                                                    <div className={`checkbox icheck-success float-left mr-3 `}>
                                                        <input
                                                            type="checkbox"
                                                            id="optinSMS"
                                                            name="optinSMS"
                                                            className="uncheck-activity"
                                                            checked={contact.optinSMS}
                                                            onChange={(e) => {
                                                                handleCheckChange(e, 'optinSMS')
                                                            }} />
                                                        <label htmlFor="optinSMS"><Translate text={'optinSMS'} /></label>
                                                    </div>
                                                </div>
                                            ) : (<></>)
                                        }

                                        {
                                            !hiddenFields.includes('optinPhone') ? (
                                                <div className="form-group col-md-2">
                                                    <div className={`checkbox icheck-success float-left mr-3 `}>
                                                        <input
                                                            type="checkbox"
                                                            id="optinPhone"
                                                            name="optinPhone"
                                                            className="uncheck-activity"
                                                            checked={contact.optinPhone}
                                                            onChange={(e) => {
                                                                handleCheckChange(e, 'optinPhone')
                                                            }} />
                                                        <label htmlFor="optinPhone"><Translate text={'optinPhone'} /></label>
                                                    </div>
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            !hiddenFields.includes('optinPost') ? (
                                                <div className="form-group col-md-2">
                                                    <div className={`checkbox icheck-success float-left mr-3 `}>
                                                        <input
                                                            type="checkbox"
                                                            id="optinPost"
                                                            name="optinPost"
                                                            className="uncheck-activity"
                                                            checked={contact.optinPost}
                                                            onChange={(e) => {
                                                                handleCheckChange(e, 'optinPost')
                                                            }} />
                                                        <label htmlFor="optinPost"><Translate text={'optinPost'} /></label>
                                                    </div>
                                                </div>
                                            ) : (<></>)
                                        }
                                    </div>
                                    <div className="form-row">
                                        {
                                            !hiddenFields.includes('preferredRetailer') ? (
                                                <div className="form-group col-md-4">
                                                    <label >Preferred Retailer</label>
                                                    <ReactSelect
                                                        options={dealers.map(rec => {
                                                            return {
                                                                ...rec,
                                                                value: rec.label
                                                            }
                                                        })}
                                                        name={"preferredRetailer"}
                                                        placeholder={'select preferred retailer'}
                                                        onChange={handleReactSelectChange}
                                                        value={contact.preferredRetailer}
                                                        classNamePrefix={`${errorFields["preferredRetailer"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : (<></>)
                                        }

                                    </div>
                                </div>

                                {
                                    contact.status === 'declined' || _.isEmpty(settings?.guest?.toString()) || settings?.guest === 0 ? (<></>) : (
                                        <>
                                            {
                                                settings?.guest === 1 ? (
                                                    <div className='form-row mt-4'>
                                                        <div className='form-group col-md-2'>
                                                            <label className='mt-2'>Are you briging a guest ?</label>
                                                        </div>
                                                        <div className='form-group col-md-1'>
                                                            <div className={`checkbox icheck-success float-left mr-3 `}>
                                                                <input
                                                                    type="radio"
                                                                    id="yes"
                                                                    name="yes"
                                                                    className="uncheck-activity"
                                                                    checked={contact.guest === 'yes'}
                                                                    onChange={(e) => {
                                                                        setContact({
                                                                            ...contact,
                                                                            guest: e.target.checked ? 'yes' : null
                                                                        })
                                                                    }} />
                                                                <label htmlFor="yes"><Translate text={'Yes'} /></label>
                                                            </div>
                                                        </div>
                                                        <div className='form-group col-md-1'>
                                                            <div className={`checkbox icheck-success float-left mr-3 `}>
                                                                <input
                                                                    type="radio"
                                                                    id="no"
                                                                    name="no"
                                                                    className="uncheck-activity"
                                                                    checked={contact.guest === 'no'}
                                                                    onChange={(e) => {
                                                                        setContact({
                                                                            ...contact,
                                                                            guest: e.target.checked ? 'no' : null
                                                                        })
                                                                        setGuestInfo([])
                                                                    }} />
                                                                <label htmlFor="no"><Translate text={'No'} /></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                contact.guest === 'no' ? (<></>) : (
                                                    <div className="eventslanding-subheader-info"><i className="ico  icon-events-guest-fix"></i> Guest Details <span className="guest-max-limit"> (Maximum {settings.guest} Guest )</span>
                                                        {
                                                            guestInfo.length === settings.guest ? (<></>) : (
                                                                <button className="btn btn-sm btn-default float-right" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setGuestModel({
                                                                        show: true,
                                                                        title: 'Add Guest'
                                                                    })
                                                                }}><i className="ico icon-add mr-1"></i> Add </button>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                guestInfo.map((info, index) => {
                                                    return <div key={index} className="events-guest-listwrap">
                                                        <div className="events-guest-list">
                                                            <div className="events-guest-info-details">
                                                                <button className="btn btn-sm btn-default float-right ml-2" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setGuestModel({
                                                                        show: true,
                                                                        title: 'Edit Guest',
                                                                        guest: info
                                                                    })
                                                                }}><i className="ico icon-edit"></i> </button>
                                                                <button className="btn btn-sm btn-default float-right ml-2" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    Swal.fire({
                                                                        title: 'Are you sure?',
                                                                        text: 'Do you want to delete?',
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonText: 'Yes',
                                                                        cancelButtonText: 'No',
                                                                        allowOutsideClick: false,
                                                                        allowEscapeKey: false
                                                                    }).then((result) => {
                                                                        if (result.value) {
                                                                            setGuestInfo(guestInfo.filter(a => a.documentID !== info.documentID))
                                                                        }
                                                                    })
                                                                }}><i className="ico icon-delete"></i> </button>
                                                                <ul>
                                                                    <li> <strong>Name</strong> : {CommonHelper.displayContactName([], info)}</li>
                                                                    {
                                                                        info.email ? (
                                                                            <li> <strong>Email</strong> : {info.email}</li>
                                                                        ) : (<></>)
                                                                    }
                                                                    {
                                                                        info.phone ? (
                                                                            <li> <strong>Email</strong> : {info.phone}</li>
                                                                        ) : (<></>)
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </>
                                    )
                                }
                                {
                                    !hiddenFields.includes('comments') ? (<div className="form-group col-md-12 pr-0 pl-0">
                                        {
                                            !_.isEmpty(settings?.commentsTitle) ? (
                                                <label>{settings?.commentsTitle}</label>
                                            ) : contact.status === 'declined' ? (
                                                <label > Comments</label>
                                            ) : (
                                                <label > Please provide any additional special accommodations</label>
                                            )
                                        }
                                        <InputTextArea
                                            className={`form-control notes`}
                                            name="notes"
                                            onChange={handleNotesChange}
                                            value={notes}
                                            maxLength="280"
                                            rows="3">
                                        </InputTextArea>
                                    </div>) : (<></>)
                                }
                                {
                                    !_.isEmpty(settings?.disclaimer) && (
                                        <div
                                            className="mt-2"
                                            dangerouslySetInnerHTML={{ __html: settings.disclaimer }} />
                                    )
                                }
                            </div>
                            {
                                !contactStatus ? (
                                    <div className="eventslanding-footer">

                                        <button type="button"
                                            className={`btn btn-primary float-right ${submitLoader ? 'btn-disable' : ''}`}
                                            onClick={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            {
                                                submitLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            {
                                                contact.status === 'declined' ? 'Submit' : _type === 'eoi' ? 'Submit your Request' : 'Register'
                                            }

                                        </button>
                                    </div>
                                ) : _type === 'eoi' ? (
                                    <div className="alert alert-success mt-2" role="alert">
                                        {`A ${client.name || ''} team member will get back to you in the coming days, so please be on the lookout for a follow-up email in the near future.`}
                                    </div>
                                ) : (
                                    <div className="alert alert-success mt-2" role="alert">
                                        {`Thank you for registering for ${event.title}. A confirmation email will be sent to your email address with your registration details. Please check your inbox/spam folder`}
                                    </div>
                                )
                            }


                            <div className="eventslanding-footer-bottom">
                                <div className="eventslanding-footer-bottom-sub">
                                    <div className="eventslanding-cpy-wrap"> <img src={images.safeTag} alt="" width="120" /></div>
                                    {/* <div className="eventslanding-cpy-wrap"> Contact us if you are interested in joining one of our events or would like more information at {client?.email}, or phone {client?.phone}</div> */}
                                    <div className="clear"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <PopUpModal show={guestModel.show}>
                <AddGuest
                    {...guestModel}
                    handleClose={(val, isNew) => {
                        if (val) {
                            setGuestInfo(isNew ? [
                                ...guestInfo,
                                val
                            ] : guestInfo.map(rec => rec.documentID === val.documentID ? {
                                ...val
                            } : { ...rec }))
                        }
                        setGuestModel({ show: false })
                    }}
                />
            </PopUpModal>
            <PopUpModal show={showTerms}>
                <Terms
                    show={showTerms}
                    clsActive='overlay-modal active'
                    imagePath='contactDetails'
                    title={'customerConsent'}
                    isVerbalTerms={false}
                    terms={contact.terms}
                    //termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'customerConsent', language)}
                    termsandCondition={settings.customerConsent}
                    addedBy={'Customer'}
                    isDisable={false}
                    handleClose={handleTermsClose}
                    dealersettings={{
                        'client': {
                            ...client,
                            projectId: projectId
                        }
                    }}

                ></Terms>
            </PopUpModal>

        </main >
    );
};

export default AttendeeConfirmationEOI;
