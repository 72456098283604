import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from './pipelineCounts'
import { initialLayoutArr } from './viewModel';
import DragDropLayout from './dragDropLayout';
import { firestoreDB } from '../../services/helper';
import CommonHelper from '../../services/common';
import EventsList from './eventsList';
import { PopUpModal } from '../../components';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { levelOptions } from '../eventsProTemplates/viewModel';

const Dashboard = (props) => {
    const [loader, setLoader] = useState(true)
    const [monthData, setMonthData] = useState([])
    const [calendarEventData, setCalendarEventData] = useState([])
    const [modelShow, setModelShow] = useState({ show: false })
    const [popHeader, setPopHeader] = useState()
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
        date: moment().format('YYYY-MM-DD')
    })
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState([])
    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/eventsdashboardSettings/${localStorage.uid}`);
    // useEffect(() => {
    //     if (ownerDashboard)
    //         setUser({ value: props.dealersettings.id })
    // }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
            window.unsubrefMonthEventsData && window.unsubrefMonthEventsData.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (!loader)
            return

        const collectionPromise = [];
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index)
        _userLevel.forEach(level => {
            let _levelIDs = CommonHelper.getAllEventsProByLevel(props.dealersettings, level.value, true, true);
            for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {
                let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
                    .where('isDeleted', '==', false)
                    .where(`${level.value === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                    .where('startDate', '>=', dateRange.startDate)
                    .where('startDate', '<=', dateRange.endDate)

                collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
            }
        });

        // let allEventProDealers = CommonHelper.getAllEventsProDealers(props.dealersettings, true);

        // for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {
        //     let refEventsData =  firestoreDB(props.dealersettings).firestore().collection('eventsPro')
        //         .where('isDeleted', '==', false)
        //         .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
        //         .where('startDate', '>=', dateRange.startDate)
        //         .where('startDate', '<=', dateRange.endDate);

        //     collectionPromise.push(refEventsData ? collection(refEventsData) : of([]));
        // }
        if (collectionPromise.length > 0) {
            window.unsubrefMonthEventsData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    let _data = [];
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach((rec) => {
                        const eventData = rec.data();
                        if (moment.unix(eventData.startDate.seconds).isSame(moment.unix(eventData.endDate.seconds).tz(eventData.timezone || moment.tz.guess()), 'day')) {
                            _data.push({
                                ...eventData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'eventDate': moment.unix(eventData.startDate.seconds).tz(eventData.timezone || moment.tz.guess()).format('YYYY-MM-DD'),
                                'eventMonth': moment.unix(eventData.startDate.seconds).tz(eventData.timezone || moment.tz.guess()).format('YYYY-MM')
                            })
                        }
                        for (var m = moment.unix(eventData.startDate.seconds).tz(eventData.timezone || moment.tz.guess()).startOf('day'); m.isSameOrBefore(moment.unix(eventData.endDate.seconds).tz(eventData.timezone || moment.tz.guess()).startOf('day')); m.add(1, 'days')) {
                            _data.push({
                                ...eventData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'eventDate': m.tz(eventData.timezone || moment.tz.guess()).format('YYYY-MM-DD'),
                                'eventMonth': moment.unix(eventData.startDate.seconds).tz(eventData.timezone || moment.tz.guess()).format('YYYY-MM')
                            })
                        }
                    })
                    setMonthData(_data.filter(a => a.pipeline))
                    if (dateRange.date === moment().format('YYYY-MM-DD')) {
                        setCalendarEventData(_.uniqBy(_data.filter(a => moment().format('YYYY-MM') === a.eventMonth && a.pipeline), 'documentID'))
                    }
                    setLoader(false);
                });
        }
        else {
            setLoader(false);
        }
        // window.unsubrefMonthEventsData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
        //     .where('isDeleted', '==', false)
        //     .where('clientIDs', 'array-contains-any', CommonHelper.getEventsProDealers(props.dealersettings, true))
        //     .where('startDate', '>=', dateRange.startDate)
        //     .where('startDate', '<=', dateRange.endDate)
        //     .onSnapshot(querySnapshot => {
        //         let _data = [];
        //         querySnapshot.forEach((rec) => {
        //             const eventData = rec.data();
        //             if (moment.unix(eventData.startDate.seconds).isSame(moment.unix(eventData.endDate.seconds), 'day')) {
        //                 _data.push({
        //                     ...eventData,
        //                     'documentID': rec.id,
        //                     'id': rec.id,
        //                     'eventDate': moment.unix(eventData.startDate.seconds).format('YYYY-MM-DD'),
        //                     'eventMonth': moment.unix(eventData.startDate.seconds).format('YYYY-MM')
        //                 })
        //             }
        //             for (var m = moment.unix(eventData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(eventData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
        //                 _data.push({
        //                     ...eventData,
        //                     'documentID': rec.id,
        //                     'id': rec.id,
        //                     'eventDate': m.format('YYYY-MM-DD'),
        //                     'eventMonth': moment.unix(eventData.startDate.seconds).format('YYYY-MM')
        //                 })
        //             }
        //         })
        //         setMonthData(_data.filter(a => a.pipeline))
        //         if (dateRange.date === moment().format('YYYY-MM-DD')) {
        //             setCalendarEventData(_.uniqBy(_data.filter(a => moment().format('YYYY-MM') === a.eventMonth && a.pipeline), 'documentID'))
        //         }
        //         setLoader(false);
        //     })
    }, [loader])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (!_.isEmpty(data && data.eventsProDashboardLayout)) {
                setLayout(data.eventsProDashboardLayout)
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setModelShow({ show: false })
    }

    const handleShowEventsList = (events, title) => {
        setModelShow({
            show: true,
            title,
            events
        })
    }

    const onLayoutChange = async (_layout) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.map((rec, index) => {
            return {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: 11,
                minW: rec.i === 'RevenueCounts' ? 8 : 4,
                h: (rec.h === 1 || rec.h === undefined) ? 11 : rec.h,
                w: (rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({ eventsProDashboardLayout: _dLayout }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        handleShowEventsList={handleShowEventsList}
                        calendarEventData={calendarEventData}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {layoutLoader ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border loader-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : <>
                            {<DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                dateRange={dateRange}
                                loader={loader}
                                initialLayout={layout}
                                setDateRange={(_dateRange) => {
                                    setDateRange(_dateRange)
                                }}
                                setLoader={(val) => {
                                    setLoader(val);
                                }}
                                monthData={monthData}
                                calendarEventData={calendarEventData}
                            />}
                        </>
                    }

                </div>

            </div>
            <PopUpModal show={modelShow.show}>
                <EventsList
                    {...props}
                    {...modelShow}
                    handleClose={handleModelClose}
                    history={props.history}
                    isFromDashboard={true}
                ></EventsList>
            </PopUpModal>

        </div>
    )
}

export default Dashboard