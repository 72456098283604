import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';

/** COMPONENTS */
import Translate from '../../../../constants/translate';

import CommonHelper from '../../../../services/common';
import { pipelineCountVM } from '../../../dashboard/viewModel'

const SalesProgressBMW = (props) => {
    const [loader, setLoader] = useState(true)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [tempData, setTempData] = useState({ enquiries: [] })
    const [countsCalculated, setCountsCalculated] = useState(false)
    const [mtdTestdrives, setTestdrives] = useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');

    let momentMonths = moment.monthsShort()
    // let momentMonths = [moment().format('MMM')]
    let months = []
    momentMonths.forEach((monthName, index) => {
        var monthDate = moment(monthName, 'MMM')
        if (moment().month() + 1 >= monthDate.month() + 1) {
            var objectToAdd = {}
            var startDate = window.firebase.firestore.Timestamp.fromDate(monthDate.startOf('month').toDate())
            var endDate = window.firebase.firestore.Timestamp.fromDate(monthDate.endOf('month').toDate())
            
            objectToAdd.title = monthName;
            objectToAdd.number = index + 1;
            objectToAdd.startDate = startDate;
            objectToAdd.endDate = endDate;
            months.push(objectToAdd)
        }
    })

    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    })

    let weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week')._d)
    let weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week')._d)
    let monthStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d)
    let monthEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    let yearStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('year')._d)
    let yearEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('year')._d)

    const [counts, setCounts] = useState(pipelineCountVM)
    const [miniCounts, setMiniCounts] = useState(pipelineCountVM)
    const [usedCounts, setUsedCounts] = useState(pipelineCountVM)

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {

        if (_.isEmpty(firestoreDB))
            return

        const getData = async () => {
            if (!refreshData)
                return

            setLoader(true)

            let refOpenEnquiryPromises = []
            let refWonEnquiryPromises = []
            let refLostEnquiryPromises = []
            let refDeliveredEnquiryPromises = []
            const testDrivePromises = []
            const actPromises = []
            const walkinPromises = []



            let refOpenEnquiryData = []
            let refWonEnquiryData = []
            let refLostEnquiryData = []
            let refDeliveredEnquiryData = []
            const allTestDrives = []
            const allAct = []
            const allWalkin = []


            let allEnqs = []

            months.forEach((monthData) => {
                refOpenEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('addedDate', '>=', monthData.startDate)
                    .where('addedDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                refWonEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'won')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('wonDate', '>=', monthData.startDate)
                    .where('wonDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                refLostEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'lost')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('lostDate', '>=', monthData.startDate)
                    .where('lostDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                refDeliveredEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'delivered')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('deliveredDate', '>=', monthData.startDate)
                    .where('deliveredDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())
                
                testDrivePromises.push(firestoreDB.firestore().collection('testdrives')
                    .where('clientID', '==', props.clientid)
                    .where('startDate', '>=', monthData.startDate)
                    .where('startDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .orderBy('startDate', 'desc')
                    .get())

                actPromises.push(firestoreDB.firestore().collection(`activities`)
                    .where('clientID', '==', props.clientid)
                    .where('type', '==', 'activity')
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', true)
                    .where('modifiedDate', '>=', monthData.startDate)
                    .where('modifiedDate', '<=', monthData.endDate)
                    .get())
                
                walkinPromises.push(firestoreDB.firestore().collectionGroup(`walkins`)
                    .where('clientID', '==', props.clientid)
                    .where('walkinDate', '>=', monthData.startDate)
                    .where('walkinDate', '<=', monthData.endDate)
                    .get())

            })

            const snapshots1 = await Promise.all(refOpenEnquiryPromises)
            snapshots1.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refOpenEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots2 = await Promise.all(refWonEnquiryPromises)
            snapshots2.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refWonEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots3 = await Promise.all(refLostEnquiryPromises)
            snapshots3.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refLostEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots4 = await Promise.all(refDeliveredEnquiryPromises)
            snapshots4.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refDeliveredEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots5 = await Promise.all(testDrivePromises)
            snapshots5.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allTestDrives.push(doc.data())
                    });
                }
            })

            const snapshots6 = await Promise.all(actPromises)
            snapshots6.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allAct.push(doc.data())
                    });
                }
            })

            const snapshots7 = await Promise.all(walkinPromises)
            snapshots7.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allWalkin.push(doc.data())
                    });
                }
            })

            let refClientData = await window.firebase.firestore().collection('clients').doc(props.clientid).get()
            refClientData = refClientData.data()

            allEnqs.push(refOpenEnquiryData)
            allEnqs.push(refWonEnquiryData)
            allEnqs.push(refLostEnquiryData)
            allEnqs.push(refDeliveredEnquiryData)

            var _enquiries = [];
            for (let i = 0; i < 4; i++) {
                allEnqs[i].forEach(el => {
                    if (!_enquiries.some(e => e.documentID === el.documentID)) {
                        _enquiries.push({
                            ...el,
                            walkins: allWalkin.filter(a => a.enquiryID === el.documentID)
                        })
                    }
                });
            }

            // allTestDrives.forEach(el => {//testdrive array
            //     if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
            //         _enquiries.push({
            //             ...el.enquiry,
            //             'documentID': el.enquiryID,
            //             testdrives: [el],
            //         })
            //     }
            // });
            setTestdrives(allTestDrives)
            setTempData({
                enquiries: _enquiries,
                testdrives: allTestDrives,
                activities: allAct
            })
            setClient(refClientData)
            if (_enquiries.length === 0) {
                setLoader(false)
                setRefreshClientData(true)
                setRefreshData(false)
            }
        }
        getData()
    }, [firestoreDB])


    useEffect(() => {
        async function getClientSettings(clientID) {
            window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get().then(doc => {
                let settingsDoc = doc.data();
                setClientSettings(settingsDoc);
            });
        }

        if (!_.isEmpty(client) && !_.isEmpty(client.documentID)) {
            getClientSettings(client.documentID)
        }
    }, [client, pipelineEnquiries])

    useEffect(() => {
        async function fetchAdditionalData(enquiries, testdrives, activities) {
            const testDrivesPromises = []
            let settings
            // fetch client settings first
            const doc = await window.firebase.firestore().doc(`clients/${client.documentID}/currentSettings/${client.documentID}`).get()
            // console.log('currentSettings', doc.data())
            let settingsDoc = doc.data();
            settings = settingsDoc

            // checking for those test drives whose enquiry is not fetched yet 
            testdrives.filter((testDrive ) => testDrive.enquiryID).forEach((testDrive ) => {
                if (!enquiries.some((enq) => enq.documentID === testDrive.enquiryID)) {
                    testDrivesPromises.push(firestoreDB.firestore().doc(`enquiries/${testDrive.enquiryID}`).get())
                }
            })
            const snapshots = await Promise.all(testDrivesPromises)
            let _enquiries = []
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && dataDoc.pipeline !== 'LeadsBucket') {
                    _enquiries.push(dataDoc)
                }
            })

            enquiries =  _.uniqBy([...enquiries, ..._enquiries], 'documentID')

            let enqWithTestDrives = enquiries.map(enq => {
                return {
                    ...enq,
                    testdrives: testdrives.filter(w => w.enquiryID === enq.documentID),
                    activities: activities.filter(w => w.enquiryID === enq.documentID),
                }
            })

            let eligibleEnq = enqWithTestDrives.filter(enq => !_.isEmpty(enq.requirement))
            setEnquiries(eligibleEnq)
            setClientSettings(settings)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
        }
        if (tempData.enquiries.length > 0)
            fetchAdditionalData(tempData.enquiries, tempData.testdrives, tempData.activities);
        else
            setEnquiries(tempData.enquiries)
        setLoader(false)
        setRefreshClientData(true)
        setRefreshData(false)
    }, [tempData])



    useEffect(() => {
        if (!_.isArray(pipelineEnquiries) || _.isEmpty(pipelineEnquiries))
            return


        let eligibleEnq = pipelineEnquiries
        //.filter(enq => !_.isEmpty(enq.requirement) && enq.enquiryType)
        let bmwEnq = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make.toLowerCase().includes('bmw')) {
                returnVal = true
            }
            return returnVal
        })
        let miniEnq = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make.toLowerCase().includes('mini')) {
                returnVal = true
            }
            return returnVal
        })
        let usedEnq = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
            }
            if (salesType.toLowerCase().includes('preowned')) {
                returnVal = true
            } else {
                returnVal = false
            }
            return returnVal
        })

        let bmwCounts = {
            yearAllEnq: bmwEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            weekAllEnq: bmwEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthAllEnq: bmwEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        bmwCounts = {
            ...bmwCounts,
            yearWalkin: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            weekWalkin: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            monthWalkin: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            yearPhoneIn: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            weekPhoneIn: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            monthPhoneIn: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            yearHeadOffice: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            weekHeadOffice: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            monthHeadOffice: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            yearAutogate: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            weekAutogate: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            monthAutogate: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            yearInternet: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            weekInternet: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            monthInternet: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            yearOutbound: bmwCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            weekOutbound: bmwCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            monthOutbound: bmwCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            yearOther: bmwCounts.yearAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            weekOther: bmwCounts.weekAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            monthOther: bmwCounts.monthAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            yearTestdriveCount: bmwEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= yearStart.seconds
                    && t.startDate.seconds <= yearEnd.seconds)
                ).length,
            weekTestdriveCount: bmwEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= weekStart.seconds
                    && t.startDate.seconds <= weekEnd.seconds)
                ).length,
            monthTestdriveCount: bmwEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= monthStart.seconds
                    && t.startDate.seconds <= monthEnd.seconds)
                ).length,
            yearSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= yearStart.seconds
                && a.wonDate.seconds <= yearEnd.seconds),
            weekSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= weekStart.seconds
                && a.wonDate.seconds <= weekEnd.seconds),
            monthSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds),
            yearFaceToFace: bmwCounts.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            weekFaceToFace: bmwCounts.weekAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace: bmwCounts.monthAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(bmwCounts.yearAllEnq),
            weekAllFollowups: calculateAllFollowups(bmwCounts.weekAllEnq),
            monthAllFollowups: calculateAllFollowups(bmwCounts.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(bmwCounts.yearAllEnq),
            weekAllFollowupLeads: calculateAllFollowupLeads(bmwCounts.weekAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(bmwCounts.monthAllEnq),
        }

        let allMiniCounts = {
            yearAllEnq: miniEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            weekAllEnq: miniEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthAllEnq: miniEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        allMiniCounts = {
            ...allMiniCounts,
            yearWalkin: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            weekWalkin: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            monthWalkin: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            yearPhoneIn: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            weekPhoneIn: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            monthPhoneIn: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            yearHeadOffice: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            weekHeadOffice: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            monthHeadOffice: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            yearAutogate: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            weekAutogate: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            monthAutogate: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            yearInternet: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            weekInternet: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            monthInternet: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            yearOutbound: allMiniCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            weekOutbound: allMiniCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            monthOutbound: allMiniCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            yearOther: allMiniCounts.yearAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            weekOther: allMiniCounts.weekAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            monthOther: allMiniCounts.monthAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            yearTestdriveCount: miniEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= yearStart.seconds
                    && t.startDate.seconds <= yearEnd.seconds)
                ).length,
            weekTestdriveCount: miniEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= weekStart.seconds
                    && t.startDate.seconds <= weekEnd.seconds)
                ).length,
            monthTestdriveCount: miniEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= monthStart.seconds
                    && t.startDate.seconds <= monthEnd.seconds)
                ).length,
            yearSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= yearStart.seconds
                && a.wonDate.seconds <= yearEnd.seconds),
            weekSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= weekStart.seconds
                && a.wonDate.seconds <= weekEnd.seconds),
            monthSale: bmwEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds),
            yearFaceToFace: allMiniCounts.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            weekFaceToFace: allMiniCounts.weekAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace: allMiniCounts.monthAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(allMiniCounts.yearAllEnq),
            weekAllFollowups: calculateAllFollowups(allMiniCounts.weekAllEnq),
            monthAllFollowups: calculateAllFollowups(allMiniCounts.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(allMiniCounts.yearAllEnq),
            weekAllFollowupLeads: calculateAllFollowupLeads(allMiniCounts.weekAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(allMiniCounts.monthAllEnq),
        }

        let allUsedCounts = {
            yearAllEnq: usedEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            weekAllEnq: usedEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthAllEnq: usedEnq.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        allUsedCounts = {
            ...allUsedCounts,
            yearWalkin: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            weekWalkin: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            monthWalkin: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
            yearPhoneIn: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            weekPhoneIn: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            monthPhoneIn: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
            yearHeadOffice: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            weekHeadOffice: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            monthHeadOffice: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
            yearAutogate: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            weekAutogate: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            monthAutogate: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
            yearInternet: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            weekInternet: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            monthInternet: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
            yearOutbound: allUsedCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            weekOutbound: allUsedCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            monthOutbound: allUsedCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
            yearOther: allUsedCounts.yearAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            weekOther: allUsedCounts.weekAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            monthOther: allUsedCounts.monthAllEnq.filter(a =>
                !checkEnqType(a.enquiryType, 'outbound')
                && !checkEnqType(a.enquiryType, 'internet')
                && !checkEnqType(a.enquiryType, 'autogate')
                && !checkEnqType(a.enquiryType, 'headoffice')
                && !checkEnqType(a.enquiryType, 'walk')
                && !checkEnqType(a.enquiryType, 'phone')),
            yearTestdriveCount: usedEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= yearStart.seconds
                    && t.startDate.seconds <= yearEnd.seconds)
                ).length,
            weekTestdriveCount: usedEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= weekStart.seconds
                    && t.startDate.seconds <= weekEnd.seconds)
                ).length,
            monthTestdriveCount: usedEnq.filter((a) => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.testdrives)
                && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                    && t.startDate.seconds >= monthStart.seconds
                    && t.startDate.seconds <= monthEnd.seconds)
                ).length,
            yearSale: usedEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= yearStart.seconds
                && a.wonDate.seconds <= yearEnd.seconds),
            weekSale: usedEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= weekStart.seconds
                && a.wonDate.seconds <= weekEnd.seconds),
            monthSale: usedEnq.filter(a => (a.status === 'won' || a.status === 'delivered') 
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds),
            yearFaceToFace: allUsedCounts.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            weekFaceToFace: allUsedCounts.weekAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace: allUsedCounts.monthAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(allUsedCounts.yearAllEnq),
            weekAllFollowups: calculateAllFollowups(allUsedCounts.weekAllEnq),
            monthAllFollowups: calculateAllFollowups(allUsedCounts.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(allUsedCounts.yearAllEnq),
            weekAllFollowupLeads: calculateAllFollowupLeads(allUsedCounts.weekAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(allUsedCounts.monthAllEnq),
        }

        setCounts({
            ...bmwCounts
        })
        setMiniCounts({
            ...allMiniCounts
        })
        setUsedCounts({
            ...allUsedCounts
        })
        setCountsCalculated(true)

    }, [pipelineEnquiries])

    const checkEnqType = (type, strToCheck) => {
        let returnVal = false

        let enqType = CommonHelper.getNameByValue(clientSettings.settings.enquiryTypes, type, '')

        if (enqType.toLowerCase().includes(strToCheck.toLowerCase())) {
            returnVal = true
        } else {
            returnVal = false
        }
        if (strToCheck === 'headoffice') {
            if (enqType.toLowerCase().includes(strToCheck.toLowerCase()) ||
                enqType.toLowerCase().includes('bmw australia') ||
                enqType.toLowerCase().includes('mini australia') ||
                enqType.toLowerCase().includes('bmw group crm') ||
                enqType.toLowerCase().includes('bmw head office')) {
                returnVal = true
            } else {
                returnVal = false
            }
        }
        return returnVal
    }

    const calculatePercentage = (val1, val2) => {
        return (val2 > 0 && val1 > 0 ?
            parseFloat((100 / (val1) * val2).toFixed(2)) + "%" :
            "0.00%");
    }

    const calculateAllFollowups = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => a.activities)
        let count = 0;
        ArrOfActivitiesArr.forEach(activities => {
            let allDates = [];
            activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date)) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    const calculateAllFollowupLeads = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => {
            return {
                activities: a.activities,
                addedDate: a.addedDate,
            }
        })
        let count = 0;
        ArrOfActivitiesArr.forEach(activitiesObj => {
            let addedDate = moment(activitiesObj.addedDate.toDate()).format('YYYY/MM/DD')
            let allDates = [];
            activitiesObj.activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date) && addedDate !== date) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('daily-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.add('d-none');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
            document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.remove('d-none');
        }
    }, [])


    return (

        <>
            {
                (countsCalculated && !_.isEmpty(clientSettings))
                    ?
                    <>
                        <div className="A4 landscape">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header report-data-loaded report-data-load">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'SUMMARY REPORT'} <br></br>
                                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">

                                    {/* NEW  */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">BMW NEW ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMMM')} - {moment(weekEnd.toDate()).format('DD MMMM YYYY')}</td>
                                                <td className="count-large">{counts.weekWalkin.length}</td>
                                                <td className="count-large">{counts.weekPhoneIn.length}</td>
                                                <td className="count-large">{counts.weekHeadOffice.length}</td>
                                                <td className="count-large">{counts.weekAutogate.length}</td>
                                                <td className="count-large">{counts.weekInternet.length}</td>
                                                <td className="count-large">{counts.weekOutbound.length}</td>
                                                <td className="count-large">{counts.weekOther.length}</td>
                                                <td className="count-large">{counts.weekAllEnq.length}</td>
                                                <td className="count-large">{counts.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{counts.weekTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{counts.weekSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(counts.weekAllFollowups, counts.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(counts.weekAllEnq.length, counts.weekTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(counts.weekTestdriveCount, counts.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(counts.weekAllEnq.length, counts.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMMM')}</td>
                                                <td className="count-large">{counts.monthWalkin.length}</td>
                                                <td className="count-large">{counts.monthPhoneIn.length}</td>
                                                <td className="count-large">{counts.monthHeadOffice.length}</td>
                                                <td className="count-large">{counts.monthAutogate.length}</td>
                                                <td className="count-large">{counts.monthInternet.length}</td>
                                                <td className="count-large">{counts.monthOutbound.length}</td>
                                                <td className="count-large">{counts.monthOther.length}</td>
                                                <td className="count-large">{counts.monthAllEnq.length}</td>
                                                <td className="count-large">{counts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{counts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{counts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(counts.monthAllFollowups, counts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(counts.monthAllEnq.length, counts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(counts.monthTestdriveCount, counts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(counts.monthAllEnq.length, counts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{counts.yearWalkin.length}</td>
                                                <td className="count-large">{counts.yearPhoneIn.length}</td>
                                                <td className="count-large">{counts.yearHeadOffice.length}</td>
                                                <td className="count-large">{counts.yearAutogate.length}</td>
                                                <td className="count-large">{counts.yearInternet.length}</td>
                                                <td className="count-large">{counts.yearOutbound.length}</td>
                                                <td className="count-large">{counts.yearOther.length}</td>
                                                <td className="count-large">{counts.yearAllEnq.length}</td>
                                                <td className="count-large">{counts.yearFaceToFace.length}</td>  
                                                <td className="count-large">{counts.yearTestdriveCount}</td>  
                                                <td className="count-large">{counts.yearSale.length}</td> 
                                                <td className="count-large">{calculatePercentage(counts.yearAllFollowups, counts.yearAllFollowupLeads)}</td>  
                                                <td className="count-large">{calculatePercentage(counts.yearAllEnq.length, counts.yearTestdriveCount)}</td>  
                                                <td className="count-large">{calculatePercentage(counts.yearTestdriveCount, counts.yearSale.length)}</td>  
                                                <td className="count-large">{calculatePercentage(counts.yearAllEnq.length, counts.yearSale.length)}</td>  
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* MINI  */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">MINI NEW ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMMM')} - {moment(weekEnd.toDate()).format('DD MMMM YYYY')}</td>
                                                <td className="count-large">{miniCounts.weekWalkin.length}</td>
                                                <td className="count-large">{miniCounts.weekPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.weekHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.weekAutogate.length}</td>
                                                <td className="count-large">{miniCounts.weekInternet.length}</td>
                                                <td className="count-large">{miniCounts.weekOutbound.length}</td>
                                                <td className="count-large">{miniCounts.weekOther.length}</td>
                                                <td className="count-large">{miniCounts.weekAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniCounts.weekTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniCounts.weekSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllFollowups, miniCounts.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllEnq.length, miniCounts.weekTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekTestdriveCount, miniCounts.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllEnq.length, miniCounts.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMMM')}</td>
                                                <td className="count-large">{miniCounts.monthWalkin.length}</td>
                                                <td className="count-large">{miniCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.monthAutogate.length}</td>
                                                <td className="count-large">{miniCounts.monthInternet.length}</td>
                                                <td className="count-large">{miniCounts.monthOutbound.length}</td>
                                                <td className="count-large">{miniCounts.monthOther.length}</td>
                                                <td className="count-large">{miniCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllFollowups, miniCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllEnq.length, miniCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthTestdriveCount, miniCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllEnq.length, miniCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{miniCounts.yearWalkin.length}</td>
                                                <td className="count-large">{miniCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.yearAutogate.length}</td>
                                                <td className="count-large">{miniCounts.yearInternet.length}</td>
                                                <td className="count-large">{miniCounts.yearOutbound.length}</td>
                                                <td className="count-large">{miniCounts.yearOther.length}</td>
                                                <td className="count-large">{miniCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.yearFaceToFace.length}</td>  
                                                <td className="count-large">{miniCounts.yearTestdriveCount}</td>  
                                                <td className="count-large">{miniCounts.yearSale.length}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllFollowups, miniCounts.yearAllFollowupLeads)}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllEnq.length, miniCounts.yearTestdriveCount)}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearTestdriveCount, miniCounts.yearSale.length)}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllEnq.length, miniCounts.yearSale.length)}</td>  
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* USed  */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">USED ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMMM')} - {moment(weekEnd.toDate()).format('DD MMMM YYYY')}</td>
                                                <td className="count-large">{usedCounts.weekWalkin.length}</td>
                                                <td className="count-large">{usedCounts.weekPhoneIn.length}</td>
                                                <td className="count-large">{usedCounts.weekHeadOffice.length}</td>
                                                <td className="count-large">{usedCounts.weekAutogate.length}</td>
                                                <td className="count-large">{usedCounts.weekInternet.length}</td>
                                                <td className="count-large">{usedCounts.weekOutbound.length}</td>
                                                <td className="count-large">{usedCounts.weekOther.length}</td>
                                                <td className="count-large">{usedCounts.weekAllEnq.length}</td>
                                                <td className="count-large">{usedCounts.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{usedCounts.weekTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{usedCounts.weekSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.weekAllFollowups, usedCounts.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.weekAllEnq.length, usedCounts.weekTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.weekTestdriveCount, usedCounts.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.weekAllEnq.length, usedCounts.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMMM')}</td>
                                                <td className="count-large">{usedCounts.monthWalkin.length}</td>
                                                <td className="count-large">{usedCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{usedCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{usedCounts.monthAutogate.length}</td>
                                                <td className="count-large">{usedCounts.monthInternet.length}</td>
                                                <td className="count-large">{usedCounts.monthOutbound.length}</td>
                                                <td className="count-large">{usedCounts.monthOther.length}</td>
                                                <td className="count-large">{usedCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{usedCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{usedCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{usedCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.monthAllFollowups, usedCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.monthAllEnq.length, usedCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.monthTestdriveCount, usedCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(usedCounts.monthAllEnq.length, usedCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{usedCounts.yearWalkin.length}</td>
                                                <td className="count-large">{usedCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{usedCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{usedCounts.yearAutogate.length}</td>
                                                <td className="count-large">{usedCounts.yearInternet.length}</td>
                                                <td className="count-large">{usedCounts.yearOutbound.length}</td>
                                                <td className="count-large">{usedCounts.yearOther.length}</td>
                                                <td className="count-large">{usedCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{usedCounts.yearFaceToFace.length}</td>  
                                                <td className="count-large">{usedCounts.yearTestdriveCount}</td>  
                                                <td className="count-large">{usedCounts.yearSale.length}</td>  
                                                <td className="count-large">{calculatePercentage(usedCounts.yearAllFollowups, usedCounts.yearAllFollowupLeads)}</td> 
                                                <td className="count-large">{calculatePercentage(usedCounts.yearAllEnq.length, usedCounts.yearTestdriveCount)}</td>  
                                                <td className="count-large">{calculatePercentage(usedCounts.yearTestdriveCount, usedCounts.yearSale.length)}</td>  
                                                <td className="count-large">{calculatePercentage(usedCounts.yearAllEnq.length, usedCounts.yearSale.length)}</td> 
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* TOTALS  */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">TOTALS&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMMM')} - {moment(weekEnd.toDate()).format('DD MMMM YYYY')}</td>
                                                <td className="count-large">{miniCounts.weekWalkin.length + usedCounts.weekWalkin.length + counts.weekWalkin.length}</td>
                                                <td className="count-large">{miniCounts.weekPhoneIn.length + usedCounts.weekPhoneIn.length + counts.weekPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.weekHeadOffice.length + usedCounts.weekHeadOffice.length + counts.weekHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.weekAutogate.length + usedCounts.weekAutogate.length + counts.weekAutogate.length}</td>
                                                <td className="count-large">{miniCounts.weekInternet.length + usedCounts.weekInternet.length + counts.weekInternet.length}</td>
                                                <td className="count-large">{miniCounts.weekOutbound.length + usedCounts.weekOutbound.length + counts.weekOutbound.length}</td>
                                                <td className="count-large">{miniCounts.weekOther.length + usedCounts.weekOther.length + counts.weekOther.length}</td>
                                                <td className="count-large">{miniCounts.weekAllEnq.length + usedCounts.weekAllEnq.length + counts.weekAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.weekFaceToFace.length + usedCounts.weekFaceToFace.length + counts.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniCounts.weekTestdriveCount + usedCounts.weekTestdriveCount + counts.weekTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniCounts.weekSale.length + usedCounts.weekSale.length + counts.weekSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllFollowups + usedCounts.weekAllFollowups + counts.weekAllFollowups, miniCounts.weekAllFollowupLeads + usedCounts.weekAllFollowupLeads + counts.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllEnq.length + usedCounts.weekAllEnq.length + counts.weekAllEnq.length, miniCounts.weekTestdriveCount + usedCounts.weekTestdriveCount + counts.weekTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekTestdriveCount + usedCounts.weekTestdriveCount + counts.weekTestdriveCount, miniCounts.weekSale.length + usedCounts.weekSale.length + counts.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.weekAllEnq.length + usedCounts.weekAllEnq.length + counts.weekAllEnq.length, miniCounts.weekSale.length + usedCounts.weekSale.length + counts.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMMM')}</td>
                                                <td className="count-large">{miniCounts.monthWalkin.length + usedCounts.monthWalkin.length + counts.monthWalkin.length}</td>
                                                <td className="count-large">{miniCounts.monthPhoneIn.length + usedCounts.monthPhoneIn.length + counts.monthPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.monthHeadOffice.length + usedCounts.monthHeadOffice.length + counts.monthHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.monthAutogate.length + usedCounts.monthAutogate.length + counts.monthAutogate.length}</td>
                                                <td className="count-large">{miniCounts.monthInternet.length + usedCounts.monthInternet.length + counts.monthInternet.length}</td>
                                                <td className="count-large">{miniCounts.monthOutbound.length + usedCounts.monthOutbound.length + counts.monthOutbound.length}</td>
                                                <td className="count-large">{miniCounts.monthOther.length + usedCounts.monthOther.length + counts.monthOther.length}</td>
                                                <td className="count-large">{miniCounts.monthAllEnq.length + usedCounts.monthAllEnq.length + counts.monthAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.monthFaceToFace.length + usedCounts.monthFaceToFace.length + counts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniCounts.monthTestdriveCount + usedCounts.monthTestdriveCount + counts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniCounts.monthSale.length + usedCounts.monthSale.length + counts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllFollowups + usedCounts.monthAllFollowups + counts.monthAllFollowups, miniCounts.monthAllFollowupLeads + usedCounts.monthAllFollowupLeads + counts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllEnq.length + usedCounts.monthAllEnq.length + counts.monthAllEnq.length, miniCounts.monthTestdriveCount + usedCounts.monthTestdriveCount + counts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthTestdriveCount + usedCounts.monthTestdriveCount + counts.monthTestdriveCount, miniCounts.monthSale.length + usedCounts.monthSale.length + counts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniCounts.monthAllEnq.length + usedCounts.monthAllEnq.length + counts.monthAllEnq.length, miniCounts.monthSale.length + usedCounts.monthSale.length + counts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{miniCounts.yearWalkin.length + usedCounts.yearWalkin.length + counts.yearWalkin.length}</td>
                                                <td className="count-large">{miniCounts.yearPhoneIn.length + usedCounts.yearPhoneIn.length + counts.yearPhoneIn.length}</td>
                                                <td className="count-large">{miniCounts.yearHeadOffice.length + usedCounts.yearHeadOffice.length + counts.yearHeadOffice.length}</td>
                                                <td className="count-large">{miniCounts.yearAutogate.length + usedCounts.yearAutogate.length + counts.yearAutogate.length}</td>
                                                <td className="count-large">{miniCounts.yearInternet.length + usedCounts.yearInternet.length + counts.yearInternet.length}</td>
                                                <td className="count-large">{miniCounts.yearOutbound.length + usedCounts.yearOutbound.length + counts.yearOutbound.length}</td>
                                                <td className="count-large">{miniCounts.yearOther.length + usedCounts.yearOther.length + counts.yearOther.length}</td>
                                                <td className="count-large">{miniCounts.yearAllEnq.length + usedCounts.yearAllEnq.length + counts.yearAllEnq.length}</td>
                                                <td className="count-large">{miniCounts.yearFaceToFace.length + usedCounts.yearFaceToFace.length + counts.yearFaceToFace.length}</td>  
                                                <td className="count-large">{miniCounts.yearTestdriveCount + usedCounts.yearTestdriveCount + counts.yearTestdriveCount}</td> 
                                                <td className="count-large">{miniCounts.yearSale.length + usedCounts.yearSale.length + counts.yearSale.length}</td> 
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllFollowups + usedCounts.yearAllFollowups + counts.yearAllFollowups, miniCounts.yearAllFollowupLeads + usedCounts.yearAllFollowupLeads + counts.yearAllFollowupLeads)}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllEnq.length + usedCounts.yearAllEnq.length + counts.yearAllEnq.length, miniCounts.yearTestdriveCount + usedCounts.yearTestdriveCount + counts.yearTestdriveCount)}</td> 
                                                <td className="count-large">{calculatePercentage(miniCounts.yearTestdriveCount + usedCounts.yearTestdriveCount + counts.yearTestdriveCount, miniCounts.yearSale.length + usedCounts.yearSale.length + counts.yearSale.length)}</td>  
                                                <td className="count-large">{calculatePercentage(miniCounts.yearAllEnq.length + usedCounts.yearAllEnq.length + counts.yearAllEnq.length, miniCounts.yearSale.length + usedCounts.yearSale.length + counts.yearSale.length)}</td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className='loader-center-screen'>
                            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                        </div>
                    </>
            }
        </>
    )
}
export default SalesProgressBMW;