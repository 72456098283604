/** LIBRARIES */
import React, { useState, useEffect, useContext } from 'react';
import _, { isEmpty } from 'lodash'
import toast from 'toasted-notes';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';
import moment from 'moment';
/** COMPONENTS */
import Translate from '../constants/translate';
import { default as _images } from '../images';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import GroupInfo from './groupInfo';
import { MainContext } from '../views/layout/provider';
import { PopUpModal } from '../components'


const SidePanel = (props) => {
    const [hadEnquiryBG, setEnquiryBG] = useState(false)
    const [hadInnerBG, setInnerBG] = useState(true)
    const [loader, setLoader] = useState(false)
    const [showCreateGroupChat, setCreateGroupChat] = useState(false)
    const { clsActive, clsName, title } = props;
    const { dealersettings, clientUsers } = useContext(MainContext);

    useEffect(() => {
        if (title === 'Chat' || title === 'selectUser') {
            window.Intercom('update', {
                "hide_default_launcher": clsActive === 'active' ? true : false
            })
        }
    }, [clsActive, title])

    // useEffect(() => {

    //     console.log('SidePanel-componentDidMount', clsActive, hadEnquiryBG, hadInnerBG)
    //     // if (clsActive) {
    //     //     if (document.getElementsByTagName('body')[0].classList.contains('inner-bg'))
    //     //         setInnerBG(true);

    //     //     if (document.getElementsByTagName('body')[0].classList.contains('enquiry-bg'))
    //     //         setEnquiryBG(true);
    //     // }
    //     // else {
    //     //     if (hadEnquiryBG)
    //     //         document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
    //     //     else
    //     //         document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');

    //     //     if (hadInnerBG)
    //     //         document.getElementsByTagName('body')[0].classList.add('inner-bg');
    //     //     else
    //     //         document.getElementsByTagName('body')[0].classList.remove('inner-bg');

    //     // }
    //     // if (clsActive && !hadEnquiryBG) {
    //     //     let pathname = window.location.pathname;
    //     //     if (pathname.indexOf('enquiry/details') >= 0 || pathname.indexOf('contacts/details') >= 0) {
    //     //         setEnquiryBG(true);
    //     //     }

    //     //     if (pathname.indexOf('pipeline') >= 0 && pathname.indexOf('pipeline/list') < 0) {
    //     //         console.log('SidePanel-pipeline', clsActive, pathname.indexOf('pipeline'), pathname.indexOf('pipeline/list'))
    //     //         setInnerBG(false);
    //     //     }
    //     // }
    //     // else if (!clsActive && hadEnquiryBG) {
    //     //     document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
    //     //     document.getElementsByTagName('body')[0].classList.remove('inner-bg');
    //     // }
    //     // else if (!clsActive && !hadInnerBG)
    //     //     document.getElementsByTagName('body')[0].classList.remove('inner-bg');


    // }, [props.clsActive])


    const updateNotification = async (name) => {
        //const batch = window.firebase.firestore().batch();
        let hasUpdate = false;

        let _notificationType = props.isSMSNotify ? ['sms'] : ['engageRequest', 'activity', 'enquiry', 'finance', 'afterMarket', 'pendingLost', 'log', 'kitchen', 'callLogs', 'inbound', 'fleet', 'fleetProAssign', 'salesHub'];
        let _notificationType2 = props.isSMSNotify ? ['sms'] : ['service', 'serviceRequest', 'serviceactivity', 'servicelog', 'financeactivity', 'financelog', 'aircallMissed', 'aircallContact', 'callMissed', 'callContact', 'afterMarketactivity', 'afterMarketlog'];
        let _notificationType3 = props.isSMSNotify ? ['sms'] : ['tradein', 'tradeinPro', 'tradeinProOffer', 'tradeinProRequest', 'tradeinProValuer', 'tradeinProFinal', 'tradeinProactivity', 'tradeinProlog', 'inboundTIP', 'email'];

        var usernotification = window.firebase.firestore().collection('notifications')
            .where('isDeleted', '==', false)
            .where('type', 'in', _notificationType)
            .where('toUserID', '==', localStorage.uid)
            .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -15).startOf('day')._d))
            .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            .orderBy('addedDate', 'desc')

        var usernotification2 = window.firebase.firestore().collection('notifications')
            .where('isDeleted', '==', false)
            .where('type', 'in', _notificationType2)
            .where('toUserID', '==', localStorage.uid)
            .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -15).startOf('day')._d))
            .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            .orderBy('addedDate', 'desc')

        var usernotification3 = window.firebase.firestore().collection('notifications')
            .where('isDeleted', '==', false)
            .where('type', 'in', _notificationType3)
            .where('toUserID', '==', localStorage.uid)
            .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -15).startOf('day')._d))
            .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            .orderBy('addedDate', 'desc')

        // if (name === 'isRead') {
        //     usernotification = usernotification.where('isRead', '==', false);
        //     usernotification2 = usernotification2.where('isRead', '==', false);
        //     usernotification3 = usernotification3.where('isRead', '==', false);
        // }

        setLoader(true)
        var refNotificationSidebarData = combineLatest(
            collection(usernotification),
            collection(usernotification2),
            collection(usernotification3),
        ).pipe(
            map(([sales, services, tradeinpro]) => {
                return [
                    _.map(sales, function (obj) { return { ...obj.data(), documentID: obj.id, ref: obj.ref } }),
                    _.map(services, function (obj) { return { ...obj.data(), documentID: obj.id, ref: obj.ref } }),
                    _.map(tradeinpro, function (obj) { return { ...obj.data(), documentID: obj.id, ref: obj.ref } }),
                ]

            }))
            .subscribe(async ([sales, services, tradeinpro]) => {

                let notifySnapshot = [];
                if (props.isSMSNotify)
                    notifySnapshot = sales;
                else
                    notifySnapshot = sales.concat(services).concat(tradeinpro)

                // notifySnapshot.forEach(doc => {
                //     hasUpdate = true;
                //     batch.update(window.firebase.firestore().doc(`notifications/${doc.documentID}`), {
                //         [name]: true
                //     });
                // });

                // batch.commit()
                //     .then(() => {
                //         setLoader(false)
                //         if (hasUpdate) {
                //             toast.notify(name === 'isDeleted' ? `Notification cleared successfully` : `Notification updated successfully`, {
                //                 duration: 2000,
                //                 position: 'top-right'
                //             })
                //         }
                //     })
                //     .catch((error) => {
                //         setLoader(false)
                //         console.error('updateNotification', error);
                //         toast.notify('Something went wrong', {
                //             duration: 2000,
                //             position: 'top-right'
                //         })
                //     });
                refNotificationSidebarData && refNotificationSidebarData.unsubscribe()

                const batchArray = [];
                batchArray.push(window.firebase.firestore().batch());
                let operationCounter = 0;
                let batchIndex = 0;

                for (const doc of notifySnapshot) {

                    hasUpdate = true;

                    batchArray[batchIndex].update(window.firebase.firestore().doc(`notifications/${doc.documentID}`), {
                        [name]: true
                    });

                    operationCounter++;
                    if (operationCounter === 499) {
                        batchArray.push(window.firebase.firestore().batch());
                        batchIndex++;
                        operationCounter = 0;
                    }
                }

                for (const batch of batchArray) {
                    await batch.commit().then(() => {
                        setLoader(false)
                        if (batchArray[batchArray.length - 1] === batch) {
                            toast.notify(name === 'isDeleted' ? `Notification cleared successfully` : `Notification updated successfully`, {
                                duration: 2000,
                                position: 'top-right'
                            })
                        }
                    }).catch(function (error) {
                        console.error("Error updating document: ", error);
                    });
                }

            });
    }

    const handleClose = (e) => {
        e.preventDefault();

        let pathname = window.location.pathname;
        if (pathname.indexOf('enquiry/details') >= 0 ||
            pathname.indexOf('contacts/details') >= 0 ||
            pathname.indexOf('tradeinpro/details') >= 0 ||
            pathname.indexOf('eventspro/details') >= 0 ||
            pathname.indexOf('wholesaler/details') >= 0 ||
            pathname.indexOf('service/details') >= 0 ||
            pathname.indexOf('fleetpro/details') >= 0 ||
            pathname.indexOf('/dashboard') >= 0 ||
            pathname.indexOf('/dashboard/bdc') >= 0 ||
            pathname.indexOf('/dashboard/service') >= 0 ||
            pathname.indexOf('/dashboard/events') >= 0 ||
            pathname.indexOf('/dashboard/tradeinpro') >= 0) {
            document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        } else if (
            (pathname.indexOf('pipeline') >= 0 ||
                pathname.indexOf('inbound') >= 0 ||
                pathname.indexOf('wholesaler') >= 0 ||
                pathname.indexOf('tradeinpro') >= 0 ||
                pathname.indexOf('aftersales') >= 0 ||
                pathname.indexOf('service') >= 0 ||
                pathname.indexOf('/kitchen') >= 0) &&
            (pathname.indexOf('ppsr/list') < 0 &&
                pathname.indexOf('tradeinpro/list') < 0 &&
                pathname.indexOf('eventspro/list') < 0 &&
                pathname.indexOf('fleetpro/list') < 0 &&
                pathname.indexOf('fleetpro/history') < 0 &&
                pathname.indexOf('eventspro/attendees') < 0 &&
                pathname.indexOf('service/list') < 0 &&
                pathname.indexOf('pipeline/oemlist') < 0 &&
                pathname.indexOf('pipeline/list') < 0 &&
                pathname.indexOf('inbound/list') < 0 &&
                pathname.indexOf('tipinbound/list') < 0 &&
                pathname.indexOf('inbound/allocation') < 0)) {
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        props.sidepanelClose();
    }

    let groupChatStyles = props.title === 'Chat' ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {}

    return (
        <>
            <div className={"overlay " + clsActive + ` ${props.title === 'Chat' ? "fix-chat-overlay" : ""}`} onClick={(e) => { handleClose(e) }}> </div>
            <nav className={`${clsName + ' ' + clsActive} ${props.title === 'Chat' ? "fix-chat" : ""}`}>
                <div className="dismiss" onClick={(e) => { handleClose(e) }}>
                    <img src={_images.arrowclose} alt="Close" width="20" height="18" />
                </div>
                {
                    !_.isEmpty(props.title) ?
                        (
                            <div className="sidebar-header" style={groupChatStyles}>
                                {props.titleTranslationDisabled ? (
                                    <span>{props.title}</span>
                                ) : (
                                    <Translate text={props.title.toLowerCase()} />
                                )}
                                {props.title === 'Chat' && props.activeTab !== 'contacts' && (
                                    <div className="chat-controls-add" onClick={() => setCreateGroupChat(true)}> <button type="button" className="btn btn-default float-right"><i className="ico icon-add"></i> New Group</button> </div>
                                )}

                                {!isEmpty(props.navigateToDashboard) && props.navigateToDashboard.show ? (
                                    <div className="notify-settings">
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>{`Navigate to ${props.navigateToDashboard.title}`}</Tooltip>
                                            }
                                        >
                                            <a onClick={(e) => { e.preventDefault(); props.navigateToDashboard.callback(`/dashboard`, props.navigateToDashboard.clientID); }}>
                                                <i className="ico icon-expand"></i>
                                            </a>
                                        </OverlayTrigger>
                                    </div>
                                ) : <></>
                                }

                                {
                                    ((props.title === 'Notifications' || props.title === 'SMS Notifications') && props.count && props.count > 0) ?
                                        (
                                            <>
                                                <div className="notify-readall mr-3">
                                                    <a href="#" title="Read All" onClick={(e) => { e.preventDefault(); updateNotification('isRead'); }}>
                                                        <i className="ico icon-multi-check"></i></a>
                                                </div>
                                                <div className="notify-clearall mr-3">
                                                    <a href="#" onClick={(e) => { e.preventDefault(); updateNotification('isDeleted'); }}>Clear all</a>
                                                </div>
                                                {
                                                    loader ? (
                                                        <span className="spinner-border spinner-button mr-3 float-right loader-primary" role="status" aria-hidden="true"></span>
                                                    ) : (<></>)
                                                }
                                            </>
                                        ) : (<></>)
                                }


                            </div>
                        ) : (<></>)
                }
                {clsActive ? props.children : <></>}
                <PopUpModal show={showCreateGroupChat}>
                    <GroupInfo
                        {...props}
                        show={showCreateGroupChat}
                        title={'Create Group Chat'}
                        handleClose={() => setCreateGroupChat(false)}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        newGroup={true}
                    />
                </PopUpModal>
            </nav>
        </>
    );
}

export default SidePanel;