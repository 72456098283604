import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import { ContentOverlay, ReactSelect, NoDataPieChart, LineChart } from '../../components'
//import FilterPanel from './filterPanel'
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import { serviceStatus } from '../../services/enum';
import Translate from '../../constants/translate';
const dateOptions = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' },
    { active: true, value: 'lastMonth', label: 'Last Month' },
    { active: true, value: 'currentQuarter', label: 'Current Quarter' },
    { active: true, value: 'lastQuarter', label: 'Last Quarter' },
]

const RevenueCounts = (props) => {
    const [data, setData] = useState([])
    const [dateType, setType] = useState('weekly')
    const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const calcOnlyRecommCost = props?.dealersettings?.client?.clientSettings?.calcOnlyRecommCost || false;

    const fetchData = async () => {
        // console.log('lead by appointmentType')
        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            type: dateType ? dateType : 'weekly'
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }

        if (dateType === 'lastMonth') {
            _filter.startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            _filter.endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            _filter.type = 'weekly'
        }
        else if (dateType === 'lastQuarter') {
            _filter.startDate = moment().add(-1, 'quarter').startOf('quarter').format('YYYY-MM-DD');
            _filter.endDate = moment().add(-1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
            _filter.type = 'monthly'
        }
        else if (dateType === 'currentQuarter') {
            _filter.startDate = moment().startOf('quarter').format('YYYY-MM-DD');
            _filter.endDate = moment().endOf('quarter').format('YYYY-MM-DD');
            _filter.type = 'monthly'
        }
        else if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }

        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.map(item => item.value).join(',')
        }
        _filter.calcOnlyRecommCost = calcOnlyRecommCost;
        //console.log('_filter', _filter)
        let _searchObject = {
            type: "getServiceRevenueReport",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)
            let enquiries_ = resp.data.data.map(revenue => {
                const _obj = { ...revenue }
                _obj.name = (revenue.name === 'others' ? 'Other/Blank' : revenue.name)
                _obj.value = revenue.name;
                _obj.approvedamount = revenue.approvedamount ? parseFloat(revenue.approvedamount) : 0;
                _obj.approvedcount = revenue.approvedcount ? parseInt(revenue.approvedcount) : 0;
                _obj.declinedamount = revenue.declinedamount ? parseFloat(revenue.declinedamount) : 0;
                _obj.declinedcount = revenue.declinedcount ? parseInt(revenue.declinedcount) : 0;

                if (revenue.id && !_.isEmpty(revenue.id.split(';'))) {
                    let _dates = revenue.id.split(';')
                    _obj.startDate = _dates[0]
                    _obj.endDate = _dates[1]
                }
                else if (props.dateRange) {
                    _obj.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    _obj.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
                return _obj
            })
            //enquiries_ = _.orderBy(enquiries_, ['count'], ['desc'])
            setData(enquiries_)
            //console.log('resp-enquiries_', enquiries_, props.height)
            setLoader(false)
            //setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            //setFirstTimeLoaded(true)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.selectedUser, props.selectedPipeline, props.dateRange, dateType])

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setType(e.value);
        }
    }
    const handleShowEnquiryList = (val, rec) => {
        //console.log('handleShowEnquiryList', val, rec)
        let _ownerResponse = val === 'approvedamount' ? serviceStatus.OWNERAPPROVED : 'recommDeclined';
        let _objRequest = {
            'ownerResponse': _ownerResponse,
            'status': _ownerResponse,
            'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
            'startDate': rec.startDate,
            'endDate': rec.endDate,
            'dateType': 'responseDate'
        }
        //console.log('handleShowEnquiryList', val, rec, _objRequest)
        props.handleShowEnquiryList(_objRequest, (_ownerResponse ? _ownerResponse.split(/(?=[A-Z])/).join(' ').toUpperCase() : ''));
    }

    const reportData = useMemo(() => data, [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">


                    <div className="form-group col-md-2 dashboard-widget-select">
                        <ReactSelect
                            options={dateOptions}
                            name={"date-type-pipeline"}
                            placeholder={'select type'}
                            onChange={handleReactSelectChange}
                            value={!_.isEmpty(dateType) ? dateType : 'weekly'}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>

                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>

                    </div>
                    <h3><Translate text={'Leads By Revenue'} /></h3>
                </div>


                <div className="dashboard-box-body" style={{ minHeight: `${props.height ? props.height : 400}px` }}>
                    <div className="text-center">

                        {<LineChart
                            id={`view-revenue-line-chart-`}
                            height={'400px'}
                            data={reportData}
                            dynamicColumns={[{ 'name': 'Owner Approved', value: 'approvedamount', color: "#64a000" }, { 'name': 'Owner Declined', value: 'declinedamount', color: "#e25b3d" }]}
                            xCategory={'name'}
                            columns={['approvedamount', 'declinedamount']}
                            setChartData={(data) => { /*setChartData(data) */ }}
                            setChartImg={(img) => { /*setChartImg(img) */ }}
                            calculationType={'revenue'}
                            currencySymbol={currencySymbol}
                            handleShowEnquiryList={handleShowEnquiryList}
                        />}

                    </div>
                </div>


            </div>

        </>
    );
}

export default RevenueCounts;