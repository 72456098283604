import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import "../../../styles/service-invoice.scss";
import { PopUpModal } from '../../../components';
import CommonHelper from "../../../services/common";
import { default as _images } from '../../../images';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import SendMail from './sendMail'

const ServiceInvoice = (props) => {
	const _useHistory = useHistory()
	const [btnLoader, setBtnLoader] = useState(false)
	const [saveLoader, setSaveLoader] = useState(false)
	const [fileOnceDownloaded, setFileOnceDownloaded] = useState(false)
	const [fileLink, setFileLink] = useState('')
	const [firestoreDB, setFirestoreDB] = useState();
	const [client, setClient] = useState()
	const [data, setData] = useState()
	const [contact, setContact] = useState()
	const [service, setService] = useState()
	const [loader, setLoader] = useState(true)
	const [currencySymbol, setCurrencySymbol] = useState()

	const [emailloader, setEmailLoader] = useState(false);
	const [showEmail, setShowEmail] = useState(false)

	const [clientUsers, setClientUsers] = useState([]);

	let params = new URLSearchParams(window.location.search);
	let _type = params.get('type');
	let _vbtn = params.get('vbtn');
	let _uid = params.get('uid');
	useEffect(() => {
		window.firebase.firestore().collectionGroup('invoices')
			.where('documentID', '==', props.serviceid)
			.get()
			.then(doc => {
				if (doc.size > 0) {
					setFirestoreDB(window.firebase)
				}
				else {
					setFirestoreDB(window.firebase2)
				}
			})
	}, [])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		if (props.serviceid) {
			loadServieData();
		}
	}, [firestoreDB])

	useEffect(() => {
		if (!fileLink)
			return
		// console.log('FILE LINK CHANGES')
		document.querySelector('#fileLinkAnchor').click()
	}, [fileLink])

	const downloadFile = (saveFile) => {
		if (saveFile)
			setSaveLoader(true)
		else
			setBtnLoader(true)
		const getDownloadLink = window.firebase.functions().httpsCallable('serviceapp-generatePDF');
		getDownloadLink({
			url: `/serviceinvoice/preview/${props.serviceid}?type=print`,
			invoiceID: props.serviceid,
			clientID: client.documentID,
			serviceID: data.serviceID,
			contactID: data.contactID,
			modifiedBy: _uid ? _uid : (localStorage?.uid ? localStorage.uid : 'system'),
			saveFile: saveFile,
			projectId: client.projectId ? client.projectId : null
		}).then((response) => {
			setSaveLoader(false)
			setBtnLoader(false)
			if (response.data.success) {
				if (saveFile) {
					setFileOnceDownloaded(true)
					Swal.fire('File saved successfully', '', 'success')
				} else {
					// download_file(response.data.data,`quote-${moment().format('YYYY-MM-DD-hmmssSS')}.pdf`)
					setFileLink(response.data.data)
				}

			}
			else {
				// console.log('err1')
				setSaveLoader(false)
				setBtnLoader(false)
				// console.log(response.data.message);
				Swal.fire('Something went wrong', '', 'error')
			}
		}).catch(err => {
			// console.log('err2')
			setSaveLoader(false)
			setBtnLoader(false)
			console.log(err)
			Swal.fire('Something went wrong', '', 'error')
		})
	}

	const loadServieData = async () => {
		const invoiceDoc = await firestoreDB.firestore().collectionGroup('invoices')
			.where('documentID', '==', props.serviceid)
			.get();
		if (invoiceDoc.size > 0) {
			let _invoice = invoiceDoc.docs[0].data();
			let companyDoc = {};
			setData(_invoice);
			const clientDoc = await window.firebase.firestore().doc(`clients/${_invoice.clientID}`).get();
			setClient(clientDoc.data())
			const contactDoc = await firestoreDB.firestore().doc(`contactDetails/${_invoice.contactID}`).get();
			const responseCountries = await fetch(window.location.origin + '/lookups/countries.json');
			const countriesjson = await responseCountries.json();
			if (contactDoc?.data()?.businessContact && contactDoc?.data()?.company?.documentID) {
				const company = await firestoreDB.firestore().doc(`companies/${contactDoc.data().company.documentID}`).get();
				companyDoc = company?.data() || {};
			}
			setContact({
				...contactDoc.data(),
				preferredName: null,
				country: _.find(countriesjson, { code: contactDoc.data().country }) ? _.find(countriesjson, { code: contactDoc.data().country }).name : contactDoc.data().country,
				company: companyDoc
			})
			setCurrencySymbol(clientDoc.data()?.currency?.symbol ? clientDoc.data()?.currency?.symbol + ' ' : '$ ')
			setLoader(false)

			if ((_.isEmpty(_type) || _type !== 'print') && _invoice.serviceID) {
				const serviceDoc = await firestoreDB.firestore().doc(`serviceJobs/${_invoice.serviceID}`).get();
				setService({ ...serviceDoc.data() })

				const usersDoc = await window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', _invoice.clientID).get();

				let _users = [];
				usersDoc.docs.forEach(doc => {
					const _userdoc = doc.data();
					if (!_.isEmpty(_userdoc.settingsID))// && _userdoc.isHidden !== true)
						_users.push({ ..._userdoc, 'documentID': doc.id, 'id': doc.id, 'active': !Boolean(_userdoc.isDisabled), 'clientID': _invoice.clientID })
				});
				if (!_.isEmpty(_users))
					_users = _.orderBy(_users, ['name'], ['asc']);

				setClientUsers(_users);

			}
			//if (_view === 'print') {
			// window.addEventListener('afterprint', (event) => {
			// 	window.close();
			// });
			// setTimeout(function () {
			// 	window.print();
			// }, 500)
			//}
		}
		else {
			setLoader(false)
		}
	}

	// useEffect(() => {
	// 	window.addEventListener('keydown', (e) => {
	// 		if ((e.ctrlKey || e.metaKey) && (e.key == "p" || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
	// 			window.open(`${window.location.origin}${window.location.pathname}?view=print&type=print`, '_blank');
	// 		}
	// 	});
	// 	return () => {
	// 		window.removeEventListener('keydown', (e) => {
	// 			if ((e.ctrlKey || e.metaKey) && (e.key == "p" || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
	// 				window.open(`${window.location.origin}${window.location.pathname}?view=print&type=print`, '_blank');
	// 			}
	// 		});
	// 		// window.removeEventListener('afterprint', (event) => {
	// 		// 	window.close();
	// 		// });
	// 	}
	// }, [])

	const handleEmailClose = async (paramObj) => {
		setShowEmail(false);
		if (!_.isEmpty(paramObj)) {
			try {
				setEmailLoader(true)
				const sendEmail = window.firebase.functions().httpsCallable('reports-sendEmailNow');
				await sendEmail(paramObj).then((response) => {
					setEmailLoader(false)
					if (response.data.success) {
						saveChangeLog(paramObj);
						Swal.fire('Email sent successfully.', '', 'success')
					}
					else {
						console.log(response.data.message);
						Swal.fire('Something went wrong', '', 'error')
					}
				})

			}
			catch (error) {
				console.log(error);
				Swal.fire('Something went wrong', '', 'error')
			}

		}
	}

	const saveChangeLog = (objData) => {

		const newlogVM = Object.assign({
			clientID: client.documentID,
			recordId: data.serviceID
		});
		newlogVM.type = 'service';
		newlogVM.subType = 'added';
		newlogVM.logBy = _uid ? _uid : (localStorage?.uid ? localStorage.uid : 'system');
		newlogVM.logDate = window.firebase.firestore.Timestamp.now();
		newlogVM.documentID = window.firebase.firestore().collection('changeLogs').doc().id;

		let _note = `Invoice ${data.displayID} has been emailed to `;

		if (!_.isEmpty(objData?.recipients)) {
			objData.recipients.forEach(_email => {
				_note = _note + ` \n${_email} `;
			})
		}
		newlogVM.notes = _note

		Object.keys(newlogVM).forEach(key => newlogVM[key] === undefined ? delete newlogVM[key] : {});

		let _reqObject = {
			"documentID": newlogVM.documentID,
			"collection": `changeLogs`,
			"data": newlogVM
		}

		const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
		genericSaveData(_reqObject)
	}

	const ContentDOM = ({ classname, id, previewType }) => {
		return (
			<div className={`dynamic-report-print-wrap report-data-loaded report-data-load ${classname}`} id={id}>
				<div className="print-wrap">
					<div className={`${previewType === 'print' ? "page-header-print" : "page-header"}`}>
						<div className="header">
							<div className="invoice-sub-headerwrapper">

								<div className="invoice-sub-header">
									<div className="invoice-sub-header-logo">
										{
											client?.servicelogoURL ? (
												<img src={client?.servicelogoURL} height="60" />
											) : client?.logoURL ? (
												<img src={client?.logoURL} height="60" />
											) : (<>{client?.name}</>)
										}
									</div>
									<div className="invoice-sub-header-company-address">
										{
											client?.address ? client?.address : ''
										}
										{
											client?.phone ? ` | PH: ${client?.phone}` : ''
										}
										{
											client?.email ? ` | Email: ${client?.email}` : ''
										}
									</div>
									{
										client?.abn ? (
											<div className="invoice-sub-header-abn">A.B.N {client?.abn}</div>
										) : client?.acn ? (
											<div className="invoice-sub-header-abn">A.C.N {client?.acn}</div>
										) : (<></>)
									}
								</div>


								<div className="invoice-sub-title">
									<div className="invoice-sub-header-titleleft">TAX INVOICE
										{
											data.isPaid && (<div className="badge badge-paid margin-top-paid"> <img src={_images.radioTick} alt="" width="16" /> PAID</div>)
										}
									</div>
									<div className="invoice-sub-header-titleright">Invoice No.: <span className="inv-num">{data.displayID}</span></div>

								</div>




							</div>

							<div className="invoice-sub-addresswrap">



								<div className="invoice-sub-addresswrap-left">

									<div className="invoice-sub-adress-head">
										Bill To:
									</div>
									<div className="invoice-sub-adress-details">
										{CommonHelper.displayContact(null, contact)}<br />
										{
											!contact.businessContact && contact?.street ? <>{contact?.street}<br /></> : ''
										}
										{
											!contact.businessContact && contact?.suburb ? <>{contact?.suburb}&nbsp; </> : ''
										}
										{
											!contact.businessContact && contact?.state ? <>{contact?.state}&nbsp; </> : ''
										}
										{
											!contact.businessContact && contact?.areaCode ? <>{contact?.areaCode}<br /> </> : ''
										}
										{
											!contact.businessContact && contact?.country ? <>{contact?.country} </> : ''
										}
										{
											contact.businessContact && contact?.company?.address ? <>{contact?.company?.address}<br />  </> : ''
										}
										{
											contact.businessContact && contact?.company?.companyAbnAcn ? contact?.company?.companyAbnAcn : ''
										}
									</div>


								</div>
								<div className="invoice-sub-addresswrap-right">

									<div className="invoice-sub-adress-head">
										Ship To:
									</div>
									<div className="invoice-sub-adress-details">
										{CommonHelper.displayContact(null, contact)}<br />
										{
											!contact.businessContact && contact?.street ? <>{contact?.street}<br /></> : ''
										}
										{
											!contact.businessContact && contact?.suburb ? <>{contact?.suburb}&nbsp; </> : ''
										}
										{
											!contact.businessContact && contact?.state ? <>{contact?.state}&nbsp; </> : ''
										}
										{
											!contact.businessContact && contact?.areaCode ? <>{contact?.areaCode}<br /> </> : ''
										}
										{
											!contact.businessContact && contact?.country ? <>{contact?.country} </> : ''
										}
										{
											contact.businessContact && contact?.company?.address ? <>{contact?.company?.address}<br />  </> : ''
										}
										{
											contact.businessContact && contact?.company?.companyAbnAcn ? contact?.company?.companyAbnAcn : ''
										}
									</div>

								</div>


							</div>

							<div className="invoice-sub-table-main">

								<table className="form-bottom-sub-fix table-td-fix">
									<thead>
										<tr>
											<th><strong>Sales Person</strong></th>
											<th><strong>Reference</strong></th>
											<th><strong>RO Number</strong></th>
											<th><strong>Ship Via</strong></th>
											<th><strong>COD</strong></th>
											<th><strong>Prepaid</strong></th>
											<th><strong>Licence</strong></th>
											<th><strong>Terms</strong></th>
											<th><strong>Date</strong></th>
										</tr>

									</thead>
									<tbody>
										<tr>
											<td width="15%"></td>
											<td width="10%">{contact?.phone ? CommonHelper.phoneWithZero(contact.phoneCode, contact?.phone) : ''}</td>
											<td width="10%">{service?.displayID ? service?.displayID : '--'}</td>
											<td width="10%">--</td>
											<td width="10%">--</td>
											<td width="10%">--</td>
											<td width="10%">{client?.invoiceSettings?.licenseNo}</td>
											<td width="10%">C.O.D</td>
											<td width="10%">{data?.addedDate?.seconds ? moment.unix(data?.addedDate?.seconds).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}</td>


										</tr>


									</tbody>
								</table>

							</div>
						</div>
					</div>



					<table style={previewType === 'print' ? { width: '100%', marginTop: '-15px' } : { width: '100%' }}>
						<thead>
							<tr>
								<td>
									<div className={`${previewType === 'print' ? "page-header-space" : ""}`}></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="invoice-sub-table-list" style={previewType === 'print' ? { minHeight: '400px' } : {}}>

										<table className="form-bottom-sub-fix table-td-fix table-page-break-fix">
											<thead>
												<tr>
													<th width="5%"><strong>QTY</strong></th>
													<th width="15%"><strong>Item No.</strong></th>
													<th width="40%"><strong>Description</strong></th>
													<th width="15%"><strong>EX Price</strong></th>
													<th width="10%"><strong>Disc%</strong></th>
													<th width="15%" className="right-text-invoice"><strong>EX Amount</strong></th>

												</tr>

											</thead>
											<tbody>
												{
													data?.vehicle ? (
														<tr>
															<td className="right-text-invoice"></td>
															<td className="left-text-invoice"></td>
															<td className="left-text-invoice">
																{
																	data?.vehicle?.make ? data?.vehicle?.make.toUpperCase() : ""
																}
																{
																	data?.vehicle?.model ? <>&nbsp;{data?.vehicle?.model.toUpperCase()}</> : ""
																}
																{
																	data?.vehicle?.rego ? <><br />REGO# : {data?.vehicle?.rego.toUpperCase()}</> : ""
																}
																{
																	data?.vehicle?.odometer ? <><br />KM@ : {data?.vehicle?.odometer}</> : ""
																}
																{
																	data?.vehicle?.vinNo ? <><br />VIN : {data?.vehicle?.vinNo}</> : ""
																}
																{/* <br />SERVICE */}
															</td>
															<td className="right-text-invoice"></td>
															<td className="left-text-invoice"></td>
															<td className="right-text-invoice"></td>
														</tr>
													) : (<></>)
												}
												{
													data?.services?.length > 0 ?
														data?.services.map((r, i) => {
															return <tr key={i}>
																<td className="right-text-invoice">{r.quantity > 0 ? r.quantity : ''}</td>
																<td className="left-text-invoice text-area-space">{r.itemNo}</td>
																<td className="left-text-invoice text-area-space">{r.item}</td>
																<td className="right-text-invoice">
																	{
																		r.rate > 0 ?
																			<NumberFormat
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				displayType={'text'}
																				value={_.round(r?.rate, 2).toFixed(2)}
																			/>
																			: ''
																	}

																</td>
																<td className="left-text-invoice">{r?.discount > 0 ? `${r.discount}%` : ''}</td>
																<td className="right-text-invoice">
																	{
																		r.amount > 0 ?
																			<NumberFormat
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				displayType={'text'}
																				value={_.round(r?.amount, 2).toFixed(2)}
																			/> : ''
																	}
																</td>
															</tr>
														})
														: (<></>)
												}
												{
													!_.isEmpty(data?.notes?.trim()) ? (
														<tr>
															<td className="right-text-invoice"></td>
															<td className="left-text-invoice"></td>
															<td className="left-text-invoice text-area-space">
																{data?.notes}
															</td>
															<td className="right-text-invoice"></td>
															<td className="left-text-invoice"></td>
															<td className="right-text-invoice"></td>
														</tr>
													) : (<></>)
												}
												{/* <tr className="blank-info">
															<td ></td>
															<td ></td>
															<td ></td>
															<td ></td>
															<td ></td>
															<td ></td>
														</tr> */}
											</tbody>
										</table>

									</div>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<div className={`${previewType === 'print' ? "page-footer-space" : ""}`}></div>
								</td>
							</tr>
						</tfoot>
					</table>

					<div className={`${previewType === 'print' ? data?.services?.length > 25 ? "page-footer-print" : "page-footer-print-fixed" : "page-footer"}`}>
						<div className="footer">
							{
								client?.invoiceSettings?.terms ? (
									<div className="invoice-sub-terms">
										{client?.invoiceSettings?.terms}
									</div>
								) : (<></>)
							}
							<div className="invoice-sub-footer">
								<div className="invoice-sub-footer-left">
									<div className="invoice-sub-footer-paymenthead">Payment Options</div>
									<div className="invoice-sub-footer-paymentdetails">
										<div className="invoice-sub-footer-payment-left">
											<div className="payment-deposit-head"> Direct Deposit</div>
											<div className="payment-deposit-sub">
												{
													client?.invoiceSettings?.acName ? (
														<>{client?.invoiceSettings?.acName} <br /></>
													) : (<></>)
												}
												{
													client?.invoiceSettings?.bsbNo ? (
														<>BSB {client?.invoiceSettings?.bsbNo} <br /></>
													) : (<></>)
												}
												{
													client?.invoiceSettings?.acNo ? (
														<>Account {client?.invoiceSettings?.acNo} <br /></>
													) : (<></>)
												}
												{
													client?.invoiceSettings?.remittanceTo ? (
														<> Remittance Advice to <br /> {client?.invoiceSettings?.remittanceTo}</>
													) : (<></>)
												}
											</div>
										</div>
										{
											client?.invoiceSettings?.bpayCode ? (
												<div className="invoice-sub-footer-payment-right">
													<div className="payment-service-wrapper">
														<div className="payment-service-logo"><img src={_images.bpay_logo} height="40" /></div>
														<div className="payment-service-info">
															<ul>
																<li>Biller Code: <span>{client?.invoiceSettings?.bpayCode}</span></li>
																<li>Ref: <span>{contact?.phone ? CommonHelper.phoneWithZero(contact.phoneCode, contact?.phone) : ''}</span></li>
															</ul>
														</div>
													</div>
													<div className="payment-service-details">
														<div className="payment-service-details-head">Telephone & Internet Banking. BPAY®</div>
														<div className="payment-service-details-text">Contact your bank or financial institution to make this payment from your cheque, savings, debit, credit card or transaction account. More info: www.bpay.com.au</div>
													</div>
												</div>
											) : (<></>)
										}

									</div>
									<div className="pay-later-wrap">
										<div className="pay-later-left">Service Now Pay Later</div>
										<div className="pay-later-right">
											<ul>
												{/* <li><img src={_images.openpay_logo} height="40" /></li> */}
												<li><img src={_images.afterpay} height="40" /></li>
												<li><img src={_images.humm} height="40" /></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="invoice-sub-footer-right">
									<div className="invoice-sub-footer-table">

										<table className="form-bottom-sub-fix table-td-fix">
											<tbody>
												<tr>
													<td width="50%" >Sale Amount:</td>
													<td width="50%">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.subTotalExGSTDiscounts > 0 ? _.round(data?.subTotalExGSTDiscounts, 2).toFixed(2) : 0.00}
														/>
													</td>
												</tr>
												{
													data?.totalDiscount > 0 ? (
														<tr>
															<td width="50%" >Discount:</td>
															<td width="50%">
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={data?.totalDiscount > 0 ? _.round(data?.totalDiscount, 2).toFixed(2) : _.round(0, 2).toFixed(2)}
																/>
															</td>
														</tr>
													) : (<></>)
												}

												<tr>
													<td width="50%">Freight:</td>
													<td width="50%">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.totalFreight > 0 ? _.round(data?.totalFreight, 2).toFixed(2) : 0.00}
														/>
													</td>

												</tr>

												<tr>
													<td width="50%">GST:</td>
													<td width="50%">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.gstPayable > 0 ? _.round(data?.gstPayable, 2).toFixed(2) : 0.00}
														/>
													</td>
												</tr>

												<tr>
													<td width="50%">Total:</td>
													<td width="50%">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.totalIncGST > 0 ? _.round(data?.totalIncGST, 2).toFixed(2) : 0.00}
														/>
													</td>

												</tr>

												<tr>
													<td width="50%">Paid Today:</td>
													<td width="50%">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.totalDeposit > 0 ? _.round(data?.totalDeposit, 2).toFixed(2) : _.round(0, 2).toFixed(2)}
														/>
													</td>

												</tr>
												<tr>
													<td width="50%" className="due">Balance Due:</td>
													<td width="50%" className="due">
														<NumberFormat
															thousandSeparator={true}
															prefix={currencySymbol}
															displayType={'text'}
															value={data?.payableOnDelivery > 0 ? _.round(data?.payableOnDelivery, 2).toFixed(2) : 0.00}
														/>
													</td>

												</tr>
											</tbody>
										</table>

									</div>


								</div>

							</div>
							{
								client?.invoiceSettings?.additionalInfo ? (
									<div className="invoice-sub-footer-additional">
										{client?.invoiceSettings?.additionalInfo}
									</div>
								) : (<></>)
							}
						</div>
					</div>

				</div>
				<div className="clear"></div>
			</div>
		)
	}

	return (<>
		{
			loader ?
				<div className='loader-center-screen'>
					<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
				</div>
				: (<>
					{
						_.isEmpty(_type) || _type !== 'print' ? (
							<>
								<div className="dynamicreport-stage-container">
									<div className="dynamicreport-stage-wraper">
										<div className="dynamicreport-stage-loop active">
											<div className="dynamicreport-stage-loop-box">
												<div className="dynamicreport-stage-count">1</div>
												<div className="dynamicreport-stage-title-wrap"> <span className="dynamicreport-stage-title">Invoice Details</span> </div>
											</div>
										</div>
										<div className="dynamicreport-stage-seperator">
											<svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
												<polyline points="9 18 15 12 9 6"></polyline>
											</svg>
										</div>
										<div className="dynamicreport-stage-loop active">
											<div className="dynamicreport-stage-loop-box">
												<div className="dynamicreport-stage-count">2</div>
												<div className="dynamicreport-stage-title-wrap"> <span className="dynamicreport-stage-title">Preview </span> </div>
											</div>
										</div>
									</div>
								</div>
								<div className="button-wrap">
									<div id="updated-date" style={{ float: "left", padding: '10px' }}>
										{
											data?.modifiedDate?.seconds ? (
												<div>Updated On : {moment.unix(data?.modifiedDate?.seconds).format('DD/MM/YYYY hh:mm A')}</div>
											) : (<></>)
										}
									</div>
									<a onClick={e => {
										e.preventDefault()
										window.open(fileLink, '_blank');
									}} id={'fileLinkAnchor'}></a>
									<a href="#" className="print-btn" onClick={(e) => {
										e.preventDefault();
										//document.getElementById("print-iframe").contentWindow.print();
										//window.open(`${window.location.origin}${window.location.pathname}?view=print&type=print`, '_blank');

										window.print();
									}} ><img src={_images.printWhite} width="12" height="11" /> Print</a>
									<a className="download-btn" onClick={() => downloadFile(false)}>
										{
											btnLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
												: (<img src={_images.downloadBlack} width="12" height="11" />)
										} Download</a>
									<a className="save-btn" onClick={() => {
										if (!fileOnceDownloaded)
											downloadFile(true)
									}}>{
											saveLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
												: (<img src={_images.saveWhite} width="12" height="11" />)
										} {fileOnceDownloaded ? 'Saved to Files' : 'Save to Files'}</a>
									<a
										className={`download-btn ${emailloader ? 'form-disable' : ''}`}
										onClick={(e) => { e.preventDefault(); setShowEmail(true); }}>
										{
											emailloader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
												: (<i className="ico icon-email-log mr-1" style={{ fontSize: '10px' }}></i>)
										}

										Send Email</a>
									{
										_vbtn === 'y' ? (
											<a href="#" className="update-btn" onClick={e => {
												e.preventDefault();
												const url = `/service/invoice/${data.serviceID}?type=edit&previewID=${data.documentID}`;
												_useHistory.push(url)
												_useHistory.go()
											}}>Back</a>
										) : (<></>)
									}
								</div>
							</>
						) : (<></>)
					}

					<ContentDOM
						classname={'no-print-content'}
						id={'print-section'}
						previewType={_type}
					/>

					<ContentDOM
						classname={'print-content'}
						id={'print-section'}
						previewType={"print"}
					/>
				</>)
		}

		<PopUpModal show={showEmail}>
			<SendMail
				show={showEmail}
				clientID={data?.clientID}
				serviceid={props.serviceid}
				contact={contact}
				service={service}
				client={client}
				dealersettings={{ 'client': { ...client } }}
				clientUsers={clientUsers}
				handleClose={handleEmailClose}
			/>
		</PopUpModal>
		{/* <iframe id="print-iframe" src={`${window.location.origin}${window.location.pathname}?view=print&type=print`} style={{ height: '100px', width: '100px', position: 'absolute', display: 'none' }}></iframe> */}
	</>)
}

export default ServiceInvoice;