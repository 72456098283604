import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import CalendarFilter from './calendarFilter';
import PageNotFound from "../../components/pagenotFound";
import { defaultfleetAssignTypes, defaultfleetCategory, defaultfleetStatuses, userLevel } from '../../services/enum';


const CalendarBookings = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 230) })
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [bookingDate, setBookingDate] = useState(moment().format('YYYY-MM-DD'))
    const [monthdata, setMonthData] = useState([])
    const [dateState, setDateState] = useState(new Date())
    const [activeStartDate, setActiveStartDate] = useState()
    const [objFilter, setObjFilter] = useState({});
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
        date: moment().format('YYYY-MM-DD')
    })
    const _limit = 30;
    const { dealersettings } = props;
    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const accessToFleetHistory = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToFleetHistory;
    let fleetProStatus = props.dealersettings?.client?.fleetPro?.fleetProStatus?.filter(a => a.scheduler === true || a.booking === true) || [];
    fleetProStatus = [...fleetProStatus, { color: '#049b8c', name: 'Assigned', value: 'assigned', }]

    const changeDate = (e) => {
        setDateState(e)
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 230)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubrefMonthFleetCalendarData && window.unsubrefMonthFleetCalendarData.unsubscribe();
        }

    }, [])

    useEffect(() => {
        if (props.dealersettings.level === userLevel.INDIVIDUAL) return
        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {

            let refEnquiryData = window.firebase.firestore().collection('fleetPro')
                .where('isDeleted', '==', false)
                .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))

            collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));

            let refeventData = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            collectionPromise.push(refeventData ? collection(refeventData) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubrefMonthFleetCalendarData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    var _list = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach((rec) => {
                        let _data = rec.data();
                        if (rec.ref.path.includes('fleetPro/')) {
                            _list.push(...(loadData({ ..._data, documentID: rec.id })))
                        }
                        else {
                            _list.push(mapDynamicData({
                                ..._data,
                                origin: 'fleetProAssign',
                                value: 'assigned',
                                regionID: _data?.fleetPro?.regionID || null,
                                subregionID: _data?.fleetPro?.subregionID || null,
                                groupID: _data?.fleetPro?.groupID || null,
                                endDate: _data?.endDate || _data?.startDate
                            }, _data.fleetPro));
                        }
                    })

                    _list.forEach((bookingData) => {
                        for (var m = moment.unix(bookingData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(bookingData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                            _fleetpro.push({
                                ...bookingData,
                                'id': bookingData.documentID,
                                'bookingDate': m.format('YYYY-MM-DD'),
                                'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
                            })
                        }
                    })
                    //console.log(_list, _fleetpro)

                    setLoader(false)
                    setMonthData(_fleetpro);
                });
        }
        else {
            setLoader(false)
        }
    }, [dateRange])

    // useEffect(() => {
    //     if (props.dealersettings.level !== 'individual') return
    //     var collectionPromise = [];
    //     let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
    //     let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
    //     for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
    //         let refeventData = window.firebase.firestore().collection('fleetProAssign')
    //             .where('isDeleted', '==', false)
    //             .where(`clientID`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
    //             .where('startDate', '>=', dateRange.startDate)
    //             .where('startDate', '<=', dateRange.endDate)
    //             .orderBy('startDate', 'desc')

    //         collectionPromise.push(refeventData ? collection(refeventData) : of([]));
    //     }
    //     if (collectionPromise.length > 0) {
    //         window.unsubrefMonthFleetData = combineLatest(collectionPromise)
    //             .subscribe(allDocs => {
    //                 var querySnapshot = [];
    //                 var _fleetpro = []
    //                 allDocs.forEach(doc => {
    //                     querySnapshot.push(...doc);
    //                 });
    //                 querySnapshot.forEach((rec) => {
    //                     let _data = rec.data();
    //                     let bookingData = mapDynamicData({
    //                         ..._data,
    //                         origin: 'fleetProAssign',
    //                         value: 'assigned',
    //                         regionID: _data?.fleetPro?.regionID || null,
    //                         subregionID: _data?.fleetPro?.subregionID || null,
    //                         groupID: _data?.fleetPro?.groupID || null,
    //                         endDate: _data?.endDate || _data?.startDate
    //                     }, _data.fleetPro);

    //                     for (var m = moment.unix(bookingData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(bookingData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
    //                         _fleetpro.push({
    //                             ...bookingData,
    //                             'documentID': rec.id,
    //                             'id': rec.id,
    //                             'bookingDate': m.format('YYYY-MM-DD'),
    //                             'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
    //                         })
    //                     }
    //                 })
    //                 setLoader(false)
    //                 setMonthData(_fleetpro);
    //             });
    //     }
    //     else {
    //         setLoader(false)
    //     }
    // }, [dateRange])

    const loadData = (doc) => {
        let _logs = [];
        fleetProStatus.filter(a => props.dealersettings.level === 'individual' ? a.value === 'operational' : true).forEach(rec => {
            if (doc?.statusDaterange?.[rec.value]?.startDate && doc?.statusDaterange?.[rec.value]?.endDate) {
                _logs.push(mapDynamicData({
                    origin: 'fleetPro',
                    documentID: window.firebase.firestore().collection('fleetPro').doc().id,
                    fleetProID: doc.documentID,
                    fleetPro: doc,
                    value: rec.value,
                    keywords: doc.keywords,
                    startDate: doc.statusDaterange[rec.value].startDate,
                    endDate: doc.statusDaterange[rec.value].endDate,
                    regionID: doc?.regionID || null,
                    subregionID: doc?.subregionID || null,
                    groupID: doc?.groupID || null,
                }, doc))
            }
        });
        if (!_.isEmpty(doc?.statusBooking)) {
            doc.statusBooking.forEach(rec => {
                if (rec?.startDate && rec?.endDate) {
                    _logs.push(mapDynamicData({
                        origin: 'fleetPro',
                        documentID: window.firebase.firestore().collection('fleetPro').doc().id,
                        fleetProID: doc.documentID,
                        fleetPro: doc,
                        value: rec.status,
                        keywords: doc.keywords,
                        startDate: rec.startDate,
                        endDate: rec.endDate,
                        regionID: doc?.regionID || null,
                        subregionID: doc?.subregionID || null,
                        groupID: doc?.groupID || null,
                    }, doc))
                }
            });
        }
        if (doc?.defleetDate?.seconds) {
            let _rec = (_.find(fleetProStatus, { value: 'defleeted' }) || _.find(defaultfleetStatuses, { value: 'defleeted' }))
            _logs.push(mapDynamicData({
                origin: 'fleetPro',
                documentID: window.firebase.firestore().collection('fleetPro').doc().id,
                fleetProID: doc.documentID,
                fleetPro: doc,
                value: _rec?.value,
                keywords: doc.keywords,
                startDate: doc.defleetDate,
                endDate: doc.defleetDate,
                regionID: doc?.regionID || null,
                subregionID: doc?.subregionID || null,
                groupID: doc?.groupID || null,
            }, doc))
        }
        return _logs
    }

    const mapDynamicData = (_fields, doc) => {

        let __enquiryOptionsDF = props.dealersettings.client.fleetPro.fleetOptionsDF
        if (!_.isEmpty(doc?.dynamicFields) && __enquiryOptionsDF) {
            let _dynamicFields = doc.dynamicFields
            __enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (_.isNumber(_dynamicFields[rec.value]) || _.isBoolean(_dynamicFields[rec.value]) || !_.isEmpty(_dynamicFields[rec.value])) {
                    if (rec.type === 'toggle') {
                        _fields[rec.value] = _dynamicFields[rec.value] === true ? 'Yes' : 'No';
                    }
                    else if (rec.type === 'date') {
                        _dynamicFields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_dynamicFields[rec.value]._seconds)._d) : _dynamicFields[rec.value] : ''
                        _fields[rec.value] = _dynamicFields[rec.value] ? moment.unix(_dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                    }
                    else if (rec.type === 'multiselect') {
                        _fields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value].join(', ') : ''
                    }
                    else {
                        _fields[rec.value] = _dynamicFields[rec.value]
                    }
                }

            })
        }

        let _clientNames = [];
        if (_fields.clientID) _clientNames.push(_.find(props.dealersettings.clients, { id: _fields.clientID })?.name || '')
        else if (doc.groupID) _clientNames.push(CommonHelper.getNameByCode(props.dealersettings.groups, doc.groupID, ''))
        else if (doc.subregionID) _clientNames.push(CommonHelper.getNameByCode(props.dealersettings.subregions, doc.subregionID, ''))
        else if (doc.regionID) _clientNames.push(CommonHelper.getNameByCode(props.dealersettings.regions, doc.regionID, ''))
        _fields.dealer = _clientNames ? _clientNames.join(', ') : '';

        return _fields;
    }

    const filterData = useMemo(() => monthdata.filter(
        ({ regionID, subregionID, groupID, clientID, keywords }) =>
            (searchText ? keywords?.includes(_.trim(searchText).toLowerCase()) : true) &&
            (objFilter?.region?.length ? objFilter.region.includes(regionID) : true) &&
            (objFilter?.subregion?.length ? _.isEmpty(subregionID) || objFilter.subregion.includes(subregionID) : true) &&
            (objFilter?.group?.length ? _.isEmpty(groupID) || objFilter.group.includes(groupID) : true) &&
            (objFilter?.dealer?.length ? objFilter.dealer.includes(clientID) : true)
    ), [monthdata, searchText, objFilter])

    const enquiryOptionsDF = props.dealersettings.client.fleetPro.fleetOptionsDF;
    let dynamicEnquiryDetailList = Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active)) ?
        Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active), function (item) { return item.value; }).join(",").split(',') :
        []));

    const divStyle = {
        height: windowSize.windowHeight,
        overflow: 'auto',
        position: "relative"
    };
    return (
        <>
            {
                dealersettings.level === userLevel.INDIVIDUAL
                    ?
                    <><PageNotFound /></>
                    :
                    <>
                        <div className="middle-wrapper">
                            <div className="filter-panel">
                                <div className="filter-search">
                                    <div className="input-group ">
                                        {/* <input type="text" className="form-control" placeholder="Search" /> */}
                                        <input type="text" placeholder="Search" className="form-control" value={searchText} onChange={(e) => {
                                            setSearchText(e?.target?.value)
                                        }} />
                                        {
                                            searchText && (<div className="input-group-append input-search-clear">
                                                <a href="#" className="input-search-clear-icon" onClick={(e) => {
                                                    e.preventDefault();
                                                    setSearchText('')
                                                }}><i className="ico icon-remove"></i></a>
                                                {/* <button className="btn btn-default" type="button"> <i className="ico icon-search-fill"></i> </button> */}
                                            </div>)
                                        }

                                    </div>
                                </div>

                                <div className="float-right">
                                    <CalendarFilter
                                        {...props}
                                        calendarFilter={setObjFilter}
                                    ></CalendarFilter>
                                </div>

                            </div>

                            <div className="fleetpro-panel">

                                <div className="calendar-fleetpro-panel">
                                    <div className="calendar-fleetpro-container">
                                        <div className="calendar-fleetpro-wrapper">

                                            <div className="calendar-fleetpro-right-panel">
                                                {
                                                    loader
                                                        ?
                                                        <>
                                                            <div className="dashboard-widget-loader h-100">
                                                                <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <div className="calendar-fleetpro-right-loop-wrap">

                                                    {
                                                        fleetProStatus.map((data, index) => {
                                                            return <div key={index} className="calendar-fleetpro-right-loop">

                                                                {(() => {

                                                                    let _qList = filterData.filter(m => (moment(dateRange.date).format('MMM YYYY') === moment(bookingDate).format('MMM YYYY')) ? (m.value === data.value && m.bookingDate === bookingDate) : false)//
                                                                    return <>
                                                                        <div className="calendar-fleetpro-right-loop-head">
                                                                            {data.name}
                                                                            <span className="badge badge-pill badge-calendar-fleetpro ml-1">{_qList.length}</span>
                                                                        </div>

                                                                        {
                                                                            !_.isEmpty(_qList)
                                                                                ?
                                                                                <>
                                                                                    <ul style={divStyle}>
                                                                                        {
                                                                                            _qList.map((log, index) => {
                                                                                                return <li key={index} >
                                                                                                    <a className="calendar-fleetpro-notify-item d-flex" href="#" onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        if (accessToFleetHistory && log.fleetProID)
                                                                                                            props.history.push("/fleetpro/details/" + log.fleetProID);
                                                                                                    }}>
                                                                                                        <div className="mr-3">
                                                                                                            <div className="calendar-fleetpro-notify-icon-circle" style={{
                                                                                                                border: `1px solid ${data.color}`,
                                                                                                                background: '#f6f9fc',
                                                                                                                color: `${data.color}`
                                                                                                            }} ><i className="ico icon-Stock"></i></div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span className="calendar-fleetpro-notify-header">{log?.fleetPro?.make || ''} {log?.fleetPro?.model || ''}</span>
                                                                                                            {
                                                                                                                log.name ? (
                                                                                                                    <span className="calendar-fleetpro-notify-info"><i className="ico icon-Contacts"></i> {log.name} </span>
                                                                                                                ) : (<></>)
                                                                                                            }
                                                                                                            {
                                                                                                                log.dealer ? (
                                                                                                                    <span className="calendar-fleetpro-notify-info"><i className="ico icon-group mb-1"></i> <span className='blue-color'>{log.dealer}</span> </span>
                                                                                                                ) : (<></>)
                                                                                                            }

                                                                                                            <div className="dashboard-testdrive-activity-column">
                                                                                                                {log?.fleetPro?.regNo && (<>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{log?.fleetPro?.regNo}</div></>)}
                                                                                                                {log?.fleetPro?.vinNo && (<>{(log?.fleetPro?.regNo) && (<span className="veh-details-seperator">|</span>)}VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{log?.fleetPro?.vinNo}</div></>)}
                                                                                                                {log?.fleetPro?.extColor && (<>{(log?.fleetPro?.regNo || log?.fleetPro?.vinNo) && (<span className="veh-details-seperator">|</span>)}&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{log?.fleetPro?.extColor}</div></>)}
                                                                                                                {log?.fleetPro?.year && (<>{(log?.fleetPro?.regNo || log?.fleetPro?.vinNo || log?.fleetPro?.extColor) && (<span className="veh-details-seperator">|</span>)}&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{log?.fleetPro?.year}</div></>)}

                                                                                                            </div>

                                                                                                            {
                                                                                                                dynamicEnquiryDetailList.map((rec, index) => {
                                                                                                                    if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0])) &&
                                                                                                                        (_.isNumber(log[rec]) || _.isBoolean(log[rec]) || !_.isEmpty(log[rec])))
                                                                                                                        return <span key={index} className="calendar-fleetpro-notify-info">
                                                                                                                            {(() => {
                                                                                                                                let info = enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                                                                if (info.type === 'price')
                                                                                                                                    return <><strong><Translate text={info.name} />:&nbsp;</strong>
                                                                                                                                        {log[rec] ? <NumberFormat style={{ fontSize: 12 }} value={log[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}
                                                                                                                                    </>
                                                                                                                                else
                                                                                                                                    return <><strong><Translate text={info.name} />:&nbsp;</strong>{log[rec] ? log[rec] : '--'}</>

                                                                                                                            })()}
                                                                                                                        </span>
                                                                                                                })
                                                                                                            }
                                                                                                            {/* <span className="calendar-fleetpro-notify-info"> SONO: 123654</span> */}
                                                                                                            {/* <div className="calendar-fleetpro-notify-date">07/11/2023 12:25 PM</div> */}
                                                                                                            {log?.assignType && (<div className="flow-notes-sub">
                                                                                                                {/* {
                                                                                                        _.find(defaultfleetCategory, { value: log?.fleetPro?.category }) ? (
                                                                                                            <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetCategory, { value: log?.fleetPro?.category }).name}</div>
                                                                                                        ) : (<></>)
                                                                                                    } */}
                                                                                                                {
                                                                                                                    _.find(defaultfleetAssignTypes, { value: log?.assignType }) ? (
                                                                                                                        <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetAssignTypes, { value: log?.assignType }).name}</div>
                                                                                                                    ) : (<></>)
                                                                                                                }
                                                                                                            </div>)}
                                                                                                        </div>
                                                                                                    </a>

                                                                                                </li>
                                                                                            })
                                                                                        }

                                                                                    </ul>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="spinner-loader h-100 p-5">
                                                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                                            {/* <div className="no-cafe-img">
                                                                                    <i className="ico icon-Contacts"></i> 
                                                                                    </div> */}
                                                                                            <div className="no-data-img"> <img src={_images.nodata} width="50" height="50" alt="" /></div>
                                                                                            <div className="no-data-txt mt-2"><Translate text={'No Data'} /></div>
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </>
                                                                })()}


                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>


                                            <div className="calendar-fleetpro-left-panel">
                                                <div className="calendar-fleetpro-filter-block-head">Calendar</div>

                                                <div className="calendar-fleetpro-side-view">
                                                    <Calendar
                                                        onChange={changeDate}
                                                        value={dateState}
                                                        activeStartDate={activeStartDate}
                                                        className={'dashboard-calendar-mini fleet-calendar-mini'}
                                                        onClickDay={(value, event) => {
                                                            setBookingDate(moment(value).format('YYYY-MM-DD'))
                                                        }}
                                                        tileClassName={'calendar-mini-day'}
                                                        tileContent={({ date }) => {
                                                            if (filterData.some(a => a.bookingDate === moment(date).format('YYYY-MM-DD'))) {
                                                                return <span className="dots">
                                                                    <span className="dot"></span>
                                                                </span>;
                                                            }
                                                        }}
                                                        view={'month'}
                                                        prev2Label={null}
                                                        next2Label={null}
                                                        onActiveStartDateChange={({ activeStartDate }) => {
                                                            if (moment(activeStartDate).format('MMM YYYY') !== moment().format('MMM YYYY')) {
                                                                setDateState(activeStartDate)
                                                                setBookingDate(moment(activeStartDate).format('YYYY-MM-DD'))
                                                            }
                                                            else {
                                                                setDateState(new Date())
                                                                setBookingDate(moment().format('YYYY-MM-DD'))
                                                            }

                                                            setActiveStartDate()
                                                            setLoader(true)
                                                            setDateRange({
                                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).startOf('month').add(-7, 'days').toDate()),
                                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).endOf('month').add(7, 'days').toDate()),
                                                                date: moment(activeStartDate).format('YYYY-MM-DD')
                                                            })
                                                        }}
                                                        navigationLabel={({ date }) => <div className='dashboard-calendar-mini-header'>{`${moment(date).format('MMM YYYY')}`}</div>}
                                                    />
                                                </div>
                                                <div className="calendar-fleetpro-side-view mt-3">
                                                    <button className="btn btn-sm btn-primary w-100" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDateState(new Date())
                                                        setBookingDate(moment().format('YYYY-MM-DD'))
                                                        if (!dateRange?.date || (moment(dateRange.date).format('MMM YYYY') !== moment().format('MMM YYYY'))) {
                                                            //console.log(moment(dateRange.date).format('MMM YYYY'), moment().format('MMM YYYY'))
                                                            setLoader(true)
                                                            setActiveStartDate(new Date())// force calendar to current month
                                                            setDateRange({
                                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
                                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
                                                                date: moment().format('YYYY-MM-DD')
                                                            })
                                                        }


                                                    }}>

                                                        <Translate text={'today'} />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </>
            }


        </>
    );
}

export default CalendarBookings;