import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';
import moment from 'moment';

export default (params) => {
    const [value, setValue] = useState();

    useEffect(() => {
        let _value = params?.value || params?.valueFormatted || (params.isFilterRenderer ? '(Blanks)' : '');
        if (params?.value === 0) {
            _value = null
        } else if (params?.colDef?.html && _.find(params?.context?.settings?.[params?.colDef?.settingsField], { searchlabel: params.value })?.name) {
            _value = _.find(params?.context?.settings?.[params?.colDef?.settingsField], { searchlabel: params.value })?.name
        } else if (params?.colDef?.dataType === 'price' && params?.value) {
            _value = <NumberFormat decimalScale={2} value={params?.value} fixedDecimalScale={true} displayType='text' thousandSeparator={true} prefix={params?.context?.currencySymbol} />
        } else if (params?.colDef?.suffix && params?.value) {
            _value = `${params?.value}${params?.colDef?.suffix}`
        } else if (params?.colDef?.contact && params?.value) {
            _value = <><i className={`ico icon-${params?.data?.businessContact && !_.isEmpty(params?.data?.contact?.company) ? 'company' : 'Contacts'} mr-1`}></i>{params?.value}</>
        } else if (params?.colDef?.dataType === 'button' && params?.colDef?.buttons?.length) {
            _value = <div>{
                params.colDef.buttons.map((r, i) => {
                    return <button className={r.className} key={i} onClick={() => params?.context?.buttonActions?.[r.action] && params?.context?.buttonActions?.[r.action](params?.data)}>
                        {r.icon ? <i
                            className={r.icon}
                            aria-hidden='true'
                            style={{ paddingTop: '0px', marginRight: '4px' }}
                        ></i> : (<></>)}
                        {r.name}
                    </button>
                })
            }</div>
        }
        if (!params.isFilterRenderer && params?.data?.fieldComments?.[params?.column?.colId]) {
            _value = (<><div className="float-right cursor-pointer" onClick={(e) => {
                e.preventDefault();
                params?.context?.onMsgIconClicked && params.context.onMsgIconClicked(params)
            }}><span className="comment-icon-linear"><i className="ico icon-activity-chat"></i></span></div>{_value}</>)
        }
        if (!params.isFilterRenderer && params?.data?.fieldTags?.[params?.column?.colId]) {
            _value = (<><div className="tag-cell-left" style={{ background: params?.data?.fieldTags?.[params?.column?.colId] }}></div>{_value}</>)
        }
        //DATE FILTER
        //if (params.isFilterRenderer && params?.value && params?.colDef?.dataType === 'date' && params?.value !== '(Select All)') {
        //    _value = moment(params.value, params?.context?.settings?.dateFormat || 'DD/MM/YYYY').format('MMMM YYYY')
        //}
        setValue(_value);
    }, [params?.value]);

    return value;
};