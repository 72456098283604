import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Modal, Accordion, Card } from 'react-bootstrap';
import toast from 'toasted-notes';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import { default as _images } from '../../../images';
import moment from 'moment';
import _ from 'lodash';

import {
	ReactSelect,
	AutoComplete,
	ImageViewer, PopUpModal,
	ImageCropHook,
} from '../../../components';

import Terms from '../../common/terms';
import AddVehicles from '../addVehicles';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';

import {
	residenceStatusOptions,
	residenceYearOptions,
	residenceTypeOptions,
	employmentPeriodOptions,
	employmentStatusOptions,
	frequency,
	yesOrNo,
} from '../constants';

import {
	livingSituationVM,
	employmentDetailsVM,
	financialDetailsVM,
	cropShowVM
} from '../viewModel';
import AddProvider from '../addProvider';

const AddFinance = ({
	show,
	title,
	type,
	handleClose,
	contact,
	requirement,
	requirements,
	signature: { termsSignature, termsAndConditions },
	dealersettings,
	enquiryBasicVM,
	currencySymbol,
	onFinanceCollectionUpdate,
}) => {
	const [imageViewer, setImgViewer] = useState({ show: false, images: null });
	const [cropShow, setCropShow] = useState(cropShowVM);
	const [displayTerms, setDisplayTerms] = useState(false);
	const [errors, setErrors] = useState({});
	const [showAddVehicles, setShowAddVehicles] = useState(false);
	const [addProviderModal, setAddProviderModal] = useState(false);
	const [activeAccordion, setActiveAccordion] = useState('0');
	const [activeTab, setActiveTab] = useState('personalinfo')
	const [fetchLoading, setFetchLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [loader, setLoader] = useState(false);
	const [financeDetails, setFinanceDetails] = useState(null);
	const [livingSituation, setLivingSituation] = useState(livingSituationVM);
	const [employmentDetails, setEmploymentDetails] = useState(employmentDetailsVM);
	const [financialDetails, setFinancialDetails] = useState(financialDetailsVM);
	const [customer, setCustomer] = useState();

	const [Requirement, setRequirement] = useState([]);
	const [financeInfoSavedOnAdd, setFinanceInfoSavedOnAdd] = useState(false);
	const [primaryRequirement, setPrimaryRequirement] = useState(requirement.documentID ? requirement : requirements[requirements.length - 1]);

	useEffect(() => {
		// console.log(`signarue`, { termsSignature, termsAndConditions })

		const setttings = dealersettings && dealersettings.client ? dealersettings.client.settings : null;

		if (type === 'add') {

			setRequirement([
				{
					Id: 0,
					Make: returnReqVal(primaryRequirement, 'make'),
					Model: returnReqVal(primaryRequirement, 'model'),
					Year: returnReqVal(primaryRequirement, 'year'),
					SaleType: returnReqVal(primaryRequirement, 'saleType'),
					CarPrice: returnReqVal(primaryRequirement, 'price'),
					FinanceAmount: returnReqVal(primaryRequirement, 'financeAmount'),
					Status: returnReqVal(primaryRequirement, 'status'),
					LeadStatus: returnReqVal(primaryRequirement, 'leadStatus'),
					ImageList: [],
					ImagesBase64: [],
					AMSProId: primaryRequirement.documentID,
					DeletedIds: []
				},
			])
			setCustomer({
				Id: 0,
				Title: contact.title ? CommonHelper.getNameByValue(setttings ? setttings.titles : [], contact.title, '') : null,
				FirstName: contact.firstName ? contact.firstName : null,
				MiddleName: contact.middleName ? contact.middleName : null,
				LastName: contact.lastName ? contact.lastName : null,
				Phone: contact.phone ? contact.phone : null,
				Email: contact.email ? contact.email : null,
				DOB: contact.dob ? moment(contact.dob)._d.toISOString() : null,
				Gender: contact.gender ? CommonHelper.getNameByValue(setttings ? setttings.genders : [], contact.gender, '') : null,
				Address: contact.address ? contact.address : null,
				Language: contact.language ? contact.language : null,
				LicenseNo: contact.licenseNo ? contact.licenseNo : null,
				LicenseState: contact.licenseState ? contact.licenseState : null,
				LicenseExpiry: contact.licenseExpiry
					? moment(contact.licenseExpiry)._d.toISOString()
					: null,
				MaritalStatus: contact.maritalStatus ? contact.maritalStatus : null,
				LicenseType: contact.licenseType ? CommonHelper.getNameByValue(setttings ? setttings.licenseType : [], contact.licenseType, '') : null,
				Dependents: contact.dependents ? contact.dependents : null,
				HomePhone: _.isArray(contact.phones) && contact.phones.some(a => a.name === 'Home') ? contact.phones.filter(a => a.name === 'Home')[0].value : null,
				WorkPhone: _.isArray(contact.phones) && contact.phones.some(a => a.name === 'Work') ? contact.phones.filter(a => a.name === 'Work')[0].value : null,
				LicenseImageURL: contact.licenseFrontURL ? contact.licenseFrontURL : null,
				LicenseImageBackURL: contact.licenseBackURL ? contact.licenseBackURL : null,
				SecondaryCardFront: contact.secondaryLicenseFrontUrl ? contact.secondaryLicenseFrontUrl : null,
				SecondaryCardBack: contact.secondaryLicenseBackUrl ? contact.secondaryLicenseBackUrl : null,
				DealerUrl: null,
				Bas64LicenseImage: null,
				Bas64LicenseImageBack: null,
				Bas64SecondaryCardFront: null,
				Bas64SecondaryCardBack: null,
				InquiryId: 0,
				AMSProInqId: enquiryBasicVM.documentID,
				AMSProCustomerId: contact.documentID,
				DealerCode: dealersettings.client.id,
			})
			if (contact.address)
				setLivingSituation({ ...livingSituationVM, ResidenceAddress: contact.address })
			setFetchLoading(false);
		}
	}, [primaryRequirement, type])


	useEffect(() => {
		if (type === 'edit') {
			// console.log('running edit effect on ' + type)
			const fetchProviders = async () => {
				const ObjectToSend = {
					AMSProInqId: enquiryBasicVM.documentID,
					DealerCode: dealersettings.client.id,
					AMSProCustomerId: contact.documentID,
				};

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(ObjectToSend),
				};

				const response = await fetch(
					dealersettings.financeURL + 'api/AMSProFinance/Action/FinanceDetails',
					requestOptions,
				);

				const respjson = await response.json();
				//	console.log('respjson', respjson);

				if (!respjson.data) {
					return
				}

				let RequirementArray = respjson.data.Requirement.map(RequirementObj => {
					RequirementObj.ImagesBase64 = []
					return RequirementObj
				})


				setLivingSituation(respjson.data.LivingSituation);
				setEmploymentDetails(respjson.data.EmploymentDetails);
				setFinancialDetails(respjson.data.FinanceDetails);
				setCustomer(respjson.data.Customer);
				setRequirement(RequirementArray);
				setFinanceDetails(respjson.data);
				setFetchLoading(false);
			};

			fetchProviders();
		}
	}, [contact.documentID, dealersettings.client.id, enquiryBasicVM.documentID, type]);



	const bindAddress = (place, obj, key) => {
		let state = {};
		let componentForm = {
			street_number: 'short_name',
			route: 'long_name',
			sublocality_level_1: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			country: 'short_name',
			//administrative_area_level_1: 'long_name',
			//country: 'long_name',
			postal_code: 'short_name',
		};

		if (place.formatted_address !== null)
			state['address'] = place.formatted_address;

		for (var i = 0; i < place.address_components.length; i++) {
			var addressType = place.address_components[i].types[0];
			if (componentForm[addressType]) {
				var val = place.address_components[i][componentForm[addressType]];

				if (addressType === 'postal_code') state['areaCode'] = val;

				if (addressType === 'administrative_area_level_1') state['state'] = val;

				if (addressType === 'country') state['country'] = val;
			}
		}
		if (obj === 'livingSituation') {
			setLivingSituation({
				...livingSituation,
				[key]: state.address,
			});
		} else if (obj === 'employmentDetails') {
			setEmploymentDetails({
				...employmentDetails,
				[key]: state.address,
			});
		}
	};

	const handleSave = async () => {
		if (loader === true) {
			return
		}

		setLoader(true)
		let isError = false;

		Requirement.forEach(RequirementObj => {
			if (!RequirementObj.CarPrice && isError === false) {
				document.querySelector("[data-rb-event-key='vehicleinfo']").click()
				let accordionToggle = document.querySelector(`[id='${RequirementObj.AMSProId}-toggle']`)
				let accordionCollapse = document.querySelector(`[id='${RequirementObj.AMSProId}-collapse']`)
				if (!accordionCollapse.classList.contains('show')) {
					accordionToggle.click()
				}
				toast.notify(`Please enter price of the Vehicle ${RequirementObj.Make} ${RequirementObj.Model}`, {
					duration: 2000,
				});
				isError = true
				let errorsObj = errors
				errorsObj[`${RequirementObj.AMSProId}-CarPrice`] = 'input_error'
				setErrors({ ...errorsObj })
				setActiveTab('vehicleinfo')
				return;
			}

			if (!RequirementObj.FinanceAmount && isError === false) {
				document.querySelector("[data-rb-event-key='vehicleinfo']").click()
				// if its already open then do not click
				let accordionToggle = document.querySelector(`[id='${RequirementObj.AMSProId}-toggle']`)
				let accordionCollapse = document.querySelector(`[id='${RequirementObj.AMSProId}-collapse']`)
				if (!accordionCollapse.classList.contains('show')) {
					accordionToggle.click()
				}
				toast.notify(`Please enter finance amount of ${RequirementObj.Make} ${RequirementObj.Model}`, {
					duration: 2000,
				});
				isError = true
				let errorsObj = errors
				errorsObj[`${RequirementObj.AMSProId}-FinanceAmount`] = 'input_error'
				setErrors({ ...errorsObj })
				setActiveTab('vehicleinfo')
				return;
			}
		})

		if (isError) {
			setLoader(false)
			return
		}

		const ObjectToSend = {
			Id: 0,
			InquiryId: 0,
			Customer: customer,
			Requirement: Requirement,
			LivingSituation: livingSituation,
			EmploymentDetails: employmentDetails,
			FinanceDetails: financialDetails,
			Base64Signature: type === 'add' ? termsSignature : financeDetails.Signature,
			UserName: dealersettings.name,
			DealerUrl: '',
			FinanceTerms: CommonHelper.getTermsByField(dealersettings?.client, 'financeTC', customer?.Language),
			EnableFinanceReqApproval: false,
			AMSProInqId: enquiryBasicVM.documentID,
			DealerCode: dealersettings.client.documentID,
			InquiryStatus: enquiryBasicVM.status,
		};

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(ObjectToSend),
		}

		const response = await fetch(dealersettings.financeURL + 'api/AMSProFinance/Action/SaveFinance', requestOptions);
		const respjson = await response.json();

		if (respjson.success) {
			if (type === 'add') {
				setFinanceInfoSavedOnAdd(true)
			}
			// const ref = window.firebase.firestore().collection('enquiries').doc(enquiryBasicVM.documentID);
			// await ref.update({ isFinance: true });
			toast.notify('Finance saved successfully', { duration: 2000 });
			setLoader(false)
			if (type === 'add' || financeInfoSavedOnAdd) {
				handleClose('saved');
			}
			onFinanceCollectionUpdate()
			let financeToggle = document.querySelector('#finance-toggle')
			let financeCollapse = document.querySelector('#finance-collapse')
			if (!financeCollapse.classList.contains('show')) {
				financeToggle.click()
			}
		} else {
			toast.notify('Finance not saved', {
				duration: 2000,
			});
			setLoader(false)
		}
	};

	const handleLivingSituation = (e, data) => {
		// console.log(e, data)
		if (data) {
			if (data.action === 'clear') {
				setLivingSituation({
					...livingSituation,
					[data.name]: null,
				});
			} else {
				setLivingSituation({
					...livingSituation,
					[data.name]: e.value,
				});
			}
		} else {
			setLivingSituation({
				...livingSituation,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleEmploymentDetails = (e, data) => {
		if (data) {
			if (data.action === 'clear') {
				setEmploymentDetails({
					...employmentDetails,
					[data.name]: null,
				});
			} else {
				setEmploymentDetails({
					...employmentDetails,
					[data.name]: e.value,
				});
			}
		} else {
			setEmploymentDetails({
				...employmentDetails,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleIncomeDetails = (e, data) => {
		if (data) {
			if (data.action === 'clear') {
				setFinancialDetails({
					...financialDetails,
					IncomeDetails: {
						...financialDetails.IncomeDetails,
						[data.name]: null,
					},
				});
			} else {
				setFinancialDetails({
					...financialDetails,
					IncomeDetails: {
						...financialDetails.IncomeDetails,
						[data.name]: e.value,
					},
				});

			}
		} else {
			setFinancialDetails({
				...financialDetails,
				IncomeDetails: {
					...financialDetails.IncomeDetails,
					[e.target.name]: e.target.value,
				},
			});
		}
	};

	const handleAssestsDetails = (e, data) => {
		if (data) {
			if (data.action === 'clear') {
				setFinancialDetails({
					...financialDetails,
					AssestsDetails: {
						...financialDetails.AssestsDetails,
						[data.name]: null,
					},
				});
			} else {

				setFinancialDetails({
					...financialDetails,
					AssestsDetails: {
						...financialDetails.AssestsDetails,
						[data.name]: e.value,
					},
				});
			}
		} else {
			setFinancialDetails({
				...financialDetails,
				AssestsDetails: {
					...financialDetails.AssestsDetails,
					[e.target.name]: e.target.value,
				},
			});
		}
	};

	const errorChange = (docID, key, value) => {
		if (errors[`${docID}-${key}`]) {
			if (value) {
				let errorsObj = errors
				errorsObj[`${docID}-${key}`] = ''
				setErrors({ ...errorsObj })
			}
		}
	}

	const onCurrencyChange = (e, name, type, docID) => {
		if (docID) {
			let allRequirements = Requirement.map(RequirementItem => {
				if (RequirementItem.AMSProId === docID) {
					RequirementItem[name] = e.floatValue;
					errorChange(RequirementItem.AMSProId, name, RequirementItem[name])
					return RequirementItem;
				}
				return RequirementItem;
			});

			setRequirement(allRequirements);
			return;
		}

		if (type === 'Requirement') {
			setRequirement({
				...Requirement,
				[name]: e.floatValue,
			});
		} else if (type === 'financialDetails.IncomeDetails') {
			setFinancialDetails({
				...financialDetails,
				IncomeDetails: {
					...financialDetails.IncomeDetails,
					[name]: e.value,
				},
			});
		} else if (type === 'financialDetails.AssestsDetails') {
			setFinancialDetails({
				...financialDetails,
				AssestsDetails: {
					...financialDetails.AssestsDetails,
					[name]: e.value,
				},
			});
		} else if (type === 'financialDetails.LiabilitiesDetails') {
			setFinancialDetails({
				...financialDetails,
				LiabilitiesDetails: {
					...financialDetails.LiabilitiesDetails,
					[name]: e.value,
				},
			});
		}
	};

	const filterRequirments = RequirementItem => {
		if (RequirementItem.documentID === primaryRequirement.documentID) {
			return false;
		}

		const found = Requirement.filter(
			obj => obj.AMSProId === RequirementItem.documentID,
		);

		if (found.length > 0) {
			return false;
		}

		return true;
	};

	const handleAddProviderOpen = () => {
		// console.log('provider')
		setAddProviderModal(true)
	};

	const handleAddProviderClose = () => {
		setAddProviderModal(false)
	};

	const returnReqVal = (object, key) => {
		// Checks if requirement obj is stock one or not 
		// and return value
		if (object.stock === null) {
			return object[key] ? object[key] : ''
		} else if (_.isEmpty(object.stock)) {
			return object[key] ? object[key] : ''
		} else {
			return object.stock[key] ? object.stock[key] : ''
		}
	}

	const handleAddRequirement = requirementsArray => {
		const newRequirements = requirementsArray.map(requirementsArrayItem => {
			const obj = {
				Id: 0,
				Make: returnReqVal(requirementsArrayItem, 'make'),
				Model: returnReqVal(requirementsArrayItem, 'model'),
				Year: returnReqVal(requirementsArrayItem, 'year'),
				SaleType: returnReqVal(requirementsArrayItem, 'saleType'),
				CarPrice: returnReqVal(requirementsArrayItem, 'price'),
				FinanceAmount: returnReqVal(requirementsArrayItem, 'financeAmount'),
				Status: returnReqVal(requirementsArrayItem, 'status'),
				LeadStatus: returnReqVal(requirementsArrayItem, 'leadStatus'),
				ImageList: [],
				ImagesBase64: [],
				AMSProId: requirementsArrayItem.documentID,
				DeletedIds: []
			};

			// console.log(obj);
			return obj;
		});

		//	console.log('newRequirements', newRequirements);

		setRequirement([...Requirement, ...newRequirements]);
	};

	const handleCropClose = id => {
		if (id && document.getElementById(id)) document.getElementById(id).value = '';

		setCropShow({
			...cropShow,
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});
	};

	const handleCropSelect = cropShow => {
		const { src, id, type, ext, docID } = cropShow;
		setLoading(true);

		// saveImageToStorage(src, id, ext);

		setCropShow({
			...cropShow,
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});

		let allRequirements = Requirement.map(RequirementObj => {
			if (RequirementObj.AMSProId === docID) {
				RequirementObj.ImagesBase64 = [...RequirementObj.ImagesBase64, src];
				return RequirementObj;
			}
			return RequirementObj;
		});

		setRequirement(allRequirements);

		setLoading(false);
	};

	const onSelectFile = (e, type, id, title, docID) => {
		if (e.target.files && e.target.files.length > 0) {
			var _size = e.target.files[0].size;
			var filesize = _size / 1024 / 1024;
			if (filesize > 10) {
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Maximum file size 10MB.'), '', 'info')
				return;
			}
			let reader = new FileReader();
			let file = e.target.files[0];
			//var extension = file.type
			reader.onloadend = () => {
				setCropShow({
					...cropShow,
					show: true,
					clsActive: 'overlay-modal active',
					src: reader.result,
					id: id,
					docID: docID,
					type: type,
					ext: file.name.substr(file.name.lastIndexOf('.') + 1),
					title: title,
				});
			};
			reader.readAsDataURL(file);
		}
	};

	const handleImageDel = (requirementId, image, type) => {
		Swal.fire({
			title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
			text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete this image.'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
			cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
		}).then(result => {
			if (result.value) {
				let allRequirements = Requirement.map(RequirementItem => {
					if (!RequirementItem.DeletedIds) {
						RequirementItem.DeletedIds = [];
					}
					if (RequirementItem.AMSProId === requirementId) {
						if (type === 'imageBase64') {
							RequirementItem.ImagesBase64 = RequirementItem.ImagesBase64.filter(img => {
								return img !== image
							})
						} else {
							RequirementItem.ImageList = RequirementItem.ImageList.filter(imgObj => {
								if (imgObj.Id === image.Id) {
									RequirementItem.DeletedIds = [...RequirementItem.DeletedIds, imgObj.Id]
								}
								return imgObj.Id !== image.Id
							})
						}
						return RequirementItem;
					}
					return RequirementItem;
				});
				setRequirement(allRequirements);
				toast.notify('Image deleted successfully', {
					duration: 2000,
				});
				return;
			}
		});
	};

	return fetchLoading ? (
		<></>
	) : (
		<>
			<Modal
				id='add-cafe'
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				enforceFocus={false}
				dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-add-finance'
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='modal-title'><Translate text={title} /></h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<div className=' pop-layout form-style'>
								<div className='tabs-wraper tabs finance-tab-fix'>
									<nav>
										<Tabs
											transition={false}
											className='nav-fill'
											defaultActiveKey={activeTab}
											onSelect={(k) => setActiveTab(k)}
										>
											{/* Personal INFO */}
											<Tab eventKey='personalinfo' title='Personal Info'>
												<div className='form-row'>
													<div className='form-group col-md-4'>
														<label ><Translate text={'firstName'} /> </label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.FirstName ? customer.FirstName : ''}
														/>
													</div>
													<div className='form-group col-md-4'>
														<label > <Translate text={'middleName'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.MiddleName ? customer.MiddleName : ''}
														/>
													</div>
													<div className='form-group col-md-4'>
														<label > <Translate text={'lastName'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.LastName ? customer.LastName : ''}
														/>
													</div>

													<div className='form-group col-md-6'>
														<label > <Translate text={'mobile'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.Phone ? customer.Phone : ''}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label > <Translate text={'email'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.Email ? customer.Email : ''}
														/>
													</div>

													<div className='form-group col-md-6'>
														<label > <Translate text={'licenseNo'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.LicenseNo ? customer.LicenseNo : ''}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label > <Translate text={'licenseExpiry'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={
																contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : ''
															}
														/>
													</div>

													<div className='form-group col-md-6'>
														<label > <Translate text={'licenseState'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.LicenseState ? customer.LicenseState : ''}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label ><Translate text={'licenseType'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.LicenseType ? customer.LicenseType : ''}
														/>
													</div>

													<div className='form-group col-md-6'>
														<label ><Translate text={'gender'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={customer.Gender ? customer.Gender : ''}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label ><Translate text={'dateOfBirth'} /></label>
														<input
															type='text'
															className='form-control'
															disabled
															value={contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : ''}
														/>
													</div>
												</div>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label ><Translate text={'primaryID'} /></label>
														<div className='finance-license-img-wraper'>
															<div className='finance-licenseboxwrap'>
																<div className='finance-licensebox'>
																	<label
																		htmlFor='licBack'
																		className='licensebox-edit secondary-license '
																	>
																		{' '}
																		<i className='' htmlFor='licBack'></i>
																		<input
																			className='fileInput'
																			type='file'
																			name='licBack'
																			accept='image/*'
																			style={{ display: 'none' }}
																		/>
																	</label>
																	{
																		(customer.LicenseImageURL)
																			?
																			<a data-fancy-box={`LicenseImageURL`}
																				href={customer.LicenseImageURL}
																				onClick={(e) => {
																					e.preventDefault();
																					setImgViewer({
																						show: true,
																						images: customer.LicenseImageURL
																					})

																				}}>
																				<img src={customer.LicenseImageURL} alt="" className="" />
																			</a>
																			:
																			<img src={_images.nolicensebg} alt="" className="" />
																	}
																</div>
																<small className='form-text text-muted'>
																	<Translate text={'front'} />
																</small>
															</div>
															<div className='finance-licenseboxwrap'>
																<div className='finance-licensebox'>
																	<label
																		htmlFor='licBack'
																		className='licensebox-edit secondary-license  '
																	>
																		{' '}
																		<i className=' ' htmlFor='licBack'></i>
																		<input
																			className='fileInput'
																			type='file'
																			name='licBack'
																			accept='image/*'
																			style={{ display: 'none' }}
																		/>
																	</label>
																	{
																		(customer.LicenseImageBackURL)
																			?
																			<a data-fancy-box={`LicenseImageBackURL`}
																				href={customer.LicenseImageBackURL}
																				onClick={(e) => {
																					e.preventDefault();
																					setImgViewer({
																						show: true,
																						images: customer.LicenseImageBackURL
																					})

																				}}>
																				<img src={customer.LicenseImageBackURL} alt="" className="" />
																			</a>
																			:
																			<img src={_images.nolicensebg} alt="" className="" />
																	}

																</div>
																<small className='form-text text-muted'>
																	<Translate text={'back'} />
																</small>
															</div>
														</div>
													</div>
													<div className='form-group col-md-6'>
														<label ><Translate text={'secondaryID'} /></label>
														<div className='finance-license-img-wraper'>
															<div className='finance-licenseboxwrap'>
																<div className='finance-licensebox'>
																	<label
																		htmlFor='licBack'
																		className='licensebox-edit secondary-license  '
																	>
																		{' '}
																		<i className=' ' htmlFor='licBack'></i>
																		<input
																			className='fileInput'
																			type='file'
																			name='licBack'
																			accept='image/*'
																			style={{ display: 'none' }}
																		/>
																	</label>
																	{
																		(customer.SecondaryCardFront)
																			?
																			<a data-fancy-box={`SecondaryCardFront`}
																				href={customer.SecondaryCardFront}
																				onClick={(e) => {
																					e.preventDefault();
																					setImgViewer({
																						show: true,
																						images: customer.SecondaryCardFront
																					})

																				}}>
																				<img src={customer.SecondaryCardFront} alt="" className="" />
																			</a>
																			:
																			<img src={_images.nolicensebg} alt="" className="" />
																	}
																</div>
																<small className='form-text text-muted'>
																	<Translate text={'front'} />
																</small>
															</div>
															<div className='finance-licenseboxwrap'>
																<div className='finance-licensebox'>
																	<label
																		htmlFor='licBack'
																		className='licensebox-edit secondary-license  '
																	>
																		{' '}
																		<i className=' ' htmlFor='licBack'></i>
																		<input
																			className='fileInput'
																			type='file'
																			name='licBack'
																			accept='image/*'
																			style={{ display: 'none' }}
																		/>
																	</label>
																	{
																		(customer.SecondaryCardBack)
																			?
																			<a data-fancy-box={`SecondaryCardBack`}
																				href={customer.SecondaryCardBack}
																				onClick={(e) => {
																					e.preventDefault();
																					setImgViewer({
																						show: true,
																						images: customer.SecondaryCardBack
																					})

																				}}>
																				<img src={customer.SecondaryCardBack} alt="" className="" />
																			</a>
																			:
																			<img src={_images.nolicensebg} alt="" className="" />
																	}
																</div>
																<small className='form-text text-muted'>
																	<Translate text={'back'} />
																</small>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											{/* VEHICLE INFO */}
											<Tab eventKey='vehicleinfo' title='Vehicle Info'>
												{requirements.length !== Requirement.length ? (
													<div className='mb-2 w-100 float-right '>
														<div className='finance-add-vehicle'>
															<button
																className='btn btn-sm btn-default float-right'
																onClick={() => setShowAddVehicles(true)}
															>
																<i className='ico icon-add'></i> <Translate text={'addVehicle'} />
															</button>
														</div>
													</div>
												) : <></>}
												<div className='finance-accordion-wraper  enquiry-sidebar'>
													<Accordion defaultActiveKey={activeAccordion}>
														{Requirement.map((RequirementItem, index) => (
															<Card key={RequirementItem.AMSProId}>
																<Accordion.Toggle
																	as={Card.Header}
																	variant='link'
																	eventKey={`${index}`}
																	onClick={() => setActiveAccordion(`${index}`)}
																	id={`${RequirementItem.AMSProId}-toggle`}
																>
																	<div className='mb-0'>
																		<div className='cardlink'>
																			{' '}
																			<a className='btn btn-link collapsed'>
																				{' '}
																				{`${RequirementItem.Make} ${RequirementItem.Model}`}
																				<i className='arrow-collapse'></i>{' '}
																			</a>
																		</div>
																	</div>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey={`${index}`} id={`${RequirementItem.AMSProId}-collapse`}>
																	<Card.Body>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<label > <Translate text={'make'} /></label>
																				<input
																					type='text'
																					className='form-control'
																					disabled
																					value={RequirementItem.Make ? RequirementItem.Make : ''}
																				/>
																			</div>
																			<div className='form-group col-md-6'>
																				<label > <Translate text={'model'} /></label>
																				<input
																					type='text'
																					className='form-control'
																					disabled
																					value={RequirementItem.Model ? RequirementItem.Model : ''}
																				/>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<label > <Translate text={'year'} /></label>
																				<input
																					type='text'
																					className='form-control'
																					disabled
																					value={RequirementItem.Year ? RequirementItem.Year : ''}
																				/>
																			</div>
																			<div className='form-group col-md-6'>
																				<label > <Translate text={'saleType'} /></label>
																				<input
																					type='text'
																					className='form-control'
																					disabled
																					value={RequirementItem.SaleType ? RequirementItem.SaleType : ''}
																				/>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<label > <Translate text={'vehiclePrice'} /></label>
																				<NumberFormat
																					allowNegative={true}
																					thousandSeparator={true}
																					prefix={currencySymbol}
																					placeholder={currencySymbol}
																					className={`form-control ${errors[`${RequirementItem.AMSProId}-CarPrice`] ? errors[`${RequirementItem.AMSProId}-CarPrice`] : 'false'}`}
																					onValueChange={e =>
																						onCurrencyChange(
																							e,
																							'CarPrice',
																							'Requirement',
																							RequirementItem.AMSProId,
																						)
																					}
																					value={RequirementItem.CarPrice ? RequirementItem.CarPrice : ''}
																				/>
																			</div>
																			<div className='form-group col-md-6'>
																				<label >
																					<Translate text={'Finance Amount Required'} />
																				</label>
																				<NumberFormat
																					allowNegative={true}
																					thousandSeparator={true}
																					prefix={currencySymbol}
																					placeholder={currencySymbol}
																					className={`form-control ${errors[`${RequirementItem.AMSProId}-FinanceAmount`] ? errors[`${RequirementItem.AMSProId}-FinanceAmount`] : 'false'}`}
																					onValueChange={e =>
																						onCurrencyChange(
																							e,
																							'FinanceAmount',
																							'Requirement',
																							RequirementItem.AMSProId,
																						)
																					}
																					value={RequirementItem.FinanceAmount ? RequirementItem.FinanceAmount : ''}
																				/>
																			</div>
																		</div>
																		<div className='finance-section-head'>
																			<Translate text={'Attachments'} />
																		</div>

																		<div className='finance-vehicleimage-wrap'>
																			<div className='finance-image-wraper'>
																				{loading ? (
																					<div className='finance-item-image'>
																						<div className='img-loader'>
																							<div className='d-flex h-100 justify-content-center align-items-center text-secondary'>
																								<div
																									className='spinner-border spinner-border-sm'
																									role='status'
																								>
																									<span className='sr-only'>
																										Loading...
																									</span>
																								</div>
																							</div>
																						</div>
																						<a onClick={(e) => { e.preventDefault(); }}
																						>
																							<img
																								src={_images.addvehicle}
																								width='70'
																								height='70'
																								alt=''
																							/>
																						</a>
																					</div>
																				) : (
																					<div className='finance-item-image upload'>
																						<label htmlFor={`images-${index}`}>
																							<img
																								src={_images.addvehicle}
																								alt=''
																								htmlFor={`images-${index}`}
																							/>
																							<input
																								className='fileInput'
																								type='file'
																								name={`images-${index}`}
																								id={`images-${index}`}
																								accept='image/*'
																								style={{ display: 'none' }}
																								onChange={e =>
																									onSelectFile(
																										e,
																										'tradein',
																										`images-${index}`,
																										'Trade-In Image',
																										RequirementItem.AMSProId,
																									)
																								}
																							/>
																						</label>
																					</div>
																				)}
																				{RequirementItem.ImageList && RequirementItem.ImageList.map(
																					(Image, index) => (
																						<div
																							className='finance-item-image'
																							key={index}
																						>
																							<div
																								className='image-delete img-delete-button'
																								onClick={() => handleImageDel(RequirementItem.AMSProId, Image, 'imageUrl')}
																							>
																								<i
																									className='ico icon-delete'
																									htmlFor='licBack'
																								></i>{' '}
																							</div>
																							<a data-fancy-box={`finance-item-image`}
																								href={Image.ImageURL}
																								onClick={(e) => {
																									e.preventDefault();
																									setImgViewer({
																										show: true,
																										images: Image.ImageURL
																									})

																								}}>
																								{' '}
																								<img
																									src={Image.ImageURL}
																									alt=''
																									width='70'
																									height='70'
																								/>{' '}
																							</a>{' '}
																						</div>
																					),
																				)}
																				{RequirementItem.ImagesBase64 &&
																					RequirementItem.ImagesBase64.map(
																						(Image, index) => (
																							<div
																								className='finance-item-image'
																								key={index}
																							>
																								<div
																									className='image-delete img-delete-button'
																									onClick={() => handleImageDel(RequirementItem.AMSProId, Image, 'imageBase64')}
																								>
																									<i
																										className='ico icon-delete'
																										htmlFor='licBack'
																									></i>{' '}
																								</div>
																								<a data-fancy-box={`finance-image`}
																									href={Image}
																									onClick={(e) => {
																										e.preventDefault();
																										setImgViewer({
																											show: true,
																											images: Image
																										})

																									}}>
																									{' '}
																									<img
																										src={Image}
																										alt=''
																										width='70'
																										height='70'
																									/>{' '}
																								</a>{' '}
																							</div>
																						),
																					)}
																			</div>
																		</div>
																		<PopUpModal show={cropShow.show}>
																			<ImageCropHook
																				cropShow={cropShow}
																				handleClose={() =>
																					handleCropClose(`images-${index}`)
																				}
																				handleSelect={handleCropSelect}
																			></ImageCropHook>
																		</PopUpModal>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														))}
													</Accordion>
												</div>
											</Tab>
											{/* Living Situation */}
											<Tab eventKey='livingsituation' title='Living Situation'>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label > <Translate text={'Residential Address'} /></label>

														<AutoComplete
															className={`form-control`}
															bindAddress={e =>
																bindAddress(
																	e,
																	'livingSituation',
																	'ResidenceAddress',
																)
															}
															types={['address']}
															value={livingSituation.ResidenceAddress ? livingSituation.ResidenceAddress : ''}
															placeholder={'Enter Residence Address'}
															onChange={e => {
																e.preventDefault();
																const { name, value } = e.target;
																setLivingSituation({
																	...livingSituation,
																	ResidenceAddress: value,
																});
															}}
															componentRestrictions={{ country: 'au' }}
															name='address'
														/>
													</div>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Residence Status'} />
														</label>
														<ReactSelect
															options={residenceStatusOptions}
															name='ResidenceStatus'
															placeholder={'Select Residence Status'}
															onChange={handleLivingSituation}
															value={livingSituation.ResidenceStatus ? livingSituation.ResidenceStatus : ''}
															// classNamePrefix={`${this.state.errors['country']} basic-select`}
															classNamePrefix={` basic-select`}
														/>
													</div>
												</div>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Residence Year'} />
														</label>
														<ReactSelect
															options={residenceYearOptions}
															name='ResidenceYear'
															placeholder={'Select Residence Year'}
															onChange={handleLivingSituation}
															value={livingSituation.ResidenceYear ? livingSituation.ResidenceYear : ''}
															// classNamePrefix={`${this.state.errors['country']} basic-select`}
															classNamePrefix={`basic-select`}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label > </label>
														<label >
															<Translate text={'Residence Type'} />
														</label>
														<ReactSelect
															options={residenceTypeOptions}
															name='ResidenceType'
															placeholder={'Select Residence Type'}
															onChange={handleLivingSituation}
															value={livingSituation.ResidenceType ? livingSituation.ResidenceType : ''}
															// classNamePrefix={`${this.state.errors['country']} basic-select`}
															classNamePrefix={`basic-select`}
														/>
													</div>
												</div>

												{livingSituation.ResidenceYear === 'Less than a year' ||
													livingSituation.ResidenceYear === '1 year' ||
													livingSituation.ResidenceYear === '2 years' ? (
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<label >
																<Translate text={'Previous Residence Address'} />
															</label>
															<AutoComplete
																className={`form-control`}
																bindAddress={e =>
																	bindAddress(
																		e,
																		'livingSituation',
																		'PreviousAddress',
																	)
																}
																types={['address']}
																value={livingSituation.PreviousAddress ? livingSituation.PreviousAddress : ''}
																placeholder={'Enter Previous Residence Address'}
																onChange={e => {
																	e.preventDefault();
																	const { name, value } = e.target;
																	setLivingSituation({
																		...livingSituation,
																		PreviousAddress: value,
																	});
																}}
																componentRestrictions={{ country: 'au' }}
																name='PreviousAddress'
															/>
														</div>
													</div>
												) : null}
											</Tab>
											{/* Employment Details */}
											<Tab eventKey='employmentdetails' title='Employment Details'>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Employer Address'} />
														</label>
														<AutoComplete
															className={`form-control`}
															bindAddress={e =>
																bindAddress(
																	e,
																	'employmentDetails',
																	'EmployerAddress',
																)
															}
															types={['address']}
															value={employmentDetails.EmployerAddress ? employmentDetails.EmployerAddress : ''}
															placeholder={'Enter Employer Address'}
															onChange={e => {
																e.preventDefault();
																const { name, value } = e.target;
																setEmploymentDetails({
																	...employmentDetails,
																	EmployerAddress: value,
																});
															}}
															componentRestrictions={{ country: 'au' }}
															name='EmployerAddress'
														/>
													</div>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Employer Name'} />
														</label>
														<input
															type='text'
															className='form-control'
															name='EmployerName'
															placeholder={'Select Employer Name'}
															onChange={handleEmploymentDetails}
															value={employmentDetails.EmployerName ? employmentDetails.EmployerName : ''}
														/>
													</div>
												</div>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Employment Period'} />
														</label>
														<ReactSelect
															options={employmentPeriodOptions}
															name='EmploymentPeriod'
															placeholder={'Select Employment Period'}
															onChange={handleEmploymentDetails}
															value={employmentDetails.EmploymentPeriod ? employmentDetails.EmploymentPeriod : ''}
															// classNamePrefix={`${this.state.errors['country']} basic-select`}
															classNamePrefix={` basic-select`}
														/>
													</div>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Employment Status'} />
														</label>
														<ReactSelect
															options={employmentStatusOptions}
															name='EmploymentStatus'
															placeholder={'Select Employment Status'}
															onChange={handleEmploymentDetails}
															value={employmentDetails.EmploymentStatus ? employmentDetails.EmploymentStatus : ''}
															// classNamePrefix={`${this.state.errors['country']} basic-select`}
															classNamePrefix={` basic-select`}
														/>
													</div>
												</div>
												<div className='form-row'>
													<div className='form-group col-md-6'>
														<label >
															<Translate text={'Occupation'} />
														</label>
														<input
															type='text'
															className='form-control'
															name='Occupation'
															placeholder={'Select Occupation'}
															onChange={handleEmploymentDetails}
															value={employmentDetails.Occupation ? employmentDetails.Occupation : ''}
														/>
													</div>
													{employmentDetails.EmploymentPeriod ===
														'Less than a year' ||
														employmentDetails.EmploymentPeriod === '1 year' ||
														employmentDetails.EmploymentPeriod === '2 years' ? (
														<div className='form-group col-md-6'>
															<label >
																<Translate text={'Previous Employer'} />
															</label>
															<input
																type='text'
																className='form-control'
																name='PreviousEmployer'
																placeholder={'Select Previous Employer'}
																onChange={handleEmploymentDetails}
																value={employmentDetails.PreviousEmployer ? employmentDetails.PreviousEmployer : ''}
															/>
														</div>
													) : null}
												</div>
											</Tab>
											{/* Financial Details */}
											<Tab eventKey='financialdetails' title='Financial Details'>
												<div className='finance-accordion-wraper enquiry-sidebar'>
													<Accordion defaultActiveKey='0'>
														<Card>
															<Accordion.Toggle
																as={Card.Header}
																variant='link'
																eventKey='0'
															>
																<div className='mb-0'>
																	<div className='cardlink'>
																		{' '}
																		<a className='btn btn-link collapsed'>
																			{' '}
																			<Translate text={'Income'} /><i className='arrow-collapse'></i>{' '}
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>
															<Accordion.Collapse eventKey='0'>
																<Card.Body>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Primary Net Income Amount'}
																				/>
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'PrimaryAmount',
																						'financialDetails.IncomeDetails',
																					)
																				}
																				value={
																					financialDetails.IncomeDetails.PrimaryAmount ?
																						financialDetails.IncomeDetails.PrimaryAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Primary Frequency '} />
																			</label>
																			<ReactSelect
																				options={frequency}
																				name='PrimaryFrequency'
																				placeholder={'Select Primary Frequency'}
																				onChange={handleIncomeDetails}
																				value={
																					financialDetails.IncomeDetails.PrimaryFrequency ?
																						financialDetails.IncomeDetails.PrimaryFrequency : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Benefits Amount '} />
																			</label>
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'BenefitsAmount',
																						'financialDetails.IncomeDetails',
																					)
																				}

																				value={
																					financialDetails.IncomeDetails.BenefitsAmount ?
																						financialDetails.IncomeDetails.BenefitsAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Benefits Frequency '} />
																			</label>

																			<ReactSelect
																				options={frequency}
																				name='BenefitsFrequency'
																				placeholder={'Select Benefits Frequency'}
																				onChange={handleIncomeDetails}

																				value={
																					financialDetails.IncomeDetails.BenefitsFrequency ?
																						financialDetails.IncomeDetails.BenefitsFrequency : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Other Amount '} />
																			</label>
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'OtherAmount',
																						'financialDetails.IncomeDetails',
																					)
																				}

																				value={
																					financialDetails.IncomeDetails.OtherAmount ?
																						financialDetails.IncomeDetails.OtherAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Other Frequency '} />
																			</label>
																			<ReactSelect
																				options={frequency}
																				name='OtherFrequency'
																				placeholder={'Select Other Frequency'}
																				onChange={handleIncomeDetails}
																				value={
																					financialDetails.IncomeDetails.OtherFrequency ?
																						financialDetails.IncomeDetails.OtherFrequency : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Rental Income Amount'}
																				/>
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'RentalAmount',
																						'financialDetails.IncomeDetails',
																					)
																				}
																				value={
																					financialDetails.IncomeDetails.RentalAmount ?
																						financialDetails.IncomeDetails.RentalAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Rental Frequency'} />
																			</label>
																			<ReactSelect
																				options={frequency}
																				name='RentalFrequency'
																				placeholder={'Select Rental Frequency'}
																				onChange={handleIncomeDetails}
																				value={
																					financialDetails.IncomeDetails.RentalFrequency ?
																						financialDetails.IncomeDetails.RentalFrequency : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
														<Card>
															<Accordion.Toggle
																as={Card.Header}
																variant='link'
																eventKey='1'
															>
																<div className='mb-0'>
																	<div className='cardlink'>
																		{' '}
																		<a className='btn btn-link collapsed'>
																			{' '}
																			<Translate text={'Assests'} /><i className='arrow-collapse'></i>{' '}
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>
															<Accordion.Collapse eventKey='1'>
																<Card.Body>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Property One Value'} />{' '}
																			</label>
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'PropertyOneValue',
																						'financialDetails.AssestsDetails',
																					)
																				}
																				value={
																					financialDetails.AssestsDetails.PropertyOneValue ?
																						financialDetails.AssestsDetails.PropertyOneValue : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Property One Financed'}
																				/>{' '}
																			</label>
																			<ReactSelect
																				options={yesOrNo}
																				name='PropertyOneFinanced'
																				placeholder={
																					'Select Property One Financed'
																				}
																				onChange={handleAssestsDetails}
																				value={
																					financialDetails.AssestsDetails.PropertyOneFinanced ?
																						financialDetails.AssestsDetails.PropertyOneFinanced : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Property Two Value'} />
																			</label>
																			{/* <input
																			type='text'
																			className='form-control'
																			name='PropertyTwoValue'
																			placeholder={'Enter Property Two Value'}
																			onChange={handleAssestsDetails}
																			value={
																				financialDetails.AssestsDetails
																					.PropertyTwoValue
																			}
																		/> */}
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'PropertyTwoValue',
																						'financialDetails.AssestsDetails',
																					)
																				}
																				value={
																					financialDetails.AssestsDetails.PropertyTwoValue ?
																						financialDetails.AssestsDetails.PropertyTwoValue : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Property Two Financed'}
																				/>
																			</label>
																			<ReactSelect
																				options={yesOrNo}
																				name='PropertyTwoFinanced'
																				placeholder={
																					'Select Property Two Financed'
																				}
																				onChange={handleAssestsDetails}
																				value={
																					financialDetails.AssestsDetails.PropertyTwoFinanced ?
																						financialDetails.AssestsDetails.PropertyTwoFinanced : ''
																				}
																				// classNamePrefix={`${this.state.errors['country']} basic-select`}
																				classNamePrefix={` basic-select`}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Deposit Estimated Amount'}
																				/>
																			</label>
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'DepositAmount',
																						'financialDetails.AssestsDetails',
																					)
																				}
																				value={
																					financialDetails.AssestsDetails.DepositAmount ?
																						financialDetails.AssestsDetails.DepositAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Savings Estimated Amount'}
																				/>
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'SavingsAmount',
																						'financialDetails.AssestsDetails',
																					)
																				}
																				value={
																					financialDetails.AssestsDetails.SavingsAmount ?
																						financialDetails.AssestsDetails.SavingsAmount : ''
																				}
																			/>
																		</div>
																	</div>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
														<Card>
															<Accordion.Toggle
																as={Card.Header}
																variant='link'
																eventKey='3'
															>
																<div className='mb-0'>
																	<div className='cardlink'>
																		{' '}
																		<a className='btn btn-link collapsed'>
																			{' '}
																			{' '}<Translate text={'Liabilities'} />
																			<i className='arrow-collapse'></i>{' '}
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>
															<Accordion.Collapse eventKey='3'>
																<Card.Body>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Mortgage Outstanding Amount'}
																				/>{' '}
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'MortgageAmount',
																						'financialDetails.LiabilitiesDetails',
																					)
																				}
																				value={
																					financialDetails.LiabilitiesDetails.MortgageAmount ?
																						financialDetails.LiabilitiesDetails.MortgageAmount : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={
																						'Personal Loan Outstanding Amount'
																					}
																				/>{' '}
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'PersonalLoanAmount',
																						'financialDetails.LiabilitiesDetails',
																					)
																				}
																				value={
																					financialDetails.LiabilitiesDetails.PersonalLoanAmount ?
																						financialDetails.LiabilitiesDetails.PersonalLoanAmount : ''
																				}
																			/>
																		</div>
																	</div>
																	<div className='form-row'>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate text={'Total Credit Limit'} />
																			</label>
																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'CreditLimit',
																						'financialDetails.LiabilitiesDetails',
																					)
																				}
																				value={
																					financialDetails.LiabilitiesDetails.CreditLimit ?
																						financialDetails.LiabilitiesDetails.CreditLimit : ''
																				}
																			/>
																		</div>
																		<div className='form-group col-md-6'>
																			<label >
																				<Translate
																					text={'Total Outstanding Amount'}
																				/>
																			</label>

																			<NumberFormat
																				allowNegative={true}
																				thousandSeparator={true}
																				prefix={currencySymbol}
																				placeholder={currencySymbol}
																				className={`form-control`}
																				onValueChange={e =>
																					onCurrencyChange(
																						e,
																						'OutstandingAmount',
																						'financialDetails.LiabilitiesDetails',
																					)
																				}
																				value={
																					financialDetails.LiabilitiesDetails.OutstandingAmount ?
																						financialDetails.LiabilitiesDetails.OutstandingAmount : ''
																				}
																			/>
																		</div>
																	</div>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													</Accordion>
												</div>
											</Tab>
											{/* <Tab eventKey='documents' title='Documents'>
													<div className='common-table finance-view-table'>
															<table className='table table-bordered'>
																<thead>
																<tr>
																		<th
																			scope='col'
																			className='head-light'
																			colSpan='8'
																		>
																			<Translate text={'name'} />
																		</th>
																		<th
																			scope='col'
																			className='head-light'
																			colSpan='4'
																		>
																			<Translate text={'Uploaded By'} />
																		</th>
																		<th
																			scope='col'
																			className='head-light'
																			colSpan='4'
																		>
																			<Translate text={'Uploaded On'} />
																		</th>
																		<th
																			scope='col'
																			className='head-light'
																			colSpan='1'
																		>
																			<Translate text={'Download'} />
																		</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		{financeDetails && financeDetails.FinanceFile.length > 0 ? financeDetails.FinanceFile.map(file => (
																			<Fragment key={file.Id}>
																				<td colSpan='8'>
																					<span> <Translate text={file.Title} /></span>
																				</td>
																				<td colSpan='4'>
																					<span> <Translate text={file.AddedUserName} /></span>
																				</td>
																				<td colSpan='4'>
																					<span> {moment(file.AddedDate).format('DD/MM/YYYY hh:mm A')} </span>
																				</td>	
																				<td colSpan='1' className='text-center'>
																					<a href={file.FilePath} target='_blank' rel="noopener noreferrer" className="common-button"><i className="fa button-action-icon fa-download"></i></a>
																				</td>
																			</Fragment>
																		)) : (
																		<td colSpan='18' style={{ textAlign: 'center' }}>
																			<span> <Translate text={'NO DATA'} /></span>
																		</td>																		
																		)}
																	</tr>
																</tbody>
															</table>
														</div>															
												</Tab> */}
										</Tabs>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<button
						type='button'
						className='btn btn-default float-left mr-2 '
						aria-label='Close'
						onClick={() => setDisplayTerms(true)}
					>
						<i className='ico icon-terms mr-1 finance-terms-icon'></i>
						{' '}<Translate text={'Terms'} />
					</button>
					<button
						type='button'
						className='btn btn-primary float-right'
						onClick={handleSave}
					>
						{
							loader ?
								(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
								: (<></>)
						}
						<Translate text={'save'} />
					</button>
					{type !== 'add' || financeInfoSavedOnAdd ? (
						<button
							type='button'
							className='btn btn-primary float-right mr-2 '
							aria-label='Close'
							// disabled={}
							onClick={handleAddProviderOpen}
						>
							{' '}<Translate text={'Request Finance'} />
						</button>
					) : (
						<></>
					)}
				</Modal.Footer>
				{fetchLoading ? <></> : (
					<>
						<PopUpModal show={displayTerms}>
							<Terms
								show={displayTerms}
								clsActive='overlay-modal active'
								imagePath='contacts'
								title={'termsAndConditions'}
								terms={{
									signature: type === 'add' ? termsSignature : financeDetails.Signature
								}}
								termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'financeTC', customer?.Language)}
								isDisable={true}
								handleClose={() => setDisplayTerms(false)}
								dealersettings={dealersettings}
								shouldReturnBase64={true}
							></Terms>
						</PopUpModal>
						<PopUpModal show={showAddVehicles}>
							<AddVehicles
								show={showAddVehicles}
								handleClose={() => setShowAddVehicles(false)}
								requirements={requirements.filter(filterRequirments)}
								handleAddRequirement={handleAddRequirement}
								dealersettings={dealersettings}
								title={'Add Vehicles'}
							></AddVehicles>
						</PopUpModal>
						<PopUpModal show={addProviderModal}>
							<AddProvider
								show={addProviderModal}
								handleClose={handleAddProviderClose}
								contact={contact}
								dealersettings={dealersettings}
								enquiryBasicVM={enquiryBasicVM}
								onFinanceCollectionUpdate={onFinanceCollectionUpdate}
							/>
						</PopUpModal>
					</>
				)}
			</Modal>
			<PopUpModal show={imageViewer.show}>
				<ImageViewer
					{...imageViewer}
					handleClose={() => { setImgViewer({ show: false, images: null }); }}
				/>
			</PopUpModal>
		</>
	);
};

export default AddFinance;
