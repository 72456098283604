import React, { useState, useEffect } from 'react';
import { Dropdown, Modal } from 'react-bootstrap'
import _ from 'lodash'
import { ContentOverlay, ReactSelect } from '../../../components'
import Translate from '../../../constants/translate';
import _images from '../../../images'
import CommonHelper from '../../../services/common';
import Swal from 'sweetalert2';
import toast from 'toasted-notes'



const SettingsKPIs = (props) => {
    const [report, setReport] = useState(props.report)
    const [activeTab, setActivetab] = useState(props.options[0])
    const [loader, setLoader] = useState(false)

    const handleSelectChange = (e) => {
        setReport({
            ...report,
            [activeTab.value]: report?.[activeTab.value]?.length ? [
                ...report[activeTab.value],
                e.value
            ] : [e.value]
        })
    }

    const handleRemove = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to remove?.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                setReport({
                    ...report,
                    [activeTab.value]: report[activeTab.value].filter(a => a !== rec.value)
                })
            }
        })
    }

    const handleSave = () => {
        setLoader(true)
        window.firebase.firestore().doc(`reports/${report.documentID}`).set({
            ...report,
            marketing: true,
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify(('Settings updated successfully'), {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-mtdreport-settings"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="mtdsettings-column-wraper">
                                <div className="mtdsettings-columns">

                                    <div className="mtdsettings-column-left">
                                        <div className="mtdsettings-column-title">

                                            <h3>Category</h3>

                                        </div>

                                        <div className="mtdsettings-column-nav">

                                            <ul>
                                                {
                                                    props.options.map((rec, index) => {
                                                        return <li key={index} className={`${activeTab.value === rec.value ? 'active' : ''}`}>
                                                            <a href="#" className="column-list-left" onClick={() => {
                                                                setActivetab(rec)
                                                            }}>{rec.label}</a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="mtdsettings-column-middle">
                                        <div className="mtdsettings-column-title">
                                            <h3>{activeTab?.label}</h3>

                                        </div>
                                        <div className="user-list">
                                            <p>Add {activeTab?.label} to report</p>
                                            <div className="filter-search">
                                                <ReactSelect
                                                    options={(!_.isEmpty(props.dealersettings.client.settings[activeTab?.value]) ?
                                                        _.map(props.dealersettings.client.settings[activeTab.value].filter(a => !report?.[activeTab?.value]?.includes(a.value)), function (c) {
                                                            return {
                                                                value: c.value,
                                                                label: `${c.name} ${c.active === true ? '' : '(In Active)'}`
                                                            };
                                                        }) : [])}
                                                    name={"type"}
                                                    placeholder={`select ${activeTab?.value}`}
                                                    onChange={handleSelectChange}
                                                    value={''}
                                                    classNamePrefix={` basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        {
                                            report?.[activeTab?.value]?.length === 0 ? (<></>) : (<div className="mtdsettings-list-items">
                                                <ul>
                                                    {
                                                        props.dealersettings.client.settings[activeTab.value].filter(a => report?.[activeTab?.value]?.includes(a.value)).map((rec, index) => {
                                                            return <li key={index} className="mtdsettings-list-box">
                                                                <div className="mtdsettings-list-items-more"> <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleRemove(rec)
                                                                }}><i className="ico icon-delete"></i></a> </div>
                                                                <div className="mtdsettings-list-items-info">
                                                                    <h4>{rec.name}</h4>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>)
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className={`btn btn-primary float-right ml-2`} onClick={() => { handleSave() }}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SettingsKPIs;