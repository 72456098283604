import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
//import CommonHelper from '../../../../services/common'
import { BarChart } from "../../../components";
import _images from '../../../images'
import { dealertargetDynCols } from '../../reports/viewModel';

const DealerTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    //const [reportData, setReportData] = useState();
    //const [brands, setBrands] = useState()
    //const [clientUsers, setClientUsers] = useState()
    const [cummulativeTargets, setCummulativeTargets] = useState([])

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _pdf = params.get('pdf');
    let _year = params.get('year');
    let _oem = params.get('oem');
    let fromOEM = params.get('fromOEM');
    let dealer = params.get('dealer') ? params.get('dealer').split(',') : [];
    _year = _year ? _year : moment().year();
    const allClient = localStorage.allClients ? JSON.parse(localStorage.allClients) : [];
    let showdealer = params.get('showdealer');
    let region = params.get('region') ? params.get('region').split(',') : [];
    let subregion = params.get('subregion') ? params.get('subregion').split(',') : [];
    let country = params.get('country') ? params.get('country').split(',') : [];


    useEffect(() => {
        var css = '@page { size: landscape; }',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
    }, [])

    useEffect(() => {
        if (Boolean(fromOEM))
            return;
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(async (doc) => {
                if (doc.exists) {
                    let timezone = doc.data().timezone ? doc.data().timezone : 'Australia/Sydney';

                    setLoader(true)
                    const reportData = window.firebase.functions().httpsCallable('generic-getData');
                    reportData({
                        type: props?.dealersettings?.mvwReport ? "getDealerTargetV2" : "getDealerTarget",
                        params: JSON.stringify({
                            oemID: _oem ? _oem : null,
                            clientID: doc.id,
                            startDate: moment([_year]).startOf('year').format('YYYY-MM-DD'),
                            endDate: moment([_year]).endOf('year').format('YYYY-MM-DD'),
                            timezone: timezone
                        })
                    }).then((response) => {
                        setLoader(false);
                        if (response.data.success) {
                            //setTargets(response.data.data)
                            let _target = _.chain(response.data.data)
                                .groupBy('targetID')
                                .map((value, key) => {
                                    return {
                                        targetName: value[0].name,
                                        calculationType: value[0].calculationType,
                                        targetID: key,
                                        counts: value.map((rec, index) => {
                                            return {
                                                ...rec,
                                                delta: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.totarget ? -(parseInt(rec.totarget)) : 0 : null,
                                                target: parseInt(rec.target),
                                                actual: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.actual ? parseInt(rec.actual) : 0 : null,
                                                index
                                            }
                                        })
                                    }
                                }).value();
                            let _cummulativeTarget = _target.map(rec => {
                                return {
                                    ...rec,
                                    counts: rec.counts.map((r, i) => {
                                        return {
                                            ...r,
                                            cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                                            cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                                            cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                                        }
                                    })
                                }
                            })

                            //console.log('_cummulativeTarget', _cummulativeTarget)
                            setCummulativeTargets(_cummulativeTarget);
                        }
                    });

                    setClient({
                        ...doc.data(),
                        documentID: doc.id
                    });
                }
            })
    }, [])

    useEffect(() => {
        if (!Boolean(fromOEM))
            return;
        window.firebase.firestore().doc(`clientSettings/${props.clientid}`)
            .get()
            .then(async (doc) => {
                if (doc.exists) {
                    setLoader(true)
                    let targetPromise = [];
                    if (dealer.length > 0) {
                        dealer.forEach(rec => {
                            targetPromise.push(window.firebase.firestore().doc(`clients/${rec}/dealerTarget/${rec}`).get());
                        })
                        const targetSnapshot = await Promise.all(targetPromise);
                        let data = [];
                        targetSnapshot.forEach(doc => {
                            if (doc.data()?.data?.length) {
                                data.push(...doc.data().data.map(r => {
                                    return {
                                        ...r,
                                        clientID: doc.id,
                                        groupKey: r?.type ? `${r.type}-${_.orderBy(r?.subType?.split(','))?.join(',')}` : r.targetID,
                                        calculationType: r.calculationType === 'amount' ? r.calculationType : 'count'
                                    }
                                }))
                            }
                        })
                        const dataFormat = data.map(r => {
                            return {
                                ...r,
                                target: parseFloat(r.target.replace(/\D/g, '')),
                                actual: parseFloat(r.actual.replace(/\D/g, '')),
                                groupKey: r?.type ? `${r.type}-${_.orderBy(r?.subType?.split(','))?.join(',')}` : r.targetID
                            }
                        });
                        const dataResult = [];
                        _.chain(dataFormat)
                            .groupBy('groupKey')
                            .map((value) => {
                                return dataResult.push(..._.chain(value).groupBy('month').map((v) => {
                                    return {
                                        ...v[0],
                                        target: _.sumBy(v, 'target'),
                                        actual: _.sumBy(v, 'actual'),
                                        totarget: _.sumBy(v, 'target') - _.sumBy(v, 'actual')
                                    }
                                }).value())
                            }).value();
                        let _cummulativeTarget = getCummulativeTarget(dataResult);
                        setCummulativeTargets(_cummulativeTarget);
                    }
                    setClient({
                        ...doc.data(),
                        documentID: doc.id
                    });
                    setLoader(false)
                }
            })
    }, [])

    const getCummulativeTarget = (data) => {
        let _target = _.chain(data)
            .groupBy('groupKey')
            .map((value, key) => {
                return {
                    targetName: value[0].name,
                    calculationType: value[0].calculationType,
                    targetID: key,
                    chartKey: `${key}-${value[0].targetID}`,
                    counts: value.map((rec, index) => {
                        return {
                            ...rec,
                            delta: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.totarget ? -(parseInt(rec.totarget)) : 0 : null,
                            target: parseInt(rec.target),
                            actual: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.actual ? parseInt(rec.actual) : 0 : null,
                            index
                        }
                    })
                }
            }).value();
        let _cummulativeTarget = _target.map(rec => {
            return {
                ...rec,
                counts: rec.counts.map((r, i) => {
                    return {
                        ...r,
                        cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                        cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                        cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                    }
                })
            }
        })
        return _cummulativeTarget;
    }


    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape report-data-load">

            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                {
                    _pdf === 'true' ? (<></>) : (<div className="print-ico">
                        <a href="#" onClick={() => {
                            window.print();
                        }}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                    </div>)
                }
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="40%"><div className="header-left" style={{ textAlign: 'left', fontSize: '14px', color: '#495057', fontWeight: 700, textTransform: 'uppercase' }}>
                                    {`DEALER TARGET - ${client.name.toUpperCase()}`}
                                    <br></br>
                                    {
                                        client && !_.isEmpty(client.timezone) ? (
                                            <span> {moment().tz(client.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }

                                </div></td>

                                <td width="60%"><div className="header-right">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="summary-table-wraper" style={{ padding: `0` }}>
                    {
                        allClient && (region.length > 0 || subregion.length > 0 || country.length > 0 || showdealer) ? (
                            <div className="report-filter-data-list">
                                {
                                    region.length > 0 ? (<>
                                        <strong>Region :</strong> <span>{_.uniq(allClient.filter(a => region.includes(a.region)).map(r => { return r.regionName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    subregion.length > 0 ? (<>
                                        <strong>Subregion :</strong> <span>{_.uniq(allClient.filter(a => subregion.includes(a.subregion)).map(r => { return r.subregionName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    country.length > 0 ? (<>
                                        <strong>Country :</strong> <span>{_.uniq(allClient.filter(a => country.includes(a.country)).map(r => { return r.countryName })).join(',')}</span>
                                    </>) : (<></>)
                                }
                                {
                                    dealer.length > 0 && showdealer ? (<>
                                        <strong>Dealer :</strong> <span>{_.uniq(allClient.filter(a => dealer.includes(a.id)).map(r => { return r.name })).join(',')}</span>
                                    </>) : (<></>)
                                }
                            </div>
                        ) : (<></>)
                    }
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td className="outside-td">
                                    {
                                        !_.isEmpty(cummulativeTargets) ? cummulativeTargets.map((cTarget, index) => {
                                            return (<Fragment key={index}>

                                                <div className="summary-table-wraper-head" style={{ fontSize: `16px` }}>{cTarget.targetName}</div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td className="head-light" width="20%"></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="head-light text-left" width="20%"><span className="th-blue-clr">TARGET</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="text-center">{`${_rec.target !== null ? `${_rec.target}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="head-light text-left" width="20%"><span className="th-blue-clr">ACTUAL</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="text-center">{`${_rec.actual !== null ? `${_rec.actual}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="head-light text-left" width="20%"><span className="th-blue-clr">DELTA</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className={`text-center ${_rec.showRed === "true" && _rec.calculationType === 'percentage' ? 'down-ico' : ''}`}>
                                                                        {
                                                                            _rec.showRed === "false" && _rec.calculationType === 'percentage' ? <img src={_images.tickgreen} alt="" height="20" /> :
                                                                                `${_rec.delta ? `${_rec.delta}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`
                                                                        }
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {
                                                    cTarget.calculationType === 'count' ? (<table className="table table-bordered mt-2">
                                                        <thead>
                                                            <tr>
                                                                <td className="head-light" width="20%"></td>
                                                                {
                                                                    cTarget.counts.map((_rec, _i) => {
                                                                        return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="head-light text-left" width="20%"><span className="th-blue-clr">TARGET - CUMMULATIVE</span></td>
                                                                {
                                                                    cTarget.counts.map((_rec, _i) => {
                                                                        return <td key={_i} className="text-center">{_rec.cummulativeTarget}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td className="head-light text-left" width="20%"><span className="th-blue-clr">ACTUAL - CUMMULATIVE</span></td>
                                                                {
                                                                    cTarget.counts.map((_rec, _i) => {
                                                                        return <td key={_i} className="text-center">{_rec.cummulativeActual}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td className="head-light text-left" width="20%"><span className="th-blue-clr">DELTA - CUMMULATIVE</span></td>
                                                                {
                                                                    cTarget.counts.map((_rec, _i) => {
                                                                        return <td key={_i} className="text-center">{_rec.cummulativeDelta}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </table>) : (<></>)
                                                }
                                                <div className="text-center mt-2">
                                                    <BarChart
                                                        id={cTarget.targetID}
                                                        height={`380px`}
                                                        data={cTarget.counts}
                                                        columns={['target', 'actual']}
                                                        lineColumns={cTarget.calculationType === 'count' ? ['cummulativeTarget', 'cummulativeActual'] : []}
                                                        dynamicColumns={dealertargetDynCols}
                                                        xCategory={'month'}
                                                        calculationType={cTarget.calculationType}
                                                        handleShowEnquiryList={(value, startDate, endDate) => {

                                                        }}
                                                        hideBullet={true}
                                                        dynColor={true}
                                                    />
                                                </div>
                                                <div style={{ pageBreakAfter: 'always' }}></div>
                                            </Fragment>)
                                        }) : (<div className="spinner-loader h-100 p-5">
                                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                <div className="no-data-txt mt-2"> Target not set for selected dealer</div>
                                            </div>
                                        </div>)
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ pageBreakBefore: 'avoid' }}></div>
                <div className="footer">
                    <div className="footer-bottom">
                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{client.phone} &nbsp;</span></> : <></>}
                                {client.website ? <>Web: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>
                        <div className="footer-right"></div>
                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )
}

export default DealerTarget;