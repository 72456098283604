import React, { useState, useEffect } from 'react';
import { ContentOverlay, PopoverPanel, MentionEditor } from '../../components';
import _ from 'lodash';
import { firestoreDB } from '../../services/helper';
import CommonHelper from '../../services/common';
import moment from 'moment';
//import MentionEditor from '../../components/mentionEditor/mentionEditor';
import Translate from '../../constants/translate';

const FieldComments = (props) => {
    const [popover, setPopover] = useState({})
    const [notes, setNotes] = useState([])
    const [loader, setLoader] = useState(false)
    const [mentions, setMentions] = useState([]);
    const [errorNotes, setErrorNotes] = useState('')
    const [btnloader, setbtnloader] = useState(false)
    const [newnote, setnewnote] = useState('')
    const _allUser = props?.params?.context?.settings?.allUsers || [];
    const _roleids = props?.params?.context?.dealersettings?.client?.roles?.filter(rec => rec?.modules?.dealLog === true)?.map(rec => { return rec.id }) || [];
    const mentionsData = _allUser.filter(a => a.id !== localStorage.uid && a.active === true && _.includes(_roleids, a.roleID)).map(clientUser => {
        return {
            name: `${clientUser.name}`,
            id: clientUser.id,
            email: clientUser.email,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });

    useEffect(() => {
        setPopover({ show: true, target: props.params.eGridCell })
    }, [])

    useEffect(() => {
        setLoader(true)
        firestoreDB({ client: { projectId: props?.data?.projectId } }).firestore()
            .collection(`fieldComments`)
            .where('isDeleted', '==', false)
            .where('field', '==', props.params.column.colId)
            .where('enquiryID', '==', props?.data?.documentID)
            .get()
            .then((snap) => {
                const _notes = [];
                if (snap.size > 0) {
                    snap.docs.forEach(doc => {
                        if (doc.data().notes) {
                            _notes.push(doc.data())
                        }
                    });
                }
                setNotes(_.orderBy(_notes, 'addedDate.seconds', 'desc'));
                setLoader(false)
            })

    }, [])

    const newlogvm = () => {
        let newlogVM = Object.assign({});
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = props?.data?.documentID || null;;
        newlogVM.contactID = props?.data?.contactID || null;
        newlogVM.clientID = props?.data?.clientID || null;
        newlogVM.oemID = props?.params?.context?.dealersettings?.oemID || null;
        newlogVM.documentID = firestoreDB({ client: { projectId: props?.data?.projectId } }).firestore().collection('fieldComments').doc().id;
        newlogVM.addedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
        newlogVM.modifiedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
        newlogVM.projectId = props?.data?.projectId || null;
        newlogVM.notes = null;
        newlogVM.isDeleted = false;
        return newlogVM;
    };


    const onMentionChange = notesText => {
        setnewnote(notesText);
    };

    const onMentionSelect = (option) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    const handleSave = (e) => {
        if (_.isEmpty(newnote?.trim())) {
            setErrorNotes('input_error');
            return;
        }
        const commnetsLog = newlogvm();
        if (mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })
            commnetsLog.mentions = _.uniq(convertedIDs)
        }
        commnetsLog.notes = newnote;
        commnetsLog.field = props.params.column.colId;
        commnetsLog.fieldName = props?.params?.colDef?.name || null;
        setbtnloader(true);
        firestoreDB({ client: { projectId: props?.data?.projectId } }).firestore().doc(`fieldComments/${commnetsLog.documentID}`)
            .set(commnetsLog)
            .then(doc => {
                firestoreDB({ client: { projectId: props?.data?.projectId } }).firestore()
                    .doc(`enquiries/${commnetsLog.enquiryID}`)
                    .update({ [`fieldComments.${commnetsLog.field}`]: true })
                setbtnloader(false);
                props.params.api.getDisplayedRowAtIndex(props.params.node.rowIndex).updateData({
                    ...props.data,
                    fieldComments: props.data.fieldComments ? {
                        ...props.data.fieldComments,
                        [commnetsLog.field]: true
                    } : { [commnetsLog.field]: true }
                })
                props.params.api.redrawRows({
                    rowNodes: [props.params.node],
                    columns: [props.params.column.colId],
                });
                //props.handleClose();
                setNotes([
                    commnetsLog,
                    ...notes
                ])
                setnewnote('')
            })

    }
    return (
        <PopoverPanel
            showpopover={popover.show}
            targetpopover={popover.target}
            title={'Comments'}
            position={props.position || 'left'}
            id='dealerlog-comments'
            className='popover-deallog-newcomments popover-deallog-theme'
            closepopover={() => {
                setPopover({ show: false });
                props.handleClose();
                //props.api.stopEditing();
            }}>

            <div>
                <div className="deallog-newcomments-user-wrap">
                    {
                        loader ? (<ContentOverlay active={true} />) : (<>
                            {
                                notes.map((rec, index) => {
                                    const logBy = rec.addedByName ? rec.addedByName : (rec.addedBy ? CommonHelper.getUserNamebyId(_allUser, rec.addedBy) : '');
                                    return <div key={index} id={rec.documentID} className="deallog-newcomments-user-item">
                                        <div>
                                            <div className="deallog-newcomments-owner-view"> <img src={CommonHelper.getUserImagebyId(_allUser, rec.addedBy)} /></div>
                                        </div>
                                        <div className="w-100">
                                            <p className="deallog-newcomments-user-status"><span className="deallog-newcomments-blue-text">{logBy}</span><span>{rec.addedDate ? moment.unix(rec.addedDate.seconds).format(`DD/MM/YYYY hh:mm A`) : ''}</span></p>
                                            <div className="deallog-newcomments-user-block deallog-newcomments-blue-bg">
                                                <p>{rec.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </>
                        )
                    }
                </div>
                <div className="deallog-newcomments-add-wrap form-group mb-0 mt-2">
                    <label>Comments</label>
                    <MentionEditor
                        mentions={mentionsData}
                        onChange={onMentionChange}
                        value={newnote || ''}
                        placeholder={'Enter @ to mention user'}
                        className={`notes ${errorNotes}`}
                        rows={3}
                        cols={40}
                        onMentionSelect={onMentionSelect}
                    />
                </div>

                <div className="mt-2 w-100 d-inline-block">
                    <button type="button" onClick={handleSave} className="btn btn-primary float-right d-flex align-items-center justify-content-center">
                        <i className="ico icon-email mr-1"></i>   {
                            btnloader ? (
                                <span
                                    className='spinner-border spinner-button mr-1'
                                    role='status'
                                    aria-hidden='true'
                                ></span>
                            ) : (
                                <></>
                            )}
                        <Translate text={'save'} />
                    </button></div>
            </div>
        </PopoverPanel>
    );
};

export default FieldComments;