import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import _, { stubFalse } from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { CustomTable, InputText, ReactSelect } from '../../../../components'
import { modelVM } from "./viewModel";

const cols = [
    { name: 'Keywords', value: 'kw', type: 'text', mandatory: true, width: '80' },
]

const AddModel = (props) => {
    const [model, setModel] = useState(props.model);
    const [errorFields, setErrorFields] = useState({});
    const newModel = !_.isEmpty(props.model) ? false : true
    const [loader, setLoader] = useState(false)
    const [addGroup, setGroup] = useState(false)
    const [addType, setType] = useState(false)
    const [state, setState] = useState(props?.model?.kw?.map(r => { return { kw: r } }) || [])
    const oldModel = props.model;

    useEffect(() => {
        if (_.isEmpty(props.model)) {
            setModel({
                name: '',
                group: '',
                type: '',
                value: ''
            })
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x]);
            }
            if (name === 'name') {
                setModel({
                    ...model,
                    [name]: newarray1.join(' '),
                    ['value']: newModel ? newarray1.join(' ') : model.value
                });
            }
            else {
                setModel({
                    ...model,
                    [name]: newarray1.join(' ')
                });
            }
        } else {
            // if (str && str.length >= 1) {
            //     var firstChar = str.charAt(0);
            //     var remainingStr = str.slice(1);
            //     str = firstChar.toUpperCase() + remainingStr;
            // }
            if (name === 'name') {
                setModel({
                    ...model,
                    [name]: str,
                    ['value']: newModel ? newarray1.join(' ') : model.value
                });
            }
            else {
                setModel({
                    ...model,
                    [name]: newarray1.join(' ')
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setModel({
            ...model,
            [name]: value
        });

    }

    const saveModel = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(model['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (newModel && props.models.some(item => item.value.toLowerCase() === model.value.toLowerCase())) {
            Swal.fire('Model with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (state.length) {
            model.kw = state.map(r => r.kw);
        }
        window.firebase.firestore().doc(`brands/${props.make.documentID}/models/${props.make.documentID}`)
            .set({
                models: newModel ? [model, ...props.models] : [..._.map(props.models, function (obj) {
                    return obj.value === model.value || (oldModel && oldModel.value === obj.value) ? { ..._.pick(model, _.keys(modelVM)) } : { ..._.pick(obj, _.keys(modelVM)) }
                })],
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedBy: localStorage.uid
            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Model ' + (newModel ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose()
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(model) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-small-popup"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100 pipeline-form-style">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label > Model </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'name'}
                                    className={`form-control ${errorFields["name"]}`}
                                    name="name"
                                    onChange={handleOnChange}
                                    value={model.name}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label > Value </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'value'}
                                    className={`form-control ${errorFields["value"]}`}
                                    name="value"
                                    onChange={handleOnChange}
                                    value={model.value}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                {
                                    !addGroup ? (<a href="#" className="inputlink-addmore custom-more"
                                        onClick={(e) => {
                                            setGroup(true)
                                            setModel({
                                                ...model,
                                                ['group']: ''
                                            });
                                        }}>
                                        + add
                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                        onClick={(e) => {
                                            setGroup(false)
                                            setModel({
                                                ...model,
                                                ['group']: ''
                                            });
                                        }}>
                                        x cancel
                                    </a>)
                                }
                                <label > Group </label>
                                {
                                    addGroup ? <InputText
                                        autoComplete="off"
                                        placeholder={'group'}
                                        className={`form-control ${errorFields["group"]}`}
                                        name="group"
                                        onChange={handleOnChange}
                                        value={model.group}
                                    /> : <ReactSelect
                                        options={_.chain(props.models.filter(item => !_.isEmpty(item.group)))
                                            .groupBy("group")
                                            .map((value, key) => ({
                                                label: key,
                                                value: key
                                            }))
                                            .value()}
                                        name={"group"}
                                        placeholder={'select group'}
                                        onChange={(e) => {
                                            if (e)
                                                setModel({
                                                    ...model,
                                                    ['group']: e.value
                                                });
                                            else
                                                setModel({
                                                    ...model,
                                                    ['group']: ''
                                                });
                                        }}
                                        value={model.group}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                }

                            </div>
                            <div className="form-group col-md-12">
                                {
                                    !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                        onClick={(e) => {
                                            setType(true)
                                            setModel({
                                                ...model,
                                                ['type']: ''
                                            });
                                        }}>
                                        + add
                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                        onClick={(e) => {
                                            setType(false)
                                            setModel({
                                                ...model,
                                                ['type']: ''
                                            });
                                        }}>
                                        x cancel
                                    </a>)
                                }
                                <label > Type </label>
                                {
                                    addType ? <InputText
                                        autoComplete="off"
                                        placeholder={'type'}
                                        className={`form-control ${errorFields["type"]}`}
                                        name="type"
                                        onChange={handleOnChange}
                                        value={model.type}
                                    />
                                        :
                                        <ReactSelect
                                            options={_.chain(props.models.filter(item => !_.isEmpty(item.type)))
                                                .groupBy("type")
                                                .map((value, key) => ({
                                                    label: key,
                                                    value: key
                                                }))
                                                .value()}
                                            name={"type"}
                                            placeholder={'select type'}
                                            onChange={(e) => {
                                                if (e)
                                                    setModel({
                                                        ...model,
                                                        ['type']: e.value
                                                    });
                                                else
                                                    setModel({
                                                        ...model,
                                                        ['type']: ''
                                                    });
                                            }}
                                            value={model.type}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                }
                            </div>
                            <div className="form-group col-md-12">
                                <label > C@P ID </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'capID'}
                                    className={`form-control ${errorFields["capID"]}`}
                                    name="capID"
                                    onChange={handleOnChange}
                                    value={model.capID}
                                />
                            </div>
                            <div className="form-group col-md-12 mt-2">
                                <CustomTable
                                    cols={cols}
                                    subList={state}
                                    setSubList={(list) => { setState(list) }}
                                    dragDisabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveModel(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                Save
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
        </Modal.Footer>
    </Modal>
    );
}

export default AddModel;