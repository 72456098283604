export const Routes = [
    {
        name: "Dashboard",
        route: "/dashboard",
        module: "enquiry",
        class: "ico icon-dashboard",
        submenu: [{
            name: "Dashboard",
            class: "ico icon-dashboard",
            route: "/dashboard",
        },
        {
            name: "BDCDashboard",
            class: "ico icon-dashboard",
            route: "/dashboard/bdc",
        }]
    },
    {
        name: "Inbound",
        route: "/inbound",
        module: "enquiry",
        class: "ico icon-inbound"
    },
    {
        name: "Pipeline",
        route: "/pipeline",
        module: "enquiry",
        class: "ico icon-Pipeline"
    },
    {
        name: "Calendar",
        route: "/calendar",
        module: "enquiry",
        class: "ico icon-Calendar"
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "enquiry",
        class: "ico icon-Contacts"
    },
    {
        name: "Mail",
        route: "/mails/inbox",
        module: "enquiry",
        class: "ico icon-Mail",
        showcount: true
    },
    {
        name: "Stock",
        module: "enquiry",
        class: "ico icon-Stock",
        icoVehicle: true,
        submenu: [{
            name: "Stock Management",
            class: "ico icon-Stock",
            icoVehicle: true,
            route: "/stock",
        },
        {
            name: "loanVehicle",
            class: "ico icon-tradein",
            icoVehicle: true,
            route: "/fleet",
        },
        {
            name: "testDrive",
            class: "ico icon-activity-testdrive",
            icoVehicle: true,
            route: "/testdrive/history",
        },
            // {
            //     name: "tradeinhistory",
            //     class: "ico icon-tradein",
            //     icoVehicle: true,
            //     route: "/tradein/history",
            // }
        ]
    },
    {
        name: "Reports",
        route: "/reports",
        module: "enquiry",
        class: "ico icon-Reports",
        submenu: [{
            name: "Reports",
            class: "ico icon-Reports",
            route: "/reports",
        },
        {
            name: "marketingroi",
            class: "ico icon-line-chart",
            route: "/marketing/report",
        },
        {
            name: "yearlysalesperformance",
            class: "ico icon-Reports",
            route: "/sales/performance/report",
        },
        {
            name: "yearlyperformance",
            class: "ico icon-Reports",
            route: "/yearly/performance/report",
        },
        {
            name: "acquisitionreport",
            class: "ico icon-mail-draft",
            route: "/acquisition",
        },
        {
            name: "triggerlogs",
            class: "ico icon-settings-workflow",
            route: "/trigger/list",
        }]
    },
    {
        name: "Orders",
        route: "/kitchen",
        module: "kitchen",
        class: "ico icon-menu-orders"
    },
    {
        name: "History",
        route: "/kitchenhistory",
        module: "kitchen",
        class: "ico icon-cafe"
    },
    {
        name: "Queue",
        route: "/frontdesk",
        module: "frontdesk",
        class: "ico icon-queue"
    },
    {
        name: "Inbound",
        route: "/inbound",
        module: "frontdesk",
        class: "ico icon-inbound"
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "frontdesk",
        class: "ico icon-Contacts"
    },
    {
        name: "Calendar",
        route: "/calendar",
        module: "frontdesk",
        class: "ico icon-Calendar"
    },
    {
        name: "Cafe",
        route: "/cafeorders",
        module: "frontdesk",
        class: "ico icon-cafe"
    },
    {
        name: "Logs",
        route: "/logs",
        module: "frontdesk",
        class: "ico icon-file-logs"
    },
    {
        name: "Stock",
        class: "ico icon-Stock",
        icoVehicle: true,
        route: "/stock",
        module: "stock",
    },
    {
        name: "loanVehicle",
        class: "ico icon-tradein",
        icoVehicle: true,
        route: "/fleet",
        module: "loanVehicle",
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "loanVehicle",
        class: "ico icon-Contacts"
    },
    {
        // name: "Dashboard",
        // class: "ico icon-locations-fix",
        // route: "/oem/dashboard",
        // module: "oem",
        name: "Dashboard",
        route: "/oem/dashboard",
        module: "oem",
        class: "ico icon-locations-fix",
        submenu: [{
            name: "OEMDashboard",
            class: "ico icon-locations-fix",
            route: "/oem/dashboard",
        },
        {
            name: "Dashboard",
            class: "ico icon-dashboard",
            route: "/dashboard/sales",
        }]
    },
    // {
    //     name: "BDCDashboard",
    //     class: "ico icon-dashboard",
    //     route: "/dashboard/bdc",
    //     module: "oem",
    // },
    {
        name: "Inbound",
        route: "/inbound",
        module: "oem",
        class: "ico icon-inbound"
    },
    {
        name: "Pipeline",
        route: "/pipeline/oemlist",
        module: "oem",
        class: "ico icon-Pipeline"
    },
    // {
    //     name: "Inbound",
    //     route: "/inbound/allocation",
    //     module: "oem",
    //     class: "ico icon-inbound"
    // },
    {
        name: "Calendar",
        class: "ico icon-Calendar",
        route: "/calendar",
        module: "oem",
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "oem",
        class: "ico icon-Contacts"
    },
    {
        name: "Dealers",
        class: "ico icon-dealers",
        route: "/oem/dealers",
        module: "oem",
    },
    {
        name: "Mail",
        class: "ico icon-Mail",
        route: "/mails/inbox",
        module: "oem",
    },
    {
        name: "Stock",
        class: "ico icon-Stock",
        icoVehicle: true,
        route: "/oem/stock",
        module: "oem",
        submenu: [{
            name: "Pipeline",
            class: "ico icon-Stock",
            route: "/oem/stock/pipeline",
        },
        {
            name: "OEM Stock",
            class: "ico icon-Stock",
            icoVehicle: true,
            route: "/oem/stock",
        },
        {
            name: "Stock Management",
            class: "ico icon-Stock",
            icoVehicle: true,
            route: "/stock",
        },
        {
            name: "loanVehicle",
            class: "ico icon-tradein",
            icoVehicle: true,
            route: "/oem/loan",
        },
        {
            name: "testDrive",
            class: "ico icon-activity-testdrive",
            icoVehicle: true,
            route: "/testdrive/history",
        }
        ]
    },
    {
        name: "Reports",
        class: "ico icon-Reports",
        route: "/oem/reports",
        module: "oem",
        submenu: [{
            name: "Reports",
            class: "ico icon-Reports",
            route: "/oem/reports",
        },
        {
            name: "Target Report",
            class: "ico icon-line-chart",
            route: "/oem/targets",
        }]
    },
    {
        name: "Dashboard",
        route: "/dashboard/service",
        module: "service",
        class: "ico icon-dashboard",
    },
    {
        name: "Appointments",
        route: "/appointment",
        module: "service",
        class: "ico icon-Calendar"
    },
    {
        name: "Pipeline",
        route: "/service",
        module: "service",
        class: "ico icon-Pipeline"
    },
    {
        name: "Calendar",
        route: "/calendar",
        module: "service",
        class: "ico icon-Calendar"
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "service",
        class: "ico icon-Contacts"
    },
    {
        name: "Stock",
        route: "/stock",
        module: "service",
        class: "ico icon-Stock",
        icoVehicle: true,
    },
    {
        name: "Reports",
        route: "/services/reports",
        module: "service",
        class: "ico icon-Reports",
    },
    {
        name: "Dashboard",
        route: "/dashboard/aftersales",
        module: "afterSales",
        class: "ico icon-dashboard"
    },
    {
        name: "Pipeline",
        route: "/aftersales",
        module: "afterSales",
        class: "ico icon-Pipeline"
    },
    {
        name: "Calendar",
        route: "/calendar",
        module: "afterSales",
        class: "ico icon-Calendar"
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "afterSales",
        class: "ico icon-Contacts"
    },
    {
        name: "Mail",
        route: "/mails/inbox",
        module: "afterSales",
        class: "ico icon-Mail",
        showcount: true
    },
    {
        name: "Dashboard",
        route: "/dashboard/appraisal",
        module: "tradeinPro",
        class: "ico icon-dashboard",
    },
    {
        name: "Inbound",
        route: "/tipinbound/list",
        module: "tradeinPro",
        class: "ico icon-inbound"
    },
    {
        name: "Pipeline",
        route: "/tradeinpro",
        module: "tradeinPro",
        class: "ico icon-Pipeline"
    },
    {
        name: "Calendar",
        route: "/calendar",
        module: "tradeinPro",
        class: "ico icon-Calendar"
    },
    {
        name: "Mail",
        route: "/mails/inbox",
        module: "tradeinPro",
        class: "ico icon-Mail",
        showcount: true
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "tradeinPro",
        class: "ico icon-Contacts"
    },
    {
        name: "Reports",
        route: "/tradeinPro/reports",
        module: "tradeinPro",
        class: "ico icon-Reports",
    },
    {
        name: "Appraisal",
        route: "/wholesaler",
        module: "wholesaler",
        class: "ico icon-Pipeline"
    },
    {
        name: "TV Scheduler",
        route: "/schedule/contact",
        module: "tvModule",
        class: "ico icon-monitor"
    },
    {
        name: "Reports",
        class: "ico icon-Reports",
        route: "/reports",
        module: "reports",
    },
    {
        name: "Dashboard",
        route: "/dashboard/events",
        module: "eventsPro",
        oemmodule: "oemeventsPro",
        class: "ico icon-dashboard",
    },
    {
        name: "Events",
        route: "/eventspro/list",
        module: "eventsPro",
        oemmodule: "oemeventsPro",
        class: "ico icon-events-event"
    },
    {
        name: "Calendar",
        route: "/eventspro/calendar",
        module: "eventsPro",
        oemmodule: "oemeventsPro",
        class: "ico icon-Calendar"
    },
    {
        name: "Attendees",
        route: "/eventspro/attendees",
        module: "eventsPro",
        oemmodule: "oemeventsPro",
        class: "ico icon-Contacts"
    },
    {
        name: "Templates",
        route: "/eventspro/templates",
        module: "eventsPro",
        oemmodule: "oemeventsPro",
        class: "ico icon-Pipeline"
    },
    {
        name: "Dashboard",
        route: "/dashboard/fleet",
        module: "fleetPro",
        oemmodule: "oemfleetPro",
        class: "ico icon-dashboard"
    },
    {
        name: "Fleet",
        route: "/fleetpro/list",
        module: "fleetPro",
        oemmodule: "oemfleetPro",
        class: "ico icon-Pipeline"
    },
    {
        name: "Calendar",
        route: "/fleetpro/calendar",
        module: "fleetPro",
        oemmodule: "oemfleetPro",
        class: "ico icon-Calendar"
    },
    {
        name: "History",
        route: "/fleetpro/history",
        module: "fleetPro",
        class: "ico icon-history"
    },
    {
        name: "Orders",
        route: "/deallog/orders",
        module: "dealLog",
        class: "ico icon-deallog"
    },
    {
        name: "Contacts",
        route: "/contacts",
        module: "dealLog",
        class: "ico icon-Contacts"
    },
];

export const SettingsRoutes = [
    {
        name: "Profile",
        route: "/settings/profile",
        class: "ico icon-settings-profile"
    },
    {
        name: "Client Settings",
        route: "/settings/company",
        class: "ico icon-settings-company",
        module: "clientSettings"
    },
    {
        name: "Workflow Setup",
        route: "/settings/workflow",
        class: "ico icon-settings-workflow",
        module: "workflowSetup"
    },
    {
        name: "Service Workflow Setup",
        route: "/settings/serviceworkflow",
        class: "ico icon-settings-workflow",
        module: "serviceworkflowSetup"
    },
    {
        name: "Trade-In Pro Workflow Setup",
        route: "/settings/tradeinProworkflow",
        class: "ico icon-settings-workflow",
        module: "tradeinProworkflowSetup"
    },
    {
        name: "Events Pro Workflow Setup",
        route: "/settings/eventsProworkflow",
        class: "ico icon-settings-workflow",
        module: "eventsProworkflowSetup"
    },
    {
        name: "Fleet Pro Workflow Setup",
        route: "/settings/fleetworkflow",
        class: "ico icon-settings-workflow",
        module: "fleetProworkflowSetup"
    },
    {
        name: "Sales Hub Workflow",
        route: "/settings/dealLogworkflow",
        class: "ico icon-settings-workflow",
        module: "dealLogworkflowSetup",
        beta: true
    },
    {
        name: "Products & Data",
        route: "/settings/product",
        class: "ico icon-settings-product",
        module: "productsData"
    },
    {
        name: "Modules",
        route: "/settings/modules",
        class: "ico icon-settings-modules",
        module: "modules"
    },
    {
        name: "Integrations",
        route: "/settings/integrations",
        class: "ico icon-settings-integrations",
        module: "integration"
    },
    // {
    //     name: "Location/Sites Management",
    //     route: "/settings/sitemanagment",
    //     class: "ico icon-settings-sites",
    //     module : "locationMgnt"
    // },
    {
        name: "Security Management",
        route: "/settings/securitymanagment",
        class: "ico icon-settings-security",
        module: "securityMgnt"
    },
    {
        name: "Billing",
        route: "/settings/billing",
        class: "ico icon-settings-billing",
        module: "billing"
    }
];

export const Pipelines = [
    {
        default: true,
        level: "dealer",
        name: "Sales",
        stages: [
            {
                name: "Lead",
                value: "Lead"
            },
            {
                name: "Prospect",
                value: "Prospect"
            },
            {
                name: "Opportunity",
                value: "Opportunity"
            }
        ]
    },
    {
        default: false,
        level: "dealer",
        name: "BDC",
        stages: [
            {
                name: "Leads In",
                value: "LeadsIn"
            },
            {
                name: "Contacted",
                value: "Contacted"
            },
            {
                name: "Not Contacted",
                value: "NotContacted"
            },
            {
                name: "Qualified",
                value: "Qualified"
            }
        ]
    }
]

export const AdminRoutes = [
    {
        name: "Dashboard",
        route: "/admin/dashboard",
        class: "ico icon-dashboard"
    },
    // {
    //     name: "Meilisearch",
    //     route: "/admin/meilisearch",
    //     class: "ico icon-Pipeline"
    // },
    {
        name: "Settings",
        route: "/admin/settings",
        class: "ico icon-settings"
    },
    {
        name: "Dealers",
        route: "/admin/dealers",
        class: "ico icon-dealers"
    },
    {
        name: "Billing",
        route: "/admin/billing",
        class: "ico icon-billing"
    },
    {
        name: "Integrations",
        route: "/admin/integration",
        class: "ico icon-settings-integrations"
    }
];

export const AdminSettingsRoutes = [
    {
        name: "Profile",
        route: "/admin/settings/profile",
        class: "ico icon-settings-profile"
    },
    {
        name: "Company",
        route: "/admin/settings/company",
        class: "ico icon-settings-company"
    },
    {
        name: "Products & Data",
        route: "/admin/settings/product",
        class: "ico icon-settings-product"
    },
    {
        name: "Modules",
        route: "/admin/settings/modules",
        class: "ico icon-settings-modules"
    },
    {
        name: "Integrations",
        route: "/admin/settings/integrations",
        class: "ico icon-settings-integrations"
    },
    {
        name: "Workflow Setup",
        route: "/admin/settings/workflow",
        class: "ico icon-settings-workflow"
    },
    // {
    //     name: "Location/Sites Management",
    //     route: "/admin/settings/sitemanagment",
    //     class: "ico icon-settings-sites"
    // },
    {
        name: "Security Management",
        route: "/admin/settings/securitymanagment",
        class: "ico icon-settings-security"
    },
    // {
    //     name: "Billing",
    //     route: "/admin/settings/billing",
    //     class: "ico icon-settings-billing"
    // }
];

export const AllModules = [
    {
        name: "Sales",
        module: "enquiry",
        class: "ico icon-module-sales",
        icoVehicle: true,
        route: "/dashboard",
        sortOrder: 1
    },
    {
        name: "OEM",
        module: "oem",
        class: "ico icon-module-oem",
        route: "/oem/dashboard",
        sortOrder: 2
    },
    {
        name: "Front Desk",
        module: "frontdesk",
        class: "ico icon-module-frontdesk-fix",
        route: "/frontdesk",
        sortOrder: 3
    },
    {
        name: "Kitchen",
        module: "kitchen",
        class: "ico icon-module-kitchen-fix",
        route: "/kitchen",
        sortOrder: 4
    },
    {
        name: "Stock",
        module: "stock",
        class: "ico icon-module-stock",
        icoVehicle: true,
        route: "/stock",
        sortOrder: 5
    },
    {
        name: "Loan Vehicle",
        module: "loanVehicle",
        class: "ico icon-module-loan",
        icoVehicle: true,
        route: "/fleet",
        sortOrder: 6
    },
    {
        name: "Service",
        module: "service",
        class: "ico icon-service-settings",
        route: "/dashboard/service",
        sortOrder: 7
    },
    {
        name: "After Sales",
        module: "afterSales",
        class: "ico icon-module-aftersales-fix",
        route: "/dashboard/aftersales",
        sortOrder: 8
    },
    {
        name: "Trade-In Pro",
        module: "tradeinPro",
        class: "ico icon-tradein-module-icon",
        icoVehicle: true,
        route: "/dashboard/appraisal",
        sortOrder: 9
    },
    {
        name: "Events Pro",
        module: "eventsPro",
        class: "ico icon-events-module-fill",
        route: "/dashboard/events",
        sortOrder: 10
    },
    {
        name: "Wholesaler",
        module: "wholesaler",
        class: "ico icon-finance",
        route: "/wholesaler",
        sortOrder: 11
    },
    {
        name: "TV Scheduler",
        module: "tvModule",
        class: "ico icon-monitor",
        route: "/schedule/contact",
        sortOrder: 12
    },
    {
        name: "Reports",
        module: "reports",
        class: "ico icon-Reports",
        route: "/reports",
        sortOrder: 13
    },
    {
        name: "Events Pro",
        module: "oemeventsPro",
        class: "ico icon-events-module-fill",
        route: "/dashboard/events",
        sortOrder: 14
    },
    {
        name: "Fleet Management",
        module: "fleetPro",
        class: "ico icon-fleetpro-icon",
        icoVehicle: true,
        route: "/dashboard/fleet",
        sortOrder: 15
    },
    {
        name: "Fleet Management",
        module: "oemfleetPro",
        class: "ico icon-fleetpro-icon",
        icoVehicle: true,
        route: "/dashboard/fleet",
        sortOrder: 16
    },
    {
        name: "Sales Hub",
        module: "dealLog",
        class: "ico icon-deallog",
        icoVehicle: true,
        beta: true,
        route: "/deallog/orders",
        sortOrder: 16
    },
];

export const MailRoutes = [
    {
        name: "Inbox",
        route: "/mail/inbox",
        class: "ico icon-mail-inbox"
    },
    // {
    //     name: "Starred",
    //     route: "/mail/starred",
    //     class: "ico icon-mail-starred"
    // },
    {
        name: "Sent",
        route: "/mail/sent",
        class: "ico icon-mail-sent"
    },
    {
        name: "Drafts",
        route: "/mail/drafts",
        class: "ico icon-mail-draft"
    },
    // {
    //     name: "Archive",
    //     route: "/mail/archive",
    //     class: "ico icon-mail-archive"
    // },
    // {
    //     name: "All Mail",
    //     route: "/mail/allmail",
    //     class: "ico icon-mail-allmail"
    // },
    {
        name: "Important",
        route: "/mail/important",
        class: "ico icon-mail-important"
    },
    {
        name: "Spam",
        route: "/mail/spam",
        class: "ico icon-mail-spam"
    },
    {
        name: "Trash",
        route: "/mail/trash",
        class: "ico icon-mail-trash"
    },
];

export const MailsRoutes = [
    {
        id: "INBOX",
        name: "Inbox",
        route: "/mails/inbox",
        class: "ico icon-mail-inbox",
        attribute: 'Inbox'
    },
    {
        id: "SENT",
        name: "Sent",
        route: "/mails/sent",
        class: "ico icon-mail-sent",
        attribute: 'Sent'
    },
    // {
    //     id: "DRAFT",
    //     name: "Drafts",
    //     route: "/mails/drafts",
    //     class: "ico icon-mail-draft",
    //     attribute: 'Drafts'
    // },
    {
        id: "IMPORTANT",
        name: "Important",
        route: "/mails/important",
        class: "ico icon-mail-important",
        attribute: 'Important'
    },
    {
        id: "SPAM",
        name: "Junk",
        route: "/mails/spam",
        class: "ico icon-mail-spam",
        attribute: 'Junk'
    },
    {
        id: "TRASH",
        name: "Trash",
        route: "/mails/trash",
        class: "ico icon-mail-trash",
        attribute: 'Trash'
    },
];