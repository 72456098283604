
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import Translate from "../constants/translate";
import { default as _images } from "../images";
import CommonHelper from '../services/common';
export default class UpgradeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.signPad.getSignaturePad()._isEmpty) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Please provide a signature.'), '', 'info')
            return false;
        }
        else {
            this.props.handleClose(this.signPad.getTrimmedCanvas().toDataURL('image/png'))
        }
    };

    render() {
        //let { trimmedDataURL } = this.state;
        const { show, handleClose } = this.props;
        return (
            <>

                <Modal
                    id="upgrade-plan"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-upgrade-pop">

                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body className="upgrade-body">
                        <div className="upgrade-subscription-overlay">
                            <div className="spinner-loader h-100">
                                <div className="upgrade-subscription-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                    <div className="upgrade-subscription-img-wrap"><img src={_images.upgrade} width="200" alt="" /></div>
                                    <div className="upgrade-subscription-txt-wrap">
                                        <div className="upgrade-subscription-content-head"><Translate text={'Upgrade your subscription plan'} /></div>
                                        <div className="upgrade-subscription-content"><Translate text={"Your current subscription doesnt include this features"} /></div>
                                        <div className="upgrade-subscription-content-btn">
                                            <button type="button" className="btn btn-primary" onClick={e => {
                                                e.preventDefault()
                                                window.Intercom('show');
                                                handleClose();
                                            }}> <Translate text={'Contact Support'} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>


            </>


        );
    }
}

