/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import { ImageViewer, PopUpModal } from '../../../components';
import AddHistory from './addHistory'
import CommonHelper from '../../../services/common';
import { firestoreDB } from "../../../services/helper";

class OdometerHistorys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAdd: false,
            selectedData: null,
            historys: Object.assign([], this.props.historys),
            imageViewer: { show: false, images: null }
        }
    }

    handleHistoryClose = (data, isExist) => {

        if (!_.isEmpty(data)) {
            let historys = Object.assign([], this.state.historys);

            if (isExist)
                historys = historys.filter(e => e.documentID !== data.documentID)

            historys.push(data);

            const { docID, dealersettings, stockID } = this.props;
            if (docID) {
                let objData = {
                    odometerHistory: historys,
                    modifiedFrom: 'web',
                    modifiedBy: dealersettings ? dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }
                const updateRef = firestoreDB(dealersettings).firestore().collection('fleet').doc(docID);
                updateRef.set(objData, { merge: true }).then((docRef) => {
                    this.setState({
                        historys: Object.assign([], historys),
                        selectedData: null
                    });
                    if (stockID && data.odometer)
                        this.addFleetDetailInStock(stockID, data.odometer);

                }).catch((error) => {
                    console.error("Error updating loan_details: ", error);
                });
            }

        }
        this.setState({
            showAdd: false
        });
    }

    addFleetDetailInStock = (stockID, odometer) => {

        if (stockID && odometer) {
            const { dealersettings } = this.props;
            var objDetail = {
                modifiedBy: dealersettings ? dealersettings.id : '',
                modifiedFrom: 'web',
                modifiedDate: window.firebase.firestore.Timestamp.now()
            }

            if (odometer)
                objDetail.miles = parseInt(odometer);

            const updateRef = window.firebase.firestore().collection('stock').doc(stockID);
            updateRef.set(objDetail, { merge: true }).then((docRef) => {

            }).catch((error) => {
                console.error("Error updating stock_details: ", error);
            });
        }
    }

    handleClose = (e) => {
        //e.preventDefault();
        const { historys } = this.state;
        this.props.handleClose(historys);
    }

    render() {

        const { show, dealersettings, clientUsers, groupUsers, isDisable } = this.props;
        const { historys } = this.state;

        return (
            <>
                <Modal
                    show={show}
                    onHide={this.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-odo-history">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" id=""><Translate text={'Odometer History'} />
                                {
                                    isDisable
                                        ?
                                        <></>
                                        :
                                        <>
                                            <div className="float-right odo-history-add">
                                                <button type="button" className="btn btn-primary float-right" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        showAdd: true,
                                                        selectedData: null
                                                    });
                                                }}><i className="ico icon-add"></i> <Translate text={'Add Odometer'} /></button>
                                            </div>
                                        </>
                                }

                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">

                                <div className="odo-history-detail-view">
                                    {
                                        !_.isEmpty(historys)
                                            ?
                                            <>
                                                {
                                                    _.orderBy(historys, ['addedDate'], ['desc']).map((history, index) => {
                                                        return <div className="odo-history-detail-loop" key={index}>

                                                            <div className="edit-button right-second-icon">
                                                                <a href="#" className="mini-button" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({
                                                                        showAdd: true,
                                                                        selectedData: history
                                                                    });
                                                                }}>
                                                                    <i className="ico icon-edit"></i>
                                                                </a>
                                                            </div>
                                                            <div className="odo-history-detail-icon">
                                                                <div className="marker-badge major-scratch">
                                                                    <img src={_images.odometer} alt="" />
                                                                </div>
                                                            </div>

                                                            {history.odometer
                                                                ?
                                                                <div className="odo-history-detail-loop-head" >
                                                                    <NumberFormat value={history.odometer} displayType={'text'} thousandSeparator={true} />
                                                                </div>
                                                                :
                                                                <></>
                                                            }

                                                            <div className="odo-activity-date">{moment.unix(history.addedDate.seconds).format('DD/MM/YYYY h:mm A')} -
                                                                {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), history.addedBy)}</div>
                                                            {
                                                                (!_.isEmpty(history.images))
                                                                    ?
                                                                    <div className="odo-history-detail-loop-images">

                                                                        {history.images.map((data, index) => (
                                                                            <div className="odo-history-detail-image" key={index}>

                                                                                <a data-fancy-box={`inspection_det_img`}
                                                                                    href={data}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({
                                                                                            imageViewer: {
                                                                                                show: true,
                                                                                                images: history.images, 
                                                                                                index
                                                                                            }
                                                                                        });
                                                                                    }}>
                                                                                    <img src={data} width="70" height="70" alt="" />
                                                                                </a>
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }


                                                        </div>
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente" style={{ minHeight: '245px' }}>
                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2">  <Translate text={'noRecordAvailable'} /></div>
                                                    </div>
                                                </div>
                                            </>

                                    }
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                <PopUpModal show={this.state.showAdd}>
                    <AddHistory
                        show={this.state.showAdd}
                        selectedData={this.state.selectedData}
                        imagePath={'loan'}
                        historys={historys}
                        currentOdometer={this.props.currentOdometer}
                        handleClose={this.handleHistoryClose}
                        dealersettings={dealersettings}
                    ></AddHistory>
                </PopUpModal>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>


        );
    }
}

export default OdometerHistorys;