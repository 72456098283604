import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'
import _ from 'lodash'
import CommonHelper from '../../services/common';

const ListView = React.memo(({ id, rec, handleNotifyremove, handleNotifyClick, handleNotifySwitchDealer, sidepanelClose, dealersettings }) => {
    return (
        <div className="notify-item-wraper">
            <div className="notify-item-close"><a href="#" onClick={(e) => { handleNotifyremove(e, rec.documentID) }}><i className="ico icon-remove"></i> </a></div>
            <a className={`notify-item d-flex ${rec.isRead === true ? '' : 'notify-unread'}`} href="#"
                onClick={(e) => {
                    e.preventDefault();
                    if (!_.isEmpty(rec.client) && (rec.type !== 'tradeinProOffer')) {
                        Swal.fire({
                            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
                            text: `${CommonHelper.showLocale({ dealersettings }, 'Do you want to switch')} ${rec.client}?`,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then((result) => {
                            if (result.value) {
                                window.firebase.firestore().doc(`/notifications/${rec.documentID}`)
                                    .set({ isRead: true }, { merge: true })
                                    .catch(error => console.log(error));
                                sidepanelClose();
                                handleNotifySwitchDealer(rec.recordID, rec.type, rec.clientID, rec.enquiryID, rec.contactID, rec);
                                // if (rec.type === 'enquiry') {
                                //    handleEnquirySwitchDealer(rec.recordID, rec.type, rec.clientID)
                                // }
                                // else { 
                                //     handleNotifySwitchDealer(rec.recordID, rec.type, rec.clientID);
                                // }
                            }
                        })
                    }
                    else {
                        if (['email'].includes(rec.type)) sidepanelClose();
                        handleNotifyClick(rec.recordID, rec.type, rec.documentID, rec.enquiryID, rec.contactID, rec, dealersettings)
                    }

                }}>
                <div className="mr-3">
                    <div className="notify-icon-circle">
                        {/* {
                                                                                    rec.fromUser ? (<div className="notify-avatar"><img src={images.nouser} alt="" /></div>)
                                                                                        : (<></>)
                                                                                } */}
                        <i className={`ico icon-${(['activity', 'tradeinProactivity', 'serviceactivity'].includes(rec.type) || (['log', 'tradeinProlog', 'servicelog'].includes(rec.type) && _.isEmpty(rec.subType)))
                            ? 'date' :
                            (['log', 'tradeinProlog', 'servicelog'].includes(rec.type)) ?
                                rec.subType :
                                ((rec.type === 'kitchen')
                                    ? 'cafe' :
                                    (rec.type === 'sms'
                                        ? 'sms-inbound' :
                                        (rec.type === 'service'
                                            ? 'service-settings' :
                                            (rec.type === 'tradeinPro' || rec.type === 'tradeinProRequest'
                                                ? CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'tradein-module-icon') :
                                                (rec.type === 'tradeinProOffer'
                                                    ? 'finance' :
                                                    (rec.type === 'aircallMissed' || rec.type === 'callMissed'
                                                        ? 'no-call ico-lg' :
                                                        ((rec.type === 'aircallContact' || rec.type === 'callContact') && rec.callType === 'outbound'
                                                            ? 'call-outbound' :
                                                            ((rec.type === 'aircallContact' || rec.type === 'callContact')
                                                                ? 'call-inbound' :
                                                                ((rec.type === 'smsMissed')
                                                                    ? 'message' :
                                                                    ((rec.type === 'email')
                                                                        ? 'mail-inbox' :
                                                                        'enquiry'
                                                                    ))))))))))}`}></i>
                    </div>
                </div>
                <div>
                    {
                        rec.type === 'email'
                            ?
                            <>
                                <span title={rec.title} className="notify-header">{_.truncate(rec.title, { length: 40 })}</span>
                                <span title={rec.body} className="notify-info">{_.truncate(rec.body, { length: 50 })}</span>
                            </>
                            :
                            <>
                                <span className="notify-header">{rec.title}</span>
                                <span className="notify-info">{rec.body}</span>
                            </>
                    }

                    {
                        rec.client ?
                            (<span className="notify-dealer">{rec.client}</span>) :
                            (<></>)
                    }
                    <OverlayTrigger
                        placement='top-start'
                        overlay={
                            <Tooltip>{rec.addedDate}</Tooltip>
                        }
                    >
                        <div className="notify-date">{rec.dateAge}</div>
                    </OverlayTrigger>{' '}
                </div>
            </a>
        </div>
    )
})

export default ListView