/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import { InputCheckBox } from '../../components';


const InActiveInfo = ({ userid, user, index, handleShow, text, isQueueActive, handleUserQueue, frontdeskQueueActivation, dealersettings }) => {
    const [editshow, setEditshow] = useState(false)
    const [isQueue, setIsQueue] = useState(false)
    const node = useRef();

    useEffect(() => {
        setIsQueue(isQueueActive);
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings }, 'Do you want to') + ` ${text}.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                setIsQueue(checked);
                handleUserQueue(userid);
            }
        })
    }

    return (
        <div className="frontdesk-queue-box frontdesk-flex-queue" onClick={(e) => { e.preventDefault(); handleShow(userid) }}>
            <div className={`frontdesk-avatar ${index === 0 ? 'active' : ''}`}>
                <div className="frontdesk-queue-count"> <span className="badge badge-pill badge-frontdesk-queue ml-1">{index + 1}</span> </div>
                <img src={CommonHelper.showUserAvatar(user.profileImage, user.name)} alt="" className="rounded-circle img-object-fit" />
            </div>
            <div className="frontdesk-queue-detail" >
                <h3>{user.name}</h3>
                <h5>{user.role}</h5>
                {/* <div className="queue-more-ico"> <a href="#" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}><i className="ico icon-more"></i></a> </div> */}
                {
                    frontdeskQueueActivation
                        ?
                        <>
                            <div className="queue-more-switch" onClick={(e) => { e.stopPropagation(); }}>
                                <span className="switch switch-sm float-right">
                                    <InputCheckBox
                                        className={`switch`}
                                        name={`isQueue_${userid}`}
                                        checked={isQueue}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>{text}</Tooltip>
                                        }
                                    >
                                        <label htmlFor={`isQueue_${userid}`}></label>
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </>
                        :
                        <></>
                }
            </div>
        </div>

    )
};

export default InActiveInfo;
