import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import Select, { components } from "react-select";

const CustomSelect = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value || null);
    const [multivalue, setMultiValue] = useState(props?.data?.[props.colDef.fieldDB] || []);
    const selectInputRef = React.createRef();
    const options = props?.dealerSettings?.[`${props?.colDef?.settingsFieldPrefix ? props.data[props?.colDef?.settingsFieldPrefix] : ''}` + props?.colDef?.settingsField]?.map(r => { return { ...r, label: r.name } }) || []

    useEffect(() => {
        if (selectInputRef.current)
            selectInputRef.current.focus();
    }, [])


    const handleChange = (e) => {
        setValue(e?.value);
        props.api.stopEditing();
        if (props.colDef.fieldDB !== props.colDef.field)
            props.data[props.colDef.fieldDB] = e?.value;
        props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (e?.searchlabel || e?.label || null))
        props.api.refreshCells({ force: true });

    };


    const DropdownIndicator = props => {
        return props.hasValue ? <></> : <components.DropdownIndicator {...props} />;
    };

    const multiValueContainer = ({ selectProps, data }) => {
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex(selected => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? "" : ", ";
        const val = `${label}${labelSuffix}`;
        return val;
    };

    // Custom MenuList component that includes a confirm button
    const CustomMenuList = (props) => {
        const {
            children,
            selectProps: { onConfirm },
        } = props;

        return (
            <components.MenuList {...props}>
                {children}
                <div style={{ padding: '8px', borderTop: '1px solid #ccc' }}>
                    <button onClick={onConfirm} className='ag-button ag-standard-button'>Confirm Selection</button>
                </div>
            </components.MenuList>
        );
    };

    const singleComponent = {
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator
    };

    const multiComponent = {
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator,
        MultiValueContainer: multiValueContainer,
        MenuList: CustomMenuList
    };

    const singleStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: ('33px')
        }),
        dropdownIndicator: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0
        }),
        clearIndicator: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    const multiStyles = {
        control: base => ({
            ...base,
            minHeight: ('33px')
        }),
        dropdownIndicator: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0
        }),
        clearIndicator: base => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "90%",
            flexWrap: "nowrap",
            overflow: "hidden",
            display: "flex"
        })
    };

    const customFilter = (option, searchText) => {
        if (
            (typeof (option.data.value) === 'string' && option.data.value.toLowerCase().includes(searchText.toLowerCase())) ||
            (typeof (option.data.label) === 'string' && option.data.label.toLowerCase().includes(searchText.toLowerCase())) ||
            (typeof (option.data.searchlabel) === 'string' && option.data.searchlabel.toLowerCase().includes(searchText.toLowerCase()))
        ) {
            return true;
        } else {
            return false;
        }
    }

    const handleMultiChange = (selectedOptions) => {
        const _value = [];
        const _label = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            _value.push(data.value)
            _label.push(data.label)
        })
        setMultiValue(_value);
    };

    const handleConfirm = () => {
        props.api.stopEditing();
        props.data[props.colDef.fieldDB] = (multivalue || null);
        props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, _.filter(options, (v) => _.indexOf(multivalue, v.value) >= 0)?.map(r => { return r.value })?.join(',') || '')
    }

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            <Select
                ref={selectInputRef}
                id={props.colDef.field}
                options={_.uniqBy(options, 'value')}
                placeholder={'select'}
                className={`basic-single`}
                classNamePrefix={'basic-select'}
                isClearable={true}
                components={props.colDef.isMulti ? multiComponent : singleComponent}
                styles={props.colDef.isMulti ? multiStyles : singleStyles}
                name={props.colDef.field}
                onChange={props.colDef.isMulti ? handleMultiChange : handleChange}
                value={props.colDef.isMulti ? _.filter(options, (v) => _.indexOf(multivalue, v.value) >= 0) : _.find(options, { value: props.data[props.colDef.fieldDB] })}
                isMulti={props.colDef.isMulti}
                isSearchable={true}
                closeMenuOnSelect={props.colDef.isMulti ? false : true}
                hideSelectedOptions={false}
                menuPlacement={'auto'}
                filterOption={customFilter}
                openMenuOnFocus={true}
                onConfirm={handleConfirm}
            />
        </div>
    );
});

export default CustomSelect;