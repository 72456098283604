import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _, { forEach } from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import { InputText, ReactSelect, ReactTimePicker, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { levelOptions, sourceVM } from './viewModel'
import { statusColors } from '../../../../services/enum'
import CommonHelper from '../../../../services/common';
import { firestoreDB } from '../../../../services/helper';
import { userLevelOptions } from '../../../eventsPro/viewModel';

const AddSource = (props) => {
    const [source, setSource] = useState(props.source)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [events, setEvents] = useState([])
    const [eventLoader, seteventLoader] = useState(false)
    const newSource = !_.isEmpty(props.source) ? false : true;
    const allClients = props.dealersettings.clients ? props.dealersettings.clients.filter(c =>
        c.moduleSettings
        && c.moduleSettings.eventsPro
        && c.moduleSettings.eventsPro.enabled === true
        && c.moduleSettings.eventsPro.active === true
    ) : []

    const eventStatus = (
        !_.isEmpty(props?.dealersettings?.client?.eventsPro?.eventsProStatus) ? props.dealersettings.client.eventsPro.eventsProStatus
            .filter(a => a.value !== 'closed')
            .map(r => {
                return r.value
            }) : ['draft', 'live', 'published'])

    const allStatus = (
        !_.isEmpty(props?.dealersettings?.client?.eventsPro?.eventsProStatus) ? props.dealersettings.client.eventsPro.eventsProStatus : [])

    useEffect(() => {
        if (_.isEmpty(props.source)) {
            const newSourceVM = Object.assign({}, sourceVM);
            newSourceVM.documentID = window.firebase.firestore().collection(`eventsProTPSource`).doc().id;
            newSourceVM.addedBy = localStorage.uid;
            newSourceVM.modifiedBy = localStorage.uid;
            newSourceVM.addedDate = window.firebase.firestore.Timestamp.now();
            newSourceVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newSourceVM.isDeleted = false;
            newSourceVM.active = true;
            newSourceVM.oemID = props.dealersettings.oemID;
            setSource(newSourceVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(source?.levelIDs))
            return

        const collectionPromise = [];
        let _levels = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: source.level })?.index + 1);
        const allLevelIDs = [{ level: source.level, levelIDs: source?.levelIDs }];
        const _clients = allClients.filter(a => source.level === 'individual' ? source.levelIDs.includes(a.id) : source.levelIDs.includes(a[source.level]))
        console.log(_clients);
        _levels.forEach(rec => {
            allLevelIDs.push({
                level: rec.value,
                levelIDs: _.reject(_.uniqBy(_clients.map(r => { return rec.value === 'individual' ? r.id : r[rec.value] })), _.isEmpty)
            })
        })
        allLevelIDs.forEach(_source => {
            for (let k = 0; k < Math.ceil(_source.levelIDs.length / 10); k++) {
                let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
                    .where('isDeleted', '==', false)
                    .where(`${_source.level === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_source.levelIDs, 10, k + 1))
                    .where('status', 'in', eventStatus)
                    .get()
                collectionPromise.push(refEnquiryData);
            }
        })
        if (collectionPromise.length > 0) {
            seteventLoader(true)
            Promise.all(collectionPromise)
                .then(allDocs => {
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc.docs.map(r => {
                            return {
                                label: <>
                                    {r.data().title} {
                                        _.find(allStatus, { value: r.data()?.status })
                                            ?
                                            <>
                                                <div className={`badge badge-pill badge-status-empty`} style={{ background: (_.find(allStatus, { value: r.data()?.status }).color ? _.find(allStatus, { value: r.data()?.status }).color : '#333') }}>
                                                    {_.find(allStatus, { value: r.data()?.status }).name}
                                                </div>
                                            </>
                                            :
                                            <>{r.data()?.status ? <div className={`badge badge-pill badge-status-${r.data()?.status.toLowerCase()}`} >{r.data()?.status} </div> : <></>}</>
                                    }
                                </>,
                                searchlabel: r.data().title,
                                value: r.id,
                            }
                        }));
                    });
                    setEvents(_.uniqBy(querySnapshot, 'value'));
                    seteventLoader(false)
                });
        }
    }, [source?.levelIDs])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSource({
                ...source,
                [name]: newarray1.join(' '),
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSource({
                ...source,
                [name]: str,
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setSource({
                ...source,
                [data.name]: e.value || null,
                ['levelIDs']: [],
                ['eventsProIDs']: []
            });
        }
        else {
            setSource({
                ...source,
                [data.name]: e.value || null,
            });
        }

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        if (name === 'levelIDs') {
            setSource({
                ...source,
                [name]: value,
                ['eventsProIDs']: []
            });
        } else {
            setSource({
                ...source,
                [name]: value
            });
        }


        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setSource({
            ...source,
            [e.target.name]: e.target.checked
        })
    }

    const handleSubmit = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(source['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(source['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }

        if (_.isEmpty(source['levelIDs'])) {
            formIsValid = false;
            errors['levelIDs'] = errorClass;
        }
        if (_.isEmpty(source['eventsProIDs'])) {
            formIsValid = false;
            errors['eventsProIDs'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (newSource) {
            let apiURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com" : "https://qa.fusionamspro.com";
            let eoi_url = `${apiURL}/attendee/confirmation/source/${source?.documentID}?type=eoi`;
            let registration_url = `${apiURL}/attendee/confirmation/source/${source?.documentID}?type=registration`;
            const getShortURL_EOI = window.firebase.functions().httpsCallable('eventsPro-getShortURL');
            await getShortURL_EOI({ url: eoi_url }).then((response) => {
                source.eoiURL = response?.data
            });
            const getShortURL_REG = window.firebase.functions().httpsCallable('eventsPro-getShortURL');
            await getShortURL_REG({ url: registration_url }).then((response) => {
                source.registrationURL = response?.data
            });
        }
        window.firebase.firestore().doc(`eventsProTPSource/${source.documentID}`)
            .set(_.pick(source, _.keys(sourceVM)), { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newSource === true ? 'Source added successfully' : 'Source updated successfully'), {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(source) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={source.name}
                                        maxLength={500}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={source.level}
                                        classNamePrefix={`${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    !_.isEmpty(source.level) ? (<div className="form-group col-md-12">
                                        <label ><Translate text={source.level === 'individual' ? 'Client' : CommonHelper.autoCaps(source.level)} /></label>
                                        <ReactMultiSelect
                                            options={props[`${source.level}Options`] || []}
                                            name={'levelIDs'}
                                            placeholder={`select ${source.level === 'individual' ? 'client' : source.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelIDs') }}
                                            value={source.levelIDs || []}
                                            classNamePrefix={`${errorFields["levelIDs"]} basic-select`}
                                            removeClearable={true}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'events'} /></label>
                                    {
                                        eventLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <ReactMultiSelect
                                        options={events}
                                        name={'eventsProIDs'}
                                        placeholder={`select event`}
                                        onChange={(rec) => { handleReactMultiSelectChange(rec, 'eventsProIDs') }}
                                        value={source.eventsProIDs || []}
                                        classNamePrefix={`${errorFields["eventsProIDs"]} basic-select`}
                                        removeClearable={true}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`source-active`}
                        name={'active'}
                        checked={source.active ? source.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`source-active`}><Translate text={'active'} /></label>
                </span>
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSubmit(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSource;