import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import images from '../../images';
import CommonHelper from '../../services/common';
 
const EventView = (props) => {
    const [event, setEvent] = useState(props.event)
    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;

    return _.isEmpty(event) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-calendar-event-info"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="events-dashboard-livewrap mb-2">
                    <div className="events-dashboard-live-flex">
                        <div className="events-dashboard-live-image">
                            {
                                event.imageURL ? (
                                    <img src={event.imageURL} alt="" width="70" height="70" />
                                ) : (
                                    <img src={images.noEvent} alt="" width="70" height="70" />
                                )
                            }
                        </div>
                        <div className="events-dashboard-live-data">
                            <div className="events-dashboard-card-title">{event.title}</div>
                            <div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {event.location} </div>
                            <div className="events-dashboard-card-sub">
                                <i className="ico icon-date"></i> {CommonHelper.showCombineDate(event.startDate, event.endDate)}
                                <i className="ico icon-time ml-2"></i> {CommonHelper.showCombineTime(event.startDate, event.endDate)}
                                </div>
                            <div className="events-dashboard-card-sub">
                                {
                                    _.find(eventsProStatus, { value: event.status }) ? (
                                        <div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: event.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: event.status })?.color ? _.find(eventsProStatus, { value: event.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: event.status }).name} </div>
                                    ) : (
                                        <div className={`badge badge-pill badge-status-${event.status.toLowerCase()}`} >{event.status} </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="events-dashboard-live-status"></div>
                    <ul className="events-dashboard-stat-details">
                        <li>
                            <div className="events-stat-column">
                                <div className="events-stat-icon events-stat-blue"> <i className="ico icon-events-invitation-fix"></i> </div>
                                <div className="events-stat-right events-stat-blue">
                                    <div className="events-stat-title">Invitations</div>
                                    <div className="events-stat-count">{event?.counts?.invitations > 0 ? event?.counts?.invitations : 0}</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="events-stat-column">
                                <div className="events-stat-icon events-stat-green"> <i className="ico icon-sent"></i> </div>
                                <div className="events-stat-right">
                                    <div className="events-stat-title">Checked-In</div>
                                    <div className="events-stat-count">{event?.counts?.checkedIn > 0 ? event?.counts?.checkedIn : 0}</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="events-stat-column">
                                <div className="events-stat-icon events-stat-orange"> <i className="ico icon-events-new"></i> </div>
                                <div className="events-stat-right">
                                    <div className="events-stat-title">New</div>
                                    <div className="events-stat-count">{event?.counts?.newAttendees > 0 ? event?.counts?.newAttendees : 0}</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="events-stat-column">
                                <div className="events-stat-icon events-stat-skyblue"> <i className="ico icon-events-testdrive"></i> </div>
                                <div className="events-stat-right">
                                    <div className="events-stat-title">Test Drive</div>
                                    <div className="events-stat-count">{event?.counts?.testDrive > 0 ? event?.counts?.testDrive : 0}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/eventspro/details/" + event.id);
                }}>
                    View Event
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer >
        </Modal >
    );
}

export default EventView;