import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import _ from 'lodash'
import { allTIPFields } from '../tradeinplus/viewModel'
import CommonHelper from '../../services/common';
import { ContentOverlay, TableView, SidePanel, PopoverPanel, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import QuickView from "../tradeinplus/quickview";
import _images from '../../images'
import { tipStatus } from '../../services/enum';

import ReAuthenticate from '../common/reAuthenticate';

import Filters from '../tradeinplus/filters'
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const AppraisalList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();

    const [hasMore, setHasMore] = useState([1])

    const [enquiries, setEnquiries] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [tradeinproFields, setTradeinProFields] = useState(props.dealersettings.tradeinProFields)
    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-tradeinpro-quickview' })
    const [csvHeader, setHeader] = useState([])
    const [selectedIds, setSelectedIds] = useState({})

    const [pageNum, setPageNum] = useState(0);
    const [filterpageNum, setfilterPageNum] = useState(0);

    const [loader, setLoader] = useState(true);
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [showReAuthentication, setReAuthentication] = useState(false);

    const fetchEnquiries = async (type = 'generic', _pageNum, localFilter, isNoLoad) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let clientID = CommonHelper.getTradeinProDealers(props.dealersettings, false, true);
        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        let filters = props.filter

        let _filter = {
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.map(item => item.value).join(',')
        }
        if (props.selectedClient) {
            clientID = props.selectedClient.value
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (filters && filters.status && filters.status !== 'all') {
            _filter.status = filters.status
        }
        if (filters && filters.make && filters.make !== 'all') {
            _filter.make = filters.make
        }
        if (props.dateRange && props.dateRange.startDate && props.dateRange.endDate && filters?.type !== 'unattended') {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && filters.startDate.toDate && filters.endDate.toDate) {
            _filter.startDate = moment(filters.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && !filters.startDate.toDate) {
            _filter.startDate = moment(filters.startDate).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate).format('YYYY-MM-DD')
        }
        if (filters && filters.stage) {
            _filter.stage = filters.stage
            // _filter.status = filters.stage
        }
        if (filters && filters.dateType) {
            _filter.dateType = filters.dateType
        }

        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(','),
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
        }
        else if (props.isFromDashboard) {
            _filter.fromDashboard = 'true';
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (filters && filters.activityOption) {
            delete _filter.startDate;
            delete _filter.endDate;
            if (_.isEmpty(props.selectedPipeline)) {
                delete _filter.pipeline;
            }
            _filter.activityOption = filters.activityOption
        }

        let _searchObject = {
            "type": "searchTradeinpro",
            "filters": _filter,
            "sortOrder": `stageDate desc`,
            "pageNum": num,
            pageLimit,
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 5000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject.clientID = _filter.clientID ? _filter.clientID : clientID;
        if (type === 'generic' && !isNoLoad) {
            setLoader(true)
        }
        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', resp, _searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)
            let _pageNum = num + 1;
            let enquiries_ = resp.data.data.map(a => CommonHelper.convertTradeinProVM(a, { ...props }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setEnquiries(enquiries_)
                setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - appraisal',
                    type: 'excelExport',
                    subType: 'tradeinPro',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (!_.isEmpty(localFilter)) {
                    if (type === 'paging') {
                        setEnquiries(_.uniqBy([...enquiries, ...enquiries_], 'documentID'))
                    } else {
                        setEnquiries(enquiries_)
                    }
                } else {
                    setEnquiries(_.uniqBy([...enquiries, ...enquiries_], 'documentID'))
                }
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            console.log('error: ', resp.data.message)
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
        }
    }

    const fetchDirectExcel = () => {
        // console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard) {
                    if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
                        fetchEnquiries('excel', filterpageNum, pipelineFilter.value)
                    } else {
                        fetchEnquiries('excel', pageNum)
                    }
                }
                else
                    fetchDirectExcel();

            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchEnquiries('generic', pageNum)
        }
    }, [])


    const updateListEnquiry = (objEnquiry) => {
        //console.log('updateListEnquiry', objEnquiry)
        props.reloadData && props.reloadData();
        if (!_.isEmpty(objEnquiry)) {
            //     let enq = CommonHelper.convertServiceJobsVM(objEnquiry, { ...props });

            //     if (!_.isEmpty(enq)) {
            //         //fetchEnquiries('generic', pageNum, null, true)
            //         let _enquiries = enquiries.map((item) => {
            //             if (item.documentID === enq.documentID) {
            //                 return enq;
            //             }
            //             return item;
            //         });
            //         setEnquiries(_enquiries)
            //     }
            // }
            // else {
            fetchEnquiries('generic', 0, null, true)
        }

    }


    useEffect(() => {
        let headerFields = tradeinproFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allTIPFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allTIPFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allTIPFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));
        //setHeader(_headers);

    }, [tradeinproFields])

    useEffect(() => {

        const enquiryOptions = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.tradeinPro)) ? props.dealersettings.client.tradeinPro.enquiryOptionsDF : []);
        enquiryOptions.forEach(rec => {
            if (!allTIPFields.some(e => e.value === rec.value))
                allTIPFields.push({
                    name: rec.name,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        tradeinproFields.forEach(rec => {
            if (!allTIPFields.some(e => e.value === rec))
                deleteids.push(rec)
        })

        const tradeinProAllStatus = Object.assign([], props.dealersettings?.client?.tradeinPro?.tradeinProStatus)
        allTIPFields.forEach((obj) => {
            if (obj.value === 'wonDate') {
                obj.name = CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON) + ' Date';
            }
            else if (obj.value === 'completedDate') {
                obj.name = CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.COMPLETED, tipStatus.COMPLETED) + ' Date';
            }
            else if (obj.value === 'lostDate') {
                obj.name = CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST) + ' Date';
            }
        });

        setTradeinProFields(_.differenceBy(tradeinproFields, deleteids))
        if (!props.isFromDashboard) {
            setLoader(false)
        }
    }, [props.dealersettings])


    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            tradeinproid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            tradeinproid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        if (!fields.some(e => e === 'checkbox')) {
            fields.push('checkbox')
        }
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ tradeinProFields: fields }, { merge: true })
            .then(() => {
                setTradeinProFields(fields)
                setSettingsLoader(false)
                setLoader(true)
                props.updateDealerSettings('tradeinProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(true)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" data-id={id} onClick={(e) => {

            sidepanelOpen(id)

        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, data) => {

        sidepanelOpen(id)


    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0]);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }



    const handleFavorite = (id, val, data) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val

            firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('Enquiry updated successfully', {
                    //     duration: 2000
                    // })
                    setEnquiries(enquiries.map(rec => {
                        return rec.documentID === id ? {
                            ...rec,
                            isFav: val
                        } : { ...rec }
                    }))
                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }



    const handlePagination = () => {
        if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
            fetchEnquiries('paging', filterpageNum, pipelineFilter.value)
        } else {
            if (enquiries.length >= props.counts) {
                return
            }
            fetchEnquiries('paging', pageNum)
        }
    }

    const handlesearchText = (text) => {
        // console.log('handlesearchText', text)
        if (text) {
            fetchEnquiries('generic', 0, { keywords: text })
            setPipelineFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setPipelineFilter({})
            fetchEnquiries('generic', pageNum)
        }
    }

    const handleApplyFilter = (_filter) => {
        // console.log('handleApplyFilter', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchEnquiries('generic', 0, _filter.value)
            setPipelineFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        //    console.log('handleClearFilter')
        if (!_.isEmpty(pipelineFilter)) {
            setPipelineFilter({})
            fetchEnquiries('generic', pageNum)
            setFilterApplied(false);
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
        }
    }



    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions)) ? props.dealersettings.rolePermissions.tradeinProPermissions : null);


    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>



                                <div className="float-right">

                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchDirectExcel();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvDirectBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`tradeinpro_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(enquiries, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {
                                                                fetchEnquiries('excel', filterpageNum, pipelineFilter.value)
                                                            } else {
                                                                fetchEnquiries('excel', pageNum)
                                                            }
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`tradeinpro_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(enquiries, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>                                                        
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                                {props.isFromDashboard && (
                                    <Filters
                                        isFromDashboard={true}
                                        searchKeywords={searchKeywords}
                                        pipelineFilter={pipelineFilter}
                                        customFilters={customFilters}
                                        dealersettings={props.dealersettings}
                                        handleApplyFilter={handleApplyFilter}
                                        handleClearFilter={handleClearFilter}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        isFilterApplied={isFilterApplied}
                                        noSaveAccess={true}
                                    >
                                    </Filters>
                                )}
                            </div>
                            {props.isFromDashboard ? loader ? (
                                <>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                            ) : (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={_.orderBy(enquiries, ['stageDateValue'], ['desc'])}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allTIPFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(tradeinproFields && tradeinproFields.length > 0) ? tradeinproFields.filter(item => props.filter && Boolean(props.filter.enableMulticheck) ? true : item !== 'checkbox') : allTIPFields.filter(e => e.default === true && props.filter && (Boolean(props.filter.enableMulticheck) ? true : e.value !== 'checkbox')).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        handleFavorite={handleFavorite}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        selectedIds={selectedIds}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    {
                                        loader ? (<></>) : (<TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(enquiries, ['stageDateValue'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={allTIPFields}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(tradeinproFields && tradeinproFields.length > 0) ? tradeinproFields.filter(item => props.filter && Boolean(props.filter.enableMulticheck) ? true : item !== 'checkbox') : allTIPFields.filter(e => e.default === true && props.filter && (Boolean(props.filter.enableMulticheck) ? true : e.value !== 'checkbox')).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={enquiryDetailclick}
                                            isSettings={true}
                                            handleFavorite={handleFavorite}
                                            handleAllChecked={handleAllChecked}
                                            handleCheckChange={handleCheckChange}
                                            selectedIds={selectedIds}
                                            displayByValue={true}
                                        />)
                                    }
                                </div>
                            )}
                            {
                                showpanel.clsActive ? (

                                    <SidePanel
                                        clsActive={showpanel.clsActive}
                                        clsName={showpanel.clsName}
                                        sidepanelClose={sidepanelClose}
                                        title=''
                                    >
                                        <QuickView {...props}
                                            tradeinproid={showpanel.tradeinproid}
                                            isFilterApplied={true}
                                            updateListEnquiry={updateListEnquiry}
                                            sidepanelClose={sidepanelClose}
                                        />
                                    </SidePanel>

                                ) : (<></>)
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

        </>
    );
}

export default AppraisalList;