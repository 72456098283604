import React, { useState, useEffect, useRef, useReducer } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix';
import _ from 'lodash'
import { allQuoteFields } from '../quote/viewModel'
import CommonHelper from '../../services/common';
import { TableView, SidePanel, PopUpModal } from '../../components'
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import _images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const QuoteList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [quoteList, setQuoteList] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [loader, setLoader] = useState(false);
    const [pageNum, setPageNum] = useState(0);
    const [quoteFilter, setquoteFilter] = useState({})
    const [filterpageNum, setfilterPageNum] = useState(0);

    useEffect(() => {
        if (_.isEmpty(quoteList))
            return
        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            { title: 'Quote No', dynValue: 'csvQuoteNo', dynWidth: 10, ...csvStyle.header },
            { title: 'Contact Name', dynValue: 'contactName', dynWidth: 10, ...csvStyle.header },
            { title: 'Vehicle Model', dynValue: 'vehicleModel', dynWidth: 10, ...csvStyle.header },
            { title: 'Sales Type', dynValue: 'vehicleSaletype', dynWidth: 10, ...csvStyle.header },
            { title: 'Quote Amount', dynValue: 'csvQuoteAmount', dynWidth: 10, ...csvStyle.header },
            { title: 'Created On', dynValue: 'createdOn', dynWidth: 10, ...csvStyle.header },
            { title: 'Created By', dynValue: 'addedBy', dynWidth: 10, ...csvStyle.header },
            { title: 'Updated On', dynValue: 'updatedOn', dynWidth: 10, ...csvStyle.header },
            { title: 'Updated By', dynValue: 'modifiedBy', dynWidth: 10, ...csvStyle.header },
        )
        var exportData = []
        quoteList.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue] ? obj[col.dynValue] : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return (h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth) ? {
                    ...h,
                    'width': { wch: w.dynWidth + 2 }
                } : h.width ? { ...h } : {
                    ...h,
                    'width': { wch: h.dynWidth + 2 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])

    }, [quoteList])

    const fetchAct = async (type = 'generic', _pageNum, localFilter) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        let filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        let _filter = {
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(',')
            }
        }
        _filter = {
            ..._filter
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
       
        let _searchObject = {
            "type": "searchQuotations",
            "filters": JSON.stringify(_filter),
            "sortOrder": `addedDate desc`,
            "pageNum": num,
            pageLimit
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 1000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);
        if (type === 'generic') {
            setLoader(true)
        }
        const searchActivities = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchActivities(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _pageNum = num + 1;
            let list_ = resp.data.data.map(a => CommonHelper.convertQuoteVM(a, { ...props }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setQuoteList(list_)
                setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - activities',
                    type: 'excelExport',
                    subType: 'activities',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (!_.isEmpty(localFilter)) {
                    if (type === 'paging') {
                        setQuoteList(_.uniqBy([...quoteList, ...list_], 'documentID'))
                    } else {
                        setQuoteList(list_)
                    }
                } else {
                    setQuoteList(_.uniqBy([...quoteList, ...list_], 'documentID'))
                }
                // setActivities(_.uniqBy([...activitylogs, ...activities_], 'documentID'))
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            console.log('error: ', resp.data.message)
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
        }
    }

    const fetchDirectExcel = () => {
        console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        csvDirectBtn.current && csvDirectBtn.current.link.click();
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard)
                    if (!_.isEmpty(quoteFilter) && !_.isEmpty(quoteFilter.value)) {
                        fetchAct('excel', filterpageNum, quoteFilter.value)
                    } else {
                        fetchAct('excel', pageNum)
                    }
                else
                    fetchDirectExcel();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchAct('generic', pageNum)
        }
    }, [])

    const handlePagination = () => {
        if (!_.isEmpty(quoteFilter) && !_.isEmpty(quoteFilter.value)) {
            fetchAct('paging', filterpageNum, quoteFilter.value)
        } else {
            if (quoteList.length >= props.counts) {
                return
            }
            fetchAct('paging', pageNum)
        }
        // fetchAct('paging')
    }

    useEffect(() => {
        if (_.isEmpty(props.filter) || props.isFromDashboard) {
            return;
        }

        var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }
        let list = []
        list = props.dataList.filter(a =>
            (!_.isEmpty(props.filter.documentIDs) ? props.filter.documentIDs.some(e => e === a.documentID) : true)
            && (!_.isEmpty(searchText) ?
                (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.enqOwner) ? a.enqOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.displayID) ? a.displayID.toLowerCase().includes(searchText.toLowerCase()) : false)
                : true)
        );
        setCSVData(CommonHelper.generateExcelData(list, csvHeader))
        setQuoteList(list);

    }, [props.dataList, searchText, csvHeader])


    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div title="View Enquiry" style={{ cursor: 'pointer' }} className="mini-button mini-tradein" onClick={(e) => {
                    sidepanelOpen(data.enquiryID)
                }}>
                    <i className="ico icon-more"></i>
                </div>
            </div>
        )
    }

    const handleDetailclick = (e, id, data) => {
        sidepanelOpen(data.enquiryID)
    }



    const handlesearchText = (text) => {
        if (text) {
            fetchAct('generic', 0, { keywords: text })
            setquoteFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setquoteFilter({})
            fetchAct('generic', pageNum)
        }
    }

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div className="float-right">
                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn ">
                                                        <ExcelFile element={<a href="#" ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!_.isEmpty(quoteFilter) && !_.isEmpty(quoteFilter.value)) {
                                                                fetchAct('excel', filterpageNum, quoteFilter.value)
                                                            } else {
                                                                fetchAct('excel', pageNum)
                                                            }
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                            {
                                props.isFromDashboard ? loader ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (<div className="common-table">
                                    <TableView
                                        datalist={_.orderBy(quoteList, ['addedDate'], ['desc'])}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allQuoteFields}
                                        dynamicFields={allQuoteFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        showSNo={true}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                    />
                                </div>) : (<div className="common-table">
                                    <TableView
                                        datalist={_.orderBy(quoteList, ['addedDate'], ['desc'])}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allQuoteFields}
                                        dynamicFields={allQuoteFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                    />
                                </div>)
                            }
                            {
                                showpanel.clsActive ? (
                                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers} history={props.history}>
                                        <StageConsumer>
                                            {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, listenScrollEvent, stageLoading, updateEnquiry, sectionloading }) => (
                                                <SidePanel
                                                    clsActive={showpanel.clsActive}
                                                    clsName={showpanel.clsName}
                                                    sidepanelClose={sidepanelClose}
                                                    title=''
                                                >
                                                    <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={true} sidepanelClose={sidepanelClose} />
                                                </SidePanel>
                                            )}
                                        </StageConsumer>
                                    </StageProvider>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
        </>
    );
}

export default QuoteList;