/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
/** COMPONENTS */
import Translate from '../../constants/translate';
import { default as _images } from '../../images';

export default class SignOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        const { show, menu, title, hideCancel } = this.props;

        return (
            <>

                <Modal
                    id="choose-options-terms"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-replace-contact">
                    {
                        hideCancel
                            ?
                            <Modal.Header closeButton>
                                <Modal.Title><Translate text={title ? title : 'Choose Option'} /> </Modal.Title>
                            </Modal.Header>
                            :
                            <Modal.Header>
                                <Modal.Title><Translate text={title ? title : 'Choose Option'} /> </Modal.Title>
                            </Modal.Header>
                    }

                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <ul className="replace-contact">

                                    {
                                        menu && menu.map((item, index) => {
                                            return <li key={index}> <a href="#" onClick={(e) => { e.preventDefault(); this.props.handleClose(item.value); }}>
                                                <i className={item.ico}> </i>
                                                {/* <i className="mdi mdi-account-arrow-right"> </i> */}
                                                <span className="replace-head"><Translate text={item.label} /></span>
                                                <span className="replace-sub"><Translate text={item.text} /></span>
                                            </a>
                                            </li>
                                        })
                                    }
                                    {
                                        hideCancel
                                            ?
                                            <></>
                                            :
                                            <>
                                                <li> <a href="#" onClick={(e) => { e.preventDefault(); this.props.handleClose(null); }}>
                                                    <i className="ico icon-close-circle"> </i>
                                                    <span className="replace-head"><Translate text={'cancel'} /></span>
                                                    <span className="replace-sub"><Translate text={'Do nothing'} /></span>
                                                </a>
                                                </li>
                                            </>
                                    }

                                </ul>

                            </div>
                        </div>
                    </Modal.Body>


                </Modal>
            </>


        );
    }
}

