import React from 'react';
import _ from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import { ReactSelect, InputMultipleText, InputCheckBox, ReactTimePicker, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { sidePhotosVM } from '../../../tradeIn/viewModel'
import images from '../../../../images';
import CommonHelper from '../../../../services/common';

const taxTypes = [
    { label: 'GST', value: 'GST' },
    { label: 'VAT', value: 'VAT' },
    { label: 'TAX', value: 'TAX' }
]

const reportTypes = [
    { value: 'report', label: 'Generic' },
    { value: 'summary', label: 'Summary' },
    { value: 'union', label: 'Union' },
    { value: 'tonyleahey', label: 'Tony Leahey' },
    { value: 'adamas', label: 'Adamas' },
    { value: 'bmw', label: 'BMW' },
    { value: 'porsche', label: 'Porsche' },
    { value: 'gulson', label: 'Gulson' }
]

const AppSettings = (props) => {
    const currencySymbol = !_.isEmpty(props.dealer?.currency?.symbol) ? props.dealer.currency.symbol + ' ' : '$ ';

    const getphoneFormat = (phoneFormat) => {
        if (_.isEmpty(phoneFormat?.format)) {
            return <></>
        }

        return <>({phoneFormat?.code && props?.dealer?.phoneCode ? `+${props.dealer.phoneCode} ` : ''}{phoneFormat?.zeroPrefix ? `0` : ''}{<NumberFormat
            value={'xxxxxxxxxx'}
            displayType={'text'}
            format={`${phoneFormat?.format || '### ### #########'}`}
        />})</>
    }

    return (<>
        <div className="settings-inner form-style">

            <div className="form-row">
                <div className={`form-group col-md-3`}>
                    <h5 className="settings-subhead"><Translate text={'Currency'} /></h5>
                    <ReactSelect
                        options={!_.isEmpty(props.currencies) ? _.map(props.currencies, function (e) {
                            return {
                                label: `${e.name} (${e.code})`,
                                value: e.code,
                                data: e,
                                active: true,
                            }
                        }) : []}
                        name={"currency"}
                        placeholder={'select currency'}
                        onChange={props.handleReactSelectChange}
                        value={!_.isEmpty(props.dealer.currency) ? props.dealer.currency.code : ''}
                        classNamePrefix={`cursor-pointer basic-select`}
                        removeClearable={true}
                    >
                    </ReactSelect>
                </div>
                {
                    props.sales === true ? (<div className="form-group col-md-3">
                        <h5 className="settings-subhead"><Translate text={'Stock VBM Currency'} /></h5>
                        <ReactSelect
                            options={!_.isEmpty(props.currencies) ? _.map(props.currencies, function (e) {
                                return {
                                    label: `${e.name} (${e.code})`,
                                    value: e.code,
                                    data: e,
                                    active: true,
                                }
                            }) : []}
                            name={"vbm"}
                            placeholder={'select vbm currency'}
                            onChange={props.handleReactSelectChange}
                            value={!_.isEmpty(props.dealer.vbm) ? props.dealer.vbm.code : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>) : (<></>)
                }
                {
                    props.service === true ? (<div className="form-group col-md-3">
                        <h5 className="settings-subhead"><Translate text={'Recommendation Text'} /></h5>
                        <input
                            type="text"
                            autoComplete="off"
                            placeholder={'recommendation text'}
                            className={`form-control`}
                            name="recommendationName"
                            onChange={(e) => {
                                props.handleTextChange(e, 'service')
                            }}
                            value={props?.dealer?.clientSettings?.service?.recommendationName || ''}
                        />
                    </div>) : (<></>)
                }
                <div className="form-group col-md-6">
                    <h5 className="settings-subhead">&nbsp;</h5>
                    <button type="button" className="btn btn-primary float-right" onClick={() => props.saveDealer()}>
                        {
                            props.btnLoader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                </div>
            </div>
            {
                props.sales === true ? (<>
                    <h5 className="settings-subhead"><Translate text={'settings'} /></h5>
                    <div className="switch-list">
                        <div className="row">
                            <div className="col-sm-6">
                                <ul>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="lead-verification-settings"
                                            className="switch"
                                            name={'leadVerification'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.leadVerification) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="lead-verification-settings"></label>
                                    </span> <Translate text={'leadVerification'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="lost-approval-settings"
                                            className="switch"
                                            name={'lostApproval'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.lostApproval) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="lost-approval-settings"></label>
                                    </span> <Translate text={'Lost Approval'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="new-next-activity"
                                            className="switch"
                                            name={'isNextActivityMandatoryNE'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.isNextActivityMandatoryNE) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="new-next-activity"></label>
                                    </span> <Translate text={'Next Activity Mandatory For New Enquiry'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="schedule-next-activity"
                                            className="switch"
                                            name={'isScheduleNextActivity'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.isScheduleNextActivity) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="schedule-next-activity"></label>
                                    </span> <Translate text={'Schedule Next Activity After Complete'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="mand-schedule-next-activity"
                                            className="switch"
                                            name={'mandScheduleNextActivity'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.mandScheduleNextActivity) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="mand-schedule-next-activity"></label>
                                    </span> <Translate text={'Mandatory Schedule Next Activity On Open / Won Status'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-2way-sms"
                                            className="switch"
                                            name={'enableTwoWaySMS'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableTwoWaySMS) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-2way-sms"></label>
                                    </span> <Translate text={'Enable Two Way SMS'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-FD-Inbound-Pipeline"
                                            className="switch"
                                            name={'enableFDInboundPipeline'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableFDInboundPipeline) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-FD-Inbound-Pipeline"></label>
                                    </span><Translate text={'Enable Inbound Pipeline in Front Desk'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="disable-trade-in-send-link"
                                            className="switch"
                                            name={'disableTradeInLink'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.disableTradeInLink) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="disable-trade-in-send-link"></label>
                                    </span><Translate text={'Disable Trade-In Send Link'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="current-status-in-report"
                                            className="switch"
                                            name={'calcCurrentStatus'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.calcCurrentStatus) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="current-status-in-report"></label>
                                    </span><Translate text={'Show Current Won/Sold Status in Reports'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-vin-scan"
                                            className="switch"
                                            name={'defaultVINScan'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.defaultVINScan) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-vin-scan"></label>
                                    </span> <Translate text={'Enable Default VIN Scan on Trade-In'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="disableVerbalConsent-settings"
                                            className="switch"
                                            name={'disableVerbalConsent'}
                                            checked={Boolean(props.dealer?.clientSettings?.disableVerbalConsent)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="disableVerbalConsent-settings"></label>
                                    </span> <Translate text={'Disable Verbal Consent on Save Contact'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="consentVerbalEmail-settings"
                                            className="switch"
                                            name={'consentVerbalEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.consentVerbalEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="consentVerbalEmail-settings"></label>
                                    </span> <Translate text={'Email Verbal Consent'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="tdStartTermsEmail-settings"
                                            className="switch"
                                            name={'tdStartTermsEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.tdStartTermsEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="tdStartTermsEmail-settings"></label>
                                    </span> <Translate text={'Email terms on test drive started'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="tdCompleteTermsEmail-settings"
                                            className="switch"
                                            name={'tdCompleteTermsEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.tdCompleteTermsEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="tdCompleteTermsEmail-settings"></label>
                                    </span> <Translate text={'Email terms on test drive completetion'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="soldTDWalkin-settings"
                                            className="switch"
                                            name={'soldTDWalkin'}
                                            checked={Boolean(props.dealer?.clientSettings?.soldTDWalkin)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="soldTDWalkin-settings"></label>
                                    </span> <Translate text={'Count sold/test drives walkins only in all reports'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="hideContinueWithNewEnquiry-settings"
                                            className="switch"
                                            name={'hideContinueWithNewEnquiry'}
                                            checked={Boolean(props.dealer?.clientSettings?.hideContinueWithNewEnquiry)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="hideContinueWithNewEnquiry-settings"></label>
                                    </span> <Translate text={'Disable continue with new enquiry option on customer exists'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="includeInsWaiverInTDTerms-settings"
                                            className="switch"
                                            name={'includeInsWaiverInTDTerms'}
                                            checked={Boolean(props.dealer?.clientSettings?.includeInsWaiverInTDTerms)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="includeInsWaiverInTDTerms-settings"></label>
                                    </span> <Translate text={'Include Insurance Waiver In Test Drive Terms'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="mandTradeinStatusonWon-settings"
                                            className="switch"
                                            name={'mandTradeinStatusonWon'}
                                            checked={Boolean(props.dealer?.clientSettings?.mandTradeinStatusonWon)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="mandTradeinStatusonWon-settings"></label>
                                    </span> <Translate text={'Mandatory select Tradein status on won'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="scheduleActWithInWHrs-settings"
                                            className="switch"
                                            name={'scheduleActWithInWHrs'}
                                            checked={Boolean(props.dealer?.clientSettings?.scheduleActWithInWHrs)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="scheduleActWithInWHrs-settings"></label>
                                    </span> <Translate text={'Schedule activity within working hours'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="disableToAddInboundLead-settings"
                                            className="switch"
                                            name={'disableToAddInboundLead'}
                                            checked={Boolean(props.dealer?.clientSettings?.disableToAddInboundLead)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="disableToAddInboundLead-settings"></label>
                                    </span> <Translate text={'Disable To Add Inbound Lead'} /> </li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <ul>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-Cust-Show-No-Show"
                                            className="switch"
                                            name={'enableCustShowNoShow'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableCustShowNoShow) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-Cust-Show-No-Show"></label>
                                    </span> <Translate text={'Enable Customer Show No-show'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-inbound-prospect-lost"
                                            className="switch"
                                            name={'enableProspectLost'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableProspectLost) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-inbound-prospect-lost"></label>
                                    </span> <Translate text={'Enable Inbound Prospect Lost'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="mand-activity-outcome"
                                            className="switch"
                                            name={'mandActivityOutcome'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.mandActivityOutcome) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="mand-activity-outcome"></label>
                                    </span> <Translate text={'Mandatory Activity Outcome On Complete'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="mand-activity-notes"
                                            className="switch"
                                            name={'mandActivityNotes'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.mandActivityNotes) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="mand-activity-notes"></label>
                                    </span>  <Translate text={'Mandatory Notes On Activity Complete'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="include-inbound-lead-report"
                                            className="switch"
                                            name={'inclInboundLeadsinRpt'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.inclInboundLeadsinRpt) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="include-inbound-lead-report"></label>
                                    </span> <Translate text={'Include Inbound Leads in Report'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="include-inbound-bucket-report"
                                            className="switch"
                                            name={'inclInboundBuckeinRpt'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.inclInboundBuckeinRpt) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="include-inbound-bucket-report"></label>
                                    </span> <Translate text={'Include Inbound Stage in Report'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="separate-cic-overdue-activity"
                                            className="switch"
                                            name={'separateInbdUserAct'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.separateInbdUserAct) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="separate-cic-overdue-activity"></label>
                                    </span> <Translate text={'Separate Inbound User Activities on Dashboard'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-classic-view"
                                            className="switch"
                                            name={'classicView'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.classicView) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-classic-view"></label>
                                    </span> <Translate text={'Enable Classic View'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-new-td"
                                            className="switch"
                                            name={'enableNewTD'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableNewTD) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-new-td"></label>
                                    </span> <Translate text={'Enable Add New Test Drive Option'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-subleads-on-won"
                                            className="switch"
                                            name={'enableSubLeadsonWon'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableSubLeadsonWon) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-subleads-on-won"></label>
                                    </span> <Translate text={'Creating Sub Leads When More Than One Vehicle Maked as Won'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="consentSignedEmail-settings"
                                            className="switch"
                                            name={'consentSignedEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.consentSignedEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="consentSignedEmail-settings"></label>
                                    </span> <Translate text={'Email Customer Signed Consent'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="consentDigitalEmail-settings"
                                            className="switch"
                                            name={'consentDigitalEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.consentDigitalEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="consentDigitalEmail-settings"></label>
                                    </span> <Translate text={'Email Digital Consent'} /></li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="loanTermsEmail-settings"
                                            className="switch"
                                            name={'loanTermsEmail'}
                                            checked={Boolean(props.dealer?.clientSettings?.loanTermsEmail)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="loanTermsEmail-settings"></label>
                                    </span> <Translate text={'Email terms on Loan Vehicle Return'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="multiTDperLead-settings"
                                            className="switch"
                                            name={'multiTDperLead'}
                                            checked={Boolean(props.dealer?.clientSettings?.multiTDperLead)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="multiTDperLead-settings"></label>
                                    </span> <Translate text={'Count multiple test drives per lead in all reports'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="stockCredit-settings"
                                            className="switch"
                                            name={'stockCredit'}
                                            checked={Boolean(props.dealer?.clientSettings?.stockCredit)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="stockCredit-settings"></label>
                                    </span> <Translate text={'Auto fetch vehicle grading on adding new stock'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="stockCreditAuto-settings"
                                            className="switch"
                                            name={'stockCreditAuto'}
                                            checked={Boolean(props.dealer?.clientSettings?.stockCreditAuto)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="stockCreditAuto-settings"></label>
                                    </span> <Translate text={'Auto fetch vehicle grading on every stock update'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="incWonStockinTD-settings"
                                            className="switch"
                                            name={'incWonStockinTD'}
                                            checked={Boolean(props.dealer?.clientSettings?.incWonStockinTD)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="incWonStockinTD-settings"></label>
                                    </span> <Translate text={'Include won stock in test drive'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="hideSalesDoc-settings"
                                            className="switch"
                                            name={'hideSalesDoc'}
                                            checked={Boolean(props.dealer?.clientSettings?.hideSalesDoc)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="hideSalesDoc-settings"></label>
                                    </span> <Translate text={'Hide Documents in Sales Activity'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="markActDoneWhenLeadOnLost-settings"
                                            className="switch"
                                            name={'markActDoneWhenLeadOnLost'}
                                            checked={Boolean(props.dealer?.clientSettings?.markActDoneWhenLeadOnLost)}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="markActDoneWhenLeadOnLost-settings"></label>
                                    </span> <Translate text={'Mark activities as completed when lead status on lost'} /> </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </>) : props.service === true ? (<>
                    <h5 className="settings-subhead"><Translate text={'Settings'} /></h5>
                    <div className="switch-list">
                        <div className="row">
                            <div className="col-sm-6">
                                <ul>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-2way-sms"
                                            className="switch"
                                            name={'enableTwoWaySMS'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableTwoWaySMS) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-2way-sms"></label>
                                    </span> <Translate text={'Enable Two Way SMS'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="service-recomm-cost"
                                            className="switch"
                                            name={'calcOnlyRecommCost'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.calcOnlyRecommCost) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="service-recomm-cost"></label>
                                    </span> <Translate text={'Calculate only recommendation cost on total revenue'} /> </li>
                                </ul>
                            </div>
                            <div className="col-sm-6">
                                <ul>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="service-daily-ro"
                                            className="switch"
                                            name={'enableServiceDailyRO'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enableServiceDailyRO) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="service-daily-ro"></label>
                                    </span> <Translate text={'Enable Service Daily RO'} /> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                ) : props.tradeinPro === true ? (<>
                    <h5 className="settings-subhead"><Translate text={'settings'} /></h5>
                    <div className="switch-list">
                        <div className="row">
                            <div className="col-sm-6">
                                <ul>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="send-email-sales-exective"
                                            className="switch"
                                            name={'sendemailtoSE'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.sendemailtoSE) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="send-email-sales-exective"></label>
                                    </span> <Translate text={'Send Email To Sales Executive'} /> </li>
                                    <li> <span className="switch switch-sm mr-2">
                                        <InputCheckBox
                                            id="enable-manager-workflow-approval"
                                            className="switch"
                                            name={'managerWorkflow'}
                                            checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.managerWorkflow) : false}
                                            onChange={(e) => { props.handleCheckChange(e) }} />
                                        <label htmlFor="enable-manager-workflow-approval"></label>
                                    </span> <Translate text={'Enable Manager Workflow Approval'} /> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                ) : (<></>)
            }
        </div>
        {
            props.sales === true ? (<>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead"><Translate text={'Previously Purchased Alert on Add Contact'} /></h5>
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'previously purchased'}
                                className={`form-control ${props.errorFields["previously-purchased"]}`}
                                name="text"
                                id={"previously-purchased"}
                                onChange={(e) => {
                                    props.handleTextChange(e, 'prevPurchased')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.prevPurchased ? props.dealer.clientSettings.prevPurchased.text : ''}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead">&nbsp;</h5>
                            <ReactMultiSelect
                                options={props.dealersettings &&
                                    props.dealersettings.client &&
                                    props.dealersettings.client.settings &&
                                    props.dealersettings.client.settings.pipelines &&
                                    props.dealersettings.client.settings.pipelines.map(rec => {
                                        return {
                                            label: rec.name,
                                            value: rec.value,
                                            doc: rec,
                                        }
                                    })
                                }
                                id={'sales-pipelines'}
                                name={'pipelines'}
                                placeholder={`select pipelines`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, 'prevPurchased', 'pipelines')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.prevPurchased) ? props.dealer.clientSettings.prevPurchased.pipelines : []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead"><Translate text={'Stock Locations'} /></h5>
                            <ReactMultiSelect
                                options={props?.dealersettings?.clients?.map(rec => {
                                    return {
                                        value: rec.id,
                                        label: rec.name
                                    }
                                })}
                                name={'stockLocations'}
                                placeholder={`select stock location`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, null, 'stockLocations')
                                }}
                                value={props?.dealer?.clientSettings?.stockLocations || []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead"><Translate text={'Test Drive / Fleet Stock Locations'} /></h5>
                            <ReactMultiSelect
                                options={props?.dealersettings?.clients?.map(rec => {
                                    return {
                                        value: rec.id,
                                        label: rec.name
                                    }
                                })}
                                name={'tdStockLocations'}
                                placeholder={`select test drive / fleet stock location`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, null, 'tdStockLocations')
                                }}
                                value={props?.dealer?.clientSettings?.tdStockLocations || []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
        {
            props.service === true ? (<>
                <div className="divider-line"></div>
                <h3><Translate text={'service'} /></h3>
                <div className="switch-list">
                    <ul>
                        <li> <span className="switch switch-sm mr-2">
                            <InputCheckBox
                                id="service-discountEnabled"
                                className="switch"
                                name={'discountEnabled'}
                                checked={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? Boolean(props.dealer.clientSettings.service.discountEnabled) : false}
                                onChange={(e) => { props.handleQuoteCheckChange(e, 'service') }} />
                            <label htmlFor="service-discountEnabled"></label>
                        </span> <Translate text={'Enable Discount'} /></li>
                    </ul>
                </div>
                <div className="switch-list">
                    <ul>
                        <li> <span className="switch switch-sm mr-2">
                            <InputCheckBox
                                id="service-internalTaxExemption"
                                className="switch"
                                name={'internalTaxExemption'}
                                checked={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? Boolean(props.dealer.clientSettings.service.internalTaxExemption) : false}
                                onChange={(e) => { props.handleQuoteCheckChange(e, 'service') }} />
                            <label htmlFor="service-internalTaxExemption"></label>
                        </span> <Translate text={'Internal Tax Exemption'} /></li>
                    </ul>
                </div>
                <h5 className="settings-subhead"><Translate text={'labourCost'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {/* <input
                                type="text"
                                autoComplete="off"
                                placeholder={'labour cost'}
                                className={`form-control`}
                                name="labourCost"
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'service')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? props.dealer.clientSettings.service.labourCost : ''}
                                maxLength={3}
                            /> */}
                            <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                                placeholder={currencySymbol}
                                className={`form-control`}
                                onValueChange={(e) => {
                                    props.handleCurrencyChange(e, 'labourCost', 'service')
                                }}
                                value={!_.isEmpty(props.dealer?.clientSettings?.service) ? props.dealer.clientSettings.service.labourCost : ''}
                            />
                        </div>
                    </div>
                </div>
                <h5 className="settings-subhead"><Translate text={'VAT / GST (%)'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={taxTypes}
                                name={"taxType"}
                                placeholder={'select tax type'}
                                onChange={(e, data) => {
                                    props.handleReactSelectChange(e, data, 'service')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? props.dealer.clientSettings.service.taxType : false}
                                classNamePrefix={`${props.errorFields["taxType"]} cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                        <div className="form-group col-md-3">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'vat / gst (%)'}
                                className={`form-control`}
                                name="tax"
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'service')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? props.dealer.clientSettings.service.tax : ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                <h3><Translate text={'Mandatory Valuation Images'} /></h3>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'no. of images'}
                                className={`form-control`}
                                name="mandImages"
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'serviceTradein')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.serviceTradein) ? props.dealer.clientSettings.serviceTradein.mandImages : ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
                <h5 className="settings-subhead"><Translate text={'Mandatory Side Images'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <ReactMultiSelect
                                options={sidePhotosVM.map(rec => {
                                    return {
                                        value: rec.value,
                                        searchlabel: rec.name,
                                        label: <div className="drop-image drop-image-fix">
                                            <img src={images[rec.ico]} alt="" width="50" className="img-object-fit" />
                                            {rec.name}
                                        </div>,
                                        name: rec.name
                                    }
                                })}
                                name={'mandSidePhotos'}
                                placeholder={`select side images`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, 'serviceTradein', 'mandSidePhotos')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.serviceTradein) ? props.dealer.clientSettings.serviceTradein.mandSidePhotos : []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                <h3><Translate text={'VSR Settings'} /></h3>
                <h5 className="settings-subhead"><Translate text={'URL'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'vsr url'}
                                className={`form-control`}
                                name="vsrURL"
                                onChange={(e) => {
                                    props.handleTextChange(e, 'service')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.service) ? props.dealer.clientSettings.service.vsrURL : ''}
                            />
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
        {
            props.sales === true ? (<>
                <div className="divider-line"></div>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead">
                                <Translate text={'Phone Number Format'} />
                            </h5>
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'### ### ###'}
                                className={`form-control`}
                                name="format"
                                id={"phone-number-format"}
                                onChange={(e) => {
                                    props.handleTextChange(e, 'phoneFormat')
                                }}
                                value={props?.dealer?.clientSettings?.phoneFormat?.format || ''}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <h5 className="settings-subhead">&nbsp;</h5>
                            <span className="switch switch-sm mr-3">
                                <InputCheckBox
                                    id="country-code-settings"
                                    className="switch"
                                    name={'code'}
                                    checked={Boolean(props?.dealer?.clientSettings?.phoneFormat?.code)}
                                    onChange={(e) => { props.handleQuoteCheckChange(e, 'phoneFormat') }} />
                                <label htmlFor="country-code-settings"></label>
                            </span> <Translate text={'Country Code'} />
                            <span className="switch switch-sm mr-1">
                                <InputCheckBox
                                    id="zero-prefix-settings"
                                    className="switch"
                                    name={'zeroPrefix'}
                                    checked={Boolean(props?.dealer?.clientSettings?.phoneFormat?.zeroPrefix)}
                                    onChange={(e) => { props.handleQuoteCheckChange(e, 'phoneFormat') }} />
                                <label htmlFor="zero-prefix-settings"></label>
                            </span> <Translate text={'Zero Prefix'} />
                        </div>
                        <div className="form-group col-md-6">
                            <h5 className="settings-subhead"><Translate text={'Approved Finance Credit Score'} /></h5>
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'approved finance credit score'}
                                className={`form-control ${props.errorFields["credit-score-approved"]}`}
                                name="approved"
                                id={"credit-score-approved"}
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'creditScore')
                                }}
                                value={props?.dealer?.clientSettings?.creditScore?.approved || ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
        {
            props.sales === true ? (<>
                <div className="divider-line"></div>
                <h3><Translate text={'Generate Order Form'} /></h3>
                <div className="switch-list">
                    <ul>
                        <li> <span className="switch switch-sm mr-2">
                            <InputCheckBox
                                id="quotation-settings"
                                className="switch"
                                name={'enabled'}
                                checked={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.quotation) ? Boolean(props.dealer.clientSettings.quotation.enabled) : false}
                                onChange={(e) => { props.handleQuoteCheckChange(e, 'quotation') }} />
                            <label htmlFor="quotation-settings"></label>
                        </span> <Translate text={'enable'} /></li>
                    </ul>
                </div>
                <h5 className="settings-subhead"><Translate text={'VAT / GST (%)'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={taxTypes}
                                name={"taxType"}
                                id={"quotation-taxType"}
                                placeholder={'select tax type'}
                                onChange={(e, data) => {
                                    props.handleReactSelectChange(e, data, 'quotation')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.quotation) ? props.dealer.clientSettings.quotation.taxType : false}
                                classNamePrefix={`${props.errorFields["quotation-taxType"]} cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                        <div className="form-group col-md-3">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'vat / gst (%)'}
                                className={`form-control ${props.errorFields["quotation-tax"]}`}
                                name="tax"
                                id={"quotation-tax"}
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'quotation')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.quotation) ? props.dealer.clientSettings.quotation.tax : ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
        {
            props.sales === true ? (<>
                <div className="divider-line"></div>
                <h3><Translate text={'Daily Activity Report'} /></h3>
                <div className="settings-inner form-style">
                    <h5 className="settings-subhead"><Translate text={'Schedule On'} /></h5>
                    <div className="form-row">
                        <div className={`form-group ${props.dealersettings.superAdmin === true ? 'col-md-3' : 'col-md-6'}`}>
                            <div className="inner-addon right-addon">
                                <i className="bg-icons ico icon-time"></i>
                                <ReactTimePicker
                                    value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.dailyRptTime) ? moment(moment().format("YYYY-MM-DD") + ' ' + props.dealer.clientSettings.dailyRptTime) : null}
                                    name={'dailyRptTime'}
                                    onChange={props.handleSettingsHoursChange}
                                    timeIntervals={15}
                                    placeholder='h:mm aa'
                                    className={`form-control`}
                                    minTime={moment(moment().format("YYYY-MM-DD") + ' 12:00:00')._d}
                                    maxTime={moment(moment().format("YYYY-MM-DD") + ' 24:00:00')._d}
                                />
                            </div>
                        </div>
                        {
                            props.dealersettings.superAdmin === true ? (
                                <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={reportTypes}
                                        name={"dailyRptType"}
                                        id={"report-dailyRptType"}
                                        placeholder={'select type'}
                                        onChange={props.handleReactSelectChange}
                                        value={!_.isEmpty(props.dealer.clientSettings) ? props.dealer.clientSettings.dailyRptType : null}
                                        classNamePrefix={` cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                    <h5 className="settings-subhead"><Translate text={'Recipients'} /></h5>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <InputMultipleText
                                placeholder={`recipients`}
                                className={`form-control react-multi-tag`}
                                name="dailyRptEmails"
                                onChange={(emails) => { props.handleOnChangeMultipleText(emails, 'dailyRptEmails') }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.dailyRptEmails) ? props.dealer.clientSettings.dailyRptEmails.split(',') : []}
                                validateEmail={true}
                                getLabel={(email, index) => {
                                    return <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => props.removeEmail(index, 'dailyRptEmails')}>
                                            ×
                                        </span>
                                    </div>
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                <h3><Translate text={'Mandatory Valuation Images'} /></h3>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input
                                id={'sales-mandImages'}
                                type="text"
                                autoComplete="off"
                                placeholder={'no. of images'}
                                className={`form-control`}
                                name="mandImages"
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'salesTradein')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.salesTradein) ? props.dealer.clientSettings.salesTradein.mandImages : ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
                <h5 className="settings-subhead"><Translate text={'Mandatory Side Images'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <ReactMultiSelect
                                options={sidePhotosVM.map(rec => {
                                    return {
                                        value: rec.value,
                                        searchlabel: rec.name,
                                        label: <div className="drop-image drop-image-fix">
                                            <img src={images[CommonHelper.getNoVehicleIcons(props.dealer.category, rec.ico)]} alt="" width="50" className="img-object-fit" />
                                            {rec.name}
                                        </div>,
                                        name: rec.name
                                    }
                                })}
                                id={'sales-mandSidePhotos'}
                                name={'mandSidePhotos'}
                                placeholder={`select side images`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, 'salesTradein', 'mandSidePhotos')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.salesTradein) ? props.dealer.clientSettings.salesTradein.mandSidePhotos : []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                <h3><Translate text={'Evaluators Emails for Trade-In'} /></h3>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <InputMultipleText
                                placeholder={`recipients`}
                                className={`form-control react-multi-tag`}
                                name="evaluatorsEmails"
                                onChange={(emails) => { props.handleOnChangeMultipleText(emails, 'evaluatorsEmails') }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.evaluatorsEmails) ? props.dealer.clientSettings.evaluatorsEmails.split(',') : []}
                                validateEmail={true}
                                getLabel={(email, index) => {
                                    return <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => props.removeEmail(index, 'evaluatorsEmails')}>
                                            ×
                                        </span>
                                    </div>
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
        {
            props.tradeinPro === true ? (<>
                <div className="divider-line"></div>
                <h3><Translate text={'Mandatory Valuation Images'} /></h3>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'no. of images'}
                                className={`form-control`}
                                name="mandImages"
                                onChange={(e) => {
                                    props.handleQuoteNumberChange(e, 'tradeinPro')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.tradeinPro) ? props.dealer.clientSettings.tradeinPro.mandImages : ''}
                                maxLength={3}
                            />
                        </div>
                    </div>
                </div>
                <h5 className="settings-subhead"><Translate text={'Mandatory Side Images'} /></h5>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <ReactMultiSelect
                                options={sidePhotosVM.map(rec => {
                                    return {
                                        value: rec.value,
                                        searchlabel: rec.name,
                                        label: <div className="drop-image drop-image-fix">
                                            <img src={images[rec.ico]} alt="" width="50" className="img-object-fit" />
                                            {rec.name}
                                        </div>,
                                        name: rec.name
                                    }
                                })}
                                name={'mandSidePhotos'}
                                placeholder={`select side images`}
                                onChange={(e) => {
                                    props.handleReactMultiSelectChange(e, 'tradeinPro', 'mandSidePhotos')
                                }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.tradeinPro) ? props.dealer.clientSettings.tradeinPro.mandSidePhotos : []}
                                classNamePrefix={`cursor-pointer basic-select`}
                                isMulti={true}
                                isSearchable={true}
                            >
                            </ReactMultiSelect>
                        </div>
                    </div>
                </div>
                <h3><Translate text={'Other Email Address (Appraisal report on complete)'} /></h3>
                <div className="settings-inner form-style">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <InputMultipleText
                                placeholder={`recipients`}
                                className={`form-control react-multi-tag`}
                                name="otheremailAddress"
                                onChange={(emails) => { props.handleOnChangeMultipleText(emails, 'otheremailAddress') }}
                                value={!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.otheremailAddress) ? props.dealer.clientSettings.otheremailAddress.split(',') : []}
                                validateEmail={true}
                                getLabel={(email, index) => {
                                    return <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => props.removeEmail(index, 'otheremailAddress')}>
                                            ×
                                        </span>
                                    </div>
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>) : (<></>)
        }
    </>);
}

export default AppSettings;