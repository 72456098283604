import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
import Translate from '../../../constants/translate';
import CommonHelper from "../../../services/common";
const LeadsBucket = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const [loader, setLoader] = useState(true);
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-leadsBucket`);

    useEffect(() => {
        return () => {
            window.unsubenquiryleadsbucketSnap && window.unsubenquiryleadsbucketSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!initialLoad)
            return

        setLoader(true)
        window.unsubenquiryleadsbucketSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader) => {
        let _filter = {
            timezone: moment.tz.guess()
        }
        if (props.fromOEM && props.dealersettings.level === 'oem' && props.dealersettings.oemID)
            _filter.oemID = props.dealersettings.oemID;
        else if (props.fromOEM && props.dealersettings.level === 'group' && props.dealersettings.groupID)
            _filter.groupID = props.dealersettings.groupID;
        else if (props.fromOEM && props.dealersettings.level === 'region' && props.dealersettings.regionID)
            _filter.regionID = props.dealersettings.regionID;
        else {
            _filter.clientID = props.dealersettings.client.id;
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')
        if (props?.dashboardFilter?.group?.length > 0) _filter.groupID = props.dashboardFilter.group.join(',')
        if (props?.dashboardFilter?.region?.length > 0) _filter.regionID = props.dashboardFilter.region.join(',')
        if (props?.dashboardFilter?.dealer?.length > 0) _filter.clientID = props.dashboardFilter.dealer.join(',')

        let _searchObject = {
            type: "getOEMInboundleadsCounts",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            const data = resp.data.data[0]
            const _counts = {
                todayConverted: data.todayConverted ? JSON.parse(data.todayConverted) : [],
                monthConverted: data.monthConverted ? JSON.parse(data.monthConverted) : [],
                pendingInboundleads: data.pendingLeads ? JSON.parse(data.pendingLeads) : [],
            }
            setCounts(_counts)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: _counts, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window.unsubenquiryleadsbucketSnap && window.unsubenquiryleadsbucketSnap();
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])


    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row" style={{ minHeight: `165px` }}>
                {
                    loader
                        ?
                        <>
                            <div className="dashboard-widget-loader h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                        </>
                }
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap ">
                                <h3><Translate text={'allocated'} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg">
                                    <div className=" row">
                                        {
                                            props.applydateRange === false ? (
                                                <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.todayConverted.length) {
                                                        props.handleShowEnquiryList({
                                                            widget: 'LeadsBucket',
                                                            documentIDs: counts.todayConverted
                                                        }, 'Today Allocated Leads', counts.todayConverted.length)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> Today</div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.todayConverted.length}</a></div>
                                                </div>) : (<></>)
                                        }
                                        <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.monthConverted.length) {
                                                props.handleShowEnquiryList({
                                                    widget: 'LeadsBucket',
                                                    documentIDs: counts.monthConverted
                                                }, (props.applydateRange === false ? `MTD Allocated` : `Allocated`), counts.monthConverted.length)
                                            }
                                        }}>
                                            <div className="dashboard-count-title"> MTD</div>
                                            <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.monthConverted.length}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                            if (counts.pendingInboundleads.length) {
                                props.handleShowEnquiryList({
                                    widget: 'LeadsBucket',
                                    documentIDs: counts.pendingInboundleads
                                }, 'Pending Lead Bucket', counts.pendingInboundleads.length)
                            }
                        }}>
                            <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt"><Translate text={'Pending'} /></span> <span className="float-right"><h2>{counts.pendingInboundleads.length}</h2></span></div>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    </>);
}

export default LeadsBucket;