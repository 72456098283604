import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { collection, docData } from 'rxfire/firestore';

//import { StageProvider, StageConsumer } from './provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
//import QuickView from "./quickview";
import FilterPanel from './filterPanel'
//import TransferOwner from '../common/transfer'
import CommonHelper from '../../services/common';
import { allEventsProFields, userLevelOptions } from './viewModel'
import images from '../../images'
//import LeadTransfer from '../pipeline/leadTransfer'
import ReAuthenticate from '../common/reAuthenticate';
import { eventStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
//import QuickView from "./quickview";
import AddEvent from './add'

const ListReducer = (state, action) => {
    function updateData() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateData()
        }
        default:
            return state;
    }
};



const EventsListView = (props) => {
    const [enquiries, dispatch] = useReducer(ListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [eventsProFields, setEventsProFields] = useState(props.dealersettings.eventsProFields)
    const pageLimit = 20
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.eventsSearchText ? localStorage.eventsSearchText : null)
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [eventsModal, setEventsModal] = useState({ show: false, docID: null })
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [pipelineCount, setPipelineCount] = useState(0)


    const [enquiryOptions, setEnquiryOptions] = useState([])

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _eventsProPipeline = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.eventsPro) &&
        !_.isEmpty(props.dealersettings.client.eventsPro.pipelines)) ? props.dealersettings.client.eventsPro.pipelines : [];


    const [defaultPipeline, setDefaultPipeline] = useState(_eventsProPipeline.find(item => item.default === true) ? _eventsProPipeline.find(item => item.default === true).value : '')

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         if (searchText && searchText.length > 2) {
    //             setDataload(true)
    //         }

    //         if (searchText === '')
    //             setDataload(true)
    //     }, 1000);
    //     return () => clearTimeout(timeoutId);
    // }, [searchText]);

    useEffect(() => {
        let headerFields = eventsProFields;
        let allHeaderFields = allEventsProFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [eventsProFields])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allEventsProFields.some(e => e.value === rec.value))
                allEventsProFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        eventsProFields.forEach(rec => {
            if (!allEventsProFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setEventsProFields(_.differenceBy(eventsProFields, deleteids))
    }, [enquiryOptions])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;
            const tipSettings = Object.assign({}, props.dealersettings.client.eventsPro);

            let _enquiryOptions = [];
            tipSettings.enquiryOptionsDF && tipSettings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });


            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setDataload(true);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
        }
    }, [])

    // useEffect(() => {
    //     if (isFilterApplied || _eventsProPipeline.length === 0)
    //         return

    //     var querySet = [];
    //     const _pipeline = _eventsProPipeline.find(item => item.default === true).value
    //     CommonHelper.getAllEventsProDealers(props.dealersettings, true).forEach(rec => {
    //         querySet.push(docData(window.firebase.firestore().doc(`clients/${rec}/counts/${rec}`)))
    //     })
    //     window.unSubeventsProPipelineLstCount = combineLatest(querySet)
    //         .subscribe(allDocs => {
    //             var stageCount = {};
    //             allDocs.forEach(doc => {
    //                 if (doc.eventsPro && doc.eventsPro[_pipeline] && doc.eventsPro[_pipeline].stages) {
    //                     let _selectedPipeline = _eventsProPipeline.find(item => item.default === true);
    //                     _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
    //                         stageCount = {
    //                             ...stageCount,
    //                             [stage.value]: {
    //                                 ...sumStages(doc.eventsPro[_pipeline].stages[stage.value], stageCount[stage.value]),
    //                                 ['total']: sumObj(doc.eventsPro[_pipeline].stages[stage.value], stageCount?.[stage.value]?.['total'] ? stageCount[stage.value]['total'] : 0)
    //                             }
    //                         }
    //                     })
    //                 }
    //             })
    //             //console.log('stageCount', stageCount)
    //             setPipelineCount(stageCount)
    //         });
    // }, [_eventsProPipeline, isFilterApplied])

    // const sumObj = (obj, _stageCount) => {
    //     var sum = 0;
    //     for (var el in obj) {
    //         if (obj.hasOwnProperty(el)) {
    //             sum += parseFloat(obj[el]);
    //         }
    //     }
    //     return sum + _stageCount;
    // }

    // const sumStages = (obj, _stageCount) => {
    //     var sum = _stageCount ? _stageCount : {};
    //     for (var el in obj) {
    //         if (obj.hasOwnProperty(el)) {
    //             sum = {
    //                 ...sum,
    //                 [el]: sum.hasOwnProperty(el) ? sum[el] += parseFloat(obj[el]) : parseFloat(obj[el])
    //             }
    //         }
    //     }
    //     return sum;
    // }

    const handleStatusFilter = (_value, _key) => {
        if (_value) {
            if (!_.isEmpty(localStorage.eventsProPipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value[_key] = _value
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { [_key]: _value }
                }
                handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { [_key]: _value }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.eventsProPipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty(_key))
                handleClearFilter();
            else {
                delete _pipelineFilter.value[_key];
                handleApplyFilter(_pipelineFilter);
            }
        }
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'eventsPro')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            eventproid: 0
        })
    }

    const sidepanelOpen = (documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            eventproid: documentID
        })

    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubEventslist && window.unsubEventslist.unsubscribe();
            window.unSubeventsProPipelineLstCount && window.unSubeventsProPipelineLstCount.unsubscribe();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.eventsProPipelineFilter', isFilterApplied, localStorage.eventsProPipelineFilter)
        if (localStorage.eventsProPipelineFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.eventsProPipelineFilter])



    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (_eventsProPipeline.length === 0) {
            return
        }
        if (!checkDataLoad && defaultPipeline === _eventsProPipeline.find(item => item.default === true).value) {
            return;
        }
        if (defaultPipeline !== _eventsProPipeline.find(item => item.default === true).value) {
            setEnquiryLoader(true)
        }

        if (isFilterApplied && pageNum === 0)
            setEnquiryLoader(true)

        if ((isFilterApplied || localStorage.eventsProPipelineFilter) && isValidFilter()) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            if (_.isEmpty(_filter.pipeline))
                _filter.pipeline = _eventsProPipeline.find(item => item.default === true).value;


            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.eventsPro) &&
                !_.isEmpty(dealersettings.client.eventsPro.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.eventsPro.pipelines)
            }

            if (searchText) _filter.keywords = searchText;

            if (!_.isEmpty(_filter?.dealer)) {
                _filter.clientID = _filter.dealer;
            }
            else if (!_.isEmpty(_filter?.group)) {
                _filter.levelID = _filter.group;
                _filter.clientID = CommonHelper.getAllEventClientsByLevel(dealersettings, null, 'group', _filter.group.split(','), true)
            }
            else if (!_.isEmpty(_filter?.subregion)) {
                let levelIds = _filter.subregion.split(',')
                let _dealers = CommonHelper.getAllEventClientsDataByLevel(dealersettings, null, 'subregion', levelIds)
                if (!_.isEmpty(_dealers)) {
                    _dealers.forEach(doc => {
                        if (!_.isEmpty(doc.group)) levelIds.push(doc.group)
                    })
                    _filter.clientID = _dealers.map(r => { return r.id }).join(',')
                }
                if (!_.isEmpty(levelIds)) _filter.levelID = _.uniq(levelIds).join(',');
            }
            else if (!_.isEmpty(_filter?.region)) {
                let levelIds = _filter.region.split(',')
                let _dealers = CommonHelper.getAllEventClientsDataByLevel(dealersettings, null, 'region', levelIds)
                if (!_.isEmpty(_dealers)) {
                    _dealers.forEach(doc => {
                        if (!_.isEmpty(doc.subregion)) levelIds.push(doc.subregion)
                        if (!_.isEmpty(doc.group)) levelIds.push(doc.group)
                    })
                    _filter.clientID = _dealers.map(r => { return r.id }).join(',')
                }
                if (!_.isEmpty(levelIds)) _filter.levelID = _.uniq(levelIds).join(',');
            }
            else {
                let _userLevel = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: dealersettings.level })?.index)
                let _levelIDs = [];
                _userLevel.forEach(level => {
                    if (level.value === 'individual')
                        _filter.clientID = CommonHelper.getAllEventsProByLevel(dealersettings, level.value, true);
                    else
                        _levelIDs = _.union(_levelIDs, CommonHelper.getAllEventsProByLevel(dealersettings, level.value, true, true));
                });
                if (!_.isEmpty(_levelIDs))
                    _filter.levelID = _levelIDs.join(',')

            }
            if (_filter.hasOwnProperty('dealer')) delete _filter['dealer'];
            if (_filter.hasOwnProperty('group')) delete _filter['group'];
            if (_filter.hasOwnProperty('subregion')) delete _filter['subregion'];
            if (_filter.hasOwnProperty('region')) delete _filter['region'];
            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            let _searchObject = {
                "type": "searchEventsPro",
                "filters": JSON.stringify(_filter),
                "sortOrder": "addedDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject.clientID = _filter.clientID ? _filter.clientID : clientID;
            _searchObject.levelID = _filter.levelID ? _filter.levelID : null;

            //console.log('filter-searchEventspro', _searchObject, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);
                if (response.data.success && (isFilterApplied || localStorage.eventsProPipelineFilter) && isValidFilter()) {
                    onCollectionTIP(response.data);
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader(false)
                    setDataload(false)
                    setPaging(false)
                }
            });

            window.unsubEventslist && window.unsubEventslist.unsubscribe();
            window.unSubeventsProPipelineLstCount && window.unSubeventsProPipelineLstCount.unsubscribe();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];
            var collectionPromise = [];

            if (localStorage.eventsProPipelineFilter) {
                let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                setSearchText(searchKeywords);
                localStorage.eventsSearchText = searchKeywords;
                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            // if (localStorage.defaultModule.includes('oem')) {

            let _userLevel = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: dealersettings.level })?.index)
            //console.log('firebase_userLevel=>', _userLevel, dealersettings.level)
            _userLevel.forEach(level => {
                let _levelIDs = CommonHelper.getAllEventsProByLevel(dealersettings, level.value, true, true);
                //console.log('firebase_level==>', level.value, _levelIDs)
                for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {
                    let refEnquiryData = firestoreDB(dealersettings).firestore().collection('eventsPro')
                        .where('pipeline', '==', _eventsProPipeline.find(item => item.default === true).value)
                        .where('isDeleted', '==', false)
                        .where(`${level.value === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                        .orderBy('addedDate', 'desc')

                    if (hasMore[k] && defaultPipeline === _eventsProPipeline.find(item => item.default === true).value) {
                        refEnquiryData = refEnquiryData
                            .startAfter(hasMore[k])
                            .limit(pageLimit)
                    }
                    else {
                        if (isPaging) {
                            refEnquiryData = '';
                        } else {
                            refEnquiryData = refEnquiryData
                                .limit(pageLimit)
                        }
                    }
                    collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
                }
            });
            //}
            // else {
            //     let allEventProDealers = CommonHelper.getAllEventsProDealers(dealersettings, true);
            //     for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {
            //         let refEnquiryData = firestoreDB(dealersettings).firestore().collection('eventsPro')
            //             .where('pipeline', '==', _eventsProPipeline.find(item => item.default === true).value)
            //             .where('isDeleted', '==', false)

            //         if (searchKeywords) {
            //             let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            //             if (formattedSearchText.trim())
            //                 refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            //         }
            //         else {
            //             refEnquiryData = refEnquiryData.where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
            //         }

            //         refEnquiryData = refEnquiryData.orderBy('addedDate', 'desc')

            //         if (hasMore[k] && defaultPipeline === _eventsProPipeline.find(item => item.default === true).value) {
            //             refEnquiryData = refEnquiryData
            //                 .startAfter(hasMore[k])
            //                 .limit(pageLimit)
            //         }
            //         else {
            //             if (isPaging) {
            //                 refEnquiryData = '';
            //             } else {
            //                 refEnquiryData = refEnquiryData
            //                     .limit(pageLimit)
            //             }
            //         }
            //         collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
            //     }
            // }


            if (collectionPromise.length > 0) {
                window.unsubEventslist = combineLatest(collectionPromise)
                    .subscribe(allDocs => {
                        var querySnapshot = [];
                        let i = 0;
                        let _hasMore = {};
                        allDocs.forEach(doc => {
                            if (doc.length > 0 && doc.length === pageLimit) {
                                _hasMore = {
                                    ..._hasMore,
                                    [i]: doc[doc.length - 1]
                                };
                            }
                            else {
                                _hasMore = {
                                    ..._hasMore,
                                    [i]: null
                                };
                            }
                            querySnapshot.push(...doc);
                            i++;
                        });
                        setHasMoreData(_hasMore);
                        onCollectionUpdate(querySnapshot);
                    });
            }
            else {
                setEnquiryLoader(false)
                setDataload(false)
                setPaging(false)
            }
        }


    }, [checkDataLoad, _eventsProPipeline, props.dealersettings])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = CommonHelper.convertEventsProVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         }, props);
        //         if (change.type === 'added') {
        //             actionType = "ADD_LIST";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_LIST"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVE_LIST"
        //         }
        //     }
        // })
        let _enquiries = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = CommonHelper.convertEventsProVM({
                    ...doc.data(),
                    documentID: doc.id
                }, props);
                _enquiries.push(logVM)
            });

            if (searchText && !localStorage.defaultModule.includes('oem')) {
                let formattedSearchText = CommonHelper.parseSearchText(props.dealersettings, searchText)
                if (formattedSearchText.trim())
                    _enquiries = _enquiries.filter(a => CommonHelper.getAllEventsProDealers(props.dealersettings, true).some(b => a.clientIDs?.includes(b)))
            }

        }

        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _.uniqBy(_enquiries, 'documentID'),
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_eventsProPipeline.find(item => item.default === true).value)
    }

    const onCollectionTIP = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = CommonHelper.convertEventsProVM(doc, props);
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_eventsProPipeline.find(item => item.default === true).value)
    }

    const isValidFilter = () => {
        return true;
        // if (localStorage.eventsProPipelineFilter) {
        //     let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
        //     let _filter = Object.assign({}, _pipelineFilter.value);

        //     // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
        //     //     return false;

        //     // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
        //     //     return false;

        //     // if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
        //     //     return false;

        //     return true;
        // }
        // else {
        //     return false;
        // }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ eventsProFields: fields }, { merge: true })
            .then(() => {
                setEventsProFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('eventsProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {

        const _permissions = props.dealersettings?.rolePermissions?.eventsProPermissions
        const deleteAccess = ((!_.isEmpty(_permissions) && _permissions.deleteEvent && (obj.statusValue === eventStatus.CLOSED || obj.statusValue === eventStatus.DRAFT)) ? true : false);

        return <>
            {
                obj.statusValue === eventStatus.DRAFT
                    ?
                    < div style={{ cursor: 'pointer' }} className="mini-button" title='Edit' onClick={(e) => { e.preventDefault(); handleEventOpen(id) }}>
                        <i className="ico icon-edit"></i>
                    </div >
                    :
                    < div style={{ cursor: 'not-allowed' }} className="mini-button" title='Edit'>
                        <i className="ico icon-lock"></i>
                    </div >
            }

            {
                deleteAccess
                    ?
                    < div style={{ cursor: 'pointer' }} className="mini-button ml-2" title='Delete' onClick={(e) => { e.preventDefault(); handleDeleteEvent(id, obj) }}>
                        <i className="ico icon-delete"></i>
                    </div >
                    :
                    < div style={{ cursor: 'not-allowed' }} className="mini-button ml-2" title='Delete'>
                        <i className="ico icon-lock"></i>
                    </div >
            }

        </>
    }

    const handleDeleteEvent = async (id, data) => {
        const { dealersettings } = props;
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            html: CommonHelper.showLocale(props, `You want to delete this event.`),
            text: data.title ? data.title : '',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {

                const updateRef = firestoreDB(dealersettings).firestore().collection('eventsPro').doc(id);
                updateRef.update({
                    isDeleted: true,
                    modifiedBy: dealersettings ? dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }).then((docRef) => {

                    //if (props.handleRouteRemove) props.handleRouteRemove({ id: id, clientid: data.clientID }, 'eventsPro');

                    toast.notify(`Event deleted successfully`, {
                        duration: 2000,
                    });

                    // if (!_.isEmpty(data)) {
                    //     dispatch({
                    //         type: "REMOVE_LIST",
                    //         data: data
                    //     });
                    // }

                    let logNotes = 'Event deleted';
                    if (!_.isEmpty(data) && data.title)
                        logNotes = 'Event: ' + data.title + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: 'eventsPro',
                        subType: 'delete',
                        recordId: id,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                    CommonHelper.saveAuditLog(_objLogData)

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    const handleDetailclick = (e, id) => {
        props.history.push("/eventspro/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_LIST",
                data: enq
            });
            firestoreDB(props.dealersettings).firestore().doc(`eventsPro/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('service updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const updateList = (objdata) => {
        if (!_.isEmpty(objdata) && !_.isEmpty(localStorage.eventsProPipelineFilter)) {
            let data = CommonHelper.convertEventsProVM(objdata, props);

            if (!_.isEmpty(data)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: data
                });
            }
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('eventsProPipelineFilter', JSON.stringify(_filter));
            //localStorage.eventsSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.eventsProPipelineFilter)) {
            localStorage.removeItem('eventsProPipelineFilter');
            localStorage.eventsSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
            setPipelineCount(0);
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }



    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.eventsProPipelineFilter ? JSON.parse(localStorage.eventsProPipelineFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);
        _filter.pipeline = _eventsProPipeline.find(item => item.default === true).value;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.eventsPro) &&
            !_.isEmpty(props.dealersettings.client.eventsPro.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.eventsPro.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )

        if (!_filter.clientID)
            _filter.clientID = CommonHelper.getAllEventsProDealers(props.dealersettings)
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchEventsPro",
            "filters": JSON.stringify(_filter),
            "sortOrder": "addedDate desc",
            "pageNum": 0,
            "pageLimit": total > 999 ? 5000 : (total ? total : 5000)
        }

        _searchObject.clientID = _filter.clientID;

        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = CommonHelper.convertEventsProVM(doc, props);
                _enquiries.push(logVM)
            });
            setCSVData(_enquiries)
            csvBtn.current && csvBtn.current.link.click();
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'eventsPro',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleEventOpen = (docID) => {
        setEventsModal({ show: true, docID: docID })
    }
    const handleEventClose = (id, data) => {

        if (!_.isEmpty(data)) updateList(data);
        setEventsModal({ show: false, docID: null })
    }

    return (
        <LayoutConsumer>
            {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => dealersettings ? (


                <div className={`middle-wrapper ${_eventsProPipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        _eventsProPipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={_eventsProPipeline}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                updateDealerSettings('eventsProPipeline', val);
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.eventsProPipelineFilter))
                                    localStorage.removeItem('eventsProPipelineFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleStatusFilter={handleStatusFilter}
                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                setSearchText(val);
                                setTimeout(() => {
                                    handleStatusFilter(val, 'keywords');
                                }, 1000);

                            }}
                            selectedIds={selectedIds}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}
                            handleEventOpen={handleEventOpen}
                        />)
                    }
                    <div className={`${_eventsProPipeline.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            _eventsProPipeline.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                        <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={_.orderBy(enquiries, 'addedDate', 'desc')}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allEventsProFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(eventsProFields && eventsProFields.length > 0) ? eventsProFields : allEventsProFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                    />
                                </div>
                                )
                        }


                    </div>

                    {/* <PopUpModal show={modalTransferShow}>
                        <TransferOwner
                            show={modalTransferShow}
                            handleClose={handleCloseTransferEnquiries}
                            title={'Transfer Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </TransferOwner>
                    </PopUpModal>

                    <PopUpModal show={modalMoveShow}>
                        <LeadTransfer
                            show={modalMoveShow}
                            handleModalClose={handleCloseMoveEnquiries}
                            title={'Move Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            isList={true}
                        >
                        </LeadTransfer>
                    </PopUpModal> */}

                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>
                    <PopUpModal show={eventsModal.show}>
                        <AddEvent
                            docID={eventsModal.docID}
                            show={eventsModal.show}
                            clsActive='overlay-modal active'
                            handleClose={handleEventClose}
                            title={eventsModal.docID ? 'event' : 'addEvent'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            history={props.history}
                        >
                        </AddEvent>
                    </PopUpModal>

                    {/* <SidePanel
                        clsActive={showpanel.clsActive}
                        clsName={showpanel.clsName}
                        sidepanelClose={sidepanelClose}
                        title=''
                    >
                        <QuickView {...props}
                            eventproid={showpanel.eventproid}
                            isFilterApplied={isFilterApplied}
                            updateListEnquiry={updateList}
                            sidepanelClose={sidepanelClose}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        />
                    </SidePanel> */}

                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default EventsListView;