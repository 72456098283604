import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputText, ImageCropHook, ImageViewer, PopUpModal } from '../../components'
import NumberFormat from 'react-number-format'
import { integrationsVM } from './viewModel'
import { apisOptions } from '../billing/pricing/viewModel';
import _images from '../../images'
import Translate from '../../constants/translate';

const AddIntegration = (props) => {
    const [integration, setIntegration] = useState(props.integration);
    const [errorFields, setErrorFields] = useState({});
    const newIntegration = !_.isEmpty(props.integration) ? false : true
    const [loader, setLoader] = useState(false)
    const [imageLoader, setImageLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const currencySymbol = '$ ';

    useEffect(() => {
        if (_.isEmpty(props.integration)) {
            const newintegrationsVM = Object.assign({}, integrationsVM);
            newintegrationsVM.documentID = window.firebase.firestore().collection(`integrations`).doc().id;
            newintegrationsVM.addedDate = window.firebase.firestore.Timestamp.now();
            newintegrationsVM.addedBy = localStorage.uid;
            newintegrationsVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newintegrationsVM.modifiedBy = localStorage.uid;
            setIntegration(newintegrationsVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'value') {
            setIntegration({
                ...integration,
                [name]: value
            });
        } else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setIntegration({
                    ...integration,
                    [name]: newarray1.join(' ')
                });
            } else {
                setIntegration({
                    ...integration,
                    [name]: str.charAt(0).toUpperCase() + str.slice(1)
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    };

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        });
    }

    const handleCropSelect = (_cropShow) => {

        const { src, id, type, ext } = _cropShow;
        setIntegration({
            ...integration,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire('File format not supported. Please select image file.', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })

            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        setImageLoader(true)
        var storageRef = window.firebase.storage().ref(`integrations/${integration.documentID}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                window.firebase.storage().ref(`integrations`).child(integration.documentID).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setIntegration({
                            ...integration,
                            [id]: dataURL
                        });
                    })
            })
    };

    const onAmountChange = (e, name, fieldName) => {
        let _value = e.floatValue
        // console.log('onAmountChange value ', _value)
        if (_value === undefined) {
            _value = ''
        }

        setIntegration(prevIntegration => ({
            ...prevIntegration,
            [fieldName]: {
                ...prevIntegration[fieldName],
                [name]: _value
            }
        }));
    }

    const saveIntegration = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(integration['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(integration['value'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)

        if (newIntegration && props.integrations.some(item => item.name.toLowerCase() === integration.name.toLowerCase())) {
            Swal.fire('Integration with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        integration.type = integration.value;
        setLoader(true)
        window.firebase.firestore().doc(`integrations/${integration.documentID}`)
            .set(integration, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Integration ' + (newIntegration ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose(props.pipeline)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(integration) ? (<></>) : (<>
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-small-popup"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100 pipeline-form-style">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > Name </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={integration.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > Value </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'value'}
                                        className={`form-control ${errorFields["value"]}`}
                                        name="value"
                                        onChange={handleOnChange}
                                        value={integration.value}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="settings-companyimage ml-0 mt-2">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (integration.logo)
                                                ?
                                                <a data-fancy-box={`logo`}
                                                    href={integration.logo}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setImgViewer({
                                                            show: true,
                                                            images: integration.logo
                                                        })

                                                    }}>
                                                    <img className="img-object-fit-contain" src={integration.logo} alt="" height="65" />
                                                </a>
                                                :
                                                <img src={_images.nocompany} alt="" height="65" />
                                        }
                                    </div>
                                    <div className="settings-profile-info mt-4">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="make-logo" className={`btn-common float-left mr-2 font-600`}>
                                                <Translate text={'Choose Picture'} />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="testee"
                                                    id="make-logo"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'profile', 'logo', 'Integration Logo')} />
                                            </label>
                                            <div className={`settings-delete-button ${_.isEmpty(integration.logo) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIntegration({
                                                        ...integration,
                                                        ['logo']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a></div>
                                        </div>
                                        <div className=" settings-limit"><Translate text={'Max size 10 MB'} /></div>
                                    </div>
                                </div>
                                {
                                    integration.value && (
                                        <div className="common-table mt-3 w-100">
                                            {apisOptions.some(rec => rec.module === integration.value) && (<>
                                                <h5> Pricing </h5>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        {
                                                            integration.value === 'mailjet' ? (
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="5%">SNo</th>
                                                                    <th scope="col" className="head-light" width="55%">Apis</th>
                                                                    <th scope="col" className="head-light" width="20%">Cost (AUD)</th>
                                                                    <th scope="col" className="head-light" width="20%">Cost (USD)</th>
                                                                </tr>
                                                            ) : (<tr>
                                                                <th scope="col" className="head-light" width="5%">SNo</th>
                                                                <th scope="col" className="head-light" width="70%">Apis</th>
                                                                <th scope="col" className="head-light" width="25%">Cost (USD)</th>
                                                            </tr>)
                                                        }

                                                    </thead>
                                                    <tbody>
                                                        {apisOptions
                                                            .filter(rec => rec.module === integration.value)
                                                            .map((rec, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{rec.name}</td>
                                                                    {
                                                                        integration.value === 'mailjet' ? (
                                                                            <td>
                                                                                <div className="grid-one text-right number-value float-left w-100">
                                                                                    <NumberFormat
                                                                                        allowNegative={false}
                                                                                        thousandSeparator={true}
                                                                                        prefix={currencySymbol}
                                                                                        placeholder={currencySymbol}
                                                                                        className={`input-amount`}
                                                                                        decimalScale={3}
                                                                                        onValueChange={e =>
                                                                                            onAmountChange(
                                                                                                e,
                                                                                                rec.value,
                                                                                                'priceAUD'
                                                                                            )
                                                                                        }
                                                                                        value={integration?.priceAUD?.[rec.value] || null}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        ) : (<></>)
                                                                    }
                                                                    <td>
                                                                        <div className="grid-one text-right number-value float-left w-100">
                                                                            <NumberFormat
                                                                                allowNegative={false}
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                placeholder={currencySymbol}
                                                                                className={`input-amount`}
                                                                                decimalScale={3}
                                                                                onValueChange={e =>
                                                                                    onAmountChange(
                                                                                        e,
                                                                                        rec.value,
                                                                                        'price'
                                                                                    )
                                                                                }
                                                                                value={integration?.price?.[rec.value] || null}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </>)}
                                        </div>
                                    )
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveIntegration(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
        <ImageCropHook
            cropShow={cropShow}
            handleClose={handleCropClose}
            handleSelect={handleCropSelect}
        ></ImageCropHook>
        <PopUpModal show={imageViewer.show}>
            <ImageViewer
                {...imageViewer}
                handleClose={() => { setImgViewer({ show: false, images: null }); }}
            />
        </PopUpModal>
    </>
    );
}

export default AddIntegration;