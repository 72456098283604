import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import { GridView, InputText, PopUpModal, ReactSelect, RouteMap } from '../../components';
import _ from 'lodash';
import { firestoreDB } from '../../services/helper';
import CommonHelper from '../../services/common';
import { alltestdriveFields } from '../../views/dealsLog/viewModel';
import Swal from 'sweetalert2';

const CustomModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(_.pick(props.colDef.nestedObj ? props.data[props.colDef.nestedObj] : props.data, props?.modalFields?.map(r => { return r.fieldName })))
  const [loader, setLoader] = useState(false)
  const [tblLoader, settblLoader] = useState(true)
  const [list, setList] = useState([])
  const [showRouteMap, setshowRouteMap] = useState({ show: false })

  useEffect(() => {
    setShow(true)
  }, [])

  useEffect(() => {
    if (_.isEmpty(props.colDef.modalQuery))
      return

    let modalDataRef = firestoreDB(props?.context?.dealersettings).firestore().collection(props.colDef.modalQuery.collection);
    props.colDef.modalQuery.where.forEach(rec => {
      modalDataRef = modalDataRef.where(rec.field, '==', rec.fixed ? rec.value : props.data[rec.value])
    })
    modalDataRef.get().then(snap => {
      setList(snap.docs.map((doc) => {
        return CommonHelper[props.colDef.modalQuery.convertVM]({
          ...doc.data(),
          id: doc.id
        }, props?.context?.settings?.propsSettings, props?.context?.settings)
      }))
      settblLoader(false)
    })
  }, [])

  const handleClose = () => {
    props.api.stopEditing();
    setShow(false)
  }

  const handleSelectChange = (e, data) => {
    setState({
      ...state,
      [data.name]: e?.value || null,
      [`${data.name}Name`]: e?.label || null
    })
  }

  const handleMultiSelectChange = (selectedOptions, data) => {
    const value = [];
    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
      value.push(data.value)
    })
    setState({
      ...state,
      [data.name]: value
    })
  }

  const handleOnChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value
    });
  }

  const handleSubmit = () => {
    setLoader(true)
    _.chain(props?.modalFields)
      .groupBy('collectionName')
      .map((value, key) => ({
        collection: key,
        docID: value[0].docID,
        fields: value
      }))
      .value()
      .forEach(rec => {
        const objData = {};
        rec?.fields?.forEach(rec => {
          if (state[rec.fieldName] && rec.fieldDB)
            objData[rec.fieldDB] = state[rec.fieldName]
        })
        if (objData && rec?.collection && props?.data?.[rec?.docID]) {
          firestoreDB({ client: { projectId: props.data.projectId } }).firestore().doc(`${rec.collection}/${props.data[rec.docID]}`)
            .update(objData);
        }
      });
    let _value = '';
    props?.modalFields?.filter(a => a.type && (props.data.businessContact ? a.collectionName === 'companies' : a.collectionName === 'contactDetails'))?.forEach(rec => {
      if (state[rec.fieldName])
        _value += state[`${rec.fieldName}${rec.type === 'select' ? 'Name' : ''}`] + ' ';
    })
    //_value = <><i className={`ico icon-${props.data.businessContact ? 'company' : 'Contacts'} mr-1`}></i>{_value}</>
    props.api.stopEditing();
    props.api.getDisplayedRowAtIndex(props.rowIndex).updateData({ ...props.data, ...state, [props.colDef.value]: _value })
  }

  const onReportClick = (data) => {
    const dealersettings = props?.context?.dealersettings;
    const enquiryID = data?.enquiryID;
    const documentID = data?.documentID;
    if (enquiryID && documentID) {
      firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/files`)
        .where('recordID', '==', documentID)
        .where('isDeleted', '==', false)
        .orderBy('modifiedDate', 'desc').limit(1).get()
        .then(response => {
          if (response && response.size > 0) {
            const objData = Object.assign({}, response.docs[0].data());
            //   console.log('handleViewReport', objData)
            if (objData.url)
              window.open(objData.url, '_blank');
            else {
              Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available'), '', 'info')
            }
          } else {
            Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available'), '', 'info')
          }
        });
    }
    else {
      Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available.'), '', 'info')
    }
  }

  const onMapClick = async (data) => {
    if (data.mapCoordinatesPath) {
      await fetch(data.mapCoordinatesPath)
        .then(async (response) => {
          const responseJSON = await response.json();
          let _poliyCoordinates = [];
          if (_.isArray(responseJSON)) {
            responseJSON.forEach((doc) => {
              _poliyCoordinates.push({ 'geopoint': doc });
            });
          }
          if (!_.isEmpty(_poliyCoordinates)) {
            let state = Object.assign({}, data);
            state['polylineCoordinates'] = _poliyCoordinates;
            setshowRouteMap({
              ...state,
              show: true,
              title: 'Map View',
            })
          }
          else
            Swal.fire(CommonHelper.showLocale(this.props, 'No co-ordinates available in map view.'), '', 'info')
        })
    } else {
      Swal.fire(CommonHelper.showLocale(this.props, 'No co-ordinates available in map view.'), '', 'info')
    }

  }

  return (<>
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      backdropClassName={show ? 'overlay-modal active' : ''}
      dialogClassName={`modal-dialog-centered ${props.colDef.modalClass}`}
    >
      <Modal.Header closeButton>
        <Modal.Title> <Translate text={props.colDef.modalTitle || props.colDef.headerName} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {
          props?.modalFields?.length ? (
            <div className="container-fluid">
              <div className="row">
                <div className="form-style w-100">
                  <div className="form-row">
                    {
                      props?.modalFields?.filter(a => a.type && (props.data.businessContact ? true : a.collectionName === 'contactDetails'))?.map((field, index) => {
                        return <div key={index} className={`form-group ${field.class}`}>
                          <label > <Translate text={field.name} /></label>
                          {
                            field.type === 'select' ? (
                              <ReactSelect
                                options={props?.dealerSettings?.[field.settingsField]?.map(r => { return { ...r, label: r.name } }) || []}
                                name={field.fieldName}
                                placeholder={`select ${field.placeholder || ''}`}
                                onChange={handleSelectChange}
                                value={state[field.fieldName]}
                                classNamePrefix={`basic-select`}
                              >
                              </ReactSelect>
                            ) : field.type === 'multiselect' ? (
                              <ReactSelect
                                options={props?.dealerSettings?.[field.settingsField]?.map(r => { return { ...r, label: r.name } }) || []}
                                name={field.fieldName}
                                placeholder={`select ${field.placeholder || ''}`}
                                onChange={handleMultiSelectChange}
                                value={state[field.fieldName]}
                                classNamePrefix={`basic-select`}
                                isMulti={true}
                                isSearchable={true}
                              >
                              </ReactSelect>
                            ) : (
                              <InputText
                                autoComplete="off"
                                placeholder={field.placeholder || ''}
                                className={`form-control`}
                                name={field.fieldName}
                                onChange={handleOnChange}
                                value={state[field.fieldName]}
                              />
                            )
                          }

                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <GridView
              windowWidth={1200}
              windowHeight={window.innerHeight - 400}
              dynamicCols={alltestdriveFields}
              rowData={list}
              loader={tblLoader}
              hideSno={true}
              minCellWidth={120}
              hideHeaderMenuButton={true}
              rowHeight={40}
              buttonActions={{ onReportClick, onMapClick }}
              currentSettings={props?.context?.settings}
            />
          )
        }

      </Modal.Body>
      {
        props?.modalFields?.length ? (
          <Modal.Footer>
            <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => handleSubmit(e)}>
              {
                loader ?
                  (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                  : (<></>)
              }
              <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => handleClose()} > <Translate text={'cancel'} /></button>
          </Modal.Footer>) : (<></>)
      }

    </Modal >
    <PopUpModal show={showRouteMap.show}>
      <RouteMap
        {...showRouteMap}
        clsActive='overlay-modal active'
        handleClose={() => { setshowRouteMap({ show: false }) }}
        dealersettings={props?.context?.dealersettings}
      ></RouteMap>
    </PopUpModal>
  </>);
});

export default CustomModal;