import React, { useEffect, useState } from 'react';
import EmailListBody from '../emailListBody';
import EmailList from '../emailList';
import _ from 'lodash';
import CommonHelper from '../../../services/common';

const Trash = ({
	folderID,
	threads,
	setMessages,
	token,
	handleOpenComposeModal,
	filterThreads,
	updateThread,
	selectedThread,
	setSelectedThread,
	shouldChildSetSelectedMsg,
	setShouldChildSetSelectedMsg,
	searchText,
	setSearchText,
	filterThread,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	setRefreshLoader,
	searchThreads,
	refreshThreads,
	structureType,
	handleMarkAs,
	selectThreads,
	userData,
	nylasAccountData,
	labels,
	folders,
	checkDataLoad,
	setDataload,
	isPaging,
	setPaging,
	hasMore,
	setHasMoreData,
	dispatch,
	setLoadingMore,

	emailtemplates,
	history,
	dealersettings
}) => {
	const [filteredThreads, setFilteredThreads] = useState([]);
	const [threadsSelected, setThreadsSelected] = useState(false);
	const [threadsLoader, setThreadsLoader] = useState(_.isEmpty(threads) ? true : false);
	const pageLimit = 10

	useEffect(() => {

		if (!checkDataLoad) {
			return;
		}

		var nylasToken = dealersettings.nylasv3Config?.apiKey;// || 'nyk_v0_DCFJja0CV8TWUjZomlLrdXWQ6m741RoHAqVrJFzAcx6mlCFw3EssyJS2V7BJAlok'
		var nylasURL = dealersettings.nylasv3Config?.apiUri;// || 'https://api.eu.nylas.com'

		if (!_.isEmpty(nylasToken) && !_.isEmpty(nylasToken)) {

			let _options = {
				limit: pageLimit,
				in: folderID
			}

			let lastRecord = hasMore.filter(e => e.folder === folderID);
			if (lastRecord.length > 0) _options.page_token = lastRecord[0].nextCursor
			if (!_.isEmpty(_.trim(searchText))) _options.search_query_native = _.trim(searchText)
			if (!_.isEmpty(filterThread)) {
				if (filterThread === 'unread') _options.unread = true
				else if (filterThread === 'read') _options.unread = false
				else if (filterThread === 'attachment') _options.has_attachment = true
				else if (filterThread === 'noattachment') _options.has_attachment = false
				else if (filterThread === 'starred') _options.starred = true
			}
			if (Object.keys(_options).length > 2 && _.isEmpty(_options.page_token)) setThreadsLoader(true)
			const queryString = new URLSearchParams(_options).toString();

			const requestOptions = {
				method: "GET",
				//headers: myHeaders,
				headers: {
					Authorization: `Bearer ${nylasToken}`,
					'Content-Type': 'application/json',
				},
				redirect: "follow"
			};

			fetch(`${nylasURL}/v3/grants/${userData.nylas_v3.grantId}/threads?${queryString}`, requestOptions)
				.then((response) => response.text())
				.then((result) => {
					let respo = JSON.parse(result)
					//console.log('fetchUserData', respo)
					if (!_.isEmpty(respo?.error)) {
						dispatch({
							type: "SUCCESS",
							activetab: folderID,
							data: [],
							sortName: 'latestMessageReceivedDate',
							sortOrder: 'desc'
						});
						console.error(respo?.error)
					}
					else {
						let _mapData = Object.assign([], respo?.data).map((doc) => {
							return CommonHelper.convertObjectKeys(doc)
						})

						console.log('_mapData', folderID, _mapData)

						dispatch({
							type: isPaging ? "APPEND_LIST" : "SUCCESS",
							activetab: folderID,
							data: _mapData,
							sortName: 'latestMessageReceivedDate',
							sortOrder: 'desc'
						});
					}

					if (respo?.next_cursor) {
						setHasMoreData([...hasMore.filter(e => e.folder !== folderID),
						{
							folder: folderID,
							nextCursor: respo?.next_cursor

						}])
					}
					else {
						setHasMoreData([...hasMore.filter(e => e.folder !== folderID)]);
					}

					setDataload(false)
					setThreadsLoader(false)
					setRefreshLoader(false)
					setPaging(false)
					setLoadingMore(false)
				})
				.catch((error) => console.error(error));
		}
		else {
			let _options = { limit: pageLimit, in: [folderID] }

			let lastRecord = hasMore.filter(e => e.folder === folderID);
			if (lastRecord.length > 0) _options.pageToken = lastRecord[0].nextCursor

			if (!_.isEmpty(_.trim(searchText))) _options.searchQueryNative = _.trim(searchText)
			if (!_.isEmpty(filterThread)) {
				if (filterThread === 'unread') _options.unread = true
				else if (filterThread === 'read') _options.unread = false
				else if (filterThread === 'attachment') _options.hasAttachment = true
				else if (filterThread === 'noattachment') _options.hasAttachment = false
				else if (filterThread === 'starred') _options.starred = true
			}
			if (Object.keys(_options).length > 2 && _.isEmpty(_options.pageToken)) setThreadsLoader(true)
			var nylasThreads = window.firebase.functions().httpsCallable('nylasv3-threads');
			nylasThreads({
				grantId: userData.nylas_v3.grantId,
				options: _options
			}).then(res => {
				//console.log('nylasThreads', _options, res)
				if (res?.data?.success === true) {

					dispatch({
						type: isPaging ? "APPEND_LIST" : "SUCCESS",
						activetab: folderID,
						data: res?.data?.list?.data,
						sortName: 'latestMessageReceivedDate',
						sortOrder: 'desc'
					});

					if (res?.data?.list?.nextCursor) {
						setHasMoreData([...hasMore.filter(e => e.folder !== folderID),
						{
							folder: folderID,
							nextCursor: res?.data?.list?.nextCursor

						}])
					}
					else {
						setHasMoreData([...hasMore.filter(e => e.folder !== folderID)]);
					}


					setDataload(false)
					setThreadsLoader(false)
					setRefreshLoader(false)
					setPaging(false)
					setLoadingMore(false)


				}
			}).catch(err => console.log(err))

		}

	}, [checkDataLoad])

	// let folderID = null;
	// if (structureType === 'labels') {
	// 	labels.forEach(label => {
	// 		if (label.name === 'sent') {
	// 			folderID = label.id
	// 		}
	// 	})
	// } else if (structureType === 'folders') {
	// 	folders.forEach(folder => {
	// 		if (folder.name === 'SENT') {
	// 			folderID = folder.id
	// 		}
	// 	})
	// }

	// useEffect(() => {
	// 	return () => {
	// 		//console.log('SENT_return', folderID)
	// 		window[`unsublist_${folderID}`] && window[`unsublist_${folderID}`]();
	// 	}
	// }, [])

	// useEffect(() => {
	// 	if (!checkDataLoad) {
	// 		return;
	// 	}

	// 	//console.log(`folderID:-${folderID}`, `searchText:-${searchText}`, `filterThread:-${filterThread}`, `checkDataLoad:-${checkDataLoad}`, `hasMore:-${JSON.stringify(hasMore)}`)
	// 	//console.log(`threadsLoader:-${threadsLoader}`, `refreshLoader:-${refreshLoader}`,)
	// 	let _options = { limit: pageLimit, in: [folderID] }

	// 	// if (hasMore.length > 0) _options.pageToken = hasMore[0]
	// 	let lastRecord = hasMore.filter(e => e.folder === folderID);
	// 	if (lastRecord.length > 0) _options.pageToken = lastRecord[0].nextCursor

	// 	if (!_.isEmpty(_.trim(searchText))) _options.searchQueryNative = _.trim(searchText)
	// 	if (!_.isEmpty(filterThread)) {
	// 		if (filterThread === 'unread') _options.unread = true
	// 		else if (filterThread === 'read') _options.unread = false
	// 		else if (filterThread === 'attachment') _options.hasAttachment = true
	// 		else if (filterThread === 'noattachment') _options.hasAttachment = false
	// 		else if (filterThread === 'starred') _options.in = [..._options.in, 'STARRED']
	// 	}
	// 	if (Object.keys(_options).length > 2 && _.isEmpty(_options.pageToken)) setThreadsLoader(true)

	// 	var nylasThreads = window.firebase.functions().httpsCallable('nylasv3-threads');
	// 	nylasThreads({
	// 		grantId: userData.nylas_v3.grantId,
	// 		options: _options
	// 	}).then(res => {
	// 		console.log('nylasThreads', `folderID:-${folderID}`, _options, res)
	// 		if (res?.data?.success === true) {

	// 			dispatch({
	// 				type: isPaging ? "APPEND_LIST" : "SUCCESS",
	// 				activetab: folderID,
	// 				data: res?.data?.list?.data,
	// 				sortName: 'latestMessageReceivedDate',
	// 				sortOrder: 'desc'
	// 			});

	// 			// if (res?.data?.list?.nextCursor)
	// 			// 	setHasMoreData([res?.data?.list?.nextCursor])

	// 			if (res?.data?.list?.nextCursor) {
	// 				setHasMoreData([...hasMore.filter(e => e.folder !== folderID),
	// 				{
	// 					folder: folderID,
	// 					nextCursor: res?.data?.list?.nextCursor

	// 				}])
	// 			}
	// 			else {
	// 				setHasMoreData([...hasMore.filter(e => e.folder !== folderID)]);
	// 			}

	// 			setDataload(false)
	// 			// setLoader(false)
	// 			setThreadsLoader(false)
	// 			setRefreshLoader(false)
	// 			setPaging(false)
	// 			setLoadingMore(false)


	// 		}
	// 	}).catch(err => console.log(err))
	// 	// let threadsRef = window.firebase.firestore().collection(`nylasv3-accounts/${userData.nylas_v3.grantId}/nylas-threads`)
	// 	// 	.where('folders', 'array-contains', folderID)
	// 	// 	//.orderBy('latestMessageReceivedDate', 'desc')

	// 	// if (hasMore.length > 0) {
	// 	// 	threadsRef = threadsRef
	// 	// 		.startAfter(hasMore[0])
	// 	// 		.limit(pageLimit)
	// 	// }
	// 	// else {
	// 	// 	threadsRef = threadsRef
	// 	// 		.limit(pageLimit)
	// 	// }

	// 	// window[`unsublist_${folderID}`] = threadsRef
	// 	// 	.onSnapshot(onCollectionUpdate);



	// }, [checkDataLoad])

	// const onCollectionUpdate = (querySnapshot) => {
	// 	let actionType;
	// 	let snapshotDoc;
	// 	let messagesArr = [];

	// 	querySnapshot.docChanges().forEach(change => {
	// 		if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
	// 		{
	// 			snapshotDoc = {
	// 				...change.doc.data(),
	// 			};
	// 			if (change.type === 'added') {
	// 				actionType = "ADD_LIST";
	// 			}
	// 			else if (change.type === 'modified') {
	// 				actionType = "UPDATE_LIST"
	// 			}
	// 			else if (change.type === 'removed' && snapshotDoc.folders.indexOf(folderID) < 0) {
	// 				actionType = "REMOVE_LIST"
	// 			}
	// 		}
	// 	})
	// 	if (!actionType) {
	// 		//console.log('querySnapshot.docs.length, pageLimit', querySnapshot.docs.length, pageLimit)
	// 		if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
	// 			setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
	// 		}
	// 		else {
	// 			setHasMoreData([]);
	// 		}
	// 		if (querySnapshot.size > 0) {
	// 			querySnapshot.forEach(doc => {
	// 				messagesArr.push(doc.data());
	// 			});
	// 			// setMessages(messagesArr)
	// 		} else {
	// 			messagesArr = [];
	// 			// setMessages([])
	// 			// return
	// 		}
	// 	}

	// 	dispatch({
	// 		type: isPaging ? "APPEND_LIST" : actionType ? actionType : "SUCCESS",
	// 		data: actionType ? snapshotDoc : messagesArr
	// 	});


	// 	setDataload(false)
	// 	// setLoader(false)
	// 	setThreadsLoader(false)
	// 	setPaging(false)
	// 	setLoadingMore(false)
	// }

	useEffect(() => {
		const filteringMessages = () => {
			if (threads && threads.length > 0) {
				let filteredMessages1 = threads.filter(message => {
					let result = false;
					message.folders.forEach(label => {
						if (label === CommonHelper.getNylasFolder(folders, 'Trash', 'id')) {
							result = true;
						}
					});

					return result;
				});


				setFilteredThreads([...filteredMessages1]);
				if (shouldChildSetSelectedMsg) {
					// setSelectedThread(filteredMessages1[0]);;
				} else if (!shouldChildSetSelectedMsg) {
					setShouldChildSetSelectedMsg(true);
				}
			}
			else {
				setFilteredThreads([]);
			}
		}
		filteringMessages();
	}, [threads]);



	return (
		<div className='email-list-wraper'>
			<EmailList
				threads={filteredThreads}
				setSelectedThread={setSelectedThread}
				selectedThread={selectedThread}
				filterThreads={filterThreads}
				selectThreads={selectThreads}
				threadsSelected={threadsSelected}
				searchText={searchText}
				setSearchText={setSearchText}
				updateThread={updateThread}
				loadMoreThreads={loadMoreThreads}
				type={folderID}
				loadingMore={loadingMore}
				searchThreads={searchThreads}
				refreshThreads={refreshThreads}
				hasMore={hasMore}
				threadsLoader={threadsLoader}
				refreshLoader={refreshLoader}
				handleMarkAs={handleMarkAs}
				setThreadsSelected={setThreadsSelected}
				setFilteredThreads={setFilteredThreads}
				filteredThreads={filteredThreads}
				filterThread={filterThread}
				emailtemplates={emailtemplates}
				history={history}
			/>
			{filteredThreads.length > 0 && folderID !== CommonHelper.getNylasFolder(folders, 'Drafts', 'id') && _.isEmpty(selectedThread?.draftIds) ? (
				<EmailListBody
					selectedThread={selectedThread}
					setSelectedThread={setSelectedThread}
					token={token}
					handleOpenComposeModal={handleOpenComposeModal}
					updateThread={updateThread}
					threadsLoader={threadsLoader}
					refreshLoader={refreshLoader}
					type={folderID}
					userData={userData}
				/>
			) : (
				<div className='email-list-body'></div>
			)}
		</div>
	);
};

export default Trash;
