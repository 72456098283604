import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import _images from '../../../images'
import moment from 'moment'
import {  FunnelChart, NoDataFunnelChart } from '../../../components'
 import { pipelinestatus } from './viewModel'
 import toast from 'toasted-notes'
 import CommonHelper from '../../../services/common';
const SalesFunnel = (props) => {
    const [data, setData] = useState([])
     const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
       const [filter, setFilter] = useState({ pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',') });
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    // useEffect(() => {
    //     setOtherEnquiry((!_.isEmpty(props.dealersettings) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //         props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false)
    // }, [props.dealersettings])

    // useEffect(() => {
    //     if (_.isEmpty(props.pipeline))
    //         return
    //     let _pipeline = [];
    //     props.pipeline.filter(item => item.value !== 'LeadsBucket').forEach(rec => {
    //         _pipeline.push({
    //             label: rec.name,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             value: rec.value,
    //             data: rec
    //         })
    //     })
    //     setPipelineOption(_pipeline)
    // }, [props.pipeline])

    // useEffect(() => {
    //     if (_.isEmpty(props.clientUsers))
    //         return
    //     let _users = [];
    //     props.clientUsers.forEach(rec => {
    //         _users.push({
    //             value: rec.id,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             searchlabel: rec.name,
    //             label: <div className="drop-image">
    //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" />
    //                 {rec.name}
    //             </div>,
    //             data: rec
    //         })
    //     })
    //     setUserOptions(_users)
    // }, [props.clientUsers])

    // useEffect(() => {
    //     if (!refreshData)
    //         return

    //     setLoader(true)

    //     const reportRef = window.firebase.functions().httpsCallable('reports-getCounts');
    //     reportRef({
    //         "type": "getSalesFunnelReport",
    //         "clientID": props.dealersettings.client.id,
    //         "filters": JSON.stringify(filter)
    //     }).then((data) => {
    //         //console.log(data)
    //         if (data.data.success === true) {
    //             if (!_.isEmpty(filter.pipeline) && filter.pipeline.split(',').length > 1) {
    //                 setData(_.map(pipelinestatus, function (e) {
    //                     return {
    //                         name: _.find(pipelinestatus, { value: e.value }) ? _.find(pipelinestatus, { value: e.value }).name : e.name,
    //                         count: _.find(data.data.data, { name: e.value }) ? _.find(data.data.data, { name: e.value }).count : 0,
    //                         value: e.value,
    //                         type: 'status',
    //                         color: e.color ? e.color : ''
    //                     }
    //                 }))
    //             }
    //             else if (!_.isEmpty(filter.pipeline) && filter.pipeline.split(',').length === 1 && props.dealersettings.client.settings.pipelines.some(item => item.value === filter.pipeline)) {
    //                 let _data = _.map(props.dealersettings.client.settings.pipelines.find(item => item.value === filter.pipeline).stages, function (e) {
    //                     return {
    //                         name: _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === filter.pipeline).stages, { value: e.value }) ? _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === filter.pipeline).stages, { value: e.value }).name : e.name,
    //                         count: _.find(data.data.data, { name: e.value }) ? _.find(data.data.data, { name: e.value }).count : 0,
    //                         value: e.value,
    //                         type: 'stage'
    //                     }
    //                 })
    //                 pipelinestatus.filter(item => item.value !== 'open').forEach(rec => {
    //                     _data.push({
    //                         name: _.find(pipelinestatus, { value: rec.value }) ? _.find(pipelinestatus, { value: rec.value }).name : rec.name,
    //                         count: _.find(data.data.data, { name: rec.value }) ? _.find(data.data.data, { name: rec.value }).count : 0,
    //                         value: rec.value,
    //                         type: 'status',
    //                         color: rec.color ? rec.color : ''
    //                     })
    //                 })
    //                 setData(_data)
    //             }
    //             setLoader(false)
    //             setRefresh(false)

    //         }
    //         else {
    //             console.log(data.data.message)
    //             setLoader(false)
    //             setRefresh(false)
    //         }
    //     });


    //}, [refreshData])

    const fetchData = async () => {
        // console.log('lead by stage')
        let _filter = {
            clientID: props.dealersettings.client.id,
            type: 'status',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket').map(item => item.value).join(',')
        }

        if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType  =  props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp alternate', resp)
            let enquiries_ = resp.data.data.map(rec => {
                const value = rec.name
                let color = ''
                let sName = _.startCase(_.toLower(rec.name));
                let _pipelinestatus = pipelinestatus.filter(a => a.value === value)[0]
                if (_pipelinestatus.color) {
                    color = _pipelinestatus.color;
                    sName = _pipelinestatus.name
                }
                return {
                    ...rec,
                    name: sName,
                    value,
                    color,
                    type: 'status',
                    startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                    endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
            })
            enquiries_ = _.orderBy(enquiries_, ['count'], ['desc'])
            setData(enquiries_)
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const fetchDataStage = async () => {
        //console.log('lead by stage')
        let _filter = {
            clientID: props.dealersettings.client.id,
            type: 'stage',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket').map(item => item.value).join(',')
        }

        if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType  =  props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp alternate', resp)
            let enquiries_ = resp.data.data.map(pipeline => {
                const value = pipeline.name
                let name = !_.isEmpty(props.selectedPipeline) ?
                    _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value: value }) ? _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value: value }).name : value
                    : 'Open'

                return {
                    ...pipeline,
                    name: name,
                    value,
                    type: 'stage',
                    startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                    endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
            })
            enquiries_ = _.orderBy(enquiries_, ['count'], ['desc'])
            setData(enquiries_)
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    useEffect(() => {
        if (!_.isEmpty(props.selectedPipeline)) {
            fetchDataStage()
        } else {
            fetchData()
        }
    }, [props.selectedUser, props.selectedPipeline, props.dateRange])

    useEffect(() => {
        if (_.isEmpty(props.pipelineEnquiries) || props.pipelineEnquiries.length === 0) {
            //setLoader(false)
            return
        }

        var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })


        var monthWon = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
            && _pipelines.some(e => e === a.pipelineValue)
            && !_.isEmpty(a.wonDate)
            && a.wonDate.seconds >= monthStart.seconds
            && a.wonDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true));

        var monthLost = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'lost'
            && _pipelines.some(e => e === a.pipelineValue)
            && !_.isEmpty(a.lostDate)
            && a.lostDate.seconds >= monthStart.seconds
            && a.lostDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true))

        var convertedLeads = props.pipelineEnquiries.filter(a => a.pipelineValue !== 'LeadsBucket'
            && !_.isEmpty(a.convertedDate)
            && a.isConverted === true
            && a.convertedDate.seconds >= monthStart.seconds
            && a.convertedDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true))

        var groupbyStatus = []
        pipelinestatus.filter(item => (item.value === 'won' || item.value === 'lost')).forEach(rec => {
            groupbyStatus.push({
                name: rec.name,
                count: rec.value === 'won' ? monthWon.length : rec.value === 'lost' ? monthLost.length : 0,
                color: rec.color ? rec.color : '',
                value: rec.value,
                type: 'status',
                startDate: monthStart,
                endDate: monthEnd
            })
        });


        var groupbyStages = _.chain(props.pipelineEnquiries.filter(item => (!_.isEmpty(props.selectedUser) ? item.ownerValue === props.selectedUser.value : true)

            && item.addedDate.seconds >= monthStart.seconds
            && item.addedDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedPipeline) ? item.pipelineValue === props.selectedPipeline.value : (item.statusValue === 'open' && _pipelines.some(e => e === item.pipelineValue)))
        ))
            .groupBy(!_.isEmpty(props.selectedPipeline) ? "stageValue" : "statusValue")
            .map((value, key) => ({
                name: !_.isEmpty(props.selectedPipeline) ?
                    _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value: key }) ? _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value: key }).name : key
                    : 'Open',
                count: value.length,
                value: key,
                type: !_.isEmpty(props.selectedPipeline) ? 'stage' : 'status',
                startDate: monthStart,
                endDate: monthEnd
            }))
            .value()

        // if(!_.isEmpty(props.selectedPipeline)&&  props.selectedPipeline.value === 'LeadsBucket'){
        //     groupbyStages.push({
        //         name :  _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value:  'Converted' }) ? _.find(props.dealersettings.client.settings.pipelines.find(item => item.value === props.selectedPipeline.value).stages, { value:  'Converted' }).name :  'Converted',
        //         count: convertedLeads.length,
        //         value: 'Converted',
        //         type :  'stage',
        //         startDate : monthStart,
        //         endDate : monthEnd
        //     })
        // }

        // var groupbyStatus = _.chain(props.pipelineEnquiries.filter(item => (item.statusValue === 'won' || item.statusValue === 'lost')
        //     && item.pipelineValue !== 'LeadsBucket'
        //     && item.addedDate.seconds >= monthStart.seconds
        //     && item.addedDate.seconds <= monthEnd.seconds
        //     && (!_.isEmpty(props.selectedUser) ? item.ownerValue === props.selectedUser.value : true)
        //     && (!_.isEmpty(props.selectedPipeline) ? item.pipelineValue === props.selectedPipeline.value : true)
        // ))
        //     .groupBy("statusValue")
        //     .map((value, key) => ({
        //         name: _.find(pipelinestatus, { value: key }) ? _.find(pipelinestatus, { value: key }).name : key,
        //         count: value.length,
        //         value: key,
        //         type: 'status',
        //         color: _.find(pipelinestatus, { value: key }) ? _.find(pipelinestatus, { value: key }).color : ''
        //     }))
        //     .value()
        // if (!_.isEmpty(props.selectedPipeline))
        //     setData(_.map(props.selectedPipeline.data.stages, function (e) {
        //         return _.find(groupbyStages, { value: e.value }) ? { ..._.find(groupbyStages, { value: e.value }) } : { ...e, type: 'stage', count: 0 }
        //     }))
        // else
        //     setData(groupbyStages.concat(groupbyStatus))
        // setData(_.chain(props.pipelineEnquiries.filter(item => !_.isEmpty(item.enquiryTypeValue)
        //     && (!_.isEmpty(props.selectedUser) ? item.ownerValue === props.selectedUser.value : true)
        //     && (!_.isEmpty(props.selectedPipeline) ? item.pipelineValue === props.selectedPipeline.value : true)
        // ))
        //     .groupBy("enquiryTypeValue")
        //     .map((value, key) => ({
        //         name: _.find(props.dealersettings.client.settings.enquiryTypes, { value: key }) ? _.find(props.dealersettings.client.settings.enquiryTypes, { value: key }).name : key,
        //         count: value.length,
        //         value: key
        //     }))
        //     .value())
        //setLoader(false)

    }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a>*/}
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                    </div>
                    <h3>Sales Funnel</h3>
                </div>
                {
                    data.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <FunnelChart
                                    id={'sales-funnel-chart'}
                                    height={'350px'}
                                    data={data.filter(item => item.count > 0)}
                                    handleShowEnquiryList={(value, name, startDate, endDate) => {
                                        //  console.log('value,startDate,endDate', value,startDate,endDate)
                                        //  console.log('data', data)
                                        props.handleShowEnquiryList({
                                            ...filter,
                                            [name]: (name === 'status' && value === 'won') ? 'wondelivered' : value,
                                            pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                            'startDate': startDate,
                                            'endDate': endDate,
                                            'dateType': value === 'delivered' ? 'deliveredDate' : value === 'won' ? 'wonDate' : value === 'lost' ? 'lostDate' : 'addedDate'
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataFunnelChart
                                    id={'sales-funnel-chart'}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }

            </div>
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}
export default SalesFunnel;