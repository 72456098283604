/** LIBRARIES */
import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useRef,
    useMemo
} from 'react';
import { Tab, Nav } from 'react-bootstrap';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import CustomerEditLog from '../pipeline/quickview/editLogs';
//import CustomerlogDetails from '../pipeline/quickview/customerlogDetails';
import { PopUpModal } from '../../components';
import { objActivitylog, enquiryActivities } from '../pipeline/viewModel';
//import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import EmailPreview from "../mails/emailPreview";
import ComposeEmail from '../mails/composeEmail'
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { enqUnActionStatus } from '../../services/enum';
//import CustomerlogReducer from './customerlogReducer';

const CustomerlogsReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callType = action.data.callType
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallStatus = item.callStatus
                item.oldcallType = item.callType
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        default:
            return state;
    }
};

function AddActivityLog(props) {
    //const[ customerlogs,setCustomerlog ] = useState([])
    const [customerlogs, dispatch] = useReducer(CustomerlogsReducer, []);
    const [loading, setLoading] = useState(false);
    //const [activeKey, setActiveKey] = useState()
    const textareaRef = useRef(null);

    const [activeTab, setActivetab] = useState('message');

    const [newCustomerlog, setNewCustomerlog] = useState(null);

    const [showCustomerlog, setShowCustomerlog] = useState(true);
    const [showPreviewModel, setPreviewModel] = useState(false)
    const [emailLog, setEmaillog] = useState(null)
    const [popheader, setPopheader] = useState()
    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
    });
    const [sendLoader, setSendLoader] = useState(false);
    const [emailTemplates, setEmailTemplates] = useState([]);

    const _messagemedia = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);
    const _smshub = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);
    const _podium = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "podium")[0]);
    const _cmdotcom = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "cmdotcom")[0]);
    const _ringcentral = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);
    const enableTwoWaySMS = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableTwoWaySMS) ? true : false);

    const justcallEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
    ) ? true : false);

    const messageMediaEnabled = enableTwoWaySMS && _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
    const smsHubEnabled = enableTwoWaySMS && _smshub && _smshub.active && _smshub.enabled ? true : false;
    const podiumEnabled = enableTwoWaySMS && _podium && _podium.active && _podium.enabled ? true : false;
    const cmdotcomEnabled = _cmdotcom && _cmdotcom.active && _cmdotcom.enabled ? true : false;
    const ringcentralEnabled = enableTwoWaySMS && _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
    const [sendLoading, setSendLoading] = useState(false);

    useEffect(() => {
        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = CommonHelper.getActivityType('log', props.originType);
        newlogVM.subType = activeTab;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        if (!_.isEmpty(props.enquiry)) {
            newlogVM.enquiryID = props.enquiry.documentID;
            newlogVM.enquiry = CommonHelper.getMinifiedData(props.enquiry, 'enquiry');
            if (!_.isEmpty(props.enquiry.contact)) {
                newlogVM.contactID = props.enquiry.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.enquiry.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.tradeinPro)) {
            newlogVM.tradeinProID = props.tradeinPro.documentID;
            newlogVM.tradeinPro = CommonHelper.getMinifiedData(props.tradeinPro, 'tradeinPro');
            if (!_.isEmpty(props.tradeinPro.contact)) {
                newlogVM.contactID = props.tradeinPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.tradeinPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.eventsPro)) {
            newlogVM.eventsProID = props.eventsPro.documentID;
            newlogVM.eventsPro = CommonHelper.getMinifiedData(props.eventsPro, 'eventsPro');
            if (!_.isEmpty(props.eventsPro.contact)) {
                newlogVM.contactID = props.eventsPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.eventsPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.service)) {
            newlogVM.serviceID = props.service.documentID;
            newlogVM.service = CommonHelper.getMinifiedData(props.service, 'service');
            if (!_.isEmpty(props.service.contact)) {
                newlogVM.contactID = props.service.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.service.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.contact)) {
            newlogVM.contactID = props.contact.documentID;
            newlogVM.contact = CommonHelper.getMinifiedData(props.contact, 'contact');
        }
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities')
            .doc().id;
        newlogVM.callDuration = 0;
        newlogVM.isDone = true;
        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(props.enquiry)
            ? props.enquiry.clientID
            : null));
        if (props.linkActivityID)
            newlogVM.linkActivityID = props.linkActivityID;
        setNewCustomerlog(newlogVM);
    }, [activeTab, showCustomerlog]);

    //get all email templates
    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || !_.isEmpty(emailTemplates)) {
            return;
        }

        var alltemplates = combineLatest(
            collectionData(
                window.firebase
                    .firestore()
                    .collection(
                        `clientSettings/${props.dealersettings.client.settingsID}/templates`,
                    )
                    .where('clientID', '==', props.dealersettings.client.id)
                    .where('visibility', '==', 'private')
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'emailTemplate'),
            ),
            collectionData(
                window.firebase
                    .firestore()
                    .collection(
                        `clientSettings/${props.dealersettings.client.settingsID}/templates`,
                    )
                    .where('visibility', '==', 'shared')
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'emailTemplate'),
            ),
        )
            .pipe(
                map(templates => {
                    var [dealer, shared] = templates;
                    return [[...dealer], [...shared]];
                }),
            )
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => {
                    _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec);
                });
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push(rec);
                });
                setEmailTemplates(listVM);
            });
        return () => {
            alltemplates && alltemplates.unsubscribe();
        };
    }, [props.dealersettings]);

    const handlecancellog = useCallback((e) => {
        e.preventDefault();
        props.handleClose();
    }, []);

    const handlechangelog = useCallback((e, objLog) => {
        e.preventDefault && e.preventDefault();
        var domTextref = document.getElementById('notes-tab-' + objLog.documentID);
        if (e.target.name === 'notes' && e.target.value.length === 0) {
            domTextref && domTextref.classList.add('input_error');
        } else {
            domTextref && domTextref.classList.remove('input_error');
        }
        if (objLog.isEditable) {
            dispatch({
                type: "CHANGELOG",
                id: objLog.documentID,
                field: e.target.name,
                value: e.target.value
            });
        } else {
            setNewCustomerlog({
                ...objLog,
                [e.target.name]: e.target.value,
            });
        }
    }, []);

    const handleDateChangelog = useCallback((value, name, objLog) => {
        if (objLog.isEditable) {
            dispatch({
                type: 'CHANGELOG',
                id: objLog.documentID,
                field: name,
                value:
                    name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber'
                        ? value
                        : window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
            });
        } else {
            setNewCustomerlog({
                ...objLog,
                subType: objLog.subType,
                [name]:
                    name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber'
                        ? value
                        : window.firebase.firestore.Timestamp.fromDate(moment(value)._d),
            });
        }
    }, []);


    const handleFileChange = useCallback((url, name, objLog, aTab) => {
        setNewCustomerlog({
            ...objLog,
            subType: objLog.subType,
            ['files']: _.isEmpty(url) ? null : [{
                name,
                url
            }],
        });
    }, []);

    const handlesavelog = useCallback((e, objLog, completedTab, mentions, isSend) => {
        e.preventDefault();
        var domTextref = document.getElementById('notes-tab-' + objLog.documentID);
        if (objLog.notes.length === 0 && domTextref && _.isEmpty(objLog?.files)) {
            domTextref.focus();
            domTextref.classList.add('input_error');
            return;
        }

        delete objLog.oldnotes;
        delete objLog.oldcallDuration;
        delete objLog.oldcallStatus;
        delete objLog.oldcallType;
        delete objLog.oldaddedDate;
        objLog.startDate = objLog.addedDate;
        if (objLog.isNewlog) {
            delete objLog.isNewlog;
            objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        } else if (objLog.isEditable) {
            delete objLog.isEditable;
            objLog.modifiedBy = localStorage.uid;
            objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        }

        objLog.modifiedByName = props.dealersettings.name;
        objLog.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        if (mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })

            if (objLog.mentions && objLog.mentions.length > 0) {
                objLog.mentions = _.uniq([...objLog.mentions, ...convertedIDs])
            } else {
                objLog.mentions = _.uniq(convertedIDs)
            }
        }
        if (isSend)
            setSendLoading(true);
        else
            setLoading(true);

        firestoreDB(props.dealersettings)
            .firestore()
            .doc(`activities/${objLog.documentID}`)
            .set(objLog, { merge: true })
            .then(snapshot => {
                toast.notify('Log updated successfully', {
                    duration: 2000,
                    position: 'top-right',
                });
                setLoading(false);
                setSendLoading(false);
                setShowCustomerlog(false);

                //update enquiry modified date if new log is added
                if (!_.isEmpty(objLog.enquiryID)) {
                    let _objEq = {
                        modifiedBy: localStorage.uid,
                        modifiedFrom: 'web',
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                    }
                    if (objLog.subType !== 'note') _objEq.unactionedNotify = enqUnActionStatus.COMPLETED;
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set(_objEq, { merge: true })
                }

                if (objLog.type === 'log' && objLog.subType === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled || cmdotcomEnabled || ringcentralEnabled || justcallEnabled) && isSend) {
                    try {
                        handleSendToMessageMedia(objLog)
                    }
                    catch (error) {
                        console.error(error)
                    }
                }

                props.handleClose(objLog.documentID);
                // if (document.getElementById('customerlog-' + objLog.documentID)) {
                //     setTimeout(function () {
                //         document
                //             .getElementById('customerlog-' + objLog.documentID)
                //             .scrollIntoView({
                //                 behavior: 'smooth',
                //                 block: 'center',
                //             });
                //     }, 1000);
                // }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setSendLoading(false);
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right',
                });
            });
    }, []);

    const handleSendToMessageMedia = (objLog) => {
        var objData = Object.assign({}, {
            "clientID": objLog.clientID,
            "activityID": objLog.documentID,
            "text": objLog.notes,
            "owner": objLog.owner,
            "projectId": props.dealersettings?.client?.projectId || null
        });

        if (!_.isEmpty(props.contact)) {
            let _contact = Object.assign({}, props.contact);
            objData.phone = _contact.phone ? _contact.phone : '';
            if (podiumEnabled) {
                objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
                objData.contactName = CommonHelper.displayContactName([], _contact);
                objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
            }
            if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
                objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
            }
        }
        else if (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact)) {
            let _contact = Object.assign({}, props.enquiry.contact);
            objData.phone = _contact.phone ? _contact.phone : '';
            if (podiumEnabled) {
                objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
                objData.contactName = CommonHelper.displayContactName([], _contact);
                objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
            }
            if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
                objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
            }
        }

        if (objLog?.files?.length > 0) {
            objData.mms = objLog.files[0].url
        }
        if (justcallEnabled) {
            objData.email = props?.dealersettings?.email || null;
        }
        if (props?.dealersettings?.client?.integrations?.filter(e => e.type === "justcall")?.[0]?.settings) {
            objData = {
                ...objData,
                ...props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].settings,
                sourceNumber: objLog.sourceNumber || null
            }
        }
        // console.log('handleSendToMessageMedia-quickview', objData);
        const messagemediasend = window.firebase.functions().httpsCallable(`${messageMediaEnabled ? 'messagemedia' : smsHubEnabled ? 'smshub' : cmdotcomEnabled ? 'cmdotcom' : ringcentralEnabled ? 'ringcentral' : justcallEnabled ? 'justcall' : 'podium'}-send`);
        messagemediasend(objData).then((_data) => {
            console.log('messagemediasend-quickview', _data);
        });

    }


    const handleModelClose = () => {
        setEmaillog(null)
        setPreviewModel(false)
    }

    const handleComposeEmail = () => {
        if (_.isEmpty(props.dealersettings?.nylas_v3?.grantId)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Please configure email settings'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Add Settings'),
                cancelButtonText: CommonHelper.showLocale(props, 'Later'),
            }).then(result => {
                if (result.value) {
                    props.history.push('/settings/profile?key=emailsettings');
                }
            });
        }
        else {
            setShowComposeModal({
                show: true,
                type: 'compose',
                title: 'New Message'
            })
        }
    }

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            show: false,
            type: '',
            title: ''
        })
    }

    // const handleSend = async (trigger) => {
    //     const { mailTo, mailCc, mailBcc, subject, email } = trigger;
    //     setSendLoader(true);

    //     if (mailTo === '' && mailCc === '' && mailBcc === '') {
    //         toast.notify('Please specify at least one recipient.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (subject === '') {
    //         toast.notify('Please write subject.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (email === '' || email === '<p></p>\n') {
    //         toast.notify('Please write in email body.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     try {
    //         let emailObj = {
    //             token: props.dealersettings.nylasAccessToken,
    //             draft: {
    //                 subject: trigger.subject,
    //                 body: trigger.email,
    //             },
    //         };

    //         if (trigger.mailTo !== '') {
    //             emailObj.draft.to = trigger.mailTo.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailCc !== '') {
    //             emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailBcc !== '') {
    //             emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.files.length > 0) {
    //             emailObj.files = trigger.files;
    //         }

    //         setTimeout(() => {
    //             // toast.notify('sending..', {
    //             // 	duration: 2000,
    //             // });
    //             toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> sending...</div>),
    //                 {
    //                     position: 'bottom-left',
    //                     duration: null
    //                 },
    //             )
    //             setShowComposeModal({
    //                 ...showComposeModal,
    //                 show: false,
    //                 type: '',
    //                 title: '',
    //                 emailData: null,
    //             });
    //         }, 300);

    //         // console.log('emailObj', emailObj);
    //         const nylasSendEmail = window.firebase
    //             .functions()
    //             .httpsCallable('nylas-sendEmail');
    //         const resp = await nylasSendEmail(emailObj);

    //         if (resp.data.success) {
    //             linkEnqruiy(resp.data)
    //             setSendLoader(false);
    //         } else {
    //             setSendLoader(false);
    //             toast.closeAll();
    //             if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
    //                 Swal.fire({
    //                     title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
    //                     icon: 'info',
    //                     showCancelButton: true,
    //                     confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
    //                     cancelButtonText: CommonHelper.showLocale(props, 'Later'),
    //                 }).then(result => {
    //                     if (result.value) {
    //                         window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
    //                             nylas: null,
    //                         }, { merge: true });
    //                         props.history.push('/settings/profile?key=emailsettings');
    //                     }
    //                 });
    //                 return
    //             }
    //             else {
    //                 Swal.fire('There was an error in sending email.', '', 'error');
    //             }
    //         }

    //         // console.log('response', resp);
    //     } catch (error) {
    //         toast.closeAll();
    //         Swal.fire('There was an error in sending email.', '', 'error');
    //         setSendLoader(false);
    //         console.log(error);
    //     }
    // };

    // const linkEnqruiy = (data) => {

    //     var id = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id;
    //     const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

    //     const model = {
    //         documentID: id,
    //         clientID: props.dealersettings.client.documentID,
    //         thread: data.thread,
    //         token: props.dealersettings.nylasAccessToken,
    //         linkedBy: localStorage.uid,
    //         linkedDate: window.firebase.firestore.Timestamp.now(),
    //         type: CommonHelper.getActivityType('log', props.originType),
    //         projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
    //     };

    //     if (!_.isEmpty(props.enquiry)) {
    //         model.enquiryID = props.enquiry.documentID;
    //         model.enquiry = props.enquiry;
    //         if (!_.isEmpty(props.enquiry.contact)) {
    //             model.contactID = props.enquiry.contact.documentID;
    //             model.contact = props.enquiry.contact;
    //         }
    //     }
    //     else if (!_.isEmpty(props.tradeinPro)) {
    //         model.tradeinProID = props.tradeinPro.documentID;
    //         model.tradeinPro = props.tradeinPro;
    //         if (!_.isEmpty(props.tradeinPro.contact)) {
    //             model.contactID = props.tradeinPro.contact.documentID;
    //             model.contact = props.tradeinPro.contact;
    //         }
    //     }
    //     else if (!_.isEmpty(props.service)) {
    //         model.serviceID = props.service.documentID;
    //         model.service = props.service;
    //         if (!_.isEmpty(props.service.contact)) {
    //             model.contactID = props.service.contact.documentID;
    //             model.contact = props.service.contact;
    //         }
    //     }
    //     else if (!_.isEmpty(props.contact)) {
    //         model.contactID = props.contact.documentID;
    //         model.contact = props.contact;
    //     }

    //     if (props.linkActivityID)
    //         model.activityID = props.linkActivityID;

    //     linkref.set(model, {
    //         merge: true,
    //     });

    //     if (!_.isEmpty(model.enquiryID)) {
    //         firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
    //             .set({
    //                 modifiedBy: localStorage.uid,
    //                 modifiedFrom: 'web',
    //                 unactionedNotify: enqUnActionStatus.COMPLETED,
    //                 modifiedDate: window.firebase.firestore.Timestamp.now(),
    //             }, { merge: true })
    //     }

    //     if (!_.isEmpty(data.data)) {
    //         let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(data.data.id)
    //         messageRef.set({
    //             ...data.data,
    //             projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
    //         })
    //     }
    //     toast.closeAll();
    //     toast.notify('Email sent successfully.', {
    //         duration: 2000,
    //     });
    // }


    const noteLogs = useMemo(() => customerlogs
        .filter(
            ({ subType }) => (subType === 'note')
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const messageLogs = useMemo(() => customerlogs
        .filter(
            ({ subType }) => (subType === 'message')
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const callLogs = useMemo(() => customerlogs
        .filter(
            ({ subType }) => (subType === 'call')
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const emailLogs = useMemo(() => customerlogs
        .filter(
            ({ subType }) => (subType === 'email')
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [customerlogs])
    const allLogs = {
        Notes: noteLogs,
        Message: messageLogs,
        Call: callLogs,
        Email: emailLogs
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style" style={{ minHeight: '350px' }}>
                                <div className='mid-column-wrap'>
                                    <div className='activity-nav' id="activity-nav-new">
                                        <Tab.Container activeKey={activeTab}>
                                            <Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
                                                {enquiryActivities
                                                    .filter(item => item.value !== 'activity' && item.value !== 'note')
                                                    .map((rec, index) => {
                                                        return (
                                                            <Nav.Item as='li' bsPrefix='li-nav' key={index}>
                                                                <Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
                                                                    {
                                                                        <>
                                                                            <i className={rec.ico}></i>
                                                                            <Translate text={(props.contact && props.contact.phone && rec.value === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled)) ? 'sendMessage' : rec.name} />

                                                                        </>
                                                                    }
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        );
                                                    })}
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                    {showCustomerlog ? (
                                        activeTab === 'note' ||
                                            activeTab === 'call' ||
                                            activeTab === 'message' ? (
                                            <div className='activity-nav-editmode pl-3 pr-3'>
                                                <CustomerEditLog
                                                    handlechangelog={handlechangelog}
                                                    handlesavelog={handlesavelog}
                                                    handlecancellog={handlecancellog}
                                                    customerlog={newCustomerlog}
                                                    textareaRef={textareaRef}
                                                    handleDateChangelog={handleDateChangelog}
                                                    loading={loading}
                                                    clientUsers={props.clientUsers}
                                                    dealersettings={props.dealersettings}
                                                    messageMediaEnabled={messageMediaEnabled}
                                                    smsHubEnabled={smsHubEnabled}
                                                    podiumEnabled={podiumEnabled}
                                                    cmdotcomEnabled={cmdotcomEnabled}
                                                    sendLoading={sendLoading}
                                                    // contact={
                                                    //     !_.isEmpty(props.enquiry)
                                                    //         ? props.enquiry.contact
                                                    //         : !_.isEmpty(props.contact)
                                                    //             ? props.contact
                                                    //             : null
                                                    // }
                                                    contact={
                                                        !_.isEmpty(props.contact)
                                                            ? props.contact
                                                            : !_.isEmpty(props.enquiry)
                                                                ? props.enquiry.contact
                                                                : null
                                                    }
                                                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                                                    originType={props.originType}
                                                    handleFileChange={handleFileChange}
                                                />
                                            </div>
                                        ) : (activeTab === "email") ? (
                                            <div
                                                className='related-list-subinput'
                                                onClick={() => {
                                                    handleComposeEmail()
                                                }}
                                            >
                                                <span className='activity-edit-label'>
                                                    <Translate text={
                                                        enquiryActivities.filter(e => e.value === activeTab)[0]
                                                            .emptymessage
                                                    } />

                                                </span>
                                            </div>
                                        ) : (
                                            <div className='related-list-subinput'>
                                                <span className='activity-edit-label'>
                                                    <Translate text={
                                                        enquiryActivities.filter(e => e.value === activeTab)[0]
                                                            .emptymessage
                                                    } />

                                                </span>
                                            </div>
                                        )
                                    ) : (
                                        <div
                                            className='related-list-subinput'
                                            onClick={() => {
                                                setShowCustomerlog(true);
                                            }}
                                        >
                                            <span className='activity-edit-label'>
                                                <Translate text={
                                                    enquiryActivities.filter(e => e.value === activeTab)[0]
                                                        .emptymessage
                                                } />

                                            </span>
                                        </div>
                                    )}
                                </div>


                                {/* <div className='quickview-activity-wraper'>
                                    {customerlogLoader ? (
                                        <div className='mt-5'>
                                            <ContentOverlay active={true} />
                                        </div>
                                    ) : (<>
                                        <div className='enquiry-flow-nav pl-0'>
                                            <ul>
                                                {customerlogTags.map((rec, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a
                                                                href='#'
                                                                id={rec}
                                                                className={`nav-activity-tabs nav-li-${rec} ${activeLogTab === rec ? 'active' : ''
                                                                    }`}
                                                                onClick={event => {
                                                                    if (event.target.id) {
                                                                        setActiveLogTab(event.target.id);
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >
                                                                {rec}
                                                                {
                                                                    allLogs[rec].length > 0 ? (
                                                                        <span className="count">({allLogs[rec].length})</span>
                                                                    ) : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        {allLogs[activeLogTab].length > 0 ? (
                                            <>
                                                <div className='quickview-activity-item'>
                                                    {allLogs[activeLogTab]
                                                        .sort(function (x, y) {
                                                            return x.startDate.seconds > y.startDate.seconds ? -1 : 1;
                                                        })
                                                        .map((obj, index) => {
                                                            return (
                                                                <CustomerlogDetails
                                                                    key={index}
                                                                    type={obj.subType}
                                                                    clientUsers={props.clientUsers}
                                                                    customerlog={obj}
                                                                    handlecancellog={handlecancellog}
                                                                    handlechangelog={handlechangelog}
                                                                    handlesavelog={handlesavelog}
                                                                    handleEditlog={handleEditlog}
                                                                    isNewlog={obj.isNewlog}
                                                                    isEditable={obj.isEditable}
                                                                    id={obj.documentID}
                                                                    notes={obj.notes}
                                                                    addedDate={obj.addedDate}
                                                                    callDuration={obj.callDuration}
                                                                    callStatus={obj.callStatus}
                                                                    textareaRef={textareaRef}
                                                                    handleDeletelog={handleDeletelog}
                                                                    handleDateChangelog={handleDateChangelog}
                                                                    loading={loading}
                                                                    completedTab={enquiryActivities.find(
                                                                        item => item.name === activeLogTab,
                                                                    )}
                                                                    dealersettings={props.dealersettings}
                                                                    handleUnlickEmail={handleUnlickEmail}
                                                                    handlePreviewEmail={handlePreviewEmail}
                                                                    handleReplylog={handleReplylog}
                                                                    sendLoading={sendLoading}
                                                                ></CustomerlogDetails>
                                                            );
                                                        })}
                                                </div>
                                            </>
                                        ) : (
                                                <div className='spinner-loader h-100 p-5'>
                                                    <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                        <div className='no-data-img'>
                                                            {' '}
                                                            <img src={_images.nodata} width='60' height='60' alt='' />
                                                        </div>
                                                        <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                                                    </div>
                                                </div>
                                            )}
                                        }
                                    </>
                                        )}
                                </div>
                            
                             */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
             <PopUpModal show={showPreviewModel}>
                <EmailPreview
                    show={showPreviewModel}
                    handleClose={handleModelClose}
                    title={popheader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    emailLog={emailLog}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : null
                    }
                    history={props.history}
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                />
            </PopUpModal> 
            <PopUpModal show={showComposeModal.show}>
                <ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    token={props.dealersettings.nylasAccessToken}
                    type={showComposeModal.type}
                    title={showComposeModal.title}
                    emailData={showComposeModal.emailData}
                    dealersettings={props.dealersettings}
                    // sendLoader={sendLoader}
                    // setSendLoader={setSendLoader}
                    // handleSend={handleSend}
                    structureType={!_.isEmpty(props.dealersettings.nylasAccountData) ? props.dealersettings.nylasAccountData.organization_unit + 's' : ''}
                    emailtemplates={emailTemplates}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : null
                    }
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                    userData={{ emailSignature: props.dealersettings.emailSignature }}
                ></ComposeEmail>
            </PopUpModal>
        </>
    );
}

export default AddActivityLog;
