/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Dropdown, Modal, Popover, DropdownButton } from "react-bootstrap";
import moment from "moment";
import _ from 'lodash';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { Tabs, Radio, Space } from 'antd';
/** COMPONENTS */
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import { InputTextArea, InputRadio, PopUpModal, ReactMultiSelect, DropDownMenu } from '../../../components';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import CommonHelper from '../../../services/common';
import Contacts from '../../contacts/list/index';
import Users from '../../users/list/index';
import AddContact from '../../contacts/add';
/** VIEW-MODELS */
import { objCafe } from '../viewModel'
import { cafeStatus } from '../../../services/enum'
import { contactBasicWithKeywordsVM } from "../../contacts/viewModel";
import CafeOrders from '../index';
import { firestoreDB } from "../../../services/helper";
import Swal from "sweetalert2";

let titles = [];
export default class AddCafe extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.refItems = window.firebase.firestore().collection('cafeItems');
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('cafeOrders');
        this.unsubscribeCOS = null;
        this.state = {
            cafeItems: Object.assign([]),
            fields: Object.assign({}, objCafe),
            showUsers: false,
            showCafeHistory: false,
            isContact: true,
            showContacts: false,
            contactModal:
            {
                show: false,
                showSearch: false,
                title: '',
                clsActive: ''
            },
            errors: {},
            filter: {
                iSearch: '',
            },
            active: 0,
            cafeCategories: [{
                active: true,
                label: 'All',
                name: 'All',
                value: 'All',
            }],
            cafeTiming:
            {
                show: false,
                title: '',
                message: ''
            },
            searchSubItem: '',
        }
    }

    //#region CAFE_HISTORY
    handleCafeHistoryClose = () => {
        this.setState({ showCafeHistory: false });
    }
    //#endregion

    //#region  PAGE LOAD

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings

        if (iSearch) {
            result = result.filter(item => item.name.toLowerCase().startsWith(iSearch))
        }
        return result
    }

    onCollectionUpdate = (querySnapshot) => {
        const cafeItems = Object.assign([]);
        querySnapshot.forEach((doc) => {
            const {
                name,
                image,
                category,
                isDeleted,
                isAvailable,
                sortOrder,
                sortIndex,
                subList
            } = doc.data();
            cafeItems.push({
                documentID: doc.id,
                doc, // DocumentSnapshot
                name,
                image,
                category,
                isDeleted,
                isAvailable,
                sortOrder,
                sortIndex,
                subList
            });
        });
        //console.log('cafeItems', cafeItems)
        this.setState({
            cafeItems: cafeItems
        });
    }

    componentDidMount() {
        const { dealersettings, reOrders, enquiryID, clientUsers } = this.props;

        this.checkTiming();
        if (dealersettings) {
            let cliendId = dealersettings.client ? dealersettings.client.id : '';

            const setttings = dealersettings.client.settings;

            let titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            if (!enquiryID) {
                let fields = Object.assign({}, this.state.fields);
                let val = true;
                if (!_.isEmpty(reOrders)) {
                    fields.orders = reOrders.orders ? reOrders.orders : [];
                    fields.orderNotes = reOrders.orderNotes ? reOrders.orderNotes : '';
                }

                if (_.find(clientUsers, { id: localStorage.uid })) {
                    let objData = _.find(clientUsers, { id: localStorage.uid })
                    fields.staff = Object.assign({}, {
                        documentID: objData.id,
                        name: objData.name ? objData.name : '',
                        email: objData.email ? objData.email : '',
                    });
                    val = false
                }

                this.setState({ fields: fields, isContact: val });
            }

            if (!_.isEmpty(dealersettings?.client?.settings?.cafecategory)) {
                //console.log('dealersettings.client.settings.cafecategory', dealersettings.client.settings.cafecategory)
                // @memo: filter out with active status as well
                let _cafeCategories = dealersettings.client.settings.cafecategory.map(a => {
                    return { ...a, label: a.name }
                })
                _cafeCategories = _.orderBy(_cafeCategories, ['label'], ['asc'])
                _cafeCategories.unshift({
                    active: true,
                    label: 'All',
                    name: 'All',
                    value: 'All',
                })
                this.setState({
                    cafeCategories: _cafeCategories
                })
            } else {
                this.setState({
                    cafeCategories: [{
                        active: true,
                        label: 'All',
                        name: 'All',
                        value: 'All',
                    }]
                })
            }

            this.unsubscribeCOS = this.refItems
                .where('clientID', '==', cliendId)
                .where('isAvailable', '==', true)
                .where('isDeleted', '==', false)
                .onSnapshot(this.onCollectionUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribeCOS && this.unsubscribeCOS();
    }

    //#endregion

    //#region contact CHANGES

    handleContactClose = (ID, objData) => {
        /*if (ID && !_.isEmpty(objData)) {

            const fields = Object.assign({}, this.state.fields);
            fields.contact = Object.assign({}, objData);
            fields.customer = fields.contact;
            fields.contactID = fields.contact.documentID;
            this.setState({ fields: fields });

        }
        else*/ if (ID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const contact = Object.assign({}, contactBasicWithKeywordsVM);
                    for (let [key, value] of Object.entries(objStockData)) {
                        contact[key] = value;
                    }
                    contact.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.contact = Object.assign({}, contact);
                    fields.customer = fields.contact;
                    fields.contactID = fields.contact.documentID;
                    this.setState({ fields: fields });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showContacts: false,
            contactModal: { show: false, showSearch: false, title: '', clsActive: '' }
        }, () => { this.errorChange('contact') });
    }

    isContactExist = () => {
        const { contact } = this.state.fields;
        if (!_.isEmpty(contact))
            return true;
        else
            return false;
    }

    handleRemovecontact = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.contact = null;
        this.setState({ fields: fields });
    }
    //#endregion

    //#region Staff CHANGES

    handleStaffClose = (ID, objData) => {
        if (ID && !_.isEmpty(objData)) {
            const fields = Object.assign({}, this.state.fields);
            fields.staff = Object.assign({}, {
                documentID: objData.id,
                name: objData.name ? objData.name : '',
                email: objData.email ? objData.email : '',
            });
            this.setState({ fields: fields });
        }
        else if (ID) {
            /*const refData = window.firebase.firestore().collection('stock').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const stock = Object.assign({}, objStock);
                    for (let [key, value] of Object.entries(objStockData)) {
                        stock[key] = value;
                    }
                    stock.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.stockID = doc.id;
                    this.setState({ stock: stock, fields: fields });
                    this.errorChange('stockID');

                } else {
                    console.error("No such document!");
                }
            });*/
        }

        this.setState({
            showUsers: false
        }, () => { this.errorChange('staff') });
    }

    isStaffExist = () => {
        const { staff } = this.state.fields;
        if (!_.isEmpty(staff))
            return true;
        else
            return false;
    }

    handleRemoveStaff = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.staff = null;
        this.setState({ fields: fields });
    }
    //#endregion

    //#region  CHANGE EVENTS

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }
        }
        //console.log(fields, errors)
        this.setState({ errors: errors });
    }

    handleTypeChange = (e) => {
        const { value } = e.target;
        //console.log('handleTypeChange', name, value)
        let fields = Object.assign({}, this.state.fields);
        fields.staff = null;
        fields.contact = null;

        const { clientUsers } = this.props;
        if (value === 'Staff' && _.find(clientUsers, { id: localStorage.uid })) {
            let objData = _.find(clientUsers, { id: localStorage.uid })
            fields.staff = Object.assign({}, {
                documentID: objData.id,
                name: objData.name ? objData.name : '',
                email: objData.email ? objData.email : '',
            });
        }

        let val = (value === 'Contact' ? true : false);
        this.setState({ fields: fields, isContact: val });
    }

    handleChange = (prop, value) => {
        let state = Object.assign({}, this.state.filter);
        state[prop] = value;
        this.setState({ filter: state });
    }

    onQuantityChange = (id, e) => {
        //console.log('onQuantityChange', id, e)
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);

        orderList.filter(function (obj) {
            if (obj.documentID === id) {
                obj.quantity = e.floatValue ? parseInt(e.floatValue) : 0;
                return true;
            }
            return false;
        });

        state.orders = Object.assign([], orderList);;
        this.setState({ fields: state });
    }

    decrementCount = (id) => {
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);
        var hasCount = true;

        orderList.filter(function (obj) {
            if (obj.documentID === id) {
                obj.quantity = parseInt(obj.quantity) - 1;
                if (obj.quantity <= 0)
                    hasCount = false;
                return true;
            }
            return false;
        });

        if (!hasCount)
            orderList.splice(orderList.findIndex(v => v.documentID === id), 1);

        state.orders = Object.assign([], orderList);
        this.setState({ fields: state });
    }

    incrementCount = (id) => {
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);

        orderList.filter(function (obj) {
            if (obj.documentID === id) {
                obj.quantity = parseInt(obj.quantity) + 1;
                return true;
            }
            return false;
        });

        state.orders = Object.assign([], orderList);;
        this.setState({ fields: state });
    }

    addCafeItem = (item) => {
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);
        {/* commented - sameer - on - 10-05-24 */ }
        //var hasRecord = false;

        // orderList.filter(function (obj) {
        //     //console.log(obj, item);
        //     if (obj.documentID === item.documentID) {
        //         hasRecord = true;
        //         obj.quantity = parseInt(obj.quantity) + 1;
        //         return true;
        //     }
        //     return false;
        // });

        // if (hasRecord === false)
        //     orderList.push({ documentID: item.documentID, quantity: parseInt(1), name: item.name, image: item.image });

        orderList.push({
            documentID: window.firebase.firestore().collection('cafeOrders').doc().id,
            cafeItemID: item.documentID,
            quantity: parseInt(1),
            name: item.name,
            image: item.image
        });


        state.orders = Object.assign([], orderList);
        this.setState({ fields: state });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields });

        // var array1 = value.split(' ');
        // var str = value;

        // if (array1.length > 0) {
        //     var newarray1 = [];
        //     for (var x = 0; x < array1.length; x++) {
        //         newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
        //     }
        //     fields[name] = newarray1.join(' ');
        //     this.setState({ fields: fields });
        // } else {
        //     if (str && str.length >= 1) {
        //         var firstChar = str.charAt(0);
        //         var remainingStr = str.slice(1);
        //         str = firstChar.toUpperCase() + remainingStr;
        //     }
        //     fields[name] = str;
        //     this.setState({ fields: fields });
        // }

    }

    showOrderQuantity = (orders) => {

        if (!_.isEmpty(orders)) {
            var total = 0;
            for (var i = 0; i < orders.length; i++) {
                total = total + (orders[i].quantity ? orders[i].quantity : 0);
            }
            return total;
        }
        else {
            return 0;
        }
    }
    //#endregion

    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        let cafeTiming = Object.assign({}, this.state.cafeTiming);
        if (cafeTiming.show === false) {
            Swal.fire(cafeTiming.title, cafeTiming?.message, 'info');
            return;
        }

        for (let [key] of Object.entries(fields)) {

            if ((key === 'orders') && _.isEmpty(fields[key])) {
                formIsValid = false;
            }
        }

        if (!this.props.enquiryID) {
            if (this.state.isContact && _.isEmpty(fields.contact)) {
                formIsValid = false;
                errors.contact = errorClass;
            }

            if (this.state.isContact === false && _.isEmpty(fields.staff)) {
                formIsValid = false;
                errors.staff = errorClass;
            }
            this.setState({ errors: errors });
        }

        if (formIsValid === true) {
            this.addCafe(this);
        }
        else
            return formIsValid;

    };

    checkTiming = () => {
        const { dealersettings } = this.props;
        let workingHours = dealersettings?.client?.cafeSettings?.workingHours;
        let _currentDay = moment().day();
        if (_.isEmpty(workingHours) || (!_.isEmpty(workingHours) && _.isEmpty(workingHours[_currentDay]))) {
            this.setState({ cafeTiming: { show: true } })
        }
        else if (!_.isEmpty(workingHours) && !_.isEmpty(workingHours[_currentDay]) && workingHours[_currentDay]?.from && workingHours[_currentDay]?.to) {
            let _workingHours = {
                ['startworkingHours']: moment(moment().format('YYYY-MM-DD' + ' ' + workingHours[_currentDay].from))._d,
                ['endworkingHours']: moment(moment().format('YYYY-MM-DD' + ' ' + workingHours[_currentDay].to))._d
            }
            let currentTime = moment()._d;
            if (_workingHours.startworkingHours <= currentTime && currentTime <= _workingHours.endworkingHours)
                this.setState({ cafeTiming: { show: true } })
            else {
                this.setState({ cafeTiming: { show: false, title: 'Sorry, We Are Closed.', message: `Working time is from ${moment(_workingHours.startworkingHours).format('h:mm A')} to ${moment(_workingHours.endworkingHours).format('h:mm A')}` } })
            }
        }
        else
            this.setState({ cafeTiming: { show: false, title: 'Sorry, We Are Closed.' } })
    }

    addCafe = async (e) => {
        const { dealersettings, enquiryID, contact, clientUsers } = this.props;
        this.isLoading = true;

        const roleSnapshot = await window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/roles`)
            .where('modules.kitchen', '==', true)
            .get();

        let roleIds = [];
        let _notificationIds = [];
        let userIds = [];
        if (roleSnapshot.docs.length > 0) {
            roleSnapshot.docs.forEach((role) => {
                roleIds.push(role.id);
            })
        }

        if (!_.isEmpty(roleIds))
            _notificationIds = _.filter(clientUsers, (v) => _.includes(roleIds, v.roleID));

        if (!_.isEmpty(_notificationIds))
            userIds = [..._.map(_notificationIds.filter(e => e.documentID !== localStorage.uid && e.notifyKitchen === true && !_.isEmpty(e.tokkens)), function (obj) {
                return obj.documentID;
            })];

        // console.log('userIds', clientUsers, userIds)
        const objData = Object.assign({}, this.state.fields);
        let _date = moment()._d;
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedByName = dealersettings ? dealersettings.name : '';
            objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] ||
                (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!_.isEmpty(userIds))
            objData.notifyUserIDs = userIds;

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (!objData.enquiryID)
            objData.enquiryID = enquiryID ? enquiryID : '';

        if (enquiryID && !_.isEmpty(contact)) {
            objData.contact = contact;
            objData.customer = contact;
            objData.contactID = contact.documentID;
        }

        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (!objData.status)
            objData.status = cafeStatus.PENDING;

        if (!objData.orderNumber)
            objData.orderNumber = 'C-' + moment(_date).format('YYMMDD-HHmmss');

        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('cafeOrders').doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('cafeOrders').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;
            toast.notify('Cafe order placed successfully', {
                duration: 2000
            })
            this.handleModalClose(this.newDocID);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };


    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}, objCafe) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };

    onTabChange = (key) => {
        this.setState({ active: key })
    }

    filterByCategory = (cafeItem) => {
        let returnVal = false;
        const activeCategory = this.state.cafeCategories[Number(this.state.active)]
        if (activeCategory.value === 'All') {
            return true
        } else if (cafeItem.category === activeCategory.value) {
            return true
        }
        return returnVal
    }

    handleDropdownChange = (_data, id) => {
        //console.log('data', _data, id)
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);

        orderList.filter(function (obj) {
            if (obj.documentID === id) {
                let subItems = Object.assign([], obj.subItems);
                subItems.push(_data)
                obj.subItems = _.uniqBy(subItems, 'id');

                return true;
            }
            return false;
        });

        state.orders = Object.assign([], orderList);;
        this.setState({ fields: state });

    };

    handleDeleteItem = (order, itemId) => {
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);

        let matchingOrder = orderList.find((obj) => obj.documentID === order.documentID);

        if (matchingOrder) {
            let subItems = Object.assign([], matchingOrder.subItems);
            subItems = subItems.filter((item) => item.id !== itemId);
            matchingOrder.subItems = subItems;
        }
        state.orders = orderList;
        this.setState({ fields: state });
    };

    handleReactMultiSelectChange = (selectedOptions, id) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.item)
        })
        let state = Object.assign({}, this.state.fields);
        let orderList = Object.assign([], state.orders);

        orderList.filter(function (obj) {
            if (obj.documentID === id) {
                obj.subItems = value
                return true;
            }
            return false;
        });

        state.orders = Object.assign([], orderList);;
        this.setState({ fields: state });
    }

    loadOptions = (cafeItemID) => {
        const { cafeItems, cafeCategories } = this.state
        let category = cafeItems.filter(cafeItem => cafeItem.documentID === cafeItemID)[0]?.category;
        return category ? _.map(cafeCategories.filter(m => m.value === category)[0]?.subList,
            function (e) { return { label: e.name, value: e.value, item: { value: e.name, id: e.value }, data: e } }) : []
    }
    //#endregion

    render() {

        const { iSearch } = this.state.filter

        const { cafeItems, filter, isContact, showContacts, showUsers, contactModal, showCafeHistory, cafeTiming } = this.state

        const filteredList = AddCafe.applyFilter(cafeItems, filter)

        const { orders, orderNotes, contact, staff } = this.state.fields;

        const { show, dealersettings, clientUsers, enquiryID, showHistory, groupUsers } = this.props;

        const divLeftStyle = {
            height: '500px',
            maxHeight: '550px',
            overflow: 'auto'
        };


        const { TabPane } = Tabs;

        return (

            <>
                <Modal
                    id="add-cafe"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    enforceFocus={false}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-cafe modal-add-cafe-width">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'cafe'} />
                                {
                                    showHistory
                                        ?
                                        <div className="float-right ml-2">
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={(e) => { e.preventDefault(); this.setState({ showCafeHistory: true }) }}
                                            >
                                                <i className="ico icon-history mr-2 pt-1 float-left">
                                                </i> <Translate text={'history'} />
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }


                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        {
                            cafeTiming.show
                                ?
                                <></>
                                :
                                <div className="upgrade-subscription-overlay">
                                    <div className="spinner-loader h-100">
                                        <div className="cafe-close-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                            <div className="upgrade-subscription-img-wrap">
                                                <i className="ico icon-cafe" style={{ fontSize: '120px', color: '#6b717f' }}></i>
                                                {/* <img src={_images.cafeBrown} width="200" alt="" /> */}
                                            </div>
                                            <div className="upgrade-subscription-txt-wrap">
                                                <div className="alert-main-textwrap red-text"><Translate text={cafeTiming.title} /></div>
                                                {
                                                    cafeTiming.message
                                                        ?
                                                        <div className="alert-main-textwrap">{cafeTiming.message}</div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className="container-fluid">
                            <div className="row">
                                <div className="cafe-enq-popup">
                                    {/* CAFE LEFT */}
                                    <div className="cafe-left">

                                        <div className="cafe-head">
                                            <div className="float-left"><Translate text={'cafeItems'} /></div>
                                            <div className="float-right">
                                                <form className="form-inline my-2 my-lg-0 search-cafe">

                                                    <input
                                                        type="text"
                                                        id="isearch"
                                                        aria-label="Search"
                                                        placeholder={`search...`}
                                                        value={iSearch}
                                                        autoComplete="off"
                                                        onChange={event =>
                                                            this.handleChange('iSearch', event.target.value)
                                                        }
                                                    />
                                                </form>
                                            </div>
                                        </div>

                                        <div className="checklist-popup-column-wraper">
                                            <Tabs tabPosition={'left'} activeKey={`${this.state.active}`} onTabClick={this.onTabChange}>
                                                {
                                                    !_.isEmpty(this.state.cafeCategories)
                                                        ?
                                                        <>
                                                            {
                                                                this.state.cafeCategories && this.state.cafeCategories
                                                                    .map((info, index) => {
                                                                        return <TabPane tab={info.label} key={index}>
                                                                            <div className="checklist-popup-column-right-large">

                                                                                <div className="checklist-popup-column-title">
                                                                                    <h3>{info.label}</h3>
                                                                                </div>

                                                                                {/* <div className="checklist-popup-large-details">

                                                                                <div className="common-table" style={divStyle}>
                                                                                    <table className="table table-bordered">

                                                                                        
                                                                                    </table>
                                                                                </div>
                                                                            </div> */}
                                                                                <div className="cafe-items-pop" style={divLeftStyle}>
                                                                                    {
                                                                                        filteredList && filteredList.length > 0
                                                                                            ?
                                                                                            <div className="row text-center ">
                                                                                                {_.orderBy(filteredList, ['sortIndex'], ['asc']).filter(a => this.filterByCategory(a)).length ?
                                                                                                    _.orderBy(filteredList, ['sortIndex'], ['asc']).filter(a => this.filterByCategory(a)).map(cafeItem =>
                                                                                                    (<div key={cafeItem.documentID} className="col-lg-4 col-md-4 col-6 mb-3">
                                                                                                        <div className="cafe-thumb-box">
                                                                                                            {
                                                                                                                (orders.length > 0 && orders.filter(e => e.cafeItemID === cafeItem.documentID).length > 0)
                                                                                                                    ?
                                                                                                                    <span className="badge badge-pill badge-cafe-count">{orders.filter(e => e.cafeItemID === cafeItem.documentID).length}</span>
                                                                                                                    :
                                                                                                                    <></>
                                                                                                            }
                                                                                                            <a
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.addCafeItem(cafeItem);
                                                                                                                }}
                                                                                                                href="#" className="d-block ">
                                                                                                                <div className="cafe-thumb-box-overlay">
                                                                                                                    <span className="add-cafe-icon">
                                                                                                                        <img
                                                                                                                            className="img-fluid"
                                                                                                                            src={_images.addcafe}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <img className="img-fluid img-thumbnail img-object-fit" src={cafeItem.image} alt="" width="157" height="105" />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                        <small className="cafe-title">{cafeItem.name}</small>
                                                                                                    </div>)
                                                                                                    ) : (
                                                                                                        <div className="spinner-loader h-100 w-100 p-5">
                                                                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                                                                <div className="no-cafe-img">
                                                                                                                    <i className="ico icon-cafe"></i> </div>
                                                                                                                <div className="no-data-txt mt-2"><Translate text={'No cafe item found for this category'} /></div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                            :
                                                                                            <>
                                                                                                <div className="spinner-loader h-100 p-5">
                                                                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                                                        <div className="no-cafe-img">
                                                                                                            <i className="ico icon-cafe"></i> </div>
                                                                                                        <div className="no-data-txt mt-2"><Translate text={'noCafeItemFound'} /></div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </TabPane>
                                                                    })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-cafe-img">
                                                                        <i className="ico icon-service-checklist"></i> </div>
                                                                    <div className="no-data-txt mt-2"> <Translate text={'noCafeItemFound'} /></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                }
                                            </Tabs>
                                        </div>
                                    </div>

                                    {/* CAFE RIGHT */}
                                    <div className="cafe-right">

                                        {
                                            enquiryID
                                                ?
                                                <>
                                                </>
                                                :
                                                <>
                                                    <div className={`cafe-contact-list ${isContact ? this.state.errors["contact"] : this.state.errors["staff"]}`}>
                                                        <h3><Translate text={isContact ? 'contactDetails' : 'staffDetails'} /> </h3>

                                                        <div className="float-right radio-contact">
                                                            <div className="radio icheck-success float-left">
                                                                <InputRadio
                                                                    id="Contact"
                                                                    type="radio"
                                                                    value="Contact"
                                                                    name="isContact"
                                                                    checked={isContact === true ? true : false}
                                                                    onChange={this.handleTypeChange}
                                                                />
                                                                <label htmlFor="Contact"><Translate text={'contact'} /></label>
                                                            </div>

                                                            <div className="radio icheck-success float-left ml-3">
                                                                <InputRadio
                                                                    id="Staff"
                                                                    type="radio"
                                                                    value="Staff"
                                                                    name="isContact"
                                                                    checked={isContact === false ? true : false}
                                                                    onChange={this.handleTypeChange}
                                                                />
                                                                <label htmlFor="Staff"><Translate text={'staff'} /></label>
                                                            </div>

                                                        </div>

                                                        {
                                                            (!_.isEmpty(contact) || !_.isEmpty(staff))
                                                                ?
                                                                <div className={`cafe-contact-view`}>
                                                                    {
                                                                        (!_.isEmpty(contact))
                                                                            ?
                                                                            <div className="cafe-contact-view-inner">
                                                                                <a href="#" className="cafe-contact-close" onClick={(e) => { e.preventDefault(); this.handleRemovecontact(); }}><i className="ico icon-remove"></i></a>
                                                                                <div className="inner-left">
                                                                                    {CommonHelper.displayContactIcon(contact, '')}
                                                                                </div>
                                                                                <div className="inner-right">
                                                                                    <div className="cafe-contact-view-head"> {CommonHelper.displayContact(titles, contact, '--')}</div>

                                                                                    {
                                                                                        (contact.businessContact && !_.isEmpty(contact.company))
                                                                                            ?
                                                                                            <div className="input-readonly-sub">
                                                                                                {/* <i className={`ico icon-Contacts`}></i> */}
                                                                                                {CommonHelper.getFullName(titles, contact, '')}
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        contact.displayID
                                                                                            ?
                                                                                            <div className="input-readonly-sub"><Translate text={'contactID'} />:  {contact.displayID}</div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (
                                                                                            <div className="blue-color">{CommonHelper.getOtherDealerName(dealersettings, contact.clientID)}</div>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {/* <div className="input-readonly-sub"> License No. {contact.licenseNo ? contact.licenseNo : '--'}</div> */}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="cafe-contact-view-inner">
                                                                                <a href="#" className="cafe-contact-close" onClick={(e) => { e.preventDefault(); this.handleRemoveStaff(); }}><i className="ico icon-remove"></i></a>
                                                                                <div className="inner-left"><i className="ico icon-Contacts"></i></div>
                                                                                <div className="inner-right">
                                                                                    <div className="cafe-contact-view-head"> {staff.name}</div>
                                                                                    {/* <div className="input-readonly-sub"> License No. --</div> */}
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className={`fleet-customer-search`}>
                                                                    {
                                                                        isContact
                                                                            ?
                                                                            <>
                                                                                <div className="testdrive-search">
                                                                                    <button type="button" className={`btn btn-secondary float-left `}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({ showContacts: true })
                                                                                        }}>
                                                                                        <i className="ico icon-search"></i> <Translate text={'searchFromExistingContacts'} />
                                                                                    </button>
                                                                                    <button type="button" className="btn btn-primary float-left ml-2" onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({
                                                                                            contactModal:
                                                                                            {
                                                                                                show: true,
                                                                                                showSearch: false,
                                                                                                title: 'addContact',
                                                                                                clsActive: 'overlay-modal active'
                                                                                            }
                                                                                        });
                                                                                    }}>
                                                                                        <i className="ico icon-add"></i> <Translate text={'addContact'} />
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="testdrive-search">
                                                                                    <button type="button" className={`btn btn-secondary float-left `}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({ showUsers: true })
                                                                                        }}>
                                                                                        <i className="ico icon-search"></i> <Translate text={'searchFromExistingStaffs'} />
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                        }

                                                    </div>
                                                </>
                                        }


                                        <div className="cafe-head">
                                            <div className="float-left">
                                                <Translate text={'selectedItems'} />
                                                {
                                                    orders.length > 0
                                                        ?
                                                        <span className="badge badge-pill badge-cafe ml-1">{this.showOrderQuantity(orders)}</span>
                                                        :
                                                        <></>

                                                }
                                            </div>
                                            <div className="float-right"></div>
                                        </div>
                                        <div className="cafe-selected-wrapper mb-5">

                                            <div>
                                                {
                                                    orders && orders.length > 0
                                                        ?
                                                        <>
                                                            {orders.map(order =>

                                                                <div key={order.documentID} className="cafe-selected-list flex-cafe align-items-center position-relative">
                                                                    <div className="cafe-item-image">
                                                                        <img src={order.image} alt="" className="img-object-fit" />
                                                                    </div>
                                                                    <div className="cafe-item-data"> {order.name}</div>

                                                                    <div><a href="#" className="cafe-item-multi-selectwrap-delete" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.decrementCount(order.documentID);
                                                                    }}><i className="ico icon-delete"></i></a></div>

                                                                    {(() => {
                                                                        let _options = this.loadOptions(order.cafeItemID)
                                                                        return !_.isEmpty(_options) && (<div className="cafe-item-multi-selectwrap form-style mt-2">
                                                                            <ReactMultiSelect
                                                                                options={_options}
                                                                                name={`notes-${order.documentID}`}
                                                                                placeholder={`extras`}
                                                                                onChange={(rec) => { this.handleReactMultiSelectChange(rec, order.documentID) }}
                                                                                value={order.subItems ? _.map(order.subItems, function (e) { return e.id }) : []}
                                                                                classNamePrefix={`basic-select`}
                                                                                removeClearable={true}
                                                                                isMulti={true}
                                                                                isSearchable={true}
                                                                            >
                                                                            </ReactMultiSelect>
                                                                        </div>)
                                                                    })()}

                                                                    {/* <div className="cafe-item-data"> {order.name}
                                                                        <div className="cafe-control">
                                                                            <div className="row">
                                                                                <div className=" col-lg-6">
                                                                                    <div className="input-group number-spinner">
                                                                                        <span className="input-group-prepend">
                                                                                            <button className="btn btn-default " data-dir="dwn"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.decrementCount(order.documentID);

                                                                                                }}>
                                                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                        <NumberFormat
                                                                                            className="form-control text-center"
                                                                                            displayType={'text'}
                                                                                            value={order.quantity}
                                                                                        />
                                                                                        <span className="input-group-append">
                                                                                            <button className="btn btn-default" data-dir="up"
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.incrementCount(order.documentID);

                                                                                                }}>
                                                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                 <div className=" col-lg-6">
                                                                                    {
                                                                                        !_.isEmpty(filteredList.filter(cafeItem => cafeItem.documentID === order.documentID && !_.isEmpty(cafeItem.subList)))
                                                                                            ?
                                                                                            <>
                                                                                                <Dropdown className="filter-cafetemplate-btn float-right"
                                                                                                    onToggle={(isOpen) => isOpen && this.setState({ searchSubItem: '' })}
                                                                                                >
                                                                                                    <Dropdown.Toggle as={CustomToggle} className="dropdown dropdown-toggle">
                                                                                                        <i className="ico icon-note"></i> <span>Notes</span>
                                                                                                    </Dropdown.Toggle>
                                                                                                        <Dropdown.Menu
                                                                                                        as={CustomMenu}
                                                                                                        className='custom-drop custom-drop-align custom-notedrop-width'
                                                                                                        ChildClass='more-dropdown dropdown-notes-more'
                                                                                                        xplacement='right-end'
                                                                                                        drop='right'
                                                                                                    >
                                                                                                        <div className="w-100 d-inline-block">
                                                                                                            <div className="quick-submenu-search">
                                                                                                                <div className="input-group ">
                                                                                                                    <input placeholder="search..."
                                                                                                                        aria-label="Search"
                                                                                                                        value={this.state.searchSubItem}
                                                                                                                        onChange={(e) => {
                                                                                                                            this.setState({ searchSubItem: e.target.value })

                                                                                                                        }} />
                                                                                                                    <div className="input-group-append input-search-clear">
                                                                                                                        {
                                                                                                                            this.state.searchSubItem && this.state.searchSubItem.length > 0
                                                                                                                                ?
                                                                                                                                <span className="input-search-clear-icon"
                                                                                                                                    title="Clear"
                                                                                                                                    onClick={(e) => {
                                                                                                                                        e.preventDefault();
                                                                                                                                        this.setState({ searchSubItem: '' })
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <i style={{ fontSize: '9px', cursor: 'pointer' }} className="ico icon-remove"></i>
                                                                                                                                </span>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                </>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <Dropdown.Divider />
                                                                                                        {filteredList.filter(cafeItem => cafeItem.documentID === order.documentID)[0]?.subList
                                                                                                            .filter(item => item.value.toLowerCase().includes(this.state.searchSubItem.toLowerCase()))
                                                                                                            .length === 0 && this.state.searchSubItem.length > 0 ? (
                                                                                                            <div className="text-center pt-3 w-100 d-inline-block"><p>No Notes Found </p></div>
                                                                                                        ) : (
                                                                                                            filteredList.filter(cafeItem => cafeItem.documentID === order.documentID)[0]?.subList
                                                                                                                .filter(item => item.value.toLowerCase().includes(this.state.searchSubItem.toLowerCase()))
                                                                                                                .map(item => (
                                                                                                                    <Dropdown.Item className="cafe-item-align-left" key={item.id}
                                                                                                                        onClick={() => this.handleDropdownChange(item, order.documentID)}
                                                                                                                    >
                                                                                                                        {item.value}
                                                                                                                    </Dropdown.Item>
                                                                                                                ))
                                                                                                        )}
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }


                                                                                </div> 
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                        !_.isEmpty(filteredList.filter(cafeItem => cafeItem.documentID === order.documentID && !_.isEmpty(cafeItem.subList)))
                                                                            ?
                                                                            <>
                                                                                {order.subItems &&
                                                                                    order.subItems.map((item) => (
                                                                                        <div className="cafe-item-note-custom mt-2" key={item.id}>
                                                                                            <div className="form-control notes" >
                                                                                                {item.value}{' '}
                                                                                                <div className="cafe-item-note-custom-delete"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); this.handleDeleteItem(order, item.id) }}> <i className="ico icon-delete"></i></a></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    } */}
                                                                </div>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-cafe-img">
                                                                        <i className="ico icon-cafe"></i> </div>
                                                                    <div className="no-data-txt mt-2"><Translate text={'nothingSelected'} /></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                }

                                            </div>

                                            <div className="cafe-notes">
                                                <div className="form-group">
                                                    <label ><Translate text={'notes'} /></label>
                                                    <InputTextArea
                                                        className={`form-control notes`}
                                                        name="orderNotes"
                                                        onChange={this.handleOnChange}
                                                        value={orderNotes}
                                                        maxLength="280"
                                                        rows="3">
                                                    </InputTextArea>
                                                </div>
                                            </div>

                                            <div className={`cafe-order-btn ${orders.length > 0 ? '' : 'btn-disable'}`}>
                                                <button
                                                    type="button"
                                                    className="btn btn-common-fullscreen button-blue float-right"
                                                    onClick={(e) => this.handleSubmit(e)}
                                                >
                                                    {this.isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'order'} />
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <PopUpModal show={contactModal.show}>
                    <AddContact
                        docID={null}
                        show={contactModal.show}
                        clsActive={contactModal.clsActive}
                        handleClose={this.handleContactClose}
                        title={contactModal.title}
                        showSearch={contactModal.showSearch}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddContact>
                </PopUpModal>

                <PopUpModal show={showContacts}>
                    <Contacts
                        show={showContacts}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        handleContactClose={this.handleContactClose}
                        showAdd={false}
                    />
                </PopUpModal>

                <PopUpModal show={showUsers}>
                    <Users
                        show={showUsers}
                        clientUsers={this.props.clientUsers}
                        groupUsers={groupUsers}
                        dealersettings={dealersettings}
                        handleUserClose={this.handleStaffClose}
                        title='staff'
                        showAdd={false}
                        showQueue={false}
                    />
                </PopUpModal>

                <PopUpModal show={showCafeHistory}>
                    <CafeOrders
                        show={showCafeHistory}
                        handleClose={this.handleCafeHistoryClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </CafeOrders>
                </PopUpModal>


            </>

        );
    }
}
