import React, { useState, useEffect } from 'react';
import moment from 'moment'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import '../form/quote.scss';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common'

const QuotePreview = ({ history, setActiveTab, newlySavedData, client, setClient, clientSettings, currencySymbol, _type, _showPrice }) => {
	const [btnLoader, setBtnLoader] = useState(false)
	const [saveLoader, setSaveLoader] = useState(false)
	const [fileOnceDownloaded, setFileOnceDownloaded] = useState(false)
	const [fileLink, setFileLink] = useState('')
	const type = newlySavedData.type === 'automotiveService' ? 'automotiveService' : 'vehicleContract'
	let _quotationTC = CommonHelper.getTermsByField(client, 'quotationTC', newlySavedData?.contact?.language);
	useEffect(() => {
		if (!fileLink)
			return
		// console.log('FILE LINK CHANGES')
		document.querySelector('#fileLinkAnchor').click()
	}, [fileLink])

	const downloadFile = (saveFile) => {
		if (saveFile)
			setSaveLoader(true)
		else
			setBtnLoader(true)
		const getDownloadLink = window.firebase.functions().httpsCallable('quotes-generatePDF');
		getDownloadLink({
			url: `/quote/details/${newlySavedData.enquiryID}?type=print&previewID=${newlySavedData.documentID}`,
			quoteID: newlySavedData.documentID,
			clientID: client.documentID,
			enquiryID: newlySavedData.enquiryID,
			contactID: newlySavedData.contactID,
			modifiedBy: localStorage.uid,
			saveFile: saveFile,
			status: newlySavedData.orderDetails && newlySavedData.orderDetails.status ? newlySavedData.orderDetails.status : '',
			projectId: client.projectId ? client.projectId : null
		}).then((response) => {
			setSaveLoader(false)
			setBtnLoader(false)
			if (response.data.success) {
				if (saveFile) {
					setFileOnceDownloaded(true)
					Swal.fire('File saved successfully', '', 'success')
				} else {
					// download_file(response.data.data,`quote-${moment().format('YYYY-MM-DD-hmmssSS')}.pdf`)
					setFileLink(response.data.data)
				}

			}
			else {
				// console.log('err1')
				setSaveLoader(false)
				setBtnLoader(false)
				// console.log(response.data.message);
				Swal.fire('Something went wrong', '', 'error')
			}
		}).catch(err => {
			// console.log('err2')
			setSaveLoader(false)
			setBtnLoader(false)
			console.log(err)
			Swal.fire('Something went wrong', '', 'error')
		})
	}


	const download_file = (fileURL, fileName) => {
		// for non-IE
		if (!window.ActiveXObject) {
			var save = document.createElement('a');
			save.href = fileURL;
			save.target = '_blank';
			var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
			save.download = fileName || filename;
			if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href;
				// window event not working here
			} else {
				var evt = new MouseEvent('click', {
					'view': window,
					'bubbles': true,
					'cancelable': false
				});
				save.dispatchEvent(evt);
				(window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
		}

		// for IE < 11
		else if (!!window.ActiveXObject && document.execCommand) {
			var _window = window.open(fileURL, '_blank');
			_window.document.close();
			_window.document.execCommand('SaveAs', true, fileName || fileURL)
			_window.close();
		}
	}

	return !_.isEmpty(newlySavedData) && !_.isEmpty(client) && !_.isEmpty(clientSettings) ? (
		<>
			{_type !== 'print' ? (
				<>
					{_type !== 'preview' && (
						<div className="dynamicreport-stage-container">
							<div className="dynamicreport-stage-wraper">
								<div className="dynamicreport-stage-loop active">
									<div className="dynamicreport-stage-loop-box">
										<div className="dynamicreport-stage-count">1</div>
										<div className="dynamicreport-stage-title-wrap">
											<span className="dynamicreport-stage-title">Contract Details</span>
										</div>
									</div>
								</div>

								<div className="dynamicreport-stage-seperator">
									<svg
										stroke="currentColor"
										fill="none"
										strokeWidth="2"
										viewBox="0 0 24 24"
										strokeLinecap="round"
										strokeLinejoin="round"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg"
									>
										<polyline points="9 18 15 12 9 6"></polyline>
									</svg>
								</div>

								<div className="dynamicreport-stage-loop active">
									<div className="dynamicreport-stage-loop-box">
										<div className="dynamicreport-stage-count">2</div>
										<div className="dynamicreport-stage-title-wrap">
											<span className="dynamicreport-stage-title">Preview </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="button-wrap">
						{!_.isEmpty(newlySavedData.modifiedDate) && (
							<>
								<div id='updated-date' style={{ float: 'left', padding: '10px' }}>
									<div>Updated On : {moment(newlySavedData.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
								</div>
								<a onClick={e => {
									e.preventDefault()
									window.open(fileLink, '_blank');
								}} id={'fileLinkAnchor'}></a>
							</>
						)}

						<a className="print-btn" onClick={() => window.print()}><img src={_images.printWhite} width="12" height="11" />  Print</a>
						<a className="download-btn" onClick={() => downloadFile(false)}>
							{
								btnLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<img src={_images.downloadBlack} width="12" height="11" />)
							} Download</a>
						<a className="save-btn" onClick={() => {
							if (!fileOnceDownloaded)
								downloadFile(true)
						}}>{
								saveLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<img src={_images.saveWhite} width="12" height="11" />)
							} {fileOnceDownloaded ? 'Saved to Files' : 'Save to Files'}</a>
						{_type !== 'preview' && (
							<a
								style={{ padding: '6px 10px' }}
								className="update-btn"
								onClick={() => setActiveTab('details')}
							>Back</a
							>
						)}
					</div>
				</>
			) : (<></>)}

			<div className="dynamic-report-print-wrap report-data-load" id="print-section">
				<div className="print-wrap">
					<div className="header">
						<div className="headerwrap-left">
							<div className="header-left">
								{
									client && client.logoURL
										?
										<div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
										:
										<>
											<div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
										</>
								}
							</div>
						</div>

						<div className="headerwrap-right">
							<div className='header-right'>
								{' '}
								{client.name} <br />
								<span>
									{' '}
									{client.address ? client.address : ''}
									{client.phone ? (
										<>
											<br />
											{`Phone: ${client.phone}`}
										</>
									) : <></>}
									{client.email || client.website ? (
										<>
											<br />
											{client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
										</>
									) : <></>}
									<br />
								</span>
							</div>
						</div>
					</div>

					<div className="clear"></div>
					<div className="middle" style={{ minHeight: '1320px' }}>
						<div className="title-head">{newlySavedData.title ? newlySavedData.title : 'Vehicle Contract'}</div>

						<div className="clear"></div>
						{type !== 'vehicleContract' && (
							<div className="owner-info-wrapper">
								<div className="owner-left-box">
									{newlySavedData.owner ? (
										<>
											<div className="owner-name">
												{!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.users) && newlySavedData.owner
													? CommonHelper.getUserNamebyId(clientSettings.users, newlySavedData.owner)
													: ''}
											</div>
											<div className="owner-title">Sales Executive:</div>
										</>
									) : (<></>)}
								</div>
								<div className="owner-right-box">
									<div className="arrival-date">&nbsp;</div>
									<div className="arrival-title">Arrival Time:</div>
								</div>
							</div>
						)}

						<div className="table-wraper">
							<div className="table-head-inner">
								Contact Information
								{newlySavedData.displayID && (
									<div className="quote-id-print">
										Quote #{' '}<span style={{ color: '#4466f2' }}> {newlySavedData.displayID ? newlySavedData.displayID : ''} </span>
									</div>
								)}
							</div>
							<div className="table-details">
								<table width="100%" cellSpacing="0" cellPadding="0" border="0">
									<tbody>
										<tr>
											<td width="25%">
												<div className="grid-one">
													<span>Name</span> {newlySavedData.contact.businessContact ? newlySavedData.contact.name : CommonHelper.displayContactName([], newlySavedData.contact)}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>Phone</span>{newlySavedData.contact.phone ? newlySavedData.contact.phone : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>EMAIL</span> {newlySavedData.contact.email ? newlySavedData.contact.email : '--'}
												</div>
											</td>
											{newlySavedData.contact.businessContact ? (
												<>
												</>
											) : (
												<td width="25%">
													<div className="grid-one"><span>DOB</span>{newlySavedData.contact.dob ? moment(newlySavedData.contact.dob).format('DD/MM/YYYY') : '--'}</div>
												</td>
											)}
										</tr>
										{newlySavedData.contact.businessContact ? (
											<tr>
												<td colSpan="3">
													<div className="grid-one">
														<span>Address</span>{newlySavedData.contact.address ? newlySavedData.contact.address : '--'}
													</div>
												</td>
											</tr>
										) : (
											<tr>
												<td width="25%">
													<div className="grid-one"><span>License</span>{newlySavedData.contact.licenseNo ? newlySavedData.contact.licenseNo : '--'}</div>
												</td>
												<td width="25%" colSpan="3">
													<div className="grid-one">
														<span>Address</span>{newlySavedData.contact.address ? newlySavedData.contact.address : '--'}
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>

						{type === 'vehicleContract' && (
							<div className="table-wraper">
								<div className="table-head-inner">Order Details</div>
								<div className="table-details">
									<table width="100%" cellSpacing="0" cellPadding="0" border="0">
										<tbody>
											<tr>
												<td width="25%">
													<div className="grid-one">
														<span>Lead ID</span> {newlySavedData.orderDetails && newlySavedData.orderDetails.leadID ? newlySavedData.orderDetails.leadID : '--'}
													</div>
												</td>
												<td width="25%">
													<div className="grid-one"><span>Order No</span>{newlySavedData.orderDetails && newlySavedData.orderDetails.orderNo ? newlySavedData.orderDetails.orderNo : '--'}</div>
												</td>
												<td width="25%">
													<div className="grid-one">
														<span>Order Date</span> {newlySavedData.orderDetails && newlySavedData.orderDetails.orderDate ? moment(newlySavedData.orderDetails.orderDate).format('DD/MM/YYYY') : '--'}
													</div>
												</td>
												<td width="25%">
													<div className="grid-one">
														<span>Est. Delivery</span>{newlySavedData.orderDetails && newlySavedData.orderDetails.estDelivery ? moment(newlySavedData.orderDetails.estDelivery).format('DD/MM/YYYY') : '--'}
													</div>
												</td>
											</tr>
											<tr>
												<td width="25%">
													<div className="grid-one">
														<span>Lead Owner</span>{newlySavedData.orderDetails && newlySavedData.orderDetails.leadOwner ? newlySavedData.orderDetails.leadOwner : '--'}
													</div>
												</td>
												<td width="25%"><div className="grid-one">&nbsp;</div></td>
												<td width="25%"><div className="grid-one">&nbsp;</div></td>
												<td width="25%"><div className="grid-one">&nbsp;</div></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						)}

						<div className="table-wraper">
							<div className="table-head-inner">Vehicle Information</div>
							<div className="table-details">
								<table width="100%" cellSpacing="0" cellPadding="0" border="0">
									<tbody>
										<tr>
											<td width="25%">
												<div className="grid-one"><span>Make</span> {newlySavedData.vehicle.make ? newlySavedData.vehicle.make : '--'}</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>Model</span>{newlySavedData.vehicle.model ? newlySavedData.vehicle.model : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one"><span>Type</span> {newlySavedData.vehicle.type ? newlySavedData.vehicle.type : '--'}</div>
											</td>
											<td width="25%">
												<div className="grid-one"><span>Sale type</span> {newlySavedData.vehicle.saleType ? newlySavedData.vehicle.saleType : '--'}</div>
											</td>
										</tr>
										<tr>
											<td width="25%">
												<div className="grid-one"><span>Year</span>{newlySavedData.vehicle.year ? newlySavedData.vehicle.year : '--'}</div>
											</td>
											<td width="25%">
												<div className="grid-one"><span>Rego No.</span>{newlySavedData.vehicle.rego ? newlySavedData.vehicle.rego : '--'}</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>VIN No.</span>{newlySavedData.vehicle.vinNo ? newlySavedData.vehicle.vinNo : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>Color</span>{newlySavedData.vehicle.extColor ? newlySavedData.vehicle.extColor : '--'}
												</div>
											</td>
										</tr>

										<tr>
											<td width="25%">
												<div className="grid-one">
													<span>Stock No.</span>{newlySavedData.vehicle.stockNo ? newlySavedData.vehicle.stockNo : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>Build Date</span>{newlySavedData.vehicle.buildDate ? moment(newlySavedData.vehicle.buildDate).format('DD/MM/YYYY') : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one"><span>Odometer</span>
													{(newlySavedData.vehicle.odometer || newlySavedData.vehicle.odometer === 0) ? newlySavedData.vehicle.odometer : '--'}
												</div>
											</td>
											<td width="25%">
												<div className="grid-one">
													<span>Rego Expiry</span>{newlySavedData.vehicle.regoExpiry ? moment(newlySavedData.vehicle.regoExpiry).format('DD/MM/YYYY') : '--'}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="qt-table">
							{type === 'vehicleContract' ? (
								<>
									<table className="table-loop page-break">
										<thead>
											<tr>
												<th width="75%" className="border-right-none">Vehicle</th>
												<th width="25%" className="border-left-none">&nbsp;</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><strong>{`${newlySavedData.vehicle.make} ${newlySavedData.vehicle.model}`}</strong></td>
												<td className="value-right-align">
													<strong><NumberFormat
														thousandSeparator={true}
														prefix={currencySymbol}
														displayType={'text'}
														value={newlySavedData.vehicle.purchasePrice}
													/></strong>
												</td>
											</tr>
										</tbody>
									</table>
									{
										newlySavedData.options && newlySavedData.options.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">Options</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.options && newlySavedData.options.map((a, i) => (
														<tr key={i}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}
									{
										newlySavedData.accessories && newlySavedData.accessories.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">
															Accessories
														</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.accessories && newlySavedData.accessories.map((a, i) => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}
									{
										newlySavedData.afterMarket && newlySavedData.afterMarket.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">
															After Market
														</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.afterMarket && newlySavedData.afterMarket.map((a, i) => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}
									{
										newlySavedData.dealerDelivery && newlySavedData.dealerDelivery.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">
															Dealer Delivery
														</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.dealerDelivery && newlySavedData.dealerDelivery.map((a, i) => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}
									{
										newlySavedData.allowance && newlySavedData.allowance.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">Allowance</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.allowance && newlySavedData.allowance.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align">- <NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}

								</>
							) : (
								<>
									<table className="table-loop page-break">
										{_showPrice === 'false' ? (
											<>
												<thead>
													<tr>
														<th width="100%" className="border-right-none">Services</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.services && newlySavedData.services.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
														</tr>
													))}
												</tbody>
											</>
										) : (
											<>
												<thead>
													<tr>
														<th width="75%" className="border-right-none">Services</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.services && newlySavedData.services.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</>
										)}

									</table>

									{_showPrice !== 'false' && (newlySavedData.subTotalExGSTDiscounts || newlySavedData.subTotalExGSTDiscounts === 0) ? (
										<>
											<table className="table-loop page-break mt-0">
												<tbody>
													<tr>
														<td width="75%" className="value-right-align">
															<strong>Total</strong>
														</td>
														<td width="25%" className="value-right-align">
															<strong>
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={newlySavedData.subTotalExGSTDiscounts}
																/>
															</strong>
														</td>
													</tr>
												</tbody>
											</table>
										</>
									) : (<></>)
									}

									<table className="table-loop page-break">
										{_showPrice === 'false' ? (
											<>
												<thead>
													<tr>
														<th width="100%" className="border-right-none">Discounts</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.discounts && newlySavedData.discounts.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
														</tr>
													))}
												</tbody>
											</>
										) : (
											<>
												<thead>
													<tr>
														<th width="75%" className="border-right-none">Discounts</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.discounts && newlySavedData.discounts.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align">- <NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</>
										)}

									</table>
								</>
							)}
							{newlySavedData.gstPercentage && _showPrice !== 'false' && (
								<table className="table-loop  page-break margin-top-fix">
									<tbody>
										<tr>
											<td width="75%" className="value-right-align">
												{type === 'vehicleContract' ? 'Vehicle Sub Total excluding ' : 'Vehicle Total excluding '}{newlySavedData.taxType ? newlySavedData.taxType : 'VAT'}
											</td>
											<td width="25%" className="value-right-align">
												<NumberFormat
													thousandSeparator={true}
													prefix={currencySymbol}
													displayType={'text'}
													value={newlySavedData.subTotalExGST}
												/>
											</td>
										</tr>
										<tr>
											<td width="75%" className="value-right-align">{newlySavedData.taxType ? newlySavedData.taxType : 'VAT'} payable</td>
											<td width="25%" className="value-right-align">
												<NumberFormat
													thousandSeparator={true}
													prefix={currencySymbol}
													displayType={'text'}
													value={newlySavedData.gstPayable}
												/>
											</td>
										</tr>
										<tr>
											<td width="75%" className="value-right-align">
												<strong>{type === 'vehicleContract' ? 'Vehicle Sub Total inclusive of ' : 'Vehicle Total inclusive of '}{newlySavedData.taxType ? newlySavedData.taxType : 'VAT'} </strong>
											</td>
											<td width="25%" className="value-right-align">
												<strong>
													<NumberFormat
														thousandSeparator={true}
														prefix={currencySymbol}
														displayType={'text'}
														value={newlySavedData.subTotalIncGST}
													/>
												</strong>
											</td>
										</tr>
									</tbody>
								</table>
							)}
							{type === 'vehicleContract' && (
								<>
									{
										newlySavedData.vatExclusiveItems && newlySavedData.vatExclusiveItems.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">
															{newlySavedData.taxType ? newlySavedData.taxType : 'VAT'} Exclusive Items
														</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.vatExclusiveItems && newlySavedData.vatExclusiveItems.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>
										) : (<></>)
									}
									{
										newlySavedData.otherInsurance && newlySavedData.otherInsurance.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">
															Other Insurance
														</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.otherInsurance && newlySavedData.otherInsurance.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align"><NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}
												</tbody>
											</table>) : (<></>)
									}
									<table className="table-loop margin-top-fix page-break">
										<tbody>
											<tr>
												<td width="75%" className="value-right-align">
													<strong>Vehicle Total inclusive of {newlySavedData.taxType ? newlySavedData.taxType : 'VAT'} </strong>
												</td>
												<td width="25%" className="value-right-align">
													<strong><NumberFormat
														thousandSeparator={true}
														prefix={currencySymbol}
														displayType={'text'}
														value={newlySavedData.totalIncGST}
													/></strong>
												</td>
											</tr>
										</tbody>
									</table>
								</>
							)}



							{type === 'vehicleContract' && (
								<>
									{
										newlySavedData.trades && newlySavedData.trades.length > 0 ? (
											<table className="table-loop page-break">
												<thead>
													<tr>
														<th width="75%" className="border-right-none">Trade(s)</th>
														<th width="25%" className="border-left-none">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													{newlySavedData.trades && newlySavedData.trades.map(a => (
														<tr key={a.id}>
															<td>{a.item}</td>
															<td className="value-right-align">- <NumberFormat
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={a.amount}
															/></td>
														</tr>
													))}

												</tbody>
											</table>
										) : (<></>)
									}
								</>
							)}
							{
								newlySavedData.lessDeposit && newlySavedData.lessDeposit.length > 0 ?
									(
										<table className="table-loop page-break">
											{_showPrice === 'false' ? (
												<>
													<thead>
														<tr>
															<th width="100%" className="border-right-none">Less Deposit</th>
														</tr>
													</thead>
													<tbody>
														{newlySavedData.lessDeposit && newlySavedData.lessDeposit.map(a => (
															<tr key={a.id}>
																<td>{`${a.reference ? `Reference: ${a.reference} - ` : ``}${a.date ? moment(a.date).format(`DD/MM/YYYY`) : ''}`}</td>
															</tr>
														))}
													</tbody>
												</>
											) : (
												<>
													<thead>
														<tr>
															<th width="75%" className="border-right-none">Less Deposit</th>
															<th width="25%" className="border-left-none">&nbsp;</th>
														</tr>
													</thead>
													<tbody>
														{newlySavedData.lessDeposit && newlySavedData.lessDeposit.map(a => (
															<tr key={a.id}>
																<td>{`${a.reference ? `Reference: ${a.reference} - ` : ``}${a.date ? moment(a.date).format(`DD/MM/YYYY`) : ''}`}</td>
																<td className="value-right-align">- <NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={a.amount}
																/></td>
															</tr>
														))}
													</tbody>
												</>
											)}
										</table>
									) : (<></>)
							}
							{(_showPrice === 'false') && (
								<table className="table-loop page-break">
									<thead>
										<tr>
											<th width="100%" className="border-right-none">Notes</th>
										</tr>
									</thead>
									<tbody>
										{newlySavedData.notes && (
											<div className='quote-notes'>
												<td>{newlySavedData.notes ? newlySavedData.notes : ''}</td>
											</div>
										)}
									</tbody>
								</table>
							)}

							{!(_showPrice === 'false') && (
								<table className="table-loop margin-top-fix page-break">
									<tbody>
										<tr>
											<td
												width="75%"
												className="value-right-align costtxt-preview border-left-none border-bottom-none"
											>
												<strong>Balance Payable on Delivery </strong>
											</td>
											<td width="25%" className="value-right-align total-preview">
												<strong> <NumberFormat
													thousandSeparator={true}
													prefix={currencySymbol}
													displayType={'text'}
													value={newlySavedData.payableOnDelivery}
												/></strong>
											</td>
										</tr>
									</tbody>
								</table>
							)}
						</div>
						{type !== 'vehicleContract' && (
							<>
								<div className="form-bottom-boxwrap">
									<div className="form-bottom-box-arrival">
										<div className="form-bottom-head">For Internal Use Only</div>
										{/* <div className="arrival-date">&nbsp;</div>
								<div className="arrival-title">Arrival Time:</div> */}
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>WASH</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>DETAILING-POLISH</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>DETAILING-CERAMIC</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>PPF</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>TINT</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="form-bottom-box">
										<table className="form-bottom-table-loop form-bottom-sub-fix table-td-fix">
											<thead>
												<tr>
													<th colSpan="2"><strong>PPF-CERAMIC</strong></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td width="50%">Date</td>
													<td width="50%" className="count-large">Time</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
												<tr>
													<td>&nbsp;</td>
													<td className="count-large">&nbsp;</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</>
						)}
						<div className="clear"></div>
					</div>
					{newlySavedData.orderDetails && (newlySavedData.orderDetails.status === 'won' || newlySavedData.orderDetails.status === 'delivered') && type === 'vehicleContract' && (
						<>
							<div className="sign-wrap">
								<div className="sign-box">
									<div className="sign-box-head">Sales Executive</div>
									<div className="sign-box-sign">
										{newlySavedData.signatureSalesExecutive ? (
											<img src={newlySavedData.signatureSalesExecutive} alt='' height='50' />
										) : (<div style={{ minHeight: '40px' }}></div>)}
									</div>

									<div>
										<b>{newlySavedData.orderDetails && newlySavedData.orderDetails.leadOwner}</b>
										{/* <b>27/04/2021</b> */}
									</div>
								</div>

								<div className="sign-box">
									<div className="sign-box-head">Sales Manager</div>
									<div className="sign-box-sign">
										{newlySavedData.signatureSalesManager ? (
											<img src={newlySavedData.signatureSalesManager} alt='' height='50' />
										) : (<div style={{ minHeight: '40px' }}></div>)}
									</div>
									{/* <b>David Johnny</b>
							<b>27/04/2021</b> */}
								</div>

								<div className="sign-box">
									<div className="sign-box-head">Customer Signature</div>
									<div className="sign-box-sign margin-top">
										{newlySavedData.signatureCustomer ? (
											<img src={newlySavedData.signatureCustomer} alt='' height='50' />
										) : (<div style={{ minHeight: '40px' }}></div>)}
									</div>
									<div>
										<b>{newlySavedData.contact.businessContact ? 
										`${newlySavedData.contact.name}` : 
										CommonHelper.displayContactName([], newlySavedData.contact)}</b>
										{/* <b>27/04/2021</b> */}
									</div>
								</div>
							</div>

						</>
					)}
					{((newlySavedData?.orderDetails?.status === 'won' || newlySavedData?.orderDetails?.status === 'delivered') || type === 'automotiveService') && (
						<div className="table-wraper brd-none page-break mt-5">
							<div className="table-head">TERMS & CONDITIONS</div>
							<div className="terms"
								dangerouslySetInnerHTML={{ __html: (_quotationTC ? _quotationTC : '--') }} />
						</div>
					)}

					<div className="clear"></div>

					<div className="footer">
						<div className="footer-bottom">
							<p>
								<span style={{ paddingLeft: 0 }} >
									{client.address ? <>{client.address}</> : <></>}
								</span>
								<span style={{ paddingLeft: 0 }}>
									{client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
									{client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
									{client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
									{client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
								</span>
							</p>
						</div>
						<div className="footer-cpy">
							<div className="footer-left">
								Powered by{' '}
								<a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a>
							</div>
							{!_.isEmpty(newlySavedData.addedDate) && (
								<div className='footer-right'>Created On - {moment(newlySavedData.addedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
							)}
						</div>
						<div className="clear"></div>
					</div>

					<div className="clear"></div>
				</div>
				<div className="clear"></div>
			</div>
		</>
	) : (<>
		<div className='loader-center-screen'>
			<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
		</div>
	</>)
}

export default QuotePreview