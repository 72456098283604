import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { PopoverPanel } from '../../components';
import images from '../../images';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { defaultfleetAssignTypes, defaultfleetCategory } from '../../services/enum';

const CalendarBookings = (props) => {
    const [loader, setLoader] = useState(true)
    const [monthdata, setMonthData] = useState([])
    const [dateState, setDateState] = useState(new Date())
    const [bookingPopOver, setBookingsPopOver] = useState({ showpopover: false, targetpopover: null })
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
        date: moment().format('YYYY-MM-DD')
    })
    const _limit = 30;
    const accessToFleetHistory = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToFleetHistory;
    const changeDate = (e) => {
        setDateState(e)
    }

    useEffect(() => {
        return () => {

        }
    }, [])

    useEffect(() => {
        if (props.dealersettings.level === 'individual')
            return
        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
            let refeventData = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            collectionPromise.push(refeventData ? collection(refeventData) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubrefMonthFleetData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach((rec) => {
                        const bookingData = {
                            ...rec.data(),
                            regionID: rec.data()?.fleetPro?.regionID || null,
                            subregionID: rec.data()?.fleetPro?.subregionID || null,
                            groupID: rec.data()?.fleetPro?.groupID || null,
                            categoryValue: rec.data()?.fleetPro?.category || null,
                            endDate: rec.data()?.endDate || rec.data()?.startDate
                        };
                        if (moment.unix(bookingData.startDate.seconds).isSame(moment.unix(bookingData.endDate.seconds), 'day')) {
                            _fleetpro.push({
                                ...bookingData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'bookingDate': moment.unix(bookingData.startDate.seconds).format('YYYY-MM-DD'),
                                'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
                            })
                        }
                        for (var m = moment.unix(bookingData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(bookingData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                            _fleetpro.push({
                                ...bookingData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'bookingDate': m.format('YYYY-MM-DD'),
                                'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
                            })
                        }
                    })
                    setLoader(false)
                    setMonthData(_fleetpro);
                });
        }
        else {
            setLoader(false)
        }
    }, [dateRange])

    useEffect(() => {
        if (props.dealersettings.level !== 'individual')
            return
        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
            let refeventData = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`clientID`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            collectionPromise.push(refeventData ? collection(refeventData) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubrefMonthFleetData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach((rec) => {
                        const bookingData = {
                            ...rec.data(),
                            regionID: rec.data()?.fleetPro?.regionID || null,
                            subregionID: rec.data()?.fleetPro?.subregionID || null,
                            groupID: rec.data()?.fleetPro?.groupID || null,
                            categoryValue: rec.data()?.fleetPro?.category || null,
                            endDate: rec.data()?.endDate || rec.data()?.startDate
                        };
                        if (moment.unix(bookingData.startDate.seconds).isSame(moment.unix(bookingData.endDate.seconds), 'day')) {
                            _fleetpro.push({
                                ...bookingData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'bookingDate': moment.unix(bookingData.startDate.seconds).format('YYYY-MM-DD'),
                                'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
                            })
                        }
                        for (var m = moment.unix(bookingData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(bookingData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                            _fleetpro.push({
                                ...bookingData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'bookingDate': m.format('YYYY-MM-DD'),
                                'bookingMonth': moment.unix(bookingData.startDate.seconds).format('YYYY-MM')
                            })
                        }
                    })
                    setLoader(false)
                    setMonthData(_fleetpro);
                });
        }
        else {
            setLoader(false)
        }
    }, [dateRange])

    const filterData = useMemo(() => monthdata
        .filter(
            ({ regionID, subregionID, groupID, clientID, categoryValue }) =>
                (props?.dashboardFilter?.region?.length ? props.dashboardFilter.region.includes(regionID) : true) &&
                (props?.dashboardFilter?.subregion?.length ? _.isEmpty(subregionID) || props.dashboardFilter.subregion.includes(subregionID) : true) &&
                (props?.dashboardFilter?.group?.length ? _.isEmpty(groupID) || props.dashboardFilter.group.includes(groupID) : true) &&
                (props?.dashboardFilter?.dealer?.length ? props.dashboardFilter.dealer.includes(clientID) : true) &&
                (props?.dashboardFilter?.category?.length ? props.dashboardFilter.category.includes(categoryValue) : true)
        ), [monthdata, props.dashboardFilter])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <h3><Translate text={'Bookings'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    <Calendar
                        onChange={changeDate}
                        value={dateState}
                        className={'dashboard-calendar-mini'}
                        onClickDay={(value, event) => {
                            const _bookings = filterData.filter(a => a.bookingDate === moment(value).format('YYYY-MM-DD'));
                            if (_bookings.length > 0) {
                                setBookingsPopOver({
                                    showpopover: true,
                                    targetpopover: event.target,
                                    bookings: _.uniqBy(_bookings, 'id')
                                })
                            }
                        }}
                        tileClassName={'calendar-mini-day'}
                        tileContent={({ date }) => {
                            if (filterData.some(a => a.bookingDate === moment(date).format('YYYY-MM-DD'))) {
                                return <span className="dots">
                                    <span className="dot"></span>
                                </span>;
                            }
                        }}
                        view={'month'}
                        prev2Label={null}
                        next2Label={null}
                        onActiveStartDateChange={({ activeStartDate }) => {
                            setLoader(true)
                            setDateRange({
                                startDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).startOf('month').add(-7, 'days').toDate()),
                                endDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).endOf('month').add(7, 'days').toDate()),
                                date: moment(activeStartDate).format('YYYY-MM-DD')
                            })
                        }}
                        navigationLabel={({ date }) => <div className='dashboard-calendar-mini-header'>{`${moment(date).format('MMM YYYY')}`}</div>}
                    />
                </div>
            </div>
            <PopoverPanel
                showpopover={bookingPopOver.showpopover}
                targetpopover={bookingPopOver.targetpopover}
                title='Bookings'
                position='right'
                id='date-events'
                className='popover-pipeline-switch'
                closepopover={() => {
                    setBookingsPopOver({ showpopover: false, targetpopover: '' });
                }}>
                <div style={{ padding: '15px' }}>
                    {
                        bookingPopOver?.bookings?.map((log, index) => {
                            return <div key={index} className='events-dashboard-livewrap mb-2' onClick={(e) => {
                                e.preventDefault();
                                if (accessToFleetHistory && log.fleetProID)
                                    props.history.push("/fleetpro/details/" + log.fleetProID);
                            }}>
                                <div className="events-dashboard-live-flex">
                                    <div className="events-dashboard-live-image">
                                        {
                                            log?.fleetPro?.images?.length ? (
                                                <img src={log.fleetPro.images[0]} alt="" width="70" height="70" />
                                            ) : (
                                                <img src={images.novehicle} alt="" width="70" height="70" />
                                            )
                                        }
                                    </div>
                                    <div className="events-dashboard-live-data">
                                        <div className="events-dashboard-card-title">{log?.fleetPro?.make || ''} {log?.fleetPro?.model || ''}</div>
                                        {
                                            log.name ? (
                                                <div className="events-dashboard-card-sub"><i className="ico icon-Contacts"></i> {log.name} </div>
                                            ) : (<></>)
                                        }
                                        {
                                            _.find(props.dealersettings.clients, { id: log.clientID }) ? (
                                                <div className="events-dashboard-card-sub"><i className="ico icon-group mb-1"></i> <span className='blue-color'>{_.find(props.dealersettings.clients, { id: log.clientID }).name}</span> </div>
                                            ) : (<></>)
                                        }
                                        <div className="events-dashboard-card-sub">
                                            <i className="ico icon-date"></i>{CommonHelper.showCombineDate(log.startDate, log.endDate || log.expectedReturnDate)}
                                        </div>
                                        <div className="flow-notes-sub">
                                            {
                                                _.find(defaultfleetCategory, { value: log?.fleetPro?.category }) ? (
                                                    <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetCategory, { value: log?.fleetPro?.category }).name}</div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.find(defaultfleetAssignTypes, { value: log?.assignType }) ? (
                                                    <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetAssignTypes, { value: log?.assignType }).name}</div>
                                                ) : (<></>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </PopoverPanel>
        </>
    );
}

export default CalendarBookings;