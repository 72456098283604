import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { allrecipientFields } from './viewModel';
import Report from './campaignReport';
import CampaignDetails from './campaignDetails'

let bodyHTML = `<div style="border: 1px solid #e5e5e5; padding: 15px 20px; max-width: 600px; margin: auto;">
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Hello ,</span><br><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">You got a new message from Tenderdash:</span></p>
<p style="padding: 12px; border-left: 6px solid #eee; font-style: italic;">&nbsp;</p>
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">&nbsp;</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Best wishes,</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Tenderdash team</span></p>
</div>`

const CampaignPreview = props => {
	const [batchStats, setBatchStats] = useState({});
	const [batchLinksStats, setBatchLinksStats] = useState({});
	const [message, setMessage] = useState({});
	const [contacts, setContacts] = useState([]);
	const [complainedContacts, setComplainedContacts] = useState([]);
	const [batchLoader, setBatchLoader] = useState(true);
	const [contactsLoader, setContactsLoader] = useState(true);
	const [complainedLoader, setComplainedLoader] = useState(true);

	const [campaign, setCampaign] = useState(props.campaign);
	const [activeTab, setActiveTab] = useState('detail');
	const [recipients, setRecipients] = useState([]);
	const [recipientLoader, setRecipientLoader] = useState(false);
	const [loader, setLoader] = useState(true);
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 380,
		windowHeight: window.innerHeight - 273,
	});
	const [checkDataLoad, setDataload] = useState(true)
	const [pageNum, setpageNum] = useState(0)
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 380,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailup-api/api/mailup/recipients-count`,
				{
					messageID: props.campaign.messageID,
					refresh_token: props.refresh_token,
					client_id: props.client_id,
					client_secret: props.client_secret
				},
			);
			if (recipientsCount?.data?.success) {
				setCampaign({
					...campaign,
					...recipientsCount?.data?.data
				})
			}
			setBatchLoader(false)
		}
		fetchData()
	}, []);

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}
		const fetchRecipients = async () => {
			const recipientsCount = await axios.post(
				`${props.apiUrl}mailup-api/api/mailup/recipients-list`,
				{
					messageID: props.campaign.messageID,
					refresh_token: props.refresh_token,
					client_id: props.client_id,
					client_secret: props.client_secret,
					pageNum
				},
			);
			if (recipientsCount?.data?.success) {
				setContacts([
					...contacts,
					...recipientsCount?.data?.data?.Items?.map(rec => {
						return {
							email: rec.Email,
							sendTime: campaign.CreationDate ? moment(campaign.CreationDate).format('DD/MM/YYYY hh:mm A') : ''
						}
					})
				])
			}
			setContactsLoader(false)
			setDataload(false)
		}
		fetchRecipients()
	}, [checkDataLoad])

	const handleActionClick = () => {

	}
	const handleRowSingleClick = () => {

	}

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							{true && (
								<div className='container-fluid pl-0 '>
									{batchLoader ? (
										<div className='loader-center-screen'>
											<div className="spinner-loader h-100 "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
										</div>
									) : activeTab === 'detail' ? (
										<CampaignDetails
											setActiveTab={setActiveTab}
											campaign={campaign}
											bodyHTML={campaign.Content}
											recipients={recipients}
											windowSize={windowSize}
											allrecipientFields={allrecipientFields}
											handleActionClick={handleActionClick}
											handleRowSingleClick={handleRowSingleClick}
											activeTab={activeTab}
											contacts={contacts}
											message={message}
											contactsLoader={contactsLoader}
											setpageNum={(val) => setpageNum(val)}
											pageNum={pageNum}
											setDataload={(val) => {
												setDataload(val)
											}}
										/>
									) : (
										<Report
											setActiveTab={setActiveTab}
											campaign={campaign}
											activeTab={activeTab}
											contacts={contacts}
											complainedContacts={complainedContacts}
											message={message}
											contactsLoader={contactsLoader}
											refresh_token={props.refresh_token}
											client_id={props.client_id}
											client_secret={props.client_secret}
											apiUrl={props.apiUrl}
										/>
									)}
								</div>
							)}
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default CampaignPreview;
