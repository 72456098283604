import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import { PieChart, NoDataPieChart } from '../../components'
import toast from 'toasted-notes'
import common from '../../services/common';
import Translate from '../../constants/translate';

const MakesCounts = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

    const fetchData = async () => {
        // console.log('lead by campaigns')
        let _filter = {
            clientID: common.getTradeinProDealers(props.dealersettings, false, true),
            type: 'make',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.selectedClient) {
            _filter.clientID = props.selectedClient.value
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.map(item => item.value).join(',')
        }

        let _searchObject = {
            type: "getTradeinProDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('getServiceDashboardWidgets-resp', resp)
            let enquiries_ = resp.data.data.map((rec, index) => {
                return {
                    ...rec,
                    name: rec.name === 'others' ? 'Other/Blank' : rec.name,
                    value: rec.name,
                    sortOrder: index
                }
            })
            enquiries_ = _.orderBy(enquiries_, ['sortOrder'], ['asc'])
            setData(enquiries_)
            setFirstTimeLoaded(true)
            setLoader(false)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setFirstTimeLoaded(true)
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.selectedUser,props.selectedClient, props.selectedPipeline, props.dateRange, props.refreshData])

    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>

                    </div>
                    <h3><Translate text={'Appraisals By Make'} /></h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={'makes-pie-chart'}
                                    height={'350px'}
                                    data={reportData}
                                    handleShowEnquiryList={(value, startDate, endDate, _status) => {
                                        props.handleShowEnquiryList({
                                            'make': value,
                                            'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                            'startDate': startDate,
                                            'endDate': endDate,
                                            'dateType': 'addedDate'
                                        }, (_status && _status.name))
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={'makes-pie-chart'}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div >
        </>
    );
}

export default MakesCounts;