/** LIBRARIES */
import React, { Component } from 'react';
//import { Modal } from "react-bootstrap";
import moment from 'moment';
//import NumberFormat from 'react-number-format';
//import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
//import Swal from 'sweetalert2'
/** COMPONENTS */
import { ImageViewer, PopUpModal } from '../../components';
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
//import MergeContact from './merge'
//import EnquiryList from './enquiryList'
/** VIEW-MODELS */
//import { objContact } from './viewModel'


//#region  ASSIGN VARIABLES

//#endregion


let languages = [];
let license_state = [];
class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            titles: [],
            showReAuthentication: false,
            showMoreContact: false,
            imageViewer: { show: false, images: null }
        }

    }


    //#region PAGE LOAD


    componentDidMount() {

        const { dealersettings } = this.props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;
            let defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

            const titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            license_state = [];
            defaultCountry && dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });

                });

            this.setState({
                titles: titles
            });
        }
    }

    handleSaveAuditLog = (imagePath) => {
        const { documentID } = this.props.contact;
        const { dealersettings } = this.props;
        let _name = CommonHelper.getFullNameWithID(null, this.props.contact, '');
        let _objLogData = {
            notes: 'License image viewed for ' + _name,
            type: 'licenseImage',
            subType: 'contactDetails',
            recordId: documentID ? documentID : null,
            contactID: documentID ? documentID : null,
            imageURL: imagePath,
        }

        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
        CommonHelper.saveAuditLog(_objLogData);
    }
    //#endregion



    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }
    //#endregion





    render() {
        //console.log('R E N D E R - C O N T A C T ');
        // const { clientID, contactType, title, displayID, firstName, lastName, preferredName, userImageURL, dob, businessContact,
        //     gender, maritalStatus, language, isVIP, nationality, address, phones, emails, phoneCode,
        //     company, designation, contactMethod, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL, secondaryTitle,
        //     phone, email, licenseState, secondaryLicenseFrontUrl, secondaryLicenseBackUrl, secondaryFirstName, secondaryLastName, secondaryPhones, secondaryEmails, secondaryPhone, secondaryEmail,
        //     licenseType, interests, origin, campaign, optinPhone, optinEmail, optinPost, dependents, driverName, owner
        // } = this.props.contact;

        const { dealersettings, } = this.props;
        const { titles, showMoreContact } = this.state;

        const { dob, businessContact, clientID, displayID, tags, creditScoreTerms,
            gender, address, banLoanVehicle, contactType, isDLScan, terms, campaign,
            company, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
            phone, phoneCode, email, optinPhone, optinEmail, optinPost, optinSMS,
            secondaryTitle, secondaryPreferredName, secondaryPhoneCode,
            secondaryFirstName, secondaryLastName, secondaryPhone, secondaryEmail,
            maritalStatus, language, nationality, contactMethod, licenseState,
            secondaryLicenseFrontUrl, secondaryLicenseBackUrl, licenseType,
            interests, dependents, driverName, phones, emails, visitingCardURL,
            prevPurchased, marketingOptinEmail, marketingOptinSMS,
            secondaryIDNumber, secondaryIDType, secondaryIDExpiry, tvDisplayName,
        } = this.props.contact;

        let _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID] ? dealersettings.group.clients[clientID] : dealersettings.client;
        const setttings = _client && _client.settings;


        let dealerName = '';
        if (clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);


        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);
        const tvModuleEnabled = ((dealersettings && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.tvModule) &&
            dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);

        const _allImages = CommonHelper.combineAllImages([licenseFrontURL, licenseBackURL, visitingCardURL, secondaryLicenseFrontUrl, secondaryLicenseBackUrl])
        return (
            <>

                <div className="info-table">
                    <table className="table-fill">
                        <tbody>
                            <tr>
                                <td className="text-left label-text"><Translate text={'contactID'} /></td>
                                <td className="text-left">{displayID ? displayID : '--'}</td>
                            </tr>
                            {
                                isDLScan
                                    ?
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'Scan Type'} /></td>
                                        <td className="text-left">
                                            <div className="badge badge-pill badge-white-card mt-0">
                                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                {`Digital Driving Licence`}
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <></>
                            }

                            {
                                !_.isEmpty(terms)
                                    ?
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'Consent'} /></td>
                                        <td className="text-left">
                                            <div className="badge badge-pill badge-white-card mt-0">
                                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                {`Customer Consent ${terms.verbalConsent ? '(Verbal)' : (terms.consentType ? `(${terms.consentType})` : '')}`}
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <></>
                            }
                            {
                                !_.isEmpty(creditScoreTerms)
                                    ?
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'Finance Consent'} /></td>
                                        <td className="text-left">
                                            <div className="badge badge-pill badge-white-card mt-0">
                                                <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                {`Finance Credit Consent ${creditScoreTerms.verbalConsent ? '(Verbal)' : (creditScoreTerms.consentType ? `(${creditScoreTerms.consentType})` : '')}`}
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    <></>
                            }
                            {
                                dealerName
                                    ?
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'dealerships'} /></td>
                                        <td className="text-left"><span className="blue-color">{dealerName}</span></td>
                                    </tr>
                                    :
                                    <></>
                            }
                            <tr>
                                <td className="text-left label-text"><Translate text={'address'} /></td>
                                <td className="text-left">{address ? address : '--'}</td>
                            </tr>
                            <tr>
                                <td className="text-left label-text"><Translate text={'dateOfBirth'} /></td>
                                <td className="text-left">{dob ? moment(dob).format('DD MMM YYYY') : '--'}</td>
                            </tr>
                            <tr>
                                <td className="text-left label-text" width="35%"><Translate text={'gender'} /></td>
                                <td className="text-left" width="65%">{gender ? CommonHelper.getNameByValue(setttings ? setttings.genders : [], gender, '') : '--'}</td>
                            </tr>

                            {/* {
                                tvModuleEnabled && (<tr>
                                    <td className="text-left label-text"><Translate text={'tvDisplayName'} /></td>
                                    <td className="text-left">{tvDisplayName ? tvDisplayName : '--'}</td>
                                </tr>)
                            } */}
                            {
                                !_.isEmpty(tags)
                                    ?
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'tags'} /></td>
                                        <td className="text-left">{
                                            tags.length > 0 ? (<li>
                                                {
                                                    tags.map((r, i) => {
                                                        return r.subType ? (
                                                            <span key={i} className="lead-badge-border">
                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
                                                            </span>
                                                        ) : r.type === 'cap' ? (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title='BP'>BP-{r.refID || CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                                            : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                                    })
                                                }
                                            </li>) : (<></>)
                                        }</td>
                                    </tr>
                                    :
                                    <></>
                            }

                        </tbody>
                    </table>
                    {
                        secondaryFirstName || secondaryLastName || secondaryPhone || secondaryEmail
                            ?
                            <>
                                <div className="contact-info-section-head"> <Translate text={'Secondary Contact'} /></div>
                                <table className='table-fill'>
                                    <tbody>

                                        {
                                            secondaryFirstName || secondaryLastName
                                                ?
                                                <>
                                                    <tr>
                                                        <td className='text-left label-text' width="35%">
                                                            <Translate text={'name'} />
                                                        </td>
                                                        <td className='text-left' width="65%">
                                                            {CommonHelper.displayFullSecondaryContact(titles, this.props.contact, '--')}
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <></>
                                        }


                                        {
                                            secondaryPhone
                                                ?
                                                <>
                                                    <tr>
                                                        <td className='text-left label-text'>
                                                            <Translate text={'phone'} />
                                                        </td>
                                                        <td className='text-left'>
                                                            {secondaryPhone ? CommonHelper.phoneFormat(secondaryPhoneCode, secondaryPhone, dealersettings) : '--'}
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            secondaryEmail
                                                ?
                                                <>
                                                    <tr>
                                                        <td className='text-left label-text'>
                                                            <Translate text={'email'} />
                                                        </td>
                                                        <td className='text-left'>
                                                            {secondaryEmail ? secondaryEmail : '--'}
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <></>
                                        }

                                    </tbody>
                                </table>
                            </>
                            :
                            <></>
                    }

                </div>

                <div className="license-item mt-2">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td width="25%"><span> <Translate text={'licenseNo'} /></span>
                                    {licenseNo ? licenseNo : '--'}
                                </td>
                                <td width="30%" className="border-left pl-3"><span><Translate text={'licenseExpiry'} /></span>
                                    {licenseExpiry ? moment(licenseExpiry).format('DD MMM YYYY') : '--'}
                                </td>
                                <td width="45%">
                                    <div className="contact-detail-license float-right">
                                        <div className="license-box">
                                            {
                                                (licenseFrontURL)
                                                    ?
                                                    <>
                                                        {
                                                            (isPrivacyEnabled)
                                                                ?
                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                :
                                                                <a data-fancy-box={`license-box`}
                                                                    href={licenseFrontURL}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleSaveAuditLog(licenseFrontURL);
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: _allImages,
                                                                                index: _allImages.findIndex(e => e === licenseFrontURL)
                                                                            }
                                                                        });
                                                                    }}><img src={licenseFrontURL} alt="" className="img-object-fit" /> </a>
                                                        }
                                                    </>
                                                    :
                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images.nolicense} alt="" /> </a>
                                            }
                                        </div>
                                        <div className="license-box">
                                            {
                                                (licenseBackURL)
                                                    ?
                                                    <>
                                                        {
                                                            (isPrivacyEnabled)
                                                                ?
                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                :
                                                                <a data-fancy-box={`license-box`}
                                                                    href={licenseBackURL}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleSaveAuditLog(licenseBackURL);
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: _allImages,
                                                                                index: _allImages.findIndex(e => e === licenseBackURL)
                                                                            }
                                                                        });
                                                                    }}><img src={licenseBackURL} alt="" className="img-object-fit" /> </a>
                                                        }
                                                    </>
                                                    :
                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images.nolicense} alt="" /> </a>
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    !showMoreContact ? (
                        <div className="contact-showmore-btn"><a href="#" onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                showMoreContact: true
                            })
                        }}><Translate text={'showMore'} />  <i className="ico icon-dropdown"></i></a></div>
                    ) : <><div className="contact-showmore-btn show-less-ico"><a href="#" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            showMoreContact: false
                        })
                    }}><Translate text={'showLess'} />  <i className="ico icon-dropdown"></i></a></div></>
                }

                {
                    showMoreContact
                        ?
                        <>
                            <table className='table-fill'>
                                <tbody>

                                    {visitingCardURL ? (
                                        <>
                                            <tr>
                                                <td
                                                    className='text-left label-text'
                                                    width='35%'
                                                >
                                                    <Translate text={'visitingCardURL'} />
                                                </td>
                                                <td className='text-left' width='65%'>
                                                    <div className='contact-detail-license'>
                                                        <div className='license-box'>
                                                            {visitingCardURL ? (
                                                                <>
                                                                    {
                                                                        (isPrivacyEnabled)
                                                                            ?
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                            :
                                                                            <a
                                                                                data-fancy-box={`visiting-box`}
                                                                                href={visitingCardURL}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.handleSaveAuditLog(visitingCardURL);
                                                                                    this.setState({
                                                                                        imageViewer: {
                                                                                            show: true,
                                                                                            images: _allImages,
                                                                                            index: _allImages.findIndex(e => e === visitingCardURL)
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={visitingCardURL}
                                                                                    alt=''
                                                                                    className="img-object-fit"
                                                                                />{' '}
                                                                            </a>
                                                                    }
                                                                </>

                                                            ) : (
                                                                <a
                                                                    href='#'
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={_images.nolicense}
                                                                        alt=''
                                                                        className="img-object-fit"
                                                                    />{' '}
                                                                </a>
                                                            )}
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <></>
                                    )}


                                    <tr>
                                        <td className='text-left label-text' width="35%">
                                            <Translate text={'licenseState'} />
                                        </td>
                                        <td className='text-left' width="65%">
                                            {licenseState ? licenseState : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'licenseType'} />
                                        </td>
                                        <td className='text-left'>
                                            {licenseType ? CommonHelper.getNameByValue(setttings ? setttings.licenseType : [], licenseType, '--') : '--'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'phones'} />
                                        </td>
                                        <td className='text-left'>
                                            {(phones && !_.isEmpty(phones)) ? <>
                                                {
                                                    phones.map((data, idx) => (
                                                        data.value
                                                            ?
                                                            <div key={idx}>
                                                                {data.value}{' [' + data.name + ']'}<br />
                                                            </div>
                                                            :
                                                            <></>
                                                    ))
                                                }
                                            </> : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'emails'} />
                                        </td>
                                        <td className='text-left'>
                                            {(emails && !_.isEmpty(emails)) ? <>
                                                {
                                                    emails.map((data, idx) => (
                                                        data.value
                                                            ?
                                                            <div key={idx}>
                                                                {data.value}{' [' + data.name + ']'}<br />
                                                            </div>
                                                            :
                                                            <></>
                                                    ))
                                                }
                                            </> : '--'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'maritalStatus'} />
                                        </td>
                                        <td className='text-left'>
                                            {maritalStatus ? CommonHelper.getNameByValue(setttings ? setttings.maritalStatus : [], maritalStatus, '--') : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'nationality'} />
                                        </td>
                                        <td className='text-left'>
                                            {nationality ? CommonHelper.getNameByValue(dealersettings.nationalities, nationality, nationality) : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'preferredLanguage'} />
                                        </td>
                                        <td className='text-left'>
                                            {language ? CommonHelper.getNameByCode(dealersettings.languages, language, '--') : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'preferredContactMethod'} />
                                        </td>
                                        <td className='text-left'>
                                            {contactMethod ? CommonHelper.getNameByValue(setttings ? setttings.contactMethod : [], contactMethod, '--') : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'interests'} />
                                        </td>
                                        <td className='text-left'>
                                            {interests ? CommonHelper.getLabelByMultipleValues(setttings ? setttings.interests : [], interests, '--', true) : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'driverName'} />
                                        </td>
                                        <td className='text-left'>
                                            {driverName ? driverName : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'dependents'} />
                                        </td>
                                        <td className='text-left'>
                                            {dependents ? dependents : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'campaign'} />
                                        </td>
                                        <td className='text-left'>
                                            {campaign ? CommonHelper.getNameByValue(setttings ? setttings.campaigns : [], campaign, '--') : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'origin'} />
                                        </td>
                                        <td className='text-left'>
                                            {origin ? CommonHelper.getNameByValue(setttings ? setttings.origins : [], origin, '--') : '--'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'banLoanVehicle'} />
                                        </td>
                                        <td className='text-left'>
                                            {banLoanVehicle ? 'Yes' : 'No'}
                                        </td>
                                    </tr>
                                    {
                                        prevPurchased
                                            ?
                                            <tr>
                                                <td className='text-left label-text'>
                                                    <Translate text={'prevPurchased'} />
                                                </td>
                                                <td className='text-left'>
                                                    {prevPurchased}
                                                </td>
                                            </tr>
                                            :
                                            <></>
                                    }

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'optinPhone'} />
                                        </td>
                                        <td className='text-left'>
                                            {optinPhone ? 'Yes' : 'No'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'optinSMS'} />
                                        </td>
                                        <td className='text-left'>
                                            {optinSMS === null ? '--' : (optinSMS ? 'Yes' : 'No')}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'optinEmail'} />
                                        </td>
                                        <td className='text-left'>
                                            {optinEmail ? 'Yes' : 'No'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'optinPost'} />
                                        </td>
                                        <td className='text-left'>
                                            {optinPost ? 'Yes' : 'No'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'marketingOptinEmail'} />
                                        </td>
                                        <td className='text-left'>
                                            {marketingOptinEmail === null ? '--' : (marketingOptinEmail ? 'Yes' : 'No')}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='text-left label-text'>
                                            <Translate text={'marketingOptinSMS'} />
                                        </td>
                                        <td className='text-left'>
                                            {marketingOptinSMS === null ? '--' : (marketingOptinSMS ? 'Yes' : 'No')}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            {
                                (secondaryIDNumber || secondaryIDType || secondaryIDExpiry || secondaryLicenseBackUrl || secondaryLicenseFrontUrl)
                                    ?
                                    <>
                                        <div className="contact-info-section-head"> <Translate text={'secondaryID'} /></div>
                                        <table className='table-fill'>
                                            <tbody>

                                                <tr>
                                                    <td className='text-left label-text' width="35%">
                                                        <Translate text={'secondaryIDNumber'} />
                                                    </td>
                                                    <td className='text-left' width="65%">
                                                        {secondaryIDNumber ? secondaryIDNumber : '--'}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='text-left label-text' width="35%">
                                                        <Translate text={'secondaryIDType'} />
                                                    </td>
                                                    <td className='text-left' width="65%">
                                                        {secondaryIDType ? secondaryIDType : '--'}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='text-left label-text' width="35%">
                                                        <Translate text={'secondaryIDExpiry'} />
                                                    </td>
                                                    <td className='text-left' width="65%">
                                                        {secondaryIDExpiry ? moment(secondaryIDExpiry).format('DD MMM YYYY') : '--'}
                                                    </td>
                                                </tr>


                                                {secondaryLicenseBackUrl ||
                                                    secondaryLicenseFrontUrl ? (
                                                    <>
                                                        <tr>
                                                            <td
                                                                className='text-left label-text'
                                                                width='35%'
                                                            >
                                                                <Translate text={'seconadryID'} />
                                                            </td>
                                                            <td className='text-left' width='65%'>
                                                                <div className='contact-detail-license'>
                                                                    <div className='license-box'>
                                                                        {secondaryLicenseFrontUrl ? (
                                                                            <>
                                                                                {
                                                                                    (isPrivacyEnabled)
                                                                                        ?
                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                        :
                                                                                        <a
                                                                                            data-fancy-box={`license-box`}
                                                                                            href={secondaryLicenseFrontUrl}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.handleSaveAuditLog(secondaryLicenseFrontUrl);
                                                                                                this.setState({
                                                                                                    imageViewer: {
                                                                                                        show: true,
                                                                                                        images: _allImages,
                                                                                                        index: _allImages.findIndex(e => e === secondaryLicenseFrontUrl)
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={secondaryLicenseFrontUrl}
                                                                                                alt=''
                                                                                                className="img-object-fit"
                                                                                            />{' '}
                                                                                        </a>
                                                                                }
                                                                            </>

                                                                        ) : (
                                                                            <a
                                                                                href='#'
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={_images.nolicense}
                                                                                    alt=''
                                                                                    className="img-object-fit"
                                                                                />{' '}
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                    <div className='license-box'>
                                                                        {secondaryLicenseBackUrl ? (
                                                                            <>
                                                                                {
                                                                                    (isPrivacyEnabled)
                                                                                        ?
                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                        :
                                                                                        <a
                                                                                            data-fancy-box={`license-box`}
                                                                                            href={secondaryLicenseBackUrl}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.handleSaveAuditLog(secondaryLicenseBackUrl);
                                                                                                this.setState({
                                                                                                    imageViewer: {
                                                                                                        show: true,
                                                                                                        images: _allImages,
                                                                                                        index: _allImages.findIndex(e => e === secondaryLicenseBackUrl)
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={secondaryLicenseBackUrl}
                                                                                                alt=''
                                                                                                className="img-object-fit"
                                                                                            />{' '}
                                                                                        </a>
                                                                                }
                                                                            </>

                                                                        ) : (
                                                                            <a
                                                                                href='#'
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={_images.nolicense}
                                                                                    alt=''
                                                                                    className="img-object-fit"
                                                                                />{' '}
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                            </tbody>
                                        </table>
                                    </>
                                    :
                                    <></>
                            }
                        </>
                        :
                        <></>
                }


                <PopUpModal show={this.state.showReAuthentication}>
                    <ReAuthenticate
                        show={this.state.showReAuthentication}
                        handleClose={this.handleReAuthOpenClose}
                        dealersettings={dealersettings}
                    />
                </PopUpModal>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>
        );
    }
}

export default ContactInfo;