/** LIBRARIES */
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
import { StageProvider, StageConsumer } from './provider'
/** COMPONENTS */
import { SidePanel } from '../../components'
import EnquiryList from './enquirylist';
import QuickView from "./quickview";
//import QuickView from "./quickview";
import StageLoader from "../pipeline/stageloader";
import FilterPanel from './filterPanel'
import images from '../../images';
import Translate from '../../constants/translate';

const Pipeline = (props) => {
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    useEffect(() => {
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.remove('fixed-top')
        return () => {
            document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        }
    }, [])

    // useEffect(() => {
    //     let settingsid = props.dealersettings.client.settings.id;
    //     let userID = props.dealersettings.id;
    //     const userRefDoc = window.firebase.firestore().collection(`users/${localStorage.uid}/filters`)
    //         .where('module', '==', 'wholesaler')
    //         .onSnapshot((querySnapshot) => {
    //             let _customFilters = [];
    //             querySnapshot.forEach(doc => {
    //                 const _filt = {
    //                     ...doc.data(),
    //                     documentID: doc.id,
    //                     clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
    //                 }
    //                 _customFilters.push(_filt);
    //             });
    //             _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
    //                 (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
    //             setCustomFilters(_customFilters)

    //         });
    //     return () => {
    //         userRefDoc && userRefDoc();
    //     }
    // }, [])

    const sidepanelClose = useCallback(() => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            offerid: 0
        })
    }, [])

    const sidepanelOpen = useCallback((documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            offerid: documentID
        })
        //props.history.push("/wholesaler/details/" + documentID);
    }, [])

    return (
        <LayoutConsumer>
            {({ updateDealerSettings, dealersettings, clientUsers, groupUsers }) => dealersettings ? (

                <StageProvider dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={props.history} updateDealerSettings={updateDealerSettings}>
                    <StageConsumer>
                        {({ tradeins, pipeline, isLoading, columns, handleEnquiryFav, loadMoreData, hasMore,
                            stageLoading, updateEnquiry, sectionloading,
                            handleApplyFilter, handleClearFilter, isFilterApplied,
                            stageCounts, searchText, handlesearchText, handlePipelineChange }) => (
                            <div className={`middle-wrapper middle-wrapper-pipeline mt-0 ${pipeline.length === 0 ? 'h-100' : ''}`}>
                                {
                                    pipeline.length === 0 ? (<></>) : (
                                        <FilterPanel
                                            pipeline={pipeline}
                                            customFilters={customFilters}
                                            handlePipelineChange={(val) => {
                                                handlePipelineChange(val);
                                            }}
                                            dealersettings={dealersettings}
                                            clientUsers={clientUsers}
                                            groupUsers={groupUsers}
                                            handleApplyFilter={handleApplyFilter}
                                            handleClearFilter={handleClearFilter}
                                            stageCounts={stageCounts}
                                            isFilterApplied={isFilterApplied}
                                            view="pipeline"
                                            searchText={searchText}
                                            handlesearchText={handlesearchText}
                                        />
                                    )
                                }
                                {

                                    pipeline.length === 0 ? (
                                        <div className="spinner-loader h-100">
                                            <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                                <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                                <div className="no-data-txt-wrap">
                                                    <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                                    {
                                                        !_.isEmpty(dealersettings)
                                                            && !_.isEmpty(dealersettings.rolePermissions)
                                                            && !_.isEmpty(dealersettings.rolePermissions.admin)
                                                            && Object.keys(dealersettings.rolePermissions.admin).filter(function (id) {
                                                                return dealersettings.rolePermissions.admin[id]
                                                            }).some(e => e === 'tradeinProworkflowSetup') ?
                                                            <>
                                                                <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please create one.'} /></div>
                                                                
                                                            </>
                                                            :
                                                            <>
                                                                <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                                            </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    ) : (isLoading || Object.keys(tradeins).length === 0 || JSON.stringify(_.map(tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) ? (<StageLoader columns={columns} stages={pipeline.find(item => item.default === true) ? pipeline.find(item => item.default === true).stages : []}></StageLoader>) :
                                        (
                                            <EnquiryList
                                                sidepanelOpen={sidepanelOpen}
                                                tradeins={tradeins}
                                                columns={columns}
                                                pipeline={pipeline}
                                                loadMoreData={loadMoreData}
                                                stageLoading={stageLoading}
                                                sectionloading={sectionloading}
                                                stageCounts={stageCounts}
                                                clientUsers={clientUsers}
                                                groupUsers={groupUsers}
                                                isFilterApplied={isFilterApplied}
                                                searchText={searchText}
                                                handleEnquiryFav={handleEnquiryFav}
                                                dealersettings={dealersettings}
                                                hasMore={hasMore}
                                            >
                                            </EnquiryList>
                                        )
                                }

                                 <SidePanel
                                    clsActive={showpanel.clsActive}
                                    clsName={showpanel.clsName}
                                    sidepanelClose={sidepanelClose}
                                    title=''
                                >
                                    <QuickView
                                        {...props}
                                        offerid={showpanel.offerid}
                                        isFilterApplied={isFilterApplied}
                                        sidepanelClose={sidepanelClose}
                                        updateEnquiry={updateEnquiry}
                                    />
                                </SidePanel> 

                            </div>
                        )}
                    </StageConsumer>
                </StageProvider>
            ) : (<></>)}
        </LayoutConsumer>
    );
}


export default Pipeline;