import React, { useRef, useState, useEffect } from "react";
import { Dropdown, Modal, Popover } from "react-bootstrap";
import moment from "moment";
import CommonHelper from '../../services/common';
import _ from 'lodash'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import { reminderOptions, defaultActivityTypes } from '../../services/enum';
import toast from 'toasted-notes';
import { firestoreDB } from "../../services/helper";
import Requirements from '../requirement/list';
import { MentionEditor } from "../../components";

const FieldCommentsPopUp = (props) => {
    const [activity, setActivity] = useState({})
    const [contact, setContact] = useState({})
    const [enquiry, setEnquiry] = useState({})

    const [service, setService] = useState({})
    const [tradeinPro, setTradeinPro] = useState({})
    const [notification, setNotification] = useState(props.notification)
    const [checkDataLoad, setDataload] = useState(true)
    const node = useRef();
    const [showPop, setShowPop] = useState(false);
    const [newnote, setnewnote] = useState('')
    const [errorNotes, setErrorNotes] = useState('')
    const [btnloader, setbtnloader] = useState(false)
    const [mentions, setMentions] = useState([]);
    const [notes, setNotes] = useState([])
    const _roleids = props?.dealersettings?.client?.roles?.filter(rec => rec?.modules?.dealLog === true)?.map(rec => { return rec.id }) || [];
    const _allUser = props?.groupUsers || props?.clientUsers || [];
    const mentionsData = _.uniqBy(_allUser, 'id').filter(a => a.id !== localStorage.uid && a.active === true && _.includes(_roleids, a.roleID)).map(clientUser => {
        return {
            name: `${clientUser.name}`,
            id: clientUser.id,
            email: clientUser.email,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });
    useEffect(() => {
        if (props.notification) setNotification(props.notification);

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShowPop(false);
    };

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        getCommentsbyID(props.notification.recordID);
    }, [checkDataLoad])

    useEffect(() => {
        if (_.isEmpty(activity))
            return

        firestoreDB({ client: { projectId: activity?.projectId } }).firestore()
            .collection(`fieldComments`)
            .where('isDeleted', '==', false)
            .where('field', '==', activity.field)
            .where('enquiryID', '==', activity.enquiryID)
            .get()
            .then((snap) => {
                const _notes = [];
                if (snap.size > 0) {
                    snap.docs.forEach(doc => {
                        if (doc.data().notes) {
                            _notes.push(doc.data())
                        }
                    });
                }
                setNotes(_.orderBy(_notes, 'addedDate.seconds', 'desc'));
            })
    }, [activity])

    const getCommentsbyID = async id => {
        if (!_.isEmpty(id)) {
            const activitySnapshot = await firestoreDB(props.dealersettings).firestore().doc(`fieldComments/${id}`).get();
            if (activitySnapshot.exists) {
                const fields = activitySnapshot.data();

                if (fields.contactID) {
                    if (_.isEmpty(fields['contact'])) {
                        const contactSnap = await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${fields.contactID}`).get();
                        const contactData = contactSnap.data();
                        setContact(contactData)
                    }
                    else
                        setContact(Object.assign({}, fields['contact']));
                }

                if (fields.enquiryID) {
                    let enquiryData = null
                    if (_.isEmpty(fields['enquiry'])) {
                        const enquiryRef = firestoreDB(props.dealersettings).firestore().doc(`enquiries/${fields.enquiryID}`);
                        const enquirySnap = await enquiryRef.get();
                        enquiryData = enquirySnap.data();
                    }
                    else
                        enquiryData = Object.assign({}, fields['enquiry'])

                    setEnquiry(enquiryData)
                    if (!fields.contactID && enquiryData?.contact) {
                        setContact(enquiryData.contact)
                    }
                }
                setActivity(fields);
            }
        }
    };

    const showTitle = (rec) => {
        return !_.isEmpty(rec.subType) && (rec.type === 'log' || rec.type === 'financelog' || rec.type === 'afterMarketlog') ? CommonHelper.autoCaps(rec.subType) : rec.title
    }

    const onMentionChange = notesText => {
        setnewnote(notesText);
    };

    const newlogvm = () => {
        let newlogVM = Object.assign({});
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = activity?.enquiryID || null;;
        newlogVM.contactID = activity?.contactID || null;
        newlogVM.clientID = activity?.clientID || null;
        newlogVM.oemID = props?.dealersettings?.oemID || null;
        newlogVM.documentID = firestoreDB({ client: { projectId: activity?.projectId } }).firestore().collection('fieldComments').doc().id;
        newlogVM.addedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
        newlogVM.modifiedByName = CommonHelper.getUserNamebyId(_allUser, localStorage.uid);
        newlogVM.projectId = activity?.projectId || null;
        newlogVM.notes = null;
        newlogVM.isDeleted = false;
        newlogVM.field = activity?.field || null;
        newlogVM.fieldName = activity?.fieldName || null;
        return newlogVM;
    };

    const handleSave = (e) => {
        if (_.isEmpty(newnote?.trim())) {
            setErrorNotes('input_error');
            return;
        }
        const commnetsLog = newlogvm();
        if (mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })
            commnetsLog.mentions = _.uniq(convertedIDs)
        }
        commnetsLog.notes = newnote;
        setbtnloader(true);
        firestoreDB({ client: { projectId: activity?.projectId } }).firestore().doc(`fieldComments/${commnetsLog.documentID}`)
            .set(commnetsLog)
            .then(doc => {
                setbtnloader(false);
                setnewnote('')
                toast.notify('Comments added successfully', {
                    duration: 2000
                })
                props.handleClose();
            })
    }

    const onMentionSelect = (option) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    return (!_.isEmpty(activity) ?
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-comments-notify-mini">
            <Modal.Header closeButton>
                <Modal.Title >
                    <Translate text={showTitle(notification)} />
                    {enquiry?.displayID ? <div className="lead-source-badge mt-2"><div className="badge badge-pill badge-white">{enquiry?.displayID}</div></div> : <></>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="comments-notify-mini-layout form-style">
                            <div className="comments-notify-mini-subhead">
                                <h3> <Translate text={'contactDetails'} /></h3>
                            </div>
                            <div className="form-group">
                                <div className="comments-notify-mini-wrap">
                                    <div className="input-wrap">
                                        <div className="input-wrap-inner">
                                            <div className="comments-notify-mini-contact"> <i className="ico icon-Contacts"></i> {CommonHelper.displayFullContact(null, contact, '--')} </div>
                                            <div className="comments-notify-mini-contact-sub">
                                                <ul className="comments-notify-mini-contact-details">
                                                    <li> <i className="ico icon-call"></i> {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}</li>
                                                    <li> <i className="ico icon-email"></i> {contact && contact.email ? contact.email : '--'}</li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                enquiry?.requirement || tradeinPro?.tradein || service?.vehicle
                                    ?
                                    <>

                                        <div className="comments-notify-mini-subhead">
                                            <h3> <Translate text={'vehicleDetails'} /></h3>
                                        </div>
                                        <Requirements
                                            requirement={
                                                !_.isEmpty(enquiry?.requirement) ? enquiry?.requirement :
                                                    (!_.isEmpty(tradeinPro?.tradein) ? tradeinPro?.tradein :
                                                        (!_.isEmpty(service?.vehicle) ? service?.vehicle : {}))}
                                            enquiryID={activity.enquiryID}
                                            handleEdit={() => { }}
                                            setPrimary={() => { }}
                                            decrementQuantity={() => { }}
                                            incrementQuantity={() => { }}
                                            isPrimary={false}
                                            isDeivered={true}
                                            hideMore={true}
                                            dealersettings={props.dealersettings}
                                            clientUsers={props.clientUsers}
                                            groupUsers={props.groupUsers}
                                        >
                                        </Requirements>
                                    </>
                                    :
                                    <></>
                            }
                            <div className="pop-subhead mt-3">
                                <h3>Comments</h3>
                            </div>
                            <div>
                                <div className="deallog-newcomments-user-wrap comments-notify-mini-fix">
                                    {
                                        notes.map((rec, index) => {
                                            const logBy = rec.addedByName ? rec.addedByName : (rec.addedBy ? CommonHelper.getUserNamebyId(_allUser, rec.addedBy) : '');
                                            return <div key={index} id={rec.documentID} className="deallog-newcomments-user-item">
                                                <div>
                                                    <div className="deallog-newcomments-owner-view"> <img src={CommonHelper.getUserImagebyId(_allUser, rec.addedBy)} /></div>
                                                </div>
                                                <div className="w-100">
                                                    <p className="deallog-newcomments-user-status"><span className="deallog-newcomments-blue-text">{logBy}</span><span>{rec.addedDate ? moment.unix(rec.addedDate.seconds).format(`DD/MM/YYYY hh:mm A`) : ''}</span></p>
                                                    <div className="deallog-newcomments-user-block deallog-newcomments-blue-bg">
                                                        <p>{rec.notes}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="deallog-newcomments-add-wrap form-group mb-0">
                                    <MentionEditor
                                        mentions={mentionsData}
                                        onChange={onMentionChange}
                                        value={newnote || ''}
                                        placeholder={'Enter @ to mention user'}
                                        className={`notes ${errorNotes}`}
                                        rows={3}
                                        cols={40}
                                        onMentionSelect={onMentionSelect}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button"
                    className={`btn btn-default float-left`}
                    onClick={(e) => { e.preventDefault(); props.handleClose(); }} >
                    <Translate text={'cancel'} />
                </button>
                <button type="button"
                    className={`btn btn-primary float-right d-flex align-items-center justify-content-center`}
                    onClick={(e) => {
                        e.preventDefault();
                        handleSave();
                    }}>
                    {
                        btnloader ? (
                            <span
                                className='spinner-border spinner-button mr-1'
                                role='status'
                                aria-hidden='true'
                            ></span>
                        ) : (
                            <i className="ico icon-email mr-1"></i>
                        )}
                    <Translate text={'send'} />
                </button>
            </Modal.Footer>
        </Modal>
        : <></>)
}

export default FieldCommentsPopUp;