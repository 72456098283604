/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import {
    ReactTimePicker, AntDatePicker,
    ImageViewer, PopUpModal, InputRadio, ReactSelect,
    InputText, InputTextArea,
    PopoverPanel, InputRange,
    Terms, AfterSignature, ImageCanvas
} from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Contacts from '../../contacts/list/index';
import Users from '../../users/list/index';
import AddContact from '../../contacts/add';
import EditUser from '../../users/edit';
import AddNotes from './notes'
import OdometerHistorys from './historyInfo'
import SignOptions from '../../common/signOptions'
/** VIEW-MODELS */
import { stockStatus, fleetStatus } from '../../../services/enum'
import { objFleet, _returnMandatoryFields } from '../viewModel'
import { objMark } from '../../tradeIn/viewModel'
import { objBasicUserInfo } from "../../users/viewModel";
import { contactBasicWithKeywordsVM } from '../../contacts/viewModel'
import { stockBasicVM } from '../../stock/viewModel'
import { firestoreDB } from "../../../services/helper";

const loan_type = [
    { value: 'Service', label: 'Service' },
    { value: 'Other', label: 'Other' }
]
const sign_type = [
    { active: true, ico: 'ico icon-email', value: 'send', label: 'Send sign request', text: 'Send signature request to customer' },
    { active: true, ico: 'ico icon-sign', value: 'sign', label: 'Sign on-sight', text: 'Get signature on-site from customer' }
]
export default class AddFleet extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('fleet');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refTestDrive = firestoreDB(this.props.dealersettings).firestore().collection('testdrives');
        this.refStock = window.firebase.firestore().collection('stock');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.returnMandatoryFields = [];
        this.mandatoryContactForTestDrive = [];
        this.mandatoryStaffForTestDrive = [];
        this.unsubscribeTerms = null;
        this.unsubscribeInsurance = null;
        this.unsubscribeSign = null;
        this.state = {
            fields: Object.assign({}, objFleet),
            mark: Object.assign({}, objMark),
            notesPopOver: { showpopover: false, targetpopover: null },
            stockBookings: Object.assign([]),
            requestsTerms: Object.assign({}),
            requestsInsurance: Object.assign({}),
            requestsSign: Object.assign({}),
            showTermsOption: false,
            showInsuranceOption: false,
            showSignOption: false,
            contactModal:
            {
                docID: null,
                showContact: false,
                showContactSearch: false,
                contactTitle: '',
                clsContactActive: '',
                mandatoryFields: null
            },
            staffModal:
            {
                docID: null,
                showContact: false,
                showContactSearch: false,
                contactTitle: '',
                clsContactActive: '',
                mandatoryFields: null
            },
            errors: {},
            isContact: true,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showUsers: false,
            showContacts: false,
            showBeforeInspection: false,
            showAfterInspection: false,
            showBeforeTerms: false,
            showAfterSignature: false,
            showStaff: false,
            showBeforeInsurance: false,
            showHistory: false,
            imageViewer: { show: false, images: null }
        }
        this.includeInsWaiverInTDTerms = ((props.dealersettings?.client?.clientSettings?.includeInsWaiverInTDTerms) ? true : false);
    }

    //#region FUNCTIONS

    //#region PAGE LOAD

    async loadFleet(props) {

        const { show, docID, stock, dealersettings, serviceID, selectedClientID } = props;
        const fields = Object.assign({}, objFleet);
        let isContact = false;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            let setttings = dealersettings.client.settings;

            if (selectedClientID && selectedClientID !== dealersettings.client.id && dealersettings.group?.clients && dealersettings.group.clients[selectedClientID] && dealersettings.group.clients[selectedClientID].settings)
                setttings = dealersettings.group.clients[selectedClientID].settings;

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
                this.mandatoryContactForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');

            const roNumRequired = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.rolePermissions) &&
                !_.isEmpty(dealersettings.rolePermissions.permissions) &&
                dealersettings.rolePermissions.permissions.roNumRequired) ? true : false);

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.fleet)) {
                let _fleetMandatory = setttings.mandatoryFields.fleet.split(',');
                let beforeMandtry = [];
                let afterMandtry = [];
                let staffMandtry = [];
                _fleetMandatory.map((field) => {
                    if (_returnMandatoryFields.indexOf(field) >= 0)
                        afterMandtry.push(field);
                    else if (field.includes('staff') === true) {
                        if (field === 'staffLicenseNo')
                            staffMandtry.push('licenseNo')
                        else if (field === 'staffLicenseExpiry')
                            staffMandtry.push('licenseExpiry')
                        else if (field === 'staffLicenseFront')
                            staffMandtry.push('licenseFrontURL')
                        else if (field === 'staffLicenseBack')
                            staffMandtry.push('licenseBackURL')
                    }
                    else
                        beforeMandtry.push(field);
                })

                if (roNumRequired && beforeMandtry.indexOf('roNumber') < 0)
                    beforeMandtry.push('roNumber');

                this.mandatoryFields = beforeMandtry;
                this.returnMandatoryFields = afterMandtry;
                this.mandatoryStaffForTestDrive = staffMandtry;
            }

        }

        if (show === true && docID) {
            this.newDocID = docID;
            const refData = await firestoreDB(dealersettings).firestore().collection('fleet').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                if (!fields.documentID)
                    fields.documentID = docID;

                if (!_.isEmpty(fields.startDate))
                    fields.startDate = moment.unix(fields.startDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(fields.endDate))
                    fields.endDate = moment.unix(fields.endDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(fields.expectedReturn))
                    fields.expectedReturn = moment.unix(fields.expectedReturn.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(fields.contact))
                    isContact = true;

                if (!_.isEmpty(fields.beforeInspection)) {
                    const _marks = [];
                    fields.beforeInspection.map((mark) => {
                        const _mark = Object.assign({}, objMark);
                        for (let [key, value] of Object.entries(mark)) {
                            _mark[key] = value;
                        }
                        _marks.push(_mark)
                    })
                    fields.beforeInspection = _marks;
                }

                if (!_.isEmpty(fields.afterInspection)) {
                    const _marks = [];
                    fields.afterInspection.map((mark) => {
                        const _mark = Object.assign({}, objMark);
                        for (let [key, value] of Object.entries(mark)) {
                            _mark[key] = value;
                        }
                        _marks.push(_mark)
                    })
                    fields.afterInspection = _marks;
                }

                if (!_.isEmpty(stock)) {
                    fields.tradePlate = (stock.tradePlate) ? stock.tradePlate : '';
                }
                //commented 02-05-23 instructed by muzammal
                // if (!_.isEmpty(fields.stock) && !fields.stock.regNo && this.mandatoryFields.indexOf('tradePlate') < 0)
                //     this.mandatoryFields.push('tradePlate');

                this.setState({ fields: fields, isContact: isContact }, () => { this.loadBookingAppointments(fields) });

            } else {
                console.error("No such document2!");
            }

        }
        else if (show === true && (!docID)) {
            this.newDocID = this.ref.doc().id;
            const fields = Object.assign({}, objFleet);
            fields.documentID = this.newDocID;
            fields.startDate = moment().format('YYYY-MM-DD HH:mm')

            if (!_.isEmpty(stock)) {
                let _stockVM = Object.assign({}, stockBasicVM);
                for (let [key, value] of Object.entries(stock)) {
                    if (_stockVM.hasOwnProperty(key))
                        _stockVM[key] = value;
                }

                fields.stockID = stock.documentID;
                fields.stock = _stockVM;
                fields.tradePlate = (stock.tradePlate) ? stock.tradePlate : '';
                fields.startOdometer = (stock.miles && Number(stock.miles)) ? Number(stock.miles) : 0;
            }

            if (!_.isEmpty(this.props.contact) && this.props.contact.documentID) {
                let _contact = Object.assign({}, contactBasicWithKeywordsVM);
                const refData = await this.refContact.doc(this.props.contact.documentID).get();
                if (refData.exists) {
                    const objcontactData = Object.assign({}, refData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        _contact[key] = value;
                    }
                    fields.contact = _contact;
                    fields.contactID = _contact.documentID;
                }
                else {

                    for (let [key, value] of Object.entries(this.props.contact)) {
                        if (_contact.hasOwnProperty(key))
                            _contact[key] = value;
                    }
                    fields.contact = _contact;
                    fields.contactID = _contact.documentID;
                }

                if (!_.isEmpty(fields.contact) && fields.contact.banLoanVehicle) {
                    Swal.fire(`${CommonHelper.displayContact(null, fields.contact, 'Contact')} ${CommonHelper.showLocale(this.props, 'has been restricted from loan vehicle')}`, '', 'info')
                    this.handleModalClose(null);
                    return;
                }

                this.setState({
                    isContact: true,
                })
            }

            if (serviceID) {
                fields.serviceID = serviceID;

                const { loanAppointmentData } = this.props;
                if (!_.isEmpty(loanAppointmentData)) {
                    fields.appointmentID = loanAppointmentData.documentID;

                    if (!_.isEmpty(loanAppointmentData.startDate))
                        fields.startDate = moment.unix(loanAppointmentData.startDate.seconds).format('YYYY-MM-DD HH:mm')

                    if (!_.isEmpty(loanAppointmentData.expectedReturn))
                        fields.expectedReturn = moment.unix(loanAppointmentData.expectedReturn.seconds).format('YYYY-MM-DD HH:mm')
                }
            }

            fields.clientID = (selectedClientID || dealersettings.client.id);

            //commented 02-05-23 instructed by muzammal
            // if (!_.isEmpty(fields.stock) && !fields.stock.regNo && this.mandatoryFields.indexOf('tradePlate') < 0)
            //     this.mandatoryFields.push('tradePlate');

            this.setState({ fields: fields }, () => { this.loadBookingAppointments(fields) });
        }
    }

    loadBookingAppointments = (fields) => {
        let _fields = !_.isEmpty(fields) ? fields : this.state.fields;
        let stockID = _fields.stockID ? _fields.stockID : (_fields.stock ? _fields.stock.documentID : null)
        if (stockID && fields.status !== fleetStatus.COMPLETE) {
            firestoreDB(this.props.dealersettings).firestore()
                .collection('loanAppointments')
                .where('stockID', '==', stockID)
                .get()
                .then(querySnapshot => {
                    let _bookings = [];
                    querySnapshot.docs.forEach(rec => {
                        _bookings.push(rec.data())
                    })
                    this.setState({ stockBookings: _bookings })
                });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadFleet(this.props);

    }

    componentWillUnmount() {
        this._isMounted = false;
        let _req = Object.assign({}, objFleet);
        _req.insurance = {};
        _req.terms = {};
        _req.keywords = [];
        _req.beforeInspection = [];
        _req.afterInspection = [];
        this.unsubscribeTerms && this.unsubscribeTerms();
        this.unsubscribeInsurance && this.unsubscribeInsurance();
        this.unsubscribeSign && this.unsubscribeSign();

        const { requestsTerms, requestsInsurance, requestsSign } = this.state;
        if (requestsTerms && requestsTerms.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsTerms.contactID}/signRequest/${requestsTerms.id}`).delete();

        if (requestsInsurance && requestsInsurance.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsInsurance.contactID}/signRequest/${requestsInsurance.id}`).delete();

        if (requestsSign && requestsSign.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsSign.contactID}/signRequest/${requestsSign.id}`).delete();

        this.setState({ fields: _req });
    }
    //#endregion

    //#region CONTACT CHANGES

    handleContactOpen = (ID, title, _manFields) => {
        //    console.log('handleContactOpen', _manFields)
        setTimeout(() => {
            this.setState({
                contactModal: {
                    docID: ID ? ID : null,
                    showContact: true,
                    showContactSearch: false,
                    contactTitle: title,
                    clsContactActive: 'overlay-modal active',
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                },
            });
        }, 200);

    }

    handleContactClose = (ID, objData) => {
        //console.log('FLEET-handleContactClose', ID, objData)
        let dealersettings = this.props.dealersettings;
        /*if (ID && !_.isEmpty(objData)) {

            let _contact = Object.assign({}, contactBasicWithKeywordsVM);
            for (let [key, value] of Object.entries(objData)) {
                if (_contact.hasOwnProperty(key))
                    _contact[key] = value;
            }

            if (dealersettings.group && _contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                _contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
            }

            this.saveValidContact(_contact);
        }
        else*/ if (ID) {

            if (!_.isEmpty(objData) && objData.banLoanVehicle) {
                Swal.fire(`${CommonHelper.displayContact(null, objData, 'Contact')} ${CommonHelper.showLocale(this.props, 'has been restricted from loan vehicle')}`, '', 'info')
                return;
            }

            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const contact = Object.assign({}, contactBasicWithKeywordsVM);
                    for (let [key, value] of Object.entries(objStockData)) {
                        if (contact.hasOwnProperty(key))
                            contact[key] = value;
                    }
                    if (contact.banLoanVehicle) {
                        Swal.fire(`${CommonHelper.displayContact(null, contact, 'Contact')} ${CommonHelper.showLocale(this.props, 'has been restricted from loan vehicle')}`, '', 'info')
                        return;
                    }
                    contact.documentID = doc.id;
                    if (contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                        contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
                    }

                    this.saveValidContact(contact);

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showContacts: false,
            contactModal: { docID: null, showContact: false, showContactSearch: false, contactTitle: '', clsContactActive: '', mandatoryFields: null }
        });
    }

    saveValidContact = (ObjCustomer) => {
        if (!_.isEmpty(this.mandatoryContactForTestDrive)) {
            var msg = '';
            this.mandatoryContactForTestDrive.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                } else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });

            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, msg),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleContactOpen(ObjCustomer.documentID, 'editContact', this.mandatoryContactForTestDrive);
                    }
                });

                return false;
            } else {
                this.handleOpenTestDriveModal(ObjCustomer, 'contact');
            }
        } else {
            this.handleOpenTestDriveModal(ObjCustomer, 'contact');
        }
    }

    handleOpenTestDriveModal = (ObjCustomer, type) => {
        if (ObjCustomer['licenseExpiry'] && (moment()._d > moment(ObjCustomer['licenseExpiry'])._d)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'License is expired.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Update Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    if (type === 'staff')
                        this.handleStaffOpen(ObjCustomer.documentID, 'editUser', ['licenseExpiry']);
                    else
                        this.handleContactOpen(ObjCustomer.documentID, 'editContact', ['licenseExpiry']);
                }
            });

            return false;
        }
        else {
            const fields = Object.assign({}, this.state.fields);

            if (type === 'staff')
                fields.staff = ObjCustomer;
            else
                fields.contact = ObjCustomer;

            //    console.log('handleOpenTestDriveModal', type, fields);

            this.setState({ fields: fields }, () => { this.errorChange(type) });
        }
    }

    isContactExist = () => {
        const { contact } = this.state.fields;
        if (!_.isEmpty(contact))
            return true;
        else
            return false;
    }

    handleRemoveContact = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.contact = null;
        this.setState({ fields: fields });
    }
    //#endregion

    //#region Staff CHANGES

    handleStaffOpen = (ID, title, _manFields) => {
        //    console.log('handleStaffOpen', _manFields)
        setTimeout(() => {
            this.setState({
                staffModal: {
                    docID: ID ? ID : null,
                    show: true,
                    contactTitle: title,
                    clsContactActive: 'overlay-modal active',
                    mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
                },
            });
        }, 200);

    }

    handleStaffClose = (ID, objData) => {
        //    console.log('handleStaffClose', ID, objData)
        if (ID && !_.isEmpty(objData)) {

            let _staff = Object.assign({}, objBasicUserInfo);
            for (let [key, value] of Object.entries(objData)) {
                if (_staff.hasOwnProperty(key) && value)
                    _staff[key] = value;
            }

            if (!_staff.documentID)
                _staff.documentID = objData.id;

            this.saveValidStaff(_staff);
            //this.setState({ fields: fields }, () => { this.errorChange('staff'); });

        }
        else if (ID) {
            /*const refData = window.firebase.firestore().collection('stock').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const stock = Object.assign({}, objStock);
                    for (let [key, value] of Object.entries(objStockData)) {
                        stock[key] = value;
                    }
                    stock.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.stockID = doc.id;
                    this.setState({ stock: stock, fields: fields });
                    this.errorChange('stockID');

                } else {
                    console.error("No such document!");
                }
            });*/
        }

        this.setState({
            showUsers: false,
            staffModal: { docID: null, show: false, contactTitle: '', clsContactActive: '', mandatoryFields: null }
        });
    }

    saveValidStaff = (_objStaff) => {
        if (!_.isEmpty(this.mandatoryStaffForTestDrive)) {
            var msg = '';
            this.mandatoryStaffForTestDrive.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!_objStaff[el]) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!_objStaff[el]) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (!_objStaff[el] && !_.isBoolean(_objStaff[el])) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });

            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, msg),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleStaffOpen(_objStaff.documentID, 'editUser', this.mandatoryStaffForTestDrive);
                    }
                });

                return false;
            } else {
                this.handleOpenTestDriveModal(_objStaff, 'staff');
            }
        } else {
            this.handleOpenTestDriveModal(_objStaff, 'staff');
        }
    }

    isStaffExist = () => {
        const { staff } = this.state.fields;
        if (!_.isEmpty(staff))
            return true;
        else
            return false;
    }

    handleRemoveStaff = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.staff = null;
        this.setState({ fields: fields });
    }
    //#endregion

    //#region DATEPICKER

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val) {
            let date = state[name];
            if (date)
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
            else
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
        }
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = moment(date).format('YYYY-MM-DD HH:mm');

        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    minStartDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }


    minEndDate = (current) => {
        // Can not select days before today and today
        let state = Object.assign({}, this.state.fields);
        var minDate = state.startDate ? moment(state.startDate).startOf('day') : moment().startOf('day')
        return current && current < minDate;
    }
    //#endregion

    //#region INSPECTION
    handleBeforeInspectionClose = (marks) => {
        const { beforeInspection } = this.state.fields;
        if (!_.isEmpty(marks) || (!_.isEmpty(beforeInspection) && _.isEmpty(marks))) {
            let state = Object.assign({}, this.state.fields);
            state.beforeInspection = marks;
            this.setState({ showBeforeInspection: false, fields: state }, () => { this.errorChange('beforeInspection'); });

        }
        else {
            this.setState({ showBeforeInspection: false })
        }
    }

    handleAfterInspectionClose = (marks) => {
        const { afterInspection } = this.state.fields;
        if (!_.isEmpty(marks) || (!_.isEmpty(afterInspection) && _.isEmpty(marks))) {
            let state = Object.assign({}, this.state.fields);
            state.afterInspection = marks;
            this.setState({ showAfterInspection: false, fields: state }, () => { this.errorChange('afterInspection'); });

        }
        else {
            this.setState({ showAfterInspection: false })
        }
    }
    //#endregion

    //#region  TERMS / NOTES

    handleBeforeTermsClose = (data, insuranceConsent) => {

        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.terms = data;

            if (this.includeInsWaiverInTDTerms && insuranceConsent) {
                let _insuranceTC = CommonHelper.getTermsByField(this.props.dealersettings?.client, 'insuranceTC', state?.contact?.language);
                state.insurance = {
                    ...data,
                    signature: null,
                    termsAndConditions: !_.isEmpty(_insuranceTC) ? _insuranceTC : ''
                }
            }

            this.setState({ showBeforeTerms: false, fields: state }, () => { this.errorChange('terms'); });

        }
        else {
            this.setState({ showBeforeTerms: false })
        }
    }

    handleAfterSignatureClose = (imgURL) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state.afterSignatureURL = imgURL;
            this.setState({ showAfterSignature: false, fields: state }, () => { this.errorChange('afterSignatureURL'); });
        }
        else {
            this.setState({ showAfterSignature: false })
        }
    }

    handleNotesClose = (val) => {
        let state = Object.assign({}, this.state.fields);
        state['notes'] = val;
        this.setState({ fields: state, notesPopOver: { showpopover: false, targetpopover: '' } }, () => { this.errorChange('notes'); });
    }

    handleBeforeInsuranceClose = (data) => {
        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.insurance = data;
            this.setState({ showBeforeInsurance: false, fields: state }, () => { this.errorChange('insurance'); });
        }
        else {
            this.setState({ showBeforeInsurance: false })
        }
    }

    handleHistoryClose = (val) => {
        if (!_.isEmpty(val)) {
            let state = Object.assign({}, this.state.fields);
            state['odometerHistory'] = Object.assign([], val);
            this.setState({ fields: state, showHistory: false });
        }
        else {
            this.setState({ showHistory: false });
        }

    }
    //#endregion

    //#region CHANGE EVENT
    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);
        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = 'Service';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });
    }

    handleTypeChange = (e, data) => {
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        fields.staff = null;
        fields.contact = null;
        let val = (value === 'Contact' ? true : false);
        this.setState({ fields: fields, isContact: val });
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }


    handleFuelChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields['startFuelGauge'] = parseInt(value);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
        this.slideguage(name, value);

    }

    slideguage = (id, value) => {
        if (value >= 0 && value <= 25) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 25 && value <= 50) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 50 && value <= 75) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) 75%, rgb(100, 215, 112) 75%, rgb(100, 215, 112) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
    }

    handleEFuelChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields['endFuelGauge'] = parseInt(value);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
        this.slideguage(name, value);

    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });
        // var array1 = value.split(' ');
        // var str = value;

        // if (array1.length > 0) {
        //     var newarray1 = [];
        //     for (var x = 0; x < array1.length; x++) {
        //         newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
        //     }
        //     fields[name] = newarray1.join(' ');
        //     this.setState({ fields: fields });
        // } else {
        //     if (str && str.length >= 1) {
        //         var firstChar = str.charAt(0);
        //         var remainingStr = str.slice(1);
        //         str = firstChar.toUpperCase() + remainingStr;
        //     }
        //     fields[name] = str;
        //     this.setState({ fields: fields });
        // }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key] && (this.props.docID ? this.returnMandatoryFields.indexOf(key) >= 0 : this.mandatoryFields.indexOf(key) >= 0)) {
                if ((key === 'beforeInspection' || key === 'insurance' || key === 'terms' || key === 'afterInspection') && _.isEmpty(fields[key])) {
                    if ((key === 'insurance' || key === 'terms') && this.includeInsWaiverInTDTerms)
                        errors['terms'] = errorClass;
                    else
                        errors[key] = errorClass;
                }

                if ((key === 'roNumber') && _.isEmpty(fields[key]) && !_.isEmpty(fields['contact']) && fields['loanType'] === 'Service') {
                    errors[key] = errorClass;
                }

                if (key !== 'roNumber' && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key]))) {
                    errors[key] = errorClass;
                }
            }
            else {
                errors[key] = '';
            }
        }
        this.setState({ errors: errors });
    }



    onCurrencyChange(name, e) {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    //#endregion

    //#region SAVE & SUBMIT 
    handleLoanSubmit = async (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        const { stock } = this.props;

        for (let [key] of Object.entries(fields)) {

            if (this.mandatoryFields.indexOf(key) >= 0) {

                if ((key === 'beforeInspection' || key === 'insurance' || key === 'terms') && _.isEmpty(fields[key])) {
                    formIsValid = false;
                    if ((key === 'insurance' || key === 'terms') && this.includeInsWaiverInTDTerms) errors['terms'] = errorClass;
                    else errors[key] = errorClass;
                }

                if ((key === 'roNumber') && _.isEmpty(fields[key]) && !_.isEmpty(fields['contact']) && fields['loanType'] === 'Service') {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

                if (key !== 'roNumber' && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key]))) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

            }
        }

        // if (fields.startDate && fields.expectedReturn && (moment(fields.startDate)._d > moment(fields.expectedReturn)._d)) {
        //     formIsValid = false;
        //     errors.expectedReturn = errorClass;
        //     Swal.fire('Expected return date should be greater than Start date', '', 'info')
        // }

        if (this.state.isContact && _.isEmpty(fields.contact)) {
            formIsValid = false;
            errors.contact = errorClass;
        }

        if (this.state.isContact === false && _.isEmpty(fields.staff) && !_.isEmpty(stock) && !stock.isServiceLoan) {
            formIsValid = false;
            errors.staff = errorClass;
        }



        this.setState({ errors: errors });
        if (formIsValid === true) {

            const { stockBookings } = this.state;
            if (!_.isEmpty(stockBookings) && fields.startDate && fields.expectedReturn) {

                const { clientUsers, dealersettings } = this.props;
                let _startDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.startDate)._d);
                let _endDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.expectedReturn)._d);
                let _recordID = fields.serviceID ? fields.serviceID : fields.documentID;
                if (await CommonHelper.checkAppointments(
                    stockBookings.filter(a => a.recordID !== _recordID),
                    _startDate,
                    _endDate,
                    formIsValid,
                    clientUsers, dealersettings))
                    this.saveFleet();
            }
            else
                this.saveFleet();
        }
        else
            return formIsValid;

    };

    saveFleet = () => {
        this.isLoading = true;
        const { dealersettings } = this.props;
        const objData = Object.assign({}, this.state.fields);

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = '';

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (objData.startDate)
            objData.startDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.startDate)._d);

        if (objData.endDate)
            objData.endDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.endDate)._d);

        if (objData.expectedReturn)
            objData.expectedReturn = window.firebase.firestore.Timestamp.fromDate(moment(objData.expectedReturn)._d);

        objData.status = fleetStatus.PENDING;
        objData.endFuelGauge = objData.startFuelGauge;
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //delete objData['stock'];

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        let tradePlate = objData.tradePlate ? objData.tradePlate : '';
        if (objData.hasOwnProperty('tradePlate') && objData.stock) {
            objData.stock['tradePlate'] = tradePlate;
        }
        if (this.props.isFromLoanBookings) {
            objData.isFromLoanBooking = true;
        }
        delete objData['tradePlate'];

        const updateRef = firestoreDB(dealersettings).firestore().collection('fleet').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            this.isLoading = false;
            toast.notify('Loan vehicle saved successfully', {
                duration: 2000
            })
            objData.tradePlate = tradePlate;
            this.addFleetDetailInStock(objData);
            CommonHelper.saveLoanAppointment(objData, 'loan', dealersettings);
            this.handleModalClose(this.newDocID, objData);


        }).catch((error) => {
            console.error("Error updating loan_details: ", error);
        });

    };



    handleLoanReturnSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            if (this.returnMandatoryFields.indexOf(key) >= 0) {

                if ((key === 'afterInspection') && _.isEmpty(fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

            }
        }

        if (!fields.endDate) {
            formIsValid = false;
            errors.endDate = errorClass
        }

        if (fields.startDate && fields.endDate && (moment(fields.startDate)._d > moment(fields.endDate)._d)) {
            formIsValid = false;
            errors.endDate = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'Return date should be greater than Start date'), '', 'info')
        }

        if (fields.startOdometer && fields.endOdometer && parseInt(fields.startOdometer) > parseInt(fields.endOdometer)) {
            formIsValid = false;
            errors.endOdometer = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'End odometer reading should be equal or greater than the current odometer reading'), '', 'info')
        }

        this.setState({ errors: errors });
        if (formIsValid === true) {

            // const { stockBookings } = this.state;
            // if (formIsValid && !_.isEmpty(stockBookings) && fields.startDate && fields.endDate) {
            //     const { clientUsers } = this.props;
            //     let _startDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.startDate)._d);
            //     let _endDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.endDate)._d);
            //     let _recordID = fields.serviceID ? fields.serviceID : fields.documentID;
            //     if (await CommonHelper.checkAppointments(
            //         stockBookings.filter(a => a.recordID !== _recordID),
            //         _startDate,
            //         _endDate,
            //         formIsValid,
            //         clientUsers))
            //         this.returnFleet();

            // }
            // else
            this.returnFleet();
        }
        else
            return formIsValid;

    };

    returnFleet = () => {
        this.isLoading = true;

        const objData = Object.assign({}, this.state.fields);

        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (objData.startDate)
            objData.startDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.startDate)._d);

        if (objData.endDate)
            objData.endDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.endDate)._d);

        if (objData.expectedReturn)
            objData.expectedReturn = window.firebase.firestore.Timestamp.fromDate(moment(objData.expectedReturn)._d);

        objData.status = fleetStatus.COMPLETE;

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //delete objData['stock'];

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        let tradePlate = objData.tradePlate ? objData.tradePlate : '';
        if (objData.hasOwnProperty('tradePlate') && objData.stock) {
            objData.stock['tradePlate'] = tradePlate;
        }
        delete objData['tradePlate'];

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('fleet').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            this.isLoading = false;
            toast.notify('Return vehicle saved successfully', {
                duration: 2000
            })
            objData.tradePlate = tradePlate;
            this.removeFleetDetailFromStock(objData);
            CommonHelper.deleteLoanAppointments(this.newDocID, 'loan', this.props.dealersettings);
            this.handleModalClose(this.newDocID, objData);

            if (this.props.setNotifyClose)
                this.props.setNotifyClose(true);

        }).catch((error) => {
            console.error("Error updating testDrive_details: ", error);
        });

    };

    addFleetDetailInStock = (obj) => {
        const { isContact } = this.state;
        if (obj.stockID) {
            var objFleetDetail = {
                fleetID: obj.documentID,
                clientID: obj.clientID ? obj.clientID : null,
                projectId: obj.projectId ? obj.projectId : null,
                status: obj.status, //fleetStatus
                contact: ((isContact && !_.isEmpty(obj.contact))
                    ? obj.contact
                    : null),
                staff: ((!isContact && !_.isEmpty(obj.staff))
                    ? obj.staff
                    : null),
                addedBy: obj.modifiedBy,
                addedDate: obj.modifiedDate,
                addedFrom: 'web',
                startDate: obj.startDate,
                expectedReturn: obj.expectedReturn ? obj.expectedReturn : null,
                loanType: obj.loanType ? obj.loanType : 'Service',
                roNumber: obj.roNumber ? obj.roNumber : ''
            }
            Object.keys(objFleetDetail).forEach(key => objFleetDetail[key] === undefined ? delete objFleetDetail[key] : {});
            var objDetail = {
                status: stockStatus.ONLOAN,
                enquiryID: null,
                tradePlate: obj.tradePlate,
                statusModifiedBy: obj.modifiedBy,
                statusModifiedFrom: 'web',
                fleetDetail: objFleetDetail,
                modifiedBy: obj.modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: obj.modifiedDate,
            }

            if (obj.startOdometer)
                objDetail.miles = parseInt(obj.startOdometer);
            Object.keys(objDetail).forEach(key => objDetail[key] === undefined ? delete objDetail[key] : {});
            const updateRef = this.refStock.doc(obj.stockID);
            updateRef.set(objDetail, { merge: true }).then((docRef) => {

            }).catch((error) => {
                console.error("Error updating stock_details: ", error);
            });
        }
    }

    removeFleetDetailFromStock = (obj) => {
        if (obj.stockID || obj?.stock?.documentID) {
            let stockID = obj.stockID ? obj.stockID : (obj?.stock?.documentID ? obj.stock.documentID : null)
            this.updateStock(obj, stockID);
        }
        else {
            window.firebase.firestore().collection('stock')
                .where('fleetDetail.fleetID', '==', obj.documentID)
                .limit(1).get()
                .then(response => {
                    if (response && response.size > 0) {
                        const stockID = response.docs[0].id;
                        this.updateStock(obj, stockID);
                        firestoreDB(this.props.dealersettings).firestore().collection('fleet').doc(obj.documentID).set({ stockID: stockID }, { merge: true });
                    }
                });
        }
    }

    updateStock = (obj, _stockID) => {
        if (_stockID) {
            const updateRef = this.refStock.doc(_stockID);

            var objDetail = {
                status: stockStatus.AVAILABLE,
                enquiryID: null,
                tradePlate: obj.tradePlate,
                statusModifiedBy: obj.modifiedBy,
                statusModifiedFrom: 'web',
                fleetDetail: null,
                modifiedBy: obj.modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: obj.modifiedDate,
            }

            if (obj.endOdometer)
                objDetail.miles = parseInt(obj.endOdometer);
            Object.keys(objDetail).forEach(key => objDetail[key] === undefined ? delete objDetail[key] : {});
            updateRef.set(objDetail, { merge: true })
        }
    }

    handleModalClose = (ID, objData) => {
        this.setState({ fields: Object.assign({}, objFleet) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData);
    };

    handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    handleViewReport = () => {
        const { documentID, enquiryID } = this.state.fields;
        const url = `/fleet/details/${documentID}`;
        window.open(url, '_blank');
    }

    //#endregion

    //#region SIGN_REQUEST

    handleTermsClick = () => {
        const { docID } = this.props;
        const { fields } = this.state;

        if (fields.contact && fields.contact.documentID && _.isEmpty(docID) && _.isEmpty(fields.terms))
            this.setState({ showTermsOption: true })
        else
            this.setState({ showBeforeTerms: true })
    }
    handleTermsChange = (type) => {

        if (type === 'sign') {
            this.setState({ showBeforeTerms: true })
        }
        else if (type === 'send') {
            const { requestsTerms, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsTerms) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showBeforeTerms: true })
                    return;
                }
                let _fleetTC = CommonHelper.getTermsByField(dealersettings?.client, 'fleetTC', _contact?.language);
                objData.title = 'Loan Vehicle Terms & Conditions';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_fleetTC) ? _fleetTC : ''
                }

                if (this.includeInsWaiverInTDTerms === true) {
                    let _insuranceTC = CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', _contact?.language);
                    objData.insuranceTerms = { 'termsAndConditions': !_.isEmpty(_insuranceTC) ? _insuranceTC : '' }
                    objData.isMandatoryInsurance = this.mandatoryFields?.indexOf('insurance') >= 0 ? true : false
                    objData.insuranceConsent = false
                }
                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeTerms = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsTerms: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeTerms && this.unsubscribeTerms();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.terms = _signRequest.terms;
                                    fields.terms['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    if (_signRequest.insuranceConsent === true && _signRequest.insuranceTerms) {
                                        fields.insurance = _signRequest.insuranceTerms;
                                        fields.insurance['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    }
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }

        this.setState({ showTermsOption: false })

    }


    handleInsuranceClick = () => {
        const { docID } = this.props;
        const { fields } = this.state

        if (fields.contact && fields.contact.documentID && _.isEmpty(docID) && _.isEmpty(fields.insurance))
            this.setState({ showInsuranceOption: true })
        else
            this.setState({ showBeforeInsurance: true })
    }
    handleInsuranceChange = (type) => {

        if (type === 'sign') {
            this.setState({ showBeforeInsurance: true })
        }
        else if (type === 'send') {
            const { requestsInsurance, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsInsurance) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showBeforeInsurance: true })
                    return;
                }

                let _insuranceTC = CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', _contact?.language);
                objData.title = 'Insurance Terms & Conditions';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_insuranceTC) ? _insuranceTC : ''
                }


                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeInsurance = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsInsurance: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeInsurance && this.unsubscribeInsurance();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.insurance = _signRequest.terms;
                                    fields.insurance['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }
        this.setState({ showInsuranceOption: false })
    }


    handleSignClick = () => {
        const { fields } = this.state

        if (fields.contact && fields.contact.documentID && _.isEmpty(fields.afterSignatureURL))
            this.setState({ showSignOption: true })
        else
            this.setState({ showAfterSignature: true })
    }
    handleSignChange = (type) => {

        if (type === 'sign') {
            this.setState({ showAfterSignature: true })
        }
        else if (type === 'send') {
            const { requestsSign, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsSign) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();
                const _selectedStock = !_.isEmpty(this.props.stock) ? this.props.stock : this.state.fields.stock;

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showAfterSignature: true })
                    return;
                }

                objData.title = 'Sign Loan ' + (_selectedStock.make ? _selectedStock.make : '') + ' ' + (_selectedStock.model ? _selectedStock.model : '');
                objData.type = 'signOnly';
                //objData.testdriveId = this.newDocID;


                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeSign = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsSign: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeSign && this.unsubscribeSign();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.afterSignatureURL = _signRequest.signature;
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }
        this.setState({ showSignOption: false })
    }

    //#endregion

    //#endregion

    render() {
        const { show, docID, stock, dealersettings, clientUsers, groupUsers, selectedClientID } = this.props;
        const { isContact, showContacts, showUsers, notesPopOver, contactModal, staffModal, showHistory } = this.state;
        const { documentID,
            contact, staff,
            startDate, startOdometer, roNumber,
            endDate, endOdometer, expectedReturn,
            beforeInspection, afterInspection, loanType,
            purpose, startFuelGauge, endFuelGauge, insurance,
            afterSignatureURL, notes, terms, status, tradePlate,
            odometerHistory, stockID, clientID
        } = this.state.fields;



        const returnVehicle = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.returnVehicle) ? true : false);

        const _selectedStock = !_.isEmpty(stock) ? stock : this.state.fields.stock;

        const isPendingReturn = (docID && status === fleetStatus.COMPLETE && _selectedStock?.status === stockStatus.ONLOAN && _selectedStock?.fleetDetail?.fleetID === docID) ? true : false
        const isReturn = docID ? true : false;
        const isComplete = isPendingReturn ? false : (status === fleetStatus.COMPLETE) ? true : false;

        const { requestsTerms, requestsInsurance, requestsSign, showTermsOption, showInsuranceOption, showSignOption } = this.state;
        const isTermsRequested = requestsTerms && requestsTerms.status === 'requested' ? true : false;
        const isInsuranceRequested = requestsInsurance && requestsInsurance.status === 'requested' ? true : false;
        const isSignRequested = requestsSign && requestsSign.status === 'requested' ? true : false;
        const stockLocations = dealersettings?.client?.clientSettings?.tdStockLocations || dealersettings?.client?.clientSettings?.stockLocations;
        return (

            this.newDocID
                ?
                <>
                    <Modal
                        id={`add-fleet-${this.newDocID}`}
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName={`modal-dialog-scrollable modal-dialog-centered modal-add-fleet ${this.props.isFromLoanBookings ? 'addfleet-tag-fix' : ''}`}>

                        <Modal.Header closeButton>
                            {this.props.isFromLoanBookings ? (
                                <Modal.Title>
                                    <h5 className='modal-title'>
                                        <Translate text={'loanVehicle'} />
                                        <div className="lead-source-badge" style={{ marginTop: '5px' }}>
                                            <div className="badge badge-pill badge-white">
                                                {CommonHelper.getNameByValue(dealersettings.integrations, 'adtorqueedgeloan')}
                                            </div>
                                        </div>
                                    </h5>
                                </Modal.Title>
                            ) : (
                                <Modal.Title>
                                    <h5 className="modal-title" >
                                        <Translate text={'loanVehicle'} />
                                    </h5>
                                </Modal.Title>
                            )}
                            {
                                clientID && (!_.isEmpty(stockLocations) || clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                    (<div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{CommonHelper.getOtherDealerName(dealersettings, clientID)}</div></div>)
                                    : (<></>)
                            }
                        </Modal.Header>
                        <Modal.Body>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className={`col-md-4 mb-2 ${isReturn ? 'form-disable' : ''}`}>
                                        <div className="add-fleet-left form-style">

                                            <h3><Translate text={'vehicleDetails'} /></h3>

                                            <div className="vehicle-fleet-list mt-1 mb-3">
                                                <div className="vehicle-fleet-image">
                                                    {
                                                        (!_.isEmpty(_selectedStock.images))
                                                            ?
                                                            <a data-fancy-box={`testdrive_img${documentID}`} 
                                                            href={_selectedStock.images[0]} 
                                                            onClick={(e) => { e.preventDefault(); 
                                                                this.setState({
                                                                    imageViewer: {
                                                                        show: true,
                                                                        images: _selectedStock.images
                                                                    }
                                                                });
                                                            }}>
                                                                <img src={_selectedStock.images} width="70" height="70" alt="" className="img-object-fit" />
                                                            </a>
                                                            :
                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.showBrandLogo(dealersettings, _selectedStock.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                            </a>
                                                    }
                                                </div>
                                                <div className="vehicle-fleet-data">
                                                    <div className="vehicle-fleet-title">{_selectedStock.make} {_selectedStock.model}</div>
                                                    <div className="vehicle-fleet-info">{_selectedStock.extColor ? `${_selectedStock.extColor}, ` : ''} {_selectedStock.year ? _selectedStock.year : ''}</div>
                                                    {
                                                        _selectedStock.clientID && (selectedClientID || _selectedStock.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (
                                                            <div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, _selectedStock.clientID)}</strong></div>
                                                        ) : (<></>)
                                                    }
                                                    <div className="vehicle-item-status">
                                                        {_selectedStock.saleType ? <div className="badge badge-pill badge-white">
                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                _selectedStock.saleType, '')}
                                                        </div> : <></>}

                                                        {_selectedStock.status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, _selectedStock.status, '', 'ml-1') : <></>}
                                                    </div>
                                                    <div className="vehicle-item-status">
                                                        {
                                                            !_.isEmpty(_selectedStock.stockInDate)
                                                                ?
                                                                <div className="badge badge-pill status-stock-age">
                                                                    <Translate text={'stockAge'} />{' : '}
                                                                    {this.handleDiffDays(_selectedStock.stockInDate)}
                                                                </div>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'loanType'} /></label>
                                                <ReactSelect
                                                    options={loan_type}
                                                    name={"loanType"}
                                                    placeholder={'select loan type'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={loanType}
                                                    classNamePrefix={`${this.state.errors["loanType"]} basic-select`}
                                                    AddUnAvailable={true}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>

                                            <div className="form-group">
                                                <label ><Translate text={'regNo'} /> </label>
                                                <InputText
                                                    type="text"
                                                    name="regNo"
                                                    value={_selectedStock.regNo}
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    placeholder={'registration number'}
                                                    className={`form-control btn-disable`}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'tradePlate'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'trade plate'}
                                                    className={`form-control ${this.state.errors["tradePlate"]}`}
                                                    name="tradePlate"
                                                    onChange={this.handleOnCapChange}
                                                    value={tradePlate ? tradePlate : ''}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'roNumber'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'ro number'}
                                                    className={`form-control ${this.state.errors["roNumber"]}`}
                                                    name="roNumber"
                                                    onChange={this.handleOnCapChange}
                                                    value={roNumber ? roNumber : ''}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'currentOdometerReading'} /></label>
                                                <NumberFormat
                                                    id={'startOdometer'}
                                                    thousandSeparator={true}
                                                    placeholder={'current odometer reading'}
                                                    className={`form-control ${this.state.errors["startOdometer"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'startOdometer')}
                                                    value={startOdometer ? startOdometer : null}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'purposeOfTheJourney'} /></label>
                                                <InputTextArea
                                                    className={`form-control notes ${this.state.errors["purpose"]}`}
                                                    name="purpose"
                                                    onChange={this.handleOnChange}
                                                    value={purpose}
                                                    maxLength="500"
                                                    rows="3">
                                                </InputTextArea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8 pl-0 form-style">

                                        <div className={`add-fleet-right ${isReturn ? 'form-disable' : ''}`} >

                                            <h3> <Translate text={isContact ? 'Contact Details' : 'Staff Details'} /></h3>

                                            {
                                                !_.isEmpty(_selectedStock) && _selectedStock.isServiceLoan
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                        <div className="float-right radio-contact">
                                                            <div className="radio icheck-success float-left">
                                                                <InputRadio
                                                                    id="Contact"
                                                                    type="radio"
                                                                    value="Contact"
                                                                    name="isContact"
                                                                    checked={isContact === true ? true : false}
                                                                    onChange={this.handleTypeChange}
                                                                />
                                                                <label htmlFor="Contact"><Translate text={'contact'} /></label>
                                                            </div>
                                                            {this.props.isFromLoanBookings ? (<></>) : (
                                                                <div className="radio icheck-success float-left ml-3">
                                                                    <InputRadio
                                                                        id="Staff"
                                                                        type="radio"
                                                                        value="Staff"
                                                                        name="isContact"
                                                                        checked={isContact === false ? true : false}
                                                                        onChange={this.handleTypeChange}
                                                                    />
                                                                    <label htmlFor="Staff"><Translate text={'staff'} /></label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                            }



                                            {
                                                (!_.isEmpty(contact) || !_.isEmpty(staff))
                                                    ?
                                                    <div className={`fleet-customer`}>
                                                        {
                                                            (!_.isEmpty(contact))
                                                                ?
                                                                <div className="fleet-customer-inner">
                                                                    {isReturn || this.props.isFromLoanBookings ? <></> : <a href="#" className="fleet-close" onClick={(e) => { e.preventDefault(); this.handleRemoveContact(); }}><i className="ico icon-remove"></i></a>}
                                                                    <div className="inner-left">
                                                                        {CommonHelper.displayContactIcon(contact, '')}
                                                                    </div>
                                                                    <div className="inner-right">
                                                                        <div className="fleet-customer-head"> {CommonHelper.displayContact([], contact, '--')}</div>
                                                                        {
                                                                            (contact.businessContact && !_.isEmpty(contact.company))
                                                                                ?
                                                                                <div className="input-readonly-sub">
                                                                                    {/* <i className={`ico icon-Contacts`}></i> */}
                                                                                    {CommonHelper.getFullName([], contact, '')}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group
                                                                                ?
                                                                                <div className="input-readonly-sub blue-color">{CommonHelper.getOtherDealerName(dealersettings, contact.clientID)}</div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            contact.displayID
                                                                                ?
                                                                                <div className="input-readonly-sub"><Translate text={'contactID'} />:  {contact.displayID}</div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        <div className="input-readonly-sub"> <Translate text={'licenseNo'} />: {contact.licenseNo ? contact.licenseNo : '--'}</div>
                                                                        {
                                                                            contact.licenseExpiry
                                                                                ?
                                                                                <div className="input-readonly-sub"><Translate text={'licenseExpiry'} />:  {moment(contact.licenseExpiry).format('DD MMM YYYY')}</div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            contact.isDLScan
                                                                                ?
                                                                                <div className="badge badge-pill badge-white mt-1">
                                                                                    <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                                                    {`Digital Driving Licence`}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            !_.isEmpty(contact.terms)
                                                                                ?
                                                                                <div className="badge badge-pill badge-white mt-1">
                                                                                    <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                                                    {`Customer Consent ${contact.terms.verbalConsent ? '(Verbal)' : (contact.terms.consentType ? `(${contact.terms.consentType})` : '')}`}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="fleet-customer-inner">

                                                                    {
                                                                        isReturn ? <></> :
                                                                            <>
                                                                                <div className="staffedit-button">
                                                                                    <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); this.handleStaffOpen(staff.documentID, null, []) }}>
                                                                                        <i className="ico icon-edit"></i>
                                                                                    </a>
                                                                                </div>
                                                                                <a href="#" className="fleet-close" onClick={(e) => { e.preventDefault(); this.handleRemoveStaff(); }}><i className="ico icon-remove"></i></a>
                                                                            </>
                                                                    }
                                                                    <div className="inner-left"><i className="ico icon-Contacts"></i></div>
                                                                    <div className="inner-right">
                                                                        <div className="fleet-customer-head"> {staff.name}</div>
                                                                        <div className="input-readonly-sub"> <Translate text={'email'} /> : {staff.email ? staff.email : '--'}</div>
                                                                        {
                                                                            staff.licenseNo
                                                                                ?
                                                                                <div className="input-readonly-sub"><Translate text={'licenseNo'} />:  {staff.licenseNo}</div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            staff.licenseExpiry
                                                                                ?
                                                                                <div className="input-readonly-sub"><Translate text={'licenseExpiry'} />:  {moment(staff.licenseExpiry).format('DD MMM YYYY')}</div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className={`fleet-customer-search`}>
                                                        {
                                                            isContact
                                                                ?
                                                                <>
                                                                    <div className="testdrive-search">
                                                                        <button type="button" className={`btn btn-secondary float-left ${this.state.errors["contact"]}`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({ showContacts: true })
                                                                            }}>
                                                                            <i className="ico icon-search"></i> <Translate text={'searchFromExistingContacts'} />
                                                                        </button>
                                                                        <button type="button" className="btn btn-primary float-left ml-2" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleContactOpen(null, 'addContact');
                                                                        }}>
                                                                            <i className="ico icon-add"></i> <Translate text={'addContact'} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="testdrive-search">
                                                                        <button type="button" className={`btn btn-secondary float-left ${this.state.errors["staff"]}`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({ showUsers: true })
                                                                            }}>
                                                                            <i className="ico icon-search"></i> <Translate text={'searchFromExistingStaffs'} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                            }

                                        </div>


                                        <div className="add-fleet-middle">
                                            <div className="add-fleet-middle-head">
                                                <div className=" float-left">
                                                    <h3> <Translate text={'beforeLoan'} /></h3>
                                                </div>
                                                <div className=" float-right">

                                                    <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["beforeInspection"]}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ showBeforeInspection: true })
                                                    }}>
                                                        {!_.isEmpty(beforeInspection) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                        <Translate text={'inspection'} />
                                                    </button>


                                                    <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["terms"]}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleTermsClick();
                                                    }}>
                                                        {!_.isEmpty(terms) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                        {
                                                            isTermsRequested
                                                                ?
                                                                <>
                                                                    <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                    <Translate text={'Waiting for response'} />
                                                                </>
                                                                :
                                                                <><i className="ico icon-terms mr-1"></i><Translate text={'termsAndConditions'} /> </>
                                                        }

                                                    </button>

                                                    {
                                                        this.includeInsWaiverInTDTerms === false && (<button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["insurance"]}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleInsuranceClick();
                                                        }}>
                                                            {(!_.isEmpty(insurance)) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                            {
                                                                isInsuranceRequested
                                                                    ?
                                                                    <>
                                                                        <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                        <Translate text={'Waiting for response'} />
                                                                    </>
                                                                    :
                                                                    <><i className="ico icon-waiver mr-1"></i><Translate text={'insuranceWaiver'} /></>
                                                            }
                                                        </button>)}

                                                </div>

                                            </div>

                                            <div className="fleet-section-head"><h4><Translate text={'loanDuration'} /></h4></div>

                                            <div className={`fleet-form`}>
                                                <div className="form-row">
                                                    <div className={`form-group col-md-3 ${isReturn ? 'form-disable' : ''}`}>
                                                        <label ><Translate text={'startDate'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {/* <i className="bg-icons ico icon-date"></i> */}
                                                            {/* <ReactDatePicker
                                                                value={startDate ? moment(moment(startDate)).format('YYYY-MM-DD') : null}
                                                                name={'startDate'}
                                                                onChange={this.handleDateChange}
                                                                readOnly={true}
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["startDate"]}`}
                                                                minDate={moment()._d}
                                                                readOnly={true}
                                                            /> */}
                                                            <AntDatePicker
                                                                value={startDate ? moment(startDate).format('YYYY-MM-DD') : null}
                                                                name={'startDate'}
                                                                onChange={(e) => { this.handleDateChange(e, 'startDate') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["startDate"]}`}
                                                                disabledDate={(current) => { return current && current > moment().endOf('day') }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className={`form-group col-md-3 ${isReturn ? 'form-disable' : ''}`}>
                                                        <label ><Translate text={'startTime'} /></label>
                                                        <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={startDate ? startDate : null}
                                                                name={'startDate'}
                                                                onChange={this.handleTimeChange}
                                                                timeIntervals={15}
                                                                placeholder='HH:MM'
                                                                className={`form-control ${this.state.errors["startDate"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'expectedReturnDate'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {/* <i className="bg-icons ico icon-date"></i> */}
                                                            {/* <ReactDatePicker
                                                                value={expectedReturn ? moment(moment(expectedReturn)).format('YYYY-MM-DD') : null}
                                                                name={'expectedReturn'}
                                                                onChange={this.handleDateChange}
                                                                readOnly={true}
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                minDate={moment()._d}
                                                                readOnly={true}
                                                            /> */}
                                                            <AntDatePicker
                                                                value={expectedReturn ? moment(expectedReturn).format('YYYY-MM-DD') : null}
                                                                name={'expectedReturn'}
                                                                onChange={(e) => { this.handleDateChange(e, 'expectedReturn') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                disabledDate={this.minStartDate}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'expectedReturnTime'} /></label>
                                                        <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={expectedReturn ? expectedReturn : null}
                                                                name={'expectedReturn'}
                                                                onChange={this.handleTimeChange}
                                                                timeIntervals={15}
                                                                placeholder='HH:MM'
                                                                className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="fleet-section-head mt-2"><h4><Translate text={'beforeFuel'} /></h4></div>

                                            <div className={`fleet-fuel ${isReturn ? 'form-disable' : ''}`}>
                                                {/* <div className="fuel-icon"> <i className="bg-icons ico icon-fuel"></i></div> */}
                                                <div className="e-guage">E</div>
                                                <div className="fuel-bar">
                                                    <span className="half-guage">1/2</span>
                                                    <InputRange
                                                        id="startFuelGauge"
                                                        name="startFuelGauge"
                                                        type="range"
                                                        value={startFuelGauge}
                                                        onChange={e => this.handleFuelChange(e)}
                                                    />
                                                </div>
                                                <div className="f-guage">F</div>
                                            </div>
                                        </div>

                                        {
                                            docID
                                                ?
                                                <div className="add-fleet-middle fleet-divider">
                                                    <div className="add-fleet-middle-head">
                                                        <div className=" float-left">
                                                            <h3><Translate text={'afterLoan'} /> </h3>
                                                        </div>
                                                        <div className=" float-right">

                                                            <button className={`btn btn-sm btn-default ml-1 ${this.state.errors["afterInspection"]}`} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ showAfterInspection: true })

                                                            }}>
                                                                {!_.isEmpty(afterInspection) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i> <Translate text={'inspection'} />
                                                            </button>

                                                            <button className={`btn btn-sm btn-default ml-1 ${this.state.errors["afterSignatureURL"]}`} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleSignClick();

                                                            }}>
                                                                {afterSignatureURL ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                                {
                                                                    isSignRequested
                                                                        ?
                                                                        <>
                                                                            <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                            <Translate text={'Waiting for response'} />
                                                                        </>
                                                                        :
                                                                        <><i className="ico icon-terms mr-1"></i><Translate text={'signature'} /></>
                                                                }
                                                            </button>

                                                            <button className="btn btn-sm btn-default ml-1"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target } })
                                                                }}>
                                                                {notes ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                                <i className="ico icon-note mr-1"></i> <Translate text={'notes'} />
                                                            </button>

                                                            <button className="btn btn-sm btn-default ml-1"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showHistory: true })
                                                                }}>
                                                                {!_.isEmpty(odometerHistory) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                                <i className="ico icon-odometer-ico mr-1"></i> <Translate text={'Odometer History'} />
                                                            </button>

                                                        </div>
                                                    </div>

                                                    <div className="fleet-section-head"><h4><Translate text={'loanDuration'} /></h4></div>

                                                    <div className={`fleet-form ${isComplete ? 'form-disable' : ''}`}>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'returnDate'} /></label>
                                                                <div className="inner-addon right-addon">
                                                                    {/* <i className="bg-icons ico icon-date"></i> */}
                                                                    <AntDatePicker
                                                                        value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
                                                                        name={'endDate'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'endDate') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["endDate"]}`}
                                                                        disabledDate={this.minEndDate}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label > <Translate text={'returnTime'} /></label>
                                                                <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                                    <ReactTimePicker
                                                                        value={endDate ? endDate : null}
                                                                        name={'endDate'}
                                                                        onChange={this.handleTimeChange}
                                                                        timeIntervals={15}
                                                                        placeholder='HH:MM'
                                                                        className={`form-control ${this.state.errors["endDate"]}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'returnOdometerReading'} /></label>

                                                                <NumberFormat
                                                                    id={'endOdometer'}
                                                                    thousandSeparator={true}
                                                                    placeholder={'return odometer reading'}
                                                                    className={`form-control ${this.state.errors["endOdometer"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'endOdometer')}
                                                                    value={endOdometer ? endOdometer : null}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="fleet-section-head mt-2"><h4><Translate text={'afterFuel'} /></h4></div>

                                                    <div className={`fleet-fuel ${isComplete ? 'form-disable' : ''}`}>
                                                        {/* <div className="fuel-icon"> <i className="bg-icons ico icon-fuel"></i></div> */}
                                                        <div className="e-guage">E</div>
                                                        <div className="fuel-bar">
                                                            <span className="half-guage">1/2</span>
                                                            <InputRange
                                                                id="endFuelGauge"
                                                                name="endFuelGauge"
                                                                value={endFuelGauge}
                                                                onChange={e => this.handleEFuelChange(e)}
                                                            />
                                                        </div>
                                                        <div className="f-guage">F</div>
                                                    </div>

                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>


                            </div>

                        </Modal.Body>
                        <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                            {
                                isComplete
                                    ?
                                    <>
                                        <div className="map-view-icon">

                                            <button type="button" className="btn btn-default float-right" onClick={(e) => {
                                                e.preventDefault();
                                                this.handleViewReport();

                                            }}>
                                                <i className="ico icon-waiver mr-1"></i>
                                                <Translate text={'report'} />
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            docID
                                                ?
                                                <>
                                                    {
                                                        returnVehicle
                                                            ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary float-right"
                                                                    onClick={(e) => this.handleLoanReturnSubmit(e)}
                                                                >
                                                                    {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                    <Translate text={'returnVehicle'} /></button>

                                                                <button type="button" className="btn btn-default float-left" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleViewReport();

                                                                }}>
                                                                    <i className="ico icon-waiver mr-1"></i>
                                                                    <Translate text={'report'} />
                                                                </button>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <button
                                                    type="button"
                                                    className="btn btn-primary float-right"
                                                    onClick={(e) => this.handleLoanSubmit(e)}
                                                >
                                                    {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'loanVehicle'} /></button>
                                        }
                                    </>
                            }
                        </Modal.Footer>
                    </Modal>

                    <PopUpModal show={contactModal.showContact}>
                        <AddContact
                            docID={contactModal.docID}
                            show={contactModal.showContact}
                            clsActive={contactModal.clsContactActive}
                            handleClose={this.handleContactClose}
                            title={contactModal.contactTitle}
                            showSearch={contactModal.showContactSearch}
                            mandatoryFields={contactModal.mandatoryFields}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            selectedClientID={selectedClientID}
                        >
                        </AddContact>
                    </PopUpModal>

                    <PopUpModal show={staffModal.show}>
                        <EditUser
                            docID={staffModal.docID}
                            show={staffModal.show}
                            clsActive={staffModal.clsContactActive}
                            handleClose={this.handleStaffClose}
                            mandatoryFields={staffModal.mandatoryFields}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        >
                        </EditUser>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeInspection}>
                        <ImageCanvas
                            show={this.state.showBeforeInspection}
                            clsActive='overlay-modal active'
                            imagePath='loan'
                            marks={this.state.fields.beforeInspection}
                            handleClose={this.handleBeforeInspectionClose}
                            isReadOnly={isReturn}
                            dealersettings={dealersettings}
                            showCost={false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={this.state.showAfterInspection}>
                        <ImageCanvas
                            show={this.state.showAfterInspection}
                            clsActive='overlay-modal active'
                            imagePath='loan'
                            marks={this.state.fields.afterInspection}
                            readOnlyMarks={this.state.fields.beforeInspection}
                            handleClose={this.handleAfterInspectionClose}
                            isReadOnly={isComplete}
                            dealersettings={dealersettings}
                            showCost={false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeTerms}>
                        <Terms
                            show={this.state.showBeforeTerms}
                            clsActive='overlay-modal active'
                            imagePath='loan'
                            terms={this.state.fields.terms}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'fleetTC', contact?.language)}
                            includeInsWaiverInTDTerms={this.includeInsWaiverInTDTerms}
                            isMandatoryInsurance={this.mandatoryFields?.indexOf('insurance') >= 0 ? true : false}
                            insurance={insurance}
                            insuranceTC={CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', contact?.language)}
                            title={'termsAndConditions'}
                            isDisable={isReturn}
                            handleClose={this.handleBeforeTermsClose}
                            dealersettings={dealersettings}

                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeInsurance}>
                        <Terms
                            show={this.state.showBeforeInsurance}
                            clsActive='overlay-modal active'
                            imagePath='loan'
                            terms={this.state.fields.insurance}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', contact?.language)}
                            title={'insuranceWaiver'}
                            handleClose={this.handleBeforeInsuranceClose}
                            isDisable={(isReturn) ? true : false}
                            dealersettings={dealersettings}
                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={this.state.showAfterSignature}>
                        <AfterSignature
                            show={this.state.showAfterSignature}
                            imagePath='loan'
                            clsActive='overlay-modal active'
                            signatureURL={this.state.fields.afterSignatureURL}
                            handleClose={this.handleAfterSignatureClose}
                            isDisable={isComplete}
                            dealersettings={dealersettings}
                        ></AfterSignature>
                    </PopUpModal>

                    <PopUpModal show={showContacts}>
                        <Contacts
                            show={showContacts}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleContactClose={this.handleContactClose}
                            showAdd={false}
                            clientID={selectedClientID}
                        />
                    </PopUpModal>

                    <PopUpModal show={showUsers}>
                        <Users
                            show={showUsers}
                            clientUsers={!_.isEmpty(stockLocations) ? groupUsers.filter(v => stockLocations.includes(v.clientID)) : (selectedClientID ? groupUsers.filter(v => v.clientID === selectedClientID) : clientUsers)}
                            groupUsers={groupUsers}
                            dealersettings={dealersettings}
                            handleUserClose={this.handleStaffClose}
                            title='staff'
                            showAdd={false}
                            showQueue={false}
                        />
                    </PopUpModal>

                    <PopUpModal show={showHistory}>
                        <OdometerHistorys
                            show={showHistory}
                            currentOdometer={startOdometer}
                            historys={odometerHistory}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            docID={this.newDocID}
                            stockID={stockID ? stockID : (_selectedStock ? _selectedStock.documentID : null)}
                            handleClose={this.handleHistoryClose}
                            isDisable={isComplete}
                        />
                    </PopUpModal>

                    <PopoverPanel
                        showpopover={notesPopOver.showpopover}
                        targetpopover={notesPopOver.targetpopover}
                        title="Notes"
                        position="bottom"
                        id="notes-fleet"
                        className="popover-pipeline-switch"
                        closepopover={() => {
                            this.setState({
                                notesPopOver: { showpopover: false, targetpopover: '' }
                            });
                        }}>
                        <AddNotes
                            notes={this.state.fields.notes}
                            handleClose={this.handleNotesClose}
                            isDisable={isComplete}
                        />
                    </PopoverPanel>

                    <PopUpModal show={showTermsOption}>
                        <SignOptions
                            show={showTermsOption}
                            menu={isTermsRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleTermsChange}
                        />
                    </PopUpModal>

                    <PopUpModal show={showInsuranceOption}>
                        <SignOptions
                            show={showInsuranceOption}
                            menu={isInsuranceRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleInsuranceChange}
                        />
                    </PopUpModal>

                    <PopUpModal show={showSignOption}>
                        <SignOptions
                            show={showSignOption}
                            menu={isSignRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleSignChange}
                        />
                    </PopUpModal>
                    <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
                </>
                :
                <></>

        );
    }
}