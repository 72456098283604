import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from './pipelineCounts'
import { PopUpModal, SidePanel } from '../../components'
import ServiceList from './serviceList'
import { initialLayoutArr } from './viewModel';
import DragDropLayout from './dragDropLayout';
import QuickView from "../service/quickview";

const defaulModelState = {
    enquiry: false
}

const Dashboard = (props) => {
    const [loader, setLoader] = useState(false)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedPipeline, setPipeline] = useState();
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard ? { value: props.dealersettings.id } : null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [applydateRange, setApplyDateRange] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [refreshActivity, setRefreshActivity] = useState(false)
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState([])

    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-service-quickview' })

    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashboardSettings/${localStorage.uid}`);

    const _pipeline = props.dealersettings.client.services && props.dealersettings.client.services.pipelines;
    // useEffect(() => {
    //     if (ownerDashboard)
    //         setUser({ value: props.dealersettings.id })
    // }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (!_.isEmpty(data && data.serviceDashboardLayout)) {
                setLayout(data.serviceDashboardLayout)
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleShowEnquiryList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Service List')
        setModelShow({
            ...defaulModelState,
            enquiry: true,
            counts
        })
    }

    const onLayoutChange = async (_layout) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.map((rec, index) => {
            return {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: 11,
                minW: rec.i === 'RevenueCounts' ? 8 : 4,
                h: (rec.h === 1 || rec.h === undefined) ? 11 : rec.h,
                w: (rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({ serviceDashboardLayout: _dLayout }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const reloadData = () => {
        console.log('reloadData, refresh data start')
        setTimeout(() => {
            setRefreshData(!refreshData)
        }, 1000);
    }

    const reloadActivity = () => {
        // console.log('isDone true, refresh data start')
        setTimeout(() => {
            setRefreshActivity(!refreshActivity)
        }, 1000);
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            serviceid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            serviceid: val
        })
    }
    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        pipeline={_pipeline}
                        handleShowEnquiryList={handleShowEnquiryList}
                        pipelineEnquiries={[]}
                        selectedPipeline={selectedPipeline}
                        selectedUser={selectedUser}
                        dateRange={dateRange}
                        applydateRange={applydateRange}
                        setPipeline={(e) => {
                            setPipeline(e)
                        }}
                        setUser={(e) => {
                            setUser(e)
                        }}
                        handleDateRangeChange={(val) => {
                            if (val) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                        refreshData={refreshData}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {/* GRID STARTS 
                        1. LeadsByAppointmentType
                        2. JobCounts
                        3. LeadsByStatus
                        4. LeadsByServiceType
                    */}
                    {layoutLoader ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border loader-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : <>
                            {<DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                pipeline={_pipeline}
                                handleShowEnquiryList={handleShowEnquiryList}
                                selectedPipeline={selectedPipeline}
                                selectedUser={selectedUser}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                initialLayout={layout}
                                ownerDashboard={false}
                                reloadData={reloadData}
                                sidepanelOpen={sidepanelOpen}
                                refreshData={refreshData}
                            />}
                        </>
                    }

                </div>

            </div>
            <PopUpModal show={modelShow.enquiry}>
                <ServiceList
                    {...props}
                    pipeline={_pipeline}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={[]}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    reloadData={reloadData}
                ></ServiceList>
            </PopUpModal>



            <SidePanel
                clsActive={showpanel.clsActive}
                clsName={showpanel.clsName}
                sidepanelClose={sidepanelClose}
                title=''
            >
                <QuickView {...props}
                    serviceid={showpanel.serviceid}
                    isFilterApplied={true}
                    updateListEnquiry={reloadData}
                    sidepanelClose={sidepanelClose}
                />
            </SidePanel>



        </div>
    )
}

export default Dashboard