import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { PieChart } from '../../../../components'

const LeadSource = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [reportData, setReportData] = useState();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? moment(_date) : moment();

    const [dateRange, setDateRange] = useState({
        startDate: moment(_date).startOf('month'),
        endDate: moment(_date)
    })

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setClient({
                        ...doc.data(),
                        documentID: doc.id
                    });
                }
            })
    }, [])


    useEffect(() => {
        if (_.isEmpty(client))
            return

        let makeSaleType = [];
        makeSaleType.push({ id: 'bmw-new', title: 'MTD - BMW NEW', saleType: 'new', notEqual: ['mini'] });
        makeSaleType.push({ id: 'bmw-used', title: 'MTD - BMW USED', saleType: 'used', notEqual: ['mini'] });
        makeSaleType.push({ id: 'mini-all', title: 'MTD - MINI', make: 'mini', saleType: 'all' });

        let enquiryTypes = [];
        enquiryTypes.push({ name: 'Internet', value: ['web', 'internet', 'website'] })
        enquiryTypes.push({ name: 'Phone', value: ['telephone inbound', 'inbound', 'phone'] })
        enquiryTypes.push({ name: 'Walk In', value: ['walk in', 'walk'] })

        const getreportData = window.firebase.functions().httpsCallable('generic-getData');
        getreportData({
            type: 'getSummaryReportbyUsers',
            params: JSON.stringify({
                clientID: client.documentID,
                startDate: dateRange.startDate.format('YYYY-MM-DD'),
                endDate: dateRange.endDate.format('YYYY-MM-DD')
            })
        }).then((response) => {
            if (response.data.success) {
                let _data = response.data.data;
                let _reportData = [];
                makeSaleType.forEach(make => {
                    enquiryTypes.forEach(enqType => {
                        _reportData.push({
                            id: make.id,
                            name: enqType.name,
                            title: make.title,
                            leads: _data.filter(a => a.col === 'enq'
                                && a.dt >= dateRange.startDate.unix()
                                && a.dt <= dateRange.endDate.unix()
                                && !_.isEmpty(a.et)
                                && enqType.value.some(b => b.toLowerCase() === a.et.toLowerCase())
                                && (!_.isEmpty(make.make) ? a.mk && make.make.toLowerCase() === a.mk.toLowerCase() : true)
                                && (!_.isEmpty(make.notEqual) ? a.mk && !make.notEqual.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
                                && (!_.isEmpty(make.notEqual) ? a.mk && !make.notEqual.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
                                && ((make.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
                                && ((make.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
                            ).length,
                            testdrives: _data.filter(a => a.col === 'td'
                                && a.dt >= dateRange.startDate.unix()
                                && a.dt <= dateRange.endDate.unix()
                                && !_.isEmpty(a.et)
                                && enqType.value.some(b => b.toLowerCase() === a.et.toLowerCase())
                                && (!_.isEmpty(make.make) ?a.mk && make.make.toLowerCase() === a.mk.toLowerCase() : true)
                                && (!_.isEmpty(make.notEqual) ? a.mk && !make.notEqual.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
                                && ((make.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
                                && ((make.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
                            ).length,
                            sold: _data.filter(a => a.col === 'enq'
                                && a.wd >= dateRange.startDate.unix()
                                && a.wd <= dateRange.endDate.unix()
                                && !_.isEmpty(a.et)
                                && enqType.value.some(b => b.toLowerCase() === a.et.toLowerCase())
                                && (!_.isEmpty(make.make) ? a.mk && make.make.toLowerCase() === a.mk.toLowerCase() : true)
                                && (!_.isEmpty(make.notEqual) ? a.mk && !make.notEqual.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
                                && ((make.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
                                && ((make.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
                            ).length,
                        })
                    })
                })
                _reportData = _reportData.map(rec => {
                    return {
                        ...rec,
                        leadtestdriveRatio: (rec.leads > 0 ? parseFloat(((100 / rec.leads) * rec.testdrives)).toFixed(2) : '0') + '%',
                        leadsaleRatio: (rec.leads > 0 ? parseFloat(((100 / rec.leads) * rec.sold)).toFixed(2) : '0') + '%',
                    }
                })
                //console.log(_reportData)
                setReportData(_.chain(_reportData)
                    .groupBy("id")
                    .map((value, key) => ({
                        key: key, data: value
                    }))
                    .value());
                setLoader(false)
            }

            else {
                setLoader(false)
            }
        })

    }, [client])



    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header report-data-loaded report-data-load">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`LEAD SOURCE REPORT - ${client.name.toUpperCase()}`} <br></br>
                                    <span> {_date.format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="chart-report-table">
                    <tbody>
                        <tr>
                            <td style={{ border: 'none !important', padding: '0 !important' }} >
                                {
                                    reportData.map((user, index) => {
                                        return (<div key={index} className="table-wraper" style={{ height: `1250px`, marginTop: `50px` }}>
                                            <div className="graph-chart-wrapper">
                                                <div className="graph-chart-left">
                                                    <div className="graph-chart-head">{`${user.data[0].title} ENQUIRY SPLIT BY SOURCE`}</div>
                                                    <div className="graph-chart-panel">
                                                        <PieChart
                                                            id={`${user.key}-enquiry`}
                                                            height={'350px'}
                                                            data={_.map(user.data, _.partialRight(_.pick, ['name', 'leads'])).map(r => { return { ...r, count: r.leads } })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="graph-chart-right">
                                                    <div className="graph-chart-head">{`${user.data[0].title} SALES SPLIT BY SOURCE`}</div>
                                                    <div className="graph-chart-panel">
                                                        <PieChart
                                                            id={`${user.key}-sales`}
                                                            height={'350px'}
                                                            data={_.map(user.data, _.partialRight(_.pick, ['name', 'sold'])).map(r => { return { ...r, count: r.sold } })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="summary-table-wraper-head">{`${user.data[0].title} CONVERSION BY SOURCE`}</div>
                                            <table className="summary-table-loop">
                                                <tbody>
                                                    <tr key={index} >
                                                        <th className="cell-head td-head" width="30%">ENQUIRY TYPE</th>
                                                        <td className="td-subhead" width="10%">LEADS</td>
                                                        <td className="td-subhead" width="10%">TEST DRIVES</td>
                                                        <td className="td-subhead" width="20%">LEAD TO TEST DRIVE RATIO</td>
                                                        <td className="td-subhead" width="10%">SOLD </td>
                                                        <td className="td-subhead" width="20%">LEAD TO SALE CONVERSION RATIO  </td>
                                                    </tr>
                                                    {
                                                        user.data.map((data, index) => {
                                                            return <tr key={index}>
                                                                <td className="td-head">{data.name}</td>
                                                                <td className="count-large">{data.leads}</td>
                                                                <td className="count-large">{data.testdrives}</td>
                                                                <td className="count-large">{data.leadtestdriveRatio}</td>
                                                                <td className="count-large">{data.sold}</td>
                                                                <td className="count-large">{data.leadsaleRatio}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    <tr>
                                                        <td className="td-head">Total</td>
                                                        <td className="count-large">{_.sumBy(user.data, 'leads')}</td>
                                                        <td className="count-large">{_.sumBy(user.data, 'testdrives')}</td>
                                                        <td className="count-large">{(_.sumBy(user.data, 'leads') > 0 ? parseFloat(((100 / _.sumBy(user.data, 'leads')) * _.sumBy(user.data, 'testdrives'))).toFixed(2) : '0') + '%'}</td>
                                                        <td className="count-large">{_.sumBy(user.data, 'sold')}</td>
                                                        <td className="count-large">{(_.sumBy(user.data, 'leads') > 0 ? parseFloat(((100 / _.sumBy(user.data, 'leads')) * _.sumBy(user.data, 'sold'))).toFixed(2) : '0') + '%'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ pageBreakAfter: 'always' }}></div>
                                        </div>)
                                    })
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="footer">
                    <div className="footer-bottom">

                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel : <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <>Web : <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className="footer-right"></div>

                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )

}

export default LeadSource;