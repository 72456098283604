/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/service-customer.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import Terms from '../../common/terms';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { InputRadio, ImageViewer, PopUpModal, LoaderOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import Reasons from './reason'
import { recommPartQuotes, recommendationChoice, recommendationOption, serviceStatus, service_type } from '../../../services/enum';
import { partQuotes } from '../viewModel'
//#region  ASSIGN VARIABLES


//#endregion

const ServiceCustomerApproval = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [servieData, setServieData] = useState({})
    const [loader, setLoader] = useState(false)
    const [isPDF, setPDF] = useState(false)
    const [inspectionIMG, setInspectionIMG] = useState(null)
    const [showApproval, setShowApproval] = useState(false)
    const [fullCost, setFullCost] = useState({
        total: 0,
        totalDiscount: 0,
        labourCost: 0,
        partsCost: 0,
        packagesCost: 0,
        servicePartsCost: 0,
        taxCost: 0,
        subTotal: 0,
        actualDiscount: 0,
        actualDiscountRecomm: 0,
        actualDiscountPack: 0,
        actualTotalCost: 0,
        actualSubTotal: 0
    })
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [showReasons, setShowReasons] = useState({
        show: false,
        id: '',
        type: ''
    })

    const {
        documentID, clientID, contactID, displayID,
        pipeline, stage, appointmentType, serviceAdvisor,
        //technician, 
        roNumber, status, brakeNotes, batteryNotes, tyreNotes,

        checkInDate, completionDate, appointmentDate, slot, timeOfDay,
        stageDate, stageHistory,
        isServicePackage, isContact, isVehicle, isInspection, isChecklist, isBbtChecklist, isFiles, isFav,
        totalServiceCost,
        images, marks, checklist,
        servicePackages, teammates, mentions, teams, notes,
        owner, checkInBy, addedBy, modifiedBy, addedDate, modifiedDate, modifiedFrom,
        isDelete, spokeTo, ownerResponse,
        jobAllocated, jobConfirmed, clientContactDate,
        discountType, discount,
        partsConfirmed, workshopConfirmed, statusHistory,
        contact, vehicle, client, users, recommendations, currentSettings,
        taxType, taxPercentage, serviceType, internalTaxExemption,
        disclaimer, userSign, serviceVideos
    } = servieData;

    const _isTaxIncluded = (serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType ? true : false;
    const _dispalyTax = _isTaxIncluded ? `(${taxType} Incl.)` : `(${taxType ? taxType : 'Tax'} Excl.)`

    const currencySymbol = ((!_.isEmpty(servieData) &&
        !_.isEmpty(servieData.client) &&
        !_.isEmpty(servieData.client.currency)) ? servieData.client.currency.symbol + ' ' : '$ ');

    const _settings = ((!_.isEmpty(servieData) &&
        !_.isEmpty(servieData.currentSettings) &&
        !_.isEmpty(servieData.currentSettings.services)) ? servieData.currentSettings.services : null);


    let _selectedPipeline = pipeline && !_.isEmpty(_settings) ? _settings.allPipelines.filter(item => item.value === pipeline)[0] : null;
    let statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;

    let reasons = [];
    _settings && _settings.reasons && _settings.reasons.forEach((doc) => {
        reasons.push({
            value: doc.name,
            active: _.isBoolean(doc.active) ? doc.active : true,
            label: doc.name
        });
    });

    const recommendationName = servieData?.client?.clientSettings?.service?.recommendationName;
    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`serviceJobs/${props.serviceid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('ServiceCustomerApproval', props.serviceid);
        if (props.serviceid) {
            loadServieData();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadServieData = async () => {

        //window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get()
        window.refCollectionData = docData(firestoreDB.firestore().doc(`serviceJobs/${props.serviceid}`))
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);
                    let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
                    const serviceIds = [props.serviceid];
                    const enqIds = _contactID ? [_contactID] : [];
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(contactID =>
                                collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
                                    map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/recommendations`)).pipe(
                                    map(recommendationSnapshot => recommendationSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/servicePackages`)).pipe(
                                    map(servicePackagesSnapshot => servicePackagesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/videos`).where('isDeleted', '==', false)).pipe(
                                    map(videosSnapshot => videosSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection(`clients/${clientID}/localizeData`)).pipe(
                                    map(localizeDataSnapshot => localizeDataSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                    map(usersSnapshot => usersSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, enquirySnapshot, recommendationSnapshot, servicePackagesSnapshot, videosSnapshot, clientSnapshot, clientSettingsSnapshot, localizeDataSnapshot, usersSnapshot,]) => {
                    let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
                    return {
                        ...tradeIns,
                        contact: _contactID ? enquirySnapshot.find(a => a.documentID === _contactID) : tradeIns.contact,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        users: usersSnapshot[0],
                        recommendations: recommendationSnapshot[0],
                        servicePackages: servicePackagesSnapshot[0],
                        currentSettings: clientSettingsSnapshot[0],
                        serviceVideos: videosSnapshot[0],
                        localizeData: localizeDataSnapshot[0]
                    }

                })
            )
            .subscribe(_tradeIns => {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let backURL = params.get('isView');

                setPDF(backURL ? false : true);

                if (!_.isEmpty(_tradeIns)) {

                    if (!_.isEmpty(_tradeIns.client) && !_.isEmpty(_tradeIns.localizeData)) {
                        _tradeIns.localizeData.forEach(rec => {
                            _tradeIns.client[rec.code] = rec;
                        })
                    }

                    handleCalculations(_tradeIns);
                    setInspection(_tradeIns.marks);
                    if (isLoading)
                        saveChangeLog(_tradeIns);
                    setIsLoading(false);
                }
                window.refCollectionData && window.refCollectionData.unsubscribe()
                //console.log('ServiceCustomerApproval_tradeIns', _tradeIns);

            });

    }

    const saveChangeLog = (_tradeIns) => {

        const newlogVM = Object.assign({});
        newlogVM.type = 'service';
        newlogVM.subType = 'added';
        newlogVM.logBy = _tradeIns.owner ? _tradeIns.owner : null;
        newlogVM.logDate = window.firebase.firestore.Timestamp.now();
        newlogVM.clientID = _tradeIns.clientID ? _tradeIns.clientID : null;
        //newlogVM.projectId = _tradeIns.projectId ? _tradeIns.projectId : null;
        newlogVM.recordId = props.serviceid ? props.serviceid : null;
        newlogVM.documentID = window.firebase.firestore().collection('changeLogs').doc().id;
        newlogVM.notes = 'Owner viewed the quotation from link';

        let _reqObject = {
            "documentID": newlogVM.documentID,
            "collection": `changeLogs`,
            "data": newlogVM
        }

        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject)
    }
    //region FOR INSPECTION IMAGES
    const setInspection = (_marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        var context = canvas.getContext('2d');

        var img = new Image();
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            setInspectionIMG(dataURL);
            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };
    //#endregion

    const handleRecommendationOkToDoChange = (e) => {
        const { name, value } = e.target;

        if (value === 'No' && !_.isEmpty(reasons.filter(m => m.active))) {
            setShowReasons({ show: true, id: name, type: 'recommendations' });
            return;
        }
        const _recommendations = Object.assign([], recommendations);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === name) {
                let recommendationDetail = Object.assign({}, obj.detail);
                recommendationDetail['oKToDo'] = value === 'Yes' ? true : false;
                recommendationDetail['reason'] = null;
                obj.detail = recommendationDetail;
            }
        });
        let ___services = {
            ...servieData,
            recommendations: _recommendations
        }
        handleCalculations(___services)

    }


    const handleApprovalClose = data => {

        if (!_.isEmpty(data)) {
            let okToDoCount = false;
            let _recommendationLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required))
            let recommendationz = [];

            _recommendationLists && _recommendationLists.forEach(_recommData => {
                const _recommendation = Object.assign({}, _recommData);
                let recommendationDetail = Object.assign({}, _recommendation.detail);

                let _objRecomm = {
                    'documentID': _recommData.documentID,
                    'detail.oKToDo': recommendationDetail.oKToDo,
                    'detail.reason': recommendationDetail.reason,
                }

                recommendationz.push(_objRecomm);

                if (recommendationDetail.option !== recommendationOption.warranty && recommendationDetail.oKToDo === true)
                    okToDoCount = true;
            })

            var objData = {
                status: serviceStatus.OWNERRESPONDED,
                documentID: props.serviceid,
                projectId: servieData?.projectId ? servieData.projectId : null,
                disclaimer: data.termsAndConditions,
                userSignB64: data.signature,
                recommendationLists: recommendationz,
                responseDate: window.firebase.firestore.Timestamp.now(),
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            if (okToDoCount || !_.isEmpty(servicePackages)) {
                objData.ownerResponse = serviceStatus.OWNERAPPROVED;
            }
            else {
                objData.ownerResponse = serviceStatus.OWNERDECLINED;
            }

            objData.totalServiceCost = getTotalServiceCost(servieData);

            objData = handleMoveStageForStatusChange(objData.ownerResponse, objData);
            //console.log('handleApprovalClose', objData)
            setLoader(true);
            const sendToCustomer = window.firebase.functions().httpsCallable('serviceapp-saveCustResp');
            sendToCustomer(objData).then((res) => {

                let response = res.data;
                //console.log('serviceapp-saveCustResp', res, '==>', response);
                setLoader(false);
                loadServieData();
                // if (response.success)
                //     toast.notify(`Service notification has send to ` + _title, { duration: 2000 });
            });






        }
        setShowApproval(false);
    }

    const handleMoveStageForStatusChange = (_status, state) => {

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }

            let _statusHistory = Object.assign([], statusHistory);
            _statusHistory.push({
                date: window.firebase.firestore.Timestamp.now(),
                status: _status,
                user: modifiedBy
            });
            state.statusHistory = Object.assign([], _statusHistory);

            return state;
        }
        else
            return state;
    }


    const handleSendNotification = (jobID, userPermission, title, body) => {

        let _title = title ? title.split(/(?=[A-Z])/).join(' ').toUpperCase() : (userPermission ? userPermission.split(/(?=[A-Z])/).join(' ').toUpperCase() : '--');
        let _postObject = {
            "jobID": jobID, //"qQOb8nx2hy1deJ6WdLHD",
            "clientID": clientID, //"ICVrcE79shbvg9cZb0my",
            "userPermission": userPermission,//"technicianRequested",
            "title": _title,
            "body": body ? body : _title,
            "type": "service", //notification type
        }


        //console.log('_postObject', _postObject);
        const sendToCustomer = window.firebase.functions().httpsCallable('serviceapp-notifyUser');
        sendToCustomer(_postObject).then((res) => {

            let response = res.data;
            //console.log('serviceapp-sendToCustomer', res, '==>', response);
            // if (response.success)
            //     toast.notify(`Service notification has send to ` + _title, { duration: 2000 });
        });
    }

    const handleReasonsClose = (objData) => {
        //console.log('handleReasonsClose', objData)
        if (!_.isEmpty(objData) && !_.isEmpty(objData.reason)) {
            if (objData.type === 'recommendations') {
                const _recommendations = Object.assign([], recommendations);

                _recommendations.filter(function (obj) {
                    //Update old data
                    if (obj.documentID === objData.docID) {
                        let recommendationDetail = Object.assign({}, obj.detail);
                        recommendationDetail['oKToDo'] = false;
                        recommendationDetail['reason'] = objData.reason;
                        obj.detail = recommendationDetail;
                    }
                });
                let ___services = {
                    ...servieData,
                    recommendations: _recommendations
                }
                handleCalculations(___services)
            }
            else if (objData.type === 'servicePackages') {
                const _recommendations = Object.assign([], servicePackages);

                _recommendations.filter(function (obj) {
                    //Update old data
                    if (obj.value === objData.docID) {
                        obj['reason'] = objData.reason;
                    }
                });
                let ___services = {
                    ...servieData,
                    servicePackages: _recommendations
                }
                handleCalculations(___services)
            }
        }
        setShowReasons({ show: false, id: null, type: null });
    }

    const handleCalculations = (_data) => {
        setServieData(_data)
        getTotalServiceCost(_data)
    }

    const getTotalServiceCost = (service) => {

        const { recommendations, servicePackages } = service;
        let _fullCost = CommonHelper.getTotalServiceCost(service, recommendations, servicePackages);
        setFullCost(_fullCost);
        return _fullCost.total ? _fullCost.total : 0;

    }

    const _recommendationList = !_.isEmpty(recommendations) && recommendations.filter(item => item.choice === recommendationChoice.Required)


    return (
        <>

            {
                isLoading
                    ?
                    <>
                        <LoaderOverlay active={true} text='Fetching details...' />
                    </>
                    :
                    <>
                        <div className="middle-wrapper report-data-load">


                            {/* <div className="customer-rec-header">
                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td width="100%"><div className="customer-rec-header-left">
                                               
                                                <div className="customer-rec-dealer">{client.name}</div>
                                            </div></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}

                            <div className="header">
                                <div className="headerwrap-left">
                                    <div className="header-left">
                                        {
                                            client && client.logoURL
                                                ?
                                                <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                :
                                                <>
                                                    <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="headerwrap-right">
                                    <div className='header-right'>
                                        {' '}
                                        {client.name} <br />
                                        <span>
                                            {' '}
                                            {client.address ? client.address : ''}
                                            {client.phone ? (
                                                <>
                                                    <br />
                                                    {`Phone: ${client.phone}`}
                                                </>
                                            ) : <></>}
                                            {client.email || client.website ? (
                                                <>
                                                    <br />
                                                    {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                </>
                                            ) : <></>}
                                            <br />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="customer-rec-title"><Translate text={_.trim(recommendationName) ? recommendationName : 'Service Recommendations'} /></div>

                            <div className="detailhead">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="detailtbl01">
                                    <tbody><tr>
                                        <td className="grid-two mob-grid">
                                            <div>
                                                <span><Translate text={'serviceAdvisor'} /></span>
                                                <div>{(!_.isEmpty(users) && serviceAdvisor) ? CommonHelper.getUserNamebyDocumentId(users, serviceAdvisor) : '--'}</div>
                                            </div>
                                        </td>
                                        <td className="grid-two">
                                            <div>
                                                <span><Translate text={'roNumber'} /></span>
                                                <div className="cap-ltr">{roNumber ? roNumber : '--'}</div>
                                            </div>
                                        </td>
                                        <td className="grid-two no-brdright">
                                            <div>
                                                <span><Translate text={'Job Number'} /></span>
                                                <div className="cap-ltr">{displayID ? displayID : '--'} </div>
                                            </div>
                                        </td>
                                        <td className="grid-two no-brdright">
                                            <div>
                                                <span><Translate text={'appointmentType'} /></span>
                                                <div className="cap-ltr">{appointmentType ? CommonHelper.getNameByValue(_settings && _settings.appointmentTypes, appointmentType, '--') : '--'}</div>
                                            </div>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td className="grid-two" >
                                                <div>
                                                    <span><Translate text={'vinNo'} upperCase={true} /></span>
                                                    <div className="cap-ltr">{vehicle && vehicle.vinNo ? vehicle.vinNo : '--'}</div>
                                                </div>
                                            </td>
                                            <td colSpan="2" className="grid-two-left" >
                                                <div>
                                                    {/* <span>VEHICLE</span> */}
                                                    <div>
                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="vhldetail">
                                                            <tbody><tr>
                                                                <td width="20%"><b><Translate text={'rego'} upperCase={true} />:&nbsp;</b><div className="cap-ltr">{vehicle && vehicle.rego ? vehicle.rego : (vehicle && vehicle.regNo ? vehicle.regNo : '--')}</div></td>
                                                                <td width="30%"><b><Translate text={'make'} upperCase={true} />:&nbsp;</b><div className="cap-ltr">{vehicle && vehicle.make ? vehicle.make : '--'}</div></td>
                                                                <td width="20%"><b><Translate text={'model'} upperCase={true} />:&nbsp;</b><div className="cap-ltr">{vehicle && vehicle.model ? vehicle.model : '--'}</div></td>
                                                                <td width="15%"><b><Translate text={'year'} upperCase={true} />:&nbsp;</b><div className="cap-ltr">{vehicle && vehicle.year ? vehicle.year : '--'}</div></td>
                                                                <td width="15%"><b><Translate text={'mileage'} upperCase={true} /> (KM):&nbsp;</b> <div className="cap-ltr">{vehicle && vehicle.odometer ? vehicle.odometer : '--'}</div></td>
                                                            </tr>
                                                            </tbody></table>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="grid-two-last">
                                                <div>
                                                    <span><Translate text={'date'} /></span>
                                                    <div className="cap-ltr"> {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY') : '--'}</div>
                                                </div>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td colSpan="4" className="grid-two" valign="top" align="left">
                                                <div className="time-comp"><Translate text={'completionDate'} />:&nbsp;<span className="blue-bold">{completionDate ? moment.unix(completionDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>&nbsp;
                                                    | &nbsp; <Translate text={'appointmentDate'} />: &nbsp;<span> {appointmentDate ? moment(appointmentDate).format('DD MMM YYYY h:mm A') : '--'}</span>&nbsp;
                                                    | &nbsp; <Translate text={'slot'} />:&nbsp;
                                                    {
                                                        !_.isEmpty(slot)
                                                            ?
                                                            <>
                                                                <div style={{ color: `${slot.color ? slot.color : '#f47815 '}` }}>
                                                                    <i className="ico icon-service-timer"></i> {slot.name}
                                                                    {
                                                                        slot.isExpress ? <div className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></div> : <></>
                                                                    }
                                                                </div>
                                                            </>

                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody></table>
                                <div className="clear"></div>
                            </div>


                            <div className="detailhead-sub">
                                <div className="no-border grid-one-new mob-grid grid-new">
                                    <span><Translate text={'customerNumber'} upperCase={true} /></span>
                                    <div className="cap-ltr">{((contact && contact.displayID) ? '(' + contact.displayID + ')' : '')}</div>
                                </div>
                                <div className="no-border grid-one-new mob-grid grid-new">
                                    <span><Translate text={'Customer Name'} upperCase={true} /></span>
                                    <div >{CommonHelper.displayFullContact(null, contact, '--')}</div>
                                </div>
                                {/* <div className="grid-two _none">
                                    <span><Translate text={'licenseNo'} upperCase={true}/></span>
                                    <div></div>
                                </div> */}
                                <div className="grid-two _none">
                                    <span><Translate text={'phone'} upperCase={true} /></span>
                                    <div>{contact && contact.phone ?
                                        CommonHelper.phoneFormat(contact.phoneCode, contact.phone, { client: client })
                                        : '--'}</div>
                                </div>
                                <div className="no-border grid-one-new mob-grid grid-new">
                                    <span><Translate text={'email'} upperCase={true} /></span>
                                    <div>{contact && contact.email ? contact.email : '--'}</div>
                                </div>
                                <div className="no-border grid-one-new mob-grid grid-new">
                                    <span><Translate text={'licenseNo'} upperCase={true} /></span>
                                    <div>{contact && contact.licenseNo ? contact.licenseNo : '--'}</div>
                                </div>
                                <div className="clear"></div>

                            </div>

                            <div className="imggal-pic" >
                                <div className="float-left">
                                    <span><Translate text={'Job Images'} /></span>
                                    <>
                                        {
                                            (!_.isEmpty(images) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                ?
                                                <>
                                                    <ul>
                                                        {
                                                            !_.isEmpty(images) && images.map((data, index) => (
                                                                <li key={index}>
                                                                    <a data-fancy-box={`customer_service_img`}
                                                                        className="venobox vbox-item"
                                                                        href={data}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setImgViewer({
                                                                                show: true,
                                                                                images: images,
                                                                                index
                                                                            })
                                                                        }} width="80" height="80">
                                                                        <img id={`servieData-before-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </li>
                                                            ))
                                                        }

                                                        {
                                                            !_.isEmpty(marks) && marks.map((mark, index1) => {
                                                                if (!_.isEmpty(mark.images)) {
                                                                    return mark.images.map((data, index) => (
                                                                        <li key={index} >
                                                                            <a data-fancy-box={`customer_service_img`}
                                                                                className="venobox vbox-item"
                                                                                href={data}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setImgViewer({
                                                                                        show: true,
                                                                                        images: mark.images,
                                                                                        index
                                                                                    })
                                                                                }} width="80" height="80">
                                                                                <img id={`servieData-mark-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                            </a>
                                                                        </li>
                                                                    ))
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </>

                                </div>

                                {
                                    inspectionIMG
                                        ?
                                        <div className="float-right service-rec-inspection">
                                            <button type="button" className="btn btn-inspection-multi float-right"
                                                data-fancy-box={`customer_service_inspection_img`}
                                                href={inspectionIMG}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setImgViewer({
                                                        show: true,
                                                        images: inspectionIMG
                                                    })
                                                }}>
                                                <i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-inspection')}></i> <Translate text={'vehicleInspection'} />
                                                {
                                                    marks && marks.length > 0
                                                        ?
                                                        <>
                                                            <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                            <div className="float-right"><span className="badge badge-inspection ml-1">{marks.length}</span></div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </button>
                                        </div>
                                        :
                                        <></>
                                }



                            </div>

                            <div className="imggal-pic" >
                                <div className="float-left">
                                    <span><Translate text={'Job Videos'} /></span>
                                    <>
                                        {
                                            (!_.isEmpty(serviceVideos.filter(item => item.recordID === props.serviceid)))
                                                ?
                                                <>
                                                    <ul>
                                                        {
                                                            serviceVideos.filter(item => item.recordID === props.serviceid).map((data, index) => (
                                                                <li key={index}>
                                                                    <a data-fancy-box={`customer_service_vid`}
                                                                        className="venobox vbox-item"
                                                                        href={data.url}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            window.open(data.url, '_blank')
                                                                        }} width="80" height="80">
                                                                        <img id={`servieData-before-vid-${index}`} src={_images.previewVideo} width="35" height="35" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </>

                                </div>
                            </div>

                            {/* {
                                !_.isEmpty(notes)
                                    ?
                                    <>
                                        <div className="right-notes">
                                            <h4>Service Notes</h4>
                                            {
                                                _.orderBy(notes, ['addedDate'], ['desc']).map((noteData, index) => {
                                                    return <div key={index} className="notes notes-item text-area-space ">
                                                        {noteData.note}
                                                        <div className="notes-item-sub">
                                                            {CommonHelper.getUserNamebyId(users, noteData.addedBy)}
                                                            <span>{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <></>
                            } */}


                            <div className="clear"></div>





                            <div className="customer-rec-grid flex-column mt-2">
                                <aside className="small-column">
                                    <div className="enquiry-sidebar">
                                        <div id="accordion-left">

                                            {
                                                (!_.isEmpty(servicePackages))
                                                    ?
                                                    <>
                                                        <h3><Translate text={'Service Packages'} upperCase={true} /></h3>
                                                        {
                                                            servicePackages.map((_serviceData, index) => {
                                                                return <div key={index} className="card">
                                                                    <>
                                                                        {(() => {

                                                                            let _packCosts = CommonHelper.getTotalPackageCost(servieData, _serviceData);
                                                                            const _isDiscountAppliedPack = _serviceData.discount && _serviceData.discountType ? true : false;
                                                                            return <>
                                                                                <div className="card-header" id="heading1">
                                                                                    <div className="mb-0">

                                                                                        <div className="cardlink"><a className="btn btn-link" >
                                                                                            {_serviceData.name}
                                                                                            {/* <div className="oktodo-icon">
                                                                                    <img src={_serviceData.oKToDo ? _images.tickcircular : (_serviceData.oKToDo === false ? _images.closecircular : _images.todoempty)} alt="" width="20" />
                                                                                </div> */}

                                                                                        </a></div>
                                                                                    </div>
                                                                                </div>

                                                                                <div id={_serviceData.value} className="collapse show" aria-labelledby="heading1" data-parent="#accordion-left">
                                                                                    <div className="card-body">

                                                                                        <div className="responsive-tbl">
                                                                                            <table>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col" className="parts-grid2"><Translate text={'Package Name'} /></th>
                                                                                                        <th scope="col" className="parts-grid6"><Translate text={'description'} /></th>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'durationHRS'} /></th>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'cost'} /></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td data-label="Package Name">  {_serviceData.name ? _serviceData.name : '--'} </td>
                                                                                                        <td data-label="Description">{
                                                                                                            !_.isEmpty(_serviceData.subList)
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="dmg">
                                                                                                                        <strong><Translate text={'Service Includes'} />:</strong>
                                                                                                                        {Array.prototype.map.call(_serviceData.subList, function (item) { return item.name; }).join(",")}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>{'--'}</>
                                                                                                        }</td>
                                                                                                        <td data-label="Length">{_serviceData.description ? _serviceData.description : '--'} </td>
                                                                                                        <td data-label="Cost">{_serviceData.price ?
                                                                                                            <NumberFormat value={_serviceData.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                            : '--'} </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                            {
                                                                                                _isDiscountAppliedPack
                                                                                                    ?
                                                                                                    <div className="reporttotal-cost">
                                                                                                        <span style={{ fontSize: '11px' }} className='mr-5'><Translate text={'discount'} upperCase={true} />:&nbsp;
                                                                                                            <span className="discount-cost">
                                                                                                                <NumberFormat value={_packCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={`${currencySymbol}-`} />
                                                                                                            </span>
                                                                                                        </span>

                                                                                                        <Translate text={'Sub Total'} />{`:`}&nbsp;
                                                                                                        <NumberFormat value={_packCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />

                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }

                                                                                            {
                                                                                                _serviceData.oKToDo === false
                                                                                                    ?
                                                                                                    <div className="ovr-reason "><span><Translate text={'Reason'} />: </span>
                                                                                                        <p> {_serviceData.reason ? _serviceData.reason : '--'} </p>

                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }


                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        })()}
                                                                    </>

                                                                </div>

                                                            })
                                                        }
                                                        {
                                                            servieData && !_.isEmpty(servieData.parts)
                                                                ?
                                                                <>
                                                                    <div className="card">

                                                                        <div className="card-header" id="heading1">
                                                                            <div className="mb-0">

                                                                                <div className="cardlink"><a className="btn btn-link" >
                                                                                    {'Service Parts'}

                                                                                </a></div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="collapse show" aria-labelledby="heading1" data-parent="#accordion-left">
                                                                            <div className="card-body">

                                                                                <div className="responsive-tbl">


                                                                                    <table className='mt-2'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'partName'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'partNumber'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'quote'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'partStatus'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'comments'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'quantity'} /></th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'Unit Cost'} /> </th>
                                                                                                <th scope="col" className="parts-grid1"><Translate text={'Part Cost'} /> </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            {
                                                                                                servieData.parts.map((_part, index1) => {
                                                                                                    return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>

                                                                                                        <td data-label="Part Name">{_part.partName ? _part.partName : '--'}  </td>
                                                                                                        <td data-label="Part No.">{_part.partNumber ? _part.partNumber : '--'}  </td>
                                                                                                        <td data-label="Part Quote">{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}  </td>
                                                                                                        <td data-label="Part Status">
                                                                                                            {_part.partStatus ? CommonHelper.getNameByValue(_settings && _settings.partStatus, _part.partStatus, '--') : '--'}
                                                                                                        </td>
                                                                                                        <td data-label="Comments">{_part.comments ? _part.comments : '--'}  </td>
                                                                                                        <td data-label="Quantity">{_part.quantity ? _part.quantity : '--'}  </td>
                                                                                                        <td data-label="Unit Cost">{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>

                                                                                                        <td data-label="Part Cost">{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan="8">
                                                                                                    <div className="ovr-cost"><Translate text={'Parts Cost'} />:  &nbsp;
                                                                                                        <NumberFormat value={fullCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                    </div>

                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>



                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>

                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }


                                            {
                                                (!_.isEmpty(_recommendationList))
                                                    ?
                                                    <>
                                                        <h3><Translate text={_.trim(recommendationName) ? recommendationName : 'Service Recommendations'} upperCase={true} /></h3>
                                                        {
                                                            _.orderBy(_recommendationList, ["addedDate"], ["asc"]).map((_recommData, index) => {
                                                                return <div key={index} className="card">
                                                                    <>
                                                                        {(() => {

                                                                            let _recommCosts = CommonHelper.getTotalRecommCost(servieData, _recommData);

                                                                            const _isDiscountAppliedRecomm = _recommData.detail && _recommData.detail.discount && _recommData.detail.discountType ? true : false;

                                                                            return <>
                                                                                <div className="card-header" id="heading1">
                                                                                    <div className="mb-0">
                                                                                        <div className="cardlink"><a className="btn btn-link" > {_recommData.name}

                                                                                            <div className="oktodo-icon">
                                                                                                {
                                                                                                    !_.isEmpty(_recommData.detail)
                                                                                                        ?
                                                                                                        <img src={_recommData.detail.oKToDo ? _images.tickcircular : (_recommData.detail.oKToDo === false ? _images.closecircular : _images.todoempty)} alt="" width="20" />
                                                                                                        :
                                                                                                        <img src={_images.todoempty} alt="" width="20" />
                                                                                                }
                                                                                            </div>
                                                                                        </a></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div id={_recommData.documentID} className="collapse show" aria-labelledby="heading1" data-parent="#accordion-left">
                                                                                    <div className="card-body">

                                                                                        <div className="responsive-tbl">
                                                                                            <table>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'option'} /></th>
                                                                                                        <th scope="col" className="parts-grid2"><Translate text={'Faults Photos'} /></th>
                                                                                                        <th scope="col" className="parts-grid2"><Translate text={'Faults Videos'} /></th>
                                                                                                        <th scope="col" className="parts-grid2"><Translate text={'faultReports'} /></th>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'status'} /></th>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'oilMiscSub'} /></th>
                                                                                                        <th scope="col" className="parts-grid1"><Translate text={'Labour Cost'} /></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td data-label="Opt 1">  {
                                                                                                            !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.option)
                                                                                                                ?
                                                                                                                <div className="badge-white">{_recommData.detail.option.charAt(0).toUpperCase()}</div>
                                                                                                                :
                                                                                                                <>{'--'}</>
                                                                                                        } </td>

                                                                                                        <td data-label="Faults Photos">
                                                                                                            {
                                                                                                                !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.images)
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <ul className="flt-pic-fix">
                                                                                                                            {
                                                                                                                                _recommData.detail.images.map((data, index) => (
                                                                                                                                    <li key={index}>
                                                                                                                                        <a data-fancy-box={`customer_recomm_service_img${_recommData.documentID}`}
                                                                                                                                            className="venobox vbox-item"
                                                                                                                                            href={data}
                                                                                                                                            onClick={(e) => {
                                                                                                                                                e.preventDefault();
                                                                                                                                                setImgViewer({
                                                                                                                                                    show: true,
                                                                                                                                                    images: _recommData.detail.images,
                                                                                                                                                    index
                                                                                                                                                })
                                                                                                                                            }} width="80" height="80">
                                                                                                                                            <img id={`servieData-before-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                                                                                        </a>
                                                                                                                                    </li>
                                                                                                                                ))
                                                                                                                            }

                                                                                                                        </ul>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>{'--'}</>
                                                                                                            }

                                                                                                        </td>

                                                                                                        <td data-label="Faults Videos">
                                                                                                            {
                                                                                                                (!_.isEmpty(serviceVideos.filter(item => item.recordID === _recommData.documentID)))
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <ul className="flt-pic-fix">
                                                                                                                            {
                                                                                                                                serviceVideos.filter(item => item.recordID === _recommData.documentID).map((data, index) => (
                                                                                                                                    <li key={index}>
                                                                                                                                        <a data-fancy-box={`customer_recomm_service_vid${_recommData.documentID}`}
                                                                                                                                            className="venobox vbox-item"
                                                                                                                                            href={data.url}
                                                                                                                                            onClick={(e) => {
                                                                                                                                                e.preventDefault();
                                                                                                                                                window.open(data.url, '_blank')
                                                                                                                                            }} width="80" height="80">
                                                                                                                                            <img id={`servieData-before-vid-${index}`} src={_images.previewVideo} width="35" height="35" alt="" className="img-object-fit" />
                                                                                                                                        </a>
                                                                                                                                    </li>
                                                                                                                                ))
                                                                                                                            }

                                                                                                                        </ul>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>{'--'}</>
                                                                                                            }

                                                                                                        </td>

                                                                                                        <td data-label="Faults Reports">
                                                                                                            <div className="dmg">
                                                                                                                {
                                                                                                                    !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.faultReports)
                                                                                                                        ?
                                                                                                                        _recommData.detail.faultReports
                                                                                                                        :
                                                                                                                        '--'
                                                                                                                }
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td data-label="Status">{
                                                                                                            !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.status)
                                                                                                                ?
                                                                                                                <div className={`${_recommData.detail.status === 'urgent' ? 'urgent' : 'badge-white'} pad-alert`}>{_recommData.detail.status.charAt(0).toUpperCase()}</div>
                                                                                                                :
                                                                                                                <>{'--'}</>
                                                                                                        } </td>
                                                                                                        <td data-label="Oils/Misc.">{
                                                                                                            !_.isEmpty(_recommData.detail) && !_.isEmpty(_recommData.detail.oilMiscSub)
                                                                                                                ?
                                                                                                                _recommData.detail.oilMiscSub
                                                                                                                :
                                                                                                                '--'
                                                                                                        } </td>
                                                                                                        <td data-label="Labour Cost">{!_.isEmpty(_recommData.detail) && _recommData.detail.totalLabourCost ?
                                                                                                            <NumberFormat value={_recommData.detail.totalLabourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                            : '--'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                            {
                                                                                                _recommData.detail && _recommData.detail.parts
                                                                                                    ?
                                                                                                    <>
                                                                                                        <table className='mt-2'>
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'partName'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'partNumber'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'quote'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'partStatus'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'comments'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'quantity'} /></th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'Unit Cost'} /> </th>
                                                                                                                    <th scope="col" className="parts-grid1"><Translate text={'Part Cost'} /> </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>

                                                                                                                {
                                                                                                                    _recommData.detail.parts.map((_part, index1) => {
                                                                                                                        return <tr key={index1} className={[recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote) ? 'font-italic' : ''}>

                                                                                                                            <td data-label="Part Name">{_part.partName ? _part.partName : '--'}  </td>
                                                                                                                            <td data-label="Part No.">{_part.partNumber ? _part.partNumber : '--'}  </td>
                                                                                                                            <td data-label="Part Quote">{_part.quote ? CommonHelper.getLabelByValue(partQuotes, _part.quote, '--') : '--'}  </td>
                                                                                                                            <td data-label="Part Status">
                                                                                                                                {_part.partStatus ? CommonHelper.getNameByValue(_settings && _settings.partStatus, _part.partStatus, '--') : '--'}
                                                                                                                            </td>
                                                                                                                            <td data-label="Comments">{_part.comments ? _part.comments : '--'}  </td>
                                                                                                                            <td data-label="Quantity">{_part.quantity ? _part.quantity : '--'}  </td>
                                                                                                                            <td data-label="Unit Cost">{_part.partCost ? <NumberFormat value={_part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'} </td>

                                                                                                                            <td data-label="Part Cost">{_part.totalCost ? <strong><NumberFormat value={([recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(_part.quote)) ? 0 : _part.totalCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong> : '--'} </td>

                                                                                                                        </tr>
                                                                                                                    })
                                                                                                                }
                                                                                                                <tr>
                                                                                                                    <td colSpan="8">
                                                                                                                        <div className="ovr-cost"><Translate text={'Parts Cost'} />:  &nbsp;
                                                                                                                            <NumberFormat value={_recommCosts.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                                        </div>

                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>

                                                                                                    </>
                                                                                                    :
                                                                                                    <></>

                                                                                            }

                                                                                            <div className="reporttotal-cost" subtotal="0.00">
                                                                                                {
                                                                                                    _isDiscountAppliedRecomm
                                                                                                        ?
                                                                                                        <><span style={{ fontSize: '11px' }} className='mr-5'><Translate text={'discount'} upperCase={true} />:&nbsp;
                                                                                                            <span className="discount-cost">
                                                                                                                <NumberFormat value={_recommCosts.actualDiscount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={`${currencySymbol}-`} />
                                                                                                            </span>
                                                                                                        </span></>
                                                                                                        :
                                                                                                        <></>
                                                                                                }

                                                                                                <Translate text={'Sub Total'} />:&nbsp;
                                                                                                <NumberFormat value={_recommCosts.subtotalAfterDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                            </div>
                                                                                            {
                                                                                                status === serviceStatus.OWNERREQUESTED
                                                                                                    ?
                                                                                                    <div className="service-rec-oktodo-wrap">
                                                                                                        <div className="service-rec-oktodo">
                                                                                                            <div className="oktodo-title"><Translate text={'oKToDo'} /></div>
                                                                                                            <div className="radio icheck-success float-left mr-3">
                                                                                                                <InputRadio
                                                                                                                    id={`Yes_${_recommData.documentID}`}
                                                                                                                    type="radio"
                                                                                                                    value="Yes"
                                                                                                                    name={_recommData.documentID}
                                                                                                                    checked={_recommData.detail && _recommData.detail.oKToDo === true ? true : false}
                                                                                                                    onChange={handleRecommendationOkToDoChange}
                                                                                                                />
                                                                                                                <label htmlFor={`Yes_${_recommData.documentID}`}><Translate text={'yes'} /></label>
                                                                                                            </div>
                                                                                                            <div className="radio icheck-success float-left mr-2 todo-red">
                                                                                                                <InputRadio
                                                                                                                    id={`No_${_recommData.documentID}`}
                                                                                                                    type="radio"
                                                                                                                    value="No"
                                                                                                                    name={_recommData.documentID}
                                                                                                                    checked={_recommData.detail && _recommData.detail.oKToDo === false ? true : false}
                                                                                                                    onChange={handleRecommendationOkToDoChange}
                                                                                                                />
                                                                                                                <label htmlFor={`No_${_recommData.documentID}`}><Translate text={'no'} /></label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }


                                                                                            {
                                                                                                _recommData.detail && _recommData.detail.oKToDo === false
                                                                                                    ?
                                                                                                    <div className="ovr-reason "><span><Translate text={'Reason'} />: </span>
                                                                                                        <p> {_recommData.detail && _recommData.detail.reason ? _recommData.detail.reason : '--'} </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }


                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        })()}
                                                                    </>

                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }



                                        </div>
                                    </div>
                                </aside>


                            </div>







                            <div className="totalcost">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                        <tr>
                                            <td><div className="mob-estimate" > <Translate text={'Delivery Estimated  Date'} /><div className="date">&nbsp;{completionDate ? moment.unix(completionDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</div>

                                            </div>
                                                <div className="total-estimate">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr >
                                                                <td className="costtxt"><Translate text={'Service Packages Cost'} /></td>
                                                                <td className="cost-box"><NumberFormat value={fullCost.packagesCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                            </tr>
                                                            <tr >
                                                                <td className="costtxt"><Translate text={'Service Parts Cost'} /></td>
                                                                <td className="cost-box"><NumberFormat value={fullCost.servicePartsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                            </tr>
                                                            <tr >
                                                                <td className="costtxt"><Translate text={'Parts Quote Cost'} /></td>
                                                                <td className="cost-box"><NumberFormat value={fullCost.partsCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                            </tr>
                                                            <tr >
                                                                <td className="costtxt"><Translate text={'Labour Repair Cost'} /></td>
                                                                <td className="cost-box"><NumberFormat value={fullCost.labourCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="costtxt blue-color"><Translate text={'Sub Total Cost'} /> {`(${taxType ? taxType : 'Tax'} Excl.)`}:</td>
                                                                <td className="cost-box blue-color" ><span><NumberFormat value={fullCost.actualSubTotal} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                                                            </tr>
                                                            {
                                                                fullCost.totalDiscount
                                                                    ?
                                                                    <>
                                                                        <tr >
                                                                            <td className="costtxt"><Translate text={'Total Discount'} /></td>
                                                                            <td className="cost-box"><NumberFormat value={fullCost.totalDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${currencySymbol}-`} /></td>
                                                                        </tr>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                _isTaxIncluded
                                                                    ?
                                                                    <>

                                                                        <tr >
                                                                            <td className="costtxt">  {taxType}&nbsp;<Translate text={'Payable'} /></td>
                                                                            <td className="cost-box"><NumberFormat value={fullCost.taxCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                                        </tr>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            <tr>
                                                                <td className="costtxt-green"><Translate text={'Total Repair Cost'} /> {_dispalyTax}</td>
                                                                <td className="cost-box total"><NumberFormat value={fullCost.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                status === serviceStatus.OWNERREQUESTED
                                    ?
                                    <div className="accordion-bottom approve-btn" >
                                        <a href="#"
                                            className={`btn btn-common button-green float-right mt-3 ${loader ? 'form-disable' : ''}`}
                                            onClick={(e) => { e.preventDefault(); setShowApproval(true); }}>
                                            {loader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'Approve'} upperCase={true} />
                                        </a>
                                        <div className="clear"> </div>
                                    </div>
                                    :
                                    <></>
                            }


                            {
                                !_.isEmpty(disclaimer) && status !== serviceStatus.OWNERREQUESTED
                                    ?
                                    <>
                                        <div className="sub-inner-wraper brd-none">
                                            <div className="sub-inner-head"><Translate text={'disclaimer'} /></div>
                                            <div className="disclaimer-list"
                                                dangerouslySetInnerHTML={{ __html: (disclaimer ? disclaimer : '--') }} />
                                        </div>
                                        <div className="sub-inner-wraper brd-none">
                                            <div className="sub-inner-head"><Translate text={'signature'} /> </div>
                                            <div className="sign-list">
                                                {
                                                    userSign
                                                        ?
                                                        <img id={`terms-signature`} src={userSign} width="120" />
                                                        :
                                                        <img id={`terms-signature`} src={_images.sign} width="120" />
                                                }
                                            </div>
                                        </div>


                                    </>
                                    :
                                    <>
                                    </>
                            }


                            <div className="clear"></div>

                            <footer>
                                <div className="footer-icons">
                                    <div className="icons">
                                        <div className="urgent"> U</div>
                                        <Translate text={'urgent'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="advisable"> A</div>
                                        <Translate text={'advisable'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="report"> R</div>
                                        <Translate text={'report'} upperCase={true} /></div>
                                    <div className="divider"> | </div>
                                    <div className="icons">
                                        <div className="badge-white"> C</div>
                                        <Translate text={'customer'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-white"> W</div>
                                        <Translate text={'warranty'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-white"> I</div>
                                        <Translate text={'internal'} upperCase={true} /></div>

                                    <div className="divider"> | </div>

                                    <div className="icons">
                                        <div className="badge-ins major-info"> i</div>
                                        <Translate text={'info'} upperCase={true} /></div>

                                    <div className="icons">
                                        <div className="badge-ins major-scratch"> S</div>
                                        <Translate text={'Major Scratch'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-ins minor-scratch"> S</div>
                                        <Translate text={'Minor Scratch'} upperCase={true} /></div>

                                    <div className="icons">
                                        <div className="badge-ins major-rust"> R</div>
                                        <Translate text={'Major Rust'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-ins minor-rust"> R</div>
                                        <Translate text={'Minor Rust'} upperCase={true} /></div>

                                    <div className="icons">
                                        <div className="badge-ins major-dent"> D</div>
                                        <Translate text={'Major Dent'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-ins minor-dent"> D</div>
                                        <Translate text={'Minor Dent'} upperCase={true} /></div>

                                    <div className="icons">
                                        <div className="badge-ins major-crack"> C</div>
                                        <Translate text={'Major Crack'} upperCase={true} /></div>
                                    <div className="icons">
                                        <div className="badge-ins minor-crack"> C</div>
                                        <Translate text={'Minor Crack'} upperCase={true} /></div>

                                    <div className="clear"></div>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-bottom">

                                        <div className="ft-left">

                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address}</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>

                                        </div>

                                        <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </footer>

                        </div>
                    </>

            }

            <PopUpModal show={showReasons.show}>
                <Reasons
                    show={showReasons.show}
                    docID={showReasons.id}
                    docType={showReasons.type}
                    reasons={reasons}
                    handleClose={handleReasonsClose}
                />
            </PopUpModal>

            {/* <PopUpModal show={showApproval}>
                <Approval
                    show={showApproval}
                    disclaimer={client && client.serviceDisclaimer}
                    handleClose={handleApprovalClose}
                />
            </PopUpModal> */}
            <PopUpModal show={showApproval}>
                <Terms
                    show={showApproval}
                    clsActive='overlay-modal active'
                    imagePath='serviceJobs'
                    title={'disclaimer'}
                    terms={''}
                    classContainer={'pl-0 pr-0'}
                    termsandCondition={CommonHelper.getTermsByField(client, 'serviceDisclaimer', contact?.language)}
                    clientID={clientID}
                    isDisable={false}
                    handleClose={handleApprovalClose}
                    dealersettings={currentSettings}
                    shouldReturnBase64={true}
                ></Terms>
            </PopUpModal>

            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>
        </>



    )
};

export default ServiceCustomerApproval;
