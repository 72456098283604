/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash';
import moment from 'moment'
import momenttz from 'moment-timezone'
//import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2'
import {ImageViewer, PopUpModal} from '../../../components'
/** COMPONENTS */
import {
    ReactSelect, InputText, AutoComplete, ReactMultiSelect,
    AntDateRangePicker, ReactTimePicker, 
    ImageCropHook, InputCheckBox, TextEditor
} from '../../../components'
//import { CustomToggle, CustomSearchMenu } from '../../../components/customdropdown';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
/** VIEW-MODELS */
import { objEventPro, defaultMandatoryEventPro, eventCountsVM, dietaryVM, attendeesDFVM } from '../viewModel'
import { firestoreDB } from '../../../services/helper';
import { userLevel } from '../../../services/enum';
import { storageBucket } from '../../../services/helper';

//#region  ASSIGN VARIABLES
//let defaultCountry = '';

const visibilities = [
    { active: true, value: 'private', label: <div className="badge badge-pill badge-private"><Translate text={'private'} /></div> },
    { active: true, value: 'shared', label: <div className="badge badge-pill badge-shared"><Translate text={'shared'} /></div> }
]

const levelOptions = [
    //{ value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'subregion', label: 'Subregion', index: 3 },
    { value: 'group', label: 'Group', index: 4 },
    { value: 'individual', label: 'Individual', index: 5 }
]
//#endregion


export default class AddEvent extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.mandatoryFields = defaultMandatoryEventPro;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('eventsPro');
        this.selectedClientID = null;
        this.state = {
            fields: Object.assign({}, objEventPro),
            errors: {},
            dealers: [],
            regions: [],
            subregions: [],
            groups: [],
            clientUsers: [],
            files: [],
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            imageViewer: { show: false, images: null }
        }
    }


    //#region PAGE LOAD

    componentDidMount() {
        const { dealersettings, docID, show, slotDate } = this.props;
        let fields = Object.assign({}, objEventPro);
        const states = [];
        if (show === true && docID) {
            const refData = firestoreDB(dealersettings).firestore().doc(`eventsPro/${docID}`);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objData = Object.assign({}, doc.data());
                    objData.documentID = doc.id;
                    for (let [key, value] of Object.entries(objData)) {
                        fields[key] = value;
                    }
                    if (!_.isEmpty(fields.clientIDs) && _.isEmpty(fields.level)) fields.level = userLevel.INDIVIDUAL;
                    //if (fields.timezone)
                    //    moment.tz.setDefault(fields.timezone);
                    this.setState({ fields: fields }, () => { this.loadSettings(); });
                } else {
                    console.error("No such company document!");
                }
            });
        }
        else if (show === true) {
            fields.owner = dealersettings ? dealersettings.id : '';
            fields.timezone = momenttz.tz.guess();
            if (dealersettings.oemID) fields.oemID = dealersettings.oemID;
            if (dealersettings.level === userLevel.INDIVIDUAL) {
                fields.clientID = (dealersettings ? dealersettings.client.id : '');
                fields.clientName = (dealersettings ? dealersettings.client.name : '');
                fields.level = userLevel.INDIVIDUAL;
            }

            if (slotDate) {
                fields.startDate = window.firebase.firestore.Timestamp.fromDate(moment(slotDate).hour(8).minute(0)._d);
                fields.endDate = window.firebase.firestore.Timestamp.fromDate(moment(slotDate).hour(17).minute(0)._d);
            }
            this.setState({ fields: fields }, () => { this.loadSettings(); });
        }
    }

    loadSettings = () => {
        const { dealersettings, clientUsers, groupUsers } = this.props;
        const { fields } = this.state;

        this.selectedClientID = fields?.clientID ? fields.clientID : dealersettings.client.id;

        let _clientData = this.selectedClientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
            && !_.isEmpty(dealersettings.group.clients[this.selectedClientID]) ? dealersettings.group.clients[this.selectedClientID] : dealersettings.client;

        if (_clientData) {
            //const setttings = _clientData.settings;
            const eventsPro_setttings = dealersettings.client.eventsPro;
            //defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

            if (!_.isEmpty(eventsPro_setttings.mandatoryFields) && !_.isEmpty(eventsPro_setttings.mandatoryFields.events))
                this.mandatoryFields = _.uniq(_.union(defaultMandatoryEventPro, eventsPro_setttings.mandatoryFields.events.split(',')));

            if (dealersettings.level !== userLevel.INDIVIDUAL) {
                let _mand = [...this.mandatoryFields, 'level'];
                this.mandatoryFields = _mand.filter(e => !['clientID', 'clientIDs'].includes(e));
            }
        }

        let _clientUsers = [];
        let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        if (this.selectedClientID && !_.isEmpty(groupUsers)) {
            _clientUsers = groupUsers.filter(e => e.clientID === this.selectedClientID);
        }
        else if (!_.isEmpty(_allUsers)) {
            _.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });
        }

        let _dealers = [];
        if (dealersettings.clients)
            dealersettings.clients.filter(c =>
                c.moduleSettings
                && c.moduleSettings.eventsPro
                && c.moduleSettings.eventsPro.enabled === true
                && c.moduleSettings.eventsPro.active === true
            ).forEach(rec => {
                _dealers.push({
                    ...rec,
                    label: rec.name,
                    value: rec.id,
                    data: rec,
                    active: true,
                })
            })

        let _subregions = [];
        dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                _subregions.push({
                    ...doc,
                    value: doc.id,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            }
        });

        let _regions = [];
        dealersettings.regions && dealersettings.regions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.region === doc.id)) {
                _regions.push({
                    ...doc,
                    value: doc.id,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            }
        });

        let _groups = [];
        dealersettings.groups && dealersettings.groups.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.group === doc.id)) {
                _groups.push({
                    ...doc,
                    value: doc.id,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            }
        });
        //console.log('regions', _groups, _subregions, _regions, _dealers)
        this.setState({
            clientUsers: _clientUsers,
            dealers: _dealers,
            regions: _regions,
            subregions: _subregions,
            groups: _groups
        });
    }

    componentWillUnmount() {
        // moment.tz.setDefault(moment.tz.guess());
    }

    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }

    }

    handleOnNameChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase().replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '') + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {

            if (str && str.length >= 1) {
                var firstChar = str.charAt(0).replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '');
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str ? str.replace(/\u2028/g, '') : '';
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {

            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && !_.isEmpty(this.mandatoryFields) && this.mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }

            this.setState({ errors: errors });
        }
    }

    handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        let state = Object.assign({}, this.state.fields);
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            let options = Object.assign([], state.level === userLevel.GROUP ? this.loadGroups() :
                state.level === userLevel.REGION ? this.loadRegions() :
                    state.level === userLevel.SUBREGION ? this.loadSubRegions() :
                        state.level === userLevel.INDIVIDUAL ? this.loadDealers() : [])

            value = options.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
        }

        state[name] = value;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleMultiSelectChange = (selectedOptions, data) => {
        let value = [];
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            value = this.loadDealers().filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
        }


        let state = Object.assign({}, this.state.fields);

        // if (data.name === 'clientIDs' && state.clientID && !_.includes(value, state.clientID))
        //     value.push(state.clientID);

        state[data.name] = value;
        if (data.name === 'regionIDs') {
            state['subregionIDs'] = [];
        }
        if (data.name === 'subregionIDs' || data.name === 'regionIDs') {
            state['clientIDs'] = [];
        }
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleDateRangeChange = (val) => {
        let state = Object.assign({}, this.state.fields);
        const offset = moment().utcOffset() - moment().tz(state.timezone).utcOffset()
        if (val) {
            state.startDate = window.firebase.firestore.Timestamp.fromDate(moment(val[0].format('YYYY-MM-DD') + '  08:00').add(offset, 'minutes').toDate())
            state.endDate = window.firebase.firestore.Timestamp.fromDate(moment(val[1].format('YYYY-MM-DD') + '  17:00').add(offset, 'minutes').toDate())
        }
        else {
            state.startDate = null;//window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
            state.endDate = null;//window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
        }
        this.setState({ fields: state }, () => { });
    }

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);
        const offset = moment().utcOffset() - moment().tz(state.timezone).utcOffset()
        state[name] = window.firebase.firestore.Timestamp.fromDate(moment(date).add(offset, 'minutes').toDate());
        //state.eventDates = this.getDaysBetweenDates(state.startDate, state.endDate);
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    handleLevelSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            state[data.name] = [e.value];
        }
        else {
            state[data.name] = null;
        }
        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            if (data.name === 'clientID') {
                state.clientName = e.label;
            }
            state[data.name] = e.value;
        }
        else {
            state[data.name] = '';
        }

        if (data.name === 'visibility' || data.name === 'level') {
            state['clientIDs'] = [];
            state['regionIDs'] = [];
            state['subregionIDs'] = [];
            state['groupIDs'] = [];
            state['levelID'] = [];
            state['clientIDs'] = [];
        }


        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    //#endregion

    //#region  LOCATION AUTOCOMPLETE SEARCH
    bindAddress = (place) => {
        let state = Object.assign({}, this.state.fields);
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        let countryForm = {
            country: 'long_name',
        };

        let _countryName = ''
        if (place.formatted_address !== null)
            state['location'] = place.formatted_address;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "postal_code")
                    state['areaCode'] = val;

                if (addressType === "administrative_area_level_1")
                    state['state'] = val;

                if (addressType === "country") {
                    state['country'] = val;
                }
            }
            if (countryForm[addressType]) {
                var val = place.address_components[i][countryForm[addressType]];
                _countryName = val;
            }

        }
        let _timezone = this.zonesForCountry(state['country'], state['location'], _countryName)
        if (_timezone) state.timezone = _timezone
        //console.log('zonesForCountry', _countryName, _timezone);
        this.setState({ fields: state }, () => {
            this.errorChange('location');
            this.errorChange('areaCode');
            this.errorChange('state');
            this.errorChange('country');
        });

    };

    zonesForCountry = (country, address, countryName) => {
        if (country) {
            let timezones = Object.assign([], moment.tz.zonesForCountry(country))
            if (!_.isEmpty(timezones)) {
                //console.log('timezones', timezones)
                if (timezones.length === 1) {
                    return timezones[0];
                }
                else if (address) {
                    let _split = _.uniq(address.replace(',', '').split(' ')).filter(c => (countryName ? c !== countryName : true));
                    let _timezonesList = timezones.filter(e => _split.some(m => e.includes(m)))
                    //console.log('_timezonesList', _timezonesList)
                    if (_timezonesList.length === 1)
                        return _timezonesList[0];
                    else if (_timezonesList.length > 1)
                        return _timezonesList[_timezonesList.length - 1]
                    else return timezones[timezones.length - 1];
                }
                else return timezones[timezones.length - 1];
            }
        }
        return null

    }
    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {
        const { src, id, type, ext } = cropShow;
        let state = Object.assign({}, this.state.fields);
        state[id] = src;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            fields: state,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFileOld = (files, type, id, title) => {

        if (files && files.length > 0) {
            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = files[0];
            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    onSelectFile = async (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }

            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)

        }
    }

    saveImageToStorage = (imageBase64, id, ext) => {
        const { dealersettings } = this.props;
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(dealersettings).ref(`${clientID}/eventsPro/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/eventsPro`).child(uid).getDownloadURL()
                    .then(dataURL => {

                        let state = Object.assign({}, this.state.fields);
                        state.imageURL = dataURL;
                        this.setState({ fields: state })
                    })
            })
    };

    handleImageDel = () => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state.imageURL = null;
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region ADD, EDIT & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        const { dealersettings } = this.props;

        this.mandatoryFields && this.mandatoryFields.forEach(key => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        if (dealersettings.level !== userLevel.INDIVIDUAL) {
            if (['region', 'subregion', 'group'].includes(fields.level) && _.isEmpty(fields['levelID'])) {
                formIsValid = false;
                errors['levelID'] = errorClass;
            }

            if (['individual'].includes(fields.level) && _.isEmpty(fields['clientIDs'])) {
                formIsValid = false;
                errors['clientIDs'] = errorClass;
            }

            if (['individual'].includes(fields.level) && fields.visibility === 'shared' && _.isEmpty(fields['clientID'])) {
                formIsValid = false;
                errors['clientID'] = errorClass;
            }
        }
        else if (dealersettings.level === userLevel.INDIVIDUAL && _.isEmpty(fields['clientIDs'])) {
            formIsValid = false;
            errors['clientIDs'] = errorClass;
        }
        else if (dealersettings.level === userLevel.INDIVIDUAL && fields.visibility === 'shared' && _.isEmpty(fields['clientID'])) {
            formIsValid = false;
            errors['clientID'] = errorClass;
        }

        console.log('errors', errors, fields)
        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.saveEvent();
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };

    saveEvent = () => {
        const { docID } = this.props;
        if (docID)
            this.updateEvent(docID);
        else
            this.addEvent();
    }

    addEvent = () => {
        const { dealersettings } = this.props;
        const setttings = dealersettings?.client?.eventsPro;
        let objData = Object.assign({}, this.state.fields);
        this.isLoading = true;

        objData.addedBy = dealersettings ? dealersettings.id : '';
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        objData.projectId = window.secondaryProjectId;
        objData.isDeleted = false;
        objData.isNewEvent = true;

        if (_.isEmpty(objData.clientIDs) || objData.level !== userLevel.INDIVIDUAL) { objData.clientID = null; objData.clientName = null; }
        else {
            if (objData.clientID) objData.clientIDs = _.uniq(Object.assign([], objData.clientIDs).concat(objData.clientID));
            if (!objData.clientID && !_.isEmpty(objData.clientIDs) && objData.level === userLevel.INDIVIDUAL) {
                objData.clientID = objData.clientIDs[0];
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID)
            }
        }


        let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
        if (_.isEmpty(_selPipeline))
            _selPipeline = Object.assign({}, setttings.pipelines[0]);

        let defaultstages = _selPipeline.stages;
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        objData.pipeline = _selPipeline.value;
        objData.stage = stages[0];
        objData.status = 'draft';
        objData.stageDate = window.firebase.firestore.Timestamp.now();
        objData.stageHistory = Object.assign([], [{ date: objData.stageDate, stage: objData.stage, pipeline: objData.pipeline }]);

        objData.counts = eventCountsVM;
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('eventsPro').doc().id;

        const updateRef = firestoreDB(dealersettings).firestore().collection('eventsPro').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = false;
            toast.notify('Event added successfully.', {
                duration: 2000
            })

            let documentID = objData.documentID;
            //CREATE DIETARY SETTING ON ADD
            firestoreDB(dealersettings).firestore().doc(`eventsPro/${documentID}/settings/${documentID}`).set({
                dietary: dietaryVM,
                attendeesDF: dealersettings.isMclaren === true ? attendeesDFVM : [],
                projectId: window.secondaryProjectId
            }, { merge: true })
            //REDIRECT 
            this.handleModalClose(objData.documentID, objData);
            this.props.history.push("/eventspro/details/" + documentID);

        }).catch((error) => {
            console.error("Error adding company: ", error);
        });


    };

    updateEvent = (docID) => {
        const { dealersettings } = this.props;
        let objData = Object.assign({}, this.state.fields);
        this.isLoading = true;

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.projectId = window.secondaryProjectId;

        if (_.isEmpty(objData.clientIDs) || objData.level !== userLevel.INDIVIDUAL) { objData.clientID = null; objData.clientName = null; }
        else {
            if (objData.clientID) objData.clientIDs = _.uniq(Object.assign([], objData.clientIDs).concat(objData.clientID));
            if (!objData.clientID && !_.isEmpty(objData.clientIDs) && objData.level === userLevel.INDIVIDUAL) {
                objData.clientID = objData.clientIDs[0];
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID)
            }
        }
        //Remove empty value from object
        Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});

        if (!objData.documentID) objData.documentID = docID.toString();

        const updateRef = firestoreDB(dealersettings).firestore().collection('eventsPro').doc(docID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = false;
            toast.notify('Event updated successfully', {
                duration: 2000
            })
            this.handleModalClose(objData.documentID, objData);
        }).catch((error) => {
            console.error("Error updating company: ", error);
        });

    }

    handleModalClose = (companyID, obj) => {

        this.setState({ fields: Object.assign({}, objEventPro) });
        this.setState({ errors: {} });
        this.props.handleClose(companyID, obj);
    };
    //#endregion

    onDrop = (files) => {
        //console.log('onDrop', files)
        this.onSelectFile(files, 'eventsPro', 'imageURL', 'Event Image')
        //this.setState({ files })
    };

    loadDealers = (isAll) => {
        const { regionIDs, subregionIDs, groupIDs } = this.state.fields;
        const { dealers } = this.state;
        let _dealers = [];
        if (!_.isEmpty(groupIDs)) {
            _dealers = dealers.filter(e => groupIDs.includes(e.group))
        }
        else if (!_.isEmpty(subregionIDs)) {
            _dealers = dealers.filter(e => subregionIDs.includes(e.subregion))
        }
        else if (!_.isEmpty(regionIDs))
            _dealers = dealers.filter(e => regionIDs.includes(e.region))
        else
            _dealers = dealers

        if (isAll && _dealers.length > 1) {
            _dealers = [{ label: 'All', value: 'all' }, ..._dealers];
        }

        return _dealers;
    }

    loadGroups = (isAll) => {
        const { regionIDs, subregionIDs } = this.state.fields;
        const { dealers, groups } = this.state;
        let _groups = [];
        if (!_.isEmpty(subregionIDs)) {
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && subregionIDs.includes(client.subregion)))
        }
        else if (!_.isEmpty(regionIDs)) {
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && regionIDs.includes(client.region)))
        }
        else
            _groups = groups

        if (isAll && _groups.length > 1) {
            _groups = [{ label: 'All', value: 'all' }, ..._groups];
        }

        return _groups;
    }

    loadSubRegions = (isAll) => {
        const { regionIDs } = this.state.fields;
        const { dealers, subregions } = this.state;
        let _subregions = [];
        if (!_.isEmpty(regionIDs)) {
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && regionIDs.includes(client.region)))
        }
        else _subregions = subregions

        if (isAll && _subregions.length > 1) {
            _subregions = [{ label: 'All', value: 'all' }, ..._subregions];
        }

        return _subregions;
    }

    loadRegions = (isAll) => {
        const { regions } = this.state;
        let _regions = regions;
        if (isAll && _regions.length > 1) {
            _regions = [{ label: 'All', value: 'all' }, ..._regions];
        }
        return _regions;
    }

    render() {
        //console.log('R E N D E R - C O M P A N Y ');
        const { clientID, title, location, clientIDs, startDate, endDate, imageURL, description, enableEOI,
            visibility, regionIDs, subregionIDs, groupIDs, displayName
        } = this.state.fields;

        const { show, dealersettings } = this.props;
        const { errors, dealers, regions, subregions, groups, fields } = this.state;

        const baseStyle = {
            width: '100%',
            background: '#f2f7ff',
            border: '2px dashed #a7cbff',
            minHeight: '100px',
            float: 'left',
            textDecoration: 'none',
            color: '#4466f2',
        };
        return (
            <>

                <Modal
                    show={show}
                    onHide={this.handleModalClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-events-info">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="pop-layout form-style">
                                    <div className="staff-info-pop-middle">
                                        <div className={`form-row`}>

                                            <div className="form-group col-md-4">
                                                <label >Event Image <span className="events-reco"> (Recommended 1200px width)</span> </label>
                                                <div className="upload-event-image-box">
                                                    <div className={imageURL ? 'upload-event-image-output' : 'upload-event-image'}>

                                                        {
                                                            (imageURL && imageURL.indexOf('base64') > 0)
                                                                ?
                                                                <div className="img-loader">
                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                        <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }

                                                        <label htmlFor="imageURL" className={`${imageURL ? 'licensebox-edit mini-button' : 'events-image-upload-iconwrap'}`}>
                                                            <i className={`ico ${imageURL ? 'icon-edit' : 'icon-files-upload'}`} htmlFor="imageURL"></i>
                                                            {imageURL ? <></> : <span className="upload-text">Upload Image</span>}

                                                            <input className="fileInput"
                                                                type="file"
                                                                name="visitingCard"
                                                                id="imageURL"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => this.onSelectFile(e, 'eventsPro', 'imageURL', 'Event Image')} />
                                                        </label>

                                                        {
                                                            imageURL
                                                                ?
                                                                <>
                                                                    <label title="Delete" htmlFor="visitingCardDel"
                                                                        className="licensebox-edit mini-button mini-hover-red"
                                                                        style={{ marginRight: '22px' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleImageDel();
                                                                        }}>
                                                                        <i className="ico icon-delete" htmlFor="visitingCardDel"></i>
                                                                    </label>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            (imageURL)
                                                                ?
                                                                <>
                                                                    <a data-fancy-box={`imageURL`} 
                                                                    href={imageURL} 
                                                                    onClick={(e) => { e.preventDefault(); 
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: imageURL
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <img src={imageURL} alt="" className="img-object-fit" />
                                                                    </a>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-8">
                                                <div className="form-group col-md-12 pl-0 pr-0">
                                                    <label ><Translate text={'title'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'title'}
                                                        className={`form-control ${errors["title"]}`}
                                                        name="title"
                                                        onChange={this.handleOnNameChange}
                                                        value={title}
                                                    />
                                                </div>
                                                <div className="form-row col-md-12 pl-0 pr-0 ml-0">
                                                    <div className="form-group col-md-8 pl-0">
                                                        <label ><Translate text={'location'} /></label>
                                                        <AutoComplete
                                                            className={`form-control ${errors["location"]}`}
                                                            bindAddress={this.bindAddress}
                                                            types={['address']}
                                                            value={location ? location : ''}
                                                            placeholder={'search location'}
                                                            onChange={this.handleOnChange}
                                                            componentRestrictions={{ country: [] }}
                                                            name="location"
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-4 pr-0">
                                                        <label ><Translate text={'Timezone'} /></label>
                                                        <ReactSelect
                                                            options={momenttz.tz.names().map(rec => {
                                                                return {
                                                                    label: rec, value: rec, active: true,
                                                                }
                                                            })}
                                                            name={"timezone"}
                                                            placeholder={'select timezone'}
                                                            onChange={this.handleReactSelectChange}
                                                            value={fields.timezone}
                                                            classNamePrefix={`${errors["timezone"]} cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                            id="client-timezone"
                                                            maxMenuHeight={200}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            {
                                                (visibility === 'shared' && (dealersettings.level === userLevel.INDIVIDUAL || fields.level === userLevel.INDIVIDUAL)) && (<div className="form-group col-md-12">
                                                    <label ><Translate text={'Host Client'} /> </label>
                                                    <ReactSelect
                                                        options={dealers}
                                                        name={'clientID'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={clientID}
                                                        classNamePrefix={`${errors["clientID"]} basic-select`}
                                                        removeClearable={true}
                                                        isSearchable={true}
                                                    ></ReactSelect>
                                                </div>
                                                )
                                            }

                                            <div className="form-group col-md-6 daterange-fix">
                                                <label ><Translate text={'eventDate'} /> </label>
                                                <AntDateRangePicker
                                                    value={startDate && endDate ? [moment.unix(startDate.seconds).tz(fields.timezone), moment.unix(endDate.seconds).tz(fields.timezone)] : null}
                                                    id={"startDate-range"}
                                                    name={'startDate-range'}
                                                    format='DD/MM/YYYY'
                                                    onChange={(e) => { this.handleDateRangeChange(e, 'startDate') }}
                                                    placeholder='DD/MM/YYYY'
                                                    className={`date-range-ams-fix ${this.state.errors["startDate"] ? 'custom_error' : ''}`}
                                                />
                                            </div>
                                            <div className={`form-group col-md-3`}>
                                                <label ><Translate text={'startTime'} /></label>
                                                <ReactTimePicker
                                                    value={startDate ? moment(moment.unix(startDate.seconds).tz(fields.timezone).format('YYYY-MM-DD HH:mm')).toDate() : null}
                                                    name={'startDate'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["startDate"]}`}
                                                />
                                            </div>
                                            <div className={`form-group col-md-3`}>
                                                <label ><Translate text={'endTime'} /></label>
                                                <ReactTimePicker
                                                    value={endDate ? moment(moment.unix(endDate.seconds).tz(fields.timezone).format('YYYY-MM-DD HH:mm')).toDate() : null}
                                                    name={'endDate'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["endDate"]}`}
                                                    minTime={
                                                        startDate ? moment(moment.unix(startDate.seconds).tz(fields.timezone).format('YYYY-MM-DD HH:mm')).toDate() : moment().startOf('day')._d
                                                    }
                                                    maxTime={moment().endOf('day')._d}
                                                />
                                            </div>

                                            
                                             <div className="form-group col-md-6">
                                                <label ><Translate text={'displayName'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'email display name'}
                                                    className={`form-control ${errors["displayName"]}`}
                                                    name="displayName"
                                                    onChange={this.handleOnNameChange}
                                                    value={displayName}
                                                />
                                            </div> 
                                            <div className="form-group col-md-3">
                                                <label > <Translate text={'Level'} /></label>
                                                <ReactSelect
                                                    options={(dealersettings.level === userLevel.OEM || _.isEmpty(dealersettings.level) || !_.find(levelOptions, { value: dealersettings.level })) ? levelOptions :
                                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: dealersettings.level }).index)}
                                                    name={"level"}
                                                    placeholder={'select level'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={fields.level}
                                                    classNamePrefix={`${errors["level"]} basic-select`}
                                                    removeClearable={true}
                                                //isDisabled={lookup.disableDelete}
                                                >
                                                </ReactSelect>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <label ><Translate text={'visibility'} /></label>
                                                <ReactSelect
                                                    options={visibilities}
                                                    name={"visibility"}
                                                    placeholder={'select visibility'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={visibility ? visibility : 'private'}
                                                    classNamePrefix={`basic-select`}
                                                    menuPlacement='top'
                                                    removeClearable={true}
                                                    isSearchable={false}
                                                >
                                                </ReactSelect>
                                            </div>


                                            {
                                                (fields.level === userLevel.INDIVIDUAL || fields.level === userLevel.GROUP || fields.level === userLevel.SUBREGION) && (<div className="form-group col-md-12">
                                                    <label ><Translate text={'regions'} /> </label>
                                                    <ReactMultiSelect
                                                        options={regions}
                                                        name={"regionIDs"}
                                                        placeholder={'filter by regions'}
                                                        onChange={this.handleMultiSelectChange}
                                                        value={regionIDs}
                                                        classNamePrefix={`${errors["regionIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement='bottom'
                                                    >
                                                    </ReactMultiSelect>
                                                </div>)
                                            }
                                            {
                                                (fields.level === userLevel.INDIVIDUAL || fields.level === userLevel.GROUP) && (<div className="form-group col-md-12">
                                                    <label ><Translate text={'subRegions'} /> </label>
                                                    <ReactMultiSelect
                                                        options={this.loadSubRegions()}
                                                        name={"subregionIDs"}
                                                        placeholder={'filter by subregions'}
                                                        onChange={this.handleMultiSelectChange}
                                                        value={subregionIDs}
                                                        classNamePrefix={`${errors["subregionIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement='bottom'
                                                    >
                                                    </ReactMultiSelect>
                                                </div>)
                                            }
                                            {
                                                (fields.level === userLevel.INDIVIDUAL) && (<div className="form-group col-md-12">
                                                    <label ><Translate text={'groups'} /> </label>
                                                    <ReactMultiSelect
                                                        options={this.loadGroups()}
                                                        name={"groupIDs"}
                                                        placeholder={'filter by groups'}
                                                        onChange={this.handleMultiSelectChange}
                                                        value={groupIDs}
                                                        classNamePrefix={`${errors["groupIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement='bottom'
                                                    >
                                                    </ReactMultiSelect>
                                                </div>)
                                            }
                                            {
                                                visibility === 'shared'
                                                    ?
                                                    <>

                                                        {
                                                            (!_.isEmpty(fields.level) && fields.level !== userLevel.OEM && fields.level !== userLevel.INDIVIDUAL) ? (<div className="form-group col-md-12">
                                                                <label ><Translate text={fields.level === userLevel.GROUP ? 'Groups' : fields.level === userLevel.REGION ? 'regions' : fields.level === userLevel.SUBREGION ? 'subRegions' : 'Clients'} /></label>
                                                                <ReactMultiSelect
                                                                    options={
                                                                        fields.level === userLevel.GROUP ? this.loadGroups(true) :
                                                                            fields.level === userLevel.REGION ? this.loadRegions(true) :
                                                                                fields.level === userLevel.SUBREGION ? this.loadSubRegions(true) :
                                                                                    this.loadDealers(true)
                                                                    }
                                                                    name={'levelID'}
                                                                    placeholder={`select ${fields.level}`}
                                                                    onChange={(rec) => { this.handleReactMultiSelectChange(rec, 'levelID') }}
                                                                    value={fields.levelID ? _.isArray(fields.levelID) ? fields.levelID : [fields.levelID] : []}
                                                                    classNamePrefix={` ${errors["levelID"]} basic-select`}
                                                                    removeClearable={true}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>) : fields.level == userLevel.INDIVIDUAL && (
                                                                <>
                                                                    <div className="form-group col-md-12">
                                                                        <label ><Translate text={'Clients'} /> </label>
                                                                        <ReactMultiSelect
                                                                            options={this.loadDealers(true)}
                                                                            name={"clientIDs"}
                                                                            placeholder={'select clients'}
                                                                            onChange={this.handleMultiSelectChange}
                                                                            value={clientIDs}
                                                                            classNamePrefix={`${errors["clientIDs"]} basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                            menuPlacement='bottom'
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div>
                                                                </>)
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (!_.isEmpty(fields.level) && fields.level !== userLevel.OEM && fields.level !== userLevel.INDIVIDUAL) ? (<div className="form-group col-md-12">
                                                                <label ><Translate text={fields.level} /></label>
                                                                <ReactSelect
                                                                    options={
                                                                        fields.level === userLevel.GROUP ? this.loadGroups() :
                                                                            fields.level === userLevel.REGION ? this.loadRegions() :
                                                                                fields.level === userLevel.SUBREGION ? this.loadSubRegions() :
                                                                                    this.loadDealers()
                                                                    }
                                                                    name={'levelID'}
                                                                    placeholder={`select ${fields.level}`}
                                                                    onChange={this.handleLevelSelectChange}
                                                                    value={fields.levelID ? _.isArray(fields.levelID) ? fields.levelID[0] : fields.levelID : null}
                                                                    classNamePrefix={` ${errors["levelID"]} basic-select`}
                                                                    removeClearable={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>) : fields.level == userLevel.INDIVIDUAL && (
                                                                <>
                                                                    <div className="form-group col-md-12">
                                                                        <label ><Translate text={'client'} /> </label>
                                                                        <ReactSelect
                                                                            options={this.loadDealers()}
                                                                            name={'clientIDs'}
                                                                            placeholder={'select client'}
                                                                            onChange={this.handleLevelSelectChange}
                                                                            value={fields.clientIDs ? _.isArray(fields.clientIDs) ? fields.clientIDs[0] : fields.clientIDs : null}
                                                                            classNamePrefix={`${errors["clientIDs"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={true}
                                                                        ></ReactSelect>
                                                                    </div>
                                                                </>)
                                                        }
                                                    </>
                                            }

                                            <div className="form-group col-md-12">
                                                <label><Translate text={'description'} /></label>

                                                {/* <InputTextArea
                                                    className={`form-control ${errors["description"]}`}
                                                    name="description"
                                                    onChange={this.handleOnAreaChange}
                                                    value={description}
                                                    maxLength={5000}
                                                    rows="4">
                                                </InputTextArea> */}
                                                <TextEditor
                                                    name={'description'}
                                                    placeholder={'event description'}
                                                    value={description}
                                                    onChange={(obj) => {
                                                        let state = Object.assign({}, this.state.fields);
                                                        state['description'] = obj.value;
                                                        this.setState({ fields: state });
                                                    }}
                                                    toolbarOptions={['inline', 'list', 'textAlign']}
                                                    height={250}
                                                />

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>


                        <span className={`switch switch-sm float-left mt-2`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`enableEOI-active`}
                                name={'enableEOI'}
                                checked={enableEOI ? enableEOI : false}
                                onChange={(e) => { this.handleCheckChange(e) }} />
                            <label htmlFor={`enableEOI-active`}><Translate text={'enableEOI'} /></label>
                        </span>
                        <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>
                        <button type="button" className="btn btn-default float-right" onClick={(e) => { e.preventDefault(); this.props.handleClose()}}>
                            <Translate text={'cancel'} />
                        </button>
                    </Modal.Footer>


                </Modal>
                <ImageCropHook
                    cropShow={this.state.cropShow}
                    handleClose={this.handleCropClose}
                    handleSelect={this.handleCropSelect}
                ></ImageCropHook>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>
        );
    }
}

