import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import moment from 'moment'
import CommonHelper from  '../../services/common';
import { PopUpModal } from '../../components';
import DisplayRecipients from './displayRecipients';
 
const SMSCampaignPreview = props => {
	const { data , clientUsers, clientID } = props;
	const [state, setState] = useState({});
	const [recipientLoader, setRecipientLoader] = useState(true);
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 380,
		windowHeight: window.innerHeight - 273,
	});
	const [displayRecipients, setDisplayRecipients] = useState({
		show: false,
		title: '',
		type: ''
	});

	useEffect(() => {
		const fetchData = async () => {
			window.smsCampaignSnapshot = await window.firebase.firestore().collection('smsCampaigns').doc(data.documentID).onSnapshot(async snapshot => {
				let smsCampaignData = {}
				if (snapshot.exists) {
					smsCampaignData = snapshot.data()
					setState(smsCampaignData)
					setRecipientLoader(false) 
				}	
				setRecipientLoader(false)
			})
		}
		fetchData()

		return () => {
			window.smsCampaignSnapshot && window.smsCampaignSnapshot()
		}
	}, [state.documentID]);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 380,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							{props.data && (
								<div className='container-fluid pl-0 '>
									{recipientLoader ? (
										<div className='loader-center-screen'>
											<div className='spinner-loader h-100 undefined '>
												<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
													<div
														role='status'
														className='spinner-border loader-primary'
													></div>
													<p className='text-muted mt-3'></p>
												</div>
											</div>
										</div>
									) : (
										<>
											<div className='bulksms-view-wrapper-head'>
												<div className='float-left'>
													<div className='bulksms-add-title'>
														{state.title}{' '}
													</div>
													<div className='bulksms-add-sub-title'>
														<div className={`badge badge-pill badge-${state.status === 'sent' ? 'available' : state.status === 'sending' ? 'pending' : 'allocated'}-fill float-left`}>
															{state.status === 'sent' ? 'SENT' : state.status === 'sending' ? 'SENDING' : 'SCHEDULED'}
														</div>
														{state.statu !== 'sent' ? (<></>) : (
															<span className='float-left bulksms-add-date'>
																{' '}
																Sent on {moment(state.modifiedDate.toDate()).format('DD/MM/YYYY hh:mm A')}
															</span>
														)}
														{state.status === 'scheduled' && state.scheduleDate && (
															<span className='float-left bulksms-add-date'>
																{' '}
																Scheduled for {moment(state.scheduleDate).format('DD/MM/YYYY hh:mm A')}
															</span>
														)}
													</div>
												</div>
											</div>
											<div
												className='bulksms-view-wrapper'
												style={{ minHeight: '800px' }}
											>
												<div className='tabs-wraper'>
													<div className='bulksms-view-tab-header'>
														<div className='float-left'>
															<h2>Message Overview</h2>
														</div>
													</div>
													<div className='bulksms-view-tab-middle mt-3'>
														<div className='row text-center'>
															<div className='col'>
																<div className='bulksms-counter' onClick={() => setDisplayRecipients({show: true, title: 'Recipients'})}>
																	<h2 className='bulksms-count-title'>{state.recipientsCount}</h2>
																	<p className='bulksms-count-text '> Recipients</p>
																</div>
															</div>
															<div className='col'>
																<div className='bulksms-counter'onClick={() => setDisplayRecipients({show: true, title: 'Delivered'})}>
																	<h2 className='bulksms-count-title '>{state.deliveredCount ? state.deliveredCount : '0'}</h2>
																	<p className='bulksms-count-text '>Delivered</p>
																</div>
															</div>
															<div className='col'>
																<div className='bulksms-counter'onClick={() => setDisplayRecipients({show: true, title: 'Failed'})}>
																	<h2 className='bulksms-count-title '>{state.failedCount ? state.failedCount : '0'}</h2>
																	<p className='bulksms-count-text '>Failed</p>
																</div>
															</div>
														</div>
														<div className='common-table mt-4'>
															<table className='table table-bordered'>
																<tbody>
																	<tr>
																		<th className='head-light'>Message Title</th>
																		<td>{state.title}</td>
																	</tr>
																	<tr>
																		<th className='head-light'>Message Content</th>
																		<td>
																			{state.content}
																		</td>
																	</tr>
																	{state.statu !== 'sent' ? (<></>) : (
																		<tr>
																			<th className='head-light'>
																				Delivery date &amp; time
																			</th>
																			<td>{moment(state.modifiedDate.toDate()).format('DD/MM/YYYY hh:mm A')}</td>
																		</tr>
																	)}
																	{!state.scheduleDate ? (<></>) : (
																		<tr>
																			<th className='head-light'>
																				Schedule date &amp; time
																			</th>
																			<td>{moment(state.scheduleDate).format('DD/MM/YYYY hh:mm A')}</td>
																		</tr>
																	)}
																	<tr>
																		<th className='head-light'>Owner</th>
																		<td>{CommonHelper.getUserNamebyId(clientUsers, state.addedBy)}</td>
																	</tr>
																	<tr>
																		<th className='head-light'>Recipients</th>
																		<td>Contacts - {state.recipientsCount}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							)}
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={displayRecipients.show}>
					<DisplayRecipients
						show={displayRecipients.show}
						handleClose={() => { setDisplayRecipients({show: false, title: ''})}}
						title={displayRecipients.title}
						data={data}
						campaignID={props.campaign_id}
					/>
			</PopUpModal>
		</>
	);
};

export default SMSCampaignPreview;
