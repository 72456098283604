import React, { useState, useEffect } from 'react'
import "../../../styles/fleetpro-external.scss";
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas'

import { docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import {
    
    ImageViewer, PopUpModal, 
    InputTextArea,
    
    ImageCanvas, ContentOverlay
} from '../../../components';
import Translate from '../../../constants/translate';

import { defaultfleetAssignTypes, fleetAssignStatus, fleetAssignTypes } from '../../../services/enum'
import { fleetAssignStatusLevel } from '../viewModel'

const AssignConfirmation = (props) => {
    const [fleetProAssign, setfleetProAssign] = useState({})
    const [fields, setfields] = useState({})
    const [errors, setErrorFields] = useState({})
    const [trimmedDataURL, setTrimmedDataURL] = useState(null)
    const [showInspection, setshowInspection] = useState({ show: false, id: null, marks: null, isReadOnly: false })
    const [loading, setLoading] = useState(false)
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const { fleetPro, client, fleetproSettings, users } = fleetProAssign

    const assign_types = [
        { value: 'retailerEvent', label: 'Retailer Event' },
        { value: 'extIntEvent', label: 'External/Internal Event' },
        { value: 'loanVehicle', label: 'Loan Vehicle' }
    ]
    let signPad = {}

    const _allUser = Object.assign([], fleetProAssign?.users);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _viewType = params.get('type');

    // const fleetProAllStatus = Object.assign([], fleetProAssign?.fleetproSettings?.fleetProStatus)
    // let _fleetStatus = fleetPro ? CommonHelper.getFleetStatus(fleetPro) : '';
    // let _objCurrentStatus = _fleetStatus && fleetProAllStatus.filter(e => e.value === _fleetStatus)[0];

    let _isDeclined = [fleetAssignStatus.CANCELLED, fleetAssignStatus.DECLINED].includes(fields?.status) ? true : false;
    let _isApproved = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == fields?.status && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.APPROVED })?.index)[0]) ? true : false;
    let _isBooked = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == fields?.status && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.RECEIVED })?.index)[0]) ? true : false;
    let _isCompleted = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == fields?.status && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.RELEASED })?.index)[0]) ? true : false;

    let _canModify = (_isDeclined) ? false : true;
    let _approvedClass = _isApproved || !_canModify ? 'form-lite' : ''
    let _BookedClass = _isBooked || !_canModify ? 'form-lite' : ''
    let _completedClass = _isCompleted || !_canModify ? 'form-lite' : ''

    useEffect(() => {

        let refCollectionDatat;
        if (props.assignid) {

            refCollectionDatat = docData(window.firebase.firestore().doc(`fleetProAssign/${props.assignid}`))
                .pipe(
                    switchMap(assigns => {
                        const fleetProID = assigns.fleetProID;
                        const oemID = assigns?.fleetPro?.oemID;
                        return combineLatest(
                            of(assigns),
                            (fleetProID ? docData(window.firebase.firestore().doc(`fleetPro/${fleetProID}`)) : of({})),
                            (oemID ? docData(window.firebase.firestore().doc(`clientSettings/${oemID}/currentSettings/${oemID}`)) : of({})),
                            (oemID ? docData(window.firebase.firestore().doc(`clientSettings/${oemID}/currentSettings/${oemID}/fleetPro/${oemID}`)) : of({}))
                        )
                    }),
                    map(([fleetProAssign, fleetPro, currentSettings, fleetproSettings]) => {
                        return {
                            fields: fleetProAssign,
                            fleetPro: fleetPro,
                            client: currentSettings,
                            fleetproSettings: fleetproSettings,
                            users: currentSettings?.users,
                        }
                    })
                )
                .subscribe(_assigns => {



                    //console.log('_assigns', _assigns);
                    if (!_.isEmpty(_assigns)) {
                        setfields(_assigns.fields)
                        setfleetProAssign(_assigns)
                    }

                });
            return () => {
                refCollectionDatat && refCollectionDatat.unsubscribe()
            }
        }
    }, [])


    const onCurrencyChange = (e, name) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
        setfields({ ...fields, [name]: e.floatValue })
        //setErrorFields(name);
    }

    const handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setfields({
            ...fields,
            [name]: value ? value.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() }) : ''
        })

        setErrorFields({
            ...errors,
            [name]: ''
        });

    }

    const handleInspectionClose = (marks, id) => {

        if (!_.isEmpty(marks) || (!_.isEmpty(fields[id]) && _.isEmpty(marks))) {
            setfields({
                ...fields,
                [id]: marks
            })
            setshowInspection({ show: false, id: null, marks: null, isReadOnly: false })

            setErrorFields({
                ...errors,
                [id]: ''
            });

        }
        else {
            setshowInspection({ show: false, id: null, marks: null, isReadOnly: false })

        }
    }

    const handleReceive = async () => {


        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';
        //let fields = Object.assign({}, fields);


        let _mandStatusFields = fleetProAssign?.fleetproSettings?.mandatoryFields?.[fields.assignType]?.split(',');
        _mandStatusFields && _mandStatusFields.filter(e => ['preInspection', 'preOdometer', 'preNotes'].includes(e)).forEach(key => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        if (signPad.getSignaturePad()._isEmpty) {
            formIsValid = false;
            errors.preSignature = errorClass;
            //Swal.fire('Please provide a signature.', '', 'info')
        }

        if (fields.odometer && fields.preOdometer && parseInt(fields.odometer) > parseInt(fields.preOdometer)) {
            formIsValid = false;
            errors.preOdometer = errorClass;
            Swal.fire('End odometer reading should be equal or greater than the current odometer reading', '', 'info')
        }


        //console.log('errors', _mandStatusFields, errors, fields)
        setErrorFields(errors)
        let imageBase64 = signPad.getTrimmedCanvas().toDataURL('image/png')
        //console.log(imageBase64)
        if (formIsValid === true) {

            Swal.fire({
                title: 'Are you sure?',
                text: `Do you want mark this fleet as received.`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then(result => {
                if (result.value) {
                    saveReceive(imageBase64);
                }
            })

        }
        else {
            return formIsValid;
        }
    };

    const saveReceive = (imageBase64) => {
        setLoading(true)

        let uid = moment()._d.getTime().toString();

        var storageRef = window.firebase.storage().ref(`${fleetPro.oemID}/fleetProAssign/preSignature/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                window.firebase.storage().ref(`${fleetPro.oemID}/fleetProAssign/preSignature`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log('saveImageToStorage-2', dataURL);

                        let objData = {}
                        objData.documentID = props.assignid;
                        objData.preInspection = fields.preInspection;
                        objData.preNotes = fields.preNotes;
                        objData.preOdometer = fields.preOdometer;
                        objData.preSignature = dataURL;
                        objData.status = fleetAssignStatus.RECEIVED;
                        objData.receivedByName = fields.name;
                        objData.receivedDate = window.firebase.firestore.Timestamp.now();
                        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                        objData = CommonHelper.removeEmptyOrNull(objData);

                        let _reqObject = {
                            "documentID": objData.documentID,
                            "collection": `fleetProAssign`,
                            "data": objData
                        }

                        //console.log('_reqObject', _reqObject)
                        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                        genericSaveData(_reqObject).then((response) => {
                            //console.log(response);
                            if (response.data.success) toast.notify(`Fleet ${objData.status} successfully`, { duration: 2000 });
                            setLoading(false)

                        });



                    })
            })
    };

    const handleReturn = async () => {


        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';
        //let fields = Object.assign({}, fields);


        let _mandStatusFields = fleetProAssign?.fleetproSettings?.mandatoryFields?.[fields.assignType]?.split(',');
        _mandStatusFields && _mandStatusFields.filter(e => ['postInspection', 'postOdometer', 'postNotes'].includes(e)).forEach(key => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        if (signPad.getSignaturePad()._isEmpty) {
            formIsValid = false;
            errors.postSignature = errorClass;
            //Swal.fire('Please provide a signature.', '', 'info')
        }

        let _odo = fields.preOdometer ? fields.preOdometer : fields.odometer
        if (_odo && fields.postOdometer && parseInt(_odo) > parseInt(fields.postOdometer)) {
            formIsValid = false;
            errors.postOdometer = errorClass;
            Swal.fire('End odometer reading should be equal or greater than the current odometer reading', '', 'info')
        }


        //console.log('errors', _mandStatusFields, errors, fields)
        setErrorFields(errors)
        let imageBase64 = signPad.getTrimmedCanvas().toDataURL('image/png')
        //console.log(imageBase64)
        if (formIsValid === true) {

            Swal.fire({
                title: 'Are you sure?',
                text: `Do you want mark this fleet as return.`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then(result => {
                if (result.value) {
                    saveReturn(imageBase64);
                }
            })

        }
        else {
            return formIsValid;
        }
    };

    const saveReturn = (imageBase64) => {
        setLoading(true)

        let uid = moment()._d.getTime().toString();

        var storageRef = window.firebase.storage().ref(`${fleetPro.oemID}/fleetProAssign/postSignature/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                window.firebase.storage().ref(`${fleetPro.oemID}/fleetProAssign/postSignature`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log('saveImageToStorage-2', dataURL);

                        let objData = {}
                        objData.documentID = props.assignid;
                        objData.postInspection = fields.postInspection;
                        objData.postNotes = fields.postNotes;
                        objData.postOdometer = fields.postOdometer;
                        objData.postSignature = dataURL;
                        objData.status = fleetAssignStatus.RELEASED;
                        objData.releasedByName = fields.name;
                        objData.releasedDate = window.firebase.firestore.Timestamp.now();
                        objData.modifiedDate = window.firebase.firestore.Timestamp.now();

                        if (fields.assignType === fleetAssignTypes.LoanVehicle)
                            objData.endDate = window.firebase.firestore.Timestamp.now();
                        objData = CommonHelper.removeEmptyOrNull(objData);

                        let _reqObject = {
                            "documentID": objData.documentID,
                            "collection": `fleetProAssign`,
                            "data": objData
                        }

                        //console.log('_reqObject', _reqObject)
                        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                        genericSaveData(_reqObject).then((response) => {
                            ///console.log(response);
                            if (response.data.success) toast.notify(`Fleet ${objData.status} successfully`, { duration: 2000 });
                            setLoading(false)

                        });

                    })
            })
    };

    return (
        <>
            {
                !_.isEmpty(fleetProAssign) && !_.isEmpty(fields)
                    ?
                    <>
                        <div className="fleetpro-externalpage-header">
                            <div className="container">
                                <div className="fleetpro-externalpage-logo-wrap">

                                    {
                                        !_.isEmpty(client) && client.logoURL
                                            ?
                                            <><span className="fleetpro-externalpage-logo-img"> <img src={client.logoURL} height="60" /></span></>
                                            :
                                            <><div className="feedbackform-logo-title">{!_.isEmpty(client) ? client.name : '--'}</div></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container">

                            <div className="fleetpro-externalpage-wrap-bg">
                                <div className="fleetpro-externalpage-wrap">

                                    <div className="fleetpro-externalpage-loop">

                                        <div className="fleetpro-externalpage-subhead">Fleet Assign</div>

                                        {/* <div className="fleetpro-externalpage-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet mi quis accumsan rutrum. Fusce at tempus mauris, non aliquet nisi.</div> */}

                                        <div className="fleetpro-externalpage-readonly-box">
                                            <h3> {
                                                fields?.assignType ?
                                                    <>
                                                        {
                                                            fields?.assignType === fleetAssignTypes.LoanVehicle
                                                                ?
                                                                <i className="ico icon-module-loan"></i>
                                                                :
                                                                <>
                                                                    {
                                                                        fields?.assignType === fleetAssignTypes.ExternalInternalEvent
                                                                            ?
                                                                            <i className="ico icon-events-module-fill"></i>
                                                                            :
                                                                            <i className="ico icon-dealers"></i>
                                                                    }
                                                                </>
                                                        }

                                                        {CommonHelper.getNameByValue(defaultfleetAssignTypes, fields.assignType)}</>
                                                    :
                                                    <>
                                                    </>
                                            }</h3>

                                            {fields.status ?
                                                <div className="float-right">
                                                    <div className={`badge badge-pill badge-status-${fields.status.trim().toLowerCase()} `}>
                                                        {fields.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                    </div></div>
                                                : <></>}
                                        </div>

                                        <div className="fleetpro-externalpage-sectionhead">Vehicle Info</div>

                                        <div className="detailhead">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="detailtbl01">
                                                <tbody>
                                                    <tr>
                                                        <td className="grid-two mob-grid"><div> <span>MAKE</span>
                                                            <div>{fleetPro?.make || '--'}</div>
                                                        </div></td>
                                                        <td className="grid-two"><div> <span>MODEL</span>
                                                            <div className="cap-ltr">{fleetPro?.model || '--'}</div>
                                                        </div></td>
                                                        <td className="grid-two"><div> <span>YEAR</span>
                                                            <div className="cap-ltr">{fleetPro?.year || '--'}</div>
                                                        </div></td>
                                                        <td className="grid-two no-brdright"><div> <span>Colour</span>
                                                            <div className="cap-ltr">{fleetPro?.extColor || '--'}</div>
                                                        </div></td>
                                                    </tr>
                                                    <tr>

                                                        <td className="grid-two mob-grid"><div> <span>Category </span>
                                                            <div>{CommonHelper.getNameByValue(fleetProAssign?.fleetproSettings?.category, fleetPro?.category, '--')}</div>
                                                        </div></td>
                                                        <td className="grid-two"><div> <span>VIN Number </span>
                                                            <div className="cap-ltr">{fleetPro?.vinNo || '--'}</div>
                                                        </div></td>
                                                        <td className="grid-two"><div> <span>Reg. Number</span>
                                                            <div className="cap-ltr">{fleetPro?.regNo || '--'}</div>
                                                        </div></td>
                                                        <td className="grid-two no-brdright"><div> <span>Mileage</span>
                                                            <div className="cap-ltr">{fleetPro?.mileage ? <NumberFormat style={{ fontSize: 12 }} value={fleetPro.mileage} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <>{'--'}</>}</div>
                                                        </div></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                            <div className="clear"></div>
                                        </div>


                                        <div className="fleetpro-externalpage-sectionhead">Fleet Info</div>


                                        <div className="fleetpro-externalpage-loopbox">

                                            <div className="fleetpro-externalpage-innerhead">
                                                <h3>

                                                    {fields.eventName ? <> <i className="ico icon-events-event mr-2"></i>{fields.eventName}</> : <></>}
                                                    {fields.eventType && fields?.assignType === fleetAssignTypes.ExternalInternalEvent && (<div title='Event Type' className="fleetpro-externalpage-tag">
                                                        <div className="badge badge-pill badge-input-white mr-1">
                                                            {fields.eventType}
                                                        </div>
                                                    </div>)}
                                                </h3>


                                                <div className="externalpage-sub-info ">
                                                    <div className="float-left mt-1">
                                                        <ul className="externalpage-sub-details">
                                                            {fields.startDate ? <>
                                                                <i className="ico icon-date mr-2"></i>{CommonHelper.showCombineDate(fields.startDate, ([fleetAssignTypes.LoanVehicle].includes(fields.assignType) && fields.status !== fleetAssignStatus.RELEASED ? fields.expectedReturnDate : fields.endDate), fields.timezone)}
                                                                <i className="ico icon-time ml-2 mr-2"></i> {CommonHelper.showCombineTime(fields.startDate, ([fleetAssignTypes.LoanVehicle].includes(fields.assignType) && fields.status !== fleetAssignStatus.RELEASED ? fields.expectedReturnDate : fields.endDate), fields.timezone, null)}</> : <></>}

                                                            {fields.purpose && (<> <i className="ico icon-files-document ml-2 mr-2" title='Purpose'></i> {fields.purpose}</>)}
                                                            {fields.odometer ? (<> <i className="ico icon-odometer-ico ml-2 mr-2" title="Odometer"></i> <NumberFormat style={{ fontSize: 12 }} value={fields.odometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /></>) : (<></>)}
                                                            {fields.location && (<> <i className="ico icon-location ml-2 mr-2" title='Location'></i> {fields.location}</>)}


                                                        </ul>
                                                    </div>

                                                </div>




                                                <div className="fleetpro-externalpage-approve-bg">
                                                    <div className="fleetpro-externalpage-approve">

                                                        {fields.requestedBy && (<span>
                                                            Requested By: <b>{CommonHelper.getUserNamebyId(_allUser, fields.requestedBy)}</b>
                                                            &nbsp;on&nbsp; <b>{fields.requestedDate ? moment.unix(fields.requestedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                        </span>)}

                                                        {fields.assignBy && (<span>
                                                            Assign By: <b>{CommonHelper.getUserNamebyId(_allUser, fields.assignBy)}</b>
                                                            &nbsp;on&nbsp; <b>{fields.assignDate ? moment.unix(fields.assignDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                        </span>)}

                                                        {fields.approvedBy && (<span>
                                                            Approved By: <b>{CommonHelper.getUserNamebyId(_allUser, fields.approvedBy)}</b>
                                                            &nbsp;on&nbsp; <b>{fields.approvedDate ? moment.unix(fields.approvedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                        </span>)}

                                                        {fields.status === fleetAssignStatus.DECLINED && (<span>
                                                            Declined By: <b>{CommonHelper.getUserNamebyId(_allUser, fields.modifiedBy)}</b>
                                                            &nbsp;on&nbsp; <b>{fields.modifiedDate ? moment.unix(fields.modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                        </span>)}

                                                        {fields.status === fleetAssignStatus.CANCELLED && (<span>
                                                            Cancelled By: <b>{CommonHelper.getUserNamebyId(_allUser, fields.modifiedBy)}</b>
                                                            &nbsp;on&nbsp; <b>{fields.modifiedDate ? moment.unix(fields.modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                        </span>)}

                                                    </div>
                                                </div>
                                            </div>

                                            {(fields.name || fields.phone || fields.email) && (<div className="fleetpro-externalpage-responsibleinfo">

                                                <div className="fleetpro-externalpage-responsibleinfo-head">Responsible Person</div>
                                                <div className="fleetpro-externalpage-responsibleinfo-list">
                                                    <ul>
                                                        {
                                                            fields.name && (<li> <i className="ico icon-Contacts"></i> {fields.name}</li>)
                                                        }
                                                        {
                                                            fields.phone && (<li> <i className="ico icon-call"></i> {fields.phone}</li>)
                                                        }
                                                        {
                                                            fields.email && (<li> <i className="ico icon-email"></i> {fields.email}</li>)
                                                        }
                                                        {
                                                            fields.companyName && (<li> <i className="ico icon-company"></i> {fields.companyName}</li>)
                                                        }
                                                        {
                                                            fields.address && (<li> <i className="ico icon-location"></i> {fields.address}</li>)
                                                        }
                                                    </ul>
                                                </div>
                                            </div>)
                                            }

                                            <div className="fleetpro-externalpage-inspection">

                                                <div className="fleetpro-externalpage-inspection-head">Vehicle Images/Inspection
                                                    <div className="float-right">
                                                        <button className={`btn btn-sm btn-inspection-multi ml-1 ${errors["inspection"]}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            setshowInspection({ show: true, id: 'inspection', marks: fields?.inspection, isReadOnly: true })
                                                        }}>
                                                            <i className={CommonHelper.getVehicleIcons(client?.category, 'mr-1 ico icon-inspection')}></i>
                                                            <Translate text={'vehicleInspection'} />
                                                            {!_.isEmpty(fields?.inspection) ? <div className="float-right">   <div className="badge badge-inspection ml-2">{fields.inspection.length}</div> </div> : <></>}
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className="externalpage-pic">
                                                    <div className="float-left">
                                                        <ul>
                                                            {
                                                                !_.isEmpty(fields?.inspection) && !_.isEmpty(fields.inspection.filter(a => !_.isEmpty(a.images))[0])
                                                                    ?
                                                                    fields.inspection.map((mark, index) => {
                                                                        return <li key={index}>
                                                                            {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                <a key={_index}
                                                                                    data-fancy-box={`testdrive-before-${fields.documentID}`}
                                                                                    className="venobox vbox-item"
                                                                                    href={data}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setImgViewer({
                                                                                            show: true,
                                                                                            images: mark.images,
                                                                                            index: _index
                                                                                        })
                                                                                    }}>
                                                                                    <img id={`testdrive-before-${index}-${_index}-${fields.documentID}`} src={data} className="img-object-fit" width="70" height="70" />
                                                                                </a>
                                                                            ))}
                                                                        </li>
                                                                    })
                                                                    :
                                                                    <>
                                                                        <li><a className="venobox vbox-item" href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} /></a></li>
                                                                    </>
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>

                                            {
                                                fields?.notes && (<div className="fleetpro-externalpage-notes">
                                                    <div className="fleetpro-externalpage-notes-head">Notes</div>
                                                    <div className="fleetpro-externalpage-notes-wrap">
                                                        <div className="fleetpro-externalpage-notes-inner text-area-space">
                                                            {fields?.notes || ''}
                                                            {/* <div className="fleetpro-externalpage-notes-sub">Williams David 07/12/2023 12:40 PM </div> */}
                                                        </div>
                                                    </div>

                                                </div>)
                                            }

                                        </div>

                                        {
                                            _isApproved && (<>
                                                {
                                                    _BookedClass
                                                        ?
                                                        <>

                                                            <div className="fleetpro-externalpage-sectionhead">Received Info</div>
                                                            <div className="fleetpro-externalpage-loopbox">

                                                                <div className="fleetpro-externalpage-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Received Odometer</td>
                                                                                <td><span className="fleetpro-info">{fields.preOdometer ? <NumberFormat style={{ fontSize: 12 }} value={fields.preOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Received Date</td>
                                                                                <td><span className="fleetpro-info">{fields.receivedDate ? moment.unix(fields.receivedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Received By</td>
                                                                                <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, fields.receivedBy, (fields.receivedByName || '--'))}</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div className="fleetpro-externalpage-inspection">
                                                                    <div className="fleetpro-externalpage-inspection-head">Vehicle Images/Inspection
                                                                        <div className="float-right">
                                                                            <button className={`btn btn-sm btn-inspection-multi ml-1 ${errors["preInspection"]}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setshowInspection({ show: true, id: 'preInspection', marks: fields?.preInspection, isReadOnly: _isBooked })
                                                                            }}>
                                                                                <i className={CommonHelper.getVehicleIcons(client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                                <Translate text={'vehicleInspection'} />
                                                                                {!_.isEmpty(fields?.preInspection) ? <div className="float-right">   <div className="badge badge-inspection ml-2">{fields.preInspection.length}</div> </div> : <></>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="externalpage-pic">
                                                                        <div className="float-left">
                                                                            <ul>
                                                                                {
                                                                                    !_.isEmpty(fields?.preInspection) && !_.isEmpty(fields.preInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                                        ?
                                                                                        fields.preInspection.map((mark, index) => {
                                                                                            return <li key={index}>
                                                                                                {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                                    <a key={_index}
                                                                                                        data-fancy-box={`testdrive-pre-${fields.documentID}`}
                                                                                                        className="venobox vbox-item"
                                                                                                        href={data}
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            setImgViewer({
                                                                                                                show: true,
                                                                                                                images: mark.images,
                                                                                                                index: _index
                                                                                                            })
                                                                                                        }}>
                                                                                                        <img id={`testdrive-pre-${index}-${_index}-${fields.documentID}`} src={data} className="img-object-fit" width="70" height="70" />
                                                                                                    </a>
                                                                                                ))}
                                                                                            </li>
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                            <li><a className="venobox vbox-item" href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} /></a></li>
                                                                                        </>
                                                                                }
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {
                                                                    fields?.preNotes && (<div className="fleetpro-externalpage-notes">

                                                                        <div className="fleetpro-externalpage-notes-head">Received Notes</div>
                                                                        <div className="fleetpro-externalpage-notes-wrap">
                                                                            <div className="fleetpro-externalpage-notes-inner text-area-space">
                                                                                {fields?.preNotes || ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>)
                                                                }


                                                                <div className="fleetpro-externalpage-signpreview">
                                                                    <div className="fleetpro-externalpage-signpreview-head">Signature</div>
                                                                    <div className="fleetpro-externalpage-signpreview-wrap">
                                                                        <div className="fleetpro-externalpage-signpreview-image">
                                                                            <img src={fields.preSignature ? fields.preSignature : _images.nosign} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>

                                                            <div className="fleetpro-externalpage-sectionhead">Receive Info</div>

                                                            <div className="fleetpro-externalpage-loopbox">

                                                                <div className="fleetpro-externalpage-inspection bg-fix-inspection">
                                                                    <div className="fleetpro-externalpage-inspection-head">Vehicle Images/Inspection
                                                                        <div className="float-right">
                                                                            {/* <button className="btn btn-sm btn-inspection-multi undefined">
                                                                        <i className="mr-1 ico icon-inspection"></i>Inspection</button> */}

                                                                            <button className={`btn btn-sm btn-inspection-multi ml-1 ${errors["preInspection"]}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setshowInspection({ show: true, id: 'preInspection', marks: fields?.preInspection, isReadOnly: _isBooked })
                                                                            }}>
                                                                                {/* {!_.isEmpty(fields?.preInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>} */}
                                                                                <i className={CommonHelper.getVehicleIcons(client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                                <Translate text={'vehicleInspection'} />
                                                                                {!_.isEmpty(fields?.preInspection) ? <div className="float-right">   <div className="badge badge-inspection ml-2">{fields.preInspection.length}</div> </div> : <></>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="fleetpro-externalpage-form form-style">
                                                                    <div className="fleetpro-externalpage-form-head"><h4>Current Odometer Reading</h4></div>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-4">
                                                                            <NumberFormat
                                                                                id={'preOdometer'}
                                                                                thousandSeparator={true}
                                                                                placeholder={'current odometer reading'}
                                                                                className={`form-control ${errors["preOdometer"]}`}
                                                                                onValueChange={(e) => onCurrencyChange(e, 'preOdometer')}
                                                                                value={fields?.preOdometer ? fields.preOdometer : null}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="fleetpro-externalpage-form-head"><h4>Release Notes</h4></div>
                                                                    <div className="fleetpro-externalpage-form-notes ">
                                                                        <InputTextArea
                                                                            className={`form-control notes-externalpage-fleet ${errors["preNotes"]}`}
                                                                            name="preNotes"
                                                                            onChange={handleOnAreaChange}
                                                                            value={fields?.preNotes ? fields.preNotes : null}
                                                                            maxLength={5000}
                                                                            rows="4">
                                                                        </InputTextArea>
                                                                    </div>

                                                                    <div className="fleetpro-externalpage-form-sign"><h4>Signature</h4></div>
                                                                    <div className={`fleetpro-externalpage-form-canvas vehicle-map  ${errors["preSignature"]}`}>
                                                                        <SignatureCanvas
                                                                            penColor='black'
                                                                            ref={(ref) => { signPad = ref }}
                                                                            canvasProps={{ width: 800, height: 200, className: `sigCanvas`, src: _images.sign }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="accordion-bottom approve-btn">

                                                                <a href="#"
                                                                    className={`btn btn-common float-left mt-3 `}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        signPad.clear();
                                                                        setTrimmedDataURL(null);
                                                                    }}>
                                                                    <Translate text={'clear'} upperCase={true} />
                                                                </a>
                                                                <a href="#" className="btn btn-common button-black float-right mt-3" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleReceive();
                                                                }}>
                                                                    {loading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                    <Translate text={'receiveVehicle'} upperCase={true} />
                                                                </a>
                                                                <div className="clear"> </div>
                                                            </div>
                                                        </>
                                                }
                                            </>)
                                        }

                                        {
                                            _isBooked && (<>
                                                {
                                                    _completedClass
                                                        ?
                                                        <>

                                                            <div className="fleetpro-externalpage-sectionhead">Released Info</div>
                                                            <div className="fleetpro-externalpage-loopbox">

                                                                <div className="fleetpro-externalpage-table">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Released Odometer</td>
                                                                                <td><span className="fleetpro-info">{fields.postOdometer ? <NumberFormat style={{ fontSize: 12 }} value={fields.postOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Released Date</td>
                                                                                <td><span className="fleetpro-info">{fields.releasedDate ? moment.unix(fields.releasedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Released By</td>
                                                                                <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, fields.releasedBy, (fields.releasedByName || '--'))}</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div className="fleetpro-externalpage-inspection">
                                                                    <div className="fleetpro-externalpage-inspection-head">Vehicle Images/Inspection
                                                                        <div className="float-right">
                                                                            <button className={`btn btn-sm btn-inspection-multi ml-1 ${errors["postInspection"]}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setshowInspection({ show: true, id: 'postInspection', marks: fields?.postInspection, isReadOnly: _isCompleted })
                                                                            }}>
                                                                                <i className={CommonHelper.getVehicleIcons(client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                                <Translate text={'vehicleInspection'} />
                                                                                {!_.isEmpty(fields?.postInspection) ? <div className="float-right">   <div className="badge badge-inspection ml-2">{fields.postInspection.length}</div> </div> : <></>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="externalpage-pic">
                                                                        <div className="float-left">
                                                                            <ul>
                                                                                {
                                                                                    !_.isEmpty(fields?.postInspection) && !_.isEmpty(fields.postInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                                        ?
                                                                                        fields.postInspection.map((mark, index) => {
                                                                                            return <li key={index}>
                                                                                                {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                                    <a key={_index}
                                                                                                        data-fancy-box={`testdrive-post-${fields.documentID}`}
                                                                                                        className="venobox vbox-item"
                                                                                                        href={data}
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            setImgViewer({
                                                                                                                show: true,
                                                                                                                images: mark.images,
                                                                                                                index: _index
                                                                                                            })
                                                                                                        }}>
                                                                                                        <img id={`testdrive-post-${index}-${_index}-${fields.documentID}`} src={data} className="img-object-fit" width="70" height="70" />
                                                                                                    </a>
                                                                                                ))}
                                                                                            </li>
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                            <li><a className="venobox vbox-item" href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} /></a></li>
                                                                                        </>
                                                                                }
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {
                                                                    fields?.postNotes && (<div className="fleetpro-externalpage-notes">

                                                                        <div className="fleetpro-externalpage-notes-head">Released Notes</div>
                                                                        <div className="fleetpro-externalpage-notes-wrap">
                                                                            <div className="fleetpro-externalpage-notes-inner text-area-space">
                                                                                {fields?.postNotes || ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>)
                                                                }


                                                                <div className="fleetpro-externalpage-signpreview">
                                                                    <div className="fleetpro-externalpage-signpreview-head">Signature</div>
                                                                    <div className="fleetpro-externalpage-signpreview-wrap">
                                                                        <div className="fleetpro-externalpage-signpreview-image">
                                                                            <img src={fields.postSignature ? fields.postSignature : _images.nosign} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                _viewType === 'return' && (<>
                                                                    <div className="fleetpro-externalpage-sectionhead">Released Info</div>

                                                                    <div className="fleetpro-externalpage-loopbox">

                                                                        <div className="fleetpro-externalpage-inspection bg-fix-inspection">
                                                                            <div className="fleetpro-externalpage-inspection-head">Vehicle Images/Inspection
                                                                                <div className="float-right">
                                                                                    {/* <button className="btn btn-sm btn-inspection-multi undefined">
            <i className="mr-1 ico icon-inspection"></i>Inspection</button> */}

                                                                                    <button className={`btn btn-sm btn-inspection-multi ml-1 ${errors["postInspection"]}`} onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setshowInspection({ show: true, id: 'postInspection', marks: fields?.postInspection, isReadOnly: _isCompleted })
                                                                                    }}>
                                                                                        {/* {!_.isEmpty(fields?.postInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>} */}
                                                                                        <i className={CommonHelper.getVehicleIcons(client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                                        <Translate text={'vehicleInspection'} />
                                                                                        {!_.isEmpty(fields?.postInspection) ? <div className="float-right">   <div className="badge badge-inspection ml-2">{fields.postInspection.length}</div> </div> : <></>}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="fleetpro-externalpage-form form-style">
                                                                            <div className="fleetpro-externalpage-form-head"><h4>Current Odometer Reading</h4></div>
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-4">
                                                                                    <NumberFormat
                                                                                        id={'postOdometer'}
                                                                                        thousandSeparator={true}
                                                                                        placeholder={'current odometer reading'}
                                                                                        className={`form-control ${errors["postOdometer"]}`}
                                                                                        onValueChange={(e) => onCurrencyChange(e, 'postOdometer')}
                                                                                        value={fields?.postOdometer ? fields.postOdometer : null}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="fleetpro-externalpage-form-head"><h4>Released Notes</h4></div>
                                                                            <div className="fleetpro-externalpage-form-notes ">
                                                                                <InputTextArea
                                                                                    className={`form-control notes-externalpage-fleet ${errors["postNotes"]}`}
                                                                                    name="postNotes"
                                                                                    onChange={handleOnAreaChange}
                                                                                    value={fields?.postNotes ? fields.postNotes : null}
                                                                                    maxLength={5000}
                                                                                    rows="4">
                                                                                </InputTextArea>
                                                                            </div>

                                                                            <div className="fleetpro-externalpage-form-sign"><h4>Signature</h4></div>
                                                                            <div className={`fleetpro-externalpage-form-canvas vehicle-map  ${errors["postSignature"]}`}>
                                                                                <SignatureCanvas
                                                                                    penColor='black'
                                                                                    ref={(ref) => { signPad = ref }}
                                                                                    canvasProps={{ width: 800, height: 200, className: `sigCanvas`, src: _images.sign }}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="accordion-bottom approve-btn">

                                                                        <a href="#"
                                                                            className={`btn btn-common float-left mt-3 `}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                signPad.clear();
                                                                                setTrimmedDataURL(null);
                                                                            }}>
                                                                            <Translate text={'clear'} upperCase={true} />
                                                                        </a>
                                                                        <a href="#" className="btn btn-common button-black float-right mt-3" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleReturn();
                                                                        }}>
                                                                            {loading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                            <Translate text={'returnVehicle'} upperCase={true} />
                                                                        </a>
                                                                        <div className="clear"> </div>
                                                                    </div>
                                                                </>)
                                                            }

                                                        </>
                                                }
                                            </>)
                                        }


                                        <footer>
                                            <div className="footer-cpy">
                                                <div className="footer-bottom">
                                                    <div className="cpy-wrap"> <img src={_images.safeTag} width="120" alt="" /></div>
                                                    <div className="cpy-wrap">
                                                        <span style={{ paddingLeft: 0 }} >
                                                            {client.name ? <strong>{client.name}</strong> : <></>}
                                                        </span>
                                                        <span style={{ paddingLeft: 0 }}>
                                                            {client?.address ? <> &nbsp; | &nbsp;<span style={{ paddingLeft: 0 }} >{client.address}</span></> : <></>}
                                                            {client?.phone ? <> &nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                            {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                            {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                            {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                        </span>
                                                    </div>
                                                    <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                                    <div className="clear"></div>
                                                </div>
                                            </div>
                                        </footer>



                                    </div>
                                </div>
                            </div>
                        </div>

                        <PopUpModal show={showInspection.show}>
                            <ImageCanvas
                                show={showInspection.show}
                                marks={showInspection.marks}
                                fieldID={showInspection.id}
                                isReadOnly={_canModify ? showInspection.isReadOnly : true}
                                clsActive='overlay-modal active'
                                imagePath='fleetProAssign'
                                handleClose={handleInspectionClose}
                                dealersettings={{
                                    ...client,
                                    'client': { ...client }
                                }}
                                showCost={false}
                                isCompressedImage={true}
                            ></ImageCanvas>
                        </PopUpModal>
                        <PopUpModal show={imageViewer.show}>
                            <ImageViewer
                                {...imageViewer}
                                handleClose={() => { setImgViewer({ show: false, images: null }); }}
                            />
                        </PopUpModal>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default AssignConfirmation;