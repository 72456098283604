/** LIBRARIES */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Tooltip as Ttip } from 'antd';
import Translate from '../../constants/translate';
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allStockFieldsVM, objStock } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { ContentOverlay, ImageViewer, PopUpModal, DealerSelect, AutoGrabScan, PopoverPanel } from '../../components';
import FilterPanel from './filter'
import ReAuthenticate from '../common/reAuthenticate';
import { stockStatus, stockScoreGrades, stockPriceScore, stockCommentsScore, stockAgeScore, stockManagementScore, stockPlateChangeScore } from '../../services/enum'
import { default as _images } from '../../images';
import AddStock from '../stock/add'
import StockHistory from './history'
import OEMStock from './oemStock/oemStock'
import AutoGrab from "../tradeIn/autograb";

//import StockListReducer from './stockListReducer'
import FleetReducer from '../fleet/fleetReducer'
import { firestoreDB } from '../../services/helper';


const stockTabsVM = [
    { active: true, value: 'Pipeline', name: 'Pipeline', id: 'pipeline' },
    { active: true, value: 'Stock', name: 'Stock', id: 'stock' },
    { active: true, value: 'Sold', name: 'Sold', id: 'sold' },
    { active: true, value: 'Delivered', name: 'Delivered', id: 'delivered' },
    { active: true, value: 'Unavailable', name: 'unavailable', id: 'unavailable' },
]



const StockList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    // const [stocks, dispatch] = useReducer(StockListReducer, [])//
    // const [checkDataLoad, setDataload] = useState(true)//
    const [stocks, dispatch] = useReducer(FleetReducer, { Stock: [], Pipeline: [], Delivered: [], Sold: [], Unavailable: [] })
    const [checkDataLoad, setDataload] = useState({ "Stock": true, "Pipeline": true, "Delivered": true, "Sold": true, "Unavailable": true })
    //const [sortName, setSortName] = useState(localStorage.stocksortName ? localStorage.stocksortName : 'modifiedDate')
    //const [sortOrder, setSortOrder] = useState(localStorage.stocksortOrder ? localStorage.stocksortOrder : 'desc')
    const [hasMore, setHasMoreData] = useState([])
    const [stockFields, setStockFields] = useState(props.dealersettings.stockFields)
    //const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [OEMShow, setOEMShow] = useState(false)
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [autograbSearch, SetAutograbSearch] = useState({ show: false, clientID: null, regNo: null, chassisNo: null, milesMandatory: null })
    const [autoGrabModal, SetAutograbModal] = useState({ show: false, tradeinID: "", selectedVehicle: {}, title: "", clsActive: "" })
    //const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 10

    const [stockLoader, setStockLoader] = useState({ "Stock": true, "Pipeline": true, "Delivered": true, "Sold": true, "Unavailable": true })
    const [activeTab, setActiveTab] = useState('')
    const [stockTabs, setTabs] = useState(stockTabsVM)
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)

    const [csvHeader, setHeader] = useState([])

    const [searchText, setSearchText] = useState(localStorage.stockSearchText ? localStorage.stockSearchText : null)
    const [stockCount, setStockCount] = useState({})
    const [stockALLCount, setStockALLCount] = useState(0)
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const enableSharedStock = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedStock) ? true : false);

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const deleteStock = ((!_.isEmpty(_permissions) && _permissions.deleteStock) ? true : false);
    const accessToPipelineStock = ((!_.isEmpty(_permissions) && _permissions.accessToPipelineStock) ? true : false);
    const accessToStockVBM = ((!_.isEmpty(_permissions) && _permissions.accessToStockVBM) ? true : false);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    const _servicePermissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.servicePermissions)) ? props.dealersettings.rolePermissions.servicePermissions : null);
    const deleteServiceStock = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteServiceStock) ? true : false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const vbmcurrencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.vbm)) ? props.dealersettings.client.vbm.symbol + ' ' : '$ ');

    const stockCredit = ((props.dealersettings?.client?.clientSettings?.stockCredit) ? true : false);
    const enquiryAllStatus = Object.assign([], props.dealersettings?.client?.settings?.enquiryStatus)
    const soldName = (_.find(enquiryAllStatus, { value: 'won' })?.name || 'Sold')
    const deliveredName = (_.find(enquiryAllStatus, { value: 'delivered' })?.name || 'Delivered')

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [showDealerModal, setDealerModal] = useState(false);
    const [editClientID, setEditClient] = useState()
    const [customScroll, setCustomScroll] = useState()
    const [costPopOver, setcostPopOver] = useState({ showpopover: false, targetpopover: null, logs: null })

    let allStockFieldsListVM = [...allStockFieldsVM];
    allStockFieldsListVM = allStockFieldsListVM.filter(item => accessToStockVBM ? true : item.group !== 'VBM')

    const isOEMModule = localStorage.defaultModule === 'oem' ? true : false;

    const accessToMarketOverlay = localStorage.defaultModule !== 'tradeinPro' ? ((!_.isEmpty(_permissions) && _permissions.accessToMarketOverlay) ? true : false) : true;

    const _autograbIntegrations = Object.assign({}, props.dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
    const autoGrabEnabled = _autograbIntegrations?.active && _autograbIntegrations?.enabled ? true : ((!_.isEmpty(props.dealersettings?.client?.moduleSettings?.autograb) &&
        props.dealersettings.client.moduleSettings.autograb.enabled &&
        props.dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : false);

    const stockLocations = props.dealersettings?.client?.clientSettings?.stockLocations
    const stockOptionsStatic = props.dealersettings?.client?.settings?.stockOptionsStatic

    const hideStockPrice = (props.dealersettings?.rolePermissions?.permissions?.hideStockPrice) ? true : false;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length >= 2) {
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
            }

            if (searchText === '')
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    useEffect(() => {

        allStockFieldsListVM.forEach((obj) => {
            if (obj.value === 'stockInDate' && props.dealersettings.isMclaren === true) {
                obj.name = 'Wholesale Date';
            }
            else if (obj.value === 'soldDate') {
                obj.name = CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date';
            }
            else if (obj.value === 'deliveredDate') {
                obj.name = CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
            }
        });

        let headerFields = null;//stockFields;
        let allHeaderFields = allStockFieldsListVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        if (isOEMModule || enableSharedStock || !_.isEmpty(stockLocations))
            _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
                var index = _.findIndex(_headers, (e) => {
                    return e.key == rec.value;
                }, 0)
                if (index >= 0) {
                    _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
                }
                else {
                    _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
                }
            })
        setHeader(_headers);

    }, [stockFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubStocks && window.unSubStocks();
        }

    }, [])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            try {

                //console.log(`accessToPipelineStock`, accessToPipelineStock)
                let _stockTabs = Object.assign([], stockTabsVM);
                if (!accessToPipelineStock) {
                    _stockTabs = _stockTabs.filter(e => e.value !== 'Pipeline');
                    handleChangeTab('Stock')
                }
                else {
                    handleChangeTab('Pipeline')
                }

                if (!_.isEmpty(enquiryAllStatus) && _.find(enquiryAllStatus, { value: 'delivered' })) {
                    _stockTabs.forEach(data => {
                        if (data.value === 'Sold') {
                            data.name = _.find(enquiryAllStatus, { value: 'won' }).name
                        }
                        else if (data.value === 'Delivered') {
                            data.name = _.find(enquiryAllStatus, { value: 'delivered' }).name
                        }
                    })
                }
                setTabs(_stockTabs)

            }
            catch (error) {
                console.error(error)
            }
        }
    }, [])

    useEffect(() => {

        if (_.isEmpty(localStorage.stockFilter)) {
            try {
                loadStockCount();
            }
            catch (error) {
                console.error(error)
            }

        }
    }, [isFilterApplied, searchText])


    const loadStockCount = () => {

        let _searchObject = {};
        const { dealersettings } = props;
        if (isOEMModule && !_.isEmpty(dealersettings.group.clients)) {
            _searchObject.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
        }
        else {
            if (!_.isEmpty(stockLocations)) _searchObject.clientID = _.uniq(stockLocations).join(',');
            else if (enableSharedStock && dealersettings.client.group) {
                _searchObject.groupID = dealersettings.client.group
            }
            else { _searchObject.clientID = dealersettings.client.id }
        }

        try {

            if (searchText && searchText.trim()) {
                _searchObject.keywords = searchText.trim().toLowerCase();
            }

            let _searchObj = {
                type: "getStockCount",
                params: JSON.stringify(_searchObject),
            }
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            getData(_searchObj).then((resp) => {
                if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
                    const data = resp.data.data[0]
                    setStockCount(data);
                    //console.log(`data of the counts`, data);
                }

            });

            const addContact = window.firebase.functions().httpsCallable('stock-getCount');
            addContact(_searchObject).then((_data) => {
                if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
                    setStockALLCount(_data.data[0].stock_count);
                }

            });


        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'stocks')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.stockFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.stockFilter])

    useEffect(() => {

        if (!checkDataLoad[activeTab] || !activeTab) {
            return;
        }

        const { dealersettings } = props;
        let stocKAllStatus = Object.assign([], dealersettings?.client?.settings?.stockStatus);
        //console.log(_.map(dealersettings?.client?.settings?.stockStatus, function (e) { return e.name }))
        let _genericConditions = (isFilterApplied || localStorage.stockFilter || isOEMModule || (!_.isEmpty(stockLocations) && activeTab === 'Stock'))
        if (_genericConditions) {
            let _stockFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
            let _filter = Object.assign({}, _stockFilter.value);
            let _isWhere = false;
            let _sortOrder = (_filter?.sorting?.name || 'modifiedDate') + ' ' + (_filter?.sorting?.type || 'desc');

            if (!_filter.owner)
                delete _filter['owner'];

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            if (!_.isEmpty(_filter.stockAge)) {
                _filter.dateType = 'stockInDate';
                _filter.startDate = moment().add(parseInt(`-${_filter.stockAge[1]}`), 'days').format('YYYY-MM-DD');
                _filter.endDate = moment().add(parseInt(`-${_filter.stockAge[0]}`), 'days').format('YYYY-MM-DD');
                // _filter.startDate = moment().add(parseInt(`-${_filter.stockAge}`), 'days').format('YYYY-MM-DD');
                // _filter.endDate = moment().format('YYYY-MM-DD');
            }

            if (!_.isEmpty(_filter.priceAge)) {
                _filter.dateType = 'priceDate';
                _filter.startDate = moment().add(parseInt(`-${_filter.priceAge[1]}`), 'days').format('YYYY-MM-DD');
                _filter.endDate = moment().add(parseInt(`-${_filter.priceAge[0]}`), 'days').format('YYYY-MM-DD');
            }

            if (!_.isEmpty(_filter.plateAge)) {
                _filter.dateType = 'regNoDate';
                _filter.startDate = moment().add(parseInt(`-${_filter.plateAge[1]}`), 'days').format('YYYY-MM-DD');
                _filter.endDate = moment().add(parseInt(`-${_filter.plateAge[0]}`), 'days').format('YYYY-MM-DD');
            }

            if (!_.isEmpty(_filter.priceNoDays))
                _filter.priceNoDate = moment().add(parseInt(`-${_filter.priceNoDays}`), 'days').format('YYYY-MM-DD');

            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

            if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();

            let _filterStatus = _filter.status;
            if (activeTab === 'Stock') {
                let _allStatus = _.map(dealersettings?.client?.settings?.stockStatus.filter(m => ![stockStatus.INPRODUCTION, stockStatus.DELIVERED, stockStatus.SOLD, stockStatus.UNAVAILABLE].includes(m.value)), function (e) { return e.value })
                _filter.status = _filter.status ?
                    ((_filter.status.includes('available') ||
                        _filter.status.includes('onLoan') ||
                        _filter.status.includes('onTestdrive') ||
                        _filter.status.includes('reserved') ||
                        _allStatus.filter(item => _filter?.status?.split(',').includes(item))) ?
                        _allStatus.filter(item => _filter?.status?.split(',').includes(item)).join(',')
                        : 'unknown')
                    : _allStatus.join(',')
            }
            else if (activeTab === 'Pipeline') {
                _filter.status = _filter.status ? (_filter.status.includes('inProduction') ? 'inProduction' : 'unknown') : 'inProduction'
            }
            else if (activeTab === 'Sold') {
                _filter.status = _filter.status ? (_filter.status.includes('sold') ? 'sold' : 'unknown') : 'sold'
            }
            else if (activeTab === 'Delivered') {
                _filter.status = _filter.status ? (_filter.status.includes('delivered') ? 'delivered' : 'unknown') : 'delivered'
            }
            else if (activeTab === 'Unavailable') {
                _filter.status = _filter.status ? (_filter.status.includes('unavailable') ? 'unavailable' : 'unknown') : 'unavailable'
            }

            if (_.isEmpty(_filter.clientID)) {
                if (isOEMModule && !_.isEmpty(dealersettings.group.clients))
                    _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
                else {
                    if (!_.isEmpty(stockLocations)) _filter.clientID = _.uniq(stockLocations).join(',');
                    else if (enableSharedStock && dealersettings.client.group)
                        _filter.groupID = dealersettings.client.group
                    else
                        _filter.clientID = dealersettings.client.id;
                }
            }

            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
            let _searchObject = {
                "type": "searchStock",
                "filters": JSON.stringify(_filter),
                "sortOrder": _sortOrder,
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            if (_filter.groupID) _searchObject.groupID = _filter.groupID
            if (_filter.clientID) _searchObject.clientID = _filter.clientID

            //   console.log('generic-searchData', isFilterApplied, pageNum, _searchObject, localStorage.stockFilter)
            const searchStock = window.firebase.functions().httpsCallable('generic-searchData');
            searchStock(_searchObject).then((response) => {
                //console.log('generic-searchStock', response);
                if (response.data.success && (_genericConditions)) {
                    onCollectionStocks(response.data);
                    //setStockCount(response.data.total)
                }
                else {
                    setDataload({
                        ...checkDataLoad,
                        [activeTab]: false
                    })
                    setStockLoader({
                        ...stockLoader,
                        [activeTab]: false
                    })
                    setPaging(false)
                }
            });

            let _searchObj = {
                type: "getStockCount",
                params: JSON.stringify({ ..._filter, status: _filterStatus }),
            }
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            getData(_searchObj).then((resp) => {
                if (!_.isEmpty(resp) && !_.isEmpty(resp.data) && resp.data.success) {
                    const data = resp.data.data[0]
                    setStockCount(data);
                    //console.log(`getStockCount`, data, '_searchObj', _searchObj)
                }

            });


            // let _searchObj = {
            //     type: "getStockCount",
            //     params: JSON.stringify(_filter),
            // }                
            // const getData = window.firebase.functions().httpsCallable('generic-getData');
            // const resp = await getData(_searchObj);
            // if (resp.data.success) {

            //     const data = resp.data.data[0]
            //     console.log(`searchStock`, data)

            // }

            //window.unSubStocks && window.unSubStocks();
        }
        else {
            let collectionDataRef = window.firebase.firestore().collection('stock')
                .where('isDeleted', '==', false)

            //collectionDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(collectionDataRef, props.dealersettings);
            if (!_.isEmpty(stockLocations)) collectionDataRef = collectionDataRef.where('clientID', 'in', CommonHelper.arrLimit(_.uniq(stockLocations), 30))
            else if (enableSharedStock && dealersettings.client.group)
                collectionDataRef = collectionDataRef.where('groupID', '==', dealersettings.client.group)
            else
                collectionDataRef = collectionDataRef.where('clientID', '==', dealersettings.client.id)


            if (searchText && searchText.trim()) {
                collectionDataRef = collectionDataRef
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            let _status = _.map(dealersettings?.client?.settings?.stockStatus.filter(m => ![stockStatus.INPRODUCTION, stockStatus.DELIVERED, stockStatus.SOLD, stockStatus.UNAVAILABLE].includes(m.value)), function (e) { return e.value })
            if (activeTab === 'Stock') {
                collectionDataRef = collectionDataRef
                    .where("status", "in", _status)
            }
            else if (activeTab === 'Pipeline') {
                collectionDataRef = collectionDataRef
                    .where("status", "==", stockStatus.INPRODUCTION);
            }
            else if (activeTab === 'Delivered') {
                collectionDataRef = collectionDataRef
                    .where("status", "==", stockStatus.DELIVERED);
            }
            else if (activeTab === 'Sold') {
                collectionDataRef = collectionDataRef
                    .where("status", "==", stockStatus.SOLD);
            }
            else if (activeTab === 'Unavailable') {
                collectionDataRef = collectionDataRef
                    .where("status", "==", stockStatus.UNAVAILABLE);
            }

            collectionDataRef = collectionDataRef
                .orderBy('modifiedDate', 'desc')

            let lastRecord = hasMore.filter(e => e.type === activeTab);
            if (lastRecord.length > 0) {
                collectionDataRef = collectionDataRef
                    .startAfter(lastRecord[0].lastDoc)
                    .limit(pageLimit)
            }
            else {
                collectionDataRef = collectionDataRef
                    .limit(pageLimit)
            }

            window.unSubStocks = collectionDataRef
                .onSnapshot(onCollectionUpdate);
        }

        // return () => {
        //     window.unSubStocks();
        // }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const stocks = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                //setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                stocks.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc',
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc',
                activetab: activeTab
            });
        }

        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setStockLoader({
            ...stockLoader,
            [activeTab]: false
        })

        setPaging(false)
    }

    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            //setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            {
                type: activeTab,
                lastDoc: querySnapshot.data[querySnapshot.data.length - 1]

            }])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        let _stockFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
        let _filter = Object.assign({}, _stockFilter.value);

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _stocks,
            activetab: activeTab,
            sortName: (_filter?.sorting?.name || 'modifiedDate'),
            sortOrder: (_filter?.sorting?.type || 'desc')
        });

        setStockLoader({
            ...stockLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }

    /*const onQueryCollectionStocks = (querySnapshot, _filter) => {

        let _stocks = [];

        if (querySnapshot.docs.length > 0) {
            setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.docs.forEach(function (doc) {
            var dataVM = dataMappingVM({ ...doc.data(), 'documentID': doc.id });
            _stocks.push(dataVM)
        });

        _stocks = _stocks.filter(item => (
            ((_filter.dateType && _filter.startDate && _filter.endDate && item[`${_filter.dateType}Value`])
                ? (item[`${_filter.dateType}Value`].seconds >= moment(_filter.startDate).unix() && item[`${_filter.dateType}Value`].seconds <= moment(_filter.endDate).unix()) : true)
            && (_filter.keywords ? _.indexOf(item.keywords, _filter.keywords.toLowerCase()) >= 0 : true)
            && (_filter.owner ? item.addedByValue === _filter.owner : true)
            && (_filter.status ? item.statusValue === _filter.status : true)
            && (_filter.saleType ? item.saleTypeValue === _filter.saleType : true)
            && (_filter.location ? item.locationValue === _filter.location : true)
            && (_filter.make ? item.make.toLowerCase().includes(_filter.make.toLowerCase()) : true)
            && (_filter.model ? item.model.toLowerCase().includes(_filter.model.toLowerCase()) : true)
            && (_filter.year ? item.year === _filter.year : true)
            && (_filter.isSale ? item.isSale === _filter.isSale : true)
            && (_filter.isTestDrive ? item.isTestDrive === _filter.isTestDrive : true)
            && (_filter.isLoan ? item.isLoan === _filter.isLoan : true)
            && (_filter.isServiceLoan ? item.isServiceLoan === _filter.isServiceLoan : true)
        ))

        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _stocks,
            sortName: 'modifiedDate',
            sortOrder: 'desc'
        });
        setStockCount(0)
        setStockLoader({
            ...stockLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }*/

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }



    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;
        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.regExp = doc.regExp && doc.regExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regExp._seconds)._d) : doc.regExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;


        doc.etaDate = doc.etaDate && doc.etaDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.etaDate._seconds)._d) : doc.etaDate;
        doc.fokDate = doc.fokDate && doc.fokDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.fokDate._seconds)._d) : doc.fokDate;
        doc.soldDate = doc.soldDate && doc.soldDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.soldDate._seconds)._d) : doc.soldDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.firstEnqDate = doc.firstEnqDate && doc.firstEnqDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.firstEnqDate._seconds)._d) : doc.firstEnqDate;
        doc.priceDate = doc.priceDate && doc.priceDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.priceDate._seconds)._d) : doc.priceDate;
        doc.lastNoteDate = doc.lastNoteDate && doc.lastNoteDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lastNoteDate._seconds)._d) : doc.lastNoteDate;
        doc.regNoDate = doc.regNoDate && doc.regNoDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regNoDate._seconds)._d) : doc.regNoDate;

        const objStockData = Object.assign({}, doc);
        let stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }
        if (stock.clientID && (!_.isEmpty(stockLocations) || stock.clientID !== dealersettings.client.id || isOEMModule) && dealersettings.group) {
            stock.dealerName = CommonHelper.getOtherDealerName(dealersettings, stock.clientID);
        }
        else
            stock.dealerName = '';

        stock.objData = objStockData;

        stock.regDateValue = doc.regDate
        stock.regExpValue = doc.regExp
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.soldDateValue = doc.soldDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.usageValue = doc.usage
        stock.transmissionValue = doc.transmission
        stock.transportMethodValue = doc.transportMethod
        stock.createdOn = stock.addedDate ? moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        stock.updatedOn = stock.modifiedDate ? moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        stock.firstEnquiryOn = stock.firstEnqDate ? moment.unix(stock.firstEnqDate.seconds).format('DD/MM/YYYY') : null;

        if (stock.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[stock.clientID] && dealersettings.group.clients[stock.clientID].settings) {
            let _settings = dealersettings.group.clients[stock.clientID].settings;
            stock.location = CommonHelper.getNameByValue(_settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(_settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(_settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(_settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(_settings.salesType, stock.saleType, '');
        }
        else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            stock.location = CommonHelper.getNameByValue(dealersettings.client.settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(dealersettings.client.settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(dealersettings.client.settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(dealersettings.client.settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, stock.saleType, '');
        }
        const hideStockPrice = (dealersettings?.rolePermissions?.permissions?.hideStockPrice) ? true : false;
        stock.purchasePriceValue = doc.purchasePrice ? doc.purchasePrice : '';
        stock.priceValue = doc.price ? doc.price : '';
        stock.price = doc.price ? CommonHelper.formatCurrency(currencySymbol, doc.price, hideStockPrice) : '';
        stock.purchasePrice = doc.purchasePrice ? CommonHelper.formatCurrency(currencySymbol, doc.purchasePrice, hideStockPrice) : '';

        stock.marginValue = doc.marginValue ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginValue) : '';
        stock.marginRetail = doc.marginRetail ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginRetail) : '';
        stock.marginOrderFill = doc.marginOrderFill ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginOrderFill) : '';
        stock.marginPayments = doc.marginPayments ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginPayments) : '';
        stock.marginMarketing = doc.marginMarketing ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginMarketing) : '';

        stock.stockAge = ((!_.isEmpty(stock.stockInDateValue)) ? handleDiffDays(stock.stockInDateValue) : '');

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.regExp))
            stock.regExp = moment.unix(stock.regExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.buildDate))
            stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

        if (!_.isEmpty(stock.soldDate))
            stock.soldDate = moment.unix(stock.soldDate.seconds).format('DD MMM YYYY');
        if (!_.isEmpty(stock.deliveredDate))
            stock.deliveredDate = moment.unix(stock.deliveredDate.seconds).format('DD MMM YYYY');
        if (!_.isEmpty(stock.deliveryDate))
            stock.deliveryDate = moment.unix(stock.deliveryDate.seconds).format('DD MMM YYYY');
        if (!_.isEmpty(stock.etaDate)) {
            stock.csvEtaDate = moment.unix(stock.etaDate.seconds).format('DD MMM YYYY') + (stock.isStockArrived ? ' (Arrived)' : '');
            stock.etaDate = <>
                <>{moment.unix(stock.etaDate.seconds).format('DD MMM YYYY')}</>
                <>{stock.isStockArrived ? <div className="badge badge-pill badge-available badge-mini ml-1">{'ARRIVED'}</div> : <></>}</>
            </>;
        }


        if (!_.isEmpty(stock.fokDate))
            stock.fokDate = moment.unix(stock.fokDate.seconds).format('DD MMM YYYY');
        if (!_.isEmpty(stock.stockInDate))
            stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('DD MMM YYYY');

        stock.statusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stock.status, '');
        stock.status = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stock.status, '');

        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isServiceLoan ? 'Service Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));

        stock.addedBy = CommonHelper.getUserNamebyId(allUsers, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(allUsers, stock.modifiedBy);
        stock.statusUpdatedBy = stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, stock.statusModifiedBy) : '';

        stock = managementRating(stock);

        return stock
    }


    // const managementRatingOld = (stock) => {
    //     stock.soldInDays = !_.isEmpty(stock.stockInDateValue) && !_.isEmpty(stock.soldDateValue) ? moment.unix(stock.soldDateValue.seconds).endOf('day').diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null;
    //     stock.priceChangeDays = stock.soldInDays !== null ? stock.soldInDays : (!_.isEmpty(stock.stockInDateValue) && !_.isEmpty(stock.priceDate) ? moment.unix(stock.priceDate.seconds).endOf('day').diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null);
    //     stock.priceChange_score = _.isNumber(stock.priceChangeDays) ? stockPriceScore.filter(e => stock.priceChangeDays >= e.min && stock.priceChangeDays <= e.max)[0]?.value : 0;

    //     stock.photo_length = !_.isEmpty(stock.images) ? stock.images.length : 0;
    //     stock.photo_score = (stock.photo_length >= 20) ? 10 : 0;

    //     stock.comments_score = !_.isEmpty(stock.lastNote) ? 10 : 0;

    //     stock.commentsChangeDays = !_.isEmpty(stock.stockInDateValue) && !_.isEmpty(stock.lastNoteDate) ? moment.unix(stock.lastNoteDate.seconds).diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null;
    //     stock.commentsChange_score = _.isNumber(stock.commentsChangeDays) ? stockCommentsScore.filter(e => stock.commentsChangeDays >= e.min && stock.commentsChangeDays <= e.max)[0]?.value : 0;

    //     let averagePrice = stock?.marketOverlay?.score?.averagePrice;
    //     stock.priceAR = stock.priceValue && averagePrice && stock.priceValue > averagePrice ? calculateProfitPercentage(averagePrice, stock.priceValue) : 0;
    //     stock.priceAR_score = stock.priceAR ? stockPriceAR.filter(e => stock.priceAR >= e.min && stock.priceAR <= e.max)[0]?.value : 0;

    //     stock.plateChangeDays = !_.isEmpty(stock.stockInDateValue) && !_.isEmpty(stock.regNoDate) ? moment.unix(stock.regNoDate.seconds).diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null;
    //     stock.plateChange_score = _.isNumber(stock.plateChangeDays) ? stockPlateChangeScore.filter(e => stock.plateChangeDays >= e.min && stock.plateChangeDays <= e.max)[0]?.value : 0;

    //     stock.inventoryDays = !_.isEmpty(stock.stockInDateValue) ? moment().diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null;
    //     stock.inventory_score = _.isNumber(stock.inventoryDays) ? stockAgeScore.filter(e => stock.inventoryDays >= e.min && stock.inventoryDays <= e.max)[0]?.value : 0;

    //     stock.management_scores = (stock.priceChange_score || 0) +
    //         (stock.photo_score || 0) +
    //         (stock.comments_score || 0) +
    //         (stock.commentsChange_score || 0) +
    //         (stock.priceAR_score || 0) +
    //         (stock.plateChange_score || 0) +
    //         (stock.inventory_score || 0)

    //     stock.management_rating = stock.management_scores ? stockManagementScore.filter(e => stock.management_scores >= e.min && stock.management_scores <= e.max)[0]?.value : 0;

    //     return stock;
    // }


    const managementRating = (stock) => {

        stock.priceChangeDays = !_.isEmpty(stock.priceDate) ? moment().endOf('day').diff(moment.unix(stock.priceDate.seconds), 'days') : null;
        stock.priceChange_score = _.isNumber(stock.priceChangeDays) ? stockPriceScore.filter(e => stock.priceChangeDays >= e.min && stock.priceChangeDays <= e.max)[0]?.value : 0;

        stock.photo_length = !_.isEmpty(stock.images) ? stock.images.length : 0;
        stock.photo_score = (stock.photo_length >= 20) ? 10 : 0;

        stock.comments_score = !_.isEmpty(stock.lastNote) ? 10 : 0;

        stock.commentsChangeDays = !_.isEmpty(stock.lastNoteDate) ? moment().endOf('day').diff(moment.unix(stock.lastNoteDate.seconds), 'days') : null;
        stock.commentsChange_score = _.isNumber(stock.commentsChangeDays) ? stockCommentsScore.filter(e => stock.commentsChangeDays >= e.min && stock.commentsChangeDays <= e.max)[0]?.value : 0;

        let averagePrice = (stock?.marketOverlay?.score?.statePriceDelisted);
        stock.priceAR = stock.priceValue && _.isNumber(averagePrice) && stock.priceValue > averagePrice ? calculateProfitPercentage(averagePrice, stock.priceValue) : 0;
        stock.priceAR_score = stock.priceAR >= 2 ? 10 : 0;
        stock.priceBR = stock.priceValue && _.isNumber(averagePrice) && stock.priceValue < averagePrice ? calculateProfitPercentage(stock.priceValue, averagePrice) : 0;
        stock.priceBR_score = stock.priceBR >= 2 ? 10 : 0;
        //stock.priceAR_score = stock.priceAR ? stockPriceAR.filter(e => stock.priceAR >= e.min && stock.priceAR <= e.max)[0]?.value : 0;

        stock.plateChangeDays = !_.isEmpty(stock.regNoDate) ? moment().endOf('day').diff(moment.unix(stock.regNoDate.seconds), 'days') : ((stock.regNo && !_.isEmpty(stock.stockInDateValue)) ? moment().diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null);
        stock.plateChange_score = _.isNumber(stock.plateChangeDays) ? stockPlateChangeScore.filter(e => stock.plateChangeDays >= e.min && stock.plateChangeDays <= e.max)[0]?.value : 0;

        stock.inventoryDays = !_.isEmpty(stock.stockInDateValue) ? moment().diff(moment.unix(stock.stockInDateValue.seconds), 'days') : null;
        stock.inventory_score = _.isNumber(stock.inventoryDays) ? stockAgeScore.filter(e => stock.inventoryDays >= e.min && stock.inventoryDays <= e.max)[0]?.value : 0;

        stock.management_scores = (stock.priceChange_score || 0) +
            (stock.photo_score || 0) +
            (stock.comments_score || 0) +
            (stock.commentsChange_score || 0) +
            (stock.priceAR_score || 0) +
            (stock.priceBR_score || 0) +
            (stock.plateChange_score || 0) +
            (stock.inventory_score || 0)

        stock.management_rating = stock.management_scores ? stockManagementScore.filter(e => stock.management_scores >= e.min && stock.management_scores <= e.max)[0]?.value : 0;

        return stock;
    }
    const calculateProfitPercentage = (averagePrice, sellingPrice) => {
        const profitOrLoss = sellingPrice - averagePrice;
        const percentage = (profitOrLoss / averagePrice) * 100;
        return Math.round((percentage > 100) ? 100 : percentage);
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('stockFilter', JSON.stringify(_filter));
            localStorage.stockSearchText = '';
            setFilterApplied(true);
            setStockLoader({
                ...stockLoader,
                [activeTab]: true
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.stockFilter)) {
            localStorage.removeItem('stockFilter');
            localStorage.stockSearchText = '';
            setFilterApplied(false);
            setStockLoader({
                ...stockLoader,
                [activeTab]: true
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
        }
    }

    // const handleSavesettings = (fields, showToast) => {
    //     setSettingsLoader(true)
    //     window.firebase.firestore().doc(`/users/${localStorage.uid}`)
    //         .set({ stockFields: fields }, { merge: true })
    //         .then(() => {
    //             setStockFields(fields)
    //             setSettingsLoader(false)
    //             props.updateDealerSettings('stockFields', fields)
    //             if (showToast)
    //                 toast.notify('Settings updated successfully', {
    //                     duration: 2000
    //                 })

    //         }).catch(error => {
    //             setSettingsLoader(false)
    //             console.error(error)
    //         });

    // }

    // const handleActionClick = (id, data) => {
    //     return (
    //         <div >
    //             <div
    //                 className="mini-button cursor-pointer"
    //                 onClick={(e) => {
    //                     setEditDocument(id);
    //                     setModelHistoryShow(true);
    //                 }}>
    //                 <i className="ico icon-history"></i>
    //             </div>

    //             {
    //                 readOnlyEnquiryView
    //                     ?
    //                     <>
    //                         <div
    //                             className="mini-button cursor-pointer ml-2"
    //                             onClick={(e) => {
    //                                 setEditDocument(id);
    //                                 setModelShow(true);
    //                             }}>
    //                             <i className="ico icon-zoom"></i>
    //                         </div>
    //                     </>
    //                     :
    //                     <>
    //                         <div
    //                             className="mini-button cursor-pointer ml-2"
    //                             onClick={(e) => {
    //                                 setEditDocument(id);
    //                                 setModelShow(true);
    //                             }}>
    //                             <i className="ico icon-edit"></i>
    //                         </div>
    //                     </>
    //             }

    //             {
    //                 deleteStock || deleteServiceStock
    //                     ?
    //                     <>
    //                         <div
    //                             className="mini-button cursor-pointer ml-2"
    //                             onClick={async (e) => {
    //                                 handleDeleteStock(id, data);
    //                             }}
    //                         >
    //                             <i className="ico icon-delete"></i>
    //                         </div>

    //                     </>
    //                     :
    //                     <></>
    //             }

    //         </div>
    //     )
    // }

    const handleDeleteStock = async (id, data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the stock.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {

                let response = await handleCheckStock(id);
                //console.log("response", id, response)
                if (response.success) {
                    let _dataType = response.type + (response.id ? ' (' + response.id + ')' : '');
                    Swal.fire(CommonHelper.showLocale(props, 'Cannot delete stock: It is being used in ') + _dataType + '.', '', 'info')
                    return false;
                }
                else {
                    const { dealersettings } = props;
                    let modifiedBy = dealersettings ? dealersettings.id : '';
                    let modifiedDate = window.firebase.firestore.Timestamp.now();

                    const updateRef = window.firebase.firestore().collection(`stock`).doc(id)
                    updateRef.
                        set({
                            isDeleted: true,
                            modifiedFrom: 'web',
                            modifiedBy,
                            modifiedDate
                        }, { merge: true })
                        .then((docRef) => {
                            toast.notify('Stock successfully deleted.', {
                                duration: 2000
                            })


                            if (!_.isEmpty(data)) {
                                let _stockFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
                                let _filter = Object.assign({}, _stockFilter.value);
                                dispatch({
                                    type: "REMOVE_LIST",
                                    data: data,
                                    activetab: activeTab,
                                    sortName: (_filter?.sorting?.name || 'modifiedDate'),
                                    sortOrder: (_filter?.sorting?.type || 'desc')
                                });
                            }


                            let logNotes = 'Stock deleted';
                            if (!_.isEmpty(data) && data.stockNo)
                                logNotes = 'Stock#: ' + data.stockNo + ' deleted';

                            let _objLogData = {
                                notes: logNotes,
                                type: 'stock',
                                subType: 'delete',
                                recordId: id,
                            }
                            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                            CommonHelper.saveAuditLog(_objLogData);

                        }).catch((error) => {
                            console.error("Error deleting Video: ", error);
                        });
                }
            }
        })
    }

    // const handleDetailsClick = (e, id) => {
    //     e.preventDefault();
    //     //props.history.push("/contacts/details/" + id);
    // }
    const handleCheckStock = async (stockID) => {
        const stockPromises = [];

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('requirement.stock.documentID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collectionGroup('requirements')
            .where('stock.documentID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('stockID', '==', stockID)
            .where('type', '==', 'activity')
            //.where('subType', '==', 'testdrive')
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('testdrives')
            .where('stockID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('fleet')
            .where('stockID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        let isExist = false;
        let _response;
        const snapshots = await Promise.all(stockPromises)
        if (!_.isEmpty(snapshots)) {
            snapshots.some((snap) => {
                if (snap.size > 0) {
                    isExist = true;
                    //console.log(stockID + ': exists')
                    snap.forEach((doc) => {
                        if (doc.ref.path.includes('testdrives')) {
                            _response = { success: true, type: 'Testdrive', data: doc.data() };
                            return true;
                        } else if (doc.ref.path.includes('enquiries') || doc.ref.path.includes('requirements')) {
                            let _resData = doc.data();
                            _response = { success: true, type: 'Enquiry', data: _resData, id: _resData.displayID };
                            return true;
                        } else if (doc.ref.path.includes('fleet')) {
                            _response = { success: true, type: 'Loan Vehicle', data: doc.data() };
                            return true;
                        } else if (doc.ref.path.includes('activities')) {
                            _response = { success: true, type: 'Activity', data: doc.data() };
                            return true;
                        }
                    });
                }
                else {
                    console.log(stockID + ': non-exists')
                }
            })
        }
        else {

            console.log(stockID + ': non-exists-valid')
        }

        if (isExist)
            return _response;
        else
            return { success: false };

    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }
    const handleClose = (id) => {
        if (id) {
            loadStockCount();
        }
        setModelShow(false);
        setEditDocument(null);
        setEditClient(null);
        setCustomScroll(null);
    }
    const handleHistoryClose = (_stock) => {
        setModelHistoryShow(false);
        setEditDocument(null);
        if (isOEMModule || isFilterApplied) updateStock(_stock)
    }

    const handleOEMClose = () => {
        setOEMShow(false);
    }
    const handleOEMOpen = () => {
        setOEMShow(true);
    }

    const handleChangeTab = (_data) => {
        setActiveTab(_data);
        setStockLoader({
            ...stockLoader,
            [_data]: true
        })

        setDataload({
            ...checkDataLoad,
            [_data]: true
        })
        setPaging(false);
        setHasMoreData([...hasMore.filter(e => e.type !== _data)]);
        setPageNum(0);
    }

    const updateStock = (_stock) => {
        if (!_.isEmpty(_stock)) {
            let _data = dataMappingVM(_stock);

            if (!_.isEmpty(_data)) {
                const oldLog = stocks[activeTab].find(item => item.documentID === _stock.documentID);
                let _stockFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
                let _filter = Object.assign({}, _stockFilter.value);
                dispatch({
                    type: (oldLog ? "UPDATE_LIST" : "ADDNEW_LIST"),
                    data: _data,
                    activetab: activeTab,
                    sortName: (_filter?.sorting?.name || 'modifiedDate'),
                    sortOrder: (_filter?.sorting?.type || 'desc')
                });
            }
        }

    }
    const excelMcLarenDownload = async () => {
        const { dealersettings } = props;
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        let _searchObject = {}
        _searchObject.clientID = dealersettings.client.id;
        _searchObject.accessToStockVBM = accessToStockVBM;
        const searchContacts = window.firebase.functions().httpsCallable('reports-McLarenStock');
        const resp = await searchContacts(_searchObject);

        if (resp.data.success) {


            CommonHelper.handleFileDownload(resp.data.data).then(doc => {
                // if (!doc.success)
                //     window.open(resp.data.data, '_blank');

                //console.log('Data downloaded successfully.')
                toast.closeAll();
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })

                let _objLogData = {
                    notes: 'Excel export from McLaren Stock',
                    type: 'excelExport',
                    subType: 'stock'
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                CommonHelper.saveAuditLog(_objLogData);
            });
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

        //console.log('excelDownload', resp)
    }

    const excelDownload = async (total) => {
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        // const isMclarenStock = true;//dealersettings.isMclaren;
        // if (isMclarenStock === true) {
        //     excelMcLarenDownload();
        //     return;
        // }

        let _companyFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
        let _filter = Object.assign({}, _companyFilter.value);
        let _sortOrder = (_filter?.sorting?.name || 'modifiedDate') + ' ' + (_filter?.sorting?.type || 'desc');


        if (!_filter.owner)
            delete _filter['owner'];

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        if (!_.isEmpty(_filter.stockAge)) {
            _filter.dateType = 'stockInDate';
            _filter.startDate = moment().add(parseInt(`-${_filter.stockAge[1]}`), 'days').format('YYYY-MM-DD');
            _filter.endDate = moment().add(parseInt(`-${_filter.stockAge[0]}`), 'days').format('YYYY-MM-DD');
            // _filter.startDate = moment().add(parseInt(`-${_filter.stockAge}`), 'days').format('YYYY-MM-DD');
            // _filter.endDate = moment().format('YYYY-MM-DD');
        }

        if (!_.isEmpty(_filter.priceAge)) {
            _filter.dateType = 'priceDate';
            _filter.startDate = moment().add(parseInt(`-${_filter.priceAge[1]}`), 'days').format('YYYY-MM-DD');
            _filter.endDate = moment().add(parseInt(`-${_filter.priceAge[0]}`), 'days').format('YYYY-MM-DD');
        }

        if (!_.isEmpty(_filter.plateAge)) {
            _filter.dateType = 'regNoDate';
            _filter.startDate = moment().add(parseInt(`-${_filter.plateAge[1]}`), 'days').format('YYYY-MM-DD');
            _filter.endDate = moment().add(parseInt(`-${_filter.plateAge[0]}`), 'days').format('YYYY-MM-DD');
        }

        if (!_.isEmpty(_filter.priceNoDays))
            _filter.priceNoDate = moment().add(parseInt(`-${_filter.priceNoDays}`), 'days').format('YYYY-MM-DD');

        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();

        if (_.isEmpty(_filter.clientID)) {
            if (isOEMModule && !_.isEmpty(dealersettings.group.clients))
                _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
            else {
                if (!_.isEmpty(stockLocations)) _filter.clientID = _.uniq(stockLocations).join(',');
                else if (enableSharedStock && dealersettings.client.group)
                    _filter.groupID = dealersettings.client.group
                else
                    _filter.clientID = dealersettings.client.id;
            }
        }

        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchStock",
            "filters": JSON.stringify(_filter),
            "sortOrder": _sortOrder,
            "pageNum": 0,
            "pageLimit": localStorage.stockFilter ? 5000 : (total ? total : 1000)
        }

        if (_filter.groupID) _searchObject.groupID = _filter.groupID
        if (_filter.clientID) _searchObject.clientID = _filter.clientID

        //console.log('generic-searchData-export', _searchObject)

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchContacts(_searchObject);
        //console.log('generic-searchData', resp);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = dataMappingVM(doc);
                _enquiries.push(logVM)
            });
            // setCSVData(_enquiries)
            // csvBtn.current && csvBtn.current.link.click();      

            setCSVData(CommonHelper.generateExcelData(_enquiries.filter(e => accessToPipelineStock ? true : (e.statusValue !== stockStatus.INPRODUCTION)), csvHeader, props, stockOptionsStatic));
            let _allSheetData = {};

            let _allStatus = _.map(dealersettings?.client?.settings?.stockStatus.filter(m => ![stockStatus.INPRODUCTION, stockStatus.DELIVERED, stockStatus.SOLD, stockStatus.UNAVAILABLE].includes(m.value)), function (e) { return e.value })
            _allSheetData['Stock'] = CommonHelper.generateExcelData(_enquiries.filter(e => _allStatus.includes(e.statusValue)), csvHeader, props, stockOptionsStatic);

            _allSheetData['Pipeline'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === stockStatus.INPRODUCTION), csvHeader, props, stockOptionsStatic);

            _allSheetData['Sold'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === stockStatus.SOLD), csvHeader, props, stockOptionsStatic);

            _allSheetData['Delivered'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === stockStatus.DELIVERED), csvHeader, props, stockOptionsStatic);

            _allSheetData['Unavailable'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === stockStatus.UNAVAILABLE), csvHeader, props, stockOptionsStatic);

            setSheetData(_allSheetData)

            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)

            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from stock',
                type: 'excelExport',
                subType: 'stock',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = 0;
                for (var i in stockCount) {
                    dataArray += parseFloat(data[i] ? data[i] : 0);
                }
                excelDownload(dataArray);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleStock = () => {
        if (isOEMModule) setDealerModal(true);
        else handleShowStock();
    }

    const handleShowStock = async () => {
        if (!_.isEmpty(stockLocations)) {
            const clients = props.dealersettings?.clients;
            const selectOptions = !_.isEmpty(clients) ? clients.filter(m => stockLocations.includes(m.documentID)).reduce((options, { name, documentID }) => {
                options[documentID] = name;
                return options;
            }, {}) : null;


            if (!_.isEmpty(selectOptions)) {
                const { value: _selectedDealer } = await Swal.fire({
                    title: 'Select Stock Location',
                    input: 'select',
                    inputOptions: selectOptions,
                    inputPlaceholder: 'stock location',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to select an location';
                        }
                    }
                });
                if (_selectedDealer) showStockModal(_selectedDealer);
            }
            else {
                showStockModal();
            }
        }
        else {
            showStockModal();
        }
    }

    const showStockModal = (_clientID) => {
        if (_clientID) setEditClient(_clientID);
        setEditDocument(null);
        setModelShow(true);
    }

    const handleDealerSelectClose = (_clientID, _origin, _pipeline) => {
        if (_clientID) {
            showStockModal(_clientID);
        }
        setDealerModal(false);
    }


    //#region AUTOGRAB
    const handleAutoGrabScanOpen = async (data) => {
        let isMiles = data?.miles && data?.miles > 0 && data?.miles !== '0' ? false : true
        if (!_.isEmpty(data?.marketOverlay?.vehicleID)) {
            if (isMiles) {
                //console.log('begin')
                let result = await Swal.fire({
                    title: 'Enter Odometer Reading',
                    input: 'number',
                    inputPlaceholder: 'Odometer Reading',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: (inputValue) => {
                        if (inputValue > 0) {
                            // Process the input value here
                            return inputValue;
                        }
                        else {
                            Swal.showValidationMessage('Odometer Reading cannot be empty');
                        }
                    },
                })
                //console.log('end', result)
                if (result.isConfirmed) {
                    data.miles = Number(result.value)
                    saveStock({ miles: data.miles }, data.documentID);
                    updateStock(data);
                }
                else {
                    return;
                }
            }

            //console.log('handleAutoGrabOpen', data)
            handleAutoGrabOpen(data);

        }
        else {
            SetAutograbSearch({ show: true, data: data, clientID: data?.clientID, regNo: data?.regNo, regState: data?.regState, chassisNo: data?.vinNo, milesMandatory: isMiles });
        }
    }

    const handleAutoGrabScanClose = async (_data, _fields) => {
        let _stock = Object.assign({}, autograbSearch?.data);
        let data = Object.assign({}, _data?.vehicle);
        if (!_.isEmpty(data) && _stock?.documentID && data?.id) {
            let state = {};
            if (data?.id) state.marketOverlay = { vehicleID: data.id };
            if (data.regNo) state.regNo = data.regNo; else if (_fields?.regNum) state.regNo = _fields.regNum;
            if (data.vinNo) state.vinNo = data.vinNo; else if (_fields?.vinNum) state.vinNo = _fields.vinNum;
            if (_fields?.miles) state.miles = _fields.miles;
            if (data.state) state.regState = data.state;

            _stock = { ..._stock, ...state };


            await saveStock(state, _stock.documentID);
            updateStock(_stock);
            handleAutoGrabOpen(_stock)
        }

        SetAutograbSearch({ show: false, data: null, clientID: null, regNo: null, chassisNo: null, milesMandatory: null });
    }

    const handleAutoGrabOpen = (data) => {
        //console.log('handleAutoGrabOpen', data)
        SetAutograbModal({
            show: true,
            tradeinID: "",
            selectedVehicle: {
                ...data,
                autograbID: data?.marketOverlay?.vehicleID,
                chassisNo: data.vinNo || data.stockNo,
                mileage: data.miles,
                regState: data.regState ? data.regState : props.dealersettings?.client?.state
            },
            title: "Market Overlay",
            clsActive: "overlay-modal active"
        })

    }

    const handleAutoGrabClose = (data, _fields) => {
        if (!_.isEmpty(data)) {
            //this.(data, _fields);
        }
        SetAutograbSearch({ show: false, clientID: null, regNo: null, chassisNo: null, milesMandatory: null });
        SetAutograbModal({ show: false, tradeinID: "", selectedVehicle: {}, title: "", clsActive: "" })
    }

    const saveStock = async (_state, id, _stock) => {
        try {
            const { dealersettings } = props;
            let modifiedBy = dealersettings ? dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();

            await window.firebase.firestore().collection(`stock`).doc(id).set({
                ..._state,
                modifiedFrom: 'web',
                modifiedBy,
                modifiedDate
            }, { merge: true })
        } catch (error) {
            console.log(error);
        }

    }
    //#endregion

    const showArrow = (_stock) => {
        let comparePrice = (_stock?.marketOverlay?.score?.statePriceDelisted)

        if (_.isNumber(comparePrice)) {
            return <div onMouseOver={(e) => {
                if (costPopOver.showpopover) setcostPopOver({ showpopover: false, targetpopover: '', logs: null })
            }}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>

                            <div className="stocknew-tooltip stocknew-tooltip-inner">
                                {
                                    (_stock.price > comparePrice)
                                        ?
                                        <div className="stocknew-tooltip-head-red">Above Market Price</div>
                                        :
                                        <div className="stocknew-tooltip-head-green">Below Market Price</div>
                                }

                                {/* {
                                    _stock?.marketOverlay?.prediction?.retail_price &&
                                    (<div className="stocknew-tooltip-text">Retail Average is <span>{CommonHelper.formatCurrency(currencySymbol, _stock?.marketOverlay?.prediction?.retail_price)}</span></div>)
                                } */}
                                {
                                    _stock?.marketOverlay?.score?.averagePrice &&
                                    (<div className="stocknew-tooltip-text">National Average is <span>{CommonHelper.formatCurrency(currencySymbol, _stock?.marketOverlay?.score?.averagePrice)}</span></div>)
                                }

                                {
                                    _stock?.marketOverlay?.score?.statePrice &&
                                    (<div className="stocknew-tooltip-text">{_stock.regState ? _stock.regState : 'State'} Average is <span>{CommonHelper.formatCurrency(currencySymbol, _stock?.marketOverlay?.score?.statePrice)}</span></div>)
                                }

                                {
                                    (_.isNumber(_stock?.marketOverlay?.score?.statePriceListed) || _.isNumber(_stock?.marketOverlay?.score?.statePriceDelisted)) && (
                                        <>
                                            <div className="stocknew-tooltip-subhead-orange">Eastern Seaboard</div>
                                            {
                                                _.isNumber(_stock?.marketOverlay?.score?.statePriceListed) &&
                                                (<div className="stocknew-tooltip-text">Active Listed Average is <span>{CommonHelper.formatCurrency(currencySymbol, _stock?.marketOverlay?.score?.statePriceListed)}</span></div>)
                                            }

                                            {
                                                _.isNumber(_stock?.marketOverlay?.score?.statePriceDelisted) &&
                                                (<div className="stocknew-tooltip-text">Delisted Average is <span>{CommonHelper.formatCurrency(currencySymbol, _stock?.marketOverlay?.score?.statePriceDelisted)}</span></div>)
                                            }
                                        </>
                                    )
                                }

                            </div>

                        </Tooltip>
                    }
                >
                    {
                        (_stock.price > comparePrice)
                            ?
                            <img src={_images.arrow_above_red} height="20" className='cursor-pointer' />
                            :
                            <img src={_images.arrow_below_green} height="20" className='cursor-pointer' />
                    }

                </OverlayTrigger>
            </div>
        }

        return <></>

    }

    const showGrade = (_stock) => {
        let carScore = _stock?.marketOverlay?.score?.carScore
        if (carScore) {

            let _scoreData = stockScoreGrades.filter(e => carScore >= e.min && carScore <= e.max)[0]

            return <div className="stock-new-grade"><OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip>
                        <div style={{ width: '200px' }}>
                            <div className="stocknew-tooltip stocknew-tooltip-inner">
                                <div className="stocknew-tooltip-head-white">Car Score</div>

                                {
                                    _stock?.marketOverlay?.score?.daysSell &&
                                    (<div className="stocknew-tooltip-text">Average days to sell: <span>{_stock?.marketOverlay?.score?.daysSell}</span></div>)
                                }
                                {
                                    _stock?.marketOverlay?.score?.daysSupply &&
                                    (<div className="stocknew-tooltip-text">Days Supply: <span>{_stock?.marketOverlay?.score?.daysSupply}</span></div>)
                                }
                                {
                                    _stock?.marketOverlay?.score?.stateSampleSize &&
                                    (<div className="stocknew-tooltip-text">State sample size: <span>{_stock?.marketOverlay?.score?.stateSampleSize}</span></div>)
                                }

                                <div className="stocknew-tooltip-head-white mt-2">Car Grading</div>
                                {
                                    stockScoreGrades.map((data, index) => {
                                        return <div key={index} className="stocknew-grading-loop">
                                            <span className="badge" style={{ color: '#fff', backgroundColor: data.color }}>{data.name}</span>
                                            <span> = </span>
                                            <span> {data.id} </span>

                                        </div>

                                    })
                                }
                                {/* <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#22b573' }}>A</span>
                                    <span> = </span>
                                    <span> 25-30 </span>

                                </div>

                                <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#29abe2' }}>B</span>
                                    <span> = </span>
                                    <span> 20-24 </span>

                                </div>


                                <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#f7931e' }}>C</span>
                                    <span> = </span>
                                    <span> 16-19 </span>

                                </div>

                                <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#d2555e' }}>D</span>
                                    <span> = </span>
                                    <span> 11-15 </span>

                                </div>

                                <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#d2555e' }}>E</span>
                                    <span> = </span>
                                    <span> 7-10 </span>

                                </div>

                                <div className="stocknew-grading-loop">
                                    <span className="badge" style={{ color: '#fff', backgroundColor: '#d2555e' }}>F</span>
                                    <span> = </span>
                                    <span> 0-6 </span>

                                </div> */}


                            </div>
                        </div>
                    </Tooltip>
                }
            >
                {
                    !_.isEmpty(_scoreData)
                        ?
                        <span className="badge cursor-pointer" style={{ color: '#fff', backgroundColor: _scoreData.color }}>{_scoreData.name} <span>({carScore})</span></span>
                        :
                        <span className="badge cursor-pointer badge-open"><span>({carScore})</span></span>
                }
            </OverlayTrigger>
            </div>
        }
        return <></>
    }

    const showPriceHistory = async (stockid, target, _stock) => {
        let _priceLogs = [];

        try {
            await window.firebase.firestore().collection('changeLogs')
                .where('recordId', '==', stockid)
                .orderBy("newData.price", "desc")
                .get().then(querySnapshot => {
                    querySnapshot.docs.forEach((doc) => {
                        let _data = doc.data();
                        if (_data?.newData?.price || _data?.oldData.price) {
                            _priceLogs.push({
                                ..._data,
                                documentID: doc.id,
                                newPrice: _data?.newData?.price,
                                oldPrice: _data?.oldData.price,
                                addedDate: _data.logDate ? moment.unix(_data.logDate.seconds).format('DD MMM YYYY h:mm A') : null,
                                addedBy: _data.logBy ? CommonHelper.getUserNamebyId(allUsers, _data.logBy) : _data.addedByName
                            });
                        }

                    });
                });
        } catch (error) {
            console.log(error);
        }

        if (_.isEmpty(_priceLogs) && _stock.priceDate && _stock.priceValue) {
            _priceLogs.push({
                documentID: stockid,
                newPrice: _stock.priceValue,
                oldPrice: null,
                addedDate: moment.unix(_stock.priceDate.seconds).format('DD MMM YYYY h:mm A'),
                addedBy: (_stock.addedBy || '')
            });
        }

        let _log = <>{
            !_.isEmpty(_priceLogs)
                ?
                <>
                    {/* <div className="stocknew-tooltip-black-table mb-3 pt-0" >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.orderBy(_priceLogs, ['logDate'], ['desc']).map((log, index) => {
                                        return <tr key={index}>
                                            <td>{log.addedDate}
                                                <div className="stocknew-tooltip-table-user">{log.addedBy}</div>
                                            </td>
                                            <td>
                                                <span className="stocknew-tooltip-table-price">
                                                    {log.oldPrice ? <NumberFormat value={log.oldPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : <>{'empty'}</>}
                                                </span>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div> */}
                    <div className="stocknew-pricehistory-wrap" style={{
                        height: '195px',
                        overflow: 'auto',
                        position: "relative"
                    }}>
                        {
                            _.orderBy(_priceLogs, ['logDate'], ['desc']).map((log, index) => {
                                return <div key={index} className="stocknew-pricehistory-loop">
                                    <div className="stocknew-pricehistory-title">
                                        <span className="stocknew-pricehistory-log-sub">
                                            {log.oldPrice ? <NumberFormat value={log.oldPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : <span>{'empty'}</span>}
                                            <span className="stocknew-pricehistory-log-arrow">→</span>
                                            {log.newPrice ? <NumberFormat value={log.newPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : <span>{'empty'}</span>}
                                        </span>
                                    </div>
                                    <div className="stocknew-pricehistory-date">{log.addedDate} - <span>{log.addedBy}</span>
                                        {log.modifiedFrom ?
                                            <>
                                                {` - `}
                                                <i className={`ico icon-${log.modifiedFrom === 'web' ? 'monitor' : log.modifiedFrom} mr-1`}></i>
                                                {log.modifiedFrom} </> : <></>}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </>
                :
                <>
                    <div className='stocknew-pricehistory-wrap'>
                        <div className='no-data-flex justify-content-center align-items-center pb-3'>
                            <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                        </div>
                    </div>
                </>
        }</>

        setcostPopOver({ showpopover: true, targetpopover: target, logs: _log })
        //console.log('_priceLogs', _.orderBy(_priceLogs, ['logDate'], ['desc']))
    }

    let _allStockFieldsListVM = allStockFieldsListVM;
    if (deleteStock || deleteServiceStock)
        _allStockFieldsListVM.filter(function (obj) {
            if (obj.name === 'Settings') {
                obj.width = 110;
                return true;
            }
            return false;
        });

    const divStyle = {
        height: windowSize.windowHeight,
        width: windowSize.windowWidth,
        overflow: 'auto',
        position: "relative"
    };


    const showManagementRating = (_stock) => {
        let management_scores = _stock.management_scores
        if (_.isNumber(management_scores)) {

            let _scoreData = stockManagementScore.filter(e => management_scores >= e.min && management_scores <= e.max)[0]
            let averagePrice = (_stock?.marketOverlay?.score?.statePriceDelisted);
            return <div className="stock-new-grade"><OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip>
                        <div style={{ width: '225px' }}>
                            <div className="stocknew-tooltip stocknew-tooltip-inner">
                                <div className="stocknew-tooltip-head-white">Ratings</div>


                                <div className="stocknew-tooltip-text">Price Change:
                                    <span>{_stock?.priceChange_score}<span>({_.isNumber(_stock?.priceChangeDays) ? `${_stock.priceChangeDays}d` : '--'})</span></span>
                                </div>
                                <div className="stocknew-tooltip-text">Photo:
                                    <span>{_stock?.photo_score}&nbsp;({_.isNumber(_stock?.photo_length) ? _stock.photo_length : '--'})</span>
                                </div>
                                <div className="stocknew-tooltip-text">Comments:
                                    <span>{_stock?.comments_score}</span>
                                </div>
                                <div className="stocknew-tooltip-text">Comments Change:
                                    <span>{_stock?.commentsChange_score}&nbsp;({_.isNumber(_stock?.commentsChangeDays) ? `${_stock.commentsChangeDays}d` : '--'})</span>
                                </div>
                                {
                                    _stock.priceValue && _.isNumber(averagePrice) && _stock.priceValue < averagePrice
                                        ?
                                        <div className="stocknew-tooltip-text">Price Below Retail:
                                            <span>{_stock?.priceBR_score}&nbsp;({_.isNumber(_stock?.priceBR) ? `${_stock.priceBR}%` : '--'})</span>
                                        </div>
                                        :
                                        <div className="stocknew-tooltip-text">Price Above Retail:
                                            <span>{_stock?.priceAR_score}&nbsp;({_.isNumber(_stock?.priceAR) ? `${_stock.priceAR}%` : '--'})</span>
                                        </div>
                                }
                                <div className="stocknew-tooltip-text">No. Plate Change:
                                    <span>{_stock?.plateChange_score}&nbsp;({_.isNumber(_stock?.plateChangeDays) ? `${_stock.plateChangeDays}d` : '--'})</span>
                                </div>
                                <div className="stocknew-tooltip-text">Inventory Age:
                                    <span>{_stock?.inventory_score}&nbsp;({_.isNumber(_stock?.inventoryDays) ? `${_stock.inventoryDays}d` : '--'})</span>
                                </div>
                                {
                                    _.isNumber(_stock?.soldInDays) && (<div className="stocknew-tooltip-text">Sold Age:
                                        <span>{_stock.soldInDays}d</span>
                                    </div>)
                                }
                                <div className="stocknew-tooltip-head-white mt-2">Management Rating</div>
                                {
                                    stockManagementScore.map((data, index) => {
                                        return <div key={index} className="stocknew-grading-loop">
                                            <span className="badge" style={{ color: '#fff', backgroundColor: data.color }}>{data.name}</span>
                                            <span> = </span>
                                            <span> {data.label} </span>

                                        </div>

                                    })
                                }

                            </div>
                        </div>
                    </Tooltip>
                }
            >
                {
                    !_.isEmpty(_scoreData)
                        ?
                        <span className="badge cursor-pointer" style={{ color: '#fff', backgroundColor: _scoreData.color }}>{_scoreData.name} <span>({management_scores})</span></span>
                        :
                        <span className="badge cursor-pointer badge-open"><span>({management_scores})</span></span>
                }
            </OverlayTrigger>
            </div>
        }
        return <></>
    }

    return (
        <>
            <FilterPanel
                handleOEMOpen={handleOEMOpen}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                //sortOrder={sortOrder}
                //sortName={sortName}
                // handlesortOrder={(val) => {
                //     setSortOrder(val)
                //     setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                //     setDataload({
                //         ...checkDataLoad,
                //         [activeTab]: true
                //     })
                // }}
                // handleSortNamechange={(val) => {
                //     setSortName(val)
                //     setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                //     setDataload({
                //         ...checkDataLoad,
                //         [activeTab]: true
                //     })
                // }}
                handleAddStock={handleStock}
                csvHeader={csvHeader}
                datalist={stocks}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setStockLoader({
                        ...stockLoader,
                        [activeTab]: true
                    })

                    // setDataload({
                    //     ...checkDataLoad,
                    //     [activeTab]: true
                    // })
                    setPaging(false);
                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.stockFilter))
                        localStorage.removeItem('stockFilter');

                }}
                stockCount={stockCount}
                stockALLCount={stockALLCount}
                readOnlyEnquiryView={readOnlyEnquiryView}
                csvData={csvData}
                excelDownload={excelDownload}
                excelMcLarenDownload={excelMcLarenDownload}
                csvBtn={csvBtn}
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
                sheetData={sheetData}
                stockTabs={stockTabs}
            />

            {/*<div className="calendar-panel">
                {
                    stockLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        stocks[activeTab] && stocks[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={stocks[activeTab]}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={_allStockFieldsListVM}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={(stockFields && stockFields.length > 0) ? _.filter(stockFields, (v) => (accessToStockVBM ? v : !v.startsWith('margin'))) : allStockFieldsListVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    displayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                } 


            </div>*/}

            <div className="stock-new-panel" style={divStyle}>
                <div className="mr-2">
                    {
                        stockLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            stocks[activeTab] && stocks[activeTab].length > 0 ? (
                                <>
                                    {
                                        stocks[activeTab].map((_stock, index) => {
                                            return <div key={index} className="stock-new-loopbox mb-2">
                                                <div className="stock-new-loopbox-inner">
                                                    <div className="stock-new-loopbox-top" id={`stk_list_id_${_stock.documentID}`}>
                                                        <div className="float-left">

                                                            <div className="stock-new-vehicleimage-wrap">{/*(!_.isEmpty(_stock.images)) ? _stock.images[0] :*/}
                                                                {/* <img src={CommonHelper.showBrandLogo(props.dealersettings, _stock.make)} /> */}
                                                                {
                                                                    (!_.isEmpty(_stock.images))
                                                                        ?
                                                                        <>
                                                                            {
                                                                                _stock.images.map((data, index) => (
                                                                                    <a key={index}
                                                                                        data-fancy-box={`stock_list_img_slider${_stock.documentID}`}
                                                                                        className={index === 0 ? '' : 'hide'} href={data}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setImgViewer({
                                                                                                show: true,
                                                                                                images: _stock.images
                                                                                            })
                                                                                        }}>
                                                                                        {index === 0 && (
                                                                                            <>
                                                                                                <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                                                                <img src={data} alt="" className="img-object-fit" />
                                                                                            </>)}
                                                                                    </a>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        :
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={CommonHelper.showBrandLogo(props.dealersettings, _stock.make)} alt="" className='img-object-fit-contain' />
                                                                        </a>
                                                                }
                                                            </div>

                                                            <div className="stock-new-vehicle-info">
                                                                <h2>{_stock.make || ''}&nbsp;{_stock.model || ''} </h2>
                                                                {/*&nbsp;({index + 1}) */}
                                                                <div className="stock-new-vehicle-icon">
                                                                    {
                                                                        _stock.dealerName && (<div className="block stock-new-dealer" title='Dealer Name'>
                                                                            <img src={_images.ti_dealer} />
                                                                            <span>{_stock.dealerName}</span>
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        _stock?.miles && _stock?.miles > 0 && _stock?.miles !== '0' && (<div className="block" title='Odometer'>
                                                                            <img src={_images.ti_odometer} />
                                                                            <span><NumberFormat style={{ fontSize: 12 }} value={_stock.miles} displayType={'text'} thousandSeparator={true} suffix={' km'} /></span>
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        _stock.type && (<div className="block" title='Body Type'>
                                                                            <img
                                                                                src={_images[CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'ti_bodytype')]}
                                                                            //src={_images.ti_bodytype} 
                                                                            />
                                                                            <span>{_stock.type}</span>
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        _stock.transmission && (<div className="block" title='Transmission'>
                                                                            <img src={_images.ti_transmission} />
                                                                            <span>{_stock.transmission}</span>
                                                                        </div>)
                                                                    }
                                                                </div>


                                                                <div className="stock-new-vehicle-details">
                                                                    <strong>Stock#:</strong> {_stock.stockNo || '--'} {_stock.stockNo && (<span className="ml-1 cursor-pointer copy-ico-opacity" title='copy' onClick={(e) => CommonHelper.handleCopy(e, _stock.stockNo)}><i className="fas fa-copy"></i></span>)}
                                                                    {_stock.extColor && (<><span className="veh-details-seperator">|</span><Ttip placement="bottom" title={<Translate text={'exteriorColor'} />}>{_stock.extColor}</Ttip></>)}
                                                                    {_stock.year && (<><span className="veh-details-seperator">|</span><Ttip placement="bottom" title={<Translate text={'year'} />}>{_stock.year}</Ttip></>)}
                                                                    {_stock.regNo && (<><span className="veh-details-seperator">|</span><strong>REG#:</strong>&nbsp;{_stock.regNo}<span className="ml-1 cursor-pointer copy-ico-opacity" title='copy' onClick={(e) => CommonHelper.handleCopy(e, _stock.regNo)}><i className="fas fa-copy"></i></span></>)}
                                                                    {_stock.vinNo && (<><span className="veh-details-seperator">|</span><strong>VIN#:</strong>&nbsp;{_stock.vinNo}<span className="ml-1 cursor-pointer copy-ico-opacity" title='copy' onClick={(e) => CommonHelper.handleCopy(e, _stock.vinNo)}><i className="fas fa-copy"></i></span></>)}
                                                                    {_stock.location && (<><span className="veh-details-seperator">|</span><Ttip placement="bottom" title={<Translate text={'location'} />}>{_stock.location}</Ttip></>)}
                                                                    {/* {_stock.usage && (<><span className="veh-details-seperator">|</span><Ttip placement="bottom" title={<Translate text={'usage'} />}>{_stock.usage}</Ttip></>)}
                                                                    {_stock.transportMethod && (<><span className="veh-details-seperator">|</span><Ttip placement="bottom" title={<Translate text={'Transport Method'} />}>{_stock.transportMethod}</Ttip></>)} */}
                                                                    {!hideStockPrice && _stock.purchasePriceValue && (<><span className="veh-details-seperator">|</span><strong><Translate text={CommonHelper.getStaticFieldName(stockOptionsStatic, 'purchasePrice')} />:</strong>&nbsp;<NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={_stock.purchasePriceValue}
                                                                    /></>)}

                                                                </div>


                                                                <div className="stock-new-vehicle-status">
                                                                    {_stock.status}
                                                                    {_stock.saleType && (<div className="badge badge-pill badge-new-fill ml-2" title='Sale Type'>{_stock.saleType}</div>)}
                                                                    {_stock.stockInDate && (<><span className="veh-details-seperator" title='Stock-In Date'>|</span><strong><Translate text={'Stock-In'} />:</strong>&nbsp;{_stock.stockInDate}</>)}
                                                                    {_stock.soldDate && (<><span className="veh-details-seperator" title={`${soldName} Date`}>|</span><strong><Translate text={soldName} />:</strong>&nbsp;{_stock.soldDate}</>)}
                                                                    {_stock.deliveredDate && (<><span className="veh-details-seperator" title={`${deliveredName} Date`}>|</span><strong><Translate text={deliveredName} />:</strong>&nbsp;{_stock.deliveredDate}</>)}
                                                                    {
                                                                        _stock.isTestDrive || _stock.isLoan || _stock.isServiceLoan || _stock.isSale && (<div className="stock-new-availability">
                                                                            <div className="mr-2"><strong>Availability</strong></div>
                                                                            {_stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
                                                                            {_stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
                                                                            {_stock.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
                                                                            {_stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
                                                                        </div>)
                                                                    }
                                                                </div>

                                                            </div>

                                                        </div>



                                                        <div className="float-right h-100 d-flex">

                                                            {_stock.priceValue && (<div className="stock-new-price-details">
                                                                {
                                                                    !hideStockPrice && (<div className="stock-new-price" >
                                                                        {/* <PriceHistory
                                                                            priceValue={_stock.priceValue}
                                                                            currencySymbol={currencySymbol}
                                                                            stockid={_stock.documentID}
                                                                            allUsers={allUsers}
                                                                        >
                                                                        </PriceHistory> */}
                                                                        {/* <div className=""
                                                                            title='Price History'
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                showPriceHistory(_stock.documentID, e.target);
                                                                            }}>
                                                                            <i className="ico icon-info-fill icon-grey mr-2"></i>
                                                                        </div> */}
                                                                        <div onMouseOver={(e) => showPriceHistory(_stock.documentID, e.target, _stock)} >
                                                                            <NumberFormat
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                displayType={'text'}
                                                                                value={_stock.priceValue}
                                                                                title='Retail Price'
                                                                                className={'form-disable'}
                                                                            />
                                                                        </div>
                                                                        {showArrow(_stock.objData)}
                                                                    </div>)
                                                                }

                                                                {stockCredit && (
                                                                    <div className="stock-grading-flex">
                                                                        {showGrade(_stock.objData)}
                                                                        {showManagementRating(_stock)}
                                                                    </div>
                                                                )}
                                                            </div>)}

                                                            <div className="stock-new-action-button">
                                                                <div className="mini-button cursor-pointer" title='Stock History' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditDocument(_stock.documentID);
                                                                    setModelHistoryShow(true);
                                                                }}><i className="ico icon-history"></i></div>
                                                                <div className="mini-button cursor-pointer ml-2" title='Edit' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditDocument(_stock.documentID);
                                                                    setModelShow(true);
                                                                }}>
                                                                    <i className={`ico icon-${readOnlyEnquiryView ? 'zoom' : 'edit'}`}></i>
                                                                </div>
                                                                {
                                                                    (deleteStock || deleteServiceStock) && (<div className="mini-button cursor-pointer ml-2" title='Delete' onClick={async (e) => {
                                                                        e.preventDefault();
                                                                        handleDeleteStock(_stock.documentID, _stock.objData);
                                                                    }}><i className="ico icon-delete"></i></div>)
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="stock-new-loopbox-bottom">
                                                    <div className="float-left stock-new-bottom-button">
                                                        <button className="btn btn-sm btn-default-blue" onClick={(e) => {
                                                            e.preventDefault();
                                                            setEditDocument(_stock.documentID);
                                                            setCustomScroll('stock-add-images');
                                                            setModelShow(true);
                                                        }}><i className="ico icon-add mr-1"></i> Photos </button>
                                                        {/* && !['New', 'new'].includes(_stock.saleType) */}
                                                        {(autoGrabEnabled && accessToMarketOverlay && !['New', 'new'].includes(_stock.saleType)) && (
                                                            <button className="btn btn-sm btn-default ml-2" onClick={(e) => {
                                                                e.preventDefault();
                                                                handleAutoGrabScanOpen(_stock.objData);
                                                            }}> Market Overlay </button>
                                                        )}

                                                    </div>

                                                    <div className="stock-new-custom-icons">

                                                        {
                                                            localStorage.defaultModule !== 'service' && _stock?.counts?.enquiries > 0 && (<div className="stock-new-enq-outer">
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditDocument(_stock.documentID);
                                                                    setModelHistoryShow(true);
                                                                }}>
                                                                    <div className="stock-new-enq-count">
                                                                        <span className="badge badge-pill badge-enqcount-blue mr-1">{_stock?.counts?.enquiries > 0 ? _stock?.counts?.enquiries : 0}</span> Enquirie(s)
                                                                    </div>
                                                                    {
                                                                        _stock.firstEnquiryOn && (<div className="stock-new-enq-addeddate">First Enquiry Date : {_stock.firstEnquiryOn}</div>)
                                                                    }
                                                                </a>
                                                            </div>)}
                                                        {/* {
                                                            _stock?.counts?.enquiries > 0 && (<div className="stock-new-enq-count">
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditDocument(_stock.documentID);
                                                                    setModelHistoryShow(true);
                                                                }}>
                                                                    <span className="badge badge-pill badge-enqcount-blue mr-1">{_stock?.counts?.enquiries > 0 ? _stock?.counts?.enquiries : 0}</span> Enquirie(s)
                                                                </a>
                                                            </div>)
                                                        } */}

                                                        {_stock.stockAge && (<div className="stock-new-stock-age" title='Stock Age'> <i className="ico icon-mail-snoozed"></i> {_stock.stockAge}</div>)}
                                                        <div className="stock-new-stock-pic"> <a href="#" onDoubleClick={(e) => {
                                                            e.preventDefault();
                                                            console.log('Stock:-', _stock)
                                                            console.log('dealersettings:-', props.dealersettings)
                                                            console.log('clientUsers:-', props.clientUsers)
                                                            console.log('groupUsers:-', props.groupUsers)
                                                        }}> <i className="ico icon-activity-camera"></i>  {!_.isEmpty(_stock.images) ? _stock.images.length : 0}   </a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                    {
                                        hasMore.find(e => e.type === activeTab) ? (
                                            <div className="loadmore-middle-btn">
                                                <button
                                                    className={`btn btn-sm btn-loadmore  ${checkDataLoad[activeTab] ? 'form-disable' : ''}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handlePagination();
                                                    }}><Translate text={'Load More'} />
                                                    {
                                                        checkDataLoad[activeTab] ? (
                                                            <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                                        ) : (<i className="fas fa-chevron-down"></i>)
                                                    }
                                                </button>
                                            </div>
                                        ) : (<></>)
                                    }
                                </>
                            ) : (
                                <div className="common-table">
                                    <div className="text-center p-5">
                                        <p><Translate text={'No record found'} /></p>

                                    </div>
                                </div>
                            )
                    }

                </div>

            </div>

            <PopUpModal show={modelShow}>
                <AddStock
                    docID={editDocumentID}
                    selectedClientID={editClientID}
                    show={modelShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={editDocumentID ? 'stock' : 'addStock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={false}
                    enableServiceLoan={!editDocumentID && localStorage.defaultModule === 'service' ? true : false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={(isOEMModule || !_.isEmpty(stockLocations)) ? true : isFilterApplied}
                    updateStock={updateStock}
                    customScroll={customScroll}
                >
                </AddStock>
            </PopUpModal>

            <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                >
                </StockHistory>
            </PopUpModal>

            <PopUpModal show={OEMShow}>
                <OEMStock
                    docID={editDocumentID}
                    show={OEMShow}
                    clsActive='overlay-modal active'
                    handleClose={handleOEMClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}

                >
                </OEMStock>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <PopUpModal show={showDealerModal}>
                <DealerSelect
                    show={showDealerModal}
                    origin={'oemStock'}
                    title={'Select Dealer'}
                    handleClose={handleDealerSelectClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    showOwner={false}
                />
            </PopUpModal>

            <PopUpModal show={autograbSearch.show}>
                <AutoGrabScan
                    {...autograbSearch}
                    clsActive='overlay-modal active'
                    //tradeinProID={tradeinProID}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'stock'}
                    handleClose={handleAutoGrabScanClose}
                >
                </AutoGrabScan>
            </PopUpModal>

            <PopUpModal show={autoGrabModal.show}>
                <AutoGrab
                    {...props}
                    {...autoGrabModal}
                    //docID={autoGrabModal.tradeinID}
                    module={'stock'}
                    handleClose={handleAutoGrabClose}
                ></AutoGrab>
            </PopUpModal>

            <PopoverPanel
                showpopover={costPopOver.showpopover}
                targetpopover={costPopOver.targetpopover}
                title='Price History'
                position='bottom'
                id='total-stock-cost'
                className='popover-pipeline-switch stocknew-tooltip-width'
                closepopover={() => {
                    setcostPopOver({ showpopover: false, targetpopover: '', logs: null })
                }}>
                <>{costPopOver.logs}</>
            </PopoverPanel>
            
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            
        </>
    )
}

export default StockList