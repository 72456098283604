import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import _ from 'lodash';
import CustomDatePicker from './datePicker';
import CustomSelect from './customSelect';
import CustomModal from './customModal';
import NumberEditor from './numberEditor';
import AddressPicker from './addressPicker';
import moment from 'moment';
import CustomPopover from './customPopover';
import cellRenderer from './cellRenderer';
import colorFilter from './colorFilter';
import CustomDateTimePicker from './datetimePicker';
import { LicenseManager } from "ag-grid-charts-enterprise";
LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-072692}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 January 2025}____[v3]_[0102]_MTczNjgxMjgwMDAwMA==405486951c8598d572e8f7139156debf");

const GridView = (props) => {
    const gridRef = useRef();
    const currencySymbol = props.currencySymbol || '$ ';
    const [colDefs, setColDefs] = useState([])

    const context = useMemo(() => {
        return {
            currencySymbol: currencySymbol,
            settings: props?.currentSettings || {},
            tagColors: props?.tagColors || [],
            onMsgIconClicked: props?.onMsgIconClicked,
            buttonActions: props?.buttonActions || {},
            dealersettings: props?.dealersettings || {}
        };
    }, [props?.currentSettings]);

    useEffect(() => {
        if (_.isEmpty(props.dynamicCols))
            return

        const rowNo = {
            headerName: "#",
            value: "sno",
            width: 50,
            suppressHeaderFilterButton: true,
            suppressHeaderMenuButton: true,
            suppressHeaderContextMenu: true,
            resizable: false,
            sortable: false,
            pinned: 'left',
            suppressColumnsToolPanel: true,
            suppressMovable: true,
            // valueFormatter: (params) => {
            //     return params.data.sno || '';
            // },
            cellRenderer: (params) => {
                return params?.data?.sno ? <>{params.data.sno}</> : <i class="ico icon-expand" aria-hidden="true"></i>
            },
            cellClass: params => {
                return `${params?.data?.sno ? 'cell-bg-sno' : 'cell-bg-ico'}`;
            },
        };
        const allOrderFields = props.dynamicCols.map(r => {
            const _colSettings = _.find(props.dynamicCols, { value: r.value }) || {};
            // if (_colSettings.dataType === 'date') {
            //     _colSettings.comparator = dateComparator;
            //     _colSettings.filter = 'agDateColumnFilter';
            //     _colSettings.filterParams = datefiltercomparator;
            // } else {
            _colSettings.comparator = (valueA, valueB, nodeA, nodeB) => {
                return _colSettings?.dataType?.includes('date') ? dateComparator(nodeA, nodeB, (_colSettings.sortField || _colSettings.value)) : otherComparator(valueA, valueB, _colSettings.dataType)
            }
            _colSettings.filter = 'agMultiColumnFilter';//_colSettings.dataType === 'price' || _colSettings.dataType === 'number' ? 'agNumberColumnFilter' : 'agSetColumnFilter';
            _colSettings.filterParams = _colSettings.dataType === 'price' || _colSettings.dataType === 'number' ? numberfiltercomparator :
                _colSettings?.dataType?.includes('date') ? datefiltercomparator : setfiltercomparator;
            // }
            return {
                ..._colSettings,
                ...r,
                prefix: _colSettings.dataType === 'price' ? currencySymbol : null,
                field: r.value,
                headerName: r.name,
                hide: !Boolean(r.default),
                minWidth: props.minCellWidth || 200,
                cellRenderer: cellRenderer,
                cellEditor: _colSettings.dataType === 'date' ? CustomDatePicker :
                    _colSettings.dataType === 'select' || _colSettings.dataType === 'multiselect' ? CustomSelect :
                        _colSettings.dataType === 'address' ? AddressPicker :
                            _colSettings.dataType === 'modal' ? CustomModal :
                                _colSettings.dataType === 'note' ? CustomPopover :
                                    _colSettings.dataType === 'datetime' ? CustomDateTimePicker : NumberEditor,
                cellEditorPopup: true,
                cellEditorParams: {
                    modalFields: _colSettings?.modalFields || [],
                    dealerSettings: props.currentSettings,
                    onKeyDown: (event) => {
                        console.log('Key pressed: ', event.key);
                    }
                },
                cellClass: params => {
                    return `${params?.data?.id ? `${params.column.colId}-${params?.data.id}` : ''} ${params?.colDef?.className || ''}`;
                },
                suppressHeaderMenuButton: Boolean(props.hideHeaderMenuButton),
                suppressHeaderContextMenu: Boolean(props.hideHeaderMenuButton),
                suppressMovable: Boolean(props.hideHeaderMenuButton),
                suppressHeaderFilterButton: Boolean(props.hideHeaderMenuButton)
            }
        })
        if (props.hideSno) {
            setColDefs([
                ...allOrderFields.filter(a => a.hide === false),
                ...allOrderFields.filter(a => a.hide === true)])
        } else {
            setColDefs([rowNo,
                ...allOrderFields.filter(a => a.hide === false),
                ...allOrderFields.filter(a => a.hide === true)])
        }

    }, [props.dynamicCols, props?.currentSettings?.fieldColors])

    const dateComparator = (date1, date2, sortField) => {
        const date1Timestamp = date1?.data?.[sortField]?.seconds ? date1?.data?.[sortField]?.seconds * 1000 : date1?.data?.[sortField] ? new Date(date1?.data?.[sortField]).getTime() : 0;
        const date2Timestamp = date2?.data?.[sortField]?.seconds ? date2?.data?.[sortField]?.seconds * 1000 : date2?.data?.[sortField] ? new Date(date2?.data?.[sortField]).getTime() : 0;

        if (date1Timestamp < date2Timestamp) {
            return -1;
        } else if (date1Timestamp > date2Timestamp) {
            return 1;
        } else {
            return 0;
        }
    }

    const otherComparator = (val1, val2, type) => {
        const valueA = type === 'price' || type?.includes('number') ? Number(val1?.toString()?.replace(/\D/g, '') || '') : val1?.props?.children?.filter(r => _.isString(r))?.map(r => { return r })?.join(' ') || val1;
        const valueB = type === 'price' || type?.includes('number') ? Number(val2?.toString()?.replace(/\D/g, '') || '') : val2?.props?.children?.filter(r => _.isString(r))?.map(r => { return r })?.join(' ') || val2;

        if (type === 'price' || type?.includes('number')) {
            return valueA - valueB;
        } else if (valueA === valueB)
            return 0;
        else {
            return (valueA > valueB) ? 1 : -1;
        }
    }

    const datefiltercomparator = {
        filters: [
            {
                filter: colorFilter,
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    valueFormatter: (params) => {
                        return params?.value && params?.colDef?.dataType?.includes('date') ?
                            moment(params.value, props?.dateFormat || 'DD/MM/YYYY').format('MMMM YYYY') : cellRenderer
                    },
                    keyCreator: (params) => {
                        return params?.value && params?.colDef?.dataType?.includes('date') ?
                            moment(params.value, props?.dateFormat || 'DD/MM/YYYY').format('MMMM YYYY') : cellRenderer
                    },
                }
            },
        ],
        //buttons: ["clear"],
    }

    const numberfiltercomparator = {
        filters: [
            {
                filter: colorFilter,
            },
            {
                filter: 'agNumberColumnFilter',
                comparator: (filterValue, cellValue) => {
                    if (cellValue == null || cellValue === 0 || cellValue === undefined) return -1;
                    if (cellValue < filterValue) return -1;
                    if (cellValue > filterValue) return 1;
                    return 0;
                },
                //buttons: ["clear"],
            },
        ]
    };

    const setfiltercomparator = {
        filters: [
            {
                filter: colorFilter,
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    cellRenderer: cellRenderer,
                    cellRendererParams: { isFilterRenderer: true },
                }
            },
        ],

        //buttons: ["clear"],
    };

    useEffect(() => {
        console.log(gridRef?.current?.api)
        props?.setgridRef && props?.setgridRef(gridRef?.current?.api)
        if (props.sideBar)
            gridRef?.current?.api?.setSideBarVisible && gridRef.current.api.setSideBarVisible(true);
    }, [gridRef?.current?.api])


    const columnResized = (params) => {
        if (params.finished && props.saveSettings) {
            props.saveSettings(params)
        }

    }

    const onColumnMoved = useCallback((params) => {
        if (params.finished && props.saveSettings) {
            props.saveSettings(params)
        }
    }, []);

    const onColumnVisible = useCallback((params) => {
        props.saveSettings && props.saveSettings(params)
    }, []);

    const onSortChanged = useCallback((params) => {
        const _sort = params.api.getColumnState()
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            })
        const visibleIndex = _sort?.[0]?.sort === 'asc' ? params.api.getDisplayedRowCount() - 1 : 1;
        params.api.ensureIndexVisible(visibleIndex);
        props.saveSettings && props.saveSettings(params, 'sort');
    }, []);

    const onColumnPinned = useCallback((params) => {
        if (params.column && props.saveSettings) {
            const index = _.findIndex(params.api.getColumnDefs().filter(a => !Boolean(a.hide)), (a) => a.colId === params.column.getColId());
            let pinCols = [{ colId: params.column.getColId(), pinned: params.pinned }];
            if (params.pinned === 'left') {
                pinCols = params.api.getColumnDefs().filter(a => !Boolean(a.hide)).slice(0, index + 1).map(r => { return { colId: r.colId, pinned: "left" } })
            }
            params.api.applyColumnState({
                state: pinCols
            });
            props.saveSettings(params)
        }
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnSelectAll: true
                    },
                },
            ],
            defaultToolPanel: "columns",
            hiddenByDefault: true
        };
    }, []);

    const getRowHeight = useCallback((params) => {
        return props.rowHeight || 35;
    }, []);

    const gridOptions = useMemo(() => {
        return {
            //getContextMenuItems: [],
            onFirstDataRendered: (params) => {
                //params.api.ensureIndexVisible(params.lastRow, 'middle');
                if (params?.context?.settings?.dLSortFields?.length) {
                    params.api.applyColumnState({
                        state: params?.context?.settings?.dLSortFields,
                        defaultState: { sort: null },
                    });
                }
            },
            // onRowDataUpdated: (params) => {
            //     params.api.ensureIndexVisible(params.api.getDisplayedRowCount() - 1);
            // },
        }
    }, []);


    const onCellValueChanged = useCallback((params) => {
        if (props.onGridValueChange) {
            props.onGridValueChange(params)
            params.api.refreshCells();
            console.log(params)
        }
    }, []);

    const onCellClicked = useCallback((params) => {
        params.node.setSelected(true)
        if (props.onCellClicked)
            props.onCellClicked(params)
    }, []);

    const getContextMenuItems = useCallback(
        (params) => {
            if (_.isEmpty(params.column)) {
                return [];
            }

            var result = [
                {
                    name: "Copy",
                    shortcut: "Ctrl + C",
                    action: (cell) => {
                        let val = cell.value;
                        if (cell?.value?.props?.children?.length) {
                            val = cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                        }
                        params.api.flashCells({ rowNodes: [cell.node], columns: [cell.column.colId] });
                        navigator.clipboard.writeText(val);
                    },
                    icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
                }]
            if (props.fieldComments) {
                result.push({
                    name: "Comments",
                    action: () => {
                        params.eGridCell = document.querySelectorAll(`.${params.column.colId}-${params.node.data.id}`)[0];
                        props.onContextMenuClick(params, 'comments')
                    },
                    icon: '<i class="ico icon-sms"></i>',
                })
            }
            if (props.changeLogs) {
                result.push({
                    name: "Change Logs",
                    action: () => {
                        props.onContextMenuClick(params, 'changeLogs')
                    },
                    icon: '<i class="ico  icon-history"></i>',
                })
            }
            result.push("separator");
            result.push({
                name: "Export",
                subMenu: [
                    {
                        name: 'Excel Export',
                        action: function (cell) {
                            cell.api.exportDataAsExcel({
                                columnKeys: props?.dynamicCols?.filter(a => a.default && a.dataType !== 'button')?.map(r => { return r.value }) || [],
                                fileName: props.excelfileName || 'ams-pro-download',
                                sheetName: props.excelfileName || 'Sheet1',
                                processCellCallback: function (cell) {
                                    // Manipulate the value however you need.
                                    if (cell?.value?.props?.children?.length) {
                                        return cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                                    }
                                    return cell.value;
                                },
                            });
                        },
                        icon: '<i class="ico icon-excel-download"></i>',
                    },
                    {
                        name: 'CSV Export',
                        action: function (cell) {
                            cell.api.exportDataAsCsv({
                                columnKeys: props?.dynamicCols?.filter(a => a.default && a.dataType !== 'button')?.map(r => { return r.value }) || [],
                                fileName: props.excelfileName || 'ams-pro-download',
                                sheetName: props.excelfileName || 'Sheet1',
                                processCellCallback: function (cell) {
                                    // Manipulate the value however you need.
                                    if (cell?.value?.props?.children?.length) {
                                        return cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                                    }
                                    return cell.value;
                                },
                            });
                        },
                        icon: '<i class="ico icon-excel-download"></i>',
                    }
                ],
                icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
            });
            if (props?.tagColors?.length) {
                const _dynColors = params?.context?.settings?.fieldColors?.[params?.column?.colId] || props?.tagColors;
                const _sTag = params?.node?.data?.fieldTags?.[params?.column?.colId];
                const _submenu = [{
                    name: 'Edit',
                    action: () => {
                        props.onContextMenuClick(params, 'colorSettings')
                    },
                    icon: `<i class="fa fa-cog fa-lg" aria-hidden="true"></i>`
                }];
                _submenu.push("separator");
                _dynColors.map(rec => {
                    return _submenu.push({
                        name: rec.name,
                        disabled: _sTag === rec.value,
                        shortcut: _sTag === rec.value ? `<div><i class="ico icon-check"></i></div>` : '',
                        action: () => {
                            params.fieldColor = rec.value
                            props.onContextMenuClick(params, 'cellTag')
                        },
                        icon: `<div style="width:20px;height:20px;border-radius: 50%;background:${rec.value}"></div>`
                    })
                })
                if (_sTag) {
                    _submenu.push({
                        name: 'Remove Tag',
                        action: () => {
                            params.fieldColor = ''
                            props.onContextMenuClick(params, 'cellTag')
                        },
                        icon: `<div style="width:20px;height:20px;border-radius: 50%;background:transparent"><i class="ico icon-close-circle" style="color: #a9b0bf; font-size: 20px;"></i></div>`
                    })
                }
                result.push("separator");
                result.push({
                    name: "Color Tag Cell",
                    subMenu: _submenu,
                    icon: '<i class="fa fa-paint-brush" aria-hidden="true"></i>',
                })
            }
            return result;
        },
        [window],
    );

    const getMainMenuItems = useCallback(
        (params) => {
            var result = [
                'sortAscending',
                'sortDescending',
                'pinSubMenu'
            ];
            if (params.column.sort)
                result.splice(1, 0, 'sortUnSort');
            // if (props.saveSettings) {
            //     result.push({
            //         name: "Hide Column",
            //         action: (cell) => {
            //             cell.api.setGridOption("columnDefs", cell.api.getColumnDefs().map(a => { return a.value === cell.column.colId ? { ...a, hide: true } : { ...a } }));
            //             props.saveSettings(cell)
            //         },
            //         icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
            //     })
            // }
            return result;
        },
        [],
    );
    const excelStyles = useMemo(() => {
        return [
            {
                id: "header",
                interior: {
                    color: "#c6e9d5",
                    pattern: "Solid",
                },
                borders: {
                    borderBottom: {
                        color: "#c6e9d5",
                        lineStyle: "Continuous",
                        weight: 1,
                    },
                },
                font: {
                    bold: true,
                },
            }
        ];
    }, []);

    const quickFilterMatcher = useCallback((quickFilterParts, rowQuickFilterAggregateText) => {
        let result;
        try {
            result = quickFilterParts.every((part) =>
                rowQuickFilterAggregateText.match(part),
            );
        } catch {
            result = false;
        }
        return result;
    }, []);

    return (
        <div className="grid-wrapper ag-theme-quartz"
            style={{ height: `${props.windowHeight}px` }} // the Data Grid will fill the size of the parent container

        >
            <AgGridReact
                ref={gridRef}
                getRowHeight={getRowHeight}
                rowData={props.rowData}
                columnDefs={colDefs}
                loading={props.loader}
                sideBar={sideBar}
                onColumnResized={columnResized}
                onColumnVisible={onColumnVisible}
                onColumnMoved={onColumnMoved}
                gridOptions={gridOptions}
                onCellValueChanged={onCellValueChanged}
                onColumnPinned={onColumnPinned}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                getMainMenuItems={getMainMenuItems}
                excelStyles={excelStyles}
                context={context}
                onCellClicked={onCellClicked}
                suppressContextMenu={Boolean(props.hideContextMenu)}
                onSortChanged={onSortChanged}
                quickFilterMatcher={quickFilterMatcher}
                includeHiddenColumnsInQuickFilter={true}
            //    grandTotalRow={"bottom"}
            //   components={components}
            // stopEditingWhenCellsLoseFocus={true}
            />
        </div>
    )
}

export default GridView;