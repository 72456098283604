/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect } from '../../components';
import { default as _images } from '../../images';


export default class AutograbFacet extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.unsubscribeAutoGrab = null;
        this.state = {
            fields: this.props.data || {},
            activeTab: 'reg',
            errors: {},
            allmakes: [],
            makes: [],
            years: [],
            isLoading: false,
            pageLoading: false,
            autograbList: [],
            autograbVehicles: {},
            showExisting: { show: false, keywords: null }
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        this._isMounted = true;
        const _years = [];
        for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 50; i--) {
            _years.push({
                value: i.toString(),
                label: i.toString(),
                active: true,
            });
        }
        this.setState({ years: _years });//, () => { this.searchPPSRList(); })
        if (this._isMounted) {
            this.loadMakes();
            if (this?.state?.fields?.make) {
                this.loadMakeModel(this.state.fields.make);
            }
        }
        // const { dealersettings } = this.props;
        // const brands = dealersettings.allMakeModels;
        // const _makes = [];
        // const _years = [];
        // brands.forEach((doc) => {
        //     _makes.push({
        //         value: doc.value,
        //         active: _.isBoolean(doc.active) ? doc.active : true,
        //         label: doc.name,
        //         models: doc?.models?.map(r => { return { label: r.name, value: r.value } })
        //     });
        // });
        // for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
        //     _years.push({
        //         value: i.toString(),
        //         label: i.toString(),
        //         active: true,
        //     });
        // }
        // this.setState({ makes: _makes, years: _years });//, () => { this.searchPPSRList(); })
    }

    async loadMakes() {
        const { dealersettings, clientID } = this.props;
        this.setState({ pageLoading: true })
        const autograbfacetsearch = window.firebase.functions().httpsCallable('autograb-getMakeModels');
        autograbfacetsearch({
            clientID: clientID ? clientID : dealersettings?.client?.id,
            facet: 'make'
        }).then((response) => {
            //console.log('autograb-searchVehicle', response, response.data?.success);
            this.setState({ pageLoading: false })
            if (response.data.success) {
                console.log(response.data.make)
                this.setState({
                    makes: _.uniqBy(response.data.data.make, 'value')
                        .map(r => {
                            return {
                                label: r.value,
                                value: r.value,
                            }
                        })
                });
            }
            else {
                if (response.data?.message)
                    Swal.fire(response.data.message, '', 'error')
                else
                    Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'error')
                this.clearList();
            }

        });
    }

    async loadMakeModel(make) {
        const { dealersettings, clientID } = this.props;
        this.setState({ pageLoading: true })
        const autograbfacetsearch = window.firebase.functions().httpsCallable('autograb-getMakeModels');
        autograbfacetsearch({
            clientID: clientID ? clientID : dealersettings?.client?.id,
            make: make,
            facet: 'model,series,badge'
        }).then((response) => {
            //console.log('autograb-searchVehicle', response, response.data?.success);
            this.setState({ pageLoading: false })
            if (response.data.success) {
                this.setState({
                    allmakes: response.data.data.model.map(r => { return { ...r, make: make } }),
                    models: _.uniqBy(response.data.data.model, 'model')
                        .map(r => {
                            return {
                                label: r.model,
                                value: r.model,
                            }
                        })
                });
            }
            else {
                if (response.data?.message)
                    Swal.fire(response.data.message, '', 'error')
                else
                    Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'error')
                this.clearList();
            }

        });
    }

    componentWillUnmount() {
        this.unsubscribeAutoGrab && this.unsubscribeAutoGrab();
    }

    clearList = () => {
        this.unsubscribeAutoGrab && this.unsubscribeAutoGrab();
        this.setState({ autograbList: [], autograbVehicles: {} });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '')).toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
        if (name === 'vinNum' && _.isEmpty(fields[name])) this.clearList();
    }


    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        if (data.name === 'make') {
            fields['model'] = '';
            fields['series'] = '';
            fields['badge'] = '';
            if (e?.value)
                this.loadMakeModel(e?.value)
        }
        if (data.name === 'model') {
            fields['series'] = '';
            fields['badge'] = '';
        }
        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let mandatoryFields = ['make', 'model', 'year']
        for (let [key, value] of Object.entries(fields)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleAutoGrabSearch();
        }
        else
            return formIsValid;
    };

    handleAutoGrabSearch = () => {
        const { dealersettings, clientID } = this.props;
        const { fields } = this.state;
        let state = Object.assign({}, this.state.fields);
        var objData = Object.assign({},
            {
                ...fields,
                "clientID": clientID ? clientID : dealersettings?.client?.id
            });

        this.setState({ isLoading: true, autograbVehicles: {} })


        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        //console.log('handleAutoGrabSearch', objData);
        const autograbfacetsearch = window.firebase.functions().httpsCallable('autograb-facetSearch');
        autograbfacetsearch(objData).then((response) => {
            //console.log('autograb-searchVehicle', response, response.data?.success);
            this.setState({ isLoading: false })
            if (response.data.success && !_.isEmpty(response.data?.data?.vehicle)) {
                this.handleSelect(response.data?.data)
            }
            else if (response.data.success && !_.isEmpty(response.data?.data?.vehicles)) {
                this.setState({ autograbVehicles: response.data?.data });
            }
            else {
                if (response.data?.message)
                    Swal.fire(response.data.message, '', 'error')
                else
                    Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'error')
                this.clearList();
            }

        });
    };

    handleSelect = async (_data) => {
        this.props.handleClose(_data, this.state.fields);
    }

    getMonthNameFromNumber = (monthNumber) => {
        const monthName = moment()
            .month(monthNumber - 1)
            .format("MMM");
        return monthName;
    };

    bindDescription = (vehicle, colour) => {
        let _description = null;

        if (vehicle) {
            if (vehicle.badge)
                _description = (_description ? _description : '') + vehicle.badge + ' '
            if (vehicle.series)
                _description = (_description ? _description : '') + vehicle.series + ' '
            if (vehicle.num_gears)
                _description = (_description ? _description : '') + vehicle.num_gears + 'sp '
            if (vehicle.num_cylinders)
                _description = (_description ? _description : '') + vehicle.num_cylinders + 'cyl '
            if (vehicle.transmission_type)
                _description = (_description ? _description : '') + vehicle.transmission_type + ' '
            if (vehicle.body_type)
                _description = (_description ? _description : '') + vehicle.body_type + ' '
            if (vehicle.drive)
                _description = (_description ? _description : '') + vehicle.drive + ' '
            if (vehicle.capacity_cc)
                _description = (_description ? _description : '') + vehicle.capacity_cc + 'cc '
            if (vehicle.power_kw)
                _description = (_description ? _description : '') + vehicle.power_kw + 'kw '
            if (vehicle.torque_nm)
                _description = (_description ? _description : '') + vehicle.torque_nm + 'nm '
            if (vehicle.fuel_type)
                _description = (_description ? _description : '') + vehicle.fuel_type + ' '
            if (vehicle.body_config)
                _description = (_description ? _description : '') + vehicle.body_config + ' '
            if (vehicle.engine_type)
                _description = (_description ? _description : '') + vehicle.engine_type + ' '
            if (vehicle.num_doors)
                _description = (_description ? _description : '') + vehicle.num_doors + 'DR '
            if (vehicle.num_seats)
                _description = (_description ? _description : '') + vehicle.num_seats + 'SEATS '
            if (colour)
                _description = (_description ? _description : '') + colour + ' '
        }

        return _description ? _.trim(_description) : null;
    }

    handleVehicleSelect = async (_data, documentID) => {

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            html: `Do you want to select <b>${this.bindDescription(_data?.vehicle, _data?.colour)}</b>.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                this.props.handleClose(_data, this.state.fields);
                let _obj = { 'documentID': documentID, 'vehicle': _data?.vehicle, }
                const updateVehicleID = window.firebase.functions().httpsCallable('autograb-updateVehicleID');
                updateVehicleID(_obj).then((response) => {
                    //console.log('autograb-updateVehicleID', '', '==>', '');
                });
            }
        })

    }

    render() {
        const { make, model, year, series, badge } = this.state.fields;
        const { show, clientUsers, groupUsers, dealersettings, milesMandatory } = this.props;
        const { makes, isLoading, showExisting, autograbVehicles, fields, years, pageLoading, allmakes, models } = this.state;

        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;

        const _autograb = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;

        const divStyle = {
            height: '300px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`${!_.isEmpty(autograbVehicles) ? 'modal-dialog-scrollable' : ''} modal-dialog-centered modal-ppsr-manager`}>
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Facet Search'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            {
                                pageLoading
                                    ?
                                    <>
                                        <div className="dashboard-widget-loader h-100">
                                            <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            <div className="row">
                                <div className="form-style w-100">
                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'make'} /></label>
                                            <ReactSelect
                                                options={makes}
                                                name={"make"}
                                                placeholder={'select make'}
                                                onChange={this.handleReactSelectChange}
                                                value={make}
                                                classNamePrefix={`${this.state.errors["make"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label > <Translate text={'model'} /></label>
                                            <ReactSelect
                                                options={models}
                                                name={"model"}
                                                placeholder={'select model'}
                                                onChange={this.handleReactSelectChange}
                                                value={model}
                                                classNamePrefix={`${this.state.errors["model"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>

                                    </div>
                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-6">
                                            <label > <Translate text={'year'} /></label>
                                            <ReactSelect
                                                options={years}
                                                name={"year"}
                                                placeholder={'select year'}
                                                onChange={this.handleReactSelectChange}
                                                value={year}
                                                classNamePrefix={`${this.state.errors["year"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'Badge'} /></label>
                                            <ReactSelect
                                                options={_.uniqBy(allmakes.filter(a => a.make === make && a.model === model), 'badge').map(a => { return { label: a.badge, value: a.badge, } })}
                                                name={"badge"}
                                                placeholder={'select badge'}
                                                onChange={this.handleReactSelectChange}
                                                value={badge}
                                                classNamePrefix={`${this.state.errors["badge"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>

                                        <div className="form-group col-md-6">
                                            <label > <Translate text={'Series'} /></label>
                                            <ReactSelect
                                                options={_.uniqBy(allmakes.filter(a => a.make === make && a.model === model), 'series').map(a => { return { label: a.series, value: a.series, } })}
                                                name={"series"}
                                                placeholder={'select series'}
                                                onChange={this.handleReactSelectChange}
                                                value={series}
                                                classNamePrefix={`${this.state.errors["series"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    {
                                        !_.isEmpty(autograbVehicles)
                                            ?
                                            <div className="market-section-wrap">
                                                <div className="market-left-head mt-1 "><div className="float-left"></div><Translate text={'Please select the vehicle model'} /></div>
                                                <div className="market-search-vehiclelist mt-1" style={divStyle}>
                                                    {
                                                        autograbVehicles.vehicles.map((vehicle, index) => {
                                                            return <div key={index} className="market-search-vehicle-splitwrap">
                                                                <div className="market-search-vehicle-loop" >
                                                                    <div className="market-search-vehicle-split">
                                                                        <div>
                                                                            <div className="market-search-vehicle-title">{CommonHelper.bindAutoGrabTitle(vehicle)}</div>
                                                                            <div className="market-search-vehicle-info">Released
                                                                                <span className="badge market-search-yellow ml-1">
                                                                                    {vehicle?.release_month ? this.getMonthNameFromNumber(vehicle?.release_month) : "--"}{", "}{vehicle?.release_year ? vehicle?.release_year : ""} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div> <button type="button" className="btn btn-secondary float-right market-search-vehicle-selectbtn" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleVehicleSelect({ 'colour': autograbVehicles.colour, 'vehicle': vehicle }, autograbVehicles.documentID);

                                                                        }}> Select</button></div>

                                                                    </div>
                                                                    <div className="market-search-vehicle-info">
                                                                        {this.bindDescription(vehicle, autograbVehicles.colour)}
                                                                        {/* {`${vehicle?.capacity_cc ? vehicle?.capacity_cc : ''} ${vehicle?.engine}  and ${vehicle?.transmission} or
          ${vehicle?.transmission_type} transmission modes. The engine has ${vehicle?.num_cylinders} cylinders and a capacity of ${vehicle?.capacity_cc ? vehicle?.capacity_cc : ''} cc, providing a power output of ${vehicle?.power_kw} kW and a torque of ${vehicle?.torque_nm} Nm`} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>

                        {
                            _autoGrabEnabled
                                ?
                                <div className="float-left"><div className="autograb-wrap"> Powered by <img src={_images.autograb_logo} /></div> </div>
                                :
                                <></>
                        }



                        <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => this.handleSubmit(e)}>
                            {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'Search'} />
                        </button>

                        <button type="button" className="btn btn-default float-right" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}> <Translate text={'cancel'} /></button>
                    </Modal.Footer>


                </Modal>
            </>


        );
    }
}

