import React, {
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useState,
	useReducer
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import toast from 'toasted-notes';
import _, { isEmpty } from 'lodash';

import Inbox from './sections/inbox';
import Sent from './sections/sent';
import Drafts from './sections/drafts';
import Trash from './sections/trash';
// import Starred from './sections/starred';
// import AllMail from './sections/allmail';
import Spam from './sections/spam';
import Important from './sections/important';

import EmailLeftBar from './emailLeftBar';
import ComposeEmail from './composeEmail';
import { PopUpModal } from '../../components';
import { TemplateContext } from '../settings/workflow/templateContext';
import { MainContext } from '../layout/provider';
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';

// const ThreadListReducer = (state, action) => {
// 	// console.log('state, action', state, action)
// 	function updateThread() {
// 		return state.map((item, index) => {
// 			if (item.id === action.data.id) {
// 				return action.data;
// 			}
// 			return item;
// 		});
// 	}
// 	switch (action.type) {
// 		case "SUCCESS": {
// 			return action.data
// 		}
// 		case "APPEND_LIST": {
// 			return [...state, ..._.differenceBy(action.data, state, 'id')]
// 		}
// 		case "ADD_LIST": {
// 			return [action.data, ...state]
// 		}
// 		case "REMOVE_LIST": {
// 			return state.filter(item => item.id !== action.data.id)
// 		}
// 		case "UPDATE_LIST": {
// 			return updateThread()
// 		}
// 		default:
// 			return state;
// 	}
// };
const ThreadListReducer = (state, action) => {
	let _sortName = (action.sortName || 'latestMessageReceivedDate');
	let _sortOrder = (action.sortOrder || 'desc');
	function updateAllThread() {
		return state[action.activetab] ? state[action.activetab].map((item) => {
			var logVM = action.data.find(e => e.id === item.id)
			if (logVM) {
				for (let [key, value] of Object.entries(logVM)) {
					item[key] = value;
				}
				return item;
			}
			return item;
		}) : [];
	}
	function updateThread() {
		return state[action.activetab] ? _.orderBy(_.uniqBy(state[action.activetab].map((item) => {
			if (item.id === action.data.id) {
				for (let [key, value] of Object.entries(action.data)) {
					item[key] = value;
				}
				return item;
			}
			return item;
		}), 'id'), [_sortName], [_sortOrder]) : [];
	}

	switch (action.type) {
		case "SUCCESS": {
			return {
				...state,
				[action.activetab]: _.orderBy(_.uniqBy(action.data, 'id'), [_sortName], [_sortOrder])
			};
		}
		case "APPEND_LIST": {
			return {
				...state,
				[action.activetab]: _.orderBy(_.uniqBy([...updateAllThread(), ..._.differenceBy(action.data, Object.assign([], state[action.activetab], 'id'))], 'id'), [_sortName], [_sortOrder])
			};
		}
		case "ADD_LIST": {
			return {
				...state,
				[action.activetab]: _.orderBy(_.uniqBy([action.data, ...state[action.activetab]], 'id'), [_sortName], [_sortOrder])
			};
		}
		case "REMOVE_LIST": {
			return {
				...state,
				[action.activetab]: _.orderBy(_.uniqBy(state[action.activetab].filter(item => item.id !== action.data.id), 'id'), [_sortName], [_sortOrder])
			};
		}
		case "UPDATE_LIST": {
			return {
				...state,
				[action.activetab]: updateThread()
			};
		}
		default:
			return state;
	}
};

const Mail = ({ location, history }) => {
	const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
	const [threads, dispatch] = useReducer(ThreadListReducer, {})
	const [folders, setFolders] = useState(null);
	const [emailtemplates, setEmailTemplates] = useState([]);
	const [structureType, setStructureType] = useState('folders');
	const [selectedThread, setSelectedThread] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [sendLoader, setSendLoader] = useState(false);
	const [loader, setLoader] = useState(true);
	const [refreshLoader, setRefreshLoader] = useState(false)
	const [userData, setUserData] = useState(null);
	const [nylasAccountData, setNylasAccountData] = useState(null);
	const [searchText, setSearchText] = useState({});
	const [filterThread, setFilterThread] = useState({});
	const [shouldChildSetSelectedMsg, setShouldChildSetSelectedMsg] = useState(true);
	const [showComposeModal, setShowComposeModal] = useState({
		show: false,
		type: '',
		title: '',
		emailData: null,
		contact: null,
		enquiry: null
	});
	const [checkDataLoad, setDataload] = useState(true)
	const [isPaging, setPaging] = useState(false)
	const [hasMore, setHasMoreData] = useState([])


	const {
		dealersettings,
		newTheardID,
		clearTheardID,
		setNylasAccessToken,
		handleUnreadCount,
		mailUnreadCount,
		setUnreadCount,
	} = useContext(MainContext);

	const getFolderID = () => {
		let _folders = Object.assign([], folders);
		if (_.isEmpty(_folders) && dealersettings && !isEmpty(dealersettings?.nylasAccountData?.folders)) {
			_folders = dealersettings.nylasAccountData.folders
		}

		let _folderID = '';
		const path = location.pathname;
		if (path.includes('inbox')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Inbox', 'id')
		} else if (path.includes('sent')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Sent', 'id')
		} else if (path.includes('drafts')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Drafts', 'id')
		} else if (path.includes('important')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Important', 'id')
		} else if (path.includes('spam')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Junk', 'id')
		} else if (path.includes('trash')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'Trash', 'id')
		} else if (path.includes('starred')) {
			_folderID = CommonHelper.getNylasFolder(_folders, 'starred', 'id')
		}
		//console.log(_folderID)
		return _folderID;
	}
	const activeFolderID = getFolderID();

	useEffect(() => {

		//console.log('************************* location.key  ***********', activeFolderID, '===', _folderID, threads)
		if (!_.isEmpty(threads) && activeFolderID && _.isEmpty(threads[activeFolderID])) {
			setDataload(true)
			setHasMoreData([...hasMore.filter(e => e.folder !== activeFolderID)]);
			setPaging(false)
			dispatch({
				type: "SUCCESS",
				activetab: activeFolderID,
				data: [],
				sortName: activeFolderID === CommonHelper.getNylasFolder(folders, 'Sent', 'id') ? 'latestMessageSentDate' : 'latestMessageReceivedDate',
				sortOrder: 'desc'
			});
		}

	}, [location.key])

	useEffect(() => {
		//console.log('window.location.href---------------------------', checkDataLoad, window.location.href)	

		if (newTheardID && !checkDataLoad) {
			//console.log('window.location.href_===========================threadId', threadId, threads['Inbox'])


			let findThreads = _.find(threads[CommonHelper.getNylasFolder(folders, 'Inbox', 'id')], { id: newTheardID })
			if (!_.isEmpty(findThreads)) {
				clearTheardID();
				//console.log('window.location.href_---------------findThreads', findThreads)
			}
			else {
				let nylasAccountID = null;
				if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
					nylasAccountID = dealersettings.nylas_v3.grantId
				} else if (userData && !isEmpty(userData.nylas_v3)) {
					nylasAccountID = userData.nylas_v3.grantId
				}
				//console.log('window.location.href_findThreads_-----------------else', nylasAccountID)
				var nylasThread = window.firebase.functions().httpsCallable('nylasv3-getThread');
				nylasThread({
					grantId: nylasAccountID,
					threadId: newTheardID,
				}).then(res => {
					//console.log(res)
					if (res?.data?.success === true) {
						let thread = res?.data?.thread;
						let otherThreads = threads[CommonHelper.getNylasFolder(folders, 'Inbox', 'id')].filter(thrd => thrd.id !== newTheardID)
						dispatch({
							type: "SUCCESS",
							activetab: CommonHelper.getNylasFolder(folders, 'Inbox', 'id'),
							data: _.unionBy(otherThreads, [thread], 'id'),
							sortName: 'latestMessageReceivedDate',
							sortOrder: 'desc'
						});
						clearTheardID();
						handleUnreadCount(mailUnreadCount + 1)
					}
				});
			}


		}


	}, [newTheardID, checkDataLoad]);

	useEffect(() => {
		//console.log('window.location.href---------------------------', checkDataLoad, window.location.href)

		let search = window.location.search;
		let params = new URLSearchParams(search);
		let threadId = params.get('threadid');

		if (threadId && !checkDataLoad) {
			//console.log('window.location.href_===========================threadId', threadId, threads['Inbox'])
			if (params.has('threadid')) {
				params.delete('threadid')
				history.replace({ search: params.toString() })
			}

			let findThreads = _.find(threads[CommonHelper.getNylasFolder(folders, 'Inbox', 'id')], { id: threadId })
			if (!_.isEmpty(findThreads)) {
				setSelectedThread(findThreads);
				if (document.getElementById(`thread-${threadId}`)) {
					document.getElementById(`thread-${threadId}`).scrollIntoView({
						behavior: "smooth",
						block: "center"
					})
				}
				//console.log('window.location.href_---------------findThreads', findThreads)
			}
			else {

				let nylasAccountID = null;
				if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
					nylasAccountID = dealersettings.nylas_v3.grantId
				} else if (userData && !isEmpty(userData.nylas_v3)) {
					nylasAccountID = userData.nylas_v3.grantId
				}
				//console.log('window.location.href_findThreads_-----------------else', nylasAccountID)
				var nylasThread = window.firebase.functions().httpsCallable('nylasv3-getThread');
				nylasThread({
					grantId: nylasAccountID,
					threadId: threadId,
				}).then(res => {
					//console.log(res)
					if (res?.data?.success === true) {
						let thread = res?.data?.thread;
						let otherThreads = threads[CommonHelper.getNylasFolder(folders, 'Inbox', 'id')].filter(thrd => thrd.id !== threadId)
						dispatch({
							type: "SUCCESS",
							activetab: CommonHelper.getNylasFolder(folders, 'Inbox', 'id'),
							data: _.unionBy(otherThreads, [thread], 'id'),
							sortName: 'latestMessageReceivedDate',
							sortOrder: 'desc'
						});
						setSelectedThread(thread);
						if (document.getElementById(`thread-${threadId}`)) {
							document.getElementById(`thread-${threadId}`).scrollIntoView({
								behavior: "smooth",
								block: "center"
							})
						}
						handleUnreadCount(mailUnreadCount + 1)
					}
				});
			}


		}


	}, [window.location.search, checkDataLoad]);

	useEffect(() => {
		//dont change 
		document.getElementById('main-container-fluid') &&
			document.getElementById('main-container-fluid').classList.add('px-0');
		return () => {
			document.getElementById('main-container-fluid') && document.getElementById('main-container-fluid').classList.remove('px-0');
		};
	}, []);

	useEffect(() => {
		const authMail = async () => {
			try {
				// console.log('1. Fetch start of userData')
				const userSnapshot = await userRef.get();
				const data = userSnapshot.data();
				setUserData(data);
				// console.log('2. Fetch end of userData', data)

				if (isEmpty(data.nylas_v3) && isEmpty(dealersettings?.nylas_v3)) {
					history.push('/settings/profile?key=emailsettings');
					return
				}

				let nylasAccountID = null;
				if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
					nylasAccountID = dealersettings.nylas_v3.grantId
				} else if (data && !isEmpty(data.nylas_v3)) {
					nylasAccountID = data.nylas_v3.grantId
				}

				if (nylasAccountID) {
					// console.log('3. Fetch start of nylasAccountData')
					const nylasAccountRef = window.firebase.firestore().collection(`nylasv3-accounts`).doc(nylasAccountID)
					const nylasAccountSnapshot = await nylasAccountRef.get();
					let nylasAccountData
					if (nylasAccountSnapshot.exists) {
						nylasAccountData = nylasAccountSnapshot.data()
						setNylasAccountData(nylasAccountData)
						// console.log('4. Fetch end of nylasAccountData', nylasAccountData)
					}

					// if (nylasAccountData.syncing) {
					// 	history.push('/settings/profile?key=emailsettings');
					// 	return
					// }


					if (!isEmpty(nylasAccountData)) {
						if (isEmpty(nylasAccountData.folders)) {
							Swal.fire('Error', 'Something went wrong in syncing your emails, Please disable and enable Email sync again from email settings', 'error')
							history.push('/settings/profile?key=emailsettings');
						}

						setToken(nylasAccountData.token);
						setNylasAccessToken(nylasAccountData.token);
						// console.log('6. Set NylasAccessToken', nylasAccountData.token)						
						setFolders(nylasAccountData.folders)

					}

					setLoading(false);

				} else {
					history.push('/settings/profile?key=emailsettings');
				}
			} catch (error) {
				console.error(error);
			}
		};

		authMail();
	}, [location.search, history]);

	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}
		if (!_.isEmpty(emailtemplates)) {
			return;
		}

		var alltemplates = combineLatest(
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`)
				.where('clientID', '==', dealersettings.client.id)
				.where('visibility', '==', 'private')
				.where('isDeleted', '==', false),
			),
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`)
				.where('visibility', '==', 'shared')
				.where('isDeleted', '==', false),
			),
		).pipe(
			map(templates => {
				var [dealer, shared] = templates;
				return [[...dealer], [...shared]];
			}),
		).subscribe(allDocs => {
			let list = [];
			allDocs.forEach(rec => {
				_.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec);
			});
			let listVM = [];
			list.forEach(rec => {
				if (!listVM.some(e => e.documentID === rec.documentID))
					listVM.push(rec);
			});
			setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'));
			setLoader(false);
		});
		return () => {
			alltemplates && alltemplates.unsubscribe();
		};
	}, []);

	const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates]);

	const handleMarkAs = async (type, setThreadsSelected) => {
		let nylasAccountID = null;
		if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
			nylasAccountID = dealersettings.nylas_v3.grantId
		} else if (userData && !isEmpty(userData.nylas_v3)) {
			nylasAccountID = userData.nylas_v3.grantId
		}
		let selectedMessages = threads[activeFolderID].filter(message => message.selected === true)
		let msgsThatWereSelected = threads[activeFolderID].filter(message => message.selected === true)

		if (selectedMessages.length > 0) {
			selectedMessages = selectedMessages.map(async item => {
				let selectedThread = item
				/// let threadRef = window.firebase.firestore()
				/// 	.collection('nylasv3-accounts')
				/// 	.doc(nylasAccountID)
				/// 	.collection('nylas-threads')
				/// 	.doc(selectedThread.id)
				selectedThread.unread = (type === 'unread' ? true : false);
				delete selectedThread.selected
				///await threadRef.set(selectedThread, { merge: true })
				return selectedThread
			})

			setThreadsSelected(false)

			msgsThatWereSelected.forEach(async selectedThread => {
				// console.log(selectedThread)
				if (type === 'unread') {
					await updateThread(selectedThread, 'unread', {})
				} else {
					await updateThread(selectedThread, 'read', {})
				}
			})
		} else {
			toast.notify('No conversation selected.', {
				duration: 2000,
			});
			setThreadsSelected(false)
		}
		// console.log(type)
	};

	const refreshThreads = () => {
		if (refreshLoader) return;

		setRefreshLoader(true);
		setHasMoreData([...hasMore.filter(e => e.folder !== activeFolderID)]);
		setDataload(true)

	};

	const loadMoreThreads = async (type = 'inbox') => {
		try {
			setPaging(true)
			setDataload(true)
			setLoadingMore(true);

		} catch (error) {
			setLoadingMore(false);
			console.error(error);
		}
	};

	const handleOpenComposeModal = ({ type, title, emailData, contact, enquiry }) => {
		setShowComposeModal({
			...showComposeModal,
			show: true,
			type,
			title,
			emailData: emailData ? emailData : null,
			contact: contact,
			enquiry: enquiry,
		});
	};

	const handleCloseComposeModal = (_type, _message) => {
		if (_type === 'reply' && !_.isEmpty(selectedThread)) setSelectedThread({ ...selectedThread, refersh: true })
		setShowComposeModal({ show: false, type: '', title: '', emailData: null });
	};

	const searchThreads = async textToSearch => {
		try {
			if (!activeFolderID) {
				return
			}

			setSearchText({ ...searchText, [activeFolderID]: textToSearch });
			if (!_.isEmpty(textToSearch)) {
				setHasMoreData([...hasMore.filter(e => e.folder !== activeFolderID)]);
				setDataload(true)
			}
			else {
				refreshThreads(activeFolderID)
			}

			// let threadsRef = window.firebase.firestore().collection(`nylasv3-accounts/${userData.nylas_v3.grantId}/nylas-threads`)
			// 	.where('keywords', 'array-contains', textToSearch)
			// threadsRef = threadsRef.orderBy('last_message_timestamp', 'desc').limit(50)

			// const snap = await threadsRef.get()
			// let _messages = []
			// if (snap.size > 0) {
			// 	snap.forEach(doc => {
			// 		_messages.push(doc.data())
			// 	})
			// 	dispatch({
			// 		type: "SUCCESS",
			// 		data: _messages
			// 	});
			// }
			// setHasMoreData([])

		} catch (error) {
			console.error(error);
		}
	};

	const filterThreads = async type => {
		try {
			if (!activeFolderID) {
				return
			}

			setFilterThread({ ...filterThread, [activeFolderID]: type });
			setHasMoreData([...hasMore.filter(e => e.folder !== activeFolderID)]);
			setDataload(true)
			// if (type !== 'clear') {
			// 	let threadsRef = window.firebase.firestore().collection(`nylasv3-accounts/${userData.nylas_v3.grantId}/nylas-threads`)
			// 		.where('folders', 'array-contains', currentSection.folderID)

			// 	if (type === 'unread') {
			// 		threadsRef = threadsRef.where('unread', '==', true)
			// 	} else if (type === 'read') {
			// 		threadsRef = threadsRef.where('unread', '==', false)
			// 	} else if (type === 'starred') {
			// 		threadsRef = threadsRef.where('starred', '==', true)
			// 	}

			// 	// //threadsRef = threadsRef.orderBy('latestMessageReceivedDate', 'desc').limit(100)
			// 	threadsRef = threadsRef.limit(100)

			// 	const snap = await threadsRef.get()
			// 	let _messages = []
			// 	if (snap.size > 0) {
			// 		snap.forEach(doc => {
			// 			_messages.push(doc.data())
			// 		})
			// 		dispatch({
			// 			type: "SUCCESS",
			// 			data: _messages
			// 		});
			// 	}
			// 	setHasMoreData([])
			// } else {
			// 	dispatch({
			// 		type: "SUCCESS",
			// 		data: []
			// 	});
			// 	setDataload(true)
			// 	setHasMoreData([])
			// }
		} catch (error) {
			console.error(error);
		}
	};

	const updateThread = async (_thread, type, data) => {
		// console.log('A updateThread args', _thread, type, data)

		let nylasAccountID = null;
		if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
			nylasAccountID = dealersettings.nylas_v3.grantId
		} else if (userData && !isEmpty(userData.nylas_v3)) {
			nylasAccountID = userData.nylas_v3.grantId
		}
		let threadId = _thread.id;

		// util function
		// const getFolder = (folderName, foldersArr) => {
		// 	let folder = foldersArr.filter(folder => {
		// 		if (folder && folder.name === folderName) {
		// 			return true;
		// 		}
		// 		return false;
		// 	});
		// 	folder = folder[0];
		// 	return folder;
		// };

		try {
			let updatedMessage = {}
			let updatedMessages = threads[activeFolderID].map((message) => {
				let focusedMessage = message;
				let updatedMessage = {}

				if (message.id === threadId) {

					if (type === 'moveToTrash') {
						let _folders = Object.assign([], message.folders)
						_folders.push(CommonHelper.getNylasFolder(folders, 'Trash', 'id'))
						_folders = _folders.filter(e => ![CommonHelper.getNylasFolder(folders, 'Inbox', 'id'), CommonHelper.getNylasFolder(folders, 'Sent', 'id')].includes(e))
						updatedMessage.folders = _folders;
						focusedMessage.folders = _folders;
						setSelectedThread(null)
						toast.notify('Conversation moved to Trash.', {
							duration: 2000,
						});
					}
					if (type === 'moveToInbox') {
						let _folders = Object.assign([], message.folders)
						_folders.push(CommonHelper.getNylasFolder(folders, 'Inbox', 'id'))
						_folders = _folders.filter(e => e !== CommonHelper.getNylasFolder(folders, 'Trash', 'id'))
						updatedMessage.folders = _folders;
						focusedMessage.folders = _folders;
						setSelectedThread(null)
						toast.notify('Conversation moved to Inbox.', {
							duration: 2000,
						});
					}

					if (type === 'important') {
						let _folders = Object.assign([], message.folders)
						_folders.push(CommonHelper.getNylasFolder(folders, 'Important', 'id'))
						updatedMessage.folders = _folders;
						focusedMessage.folders = _folders;
						setSelectedThread(null)
						toast.notify('Conversation marked as important.', {
							duration: 2000,
						});
					}
					if (type === 'notimportant') {
						let _folders = Object.assign([], message.folders)
						_folders = _folders.filter(e => e !== CommonHelper.getNylasFolder(folders, 'Important', 'id'))
						updatedMessage.folders = _folders;
						focusedMessage.folders = _folders;
						setSelectedThread(null)
						toast.notify('Conversation marked as not important.', {
							duration: 2000,
						});
					}
					if (type === 'star') {
						updatedMessage.starred = true;
						focusedMessage.starred = true;
						toast.notify('Conversation starred.', {
							duration: 2000,
						});
					}
					if (type === 'unstar') {
						updatedMessage.starred = false;
						focusedMessage.starred = false;
						toast.notify('Conversation unstarred.', {
							duration: 2000,
						});
					}
					if (type === 'read') {
						updatedMessage.unread = false;
						focusedMessage.unread = false;
						handleUnreadCount(mailUnreadCount - 1)
					}
					if (type === 'unread') {
						updatedMessage.unread = true;
						focusedMessage.unread = true;
						handleUnreadCount(mailUnreadCount + 1)
					}

					if (!_.isEmpty(updatedMessage)) {
						let nylasUpdateThread = window.firebase.functions().httpsCallable('nylasv3-updateThread');
						nylasUpdateThread({
							grantId: nylasAccountID,
							threadId: threadId,
							options: updatedMessage
						}).then(res => {
							console.log('nylasUpdateThread', updatedMessage, res)
						});
					}

					// console.log(updatedMessage);
					///threadRef.set(updatedMessage, { merge: true })
				}

				return focusedMessage;
			});

			if (type === 'moveToTrash' && !_.isUndefined(threads[CommonHelper.getNylasFolder(folders, 'Trash', 'id')])) {
				dispatch({
					type: "APPEND_LIST",
					activetab: CommonHelper.getNylasFolder(folders, 'Trash', 'id'),
					data: [{ ..._thread, ...updatedMessage }],
					sortName: 'latestMessageReceivedDate',
					sortOrder: 'desc'
				});
			}
			else if (type === 'moveToInbox' && !_.isUndefined(threads[CommonHelper.getNylasFolder(folders, 'Inbox', 'id')])) {
				dispatch({
					type: "APPEND_LIST",
					activetab: CommonHelper.getNylasFolder(folders, 'Inbox', 'id'),
					data: [{ ..._thread, ...updatedMessage }],
					sortName: 'latestMessageReceivedDate',
					sortOrder: 'desc'
				});
			}
			else if (type === 'important' && !_.isUndefined(threads[CommonHelper.getNylasFolder(folders, 'Important', 'id')])) {
				dispatch({
					type: "APPEND_LIST",
					activetab: CommonHelper.getNylasFolder(folders, 'Important', 'id'),
					data: [{ ..._thread, ...updatedMessage }],
					sortName: 'latestMessageReceivedDate',
					sortOrder: 'desc'
				});
			}

			dispatch({
				type: "SUCCESS",
				activetab: activeFolderID,
				data: updatedMessages,
				sortName: activeFolderID === CommonHelper.getNylasFolder(folders, 'Sent', 'id') ? 'latestMessageSentDate' : 'latestMessageReceivedDate',
				sortOrder: 'desc'
			});


			if (type === 'read' || type === 'star' || type === 'unstar') {
				// setSelectedThread(focusedMessage);
				setShouldChildSetSelectedMsg(false);
			}
			// console.log('reqObj before request', reqObj);
			// const response = await nylasUpdateThread(reqObj);
			// console.log('response of updateThread',response)
		} catch (error) {
			console.log(error);
		}
	};

	// const handleReply = async (trigger, emailData, handleClose, replyToAll) => {
	// 	const { mailTo, mailCc, mailBcc } = trigger;

	// 	setSendLoader(true);

	// 	if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
	// 		toast.notify('Please specify at least one recipient.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	try {
	// 		let nylasAccountID = null;
	// 		if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
	// 			nylasAccountID = dealersettings.nylas_v3.grantId
	// 		} else if (userData && !isEmpty(userData.nylas_v3)) {
	// 			nylasAccountID = userData.nylas_v3.grantId
	// 		}
	// 		let emailObj = {
	// 			grantId: nylasAccountID,
	// 			draft: {
	// 				subject: trigger.subject,
	// 				body: trigger.email,
	// 			},
	// 		};

	// 		emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		// emailObj.draft.replyTo = trigger.mailTo.split(',').map(email => {
	// 		// 	return {
	// 		// 		name: '',
	// 		// 		email: email,
	// 		// 	};
	// 		// });
	// 		if (replyToAll) {
	// 			emailObj.draft.replyToCC = !_.isEmpty(trigger?.mailTo) ? trigger.mailCc.split(',').map(email => {
	// 				return {
	// 					name: '',
	// 					email: email,
	// 				};
	// 			}) : []
	// 		}

	// 		if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
	// 			emailObj.files = trigger.files;
	// 		}

	// 		emailObj.draft.body = trigger.email;
	// 		emailObj.draft.replyToMessageId = emailData.replyToMessageId;

	// 		console.log('handleReply', emailObj);

	// 		setTimeout(() => {
	// 			toast.notify('Email has been sent.', { duration: 2000 });
	// 			setShowComposeModal({ show: false, type: '', title: '', emailData: null });
	// 		}, 300);

	// 		const nylasSendEmail = window.firebase.functions().httpsCallable('nylasv3-sendEmail');
	// 		const resp = await nylasSendEmail(emailObj);
	// 		console.log('handleReply_resp', resp, resp?.data?.data);

	// 		let thread = resp?.data?.data
	// 		let presentThread = threads[activeFolderID].filter(thrd => thrd.id === thread?.id)
	// 		if (presentThread.length > 0) {
	// 			let otherThreads = threads[activeFolderID].filter(thrd => thrd.id !== thread?.id)

	// 			if (selectedThread && selectedThread.id === thread?.id) {
	// 				setSelectedThread(thread)
	// 			}
	// 		}

	// 		if (resp.data.success) {
	// 			setSendLoader(false);
	// 		} else {
	// 			setSendLoader(false);
	// 			if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
	// 				Swal.fire({
	// 					title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
	// 					icon: 'info',
	// 					showCancelButton: true,
	// 					confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
	// 					cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
	// 				}).then(result => {
	// 					if (result.value) {
	// 						window.firebase.firestore().doc(`users/${localStorage.uid}`).set({ nylas: null, }, { merge: true });
	// 						history.push('/settings/profile?key=emailsettings');
	// 					}
	// 				});
	// 				return
	// 			}
	// 			else {
	// 				Swal.fire('There was an error in sending email.', '', 'error');
	// 			}
	// 		}
	// 	} catch (error) {
	// 		setSendLoader(false);
	// 		console.log(error);
	// 	}
	// };

	// const handleSend = async (trigger, emailData, handleClose, replyToAll, createDraft) => {
	// 	const { mailTo, mailCc, mailBcc, subject, email } = trigger;
	// 	setSendLoader(true);

	// 	if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
	// 		toast.notify('Please specify at least one recipient.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(subject)) {
	// 		toast.notify('Please write subject.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(email) || email === '<p></p>\n') {
	// 		toast.notify('Please write in email body.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	let nylasAccountID = null;
	// 	if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
	// 		nylasAccountID = dealersettings.nylas_v3.grantId
	// 	} else if (userData && !isEmpty(userData.nylas_v3)) {
	// 		nylasAccountID = userData.nylas_v3.grantId
	// 	}

	// 	try {
	// 		let emailObj = {
	// 			grantId: nylasAccountID,
	// 			createDraft: (createDraft || false),
	// 			draft: {
	// 				subject: trigger.subject,
	// 				body: trigger.email,
	// 				metadata:{
	// 					key1: 'enquiry001',
	// 					key2: 'thread001'
	// 				}
	// 			},
	// 		};

	// 		emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		emailObj.draft.cc = !_.isEmpty(trigger?.mailCc) ? trigger.mailCc.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		emailObj.draft.bcc = !_.isEmpty(trigger?.mailBcc) ? trigger.mailBcc.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];

	// 		if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
	// 			emailObj.files = trigger.files;
	// 		}

	// 		setTimeout(() => {
	// 			toast.notify('Email has been sent.', { duration: 2000 });
	// 			setShowComposeModal({ show: false, type: '', title: '', emailData: null });
	// 		}, 300);

	// 		console.log('nylasv3-sendEmail', emailObj);
	// 		const nylasSendEmail = window.firebase
	// 			.functions()
	// 			.httpsCallable('nylasv3-sendEmail');
	// 		const resp = await nylasSendEmail(emailObj);
	// 		let thread = resp.data.thread
	// 		let email = resp.data.data
	// 		console.log('res of email that i sent', resp);
	// 		console.log('res email', email);
	// 		console.log('res thread', thread);

	// 		// check if we are in sent 
	// 		if (window.location.pathname === '/mails/sent') {
	// 			// setMessages(
	// 			// 	_.orderBy(
	// 			// 		_.unionBy(threads, [thread], 'id'),
	// 			// 		['last_message_timestamp'],
	// 			// 		['desc'],
	// 			// 	),
	// 			// );
	// 		}


	// 		if (resp.data.success) {
	// 			setSendLoader(false);
	// 		} else {
	// 			setSendLoader(false);
	// 			if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
	// 				Swal.fire({
	// 					title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
	// 					icon: 'info',
	// 					showCancelButton: true,
	// 					confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
	// 					cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
	// 				}).then(result => {
	// 					if (result.value) {
	// 						window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
	// 							nylas: null,
	// 						}, { merge: true });
	// 						history.push('/settings/profile?key=emailsettings');
	// 					}
	// 				});
	// 				return
	// 			}
	// 			else {
	// 				Swal.fire('There was an error in sending email.', '', 'error');
	// 			}
	// 		}

	// 		// console.log('response', resp);
	// 	} catch (error) {
	// 		Swal.fire('There was an error in sending email.', '', 'error');

	// 		setSendLoader(false);
	// 		console.log(error);
	// 	}
	// };

	const selectThreads = (type, threadId, filteredThreads, setFilteredThreads, setThreadsSelected) => {
		//console.log('select Thread run', type, threadId, filteredThreads);
		let selectedMessages = filteredThreads.map(message => {
			let editedMessage = message;

			if (type !== 'single' && type !== 'single-unselect') {
				editedMessage.selected = false;
			}

			if (message.unread === true && type === 'unread') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.unread === false && type === 'read') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.starred === true && type === 'starred') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.starred === false && type === 'unstarred') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'all') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'single' && message.id === threadId) {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'single-unselect' && message.id === threadId) {
				editedMessage.selected = false;
				return editedMessage;
			} else if (
				type !== 'unread' &&
				type !== 'read' &&
				type !== 'starred' &&
				type !== 'unstarred' &&
				type !== 'all'
			) {
				if (message.selected) {
					// console.log(message, 'message.selected')
					editedMessage.selected = true;
				}
			}

			return editedMessage;
		});

		setFilteredThreads([...selectedMessages]);
		setThreadsSelected(type === 'none' ? false : true)

		if (type === 'single-unselect') {
			let selectedMessages = threads[activeFolderID].filter(message => message.selected === true)
			if (selectedMessages.length === 0) {
				setThreadsSelected(false)
			}
		}
	};
	//console.log('D A T A R E T UR N - %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%', threads)
	return loading ? (
		<div
			className='mt-5'
			style={{
				minHeight: '80vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div className='spinner-loader pt-5 h-100'>
				<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
					<div role='status' className='spinner-border loader-primary'></div>
					<p className='text-muted mt-3'></p>
				</div>
			</div>
		</div>
	) : (
		<div className='middle-wrapper h-100'>
			<div className='email-main-container'>
				<div className='email-wrapper'>
					<EmailLeftBar
						handleOpenComposeModal={handleOpenComposeModal}
						folders={folders}
						unreadCount={mailUnreadCount}
						setSelectedThread={setSelectedThread}
						structureType={structureType}
					/>
					<TemplateContext.Provider
						value={{
							emailTemplateList: emailTemplatesMemo,
							loading: loader,
						}}
					>
						<Suspense fallback={<></>}>
							<Switch>
								<Route
									exact
									path='/mails/inbox'
									render={() => (
										<Inbox
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											dealersettings={dealersettings}
										/>
									)}
								/>
								<Route
									exact
									path='/mails/sent'
									render={() => (
										<Sent
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											dealersettings={dealersettings}
										/>
									)}
								/>

								<Route
									exact
									path='/mails/drafts'
									render={() => (
										<Sent
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											emailtemplates={emailtemplates}  //only for drafts
											history={history} //only for drafts
											dealersettings={dealersettings}
										/>
									)}
								/>

								<Route
									exact
									path='/mails/important'
									render={() => (
										<Sent
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											dealersettings={dealersettings}
										/>
									)}
								/>
								<Route
									exact
									path='/mails/spam'
									render={() => (
										<Sent
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											dealersettings={dealersettings}
										/>
									)}
								/>
								<Route
									exact
									path='/mails/trash'
									render={() => (
										<Trash
											folderID={activeFolderID}
											threads={threads[activeFolderID]}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateThread={updateThread}
											selectedThread={selectedThread}
											setSelectedThread={setSelectedThread}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={setShouldChildSetSelectedMsg}
											searchText={searchText[activeFolderID] || ''}
											setSearchText={setSearchText}
											filterThread={filterThread[activeFolderID] || null}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											setRefreshLoader={setRefreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											dealersettings={dealersettings}
										/>
									)}
								/>
							</Switch>
						</Suspense>
					</TemplateContext.Provider>
				</div>
			</div>
			<PopUpModal show={showComposeModal.show}>
				<ComposeEmail
					show={showComposeModal.show}
					handleClose={handleCloseComposeModal}
					token={token}
					type={showComposeModal.type}
					title={showComposeModal.title}
					emailData={showComposeModal.emailData}
					contact={showComposeModal.contact}
					enquiry={showComposeModal.enquiry}
					dealersettings={dealersettings}
					userData={userData}
					sendLoader={sendLoader}
					setSendLoader={setSendLoader}
					//handleReply={handleReply}
					//handleSend={handleSend}
					structureType={structureType}
					emailtemplates={emailtemplates}
				></ComposeEmail>
			</PopUpModal>
		</div>
	);
};

export default Mail;
