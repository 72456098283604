export const allenquiryFields = [
    {
        name: 'Order Date',
        value: 'wonOn',
        default: true,
        editable: true,
        dataType: 'date',
        fieldDB: 'wonDate',
        sortField: 'wonDate',
        collections: [
            { name: 'enquiries' }
        ],
        disableDate: {
            option: '>',
            day: 'today'
        }
    },
    {
        name: 'Customer Name',
        value: 'contactName',
        default: true,
        editable: true,
        dataType: 'modal',
        modalClass: 'modal-add-lookup',
        contact: true,
        modalFields: [
            { fieldName: 'title', fieldDB: 'title', name: 'Title', placeholder: 'title', type: 'select', class: 'col-md-4', settingsField: 'titles', collectionName: 'contactDetails', docID: 'contactID', },
            { fieldName: 'titleName' },
            { fieldName: 'firstName', fieldDB: 'firstName', name: 'First Name', placeholder: 'first name', type: 'text', class: 'col-md-8', collectionName: 'contactDetails', docID: 'contactID', },
            { fieldName: 'middleName', fieldDB: 'middleName', name: 'Middle Name', placeholder: 'middle name', type: 'text', class: 'col-md-12', collectionName: 'contactDetails', docID: 'contactID', },
            { fieldName: 'lastName', fieldDB: 'lastName', name: 'Last Name', placeholder: 'last name', type: 'text', class: 'col-md-12', collectionName: 'contactDetails', docID: 'contactID', },
            { fieldName: 'companyName', fieldDB: 'name', name: 'company', placeholder: 'company', type: 'text', class: 'col-md-12', collectionName: 'companies', docID: 'companyID', },
        ]
    },
    {
        name: 'Email',
        value: 'email',
        editable: true,
        default: true,
        fieldDB: 'email',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Phone',
        value: 'phoneNoCode',
        editable: true,
        default: true,
        fieldDB: 'phone',
        dataType: 'numberstring',
        placeholder: 'phone',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Make',
        value: 'make',
        default: true,
        editable: true,
        dataType: 'select',
        settingsField: 'makes',
        fieldDB: 'make',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Group',
        value: 'group',
        editable: true,
        dataType: 'select',
        settingsFieldPrefix: 'make',
        settingsField: 'groups',
        fieldDB: 'group',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Type',
        value: 'type',
        editable: true,
        dataType: 'select',
        settingsFieldPrefix: 'make',
        settingsField: 'types',
        fieldDB: 'type',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Model',
        value: 'model',
        default: true,
        editable: true,
        dataType: 'select',
        settingsFieldPrefix: 'make',
        settingsField: 'models',
        fieldDB: 'model',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Sale Type',
        value: 'vehicleSaletype',
        default: true,
        //editable: true,
        dataType: 'select',
        settingsField: 'salesType',
        fieldDB: 'saleType',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Year',
        value: 'vehicleYear',
        editable: true,
        dataType: 'select',
        settingsField: 'years',
        fieldDB: 'year',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Ext. Colour',
        value: 'extColor',
        editable: true,
        dataType: 'text',
        fieldDB: 'extColor',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Int. Colour',
        value: 'intColor',
        editable: true,
        dataType: 'text',
        fieldDB: 'intColor',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Vehicle Price',
        value: 'price',
        fieldDB: 'price',
        editable: true,
        dataType: 'price',
        collections: [
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        editable: true,
        fieldDB: 'vinNo',
        dataType: 'text',
        uppercase: true,
        collections: [
            { name: 'enquiries', fieldDB: 'chassisNo', },
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Reg #',
        value: 'regNo',
        editable: true,
        fieldDB: 'regNo',
        dataType: 'text',
        uppercase: true,
        collections: [
            { name: 'enquiries' },
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]

    },
    {
        name: 'Stock #',
        value: 'stockNo',
        editable: true,
        fieldDB: 'stockNo',
        dataType: 'text',
        collections: [
            { name: 'enquiries' },
            { name: 'enquiries', nestedObj: 'requirement', req: true },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID' },
        ]
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDateOn',
        editable: true,
        dataType: 'date',
        fieldDB: 'warrantyStartDate',
        formatSave: 'YYYY-MM-DD',
        sortField: 'warrantyStartDate',
        collections: [
            { name: 'enquiries' },
            { name: 'enquiries', nestedObj: 'requirement', req: true, fieldDB: 'warrantyStartDate', convertType: 'date' },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID', fieldDB: 'warrantyStartDate', convertType: 'date' },
        ]
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiryOn',
        editable: true,
        dataType: 'date',
        fieldDB: 'warrantyExpiry',
        sortField: 'warrantyExpiry',
        formatSave: 'YYYY-MM-DD',
        collections: [
            { name: 'enquiries' },
            { name: 'enquiries', nestedObj: 'requirement', req: true, fieldDB: 'warrantyExp', convertType: 'date' },
            { name: 'requirements', docID: 'requirementID', req: true, subcollection: true, collection: 'enquiries', collectionID: 'documentID', fieldDB: 'warrantyExp', convertType: 'date' },
        ]
    },
    {
        name: 'Origin',
        value: 'originName',
        default: true,
        editable: true,
        dataType: 'select',
        settingsField: 'origins',
        fieldDB: 'origin',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Status',
        value: 'statusName',
        dataType: 'select',
        html: true,
        settingsField: 'enquiryStatuses',
        //editable: true,
    },
    // {
    //     name: 'Last Note',
    //     value: 'lastNotes',
    //     editable: true,
    //     dataType: 'note'
    // },
    {
        name: 'Owner',
        value: 'ownerName',
        editable: true,
        dataType: 'select',
        settingsField: 'clientUsers',
        fieldDB: 'owner',
        html: true,
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Stage Date',
        value: 'stageDateOn',
        dataType: 'date',
        sortField: 'stageDate',
    },
    {
        name: 'Created On',
        value: 'createdOn',
        //editable: true,
    },
    {
        name: 'Last Updated On',
        value: 'updatedOn',
        dataType: 'date',
        sortField: 'modifiedDate',
    },
    {
        name: 'Unattended Leads',
        value: 'rottenDays',
    },
    {
        name: 'Requirement Count',
        value: 'requirementCount',
    },
    {
        name: 'Won Count',
        value: 'soldCount',
    },
    {
        name: 'Enquiry Age',
        value: 'enquiryAge',

    },
    {
        name: 'Lost Date',
        value: 'lostOn',
        editable: false,
        dataType: 'date',
        fieldDB: 'lostDate',
        sortField: 'lostDate',
        collections: [
            { name: 'enquiries' }
        ]

    },
    {
        name: 'Actual Delivery Date',
        value: 'deliveredOn',
        editable: false,
        dataType: 'date',
        fieldDB: 'deliveredDate',
        sortField: 'deliveredDate',
        time: true,
        collections: [
            { name: 'enquiries' }
        ]
    },

    {
        name: 'Estimated Delivery Date',
        value: 'deliveryOn',
        default: true,
        editable: true,
        dataType: 'datetime',
        fieldDB: 'deliveryDate',
        timeDB: 'deliveryTime',
        sortField: 'deliveryDate',
        collections: [
            { name: 'enquiries' }
        ],
        colors: true
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryTypeName',
        editable: true,
        dataType: 'select',
        settingsField: 'enquiryTypes',
        fieldDB: 'enquiryType',
        collections: [
            { name: 'enquiries' }
        ]
    },

    {
        name: 'Campaign',
        value: 'campaignName',
        editable: true,
        dataType: 'select',
        settingsField: 'campaigns',
        fieldDB: 'campaign',
        collections: [
            { name: 'enquiries' }
        ]


    },

    {
        name: 'Label',
        value: 'labelName',
        editable: true,
        dataType: 'select',
        settingsField: 'enqLabels',
        fieldDB: 'label',
        collections: [
            { name: 'enquiries' }
        ]

    },

    {
        name: 'Pipeline',
        value: 'pipelineName',
    },

    {
        name: 'Stage',
        value: 'stageName',
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention',
        editable: true,
        dataType: 'date',
        fieldDB: 'purchaseIntention',
        sortField: 'purchaseIntention',
        placeholder: 'purchase intention',
        format: 'MMM YYYY',
        formatSave: 'MMM YYYY',
        picker: 'month',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount',
        editable: true,
        default: true,
        dataType: 'price',
        fieldDB: 'grossAmount',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount',
        editable: true,
        default: true,
        dataType: 'price',
        fieldDB: 'depositAmount',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber',
        editable: true,
        fieldDB: 'depositReciptNumber',
        dataType: 'text',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Lead Source',
        value: 'leadSourceName',
        editable: true,
        dataType: 'select',
        settingsField: 'leadSource',
        fieldDB: 'leadSource',
        collections: [
            { name: 'enquiries' }
        ]
    },

    {
        name: 'Contract Number',
        value: 'contractNumber',
        editable: true,
        fieldDB: 'contractNumber',
        dataType: 'text',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Verified',
        value: 'verifiedDateOn',
        dataType: 'date',
        sortField: 'verifiedDate',

    },
    {
        name: 'Received Date',
        value: 'receivedDateOn',
        dataType: 'date',
        sortField: 'receivedDate',
    },
    {
        name: 'Quote Amount',
        value: 'quoteAmount',
        editable: true,
        dataType: 'price',

    },
    {
        name: 'Confirmed',
        value: 'confirmedDateOn',
        dataType: 'date',
        sortField: 'confirmedDate',

    },
    {
        name: 'Converted Date',
        value: 'convertedOn',

    },
    {
        name: 'Test Drive Model',
        value: 'testdriveModel',
        dataType: 'modal',
        modalClass: 'modal-view-testdrive',
        modalTitle: 'Test Drive List',
        modalQuery: {
            collection: 'testdrives',
            convertVM: 'convertTestdriveVM',
            where: [
                { field: 'isDeleted', value: false, fixed: true },
                { field: 'enquiryID', value: 'id' },
                { field: 'clientID', value: 'clientID' }
            ]
        },
        editable: true,
    },
    {
        name: 'Trade-In Model',
        value: 'tradeinModel',
        dataType: 'modal',
        modalClass: 'modal-view-testdrive',
        modalTitle: 'Trade-In List',
        modalQuery: {
            collection: 'tradeins',
            convertVM: 'convertTradeinVM',
            where: [
                { field: 'isDeleted', value: false, fixed: true },
                { field: 'enquiryID', value: 'id' },
                { field: 'clientID', value: 'clientID' }
            ]
        },
        editable: true,
    },
    {
        name: 'Inbound Lead Source',
        value: 'domleadSource',
    },
    {
        name: 'Finance Business Manager',
        value: 'financeBMName',
        editable: true,
        dataType: 'select',
        settingsField: 'financeManagers',
        fieldDB: 'financeBM',
        html: true,
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Finance Status',
        value: 'financeStatusName',
        editable: true,
        dataType: 'select',
        settingsField: 'financeStatuses',
        fieldDB: 'financeStatus',
        html: true,
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Finance Amount',
        value: 'financeAmount',
        editable: true,
        dataType: 'price',
        fieldDB: 'financeAmount',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'Finance Rate',
        value: 'financeRate',
        editable: true,
        dataType: 'number',
        suffix: '%',
        fieldDB: 'financeRate',
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'After Market Manager',
        value: 'afterMarketBMName',
        editable: true,
        dataType: 'select',
        settingsField: 'afterMarketManagers',
        fieldDB: 'afterMarketBM',
        html: true,
        collections: [
            { name: 'enquiries' }
        ]
    },
    {
        name: 'After Market Status',
        value: 'afterMarketStatusName',
        editable: true,
        dataType: 'select',
        settingsField: 'afterMarketStatuses',
        fieldDB: 'afterMarketStatus',
        html: true,
        collections: [
            { name: 'enquiries' }
        ]

    },
    {
        name: 'First Name',
        value: 'firstName',
        editable: true,
        fieldDB: 'firstName',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Middle Name',
        value: 'middleName',
        editable: true,
        fieldDB: 'middleName',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Last Name',
        value: 'lastName',
        editable: true,
        fieldDB: 'lastName',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'preferred Name',
        value: 'preferredName',
        editable: true,
        fieldDB: 'preferredName',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiryOn',
        editable: true,
        dataType: 'date',
        formatSave: 'YYYY-MM-DD',
        fieldDB: 'licenseExpiry',
        sortField: 'licenseExpiry',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'License State',
        value: 'licenseStateName',
        editable: true,
        dataType: 'select',
        fieldDB: 'licenseState',
        settingsFieldPrefix: 'country',
        settingsField: 'states',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'License Type',
        value: 'licenseTypeName',
        editable: true,
        dataType: 'select',
        settingsField: 'licenseType',
        fieldDB: 'licenseType',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Address',
        value: 'address',
        editable: true,
        dataType: 'address',
        fieldDB: 'address',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'State',
        value: 'stateName',
        editable: true,
        dataType: 'select',
        settingsFieldPrefix: 'country',
        settingsField: 'states',
        fieldDB: 'state',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Country',
        value: 'countryName',
        editable: true,
        dataType: 'select',
        settingsField: 'countries',
        fieldDB: 'country',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Area Code',
        value: 'areaCode',
        editable: true,
        fieldDB: 'areaCode',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Designation',
        value: 'designation',
        editable: true,
        fieldDB: 'designation',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Birthday',
        value: 'dobOn',
        editable: true,
        dataType: 'date',
        formatSave: 'YYYY-MM-DD',
        fieldDB: 'dob',
        sortField: 'dob',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Gender',
        value: 'genderName',
        editable: true,
        dataType: 'select',
        settingsField: 'genders',
        fieldDB: 'gender',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Marital Status',
        value: 'maritalStatusName',
        editable: true,
        dataType: 'select',
        settingsField: 'maritalStatus',
        fieldDB: 'maritalStatus',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Nationality',
        value: 'nationalityName',
        editable: true,
        dataType: 'select',
        settingsField: 'nationalities',
        fieldDB: 'nationality',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]


    },
    {
        name: 'Language',
        value: 'languageName',
        editable: true,
        dataType: 'select',
        settingsField: 'languages',
        fieldDB: 'language',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Phone Opt In',
        value: 'optinPhoneName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'optinPhone',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'SMS Opt In',
        value: 'optinSMSName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'optinSMS',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Email Opt In',
        value: 'optinEmailName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'optinEmail',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Post Opt In',
        value: 'optinPostName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'optinPost',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmailName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'marketingOptinEmail',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]

    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMSName',
        editable: true,
        dataType: 'select',
        settingsField: 'opts',
        fieldDB: 'marketingOptinSMS',
        collections: [
            { name: 'enquiries', nestedObj: 'contact' },
            { name: 'contactDetails', docID: 'contactID' },
        ]
    },
    {
        name: 'Enquiry Number',
        value: 'displayID',
        default: true
    },
]

export const alltestdriveFields = [
    {
        name: 'Stock #',
        value: 'stockNo',
        default: true,
    },
    {
        name: 'Make',
        value: 'make',
        default: true,
    },
    {
        name: 'Model',
        value: 'model',
        default: true,
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        default: true,

    },
    {
        name: 'Start Date',
        value: 'startDate',
        default: true,
    },
    {
        name: 'Created By',
        value: 'addedBy',
        default: true,
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn',
        default: true,
    },
    {
        name: 'End Date',
        value: 'endDate',
        default: true,
    },
    {
        name: 'Updated By',
        value: 'modifiedBy',
        default: true,
    },
    {
        name: 'Status',
        value: 'tdstatusName',
        default: true,
        dataType: 'select',
        html: true,
        settingsField: 'tdStatuses',
    },
    {
        name: 'Stock Status',
        value: 'stockStatusName',
        default: true,
        dataType: 'select',
        html: true,
        settingsField: 'stockStatuses',

    },
    {
        name: 'Notes',
        value: 'notes',
        default: true,
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer',
        default: true,
    },
    {
        name: 'End Odometer',
        value: 'endOdometer',
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        default: true,
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        default: true,
    },
    {
        name: 'Year',
        value: 'year',
        default: true,
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        default: true,
    },
    {
        name: 'Reg #',
        value: 'regNo',
        default: true,
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        default: true,
    },

    {
        name: 'Body Type',
        value: 'type',
        default: true,
    },
    {
        name: 'Action',
        value: 'documentID',
        dataType: 'button',
        buttons: [
            { action: 'onReportClick', name: 'Report', className: 'btn btn-secondary float-left mr-2 mt-1 grid-small-button', icon: 'ico icon-waiver mr-1 mt-1' },
            { action: 'onMapClick', name: 'Map', className: 'btn btn-secondary float-left mr-2 mt-1 grid-small-button', icon: 'ico icon-map mr-1 mt-1' },
        ],
        default: true,
        pinned: 'right',
        sort: false,
        editable: false,
    }
]

export const tagColors = [
    { value: '#26bda5', name: 'Green' },
    { value: '#6a78d1', name: 'Violet' },
    { value: '#f2545c', name: 'Red' },
    { value: '#f2547d', name: 'Pink' },
    { value: '#fb8f59', name: 'Orange' },
    { value: '#f6c26b', name: 'Yellow' }
]

export const revenueTargets = [
    { value: 'carryover', name: 'Carry Over' },
    { value: 'won', name: 'Sold', target: true },
    { value: 'covered', name: 'Covered', target: true },
    { value: 'delivered', name: 'Delivered', target: true },
    { value: 'reported', name: 'Reported', target: true }
]