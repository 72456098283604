import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import CommonHelper from '../../../../services/common'
import { LineChart } from '../../../../components'

const LeadSalesAnalysis = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [allEnquiries, setEnquiries] = useState([]);
    const [reportData, setReportData] = useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? moment(_date) : moment();

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(_date.endOf('day')._d)
    })

    const columnValues = [
        { name: 'Leads', value: 'leads', color: '#318de4' },
        { name: 'Sold', value: 'wonLeads', color: '#fc6940' }
    ];
    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {

        if (_.isEmpty(firestoreDB))
            return

        let refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('clientID', '==', props.clientid)
            .where('addedDate', '>=', dateRange.startDate)
            .where('addedDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)

        //WON ENQUIRIES  
        let refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('status', '==', 'won')
            .where('clientID', '==', props.clientid)
            .where('wonDate', '>=', dateRange.startDate)
            .where('wonDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)

        let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)

        const unSubLeadsalesanbalysisReport = combineLatest(
            refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
            refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
            collection(refClientData)
        ).pipe(
            map(([openLeads, wonLeads, clients]) => {
                return [
                    _.map(openLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(wonLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            }))
            .subscribe(allDocs => {
                var _enquiries = [];
                for (let i = 0; i < 2; i++) {
                    allDocs[i].forEach(el => {
                        if (!_enquiries.some(e => e.documentID === el.documentID) && !_.isEmpty(el.pipeline) && el.pipeline !== 'LeadsBucket' && !_.isEmpty(el.requirement)) {
                            _enquiries.push(el)
                        }
                    });
                }
                setClient(allDocs[2] ? Object.assign({}, allDocs[2][0]) : {})
                setEnquiries(_enquiries)
                setLoader(false)
            });
        return () => {
            unSubLeadsalesanbalysisReport && unSubLeadsalesanbalysisReport.unsubscribe()
        }
    }, [firestoreDB])

    useEffect(() => {
        if (_.isEmpty(allEnquiries) || _.isEmpty(client))
            return
        let makeSaleType = [{ title: 'TOTAL LEADS', make: 'all', saleType: 'all' }];
        if (!_.isEmpty(client.clientSettings) && !_.isEmpty(client.clientSettings.leadsalesAnalysis) && !_.isEmpty(client.clientSettings.leadsalesAnalysis.make)) {
            let makes = client.clientSettings.leadsalesAnalysis.make.split(';')
            makes.forEach(rec => {
                let makeSplit = rec.split(':');
                if (makeSplit[0].toLowerCase() === 'other') {
                    makeSaleType.push({
                        title: `OTHER MAKES`,
                        saleType: makeSplit[1] ? makeSplit[1].toLowerCase() : 'all',
                        notEqual: makes.map(r => {
                            return r.split(':')[0]
                        }).filter(r => r.toLowerCase() !== 'other' && r.toLowerCase() !== 'all')
                    })
                }
                else {
                    makeSaleType.push({
                        title: `${makeSplit[0].toUpperCase()} ${makeSplit[1].toUpperCase()} LEADS`,
                        make: makeSplit[0].toLowerCase(),
                        saleType: makeSplit[1].toLowerCase()
                    })
                }
            })
        }
        else {
            makeSaleType.push({ title: 'BMW NEW LEADS', make: 'bmw', saleType: 'new' });
            makeSaleType.push({ title: 'BMW USED LEADS', make: 'bmw', saleType: 'used' });
            makeSaleType.push({ title: 'MINI NEW LEADS', make: 'mini', saleType: 'new' });
            makeSaleType.push({ title: 'MINI USED LEADS', make: 'mini', saleType: 'used' });
            makeSaleType.push({ title: 'OTHER MAKES', saleType: 'all', notEqual: ['bmw', 'mini'] })
        }

        let _report = [];
        let _arrDates = ['wtd', 'mtd']
        makeSaleType.forEach(rec => {
            let _reportbyMake = [];
            _arrDates.forEach(d => {
                let _leads = [];
                let dates = d === 'wtd' ? CommonHelper.getAllDays(moment.unix(dateRange.endDate.seconds).add('days', -6), moment.unix(dateRange.endDate.seconds).add('days', 1)) :
                    d === 'mtd' ? CommonHelper.getAllWeeks(moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds), moment().day(client.weekStart ? client.weekStart : 1).day()) : []
                dates.forEach(date => {
                    let sDate = moment(date[0]).startOf('day').unix();
                    let eDate = moment(date[date.length - 1]).endOf('day').unix();
                    _leads.push({
                        name: d === 'wtd' ? moment(date[0]).format('ddd, D MMM') : `${moment(date[0]).format('DD MMM')}-${moment(date[date.length - 1]).format('DD MMM YYYY')}`,
                        leads: allEnquiries.filter(a =>
                            (rec.make && rec.make !== 'all' ? (a.requirement && (a.requirement.make && a.requirement.make.toLowerCase() === rec.make.toLowerCase() || (a.requirement.stock && a.requirement.stock.make && a.requirement.stock.make.toLowerCase() === rec.make.toLowerCase()))) : true)
                            && (!_.isEmpty(rec.notEqual) ? (a.requirement && (a.requirement.make && !rec.notEqual.some(b => a.requirement.make.toLowerCase() === b.toLowerCase()) || (a.requirement.stock && a.requirement.stock.make && !rec.notEqual.some(b => a.requirement.stock.make.toLowerCase() === b.toLowerCase())))) : true)
                            && (rec.saleType === 'new' ? (a.requirement && (a.requirement.saleType && a.requirement.saleType.toLowerCase() !== 'preowned' || (a.requirement.stock && a.requirement.stock.saleType && a.requirement.stock.saleType.toLowerCase() !== 'preowned'))) : true)
                            && (rec.saleType === 'used' ? (a.requirement && (a.requirement.saleType && a.requirement.saleType.toLowerCase() === 'preowned' || (a.requirement.stock && a.requirement.stock.saleType && a.requirement.stock.saleType.toLowerCase() === 'preowned'))) : true)
                            && a.addedDate.seconds >= sDate && a.addedDate.seconds <= eDate).length,
                        wonLeads: allEnquiries.filter(a =>
                            (rec.make && rec.make !== 'all' ? (a.requirement && (a.requirement.make && a.requirement.make.toLowerCase() === rec.make.toLowerCase() || (a.requirement.stock && a.requirement.stock.make && a.requirement.stock.make.toLowerCase() === rec.make.toLowerCase()))) : true)
                            && (!_.isEmpty(rec.notEqual) ? (a.requirement && (a.requirement.make && !rec.notEqual.some(b => a.requirement.make.toLowerCase() === b.toLowerCase()) || (a.requirement.stock && a.requirement.stock.make && !rec.notEqual.some(b => a.requirement.stock.make.toLowerCase() === b.toLowerCase())))) : true)
                            && (rec.saleType === 'new' ? (a.requirement && (a.requirement.saleType && a.requirement.saleType.toLowerCase() !== 'preowned' || (a.requirement.stock && a.requirement.stock.saleType && a.requirement.stock.saleType.toLowerCase() !== 'preowned'))) : true)
                            && (rec.saleType === 'used' ? (a.requirement && (a.requirement.saleType && a.requirement.saleType.toLowerCase() === 'preowned' || (a.requirement.stock && a.requirement.stock.saleType && a.requirement.stock.saleType.toLowerCase() === 'preowned'))) : true)
                            && a.wonDate && a.wonDate.seconds >= sDate && a.wonDate.seconds <= eDate && (a.status === 'won' || a.status === 'delivered')).length,
                    })
                })
                _reportbyMake.push({
                    title: `${d.toUpperCase()} Leads vs Sales`,
                    report: _leads
                })
            })
            _report.push({
                ...rec,
                data: _reportbyMake
            })
        })
        setReportData(_report)
     
    }, [allEnquiries, client])

    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header report-data-loaded report-data-load">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`LEAD vs SALES ANALYSIS - ${client.name.toUpperCase()}`} <br></br>
                                    <span> {_date.format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="chart-report-table">
                    <tbody>
                        <tr>
                            <td style={{ border: 'none !important', padding: '0 !important' }} >
                                {
                                    reportData.map((report, index) => {
                                        return <div key={index} style={{ height: `1150px` }}>
                                            <div className="chart-report-wraper brd-none">
                                                <div className="chart-report-head">{report.title}</div>
                                                {
                                                    report.data.map((data, i) => {
                                                        return <div key={i} className="chart-report-borderbox">
                                                            <div className="chart-report-borderbox-head">{data.title}</div>
                                                            <div className="chart-report-borderbox-chart">
                                                                <LineChart
                                                                    id={`${report.title.replace(' ', '-')}-${data.title.replace(' ', '-')}`}
                                                                    height={`350px`}
                                                                    data={data.report}
                                                                    columns={['leads', 'wonLeads']}
                                                                    dynamicColumns={columnValues}
                                                                    calculationType={'count'}
                                                                    handleShowEnquiryList={(value, startDate, endDate) => {

                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="footer">
                    <div className="footer-bottom">

                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel : <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <>Web : <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className="footer-right"></div>

                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )

}

export default LeadSalesAnalysis;