import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'

const SummaryReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [reportData, setReportData] = useState([]);
    const [brands, setBrands] = useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? moment(_date) : moment();

    const [dateRange, setDateRange] = useState({})

    const arrheadOffice = ['bmw aus lead', 'mini aus lead']
    const arrAutogate = ['autogate', 'carsales']
    const arrInternet = ['web', 'website', 'internet']
    const dateTypes = ['WTD', 'MTD', 'YTD']
    const saleTypes = ['New', 'Used']

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(async (doc) => {
                if (doc.exists) {
                    if (doc.data().clientBrandIDs) {
                        let _brands = []
                        const brandpromises = []
                        doc.data().clientBrandIDs.forEach(bid => {
                            brandpromises.push(window.firebase.firestore().doc(`brands/${bid}`).get())
                        })
                        const brandsnapshots = await Promise.all(brandpromises);
                        brandsnapshots.forEach(snap => {
                            _brands.push(snap.data().value)
                        })
                        setBrands(_brands);
                    }
                    let timezone = doc.data().timezone ? doc.data().timezone : 'Australia/Sydney';
                    // moment.updateLocale('en', {
                    //     week: {
                    //         dow: moment().day(doc.data().weekStart ? doc.data().weekStart : 1).day(),
                    //     },
                    // })
                    setDateRange({
                        monthStart: moment(_date).tz(timezone).startOf('month').unix(),
                        monthEnd: moment(_date).tz(timezone).endOf('day').unix(),
                        yearStart: moment(_date).tz(timezone).startOf('year').unix(),
                        yearEnd: moment(_date).tz(timezone).endOf('day').unix(),
                        weekStart: moment(_date).tz(timezone).add('days', -6).startOf('day').unix(),
                        weekEnd: moment(_date).tz(timezone).endOf('day').unix()
                    })
                    setClient({
                        ...doc.data(),
                        documentID: doc.id
                    });

                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(client) || _.isEmpty(brands))
            return
        let makeSaleType = [];
        let makes = '';
        if (!_.isEmpty(brands)) {
            brands.forEach(mk => {
                _.filter(saleTypes, (v) => !_.isEmpty(client.reportSaleType) ? _.indexOf(client.reportSaleType, v) >= 0 : true).forEach(st => {
                    makes += `${mk}:${st};`;
                })
            })
            if (_.isEmpty(client.reportSaleType) || client.reportSaleType.includes('Used'))
                makes += 'others:All;nomake:All';
            else
                makes += 'nomake:All';
        }
        if (_.isEmpty(makes)) {
            makes = 'BMW:New;MINI:New;BMW:Used;MINI:Used;others:All;nomake:All';
        }

        makes.split(';').forEach(rec => {
            let makeSplit = rec.split(':');
            makeSaleType.push({
                title: (`${makeSplit[0].toUpperCase() === 'ALL' ? '' : makeSplit[0].toUpperCase() === 'NOMAKE' ? 'LEADS WITHOUT VEHICLE MODEL REQUIREMENT' : makeSplit[0].toUpperCase() === 'OTHERS' ? 'Others Used' : makeSplit[0].toUpperCase()} ${makeSplit[0].toUpperCase() === 'NOMAKE' || makeSplit[0].toUpperCase() === 'OTHERS' ? '' : makeSplit[1].toUpperCase()} ${makeSplit[0].toUpperCase() !== 'NOMAKE' ? 'ENQUIRY' : ''}`).trim(),
                make: makeSplit[0].toLowerCase(),
                saleType: makeSplit[1].toLowerCase()
            })
        });
        const getEnquiryData = window.firebase.functions().httpsCallable('generic-getData');
        getEnquiryData({
            type: 'getSummaryReportbyUsers',
            params: JSON.stringify({
                clientID: client.documentID,
                date: moment(_date).format('YYYY-MM-DD')
            })
        }).then((response) => {
            if (response.data.success) {
                //activity collection and origin col is removed
                let _data = response.data.data;
                let _reportData = [];
                makeSaleType.forEach((rec) => {
                    let wkNewLead = _data.filter(e => e.col === 'enq' && e.dt >= dateRange.weekStart && e.dt <= dateRange.weekEnd).map(r => { return r.eid });
                    let mnNewLead = _data.filter(e => e.col === 'enq' && e.dt >= dateRange.monthStart && e.dt <= dateRange.monthEnd).map(r => { return r.eid });
                    let yrNewLead = _data.filter(e => e.col === 'enq' && e.dt >= dateRange.yearStart && e.dt <= dateRange.yearEnd).map(r => { return r.eid });
                    //week data
                    _reportData.push({
                        title: rec.title,
                        dateType: 'WTD',
                        name: `WTD ${moment(_date).add(-6, 'days').format('DD MMM')}-${moment(_date).format('DD MMM YYYY')}`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => a.col === 'enq' || a.col === 'td' || a.col === 'wk'), rec, dateRange.weekStart, dateRange.weekEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && wkNewLead.includes(a.eid)), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act'), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act')), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                    })

                    //month data
                    _reportData.push({
                        title: rec.title,
                        dateType: 'MTD',
                        name: `MTD ${moment(_date).format('MMM')}`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => a.col === 'enq' || a.col === 'td' || a.col === 'wk'), rec, dateRange.monthStart, dateRange.monthEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && mnNewLead.includes(a.eid)), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act'), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act')), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                    })

                    //year data
                    _reportData.push({
                        title: rec.title,
                        dateType: 'YTD',
                        name: `YTD ${moment(_date).format('YYYY')}`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => a.col === 'enq' || a.col === 'td' || a.col === 'wk'), rec, dateRange.yearStart, dateRange.yearEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && yrNewLead.includes(a.eid)), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.yearStart, dateRange.yearEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act'), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act')), rec, dateRange.yearStart, dateRange.yearEnd, null, null, null),
                    })
                })

                if (_reportData.length > 0) {
                    dateTypes.map(rec => {
                        return _reportData.push({
                            title: 'Totals',
                            dateType: rec,
                            name: `Total ${rec}`,
                            walkIn: _.sumBy(_reportData.filter(a => a.dateType === rec), 'walkIn'),
                            phone: _.sumBy(_reportData.filter(a => a.dateType === rec), 'phone'),
                            headOffice: _.sumBy(_reportData.filter(a => a.dateType === rec), 'headOffice'),
                            autogate: _.sumBy(_reportData.filter(a => a.dateType === rec), 'autogate'),
                            web: _.sumBy(_reportData.filter(a => a.dateType === rec), 'web'),
                            outbound: _.sumBy(_reportData.filter(a => a.dateType === rec), 'outbound'),
                            newLead: _.sumBy(_reportData.filter(a => a.dateType === rec), 'newLead'),
                            existingLead: _.sumBy(_reportData.filter(a => a.dateType === rec), 'existingLead'),
                            facetoface: _.sumBy(_reportData.filter(a => a.dateType === rec), 'facetoface'),
                            testdrives: _.sumBy(_reportData.filter(a => a.dateType === rec), 'testdrives'),
                            newtestdrives: _.sumBy(_reportData.filter(a => a.dateType === rec), 'newtestdrives'),
                            testdriveSold: _.sumBy(_reportData.filter(a => a.dateType === rec), 'testdriveSold'),
                            newsold: _.sumBy(_reportData.filter(a => a.dateType === rec), 'newsold'),
                            sold: _.sumBy(_reportData.filter(a => a.dateType === rec), 'sold'),
                            followup: _.sumBy(_reportData.filter(a => a.dateType === rec), 'followup'),
                            totalFollowup: _.sumBy(_reportData.filter(a => a.dateType === rec), 'totalFollowup'),
                        })
                    })

                    _reportData = _reportData.map(rec => {
                        return {
                            ...rec,
                            other: sumOtherLeads(rec),
                            totalLead: rec.newLead + rec.existingLead,
                            followupRate: (rec.totalFollowup > 0 ? parseFloat(((100 / rec.totalFollowup) * rec.followup)).toFixed(2) : '0') + '%',
                            leadtestdriveRatio: ((rec.newLead + rec.existingLead) > 0 ? parseFloat(((100 / (rec.newLead + rec.existingLead)) * rec.testdrives)).toFixed(2) : '0') + '%',
                            leadsaleRatio: ((rec.newLead + rec.existingLead) > 0 ? parseFloat(((100 / (rec.newLead + rec.existingLead)) * rec.sold)).toFixed(2) : '0') + '%',
                            testdrivesaleRatio: (rec.testdrives > 0 ? parseFloat(((100 / rec.testdrives) * rec.testdriveSold)).toFixed(2) : '0') + '%',
                            newtestdriveRatio: (rec.newLead > 0 ? parseFloat(((100 / rec.newLead) * rec.newtestdrives)).toFixed(2) : '0') + '%',
                            newsaleRatio: (rec.newLead > 0 ? parseFloat(((100 / rec.newLead) * rec.newsold)).toFixed(2) : '0') + '%',
                        }
                    })
                }

                setReportData(_.chain(_reportData)
                    .groupBy("title")
                    .map((value) => value)
                    .value());
                setLoader(false)
            }
            else {
                setLoader(false)
            }
        });
    }, [client, brands])

    const sumOtherLeads = (rec) => {
        if ((rec.newLead - (rec.walkIn + rec.phone + rec.web + rec.outbound)) > 0)
            return (rec.newLead - (rec.walkIn + rec.phone + rec.web + rec.outbound))

        return 0;
    }

    const checkDataFilter = (_data, rec, startDt, endDt, enqType, origin, status, dateType, newlead) => {

        return _.uniq(_.map(_data.filter(a =>
            (((enqType === 'walk' || enqType === 'outbound')) ? (a.et && a.et.toLowerCase().includes(enqType.toLowerCase())) : true)
            && ((enqType === 'phone') ? (a.et && (a.et.toLowerCase().includes(enqType.toLowerCase()) || a.et.toLowerCase().includes('inbound'))) : true)
            && ((origin === 'headOffice') ? (a.org && arrheadOffice.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((origin === 'autogate') ? (a.org && arrAutogate.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((enqType === 'web') ? (a.et && arrInternet.some(b => b.toLowerCase() === a.et.toLowerCase())) : true)
            && ((status === 'sold') ? (a.est && (a.est.toLowerCase() === 'won' || a.est.toLowerCase() === 'delivered')) : true)
            && ((rec.make && rec.make !== 'all' && rec.make !== 'others' && rec.make !== 'nomake') ? (a.mk && a.mk.toLowerCase() === rec.make) : true)
            && (rec.make === 'others' ? (a.mk && !brands.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && (rec.make === 'nomake' ? _.isEmpty(a.mk) : true)
            && ((rec.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((rec.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ), function (e) {
            return e.eid
        })).length;
    }

    const checkExistingLedas = (_data, rec, startDt, endDt) => {

        return _.uniq(_.map(_data.filter(a =>
            ((rec.make && rec.make !== 'all' && rec.make !== 'others' && rec.make !== 'nomake') ? (a.mk && a.mk.toLowerCase() === rec.make) : true)
            && (rec.make === 'others' ? (a.mk && !brands.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && (rec.make === 'nomake' ? _.isEmpty(a.mk) : true)
            && ((rec.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((rec.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && (a.col === 'enq' && a.dt < startDt)
            && (
                (a.dt >= startDt && a.dt <= endDt)
                || (a.wd >= startDt && a.wd <= endDt)
                || (a.est && (a.est.toLowerCase() === 'lost' || a.est.toLowerCase() === 'prospectlost' || a.est.toLowerCase() === 'pendinglost') && a.ld >= startDt && a.ld <= endDt)
                || (_data.some(b => (b.col === 'wk' || b.col === 'td') && a.eid === b.eid && (b.dt >= startDt && b.dt <= endDt)))
            )
        ), function (e) {
            return e.eid
        })).length;
    }

    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="report-loader spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`SUMMARY REPORT - ${client.name.toUpperCase()}`} <br></br>
                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="summary-table-wraper">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td className="outside-td">
                                    <table className="summary-table-loop">
                                        <tbody>
                                            {
                                                reportData.map((rec, index) => {
                                                    return <><tr key={index} >
                                                        <th className="cell-head td-head" width="12%">{rec[0].title}</th>
                                                        <td className="td-subhead" width="5%">WALKIN</td>
                                                        <td className="td-subhead" width="5%">PHONE-IN</td>
                                                        {/* <td className="td-subhead" width="5%">HEAD OFFICE</td>
                                                        <td className="td-subhead" width="5%">AUTOGATE </td> */}
                                                        <td className="td-subhead" width="5%">INTERNET  </td>
                                                        <td className="td-subhead" width="5%">OUTBOUND</td>
                                                        <td className="td-subhead" width="5%">OTHER</td>
                                                        <td className="td-subhead" width="5%">NEW LEADS</td>
                                                        <td className="td-subhead" width="5%">EXISITNG LEADS</td>
                                                        <td className="td-subhead" width="5%">TOTAL LEADS</td>
                                                        <td className="td-subhead" width="8%">NEW LEADS TEST DRIVE (%)</td>
                                                        <td className="td-subhead" width="8%">TOTAL LEADS TEST DRIVE (%)</td>
                                                        <td className="td-subhead" width="8%">NEW LEADS SOLD (%)</td>
                                                        <td className="td-subhead" width="8%">OVERALL LEADS SOLD (%)</td>
                                                        <td className="td-subhead" width="8%">TEST DRIVE TO SALE (%)</td>
                                                    </tr>
                                                        {
                                                            rec.map((data, index) => {
                                                                return <tr key={index}>
                                                                    <td className="td-head">{data.name}</td>
                                                                    <td className="count-large">{data.walkIn}</td>
                                                                    <td className="count-large">{data.phone}</td>
                                                                    {/* <td className="count-large">{data.headOffice}</td>
                                                                    <td className="count-large">{data.autogate}</td> */}
                                                                    <td className="count-large">{data.web}</td>
                                                                    <td className="count-large">{data.outbound}</td>
                                                                    <td className="count-large">{data.other}</td>
                                                                    <td className="count-large">{data.newLead}</td>
                                                                    <td className="count-large">{data.existingLead}</td>
                                                                    <td className="count-large">{data.totalLead}</td>
                                                                    <td className="count-large">{`${data.newtestdrives} (${data.newtestdriveRatio})`}</td> {/*   */}
                                                                    <td className="count-large">{`${data.testdrives} (${data.leadtestdriveRatio})`}</td> {/*   */}
                                                                    <td className="count-large">{`${data.newsold} (${data.newsaleRatio})`}</td> {/*   */}
                                                                    <td className="count-large">{`${data.sold} (${data.leadsaleRatio})`}</td> {/*   */}
                                                                    <td className="count-large">{`${data.testdriveSold} (${data.testdrivesaleRatio})`}</td> {/*   */}
                                                                </tr>
                                                            })
                                                        }
                                                        <tr>
                                                            <td colSpan="14" style={{ padding: "0px", border: "none", height: "30px" }} >&nbsp;</td>
                                                        </tr>
                                                    </>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <div className="footer-bottom">
                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <>Web: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>
                        <div className="footer-right"></div>
                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )
}

export default SummaryReport;