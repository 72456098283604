import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { PopUpModal } from "../../components";
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData, docData } from 'rxfire/firestore';
import EmailListBody from '../mails/emailListBody'
import ComposeEmail from '../mails/composeEmail'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const EmailPreview = (props) => {
    const [selectedThread, setSelectedThread] = useState(props.emailLog)
    const [sendLoader, setSendLoader] = useState(false);
    const [emailtemplates, setEmailTemplates] = useState([]);
    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
    });


    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || !_.isEmpty(emailtemplates)) {
            return;
        }

        var alltemplates = combineLatest(
            collectionData(
                window.firebase
                    .firestore()
                    .collection(
                        `clientSettings/${props.dealersettings.client.settingsID}/templates`,
                    )
                    .where('clientID', '==', props.dealersettings.client.id)
                    .where('visibility', '==', 'private')
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'emailTemplate'),
            ),
            collectionData(
                window.firebase
                    .firestore()
                    .collection(
                        `clientSettings/${props.dealersettings.client.settingsID}/templates`,
                    )
                    .where('visibility', '==', 'shared')
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'emailTemplate'),
            ),
        )
            .pipe(
                map(templates => {
                    var [dealer, shared] = templates;
                    return [[...dealer], [...shared]];
                }),
            )
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => {
                    _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec);
                });
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push(rec);
                });
                setEmailTemplates(listVM);
            });
        return () => {
            alltemplates && alltemplates.unsubscribe();
        };
    }, []);


    const handleOpenComposeModal = ({ type, title, emailData, contact, enquiry }) => {
        setShowComposeModal({
            ...showComposeModal,
            show: true,
            type,
            title,
            emailData: emailData ? emailData : null,
        });
    };

    const updateThread = async (message, type, data) => {

        try {
            let reqObj = {
               
            };
            const nylasUpdateThread = window.firebase
                .functions()
                .httpsCallable('nylasv3-updateThread');
            
            if (type === 'star') {
                reqObj.starred = true;
            }
            if (type === 'unstar') {
                reqObj.starred = false;
            }
            if (type === 'star') {
                setSelectedThread({
                    ...message,
                    'starred': true
                })
                // updatedMessage.starred = true;
                // focusedMessage = updatedMessage;
                toast.notify('Conversation starred.', {
                    duration: 2000,
                });
            }
            if (type === 'unstar') {
                setSelectedThread({
                    ...message,
                    'starred': false
                })
                toast.notify('Conversation unstarred.', {
                    duration: 2000,
                });
                // console.log(updatedMessage);
            }
            const response = await nylasUpdateThread({
                grantId: props.dealersettings.nylas_v3.grantId,
                threadId: message.id,
                options: reqObj
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            ...showComposeModal,
            show: false,
            type: '',
            title: '',
            emailData: null,
        });
    };

    // const handleReply = async (trigger, emailData, handleClose, replyToAll) => {
    //     const { mailTo, mailCc, mailBcc } = trigger;
    //     // console.log(emailData);
    //     setSendLoader(true);

    //     if (mailTo === '' && mailCc === '' && mailBcc === '') {
    //         toast.notify('Please specify at least one recipient.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     try {
    //         let emailObj = {
    //             token: props.dealersettings.nylasAccessToken,
    //             draft: {
    //                 // "subject": trigger.subject,
    //                 body: trigger.email,
    //             },
    //         };

    //         emailObj.draft.to = trigger.mailTo.split(',').map(email => {
    //             return {
    //                 name: '',
    //                 email: email,
    //             };
    //         });
    //         emailObj.draft.replyTo = trigger.mailTo.split(',').map(email => {
    //             return {
    //                 name: '',
    //                 email: email,
    //             };
    //         });
    //         emailObj.draft.body = trigger.email;
    //         emailObj.draft.replyToMessageId = emailData.replyToMessageId;

    //         if (replyToAll) {
    //             emailObj.draft.replyToCC = trigger.mailCc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.files.length > 0) {
    //             emailObj.files = trigger.files;
    //         }
    //         // console.log(emailObj);
    //         setTimeout(() => {
    //             toast.notify('Email has been sent.', {
    //                 duration: 2000,
    //             });
    //             setShowComposeModal({
    //                 ...showComposeModal,
    //                 show: false,
    //                 type: '',
    //                 title: '',
    //                 emailData: null,
    //             });
    //         }, 300);

    //         const nylasSendEmail = window.firebase
    //             .functions()
    //             .httpsCallable('nylas-sendEmail');
    //         // console.log(emailObj);
    //         const resp = await nylasSendEmail(emailObj);
    //         if (resp.data.success) {
    //             setSendLoader(false);
    //         } else {
    //             setSendLoader(false);
    //             if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
    //                 Swal.fire({
    //                     title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
    //                     icon: 'info',
    //                     showCancelButton: true,
    //                     confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
    //                     cancelButtonText: CommonHelper.showLocale(props, 'Later'),
    //                 }).then(result => {
    //                     if (result.value) {
    //                         window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
    //                             nylas: null,
    //                         }, { merge: true });
    //                         props.history.push('/settings/profile?key=emailsettings');
    //                     }
    //                 });
    //                 return
    //             }
    //             else {
    //                 Swal.fire(CommonHelper.showLocale(props, 'There was an error in sending email.'), '', 'error');
    //             }
    //         }

    //         // console.log('response', resp);
    //     } catch (error) {
    //         setSendLoader(false);
    //         console.log(error);
    //     }
    // };

    // const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
    //     const { mailTo, mailCc, mailBcc, subject, email } = trigger;
    //     setSendLoader(true);

    //     if (mailTo === '' && mailCc === '' && mailBcc === '') {
    //         toast.notify('Please specify at least one recipient.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (subject === '') {
    //         toast.notify('Please write subject.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (email === '' || email === '<p></p>\n') {
    //         toast.notify('Please write in email body.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     try {
    //         let emailObj = {
    //             token: props.dealersettings.nylasAccessToken,
    //             draft: {
    //                 subject: trigger.subject,
    //                 body: trigger.email,
    //             },
    //         };

    //         if (trigger.mailTo !== '') {
    //             emailObj.draft.to = trigger.mailTo.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailCc !== '') {
    //             emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailBcc !== '') {
    //             emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.files.length > 0) {
    //             emailObj.files = trigger.files;
    //         }

    //         setTimeout(() => {
    //             toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> sending...</div>),
    //                 {
    //                     position: 'bottom-left',
    //                     duration: null
    //                 },
    //             )
    //             setShowComposeModal({
    //                 ...showComposeModal,
    //                 show: false,
    //                 type: '',
    //                 title: '',
    //                 emailData: null,
    //             });
    //             props.handleClose()
    //         }, 300);

    //         // console.log('emailObj', emailObj);
    //         const nylasSendEmail = window.firebase
    //             .functions()
    //             .httpsCallable('nylas-sendEmail');
    //         const resp = await nylasSendEmail(emailObj);

    //         if (resp.data.success) {
    //             setSendLoader(false);
    //             toast.closeAll();
    //             toast.notify('Email sent successfully.', {
    //                 duration: 2000,
    //             });
    //         } else {
    //             toast.closeAll()
    //             setSendLoader(false);
    //             if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
    //                 Swal.fire({
    //                     title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
    //                     icon: 'info',
    //                     showCancelButton: true,
    //                     confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
    //                     cancelButtonText: CommonHelper.showLocale(props, 'Later'),
    //                 }).then(result => {
    //                     if (result.value) {
    //                         window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
    //                             nylas: null,
    //                         }, { merge: true });
    //                         props.history.push('/settings/profile?key=emailsettings');
    //                     }
    //                 });
    //                 return
    //             }
    //             else {
    //                 Swal.fire(CommonHelper.showLocale(props, 'There was an error in sending email.'), '', 'error');
    //             }
    //         }

    //         // console.log('response', resp);
    //     } catch (error) {
    //         toast.closeAll()
    //         Swal.fire(CommonHelper.showLocale(props, 'There was an error in sending email.'), '', 'error');

    //         setSendLoader(false);
    //         console.log(error);
    //     }
    // };


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-email-preview"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid" style={{ minHeight: `500px` }}>
                        <div className="row">
                            <EmailListBody
                                selectedThread={selectedThread}
                                token={selectedThread.token}
                                handleOpenComposeModal={handleOpenComposeModal}
                                updateThread={updateThread}
                                threadsLoader={false}
                                type={CommonHelper.getNylasFolder(props.dealersettings?.nylasAccountData?.folders, 'Inbox', 'id')}
                                disableLinkedinfo={true}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showComposeModal.show}>
                <ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    token={props.dealersettings.nylasAccessToken}
                    type={showComposeModal.type}
                    title={showComposeModal.title}
                    emailData={showComposeModal.emailData}
                    dealersettings={props.dealersettings}
                    sendLoader={sendLoader}
                    setSendLoader={setSendLoader}
                    //handleReply={handleReply}
                    //handleSend={handleSend}
                    //structureType={!_.isEmpty(props.dealersettings.nylasAccountInfo) ? props.dealersettings.nylasAccountInfo.organization_unit + 's' : ''}
                    emailtemplates={emailtemplates}
                    contact={props.contact}
                    enquiry={props.enquiry}
                    tradeinPro={props.tradeinPro}
                ></ComposeEmail>
            </PopUpModal>
        </>
    );
}

export default EmailPreview;