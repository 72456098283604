import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import Swal from "sweetalert2";
import moment from "moment";
import toast from "toasted-notes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ContentOverlay,
  ReactSelect,
  PopUpModal,
} from "../../../../components";
import CommonHelper from "../../../../services/common";
import Translate from "../../../../constants/translate";
import images from "../../../../images";
import RequestForm from "../request";
import Options from "../options";

import { default as _images } from "../../../../images";
import { TemplateContext } from "../templateContext";
import NumberFormat from "react-number-format";

const levelOptions = [
  { value: "oem", label: "OEM", index: 1 },
  { value: "region", label: "Region", index: 2 },
  { value: "group", label: "Group", index: 3 },
  { value: "individual", label: "Individual", index: 4 },
];

const ModuleAddOns = (props) => {
  const [loader, setLoader] = useState(true);
  const [modules, setModules] = useState({});
  const [btnLoader, setBtnLoader] = useState({});
  const [modalRequestShow, setRequestShow] = useState(false);
  const [reqForm, setReqForm] = useState({});
  const [moduleToChange, setModuleToChange] = useState({
    module: "",
    checked: null,
  });
  const [modalOptionsShow, setOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const [options, setOptions] = useState({
    origin: "",
    enqType: "",
  });
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [currentClient, setCurrentClient] = useState({})
  const [ref, setRef] = useState(null);

  const [showReadMoreModal, setShowReadMoreModal] = useState({
    show: false,
    title: "",
    image: "",
    description: "",
  });

  const { clients, groups, regions } = useContext(TemplateContext);

  useEffect(() => {
    if (localStorage.defaultModule === "oem")
      setSelectedLevel({
        level: props.dealersettings.level,
        levelID:
          props.dealersettings.level === "oem"
            ? props.dealersettings.oemID
            : props.dealersettings.level === "region"
              ? props.dealersettings.regionID
              : props.dealersettings.level === "group"
                ? props.dealersettings.groupID
                : props.dealersettings.client.id,
      });
    else
      setSelectedLevel({
        level: "individual",
        levelID: props.dealersettings.client.id,
      });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _autoGrab = params.get('autograb');
    if (Boolean(_autoGrab)) {
      handleEditOptionsOpen("autograb", true, "Rego Search");
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(clients)) {
      let _clients = [];
      _.filter(
        clients,
        (v) =>
          _.indexOf(
            props.dealersettings.clients.map((v) => _.pick(v, ["id"]).id),
            v.documentID
          ) >= 0
      ).forEach((_client) => {
        _clients.push({
          label: _client.name,
          value: _client.documentID,
          data: _client,
        });
      });
      setClientOptions(_clients);
    } else {
      setClientOptions([{ name: "oem" }]);
    }
  }, [clients]);

  useEffect(() => {
    if (_.isEmpty(clientOptions)) return;

    setRegionOptions(
      _.filter(
        regions,
        (v) =>
          _.indexOf(
            _.uniq(
              _.map(
                clientOptions.map((rec) => {
                  return rec.data;
                }),
                "region"
              )
            ),
            v.code
          ) >= 0
      ).map((rec) => {
        return {
          value: rec.code,
          label: rec.name,
          data: rec,
          active: _.isBoolean(rec.active) ? rec.active : true,
        };
      })
    );
    setGroupOptions(
      _.filter(
        groups,
        (v) =>
          _.indexOf(
            _.uniq(
              _.map(
                clientOptions.map((rec) => {
                  return rec.data;
                }),
                "group"
              )
            ),
            v.documentID
          ) >= 0
      ).map((rec) => {
        return {
          value: rec.documentID,
          label: rec.name,
          data: rec,
          active: _.isBoolean(rec.active) ? rec.active : true,
          regionID: _.find(clients, { group: rec.documentID })
            ? _.find(clients, { group: rec.documentID }).region
            : "",
        };
      })
    );
  }, [clientOptions, groups, regions, clients]);

  useEffect(() => {
    if (
      !(
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID))
      )
    )
      return;

    setLoader(true);
    setModules({});

    let refModuleSettings;
    if (selectedLevel.level === "oem") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID);
    } else if (selectedLevel.level === "region") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "group") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "individual") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID);
    }

    const snapModuleSettings = refModuleSettings.onSnapshot((querySnapshot) => {
      if (querySnapshot.exists) {
        setCurrentClient(querySnapshot.data())
        setModules(
          querySnapshot.data().moduleSettings
            ? querySnapshot.data().moduleSettings
            : {}
        );
      } else {
        setCurrentClient(props.dealersettings.client)
        setModules({});
      }
      setLoader(false);
    });
    return () => {
      snapModuleSettings && snapModuleSettings();
    };
  }, [selectedLevel, props.dealersettings.client.settingsID]);

  const enableAddons = (module, checked) => {
    if (!checked) {
      Swal.fire({
        title: CommonHelper.showLocale(props, "Are you sure?"),
        text: CommonHelper.showLocale(props, "Do you want to disable addon."),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(props, "Yes"),
        cancelButtonText: CommonHelper.showLocale(props, "No"),
      }).then((result) => {
        if (result.value) {
          updateAddons(module, checked);
        }
      });
    } else {
      updateAddons(module, checked);
    }
  };
  const updateAddons = (module, checked, settings = {}) => {
    setBtnLoader({
      [module]: true,
    });

    const isLevelOEM =
      !selectedLevel || selectedLevel.level === "oem" ? true : false;

    let refModuleSettings;
    if (isLevelOEM) {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.oemID);
    } else if (selectedLevel.level === "region") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.oemID)
        .collection("regions")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "group") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(props.dealersettings.oemID)
        .collection("groups")
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "individual") {
      refModuleSettings = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID);
    }
    //console.log(settings, "settings");
    const updateModule = {
      ...modules,
      [module]: {
        ...modules[module],
        ...settings,
        active: true,
        enabled: checked,
      },
    };
    refModuleSettings
      .set(
        {
          moduleSettings: updateModule,
        },
        { merge: true }
      )
      .then((snapshot) => {
        if (selectedLevel && selectedLevel.level === "individual") {
          //update local dealer settings
          props.updateDealerSettings("moduleSettings", updateModule);

          //update settings date and priority
          window.firebase
            .firestore()
            .doc(`clients/${selectedLevel.levelID}`)
            .set(
              {
                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                settingsUpdatePriority: "critical",
                settingsUpdateText: 'Module Settings has been updated',
              },
              { merge: true }
            );
        }

        //end
        toast.notify(
          `Add-on ${checked ? "enabled" : "disabled"} successfully.`,
          {
            duration: 2000,
          }
        );
        setBtnLoader({});
      })
      .catch((error) => {
        console.log(error);
        toast.notify("Something went wrong", {
          duration: 2000,
        });
      });
  };

  const updateRequestedAddons = (module) => {
    let userID = props.dealersettings ? props.dealersettings.id : "";
    let currentDate = window.firebase.firestore.Timestamp.now();

    setBtnLoader({
      [module]: true,
    });
    window.firebase
      .firestore()
      .doc(`clients/${selectedLevel.levelID}`)
      .set(
        {
          moduleSettings: {
            ...modules,
            [module]: {
              ...modules[module],
              requestedBy: userID,
              requestedDate: currentDate,
            },
          },
        },
        { merge: true }
      )
      .then((snapshot) => {
        //update dealer settings
        props.updateDealerSettings("moduleSettings", {
          ...modules,
          [module]: {
            ...modules[module],
            requestedBy: userID,
            requestedDate: currentDate,
          },
        });

        //update settings date and priority
        window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).set(
          {
            settingsUpdate: window.firebase.firestore.Timestamp.now(),
            settingsUpdatePriority: "critical",
            settingsUpdateText: 'Module Settings has been updated',
          },
          { merge: true }
        );

        //end
        toast.notify(`Add-on requested successfully.`, {
          duration: 2000,
        });
        setBtnLoader({});
      })
      .catch((error) => {
        console.log(error);
        toast.notify("Something went wrong", {
          duration: 2000,
        });
      });
  };

  const handleFormClose = (obj) => {
    //console.log('handleFormClose', obj)
    if (!_.isEmpty(obj) && obj.module) {
      updateRequestedAddons(obj.module);
    }
    setRequestShow(false);
    setReqForm({});
  };

  const handleFormOpen = (_module, _imageurl, _title) => {
    if (selectedLevel && selectedLevel.level === "individual") {
      setRequestShow(true);
      let obj = Object.assign(
        {},
        {
          module: _module,
          image: _imageurl,
          title: _title,
        }
      );
      setReqForm(obj);
    } else {
      window.Intercom("show");
    }
  };

  const handleEditOptionsOpen = (module, checked, title) => {
    setModuleToChange({ module, checked });
    setOptionsShow({
      show: true,
      title,
      isEdit: true,
    });
  };
  const handleOptionsClose = () => {
    setOptionsShow({
      show: false,
      title: "",
      isEdit: false,
    });
  };
  return (
    <>
      <h2>
        <Translate text={"Add-ons"} />
      </h2>
      <div className="divider-line"></div>
      <div className="mt-4">
        <div className="settings-head form-style">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3 pr-0">
                  <ReactSelect
                    options={
                      props.dealersettings.level === "oem" ||
                        _.isEmpty(props.dealersettings.level) ||
                        !_.find(levelOptions, {
                          value: props.dealersettings.level,
                        })
                        ? levelOptions
                        : levelOptions.filter(
                          (e) =>
                            e.index >=
                            _.find(levelOptions, {
                              value: props.dealersettings.level,
                            }).index
                        )
                    }
                    name={"level"}
                    placeholder={"select level"}
                    onChange={(e, data) => {
                      if (e) {
                        if (e.value === "oem") setLoader(true);
                        setSelectedLevel({
                          level: e.value,
                        });
                      } else setSelectedLevel(null);
                    }}
                    value={selectedLevel ? selectedLevel.level : ""}
                    classNamePrefix={`cursor-pointer basic-select`}
                    removeClearable={true}
                  ></ReactSelect>
                </div>
                {!_.isEmpty(selectedLevel) && selectedLevel.level !== "oem" ? (
                  <div className="col-md-3 pr-0">
                    <ReactSelect
                      options={
                        selectedLevel.level === "group"
                          ? groupOptions
                          : selectedLevel.level === "region"
                            ? regionOptions
                            : clientOptions
                      }
                      name={"levelID"}
                      placeholder={`select ${selectedLevel.level === "individual"
                        ? "client"
                        : selectedLevel.level
                        }`}
                      onChange={(e, data) => {
                        if (e) {
                          setLoader(true);
                          setSelectedLevel({
                            ...selectedLevel,
                            levelID: e.value,
                          });
                        } else setSelectedLevel(null);
                      }}
                      value={selectedLevel ? selectedLevel.levelID : ""}
                      classNamePrefix={`basic-select`}
                      removeClearable={true}
                    ></ReactSelect>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <div className="mt-5">
          <ContentOverlay active={true} />
        </div>
      ) : !loader &&
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID)) ? (
        <div className="settings-integration-list">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsSales} />
                  </div>
                  <div className="integration-title">
                    <a href="#">AMS Sales</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.sales) ||
                    !modules.sales.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.sales && modules.sales.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.sales.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.sales.requestedDate
                                    ? moment
                                      .unix(
                                        modules.sales.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen("sales", images.sales, "Sales");
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.sales && modules.sales.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("sales", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["sales"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("sales", true);
                      }}
                    >
                      {btnLoader["sales"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.tradeinplus} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Trade-In Plus</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.tradeinPlus) ||
                    !modules.tradeinPlus.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.tradeinPlus &&
                        modules.tradeinPlus.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.tradeinPlus.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.tradeinPlus.requestedDate
                                    ? moment
                                      .unix(
                                        modules.tradeinPlus.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "tradeinPlus",
                                images.tradeinplus,
                                "Trade-In Plus"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.tradeinPlus &&
                    modules.tradeinPlus.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("tradeinPlus", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["tradeinPlus"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("tradeinPlus", true);
                      }}
                    >
                      {btnLoader["tradeinPlus"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsvideo} />
                  </div>
                  <div className="integration-title">
                    <a href="#">AMS Video</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.amsVideo) ||
                    !modules.amsVideo.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.amsVideo && modules.amsVideo.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.amsVideo.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.amsVideo.requestedDate
                                    ? moment
                                      .unix(
                                        modules.amsVideo.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "amsVideo",
                                images.amsvideo,
                                "AMS Video"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.amsVideo &&
                    modules.amsVideo.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("amsVideo", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["amsVideo"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("amsVideo", true);
                      }}
                    >
                      {btnLoader["amsVideo"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsfinance} />
                  </div>
                  <div className="integration-title">
                    <a href="#">AMS Finance</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.finance) ||
                    !modules.finance.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.finance && modules.finance.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.finance.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.finance.requestedDate
                                    ? moment
                                      .unix(
                                        modules.finance.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "finance",
                                images.amsfinance,
                                "AMS Finance"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.finance && modules.finance.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("finance", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["finance"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("finance", true);
                      }}
                    >
                      {btnLoader["finance"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              {/* <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.regoscan} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Reg Scan</a>
                  </div>
                   {(_.isEmpty(modules) ||
                    _.isEmpty(modules.regoScan) ||
                    !modules.regoScan.active) &&
                  localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.regoScan && modules.regoScan.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.regoScan.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.regoScan.requestedDate
                                    ? moment
                                        .unix(
                                          modules.regoScan.requestedDate.seconds
                                        )
                                        .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "regoScan",
                                images.regoscan,
                                "Reg Scan"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.regoScan &&
                    modules.regoScan.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("regoScan", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["regoScan"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("regoScan", true);
                      }}
                    >
                      {btnLoader["regoScan"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div> */}
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.rego_search} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Rego Search</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.autograb) ||
                    !modules.autograb.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.autograb && modules.autograb.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.autograb.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.autograb.requestedDate
                                    ? moment
                                      .unix(
                                        modules.autograb.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "autograb",
                                images.rego_search,
                                "Rego Search"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.autograb &&
                    modules.autograb.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("autograb", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["autograb"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                      <button
                        type="button"
                        className="btn btn-default ml-2 custom-settings-ico"
                        onClick={() => {
                          handleEditOptionsOpen("autograb", true, "Rego Search");
                        }}
                      >
                        <i className="ico icon-settings"></i>
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("autograb", true);
                      }}
                    >
                      {btnLoader["autograb"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.ppsr} />
                  </div>
                  <div className="integration-title">
                    <a href="#">PPSR</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.ppsr) ||
                    !modules.ppsr.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.ppsr && modules.ppsr.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.ppsr.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.ppsr.requestedDate
                                    ? moment
                                      .unix(
                                        modules.ppsr.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "ppsr",
                                images.rego_search,
                                "PPSR"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.ppsr &&
                    modules.ppsr.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("ppsr", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["ppsr"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                      <button
                        type="button"
                        className="btn btn-default ml-2 custom-settings-ico"
                        onClick={() => {
                          handleEditOptionsOpen("ppsr", true, "PPSR");
                        }}
                      >
                        <i className="ico icon-settings"></i>
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("ppsr", true);
                      }}
                    >
                      {btnLoader["ppsr"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.licensescan} />
                  </div>
                  <div className="integration-title">
                    <a href="#">License Scan</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.licenseScan) ||
                    !modules.licenseScan.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.licenseScan &&
                        modules.licenseScan.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.licenseScan.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.licenseScan.requestedDate
                                    ? moment
                                      .unix(
                                        modules.licenseScan.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "licenseScan",
                                images.licensescan,
                                "License Scan"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.licenseScan &&
                    modules.licenseScan.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("licenseScan", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["licenseScan"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("licenseScan", true);
                      }}
                    >
                      {btnLoader["licenseScan"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsfrontdesk} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Front Desk</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.frontdesk) ||
                    !modules.frontdesk.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.frontdesk && modules.frontdesk.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.frontdesk.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.frontdesk.requestedDate
                                    ? moment
                                      .unix(
                                        modules.frontdesk.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "frontdesk",
                                images.amsfrontdesk,
                                "Front Desk"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.frontdesk &&
                    modules.frontdesk.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("frontdesk", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["frontdesk"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("frontdesk", true);
                      }}
                    >
                      {btnLoader["frontdesk"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsService} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Service</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.service) ||
                    !modules.service.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.service && modules.service.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.service.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.service.requestedDate
                                    ? moment
                                      .unix(
                                        modules.service.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "service",
                                images.amsService,
                                "service"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.service && modules.service.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("service", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["service"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("service", true);
                      }}
                    >
                      {btnLoader["service"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.tradeinPro} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Trade-In Pro</a>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.tradeinPro) ||
                    !modules.tradeinPro.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.tradeinPro && modules.tradeinPro.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.tradeinPro.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.tradeinPro.requestedDate
                                    ? moment
                                      .unix(
                                        modules.tradeinPro.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "tradeinPro",
                                images.tradeinPro,
                                "Trade-In Pro"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.tradeinPro &&
                    modules.tradeinPro.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("tradeinPro", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["tradeinPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("tradeinPro", true);
                      }}
                    >
                      {btnLoader["tradeinPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.eventsPro} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Events Pro</a>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.eventsPro) ||
                    !modules.eventsPro.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.eventsPro && modules.eventsPro.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.eventsPro.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.eventsPro.requestedDate
                                    ? moment
                                      .unix(
                                        modules.eventsPro.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "eventsPro",
                                images.eventsPro,
                                "Events Pro"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.eventsPro &&
                    modules.eventsPro.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("eventsPro", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["eventsPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("eventsPro", true);
                      }}
                    >
                      {btnLoader["eventsPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.cafe} />
                  </div>
                  <div className="integration-title">
                    <a href="#">AMS Cafe</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.cafe) ||
                    !modules.cafe.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.cafe && modules.cafe.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.cafe.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.cafe.requestedDate
                                    ? moment
                                      .unix(
                                        modules.cafe.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen("cafe", images.cafe, "cafe");
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.cafe && modules.cafe.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("cafe", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["cafe"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("cafe", true);
                      }}
                    >
                      {btnLoader["cafe"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.fusionevents} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Fusion Events</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.fusionevents) ||
                    !modules.fusionevents.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.fusionevents &&
                        modules.fusionevents.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.fusionevents.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.fusionevents.requestedDate
                                    ? moment
                                      .unix(
                                        modules.fusionevents.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "fusionevents",
                                images.fusionevents,
                                "fusionevents"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.fusionevents &&
                    modules.fusionevents.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("fusionevents", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["fusionevents"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("fusionevents", true);
                      }}
                    >
                      {btnLoader["fusionevents"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.tvModule} />
                  </div>
                  <div className="integration-title">
                    <a href="#">TV Display</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.tvModule) ||
                    !modules.tvModule.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.tvModule && modules.tvModule.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.tvModule.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.tvModule.requestedDate
                                    ? moment
                                      .unix(
                                        modules.tvModule.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "tvModule",
                                images.tvModule,
                                "TV Display"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.tvModule &&
                    modules.tvModule.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("tvModule", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["tvModule"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("tvModule", true);
                      }}
                    >
                      {btnLoader["tvModule"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.performanceReview} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Daily Performance Review</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.performanceReview) ||
                    !modules.performanceReview.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.performanceReview &&
                        modules.performanceReview.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.performanceReview.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.performanceReview.requestedDate
                                    ? moment
                                      .unix(
                                        modules.performanceReview
                                          .requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "performanceReview",
                                images.performanceReview,
                                "Daily Performance Review"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.performanceReview &&
                    modules.performanceReview.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("performanceReview", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["performanceReview"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("performanceReview", true);
                      }}
                    >
                      {btnLoader["performanceReview"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.roster} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Roster</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.roster) ||
                    !modules.roster.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.roster && modules.roster.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.roster.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.roster.requestedDate
                                    ? moment
                                      .unix(
                                        modules.roster.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen("roster", images.roster, "Roster");
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.roster && modules.roster.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("roster", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["roster"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("roster", true);
                      }}
                    >
                      {btnLoader["roster"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.invoice} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Service Invoice</a>
                  </div>
                  {/* <p className="integration-content"> Suspendisse consectetur neque vitae mi laoreet, nec laoreet mauris bibendum. Cras et nisl diam. </p> */}
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.serviceInvoice) ||
                    !modules.serviceInvoice.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.serviceInvoice &&
                        modules.serviceInvoice.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.serviceInvoice.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.serviceInvoice.requestedDate
                                    ? moment
                                      .unix(
                                        modules.serviceInvoice.requestedDate
                                          .seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "serviceInvoice",
                                images.invoice,
                                "Service Invoice"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.serviceInvoice &&
                    modules.serviceInvoice.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("serviceInvoice", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["serviceInvoice"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("serviceInvoice", true);
                      }}
                    >
                      {btnLoader["serviceInvoice"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.amsoem} />
                  </div>
                  <div className="integration-title">
                    <a href="#">OEM</a>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.oem) ||
                    !modules.oem.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.oem && modules.oem.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.oem.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.oem.requestedDate
                                    ? moment
                                      .unix(modules.oem.requestedDate.seconds)
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen("oem", images.amsoem, "OEM");
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules && modules.oem && modules.oem.enabled ? (
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        enableAddons("amsOEM", false);
                      }}
                    >
                      {" "}
                      <i className="ico icon-check"></i>
                      {btnLoader["oem"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enabled{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("oem", true);
                      }}
                    >
                      {btnLoader["oem"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.emailCampaign} />
                  </div>
                  {
                    modules?.mailjet?.active && modules?.mailjet?.enabled ? (
                      <div className="alert alert-integration-green">
                        <b>{modules?.mailjet?.currency || ''} $ {modules?.mailjet?.price || 0}/month </b>&nbsp;-&nbsp;{<NumberFormat displayType={'text'} thousandSeparator={true} value={modules?.mailjet?.limit || null} />}&nbsp;emails
                      </div>
                    ) : (
                      <div className="integration-title">
                        <a href="#">Email Campaign</a>
                      </div>
                    )
                  }
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.mailjet) ||
                    !modules.mailjet.active) ? (
                    <>
                      {modules.mailjet && modules.mailjet.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.mailjet.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.mailjet.requestedDate
                                    ? moment
                                      .unix(
                                        modules.mailjet.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "mailjet",
                                images.mailjet,
                                "Email Campaign"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.mailjet &&
                    modules.mailjet.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("mailjet", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["mailjet"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                      {
                        Boolean(props.dealersettings.superAdmin) ? (
                          <button
                            type="button"
                            className="btn btn-default ml-2 custom-settings-ico"
                            onClick={() => {
                              handleEditOptionsOpen("mailjet", true, "Email Campaign");
                            }}
                          >
                            <i className="ico icon-settings"></i>
                          </button>
                        ) : (<></>)
                      }
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleEditOptionsOpen("mailjet", true, "Email Campaign");
                      }}
                      disabled={!Boolean(props.dealersettings.superAdmin)}
                    >
                      {btnLoader["mailjet"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.fleetPro} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Fleet Management</a>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.fleetPro) ||
                    !modules.fleetPro.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.fleetPro && modules.fleetPro.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.fleetPro.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.fleetPro.requestedDate
                                    ? moment
                                      .unix(
                                        modules.fleetPro.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "fleetPro",
                                images.fleetPro,
                                "Fleet Management"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.fleetPro &&
                    modules.fleetPro.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("fleetPro", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["fleetPro"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("fleetPro", true);
                      }}
                    >
                      {btnLoader["fleetPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.serviceFleetPro} />
                  </div>
                  <div className="integration-title">
                    <a href="#">Service Fleet Pro</a>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.serviceFleetPro) ||
                    !modules.serviceFleetPro.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.serviceFleetPro && modules.serviceFleetPro.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.serviceFleetPro.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.serviceFleetPro.requestedDate
                                    ? moment
                                      .unix(
                                        modules.serviceFleetPro.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "serviceFleetPro",
                                images.serviceFleetPro,
                                "Fleet Management"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.serviceFleetPro &&
                    modules.serviceFleetPro.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("serviceFleetPro", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["serviceFleetPro"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("serviceFleetPro", true);
                      }}
                    >
                      {btnLoader["serviceFleetPro"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 margin-btm-30">
                <div className="integration-box">
                  <div className="integration-icon">
                    <img alt="" src={images.deallog} />
                  </div>
                  <div className="integration-title">
                    <a href="#">AMS Sales Hub</a>
                    <div className="badge badge-beta ml-2" style={{ lineHeight: '8px' }}>Beta</div>
                  </div>
                  {(_.isEmpty(modules) ||
                    _.isEmpty(modules.dealLog) ||
                    !modules.dealLog.active) &&
                    localStorage.defaultModule !== "oem" ? (
                    <>
                      {modules.dealLog && modules.dealLog.requestedBy ? (
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                <>
                                  <span>Requested by:</span>{" "}
                                  {CommonHelper.getUserNamebyId(
                                    props.clientUsers,
                                    modules.dealLog.requestedBy
                                  )}
                                </>
                                <br></br>
                                <>
                                  <span>Requested on:</span>{" "}
                                  {modules.dealLog.requestedDate
                                    ? moment
                                      .unix(
                                        modules.dealLog.requestedDate.seconds
                                      )
                                      .format("DD MMM YYYY h:mm A")
                                    : "--"}
                                </>
                              </Tooltip>
                            }
                          >
                            <button type="button" className="btn btn-orange">
                              Requested{" "}
                            </button>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormOpen(
                                "dealLog",
                                images.deallog,
                                "AMS Sales Hub"
                              );
                            }}
                          >
                            Contact Us
                          </button>
                        </>
                      )}
                    </>
                  ) : modules &&
                    modules.dealLog &&
                    modules.dealLog.enabled ? (
                    <div className="d-inline-block">
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={() => {
                          enableAddons("dealLog", false);
                        }}
                      >
                        {" "}
                        <i className="ico icon-check"></i>
                        {btnLoader["dealLog"] ? (
                          <span
                            className="spinner-border spinner-button mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <></>
                        )}
                        Enabled{" "}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        enableAddons("dealLog", true);
                      }}
                    >
                      {btnLoader["dealLog"] ? (
                        <span
                          className="spinner-border spinner-button mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <></>
                      )}
                      Enable{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
        <div style={{ height: "45vh" }}>
          <div className="spinner-loader h-100">
            <div
              className="d-flex h-100 justify-content-center align-items-center loader-primary"
              style={{ flexDirection: "column" }}
            >
              <div className="no-data-img">
                {" "}
                <img src={_images.nodata} width="60" height="60" alt="" />
              </div>
              <div className="no-data-txt mt-2">
                <p>{`Please select ${selectedLevel
                  ? selectedLevel.level === "individual"
                    ? "client"
                    : selectedLevel.level
                  : ""
                  }`}</p>
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}

      <PopUpModal show={modalRequestShow}>
        <RequestForm
          show={modalRequestShow}
          handleClose={handleFormClose}
          title={
            reqForm && reqForm.title
              ? "Request - " + reqForm.title
              : "Contact Us"
          }
          requests={reqForm}
          clients={clients}
          selectedClient={
            selectedLevel &&
              selectedLevel.levelID &&
              selectedLevel.level === "individual"
              ? selectedLevel.levelID
              : ""
          }
          dealersettings={props.dealersettings}
          clientUsers={props.clientUsers}
          groupUsers={props.groupUsers}
        ></RequestForm>
      </PopUpModal>
      <PopUpModal show={modalOptionsShow.show}>
        <Options
          clients={clients}
          selectedClient={
            selectedLevel &&
              selectedLevel.levelID &&
              selectedLevel.level === "individual"
              ? selectedLevel.levelID
              : ""
          }
          clientUsers={props.clientUsers}
          groupUsers={props.groupUsers}
          show={modalOptionsShow.show}
          handleClose={handleOptionsClose}
          enableAddons={updateAddons}
          title={modalOptionsShow.title}
          isEdit={modalOptionsShow.isEdit}
          setOptions={setOptions}
          options={options}
          moduleToChange={moduleToChange}
          selectedLevel={selectedLevel}
          dealersettings={props.dealersettings}
          currentClient={currentClient}
          {...props}
        ></Options>
      </PopUpModal>
    </>
  );
};

export default ModuleAddOns;
