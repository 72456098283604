/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import toast from 'toasted-notes';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import { Tooltip } from 'antd';
/** COMPONENTS */
import { WidgetOverlay, PpsrScan, ImageViewer, PopUpModal } from '../../components'
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import TradeInFields from '../tradeIn/tradeInFields'
import AutoGrabInfo from '../tradeIn/autograb/info'

import CommonHelper from '../../services/common'
import { tipStatus, tipOfferStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import enquiryHelper from '../../services/enquiryHelper';

const MySwal = withReactContent(Swal);
const TradeInInfo = ({ tradein, handleEditTradeIn, isDeivered, handleAutoGrap,
    dealersettings, handleReSendLink, isTradein, groupUsers, clientUsers, loadScreen,
    hideMore, isStatusChange, handleStatusChange, errors, showTag, isReopen }) => {
    const [editshow, setEditshow] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isIntakeLoading, setIntakeLoading] = useState(false)
    const [showPpsrScan, setShowPpsrScan] = useState(false)
    const [showTradein, setShowTradein] = useState(false)
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [ppsrCertURL, setPPSRCertURL] = useState(null)

    const node = useRef();
    // const {
    //     documentID, evalexpertID, enquiryID,
    //     make, model, regNo, status, offerPrice,
    //     year, exteriorColor, images, sent, tags, linkStatus, linkURL
    // } = tradein;
    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    let _allImages = CommonHelper.bindAllImages(tradein?.images, tradein?.sidePhotos)
    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

    const accessToShowTradeInPrice = ((!_.isEmpty(_permissions) && _permissions.accessToShowTradeInPrice) ? true : false);
    const accessToShowIntakeAgreement = ((!_.isEmpty(_permissions) && _permissions.accessToShowIntakeAgreement) ? true : false);
    const lockFinalPrice = ((!_.isEmpty(_permissions) && _permissions.lockFinalPrice) ? true : false);

    const isLinkSent = tradein.linkStatus === 'sent' ? true : false;

    const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
    let _objCurrentStatus = tradein.tradeinProID && tradein.status && tradeinProAllStatus.filter(e => e.value === tradein.status)[0]

    const ppsrEnabled = (!_.isEmpty(dealersettings?.client?.moduleSettings?.ppsr) &&
        dealersettings.client.moduleSettings.ppsr.enabled &&
        dealersettings.client.moduleSettings.ppsr.active) ? true : false;

    const _autograbIntegrations = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
    const autoGrabEnabled = _autograbIntegrations?.active && _autograbIntegrations?.enabled ? true : ((!_.isEmpty(dealersettings?.client?.moduleSettings?.autograb) &&
        dealersettings.client.moduleSettings.autograb.enabled &&
        dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : false);
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(tradein?.status) ? true : false;
    const _isTradeinDone = [tipStatus.WON].includes(tradein?.status) ? true : false;


    function fixBootstrapModal() {
        var modalNode = document.querySelector('.modal[tabindex="-1"]');
        if (!modalNode) return;

        modalNode.removeAttribute('tabindex');
        modalNode.classList.add('js-swal-fixed');
    }

    // call this before hiding SweetAlert (inside done callback):
    function restoreBootstrapModal() {
        var modalNode = document.querySelector('.modal.js-swal-fixed');
        if (!modalNode) return;

        modalNode.setAttribute('tabindex', '-1');
        modalNode.classList.remove('js-swal-fixed');
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    // const deleteTradeIns = (fileID) => {

    //     Swal.fire({
    //         title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
    //         text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete the trade-in?'),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
    //         cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
    //     }).then((result) => {
    //         if (result.value) {

    //             let modifiedBy = dealersettings ? dealersettings.id : '';
    //             let modifiedDate = window.firebase.firestore.Timestamp.now();

    //             const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(fileID)
    //             updateRef.set({
    //                 isDeleted: true,
    //                 modifiedBy,
    //                 modifiedDate,
    //                 modifiedFrom: 'web'
    //             }, { merge: true })
    //                 .then((docRef) => {
    //                     toast.notify('Trade-In successfully deleted.', {
    //                         duration: 2000
    //                     })

    //                     let logNotes = 'Trade-In deleted';

    //                     let _objLogData = {
    //                         notes: logNotes,
    //                         type: 'tradeins',
    //                         subType: 'delete',
    //                         recordId: fileID,
    //                         enquiryID: tradein.enquiryID ? tradein.enquiryID : null,
    //                     }
    //                     _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
    //                     CommonHelper.saveAuditLog(_objLogData);
    //                     CommonHelper.saveFileChangeLog(_objLogData, 'updated');

    //                 }).catch((error) => {
    //                     console.error("Error updating contact_details: ", error);
    //                 });

    //         }
    //     })

    // }

    //#region handleStatusSave
    const handleStatusSave = (tradeinProID, tradeinID, _status, _isReopen) => {
        const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
        let __status = CommonHelper.getNameByValue(tradeinProAllStatus, _status)
        let _text = _isReopen ? 'You want to re-open appraisal.' : `${CommonHelper.showLocale({ dealersettings }, 'You want to change appraisal status to')} ${__status.toUpperCase()}`
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: _text,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then(result => {
            if (result.value) {
                saveTradeinProField(tradeinProID, tradeinID, _status, _isReopen);
            }

        });
    }

    const saveTradeinProField = async (tradeinproid, tradeinid, val, _isReopen) => {
        setLoading(true);
        let __promise = []
        __promise.push(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`).get());
        __promise.push(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinproid}/offers`).get());
        const __snapsnot = await Promise.all(__promise);
        let _tradePro = null
        let _tradeproOffers = [];
        __snapsnot.forEach((snap) => {
            if (snap.exists) {
                _tradePro = snap.data();
            }
            else {
                snap.docs && snap.docs.forEach(_offer => {
                    if (_offer.exists)
                        _tradeproOffers.push(_offer.data())
                })
            }
        })

        //console.log('_tradePro', _tradePro)
        //console.log('_tradeproOffers', _tradeproOffers)

        let _clientID = _tradePro?.clientID ? _tradePro.clientID : null
        let _client = _.find(dealersettings?.clients, { id: _clientID })
        let managerWorkflow = _client?.clientSettings?.managerWorkflow ? true : false;

        let _finalPrice = null;
        if (!_isReopen && managerWorkflow && !_tradePro.finalPrice) {
            //console.log('begin')
            fixBootstrapModal();
            // let result = await Swal.fire({
            //     title: 'Enter Final Price',
            //     text: _tradePro.valuerPrice ? `The valuer price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, _tradePro.valuerPrice)}` : '',
            //     input: 'number',
            //     inputPlaceholder: 'Enter final price',
            //     showCancelButton: true,
            //     confirmButtonText: 'Submit',
            //     showLoaderOnConfirm: true,
            //     allowOutsideClick: false,
            //     allowEscapeKey: false,
            //     preConfirm: (inputValue) => {
            //         if (inputValue) {
            //             // Process the input value here
            //             return inputValue;
            //         }
            //         else {
            //             Swal.showValidationMessage('Final price cannot be empty');
            //         }
            //     },
            // })
            let result = await MySwal.fire({
                title: 'Enter Final Price',
                html: (
                    <>
                        <label>{_tradePro.valuerPrice ? `The valuer price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, _tradePro.valuerPrice)}` : ''}</label>
                        <NumberFormat
                            id={'currency-input'}
                            decimalScale={2}
                            thousandSeparator={true}
                            allowNegative={true}
                            prefix={currencySymbol}
                            placeholder={currencySymbol}
                            className={`form-control`}
                            onValueChange={(e, name) => { _finalPrice = e.floatValue }}
                        />
                    </>
                ),
                showCancelButton: true,
                confirmButtonText: 'Submit',
                preConfirm: () => {
                    const inputValue = document.getElementById('currency-input').value;
                    return inputValue ? inputValue : MySwal.showValidationMessage('Final price cannot be empty!');
                }
            })

            //console.log('end', result, Number(result.value.replace(/[^0-9.]/g, '')))
            if (result.isConfirmed && result.value)
                _finalPrice = Number(result.value.replace(/[^0-9.]/g, '')) || _finalPrice
            else {
                _finalPrice = null;
                setLoading(false);
                return;
            }
            restoreBootstrapModal();
        }
        //console.log('Continue', _finalPrice)

        let _msg = ''
        let objData = {};
        if (_finalPrice) {
            objData.finalPrice = _finalPrice;
            objData.finalizedBy = dealersettings ? dealersettings.id : '';
            objData.finalizedDate = window.firebase.firestore.Timestamp.now();
            objData.finalizedFrom = 'web';
            if (!_.isEmpty(_tradePro.tradein))
                objData.tradein = {
                    ..._tradePro.tradein,
                    'offerPrice': _finalPrice
                }
            _msg = `The evaluation has been approved, setting the final price at ${CommonHelper.formatCurrency(currencySymbol, _finalPrice)}`
        }
        if (val === tipStatus.WON) {
            objData.wonDate = window.firebase.firestore.Timestamp.now();
            objData.lostDate = null;
            objData.lostReason = null;
            objData.lostSubReason = null;
        }
        else if (val === tipStatus.LOST) {
            objData.lostDate = window.firebase.firestore.Timestamp.now();
            objData.wonDate = null;
        }
        else if (_isReopen) {
            objData.wonDate = null;
            objData.lostDate = null;
            objData.lostReason = null;
            objData.lostSubReason = null;

            if (!_.isEmpty(_tradePro.tradein))
                objData.tradein = {
                    ..._tradePro.tradein,
                    ['status']: val
                }

            _msg = `The appraisal has been reopened from the enquiry.`
        }
        objData = handleMoveStageForStatusChange(val, objData, _tradePro);

        objData.status = val;
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        const batch = firestoreDB(dealersettings).firestore().batch();

        batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinproid}`), objData, { merge: true });
        saveTradeinFieldStatus(val, _finalPrice, tradeinid, _tradePro, _isReopen, batch)
        saveOfferStatus(val, _tradePro, _tradeproOffers, _isReopen, batch);

        batch.commit().then(snapshot => {
            toast.notify('Appraisal updated successfully.', { duration: 2000 });
            if (_msg) {
                let _objLogData = {
                    tradeinProID: tradeinproid,
                    offerID: _tradePro.offer?.documentID ? _tradePro?.offer?.documentID : null,
                    projectId: _tradePro?.projectId ? _tradePro?.projectId : null,
                    message: _msg
                }
                CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
            }

            setLoading(false);
            if (handleStatusChange) handleStatusChange(tradeinid, val, _finalPrice)
        }).catch((error) => {
            setLoading(false);
            console.error("Error updating document: ", error);
        });

    };

    const handleMoveStageForStatusChange = (_status, state, fields) => {
        let setttings = dealersettings.client?.tradeinPro;
        const { stageHistory } = fields;

        let _selPipeline = fields.pipeline && !_.isEmpty(setttings) && setttings.allPipelines.filter(e => e.value === fields.pipeline)[0];
        let statusSettings = _selPipeline && _selPipeline.statusSettings;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    const saveTradeinFieldStatus = (_status, _finalPrice, _tradeinID, fields, _isReopen, batch) => {
        let objData = {};
        objData.status = _status;
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (_.isNumber(_finalPrice)) objData.offerPrice = _finalPrice;
        else {
            if (dealersettings?.client?.clientSettings?.managerWorkflow) {
                objData.valuerPrice = fields.valuerPrice ? fields.valuerPrice : 0;
                objData.offerPrice = fields.finalPrice ? fields.finalPrice : 0;
            }
            else if (fields?.offer?.dealerPrice) objData.offerPrice = fields.offer.dealerPrice;
        }

        if (_tradeinID)
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${_tradeinID}`), objData, { merge: true });
    }

    const saveOfferStatus = (_status, fields, tipOffers, _isReopen, batch) => {
        !_.isEmpty(tipOffers) && tipOffers.forEach(newVM => {
            newVM.modifiedBy = localStorage.uid;
            newVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newVM.modifiedFrom = 'web';

            if (_isReopen) {
                if (newVM.evaluationPrice) newVM.status = tipOfferStatus.COMPLETED; else newVM.status = tipOfferStatus.PENDING;
            }
            else if (_status === tipStatus.WON || _status === tipStatus.LOST) {
                if (!_.isEmpty(fields.offer) && fields.offer.documentID === newVM.documentID && _status === tipStatus.WON)
                    newVM.status = tipOfferStatus.WON;
                else
                    newVM.status = tipOfferStatus.LOST;
            }

            if (newVM.tradeinProID && newVM.documentID)
                batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${newVM.tradeinProID}/offers/${newVM.documentID}`), newVM, { merge: true });
        })

    }
    //#endregion

    const handleModalClose = (_data) => {
        if (!_.isEmpty(_data)) handleAutoGrap(tradein?.documentID, { ...tradein, ..._data });
        setShowTradein(false);
    }

    const handleIntakeAgreement = async () => {
        if (ppsrEnabled) {
            setIntakeLoading(true);
            if (loadScreen) loadScreen(true);

            let response = await enquiryHelper.getIntakeURL(tradein, dealersettings, ppsrCertURL)
            if (response?.success && response?.data) {
                window.open(response.data, '_blank');
                loaderOff();
                //toast.closeAll();
            }
            else if (response?.success === false && response?.type === 'info') {
                Swal.fire((response?.title || ''), (response?.message || ''), 'info');
                loaderOff();
                //toast.closeAll();
            }
            else {
                console.log('response', response);
                loaderOff(response?.ppsrCertURL);
            }
        }
        else {
            loaderOff(ppsrCertURL); Swal.fire('PPSR document not found.', 'Generate a PPSR document to proceed', 'info'); return;
        }
    }

    const handleIntakeAgreement_deprecated = async () => {
        setIntakeLoading(true);
        if (loadScreen) loadScreen(true);
        // toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> generating intake agreement...</div>),
        //     {
        //         position: 'bottom-left',
        //         duration: null
        //     },
        // )
        let response = await enquiryHelper.getIntakeURL(tradein, dealersettings)
        if (response?.success && response?.data) {
            window.open(response.data, '_blank');
            loaderOff();
            //toast.closeAll();
        }
        else {
            if (response?.type === 'ppsr') {
                if (!ppsrEnabled) { loaderOff(); Swal.fire('PPSR document not found.', 'Generate a PPSR document to proceed', 'info'); return; }

                Swal.fire({
                    title: 'PPSR document not found.',
                    text: 'Would you like to generate it to proceed?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(result => {
                    if (result.value) {
                        setShowPpsrScan(true);
                    }
                    else { loaderOff(); }

                });
            }
            else { loaderOff(); }
        }
    }

    const loaderOff = (_ppsrCertURL) => {
        setIntakeLoading(false);
        setPPSRCertURL(_ppsrCertURL || null);
        if (loadScreen) loadScreen(false);
    }

    return (<>
        {
            tradein.linkURL && !tradein.linkStatus && !isTradein
                ?
                <></>
                :
                <>
                    <div className={`vehicle-item  ${!_.isEmpty(errors) ? errors[tradein.documentID] : ''}`} id={'tradeins_pro_' + tradein.documentID}>
                        <div className="flex-vehicle">
                            {
                                hideMore
                                    ?
                                    <></>
                                    :
                                    <div className="quickview-activity-more">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setEditshow(!editshow)
                                            if (editshow)
                                                document.addEventListener('click', setEditshow(false));
                                        }}><i className="ico icon-more"></i></a>
                                        <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                            <ul className="more-dropdown">
                                                <li>
                                                    <a href="#" onClick={(e) => { e.preventDefault(); handleEditTradeIn(tradein.documentID, tradein.sent) }}>
                                                        {
                                                            (tradein.sent || isDeivered)
                                                                ?
                                                                <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                                                                :
                                                                <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                                                        }
                                                    </a>
                                                </li>
                                                {
                                                    isLinkSent
                                                        ?
                                                        <>
                                                            <li> <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setEditshow(false);
                                                                handleReSendLink(tradein.documentID)
                                                            }}> <i className="ico icon-email"></i><Translate text={'resend'} /> </a> </li>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {/* {
                                                    isTradein
                                                        ?
                                                        <>
                                                            
                                                        </>
                                                        :
                                                        <></>
                                                } */}


                                                {/*  */}
                                            </ul>
                                        </div>
                                    </div>
                            }

                            {
                                isLinkSent && !isTradein
                                    ?
                                    <><div className="vehicle-data" >
                                        <div className="vehicle-data pb-1" ><i className="ico icon-multi-check mr-1"></i><Translate text={'Link sent to customer/internal'} /></div>

                                        <div className="flow-activity-date ml-4">
                                            <span>{tradein.modifiedDate ? <>{moment.unix(tradein.modifiedDate.seconds).format('DD MMM YYYY h:mm A')}{' - '}</> : <></>}</span>{CommonHelper.getUserNamebyId(allUsers, tradein.modifiedBy)}
                                            {tradein.modifiedFrom ?
                                                <>
                                                    {` - `}
                                                    <i className={`ico icon-${tradein.modifiedFrom === 'web' ? 'monitor' : tradein.modifiedFrom} mr-1`}></i>
                                                    {tradein.modifiedFrom} </> : <></>}
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                        <div className="vehicle-item-image">
                                            {
                                                (!_.isEmpty(_allImages))
                                                    ?
                                                    <>
                                                        {
                                                            _allImages.map((data, index) => (
                                                                <a key={index}
                                                                    data-fancy-box={`tradein_info_img_slider${tradein.documentID}`}
                                                                    className={index === 0 ? '' : 'hide'} href={data}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setImgViewer({
                                                                            show: true,
                                                                            images: _allImages
                                                                        })
                                                                    }}
                                                                >
                                                                    {index === 0 ?
                                                                        <>
                                                                            <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                                            <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                        </>
                                                                        : <></>}

                                                                </a>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                        <img src={CommonHelper.showBrandLogo(dealersettings, tradein?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                    </a>
                                            }

                                        </div>
                                        <div className="vehicle-data" >
                                            <div className="vehicle-item-title" onClick={(e) => { handleEditTradeIn(tradein.documentID, tradein.sent) }}>{tradein.make} {tradein.model}
                                                {
                                                    !_.isEmpty(tradein.tags) && tradein.tags.map((rec, index) => {
                                                        return <div key={index} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, rec.type)}</div>
                                                    })
                                                }
                                            </div>
                                            {/* {tradein?.clientID ? <div className="dealer-group-title blue-color">{CommonHelper.getUserNamebyId(dealersettings.clients, tradein.clientID)}</div> : <></>} */}

                                            <div className="vehicle-item-info" onClick={(e) => { handleEditTradeIn(tradein.documentID, tradein.sent) }}>{tradein.exteriorColor ? `${tradein.exteriorColor},` : ''} {tradein.year ? tradein.year : ''}</div>
                                            <div className="vehicle-item-info" onClick={(e) => { if (!tradein.regNo) handleEditTradeIn(tradein.documentID, tradein.sent) }}>REG#: {tradein.regNo ? (<>{tradein.regNo} <span onClick={(e) => CommonHelper.handleCopy(e, tradein.regNo)}><i className="fas fa-copy"></i></span></>) : "--"} </div>
                                            <div className="vehicle-item-info" onClick={(e) => { if (!tradein.chassisNo) handleEditTradeIn(tradein.documentID, tradein.sent) }}>VIN#: {tradein.chassisNo ? (<>{tradein.chassisNo} <span onClick={(e) => CommonHelper.handleCopy(e, tradein.chassisNo)}><i className="fas fa-copy"></i></span></>) : "--"} </div>
                                            {tradein.evalexpertID ? <div className="vehicle-item-info" onClick={(e) => { handleEditTradeIn(tradein.documentID, tradein.sent) }}>EVAL#: {tradein.evalexpertID} </div> : <></>}




                                        </div>
                                    </>
                            }
                        </div>

                        {
                            showTag && tradein.autograbID
                                ?
                                <>
                                    <AutoGrabInfo
                                        selectedVehicle={tradein}
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        dealersettings={dealersettings}
                                    ></AutoGrabInfo>
                                </>
                                :
                                <></>
                        }

                        {
                            tradein.status || tradein.offerPrice || (isLinkSent && isTradein)
                                ?
                                <>
                                    <div className="tradin-item-button" onClick={(e) => { handleEditTradeIn(tradein.documentID, tradein.sent) }}>
                                        {
                                            tradein.status && _objCurrentStatus
                                                ?
                                                <>
                                                    <div className="float-left">
                                                        <div
                                                            className={`badge badge-pill mt-1 badge-status-${tradein.status === tipStatus.NEW ? 'open' : 'empty'}`}
                                                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                        >
                                                            <Translate
                                                                text={_objCurrentStatus.name}
                                                                upperCase={true}
                                                            />
                                                        </div></div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            isStatusChange && isReopen && [tipStatus.WON, tipStatus.LOST].includes(tradein.status)
                                                ?
                                                <>
                                                    <div className="float-right tradein-offer-price ml-2">
                                                        <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleStatusSave(tradein.tradeinProID, tradein.documentID, tipStatus.COMPLETED, true);

                                                        }}> <Translate text={`reopen`} upperCase={true} /> </a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        isStatusChange && !isReopen && tradein.status === tipStatus.COMPLETED
                                                            ?
                                                            <>
                                                                <div className="float-right tradein-offer-price ml-2">


                                                                    <a href="#" className="btn btn-common button-green mr-2" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleStatusSave(tradein.tradeinProID, tradein.documentID, tipStatus.WON);

                                                                    }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} upperCase={true} /> </a>

                                                                    <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleStatusSave(tradein.tradeinProID, tradein.documentID, tipStatus.LOST);

                                                                    }}> <Translate text={CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)} upperCase={true} /> </a>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    tradein.offerPrice && accessToShowTradeInPrice && !lockFinalPrice && _isTradeinCompleted
                                                                        ?
                                                                        <div className="float-right">
                                                                            <div className="tradein-offer-price ml-2">
                                                                                <NumberFormat value={tradein.offerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                            </div></div>
                                                                        :
                                                                        <>
                                                                            {
                                                                                isLinkSent
                                                                                    ?
                                                                                    <>
                                                                                        <Tooltip placement="bottom" title={`${tradein.modifiedDate ? moment.unix(tradein.modifiedDate.seconds).format('DD MMM YYYY h:mm A') : ''} - ${CommonHelper.getUserNamebyId(allUsers, tradein.modifiedBy)}`} >
                                                                                            <div className="float-right">
                                                                                                <div className={`badge badge-pill mt-1 badge-status-open`}>
                                                                                                    <Translate
                                                                                                        text={'Link Sent'}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                                </div></div>
                                                                                        </Tooltip>

                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </>
                                        }



                                    </div>
                                </>
                                :
                                <></>
                        }
                        {
                            ((isTradein && showTag && (ppsrEnabled || autoGrabEnabled)) || (_isTradeinDone && accessToShowIntakeAgreement))
                                ?
                                <>
                                    <div className={`tradin-item-ppsr mt-2 ${isIntakeLoading ? 'form-disable' : ''}`}>
                                        {
                                            (_isTradeinDone && accessToShowIntakeAgreement) && (<div className="float-left">
                                                <button
                                                    type="button"
                                                    className="btn btn-green"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleIntakeAgreement();
                                                    }}
                                                >
                                                    {isIntakeLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className="ico icon-waiver mr-2"></i>}

                                                    {'Intake Agreement'}</button>
                                            </div>)
                                        }

                                        <div className="float-right">

                                            {ppsrEnabled && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setShowPpsrScan(true);
                                                    }}
                                                >
                                                    <img src={_images.ppsr_logo} width="12" className="mr-1" style={{ marginTop: '-2px' }} />{'PPSR'}</button>
                                            )}

                                            {(autoGrabEnabled) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (_.isEmpty(tradein.mileage) || tradein.mileage === '0' || _.isEmpty(tradein.chassisNo))
                                                            setShowTradein(true);
                                                        else
                                                            handleAutoGrap(tradein.documentID, tradein);
                                                    }}
                                                >
                                                    <i className="ico icon-market-overlay"></i>{" "}
                                                    <Translate text={"Market Overlay"} />
                                                </button>
                                            )}

                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }


                        {isLoading ? <WidgetOverlay /> : <></>}

                    </div >

                </>
        }

        <PopUpModal show={showPpsrScan}>
            <PpsrScan
                show={showPpsrScan}
                clsActive='overlay-modal active'
                regNo={tradein?.regNo}
                regState={tradein?.regState}
                tradeinProID={tradein?.tradeinProID}
                clientID={tradein?.clientID}
                tradeinID={tradein?.documentID}
                chassisNo={tradein?.chassisNo}
                dealersettings={dealersettings}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
                handleClose={() => {
                    setShowPpsrScan(false);
                    if (isIntakeLoading) handleIntakeAgreement();
                }}
            >
            </PpsrScan>
        </PopUpModal>

        <PopUpModal show={showTradein}>
            <TradeInFields
                show={showTradein}
                tradeinID={tradein?.documentID}
                tradein={tradein}
                dealersettings={dealersettings}
                handleClose={handleModalClose}
            >
            </TradeInFields>
        </PopUpModal>
        <PopUpModal show={imageViewer.show}>
            <ImageViewer
                {...imageViewer}
                handleClose={() => { setImgViewer({ show: false, images: null }); }}
            />
        </PopUpModal>
    </>
    )
};

export default TradeInInfo;
