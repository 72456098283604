import React, { useState, useEffect } from 'react'
import "../../../styles/testdrive.scss";
import _ from 'lodash'
import moment from 'moment';

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay, ImageViewer, PopUpModal } from '../../../components';
import Translate from '../../../constants/translate';
import { objContact } from '../viewModel'

const CustomerConsent = (props) => {
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [contact, setcontact] = useState(objContact)
    const [firestoreDB, setFirestoreDB] = useState()
    const [urlType, setURLType] = useState('consent')
    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


    const { documentID, contactType, displayID, phoneCode,
        owner,
        company, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL, creditScoreTerms,
        phone, email, secondaryLicenseFrontUrl, secondaryLicenseBackUrl, licenseType, terms,
        users, client,
    } = contact;

    useEffect(() => {
        window.firebase.firestore().doc(`contactDetails/${props.contactid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB)) return
        //console.log('CustomerConsent', props.contactid);
        let refCollectionDatat;
        if (props.contactid) {

            refCollectionDatat = docData(firestoreDB.firestore().doc(`contactDetails/${props.contactid}`)
            )
                .pipe(
                    switchMap(tradeIns => {
                        //console.log(tradeIns);
                        const clientIds = [tradeIns.clientID];
                        return combineLatest(
                            of(tradeIns),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                        map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                        map(usersSnapshot => usersSnapshot)
                                    )
                                ) : of([])
                            ),
                        )
                    }),
                    map(([tradeIns, clientSnapshot, usersSnapshot]) => {
                        return {
                            ...tradeIns,
                            client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                            users: usersSnapshot[0],
                        }

                    })
                )
                .subscribe(_contacts => {
                    let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let _urlType = params.get('type');
                    if (_urlType) setURLType(_urlType)
                    //console.log('contactDetails', _contacts);
                    if (!_.isEmpty(_contacts))
                        setcontact(_contacts)
                });
            return () => {
                refCollectionDatat && refCollectionDatat.unsubscribe()
            }
        }
    }, [firestoreDB])

    return (
        <>
            {
                !_.isEmpty(contact) && !_.isEmpty(client)
                    ?
                    <>
                        <div className="quotation-wrap report-data-load">
                            <div className="wrapper">
                                <div className="print-wrap">
                                    <div className="header">
                                        <div className="headerwrap-left">
                                            <div className="header-left">
                                                {
                                                    client && client.logoURL
                                                        ?
                                                        <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                        :
                                                        <>
                                                            <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </>
                                                }
                                            </div>
                                        </div>

                                        <div className="headerwrap-right">
                                            <div className='header-right'>
                                                {' '}
                                                {client.name} <br />
                                                <span>
                                                    {' '}
                                                    {client.address ? client.address : ''}
                                                    {client.phone ? (
                                                        <>
                                                            <br />
                                                            {`Phone: ${client.phone}`}
                                                        </>
                                                    ) : <></>}
                                                    {client.email || client.website ? (
                                                        <>
                                                            <br />
                                                            {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                        </>
                                                    ) : <></>}
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="clear"></div>
                                    <div className="middle">
                                        <div className="title-head"><Translate text={urlType === 'credit' ? 'Finance Credit Score Consent' : 'Customer Consent'} upperCase={true} /></div>
                                        <div className="clear"></div>
                                        <div className="table-wraper brd-none">
                                            <div className="table-head"><Translate text={'contactDetails'} />
                                                {displayID ? ' (' + displayID + ')' : (`CNT-` + documentID)}
                                                {
                                                    contact && contact.isDLScan
                                                        ?
                                                        <div className="mrg-fixstatus badge-white ml-1 mt-0">
                                                            <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                            {`Digital Driving Licence`}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'Contact Name'} /></span>{CommonHelper.getFullName(null, contact, '--')}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'phone'} /></span>{phone ? (phoneCode ? phoneCode : '') + phone : '--'}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'email'} /></span>{email ? email : '--'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'owner'} /></span>{(!_.isEmpty(users) && owner) ? CommonHelper.getUserNamebyDocumentId(users, owner) : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'company'} /></span>{company?.name ? company.name : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'contactType'} /></span>{contactType ? contactType : '--'}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'licenseNo'} /></span>{licenseNo ? licenseNo : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'licenseType'} /></span>{licenseType ? licenseType : '--'}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span><Translate text={'licenseExpiry'} /></span>{licenseExpiry ? moment(licenseExpiry).format('DD MMM YYYY') : '--'}</div></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                                <div className="tbl-lic-fix">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span><Translate text={'licenseFrontURL'} /></span>
                                                                        {
                                                                            (licenseFrontURL)
                                                                                ?
                                                                                <a data-fancy-box={`licenseFrontURL`}
                                                                                    href={licenseFrontURL}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setImgViewer({
                                                                                            show: true,
                                                                                            images: licenseFrontURL
                                                                                        })
                                                                                    }}>
                                                                                    <img id="licenseFrontURL" src={licenseFrontURL} alt="" className="" width="150" />

                                                                                </a>
                                                                                :
                                                                                <img id="licenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span><Translate text={'licenseBackURL'} /></span>
                                                                        {
                                                                            (licenseBackURL)
                                                                                ?
                                                                                <a data-fancy-box={`licenseBackURL`}
                                                                                    href={licenseBackURL}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setImgViewer({
                                                                                            show: true,
                                                                                            images: licenseBackURL
                                                                                        })
                                                                                    }}>
                                                                                    <img id="licenseBackURL" src={licenseBackURL} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="licenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span><Translate text={'secondaryLicenseFrontUrl'} /></span>
                                                                        {
                                                                            (secondaryLicenseFrontUrl)
                                                                                ?
                                                                                <a data-fancy-box={`secondaryLicenseFrontUrl`}
                                                                                    href={secondaryLicenseFrontUrl}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setImgViewer({
                                                                                            show: true,
                                                                                            images: secondaryLicenseFrontUrl
                                                                                        })
                                                                                    }}>
                                                                                    <img id="secondaryLicenseFrontUrl" src={secondaryLicenseFrontUrl} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseFrontUrl" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span><Translate text={'secondaryLicenseBackUrl'} /></span>
                                                                        {
                                                                            (secondaryLicenseBackUrl)
                                                                                ?
                                                                                <a data-fancy-box={`secondaryLicenseBackUrl`}
                                                                                    href={secondaryLicenseBackUrl}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setImgViewer({
                                                                                            show: true,
                                                                                            images: secondaryLicenseBackUrl
                                                                                        })
                                                                                    }}>
                                                                                    <img id="secondaryLicenseBackUrl" src={secondaryLicenseBackUrl} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseBackUrl" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        {
                                            urlType === 'credit'
                                                ?
                                                <>
                                                    {
                                                        !_.isEmpty(creditScoreTerms)
                                                            ?
                                                            <>

                                                                <div className="table-wraper brd-none">
                                                                    <div className="table-head"><Translate text={'termsAndConditions'} upperCase={true} /></div>
                                                                    <div className="terms"
                                                                        dangerouslySetInnerHTML={{ __html: (creditScoreTerms.termsAndConditions ? creditScoreTerms.termsAndConditions : '--') }} />

                                                                </div>

                                                                {
                                                                    creditScoreTerms.verbalConsent
                                                                        ?
                                                                        <>
                                                                            <div className="table-wraper brd-none">
                                                                                <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                                                <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{'Verbal Consent'}</strong>{': Has the customer verbally provided you with their consent to capture and record their details/information.'}</span>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                creditScoreTerms.consentType
                                                                                    ?
                                                                                    <>
                                                                                        <div className="table-wraper brd-none">
                                                                                            <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                                                            <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{`${creditScoreTerms.consentType} Consent`}</strong>{`: Has the customer ${creditScoreTerms.consentType}ly provided you with their consent to capture and record their details/information.`}</span>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </>
                                                                }



                                                                <div className="table-wraper brd-none">
                                                                    <div className="table-head"><Translate text={'signature'} /> </div>
                                                                    <div className="vehicle-map">
                                                                        {
                                                                            creditScoreTerms.signature
                                                                                ?
                                                                                <img id={`creditScoreTerms-signature`} src={creditScoreTerms.signature} width="120" />
                                                                                :
                                                                                <img id={`creditScoreTerms-signature`} src={_images.sign} width="120" />
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {
                                                                    !_.isEmpty(users) && creditScoreTerms.addedBy
                                                                        ?
                                                                        <>
                                                                            <div className="table-wraper cst-consent-wrapper">
                                                                                <div className="footer-left"><Translate text={'Actioned by'} />  :&nbsp;
                                                                                    <strong>{CommonHelper.getUserNamebyDocumentId(users, creditScoreTerms.addedBy)}</strong>

                                                                                    {creditScoreTerms.addedDate ?
                                                                                        <>
                                                                                            &nbsp;on &nbsp;<strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(creditScoreTerms.addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(creditScoreTerms.addedDate.seconds).format('DD/MM/YYYY hh:mm A'))}</strong>
                                                                                        </>
                                                                                        : '--'}
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }


                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        !_.isEmpty(terms)
                                                            ?
                                                            <>

                                                                <div className="table-wraper brd-none">
                                                                    <div className="table-head"><Translate text={'termsAndConditions'} upperCase={true} /></div>
                                                                    <div className="terms"
                                                                        dangerouslySetInnerHTML={{ __html: (terms.termsAndConditions ? terms.termsAndConditions : '--') }} />

                                                                </div>

                                                                {
                                                                    terms.verbalConsent
                                                                        ?
                                                                        <>
                                                                            <div className="table-wraper brd-none">
                                                                                <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                                                <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{'Verbal Consent'}</strong>{': Has the customer verbally provided you with their consent to capture and record their details/information.'}</span>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                terms.consentType
                                                                                    ?
                                                                                    <>
                                                                                        <div className="table-wraper brd-none">
                                                                                            <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                                                            <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{`${terms.consentType} Consent`}</strong>{`: Has the customer ${terms.consentType}ly provided you with their consent to capture and record their details/information.`}</span>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </>
                                                                }



                                                                <div className="table-wraper brd-none">
                                                                    <div className="table-head"><Translate text={'signature'} /> </div>
                                                                    <div className="vehicle-map">
                                                                        {
                                                                            terms.signature
                                                                                ?
                                                                                <img id={`terms-signature`} src={terms.signature} width="120" />
                                                                                :
                                                                                <img id={`terms-signature`} src={_images.sign} width="120" />
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {
                                                                    !_.isEmpty(users) && terms.addedBy
                                                                        ?
                                                                        <>
                                                                            <div className="table-wraper cst-consent-wrapper">
                                                                                <div className="footer-left"><Translate text={'Actioned by'} />  :&nbsp;
                                                                                    <strong>{CommonHelper.getUserNamebyDocumentId(users, terms.addedBy)}</strong>

                                                                                    {terms.addedDate ?
                                                                                        <>
                                                                                            &nbsp;on &nbsp;<strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(terms.addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(terms.addedDate.seconds).format('DD/MM/YYYY hh:mm A'))}</strong>
                                                                                        </>
                                                                                        : '--'}
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }


                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </>
                                        }



                                    </div>
                                </div>
                                <div className="push"></div>
                            </div>
                            <div className="clear"></div>
                            <div className="footer">
                                <div className="footer-bottom">

                                    <p>
                                        <span style={{ paddingLeft: 0 }} >
                                            {client.address ? <>{client.address}</> : <></>}
                                        </span>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                            {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                            {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                            {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-left">{'Powered by FusionSD.'}</div>

                                    <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>

                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <PopUpModal show={imageViewer.show}>
                            <ImageViewer
                                {...imageViewer}
                                handleClose={() => { setImgViewer({ show: false, images: null }); }}
                            />
                        </PopUpModal>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default CustomerConsent;