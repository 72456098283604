import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';
import { LineChart, PopUpModal, ReactSelect } from '../../components';
import AttendeesList from './attendeesList';
import { tr } from 'date-fns/locale';

const EventAttendees = (props) => {
    const [selecttedEvent, setSelecttedEvent] = useState({})
    const [reportData, setReportData] = useState([])
    const [events, setEvents] = useState([])
    const [reportLoader, setReportLoader] = useState(true)
    const columnValues = [
        { name: 'Checked In', value: 'checkedin', color: '#39b54a' },
        { name: 'New', value: 'new', color: '#f15a29' },
        { name: 'Test Drive', value: 'testdrive', color: '#27aae1' },
    ];
    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;
    const [eventSettings, setEventSettings] = useState({})
    const [modelAttendeeShow, setModelAttendeeShow] = useState({ show: false })

    useEffect(() => {
        if (_.isEmpty(props.calendarEventData)) {
            setReportLoader(false)
            return
        }

        let _events = _.uniqBy(props.calendarEventData.filter(a => a.status === 'live' || a.status === 'closed'), 'id');
        if (_events.length === 0) {
            setReportLoader(false)
        }
        _events = [
            ..._.orderBy(_events.filter(a => a.status === 'live'), 'startDate.seconds', 'desc'),
            ..._.orderBy(_events.filter(a => a.status === 'closed'), 'startDate.seconds', 'desc')
        ]
        setEvents(_events.map(rec => {
            return {
                label: <>
                    {rec.title} {
                        _.find(eventsProStatus, { value: rec.status }) ? (
                            <div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: rec.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: rec.status })?.color ? _.find(eventsProStatus, { value: rec.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: rec.status }).name} </div>
                        ) : (
                            <div className={`badge badge-pill badge-status-${rec.status.toLowerCase()}`} >{rec.status} </div>
                        )
                    }
                </>,
                searchlabel: rec.title,
                value: rec.id,
                data: rec
            }
        }))
        setSelecttedEvent(_events[0])
    }, [props.calendarEventData])

    useEffect(() => {
        if (_.isEmpty(selecttedEvent)) {
            return;
        }

        firestoreDB(props.dealersettings).firestore().doc(`eventsPro/${selecttedEvent.id}/settings/${selecttedEvent.id}`).get()
            .then(doc => {
                if (doc.exists) {
                    setEventSettings(doc.data())
                }
            });

        window.unsubrefAttendeeDataData = firestoreDB(props.dealersettings).firestore().collection('eventsProAttendees')
            .where('isDeleted', '==', false)
            .where('eventsProID', '==', selecttedEvent.id)
            .onSnapshot(querySnapshot => {
                let _data = [];
                querySnapshot.forEach((rec) => {
                    const attendeeData = rec.data();
                    const timezone = selecttedEvent.timezone || moment.tz.guess()
                    for (var m = moment.unix(selecttedEvent.startDate.seconds).tz(timezone).startOf('day'); m.isSameOrBefore(moment.unix(selecttedEvent.endDate.seconds).tz(timezone).startOf('day')); m.add(1, 'days')) {
                        _data.push({
                            ...attendeeData,
                            'documentID': rec.id,
                            'id': rec.id,
                            'strcheckinDate': attendeeData?.checkinDate?.seconds ? moment.unix(attendeeData.checkinDate.seconds).tz(timezone).format('YYYY-MM-DD') : null,
                            'strtestdriveDate': attendeeData?.testDrives?.length > 0 ? moment.unix(attendeeData.testDrives[0]?.startDate?.seconds).tz(timezone).format('YYYY-MM-DD') : null,
                            'strnewDate': attendeeData?.addedDate?.seconds > 0 ? moment.unix(attendeeData.addedDate.seconds).tz(timezone).format('YYYY-MM-DD') : null,
                            'eventDate': m.format('YYYY-MM-DD')
                        })
                    }
                })
                setReportData(_.chain(_data)
                    .groupBy("eventDate")
                    .map((value, key) => ({
                        name: key,
                        checkedin: value.filter(a => a.strcheckinDate === key).length,
                        new: value.filter(a => a.strnewDate === key && a.isNew === true).length,
                        testdrive: value.filter(a => a.strtestdriveDate === key).length,
                        checkedindata: value.filter(a => a.strcheckinDate === key),
                        newdata: value.filter(a => a.strnewDate === key && a.isNew === true),
                        testdrivedata: value.filter(a => a.strtestdriveDate === key),
                    }))
                    .value())
                setReportLoader(false)
            })
    }, [selecttedEvent])

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <div className="form-group" style={{ width: '200px' }}>
                            <ReactSelect
                                options={events}
                                name={"events"}
                                placeholder={'select event'}
                                onChange={(e) => {
                                    setReportLoader(true)
                                    setSelecttedEvent(e ? e.data : null)
                                }}
                                value={selecttedEvent ? selecttedEvent.id : null}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    </div>
                    <h3><Translate text={'Event Attendees'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    <div className="text-center">
                        {
                            reportLoader ? (<div className="dashboard-widget-loader h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                </div>
                            </div>) : (<></>)
                        }
                        {
                            !reportLoader && _.isEmpty(reportData) ? (
                                <div className="dashboard-activity-item" >
                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2"> <Translate text={'No live events'} /> </div>
                                    </div>
                                </div>
                            ) : (
                                <LineChart
                                    id={'attendees-line-chart'}
                                    height={`420px`}
                                    data={reportData}
                                    columns={columnValues.map(rec => { return rec.value })}
                                    dynamicColumns={columnValues}
                                    calculationType={'count'}
                                    handleShowEnquiryList={(value, startDate, endDate) => {
                                        if (startDate[`${value}data`] && startDate[`${value}data`].length > 0) {
                                            setModelAttendeeShow({
                                                show: true,
                                                title: 'Attendees List',
                                                attendees: startDate[`${value}data`],
                                                eventSettings
                                            })
                                        }
                                    }}
                                />
                            )
                        }

                    </div>
                </div>
            </div >
            <PopUpModal show={modelAttendeeShow.show}>
                <AttendeesList
                    {...props}
                    {...modelAttendeeShow}
                    handleClose={() => setModelAttendeeShow({ show: false })}
                    history={props.history}
                    isFromDashboard={true}
                    eventSettings={eventSettings}
                ></AttendeesList>
            </PopUpModal>
        </>
    );
}

export default EventAttendees;