import React, { useState, useEffect } from 'react';
import { TableView } from '../../../../components';
import { mailjetcampaignFields } from '../viewModel';
import _ from 'lodash'
import { PopUpModal, ContentOverlay } from '../../../../components';
import CampaignPreview from '../campaignPreview';
import Translate from '../../../../constants/translate';
import ScheduleCampaign from '../scheduleCampaign';
import Swal from 'sweetalert2';
import axios from 'axios';
import toast from 'toasted-notes'

const CampaignsList = ({ activeTab, dealersettings, campaigns, setCampaigns, showAddCampaign,
	setShowAddCampaign, apiUrl, onCreateCampaign, loader, refresh_token, client_id, client_secret, tableLoader, handlePagination, hasMore, reload, setReload, timezone }) => {
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});
	const [showPreview, setShowPreview] = useState({
		show: false,
		previewID: '',
		title: '',
		campaign: null
	})
	const [showScheduleCampaign, setShowScheduleCampaign] = useState({
		show: false,
		title: '',
	});
	const [scheduleLoader, setscheduleLoader] = useState(false)
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 300,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	const handleTemplateModalClose = () => {
		setShowPreview({
			show: false,
			previewID: '',
			title: 'Campaign Info'
		})
	}

	const deleteCampaign = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to delete?',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then(async (result) => {
			if (result.value) {
				const url = `${apiUrl}mailjet-api/api/mailjet/delete-campaign`
				const payload = {
					campaignID: id,
					settingsID: dealersettings.oemID
				}
				if (dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && dealersettings.oemID) {
					payload.oemID = dealersettings.oemID;
				}
				else if (dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && dealersettings.regionID) {
					payload.regionID = dealersettings.regionID;
				}
				else if (dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && dealersettings.groupID) {
					payload.groupID = dealersettings.groupID;
				}
				else {
					payload.clientID = dealersettings.client.id
				}
				const sendNewCampaignResp = await axios.post(
					url,
					payload,
				);
				if (
					sendNewCampaignResp?.data?.success
				) {
					toast.notify('Campaign deleted successfully', {
						duration: 2000
					})
				}
				else {
					Swal.fire(sendNewCampaignResp?.message, '', 'error')
				}
				setReload(true)
			}
		})
	}

	const archiveCampaign = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to delete?',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then(async (result) => {
			if (result.value) {
				const url = `${apiUrl}mailjet-api/api/mailjet/archive-campaign`
				const payload = {
					campaignID: id,
					settingsID: dealersettings.oemID
				}
				if (dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && dealersettings.oemID) {
					payload.oemID = dealersettings.oemID;
				}
				else if (dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && dealersettings.regionID) {
					payload.regionID = dealersettings.regionID;
				}
				else if (dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && dealersettings.groupID) {
					payload.groupID = dealersettings.groupID;
				}
				else {
					payload.clientID = dealersettings.client.id
				}
				const sendNewCampaignResp = await axios.post(
					url,
					payload,
				);
				if (
					sendNewCampaignResp?.data?.success
				) {
					toast.notify('Campaign deleted successfully', {
						duration: 2000
					})
				}
				else {
					Swal.fire(sendNewCampaignResp?.message, '', 'error')
				}
				setReload(true)
			}
		})
	}

	const handleActionClick = id => {
		let currentCampaign = campaigns.filter(campaign => campaign["ID"] === id)[0]
		return currentCampaign?.Status === 2 ? (<>
			<div
				title='Clone Campaign'
				style={{ cursor: 'pointer' }}
				className='mini-button mr-1'
				onClick={e => {
					onCreateCampaign(currentCampaign)
				}}
			>
				<i className='ico icon-rearrange'></i>
			</div>
			<div
				style={{ cursor: 'pointer' }}
				className='mini-button mr-1'
				onClick={e => {
					setShowPreview({
						show: true,
						campaign: currentCampaign,
						title: 'Campaign Info'
					})
				}}
			>
				<i className='ico icon-zoom'></i>
			</div>
			<div
				title='delete'
				style={{ cursor: 'pointer' }}
				className='mini-button mr-1'
				onClick={e => {
					archiveCampaign(id)
				}}
			>
				<i className='ico icon-delete'></i>
			</div>
		</>) : currentCampaign?.Status === 1 ? (<>
			<div
				title='reschedule'
				style={{ cursor: 'pointer' }}
				className='mini-button mr-1'
				onClick={e => {
					setShowScheduleCampaign({
						show: true,
						campaignID: id
					})
				}}
			>
				<i className='ico icon-reassign'></i>
			</div>
			<div
				title='reschedule'
				style={{ cursor: 'pointer' }}
				className='mini-button mr-1'
				onClick={e => {
					deleteCampaign(id)
				}}
			>
				<i className='ico icon-delete'></i>
			</div>
		</>) : (<></>);
	};

	const handleCampaigndetailsClick = (e, id) => {
		e.preventDefault();
		let currentCampaign = campaigns.filter(campaign => campaign["ID"] === id)[0]
		if (currentCampaign && currentCampaign?.Status === 2) {
			setShowPreview({
				show: true,
				previewID: currentCampaign["ID"],
				title: 'Campaign Info',
				campaign: currentCampaign
			})
		}

		// if (currentCampaign.status === 'sent' || currentCampaign.status === 'sending') {
		// 	setShowPreview({
		// 		show: true,
		// 		previewID: currentCampaign.documentID,
		// 		title: 'Campaign Info'
		// 	})
		// } else {
		// 	setShowAddCampaign({
		// 		...showAddCampaign,
		// 		show: true,
		// 		title: 'Edit Campaign',
		// 		campaignID: currentCampaign.documentID,
		// 	})
		// }

		// this one will be called when opening a edit or preview/report modal

		// props.history.push("/contacts/details/" + id);
	};

	const filterCampaigns = (campaign) => {
		let returnVal = true

		if (activeTab === 'ongoing' && campaign.status !== 'Scheduled') {
			returnVal = false
		} else if (activeTab === 'draft' && campaign.status !== 'Draft') {
			returnVal = false
		} else if (activeTab === 'completed' && campaign.status !== 'Sent') {
			returnVal = false
		}

		if (searchText.length > 0 && !campaign.Title.includes(searchText)) {
			returnVal = false
		}
		return returnVal
	}

	const onSaveAndSend = async (date) => {
		setscheduleLoader(true)
		const url = `${apiUrl}mailjet-api/api/mailjet/schedule-campaign`
		const payload = {
			campaignID: showScheduleCampaign.campaignID,
			scheduleDate: date,
			settingsID: dealersettings.oemID
		}
		if (dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && dealersettings.oemID) {
			payload.oemID = dealersettings.oemID;
		}
		else if (dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && dealersettings.regionID) {
			payload.regionID = dealersettings.regionID;
		}
		else if (dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && dealersettings.groupID) {
			payload.groupID = dealersettings.groupID;
		}
		else {
			payload.clientID = dealersettings.client.id
		}
		const sendNewCampaignResp = await axios.post(
			url,
			payload,
		);
		if (
			sendNewCampaignResp?.data?.success
		) {
			toast.notify('Campaign sent successfully', {
				duration: 2000
			})
		}
		else {
			Swal.fire(sendNewCampaignResp?.data?.message, '', 'error')
		}
		setscheduleLoader(false);
		setShowScheduleCampaign({
			show: false
		})
		setReload(true)
	}

	return (
		<div className='campaign-right-panel'>
			<div className='campaign-list-container'>
				<h2>All Campaigns</h2>
				<div className='divider-line'></div>
				<div className='campaign-list-filter'>
					<div className='float-left'>
						<div className='filter-search search-icon'>
							<input placeholder='search' aria-label='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
						</div>
					</div>
					{
						campaigns?.length > 0 ? (
							<div className='float-right'>
								<button
									type='button'
									onClick={() => setReload(true)}
									className={`btn btn-primary float-left ml-2 mr-3 email-send-btn`}
								>
									{
										reload ?
											(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
											: (<><i className='ico icon-refresh'> </i></>)
									}
									Reload
								</button>
							</div>
						) : (<></>)
					}
				</div>
				<div className='campaign-list-table'>
					{loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
						<div className='common-table'>
							{campaigns.length > 0 ? (<>
								{
									tableLoader
										?
										<>
											<div className="dashboard-widget-loader h-100">
												<div className="d-flex h-100 justify-content-center align-items-center loader-primary">
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div>

											</div>
										</>
										:
										<>
										</>
								}
								<TableView
									isReorderable={false}
									rowIndex={'ID'}
									datalist={campaigns.filter(campaign => filterCampaigns(campaign))}
									height={windowSize.windowHeight}
									width={windowSize.windowWidth}
									columns={mailjetcampaignFields}
									dynamicFields={mailjetcampaignFields
										.filter(e => e.default === true)
										.map(v => _.pick(v, ['value']).value)}
									settingsLoader={false}
									handleActionClick={handleActionClick}
									handleRowSingleClick={handleCampaigndetailsClick}
									isSettings={false}
									hasMore={hasMore}
									handlePagination={handlePagination}
									isPaging={true}
								/>
							</>) : (
								<div className="text-center p-5">
									<p><Translate text={'No campaigns found'} /></p>
									<button className="btn btn-sm btn-default" onClick={(e) => onCreateCampaign()} ><i className="ico icon-add mr-2 pt-1 float-left"></i>
										<Translate text={'Create Campaign'} />
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<PopUpModal show={showPreview.show}>
				<CampaignPreview
					{...showPreview}
					handleClose={handleTemplateModalClose}
					apiUrl={apiUrl}
					refresh_token={refresh_token}
					client_id={client_id}
					client_secret={client_secret}
					dealersettings={dealersettings}
					timezone={timezone}
				/>
			</PopUpModal>
			<PopUpModal show={showScheduleCampaign.show}>
				<ScheduleCampaign
					show={showScheduleCampaign.show}
					handleClose={() => {
						setShowScheduleCampaign({ show: false })
					}}
					handleSchedule={onSaveAndSend}
					title={showScheduleCampaign.title}
					scheduleLoader={scheduleLoader}
					timezone={timezone}
				/>
			</PopUpModal>
		</div>
	);
};

export default CampaignsList;
