import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { ContentOverlay, TableView, ReactSelect } from "../../../../components";
import { vehicleModelFields, levelOptions } from './viewModel'
import Translate from '../../../../constants/translate';
import { default as _images } from '../../../../images';


const VehicleModels = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([]);
    const [selectedMake, setSelectedMake] = useState(null)
    const [selectedClient, setSelectedClient] = useState([])
    const [hiddenModels, setHiddenModels] = useState([])
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [brandIDs, setBrandIDs] = useState()
    const [groups, setClientGroups] = useState([]);
    const [clients, setOEMClients] = useState([]);
    const [regions, setClientRegions] = useState([])


    const isQuotationEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.clientSettings) &&
        !_.isEmpty(props.dealersettings.client.clientSettings.quotation) &&
        props.dealersettings.client.clientSettings.quotation.enabled && localStorage.defaultModule !== 'oem') ? true : false);
    let vehicleModelFieldsVM = [...vehicleModelFields];
    vehicleModelFieldsVM = vehicleModelFieldsVM.filter(item => isQuotationEnabled && selectedLevel && selectedLevel.level === 'individual' ? true : item.value !== 'settings')

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])

    useEffect(() => {
        if (localStorage.defaultModule === "oem")
            setSelectedLevel({
                'level': props.dealersettings.level,
                'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                    props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                        props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                            props.dealersettings.client.id
            })
        else
            setSelectedLevel({
                'level': 'individual',
                'levelID': props.dealersettings.client.id
            })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
        else {
            setClientOptions([{ name: 'oem' }])
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        if (_.isEmpty(selectedLevel) || (_.isEmpty(selectedLevel.levelID) && selectedLevel.level !== 'oem'))
            return

        let refQuery = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`).get();
        if (selectedLevel.level === 'region')
            refQuery = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${selectedLevel.levelID}`).get();
        else if (selectedLevel.level === 'group')
            refQuery = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedLevel.levelID}`).get();
        else if (selectedLevel.level === 'individual')
            refQuery = window.firebase.firestore().doc(`clients/${selectedLevel.levelID}`).get();

        refQuery.then(snapshot => {
            if (snapshot.exists) {
                if (snapshot.data().brandIDs) {
                    setBrandIDs(snapshot.data().brandIDs)
                }
                else if (snapshot.data().clientBrandIDs) {
                    setBrandIDs(snapshot.data().clientBrandIDs)
                }
                else {
                    setBrandIDs(props.dealersettings.client.brands.map(r => { return r.documentID }))
                }
                setHiddenModels(snapshot.data().hiddenModels ? snapshot.data().hiddenModels : [])
            }
            else {
                setLoader(false);
            }
        })

    }, [selectedLevel])


    // useEffect(() => {
    //     if (_.isEmpty(clients))
    //         return


    //     if (_.isEmpty(selectedClient)) {
    //         setSelectedClient(props.dealersettings.client)
    //         setHiddenModels(!_.isEmpty(props.dealersettings.client.hiddenModels) ? props.dealersettings.client.hiddenModels : [])
    //     }
    //     else {
    //         setHiddenModels(clients.find(item => item.value === selectedClient.documentID).data.hiddenModels)
    //     }
    // }, [clients])

    useEffect(() => {
        if (_.isEmpty(brandIDs))
            return

        async function getBrands() {
            const makepromises = []
            let _oemBrands = [];
            brandIDs.forEach(brand => {
                makepromises.push(
                    window.firebase.firestore().doc(`brands/${brand}`).get()
                )
            })

            const makeSnapshots = await Promise.all(makepromises)
            makeSnapshots.forEach(snap => {
                _oemBrands.push({
                    label: snap.data().name,
                    name: snap.data().value,
                    value: snap.id,
                    'documentID': snap.id,
                    active: _.isBoolean(snap.active) ? snap.active : true,
                })
            })
            setMakes(_oemBrands)
            setSelectedMake(_oemBrands[0])
        }
        getBrands();

    }, [brandIDs])

    useEffect(() => {
        if (_.isEmpty(selectedMake))
            return

        getModels()
    }, [selectedMake])

    const getModels = async () => {
        setLoader(true)
        const modelsSnapshot = await window.firebase.firestore().doc(`brands/${selectedMake.documentID}/models/${selectedMake.documentID}`).get();
        if (modelsSnapshot.exists) {
            let _models = [];
            modelsSnapshot.data().models.forEach((rec) => {
                _models.push({
                    ...rec,
                    'documentID': rec.value,
                    'active': !_.isEmpty(hiddenModels) ? !hiddenModels.some(item => item.make === selectedMake.label && item.model === rec.value) : true
                })
            })
            setModels(_models)
            setLoader(false)
        }
        else {
            setLoader(false)
        }
    }


    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
    }

    const handleActionClick = (id, data) => {

        return (
            <div className="fleet-btn-loan  reassign-fix" style={{ float: 'left' }}>
                <button type="submit" className="btn btn-sm btn-primary float-left" onClick={(e) => {
                    e.preventDefault();

                    let _clientId = selectedLevel.level === 'individual' && selectedLevel.levelID ? selectedLevel.levelID : props.dealersettings.client.id
                    let _make = selectedMake ? selectedMake.name : null;
                    let _model = data && data.value ? data.value : null;
                    let _type = data && data.type ? data.type : null;
                    let _group = data && data.group ? data.group : null;

                    window.open(`${window.location.origin}/stock/quote/${_clientId}?mk=${_make}&ml=${_model}&ty=${_type}&gp=${_group}&type=new`, '_blank');
                }}> <i className="ico icon-contract-fill" aria-hidden="true"></i>  <Translate text={'Set Quote'}/></button>
            </div>
        )
    }

    const handleSwitchChange = (id, checked, data) => {
        if (_.isEmpty(data))
            return

        let _hideModels = !checked ? [...hiddenModels.filter(m => m.make !== selectedMake.label),
        ..._.filter(hiddenModels, { 'make': selectedMake.label }).filter(item => item.model !== data.value),
        {
            make: selectedMake.label,
            model: data.value
        }
        ] : [...hiddenModels.filter(m => m.make !== selectedMake.label), ..._.filter(hiddenModels, { 'make': selectedMake.label }).filter(item => item.model !== data.value)]
        setHiddenModels(_hideModels)
        setModels(_.map(models, function (obj) {
            return obj.value === data.value ? { ...obj, 'active': checked } : { ...obj }
        }))

        let refQuery = `clientSettings/${props.dealersettings.client.settingsID}`;
        if (selectedLevel.level === 'region')
            refQuery = `clientSettings/${props.dealersettings.client.settingsID}/regions/${selectedLevel.levelID}`;
        else if (selectedLevel.level === 'group')
            refQuery = `clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedLevel.levelID}`;
        else if (selectedLevel.level === 'individual')
            refQuery = `clients/${selectedLevel.levelID}`;


        window.firebase.firestore().doc(refQuery)
            .set({
                hiddenModels: _hideModels
            }, { merge: true })
            .then(rec => {
                if (props.dealersettings.client && props.dealersettings.client.id)
                    window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Model Settings has been updated',
                        }, { merge: true })
            })
            .catch(error => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return (<>
        <h2><Translate text={'Vehicle Models'} /></h2>
        <div className='divider-line'></div>
        <div className="mt-4">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={(e, data) => {
                                        if (e) {
                                            if (e.value === 'oem')
                                                setLoader(true)
                                            setSelectedLevel({
                                                level: e.value
                                            });
                                            setMakes([]);
                                            setModels([]);
                                        }
                                        else {
                                            setSelectedLevel(null);
                                            setMakes([]);
                                            setModels([]);
                                        }
                                    }}
                                    value={selectedLevel ? selectedLevel.level : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0">
                                    <ReactSelect
                                        options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                        onChange={(e, data) => {
                                            if (e) {
                                                setLoader(true)
                                                setSelectedLevel({
                                                    ...selectedLevel,
                                                    levelID: e.value
                                                });
                                                setMakes([]);
                                                setModels([])
                                            }
                                            else {
                                                setSelectedLevel(null);
                                                setMakes([]);
                                                setModels([])
                                            }
                                        }}
                                        value={selectedLevel ? selectedLevel.levelID : ''}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            }
                            <div className="col-md-3 pr-0" id={'fieldsettings-levelid-fleet'}>
                                <ReactSelect
                                    options={makes.sort((a, b) => a.label.localeCompare(b.label))}
                                    name={'make'}
                                    placeholder={`select make`}
                                    onChange={(e) => { setSelectedMake(e) }}
                                    value={!_.isEmpty(selectedMake) ? selectedMake.documentID : ''}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="filter-search search-icon col-md-3">
                                <input placeholder="model,group,type" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    models.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={models.filter(({ name, group, type }) =>
                                      (!_.isEmpty(searchText) ? (
                                        (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(group) ? group.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(type) ? type.toLowerCase().includes(searchText.toLowerCase()) : false)
                                      ) : true)
                                    )
                                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                }
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={vehicleModelFieldsVM}
                                dynamicFields={vehicleModelFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                isSettings={false}
                                isReorderable={false}
                                handleSwitchChange={handleSwitchChange}
                                isSorting={true}
                                isTranslate={true}
                            dealersettings={props.dealersettings}
                            />
                        </div>
                    ) : selectedLevel && (selectedLevel.level !== 'oem' || _.isEmpty(selectedLevel.levelID)) ? (
                        <div className="common-table">
                            <div className='h-100  w-100'>
                                <div className='spinner-loader h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2">
                                            <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="common-table">
                            <div className='h-100  w-100'>
                                <div className='spinner-loader h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2">
                                            <p><Translate text={'No models found'} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div >
    </>);
}

export default VehicleModels;