/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab, } from 'react-bootstrap';
import moment from 'moment'
import _ from 'lodash';
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';
import { ImageViewer, PopUpModal } from '../../components'
/** VIEW-MODELS */

const EventModal = ({ handleClose, show, event, dealersettings }) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState('contactInfo');
  const [imageViewer, setImgViewer] = useState({ show: false, images: null });

  useEffect(() => {
    const fetchData = async () => {
      const ObjectToSend = {
        "CustomerId": [
          // '1647' 
          event.eventCustomerID
        ]
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ObjectToSend),
      };

      const response = await fetch(
        `${dealersettings.eventappURL}api/CustomerApp/Action/EventsbyAMSCustomerId`,
        requestOptions,
      );

      const respjson = await response.json();
      //console.log('event fetch', respjson)

      if (respjson.success && respjson.data && respjson.data[0]) {
        //console.log('resp', respjson.data[0])

        setData(respjson.data[0])
        setLoader(false)
      } else {
        setLoader(false)
      }
    }
    fetchData()
  }, [])

  const {
    documentID,
    title,
    location,
    eventDate,
    checkinDate,
    isCheckIn,
    invitationSent,
  } = event;

  const eventDateStart = moment(eventDate, "YYYY-MM-DD hh:mm A").format('ddd, DD MMM, hh:mm A')
  const checkinDateConverted = checkinDate ? moment(checkinDate).format('ddd, DD MMM, hh:mm A') : ''
  const eventDay = moment(eventDate, "YYYY-MM-DD hh:mm A").format('DD')
  const eventMonth = moment(eventDate, "YYYY-MM-DD hh:mm A").format('MMM')

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-event-app-info'
      >
        <Modal.Header closeButton>
          <Modal.Title><Translate text={'Event Info'} /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <div className='container-fluid'>
            {loader ? (
              <div style={{ height: '668.633px' }}>
                <div className='loader-center-screen' style={{}}>
                  <div className="spinner-loader h-100"><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                </div>
              </div>
            ) : (
              <>
                <div className='row'>
                  <div className=' pop-layout form-style'>
                    <div className='event-app-item-popupwrap'>
                      <div
                        className='event-app-item p-3'
                        data-toggle='modal'
                        data-target='#event-app-info-pop'
                      >
                        <div className='flex-event-app'>
                          <div className='event-app-item-date'>
                            <div className='event-app-item-subdate'>{eventDay}</div>
                            <div className='event-app-item-submonth'>{eventMonth}</div>
                          </div>
                          <div className='event-app-item-data'>
                            <div className='event-app-item-title event-app-item-title-large'>
                              {title ? title : ''}
                            </div>

                            <div className='event-app-item-location'>
                              {location ? location : ''}
                            </div>
                            <div className='event-app-item-info'>
                              <i className='ico icon-time'></i> {eventDateStart ? eventDateStart : ''}
                            </div>

                            {isCheckIn ? (
                              <>
                                <div className='event-app-item-button d-inline-block w-100'>
                                  <div className='badge badge-pill badge-event-checkedin'>
                                    <Translate text={'checkIn'} />
                                  </div>
                                  <div className='event-app-item-checkin float-right'>
                                    {' '}
                                    {checkinDateConverted ? checkinDateConverted : ''}
                                  </div>
                                </div>
                              </>
                            ) : invitationSent ? (
                              <>
                                <div className="event-app-item-button d-inline-block w-100">
                                  <div className="badge badge-pill badge-event-invited"><Translate text={'Invited'} /></div>
                                </div>
                              </>) : (<></>)}


                          </div>
                        </div>

                        <div className='event-app-item-description'>
                          <p>
                            {' '}
                            {data.EventDesc ? data.EventDesc.length > 150 ? data.EventDesc.substring(0, 149) + "..." : data.EventDesc : ''}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='tabs-wraper tabs finance-tab-fix'>
                      <nav>
                        <Tabs
                          transition={false}
                          className='nav-fill'
                          defaultActiveKey={activeTab}
                          onSelect={(k) => setActiveTab(k)}
                        >
                          {/* Contact Info */}
                          <Tab eventKey='contactInfo' title='Contact Info'>
                            <div className='form-row form-disable'>
                              <div className='form-group col-md-4'>
                                <label htmlFor=''><Translate text={'firstName'} /></label>
                                <input type='text' className='form-control' id='' value={data.FirstName ? data.FirstName : '--'} />
                              </div>
                              <div className='form-group col-md-4'>
                                <label htmlFor=''><Translate text={'middleName'} /> </label>
                                <input type='text' className='form-control' id='' value={data.MiddleName ? data.MiddleName : '--'} />
                              </div>
                              <div className='form-group col-md-4'>
                                <label htmlFor=''><Translate text={'lastName'} /> </label>
                                <input type='text' className='form-control' id='' value={data.LastName ? data.LastName : '--'} />
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'mobile'} /> </label>
                                <input type='text' className='form-control' id='' value={data.Phone ? data.Phone : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'email'} /> </label>
                                <input type='text' className='form-control' id='' value={data.Email ? data.Email : '--'} />
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'licenseNo'} /> </label>
                                <input type='text' className='form-control' id='' value={data.LicenseNo ? data.LicenseNo : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'licenseExpiry'} /> </label>
                                <input type='text' className='form-control' id='' value={data.LicenseExpiry ? data.LicenseExpiry : '--'} />
                              </div>
                            </div>

                            <div className='form-row'>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'primaryID'} /></label>
                                <div className='finance-license-img-wraper'>
                                  <div className='finance-licenseboxwrap'>
                                    <div className='finance-licensebox'>
                                      {
                                        (data.LicenseImageURL)
                                          ?
                                          <a data-fancy-box={`LicenseImageURL`}
                                            href={data.LicenseImageURL}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setImgViewer({
                                                show: true,
                                                images: data.LicenseImageURL
                                              })
                                            }}>
                                            <img src={data.LicenseImageURL} alt="" className="" />
                                          </a>
                                          :
                                          <img src={_images.nolicensebg} alt="" className="" />
                                      }
                                    </div>
                                    <small className='form-text text-muted'><Translate text={'front'} /></small>
                                  </div>
                                  <div className='finance-licenseboxwrap'>
                                    <div className='finance-licensebox'>
                                      {
                                        (data.LicenseImageBackURL)
                                          ?
                                          <a data-fancy-box={`LicenseImageBackURL`}
                                            href={data.LicenseImageBackURL}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setImgViewer({
                                                show: true,
                                                images: data.LicenseImageBackURL
                                              })
                                            }}>
                                            <img src={data.LicenseImageBackURL} alt="" className="" />
                                          </a>
                                          :
                                          <img src={_images.nolicensebg} alt="" className="" />
                                      }
                                    </div>
                                    <small className='form-text text-muted'><Translate text={'back'} /></small>{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          {/* Vehicle Info */}
                          <Tab eventKey='vehicleInfo' title='Vehicle Info'>
                            <div className='form-row form-disable'>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'model'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.VehicleMake ? data.Requirements.VehicleMake : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'model'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.VehicleModel ? data.Requirements.VehicleModel : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'saleType'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.VehicleSaleType ? data.Requirements.VehicleSaleType : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'type'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.VehicleModelType ? data.Requirements.VehicleModelType : '--'} />
                              </div>
                            </div>
                            <div className='form-row form-disable'>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'year'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.VehicleYear ? data.Requirements.VehicleYear : '--'} />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor=''><Translate text={'Color'} /> </label>
                                <input type='text' className='form-control' id='' value={!_.isEmpty(data.Requirements) && data.Requirements.Colour ? data.Requirements.Colour : '--'} />
                              </div>
                            </div>
                          </Tab>


                          {/* Test drives */}
                          <Tab eventKey='testDrives' title='Test Drives'>
                            {!_.isEmpty(data.TestDrives) && data.TestDrives.map(testDrive => (
                              <div className='vehicle-item '>
                                <div className='testdrive-listing-tab flex-vehicle'>
                                  <div className='vehicle-item-image'>
                                    {' '}
                                    <a href='#'>
                                      <div className='zoom-ico'>
                                        <i className='ico icon-zoom'></i>
                                      </div>
                                      <img
                                        src={CommonHelper.showBrandLogo(dealersettings, testDrive.VehicleMake)} className="img-object-fit-contain"
                                        alt=''
                                        width='70'
                                        height='70'
                                      />{' '}
                                    </a>{' '}
                                  </div>
                                  <div className='vehicle-data'>
                                    <div className='vehicle-item-title'>
                                      {testDrive.VehicleMake}{' '}{testDrive.VehicleModel}
                                    </div>
                                    <div className='vehicle-item-info'>
                                      {testDrive.VehicleModelType ? `${testDrive.VehicleModelType}, ` : ''}{testDrive.VehicleYear}
                                    </div>
                                    <div className='testdrive-item-status'>
                                      <div className='badge badge-pill badge-testdrive-done'>
                                        <Translate text={'Test Drive Done'} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='testdrive-duration'>
                                  <table
                                    width='100%'
                                    cellSpacing='0'
                                    cellPadding='0'
                                    border='0'
                                  >
                                    <tbody>
                                      <tr>
                                        <td width='50%'><Translate text={'Starting Time'} /></td>
                                        <td width='50%'> <Translate text={'End'} /></td>
                                      </tr>
                                      <tr>
                                        <td className='label-duration'>
                                          {testDrive.StartTime ? moment(testDrive.StartTime).format('DD/MM/YYYY hh:mm A') : '--'}
                                        </td>
                                        <td className='label-duration'>
                                          {testDrive.EndTime ? moment(testDrive.EndTime).format('DD/MM/YYYY hh:mm A') : '--'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ))}
                          </Tab>
                          {/* Notes */}
                          <Tab eventKey='notes' title='Notes'>
                            {!_.isEmpty(data.Notes) && data.Notes.map(note => (
                              <div className='notes-item' key={note.Id}>
                                {' '}
                                {note.Notes}
                                <div className='notes-item-sub'>
                                  {note.UserName} <span>{moment(note.ModifiedDate).format('DD/MM/YYYY hh:mm A')}</span>
                                </div>
                              </div>
                            ))}
                          </Tab>
                        </Tabs >
                      </nav>
                    </div>
                  </div>
                </div>
              </>)}
          </div>
        </Modal.Body>
      </Modal>
      <PopUpModal show={imageViewer.show}>
        <ImageViewer
          {...imageViewer}
          handleClose={() => { setImgViewer({ show: false, images: null }); }}
        />
      </PopUpModal>
    </>
  );
};
export default EventModal;
