import _, { truncate } from 'lodash';
import React from 'react';
import CommonHelper from '../../services/common';
import { ImageViewer, PopUpModal } from '../../components';

const FileItem = ({ file, token, apiURL, message, nylasToken, nylasURL }) => {
	const [downloadLoader, setDownloadLoader] = React.useState(false);
	const [imageViewer, setImgViewer] = React.useState({ show: false, images: null });

	const handleDownload = async (fileID, file, token) => {
		try {

			if (_.isEmpty(message.grantId) || _.isEmpty(message.id) || _.isEmpty(fileID) || _.isEmpty(nylasToken) || _.isEmpty(nylasURL)) return;

			setDownloadLoader(true);

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${nylasToken}`,
				},
				redirect: "follow"
			};
			//let _base64Data = ''
			await fetch(`${nylasURL}/v3/grants/${message.grantId}/attachments/${fileID}/download?message_id=${message.id}`, requestOptions)
				.then(async (result) => {

					if (CommonHelper.isValidImageFile(file.filename.split('.').pop())) {
						const fileBlob = await result.blob();
						setImgViewer({
							show: true,
							images: URL.createObjectURL(fileBlob),
							alt: file.filename
						})
					}
					else {
						const fileBlob = await result.blob();
						// Create a temporary link
						const downloadLink = document.createElement('a');
						downloadLink.href = URL.createObjectURL(fileBlob);
						downloadLink.download = file.filename; // Set file name
						document.body.appendChild(downloadLink);

						// Trigger the download
						downloadLink.click();

						// Cleanup
						document.body.removeChild(downloadLink);
						URL.revokeObjectURL(downloadLink.href);

						// const binaryData = await result.arrayBuffer();
						// let base64String = CommonHelper.bytesToBase64(binaryData)

						// //console.log('downloadFile_base64', base64String)
						// _base64Data = `data:${file.contentType.split(';')[0] ? file.contentType.split(';')[0] : file.contentType};base64,${base64String}`;
						// const a = document.createElement('a');
						// a.style.display = 'none';
						// a.href = _base64Data;
						// // the filename you want
						// a.download = file.filename;
						// document.body.appendChild(a);
						// a.click();
						// document.body.removeChild(a);
					}
				})
				.catch((error) => console.error(error));



			// var nylasMessage = window.firebase.functions().httpsCallable('nylasv3-downloadFile');
			// let response = await nylasMessage({
			// 	"grantId": message.grantId,
			// 	"messageId": message.id,
			// 	"attachmentId": fileID,
			// 	"type": "attachment",
			// })

			// let base64String = CommonHelper.bytesToBase64(response?.data?.thread?.data)
			// const a = document.createElement('a');
			// a.style.display = 'none';
			// a.href = `data:${file.contentType.split(';')[0] ? file.contentType.split(';')[0] : file.contentType};base64,${base64String}`;
			// // the filename you want
			// a.download = file.filename;
			// document.body.appendChild(a);
			// a.click();
			// document.body.removeChild(a);

			setDownloadLoader(false);
		} catch (error) {
			setDownloadLoader(false);

			console.log(error);
		}
	};

	const showFileIcon = type => {
		if (type) {
			switch (type.toLowerCase()) {
				case 'pdf':
					type = <i className='ico icon-files-pdf'></i>;
					break;
				case 'docx':
				case 'doc':
					type = <i className='ico icon-files-word'></i>;
					break;
				case 'xls':
				case 'xlsx':
					type = <i className='ico icon-files-excel'></i>;
					break;
				case 'zip':
				case '7z':
				case 'rar':
					type = <i className='ico icon-files-zip'></i>;
					break;
				case 'mp3':
				case 'ogg':
				case 'wav':
					type = <i className='ico icon-files-audio'></i>;
					break;
				case 'mp4':
				case 'avi':
				case 'wmv':
				case 'mpeg':
				case 'mov':
					type = <i className='ico icon-files-video'></i>;
					break;
				case 'jpg':
				case 'jpeg':
				case 'png':
					type = <i className='ico icon-files-image'></i>;
					break;
				default:
					type = <i className='ico icon-files-document'></i>;
			}

			return type;
		} else {
			return <i className='ico icon-files-document'></i>;
		}
	};

	return (
		<>
			<li>
				<span className='mailbox-attachment-icon'>
					{showFileIcon(file.filename.split('.').pop())}
				</span>
				<div className="mailbox-attachment-info">
					<a href="#" title={file.filename} className="mailbox-attachment-name" onClick={(e) => { e.preventDefault(); }}>
						<span> {truncate(`${file.filename}`, { length: 23, })}</span>
						<span>{' (' + CommonHelper.showFileSize(file.size) + ')'}</span>
					</a>
					<div className="mailbox-download-wrap">
						<span className="mailbox-attachment-size clearfix">
							{downloadLoader ? (
								<a
									href='#'
									className='btn btn-default btn-sm float-right'
									onClick={(e) => { e.preventDefault(); }}
									style={{ padding: '5px 0px 5px 5px' }}
								>
									<span
										className='spinner-border spinner-button mr-1 mt-0'
										role='status'
										aria-hidden='true'
									></span>
								</a>
							) : (
								<a
									href='#'
									className='btn btn-default btn-sm float-right'
									onClick={(e) => { e.preventDefault(); handleDownload(file.id, file, token) }}
								>
									<i className="fa fa-download" aria-hidden="true"></i>
								</a>
							)}
						</span>
					</div>
				</div>

			</li>
			<PopUpModal show={imageViewer.show}>
				<ImageViewer
					{...imageViewer}
					handleClose={() => { setImgViewer({ show: false, images: null }); }}
				/>
			</PopUpModal>
		</>

	);
};
// padding: 5px 0px 5px 5px;
export default FileItem;
