/** LIBRARIES */
import React, { useEffect, useRef, useState } from 'react';
import { Mention } from 'primereact/mention';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import _ from 'lodash';

const MentionEditor = (props) => {
    const [mentions, setMentions] = useState(props.mentions || [])
    const [suggestions, setSuggestions] = useState([]);
    const ref = useRef()

    useEffect(() => {
        ref?.current?.focus && ref.current.focus();
    }, [])

    useEffect(() => {
        if (_.isEmpty(props?.smsTemplateData?.textHTML))
            return

        props?.clearSmsTemplateData && props?.clearSmsTemplateData();
        props.onChange(props.smsTemplateData.textHTML)
        // let text = this.state.editorState.getCurrentContent().getPlainText();
        // text = text.charAt(0).toUpperCase() + text.slice(1);
        // this.props.onMentionChange(text);

    }, [props.smsTemplateData])

    const onSearch = (event) => {
        //in a real application, make a request to a remote url with the query and return suggestions, for demo we filter at client side
        const query = event.query;
        let suggestions;

        if (!query.trim().length) {
            suggestions = [...mentions];
        }
        else {
            suggestions = mentions.filter((user) => {
                return user.name.toLowerCase().includes(query.toLowerCase());
            });
        }

        setSuggestions(suggestions);
    }

    const itemTemplate = (suggestion) => {
        return (
            <div className="d-flex">
                <div>
                    <img alt={suggestion.name} src={suggestion.avatar} width="32" style={{ borderRadius: '50%' }} />
                </div>
                <div>
                    <span className="flex flex-column ml-2">
                        {suggestion.name}
                    </span>
                    <span className="flex flex-column ml-2">
                        <small style={{ fontSize: '.75rem', color: 'var(--text-color-secondary)' }}>{suggestion.email}</small>
                    </span>
                </div>
            </div>
        );
    }

    const onSelect = (e) => {
        props.onMentionSelect(e.suggestion)
    }

    return (
        <div className="card flex justify-content-center" id="simple-mention-editor">
            <Mention
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                suggestions={suggestions}
                onSearch={onSearch}
                field={'name'}
                placeholder={props.placeholder}
                rows={props.rows || 5}
                cols={props.cols || 40}
                itemTemplate={itemTemplate}
                inputClassName={props.className}
                onSelect={onSelect}
                inputRef={ref}
            />
        </div>
    );
}

export default MentionEditor;

//     constructor(props) {
//         super(props);
//         const contentBlock = htmlToDraft(this.props.value);
//         if (contentBlock) {
//             const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
//             const editorState = EditorState.createWithContent(contentState);
//             this.state = {
//                 editorState,
//             };
//         }
//         else {
//             this.state = {
//                 editorState: EditorState.createEmpty(),
//             };
//         }
//     }
//     componentDidMount = () => {
//         //this.editor.focus();
//     }

//     UNSAFE_componentWillReceiveProps

//     // onEditorStateChange = (editorState) => {
//     //     this.setState({
//     //         editorState,
//     //     });

//     //     console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
//     // };

//     // uploadImageCallBack(file) {
//     //   return new Promise((resolve, reject) => {
//     //       const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
//     //       xhr.open("POST", "https://api.imgur.com/3/image");
//     //       xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
//     //       const data = new FormData(); // eslint-disable-line no-undef
//     //       data.append("image", file);
//     //       xhr.send(data);
//     //       xhr.addEventListener("load", () => {
//     //         const response = JSON.parse(xhr.responseText);
//     //         //console.log(response);
//     //         resolve(response);
//     //       });
//     //       xhr.addEventListener("error", () => {
//     //         const error = JSON.parse(xhr.responseText);
//     //         reject(error);
//     //       });

//     //     });
//     // }

//     render() {
//         const { editorState } = this.state;
//         return (
//             <div>
//                 <Editor
//                     editorState={editorState}
//                     wrapperClassName="editor-item"
//                     editorClassName="editor-textarea"
//                     toolbarClassName="editor-toolbar"
//                     // mention={{
//                     //     separator: ' ',
//                     //     trigger: '@',
//                     //     suggestions: [
//                     //         //{ text: <div><img src="https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg" alt="" />APPLE</div>, value: 'apple', url: 'apple' },
//                     //         { text: 'Willims David', value: 'Willims David', url: 'Willims-David' }
//                     //     ],
//                     // }}
//                     //hashtag={{}}
//                     onEditorStateChange={this.props.onEditorStateChange}
//                     toolbar={{

//                         options: ['inline', 'list'],
//                         inline: {
//                             options: ['bold', 'italic', 'underline'],
//                             inDropdown: false,
//                             bold: { className: 'editor-button' },
//                             italic: { className: 'editor-button' },
//                             underline: { className: 'editor-button' },
//                         },
//                         list: {
//                             inDropdown: false,
//                             options: ['unordered', 'ordered'],
//                             unordered: { className: 'editor-button' },
//                             ordered: { className: 'editor-button' },
//                         },
//                         // link: {
//                         //     inDropdown: false,
//                         //     showOpenOptionOnHover: true,
//                         //     defaultTargetOption: '_self',
//                         //     options: ['link'],
//                         //     popupClassName: undefined,
//                         //     link: { className: 'editor-button' },
//                         // },
//                         //  image: {
//                         //   // icon: image,
//                         //    className: undefined,
//                         //    component: undefined,
//                         //    popupClassName: undefined,
//                         //    urlEnabled: true,
//                         //    uploadEnabled: true,
//                         //    alignmentEnabled: true,
//                         //    uploadCallback: uploadImageCallBack,
//                         //    previewImage: false,
//                         //    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//                         //    alt: { present: false, mandatory: false },
//                         //    defaultSize: {
//                         //      height: 'auto',
//                         //      width: 'auto',
//                         //    },
//                         //  }
//                     }}
//                 />
//             </div>
//         );
//     }
// }

//export default TextEditor;