import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../../images'
import { PieChart, NoDataPieChart } from '../../../components'
import { pipelinestatus } from "./viewModel";
 import toast from 'toasted-notes'
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';

const StatusCounts = (props) => {
    const [data, setData] = useState([])
     const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

   
    const fetchData = async () => {
        // console.log('lead by campaigns')
        let _filter = {
            clientID: props.dealersettings.client.id,
            type: 'status',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket').map(item => item.value).join(',')
        }

        if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType  =  props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)
            let enquiries_ = resp.data.data.map(rec => {
                const value = rec.name
                let color = ''
                let sName = CommonHelper.autoCaps(_.toLower(rec.name));
                let _pipelinestatus = pipelinestatus.filter(a => a.value === value)[0]
                if (_pipelinestatus.color) {
                    color = _pipelinestatus.color;
                    sName = _pipelinestatus.name
                }
                return {
                    ...rec,
                    name:  sName,
                    value,
                    color
                }
            })
            enquiries_ = _.orderBy(enquiries_, ['count'], ['desc'])
            setData(enquiries_)
            setFirstTimeLoaded(true)
            setLoader(false)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setFirstTimeLoaded(true)
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.selectedUser, props.selectedPipeline, props.dateRange])

    // useEffect(() => {
    //     if (!refreshData)
    //         return

    //     if (_.isEmpty(props.dealersettings.client.settings.campaigns)) {
    //         setLoader(false)
    //         setRefresh(false)
    //         return
    //     }
    //     setLoader(true)
    //     const reportRef = window.firebase.functions().httpsCallable('reports-getCounts');
    //     reportRef({
    //         "type": "getCampaignCount",
    //         "clientID": props.dealersettings.client.id,
    //         "filters": JSON.stringify(filter)
    //     }).then((data) => {
    //         //console.log(data)
    //         if (data.data.success === true) {
    //             if (!_.isEmpty(props.dealersettings.client.settings.campaigns) && props.dealersettings.client.settings.campaigns.length > 0) {
    //                 setData(_.map(props.dealersettings.client.settings.campaigns, function (e) {
    //                     return {
    //                         name: _.find(props.dealersettings.client.settings.campaigns, { value: e.value }) ? _.find(props.dealersettings.client.settings.campaigns, { value: e.value }).name : e.name,
    //                         count: _.find(data.data.data, { name: e.value }) ? _.find(data.data.data, { name: e.value }).count : 0,
    //                         value: e.value
    //                     }
    //                 }))
    //                 setLoader(false)
    //                 setRefresh(false)
    //             }
    //             else {
    //                 setLoader(false)
    //                 setRefresh(false)
    //             }
    //         }
    //         else {
    //             console.log(data.data.message)
    //             setLoader(false)
    //             setRefresh(false)
    //         }
    //     });


    // }, [refreshData])

    useEffect(() => {
        // if (props.pipelineEnquiries.length === 0) {
        //     //setLoader(false)
        //     return
        // }
        // var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        // if (!_.isEmpty(props.selectedPipeline)) {
        //     _pipelines = [props.selectedPipeline.value]
        // }
        // var monthOpen = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'open'
        //     && _pipelines.some(e => e === a.pipelineValue)
        //     && !_.isEmpty(a.addedDate)
        //     && a.addedDate.seconds >= monthStart.seconds
        //     && a.addedDate.seconds <= monthEnd.seconds
        //     && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true))

        // var monthWon = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
        //     && _pipelines.some(e => e === a.pipelineValue)
        //     && !_.isEmpty(a.wonDate)
        //     && a.wonDate.seconds >= monthStart.seconds
        //     && a.wonDate.seconds <= monthEnd.seconds
        //     && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true));

        // var monthLost = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'lost'
        //     && _pipelines.some(e => e === a.pipelineValue)
        //     && !_.isEmpty(a.lostDate)
        //     && a.lostDate.seconds >= monthStart.seconds
        //     && a.lostDate.seconds <= monthEnd.seconds
        //     && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true))

        // var monthDelivered = props.pipelineEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'delivered'
        //     && _pipelines.some(e => e === a.pipelineValue)
        //     && !_.isEmpty(a.deliveredDate)
        //     && a.deliveredDate.seconds >= monthStart.seconds
        //     && a.deliveredDate.seconds <= monthEnd.seconds
        //     && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true))

        // var _data = []
        // pipelinestatus.forEach(rec => {
        //     _data.push({
        //         name: rec.name,
        //         count: rec.value === 'open' ? monthOpen.length : rec.value === 'won' ? monthWon.length : rec.value === 'lost' ? monthLost.length : rec.value === 'delivered' ? monthDelivered.length : 0,
        //         color: rec.color ? rec.color : '',
        //         value: rec.value,
        //         startDate : monthStart,
        //         endDate : monthEnd
        //     })
        // })
        // setData(_data)
        //setLoader(false)

    }, [props.selectedUser, props.selectedPipeline])


    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-more"></i></a>*/}
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                    </div>
                    <h3><Translate text={'Leads By Status'} /></h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={'status-pie-chart'}
                                    height={'350px'}
                                    data={reportData}
                                    handleShowEnquiryList={(value, startDate, endDate) => {
                                        props.handleShowEnquiryList({
                                            'status': value === 'won' ? 'wondelivered' : value,
                                            'pipeline': _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                            'startDate': startDate,
                                            'endDate': endDate,
                                            'dateType': value === 'delivered' ? 'deliveredDate' : value === 'won' ? 'wonDate' : value === 'lost' ? 'lostDate' : 'addedDate'
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={'status-pie-chart'}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div >
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default StatusCounts;