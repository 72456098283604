export const dateFormats = [
    { value: "MM/DD/YYYY" },
    { value: "DD/MM/YYYY" },
    { value: "YYYY-MM-DD" },
    { value: "DD-MMM-YY" },
    { value: "DD-MMM-YYYY" },
    { value: "MM/DD/YY" },
    { value: "YY/MM/DD" }
]

export const timeFormats = [
    { value: "hh:mm a" },
    { value: "hh:mm A" },
    { value: "HH:mm" }
]


export const columnsAction = [
    { active: true, value: 'add', label: 'Add' },
    { active: true, value: 'sub', label: 'Subtract' },
]