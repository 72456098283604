/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { Signature, PopUpModal, ImageCropHook, ImageViewer } from '../../../components';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
/** VIEW-MODELS */
import { objSecondaryDriver } from '../viewModel'
import { storageBucket } from "../../../services/helper";

export default class SecondaryDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: Object.assign({}, objSecondaryDriver),
            errors: {},
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            imageViewer: {
                show: false,
                images: null
            },
            showSignature: false
        }

        // this.handleCropSelect = this.handleCropSelect.bind(this);
        // this.handleCropClose = this.handleCropClose.bind(this);
        // this.handleSignatureClose = this.handleSignatureClose.bind(this);

    }

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropInsShow) => {

        const { src, id, ext } = cropInsShow;
        let state = Object.assign({}, this.state.fields);
        state[id] = src;
        this.setState({ fields: state })
        this.saveImageToStorage(src, id, ext);

        this.setState({
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            //var extension = file.type
            reader.onloadend = () => {

                this.setState({
                    cropInsShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/testdrives/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/testdrives`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        this.setState({ fields: state }, () => { this.errorChange(id); })
                    })
            })
    };

    //#endregion


    handleSignatureClose = (imgURL) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state.secondarySignatureURL = imgURL;
            this.setState({ fields: state, showSignature: false })
            this.saveImageToStorage(imgURL, 'secondarySignatureURL', 'png');
        }
        else {
            this.setState({ showSignature: false })
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }
    componentDidMount() {
        const fields = Object.assign({}, objSecondaryDriver);
        const objData = Object.assign({}, this.props);
        fields.secondaryLicenseFrontURL = objData.licenseFront;
        fields.secondaryLicenseBackURL = objData.licenseBack;
        fields.secondarySignatureURL = objData.signURL;
        this.setState({ fields: fields })
    }

    componentWillUnmount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        //let errorClass = 'input_error';

        /*for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });*/

        if (formIsValid === true) {
            //console.log('formIsValid')
            this.props.handleClose(fields)
        }
        else
            return formIsValid;
    };



    render() {
        const { secondaryLicenseFrontURL, secondaryLicenseBackURL, secondarySignatureURL } = this.state.fields;
        const { imageViewer } = this.state;
        const { show, isDisable } = this.props;

        return (
            <>

                <Modal
                    id="add-secondary-driver"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-secondary-driver">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" > <Translate text={'secondaryLicense'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`container-fluid ${(isDisable) ? 'form-disable' : ''}`}>
                            <div className="row">
                                <div className="secondary-license-wraper">
                                    <div className="secondary-license-img-wraper">

                                        <div className="secondary-licenseboxwrap">
                                            <div className={`secondary-licensebox ${this.state.errors["secondaryLicenseFrontURL"]}`}>
                                                {
                                                    (secondaryLicenseFrontURL && secondaryLicenseFrontURL.indexOf('base64') > 0)
                                                        ?
                                                        <div className="img-loader">
                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    isDisable
                                                        ?
                                                        <></>
                                                        :
                                                        <label htmlFor="secondaryLicenseFrontURL" className={`${(secondaryLicenseFrontURL && secondaryLicenseFrontURL.indexOf('base64') <= 0) ? 'licensebox-edit secondary-license mini-button' : ''}`}>
                                                            <i className={`ico ${secondaryLicenseFrontURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="secondaryLicenseFrontURL"></i>
                                                            <input className="fileInput"
                                                                type="file"
                                                                name="licFront"
                                                                id="secondaryLicenseFrontURL"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => this.onSelectFile(e, 'license', 'secondaryLicenseFrontURL', 'License Front')} />
                                                        </label>

                                                }
                                                {
                                                    (secondaryLicenseFrontURL)
                                                        ?
                                                        <a data-fancy-box={`secondaryLicenseFrontURL`} href={secondaryLicenseFrontURL}
                                                            onClick={(e) => { e.preventDefault(); this.setState({ imageViewer: { show: true, images: secondaryLicenseFrontURL } }); }}>
                                                            <img src={secondaryLicenseFrontURL} alt="" className="" />
                                                        </a>
                                                        :
                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                }

                                            </div>
                                            <small className="form-text text-muted"><Translate text={'front'} /></small>
                                        </div>

                                        <div className="secondary-licenseboxwrap">
                                            <div className={`secondary-licensebox ${this.state.errors["secondaryLicenseBackURL"]}`}>
                                                {
                                                    (secondaryLicenseBackURL && secondaryLicenseBackURL.indexOf('base64') > 0)
                                                        ?
                                                        <div className="img-loader">
                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    isDisable
                                                        ?
                                                        <></>
                                                        :
                                                        <label htmlFor="secondaryLicenseBackURL" className={`${(secondaryLicenseBackURL && secondaryLicenseBackURL.indexOf('base64') <= 0) ? 'licensebox-edit secondary-license mini-button' : ''}`}>
                                                            <i className={`ico ${secondaryLicenseBackURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="secondaryLicenseBackURL"></i>
                                                            <input className="fileInput"
                                                                type="file"
                                                                name="licFront"
                                                                id="secondaryLicenseBackURL"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => this.onSelectFile(e, 'license', 'secondaryLicenseBackURL', 'License Back')} />
                                                        </label>
                                                }
                                                {
                                                    (secondaryLicenseBackURL)
                                                        ?
                                                        <a data-fancy-box={`secondaryLicenseBackURL`} href={secondaryLicenseBackURL}
                                                            onClick={(e) => { e.preventDefault(); this.setState({ imageViewer: { show: true, images: secondaryLicenseBackURL } }); }}>
                                                            <img src={secondaryLicenseBackURL} alt="" className="" />
                                                        </a>
                                                        :
                                                        <img src={_images.nolicensebg} alt="" className="" />
                                                }

                                            </div>
                                            <small className="form-text text-muted"><Translate text={'back'} /></small>
                                        </div>

                                    </div>
                                    <h2><Translate text={'signature'} /></h2>
                                    <div className="signature-box" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ showSignature: true })
                                    }}>

                                        {
                                            (secondarySignatureURL && secondarySignatureURL.indexOf('base64') > 0)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (secondarySignatureURL && !isDisable && secondarySignatureURL.indexOf('base64') <= 0)
                                                ?
                                                <div className="editsign-btn">
                                                    {(secondarySignatureURL && !isDisable) ? <a href="#" className="editsign mini-button" onClick={(e) => { e.preventDefault(); }}>
                                                        <i className="ico icon-edit" htmlFor="licBack"></i>
                                                    </a> : <></>}
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (secondarySignatureURL)
                                                ?
                                                <a data-fancy-box={`secondarySignatureURL`} href={secondarySignatureURL}
                                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ imageViewer: { show: true, images: secondarySignatureURL } }); }}>
                                                    <img src={secondarySignatureURL} alt="" width="75" />
                                                </a>
                                                :
                                                <img src={_images.nosign} alt="" width="75" />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    {
                        isDisable
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer className="modal-footer">
                                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose([]) }}><Translate text={'cancel'} /></button>
                                    <button type="button"
                                        className={`btn btn-primary float-right ${((secondarySignatureURL && secondarySignatureURL.indexOf('base64') > 0) || (secondaryLicenseBackURL && secondaryLicenseBackURL.indexOf('base64') > 0) || (secondaryLicenseFrontURL && secondaryLicenseFrontURL.indexOf('base64') > 0)) ? 'form-disable' : ''}`}
                                        onClick={(e) => this.handleSubmit(e)}><Translate text={'done'} /></button>
                                </Modal.Footer>
                            </>
                    }

                </Modal>

                <PopUpModal show={this.state.cropInsShow.show}>
                    <ImageCropHook
                        cropShow={this.state.cropInsShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>
                <PopUpModal show={this.state.showSignature}>
                    <Signature
                        show={this.state.showSignature}
                        clsActive='overlay-modal active'
                        handleClose={this.handleSignatureClose}
                    ></Signature>
                </PopUpModal>
                <PopUpModal show={imageViewer.show}>
                    <ImageViewer
                        {...imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>


        );
    }
}

