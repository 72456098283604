import React, { useState } from 'react';
import { InputText, ImageViewer, PopUpModal } from '../../../../components'
import _ from 'lodash'
import _images from '../../../../images'
import Translate from '../../../../constants/translate';
export const weekDays = [
    { active: true, value: 'sunday', label: 'Sunday' },
    { active: true, value: 'monday', label: 'Monday' },
    { active: true, value: 'tuesday', label: 'Tuesday' },
    { active: true, value: 'wednesday', label: 'Wednesday' },
    { active: true, value: 'thursday', label: 'Thursday' },
    { active: true, value: 'friday', label: 'Friday' },
    { active: true, value: 'saturday', label: 'Saturday' },
]

const InvoiceSettings = ({ countries, states, dealer, handleReactSelectChange, handleDynChange, handleHoursChange, onSelectFile, handleNumberChange, bindAddress, errorFields, deleteLogo, imageLoader }) => {
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    return (<>
        <div className="form-style w-100">
            <div className="form-row settings-company pt-2">
                <div className="settings-companyimage">
                    {
                        (imageLoader)
                            ?
                            <div className="img-loader">
                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (dealer.servicelogoURL)
                            ?
                            <a data-fancy-box={`servicelogoURL`} 
                            href={dealer.servicelogoURL} 
                            onClick={(e) => { e.preventDefault(); 
                                setImgViewer({
                                    show: true,
                                    images: dealer.servicelogoURL
                                })

                            }}>
                                <img className="img-object-fit-contain" src={dealer.servicelogoURL} alt="" height="65" />
                            </a>
                            :
                            <img src={_images.nocompany} alt="" height="65" />
                    }
                </div>
                <div className="settings-profile-info">
                    <div className="settings-profile-upload">
                        <label htmlFor="dealer-servicelogoURL" className={`btn-common float-left mr-2 font-600`}>
                            <Translate text={'Choose Picture'} />
                            <input className="fileInput"
                                type="file"
                                name="testee"
                                id="dealer-servicelogoURL"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => onSelectFile(e, 'profile', 'servicelogoURL', 'Client Logo')} />
                        </label>
                        <div className={`settings-delete-button ${_.isEmpty(dealer.servicelogoURL) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                            onClick={(e) => {
                                e.preventDefault();
                                deleteLogo();
                            }}> <i className="ico icon-delete"></i></a></div>
                    </div>
                    <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                </div>
            </div>
            <div className="settings-tab-fields-wrap">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Account Name'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'account name'}
                            className={`form-control ${errorFields["acName"]}`}
                            name="acName"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.acName}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Account No'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'account no'}
                            className={`form-control ${errorFields["acNo"]}`}
                            name="acNo"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.acNo}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'BSB No'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'bsb number'}
                            className={`form-control ${errorFields["bsbNo"]}`}
                            name="bsbNo"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.bsbNo}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Remittance Advice to'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'remittance advice to'}
                            className={`form-control ${errorFields["remittanceTo"]}`}
                            name="remittanceTo"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.remittanceTo}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'licenseNo'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'license no'}
                            className={`form-control ${errorFields["licenseNo"]}`}
                            name="licenseNo"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.licenseNo}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'BPAY Code'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'bpay code'}
                            className={`form-control ${errorFields["bpayCode"]}`}
                            name="bpayCode"
                            onChange={(e) => handleDynChange(e, 'invoiceSettings')}
                            value={dealer?.invoiceSettings?.bpayCode}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className='form-group col-md-6'>
                        <label ><Translate text={'Warranty Terms'} /></label>
                        <textarea
                            //ref={textareaRef}
                            id={`warranty-terms`}
                            className='form-control'
                            name='terms'
                            value={dealer?.invoiceSettings?.terms}
                            rows='3'
                            placeholder='warranty terms...'
                            onChange={e => {
                                handleDynChange(e, 'invoiceSettings');
                            }}
                            onFocus={e => {
                                var temp_value = e.target.value;
                                e.target.value = '';
                                e.target.value = temp_value;
                            }}
                            style={{ resize: 'none' }}
                        ></textarea>
                    </div>
                    <div className='form-group col-md-6'>
                        <label ><Translate text={'Additional Info'} /></label>
                        <textarea
                            //ref={textareaRef}
                            id={`additional-info`}
                            className='form-control'
                            name='additionalInfo'
                            value={dealer?.invoiceSettings?.additionalInfo}
                            rows='3'
                            placeholder='additional info...'
                            onChange={e => {
                                handleDynChange(e, 'invoiceSettings');
                            }}
                            onFocus={e => {
                                var temp_value = e.target.value;
                                e.target.value = '';
                                e.target.value = temp_value;
                            }}
                            style={{ resize: 'none' }}
                        ></textarea>
                    </div>
                </div>
            </div>

        </div>
        <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>
    </>);
}

export default InvoiceSettings;