import React, { useEffect, useState } from 'react'
import BeePlugin from 'bee-plugin'
import _ from 'lodash'
import { mentions } from './viewModel'

// Move credentials to backend for production.
const CLIENT_ID = "6ddd2aa7-69e4-4ab5-a790-6ef79e03d840"
const CLIENT_SECRET = "MCfUaEPW9ARY3KQ9JRnAR8hqBJEvtdmqtfsKCu51d5M7nOuiszl4"

//const BASE_TEMPLATE = './blankTemplate.json'



const BeePluginEditor = (props) => {
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        const DEFAULT_CONFIGURATION = {
            uid: 'template-editor-uid-' + props.dealersettings.client.settingsID,                   // Needed for identify resources of the user.
            container: 'template-editor-container', // Identifies the id of div element that contains BEE Plugin.
            language: 'en-US',
            autosave: true,
            mergeTags: props.formMentions ? [...mentions, ...props.formMentions] : mentions,
            specialLinks: props.specialLinks ? props.specialLinks : [],
            editorFonts: {
                showDefaultFonts: true,
                customFonts: [{
                    name: "FOR smart Sans",
                    fontFamily: "FOR smart Sans",
                    url: `${window.location.origin}/fonts/smart.css`
                },
                {
                    name: "FOR smart Next",
                    fontFamily: "FOR smart Next",
                    url: `${window.location.origin}/fonts/smartnext.css`
                },
                {
                    name: "Noto Sans",
                    fontFamily: "Noto Sans",
                    url: `${window.location.origin}/fonts/Noto-Sans.css`
                },
                {
                    name: "McLaren Bespoke",
                    fontFamily: "McLaren Bespoke",
                    url: `${window.location.origin}/fonts/McLaren-Bespoke.css`
                },
                {
                    name: "Soho Gothic Pro",
                    fontFamily: "Soho Gothic Pro",
                    url: `${window.location.origin}/fonts/Soho-Gothic-Pro.css`
                }]
            }
        }

        const beeConfig = {
            language: 'en-US',
            autosave: true,
        }

        const { template, onSave, onSend, onStart, onSaveAsTemplate } = props
        const beeFinalConfig = { ...DEFAULT_CONFIGURATION, ...beeConfig, onSave, onSaveAsTemplate, onSend, onStart }

        // handleFetchTemplate(baseTemplate || BASE_TEMPLATE)
        //     .then((template) => {
        const beeEditor = new BeePlugin()
        hanleFetchToken(CLIENT_ID, CLIENT_SECRET, beeEditor)
            .then(() => {
                setLoader(false)
                beeEditor.start(beeFinalConfig, template)
            })
        //     })
        //     .catch((error) => {
        //         console.error(error)
        //     })

    }, [])

    const hanleFetchToken = (clientId, clientSecret, beeEditor) => {
        return beeEditor.getToken(clientId, clientSecret)
    }

    return (
        <div id="template-editor-container" style={props.style} className={props.className} >
            {
                loader ? (<div className="spinner-loader h-100">
                    <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                    </div>
                </div>) : (<></>)
            }

        </div>
    )
}

export default BeePluginEditor