/** LIBRARIES */
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
import { StageProvider, StageConsumer } from '../pipeline/provider'
//import { StageProvider, StageConsumer } from './provider'
/** COMPONENTS */
import { SidePanel, PopUpModal } from '../../components'
import EnquiryList from '../pipeline/enquirylist';
import QuickView from "../pipeline/quickview";
import StageLoader from "../pipeline/stageloader";
import FilterPanel from './filterPanel'
import images from '../../images';
import Translate from '../../constants/translate';
import CallLogsContainer from './callLogsContainer';

const InBound = (props) => {
  const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
  const [showCallLogs, setShowCallLogs] = useState({ show: false, })
  const [customFilters, setCustomFilters] = useState([])
  const [pipelineView, setPipelineView] = useState('INBOUND')

  const _permissions = ((!_.isEmpty(props.dealersettings) &&
    !_.isEmpty(props.dealersettings.rolePermissions) &&
    !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

  const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

  useEffect(() => {
    document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.remove('fixed-top')
    return () => {
      document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
    }
  }, [])

  useEffect(() => {
    let settingsid = props.dealersettings.client.settings.id;
    let userID = props.dealersettings.id;
    const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
      .where('module', '==', 'inbound')
      .onSnapshot((querySnapshot) => {
        let _customFilters = [];
        querySnapshot.forEach(doc => {
          const _filt = {
						...doc.data(),
						documentID: doc.id,
						clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
					}
					_customFilters.push(_filt);
        });
        _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
        setCustomFilters(_customFilters)

      });
    return () => {
      userRefDoc && userRefDoc();
    }
  }, [])

  const sidepanelClose = useCallback(() => {
    setShowPanel({
      ...showpanel,
      clsActive: '',
      enquiryid: 0
    })
  }, [])

  const sidepanelOpen = useCallback((val) => {
    setShowPanel({
      ...showpanel,
      clsActive: 'active',
      enquiryid: val
    })
  }, [])

  const showCallLogsClose = useCallback(() => {
    setShowCallLogs({
      ...showpanel,
      show: false,
    })
  }, [])

  const showCallLogsOpen = useCallback((val) => {
    setShowCallLogs({
      ...showpanel,
      show: true
    })
  }, [])

  return (
    <>
      <LayoutConsumer>
        {({ pipeline, handlePipelineChange, dealersettings, clientUsers, groupUsers, clientModule }) => dealersettings ? (

          <StageProvider dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={props.groupUsers} history={props.history} pipelineView={pipelineView} clientModule={clientModule}>
            <StageConsumer>
              {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, loadMoreData,
                stageLoading, updateEnquiry, sectionloading, hasMore,
                isFilterApplied, handleShowInboundLead, handleLeadConvertShow, stageCounts,
                inBoundSearchText, handleInBoundSearchText, handleApplyInBoundFilter, handleClearInBoundFilter, }) => (
                <div className={`middle-wrapper middle-wrapper-pipeline mt-0 ${pipeline.length === 0 ? 'h-100' : ''}`}>
                  {
                    pipeline.filter(e => e.value === 'LeadsBucket').length === 0 ? (<></>) : (

                      <FilterPanel
                        pipeline={pipeline}
                        customFilters={customFilters}
                        handlePipelineChange={handlePipelineChange}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        handleApplyFilter={handleApplyInBoundFilter}
                        handleClearFilter={handleClearInBoundFilter}
                        stageCounts={stageCounts}
                        isFilterApplied={isFilterApplied}
                        view="pipeline"
                        searchText={inBoundSearchText}
                        handlesearchText={handleInBoundSearchText}
                        pipelineView={pipelineView}
                        showCallLogsOpen={showCallLogsOpen}
                        readOnlyEnquiryView={readOnlyEnquiryView}
                      />
                    )
                  }
                  {

                    pipeline.filter(e => e.value === 'LeadsBucket').length === 0 ? (
                      <div className="spinner-loader h-100">
                        <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                          <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                          <div className="no-data-txt-wrap">
                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                            <div className="no-data-content"><Translate text={'You dont have any inbound access. Please contact administrator.'} /></div>
                          </div>
                        </div>
                      </div>
                    ) : (isLoading || Object.keys(enquiries).length === 0 || JSON.stringify(_.map(enquiries.columns, function (item) { if (item.pipeline === pipeline.find(item => item.value === 'LeadsBucket').value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) ? (<StageLoader columns={columns} stages={pipeline.find(item => item.value === 'LeadsBucket') ? pipeline.find(item => item.value === 'LeadsBucket').stages : []}></StageLoader>) :
                      (
                        <EnquiryList
                          sidepanelOpen={sidepanelOpen}
                          enquiries={enquiries}
                          columns={columns}
                          pipeline={pipeline}
                          loadMoreData={loadMoreData}
                          stageLoading={stageLoading}
                          sectionloading={sectionloading}
                          stageCounts={stageCounts}
                          clientUsers={clientUsers}
                          groupUsers={groupUsers}
                          isFilterApplied={isFilterApplied}
                          searchText={inBoundSearchText}
                          handleEnquiryFav={handleEnquiryFav}
                          dealersettings={dealersettings}
                          handleShowInboundLead={handleShowInboundLead}
                          pipelineView={pipelineView}
                          hasMore={hasMore}
                        >
                        </EnquiryList>
                      )
                  }
                  <SidePanel
                    clsActive={showpanel.clsActive}
                    clsName={showpanel.clsName}
                    sidepanelClose={sidepanelClose}
                    title=''
                  >
                    <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={isFilterApplied} sidepanelClose={sidepanelClose} />
                  </SidePanel>
                  {/* <Notifier show={showNotifier} msg={notifierMsg} header={notifierInfo}/>  */}
                </div>
              )}
            </StageConsumer>
          </StageProvider>
        ) : (<></>)
        }
      </LayoutConsumer>
      <PopUpModal show={showCallLogs.show}>
        <CallLogsContainer
          show={showCallLogs.show}
          handleClose={showCallLogsClose}
          title={showCallLogs.title}
          history={props.history}
        />
      </PopUpModal>
    </>
  );
}


export default InBound;