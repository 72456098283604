/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import _ from 'lodash'
import moment from 'moment'
/** COMPONENTS */
import { ImageViewer, PopUpModal } from '../../components';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';
import ComposeEmail from '../mails/composeEmail';
import { firestoreDB } from '../../services/helper'
import enquiryHelper from '../../services/enquiryHelper'
import ViewRecipient from '../pandadoc/viewRecipient'
import PandaDocEditor from '../pandadoc'
import TriggerHistoryModal from "../triggersLog/triggerHistoryModal";
//import { default as _images } from '../../images';

const timelineList = [
    { active: true, index: 1, value: 'sent', label: <Translate text={'sent'} /> },
    { active: true, index: 2, value: 'viewed', label: <Translate text={'Viewed'} /> },
    { active: true, index: 3, value: 'completed', label: <Translate text={'Completed'} /> }
]

const FileInfo = ({ file, dealersettings, selectedfiles, handleCheckChange, isDeivered, pathToDelete, enquiryID, contactID, companyID, serviceID, stockID, contact, tradeinProID, fleetProID, history, dealerID, hideSelect, clientUsers }) => {
    const [editshow, setEditshow] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [signdownloadLoader, setSignDownloadLoader] = useState(false)
    //const [sendAsEmailLoader, setSendAsEmailLoader] = useState(false)
    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
        file: null,
    });
    const [sendLoader, setSendLoader] = useState(false);
    const [recpModel, setrecpModel] = useState({ show: false })
    const [signModel, setSignModel] = useState({ show: false })
    const [showHistoryModal, setShowHistoryModal] = useState({
        show: false,
        data: {},
        title: "Stats Timeline",
    });
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const node = useRef();
    const { documentID, name, url, type, createdOn, addedBy, size, digitalSignResp } = file;

    const [showReAuthentication, setReAuthentication] = useState(false);

    const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(dealersettings);
    const deleteFiles = ((dealersettings?.rolePermissions?.permissions?.deleteFiles) ? true : false);

    const pandadocEnabled = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0] &&
        dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0].active === true &&
        dealersettings.client.integrations.filter(e => e.type === "pandadoc")[0].enabled === true
    ) ? true : false);
    const pandadocapikey = dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings?.api_key
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleFileLog = (_subType) => {
        let logNotes = 'File viewed';
        if (file.name)
            logNotes = 'File: ' + file.name + (_subType === 'download' ? ' downloaded' : ' viewed');

        let viewPath = file.refPath ? file.refPath : ((serviceID || tradeinProID || fleetProID || enquiryID || stockID || dealerID) && pathToDelete) ? pathToDelete :
            ((file.serviceID || serviceID) ? `serviceJobs/${file.serviceID || serviceID}/files` :
                ((file.tradeinProID || tradeinProID) ? `tradeinPro/${file.tradeinProID || tradeinProID}/files` :
                    ((file?.fleetProID || fleetProID) ? `fleetPro/${file?.fleetProID || fleetProID}/files` :
                        ((file.enquiryID || enquiryID) ? `enquiries/${file.enquiryID || enquiryID}/files` :
                            ((file.stockID || stockID) ? `stock/${file.stockID || stockID}/files` :
                                ((file.companyID || companyID) ? `companies/${file.companyID || companyID}/files` :
                                    (dealerID ? `clients/${dealerID}/files` :
                                        ((file.contactID || contactID) ? `contactDetails/${file.contactID || contactID}/files` :
                                            null))))))));

        let _objLogData = {
            notes: logNotes,
            type: 'files',
            subType: _subType ? _subType : 'view',
            recordId: documentID,
            path: viewPath,
            enquiryID: (file?.enquiryID || enquiryID),
            serviceID: (file?.serviceID || serviceID),
            tradeinProID: (file?.tradeinProID || tradeinProID),
            fleetProID: (file?.fleetProID || fleetProID),
            contactID: (file?.contactID || contactID),
            companyID: (file?.companyID || companyID),
            stockID: (file?.stockID || stockID),
            dealerID: (file?.dealerID || dealerID),
        }

        Object.keys(_objLogData).forEach(key => (_objLogData[key] === undefined) ? delete _objLogData[key] : {});
        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
        CommonHelper.saveAuditLog(_objLogData);
        //if (stockID)
        CommonHelper.saveFileChangeLog(_objLogData, 'updated');
    }

    const handleSendAsEmail = () => {
        setShowComposeModal({
            ...showComposeModal,
            show: true,
            type: 'compose',
            title: 'New Message',
            emailData: !_.isEmpty(contact) ? {
                mailTo: [{
                    name: contact.firstName,
                    email: contact.email
                }]
            } : null,
        });
    }

    const handleFileDownload = async url => {
        setDownloadLoader(true)
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            //console.log('blob file', blob)
            const bloburl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = bloburl;
            a.download = _.trim(name) ? _.trim(name) : 'download';
            const clickHandler = () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    a.removeEventListener('click', clickHandler);
                }, 150);
            };
            a.addEventListener('click', clickHandler, false);
            a.click();
            setDownloadLoader(false)
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const handleSignedFileDownload = async id => {
        setSignDownloadLoader(true)
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: `API-Key ${pandadocapikey}`
            }
        };
        // Fetch the original image
        fetch(`https://api.pandadoc.com/public/v1/documents/${id}/download`, options)
            // Retrieve its body as ReadableStream
            .then((response) => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            // Create an object URL for the response
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `SINGED-${_.trim(name) ? _.trim(name) : 'document'}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setSignDownloadLoader(false)
            })
            .catch((err) => console.error(err));
    }

    const deleteFile = (fileID) => {
        let deletePath = file.refPath ? file.refPath : ((serviceID || tradeinProID || fleetProID || enquiryID || stockID || dealerID) && pathToDelete) ? `${pathToDelete}/${fileID}` :
            ((file.serviceID || serviceID) ? `serviceJobs/${file.serviceID || serviceID}/files/${fileID}` :
                ((file.tradeinProID || tradeinProID) ? `tradeinPro/${file.tradeinProID || tradeinProID}/files/${fileID}` :
                    ((file?.fleetProID || fleetProID) ? `fleetPro/${file?.fleetProID || fleetProID}/files/${fileID}` :
                        ((file.enquiryID || enquiryID) ? `enquiries/${file.enquiryID || enquiryID}/files/${fileID}` :
                            ((file.stockID || stockID) ? `stock/${file.stockID || stockID}/files/${fileID}` :
                                ((file.companyID || companyID) ? `companies/${file.companyID || companyID}/files/${fileID}` :
                                    (dealerID ? `clients/${dealerID}/files/${fileID}` :
                                        ((file.contactID || contactID) ? `contactDetails/${file.contactID || contactID}/files/${fileID}` :
                                            null))))))));

        if (_.isEmpty(deletePath)) return;

        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete the selected file.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {

                let modifiedBy = dealersettings ? dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB((file.stockID || dealerID || stockID) ? null : dealersettings).firestore().doc(deletePath)
                updateRef.
                    set({
                        isDeleted: true,
                        modifiedBy,
                        modifiedDate
                    }, { merge: true })
                    .then((docRef) => {
                        toast.notify('File successfully deleted.', {
                            duration: 2000
                        })

                        let logNotes = 'File deleted';
                        if (file.name)
                            logNotes = 'File: ' + file.name + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'files',
                            subType: 'delete',
                            recordId: fileID,
                            path: deletePath,
                            enquiryID: (file?.enquiryID || enquiryID),
                            serviceID: (file?.serviceID || serviceID),
                            tradeinProID: (file?.tradeinProID || tradeinProID),
                            fleetProID: (file?.fleetProID || fleetProID),
                            contactID: (file?.contactID || contactID),
                            companyID: (file?.companyID || companyID),
                            stockID: (file?.stockID || stockID),
                            dealerID: (file?.dealerID || dealerID),
                        }

                        Object.keys(_objLogData).forEach(key => (_objLogData[key] === undefined) ? delete _objLogData[key] : {});

                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);
                        CommonHelper.saveFileChangeLog(_objLogData, 'updated');

                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });

            }
        })

    }

    const showFileIcon = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                    type = <i className="ico icon-files-pdf"></i>;
                    break;
                case 'lock':
                    type = <i className="ico icon-files-lock"></i>;
                    break;
                case 'docx':
                case 'doc':
                    type = <i className="ico icon-files-word"></i>;
                    break;
                case 'xls':
                case 'xlsx':
                    type = <i className="ico icon-files-excel"></i>;
                    break;
                case 'zip':
                case '7z':
                case 'rar':
                    type = <i className="ico icon-files-zip"></i>;
                    break;
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = <i className="ico icon-files-audio"></i>;
                    break;
                case 'mp4':
                case 'mpeg':
                case 'mov':
                case 'avi':
                    type = <i className="ico icon-files-video"></i>;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = <i className="ico icon-files-image"></i>;
                    break;
                default:
                    type = <i className="ico icon-files-document"></i>;
            }

            return type;
        }
        else {
            return <i className="ico icon-files-document"></i>
        }
    }

    const isMediaFile = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                case 'docx':
                case 'doc':
                case 'xls':
                case 'xlsx':
                case 'zip':
                case '7z':
                case 'rar':
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = false;
                    break;
                case 'mp4':
                case 'avi':
                case 'mpeg':
                case 'mov':
                    type = false;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = true;
                    break;
                default:
                    type = false;
            }

            return type;
        }
        else {
            return false;
        }


    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        setReAuthentication(false);
    }
    //#endregion

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            ...showComposeModal,
            show: false,
            type: '',
            title: '',
            emailData: null,
            file: null,
        });
    };

    // const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
    //     const { mailTo, mailCc, mailBcc, subject, email } = trigger;
    //     setSendLoader(true);

    //     if (mailTo === '' && mailCc === '' && mailBcc === '') {
    //         toast.notify('Please specify at least one recipient.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (subject === '') {
    //         toast.notify('Please write subject.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     if (email === '' || email === '<p></p>\n') {
    //         toast.notify('Please write in email body.', {
    //             duration: 2000,
    //         });
    //         setSendLoader(false);
    //         return;
    //     }

    //     try {
    //         let emailObj = {
    //             token: dealersettings.nylasAccessToken,
    //             draft: {
    //                 subject: trigger.subject,
    //                 body: trigger.email,
    //             },
    //         };

    //         if (trigger.mailTo !== '') {
    //             emailObj.draft.to = trigger.mailTo.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailCc !== '') {
    //             emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.mailBcc !== '') {
    //             emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
    //                 return {
    //                     name: '',
    //                     email: email,
    //                 };
    //             });
    //         }

    //         if (trigger.files.length > 0) {
    //             emailObj.files = trigger.files;
    //             //console.log('emailObj.files', emailObj.files)
    //         }

    //         setTimeout(() => {
    //             toast.notify('Email has been sent.', {
    //                 duration: 2000,
    //             });
    //             setShowComposeModal({
    //                 ...showComposeModal,
    //                 show: false,
    //                 type: '',
    //                 title: '',
    //                 emailData: null,
    //                 file: null,
    //             });
    //         }, 300);

    //         // console.log('emailObj', emailObj);
    //         const nylasSendEmail = window.firebase
    //             .functions()
    //             .httpsCallable('nylas-sendEmail');
    //         const resp = await nylasSendEmail(emailObj);
    //         if (resp.data.success) {
    //             enquiryHelper.linkEnquiry(resp.data, { dealersettings, enquiryID, contactID, companyID, serviceID, stockID, contact, tradeinProID, fleetProID, history })
    //             setSendLoader(false);
    //         } else {
    //             setSendLoader(false);
    //             if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
    //                 Swal.fire({
    //                     title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
    //                     icon: 'info',
    //                     showCancelButton: true,
    //                     confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
    //                     cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
    //                 }).then(result => {
    //                     if (result.value) {
    //                         window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
    //                             nylas: null,
    //                         }, { merge: true });
    //                         history.push('/settings/profile?key=emailsettings');
    //                     }
    //                 });
    //                 return
    //             }
    //             else {
    //                 Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');
    //             }
    //         }
    //     } catch (error) {
    //         Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');

    //         setSendLoader(false);
    //         console.log(error);
    //     }
    // };

    const handleSignRequest = (id) => {
        let viewPath = file?.refPath?.split('/files')?.[0] || '';

        const payload = {
            name: name,
            recipients: [{
                ..._.pick(contact, ['firstName', 'lastName', 'email']),
                name: `${contact?.firstName || ''} ${contact?.lastName || ''}`,
                imageURL: contact.userImageURL
            }],
            url: url,
            parse_form_fields: false,
            metadata: {
                collection: viewPath,
                fileID: documentID || '',
                projectId: viewPath?.includes('stock/') ? null : dealersettings?.client?.projectId || null
            }
        };
        setrecpModel({
            show: true,
            title: 'Review recipients',
            data: payload,
            saveDoc: true,
            apikey: pandadocapikey,
            document_id: id
        })
    }

    return (
        <>
            <div className="file-manager-wrap" id={'file_' + documentID}>

                <div className="flex-file-manager" >

                    {signdownloadLoader ? (
                        <>
                            <div className="dashboard-widget-loader h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {
                        isDeivered
                            ?
                            <></>
                            :
                            <>
                                {
                                    stockID || hideSelect
                                        ?
                                        <></>
                                        :
                                        <>
                                            <div className="file-manager-check-wrap">
                                                <div className="checkbox icheck-success">
                                                    <input
                                                        type="checkbox"
                                                        id={documentID}
                                                        name={documentID}
                                                        className="uncheck-activity"
                                                        checked={(_.isObject(selectedfiles) && !_.isEmpty(selectedfiles) && selectedfiles.indexOf(documentID) >= 0) ? true : false}
                                                        onChange={(e) => {
                                                            handleCheckChange(e)
                                                        }} />
                                                    <label htmlFor={documentID}></label>
                                                </div>
                                            </div>
                                        </>
                                }


                                <div className="file-manager-more">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setEditshow(!editshow)
                                        if (editshow)
                                            document.addEventListener('click', setEditshow(false));
                                    }}><i className="ico icon-more"></i></a>
                                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>

                                        <ul className="more-dropdown">
                                            <>
                                                {
                                                    (isPrivacyFileEnabled)
                                                        ?
                                                        <>
                                                            <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                <i className="ico icon-zoom"></i> <Translate text={'view'} /> </a>
                                                            </li>
                                                            {
                                                                pandadocEnabled && digitalSignResp?.status === 'completed' ? (
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                        <i className="ico icon-excel-download"></i> <Translate text={signdownloadLoader ? 'Downloading...' : 'download'} /> </a>
                                                                    </li>
                                                                ) : <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                    <i className="ico icon-excel-download"></i> <Translate text={downloadLoader ? 'Downloading...' : 'download'} /> </a>
                                                                </li>
                                                            }

                                                            {
                                                                pandadocEnabled && _.isEmpty(digitalSignResp?.status) && CommonHelper.isValidSignatureFile(type) === true ? (
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                        <i className="ico ico icon-sign"></i> <Translate text={'requestSignature'} /> </a>
                                                                    </li>
                                                                ) : (<></>)
                                                            }

                                                            <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                <i className="ico icon-Mail"></i> <Translate text={'Send as Email'} /> </a>
                                                            </li>
                                                            {
                                                                deleteFiles
                                                                    ?
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                                        <i className="ico icon-delete"></i> <Translate text={'delete'} /> </a>
                                                                    </li>
                                                                    :
                                                                    <></>
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <li>
                                                                {
                                                                    (isMediaFile(type) === true)
                                                                        ?
                                                                        <a data-fancy-box={`file_data_view_${documentID}`}
                                                                            href={url}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setImgViewer({
                                                                                    show: true,
                                                                                    images: url
                                                                                })
                                                                                handleFileLog('view');
                                                                            }}>
                                                                            <i className="ico icon-zoom"></i> <Translate text={'view'} />
                                                                        </a>
                                                                        :
                                                                        <a href="#"
                                                                            onClick={(e) => { e.preventDefault(); window.open(url, '_blank'); handleFileLog('view'); }}>
                                                                            <i className="ico icon-zoom"></i> <Translate text={'view'} />
                                                                        </a>
                                                                }
                                                            </li>


                                                            {
                                                                pandadocEnabled && digitalSignResp?.status === 'completed' ? (
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleSignedFileDownload(digitalSignResp?.id); }}>
                                                                        <i className="ico icon-excel-download"></i> <Translate text={signdownloadLoader ? 'Downloading...' : 'download'} /> </a>
                                                                    </li>
                                                                ) : <li> <a href="#" onClick={(e) => { e.preventDefault(); handleFileDownload(url); handleFileLog('download') }}>
                                                                    <i className="ico icon-excel-download"></i> <Translate text={downloadLoader ? 'Downloading...' : 'download'} /> </a>
                                                                </li>
                                                            }

                                                            {
                                                                pandadocEnabled && (_.isEmpty(digitalSignResp?.status) || digitalSignResp?.status === 'draft') && CommonHelper.isValidSignatureFile(type) === true ? (
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleSignRequest(digitalSignResp?.id); }}>
                                                                        <i className="ico ico icon-sign"></i> <Translate text={'requestSignature'} /> </a>
                                                                    </li>
                                                                ) : (<></>)
                                                            }

                                                            {
                                                                !_.isEmpty(dealersettings.nylasAccessToken) && (<li> <a href="#" onClick={(e) => { e.preventDefault(); handleSendAsEmail(url); }}>
                                                                    <i className="ico icon-Mail"></i> <Translate text={'Send as Email'} /> </a>
                                                                </li>)
                                                            }

                                                            {
                                                                deleteFiles
                                                                    ?
                                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); deleteFile(documentID) }}>
                                                                        <i className="ico icon-delete"></i> <Translate text={'delete'} /> </a>
                                                                    </li>
                                                                    :
                                                                    <></>
                                                            }

                                                        </>
                                                }
                                            </>

                                        </ul>
                                    </div>
                                </div>
                            </>
                    }



                    <div className="file-manager-icon" onClick={(e) => { e.preventDefault(); console.log('file-->0', file) }}>
                        {
                            showFileIcon(isPrivacyFileEnabled ? 'lock' : type)
                        }
                    </div>

                    <div className="file-manager-data" >
                        <div className="file-manager-text">
                            <div className="file-manager-title">
                                <>
                                    {
                                        (isPrivacyFileEnabled)
                                            ?
                                            <>
                                                <a href="#" onClick={(e) => { e.preventDefault(); handleReAuthOpen(); }}>
                                                    {name}
                                                    {size ? ' (' + CommonHelper.showFileSize(size) + ')' : ''}
                                                </a>
                                            </>
                                            :
                                            <>
                                                {
                                                    pandadocEnabled && digitalSignResp?.status === 'completed' ?
                                                        <>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleSignedFileDownload(digitalSignResp?.id); handleFileLog('view'); }}>
                                                                {name}
                                                                {size ? ' (' + CommonHelper.showFileSize(size) + ')' : ''}
                                                            </a>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                (isMediaFile(type) === true)
                                                                    ?
                                                                    <a data-fancy-box={`file_data${documentID}`} href={url}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleFileLog('view');
                                                                            setImgViewer({
                                                                                show: true,
                                                                                images: url
                                                                            })
                                                                        }}
                                                                    >
                                                                        {name}
                                                                        {size ? ' (' + CommonHelper.showFileSize(size) + ')' : ''}
                                                                    </a>
                                                                    :
                                                                    <a href="#" onClick={(e) => { e.preventDefault(); window.open(url, '_blank'); handleFileLog('view'); }}>
                                                                        {name}
                                                                        {size ? ' (' + CommonHelper.showFileSize(size) + ')' : ''}
                                                                    </a>
                                                            }
                                                        </>
                                                }

                                            </>
                                    }
                                </>
                            </div>
                            <div className="file-manager-date"><span>{createdOn} </span> - {addedBy}</div>
                        </div>
                    </div>
                </div>
                {
                    pandadocEnabled && !_.isEmpty(digitalSignResp?.status) && digitalSignResp?.status !== 'draft' ? (
                        <div className="documentsign-timeline-status cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            console.log(digitalSignResp?.status)
                            if (!_.isEmpty(digitalSignResp?.events))
                                setShowHistoryModal({
                                    ...showHistoryModal,
                                    data: {
                                        ...digitalSignResp,
                                        events: digitalSignResp?.events?.length ? _.uniqBy(_.orderBy(digitalSignResp?.events, 'date.seconds', 'desc'), function (elem) { return [elem.name, elem.email].join(); }) : []
                                    },
                                    show: true,
                                });
                        }}>

                            <ul className="documentsign-timeline">
                                {
                                    timelineList.map((data, i) => {
                                        return <li key={i} className={data.index <= _.find(timelineList, { value: digitalSignResp?.status })?.index ? 'active' : ''}>{data.label}</li>
                                    })
                                }
                            </ul>
                        </div>
                    ) : (<></>)
                }


            </div >

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <PopUpModal show={showComposeModal.show}>
                <ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    title={showComposeModal.title}
                    type={showComposeModal.type}
                    emailData={showComposeModal.emailData}
                    userData={{ emailSignature: dealersettings.emailSignature ? dealersettings.emailSignature : '' }}
                    // sendLoader={sendLoader}
                    // handleSend={handleSend}
                    //handleReply={() => { }}
                    dealersettings={dealersettings}
                    file={[
                        {
                            uid: moment()._d.getTime().toString(),
                            name: name,
                            size: size,
                            isUploaded: true,
                            url,
                            path: url ? url.split('/o/')[1].split('?alt')[0].replaceAll('%2F', `/`) : '',
                        }
                    ]}
                    contact={contact}
                    enquiryID
                    contactID
                    companyID
                    serviceID
                    stockID
                    tradeinProID
                    fleetProID
                    dealerID
                ></ComposeEmail>
            </PopUpModal>
            <PopUpModal show={recpModel.show}>
                <ViewRecipient
                    {...recpModel}
                    clientUsers={clientUsers}
                    handleClose={(val, data) => {
                        if (val && data) {
                            const __settings = dealersettings?.client?.integrations?.filter(e => e.type === "pandadoc")?.[0]?.settings || {};
                            setSignModel({
                                show: true,
                                title: 'Edit Document',
                                document: data,
                                iframeID: moment().format('X'),
                                payload: val,
                                apikey: pandadocapikey,
                                ...__settings
                            })
                        }
                        setrecpModel({ show: false })
                    }}
                />
            </PopUpModal>
            <PopUpModal show={signModel.show}>
                <PandaDocEditor
                    {...signModel}
                    handleClose={() => {
                        setSignModel({ show: false });
                    }}
                />
            </PopUpModal>
            <PopUpModal show={showHistoryModal.show}>
                <TriggerHistoryModal
                    {...showHistoryModal}
                    show={showHistoryModal.show}
                    hideMailgun={true}
                    handleClose={() =>
                        setShowHistoryModal({ ...showHistoryModal, show: false })
                    }
                />
            </PopUpModal>

            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>
        </>
    )
};

export default FileInfo;
