import React, { useEffect, useState } from 'react';

import moment from 'moment'
import toast from 'toasted-notes';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import { ReactSelect } from '../../components';

const SubscriptionEnded = ({ dealersettings, paymentDue, paymentDueDate, accountSuspended, history, setUser, handleClientChange }) => {
	const [dealer, setDealer] = useState()

	useEffect(() => {
		return () => {
			if (history.action === 'POP') {
				history.go(1);
			}
		};
	}, [history]);

	useEffect(() => {
		document.getElementById('top-nav-bar') &&
			document.getElementById('top-nav-bar').classList.add('d-none');
		document.getElementById('main-container-fluid') &&
			document.getElementById('main-container-fluid').classList.add('p-0');
		return () => {
			document.getElementById('top-nav-bar') &&
				document.getElementById('top-nav-bar').classList.remove('d-none');
			document.getElementById('main-container-fluid') &&
				document.getElementById('main-container-fluid').classList.remove('p-0');
		};
	}, []);

	useEffect(() => {
		if (paymentDue === false || accountSuspended === false) {
			history.push('/dashboard')
		}
	}, [])

	const handleOpenSupport = (e) => {
		e.preventDefault()
		window.Intercom('show')
	}

	const swtichadmin = async (e) => {
		e.preventDefault();
		const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
		await userRef.set({
			arrayClientID: null,
			roleID: null,
			settingsID: null
		}, { merge: true })
		setUser({
			arrayClientID: null,
			roleID: null,
			settingsID: null
		})
		const dashboardRef = await window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`).get();
		if (dashboardRef.exists) {
			window.firebase.firestore().doc(`users/${localStorage.uid}/adminDashboardSettings/${dealersettings.oemID}`).set({
				...dashboardRef.data()
			}, { merge: true });
		}
		localStorage.removeItem(localStorage.uid);
		localStorage.setItem('defaultModule', 'enquiry')
		localStorage.removeItem('brandTheme')
		history.push('/admin/dealers')
	}

	const handleLogout = async (e) => {
		e.preventDefault()
		try {
			try {
				const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${localStorage.browserKey}`)
				await userRef.set({
					logoutDate: window.firebase.firestore.Timestamp.now(),
					isLogin: false,
					forceLogout: false,
					modifiedDate: window.firebase.firestore.Timestamp.now()
				}, { merge: true })
			}
			catch (err) {
				console.log(err);
			}
			const logRef = window.firebase.firestore().collection('auditLogs')
			const auditLog = {
				clientID: dealersettings.client.id ? dealersettings.client.id : null,
				documentID: logRef.doc().id,
				groupID: dealersettings.groupID ? dealersettings.groupID : null,
				imageURL: null,
				logBy: localStorage.uid,
				logDate: window.firebase.firestore.Timestamp.now(),
				notes: null,
				oemID: dealersettings.oemID ? dealersettings.oemID : null,
				platform: 'web',
				recordID: localStorage.browserKey ? localStorage.browserKey : null,
				regionID: dealersettings.regionID ? dealersettings.regionID : null,
				subtype: 'logout',
				type: 'authentication',
			}
			await logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
			await window.firebase
				.auth()
				.signOut()
			await window.firebase2
				.auth()
				.signOut();
			toast.closeAll();
			const brandTheme = localStorage.brandTheme;
			localStorage.clear();
			return <Redirect to={`${brandTheme ? `/login?theme=${brandTheme}` : '/login'}`} />;
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div
			className='subscription-ended middle-wrapper h-100 mt-0 subscription-bg'
			style={{
				minHeight: '90vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{/* <div className='spinner-loader h-100'>
				<div className='no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary'>
					<div className='no-data-img-wrap'>
						<img src={_images.error} width='350' alt='' />
					</div>
					<div className='no-data-txt-wrap'>
						<div className='no-data-content-head'>
							404 - The page you were looking for does not exist.
						</div>
						<div className='no-data-content'>
							You may have mistyped the address or the page has been moved.
						</div>
						<div className='no-data-content-head'>
							<a to='/dashboard' className='btn btn-primary'>
								Back to Dashboard
							</a>
						</div>
					</div>
				</div>
			</div> */}
			<div className='subscription-wrapper'>
				<div className='subscription-box'>
					<div className='subscription-brand'>
						<img src={_images.amsProLogoDark} alt='' width='100' />
					</div>
					<div className='subscription-img'>
						<img src={_images.subscriptionAlert} alt='' width='300' />
					</div>

					<div className='subscription-header'>
						<h2><Translate text={'Your account has been suspended for non-payment'} /></h2>
						<h3>
							<Translate text={'Your account was suspended on'} /> {!isEmpty(paymentDueDate) ? moment(paymentDueDate.toDate()).format(`dddd DD, YYYY`) : ''},
							<Translate text={'due to an outstanding balance'} />
						</h3>
						<h3>
							<Translate text={'If you wish to'} />&nbsp;<span className='subblue-text'><Translate text={'restore your account'} /></span>,
							<Translate text={'Please contact support'} />
						</h3>
					</div>
					<div className='subscription-button-wrap'>
						<a className='subscription-button' href='#' onClick={e => handleOpenSupport(e)}>
							<span><Translate text={'Contact Support'} /></span>
						</a>
					</div>
					{
						dealersettings?.clients?.length > 1 ? (<>
							<div className="subscription-divider"> <div className="subscription-text-divider">OR</div></div>

							<div className="subscription-dealer-switch form-style">
								<label htmlFor="">Switch Dealership </label>
								<div className="form-row">
									<div className="form-group col-md-12" style={{ textAlign: 'left' }}>
										<ReactSelect
											options={dealersettings.clients.map(r => {
												return {
													value: r.id,
													label: <>{r.name}{r.suspended === true ? (<div className="badge badge-pill badge-suspended ml-2">Suspended</div>) : (<></>)}</>,
													searchlabel: r.name
												}
											})}
											id={"date-type"}
											name={"clientID"}
											placeholder={'switch dealer'}
											onChange={(e) => {
												setDealer(e.value)
												CommonHelper.clearlocalFilter();
												handleClientChange(e.value);
												history.push('/');
											}}
											value={dealer || dealersettings.client.id}
											classNamePrefix={`cursor-pointer basic-select`}
											removeClearable={true}
										>
										</ReactSelect>
									</div>
								</div>
							</div>
						</>

						) : (<></>)
					}

					<div className="subscription-logout">
						<a href="#" onClick={e => handleLogout(e)}>
							<span><Translate text={'logout'} /></span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionEnded;
