/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import imageCompression from 'browser-image-compression';
/** COMPONENTS */
import {
    ImageViewer, PopUpModal, ImageCropHook, DropDownMenu, AutoGrabScan,
    ImageCanvas, MarkingInfo, RegScan, VinScan, VehicleScan, RegStatus
} from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { tipStatus } from '../../../services/enum'
import OwnedVehicles from '../../requirement/owned';
import TipInfoInputs from './infoInputs'
import AddNotes from '../../service/details/addNotes'
/** VIEW-MODELS */
import { objTradeIn, objMark, sidePhotosVM, tradeinSortOptions } from '../viewModel'
import { contactBasicDetailsVM } from '../../contacts/viewModel'
import { enquiryBasicTDVM } from '../../pipeline/viewModel'
import { firestoreDB, storageBucket } from '../../../services/helper';
import { objServiceBasicVM } from '../../service/viewModel'

//#region  ASSIGN VARIABLES
const vCondtions = [
    { active: true, value: 'Poor', label: 'Poor' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'V. Good', label: 'V. Good' },
    { active: true, value: 'New', label: 'New' }
]
const engineTypes = [
    { active: true, value: 'Petrol', label: 'Petrol' },
    { active: true, value: 'Diesel', label: 'Diesel' },
    { active: true, value: 'Electric', label: 'Electric' },
    { active: true, value: 'Hybrid', label: 'Hybrid' }
]
const transmissions = [
    { active: true, value: 'Automatic', label: 'Automatic' },
    { active: true, value: 'Manual', label: 'Manual' }
]
const vDoors = [
    { active: true, value: '2', label: '2' },
    { active: true, value: '3', label: '3' },
    { active: true, value: '4', label: '4' },
    { active: true, value: '5', label: '5' },
    { active: true, value: '6', label: '6' },
    { active: true, value: '7', label: '7' }
]
const wheelConditions = [
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Poor', label: 'Poor' }
]
const wheelConditionsNA = [
    { active: true, value: 'N/A', label: 'N/A' },
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Poor', label: 'Poor' }
]
const __status = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}>IN PROGRESS</div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}>PENDING</div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]

let tradeinOptionsDF = [];
//#endregion

class AddTradeIn extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.isContentLoader = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('tradeins');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.mandImages = 0;
        this.mandSidePhotos = [];
        this.unsubscribeTradeinNotes = null;
        this.state = {
            fields: Object.assign({}, objTradeIn),
            mark: Object.assign({}, objMark),
            notesHistory: Object.assign([]),
            errors: {},
            errorPhotos: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showCanvas: false,
            showRegScan: false,
            showVinScan: false,
            showDamage: false,
            imageURL: null,
            imageLoader: Object.assign({}),
            imageURLs: null,
            isLoadingInspection: false,
            addMake: false,
            addType: false,
            addGroup: false,
            addModel: false,
            vehicleScan: { show: false, type: null },
            autograbSearch: false,
            showRegStatus: false,
            showOwnedVehicles: false,
            imageViewer: {
                show: false,
                images: null
            }

        }
        const _evalexpert = Object.assign({}, props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.integrations &&
            props.dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

        this.regoScanEnabled = ((!_.isEmpty(props.dealersettings.client.moduleSettings) &&
            !_.isEmpty(props.dealersettings.client.moduleSettings.regoScan) &&
            props.dealersettings.client.moduleSettings.regoScan.enabled) ? true : false);

        this.evalexpertEnabled = localStorage.defaultModule !== 'oem' && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;

        const _autograb = Object.assign({}, props.dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;
        this.autograbEnabled = ((!_.isEmpty(props.dealersettings.client.moduleSettings) &&
            !_.isEmpty(props.dealersettings.client.moduleSettings.autograb) &&
            props.dealersettings.client.moduleSettings.autograb.enabled &&
            props.dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : _autoGrabEnabled);
        this.autograbRegCheckEnabled = ((!_.isEmpty(props.dealersettings.client.moduleSettings) &&
            !_.isEmpty(props.dealersettings.client.moduleSettings.autograb) &&
            props.dealersettings.client.moduleSettings.autograb.enabled &&
            props.dealersettings.client.moduleSettings.autograb.registrationCheck) ? true : _autoGrabEnabled);
    }


    //#region INSPECTION
    handleCanvasClose = (marks) => {
        this.setState({ showCanvas: false })
        let state = Object.assign({}, this.state.fields);
        if (!_.isEmpty(marks) || (!_.isEmpty(state.marks) && _.isEmpty(marks))) {

            if (JSON.stringify(Object.assign([], state.marks)) !== JSON.stringify(marks)) {
                //   console.log('handleCanvasClose', marks, state.marks)
                this.getInspectionSnapshot(marks);
            }
            state.marks = marks;
            this.setState({ fields: state })
        }
    }

    getInspectionSnapshot = async (_marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        var context = canvas.getContext('2d');

        var img = new Image();
        const { dealersettings } = this.props;
        if (dealersettings?.client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        img.onload = async (e) => {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await this.asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await this.loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            if (dataURL)
                this.saveInspectionToStorage(dataURL);

            return dataURL;
        }
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    handleRegScanClose = (data) => {
        if (!_.isEmpty(data)) {
            // if (this.evalexpertEnabled === true)
            //     this.bindEvalExpertTradeIn(data);
            // else
            //     this.bindTradeIn(data);
        }
        this.setState({ showRegScan: false })
    }

    handleVinScanClose = (data) => {
        if (!_.isEmpty(data)) {
            // if (this.evalexpertEnabled === true)
            //     this.bindEvalExpertTradeIn(data);            
            // else
            //     this.bindTradeIn(data);
        }
        this.setState({ showVinScan: false })
    }

    handleScanClose = (data) => {
        if (!_.isEmpty(data)) {
            if (this.evalexpertEnabled === true)
                this.bindEvalExpertTradeIn(data);
            else if (this.regoScanEnabled === true)
                this.bindTradeIn(data);
        }
        this.setState({ vehicleScan: { show: false, type: null } })
    }

    bindEvalExpertTradeIn = (data) => {
        let state = Object.assign({}, this.state.fields);

        if (data.BuildMth && data.BuildYr && data.BuildMth !== '0' && data.BuildYr !== '0')
            state.buildDate = `${data.BuildYr}-${data.BuildMth}-01`
        if (data.CompMth && data.CompYr && data.CompMth !== '0' && data.CompYr !== '0')
            state.complianceDate = `${data.CompYr}-${data.CompMth}-01`
        if (data.SvcMth && data.SvcYear && data.SvcMth !== '0' && data.SvcYear !== '0')
            state.lastService = `${data.SvcYear}-${data.SvcMth}-01`
        if (data.WtyMth && data.WtyYear && data.WtyMth !== '0' && data.WtyYear !== '0')
            state.warrantyExpiry = `${data.WtyYear}-${data.WtyMth}-01`

        if (data.Doors) state.doors = data.Doors;
        if (data.EngineNo) state.engineNo = data.EngineNo;
        if (data.Litres) state.engineSize = data.Litres;
        if (data.Ext_Colour) state.exteriorColor = data.Ext_Colour;
        if (data.Int_Colour) state.interiorColor = data.Int_Colour;
        if (data.ServiceHistory && _.isString(data.ServiceHistory)) state.serviceHistory = data.ServiceHistory;
        if (data.SpareKey && _.isString(data.SpareKey)) state.spareKey = data.SpareKey;
        if (data.customer && _.isString(data.customer)) state.owners = data.customer;
        if (data.FinanceName) state.financeCo = data.FinanceName;
        if (data.KM) state.mileage = data.KM;

        if (data.Make) state.make = data.Make;
        if (data.Model) state.model = data.Model;
        if (data.Year) state.year = data.Year;
        if (data.Body) state.type = data.Body;
        if (data.Registration) state.regNo = data.Registration;
        if (data.RegExpiry) state.regDate = moment(data.RegExpiry).format('YYYY-MM-DD')
        if (data.VIN) state.chassisNo = data.VIN;
        if (data.Reconditioning) state.reconditioning = this.stringToBoolean(data.Reconditioning);

        if (data.FinalPrice) state.offerPrice = parseFloat(data.FinalPrice);
        if (data.Recon) state.reconditioningCost = parseFloat(data.Recon);

        state.modelDescription = this.bindEvalModelDescription(data);

        if (data.Transmission) {
            if (data.Transmission.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.Transmission.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        if (data.Fuel) {
            if (data.Fuel.toLowerCase().indexOf('petrol') > -1)
                state.engineType = 'Petrol';
            else if (data.Fuel.toLowerCase().indexOf('diesel') > -1)
                state.engineType = 'Diesel';
            else if (data.Fuel.toLowerCase().indexOf('electric') > -1)
                state.engineType = 'Electric';
            else if (data.Fuel.toLowerCase().indexOf('hybrid') > -1)
                state.engineType = 'Hybrid';
        }

        // if (data.ImgURL && _.isString(data.ImgURL)) {
        //     const _images = Object.assign([], state.images);
        //     _images.push(data.ImgURL);
        //     state.images = _images ? _images : state.images;
        // }

        let _images = [];
        if (data.ImgURL && _.isString(data.ImgURL))
            _images.push(data.ImgURL);

        data.CarPics && data.CarPics.forEach((item) => {
            if (_.isString(item)) _images.push(item)
            else if (item.URL) _images.push(item.URL)
        })

        state.images = _images ? _images : state.images;

        if (data.EvalID) {
            state.evalexpertID = data.EvalID;
            let _tags = Object.assign([], state.tags);
            _tags = _tags.filter(e => e.type !== "evalexpert")
            _tags.push({ "refID": data.EvalID, "type": "evalexpert" })
            state.tags = _tags;
        }

        if (data.Notes) {
            state.notes = `${data.Notes}${(state.notes ? '\n' + state.notes : '')}`;
        }

        if (data.CarSpecs) {
            state.specifications = this.bindEvalSpecifications(data);
        }

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    bindEvalSpecifications = (data) => {
        let _description = null;
        if (data.CarSpecs && data.CarSpecs.Specifications) {
            data.CarSpecs.Specifications.forEach((item) => {
                if (item.Description)
                    _description = (_description ? _description : '') + '\n' + item.Description;
            })
        }
        //console.log('_description', _description)
        return _description ? _.trim(_description) : null;
    }

    bindEvalModelDescription = (data) => {

        let _description = null;
        if (data.Make)
            _description = data.Make + ' '
        if (data.Series)
            _description = (_description ? _description : '') + data.Series + ' '
        if (data.Model)
            _description = (_description ? _description : '') + data.Model + ' '
        if (data.Variant)
            _description = (_description ? _description : '') + data.Variant + ' '
        if (data.Cylinders)
            _description = (_description ? _description : '') + data.Cylinders + 'cyl '
        if (data.Body)
            _description = (_description ? _description : '') + data.Body + ' '
        if (data.Transmission)
            _description = (_description ? _description : '') + data.Transmission + ' '
        if (data.Drive)
            _description = (_description ? _description : '') + data.Drive + ' '

        return _description ? _.trim(_description) : null;

    }

    stringToBoolean = (_data) => {
        switch (_data.toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(_data);
        }
    }

    bindTradeIn = (data) => {
        let state = Object.assign({}, this.state.fields);


        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.modelDescription) state.modelDescription = data.modelDescription;
        if (data.yearOfManufacturer) state.year = data.yearOfManufacturer.toString();
        if (data.bodyStyle) state.type = data.bodyStyle;
        if (data.rego) state.regNo = data.rego;
        if (data.vin) state.chassisNo = data.vin;
        if (data.primaryColour) state.exteriorColor = data.primaryColour;
        if (data.engineCapacity) state.engineSize = data.engineCapacity;
        if (data.secondaryColour) state.interiorColor = data.secondaryColour;

        if (data.transmission) {
            if (data.transmission.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.transmission.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }

                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    handleAutoGrabClose = (data, _fields, _isexpanded) => {
        if (_isexpanded) this.handleModalClose()
        if (!_.isEmpty(data)) {
            this.bindAutoGrabTradeIn(data, _fields);
        }
        this.setState({ autograbSearch: false })
    }

    bindAutoGrabTradeIn = (_data, _fields) => {
        //console.log('bindAutoGrabTradeIn', _data)

        let state = Object.assign({}, this.state.fields);
        let data = Object.assign({}, _data?.vehicle);

        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.body_type) state.type = data.body_type;
        if (data.year) state.year = data.year.toString();

        if (data.title)
            state.modelDescription = data.title;
        else state.modelDescription = CommonHelper.bindAutoGrabDescription(data);

        if (data.capacity_cc) state.engineSize = data.capacity_cc.toString();
        if (data.num_doors) state.doors = data.num_doors.toString();
        if (data.engine_type) state.engineType = data.engine_type;

        if (data.drive_type) state.driveType = data.drive_type;
        if (data.fuel_type) state.fuelType = data.fuel_type;
        if (data.num_cylinders) state.cylinders = data.num_cylinders;
        if (data.num_gears) state.gears = data.num_gears;
        if (data.num_seats) state.seats = data.num_seats;
        if (data.power_kw) state.powerKw = data.power_kw;
        if (data.range) state.range = data.range;
        if (data.series) state.series = data.series;
        if (data.torque_nm) state.torqueNm = data.torque_nm.toString();
        if (data.transmission) state.transmissionType = data.transmission;
        if (data.transmission_type) state.transmissionType = data.transmission_type;
        if (data.wheelbase) state.wheelbase = data.wheelbase;
        if (data.badge) state.badge = data.badge;
        if (data.region) state.region = data.region;
        // if (data.release_month && data.release_year) {
        //     state.buildDate = `${data.release_year}-${CommonHelper.limit(data.release_month, '12')}-01`
        // }

        if (data.regNo) state.regNo = data.regNo; else if (_fields?.regNum) state.regNo = _fields.regNum;
        if (data.vinNo) state.chassisNo = data.vinNo; else if (_fields?.vinNum) state.chassisNo = _fields.vinNum;
        if (_data.colour) state.exteriorColor = _data.colour;

        if (data.compliance_plate) state.complianceDate = data.compliance_plate + '-01';
        if (data.registration_expiry) state.regDate = data.registration_expiry;
        if (data.plate_number) state.regNo = data.plate_number;
        if (data.vin) state.chassisNo = data.vin;
        if (data.registration_status) state.regStatus = data.registration_status;
        if (data.state) state.regState = data.state;
        if (data.engineNo) state.engineNo = data.engineNo;

        if (data.transmission_type) {
            if (data.transmission_type.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.transmission_type.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        //state.specifications = CommonHelper.bindAutoGrabSpecifications(data);

        if (data.id) {
            state.autograbID = data.id;
            // let _tags = Object.assign([], state.tags);
            // _tags = _tags.filter(e => e.type !== "autograb")
            // _tags.push({ "refID": data.id, "type": "autograb" })
            // state.tags = _tags;
        }

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }

                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    handleRegStatusClose = (data) => {
        if (!_.isEmpty(data)) {
            //console.log('handleRegStatusClose', data)
            let state = Object.assign({}, this.state.fields);
            if (data.compliance_plate) state.complianceDate = data.compliance_plate + '-01';
            if (data.registration_expiry) state.regDate = data.registration_expiry;
            if (data.plate_number) state.regNo = data.plate_number;
            if (data.vin) state.chassisNo = data.vin;
            if (data.registration_status) state.regStatus = data.registration_status;
            if (data.state) state.regState = data.state;
            this.setState({ fields: state })
        }
        this.setState({ showRegStatus: false })
    }
    // showDamageCost = () => {
    //     let marks = Object.assign([], this.state.fields.marks)
    //     const { dealersettings } = this.props;
    //     const currencySymbol = ((!_.isEmpty(dealersettings) &&
    //         !_.isEmpty(dealersettings.client) &&
    //         !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    //     if (!_.isEmpty(marks)) {
    //         var total = 0;
    //         for (var i = 0; i < marks.length; i++) {
    //             total = total + (marks[i].price ? marks[i].price : 0);
    //         }
    //         return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
    //     }
    //     else {
    //         return <NumberFormat value={0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
    //     }
    // }

    handleDamageClose = () => {
        this.setState({ showDamage: false })
    }
    //#endregion

    //#region PAGE LOAD
    async loadTradeIn(props) {
        const { show, dealersettings, docID, enquiryID, enquiry, contact, tradeinProID, mandatoryFields, serviceID, service } = props;
        const fields = Object.assign({}, objTradeIn);
        if (show === true && docID && (enquiryID || tradeinProID || serviceID)) {
            this.isContentLoader = true;
            const tradeinPlusEnabled = (enquiryID || serviceID) && ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.moduleSettings) &&
                !_.isEmpty(dealersettings.client.moduleSettings.tradeinPlus) &&
                dealersettings.client.moduleSettings.tradeinPlus.enabled) ? true : false);

            this.newDocID = docID;
            const refData = await firestoreDB(this.props.dealersettings).firestore().collection('tradeins').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                const _marks = [];
                if (!_.isEmpty(fields.marks)) {
                    fields.marks.map((mark) => {
                        const _mark = Object.assign({}, objMark);
                        for (let [key, value] of Object.entries(mark)) {
                            _mark[key] = value;
                        }
                        _marks.push(_mark)
                    })
                    fields.marks = _marks;
                }

                if (fields.sent && fields.evalexpertID) {
                    this.getDetailsFromEvalExpert(docID, fields);
                }
                else if (fields.sent && tradeinPlusEnabled && dealersettings.tradeinplusURL) {
                    this.getDetailsFromTradeInPlus(docID, fields);
                }
                else {
                    this.isContentLoader = false;
                }

                this.unsubscribeTradeinNotes = firestoreDB(this.props.dealersettings).firestore().collection(`tradeins/${docID}/notes`)
                    .onSnapshot(this.onNotesCollectionUpdate);

                this.setState({ fields: fields });

            } else {
                console.error("No such document2!");
            }

        }
        else if (show === true && (!docID) && (enquiryID || tradeinProID || serviceID)) {
            this.newDocID = this.ref.doc().id;

            if (!_.isEmpty(contact)) {
                let _contact = Object.assign({}, contactBasicDetailsVM);
                for (let [key, value] of Object.entries(contact)) {
                    if (_contact.hasOwnProperty(key))
                        _contact[key] = value;
                }
                fields.contact = _contact;
                fields.contactID = _contact.documentID;
            }

            if (!_.isEmpty(enquiry)) {
                let _enquiry = Object.assign({}, enquiryBasicTDVM);
                for (let [key, value] of Object.entries(enquiry)) {
                    if (_enquiry.hasOwnProperty(key))
                        _enquiry[key] = value;
                }
                fields.enquiry = _enquiry;
                fields.enquiryID = _enquiry.documentID;
            }

            if (!_.isEmpty(service)) {
                let _service = Object.assign({}, objServiceBasicVM);
                for (let [key, value] of Object.entries(service)) {
                    if (_service.hasOwnProperty(key))
                        _service[key] = value;
                }
                fields.service = _service;
                fields.serviceID = _service.documentID;
            }

            if (tradeinProID)
                fields.tradeinProID = tradeinProID;

            this.unsubscribeTradeinNotes = firestoreDB(this.props.dealersettings).firestore().collection(`tradeins/${this.newDocID}/notes`)
                .onSnapshot(this.onNotesCollectionUpdate);

            this.setState({ fields: fields });
        }


        if (show === true && (enquiryID || tradeinProID || serviceID) && dealersettings && dealersettings.client) {
            const setttings = dealersettings.client.settings;
            const tradeinPro_setttings = dealersettings.client.tradeinPro;
            const service_setttings = dealersettings.client.services;
            const brands = dealersettings.allMakeModels;

            tradeinOptionsDF = [];
            if (setttings.tradeinOptionsDF)
                tradeinOptionsDF = setttings.tradeinOptionsDF;

            if (localStorage.defaultModule === 'service' && serviceID) {
                if (!_.isEmpty(service_setttings?.mandatoryFields?.tradein))
                    this.mandatoryFields = service_setttings.mandatoryFields.tradein.split(',');

                if (dealersettings?.client?.clientSettings?.serviceTradein?.mandImages)
                    this.mandImages = dealersettings?.client?.clientSettings?.serviceTradein.mandImages;

                if (dealersettings?.client?.clientSettings?.serviceTradein?.mandSidePhotos)
                    this.mandSidePhotos = dealersettings?.client?.clientSettings?.serviceTradein.mandSidePhotos;
            }
            else if (localStorage.defaultModule === 'tradeinPro' && tradeinProID) {
                if (!_.isEmpty(tradeinPro_setttings?.mandatoryFields?.tradein))
                    this.mandatoryFields = tradeinPro_setttings.mandatoryFields.tradein.split(',');

                if (dealersettings?.client?.clientSettings?.tradeinPro?.mandImages)
                    this.mandImages = dealersettings?.client?.clientSettings?.tradeinPro.mandImages;

                if (dealersettings?.client?.clientSettings?.tradeinPro?.mandSidePhotos)
                    this.mandSidePhotos = dealersettings?.client?.clientSettings?.tradeinPro.mandSidePhotos;
            }
            else {
                //mandatoryFields
                if (enquiryID && !_.isEmpty(setttings?.mandatoryFields?.tradein))
                    this.mandatoryFields = setttings.mandatoryFields.tradein.split(',');
                //mandImages
                if (enquiryID && dealersettings?.client?.clientSettings?.salesTradein?.mandImages)
                    this.mandImages = dealersettings?.client?.clientSettings?.salesTradein.mandImages;
                //mandSidePhotos
                if (enquiryID && dealersettings?.client?.clientSettings?.salesTradein?.mandSidePhotos)
                    this.mandSidePhotos = dealersettings?.client?.clientSettings?.salesTradein.mandSidePhotos;
            }


            if (!_.isEmpty(mandatoryFields))
                this.mandatoryFields = _.union(this.mandatoryFields, mandatoryFields);

            const defaultVINScan = (!_.isEmpty(dealersettings?.client?.clientSettings) &&
                dealersettings.client?.clientSettings?.defaultVINScan ? true : false);

            if (show === true && (!docID)) {
                if (this.evalexpertEnabled) {
                    if (defaultVINScan)
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'vin' } }), 500);
                    else
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'reg' } }), 500);
                }
                else if (this.autograbEnabled) {
                    setTimeout(() => this.setState({ autograbSearch: true }), 500);
                }
                else if (this.regoScanEnabled) {
                    if (defaultVINScan)
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'vin' } }), 500);
                    else
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'reg' } }), 500);
                }

            }

            const years = [];
            const makes = [];
            const types = [];
            const models = [];
            const groups = [];
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            if (fields.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === fields.make)[0]) ? makes.filter(m => m.value === fields.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }
                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });

            }

            this.setState({
                makes: makes,
                years: years,
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            }, () => { this.errorOnLoad() });

        }
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let errorClass = 'input_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);


            let fields = Object.assign({}, this.state.fields);
            for (let [key] of Object.entries(fields)) {
                if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {
                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                        errors[key] = errorClass;
                    }
                }
            }

            this.setState({ errors: errors });

            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

        }

    }

    getDetailsFromTradeInPlus = async (docID, _fields) => {
        try {

            const ObjectToSend = {
                Name: docID
            };

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(ObjectToSend),
            };

            const response = await fetch(
                this.props.dealersettings.tradeinplusURL + 'api/TradeInAMS/Action/GetTradeInByAMSProId',
                requestOptions,
            );

            const respjson = await response.json();

            if (respjson.success) {
                //console.log(respjson);
                var tradein = CommonHelper.convertTradeInMappingVM(respjson.data, _fields);
                this.isContentLoader = false;
                this.setState({ fields: tradein });
                //this.convertMappingVM(respjson.data, _fields);
            } else {
                this.isContentLoader = false; this.forceUpdate();
                //console.log('getDetailsFromTradeInPlus', respjson);
            }
        } catch (error) {
            console.log(error);
        }
    }

    getDetailsFromEvalExpert = async (docID, _fields) => {
        try {

            const evalexpertGet = window.firebase.functions().httpsCallable('evalexpert-get');
            evalexpertGet(_fields).then((_response) => {

                if (_response && _response.data && _response.data.success && _response.data.data) {
                    //console.log('evalexpert-get--', _response.data);
                    var tradein = CommonHelper.convertEvalMappingVM(_response.data.data[0], _fields);
                    this.isContentLoader = false;
                    this.setState({ fields: tradein });
                } else { this.isContentLoader = false; this.forceUpdate(); }

            }).catch((error) => {
                this.isContentLoader = false; this.forceUpdate();
                Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
            console.log(error);
        }
    }

    convertMappingVM = (tradeinplus, tradein) => {
        tradein = Object.assign({}, tradein);
        if (!_.isEmpty(tradeinplus)) {
            tradein.make = tradeinplus['VehicleMake'];
            tradein.model = tradeinplus['VehicleModel'];
            tradein.modelDescription = tradeinplus['VehicleModelDescription'];
            tradein.notes = tradeinplus['Notes'];
            tradein.year = tradeinplus['VehicleYear'] ? tradeinplus['VehicleYear'].toString() : '';
            tradein.regNo = tradeinplus['RegNum'];
            tradein.regDate = tradeinplus['RegDate'] && tradeinplus['RegDate'] !== 'n/a' ? tradeinplus['RegDate'] : null; //DATE
            tradein.chassisNo = tradeinplus['ChassisNum'];
            tradein.engineNo = tradeinplus['EngineNum'];
            tradein.transmission = tradeinplus['Gear'];
            tradein.doors = tradeinplus['Doors'];
            tradein.mileage = tradeinplus['MileageKM'];
            tradein.exteriorColor = tradeinplus['VehicleColor'];
            tradein.interiorColor = tradeinplus['Interior'];
            tradein.trim = tradeinplus['Trim'];
            tradein.spareTyre = tradeinplus['SpareTyre'];
            tradein.massageVentilated = tradeinplus['MassageVentilated'] === 'True' ? true : false;
            tradein.camera = tradeinplus['Camera'] === 'True' ? true : false;
            tradein.contrastStiching = tradeinplus['ContrastStiching'] === 'True' ? true : false;
            tradein.woodSteering = tradeinplus['WoodSteering'] === 'True' ? true : false;
            tradein.sunroof = tradeinplus['Sunroof'] === 'True' ? true : false;
            tradein.owners = tradeinplus['Owners'];
            tradein.outstandingPayments = tradeinplus['OutstandingPayments'] === 'True' ? true : false;
            tradein.warranty = tradeinplus['Warranty'] === 'True' ? true : false;
            tradein.financeCo = tradeinplus['FinanceCo'];
            tradein.serviceContFrom = tradeinplus['ServiceContFrom'] && tradeinplus['ServiceContFrom'] !== 'n/a' ? tradeinplus['ServiceContFrom'] : null; //DATE
            tradein.serviceContTo = tradeinplus['ServiceContTo'] && tradeinplus['ServiceContTo'] !== 'n/a' ? tradeinplus['ServiceContTo'] : null; //DATE
            tradein.reconditioning = tradeinplus['Reconditioning'] === 'True' ? true : false;
            tradein.inspectionSnapshot = tradeinplus['VehicleImageURL'];
            tradein.tyreAge = tradeinplus['TyreAge'];
            tradein.financed = tradeinplus['Financed'] === 'True' ? true : false;
            tradein.lastService = tradeinplus['LastService'] && tradeinplus['LastService'] !== 'n/a' ? tradeinplus['LastService'] : null; //DATE
            //tradein.status = tradeinplus['Status'];
            tradein.engineSize = tradeinplus['EngineSize'];
            tradein.type = tradeinplus['BodyType'];
            tradein.engineType = tradeinplus['EngineType'];
            tradein.frontLeftWheel = tradeinplus['FrontLeftWheel'];
            tradein.frontRightWheel = tradeinplus['FrontRightWheel'];
            tradein.rearLeftWheel = tradeinplus['RearLeftWheel'];
            tradein.rearRightWheel = tradeinplus['RearRightWheel'];
            tradein.vehicleCondition = tradeinplus['VehicleCondition'];
            tradein.buildDate = tradeinplus['BuildDate'] && tradeinplus['BuildDate'] !== 'n/a' ? tradeinplus['BuildDate'] : null; //DATE
            tradein.complianceDate = tradeinplus['ComplianceDate'] && tradeinplus['ComplianceDate'] !== 'n/a' ? tradeinplus['ComplianceDate'] : null; //DATE
            tradein.spareKey = tradeinplus['SpareKey'];
            tradein.logBooks = tradeinplus['LogBooks'];
            tradein.serviceHistory = tradeinplus['ServiceHistory'];
            tradein.cargoBlind = tradeinplus['CargoBlind'];
            tradein.headRests = tradeinplus['HeadRests'];
            tradein.sdCard = tradeinplus['SDCard'];
            tradein.warrantyExpiry = tradeinplus['WarrantyExpiryDate'] && tradeinplus['WarrantyExpiryDate'] !== 'n/a' ? tradeinplus['WarrantyExpiryDate'] : null; //DATE
            tradein.offerPrice = tradeinplus['FinalApprovedPrice'];
            tradein.isDeletedFromTradeIn = (tradeinplus['IsDeleted'] === 'True' || tradeinplus['IsDeleted'] === true) ? true : false;

            if (_.isArray(tradeinplus['TradeInImages'])) {
                const _images = [];
                tradeinplus['TradeInImages'].filter(e => e.ImageCategory === 'Detail').forEach((ele) => {
                    _images.push(ele.ImageURL);
                });

                tradein.images = _images ? _images : tradein.images;
            }
            // console.log('convertMappingVM', tradein, tradeinplus)
            this.setState({ fields: tradein });
        }

    }


    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadTradeIn(this.props);

    }
    componentWillUnmount() {
        this.unsubscribeTradeinNotes && this.unsubscribeTradeinNotes();
        this.setState(
            {
                fields: Object.assign({}, objTradeIn),
                mark: Object.assign({}, objMark),
                errors: {},
                makes: [],
                models: [],
                types: [],
                years: [],
                cropShow: {
                    show: false,
                    clsActive: '',
                    src: null,
                    id: '',
                    type: '',
                    ext: '',
                    title: ''
                },
                showCanvas: false,
                showRegScan: false,
                showVinScan: false,
                showDamage: false,
                imageURL: null,
                imageURLs: null

            }
        )
    }

    onNotesCollectionUpdate = (querySnapshot) => {

        let notesHistory = [];
        querySnapshot.forEach((_req) => {
            const req = Object.assign({}, _req.data());
            req.documentID = _req.id;
            notesHistory.push(req);
        });

        notesHistory = _.orderBy(notesHistory, ['addedDate'], ['desc'])

        this.setState({ notesHistory: notesHistory });
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = value;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str;
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onValueChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let fields = Object.assign({}, this.state.fields);
        fields[name] = e.value;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let fields = Object.assign({}, this.state.fields);
        fields[name] = e.floatValue;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {

            const { dealersettings } = this.props;
            let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinOptions))
                ? dealersettings.client.tradeinOptions :
                Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
            dynamicDetailList = _.union(dynamicDetailList, ['images', 'sidePhotos', 'marks'])
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0 && dynamicDetailList.includes(key)) {
                if (objTradeIn.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {

                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {

                    errors[key] = errorClass;
                }
                else
                    errors[key] = '';
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

    }

    handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })


        let state = Object.assign({}, this.state.fields);
        state['interests'] = value;
        this.setState({ fields: state });
    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ fields: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ fields: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            fields: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    //#endregion

    //#region DATEPICKER
    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleDateValueChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.fields);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ fields: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.fields);
            state[name] = null;
            this.setState({ fields: state });
        }
    }


    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }

    //#endregion

    //#region IMAGE UPLOAD

    saveInspectionToStorage = (imageBase64) => {
        this.setState({ isLoadingInspection: true })
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/inspections/snapshot.png`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/inspections`).child('snapshot.png').getDownloadURL()
                    .then(dataURL => {
                        //console.log(dataURL);
                        let state = Object.assign({}, this.state.fields);
                        state['inspectionSnapshot'] = dataURL;
                        this.setState({ fields: state, isLoadingInspection: false })
                    })
            })
    };

    saveBaseImageToStorage = async (imageBase64, id, ext, fileID) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }
        let promises = [];
        let storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/${uid}`);
        promises.push(
            storageRef.putString(imageBase64, 'data_url').then((snapshot) => {
                return snapshot.ref.getDownloadURL()
            })
        );
        const snapshots = await Promise.all(promises)

        let state = Object.assign({}, this.state.fields);
        if (fileID) {
            let sidePhotos = Object.assign({}, state.sidePhotos);
            let imageLoader = Object.assign({}, this.state.imageLoader)
            sidePhotos[fileID] = !_.isEmpty(snapshots) ? snapshots[0] : null;;
            state['sidePhotos'] = sidePhotos;
            imageLoader[fileID] = null;
            this.setState({ fields: state, imageLoader: imageLoader })
        }
        else {
            let fileURLS = Object.assign([], state[id]);
            if (!_.isEmpty(snapshots)) fileURLS.push(snapshots[0]);
            state[id] = Object.assign([], fileURLS);
            this.setState({ fields: state, imageURL: null }, () => { this.errorChange(id); });
        }

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
    };

    saveImagesToStorage = async (filez, id, fileID, heicFile) => {
        let promises = [];

        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            files.push(file);
        }
        if (!heicFile) {
            if (fileID) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                imageLoader[fileID] = fileID;
                this.setState({ imageLoader: imageLoader });
            }
            else
                this.setState({ imageURLs: files });
        }


        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }
        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)

        let state = Object.assign({}, this.state.fields);
        let fileURLS = Object.assign([], state.images);
        let sidePhotos = Object.assign({}, state.sidePhotos);
        let imageLoader = Object.assign({}, this.state.imageLoader)

        if (fileID) {
            sidePhotos[fileID] = snapshots ? snapshots[0] : null;
            state['sidePhotos'] = sidePhotos;
            imageLoader[fileID] = null;
        }
        else {
            snapshots.forEach(url => {
                fileURLS.push(url);
            })
            state.images = fileURLS
        }

        this.setState({ fields: state, imageURLs: null, imageLoader: imageLoader }, () => { this.errorChange(id); });

        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

    }

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    handleSideImageDel = (fileID) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {

                let state = Object.assign({}, this.state.fields);
                let sidePhotos = Object.assign({}, state.sidePhotos);

                if (fileID)
                    sidePhotos[fileID] = null;

                state.sidePhotos = sidePhotos;
                this.setState({ fields: state })

                //console.log('handleImageDel', state, sidePhotos)
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }
    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, ext, fileID } = cropShow;

        this.saveBaseImageToStorage(src, id, ext, fileID);

        if (fileID) {
            let imageLoader = Object.assign({}, this.state.imageLoader)
            imageLoader[fileID] = fileID;
            this.setState({
                imageLoader: imageLoader,
                cropShow: { show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '', fileID: null }
            });
        }
        else {
            this.setState({
                imageURL: src,
                cropShow: { show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '', fileID: null }
            });
        }
    }

    onSelectFile = async (e, type, id, title, fileID) => {
        const files = e.target.files;

        if (files && files.length > 1) {

            const imageFile = [];
            if (fileID) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                imageLoader[fileID] = fileID;
                this.setState({ imageLoader: imageLoader });
            }
            else
                this.setState({ imageURLs: Object.assign([], files) });

            for (let [key, file] of Object.entries(files)) {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Maximum size of each file is 10MB'), '', 'info')
                    return;
                }

                if (file.name.toLowerCase().includes('.heic')) {
                    let _file = await CommonHelper.heic2Image(file);
                    imageFile.push(_file)
                } else if (filesize > 1) {
                    //console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    try {
                        const compressedFile = await imageCompression(file, options);
                        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                        imageFile.push(compressedFile)

                    } catch (error) {
                        console.log(error);
                    }
                }
                else
                    imageFile.push(file)
            }

            this.saveImagesToStorage(imageFile, id, fileID)

        } else if (files && files.length === 1) {

            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = files[0];
            if (file.name.toLowerCase().includes('.heic')) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                if (fileID) {
                    imageLoader[fileID] = fileID;
                    this.setState({ imageLoader: imageLoader });
                }
                else {
                    this.setState({ imageURL: _images.novehicle });
                }
                file = await CommonHelper.heic2Image(file);
                imageLoader[fileID] = null;
                this.setState({
                    imageURL: null,
                    imageLoader: imageLoader,
                })
            }
            reader.onloadend = () => {
                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title,
                        fileID: fileID
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    //#endregion

    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let errorPhotos = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        const { dealersettings } = this.props;
        let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinOptions))
            ? dealersettings.client.tradeinOptions :
            Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        dynamicDetailList = _.union(dynamicDetailList, ['images', 'sidePhotos', 'marks'])
        this.mandatoryFields && this.mandatoryFields.map(key => {
            if (dynamicDetailList.includes(key)) {
                if (objTradeIn.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        })

        // for (let [key] of Object.entries(fields)) {

        //     if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0 && dynamicDetailList.includes(key)) {
        //         if (objTradeIn.hasOwnProperty(key) && (_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
        //             formIsValid = false;
        //             errors[key] = errorClass;
        //         }
        //         else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
        //             (_.isEmpty(fields.dynamicFields) ||
        //                 (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
        //             )) {
        //             formIsValid = false;
        //             errors[key] = errorClass;
        //         }
        //     }
        // }

        if (this.mandImages && this.mandImages > 0) {
            let _allImages = CommonHelper.bindAllImages(fields.images, fields.sidePhotos)
            if (_.isEmpty(_allImages) || (!_.isEmpty(_allImages) && _allImages.length < this.mandImages)) {
                formIsValid = false;
                errors['images'] = errorClass;
            }
        }

        this.mandSidePhotos && this.mandSidePhotos.forEach((key) => {
            if (_.isEmpty(fields.sidePhotos) || (!_.isEmpty(fields.sidePhotos) && _.isEmpty(fields?.sidePhotos[key]))) {
                formIsValid = false;
                errorPhotos[key] = 'border-error';
            }
        });
        // console.log('errors', errors)
        // console.log('this.mandatoryFields', this.mandatoryFields)
        // console.log('dynamicDetailList', dynamicDetailList, tradeinOptionsDF)
        this.setState({ errors: errors, errorPhotos: errorPhotos });

        if (formIsValid === true) {
            this.addTradeIn(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id === 'images') id = 'tradein-photos'
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };

    addTradeIn = (e) => {
        this.isLoading = true;
        let objData = Object.assign({}, this.state.fields);
        const { dealersettings, enquiryID, tradeinProID, docID, serviceID } = this.props;
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        //if (objData.evalexpertID) objData.sent = true;

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (propName !== 'images') delete objData[propName];
            }
        }

        if (!objData.enquiryID && enquiryID) objData.enquiryID = enquiryID;
        if (!objData.serviceID && serviceID) objData.serviceID = serviceID;

        if (!objData.tradeinProID && tradeinProID)
            objData.tradeinProID = tradeinProID;

        if (!objData.clientID || localStorage.defaultModule === 'oem')
            objData = CommonHelper.setClientOrGroupOrRegionOrOemID(objData, dealersettings, null, objData.clientID, true)

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        if (objData.linkStatus === 'sent') objData.linkStatus = 'updated'

        const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;
            toast.notify('Trade-In saved successfully', {
                duration: 2000
            })

            let _makeModel = (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '') + (objData.regNo ? ', ' + objData.regNo : '');
            let _logNote = 'Trade-In added'
            if (docID)
                _logNote = 'Trade-In updated'

            let _ID = (objData.enquiryID ? objData.enquiryID : objData.serviceID ? objData.serviceID : objData.tradeinProID)
            let _subType = (objData.serviceID ? 'service' : objData.tradeinProID ? 'tradeinPro' : 'enquiry')

            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, _ID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', _subType);

            this.handleModalClose(this.newDocID, objData);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });


    };


    handleModalClose = (ID, objData) => {
        this.setState({ fields: Object.assign({}, objTradeIn) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData);
    };

    //#endregion

    //#region OWNED VEHICLE
    handleOwnedVehicleOpen = () => {
        this.setState({ showOwnedVehicles: true });
    }

    handleOwnedVehicleClose = (_selectedVehicle) => {
        if (!_.isEmpty(_selectedVehicle)) {
            const { dealersettings } = this.props;

            const _requirement = Object.assign({}, this.state.fields);
            const objReqData = Object.assign({}, _selectedVehicle);
            for (let [key, value] of Object.entries(objReqData)) {
                if (_requirement.hasOwnProperty(key) && value)
                    _requirement[key] = value;

                if (key === 'vinNo' && value)
                    _requirement['chassisNo'] = value;

                if (key === 'registrationExpiry' && value)
                    _requirement['regDate'] = moment.unix(value.seconds).format('YYYY-MM-DD');

                if (key === 'warrantyExp' && value)
                    _requirement['warrantyExpiry'] = moment.unix(value.seconds).format('YYYY-MM-DD');


            }
            this.setState({ fields: _requirement });

            if (_selectedVehicle.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
                const { makes } = this.state;
                const types = [];
                const models = [];
                const groups = [];
                if (_selectedVehicle.make) {
                    let _models = !_.isEmpty(makes.filter(m => m.value === _selectedVehicle.make)[0]) ? makes.filter(m => m.value === _selectedVehicle.make)[0].models : [];
                    _models && _models.filter((model, index) => {
                        if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                            types.push({
                                value: model.type,
                                label: model.type,
                                active: _.isBoolean(model.active) ? model.active : true,
                            });
                        }

                        if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                            groups.push({
                                value: model.group,
                                active: _.isBoolean(model.active) ? model.active : true,
                                label: model.group
                            });
                        }
                    })

                    _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                        models.push({
                            value: data.value,
                            label: CommonHelper.displayModelName(data),
                            active: _.isBoolean(data.active) ? data.active : true,
                        });
                    });
                }
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });
            }
        }
        this.setState({ showOwnedVehicles: false });
    }
    //#endregion
    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            fields: {
                ...this.state.fields,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    handleStatusChange = (status) => {
        //   console.log('handleStatusChange', status)
        const { dealersettings } = this.props;
        let modifiedBy = dealersettings ? dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(this.newDocID);
        updateRef.update(
            {
                status: status,
                modifiedBy: modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: modifiedDate
            }
        );

        let state = Object.assign({}, this.state.fields);
        state['status'] = status;
        this.setState({ fields: state });
    };

    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = e.value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(_data.name); });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion


    //#region  ADD VEHICLE MANUAL
    handleAddMakeEnable = () => {
        this.setState({
            addMake: true
        })
        setTimeout(() => {
            let elem = document.getElementById('make')
            elem.focus()
        }, 100)
    }

    handleAddMakeDisable = () => {
        this.setState({
            addMake: false,
            errors: {
                ...this.state.errors,
                ['make']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['make']: ''
            }
        })
    }

    handleAddGroupEnable = () => {
        this.setState({
            addGroup: true
        })
        setTimeout(() => {
            let elem = document.getElementById('group')
            elem.focus()
        }, 100)
    }

    handleAddGroupDisable = () => {
        this.setState({
            addGroup: false,
            errors: {
                ...this.state.errors,
                ['group']: 'input_error'
            },
            stock: {
                ...this.state.stock,
                ['group']: ''
            }
        })
    }

    handleAddTypeEnable = () => {
        this.setState({
            addType: true
        })
        setTimeout(() => {
            let elem = document.getElementById('type')
            elem.focus()
        }, 100)
    }

    handleAddTypeDisable = () => {
        this.setState({
            addType: false,
            errors: {
                ...this.state.errors,
                ['type']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['type']: ''
            }
        })
    }

    handleAddModelEnable = () => {
        this.setState({
            addModel: true
        })
        setTimeout(() => {
            let elem = document.getElementById('model')
            elem.focus()
        }, 100)
    }

    handleAddModelDisable = () => {
        this.setState({
            addModel: false,
            errors: {
                ...this.state.errors,
                ['model']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['model']: ''
            }
        })
    }

    handleVehicleScan = (_type) => {

        if (this.evalexpertEnabled)
            this.setState({ vehicleScan: { show: true, type: _type } })
        else if (this.autograbEnabled)
            this.setState({ autograbSearch: true });
        else if (this.regoScanEnabled)
            this.setState({ vehicleScan: { show: true, type: _type } })

    }

    //#endregion

    //#region CHANGE EVENT Notes  
    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions, tradeinID) => {
        if (noteTxt) {

            const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
            const _currentDate = window.firebase.firestore.Timestamp.now();
            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: window.firebase.firestore().collection('tradeins').doc().id,
                notes: noteTxt,
                tradeinID: this.newDocID,
                mentions: Object.assign([], convertedIDs),
                addedBy: _userID,
                addedDate: _currentDate,
                addedFrom: 'web'
            }

            const updateRef = firestoreDB(this.props.dealersettings).firestore().doc(`tradeins/${this.newDocID}/notes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {
                toast.notify('notes added successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }
    //#endregion

    render() {
        //console.log('R E N D E R - T R A D E - I N ', this.state.fields.images);
        const {
            sent,
            images, marks, status, offerPrice,
            tradeinProID, evalexpertID, sidePhotos
        } = this.state.fields;

        const { addMake, addType, addModel, addGroup, imageURL, imageURLs, vehicleScan, notesHistory, showNotes,
            errorPhotos, imageLoader, showOwnedVehicles, fields, autograbSearch, imageViewer } = this.state
        const { isDeivered, show, dealersettings, isReadOnlyView, enquiryID, serviceID, clientUsers,
            groupUsers } = this.props;


        const _moduleSettings = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

        const tradeinPlusEnabled = (enquiryID || serviceID) && ((!_.isEmpty(_moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.tradeinPlus) &&
            dealersettings.client.moduleSettings.tradeinPlus.enabled) ? true : false);

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _evalexpert = Object.assign({}, dealersettings &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

        const evalexpertEnabled = localStorage.defaultModule !== 'oem' && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const accessToRegistrationCheck = localStorage.defaultModule !== 'tradeinPro' ? ((!_.isEmpty(_permissions) && _permissions.accessToRegistrationCheck) ? true : false) : true;
        const editTradeInAfterSent = localStorage.defaultModule !== 'tradeinPro' ? ((!_.isEmpty(_permissions) && _permissions.editTradeInAfterSent) ? true : false) : true;
        const _tradeinProPermissions = (localStorage.defaultModule === 'tradeinPro' && (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
        const showDamageCost = ((!_.isEmpty(_tradeinProPermissions) && _tradeinProPermissions.showDamageCost) ? true : (localStorage.defaultModule === 'tradeinPro' ? false : true));

        const _isTradeinProCompleted = [tipStatus.WON, tipStatus.LOST].includes(status) ? true : false;
        const isComplete = ((sent && localStorage.defaultModule !== 'tradeinPro' && editTradeInAfterSent === false) || isDeivered || isReadOnlyView || _isTradeinProCompleted) ? true : false;

        const limitedAcces = (isComplete === false && sent && localStorage.defaultModule !== 'tradeinPro' && editTradeInAfterSent === true) ? true : false

        const accessToShowTradeInPrice = localStorage.defaultModule === 'tradeinPro' ? ((!_.isEmpty(_tradeinProPermissions) && _tradeinProPermissions.accessToShowTradeInPrice) ? true : false) : ((!_.isEmpty(_permissions) && _permissions.accessToShowTradeInPrice) ? true : false);
        let _allImages = CommonHelper.bindAllImages(images, sidePhotos)
        const _imageTitle = !isComplete &&
            this.mandImages > 0 && _allImages.length < this.mandImages ? `Minimum ${this.mandImages} ${this.mandImages > 1 ? 'images' : 'image'} are required.` : ''

        const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
        let _objCurrentStatus = tradeinProID && status && tradeinProAllStatus.filter(e => e.value === status)[0]

        let _contactID = fields.contactID ? fields.contactID : fields.contact?.documentID

        let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinOptions))
            ? dealersettings.client.tradeinOptions :
            Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
        return (
            this.newDocID
                ?
                <>

                    <Modal show={show}
                        onHide={this.handleModalClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-stock"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className='w-100'><Translate text={this.props.title} />
                                {offerPrice && accessToShowTradeInPrice
                                    ?
                                    <div className="float-right offer-price-head ml-2 pt-2"><Translate text={'Offer Price'} />
                                        <span className="badge badge-pill badge-offer-price ml-1">
                                            <span><NumberFormat value={offerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></span>
                                        </span>
                                    </div>
                                    :
                                    <></>}

                                {
                                    fields.isDeletedFromTradeIn
                                        ?
                                        <>
                                            <div className="float-right kitchen-modal-status ml-2 pt-2">
                                                <div className={`badge badge-pill badge-status-lost `}>
                                                    {'Deleted From TradeIn Plus'}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>

                                        </>
                                }

                                {/* {
                                    !_.isEmpty(fields.tags)
                                        ?
                                        <>
                                            <div className="float-right kitchen-modal-status ml-2 pt-2">
                                                {
                                                    fields.tags.map((rec, index) => {
                                                        return <div key={index} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={rec.type === 'evalexpert' ? `EVAL#: ${rec.refID}` : ''}>
                                                            {CommonHelper.getNameByValue(dealersettings.integrations, rec.type)} 
                                                           
                                                            </div>
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <> </>
                                } */}

                                {
                                    !tradeinPlusEnabled && !isReadOnlyView && !tradeinProID && !evalexpertID
                                        ?
                                        <>
                                            <div className="float-right kitchen-modal-status ml-2 pt-2 mr-2">
                                                <div className={`badge badge-pill badge-status-${status ? status.toLowerCase() : 'empty'}`} style={{ color: (status ? '#fff' : '#333') }}>
                                                    <DropDownMenu
                                                        text={status ? status.toUpperCase() : 'Select status'}
                                                        keyVal="10045341"
                                                        //className={`inputlink-addmore`}
                                                        tIndex="-1"
                                                        menu={__status}
                                                        handleChange={this.handleStatusChange}
                                                    >
                                                    </DropDownMenu>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                _objCurrentStatus
                                                    ?
                                                    <>
                                                        <div className="float-right kitchen-modal-status ml-2 pt-2">
                                                            <div
                                                                className={`badge badge-pill badge-status-${status === tipStatus.NEW ? 'open' : 'empty'}`}
                                                                style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                            >
                                                                <Translate
                                                                    text={_objCurrentStatus.name}
                                                                    upperCase={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {status ?
                                                            <div className="float-right kitchen-modal-status ml-2 pt-2">
                                                                <div className={`badge badge-pill badge-status-${status.trim().toLowerCase()} `}>
                                                                    {status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                                </div></div>
                                                            : <></>}
                                                    </>
                                            }
                                        </>
                                }






                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {
                                this.isContentLoader ? <div className='loader-center-screen'>
                                    <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                                </div> : <div className="container-fluid">
                                    <div className="row">
                                        <div className=" pop-layout form-style tradein-pop">
                                            <div className="inspection-marker-wrap">
                                                <div className="form-group col-md-12">

                                                    <div className={`side-photos-image-wraper`} id="tradein-photos">
                                                        {
                                                            sidePhotosVM && sidePhotosVM.filter(e => e.value !== 'others').map((data, index) => {
                                                                return <div key={index}>
                                                                    {
                                                                        !_.isEmpty(sidePhotos) && sidePhotos[data.value] && data.default
                                                                            ?
                                                                            <>
                                                                                <div title={data.name} className="side-photos-item-image">
                                                                                    {
                                                                                        isComplete
                                                                                            ?
                                                                                            <></>
                                                                                            :
                                                                                            <>
                                                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleSideImageDel(data.value);
                                                                                                }}>
                                                                                                    <i className="ico icon-delete" ></i>
                                                                                                </div>
                                                                                            </>
                                                                                    }

                                                                                    <a data-fancy-box={`tradein_img_add`}
                                                                                        href={sidePhotos[data.value]}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({
                                                                                                imageViewer: {
                                                                                                    show: true,
                                                                                                    images: _allImages,
                                                                                                    index: _allImages.findIndex(e => e === sidePhotos[data.value])
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                        <img src={sidePhotos[data.value]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                    {/* <div className="side-photos-item-label">{data.name}</div> */}
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>

                                                                                {
                                                                                    isComplete
                                                                                        ?
                                                                                        <></>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                (imageLoader && imageLoader[data.value]) ?
                                                                                                    (
                                                                                                        <div title={data.name} className="side-photos-item-image">
                                                                                                            <div className="img-loader">
                                                                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                                                                        <span className="sr-only">Loading...</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                            </a>
                                                                                                            {/* <div className="side-photos-item-label">{data.name}</div> */}
                                                                                                        </div>
                                                                                                    )
                                                                                                    :
                                                                                                    <>

                                                                                                        <div title={data.name} className={`side-photos-item-image upload ${errorPhotos[data.value]}`}>
                                                                                                            <label htmlFor={`sidePhotos_${data.value}`}>
                                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, data.ico)]} alt="" htmlFor={`sidePhotos_${data.value}`} />
                                                                                                                <input className="fileInput"
                                                                                                                    type="file"
                                                                                                                    name={`sidePhotos_${data.value}`}
                                                                                                                    id={`sidePhotos_${data.value}`}
                                                                                                                    accept="image/*"
                                                                                                                    style={{ display: 'none' }}
                                                                                                                    multiple={data.default ? false : true}
                                                                                                                    onChange={(e) => this.onSelectFile(e, 'tradein', `sidePhotos_${data.value}`, `Side Photo (${data.name})`, data.value)} />
                                                                                                                {
                                                                                                                    data.default
                                                                                                                        ?
                                                                                                                        <div className="side-photos-item-addwrap">
                                                                                                                            <div className="side-photos-item-add"><i className="ico icon-add"></i></div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <></>
                                                                                                                }

                                                                                                            </label>

                                                                                                            {/* <div className="side-photos-item-label">{data.name}</div> */}
                                                                                                        </div>


                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }


                                                                            </>
                                                                    }
                                                                </div>
                                                            })
                                                        }

                                                        {
                                                            isComplete && _.isEmpty(sidePhotos) && _.isEmpty(images)
                                                                ?
                                                                <>
                                                                    <div className="side-photos-item-image mt-2">

                                                                        <a href={'#'} onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" className="img-object-fit" />
                                                                        </a>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>

                                                                </>
                                                        }

                                                        {/* </div>
                                                    <div className={`tradein-image-wraper ${(_.isEmpty(images) && !imageURL && !imageURLs) ? 'inspection-none-image' : ''}`}> */}


                                                        {
                                                            !_.isEmpty(images)
                                                                ?
                                                                <>
                                                                    {
                                                                        images.map((data, index) => {
                                                                            return <div className="side-photos-item-image" key={index}>
                                                                                {isComplete ? <></> :
                                                                                    <>
                                                                                        <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleImageDel(index);
                                                                                        }}>
                                                                                            <i className="ico icon-delete" ></i>
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                <a data-fancy-box={`tradein_img_add`}
                                                                                    href={data}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({ imageViewer: {
                                                                                            show: true,
                                                                                            images: _allImages,
                                                                                            index: _allImages.findIndex(e => e === data)
                                                                                        } });
                                                                                    }}>
                                                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                </a>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                        {
                                                            (imageURL) ?
                                                                (
                                                                    <div className="side-photos-item-image">
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={imageURL} width="70" height="70" alt="" />
                                                                        </a>
                                                                    </div>
                                                                )
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            (imageURLs) ?
                                                                <>
                                                                    {
                                                                        imageURLs.map((data, index) => (
                                                                            <div key={index} className="side-photos-item-image">
                                                                                <div className="img-loader">
                                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                    }


                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            isComplete
                                                                ?
                                                                <></>
                                                                :
                                                                <>
                                                                    <div title={_imageTitle ? _imageTitle : 'More Images'} className={`side-photos-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                                                        <label htmlFor="tradeins_images">
                                                                            <img src={_images.addvehicle} alt="" htmlFor="tradeins_images" />
                                                                            <input className="fileInput"
                                                                                type="file"
                                                                                name="tradeins_images"
                                                                                id="tradeins_images"
                                                                                accept="image/*"
                                                                                style={{ display: 'none' }}
                                                                                multiple={true}
                                                                                onChange={(e) => this.onSelectFile(e, 'tradein', 'images', 'Trade-In Image')} />
                                                                        </label>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>

                                                    {
                                                        _imageTitle ? <div className={`req-image-red-alert ${this.state.errors["images"] ? 'active' : ''}`}><span>*</span> {_imageTitle}</div> : <></>
                                                    }




                                                    <div className="tradein-inspection-wrap">
                                                        {
                                                            showDamageCost
                                                                ?
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className={`btn btn-inspection-cost float-right ${!_.isEmpty(marks) && marks.length > 0 ? '' : 'btn-inspection-cost-disable'}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ showDamage: true })
                                                                        }}
                                                                    ><Translate text={'damageCost'} />
                                                                        <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                        <div className="float-right">
                                                                            {CommonHelper.showReconditioningCost(marks, currencySymbol)}
                                                                        </div>
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className={`btn btn-inspection-cost float-right btn-inspection-cost-disable`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    ><Translate text={'damageCost'} />
                                                                    </button>
                                                                </>
                                                        }


                                                        <button
                                                            type="button"
                                                            className="btn btn-inspection-multi float-right"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ showCanvas: true })
                                                            }}
                                                        >
                                                            {this.state.isLoadingInspection ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-inspection')}></i>}

                                                            <Translate text={'vehicleInspection'} />
                                                            {
                                                                !_.isEmpty(marks) && marks.length > 0
                                                                    ?
                                                                    <>
                                                                        <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                        <div className="float-right"><span className="badge badge-inspection">{marks.length}</span></div>
                                                                    </>

                                                                    :
                                                                    <>
                                                                        <div className="float-right ml-2"><i className="ico icon-add" aria-hidden="true"></i></div>
                                                                    </>

                                                            }
                                                        </button>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className={`tabs-wraper tabs tradein-tab-fix mt-2`}>


                                                <div className="requirement-section-head"><Translate text={'vehicleInformation'} />
                                                    <div className=" float-right linked-activities-add">
                                                        {
                                                            _contactID && !isComplete && !evalexpertID && !limitedAcces
                                                                ?
                                                                <> <button type="button" className={`btn btn-secondary float-right`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleOwnedVehicleOpen();
                                                                    }}>
                                                                    <i className="ico icon-search"></i> <Translate text={'Search From Customer Owned Vehicle'} />
                                                                </button>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                        {
                                                            !isComplete && this.autograbRegCheckEnabled && accessToRegistrationCheck
                                                                ?
                                                                <button type="button" className="btn btn-primary float-right mr-2" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showRegStatus: true })
                                                                }}><i className="ico icon-search"></i> <Translate text={'Registration Check'} /> </button>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>


                                                <div className={`form-row ${(isComplete) ? 'form-disable' : ''}`}>

                                                    {
                                                        dynamicDetailList && dynamicDetailList.map((info, index) => (
                                                            <TipInfoInputs
                                                                key={index}
                                                                type={info}
                                                                formClassName='form-group col-md-4'
                                                                fields={this.state.fields}
                                                                errors={this.state.errors}
                                                                currencySymbol={currencySymbol}
                                                                dealersettings={dealersettings}
                                                                tradeinOptionsDF={tradeinOptionsDF}
                                                                handleSelectSave={this.handleSelectSave}
                                                                isComplete={isComplete}
                                                                limitedAcces={limitedAcces}
                                                                makes={this.state.makes}
                                                                addMake={addMake}
                                                                groups={this.state.groups}
                                                                addGroup={addGroup}
                                                                types={this.state.types}
                                                                addType={addType}
                                                                models={this.state.models}
                                                                addModel={addModel}
                                                                years={this.state.years}
                                                                engineTypes={engineTypes}
                                                                transmissions={transmissions}
                                                                vCondtions={vCondtions}
                                                                vDoors={vDoors}
                                                                wheelConditions={wheelConditions}
                                                                wheelConditionsNA={wheelConditionsNA}
                                                                tradeinPlusEnabled={tradeinPlusEnabled}
                                                                tinStatus={__status}
                                                                handleAddMakeEnable={this.handleAddMakeEnable}
                                                                handleAddMakeDisable={this.handleAddMakeDisable}
                                                                handleMakeChange={this.handleMakeChange}
                                                                handleAddGroupEnable={this.handleAddGroupEnable}
                                                                handleAddGroupDisable={this.handleAddGroupDisable}
                                                                handleGroupChange={this.handleGroupChange}
                                                                handleAddTypeEnable={this.handleAddTypeEnable}
                                                                handleAddTypeDisable={this.handleAddTypeDisable}
                                                                handleTypeChange={this.handleTypeChange}
                                                                handleAddModelEnable={this.handleAddModelEnable}
                                                                handleAddModelDisable={this.handleAddModelDisable}
                                                                handleOnChange={this.handleOnChange}
                                                                handleVehicleScan={this.handleVehicleScan}
                                                                handleOnCapChange={this.handleOnCapChange}
                                                                handleDateChange={this.handleDateChange}
                                                                onValueChange={this.onValueChange}
                                                                handleCustomOption={this.handleCustomOption}
                                                                handleReactSelectChange={this.handleReactSelectChange}
                                                                onCurrencyChange={this.onCurrencyChange}
                                                                handleCheckChange={this.handleCheckChange}
                                                                handleOnAreaChange={this.handleOnAreaChange}
                                                                handleDynamicCheckChange={this.handleDynamicCheckChange}
                                                                handleDynamicOnChange={this.handleDynamicOnChange}
                                                                saveDynamicField={this.saveDynamicField}
                                                                cancelDynamicField={this.cancelDynamicField}
                                                                onDynamicNumberChange={this.onDynamicNumberChange}
                                                                handleDynamicDateChange={this.handleDynamicDateChange}
                                                                handleDynamicSelectSave={this.handleDynamicSelectSave}
                                                                handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}

                                                            ></TipInfoInputs>
                                                        ))

                                                    }
                                                </div>

                                                <div className="requirement-pop-inner mt-3 p-0">
                                                    <div className="linked-activities-title p-0 pb-2"> <Translate text={'Notes History'} />
                                                        <div className=" float-right linked-activities-add">
                                                            <button type="button"
                                                                className="btn btn-primary float-right"
                                                                onClick={(e) => { e.preventDefault(); this.handleNotesOpen(); }}
                                                            >
                                                                <i className="ico icon-add"></i> <Translate text={'addNote'} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {
                                                        //notesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                        <div className="enquiry-flow mt-0 mb-3">
                                                            {
                                                                !_.isEmpty(notesHistory)
                                                                    ?
                                                                    <div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
                                                                        <div className="flow-timelinebar"></div>

                                                                        {
                                                                            notesHistory.map((obj, index) => {
                                                                                return (
                                                                                    <div key={index} className="enquiry-flow-item note">
                                                                                        <div className="flow-item  note  ">
                                                                                            <div className="flow-item-content">
                                                                                                <div className="flow-text-wrap">
                                                                                                    <div className="flow-text text-area-space note">{obj.notes}
                                                                                                        <div className="flow-notes-sub mt-1">
                                                                                                            <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                            {
                                                                                                                obj.addedByName
                                                                                                                    ?
                                                                                                                    <>{' - '}{obj.addedByName}</>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            obj.addedBy
                                                                                                                                ?
                                                                                                                                <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), obj.addedBy)}</>
                                                                                                                                :
                                                                                                                                <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                            }

                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }

                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="text-center p-2">
                                                                            <p><Translate text={'no notes created'} /> </p>
                                                                        </div>
                                                                    </>
                                                            }

                                                        </div>
                                                        //)
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                            {
                                isComplete
                                    ?
                                    <>
                                        <div className="map-view-icon">
                                            <button type="button" className="btn btn-default float-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    CommonHelper.saveChangeLog(this.props.dealersettings.client.id, null, (enquiryID || tradeinProID || serviceID), `External Report - Viewed `, 'tradein', 'view');
                                                    const url = `/tradein/details/${this.newDocID}?isView=y${accessToShowTradeInPrice ? "" : "es"}`;
                                                    window.open(url, "_blank");
                                                }}>
                                                <i className="ico icon-waiver mr-1"></i>
                                                <Translate text={'External Report'} />
                                            </button>
                                            {tradeinProID && (<button type="button" className="btn btn-default float-right mr-3"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    CommonHelper.saveChangeLog(this.props.dealersettings.client.id, null, tradeinProID, `Internal Report - Viewed `, 'tradeinPro', 'view');
                                                    const url = `/tradeinpro/internal/${tradeinProID}?isView=y`;
                                                    window.open(url, "_blank");
                                                }}>
                                                <i className="ico icon-waiver mr-1"></i>
                                                <Translate text={'Internal Report'} />
                                            </button>)}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                                            <Translate text={'cancel'} />
                                        </button>

                                        <button type="button"
                                            className={`btn btn-primary float-right ${(imageURL || imageURLs || this.state.isLoadingInspection) ? 'form-disable' : ''}`}
                                            onClick={(e) => this.handleSubmit(e)} >
                                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'save'} />
                                        </button>
                                    </>
                            }
                        </Modal.Footer>

                    </Modal>

                    <PopUpModal show={this.state.cropShow.show}>
                        <ImageCropHook
                            cropShow={this.state.cropShow}
                            handleClose={this.handleCropClose}
                            handleSelect={this.handleCropSelect}
                        ></ImageCropHook>
                    </PopUpModal>


                    <PopUpModal show={this.state.showCanvas}>
                        <ImageCanvas
                            show={this.state.showCanvas}
                            clsActive='overlay-modal active'
                            imagePath='tradein'
                            marks={fields.marks}
                            handleClose={this.handleCanvasClose}
                            dealersettings={this.props.dealersettings}
                            showCost={showDamageCost}
                            isReadOnly={(isComplete) ? true : false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={this.state.showDamage}>
                        <MarkingInfo
                            show={this.state.showDamage}
                            clsActive='overlay-modal active'
                            marks={this.state.fields.marks}
                            handleClose={this.handleDamageClose}
                            dealersettings={dealersettings}
                            showCost={true}
                        ></MarkingInfo>
                    </PopUpModal>

                    <PopUpModal show={this.state.showRegScan}>
                        <RegScan
                            show={this.state.showRegScan}
                            clsActive='overlay-modal active'
                            regNo={fields.regNo}
                            dealersettings={this.props.dealersettings}
                            isEvalExpert={evalexpertEnabled}
                            handleClose={this.handleRegScanClose}
                        >
                        </RegScan>
                    </PopUpModal>

                    <PopUpModal show={this.state.showVinScan}>
                        <VinScan
                            show={this.state.showVinScan}
                            clsActive='overlay-modal active'
                            chassisNo={fields.chassisNo}
                            dealersettings={this.props.dealersettings}
                            isEvalExpert={evalexpertEnabled}
                            handleClose={this.handleVinScanClose}
                        >
                        </VinScan>
                    </PopUpModal>

                    <PopUpModal show={vehicleScan.show}>
                        <VehicleScan
                            show={vehicleScan.show}
                            type={vehicleScan.type}
                            clsActive='overlay-modal active'
                            regNo={fields.regNo}
                            chassisNo={fields.chassisNo}
                            dealersettings={this.props.dealersettings}
                            isEvalExpert={evalexpertEnabled}
                            handleClose={this.handleScanClose}
                        >
                        </VehicleScan>
                    </PopUpModal>

                    <PopUpModal show={autograbSearch}>
                        <AutoGrabScan
                            show={autograbSearch}
                            clsActive='overlay-modal active'
                            regNo={fields.regNo}
                            regState={fields.regState}
                            chassisNo={fields.chassisNo}
                            existingSearch={tradeinProID ? true : false}
                            tradeinProID={tradeinProID}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleAutoGrabClose}
                        >
                        </AutoGrabScan>
                    </PopUpModal>

                    <PopUpModal show={this.state.showRegStatus}>
                        <RegStatus
                            show={this.state.showRegStatus}
                            clsActive='overlay-modal active'
                            regNo={fields.regNo}
                            regState={fields.regState}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleRegStatusClose}
                        >
                        </RegStatus>
                    </PopUpModal>

                    <PopUpModal show={showOwnedVehicles}>
                        <OwnedVehicles
                            dealersettings={this.props.dealersettings}
                            show={showOwnedVehicles}
                            handleClose={this.handleOwnedVehicleClose}
                            contactID={_contactID}
                        />
                    </PopUpModal>

                    <PopUpModal show={showNotes}>
                        <AddNotes
                            show={showNotes}
                            handleClose={this.handleNotesClose}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        />
                    </PopUpModal>

                    <PopUpModal show={imageViewer.show}>
                        <ImageViewer
                            {...imageViewer}
                            handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                        />
                    </PopUpModal>

                </>
                :
                <></>
        );
    }
}


export default AddTradeIn;