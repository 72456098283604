import React, { useState, useEffect, useMemo } from 'react';
import AddCampaign from './add';
import { PopUpModal } from '../../../components';
import _ from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import { TemplateContext } from '../../settings/workflow/templateContext';
import { Tab, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CampaignsList from './list';
import axios from 'axios';
import moment from 'moment';
import CommonHelper from '../../../services/common';
import Swal from 'sweetalert2';
import CampaignsReport from './list/stats';

const MailjetCampaigns = ({ refresh_token, client_id, client_secret, dealersettings, clientUsers, updateDealerSettings, history }) => {
	const [showAddCampaign, setShowAddCampaign] = useState({
		show: false,
		title: '',
		campaignID: ''
	});
	const [campaignsLoader, setCampaignsLoader] = useState(true)
	const [oemClients, setOEMClients] = useState([]);

	const apiURL = process.env.NODE_ENV === 'development'
		? 'http://localhost:5001/ams-pro-qa/us-central1/'
		: dealersettings.apiURL

	const [activeTab, setActiveTab] = useState('all');
	const [campaigns, setCampaigns] = useState([]);
	const [emailtemplates, setEmailTemplates] = useState([]);
	const [loader, setLoader] = useState(true);
	const [pageNum, setPageNum] = useState(0);
	const [isPaging, setPaging] = useState(false)
	const pageLimit = 15
	const [tableLoader, setTableLoader] = useState(false)
	const [hasMore, setHasMoreData] = useState([])
	const [reload, setReload] = useState()
	const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates]);
	// const groupsMemo = useMemo(() => clientGroups, [clientGroups])
	const clientsMemo = useMemo(() => oemClients, [oemClients])
	const timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
	const [dateRange, setDateRange] = useState({
		startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
		endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate())
	})
	const [initialLoad, setInitialLoad] = useState(true)
	const [campaignLimit, setCampaignLimit] = useState(0);
	const [campaignStats, setCampaignStats] = useState([])
	const [statsLoader, setStatsLoader] = useState(true)

	const campaignCount = dealersettings?.client?.moduleSettings?.mailjet?.limit || 3000
	const moduleEnable = dealersettings?.client?.moduleSettings?.mailjet?.enabled && dealersettings?.client?.moduleSettings?.mailjet?.active

	const handleModelClose = (reload) => {
		setShowAddCampaign({
			...showAddCampaign,
			show: false,
			title: '',
			campaignID: ''
		});
		if (reload) {
			setTableLoader(true)
			fetchData(true);
			setTimeout(() => {
				setTableLoader(true)
				fetchData(true);
			}, 60000)
		}
	};

	const onCreateCampaign = (data) => {
		if (campaignLimit >= campaignCount && moduleEnable) {
			Swal.fire(`You've exceed your ${campaignCount} emails limit.`, '', 'info')
			return;
		} else {
			setShowAddCampaign({
				...showAddCampaign,
				show: true,
				title: 'Create Campaign',
				data: !_.isEmpty(data) ? {
					title: `${data.Title} - Copy`,
					subject: data.Subject,
					fromName: data.SenderName,
					fromEmail: data.SenderEmail,
					unsub: true
				} : null,
				campaignID: data?.ID || null,
				ContactsListID: data?.ContactsListID || null
			})
		}

	}

	useEffect(() => {
		const oemclientSnapshot = window.firebase.firestore().collection('clients')
			.where('settingsID', '==', dealersettings.client.settingsID)
			.onSnapshot(querySnapshot => {
				let _clients = [];
				querySnapshot.forEach((rec) => {
					_clients.push({ ...rec.data(), 'documentID': rec.id })
				})
				setOEMClients(_clients)
			})
		return () => {
			oemclientSnapshot && oemclientSnapshot();
		}
	}, [])

	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}
		if (!_.isEmpty(emailtemplates)) {
			return;
		}

		let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`).
			where('visibility', '==', 'private')
			.where('isDeleted', '==', false)
		privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, dealersettings, null, true);

		var alltemplates = combineLatest(
			collectionData(privateTemplatesRef),
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`)
				.where('visibility', '==', 'shared')
				.where('isDeleted', '==', false))
		).pipe(
			map((templates) => {
				var [dealer, shared] = templates;
				return [
					[...dealer],
					[...shared]
				];
			}))
			.subscribe(allDocs => {
				let list = [];
				allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
				let listVM = [];
				list.forEach(rec => {
					if (!listVM.some(e => e.documentID === rec.documentID))
						listVM.push(rec)
				})
				setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate' || item.type === 'email'))
			});
		return () => {
			alltemplates && alltemplates.unsubscribe()
		}
	}, []);

	useEffect(() => {
		fetchData()
	}, [isPaging]);

	const fetchData = async (reload) => {
		const url = `${apiURL}mailjet-api/api/mailjet/list-campaign`
		const payload = {
			"limit": pageLimit,
			"offset": reload ? 0 : pageNum,
			settingsID: dealersettings.oemID
		}
		if (dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && dealersettings.oemID) {
			payload.oemID = dealersettings.oemID;
		}
		else if (dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && dealersettings.regionID) {
			payload.regionID = dealersettings.regionID;
		}
		else if (dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && dealersettings.groupID) {
			payload.groupID = dealersettings.groupID;
		}
		else {
			payload.clientID = dealersettings.client.id
		}
		const recipientsCount = await axios.post(url, payload);
		if (recipientsCount?.data?.success) {
			let data = recipientsCount.data.data.map((item, index) => {
				return convertCampaignVM({
					...item,
					'documentID': item?.ID,
					'id': item?.ID
				})
			})
			if (data?.length > 0 && data.length === pageLimit) {
				setHasMoreData([data[data.length - 1]])
			}
			else {
				setHasMoreData([]);
			}
			data = reload ? data : [...campaigns, ...data];
			//console.log(data);
			setCampaigns(data)
			setCampaignsLoader(false)
			setTableLoader(false)
			setReload(false)
		} else {
			setCampaigns([])
			setCampaignsLoader(false)
			setReload(false)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			const url = `${apiURL}mailjet-api/api/mailjet/campaign-stats`
			const payload = {
				counterSource: 'APIKey',
				counterTiming: 'Message',
				counterResolution: 'Day',
				fromTs: moment.unix(dateRange.startDate.seconds).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
				toTS: moment.unix(dateRange.endDate.seconds).tz(timezone).format('YYYY-MM-DDTHH:mm:ss'),
				settingsID: dealersettings.oemID
			}
			if (dealersettings.level === 'oem' && localStorage.defaultModule.includes('oem') && dealersettings.oemID) {
				payload.oemID = dealersettings.oemID;
			}
			else if (dealersettings.level === 'region' && localStorage.defaultModule.includes('oem') && dealersettings.regionID) {
				payload.regionID = dealersettings.regionID;
			}
			else if (dealersettings.level === 'group' && localStorage.defaultModule.includes('oem') && dealersettings.groupID) {
				payload.groupID = dealersettings.groupID;
			}
			else {
				payload.clientID = dealersettings.client.id
			}
			const recipientsCount = await axios.post(url, payload);
			if (recipientsCount?.data?.success) {
				let data = recipientsCount.data.data;
				setCampaignStats(data);
				setStatsLoader(false)
				if (data?.length && initialLoad) {
					setCampaignLimit(_.sumBy(data, 'Total'))
					setInitialLoad(false)
				}
			}
		}
		setStatsLoader(true)
		fetchData()
	}, [dateRange])

	const convertCampaignVM = doc => {
		let objToReturn = { ...doc };
		objToReturn.title = doc.Title || 'Untitled Message';
		objToReturn.status = doc.Status === 0 ? 'Draft' : doc.Status === 1 ? 'Scheduled' : doc.Status === 2 ? 'Sent' : doc.Status < 0 ? 'Deleted' : 'Draft'
		objToReturn.documentID = doc.id;
		objToReturn.nameText = doc.Title || 'Untitled Message';
		objToReturn.name = (
			<>
				<div className='campaign-subject'>
					{doc.Title || 'Untitled Campaign'}
				</div>
				<span>{doc.Subject || 'Untitled Campaign'}</span>
			</>
		);
		objToReturn.SenderDOM = (
			<>
				<div className='campaign-subject'>
					{doc.SenderName}
				</div>
				<span>{doc.SenderEmail}</span>
			</>
		);
		objToReturn.statusToShow = (
			<div
				className={`badge badge-pill badge-${doc.Status === 2
					? 'available'
					: doc.Status === 1
						? 'pending'
						: doc.Status === 0 || doc.Status > 2
							? 'draft'
							: 'failed'
					}-fill `}
			>
				{/* {doc.Status === 'save' ? 'draft' : doc.Status} */}
				{doc.Status === 0 ? 'Draft' : doc.Status === 1 ? 'Scheduled' : doc.Status === 2 ? 'Sent' : doc.Status < 0 ? 'Deleted' : 'Draft'}
			</div>
		);
		objToReturn.stats = (
			<div className='campaign-stat-box-wraper'>
				<div
					className={`campaign-stat-box ${_.isEmpty(doc.report_summary) ? 'no-stat' : ''
						}`}
				>
					<p className='campaign-stat-count w-100'>
						{!_.isEmpty(doc.report_summary) ? doc.report_summary.opens : '0'}
					</p>
					<p className='campaign-stat-title w-100'>Opens</p>
				</div>
				<div
					className={`campaign-stat-box ${_.isEmpty(doc.report_summary) ? 'no-stat' : ''
						}`} s
				>
					<p className='campaign-stat-count w-100'>
						{!_.isEmpty(doc.report_summary) ? doc.report_summary.clicks : '0'}
					</p>
					<p className='campaign-stat-title w-100'>Clicks</p>
				</div>
			</div>
		);
		objToReturn.createdOn = (
			<div className='user-list-items-info '>
				{doc.DeliveredAt ? <h4>{moment(doc.DeliveredAt).tz(timezone).format('DD/MM/YYYY hh:mm A')}</h4> : '--'}
				{/* <h5>Williams David</h5> */}
			</div>
		);
		objToReturn.date = doc.DeliveredAt ? moment(doc.DeliveredAt).toDate() : '--';
		objToReturn.dateSeconds = doc.DeliveredAt ? moment(doc.DeliveredAt).unix() : 0;

		return objToReturn;
	};

	const handlePagination = () => {
		let _pageNum = (pageNum + 1) + pageLimit;
		setPageNum(_pageNum)
		setTableLoader(true)
		setPaging(!isPaging)
	}

	return (
		<TemplateContext.Provider
			value={{
				emailTemplateList: emailTemplatesMemo,
				groups: [],
				clients: clientsMemo,
				loading: loader,
			}}
		>
			<div className='middle-wrapper'>
				<div className='filter-panel'>
					<div className='float-left campaign-head'>
						<h3>Email Campaigns</h3>
					</div>
					<div className='float-right'>
						<div className='filter-add-button'>
							<button
								type='button'
								className='btn btn-primary'
								onClick={(e) => onCreateCampaign()}
							>
								{' '}
								<i className='ico icon-add'></i> Create Campaign
							</button>
						</div>
					</div>
				</div>
				<div className='campaign-panel'>
					<div className='campaign-main-container'>
						<div className='campaign-wrapper'>
							<Tab.Container
								defaultActiveKey={activeTab}
								mountOnEnter={true}
								onSelect={k => { setActiveTab(k) }}
							>
								<div className='campaign-left-panel'>
									<div className='campaign-left-menu'>
										<h4>Campaigns</h4>
										<div className='campaign-submenu'>
											<Nav as='ul' bsPrefix='ul-nav'>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='all' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-all'> </i>{' '}
														<span>All</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='ongoing' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-ongoing'> </i>{' '}
														<span>Scheduled</span>{' '}
													</Nav.Link>
												</Nav.Item>
												{/* <Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='draft' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-draft'> </i>{' '}
														<span>Draft</span>{' '}
													</Nav.Link>
												</Nav.Item> */}
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='completed' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-completed'> </i>{' '}
														<span>Completed </span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='report' bsPrefix='li-a-nav'>
														<i className='ico icon-Reports'> </i>{' '}
														<span>Reports</span>{' '}
													</Nav.Link>
												</Nav.Item>
											</Nav>
										</div>
									</div>
								</div>
								{
									activeTab === 'report' ? (
										<CampaignsReport
											dateRange={dateRange}
											setDateRange={(val) => setDateRange(val)}
											campaignStats={campaignStats}
											statsLoader={statsLoader}
										/>
									) : (
										<CampaignsList
											activeTab={activeTab}
											campaigns={campaigns}
											setCampaigns={setCampaigns}
											setShowAddCampaign={setShowAddCampaign}
											showAddCampaign={showAddCampaign}
											apiUrl={apiURL}
											onCreateCampaign={onCreateCampaign}
											loader={campaignsLoader}
											refresh_token={refresh_token}
											client_id={client_id}
											client_secret={client_secret}
											dealersettings={dealersettings}
											tableLoader={tableLoader}
											handlePagination={handlePagination}
											hasMore={hasMore}
											reload={reload}
											setReload={val => {
												if (val)
													setPageNum(0)
												setReload(val);
												setTableLoader(true)
												fetchData(true);
											}}
											timezone={timezone}
											campaignCount={campaignCount}
											campaignLimit={campaignLimit}
										/>
									)
								}

							</Tab.Container>
						</div>
					</div>
				</div>
				<PopUpModal show={showAddCampaign.show}>
					<AddCampaign
						{...showAddCampaign}
						handleClose={handleModelClose}
						dealersettings={dealersettings}
						clientUsers={clientUsers}
						updateDealerSettings={updateDealerSettings}
						history={history}
						apiUrl={apiURL}
						isScheduleAllowed={true}
						refresh_token={refresh_token}
						client_id={client_id}
						client_secret={client_secret}
						timezone={timezone}
					/>
				</PopUpModal>
			</div>
		</TemplateContext.Provider>
	);
};

export default MailjetCampaigns;
