import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _, { isEmpty, truncate } from 'lodash'
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { MainContext } from '../layout/provider';
import { PopUpModal } from '../../components';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import ComposeDraft from './composeEmail'

const EmailList = ({
	threads,
	selectedThread,
	setSelectedThread,
	filterThreads,
	selectThreads,
	threadsSelected,
	searchText,
	setSearchText,
	updateThread,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	type,
	searchThreads,
	refreshThreads,
	threadsLoader,
	hasMore,
	handleMarkAs,
	setThreadsSelected,
	setFilteredThreads,
	filteredThreads,
	filterThread,

	token,
	structureType,
	userData,
	emailtemplates
}) => {
	const history = useHistory();
	const [searchMessage, setSearch] = useState(searchText || '');
	const [filter, setFilter] = useState(filterThread || null);
	const { dealersettings, nylas_accountData } = useContext(MainContext);

	const [sendLoader, setSendLoader] = useState(false);
	const [showComposeModal, setShowComposeModal] = useState({
		show: false,
		type: '',
		title: '',
		emailData: null,
		thread: '',
	});

	const important_id = CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Important', 'id', true)
	const nylasEmail = dealersettings.nylas_v3 && dealersettings.nylas_v3.email_address ? dealersettings.nylas_v3.email_address : dealersettings.email
	useEffect(() => {
		//console.log('EmailList', type)
		function handleResize() {
			document.documentElement.style.setProperty(
				'--emailListItemWrapHeight',
				window.innerHeight - 170 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--emailListItemWrapHeight',
			window.innerHeight - 170 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// useEffect(() => {
	// 	if (threads.length <= 0) {
	// 		setSelectedThread({})
	// 	}
	// }, [selectedThread])

	const getNameFromParticipants = (participants, email) => {
		const filteredParticipants = participants.filter(
			participant => participant.email !== email,
		);

		if (filteredParticipants.length === 0) {
			if (participants && participants.length > 0 && participants[0].name) {
				return participants[0].name ? participants[0].name : '';
			}
			else if (participants && participants.length > 0 && participants[0].email) {
				return participants[0].email ? participants[0].email.split('@')[0] : '';
			}
		}
		if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0].name) {
			return filteredParticipants[0].name ? filteredParticipants[0].name : '';
		}
		else if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0].email) {
			return filteredParticipants[0].email ? filteredParticipants[0].email.split('@')[0] : '';
		}
	};

	const handleFilter = _type => {
		setFilter(_type);
		filterThreads(_type);
	};

	const handleSelectThreads = _type => {
		selectThreads(
			_type,
			null,
			filteredThreads,
			setFilteredThreads,
			setThreadsSelected,
		);
	};

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			if (searchMessage.length > 0) {
				searchThreads(searchMessage);
			}
			if (!_.isEmpty(searchText) && _.isEmpty(searchMessage)) {
				searchThreads('');
			}
		}
	};

	useEffect(() => {
		// if (!threadsLoader) {
		// 	let search = window.location.search;
		// 	let params = new URLSearchParams(search);
		// 	let threadid = params.get('threadid');
		// 	if (params.has('threadid')) {
		// 		params.delete('threadid')
		// 		history.replace({ search: params.toString() })
		// 	}
		// 	if (document.getElementById(`thread-${threadid}`))
		// 		document.getElementById(`thread-${threadid}`).click();
		// }
	}, [threadsLoader])

	//#region DRAFTS


	const handleOpenComposeModal = ({ type, title, emailData, thread }) => {
		setShowComposeModal({
			...showComposeModal,
			show: true,
			type,
			title,
			emailData: emailData ? emailData : null,
			thread: thread ? thread : '',
		});
	};

	const handleCloseComposeModal = () => {
		setShowComposeModal({
			...showComposeModal,
			show: false,
			type: '',
			title: '',
			emailData: null,
		});
	};

	// const handleSend = async (trigger, emailData, handleClose, replyToAll, createDraft) => {
	// 	const { mailTo, mailCc, mailBcc, subject, email } = trigger;
	// 	setSendLoader(true);

	// 	if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
	// 		toast.notify('Please specify at least one recipient.', {
	// 			duration: 2000,
	// 		});
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(subject)) {
	// 		toast.notify('Please write subject.', {
	// 			duration: 2000,
	// 		});
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(email) || email === '<p></p>\n') {
	// 		toast.notify('Please write in email body.', {
	// 			duration: 2000,
	// 		});
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	try {

	// 		let nylasAccountID = null;
	// 		if (dealersettings && !isEmpty(dealersettings.nylas_v3)) {
	// 			nylasAccountID = dealersettings.nylas_v3.grantId
	// 		} else if (userData && !isEmpty(userData.nylas_v3)) {
	// 			nylasAccountID = userData.nylas_v3.grantId
	// 		}

	// 		let emailObj = {
	// 			grantId: nylasAccountID,
	// 			createDraft: (createDraft || false),
	// 			draft: {
	// 				subject: trigger.subject,
	// 				body: trigger.email,

	// 			},
	// 		};

	// 		if (trigger.mailTo !== '') {
	// 			emailObj.draft.to = trigger.mailTo.split(',').map(email => {
	// 				return {
	// 					name: '',
	// 					email: email,
	// 				};
	// 			});
	// 		}

	// 		if (trigger.mailCc !== '') {
	// 			emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
	// 				return {
	// 					name: '',
	// 					email: email,
	// 				};
	// 			});
	// 		}

	// 		if (trigger.mailBcc !== '') {
	// 			emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
	// 				return {
	// 					name: '',
	// 					email: email,
	// 				};
	// 			});
	// 		}

	// 		if (trigger.files.length > 0) {
	// 			emailObj.files = trigger.files;
	// 		}
	// 		// console.log(emailObj);

	// 		setTimeout(() => {
	// 			toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> sending...</div>),
	// 				{
	// 					position: 'bottom-left',
	// 					duration: null
	// 				},
	// 			)
	// 			setShowComposeModal({
	// 				...showComposeModal,
	// 				show: false,
	// 				type: '',
	// 				title: '',
	// 				emailData: null,
	// 			});
	// 		}, 300);


	// 		console.log('emailObj', emailObj);
	// 		const nylasSendDraft = window.firebase
	// 			.functions()
	// 			.httpsCallable('nylasv3-sendEmail');
	// 		const resp = await nylasSendDraft(emailObj);
	// 		console.log('nylasSendDraft', resp);
	// 		if (resp.data.success) {
	// 			setSendLoader(false);
	// 			toast.closeAll();
	// 			toast.notify('Email sent successfully.', {
	// 				duration: 2000,
	// 			});
	// 		} else {
	// 			toast.closeAll();
	// 			setSendLoader(false);
	// 			if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
	// 				Swal.fire({
	// 					title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
	// 					icon: 'info',
	// 					showCancelButton: true,
	// 					confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
	// 					cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
	// 				}).then(result => {
	// 					if (result.value) {
	// 						window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
	// 							nylas: null,
	// 						}, { merge: true });
	// 						history.push('/settings/profile?key=emailsettings');
	// 					}
	// 				});
	// 				return
	// 			}
	// 			else {
	// 				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');
	// 			}
	// 		}

	// 		// console.log('response', resp);
	// 	} catch (error) {
	// 		setSendLoader(false);
	// 		console.log(error);
	// 		toast.closeAll();
	// 		Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');

	// 	}
	// };
	//#endregion DRAFTS

	return (
		<div className='email-list-left'>
			{
				nylas_accountData?.syncLog?.syncing ? (<div className="alert alert-primary" role="alert">
					<span className="spinner-border spinner-button mr-2 mt-1" role="status" aria-hidden="true"></span>
					<Translate text={'We are syncing your email account with AMS Pro'} />
				</div>) : nylas_accountData?.syncLog?.success === false && nylas_accountData?.grantId ? (
					<div className="mfa-red mt-2">
						<div className="float-left mt-1">
							<i className="ico icon-settings-security"></i>Sync Failed </div>
						<div className="float-right ">
							<button onClick={() => {
								window.firebase.firestore().doc(`nylasv3-accounts/${nylas_accountData.grantId}`).update({
									syncLog: {
										syncing: true
									}
								})
								// const syncThreads = window.firebase.functions().httpsCallable('nylasv3-syncThreads');
								// syncThreads({ grantId: nylas_accountData.grantId })
							}} type="button" className="btn btn-default float-left ml-2 mfa-enable-btn">Sync</button>
						</div>
					</div>
				) : (<></>)
			}
			<div className='email-list-filter'>
				<Dropdown
					className='mailbox-filter-white-btn email-muti-check float-left dropdown-toggle'
					alignRight
				>
					{/* Add float right and d-inline by myself */}
					<div className='checkbox icheck-success muticheck-fix'>
						<input type='checkbox' id='successaa' checked={threadsSelected} onChange={() =>
							handleSelectThreads(threadsSelected ? 'none' : 'all')
						} />
						<label
							htmlFor='successaa'
						></label>
					</div>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('all')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('none')}
						>
							<div className='pipeline-check'> </div>
							None
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unstarred')}
						>
							<div className='pipeline-check'> </div>
							Unstarred
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* <div className='filter-email-btn ml-2'>
					<a href='#'>
						<i className='ico icon-filter'></i>
						<span>Filter </span>
						<i className='ico icon-dropdown'></i>
					</a>
				</div> */}
				<Dropdown
					className='mailbox-filter-white-btn  ml-1 float-left dropdown-toggle'
					alignRight
				>
					<div className="nylas-filter-btn">
						<Dropdown.Toggle
							as={CustomToggle}
							id='dropdown-custom-components'
							className={['read', 'unread', 'starred', 'attachment'].includes(filter) ? 'filter-active' : ''}
						>
							<i className='ico icon-filter'></i> <span>
								{`${filter === 'read' ? 'Read' :
									filter === 'unread' ? 'Unread' :
										filter === 'attachment' ? 'Attachment' :
											filter === 'starred' ? "Starred" : 'All'}`}</span>
							<i className='ico icon-dropdown'></i>
						</Dropdown.Toggle>
						{/* {
							!_.isEmpty(filter)
								?
								<>
									<a href="#" className="clear-btn" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-remove"></i> </a>
								</>
								:
								<></>
						} */}
					</div>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className={`current-pipeline ${(filter === 'clear' || _.isEmpty(filter)) ? 'active' : ''}`}
							onClick={() => handleFilter('clear')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'read' ? 'active' : ''}`}
							onClick={() => handleFilter('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'unread' ? 'active' : ''}`}
							onClick={() => handleFilter('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'starred' ? 'active' : ''}`}
							onClick={() => handleFilter('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'attachment' ? 'active' : ''}`}
							onClick={() => handleFilter('attachment')}
						>
							<div className='pipeline-check'> </div>
							Attachment
						</Dropdown.Item>

					</Dropdown.Menu>
				</Dropdown>

				{/* @info: Mark Dropdown */}
				<Dropdown
					className='mailbox-filter-white-btn  ml-1 float-left dropdown-toggle'
					alignRight
				>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i> <span>Mark</span>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('read', setThreadsSelected)}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('unread', setThreadsSelected)}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<div className='mail-refresh float-right'>
					<a href='#' onClick={(e) => { e.preventDefault(); refreshThreads(type) }}>
						<i className='ico icon-refresh custom-spin'></i>
					</a>
				</div>
			</div>
			<div className='email-list-search'>
				<div className='input-group '>
					<input
						type='text'
						className='form-control'
						placeholder='Search'
						value={searchMessage}
						onChange={e => {
							setSearch(_.trim(e.target.value));
						}}
						onKeyPress={handleKeyPress}
					/>
					<div className='input-group-append input-search-clear'>
						{searchText.length > 0 && (
							<a
								href='#'
								className='input-search-clear-icon'
								onClick={(e) => {
									e.preventDefault();
									setSearch('');
									searchThreads('');
								}}
							>
								<i className='ico icon-remove'></i>
							</a>
						)}
						<button
							className='btn btn-default'
							type='button'
							onClick={e => {
								searchThreads(searchMessage);
							}}
						>
							{' '}
							<i className='ico icon-search-fill'></i>{' '}
						</button>
					</div>
				</div>
			</div>

			{threadsLoader ? (
				<div
					style={{
						minHeight: '80vh',
						//display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{CommonHelper.emailPlaceholder(4)}
				</div>
			) : (
				<div className='email-list-item-wrap'>
					{threads && threads.length > 0 ? (
						threads.map(thread => (
							<div
								key={thread.id}
								className={`email-list-item email-list-box email-list-nowrap position-relative ${(!isEmpty(selectedThread) && selectedThread.id === thread.id) ? 'active' : ''} ${thread.unread === true ? 'unread' : ''}`}
								onDoubleClick={() => {
									console.log('dealersettings', dealersettings)
									console.log('thread', thread)
								}}

							>
								<div className='email-list-flex email-list-checkbox'>
									<div className='checkbox icheck-success'>
										<input
											type='checkbox'
											id={`success-${thread.id}`}
											checked={thread.selected && thread.selected === true}
											onChange={() =>
												selectThreads(
													thread.selected === true
														? 'single-unselect'
														: 'single',
													thread.id,
													filteredThreads,
													setFilteredThreads,
													setThreadsSelected,
												)
											}
										/>
										<label
											htmlFor={`success-${thread.id}`}
										></label>
									</div>
								</div>
								<div className='email-list-flex email-list-grid email-list-hidden' id={`thread-${thread.id}`}
									onClick={() => {
										setSelectedThread(thread)
										if (type === CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Drafts', 'id') || !_.isEmpty(thread.draftIds)) {
											handleOpenComposeModal({
												type: 'compose',
												title: 'New Message',
												thread: thread,
											});
										}

									}
									}

								>
									<div className='email-list-item email-list-item-title'>
										<div className='email-list-flex email-list-grid'>
											<h6 className='email-list-text'>
												{
													important_id && (<span className="mail-icon-important"
														onClick={() => { updateThread(thread, `${thread.folders.includes(important_id) ? 'notimportant' : 'important'}`, {}) }}
													>
														{
															thread.folders.includes(important_id)
																?
																<i className="ico icon-mail-important-active"> </i>
																:
																<i className="ico icon-mail-important-linear"> </i>
														}

													</span>)
												}

												{type === CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Sent', 'id') ? (
													<>
														{truncate(
															`To: ${thread.participants
																.filter(
																	participant =>
																		participant.email !== nylasEmail,
																)
																.map(participant => participant.email)
																.join(',')}`,
															{
																length: 25,
															},
														)}
														{/* latestDraftOrMessage */}
													</>
												) : (
													truncate(getNameFromParticipants((!thread.folders.includes(CommonHelper.getNylasFolder(dealersettings?.nylasAccountData?.folders, 'Sent', 'id')) ? (thread?.latestDraftOrMessage?.from || thread.participants) : thread.participants), nylasEmail), { length: 28, })
												)}{' '}
												<span className='mail-count'>
													{thread?.messageIds?.length > 1 &&
														thread?.messageIds?.length}
												</span>
											</h6>
										</div>
										{
											thread.hasAttachments && (<span className="mail-attach-icon"><i className="ico icon-mail-attachment-linear"></i></span>)
										}

										<div className='email-list-flex email-datetime'>
											<small>
												{
													thread.latestMessageReceivedDate ? CommonHelper.calculateEmailAge(thread.latestMessageReceivedDate)
														: thread.latestMessageSentDate ? CommonHelper.calculateEmailAge(thread.latestMessageSentDate)
															: ''
												}

											</small>
										</div>
									</div>
									<h5 className='email-list-head'>{thread.subject || '-- no subject --'}</h5>
									<span
										className='email-list-content email-list-hidden'
									//style={{ paddingRight: '20px', position: 'relative' }}
									>
										{thread.snippet}

										<span onClick={() => { updateThread(thread, `${thread.starred ? 'unstar' : 'star'}`, {}) }}
											className='mail-icon-fav'
										>
											<i
												className={`ico icon-star${thread.starred ? '-active' : ''
													}`}
											></i>
										</span>
									</span>
								</div>
							</div>
						))
					) : (
						<h6 className='text-center pt-3'>
							We didn't find any emails to show here.
						</h6>
					)}

					{_.isEmpty(hasMore.filter(e => e.folder === type)) ? (
						<></>
					) : (
						<div className='email-load-more w-100 d-flex justify-content-center align-items-center py-3'>
							<button
								className='btn btn-primary'
								onClick={() => loadMoreThreads(type)}
							>
								{loadingMore && (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								)}
								Load more
							</button>
						</div>
					)}


				</div>
			)}


			{/* <PopUpModal show={showComposeModal.show}>
				<ComposeDraft
					show={showComposeModal.show}
					handleClose={handleCloseComposeModal}
					token={token}
					type={showComposeModal.type}
					title={showComposeModal.title}
					emailData={showComposeModal.emailData}
					thread={showComposeModal.thread}
					userData={userData}
					sendLoader={sendLoader}
					setSendLoader={setSendLoader}
					// handleReply={handleReply}
					handleSend={handleSend}
					structureType={structureType}
					emailtemplates={emailtemplates}
					dealersettings={dealersettings}
				></ComposeDraft>
			</PopUpModal> */}
		</div>
	);
};

export default EmailList;
