/** LIBRARIES */
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import { Modal, Tab, Tabs } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { Progress } from 'antd';
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import { InputText, ImageViewer, PopUpModal, ImageCropHook, ReactSelect } from '../../../../components';
import Translate from '../../../../constants/translate';

import { objCafeItem, mandatoryFields } from './viewModel'
import { useDropzone } from 'react-dropzone';
import { storageBucket } from '../../../../services/helper';
import CommonHelper from '../../../../services/common';
import uuid from 'react-uuid'

const AddCafeItem = props => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
        accept: `.jpeg, .jpg, .png, image/png, image/jpeg, image/jpg`
    });
    const [cropShow, setCropShow] = useState({
        show: false,
        clsActive: '',
        src: null,
        id: '',
        type: '',
        ext: '',
        title: ''
    })
    const [state, setState] = useState({
        fields: Object.assign({}, objCafeItem),
        errors: {},
        fileName: '',
        fileEXT: 'jpg',
        progress: 0,
    })
    const [isLoading, setIsLoading] = useState(false)

    const [errorFields, setErrorFields] = useState({});
    const [addSubList, setAddSubList] = useState({ value: '', id: uuid() })
    const [subList, setSubList] = useState(!_.isEmpty(props.cafeItems) && !_.isEmpty(props.cafeItems.subList) ? props.cafeItems.subList.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);
    const [editSubList, setEditSubList] = useState()
    const [textEditor, setTexteditor] = useState({})
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });


    const ref = window.firebase.firestore().collection('cafeItems');

    useEffect(() => {
        const { Item } = props;

        if (!_.isEmpty(Item)) {
            setState({ ...state, fields: Item })
            setSubList(Item.subList);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(acceptedFiles))
            return

        const validateFiles = files => {
            // console.log('acceptedFiles', acceptedFiles)
            let filteredFiles = [];

            acceptedFiles.forEach(file => {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
                } else {
                    filteredFiles.push(file)
                }
            })

            if (filteredFiles.length > 0) {
                onSelectFile(filteredFiles)
            }
        }

        validateFiles(acceptedFiles)
    }, [acceptedFiles])

    //#region CHANGE EVENT

    const handleSelectChange = (e, data) => {
        let evt = e;
        if (data.action === 'clear' && !e) {
            evt = { value: '' };
        }
        let fields = Object.assign({}, state.fields);

        if (evt) {
            fields[data.name] = evt.value
            setState({ ...state, fields: fields });
        }
    };

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setState({ ...state, fields: fields });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setState({ ...state, fields: fields });
        }

    }

    const errorChange = (key) => {
        let errors = Object.assign({}, state.errors);
        let fields = Object.assign({}, state.fields);
        let errorClass = 'dashed_error';

        if (errors !== {}) {
            if (!fields[key] && mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            setState({ ...state, errors: errors });
        }

    }
    //#endregion

    //#region IMAGE CROP
    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })

    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext, fileName } = cropShow;

        const fileVM = Object.assign({}, state.fields);
        fileVM.image = src;
        setState({ ...state, fields: fileVM, progress: 0, fileName: fileName, fileEXT: ext, imageURL: src, });

        //saveImageToStorage(src, id, ext);
        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: '',
            fileName: ''
        })
    }

    const onSelectFile = (files) => {
        if (files && files.length > 0) {

            var _size = files[0].size;
            if (!files[0].name.match(/.(jpeg|jpg|png|JPEG|PNG|JPG)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
                return;
            }

            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            if (document.querySelector('.upload-iconwrap').classList.contains('border-color-red')) {
                document.querySelector('.upload-iconwrap').classList.remove('border-color-red')
            }

            let reader = new FileReader();
            let file = files[0];
            //var extension = file.type
            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: 'test123',
                    type: file.type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: 'test',
                    fileName: file.name
                })
            }
            reader.readAsDataURL(file)
        }
    }

    //#endregion

    //#region FILE UPLOAD

    const handleFileRemove = (e) => {
        e.preventDefault();

        // document.getElementById("fileUpload").value = "";
        const fileVM = Object.assign({}, state.fields);
        fileVM.image = null;
        setState({ ...state, fields: fileVM })
    }

    //#endregion

    //#region SAVE & SUBMIT 
    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, state.fields);
        let errorClass = 'dashed_error';

        for (let [key] of Object.entries(fields)) {
            if (mandatoryFields.indexOf(key) >= 0 && !fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        console.log('errors', errors)
        if (errors.image === 'dashed_error') {
            document.querySelector('.upload-iconwrap').classList.add('border-color-red')
        }

        setState({ ...state, errors: errors });

        if (formIsValid === true) {
            addFile();
        }
        else {

            return formIsValid;
        }
    };

    const addFile = (e) => {
        setIsLoading(true)
        const objData = Object.assign({}, state.fields);

        let uid = moment()._d.getTime().toString() + '.' + state.fileEXT;
        let clientID = props.clientID ? props.clientID : '';
        if (clientID && objData.image && objData.image.indexOf('base64') >= 0) {
            var storageRef = storageBucket(props.dealersettings).ref(`${clientID}/cafeItems/${uid}`).putString(objData.image, 'data_url');
            storageRef.on('state_changed', (snapshot) => {
                //console.log(snapshot);
                var percentage = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);

                if (percentage > 0 && percentage < 99) {
                    setState({ ...state, progress: percentage })
                }
            },
                (error) => {
                    console.error(error);
                },
                () => {
                    storageBucket(props.dealersettings).ref(`${clientID}/cafeItems`).child(uid).getDownloadURL()
                        .then(dataURL => {
                            //console.log(dataURL)
                            setState({ ...state, progress: 100 });
                            objData.image = dataURL
                            handleSaveCafe(objData);
                        })

                })
        }
        else {
            handleSaveCafe(objData);
        }
    };

    const handleSaveCafe = (objData) => {
        let clientID = props.clientID ? props.clientID : '';
        let isNew = false;
        if (!objData.clientID)
            objData.clientID = clientID

        if (!objData.documentID)
            objData.documentID = ref.doc().id;

        if (!objData.documentID) {
            isNew = false
        } else {
            isNew = true
        }

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        if (isNew) {
            if (props.cafeItems.length) {
                objData.sortIndex = props.cafeItems.length + 1
            } else {
                objData.sortIndex = 0
            }
        }
        //console.log('handleSaveCafe', objData)
        const updateRef = window.firebase.firestore().collection(`cafeItems`).doc(objData.documentID);
        updateRef.set(objData).then((docRef) => {
            //console.log(docRef);
            setIsLoading(false)
            toast.notify('Cafe Item saved successfully', {
                duration: 2000
            })
            props.handleClose(objData.documentID);

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });
    }

    //#endregion
    const baseStyle = {
        width: '100%',
        background: '#f2f7ff',
        border: '2px dashed #a7cbff',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#4466f2',
    };
    const baseBlackStyle = {
        width: '100%',
        background: '#eeeff2',
        border: '2px dashed #808080',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#00000',
    };
    const activeStyle = {
        border: '2px dashed #2bb673',
    };

    const acceptStyle = {
        borderColor: '#2bb673',
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...(props?.dealersettings?.isPorsche ? baseBlackStyle : baseStyle),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    //#subItem Region
    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setAddSubList({
            ...addSubList,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};

        // Validation for the 'value' property only
        if (_.isEmpty(rec['value']) || _.isEmpty(rec['value'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistvalue'] = errorClass;
            else
                errors['editsublistvalue'] = errorClass;
        }

        setErrorFields(errors);

        if (!formIsValid) {
            return;
        }

        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];

        setSubList(addList);
        setState({
            ...state,
            fields: {
                ...state.fields,
                subList: addList
            }
        });
        setAddSubList({ value: '', id: uuid() });
        setEditSubList(null);
        setTexteditor({});
    };


    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setEditSubList({
            ...editSubList,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.id !== rec.id)];
                setSubList(deleteList)
                setState({
                    ...state,
                    fields: {
                        ...state.fields,
                        subList: deleteList
                    }
                });
            }
        })
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-file-manager"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="form-style w-100">
                                <div className="form-row ">
                                    <div className="form-group col-md-6">
                                        <label > <Translate text={'Item Name'} /></label>
                                        <InputText
                                            type="text"
                                            name="name"
                                            value={state.fields.name ? state.fields.name : ''}
                                            onChange={handleOnChange}
                                            autoComplete="off"
                                            placeholder={'Enter item name'}
                                            className={`form-control ${state.errors["name"] ? 'input_error' : ''}`}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label> <Translate text={'Category'} /></label>
                                        <ReactSelect
                                            options={props.cafeCategories}
                                            name={'category'}
                                            placeholder={'select category'}
                                            onChange={handleSelectChange}
                                            value={state.fields.category ? state.fields.category : ''}
                                            classNamePrefix={`${state.errors["category"] ? 'input_error' : ''} basic-select`}
                                        ></ReactSelect>
                                    </div>
                                </div>
                            </div>

                            <div className="upload-file-box">
                                {/* <div className={`upload-box ${state.errors["image"]}`}>
                                        <label className="upload-iconwrap" htmlFor="fileUpload" style={{ margin: 0, cursor: 'pointer' }}>
                                            <i className="ico icon-files-upload"></i>
                                            <span className="upload-text"><Translate text={'Upload Image'} /></span>
                                            <input
                                                type="file"
                                                id="fileUpload"
                                                style={{ display: 'none' }}
                                                //onChange={(e) => onSelectFile(e)}
                                                onChange={(e) => onSelectFile(e, 'cafeItems', 'image', 'Cafe Item Image')}
                                            />

                                        </label>
                                    </div> */}
                                <div {...getRootProps({ style, className: 'upload-iconwrap upload-box-dragndrop' })}>
                                    <input {...getInputProps()} />
                                    <i className="ico icon-files-upload"></i>
                                    <p><Translate text="Drag 'n' drop some files here, or click to upload files" /></p>
                                </div>

                                {
                                    state.fields.image
                                        ?
                                        <div className="uploaded-list-video">
                                            <div className="flex-upload">
                                                <div className="upload-clear">
                                                    <a href="#" onClick={(e) => handleFileRemove(e)} className={`${isLoading ? 'form-disable' : ''}`}>
                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div className="file-upload-icon">
                                                    <i className="ico icon-files-image"></i>
                                                    {/*
                                                            showFileIcon(type)
                                                        */}
                                                </div>
                                                <a data-fancy-box={'cafe-tem-image'}
                                                    href={state.fields.image}
                                                    className="upload-data"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setImgViewer({
                                                            show: true,
                                                            images: state.fields.image
                                                        })

                                                    }}>

                                                    {state.fileName ? state.fileName : (state.fields.name ? state.fields.name : 'Cafe Item Image')}
                                                    {/* {' (' + CommonHelper.showFileSize(size) + ')'} */}
                                                </a>
                                            </div>
                                            {isLoading ? <Progress percent={state.progress} /> : <></>}
                                        </div>
                                        :
                                        <></>
                                }


                            </div>
                            <div className="settings-tab-wrap tabs col-md-12 p-0">
                                <div className="tabs-wraper">
                                    <nav>
                                        <Tabs className="nav-fill">
                                            <Tab eventKey="cafeSubItems" title="Notes">
                                                <div className="common-table w-100 mt-2">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="head-light" width="90%"> <Translate text={'name'} /></th>
                                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div className="">
                                                                    <InputText
                                                                        id="sublistvalue"
                                                                        autoComplete="off"
                                                                        placeholder={'Enter sub-item name'}
                                                                        className={`form-control ${errorFields["sublistvalue"]}`}
                                                                        name="value"
                                                                        onChange={handleOnSubChange}
                                                                        value={addSubList.value}
                                                                    />
                                                                </div></td>
                                                                <td className="border-right-0">
                                                                    <div className="button-height-fix">
                                                                        <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {
                                                                subList.length > 0 ?
                                                                    subList.map((rec, index) => {
                                                                        return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                            <td>
                                                                                {
                                                                                    textEditor[rec.id] ? (<><InputText
                                                                                        id="editsublistvalue"
                                                                                        autoComplete="off"
                                                                                        placeholder={'value'}
                                                                                        className={`form-control ${errorFields["editsublistvalue"]}`}
                                                                                        name="value"
                                                                                        onChange={handleOnEditSubChange}
                                                                                        value={editSubList.value}
                                                                                    /></>) : (<>{rec.value}</>)
                                                                                }
                                                                            </td>
                                                                            <td className="border-right-0">
                                                                                <div className="action-fix">
                                                                                    {
                                                                                        textEditor[rec.id] ?
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                            </>) :
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                            </>)
                                                                                    }

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                    : (<></>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </nav>
                                </div>
                            </div>




                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>


            <PopUpModal show={cropShow.show}>
                <ImageCropHook
                    cropShow={cropShow}
                    handleClose={handleCropClose}
                    handleSelect={handleCropSelect}
                ></ImageCropHook>
            </PopUpModal>
            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>

        </>


    )
}

export default AddCafeItem