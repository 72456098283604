import React, { useState, useEffect } from 'react';
import { CustomTable } from '../../components';
import _ from 'lodash';
import Translate from '../../constants/translate';
import { Modal } from 'react-bootstrap';
import { tagColors } from './viewModel';

const cols = [
    { name: 'Colour', value: 'value', type: 'colorpicker', mandatory: true, width: '20' },
    { name: 'Name', value: 'name', type: 'text', width: '70', mandatory: true }
]

const CellColorSettings = (props) => {
    const [btnloader, setbtnloader] = useState(false)
    const [state, setState] = useState(props?.deallogSettings?.fieldColors?.[props.params.column.colId] || tagColors)

    const handleSave = (e) => {
        setbtnloader(true)
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .set({
                fieldColors: {
                    [props.params.column.colId]: state
                }
            }, { merge: true })
            .then(() => {
                props.handleClose({
                    [props.params.column.colId]: state
                });
            })
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName={`modal-dialog-scrollable modal-dialog-centered modal-deallog-history-new`}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <CustomTable
                        cols={cols}
                        subList={state}
                        setSubList={(list) => { setState(list) }}
                        dragDisabled={true}
                    />
                    <div className="mt-2 w-100 d-inline-block">
                        <button type="button" onClick={handleSave} className="btn btn-primary float-right d-flex align-items-center justify-content-center">
                            {
                                btnloader ? (
                                    <span
                                        className='spinner-border spinner-button mr-1'
                                        role='status'
                                        aria-hidden='true'
                                    ></span>
                                ) : (
                                    <></>
                                )
                            }
                            <Translate text={'save'} />
                        </button>
                    </div>
                </div>

            </Modal.Body>
        </Modal >
    );
};

export default CellColorSettings;