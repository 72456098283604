import React, { useState, useEffect, useContext } from 'react'
import { Modal, Tabs, Tab, Dropdown } from 'react-bootstrap'
import { InputText, ReactSelect, ImageCropHook, ReactMultiSelect, AutoComplete, InputCheckBox, ImageViewer, PopUpModal } from '../../../../components'
import Translate from '../../../../constants/translate';
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { mandatoryFields, wholesalerVM } from '../users/viewModel'
import _ from 'lodash'
import { default as _images } from "../../../../images";
import { storageBucket, validateEmail } from "../../../../services/helper";
import moment from 'moment';
import { ClientContext } from '../clientContext'
import CommonHelper from '../../../../services/common';
const AddWholesaler = (props) => {
    const [wholesaler, setWholesaler] = useState(props.wholesaler);
    const [loader, setLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageLoader, setImageLoader] = useState(false)
    const [showPasswordModel, setShowPasswordModel] = useState(false)
    const newWholesaler = !_.isEmpty(props.wholesaler) ? false : true
    const oldEmailaddress = !_.isEmpty(props.wholesaler) ? props.wholesaler.email : ''
    const oldPhoneNo = !_.isEmpty(props.wholesaler) ? props.wholesaler.phone : ''
    const oldEnableMFA = !_.isEmpty(props.wholesaler) ? props.wholesaler.enableMFA : ''
    const [userClients, setUserClients] = useState([])
    const [mandFields, setMandFields] = useState(mandatoryFields)
    const [key, setKey] = useState('login')
    const [phoneCodes, setphoneCodes] = useState([])
    const [showMFAEnableModel, setShowMFAEnableModel] = useState(false)
    const [mfaLoader, setmfaLoader] = useState(false);
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const { clients } = useContext(ClientContext);
    const enableTwoFactorAuth = props.dealersettings
        && props.dealersettings.client
        && props.dealersettings.client.securitysettings ? Boolean(props.dealersettings.client.securitysettings.enableTwoFactorAuth) : false;

    useEffect(() => {
        if (_.isEmpty(props.wholesaler)) {
            const newwholesalerVM = Object.assign({}, wholesalerVM);
            newwholesalerVM.addedDate = window.firebase.firestore.Timestamp.now();
            newwholesalerVM.addedBy = localStorage.uid;
            newwholesalerVM.isWholesaler = true;
            newwholesalerVM.enableMFA = true;

            let countryCode = props.dealersettings && props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            let phone_code = countryCode && props.dealersettings.countries && props.dealersettings.countries.filter(m => m.code === countryCode)[0];
            if (!_.isEmpty(phone_code)) {
                newwholesalerVM.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
            }
            setWholesaler(newwholesalerVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clients))
            return
        setUserClients(clients.filter(c =>
            c.moduleSettings
            && c.moduleSettings.tradeinPro
            && c.moduleSettings.tradeinPro.enabled === true
            && c.moduleSettings.tradeinPro.active === true
            && _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), c.documentID) >= 0))
    }, [clients])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client)) {
            let _phoneCodes = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                if (doc.phone_code) {
                    _phoneCodes.push({
                        country_code: doc.code,
                        value: doc.phone_code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    });
                }
            });
            setphoneCodes(_phoneCodes);
        }

    }, [props.dealersettings])


    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'password' || name === 'cpassword') {
            setWholesaler({
                ...wholesaler,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setWholesaler({
                    ...wholesaler,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setWholesaler({
                    ...wholesaler,
                    [name]: str
                });
            }
        }

        errorChange(name);

    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            if (data.name === 'roleID') {
                setWholesaler({
                    ...wholesaler,
                    [data.name]: e.value,
                    ['role']: e.doc.name,
                    ['level']: e.doc.level,
                });
            }
            else if (data.name === 'regionID' || data.name === 'groupID') {
                setWholesaler({
                    ...wholesaler,
                    [data.name]: e.value,
                    ['wholesalerClientID']: []
                });
            }
            else {
                setWholesaler({
                    ...wholesaler,
                    [data.name]: e.value
                });
            }
        }
        else {
            if (data.name === 'regionID' || data.name === 'groupID') {
                setWholesaler({
                    ...wholesaler,
                    [data.name]: null,
                    ['wholesalerClientID']: []
                });
            }
        }
    }

    const handleReactMultiSelectChange = (selectedOptions) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
        })

        setWholesaler({
            ...wholesaler,
            ['wholesalerClientID']: _.uniq([...wholesaler.wholesalerClientID.filter(a => _.indexOf(clients.map(v => _.pick(v, ['id']).id), a) < 0), ...value])
            // ['arrayRegionID']: user.level === 'region' ? value : [],
            // ['arrayGroupID']: user.level === 'group' ? value : []
        });
    }

    const errorChange = (key) => {
        let errorClass = 'input_error';

        if (errorFields !== {}) {
            if (!wholesaler[key] && mandFields.indexOf(key) >= 0) {
                setErrorFields({
                    ...errorFields,
                    [key]: errorClass
                });
            }
            else {
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            setErrorFields({
                                ...errorFields,
                                [key]: errorClass
                            });
                        }
                        else
                            setErrorFields({
                                ...errorFields,
                                [key]: ''
                            });
                    }
                }
                else
                    setErrorFields({
                        ...errorFields,
                        [key]: ''
                    });
            }


        }

    }

    const saveWholesaler = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key, value] of Object.entries(wholesaler)) {
            if (mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(wholesaler[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        if (_.isEmpty(wholesaler.wholesalerClientID)) {
            formIsValid = false;
            errors['wholesalerClientID'] = errorClass;
        }
        if (wholesaler.personelEmail && !validateEmail(wholesaler.personelEmail)) {
            formIsValid = false;
            errors['personelEmail'] = errorClass;
            setKey('other')
        }
        if (wholesaler.enableMFA === true) {
            if (_.isEmpty(wholesaler['phone']) || _.isEmpty(wholesaler['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (newWholesaler === true) {
            const addWholesaler = window.firebase.functions().httpsCallable('amsadmin-createUser');
            addWholesaler({
                "email": wholesaler.email,
                "password": window.firebase.firestore().collection(`users`).doc().id,
                "phone": `+${wholesaler.phoneCode.replace('+', '')}${wholesaler.phone}`,
                "enableMFA": wholesaler.enableMFA,
                "name": wholesaler.name
            }).then(async (data) => {
                //console.log(data)
                if (data.data.success === true) {
                    wholesaler.documentID = data.data.data;
                    updateWholesaler(wholesaler)
                }
                else {
                    const existingWholesaler = await window.firebase.firestore().collection('users')
                        .where('email', '==', wholesaler.email)
                        //.where('isWholesaler', '==', true)
                        .limit(1)
                        .get();
                    if (existingWholesaler.docs && existingWholesaler.docs.length > 0) {
                        setWholesaler(existingWholesaler.docs[0].data());
                        updateWholesaler(existingWholesaler.docs[0].data(), true);
                    }
                    else {
                        setLoader(false)
                        Swal.fire(data.data.message, '', 'error')
                    }
                }
            });
        }
        else {
            if (wholesaler.email !== oldEmailaddress) {
                const updateWholesalerEmail = window.firebase.functions().httpsCallable('amsadmin-updateUserEmail');
                const resp = await updateWholesalerEmail({
                    "email": wholesaler.email,
                    "uid": wholesaler.documentID
                });
                if (resp.data.success === true) {
                    //user.documentID = data.data.data; 
                }
                else {
                    setLoader(false)
                    Swal.fire(resp.data.message, '', 'error')
                    return;
                }
            }
            if (wholesaler.phone !== oldPhoneNo || wholesaler.enableMFA !== oldEnableMFA) {
                const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
                const resp = await enrollMFA({
                    "uid": wholesaler.documentID,
                    "phone": `+${wholesaler.phoneCode.replace('+', '')}${wholesaler.phone}`,
                    "enroll": wholesaler.enableMFA
                })
                if (resp.data.success === true) {

                }
                else {
                    setLoader(false)
                    let msg = resp.data.message;
                    if (resp.data.data.errorInfo && resp.data.data.errorInfo.code === 'auth/invalid-phone-number') {
                        msg = 'Invalid phone number.'
                    }
                    Swal.fire(msg, '', 'error');
                    return;
                }
            }
            updateWholesaler(wholesaler);
        }
    }

    const updateWholesaler = (_wholesaler) => {
        if (_.isEmpty(_wholesaler) || _.isEmpty(_wholesaler.documentID)) {
            Swal.fire(CommonHelper.showLocale(props, 'Invalid wholesaler.'), '', 'info')
            return
        }
        _wholesaler.modifiedDate = window.firebase.firestore.Timestamp.now();
        _wholesaler.modifiedBy = localStorage.uid;
        _wholesaler.wholesalerOEMID = _.uniq([...(_wholesaler.wholesalerOEMID ? _wholesaler.wholesalerOEMID : []), props.dealersettings.oemID]);
        _wholesaler.wholesalerClientID = _.uniq([...(_wholesaler.wholesalerClientID ? _wholesaler.wholesalerClientID : []), ...wholesaler.wholesalerClientID]);
        _wholesaler.isWholesaler = true;
        delete _wholesaler.password
        delete _wholesaler.cpassword

        window.firebase.firestore().doc(`users/${_wholesaler.documentID}`).set(_wholesaler, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newWholesaler === true ? 'Wholesaler added successfully' : 'Wholesaler updated successfully'), {
                    duration: 2000
                })
                if (newWholesaler === true) {
                    window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Wholesaler Settings has been updated',
                        }, { merge: true })
                }
                props.handleClose(_wholesaler)
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {

                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        setWholesaler({
            ...wholesaler,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);


        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        setImageLoader(true)
        var storageRef = storageBucket(props.dealersettings).ref(`users/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`users`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setWholesaler({
                            ...wholesaler,
                            [id]: dataURL
                        });
                    })
            })
    };


    const bindAddress = (place) => {
        var objWholesaler = wholesaler;
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };
        if (place.formatted_address !== null)
            objWholesaler = {
                ...objWholesaler,
                ['address']: place.formatted_address
            };

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "administrative_area_level_1")
                    objWholesaler = {
                        ...objWholesaler,
                        ['state']: val
                    };
                if (addressType === "postal_code")
                    objWholesaler = {
                        ...objWholesaler,
                        ['areaCode']: val
                    };
                if (addressType === "country")
                    objWholesaler = {
                        ...objWholesaler,
                        ['country']: val
                    };
            }
        }
        setWholesaler(objWholesaler);
    };

    const handlePhoneChange = e => {
        const { name, value } = e.target;
        const phoneReg = /^[0-9]+$/;
        if (phoneReg.test(value) || value === '') {
            setWholesaler({ ...wholesaler, [name]: value });
        }
    };

    const handleAddPhoneCode = (name, value) => {
        let val = value ? value : '';
        setWholesaler({ ...wholesaler, [name]: val });
    };

    const handleChange = e => {
        const { value, name } = e.target;
        setWholesaler({ ...wholesaler, [name]: value });

        setErrorFields({
            ...errorFields,
            [name]: ''
        })
    };

    const emailVerification = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _mandFields = ["name", "email"];
        for (let [key] of Object.entries(wholesaler)) {
            if (_mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(wholesaler[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setVerifyLoader(true);
        try {
            const emailVerificationLink = window.firebase.functions().httpsCallable('amsadmin-sendNewUserEmail');
            emailVerificationLink({
                "email": wholesaler.email,
                "name": wholesaler.name,
                "settingsID": props.dealersettings.oemID
            }).then((data) => {
                setVerifyLoader(false);
                if (data.data.success === true) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setVerifyLoader(false);
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong. Please contact support.'), '', 'error')
        }
    }

    const handleMFAChange = (cheked) => {
        if (cheked) {
            setShowMFAEnableModel(true);
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to disable multi-factor authentication.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then((result) => {
                if (result.value) {
                    handleEnableMFA(cheked)
                }
            })

        }
    }

    const handleMFAModelClose = (wholesaler) => {
        setShowMFAEnableModel(false);
        if (wholesaler)
            setWholesaler({
                ...wholesaler,
                phone: wholesaler.phone,
                phoneCode: wholesaler.phoneCode
            })
    }

    const handleEnableMFA = (checked) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (checked) {
            if (_.isEmpty(wholesaler['phone']) || _.isEmpty(wholesaler['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
            setErrorFields(errors)
            if (!formIsValid) {
                return;
            }
        }
        setmfaLoader(true);
        try {
            const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
            enrollMFA({
                "uid": wholesaler.documentID,
                "phone": `+${wholesaler.phoneCode.replace('+', '')}${wholesaler.phone}`,
                "enroll": checked
            }).then((data) => {
                setmfaLoader(false);
                if (data.data.success === true) {
                    let updateWholesaler = {
                        phoneCode: wholesaler.phoneCode,
                        phone: wholesaler.phone,
                        enableMFA: checked
                    }
                    setWholesaler({
                        ...wholesaler,
                        ...updateWholesaler
                    })
                    setShowMFAEnableModel(false);
                    window.firebase.firestore().doc(`users/${wholesaler.documentID}`).set(updateWholesaler, { merge: true });
                    toast.notify(`Multi-factor authentication ${checked ? 'enabled' : 'disabled'} successfully.`, {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setmfaLoader(false);
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong. Please contact support.'), '', 'error')
        }
    }

    const handleCheckChange = (e) => {
        if (e.target.name === 'enableMFA')
            setWholesaler({
                ...wholesaler,
                [e.target.name]: enableTwoFactorAuth ? true : e.target.checked
            })
        else
            setWholesaler({
                ...wholesaler,
                [e.target.name]: e.target.checked
            })
    }


    return _.isEmpty(wholesaler) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-user"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="form-row settings-profile pt-2">

                                    <div className="settings-profileimage">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader pl-2">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (!_.isEmpty(wholesaler.profileImage))
                                                ?
                                                <a data-fancy-box={`profileImage`} 
                                                href={wholesaler.profileImage} 
                                                onClick={(e) => { e.preventDefault(); 
                                                    setImgViewer({
                                                        show: true,
                                                        images: wholesaler.profileImage
                                                    })

                                                }}>
                                                    <img src={wholesaler.profileImage} alt="" className="rounded-circle img-object-fit" />
                                                </a>
                                                :
                                                <img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
                                        }

                                    </div>
                                    <div className="settings-profile-info">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="profileImage" className={`btn-common float-left mr-2 font-600`}><Translate text={'Choose Picture'} />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="profileImage"
                                                    id="profileImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'users', 'profileImage', 'Profile Image')} />
                                            </label>
                                            <div className={`settings-delete-button ${_.isEmpty(wholesaler.profileImage) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setWholesaler({
                                                        ...wholesaler,
                                                        ['profileImage']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a>
                                            </div>
                                        </div>
                                        <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                                    </div>

                                </div>
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="nav-fill" mountOnEnter={true}>
                                                <Tab eventKey="login" title={CommonHelper.showLocale(props, "Login Details")}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label ><Translate text={'name'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={wholesaler.name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label ><Translate text={'email'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'email'}
                                                                className={`form-control ${errorFields["email"]}`}
                                                                name="email"
                                                                onChange={handleOnChange}
                                                                value={wholesaler.email}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label ><Translate text={'client'} /></label>
                                                            <ReactMultiSelect
                                                                options={userClients.map(rec => {
                                                                    return {
                                                                        label: rec.name,
                                                                        value: rec.documentID
                                                                    }
                                                                })}
                                                                name={'wholesalerClientID'}
                                                                placeholder={'select client'}
                                                                onChange={handleReactMultiSelectChange}
                                                                value={wholesaler.wholesalerClientID}
                                                                classNamePrefix={`${errorFields["wholesalerClientID"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">

                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'phone'} /></label>
                                                            <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                                <div className='input-group-prepend'>
                                                                    <Dropdown className={`btn btn-outline-secondary`}>
                                                                        <Dropdown.Toggle as={CustomToggle}>
                                                                            <span id='spn-code-name' data-name={wholesaler.phoneCode}>
                                                                                {wholesaler.phoneCode &&
                                                                                    phoneCodes.find(item => item.value === wholesaler.phoneCode)
                                                                                    ? phoneCodes.find(
                                                                                        item => item.value === wholesaler.phoneCode,
                                                                                    ).value + ' '
                                                                                    : 'Code'}
                                                                            </span>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu
                                                                            as={CustomSearchMenu}
                                                                            className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                            ChildClass='pipeline-dropdown'
                                                                            xplacement='bottom-end'
                                                                        >
                                                                            {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                                phoneCodes.map((rec, index) => {
                                                                                    return (
                                                                                        <Dropdown.Item
                                                                                            key={index}
                                                                                            className={`current-pipeline ${wholesaler.phoneCode && rec.value === wholesaler.phoneCode
                                                                                                ? 'active'
                                                                                                : ''
                                                                                                }`}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                handleAddPhoneCode(
                                                                                                    'phoneCode',
                                                                                                    rec.value,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {rec.label}
                                                                                        </Dropdown.Item>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'phone'}
                                                                    className={`form-control`}
                                                                    name="phone"
                                                                    onChange={handlePhoneChange}
                                                                    value={wholesaler.phone}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Two Factor Authentication'} /></label>
                                                            <span className="switch switch-sm mr-2 w-100 d-inline-block mt-2">
                                                                <InputCheckBox
                                                                    id="enable-mfa"
                                                                    className="switch"
                                                                    name={'enableMFA'}
                                                                    checked={Boolean(wholesaler.enableMFA)}
                                                                    onChange={(e) => { handleCheckChange(e) }} />
                                                                <label htmlFor="enable-mfa"><Translate text={'enable'} /></label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="other" title={CommonHelper.showLocale(props, "otherInfo")}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Personal Email'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'personel email'}
                                                                className={`form-control ${errorFields["personelEmail"]}`}
                                                                name="personelEmail"
                                                                onChange={handleChange}
                                                                value={wholesaler.personelEmail}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'designation'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'designation'}
                                                                className={`form-control ${errorFields["designation"]}`}
                                                                name="designation"
                                                                onChange={handleOnChange}
                                                                value={wholesaler.designation}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'address'} /></label>
                                                            <AutoComplete
                                                                className={`form-control`}
                                                                bindAddress={bindAddress}
                                                                types={['address']}
                                                                value={wholesaler.address ? wholesaler.address : ''}
                                                                placeholder={'search address'}
                                                                onChange={handleChange}
                                                                name="address"
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label > <Translate text={'country'} /></label>
                                                            <ReactSelect
                                                                options={!_.isEmpty(props.dealersettings.countries) ? _.map(props.dealersettings.countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } }) : []}
                                                                name={"country"}
                                                                placeholder={'select country'}
                                                                onChange={handleReactSelectChange}
                                                                value={wholesaler.country}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label><Translate text={'state'} /></label>
                                                            <ReactSelect
                                                                options={
                                                                    !_.isEmpty(wholesaler.country) && !_.isEmpty(props.dealersettings.states) ?
                                                                        _.map(props.dealersettings.states.filter(item => item.country_code === wholesaler.country), function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })
                                                                        : []
                                                                }
                                                                name={"state"}
                                                                placeholder={'select state'}
                                                                onChange={handleReactSelectChange}
                                                                value={wholesaler.state}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Office Phone No'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'work phone'}
                                                                className={`form-control`}
                                                                name="workPhone"
                                                                onChange={handlePhoneChange}
                                                                value={wholesaler.workPhone}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'areaCode'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'area code'}
                                                                className={`form-control`}
                                                                name="areaCode"
                                                                onChange={handlePhoneChange}
                                                                value={wholesaler.areaCode}
                                                            />
                                                        </div>

                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {
                        newWholesaler === false ?
                            (<>
                                {/* <button type="button" className="btn btn-secondary float-left" onClick={() => { setShowPasswordModel(true) }}>Update Password</button> */}
                                <button type="button" className="btn btn-secondary float-left" onClick={() => emailVerification()} >

                                    {
                                        verifyLoader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<><i className="ico icon-email"></i></>)
                                    }
                                    <Translate text={'Resend Email Invitation'} /></button>
                            </>) : (<></>)
                    }

                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveWholesaler(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        {
                            newWholesaler ? (<Translate text={'Send Invitation'} />)
                                : (<Translate text={'Update'} />)
                        }

                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal>
            {
                showMFAEnableModel ? (
                    <Modal
                        show={showMFAEnableModel}
                        onHide={handleMFAModelClose}
                        backdrop="static"
                        backdropClassName={props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-update-password"
                        enforceFocus={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title><Translate text={'Enable Multi-factor authentication'} /> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="form-style w-100">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'phone'} /></label>
                                                <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                    <div className='input-group-prepend'>
                                                        <Dropdown className={`btn btn-outline-secondary`}>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                <span id='spn-code-name' data-name={wholesaler.phoneCode}>
                                                                    {wholesaler.phoneCode &&
                                                                        phoneCodes.find(item => item.value === wholesaler.phoneCode)
                                                                        ? phoneCodes.find(
                                                                            item => item.value === wholesaler.phoneCode,
                                                                        ).value + ' '
                                                                        : 'Code'}
                                                                </span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu
                                                                as={CustomSearchMenu}
                                                                className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                ChildClass='pipeline-dropdown'
                                                                xplacement='bottom-end'
                                                            >
                                                                {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                    phoneCodes.map((rec, index) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={index}
                                                                                className={`current-pipeline ${wholesaler.phoneCode && rec.value === wholesaler.phoneCode
                                                                                    ? 'active'
                                                                                    : ''
                                                                                    }`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    handleAddPhoneCode(
                                                                                        'phoneCode',
                                                                                        rec.value,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {rec.label}
                                                                            </Dropdown.Item>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'phone'}
                                                        className={`form-control`}
                                                        name="phone"
                                                        onChange={handlePhoneChange}
                                                        value={wholesaler.phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleEnableMFA(true)}>
                                {
                                    mfaLoader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                <Translate text={'enable'} />
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => handleMFAModelClose()} ><Translate text={'cancel'} /></button>
                        </Modal.Footer>
                    </Modal>
                ) : (<></>)
            }
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>
        </>
    )
}

export default AddWholesaler