import React, { useRef, useState, useEffect } from "react";
import { Dropdown, Modal, Popover } from "react-bootstrap";
import moment from "moment";
import CommonHelper from '../../services/common';
import _ from 'lodash'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import { reminderOptions, defaultActivityTypes } from '../../services/enum';
import toast from 'toasted-notes';
import { firestoreDB } from "../../services/helper";
import Requirements from '../requirement/list';

const ReminderPopUp = (props) => {
    const [activity, setActivity] = useState({})
    const [contact, setContact] = useState({})
    const [enquiry, setEnquiry] = useState({})

    const [service, setService] = useState({})
    const [tradeinPro, setTradeinPro] = useState({})
    const [notification, setNotification] = useState(props.notification)
    const [checkDataLoad, setDataload] = useState(true)
    const node = useRef();
    const [showPop, setShowPop] = useState(false);

    const activityTypes = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.activityTypes ? props.dealersettings.client.settings.activityTypes : defaultActivityTypes;

    useEffect(() => {
        if (props.notification) setNotification(props.notification);

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShowPop(false);
    };

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        if (props.notification.type === 'chat') {
            getChatbyID(props.notification);
        }
        else if (props.notification.type === 'salesHub') {
            getCommentsbyID(props.notification.recordID);
        }
        else if (props.notification.recordID)
            getActivitybyID(props.notification.recordID);

    }, [checkDataLoad])

    const getActivitybyID = async id => {
        if (!_.isEmpty(id)) {
            const activitySnapshot = await firestoreDB(props.dealersettings).firestore().doc(`activities/${id}`).get();
            if (activitySnapshot.exists) {
                const fields = activitySnapshot.data();

                if (fields.contactID) {
                    if (_.isEmpty(fields['contact'])) {
                        const contactSnap = await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${fields.contactID}`).get();
                        const contactData = contactSnap.data();
                        setContact(contactData)
                    }
                    else
                        setContact(Object.assign({}, fields['contact']));
                }

                if (fields.enquiryID) {
                    let enquiryData = null
                    if (_.isEmpty(fields['enquiry'])) {
                        const enquiryRef = firestoreDB(props.dealersettings).firestore().doc(`enquiries/${fields.enquiryID}`);
                        const enquirySnap = await enquiryRef.get();
                        enquiryData = enquirySnap.data();
                    }
                    else
                        enquiryData = Object.assign({}, fields['enquiry'])

                    setEnquiry(enquiryData)
                    if (!fields.contactID && enquiryData?.contact) {
                        setContact(enquiryData.contact)
                    }
                }

                if (fields.serviceID) {
                    let serviceData = null
                    if (_.isEmpty(fields['service'])) {
                        const serviceRef = firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${fields.serviceID}`);
                        const serviceSnap = await serviceRef.get();
                        serviceData = serviceSnap.data();
                    }
                    else
                        serviceData = Object.assign({}, fields['service'])

                    setService(serviceData)
                    if (!fields.contactID && serviceData?.contact) {
                        setContact(serviceData.contact)
                    }
                }

                if (fields.tradeinProID) {
                    let tradeinProData = null
                    if (_.isEmpty(fields['tradeinPro'])) {
                        const tradeinProRef = firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${fields.tradeinProID}`);
                        const tradeinProSnap = await tradeinProRef.get();
                        tradeinProData = tradeinProSnap.data();
                    }
                    else
                        tradeinProData = Object.assign({}, fields['tradeinPro'])

                    setService(tradeinProData)
                    if (!fields.contactID && tradeinProData?.contact) {
                        setContact(tradeinProData.contact)
                    }
                }

                setActivity(fields);
            }
        }
    };

    const getChatbyID = async fields => {
        if (!_.isEmpty(fields)) {
            if (fields.enquiryID) {
                const enquiryRef = firestoreDB(props.dealersettings).firestore().doc(`enquiries/${fields.enquiryID}`);
                const enquirySnap = await enquiryRef.get();
                const enquiryData = enquirySnap.data();
                setEnquiry(enquiryData)
                if (enquiryData.contact) {
                    setContact(enquiryData.contact)
                }
            } else if (fields.contactID) {
                const contactSnap = await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${fields.contactID}`).get();
                const contactData = contactSnap.data();
                setContact(contactData)
            }

            setActivity(fields);

        }
    };

    const getCommentsbyID = async id => {
        if (!_.isEmpty(id)) {
            const activitySnapshot = await firestoreDB(props.dealersettings).firestore().doc(`fieldComments/${id}`).get();
            if (activitySnapshot.exists) {
                const fields = activitySnapshot.data();

                if (fields.contactID) {
                    if (_.isEmpty(fields['contact'])) {
                        const contactSnap = await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${fields.contactID}`).get();
                        const contactData = contactSnap.data();
                        setContact(contactData)
                    }
                    else
                        setContact(Object.assign({}, fields['contact']));
                }

                if (fields.enquiryID) {
                    let enquiryData = null
                    if (_.isEmpty(fields['enquiry'])) {
                        const enquiryRef = firestoreDB(props.dealersettings).firestore().doc(`enquiries/${fields.enquiryID}`);
                        const enquirySnap = await enquiryRef.get();
                        enquiryData = enquirySnap.data();
                    }
                    else
                        enquiryData = Object.assign({}, fields['enquiry'])

                    setEnquiry(enquiryData)
                    if (!fields.contactID && enquiryData?.contact) {
                        setContact(enquiryData.contact)
                    }
                }
                setActivity(fields);
            }
        }
    };

    const handleView = () => {
        const { history, dealersettings, notification } = props
        let _contactid = activity?.contactID ? activity?.contactID : contact?.documentID
        if (activity?.enquiryID)
            history.push("/enquiry/details/" + activity?.enquiryID + '?comments=' + activity.documentID);
        else if (activity?.tradeinProID)
            history.push("/tradeinpro/details/" + activity?.tradeinProID + '?comments=' + activity.documentID);
        else if (activity?.serviceID)
            history.push("/service/details/" + activity?.serviceID + '?comments=' + activity.documentID);
        else if (_contactid)
            history.push("/contacts/details/" + _contactid + '?comments=' + activity.documentID);
        else if (notification.recordID) props.handleNotifyClick(notification.recordID, notification.type, notification.documentID, notification.enquiryID, notification.contactID, notification, dealersettings)

        props.handleClose();
    }

    // const showIcon = (rec) => {
    //     return `ico icon-${(['activity', 'tradeinProactivity', 'serviceactivity'].includes(rec.type) || (['log', 'tradeinProlog', 'servicelog'].includes(rec.type) && _.isEmpty(rec.subType)))
    //         ? 'date' :
    //         (['log', 'tradeinProlog', 'servicelog'].includes(rec.type)) ?
    //             rec.subType :
    //             ((rec.type === 'kitchen')
    //                 ? 'cafe' :
    //                 (rec.type === 'sms'
    //                     ? 'sms-inbound' :
    //                     (rec.type === 'service'
    //                         ? 'service-settings' :
    //                         (rec.type === 'tradeinPro' || rec.type === 'tradeinProRequest'
    //                             ? CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'tradein-module-icon') :
    //                             (rec.type === 'tradeinProOffer'
    //                                 ? 'finance' :
    //                                 (rec.type === 'aircallMissed' || rec.type === 'callMissed'
    //                                     ? 'no-call ico-lg' :
    //                                     ((rec.type === 'aircallContact' || rec.type === 'callContact') && rec.callType === 'outbound'
    //                                         ? 'call-outbound' :
    //                                         ((rec.type === 'aircallContact' || rec.type === 'callContact')
    //                                             ? 'call-inbound' :
    //                                             ((rec.type === 'smsMissed')
    //                                                 ? 'message' :
    //                                                 'enquiry'
    //                                             )))))))))}`
    // }

    const showTitle = (rec) => {
        return !_.isEmpty(rec.subType) && (rec.type === 'log' || rec.type === 'financelog' || rec.type === 'afterMarketlog') ? CommonHelper.autoCaps(rec.subType) : rec.title
    }

    return (!_.isEmpty(activity) ?
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-newlead-assigned">
            <Modal.Header >
                <Modal.Title>
                    <Translate text={showTitle(notification)} />
                    {enquiry?.displayID ? <div className="lead-source-badge"><div className="badge badge-pill badge-white">{enquiry?.displayID}</div></div> : <></>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="newlead-assigned-head">
                            <div className="vehicle-status-alert alert-user">
                                {notification.body}
                            </div>

                        </div>
                        <div className="newlead-assigned-body">
                            <div className="newlead-assigned-contact-details">
                                <h3> <Translate text={'contactDetails'} /></h3>
                                <div className="newlead-box">


                                    <div className="newlead-box-left">
                                        <table className="table-newlead">
                                            <tbody>
                                                <tr>
                                                    <td className="text-left ">
                                                        <div className="new-lead-contact-title">
                                                            {CommonHelper.displayFullContact(null, contact, '--')}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">
                                                        <i className="ico icon-call" style={{ fontSize: '12px' }}></i>
                                                        {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">
                                                        <i className="ico icon-Mail"></i>
                                                        {contact && contact.email ? contact.email : '--'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        !_.isEmpty(enquiry) && (<div className="newlead-box-right">
                                            <table className="table-newlead">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left label-text" width="40%"><Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} />:</td>
                                                        <td className="text-left" width="60%">{CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.enquiryTypes, enquiry?.enquiryType, '--')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} />:</td>
                                                        <td className="text-left">{CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.origins, enquiry?.origin, '--')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'type'} />:</td>
                                                        <td className="text-left">{CommonHelper.getNameByValue(activityTypes, activity.subType)}</td>
                                                    </tr>
                                                    {/* <tr>
                                                <td className="text-left label-text"><Translate text={'campaign'} />:</td>
                                                <td className="text-left">{CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.campaigns, enquiry?.campaign, '--')}</td>
                                            </tr> */}
                                                </tbody>
                                            </table>
                                        </div>)
                                    }

                                </div>

                                {
                                    enquiry?.requirement || tradeinPro?.tradein || service?.vehicle
                                        ?
                                        <>
                                            <h3> <Translate text={'vehicleDetails'} /></h3>

                                            <Requirements
                                                requirement={
                                                    !_.isEmpty(enquiry?.requirement) ? enquiry?.requirement :
                                                        (!_.isEmpty(tradeinPro?.tradein) ? tradeinPro?.tradein :
                                                            (!_.isEmpty(service?.vehicle) ? service?.vehicle : {}))}
                                                enquiryID={activity.enquiryID}
                                                handleEdit={() => { }}
                                                setPrimary={() => { }}
                                                decrementQuantity={() => { }}
                                                incrementQuantity={() => { }}
                                                isPrimary={false}
                                                isDeivered={true}
                                                hideMore={true}
                                                dealersettings={props.dealersettings}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                            >
                                            </Requirements>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="newlead-assigned-notes">
                                <h3> <Translate text={'notes'} /></h3>
                                <textarea
                                    className={'form-control notes'}
                                    name={'newleadassignednotes'}
                                    onChange={(e) => { e.preventDefault(); }}
                                    value={activity && activity.notes ? activity.notes : ''}
                                    readOnly={true}
                                >
                                </textarea>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">

                <button type="button"
                    className={`btn btn-default float-left`}
                    onClick={(e) => { e.preventDefault(); props.handleClose(); }} >
                    <Translate text={'cancel'} />
                </button>
                <button type="button"
                    className={`btn btn-info float-right`}
                    onClick={(e) => {
                        e.preventDefault();
                        handleView();
                    }}>
                    <Translate text={'view'} />
                </button>


            </Modal.Footer>
        </Modal >

        : <></>)
}

export default ReminderPopUp;