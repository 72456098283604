import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import images, { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { combineLatest, of } from 'rxjs';
import { collection, docData } from 'rxfire/firestore';
import { ContentOverlay, ReactSelect, TableView } from '../../../components';
import ContactListReducer from '../../contacts/contactlistReducer';
import { allcontactFields, objContact } from '../../contacts/viewModel';
import FilterPanel from '../../contacts/filter';
import { firestoreDB } from '../../../services/helper';
import { PopUpModal } from '../../../components';
import AttendeesList from '../../dashboardEventsPro/attendeesList';

const EventReports = props => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 140) })
    const [counts, setCounts] = useState({})
    const [checkDataLoad, setDataload] = useState(true)

    const [modelAttendeeShow, setModelAttendeeShow] = useState({ show: false, attendees: null, title: null })

    const [filterTrigger, setFilterTrigger] = useState('')

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 140)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        let _filter = Object.assign({
            eventsProID: props.eventsProID
        });

        if (!_.isEmpty(filterTrigger)) _filter.triggerID = filterTrigger

        let _searchObject = {
            "type": "getEventsProTriggerCount",
            params: JSON.stringify(_filter)
        }

        //console.log('generic-getData', _searchObject)
        const searchContacts = window.firebase.functions().httpsCallable('generic-getData');
        searchContacts(_searchObject).then((response) => {
            //console.log('generic-getData', response);
            if (response.data.success)
                setCounts(response.data.data[0]);
            else
                setCounts({});
            setDataload(false)
        });

    }, [checkDataLoad])


    const handleReactSelectChange = (selectedOptions) => {
        if (selectedOptions?.value) {
            setFilterTrigger(selectedOptions.value);
        } else {
            setFilterTrigger(null);
        }
        setDataload(true)
    };


    const handleShow = async (data, title) => {
        if (!_.isEmpty(data) && data.length > 0) {

            toast.notify(
                <div style={{ fontSize: `14px` }}>
                    <span
                        className="spinner-border spinner-button  mr-2 spinner-border loader-primary"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    {`fetching ${title} data...`}
                </div>,
                {
                    position: "top",
                    duration: null,
                }
            );

            let _list = _.uniq(data)
            var collectionPromise = [];
            for (let k = 0; k < Math.ceil(_list.length / 25); k++) {
                let _ids = CommonHelper.arrPaginate(_list, 25, k + 1)
                collectionPromise.push(firestoreDB(props.dealersettings).firestore().collection('eventsProAttendees').where('documentID', 'in', _ids).get())
                //collectionPromise.push(refeventsProAttendeesData ? collection(refeventsProAttendeesData) : of([]));
            }
            let attendees = [];
            const snapshots = await Promise.all(collectionPromise)
            snapshots.forEach(response => {
                response.docs.forEach((doc) => {
                    attendees.push({
                        ...doc.data(),
                        documentID: doc.id
                    })
                });
            });
            toast.closeAll();
            setModelAttendeeShow({ show: true, attendees: _.uniq(attendees), title: title })

            // if (collectionPromise.length > 0) {
            //     window.unsubEntReports = combineLatest(collectionPromise)
            //         .subscribe(allDocs => {
            //             var querySnapshot = [];
            //             let i = 0;

            //             allDocs.forEach(doc => {
            //                 querySnapshot.push(...doc);
            //                 i++;
            //             });

            //             const attendees = [];
            //             querySnapshot.forEach(function (data) {
            //                 attendees.push({
            //                     ...data.data(),
            //                     documentID: data.id
            //                 })
            //             });

            //             setModelAttendeeShow({ show: true, attendees: _.uniq(attendees), title: title })
            //             window.unsubEntReports && window.unsubEntReports.unsubscribe();

            //         });
            // }
        }
    }

    return (
        <>
            <div className="campaign-view-tab-middle">
                <div className="card campaign-custom-card">
                    <div className="card-header">

                        <div className="events-reportlist-section-header">

                            <div className="float-left">
                                <h2>
                                    <i className="ico icon-mail-draft"></i>
                                    <Translate text={'Reports'} />
                                    {checkDataLoad && <div className="align-items-center d-flex justify-content-center loader-primary spinner-loader-h3"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div>}
                                </h2>
                            </div>

                            <div className="float-right">
                                <div className="dash-widget-settings-wrap">
                                    <a href="#" className="dash-mini-button" onClick={(e) => {
                                        e.preventDefault();
                                        setDataload(true);
                                    }}> <i className="ico icon-refresh"></i></a></div>
                                <div className="events-reportlist-dropdown ml-3">
                                    <ReactSelect
                                        options={props.trigger.map(e => {
                                            return { label: e.subject, value: e.documentID };
                                        })}
                                        name={"filterTrigger"}
                                        placeholder={"select trigger"}
                                        onChange={(e, data) => {
                                            handleReactSelectChange(e, data)
                                        }}
                                        value={filterTrigger}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={false}
                                    ></ReactSelect>
                                </div>
                            </div>
                        </div>
                        {/* <div className="">
                            <div className="float-left d-flex">
                                <Translate text={'Reports'} />
                                {checkDataLoad && <div className="align-items-center d-flex justify-content-center loader-primary spinner-loader-h3"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div>}
                            </div>
                            <div className="dash-widget-settings-wrap">
                                <a href="#" className="dash-mini-button" onClick={(e) => {
                                    e.preventDefault();
                                    setDataload(true);
                                }}> <i className="ico icon-refresh"></i></a>
                            </div>
                            <div className="float-lg-right form-dashboard">
                                <div className="form-row justify-content-end">
                                    <div className="form-group col-md-3">

                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <div className="card-body">
                        <div className="row text-center mb-3">
                            <div className="col">
                                <div className="campaign-counter">
                                    <div className="campaign-count-icon"><img src={images.campaignDelivered} alt="" height="70" /></div>
                                    <p className="campaign-count-text ">Delivered</p>
                                    <h2 className="campaign-count-title " onClick={e => { e.preventDefault(); handleShow(counts?.delivered, 'delivered') }}>{counts?.delivered?.length || 0}</h2>
                                </div>
                            </div>
                            <div className="col">
                                <div className="campaign-counter">
                                    <div className="campaign-count-icon"><img src={images.campaignOpen} alt="" height="70" /></div>
                                    <p className="campaign-count-text "> Opened</p>
                                    <h2 className="campaign-count-title" onClick={e => { e.preventDefault(); handleShow(counts?.opened, 'opened') }} >{counts?.opened?.length || 0}</h2>
                                </div>
                            </div>

                            <div className="col">
                                <div className="campaign-counter">
                                    <div className="campaign-count-icon"><img src={images.campaignClick} alt="" height="70" /></div>
                                    <p className="campaign-count-text ">Clicked</p>
                                    <h2 className="campaign-count-title " onClick={e => { e.preventDefault(); handleShow(counts?.clicked, 'clicked') }}>{counts?.clicked?.length || 0}</h2>
                                </div>
                            </div>



                            <div className="col">
                                <div className="campaign-counter">
                                    <div className="campaign-count-icon"><img src={images.campaignFailed} alt="" height="70" /></div>
                                    <p className="campaign-count-text ">Failed</p>
                                    <h2 className=" campaign-count-title campaign-count-number" onClick={e => { e.preventDefault(); handleShow(counts?.failed, 'failed') }}>{counts?.failed?.length || 0}</h2>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

            <PopUpModal show={modelAttendeeShow.show}>
                <AttendeesList
                    {...props}
                    {...modelAttendeeShow}
                    handleClose={() => setModelAttendeeShow({ show: false })}
                    history={props.history}
                    isFromDashboard={true}
                ></AttendeesList>
            </PopUpModal>
        </>
    );
};

export default EventReports;
