/** LIBRARIES */
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import kitchen_order from '../../sounds/kitchen_order.mp3'
import toast from 'toasted-notes';
import toaster from 'toasted-notes';
import { AllModules, Routes } from '../../constants/routes';
import { PopUpModal, ForceUpdateAlert } from '../../components';
import SelectModule from './selectModules';
import { messaging } from '../../init-fcm';
import NotifyToast from './notifyToast';
import AddActivity from '../activity';
import EngageRequest from '../frontdesk/engageRequest';
import EngageLogRequest from '../callLogs/engageLog'
import AssignLead from '../pipeline/assignLead';
import AddFleet from '../fleet/add';
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert'
import InBoundTIPConvert from '../tradeinplus/inBoundLeadConvert'
import AssignFleet from '../fleetPro/assign'
import NewSMS from '../pipeline/sms';
import CafeOrder from '../cafe/order'
import { allModules } from '../settings/workflow/fieldSettings/viewModel'
import { serviceModules } from '../settings/serviceworkflow/fieldSettings/viewModel'
import { appraisalModules } from '../settings/tradeinProworkflow/fieldSettings/viewModel'
import { clientLookups } from '../settings/workflow/lookups/viewModel'
import { serviceLookups } from '../settings/serviceworkflow/lookups/viewModel'
import { tradeinProLookups } from '../settings/tradeinProworkflow/lookups/viewModel'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';
import CommonHelper from "../../services/common";
import { enqiuryOptions } from '../settings/workflow/enquiryOptions/viewModel'
import SleepMode from './sleepMode';
import NotifyServiceRequest from '../service/details/notifyRequest'
import NotifyTradeinProRequest from '../tradeinplus/details/notifyRequest'
import Swal from 'sweetalert2'
import { default as _images } from '../../images';
import { defaultenquiryStatus, defaultActivityTypes, defaultenquiryLabels, defaultcontactTypes, defaultStockStatus, calendarEventColor, defaulttipStatuses, defaultserviceStatuses, defaultTechnicianStatuses, defaulteventStatuses, defaultfleetStatuses, defaultfleetCategory, defaultaftermarketStatus, defaultFileCategories, enquiryStatus } from '../../services/enum';
import { msalConfig } from "../../services/msal/authConfig";
import ReminderPopUp from '../activity/reminderPopup'
import NotifyPopUp from '../activity/notifyPopup'
import { MeiliSearch } from 'meilisearch'
import i18n from '../../i18n';
import { firestoreDB } from '../../services/helper';
import AircallPhone from 'aircall-everywhere'
import NotifyAircall from '../aircall/notifyMsg'
import axios from 'axios';
import { eventsProLookups } from '../settings/eventsProworkflow/lookups/viewModel';
import { fleetProLookups } from '../settings/fleetworkflow/lookups/viewModel';
import { allenquiryFields } from '../pipeline/viewModel';
import { allactivityFields } from '../calendar/viewModel';
import { allTradeinFields } from '../tradeIn/viewModel';
import { allCallLogsFieldsVM } from '../callLogs/viewModel';
import { allserviceFields } from '../service/viewModel';
import ChatPopUp from '../activity/chatPopup';
import EmailPopUp from '../activity/emailPopup';
import FieldCommentsPopUp from '../activity/commentsPopup';

export const MainContext = React.createContext();

export const LayoutConsumer = MainContext.Consumer;

//export class LayoutProvider extends React.Component {
export function LayoutProvider({ history, children }) {
	const [dealersettings, setDealersettings] = useState(null);
	const [pipeline, setPipeline] = useState([]);
	const [currentClient, setCurrentClient] = useState('');
	const [loadingText, setLoadingText] = useState('');
	const [clientUsers, setClientUsers] = useState([{ name: 'test', id: '123' }]);
	const [modules, setModules] = useState([]);
	const [showselectmodules, setShowselectmodules] = useState(false);
	const [clientModule, setClientModule] = useState(localStorage.defaultModule);
	const [deviceTokens, setTokens] = useState([]);
	const [notificationCount, setNotificationCount] = useState(0);
	const [devicefcmToken, setDeviceToken] = useState('');
	const [notificationRecordId, setRecordId] = useState('');
	const [notificationId, setNotificationId] = useState('');
	const [showActivityModel, setShowActivityModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showEngageModel, setShowEngageModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showEngageModelClose, setShowEngageModelClose] = useState(true);
	const [showCallLogModel, setShowCallLogModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showKitchenModel, setShowKitchenModel] = useState({ show: false, notifyID: null, recordID: null });
	const [queueNum, setQueueNum] = useState(0);
	const [regions, setRegions] = useState([])
	const [subregions, setSubregions] = useState([])
	const [groups, setGroups] = useState([])
	const [checkUserload, setUserLoad] = useState(false)
	const [userNotifications, setNotifications] = useState([]);
	const [newTheardID, setTheardID] = useState();
	const [nylasAccessToken, setNylasAccessToken] = useState(null);
	const [nylas_accountData, setNylasAccountData] = useState(null);
	const [showAssignLeadModel, setShowAssignLeadModel] = useState({ show: false, notifyID: null, recordID: null });
	const [titleLeadModel, setTitleLeadModel] = useState();
	const [financeModel, setFinanceModel] = useState(false);
	const [afterMarketModel, setAfterMarketModel] = useState(false);
	const [mailUnreadCount, setUnreadCount] = useState(0)
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [smsTemplates, setSmsTemplates] = useState([]);
	const [emailSignature, setEmailSignature] = useState();
	const [showForceAlert, setShowForceAlert] = useState(false);
	const [notifyClose, setNotifyClose] = useState(false)
	const [smsNotifications, setSMSNotifications] = useState([]);
	const [smsNotificationCount, setSMSNotificationCount] = useState(0);
	const [showSMSModel, setShowSMSModel] = useState({ show: false, notifyID: null, recordID: null });
	const [objNotification, setObjNotification] = useState({});
	const [localClient, setLocalClient] = useState('');
	const [inboundCount, setInboundCount] = useState(0);
	const [groupUsers, setGroupUsers] = useState([]);
	const [currentGroup, setCurrentGroup] = useState('');
	const [showInboundLeadModel, setShowInboundLeadModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showInboundTIPModel, setShowInboundTIPModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showFleetModel, setShowFleetModel] = useState({ show: false, notifyID: null, recordID: null });
	const [sleepMode, setSleepMode] = useState(localStorage.sleepMode ? true : false);
	const [unReadChatCounts, setUnReadChatCounts] = useState({})
	const [showServiceRequestModel, setShowServiceRequestModel] = useState({ show: false, notifyID: null, recordID: null });
	const [showTradeinProRequestModel, setShowTradeinProRequestModel] = useState({ show: false, notifyID: null, recordID: null });
	const [isOnline, setIsOnline] = useState(true)
	const [hasMoreSalesNotifications, setHasMoreSalesNotifications] = useState([])
	const [hasMoreServiceNotifications, setHasMoreServiceNotifications] = useState([])
	const [hasMoreTradeinProNotifications, setHasMoreTradeinProNotifications] = useState([])
	const [notificationsFetchedOnce, setNotificationsFetchedOnce] = useState(false)
	const [paymentDue, setPaymentDue] = useState(false)
	const [paymentDueDateText, setPaymentDueDateText] = useState(null)
	const [paymentDueDate, setPaymentDueDate] = useState(null)
	const [accountSuspended, setAccountSuspended] = useState(false)
	const [groupClients, setGroupClients] = useState([]);
	const [showReminderModel, setShowReminderModel] = useState(false);
	const [reminderActivity, setReminderActivity] = useState();
	const [localDate, setLocalDate] = useState(moment().format('DD-MM-YYYY'));
	const [tvTemplates, setTVTemplates] = useState([]);
	const [aircallPhone, setAircallPhone] = useState()
	const [aircallModal, setAircallModal] = useState(false)
	const [refreshAircall, setRefreshAircall] = useState(false)
	const [showAircallNotifyModel, setShowAircallNotifyModel] = useState({ show: false, notifyID: null, recordID: null });
	const [otherSettigs, setOtherSettings] = useState(true);
	const groupUsersModule = ['oem', 'tradeinPro'];
	const [tradeinProinboundCount, setTradeinProInboundCount] = useState(0);
	const [ringcentralAuth, setRingcentralAuth] = useState(false)
	const [ringcentralModal, setRingcentralModal] = useState(false)
	const [fleetAssignModal, setfleetAssignModal] = useState({ show: false, docID: null, fleetProID: null, notifyID: null })
	const [justcallModal, setJustcallModal] = useState(false)

	const [showNotifyModel, setShowNotifyModel] = useState({ show: false, notification: null });
	const [showChatModel, setShowChatModel] = useState({ show: false, notification: null });
	const [showEmailModel, setShowEmailModel] = useState({ show: false, notification: null });
	const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
		show: false
	})
	const [saleshubcommentsModel, setSaleshubcommentsModel] = useState({ show: false, notification: null });
	// useEffect(() => {
	// 	if (_.isEmpty(nylasAccountData) || _.isEmpty(nylasAccountData.account_id) || _.isEmpty(devicefcmToken) || !devicefcmToken.includes('safari-'))
	// 		return

	// 	let refNewdeltaSnapshot = window.firebase.firestore().collection('nylas-deltas')
	// 		.where('object_data.account_id', '==', nylasAccountData.account_id)
	// 		.orderBy('object_data.attributes.received_date', 'desc').limit(1)
	// 		.onSnapshot(querySnapshot => {
	// 			querySnapshot.docChanges().forEach(change => {
	// 				if (change.type === 'added' && change.doc.exists) {
	// 					const attributes = change.doc.data().object_data.attributes;
	// 					const received_date = moment.unix(attributes.received_date);
	// 					if (moment().utc().diff(received_date, 'minutes') <= 5) {
	// 						getUnreadCount(nylasAccountData.token, null, nylasAccountData.last_message_after, nylasAccountData.last_message_before)
	// 						// toast.notify(
	// 						// 	({ onClose }) => (
	// 						// 		<NotifyToast
	// 						// 			payload={{
	// 						// 				data: {
	// 						// 					type: 'email'
	// 						// 				},
	// 						// 				notification : {
	// 						// 					title: 'Email',
	// 						// 					body: 'New email received'
	// 						// 				}
	// 						// 			}}
	// 						// 			onClose={onClose}
	// 						// 			handleNotifyClick={handleNotifyClick}
	// 						// 		/>
	// 						// 	),
	// 						// 	{
	// 						// 		duration: 4000,
	// 						// 		position: 'top-right',
	// 						// 	},
	// 						// );
	// 					}
	// 				}
	// 			})
	// 		});
	// 	return () => {
	// 		refNewdeltaSnapshot && refNewdeltaSnapshot()
	// 	}
	// }, [nylasAccountData, devicefcmToken])

	useEffect(() => {
		function handleChangeStyles() {
			if (paymentDue === true || (!dealersettings?.enableTOTP && dealersettings?.showMFABanner === 'true')) {
				document.documentElement.classList.add('subEnding');
			} else {
				if (document.documentElement.classList.contains('subEnding')) {
					document.documentElement.classList.remove('subEnding');
				}
			}
		}

		handleChangeStyles()
	}, [paymentDue, dealersettings?.enableTOTP]);

	useEffect(() => {
		getdealerSettings(true);
		fnmessaging();
		return () => {
			window.alltemplates && window.alltemplates.unsubscribe()
			window.unSubnylasAccountInfo && window.unSubnylasAccountInfo()
			window.unSubuserclientRefDoc && window.unSubuserclientRefDoc();
			window.reminderTimeout && clearInterval(window.reminderTimeout);
			window.refReminderActivities && window.refReminderActivities();
			window.unSubcurrentclientRefDoc && window.unSubcurrentclientRefDoc();
			window.unSubDeploymentsRefDoc && window.unSubDeploymentsRefDoc()
		}
	}, []);

	useEffect(() => {
		if (_.isEmpty(dealersettings?.strPorsche))
			return
		console.log(dealersettings?.strPorsche)
		if (dealersettings?.strPorsche === 'true') {
			document.documentElement.style.setProperty('--dynfontFamily', "'Porsche Next', Arial, Helvetica, sans-serif");
			document.documentElement.style.setProperty('--body-text-color', "#010205");
			document.documentElement.style.setProperty('--innerbgColor', "#f7f7f7");
			document.documentElement.style.setProperty('--primarybgColor', "#000000");
			document.documentElement.style.setProperty('--primarytxtColor', "#ffffff");
			document.documentElement.style.setProperty('--primaryHoverColor', "#535457");
			document.documentElement.style.setProperty('--bgdark', "#000000");
			document.documentElement.style.setProperty('--navbardark', "#363940");
			document.documentElement.style.setProperty('--navbardarkhover', "#4d5058");
			document.documentElement.style.setProperty('--financeinfo', "#000000");
			document.documentElement.style.setProperty('--rbctoday', "#e5e7eb");
			document.documentElement.style.setProperty('--navactive', "#f0f0f1");
			document.documentElement.style.setProperty('--addiconColor', "#dd0c29");
			document.documentElement.style.setProperty('--addiconhover', "#cc1922");
			document.documentElement.style.setProperty('--borderColor', "#000000");
			document.documentElement.style.setProperty('--greybg', "#eeeff2");
			document.documentElement.style.setProperty('--lightbg', "#eeeff2");
			document.documentElement.style.setProperty('--fontweightsemibold', " 600");
			document.documentElement.style.setProperty('--labelcolor', "#6b6d70");
			document.documentElement.style.setProperty('--stagebg', "#f7f7f7");
			document.documentElement.style.setProperty('--tilesubfont', "#535457");
			document.documentElement.style.setProperty('--quickview-bg', "#eeeff2");
			document.documentElement.style.setProperty('--checkboxcolor', "#000000");
			document.documentElement.style.setProperty('--settingsmenu', "#495057");
			document.documentElement.style.setProperty('--settingsnavactive', "#d8d8db");
			document.documentElement.style.setProperty('--tableheadbg', "#f7f7f7");
			document.documentElement.style.setProperty('--tabledividerborder', "#D8D8DB");
			document.documentElement.style.setProperty('--stagestepbackground', "#dad9de");
			document.documentElement.style.setProperty('--stagestepactive', "#2a3042");
			document.documentElement.style.setProperty('--stagestepskip', "#616b84");
			document.documentElement.style.setProperty('--btngreybackground', "#ececec");
			document.documentElement.style.setProperty('--bggrey', "#eeeff2");
			document.documentElement.style.setProperty('--bglightgrey', "#d8d8db");
			document.documentElement.style.setProperty('--closebg', "#000000");
			document.documentElement.style.setProperty('--tablehoverbg', "#f7f7f7");
			document.documentElement.style.setProperty('--uploadbg', "#eeeff2");
			document.documentElement.style.setProperty('--uploadborder', "#6b6d70");
			document.documentElement.style.setProperty('--scheduledbg', "#000000");
			document.documentElement.style.setProperty('--badgecompleted', "#197e10");

		} else {
			document.documentElement.style.setProperty('--dynfontFamily', "'Source Sans Pro',Arial,Helvetica,sans-serif");
			document.documentElement.style.setProperty('--body-text-color', "#6b717f");
			document.documentElement.style.setProperty('--innerbgColor', "#f6f9fc");
			document.documentElement.style.setProperty('--primarybgColor', "#4466f2");
			document.documentElement.style.setProperty('--primarytxtColor', "#eff5fd");
			document.documentElement.style.setProperty('--primaryHoverColor', "#2b57e2");
			document.documentElement.style.setProperty('--bgdark', "#2a3042");
			document.documentElement.style.setProperty('--navbardark', "#3f4556");
			document.documentElement.style.setProperty('--navbardarkhover', "#545a6d");
			document.documentElement.style.setProperty('--financeinfo', "#6c9cff");
			document.documentElement.style.setProperty('--rbctoday', "#e6f4ff");
			document.documentElement.style.setProperty('--navactive', "#f2f6fa");
			document.documentElement.style.setProperty('--addiconColor', "#4466f2");
			document.documentElement.style.setProperty('--addiconhover', "#2b57e2");
			document.documentElement.style.setProperty('--borderColor', "#d3d9e6");
			document.documentElement.style.setProperty('--greybg', "#f2f5fa");
			document.documentElement.style.setProperty('--lightbg', "#edf0fe");
			document.documentElement.style.setProperty('--fontweightsemibold', "700");
			document.documentElement.style.setProperty('--labelcolor', "#6b717f");
			document.documentElement.style.setProperty('--stagebg', "#f6f9fc");
			document.documentElement.style.setProperty('--tilesubfont', "#6b717f");
			document.documentElement.style.setProperty('--quickview-bg', "#edf1f2");
			document.documentElement.style.setProperty('--checkboxcolor', "#2bb673");
			document.documentElement.style.setProperty('--settingsmenu', "#495057");
			document.documentElement.style.setProperty('--settingsnavactive', "#e5ebfb");
			document.documentElement.style.setProperty('--tableheadbg', "#f9fafb");
			document.documentElement.style.setProperty('--tabledividerborder', "#e0e7ed");
			document.documentElement.style.setProperty('--stagestepbackground', "#d3d9e6");
			document.documentElement.style.setProperty('--stagestepactive', "#4466f2");
			document.documentElement.style.setProperty('--stagestepskip', "#9fb5e2");
			document.documentElement.style.setProperty('--btngreybackground', "#f7f9fc");
			document.documentElement.style.setProperty('--bggrey', "#f2f4f5");
			document.documentElement.style.setProperty('--bglightgrey', "#e5ebfb");
			document.documentElement.style.setProperty('--closebg', "#30394d");
			document.documentElement.style.setProperty('--tablehoverbg', "#f8f9fc");
			document.documentElement.style.setProperty('--uploadbg', "#f2f7ff");
			document.documentElement.style.setProperty('--uploadborder', "#a7cbff");
			document.documentElement.style.setProperty('--scheduledbg', "#616b84");
			document.documentElement.style.setProperty('--badgecompleted', "#4CAF50");
		}
	}, [dealersettings?.strPorsche])

	useEffect(() => {
		//!window.firebase.messaging.isSupported()
		if (!_.isEmpty(localStorage.browserKey)) {
			localStorage.setItem('fcmtoken', 'safari-' + localStorage.browserKey);
			setDeviceToken('safari-' + localStorage.browserKey)
		}
	}, [localStorage.browserKey])

	useEffect(() => {
		if (!_.isEmpty(localStorage.browserKey) && !_.isEmpty(dealersettings)) {
			const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${localStorage.browserKey}`)
			userRef.onSnapshot(userDoc => {
				if (userDoc.exists) {
					const userData = userDoc.data()
					// console.log('this is device obj from provider', userData)
					if (userData.forceLogout) {
						// console.log('this is device obj from provider forceLogout === true')
						const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${localStorage.browserKey}`)
						// const logRef = window.firebase.firestore().collection('auditLogs')
						// const auditLog = {
						// 	clientID: dealersettings.client.id ? dealersettings.client.id : null,
						// 	documentID: logRef.doc().id,
						// 	groupID: dealersettings.groupID ? dealersettings.groupID : null,
						// 	imageURL: null,
						// 	logBy: localStorage.uid,
						// 	logDate: window.firebase.firestore.Timestamp.now(),
						// 	notes: null,
						// 	oemID: dealersettings.oemID ? dealersettings.oemID : null,
						// 	platform: 'web',
						// 	recordID: localStorage.browserKey,
						// 	regionID: dealersettings.regionID ? dealersettings.regionID : null,
						// 	subtype: 'logout',
						// 	type: 'authentication',
						// }
						// console.log('force logout function ran')

						// logRef.doc(auditLog.documentID).set(auditLog, { merge: true }).then(() => {
						userRef.set({
							logoutDate: window.firebase.firestore.Timestamp.now(),
							isLogin: false,
							forceLogout: false,
							modifiedDate: window.firebase.firestore.Timestamp.now()
						}, { merge: true }).then(() => {
							window.firebase
								.auth()
								.signOut().then(() => {
									window.firebase2.auth().signOut();
									toast.closeAll();
									const brandTheme = localStorage.brandTheme;
									localStorage.clear();
									history.push(`${brandTheme ? `/login?theme=${brandTheme}` : '/login'}`)
								})
						})
						// })
					}
				}
			})
		}
	}, [localStorage.browserKey, dealersettings])

	useEffect(() => {
		if (_.isEmpty(localClient))
			return

		// let refNotificationData = window.firebase.firestore().collection('notifications')
		// 	.where('isDeleted', '==', false)
		// 	.where('type', 'in', ['engageRequest', 'activity', 'enquiry', 'pendingLost', 'log', 'kitchen', 'callLogs', 'inbound', 'fleet', 'service'])//if add/remove any type pls do update in sidebar.js/updateNotification
		// 	.where('toUserID', '==', localStorage.uid)
		// 	.where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -30).startOf('day')._d))
		// 	.where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
		// 	.orderBy('addedDate', 'desc')
		// 	.onSnapshot(onCollectionUpdate);

		let refSMSNotificationData = window.firebase.firestore().collection('notifications')
			.where('isDeleted', '==', false)
			.where('type', 'in', ['sms'])//if add/remove any type pls do update in sidebar.js/updateNotification
			.where('toUserID', '==', localStorage.uid)
			.orderBy('addedDate', 'desc')
			.limit(10)
			.onSnapshot(onSMSCollectionUpdate);

		return () => {
			//refNotificationData && refNotificationData();
			refSMSNotificationData && refSMSNotificationData();
		}

	}, [localClient])

	const notifyWithSound = (_url) => {
		if (localStorage.isNotifyWithSound === 'true') {
			const audio = new Audio(_url ? _url : kitchen_order);
			audio.play();
		}
	}

	useEffect(() => {
		if (_.isEmpty(localClient) && localStorage.defaultModule !== 'wholesaler')
			return

		var refNotificationData = combineLatest([
			collection(window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['engageRequest', 'activity', 'enquiry', 'finance', 'afterMarket', 'pendingLost', 'log', 'kitchen', 'callLogs', 'inbound', 'fleet', 'fleetProAssign', 'salesHub'])//if add/remove any type pls do update in sidebar.js/updateNotification and in the loadMoreNotifications Function below
				.where('toUserID', '==', localStorage.uid)
				.where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -14).startOf('day')._d))
				.where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
				.orderBy('addedDate', 'desc')
			),
			collection(window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['service', 'serviceRequest', 'serviceactivity', 'servicelog', 'financeactivity', 'financelog', 'aircallMissed', 'aircallContact', 'callMissed', 'callContact', 'smsMissed', 'afterMarketactivity', 'afterMarketlog'])//if add/remove any type pls do update in sidebar.js/updateNotification and in the loadMoreNotifications Function below
				.where('toUserID', '==', localStorage.uid)
				.where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -14).startOf('day')._d))
				.where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
				.orderBy('addedDate', 'desc')
			),
			collection(window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['tradein', 'tradeinPro', 'tradeinProOffer', 'tradeinProRequest', 'tradeinProValuer', 'tradeinProFinal', 'tradeinProactivity', 'tradeinProlog', 'inboundTIP'])//if add/remove any type pls do update in sidebar.js/updateNotification and in the loadMoreNotifications Function below
				.where('toUserID', '==', localStorage.uid)
				.where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -14).startOf('day')._d))
				.where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
				.orderBy('addedDate', 'desc')
			),
			collection(window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['chat', 'email'])//if add/remove any type pls do update in sidebar.js/updateNotification and in the loadMoreNotifications Function below
				.where('toUserID', '==', localStorage.uid)
				.where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('days', -14).startOf('day')._d))
				.where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
				.orderBy('addedDate', 'desc')
			)
		]).pipe(
			map(([sales, service, tradeinpro, chat]) => {
				if (sales.length > 0 && !notificationsFetchedOnce) {
					setHasMoreSalesNotifications([sales[sales.length - 1]])
				}
				if (service.length > 0 && !notificationsFetchedOnce) {
					setHasMoreServiceNotifications([service[service.length - 1]])
				}
				if (tradeinpro.length > 0 && !notificationsFetchedOnce) {
					setHasMoreTradeinProNotifications([tradeinpro[tradeinpro.length - 1]])
				}
				if (service.length > 0 || tradeinpro.length > 0 || sales.length > 0) {
					// setting fetchedOnce: so that it doesn't mess up pagination with lastDoc
					setNotificationsFetchedOnce(true)
				}
				return [
					_.map(sales, function (obj) { return { ...obj.data(), documentID: obj.id } }),
					_.map(service, function (obj) { return { ...obj.data(), documentID: obj.id } }),
					_.map(tradeinpro, function (obj) { return { ...obj.data(), documentID: obj.id } }),
					_.map(chat, function (obj) { return { ...obj.data(), documentID: obj.id } })
				]
			}))
			.subscribe(async ([sales, service, tradeinpro, chat]) => {
				let querySnapshot = sales.concat(service).concat(tradeinpro)
				let _notification = [];
				let _chat = [];
				querySnapshot.forEach(doc => { _notification.push(doc) })
				chat.forEach(doc => {
					if (doc.type === 'email') _notification.push(doc);
					_chat.push(doc)
				})
				//console.log('_chat_notification', _chat)
				//console.log('_notification', _notification)
				_notification = _notification.filter(a => localStorage.currentDealerNorification === 'true' ? a.clientID === localClient : true).sort(function (x, y) { return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1); })

				if (!_.isEmpty(_notification) && currentClient && localStorage.defaultModule !== 'oem') {

					// ENGAGE REQUEST NOTIFICATION
					let _record = _notification.filter(m => m.type === 'engageRequest' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_record) && (_.isEmpty(localStorage.notificationUpdatedOn) || (!_.isEmpty(localStorage.notificationUpdatedOn) && _record.addedDate.seconds > Number(localStorage.notificationUpdatedOn)))) {

						if (_record.documentID && _record.recordID) {
							localStorage.setItem('notificationUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_record.recordID);
							//setNotificationId(_record.documentID);
							setShowEngageModel({ show: true, notifyID: _record.documentID, recordID: _record.recordID });
							setShowEngageModelClose(true);
							notifyWithSound();
						}
					}

					// CALL LOGS NOTIFICATION
					let _recordLog = _notification.filter(m => m.type === 'callLogs' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordLog) && (_.isEmpty(localStorage.notificationLogUpdatedOn) || (!_.isEmpty(localStorage.notificationLogUpdatedOn) && _recordLog.addedDate.seconds > Number(localStorage.notificationLogUpdatedOn)))) {

						if (_recordLog.documentID && _recordLog.recordID) {
							localStorage.setItem('notificationLogUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordLog.recordID);
							//setNotificationId(_recordLog.documentID);
							setShowCallLogModel({ show: true, notifyID: _recordLog.documentID, recordID: _recordLog.recordID });
							notifyWithSound();
						}
					}
					// LEAD ASSIGN NOTIFICATION
					let _recordEnquiry = _notification.filter(m => m.type === 'enquiry' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordEnquiry) && (_.isEmpty(localStorage.notificationAssignUpdatedOn) || (!_.isEmpty(localStorage.notificationAssignUpdatedOn) && _recordEnquiry.addedDate.seconds > Number(localStorage.notificationAssignUpdatedOn)))) {

						if (_recordEnquiry.documentID && _recordEnquiry.recordID) {
							localStorage.setItem('notificationAssignUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordEnquiry.recordID);
							//setNotificationId(_recordEnquiry.documentID);
							setShowAssignLeadModel({ show: true, notifyID: _recordEnquiry.documentID, recordID: _recordEnquiry.recordID });
							notifyWithSound();
						}
					}

					// FINANCE MANAGER NOTIFICATION
					let _financeEnquiry = _notification.filter(m => m.type === 'finance' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_financeEnquiry) && (_.isEmpty(localStorage.notificationAssignUpdatedOn) || (!_.isEmpty(localStorage.notificationAssignUpdatedOn) && _financeEnquiry.addedDate.seconds > Number(localStorage.notificationAssignUpdatedOn)))) {

						if (_financeEnquiry.documentID && _financeEnquiry.recordID) {
							localStorage.setItem('notificationAssignUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_financeEnquiry.recordID);
							//setNotificationId(_financeEnquiry.documentID);
							setShowAssignLeadModel({ show: true, notifyID: _financeEnquiry.documentID, recordID: _financeEnquiry.recordID });
							setTitleLeadModel('Finance Enquiry');
							setFinanceModel(true);
							notifyWithSound();

						}
					}

					// after Market MANAGER NOTIFICATION
					let _afterMarketEnquiry = _notification.filter(m => m.type === 'afterMarket' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_afterMarketEnquiry) && (_.isEmpty(localStorage.notificationAssignUpdatedOn) || (!_.isEmpty(localStorage.notificationAssignUpdatedOn) && _afterMarketEnquiry.addedDate.seconds > Number(localStorage.notificationAssignUpdatedOn)))) {

						if (_afterMarketEnquiry.documentID && _afterMarketEnquiry.recordID) {
							localStorage.setItem('notificationAssignUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							setShowAssignLeadModel({ show: true, notifyID: _afterMarketEnquiry.documentID, recordID: _afterMarketEnquiry.recordID });
							setTitleLeadModel('Aftermarket Enquiry');
							setAfterMarketModel(true);
							notifyWithSound();
						}
					}

					// INBOUND LEAD NOTIFICATION
					let _recordInbound = _notification.filter(m => m.type === 'inbound' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordInbound) && (_.isEmpty(localStorage.notificationInboundUpdatedOn) || (!_.isEmpty(localStorage.notificationInboundUpdatedOn) && _recordInbound.addedDate.seconds > Number(localStorage.notificationInboundUpdatedOn)))) {

						if (_recordInbound.documentID && _recordInbound.recordID) {
							localStorage.setItem('notificationInboundUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordInbound.recordID);
							//setNotificationId(_recordInbound.documentID);
							setShowInboundLeadModel({ show: true, notifyID: _recordInbound.documentID, recordID: _recordInbound.recordID });
							notifyWithSound();
						}
					}

					// INBOUND TIP NOTIFICATION
					let _recordInboundTIP = _notification.filter(m => m.type === 'inboundTIP' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordInboundTIP) && (_.isEmpty(localStorage.notificationInboundTIPUpdatedOn) || (!_.isEmpty(localStorage.notificationInboundTIPUpdatedOn) && _recordInboundTIP.addedDate.seconds > Number(localStorage.notificationInboundTIPUpdatedOn)))) {

						if (_recordInboundTIP.documentID && _recordInboundTIP.recordID) {
							localStorage.setItem('notificationInboundTIPUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordInboundTIP.recordID);
							//setNotificationId(_recordInboundTIP.documentID);
							setShowInboundTIPModel({ show: true, notifyID: _recordInboundTIP.documentID, recordID: _recordInboundTIP.recordID });
							notifyWithSound();
						}
					}

					// LOAN VEHICLE NOTIFICATION
					let _recordFleet = _notification.filter(m => m.type === 'fleet' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordFleet) && (_.isEmpty(localStorage.notificationFleetUpdatedOn) || (!_.isEmpty(localStorage.notificationFleetUpdatedOn) && _recordFleet.addedDate.seconds > Number(localStorage.notificationFleetUpdatedOn)))) {

						if (_recordFleet.documentID && _recordFleet.recordID) {
							localStorage.setItem('notificationFleetUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordFleet.recordID);
							//setNotificationId(_recordFleet.documentID);
							setShowFleetModel({ show: true, notifyID: _recordFleet.documentID, recordID: _recordFleet.recordID });
							notifyWithSound();
						}
					}

					// SERVICE REQUEST NOTIFICATION
					let _recordServiceRequest = _notification.filter(m => m.type === 'serviceRequest' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (localStorage.defaultModule === 'service' && !_.isEmpty(_recordServiceRequest) && (_.isEmpty(localStorage.notificationJobRequestUpdatedOn) || (!_.isEmpty(localStorage.notificationJobRequestUpdatedOn) && _recordServiceRequest.addedDate.seconds > Number(localStorage.notificationJobRequestUpdatedOn)))) {

						if (_recordServiceRequest.documentID && _recordServiceRequest.recordID) {
							localStorage.setItem('notificationJobRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordServiceRequest.recordID);
							//setNotificationId(_recordServiceRequest.documentID);
							setShowServiceRequestModel({ show: true, notifyID: _recordServiceRequest.documentID, recordID: _recordServiceRequest.recordID });
							notifyWithSound();
						}
					}

					// TRADEINPRO REQUEST NOTIFICATION
					let _recordTradeinProRequest = _notification.filter(m => m.type === 'tradeinProRequest' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordTradeinProRequest) && (_.isEmpty(localStorage.notificationTIPRequestUpdatedOn) || (!_.isEmpty(localStorage.notificationTIPRequestUpdatedOn) && _recordTradeinProRequest.addedDate.seconds > Number(localStorage.notificationTIPRequestUpdatedOn)))) {

						if (_recordTradeinProRequest.documentID && _recordTradeinProRequest.recordID) {
							localStorage.setItem('notificationTIPRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setRecordId(_recordTradeinProRequest.recordID);
							//setNotificationId(_recordTradeinProRequest.documentID);
							setShowTradeinProRequestModel({ show: true, notifyID: _recordTradeinProRequest.documentID, recordID: _recordTradeinProRequest.recordID });
							notifyWithSound();
						}
					}

					// AIRCALL REQUEST NOTIFICATION
					let _recordAircallRequest = _notification.filter(m => (m.type === 'aircallContact' || m.type === 'aircallMissed' || m.type === 'callContact' || m.type === 'callMissed' || m.type === 'smsMissed') && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];

					if (!_.isEmpty(_recordAircallRequest) && (_.isEmpty(localStorage.notificationAircallRequestUpdatedOn) || (!_.isEmpty(localStorage.notificationAircallRequestUpdatedOn) && _recordAircallRequest.addedDate.seconds > Number(localStorage.notificationAircallRequestUpdatedOn)))) {

						if (_recordAircallRequest.documentID) {
							localStorage.setItem('notificationAircallRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							//setNotificationId(_recordAircallRequest.documentID);
							setShowAircallNotifyModel({ show: true, notifyID: _recordAircallRequest.documentID, recordID: null });
							notifyWithSound();
						}
					}

					//ACTIVITY OR LOGS NOTIFICATION
					if (_.isEmpty(localStorage.notificationLogRequestUpdatedOn)) {
						localStorage.setItem('notificationLogRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
					}
					let rec = _notification.filter(m => [
						'activity', 'log', 'salesHub',
						'financeactivity', 'financelog',
						'afterMarketactivity', 'afterMarketlog',
						'tradeinProactivity', 'tradeinProlog',
						'serviceactivity', 'servicelog',
						'tradeinProValuer', 'tradeinProFinance', 'fleetProAssign'
					].includes(m.type) && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
					if (!_.isEmpty(rec) && ((!_.isEmpty(localStorage.notificationLogRequestUpdatedOn) && rec.addedDate.seconds > Number(localStorage.notificationLogRequestUpdatedOn)))) {

						let recTitle = !_.isEmpty(rec.subType) && (rec.type === 'log' || rec.type === 'financelog' || rec.type === 'afterMarketlog') ? CommonHelper.autoCaps(rec.subType) : rec.title
						if (rec.documentID && rec.recordID) {
							localStorage.setItem('notificationLogRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							notifyWithSound();
							toast.notify(
								({ onClose }) => (
									<NotifyToast
										payload={{
											data: rec,
											notification: {
												...rec,
												title: recTitle
											}
										}}
										onClose={onClose}
										handleNotifyClick={handleNotifyClick}
										currentClient={currentClient}
										dealersettings={dealersettings}
									/>
								),
								{
									duration: 4000,
									position: 'top-right',
								},
							);

							if (['fleetProAssign'].includes(rec.type)) {
								setfleetAssignModal({ show: true, notifyID: rec.documentID, docID: rec.recordID, fleetProID: rec?.fleetProID })
							}
							else if (rec.type === 'salesHub') {
								setSaleshubcommentsModel({ show: true, notification: rec })
							}
							else if (!['tradeinProValuer', 'tradeinProFinance'].includes(rec.type)) {
								setShowNotifyModel({ show: true, notification: rec })
							}

							// if (rec.type !== 'settings') {
							// 	let _icon = `ico icon-${(['activity', 'tradeinProactivity', 'serviceactivity'].includes(rec.type) || (['log', 'tradeinProlog', 'servicelog'].includes(rec.type) && _.isEmpty(rec.subType)))
							// 		? 'date' :
							// 		(['log', 'tradeinProlog', 'servicelog'].includes(rec.type)) ?
							// 			rec.subType :
							// 			((rec.type === 'kitchen')
							// 				? 'cafe' :
							// 				(rec.type === 'sms'
							// 					? 'sms-inbound' :
							// 					(rec.type === 'service'
							// 						? 'service-settings' :
							// 						(rec.type === 'tradeinPro' || rec.type === 'tradeinProRequest'
							// 							? CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'tradein-module-icon') :
							// 							(rec.type === 'tradeinProOffer'
							// 								? 'finance' :
							// 								(rec.type === 'aircallMissed' || rec.type === 'callMissed'
							// 									? 'no-call ico-lg' :
							// 									((rec.type === 'aircallContact' || rec.type === 'callContact') && rec.callType === 'outbound'
							// 										? 'call-outbound' :
							// 										((rec.type === 'aircallContact' || rec.type === 'callContact')
							// 											? 'call-inbound' :
							// 											((rec.type === 'smsMissed')
							// 												? 'message' :
							// 												'enquiry'
							// 											)))))))))}`
							// 	Swal.fire({
							// 		title: 'Notification',
							// 		html:
							// 			'<div class="swal-custom-notify-wrap">' +
							// 			'<div class="swal-custom-notify-border">' +
							// 			'<div class="text-center mt-2">' +
							// 			'<div class="swal-custom-notify-icon"> ' +
							// 			`<i class="${_icon}"></i> ` +
							// 			'</div>' +
							// 			'</div>' +
							// 			`div class="swal-custom-notify-subhead">${recTitle}</div>` +
							// 			`<div class="swal-custom-notify-content">${rec.body}</div>` +
							// 			'</div>' +
							// 			'</div>',
							// 		showCancelButton: true,
							// 		confirmButtonText: 'View Now',
							// 		cancelButtonText: 'Later',
							// 	}).then(result => {
							// 		if (result.value) {
							// 			handleNotifyClick(rec.recordID, rec.type, rec.documentID, rec.enquiryID, rec.contactID, rec, dealersettings)
							// 		}
							// 		//else notifyRead(rec.documentID);
							// 	});
							// }
						}
					}

				}
				if (!_.isEmpty(_chat) && currentClient && localStorage.defaultModule !== 'oem') {
					//ACTIVITY OR LOGS NOTIFICATION
					if (_.isEmpty(localStorage.notificationChatRequestUpdatedOn)) {
						localStorage.setItem('notificationChatRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
					}
					let rec = _chat.filter(m => ['chat', 'email'].includes(m.type) && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
					if (!_.isEmpty(rec) && ((!_.isEmpty(localStorage.notificationChatRequestUpdatedOn) && rec.addedDate.seconds > Number(localStorage.notificationChatRequestUpdatedOn)))) {

						let recTitle = `${rec.title}`
						if (rec.documentID && rec.recordID) {
							localStorage.setItem('notificationChatRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
							notifyWithSound();
							toast.notify(
								({ onClose }) => (
									<NotifyToast
										payload={{
											data: rec,
											notification: {
												...rec,
												title: recTitle
											}
										}}
										onClose={onClose}
										handleNotifyClick={handleNotifyClick}
										currentClient={currentClient}
										dealersettings={dealersettings}
									/>
								),
								{
									duration: 4000,
									position: 'top-right',
								},
							);
							if (rec.type === 'chat' && (_.isEmpty(localStorage.showChat) || localStorage.showChat === 'false')) {
								if (rec.contactID || rec.enquiryID) {
									setShowNotifyModel({ show: true, notification: rec })
								} else {
									setShowChatModel({ show: true, notification: rec })
								}
							} else if (rec.type === 'email') {
								if (window.location.pathname.includes('/mails')) setTheardID(rec.recordID)

								setShowEmailModel({
									show: true,
									notification: rec,
									title: 'Email Received',
									history
								})
							}
						}
					}
				}
				setNotifications(_notification);
				setNotificationCount(_notification.filter(e => e.isRead === false).length);
				//setUnReadChatCounts(chat.filter(e => e.isRead === false).length);

			});

		return () => {
			refNotificationData && refNotificationData.unsubscribe()

		}

	}, [localClient, localStorage.defaultModule])

	useEffect(() => {
		if (_.isEmpty(localClient))
			return

		let refActivitiesData = firestoreDB(dealersettings).firestore().collection('activities')
			.where('type', '==', CommonHelper.getActivityType('activity'))
			.where('isDeleted', '==', false)
		if (localStorage.defaultModule === 'tradeinPro') {
			refActivitiesData = refActivitiesData.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true));
		}
		else {
			refActivitiesData = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(refActivitiesData, dealersettings, localClient, true);
		}
		refActivitiesData = refActivitiesData
			.where('searchUsers', 'array-contains', localStorage.uid)
			.where('isDone', '==', false)
			.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate()))
			.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day').toDate()))
			.orderBy('startDate', 'desc')
		const refReminderActivities = refActivitiesData
			.onSnapshot(querySnapshot => {
				let _activties = []
				querySnapshot.forEach(function (doc) {
					if (doc.data().reminder >= 0)
						_activties.push({
							...doc.data(),
							documentID: doc.id
						})
				});
				if (window.reminderTimeout) {
					clearInterval(window.reminderTimeout);
				}
				window.reminderTimeout = setInterval(() => {
					setLocalDate(moment().format('DD-MM-YYYY HH:mm'));
					_activties.filter(rec => (rec.startDate.seconds - (rec.reminder * 60)) > (moment().unix() - 60)).forEach(rec => {
						if (rec.owner === localStorage.uid && moment.unix(rec.startDate.seconds - (rec.reminder * 60)).format('DD-MM-YYYY HH:mm') === moment().format('DD-MM-YYYY HH:mm')) {
							setReminderActivity(rec);
							setShowReminderModel(true);
						}
					})

				}, 60000);
			});

		return () => {
			window.reminderTimeout && clearInterval(window.reminderTimeout);
			refReminderActivities && refReminderActivities();
		}

	}, [localClient, localDate, localStorage.defaultModule])

	const loadMoreNotifications = async () => {
		const limit = 50
		let salesNotifications = []
		let serviceNotifications = []
		let tradeinproNotifications = []

		if (hasMoreSalesNotifications.length) {
			let salesNotificationsToAdd = []
			let salesNotificationsRef = window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['engageRequest', 'activity', 'enquiry', 'finance', 'afterMarket', 'pendingLost', 'log', 'kitchen', 'callLogs', 'inbound', 'fleet', 'fleetProAssign'])//if add/remove any type pls do update in sidebar.js/updateNotification and the onSnapshot listener above
				.where('toUserID', '==', localStorage.uid)
				.orderBy('addedDate', 'desc')
				.startAfter(hasMoreSalesNotifications[0])
				.limit(limit)
			if (localStorage.currentDealerNorification === 'true') {
				salesNotificationsRef = salesNotificationsRef.where('clientID', '==', currentClient)
			}
			const salesNotificationsSnapshots = await salesNotificationsRef.get()
			//console.log('salesNotificationsSnapshots Length', salesNotificationsSnapshots.docs.length);
			if (!salesNotificationsSnapshots.empty) {
				salesNotificationsSnapshots.forEach((doc) => {
					salesNotificationsToAdd.push(doc.data())
				});
				if (salesNotificationsSnapshots.docs.length > 0 && salesNotificationsSnapshots.docs.length <= limit) {
					setHasMoreSalesNotifications([salesNotificationsSnapshots.docs[salesNotificationsSnapshots.docs.length - 1]])
				} else {
					setHasMoreSalesNotifications([])
				}
				salesNotifications = [...salesNotificationsToAdd]
			}
		}

		if (hasMoreServiceNotifications.length) {
			let serviceNotificationsToAdd = []
			let serviceNotificationsRef = window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['service', 'serviceRequest', 'serviceactivity', 'servicelog', 'financeactivity', 'financelog', 'aircallMissed', 'aircallContact', 'callMissed', 'smsMissed', 'callContact', 'afterMarketactivity', 'afterMarketlog'])//if add/remove any type pls do update in sidebar.js/updateNotification and the onSnapshot listener above
				.where('toUserID', '==', localStorage.uid)
				.orderBy('addedDate', 'desc')
				.startAfter(hasMoreServiceNotifications[0])
				.limit(limit)
			if (localStorage.currentDealerNorification === 'true') {
				serviceNotificationsRef = serviceNotificationsRef.where('clientID', '==', currentClient)
			}
			const serviceNotificationsSnapshots = await serviceNotificationsRef.get()
			//console.log('serviceNotificationsSnapshots Length', serviceNotificationsSnapshots.docs.length);

			if (!serviceNotificationsSnapshots.empty) {
				serviceNotificationsSnapshots.forEach((doc) => {
					serviceNotificationsToAdd.push(doc.data())
				});
				if (serviceNotificationsSnapshots.docs.length > 0 && serviceNotificationsSnapshots.docs.length <= limit) {
					setHasMoreServiceNotifications([serviceNotificationsSnapshots.docs[serviceNotificationsSnapshots.docs.length - 1]])
				} else {
					setHasMoreServiceNotifications([])
				}
				serviceNotifications = [...serviceNotificationsToAdd]
			}
		}

		if (hasMoreTradeinProNotifications.length) {
			let tradeinproNotificationsToAdd = []
			let tradeinproNotificationsRef = window.firebase.firestore().collection('notifications')
				.where('isDeleted', '==', false)
				.where('type', 'in', ['tradein', 'tradeinPro', 'tradeinProOffer', 'tradeinProRequest', 'tradeinProValuer', 'tradeinProFinal', 'tradeinProactivity', 'tradeinProlog', 'inboundTIP'])//if add/remove any type pls do update in sidebar.js/updateNotification and the onSnapshot listener above
				.where('toUserID', '==', localStorage.uid)
				.orderBy('addedDate', 'desc')
				.startAfter(hasMoreTradeinProNotifications[0])
				.limit(limit)
			if (localStorage.currentDealerNorification === 'true') {
				tradeinproNotificationsRef = tradeinproNotificationsRef.where('clientID', '==', currentClient)
			}
			const tradeinproNotificationsSnapshots = await tradeinproNotificationsRef.get()
			//console.log('tradeinproNotificationsSnapshots Length', tradeinproNotificationsSnapshots.docs.length);

			if (!tradeinproNotificationsSnapshots.empty) {
				tradeinproNotificationsSnapshots.forEach((doc) => {
					tradeinproNotificationsToAdd.push(doc.data())
				});
				if (tradeinproNotificationsSnapshots.docs.length > 0 && tradeinproNotificationsSnapshots.docs.length <= limit) {
					setHasMoreTradeinProNotifications([tradeinproNotificationsSnapshots.docs[tradeinproNotificationsSnapshots.docs.length - 1]])
				} else {
					setHasMoreTradeinProNotifications([])
				}
				tradeinproNotifications = [...tradeinproNotificationsToAdd]
			}
		}

		setNotifications(_.uniqBy([...userNotifications, ...salesNotifications, ...serviceNotifications, ...tradeinproNotifications], 'documentID'))
	}

	useEffect(() => {
		const userRefDoc = window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}`)
			.onSnapshot(docSnapshot => {
				if (docSnapshot.exists) {
					const userdoc = docSnapshot.data();
					setTokens(userdoc.tokkens);
					setUserLoad(true);
					setEmailSignature(userdoc.emailSignature);
					localStorage.setItem('isCafeOrderNotifySound', userdoc.isCafeOrderNotifySound ? userdoc.isCafeOrderNotifySound : false)
					localStorage.setItem('isNotifyWithSound', userdoc.isNotifyWithSound ? userdoc.isNotifyWithSound : false)
					localStorage.setItem('currentDealerNorification', userdoc.currentDealerNorification ? userdoc.currentDealerNorification : false)
					if (userdoc?.incomingCall === true) {
						setJustcallModal(true)
					}
				}
			});
		return () => {
			userRefDoc && userRefDoc();
		};
	}, []);

	useEffect(() => {
		if (!_.isEmpty(dealersettings)) {
			setDealersettings({
				...dealersettings,
				emailSignature: emailSignature
			})
		}
	}, [emailSignature])

	useEffect(() => {
		if (_.isEmpty(currentClient)) {
			return
		}
		const unSubuserclientRefDoc = window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}/userClients/${currentClient}`)
			.onSnapshot(docSnapshot => {
				if (docSnapshot.exists) {
					const userdoc = docSnapshot.data();
					setQueueNum(userdoc.queueNum);
				}
			});

		return () => {
			unSubuserclientRefDoc && unSubuserclientRefDoc();
		};
	}, [currentClient]);

	useEffect(() => {
		if (_.isEmpty(currentClient) || _.isEmpty(localStorage.defaultModule) || groupUsersModule.includes(localStorage.defaultModule))
			return

		const clientUserCollection = window.firebase.firestore().collection('users')
			.where('arrayClientID', 'array-contains', currentClient)
			.get()
			.then(async (docSnapshot) => {
				let _users = [];
				docSnapshot.docs.forEach(doc => {
					const _userdoc = doc.data();
					if (!_.isEmpty(_userdoc.settingsID) && (!Boolean(_userdoc.superAdmin) || localStorage.isDemo === 'demo'))// && _userdoc.isHidden !== true)
						_users.push({ ..._userdoc, 'documentID': doc.id, 'id': doc.id, 'active': !Boolean(_userdoc.isDisabled), 'clientID': currentClient })
				});
				if (_users[0]?.settingsID) {
					const oemUsers = await window.firebase.firestore().collection('users')
						.where('arrayOEMID', 'array-contains', _users[0]?.settingsID)
						.get();
					if (oemUsers.docs.length > 0) {
						oemUsers.docs.forEach(rec => {
							if (!_users.some(a => a.id === rec.id)
								&& !_.isEmpty(rec.data().settingsID)
								&& (!Boolean(rec.data().superAdmin) || localStorage.isDemo === 'demo')
								&& (_.isEmpty(_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients) ||
									_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients?.includes(currentClient))) {
								_users.push({
									...rec.data(),
									'documentID': rec.id,
									'id': rec.id,
									'active': !Boolean(rec.isDisabled),
									'clientID': currentClient,
									'otherOEMAccess': true,
									'roleID': _.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.roleID || rec.data()?.roleID
								})
							}
						})
					}
				}
				if (!_.isEmpty(_users))
					_users = _.orderBy(_users, ['name'], ['asc']);

				setClientUsers(_users);

			});
		//return () => {
		//clientUserCollection && clientUserCollection();
		//}
	}, [currentClient, localStorage.defaultModule])

	useEffect(() => {
		if (_.isEmpty(dealersettings) || dealersettings.wholesalerDashboard || _.isEmpty(localStorage.defaultModule) || !groupUsersModule.includes(localStorage.defaultModule))
			return

		let tradeinProRoles = [];
		if (localStorage.defaultModule === 'tradeinPro') {
			tradeinProRoles = dealersettings.client &&
				dealersettings.client.roles &&
				dealersettings.client.roles.filter(a => a.modules && a.modules.tradeinPro === true)
					.map(r => {
						return r.id
					})
		}

		let refUserCollection = window.firebase.firestore().collection('users')
		if (dealersettings.level === 'oem' && dealersettings.oemDashboard === true)
			refUserCollection = refUserCollection.where('settingsID', '==', dealersettings.oemID).where('level', '==', 'oem');
		else if (dealersettings?.level?.includes('region') && dealersettings.oemDashboard === true)
			refUserCollection = refUserCollection.where('regionID', '==', dealersettings.regionID).where('level', '==', 'region');
		else if (dealersettings.level === 'group' && dealersettings.oemDashboard === true)
			refUserCollection = refUserCollection.where('groupID', '==', dealersettings.groupID).where('level', '==', 'group');
		else
			refUserCollection = refUserCollection.where('arrayClientID', 'array-contains', dealersettings.client.id);

		const oemUserCollection = refUserCollection.get().then(async (docSnapshot) => {
			let _users = [{ isHidden: true }];
			docSnapshot.docs.forEach(doc => {
				const _userdoc = doc.data();
				if (_userdoc.isDisabled !== true && !_.isEmpty(_userdoc.settingsID) && (!Boolean(_userdoc.superAdmin) || localStorage.isDemo === 'demo'))// && _userdoc.isHidden !== true)
					_users.push({ ..._userdoc, 'documentID': doc.id, 'id': doc.id, 'active': true, 'clientID': currentClient })
			});

			const oemUsers = await window.firebase.firestore().collection('users')
				.where('arrayOEMID', 'array-contains', dealersettings.oemID)
				.get();
			if (oemUsers.docs.length > 0) {
				oemUsers.docs.forEach(rec => {
					if (!_users.some(a => a.id === rec.id) && rec.data().isDisabled !== true
						&& !_.isEmpty(rec.data().settingsID)
						&& (!Boolean(rec.data().superAdmin) || localStorage.isDemo === 'demo')
						&& (_.isEmpty(_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients) ||
							_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients?.includes(currentClient))) {
						_users.push({ ...rec.data(), 'documentID': rec.id, 'id': rec.id, 'active': !Boolean(rec.isDisabled), 'clientID': currentClient, 'otherOEMAccess': true })
					}
				})
			}
			if (!_.isEmpty(_users))
				_users = _.orderBy(_users, ['name'], ['asc']);

			setClientUsers(_users.filter(a => tradeinProRoles.length > 0 ? tradeinProRoles.includes(a.roleID) : true));

			let refSettingsSnapshot = `clients/${dealersettings.client.id}/currentSettings/${dealersettings.client.id}`;
			if (dealersettings.level === 'oem' && dealersettings.oemDashboard === true)
				refSettingsSnapshot = `clientSettings/${dealersettings.oemID}/currentSettings/${dealersettings.oemID}`;
			else if (dealersettings?.level?.includes('region') && dealersettings.oemDashboard === true)
				refSettingsSnapshot = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/currentSettings/${dealersettings.regionID}`;
			else if (dealersettings.level === 'group' && dealersettings.oemDashboard === true)
				refSettingsSnapshot = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/currentSettings/${dealersettings.groupID}`;
			window.firebase
				.firestore()
				.doc(refSettingsSnapshot)
				.set({ users: CommonHelper.removeEmptyOrNull(_.map(_users, _.partialRight(_.pick, ['id', 'name', 'email', 'roleID', 'superAdmin', 'dmsKeys', 'phone', 'nylas.account_id', 'nylas_v3.grantId', 'notifyInboundlead', 'notifyNewtradeinPro', 'notifyInboundleadOEM', 'isDisabled', 'salesType', 'notifyServiceBooking', 'notifyKitchen', 'alerts', 'department']))) }, { merge: true });

		});


		async function getAllUsers(_tradeinProRoles) {
			let allUserPromise = [];
			let _groupUsers = [];
			let _clients = [];
			dealersettings.clients.filter(c => localStorage.defaultModule === 'tradeinPro' ?
				c.moduleSettings
				&& c.moduleSettings[localStorage.defaultModule]
				&& c.moduleSettings[localStorage.defaultModule].enabled === true
				&& c.moduleSettings[localStorage.defaultModule].active === true
				: true).forEach(rec => {
					if (_tradeinProRoles.length > 0) {
						_tradeinProRoles.forEach(role => {
							allUserPromise.push(window.firebase.firestore().collection(`users`)
								.where('arrayClientID', 'array-contains', rec.id)
								.where('roleID', '==', role)
								.get())
							_clients.push({
								id: rec.id,
								name: rec.name
							});
						})
					}
					else {
						allUserPromise.push(window.firebase.firestore().collection(`users`).where('arrayClientID', 'array-contains', rec.id).get())
						_clients.push({
							id: rec.id,
							name: rec.name
						});
					}

				})
			const promiseUsersSnapshot = await Promise.all(allUserPromise);
			promiseUsersSnapshot.forEach((groupUsersSnapshot, index) => {
				groupUsersSnapshot.docs.forEach(user => {
					const _userdoc = user.data();
					if (_userdoc.isDisabled !== true && !_.isEmpty(_userdoc.settingsID) && (!Boolean(_userdoc.superAdmin) || localStorage.isDemo === 'demo'))// && _userdoc.isHidden !== true)
						_groupUsers.push(
							{
								..._.pick(_userdoc, ['name', 'profileImage', 'email', 'phone', 'designation', 'superAdmin', 'arrayGroupID', 'arrayRegionID', 'arraySubregionID']),
								'label': (
									<div className='drop-image'>
										<img src={CommonHelper.showUserAvatar(_userdoc.profileImage, _userdoc.name)} alt="" width="50" className="img-object-fit" />
										{_userdoc.name}
									</div>
								),
								'id': user.id,
								'documentID': user.id,
								'value': user.id,
								'clientID': _clients[index] ? _clients[index].id : _userdoc.defaultClientID,
								'clientName': _clients[index] ? _clients[index].name : '',
								'roleID': _userdoc.roleID,
								'role': _userdoc.role,
								'clientIDs': _userdoc.arrayClientID,
								'searchlabel': _userdoc.name,
								'active': !Boolean(_userdoc.isDisabled)
							}
						)
				})
			})
			setGroupUsers(_.orderBy(_groupUsers, ['name'], ['asc']));
		}
		getAllUsers([]);

		//return () => {
		//oemUserCollection && oemUserCollection();
		//}
	}, [(dealersettings ? dealersettings.level : false), localStorage.defaultModule])


	useEffect(() => {
		if (_.isEmpty(currentGroup) || _.isEmpty(clientUsers) || localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'tradeinPro' || localStorage.defaultModule === 'wholesaler') {
			if (!groupUsersModule.includes(localStorage.defaultModule))
				setGroupUsers([]);
			return
		}

		async function getGroupUsers() {
			const _groupUsers = []
			const _groupClients = Object.keys(currentGroup.clients)
				.map(cid => { return { id: cid, name: currentGroup.clients[cid].name } });
			for (let i = 0; i < _groupClients.length; i++) {
				if (_groupClients[i].id === currentClient) {
					clientUsers.map(rec => {
						return _groupUsers.push({
							..._.pick(rec, ['name', 'profileImage', 'email', 'phone', 'designation', 'active']),
							'label': (
								<div className='drop-image'>
									<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
									{rec.name}
								</div>
							),
							'id': rec.id,
							'value': rec.id,
							'clientID': _groupClients[i].id,
							'clientName': _groupClients[i].name,
							'searchlabel': rec.name,
							'roleID': rec.roleID,
							'role': rec.role
						})
					})
				}
				else {
					const groupUsersSnapshot = await window.firebase.firestore().collection(`users`).where('arrayClientID', 'array-contains', _groupClients[i].id).get();
					groupUsersSnapshot.docs.forEach(user => {
						const _userdoc = user.data();
						if (_userdoc.isDisabled !== true && !_.isEmpty(_userdoc.settingsID) && (!Boolean(_userdoc.superAdmin) || localStorage.isDemo === 'demo'))// && _userdoc.isHidden !== true)
							_groupUsers.push(
								{
									..._.pick(_userdoc, ['name', 'profileImage', 'email', 'phone', 'designation', 'superAdmin', 'arrayGroupID', 'arrayRegionID', 'arraySubregionID']),
									'label': (
										<div className='drop-image'>
											<img src={CommonHelper.showUserAvatar(_userdoc.profileImage, _userdoc.name)} alt="" width="50" className="img-object-fit" />
											{_userdoc.name}
										</div>
									),
									'id': user.id,
									'value': user.id,
									'clientID': _groupClients[i].id,
									'clientName': _groupClients[i].name,
									'searchlabel': _userdoc.name,
									'roleID': _userdoc.roleID,
									'role': _userdoc.role,
									'active': !Boolean(_userdoc.isDisabled)
								}
							)
					})
				}
			}
			setGroupUsers(_.orderBy(_groupUsers, ['name'], ['asc']))
		}
		getGroupUsers()

	}, [currentGroup, clientUsers])

	useEffect(() => {
		if (_.isEmpty(dealersettings) || !Boolean(dealersettings.wholesalerDashboard))
			return

		async function getAllUsers() {
			let allUserPromise = [];
			let _groupUsers = [];
			let _clients = [];
			dealersettings.wholesalerClientIDs.forEach(rec => {
				allUserPromise.push(window.firebase.firestore().collection(`users`).where('arrayClientID', 'array-contains', rec).get())
			})
			const promiseUsersSnapshot = await Promise.all(allUserPromise);
			promiseUsersSnapshot.forEach((groupUsersSnapshot, index) => {
				groupUsersSnapshot.docs.forEach(user => {
					const _userdoc = user.data();
					if (_userdoc.isDisabled !== true && !Boolean(_userdoc.superAdmin))// && _userdoc.isHidden !== true)
						_groupUsers.push(
							{
								..._.pick(_userdoc, ['name', 'profileImage', 'email', 'phone', 'designation']),
								'label': (
									<div className='drop-image'>
										<img src={CommonHelper.showUserAvatar(_userdoc.profileImage, _userdoc.name)} alt="" width="50" className="img-object-fit" />
										{_userdoc.name}
									</div>
								),
								'id': user.id,
								'documentID': user.id,
								'value': user.id,
								'searchlabel': _userdoc.name
							}
						)
				})
			})
			_groupUsers.push({
				name: dealersettings.name,
				id: dealersettings.id,
				value: dealersettings.id,
				email: dealersettings.email
			})
			setGroupUsers(_.orderBy(_groupUsers, ['name'], ['asc']));
			setClientUsers(_.orderBy(_groupUsers, ['name'], ['asc']));
		}
		getAllUsers();
	}, [dealersettings])

	useEffect(() => {
		if (!_.isEmpty(localClient)) {

			let _roles = dealersettings && dealersettings.client && dealersettings.client.roles ? dealersettings.client.roles : [];

			window.firebase.firestore().collection('users')
				.where('arrayClientID', 'array-contains', localClient)
				.get()
				.then(async (docSnapshot) => {
					let _users = [];
					docSnapshot.docs.forEach(doc => {
						const _userdoc = doc.data();
						_users.push({
							..._userdoc,
							'id': doc.id,
							'salesType': _.find(_roles, { id: _userdoc.roleID }) && _.find(_roles, { id: _userdoc.roleID }).salesType ? _.find(_roles, { id: _userdoc.roleID }).salesType.join(',') : ''
						})
					})
					const oemUsers = await window.firebase.firestore().collection('users')
						.where('arrayOEMID', 'array-contains', dealersettings.oemID)
						.get();
					if (oemUsers.docs.length > 0) {
						oemUsers.docs.forEach(rec => {
							if (!_users.some(a => a.id === rec.id) && !_.isEmpty(rec.data().settingsID)
								&& (_.isEmpty(_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients) ||
									_.find(rec.data().arrayOEM, { oem: _users[0]?.settingsID })?.clients?.includes(currentClient))) {
								_users.push({ ...rec.data(), 'documentID': rec.id, 'id': rec.id, 'active': !Boolean(rec.isDisabled), 'clientID': currentClient, 'otherOEMAccess': true })
							}
						})
					}
					//update all lookups in current client sub collection
					window.firebase
						.firestore()
						.doc(`clients/${localClient}/currentSettings/${localClient}`)
						.set({ users: CommonHelper.removeEmptyOrNull(_.map(_users, _.partialRight(_.pick, ['id', 'name', 'email', 'roleID', 'superAdmin', 'dmsKeys', 'phone', 'nylas.account_id', 'nylas_v3.grantId', 'notifyInboundlead', 'notifyNewtradeinPro', 'notifyInboundleadOEM', 'isDisabled', 'salesType', 'notifyServiceBooking', 'notifyKitchen', 'alerts', 'department']))) }, { merge: true });
				});

			// window.firebase.firestore().collection(`clients/${localClient}/integrations`)
			// 	.get()
			// 	.then((docSnapshot) => {
			// 		let _integrations = [];
			// 		docSnapshot.docs.forEach(doc => {
			// 			_integrations.push({ ...doc.data(), 'id': doc.id })
			// 		})

			// 		//update all lookups in current client sub collection
			// 		window.firebase
			// 			.firestore()
			// 			.doc(`clients/${localClient}/currentSettings/${localClient}`)
			// 			.set({ integrations: _integrations }, { merge: true });
			// 	});
		}
	}, [localClient])

	// useEffect(() => {
	// 	if (!_.isEmpty(currentClient)) {
	// 		window.unSubuserclientRefDoc = window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${currentClient}`)
	// 			.onSnapshot((docSnapshot) => {
	// 				if (docSnapshot.exists) {
	// 					const userdoc = docSnapshot.data();
	// 					setQueueNum(userdoc.queueNum);
	// 				}
	// 			});
	// 	}
	// }, [currentClient])

	useEffect(() => {
		if (!_.isEmpty(devicefcmToken) && currentClient && checkUserload && _.isArray(deviceTokens) && !deviceTokens.some(item => item === devicefcmToken)) {
			window.firebase.firestore().doc(`/users/${localStorage.uid}`)
				.set({ tokkens: [...deviceTokens, devicefcmToken] }, { merge: true });
		}
		if (!_.isEmpty(devicefcmToken) && currentClient && checkUserload && _.isArray(deviceTokens) && !_.isEmpty(currentClient) && localStorage.isCurrentlyLogin) {
			// console.log('isCurrentlyLogin');
			localStorage.removeItem('isCurrentlyLogin');
			window.firebase.firestore()
				.doc(`users/${localStorage.uid}/userClients/${currentClient}`)
				.update({
					isQueue: true
				}).catch((error) => {
					console.error("Error updating contacts: ", error);
				});
		}
	}, [devicefcmToken, deviceTokens, currentClient])

	// useEffect(() => {
	// 	if (!_.isEmpty(devicefcmToken) && !_.isEmpty(localStorage.browserKey)) {
	// 		window.firebase
	// 			.firestore()
	// 			.doc(`/users/${localStorage.uid}/devices/${localStorage.browserKey}`)
	// 			.set({ token: devicefcmToken }, { merge: true });
	// 	}
	// }, [localStorage.browserKey, devicefcmToken]);

	//check settings update
	useEffect(() => {
		if (_.isEmpty(currentClient))
			return

		const unSubcurrentclientRefDoc = window.firebase.firestore().doc(`clients/${currentClient}`)
			.onSnapshot((docSnapshot) => {
				if (docSnapshot.exists) {
					const clientdoc = docSnapshot.data();

					if (!_.isEmpty(localStorage.settingsUpdatedOn) && clientdoc.settingsUpdate && clientdoc.settingsUpdate.seconds > Number(localStorage.settingsUpdatedOn)) {
						toast.closeAll()
						toast.notify(
							({ onClose }) => (
								<div className="notify-fixed">
									<div className="notify-fixed-toast-wraper">
										<div className="notification-settings-close"><a href="#" onClick={() => {
											onClose();
											localStorage.setItem('settingsUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
										}

										}><i className="ico icon-remove"></i> </a></div>
										<div className="notify-fixed-toast d-flex">
											<div className="mr-3">
												<div className="notify-fixed-toast-circle"> <i className="ico icon-settings"></i> </div>
											</div>
											<div>
												<span className="notify-fixed-toast-header">
													{clientdoc.settingsUpdateText || 'Settings has been updated.'}
												</span> <span className="notify-fixed-toast-info">Please update your web settings to apply the changes.</span>
												{
													clientdoc.settingsUpdateBy ? (
														<span className="notify-fixed-toast-info">Updated By : {clientdoc.settingsUpdateBy}</span>
													) : (<></>)
												}
												<div className="notify-fixed-toast-button"><button type="button" className="btn btn-default" onClick={() => {
													onClose();
													//getdealerSettings(true);
													window.location.reload(true)
												}}>Update</button></div>
											</div>
										</div>
									</div>

								</div >
							),
							{
								duration: null,
								position: 'bottom-right',
							},
						);
					}
				}
			});
		return () => {
			unSubcurrentclientRefDoc && unSubcurrentclientRefDoc()
		}
	}, [currentClient])

	// const onCollectionUpdate = (querySnapshot) => {
	// 	let _notification = []

	// 	querySnapshot.forEach(function (doc) {
	// 		_notification.push({
	// 			...doc.data(),
	// 			documentID: doc.id
	// 		})
	// 	});

	// 	//_notification = _.filter(_notification, (v) => _.includes(['engageRequest', 'activity', 'enquiry', 'pendingLost', 'log', 'kitchen'], v.type));

	// 	_notification = _notification.sort(function (x, y) { return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1); })
	// 	if (!_.isEmpty(_notification) && currentClient && localStorage.defaultModule !== 'oem') {

	// 		// ENGAGE REQUEST NOTIFICATION
	// 		let _record = _notification.filter(m => m.type === 'engageRequest' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//	console.log('engage-Request-notificationUpdatedOn', '=====>', _record)
	// 		if (!_.isEmpty(_record) && (_.isEmpty(localStorage.notificationUpdatedOn) || (!_.isEmpty(localStorage.notificationUpdatedOn) && _record.addedDate.seconds > Number(localStorage.notificationUpdatedOn)))) {

	// 			if (_record.documentID && _record.recordID) {
	// 				localStorage.setItem('notificationUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_record.recordID);
	// 				setNotificationId(_record.documentID);
	// 				setShowEngageModel(true);
	// 				setShowEngageModelClose(true);

	// 				// window.firebase.firestore()
	// 				// 	.doc(`/notifications/${_record.documentID}`)
	// 				// 	.set({ isRead: true }, { merge: true });
	// 			}
	// 		}
	// 		// CALL LOGS NOTIFICATION
	// 		let _recordLog = _notification.filter(m => m.type === 'callLogs' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//console.log('callLogs-notificationLogUpdatedOn', '=====>', _recordLog)
	// 		if (!_.isEmpty(_recordLog) && (_.isEmpty(localStorage.notificationLogUpdatedOn) || (!_.isEmpty(localStorage.notificationLogUpdatedOn) && _recordLog.addedDate.seconds > Number(localStorage.notificationLogUpdatedOn)))) {

	// 			if (_recordLog.documentID && _recordLog.recordID) {
	// 				localStorage.setItem('notificationLogUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_recordLog.recordID);
	// 				setNotificationId(_recordLog.documentID);
	// 				setShowCallLogModel(true);
	// 			}
	// 		}
	// 		// LEAD ASSIGN NOTIFICATION
	// 		let _recordEnquiry = _notification.filter(m => m.type === 'enquiry' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//console.log('notificationAssignUpdatedOn', '=====>', _recordEnquiry)
	// 		if (!_.isEmpty(_recordEnquiry) && (_.isEmpty(localStorage.notificationAssignUpdatedOn) || (!_.isEmpty(localStorage.notificationAssignUpdatedOn) && _recordEnquiry.addedDate.seconds > Number(localStorage.notificationAssignUpdatedOn)))) {

	// 			if (_recordEnquiry.documentID && _recordEnquiry.recordID) {
	// 				localStorage.setItem('notificationAssignUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_recordEnquiry.recordID);
	// 				setNotificationId(_recordEnquiry.documentID);
	// 				setShowAssignLeadModel(true);

	// 				// window.firebase.firestore()
	// 				// 	.doc(`/notifications/${_recordEnquiry.documentID}`)
	// 				// 	.set({ isRead: true }, { merge: true });

	// 			}
	// 		}

	// 		// INBOUND LEAD NOTIFICATION
	// 		let _recordInbound = _notification.filter(m => m.type === 'inbound' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//console.log('notificationInboundUpdatedOn_recordInbound', '=====>', _recordInbound)
	// 		if (!_.isEmpty(_recordInbound) && (_.isEmpty(localStorage.notificationInboundUpdatedOn) || (!_.isEmpty(localStorage.notificationInboundUpdatedOn) && _recordInbound.addedDate.seconds > Number(localStorage.notificationInboundUpdatedOn)))) {

	// 			if (_recordInbound.documentID && _recordInbound.recordID) {
	// 				localStorage.setItem('notificationInboundUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_recordInbound.recordID);
	// 				setNotificationId(_recordInbound.documentID);
	// 				setShowInboundLeadModel(true);
	// 			}
	// 		}

	// 		// LOAN VEHICLE NOTIFICATION
	// 		let _recordFleet = _notification.filter(m => m.type === 'fleet' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//console.log('notificationFleetUpdatedOn_recordFleet', '=====>', _recordFleet)
	// 		if (!_.isEmpty(_recordFleet) && (_.isEmpty(localStorage.notificationFleetUpdatedOn) || (!_.isEmpty(localStorage.notificationFleetUpdatedOn) && _recordFleet.addedDate.seconds > Number(localStorage.notificationFleetUpdatedOn)))) {

	// 			if (_recordFleet.documentID && _recordFleet.recordID) {
	// 				localStorage.setItem('notificationFleetUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_recordFleet.recordID);
	// 				setNotificationId(_recordFleet.documentID);
	// 				setShowFleetModel(true);
	// 			}
	// 		}

	// 		// LOAN VEHICLE NOTIFICATION
	// 		let _recordServiceRequest = _notification.filter(m => m.type === 'serviceRequest' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
	// 		//console.log('notificationJobRequestUpdatedOn_recordFleet', '=====>', _recordFleet)
	// 		if (!_.isEmpty(_recordFleet) && (_.isEmpty(localStorage.notificationJobRequestUpdatedOn) || (!_.isEmpty(localStorage.notificationJobRequestUpdatedOn) && _recordServiceRequest.addedDate.seconds > Number(localStorage.notificationJobRequestUpdatedOn)))) {

	// 			if (_recordServiceRequest.documentID && _recordServiceRequest.recordID) {
	// 				localStorage.setItem('notificationJobRequestUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
	// 				setRecordId(_recordServiceRequest.recordID);
	// 				setNotificationId(_recordServiceRequest.documentID);
	// 				setShowServiceRequestModel(true);
	// 			}
	// 		}
	// 	}

	// 	setNotifications(_notification);
	// 	setNotificationCount(_notification.filter(e => e.isRead === false).length);
	// }

	const onSMSCollectionUpdate = (querySnapshot) => {
		let _notification = []

		querySnapshot.forEach(function (doc) {
			_notification.push({
				...doc.data(),
				documentID: doc.id
			})
		});

		_notification = _notification.sort(function (x, y) { return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1); });

		if (!_.isEmpty(_notification) && currentClient) {

			let _recordSMS = _notification.filter(m => m.type === 'sms' && m.clientID === currentClient && m.isRead === false && m.isDeleted === false)[0];
			//console.log('notificationSMSUpdatedOn', '=====>', _recordSMS)
			if (!_.isEmpty(_recordSMS) && (_.isEmpty(localStorage.notificationSMSUpdatedOn) || (!_.isEmpty(localStorage.notificationSMSUpdatedOn) && _recordSMS.addedDate.seconds > Number(localStorage.notificationSMSUpdatedOn)))) {

				if (!_.isEmpty(_recordSMS) && _recordSMS.documentID && _recordSMS.recordID) {
					localStorage.setItem('notificationSMSUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
					//setRecordId(_recordSMS.recordID);
					//setNotificationId(_recordSMS.documentID);
					setShowSMSModel({ show: true, notifyID: _recordSMS.documentID, recordID: _recordSMS.recordID });
					setObjNotification(_recordSMS);
				}
			}
		}

		setSMSNotifications(_notification);
		setSMSNotificationCount(_notification.filter(e => e.isRead === false).length);
	}


	//end

	//check latest deployment
	useEffect(() => {
		if (_.isEmpty(currentClient))
			return

		const unSubDeploymentsRefDoc = window.firebase.firestore().doc(`deployments/webDeployments`)
			.onSnapshot((docSnapshot) => {
				if (docSnapshot.exists) {
					const deploymentdoc = docSnapshot.data();

					if (!_.isEmpty(localStorage.deploymentUpdatedOn) && deploymentdoc.updatedOn && deploymentdoc.updatedOn.seconds > Number(localStorage.deploymentUpdatedOn)) {
						setShowForceAlert(true)
					}
				}
			});
		return () => {
			unSubDeploymentsRefDoc && unSubDeploymentsRefDoc()
		}
	}, [currentClient])

	//end

	//mail unread count
	// deprecated for nylas_v3 by SaMeeR
	// useEffect(() => {
	// 	if (_.isEmpty(dealersettings) || _.isEmpty(dealersettings.nylas))
	// 		return

	// 	window.unSubnylasAccountInfo = window.firebase.firestore().doc(`nylas-accounts/${dealersettings.nylas.account_id}`)
	// 		.onSnapshot(snap => {
	// 			if (snap.exists) {
	// 				setNylasAccountData(snap.data())
	// 				setUnreadCount(snap.data().unreadCount)
	// 			}
	// 			//getUnreadCount(snap.data().token, dealersettings)
	// 		});
	// }, [dealersettings])

	useEffect(() => {
		if (_.isEmpty(dealersettings) || _.isEmpty(dealersettings.nylas_v3))
			return

		window.unSubnylasAccountInfo = window.firebase.firestore().doc(`nylasv3-accounts/${dealersettings.nylas_v3.grantId}`)
			.onSnapshot(snap => {
				if (snap.exists) {
					setNylasAccountData(snap.data())
					setUnreadCount(snap.data().unreadCount)
				}
				//getUnreadCount(snap.data().token, dealersettings)
			});
	}, [dealersettings])

	useEffect(() => {
		const checkOutlookRefreshTokenExpiry = async () => {
			if (_.isEmpty(dealersettings) || _.isEmpty(dealersettings.outlookCalendar)) {
				return
			}
			// console.log('checkOutlookRefreshTokenExpiry start', dealersettings.outlookCalendar)

			const alertInfoObj = {
				title: CommonHelper.showLocale({ dealersettings }, 'Your outlook calendar session has been expired'),
				text: CommonHelper.showLocale({ dealersettings }, 'Please authenticate your outlook calendar session to continue'),
				icon: 'error',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate Now'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
			}

			if (
				dealersettings.outlookCalendar.refreshTokenExpired === true
				&& !dealersettings.outlookCalendar.expiryReminded
			) {
				// console.log('one')
				const result = await Swal.fire(alertInfoObj)

				if (!result.value) {
					onSelect(false)
				} else {
					onSelect(true)
				}
				// Show Swal(onSelectLater, onSelect)
			} else if (
				dealersettings.outlookCalendar.refreshTokenExpired === false
				&& !_.isEmpty(dealersettings.outlookCalendar.loginDate)
				&& dealersettings.outlookCalendar.loginDate.toDate
				&& !dealersettings.outlookCalendar.expiryReminded
			) {
				const date1 = dealersettings.outlookCalendar.loginDate.toDate()
				const date2 = new Date();

				const Difference_In_Time = date2.getTime() - date1.getTime();

				const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

				// console.log('Difference_In_Days', Difference_In_Days)
				// @memo: change it to 89
				if (Difference_In_Days >= 89) {
					//console.log('two')
					const result = await Swal.fire(alertInfoObj)

					if (!result.value) {
						onSelect(false)
					} else {
						onSelect(true)
					}
				}
			}

			// console.log('checkOutlookRefreshTokenExpiry end')
		}

		const onSelect = async (goToSettings) => {
			// console.log('onSelect start')

			updateDealerSettings('outlookCalendar', {
				...dealersettings.outlookCalendar,
				expiryReminded: true,
				refreshTokenExpired: true
			})

			// UpdateFirebase
			const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
			await userRef.set({
				outlookCalendar: {
					...dealersettings.outlookCalendar,
					expiryReminded: true,
					refreshTokenExpired: true
				}
			}, { merge: true })

			if (goToSettings) {
				// history.push("/settings/profile?state=outlookcalendar");

				const authURL = `${msalConfig.authority}oauth2/v2.0/authorize?
				client_id=${msalConfig.clientId}
				&response_type=code
				&redirect_uri=${encodeURIComponent(`${window.location.origin}/settings/profile`)}
				&response_mode=query
				&scope=${msalConfig.scopes}
				&state=outlookcalendar`

				window.location.replace(authURL);
			}
		}
		checkOutlookRefreshTokenExpiry()


	}, [dealersettings])

	// const getUnreadCount = (token, settings, last_message_after, last_message_before) => {
	// 	try {
	// 		const nylasMessages = window.firebase
	// 			.functions()
	// 			.httpsCallable('nylas-threads');
	// 		const reqObj = {
	// 			token,
	// 			options: {
	// 				view: 'count',
	// 				in: 'inbox',
	// 				unread: true,
	// 				last_message_before: (settings
	// 					&& settings.nylasAccountData
	// 					&& settings.nylasAccountData.last_message_before) ?
	// 					settings.nylasAccountData.last_message_before :
	// 					last_message_before ? last_message_before : 0,
	// 				last_message_after: (settings
	// 					&& settings.nylasAccountData
	// 					&& settings.nylasAccountData.last_message_after) ?
	// 					settings.nylasAccountData.last_message_after :
	// 					last_message_after ? last_message_after : 0
	// 			},
	// 		}
	// 		nylasMessages(reqObj).then(result => {
	// 			if (result.data)
	// 				setUnreadCount(result.data);
	// 		}).catch(error => {
	// 			console.log(error);
	// 		});

	// 	} catch (error) {
	// 		console.log(error);
	// 	}

	// }

	//get all email templates
	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}

		let _collection = 'templates';
		if (clientModule === 'tradeinPro' || clientModule === 'service') {
			_collection = `${clientModule}templates`
		}

		let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/${_collection}`).
			where('visibility', '==', 'private')
			.where('isDeleted', '==', false)
		privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, dealersettings, null, true);

		window.alltemplates = combineLatest(
			collectionData(privateTemplatesRef),
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/${_collection}`)
				.where('visibility', '==', 'shared')
				.where('isDeleted', '==', false))
		).pipe(
			map((templates) => {
				var [dealer, shared] = templates;
				return [
					[...dealer],
					[...shared]
				];
			}))
			.subscribe(allDocs => {
				let list = [];
				allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
				let listVM = [];
				list.forEach(rec => {
					if (!listVM.some(e => e.documentID === rec.documentID))
						listVM.push(rec)
				})
				setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'))
				setSmsTemplates(listVM.filter(item => item.type === 'smsTemplate'))
				setTVTemplates(listVM.filter(item => item.type === 'tvTemplate'))
			});
		// return () => {
		// 	alltemplates && alltemplates.unsubscribe()
		// }
	}, [dealersettings, localStorage.defaultModule]);

	useEffect(() => {
		if (_.isEmpty(groupClients))
			return;

		console.log('group settings start', new Date());
		getSettings();
		async function getSettings() {
			let results = dealersettings;
			const _settingSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.oemID}`).get();
			let settingsDoc = _settingSnapshot.data()
			clientLookups.forEach(rec => {
				if (_.isEmpty(settingsDoc[rec.value]))
					settingsDoc = {
						...settingsDoc,
						[rec.value]: []
					}
			})
			if (_.isEmpty(settingsDoc['mandatoryFields'])) {
				settingsDoc = {
					...settingsDoc,
					['mandatoryFields']: {}
				}
			}
			[...clientLookups, 'pipelines'].forEach(lookup => {
				if (_.isArray(settingsDoc[lookup])) {
					settingsDoc = {
						...settingsDoc,
						[lookup]: settingsDoc[lookup].map(r => { return { ...r, level: 'oem' } })
					}
				}

			})
			for (let i = 0; i < groupClients.length; i++) {
				const cid = groupClients[i]
				var otherClient = await window.firebase.firestore().doc(`clients/${cid}`).get();
				if (otherClient.exists) {
					var _cSettings = await getClientSettings({ ...otherClient.data(), id: otherClient.id }, settingsDoc, 'individual');
					var _sSettings = {};
					var _tSettings = {};
					var _eSettings = {};
					var _fSettings = {};
					if (otherClient.data()?.moduleSettings?.service
						&& otherClient.data()?.moduleSettings.service.enabled === true
						&& otherClient.data()?.moduleSettings.service.active === true) {
						_sSettings = await getServiceSettings({ ...otherClient.data(), id: otherClient.id }, 'individual', 'services');
					}
					if (otherClient.data()?.moduleSettings?.tradeinPro
						&& otherClient.data()?.moduleSettings.tradeinPro.enabled === true
						&& otherClient.data()?.moduleSettings.tradeinPro.active === true) {
						_tSettings = await getServiceSettings({ ...otherClient.data(), id: otherClient.id }, 'individual', 'tradeinPro');
					}
					if (otherClient.data()?.moduleSettings?.eventsPro
						&& otherClient.data()?.moduleSettings.eventsPro.enabled === true
						&& otherClient.data()?.moduleSettings.eventsPro.active === true) {
						_eSettings = await getServiceSettings({ ...otherClient.data(), id: otherClient.id }, 'individual', 'eventsPro');
					}
					if (otherClient.data()?.moduleSettings?.fleetPro
						&& otherClient.data()?.moduleSettings.fleetPro.enabled === true
						&& otherClient.data()?.moduleSettings.fleetPro.active === true) {
						_fSettings = await getServiceSettings({ ...otherClient.data(), id: otherClient.id }, 'individual', 'fleetPro');
					}
					results.group.clients[otherClient.id] = {
						...otherClient.data(),
						enableSharedContact: _.find(results.groups, { documentID: otherClient.data().group }) ? _.find(results.groups, { documentID: otherClient.data().group }).enableSharedContact : false,
						enableLeadTransfer: _.find(results.groups, { documentID: otherClient.data().group }) ? _.find(results.groups, { documentID: otherClient.data().group }).enableLeadTransfer : false,
						name: otherClient.data().name,
						settings: {
							..._cSettings,
							'allPipelines': _cSettings.pipelines.filter(a => a.active === true),
							'pipelines': (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.pipeline)) ?
								_.filter(_cSettings.pipelines, (v) => _.indexOf(results.rolePermissions.pipeline, v.value) >= 0)
								: _cSettings.pipelines
						},
						services: _sSettings,
						tradeinPro: _tSettings,
						eventsPro: _eSettings,
						fleetPro: _fSettings
					}
				}
			}
			if (!_.isEmpty(dealersettings?.nylas_v3)) results.nylas_v3 = dealersettings.nylas_v3
			if (!_.isEmpty(dealersettings?.nylasAccountData)) results.nylasAccountData = dealersettings.nylasAccountData
			setDealersettings(results);
			console.log('group settings end', new Date());
		}

	}, [groupClients])

	useEffect(() => {
		if (_.isEmpty(dealersettings))
			return;

		console.log('other settings start', new Date());
		getSettings();
		async function getSettings() {
			let results = dealersettings;
			let clientid = results?.client?.id || 'undefined';
			let refTeamsSnapshot = window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/teams`);
			if (results.level === 'oem' && results.oemDashboard === true)
				refTeamsSnapshot = refTeamsSnapshot.where('oemID', '==', results.oemID);
			else if (results?.level?.includes('region') && results.oemDashboard === true)
				refTeamsSnapshot = refTeamsSnapshot.where('regionID', '==', results.regionID);
			else if (results.level === 'group' && results.oemDashboard === true)
				refTeamsSnapshot = refTeamsSnapshot.where('groupID', '==', results.groupID);
			else
				refTeamsSnapshot = refTeamsSnapshot.where('clientID', '==', clientid);

			const otherSettingsPromise = [];
			otherSettingsPromise.push(window.firebase.firestore().doc(`clients/${clientid}/salesPerformance/${clientid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/users/${localStorage.uid}/googleCalendar/${localStorage.uid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/users/${localStorage.uid}/outlookCalendar/${localStorage.uid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/clients/${clientid}/securitySettings/${clientid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().collection(`/clients/${clientid}/localizeData`).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/clients/${clientid}/gdprSettings/${clientid}`).get())
			otherSettingsPromise.push(window.firebase.firestore().collection(`tvDevices`).where('isDeleted', '==', false).where('visibility', '==', 'private').where('clientID', '==', clientid).get())
			otherSettingsPromise.push(window.firebase.firestore().collection(`tvDevices`).where('isDeleted', '==', false).where('visibility', '==', 'shared').where('oemID', '==', results.oemID).get())
			otherSettingsPromise.push(window.firebase.firestore().doc(`/clients/${clientid}/roster/${clientid}`).get())
			otherSettingsPromise.push(refTeamsSnapshot.get())

			const otherSettingsSnap = await Promise.all(otherSettingsPromise);
			console.log('other settings query end', new Date());
			if (otherSettingsSnap[0].exists) {
				results.client.salesPerformance = otherSettingsSnap[0].data().columns ? otherSettingsSnap[0].data().columns.map(rec => { return rec.value }) : [];
			}
			if (otherSettingsSnap[1].exists) {
				const columnSettings = otherSettingsSnap[1].data();
				results.unallocatedFields = columnSettings.unallocatedFields ? columnSettings.unallocatedFields : [];
				results.allocatedFields = columnSettings.allocatedFields ? columnSettings.allocatedFields : [];
				results.tradeinProFields = columnSettings.tradeinProFields ? columnSettings.tradeinProFields : [];
				results.eventsProFields = columnSettings.eventsProFields ? columnSettings.eventsProFields : [];
				results.attendeeFields = columnSettings.attendeeFields ? columnSettings.attendeeFields : [];
				results.unallocatedAttnFields = columnSettings.unallocatedAttnFields ? columnSettings.unallocatedAttnFields : [];
				results.allocatedAttnFields = columnSettings.allocatedAttnFields ? columnSettings.allocatedAttnFields : [];
				results.fleetProFields = columnSettings.fleetProFields ? columnSettings.fleetProFields : [];
				results.fleetAssignFields = columnSettings.fleetAssignFields ? columnSettings.fleetAssignFields : [];
			}
			else {
				results.unallocatedFields = [];
				results.allocatedFields = [];
				results.tradeinProFields = [];
				results.eventsProFields = [];
				results.attendeeFields = [];
				results.unallocatedAttnFields = [];
				results.allocatedAttnFields = [];
				results.fleetProFields = [];
				results.fleetAssignFields = [];
			}
			if (otherSettingsSnap[2].exists) {
				results.googleCalendar = otherSettingsSnap[2].data();
			}
			if (otherSettingsSnap[3].exists) {
				results.outlookCalendar = otherSettingsSnap[3].data();
			}
			//fetch securitysettings
			if (otherSettingsSnap[4].exists) {
				results.client.securitysettings = otherSettingsSnap[4].data();
			}
			//fetch other language terms
			if (otherSettingsSnap[5]?.docs?.length > 0) {
				otherSettingsSnap[5].docs.forEach(rec => {
					results.client[rec.id] = rec.data();
				})
			}
			if (otherSettingsSnap[6].exists) {
				results.client['gdpr'] = otherSettingsSnap[6].data();
			}

			if (!Boolean(results.wholesalerDashboard)) {
				if (results.client
					&& results.client.moduleSettings
					&& (!_.isEmpty(results.client.moduleSettings.fusionevents)
						&& results.client.moduleSettings.fusionevents.enabled === true
						&& results.client.moduleSettings.fusionevents.active === true)) {
					let eventList = [];
					try {
						const requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({ Name: results.client.id }),
						};

						const response = await fetch(
							`${results.eventappURL}api/EventApp/Action/DealerClosedEvents`,
							requestOptions,
						);

						const respjson = await response.json();
						if (respjson.success && respjson.data) {
							eventList = respjson.data
						}

					} catch (err) { }
					results.client.integrations.push({
						inbound: true,
						active: true,
						enabled: true,
						name: "Fusion Events",
						type: "fusionevents",
						value: "fusionevents",
						subList: eventList
					})
				}

				if (results.client
					&& results.client.moduleSettings
					&& (!_.isEmpty(results.client.moduleSettings.eventsPro)
						&& results.client.moduleSettings.eventsPro.enabled === true
						&& results.client.moduleSettings.eventsPro.active === true)) {
					results.client.integrations.push({
						inbound: true,
						active: true,
						enabled: true,
						name: "Events Pro",
						type: "eventsPro",
						value: "eventsPro"
					})
				}
			}
			var _devices = []
			if (otherSettingsSnap[7]?.docs?.length > 0) {
				otherSettingsSnap[7].docs.forEach(rec => {
					if (!_devices.some(e => e.id === rec.id))
						_devices.push({
							...rec.data(),
							id: rec.id,
						})
				})
			}
			if (otherSettingsSnap[8]?.docs?.length > 0) {
				otherSettingsSnap[8].docs.forEach(rec => {
					if (!_devices.some(e => e.id === rec.id))
						_devices.push({
							...rec.data(),
							id: rec.id,
						})
				})
			}
			if (_devices.length > 0) {
				results.tvDevices = _.orderBy(_devices, ['addedDate.seconds'], ['asc']).map((rec, index) => {
					return {
						...rec,
						resourceId: rec.documentID,
						resourceTitle: rec.name,
						wrapper: calendarEventColor[index] ? calendarEventColor[index].value : null
					}
				});
			}
			else {
				results.tvDevices = []
			}
			//roster settings
			if (otherSettingsSnap[9].exists) {
				results.rosterSettings = otherSettingsSnap[9].data();
			}

			var _clientteams = []
			if (otherSettingsSnap[10]?.docs?.length > 0) {
				otherSettingsSnap[10].docs.forEach(rec => {
					if (rec.data().user_ids && rec.data().user_ids.length > 0) {
						_clientteams.push({
							...rec.data(),
							id: rec.id,
						})
					}
				})
				results.client.teams = _clientteams;
			}
			else {
				results.client.teams = []
			}

			setDealersettings(results);
			setRefreshAircall(!refreshAircall);
			try {
				let refSettingsSnapshot = `clients/${clientid}/currentSettings/${clientid}`;
				if (results.level === 'oem' && results.oemDashboard === true)
					refSettingsSnapshot = `clientSettings/${results.oemID}/currentSettings/${results.oemID}`;
				else if (results?.level?.includes('region') && results.oemDashboard === true)
					refSettingsSnapshot = `clientSettings/${results.oemID}/regions/${results.regionID}/currentSettings/${results.regionID}`;
				else if (results.level === 'group' && results.oemDashboard === true)
					refSettingsSnapshot = `clientSettings/${results.oemID}/groups/${results.groupID}/currentSettings/${results.groupID}`;

				let refeventsSettingsSnapshot = `clients/${clientid}/currentSettings/${clientid}/eventsPro/${clientid}`;
				if (results.level === 'oem' && results.oemDashboard === true)
					refeventsSettingsSnapshot = `clientSettings/${results.oemID}/currentSettings/${results.oemID}/eventsPro/${results.oemID}`;
				else if (results?.level?.includes('region') && results.oemDashboard === true)
					refeventsSettingsSnapshot = `clientSettings/${results.oemID}/regions/${results.regionID}/currentSettings/${results.regionID}/eventsPro/${results.regionID}`;
				else if (results.level === 'group' && results.oemDashboard === true)
					refeventsSettingsSnapshot = `clientSettings/${results.oemID}/groups/${results.groupID}/currentSettings/${results.groupID}/eventsPro/${results.groupID}`;

				let reffleetSettingsSnapshot = `clients/${clientid}/currentSettings/${clientid}/fleetPro/${clientid}`;
				if (results.level === 'oem' && results.oemDashboard === true)
					reffleetSettingsSnapshot = `clientSettings/${results.oemID}/currentSettings/${results.oemID}/fleetPro/${results.oemID}`;
				else if (results?.level?.includes('region') && results.oemDashboard === true)
					reffleetSettingsSnapshot = `clientSettings/${results.oemID}/regions/${results.regionID}/currentSettings/${results.regionID}/fleetPro/${results.regionID}`;
				else if (results.level === 'group' && results.oemDashboard === true)
					reffleetSettingsSnapshot = `clientSettings/${results.oemID}/groups/${results.groupID}/currentSettings/${results.groupID}/fleetPro/${results.groupID}`;

				const objSettigs = {
					settings: {
						...results.client.settings,
						'allPipelines': results.allPipelines,
						'pipelines': results.allPipelines
					},
					services: {
						...results.client.services,
						'allPipelines': results?.client?.services?.allPipelines || [],
						'pipelines': results?.client?.services?.allPipelines || [],
					},
					tradeinPro: {
						...results.client.tradeinPro,
						'allPipelines': results?.client?.tradeinPro?.allPipelines || [],
						'pipelines': results?.client?.tradeinPro?.allPipelines || [],
					},
					..._.pick(results.client, ['name', 'weekStart', 'workingHours', 'logoURL', 'address', 'phone', 'phoneCode', 'website', 'timezone', 'category', 'projectId', 'integrationKey']),
					brands: results.client.brands,
					integrations: results.client.integrations,
					oemID: results.oemID,
					allintegrations: null,
					moduleSettings: results.client.moduleSettings ? results.client.moduleSettings : null,
					group: _.pick(results.group, ['name', 'client_ids', 'documentID', 'enableLeadTransfer', 'enableSharedContact', 'enableSharedStock']),
					projectId: results?.client?.projectId ? results.client.projectId : null,
					updatedOn: window.firebase.firestore.Timestamp.now(),
					isPorsche: results.isPorsche || null,
					isMclaren: results.isMclaren || null,
					mvwReport: results.mvwReport || null
				}
				window.firebase
					.firestore()
					.doc(refSettingsSnapshot)
					.set(CommonHelper.removeEmptyOrNull(objSettigs), { merge: true });
				if (!_.isEmpty(results.client.eventsPro)) {
					window.firebase
						.firestore()
						.doc(refeventsSettingsSnapshot)
						.set(CommonHelper.removeEmptyOrNull({
							...results.client.eventsPro,
							brands: _.map(results.client.brands, _.partialRight(_.pick, ['name', 'value', 'models'])),
						}), { merge: true });
				}
				if (!_.isEmpty(results.client.fleetPro)) {
					window.firebase
						.firestore()
						.doc(reffleetSettingsSnapshot)
						.set(CommonHelper.removeEmptyOrNull({
							...results.client.fleetPro,
							brands: _.map(results.client.brands, _.partialRight(_.pick, ['name', 'value', 'models'])),
						}), { merge: true });
				}
				//}
			} catch (err) { }
			console.log('other settings end', new Date());
		}

	}, [otherSettigs])

	async function getdealerSettings(forceUpdate) {
		toast.closeAll();
		localStorage.setItem('settingsUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
		localStorage.setItem('deploymentUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
		setGroupClients([]);
		setLoadingText('Fetching user...');
		let _modules = [];
		let results = null;
		console.log('start', new Date());
		//const t1 = performance.now();
		const userSnapshot = await window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}`)
			.get();
		// console.log('user start', new Date());
		// const t2 = performance.now();
		// console.log('diff 1 - ', (t2 - t1) / 1000)
		const userDoc = (userSnapshot.data().isWholesaler === true && _.isEmpty(userSnapshot.data().settingsID)) ? {
			...userSnapshot.data(),
			id: userSnapshot.id,
			oemID: `wholesalersettingsID`,
			settingsID: `wholesalersettingsID`,
			groupID: `wholesalergroupID`,
			regionID: `wholesalerregionID`,
			arrayClientID: [],
			wholesalerClientIDs: userSnapshot.data().wholesalerClientID,
			defaultClientID: null,
			wholesalerDashboard: true
		} : {
			...userSnapshot.data(),
			wholesalerClientIDs: userSnapshot.data().wholesalerClientID,
		};
		results = {
			name: userDoc.name,
			id: localStorage.uid,
			email: userDoc.email,
			roleName: userDoc.role,
			roleID: userDoc.roleID,
			oemID: userDoc.settingsID,
			groupID: userDoc.groupID,
			regionID: userDoc.regionID,
			level: userDoc.level,
			lastLoginTime: userDoc.lastLoginTime,
			language: userDoc.language ? userDoc.language : 'en',
			clients: [],
			multiOEM: [],
			contactFields: userDoc.contactFields ? userDoc.contactFields : [],
			companyFields: userDoc.companyFields ? userDoc.companyFields : [],
			stockFields: userDoc.stockFields ? userDoc.stockFields : [],
			oemStockFields: userDoc.oemStockFields ? userDoc.oemStockFields : [],
			fleetFields: userDoc.fleetFields ? userDoc.fleetFields : [],
			stockFleetFields: userDoc.stockFleetFields
				? userDoc.stockFleetFields
				: [],
			testDriveHistoryFields: userDoc.testDriveHistoryFields
				? userDoc.testDriveHistoryFields
				: [],
			loanHistoryFields: userDoc.loanHistoryFields
				? userDoc.loanHistoryFields
				: [],
			cafeHistoryFields: userDoc.cafeHistoryFields
				? userDoc.cafeHistoryFields
				: [],
			activityFields: userDoc.activityFields?.length ? userDoc.activityFields : allactivityFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value),
			enquiryFields: userDoc.enquiryFields?.length ? userDoc.enquiryFields : allenquiryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value),
			enquiryFilters: userDoc.enquiryFilters ? userDoc.enquiryFilters : [],
			tradeInFields: userDoc.tradeInFields?.length ? userDoc.tradeInFields : allTradeinFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value),
			callLogsFields: userDoc.callLogsFields?.length ? userDoc.callLogsFields : allCallLogsFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value),
			serviceFields: userDoc.serviceFields?.length ? userDoc.serviceFields : allserviceFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value),
			serviceFilters: userDoc.serviceFilters ? userDoc.serviceFilters : [],
			//tradeinProFields: userDoc.tradeinProFields ? userDoc.tradeinProFields : [],
			// nylasAccessToken: userDoc.nylasAccessToken,
			// nylasAccountInfo: userDoc.nylasAccountInfo,
			//nylas: !_.isEmpty(userDoc.nylas) ? userDoc.nylas : null, // deprecated for nylas_v3 by SaMeeR
			nylas_v3: !_.isEmpty(userDoc.nylas_v3) ? userDoc.nylas_v3 : null,
			// outlookCalendar: !_.isEmpty(userDoc.outlookCalendar) ? userDoc.outlookCalendar : null,
			// googleCalendar: !_.isEmpty(userDoc.googleCalendar) ? userDoc.googleCalendar : null,
			nylasAccountData: null,
			emailSignature: userDoc.emailSignature,
			oemDashboard: false,
			enableServicePackages: false,
			ownDashboard: userDoc.ownDashboard ? userDoc.ownDashboard : false,
			superAdmin: userDoc.superAdmin,
			phone: userDoc.phone,
			phoneCode: userDoc.phoneCode,
			designation: userDoc.designation,
			isWholesaler: userDoc.isWholesaler,
			arrayOEMID: userDoc.arrayOEMID,
			arrayOEM: userDoc.arrayOEM,
			arrayClientID: userDoc.arrayClientID,
			wholesalerClientIDs: userDoc.wholesalerClientIDs,
			wholesalerDashboard: userDoc.wholesalerDashboard,
			arrayRegionID: userDoc.arrayRegionID,
			arraySubregionID: userDoc.arraySubregionID,
			arrayGroupID: userDoc.arrayGroupID,
			workingServiceID: userDoc.workingServiceID ? userDoc.workingServiceID : null,
			enableTOTP: userDoc.enableTOTP || false,
			enableMFA: userDoc.enableMFA || false
		};
		// if (_.isEmpty(results.googleCalendar)) {
		// 	const userSnapshot = await window.firebase
		// 		.firestore()
		// 		.doc(`users/${localStorage.uid}/googleCalendar/${localStorage.uid}`)
		// 		.get();
		// 	if (userSnapshot.exists) {
		// 		results.googleCalendar = userSnapshot.data();
		// 	}
		// }
		// if (_.isEmpty(results.outlookCalendar)) {
		// 	const userSnapshot = await window.firebase
		// 		.firestore()
		// 		.doc(`users/${localStorage.uid}/outlookCalendar/${localStorage.uid}`)
		// 		.get();
		// 	if (userSnapshot.exists) {
		// 		results.outlookCalendar = userSnapshot.data();
		// 	}
		// }
		if (_.isEmpty(userDoc.arrayRegionID) && !_.isEmpty(userDoc.regionID)) {
			results.arrayRegionID = [userDoc.regionID]
		}
		if (_.isEmpty(userDoc.arrayGroupID) && !_.isEmpty(userDoc.groupID)) {
			results.arrayGroupID = [userDoc.groupID]
		}
		if (_.isEmpty(results.name) && results.superAdmin === true) {
			results.name = 'Super Admin';
		}

		//MEILISEARCH CONFIG
		// console.log('meilisearch start', new Date());
		// const t3 = performance.now();
		// console.log('diff 1 - ', (t3 - t2) / 1000)
		// const meilisearchSnapshot = await window.firebase
		// 	.firestore()
		// 	.doc(`meilisearch/settings`)
		// 	.get();
		// if (meilisearchSnapshot.exists) {
		// 	results.meilisearch = {
		// 		enabled: meilisearchSnapshot.data().enable,
		// 		config: new MeiliSearch({
		// 			host: meilisearchSnapshot.data().server,
		// 			apiKey: meilisearchSnapshot.data().key
		// 		})
		// 	}
		// }
		//END
		//const t4 = performance.now();

		// deprecated for nylas_v3 by SaMeeR
		//  if (!_.isEmpty(userDoc.nylas)) {
		//  	// console.log('nylas start', new Date());
		//  	// console.log('diff 1 - ', (t4 - t3) / 1000)
		//  	const nylasAccSnapshot = await window.firebase
		//  		.firestore()
		//  		.doc(`nylas-accounts/${userDoc.nylas.account_id}`)
		//  		.get();
		//  	if (nylasAccSnapshot.exists) {
		//  		results.nylasAccountData = nylasAccSnapshot.data()
		//  		results.nylasAccessToken = nylasAccSnapshot.data().token
		//  	}
		//  }

		if (!_.isEmpty(userDoc.nylas_v3)) {
			// console.log('nylas start', new Date());
			// console.log('diff 1 - ', (t4 - t3) / 1000)
			const nylasAccSnapshot = await window.firebase
				.firestore()
				.doc(`nylasv3-accounts/${userDoc.nylas_v3.grantId}`)
				.get();
			if (nylasAccSnapshot.exists) {
				results.nylasAccountData = nylasAccSnapshot.data()
				//results.nylasAccessToken = nylasAccSnapshot.data().token
			}
		}

		if (_.isEmpty(userDoc.settingsID) && userDoc.superAdmin === true) {
			return;
		}
		if (userDoc.roleID) {
			let userRoleSnapshot = await window.firebase.firestore().doc(`clientSettings/${userDoc.settingsID}/roles/${userDoc.roleID}`).get()
			if (userRoleSnapshot?.data()?.eventsProPermissions?.accessToLiveOnlyEvents === true && Boolean(userDoc.superAdmin) === false) {
				Swal.fire('Access denied. Please contact administrator.', '', 'info');
				await window.firebase
					.auth()
					.signOut();
				await window.firebase2
					.auth()
					.signOut();
				return history.push(`${localStorage.brandTheme ? `/login?theme=${localStorage.brandTheme}` : '/login'}`);
			}
		}
		localStorage.setItem('isCafeOrderNotifySound', userDoc.isCafeOrderNotifySound ? userDoc.isCafeOrderNotifySound : false)
		localStorage.setItem('isNotifyWithSound', userDoc.isNotifyWithSound ? userDoc.isNotifyWithSound : false)
		localStorage.nylasToken = results.nylasAccessToken;
		setEmailSignature(userDoc.emailSignature)
		var clientid = userDoc.defaultClientID
		if (_.isEmpty(clientid) || (userDoc.arrayClientID && userDoc.arrayClientID.length > 0 && !userDoc.arrayClientID.some(a => a === clientid))) {
			clientid = userDoc.arrayClientID ? userDoc.arrayClientID[0] : ''
		}
		if (_.isEmpty(clientid)) {
			localStorage.removeItem('oemswitchclient')
		}
		setLoadingText(`Fetching client settings...`);
		const clientpromises = []
		if (userDoc.arrayClientID && userDoc.arrayClientID.length > 0) {
			userDoc.arrayClientID.forEach(cid => {
				clientpromises.push(window.firebase.firestore().doc(`clients/${cid}`).get())
			})
		}
		// console.log('clients start', new Date());
		// const t5 = performance.now();
		// console.log('diff 1 - ', (t5 - t4) / 1000)
		const clientSnapshot = clientid ? await window.firebase.firestore().doc(`clients/${clientid}`).get() : { exists: false };
		results.client = clientid ? {
			...clientSnapshot.data(),
			category: _.isEmpty(clientSnapshot.data().category) ? 'Automotive' : clientSnapshot.data().category
		} : {
			settingsID: userDoc.settingsID,
			category: 'Automotive'
		}
		results.client.id = clientid ? clientSnapshot.id : null;
		results.client.documentID = clientid ? clientSnapshot.id : null;
		results.clients = [];
		results.allClients = [];
		//set language
		localStorage.setItem('i18nextLng', results.language);
		localStorage.setItem('isDemo', (results?.client?.status || ''))
		setCurrentClient(clientid)
		i18n.changeLanguage(results.language);
		// console.log('all clients start', new Date());
		const t6 = performance.now();
		// console.log('diff 1 - ', (t6 - t5) / 1000)
		const clientsnapshots = await Promise.all(clientpromises);
		var i = 1;
		clientsnapshots.forEach(snap => {
			var _client = snap.data();
			const _paymentDueDate = moment.unix(_client?.paymentDueDate?.seconds).endOf('day');
			const currentDate = moment();
			const _clientData = {
				name: _client.name,
				location: _client.location,
				id: snap.id,
				documentID: snap.id,
				default: clientid === snap.id ? true : false,
				hiddenModels: !_.isEmpty(_client.hiddenModels) ? _client.hiddenModels : [],
				brandIDs: _client.clientBrandIDs,
				clientBrandIDs: _client.clientBrandIDs,
				region: _client.region,
				subregion: _client.subregion,
				country: _client.country,
				group: _client.group,
				moduleSettings: _client.moduleSettings,
				currency: _client.currency,
				clientSettings: _client.clientSettings,
				workingHours: _client.workingHours,
				settingsID: _client.settingsID,
				logoURL: _client.logoURL,
				projectId: _client.projectId,
				sortOrder: _client.sortOrder ? _client.sortOrder : i,
				suspended: _client.paymentDue ? _paymentDueDate.diff(currentDate) < 1 : false,
				status: _client.status
			};
			results.allClients.push(_clientData);
			if (results.client.settingsID === _client.settingsID || localStorage.defaultModule === 'oem') {
				results.clients.push(_clientData);
			}
			i++;
		});

		//LOAD TRADEIN PRO CLIENTS
		if (userDoc?.tradeinProClientID?.length) {
			results.tradeinProClients = [];
			const tradeinproclientpromises = []
			userDoc.tradeinProClientID.forEach(cid => {
				if (_.find(results.clients, { id: cid })) {
					results.tradeinProClients.push(_.find(results.clients, { id: cid }))
				} else {
					tradeinproclientpromises.push(window.firebase.firestore().doc(`clients/${cid}`).get())
				}
			})
			if (tradeinproclientpromises.length) {
				console.log('tradeinpro client - ', new Date())
				const tradeinproclientsnapshots = await Promise.all(tradeinproclientpromises);
				tradeinproclientsnapshots.forEach(snap => {
					var _client = snap.data();
					const _paymentDueDate = moment.unix(_client?.paymentDueDate?.seconds).endOf('day');
					const currentDate = moment();
					const _clientData = {
						name: _client.name,
						location: _client.location,
						id: snap.id,
						documentID: snap.id,
						default: clientid === snap.id ? true : false,
						hiddenModels: !_.isEmpty(_client.hiddenModels) ? _client.hiddenModels : [],
						brandIDs: _client.clientBrandIDs,
						clientBrandIDs: _client.clientBrandIDs,
						region: _client.region,
						subregion: _client.subregion,
						country: _client.country,
						group: _client.group,
						moduleSettings: _client.moduleSettings,
						currency: _client.currency,
						clientSettings: _client.clientSettings,
						workingHours: _client.workingHours,
						settingsID: _client.settingsID,
						logoURL: _client.logoURL,
						projectId: _client.projectId,
						sortOrder: _client.sortOrder ? _client.sortOrder : i,
						suspended: _client.paymentDue ? _paymentDueDate.diff(currentDate) < 1 : false
					};
					if (results.client.settingsID === _client.settingsID) {
						results.tradeinProClients.push(_clientData);
					}
					i++;
				});
			}
		} else {
			results.tradeinProClients = results.clients;
		}
		//END

		//wholesaler clients
		const t7 = performance.now();
		console.log('all client - ', (t7 - t6) / 1000)
		if (results.wholesalerClientIDs && results.wholesalerClientIDs.length > 0) {
			results.wholesalerclients = [];
			const wsclientpromises = []
			userDoc.wholesalerClientIDs.forEach(cid => {
				wsclientpromises.push(window.firebase.firestore().doc(`clients/${cid}`).get())
			})
			// console.log('all wsclientpromises start', new Date());
			// console.log('diff 1 - ', (t7 - t6) / 1000)
			const wsclientsnapshots = await Promise.all(wsclientpromises);
			wsclientsnapshots.forEach(snap => {
				var _client = snap.data();
				const _clientData = {
					name: _client.name,
					location: _client.location,
					id: snap.id,
					documentID: snap.id,
					default: clientid === snap.id ? true : false,
					hiddenModels: !_.isEmpty(_client.hiddenModels) ? _client.hiddenModels : [],
					brandIDs: _client.clientBrandIDs,
					clientBrandIDs: _client.clientBrandIDs,
					region: _client.region,
					group: _client.group,
					moduleSettings: _client.moduleSettings,
					currency: _client.currency,
					clientSettings: _client.clientSettings,
					workingHours: _client.workingHours,
					settingsID: _client.settingsID,
					projectId: _client.projectId,
					logoURL: _client.logoURL
				};
				results.wholesalerclients.push(_clientData);
			});
			if (_.isEmpty(results.client.id)) {
				results.client = { ...results.client, projectId: results.wholesalerclients[0]?.projectId ? results.wholesalerclients[0]?.projectId : null }
			}
		}

		//fetch multi oem
		//const t8 = performance.now();
		if (userDoc.arrayOEMID && userDoc.arrayOEMID.length > 0 && !Boolean(userDoc.wholesalerDashboard)) {
			const oempromises = []
			userDoc.arrayOEMID.forEach(cid => {
				oempromises.push(window.firebase.firestore().doc(`clientSettings/${cid}`).get())
			})
			// console.log('all oemsnapshots start', new Date());
			// console.log('diff 1 - ', (t8 - t7) / 1000)
			const oemsnapshots = await Promise.all(oempromises);
			oemsnapshots.forEach(snap => {
				results.multiOEM.push({
					...snap.data(),
					id: snap.id
				});
			})
		}
		// if (!_.isEmpty(clientSnapshot.data().timezone)) {
		//     moment.tz.setDefault(clientSnapshot.data().timezone);
		// }
		// else {
		//     moment.tz.setDefault();
		// }

		if (results.client && results.client.requirementType === 'automotiveServices') {
			results.enableServicePackages = true;
		}
		results.afterSales = results.client
			&& results.client.moduleSettings
			&& results.client.moduleSettings.afterSales
			&& (results.client.moduleSettings.afterSales.enabled === true || results.client.moduleSettings.afterSales.active === true)
		results.client.brands = []
		//fetch all regions and groups of oem
		// console.log('all groups start', new Date());
		// const t9 = performance.now();
		// console.log('diff 1 - ', (t9 - t8) / 1000)
		const t11 = performance.now();
		const allBrandsSnapshot = await window.firebase.firestore().collection(`brands`).where('isDeleted', '==', false).get();
		//const brandpromises = []
		let allMakeModels = []
		const regionPromise = [];
		regionPromise.push(window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/groups`).get())
		regionPromise.push(window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/regions`).get())
		regionPromise.push(window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/subregions`).get())
		regionPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}`).get())
		allBrandsSnapshot.docs.forEach(brand => {
			allMakeModels.push(brand.data());
			regionPromise.push(window.firebase.firestore().doc(`brands/${brand.id}/models/${brand.id}`).get())
		})
		const regionSnaps = await Promise.all(regionPromise);
		const t12 = performance.now();
		console.log('models  1 - ', (t12 - t11) / 1000)
		//const groupsSnapshot = await window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/groups`).get();
		if (regionSnaps[0]?.docs?.length > 0) {
			var _groups = []
			let i = 0;
			regionSnaps[0].docs.forEach(rec => {
				i++;
				_groups.push({
					...rec.data(),
					documentID: rec.id,
					id: rec.id,
					fleetTC: rec.data().fleetTC || regionSnaps[3]?.data()?.fleetTC || null,
					client_ids: results.clients ? results.clients.filter(e => e.group === rec.id).map(r => { return r.id }) : [],
					sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
				})
			})
			results.groups = _.orderBy(_groups, ['sortOrder'], ['asc']);
			setGroups(_groups)
		}
		//const regionsSnapshot = await window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/regions`).get();
		if (regionSnaps[1]?.docs?.length > 0) {
			var _regions = []
			let i = 0;
			regionSnaps[1].docs.forEach(rec => {
				i++;
				_regions.push({
					name: rec.data().name,
					documentID: rec.id,
					id: rec.id,
					fleetTC: rec.data().fleetTC || regionSnaps[3]?.data()?.fleetTC || null,
					code: rec.id,
					client_ids: results.clients ? results.clients.filter(e => e.region === rec.id).map(r => { return r.id }) : [],
					sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
				})
			})
			results.regions = _.orderBy(_regions, ['sortOrder'], ['asc']);
			setRegions(_regions)
		}
		//const subregionsSnapshot = await window.firebase.firestore().collection(`clientSettings/${results.client.settingsID}/subregions`).get();
		if (regionSnaps[2]?.docs?.length > 0) {
			var _subregions = []
			let i = 0;
			regionSnaps[2].docs.forEach(rec => {
				i++;
				_subregions.push({
					name: rec.data().name,
					documentID: rec.id,
					id: rec.id,
					fleetTC: rec.data().fleetTC || regionSnaps[3]?.data()?.fleetTC || null,
					code: rec.id,
					client_ids: results.clients ? results.clients.filter(e => e.subregion === rec.id).map(r => { return r.id }) : [],
					sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
				})
			})
			results.subregions = _.orderBy(_subregions, ['sortOrder'], ['asc']);
			setSubregions(_subregions)
		}
		else {
			results.subregions = [];
		}
		//(results.level === 'individual' || !_.isEmpty(localStorage.oemswitchclient))
		//const t10 = performance.now();
		if (!_.isEmpty(results.client.group)) {
			//const groupSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.client.group}`).get()
			if (_.find(_groups, { id: results.client.group })) {
				results.group = _.find(_groups, { id: results.client.group })
				if (results.group && results.group.enableSharedContact === true) {
					// console.log('all clients groups start', new Date());
					// console.log('diff 1 - ', (t10 - t9) / 1000)
					const clientgroupSnapshot = await window.firebase.firestore().collection(`clients`)
						.where('settingsID', '==', results.client.settingsID)
						.where('group', '==', results.client.group)
						.get();
					if (clientgroupSnapshot.docs.length > 0) {
						results.group.client_ids = clientgroupSnapshot.docs.map(rec => { return rec.id });
					}
					results.group.client_ids_shared = results.clients.filter(a => a.group === results.client.group).map(r => r.id);
				}
			}
		}
		if (_.isEmpty(results.client.currency)) {
			results.client.currency = Object.assign({}, { code: "AUD", symbol: "$" });
		}
		// console.log('all brands start', new Date());

		// console.log('diff 1 - ', (t11 - t10) / 1000)

		//console.log('all modelpromises start', new Date());
		for (let i = 4; i < regionSnaps.length - 1; i++) {
			allMakeModels = _.map(allMakeModels, function (make) {
				return (regionSnaps[i].exists && regionSnaps[i].id === make.documentID) ?
					{
						...make,
						'models': regionSnaps[i].data().models
					} : {
						...make
					}
			})
		}
		results.allMakeModels = _.orderBy(allMakeModels.filter(a => a.category === results.client.category), ['name'], ['asc']);
		results.client.brands = clientid ? _.filter(_.orderBy(allMakeModels, ['name'], ['asc']), (v) => _.indexOf(clientSnapshot.data().clientBrandIDs, v.documentID) >= 0).map(rec => {
			return {
				...rec,
				'models': _.filter(rec.models, (v) => !_.includes(_.map(results.client.hiddenModels, function (e) { return e.model }), v.value))
			}
		}) : []
		// clientSnapshot.data().clientBrandIDs.map((brand) => {
		// 	brandpromises.push(window.firebase.firestore().doc(`brands/${brand}`).get())
		// 	modelpromises.push(window.firebase.firestore().doc(`brands/${brand}/models/${brand}`).get())
		// })
		// const brandsnapshots = await Promise.all(brandpromises)
		// brandsnapshots.forEach(snap => {
		// 	results.client.brands.push(snap.data())
		// })
		// const modelSnapshots = await Promise.all(modelpromises)
		// modelSnapshots.forEach(snap => {
		// 	results.client.brands = _.map(results.client.brands, function (make) {
		// 		return (snap.exists && snap.id === make.documentID) ?
		// 			{
		// 				...make,
		// 				'models': _.filter(snap.data().models, (v) => !_.includes(_.map(results.client.hiddenModels, function (e) { return e.model }), v.value))
		// 			} : {
		// 				...make
		// 			}
		// 	})
		// })
		let settingsid = clientid ? clientSnapshot.data().settingsID : userDoc.settingsID;
		// const clientUserSnapshot = await window.firebase.firestore().collection('users')
		//     .where('arrayClientID', 'array-contains', clientid)
		//     .get()
		// let users = []
		let userRoleID = '';
		let defaultModule = '';
		// clientUserSnapshot.forEach((doc) => {
		//     const _user = doc.data()
		//     users.push({
		//         id: doc.id,
		//         name: _user.name,
		//         email: _user.email
		//     })
		// });
		//await asyncForEach(users.filter(e => e.id === localStorage.uid), async (_user) => {
		//console.log('all userClients start', new Date());

		const rolesPromises = [];
		rolesPromises.push(window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${clientid || 'undefined'}`).get())
		rolesPromises.push(window.firebase.firestore().doc(`clientSettings/${settingsid}`).get())
		rolesPromises.push(window.firebase.firestore().collection(`clientSettings/${settingsid}/roles`).get())
		const userRolesSnap = await Promise.all(rolesPromises);
		let department = userRolesSnap[0].exists ? userRolesSnap[0].data().department : '';
		let departments = userRolesSnap[1].exists ? userRolesSnap[1].data().departments : null;
		let departmentName = department;
		if (department && !_.isEmpty(departments)) {
			var userDepartment = departments.filter(item => item.value === department).length > 0 ? departments.filter(item => item.value === department)[0] : null;
			if (userDepartment)
				departmentName = userDepartment.name
		}
		// console.log('all roles start', new Date());
		// const t13 = performance.now();
		// console.log('diff 1 - ', (t13 - t12) / 1000)
		var _roles = []
		if (userRolesSnap[2]?.docs?.length > 0) {
			userRolesSnap[2].docs.forEach(rec => {
				_roles.push({
					...rec.data(),
					id: rec.id
				})
			})
			results.client.roles = _roles;
		}
		else {
			results.client.roles = [];
		}
		// users.map((item, index) => {
		//     if (item.id === localStorage.uid) {
		results.roleID = userDoc.roleID;
		//userClientSnapshot.exists ? userClientSnapshot.data().roleID :
		//item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
		results.queueNum = userRolesSnap[0].exists ? userRolesSnap[0].data().queueNum : '';
		results.department = department;
		results.departmentName = departmentName;
		results.liteVersion = userRolesSnap[1].exists ? userRolesSnap[1].data().liteVersion : null;
		results.isMclaren = userRolesSnap[1].exists ? userRolesSnap[1].data().isMclaren : null;
		results.isPorsche = userRolesSnap[1].exists ? userRolesSnap[1].data().isPorsche : null;
		results.oemName = userRolesSnap[1].exists ? userRolesSnap[1].data().name : null;
		results.mvwReport = userRolesSnap[1].exists ? userRolesSnap[1].data().mvwReport : null;
		results.strPorsche = results.isPorsche === true ? 'true' : 'false';

		if (userRolesSnap[1].exists && userRolesSnap[1].data().theme) {
			results.theme = userRolesSnap[1].data().theme;
			localStorage.setItem('brandTheme', results.theme);
		}
		else {
			results.theme = null;
			localStorage.removeItem('brandTheme');
		}

		if (userRolesSnap[1].exists && userRolesSnap[1].data().brandIDs) {
			results.oemBrands = _.filter(allMakeModels, (v) => _.indexOf(userRolesSnap[1].data().brandIDs, v.documentID) >= 0);
		}
		const _customFonts = [];
		if (!_.isEmpty(results.oemBrands)) {

			if (results.oemBrands.some(a => a.value.toLowerCase() === 'lexus')) {
				_customFonts.push('Nobel')
			}
			if (results.oemBrands.some(a => a.value.toLowerCase() === 'toyota')) {
				_customFonts.push('Toyota')
			}
		}
		if (results?.client?.name?.includes('smart')) {
			_customFonts.push('FOR smart Sans')
		}
		if (results?.isMclaren === true) {
			_customFonts.push('Noto Sans')
			_customFonts.push('McLaren Bespoke')
			_customFonts.push('Soho Gothic Pro')
		}
		results.customFonts = _customFonts;
		//return item;
		//     }
		//     return item;
		// });
		//if (_user.id === localStorage.uid) {
		userRoleID = userDoc.roleID;//userClientSnapshot.exists ? userClientSnapshot.data().roleID : '';
		if (!results.client.roles.some(a => a.id === userRoleID)) {
			const adminRole = results.client.roles.find(a => a.name === 'Administrator');
			if (adminRole)
				userRoleID = adminRole.id;
			results.roleID = adminRole ? adminRole.id : 'wholesalerRoleID';
		}
		defaultModule = userRolesSnap[0].exists ? userRolesSnap[0].data().defaultModule : '';
		if (defaultModule === 'sales')
			defaultModule = 'enquiry'
		if (userDoc.isWholesaler === true && userDoc.wholesalerDashboard === true) {
			defaultModule = 'wholesaler'
		}
		//}
		//});
		//setClientUsers(users)
		//const settingSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}`).get()
		//const t14 = performance.now();
		if (userRoleID) {
			// console.log('all userRoleID start', new Date());
			// console.log('diff 1 - ', (t14 - t13) / 1000)
			let roleSnapshot = _.find(results.client.roles, { id: userRoleID });
			if (roleSnapshot) {
				if (!_.isEmpty(roleSnapshot.modules)) {
					Object.keys(roleSnapshot.modules).map(function (el, i) {
						if (roleSnapshot.modules[el] === true) {
							_modules.push(
								{
									name: el,
									default: defaultModule === el ? true : false,
									type: (el === 'service' || el === 'tradeinPro' || el === 'eventsPro' || el === 'fleetPro' || el === 'dealLog') ? el : 'sales',
									sortOrder: AllModules.find(a => a.module === el) ? AllModules.find(a => a.module === el).sortOrder : i
								})
						}
					})
					if (_modules.length > 0 && !_modules.some(e => e.name === 'oem')) {
						_modules.push(
							{
								name: 'oem',
								default: false,
								type: 'sales',
								sortOrder: AllModules.find(a => a.module === 'oem') ? AllModules.find(a => a.module === 'oem').sortOrder : _modules.length + 1
							})
					}
					if (results.client
						&& results.client.moduleSettings
						&& results.client.moduleSettings.sales
						&& (results.client.moduleSettings.sales.enabled === false || results.client.moduleSettings.sales.active === false)) {
						_modules = _modules.filter(a => a.type !== 'sales')
					}

					if (results.client
						&& (_.isEmpty(results.client.moduleSettings)
							|| _.isEmpty(results.client.moduleSettings.service)
							|| results.client.moduleSettings.service.enabled === false
							|| results.client.moduleSettings.service.active === false)) {
						_modules = _modules.filter(a => a.type !== 'service')
					}

					if (results.client
						&& (_.isEmpty(results.client.moduleSettings)
							|| _.isEmpty(results.client.moduleSettings.tradeinPro)
							|| results.client.moduleSettings.tradeinPro.enabled === false
							|| results.client.moduleSettings.tradeinPro.active === false)) {
						_modules = _modules.filter(a => a.type !== 'tradeinPro')
					}

					if (results.client
						&& (_.isEmpty(results.client.moduleSettings)
							|| _.isEmpty(results.client.moduleSettings.dealLog)
							|| results.client.moduleSettings.dealLog.enabled === false
							|| results.client.moduleSettings.dealLog.active === false)) {
						_modules = _modules.filter(a => a.type !== 'dealLog')
					}

					if (results.client
						&& (_.isEmpty(results.client.moduleSettings)
							|| _.isEmpty(results.client.moduleSettings.eventsPro)
							|| results.client.moduleSettings.eventsPro.enabled === false
							|| results.client.moduleSettings.eventsPro.active === false)) {
						_modules = _modules.filter(a => a.type !== 'eventsPro')
					}

					if (results.client
						&& (_.isEmpty(results.client.moduleSettings)
							|| _.isEmpty(results.client.moduleSettings.fleetPro)
							|| results.client.moduleSettings.fleetPro.enabled === false
							|| results.client.moduleSettings.fleetPro.active === false)) {
						_modules = _modules.filter(a => a.type !== 'fleetPro')
					}

					if (results.client
						&& results.client.moduleSettings
						&& (_.isEmpty(results.client.moduleSettings.frontdesk)
							|| results.client.moduleSettings.frontdesk.enabled === false
							|| results.client.moduleSettings.frontdesk.active === false)) {
						_modules = _modules.filter(a => a.name !== 'frontdesk')
					}
					if (!Boolean(roleSnapshot?.permissions?.accessToTVDisplayOnly)) {
						_modules = _modules.filter(a => a.name !== 'tvModule')
					}

					if (results.client
						&& results.client.moduleSettings
						&& results.client.moduleSettings.cafe
						&& (results.client.moduleSettings.cafe.enabled === false || results.client.moduleSettings.cafe.active === false)) {
						_modules = _modules.filter(a => a.type !== 'cafe')
					}
					else {
						results.client.moduleSettings = {
							...results.client.moduleSettings,
							cafe: {
								enabled: true,
								active: true
							}
						}
					}
					_modules = _modules.filter(a => a.name !== 'oem' && a.name !== 'serviceFleetPro')
					if (_modules.length === 1) {
						defaultModule = _modules[0].name
					}
					if (defaultModule === 'sales')
						defaultModule = 'enquiry'
					//aftersales changes
					if (results.afterSales) {
						_modules.push(
							{
								name: 'afterSales',
								default: defaultModule === 'afterSales',
								sortOrder: AllModules.find(a => a.module === 'afterSales') ? AllModules.find(a => a.module === 'afterSales').sortOrder : _modules.length + 1
							})
					}
					//end

					//wholesaler module
					if (userDoc.isWholesaler === true) {
						_modules.push(
							{
								name: 'wholesaler',
								default: defaultModule === 'wholesaler',
								sortOrder: AllModules.find(a => a.module === 'wholesaler') ? AllModules.find(a => a.module === 'wholesaler').sortOrder : _modules.length + 1
							})
					}
					//end
					if (!_.isEmpty(localStorage.objSales)) {
						setModules(_modules.length === 0 ? _modules.push({ name: 'enquiry', default: true }) : _.map(_modules, function (e) { return e.name === 'enquiry' ? { ...e, default: true } : { ...e, default: false } }));
					}
					else {
						setModules(_modules.length === 0 ? _modules.push({ name: 'enquiry', default: true }) : _modules);
					}

					if (roleSnapshot?.permissions?.accessToTVDisplayOnly === true) {
						setModules([{
							name: 'tvModule',
							default: true,
							sortOrder: 0
						}]);
					}

					if (roleSnapshot?.permissions?.accessToReportsOnly === true) {
						_modules = [{
							name: 'reports',
							default: true,
							sortOrder: 0
						}]
						setModules(_modules);
						defaultModule = 'reports';
					}
				}
				//results.level = !_.isEmpty(roleSnapshot.data().level) ? roleSnapshot.data().level : results.level;
				results.roleName = roleSnapshot.name;
				results.rolePermissions = roleSnapshot;
			}
		}
		if (userDoc.isWholesaler === true && userDoc.wholesalerDashboard === true) {
			_modules.push(
				{
					name: 'wholesaler',
					default: true,
					sortOrder: 1
				})
		}
		let settingsDoc = userRolesSnap[1].data()
		if (userDoc.isWholesaler === true && userDoc.wholesalerDashboard === true) {
			results.isWholesaler = true;
			results.client.name = userDoc.name
			settingsDoc = getWholesalerSettings(settingsDoc);
		}
		clientLookups.forEach(rec => {
			if (_.isEmpty(settingsDoc[rec.value]))
				settingsDoc = {
					...settingsDoc,
					[rec.value]: []
				}
		})
		if (_.isEmpty(settingsDoc['mandatoryFields'])) {
			settingsDoc = {
				...settingsDoc,
				['mandatoryFields']: {}
			}
		}
		[...clientLookups, 'pipelines'].forEach(lookup => {
			if (_.isArray(settingsDoc[lookup])) {
				settingsDoc = {
					...settingsDoc,
					[lookup]: settingsDoc[lookup].map(r => { return { ...r, level: 'oem' } })
				}
			}

		})
		console.log('all getClientSettings start', new Date());
		// const t15 = performance.now();
		// console.log('diff 1 - ', (t15 - t14) / 1000)
		var _settingsDoc = { ...settingsDoc };
		var cSettings = await getClientSettings(results.client, settingsDoc, ((!_.isEmpty(localStorage.oemswitchclient) || _.isEmpty(results.rolePermissions) || _.isEmpty(results.rolePermissions.admin) || !Boolean(results.rolePermissions.admin.oemDashboard)) ? 'individual' : results.level))

		var sSettings = {};
		var tSettings = {};
		var eSettings = {};
		var fSettings = {};
		if (results?.client?.moduleSettings?.service
			&& results.client.moduleSettings.service.enabled === true
			&& results.client.moduleSettings.service.active === true) {
			sSettings = await getServiceSettings(results.client, ((!_.isEmpty(localStorage.oemswitchclient) || _.isEmpty(results.rolePermissions) || _.isEmpty(results.rolePermissions.admin) || !Boolean(results.rolePermissions.admin.oemDashboard)) ? 'individual' : results.level), 'services')
		}
		if (results?.client?.moduleSettings?.tradeinPro
			&& results.client.moduleSettings.tradeinPro.enabled === true
			&& results.client.moduleSettings.tradeinPro.active === true) {
			tSettings = await getServiceSettings(results.client, ((!_.isEmpty(localStorage.oemswitchclient) || _.isEmpty(results.rolePermissions) || _.isEmpty(results.rolePermissions.admin) || !Boolean(results.rolePermissions.admin.oemDashboard)) ? 'individual' : results.level), 'tradeinPro')
			if (results.client.id) {
				let allWholesalerPromise = [];
				var _wholesaler = []
				results.clients.filter(c => c.moduleSettings
					&& c.moduleSettings.tradeinPro
					&& c.moduleSettings.tradeinPro.enabled === true
					&& c.moduleSettings.tradeinPro.active === true).forEach(rec => {
						allWholesalerPromise.push(window.firebase.firestore().collection(`users`)
							.where('isWholesaler', '==', true)
							.where('wholesalerClientID', 'array-contains', rec.id).get())

					})
				const promiseUsersSnapshot = await Promise.all(allWholesalerPromise);
				promiseUsersSnapshot.forEach((groupUsersSnapshot, index) => {
					groupUsersSnapshot.docs.forEach(user => {
						const _userdoc = user.data();
						if (_userdoc.isDisabled !== true && !_wholesaler.some(a => a.id === user.id) && (!Boolean(_userdoc.superAdmin) || localStorage.isDemo === 'demo'))// && _userdoc.isHidden !== true)
							_wholesaler.push({ ...user.data(), 'documentID': user.id, 'id': user.id, 'active': true })
					})
				})
				results.wholesalers = _wholesaler;
			}

		}
		if (results?.client?.moduleSettings?.eventsPro
			&& results.client.moduleSettings.eventsPro.enabled === true
			&& results.client.moduleSettings.eventsPro.active === true) {
			eSettings = await getServiceSettings(results.client, ((!_.isEmpty(localStorage.oemswitchclient) || _.isEmpty(results.rolePermissions) || _.isEmpty(results.rolePermissions.admin) || !Boolean(results.rolePermissions.admin.oemDashboard)) ? 'individual' : results.level), 'eventsPro')
		}
		if (results?.client?.moduleSettings?.fleetPro
			&& results.client.moduleSettings.fleetPro.enabled === true
			&& results.client.moduleSettings.fleetPro.active === true) {
			fSettings = await getServiceSettings(results.client, ((!_.isEmpty(localStorage.oemswitchclient) || _.isEmpty(results.rolePermissions) || _.isEmpty(results.rolePermissions.admin) || !Boolean(results.rolePermissions.admin.oemDashboard)) ? 'individual' : results.level), 'fleetPro')
		}
		console.log('all getClientSettings end', new Date());
		results.client.settings = cSettings
		results.client.services = sSettings
		results.client.tradeinPro = tSettings
		results.client.eventsPro = eSettings
		results.client.fleetPro = fSettings
		results.client.settings.id = settingsid;
		results.settingName = settingsDoc.name;
		if (cSettings.pipelines) {
			settingsDoc.pipelines = cSettings.pipelines;
		}
		const objList = !_.isEmpty(results.client.settings) && Object.keys(results.client.settings).filter(function (e) { return _.isObject(results.client.settings[e]) })

		//group client settings
		if (localStorage.defaultModule !== 'oem' && !_.isEmpty(results.group) && !_.isEmpty(results.group.client_ids) && results.group.client_ids.length > 0 && (Boolean(results.group.enableSharedContact) || Boolean(results.group.enableLeadTransfer))) {
			results.group.clients = {};
			for (let i = 0; i < results.group.client_ids.length; i++) {
				const cid = results.group.client_ids[i]
				if (cid === results.client.id) {
					results.group.clients[results.client.id] = {
						...results.client,
						enableSharedContact: _.find(results.groups, { documentID: results.client.group }) ? _.find(results.groups, { documentID: results.client.group }).enableSharedContact : false,
						enableLeadTransfer: _.find(results.groups, { documentID: results.client.group }) ? _.find(results.groups, { documentID: results.client.group }).enableLeadTransfer : false,
						name: results.client.name,
						settings: {
							...cSettings,
							'allPipelines': cSettings.pipelines.filter(a => a.active === true),
							'pipelines': (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.pipeline)) ?
								_.filter(cSettings.pipelines, (v) => _.indexOf(results.rolePermissions.pipeline, v.value) >= 0)
								: cSettings.pipelines
						},
						services: sSettings
					}
				}
				else {
					// var otherClient = await window.firebase.firestore().doc(`clients/${cid}`).get();
					// if (otherClient.exists) {
					// 	var _cSettings = await getClientSettings({ ...otherClient.data(), id: otherClient.id }, _settingsDoc, 'individual');
					// 	var _sSettings = await getServiceSettings({ ...otherClient.data(), id: otherClient.id }, 'individual');
					results.group.clients[cid] = {
						...results.client,
						enableSharedContact: _.find(results.groups, { documentID: results.client.group }) ? _.find(results.groups, { documentID: results.client.group }).enableSharedContact : false,
						enableLeadTransfer: _.find(results.groups, { documentID: results.client.group }) ? _.find(results.groups, { documentID: results.client.group }).enableLeadTransfer : false,
						name: results.client.name,
						settings: {
							...cSettings,
							'allPipelines': cSettings.pipelines.filter(a => a.active === true),
							'pipelines': (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.pipeline)) ?
								_.filter(cSettings.pipelines, (v) => _.indexOf(results.rolePermissions.pipeline, v.value) >= 0)
								: cSettings.pipelines
						},
						services: sSettings
					}
					//}
				}
			}
		}
		// else if (localStorage.defaultModule === 'oem') {
		// 	if (results.group)
		// 		results.group.clients = {};
		// 	else
		// 		results.group = {
		// 			...results.group,
		// 			clients: {}
		// 		}
		// 	for (let i = 0; i < results.clients.length; i++) {
		// 		var _clientDoc = { ...settingsDoc };
		// 		const _client = results.clients[i];
		// 		var _cSettings = await getClientSettings({ ..._client, id: _client.id }, _clientDoc, 'individual');
		// 		var _sSettings = await getServiceSettings({ ..._client, id: _client.id }, 'individual');
		// 		results.group.clients[_client.id] = {
		// 			..._client,
		// 			name: _client.name,
		// 			settings: {
		// 				..._cSettings,
		// 				'allPipelines': _cSettings.pipelines.filter(a => a.active === true),
		// 				'pipelines': (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.pipeline)) ?
		// 					_.filter(_cSettings.pipelines, (v) => _.indexOf(results.rolePermissions.pipeline, v.value) >= 0)
		// 					: _cSettings.pipelines
		// 			},
		// 			services: _sSettings
		// 		}
		// 	}
		// }
		else {
			if (results.group)
				results.group.clients = {};
			else
				results.group = {
					...results.group,
					clients: {}
				}
			results.clients.forEach(rec => {
				results.group.clients[rec.id] = {
					...rec,
					enableSharedContact: _.find(results.groups, { documentID: rec.group }) ? _.find(results.groups, { documentID: rec.group }).enableSharedContact : false,
					enableLeadTransfer: _.find(results.groups, { documentID: rec.group }) ? _.find(results.groups, { documentID: rec.group }).enableLeadTransfer : false,
					name: rec.name,
					settings: {
						...cSettings,
						'allPipelines': cSettings.pipelines.filter(a => a.active === true),
					},
					services: sSettings
				}
			})
		}
		//set oem dashboard settings
		if (!Boolean(userDoc.wholesalerDashboard)) {
			if ((!_.isEmpty(results.rolePermissions) && !_.isEmpty(results.rolePermissions.admin) && results.rolePermissions.admin.oemDashboard === true) || _.isEmpty(clientid)) {
				if (_.isEmpty(localStorage.oemswitchclient)) {
					results.oemDashboard = true;
					localStorage.removeItem('objSales');
					localStorage.removeItem('objNotify');
					defaultModule = defaultModule?.includes('oem') ? defaultModule : 'oem';
					results.showSwitchOEM = false;
					let oemhiddenModels = [];
					if (results.level === 'oem') {
						if (userRolesSnap[1].exists) {
							results.oemName = userRolesSnap[1].data().name;
							results.client.clientSettings = userRolesSnap[1].data().clientSettings;
							results.client.currency = userRolesSnap[1].data().currency;
							results.client.countryCode = userRolesSnap[1].data().countryCode;
							results.client.customerConsent = userRolesSnap[1].data().customerConsent;
							results.client.testdriveTC = userRolesSnap[1].data().testdriveTC;
							results.client.insuranceTC = userRolesSnap[1].data().insuranceTC;
							results.client.moduleSettings = userRolesSnap[1].data().moduleSettings || {};
							results.client.timezone = userRolesSnap[1].data().timezone;
							results.client.integrationKey = userRolesSnap[1].data().integrationKey;
							results.client.projectId = userRolesSnap[1].data().projectId ? userRolesSnap[1].data().projectId : null;
							oemhiddenModels = userRolesSnap[1].data().hiddenModels;

						}
						//get enquiry options
						const optionsPromise = [];
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/enquiryOptions/${results.client.settingsID}`).get())
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/tradeinOptions/${results.client.settingsID}`).get())
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/tradeinPro/${results.client.settingsID}/enquiryOptions/${results.client.settingsID}`).get());
						const optionsSnap = await Promise.all(optionsPromise);
						// const enquiryOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/enquiryOptions/${results.client.settingsID}`).get()
						// const tradeinOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/tradeinOptions/${results.client.settingsID}`).get()
						// const tradeinProOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/tradeinPro/${results.client.settingsID}/enquiryOptions/${results.client.settingsID}`).get()
						if (optionsSnap[0].exists) {
							results.client.enquiryOptions = optionsSnap[0].data().options;
						}
						if (optionsSnap[1].exists) {
							results.client.tradeinOptions = optionsSnap[1].data().options;
						}
						if (optionsSnap[2].exists) {
							results.client.tradeinProOptions = optionsSnap[2].data().options;
						}
						if (results.clients.some(a => a?.moduleSettings?.eventsPro?.enabled === true &&
							a?.moduleSettings?.eventsPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								eventsPro: {
									enabled: true,
									active: true
								}
							}
							results.client.eventsPro = await getServiceSettings(results.client, results.level, 'eventsPro')
						}
						if (results.clients.some(a => a?.moduleSettings?.fleetPro?.enabled === true &&
							a?.moduleSettings?.fleetPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								fleetPro: {
									enabled: true,
									active: true
								}
							}
							results.client.fleetPro = await getServiceSettings(results.client, results.level, 'fleetPro');
						}
					}
					else if (results?.level?.includes('region') && !_.isEmpty(results.regionID)) {
						const regionSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}`).get()
						if (regionSnapshot.exists) {
							results.oemName = regionSnapshot.data().name;
							results.client.clientSettings = regionSnapshot.data().clientSettings;
							results.client.currency = regionSnapshot.data().currency;
							results.client.countryCode = regionSnapshot.data().countryCode;
							results.client.customerConsent = regionSnapshot.data().customerConsent;
							results.client.testdriveTC = regionSnapshot.data().testdriveTC;
							results.client.insuranceTC = regionSnapshot.data().insuranceTC;
							results.client.moduleSettings = regionSnapshot.data().moduleSettings || {};;
							results.client.timezone = regionSnapshot.data().timezone;
							results.client.integrationKey = regionSnapshot.data().integrationKey;
							results.client.projectId = regionSnapshot.data().projectId ? regionSnapshot.data().projectId : null;
							oemhiddenModels = regionSnapshot.data().hiddenModels;
							if (results.level === 'subregion' && results?.arraySubregionID?.length) {
								results.oemName = _.find(results.subregions, { id: results?.arraySubregionID[0] })?.name || results.oemName;
							}
						}
						//get enquiry options
						const optionsPromise = [];
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/enquiryOptions/${results.regionID}`).get())
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/tradeinOptions/${results.regionID}`).get())
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/tradeinPro/${results.regionID}/enquiryOptions/${results.regionID}`).get());
						const optionsSnap = await Promise.all(optionsPromise);
						// const enquiryOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/enquiryOptions/${results.regionID}`).get()
						// const tradeinOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/tradeinOptions/${results.regionID}`).get()
						// const tradeinProOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/regions/${results.regionID}/tradeinPro/${results.regionID}/enquiryOptions/${results.regionID}`).get()
						if (optionsSnap[0].exists) {
							results.client.enquiryOptions = optionsSnap[0].data().options;
						}
						if (optionsSnap[1].exists) {
							results.client.tradeinOptions = optionsSnap[1].data().options;
						}
						if (optionsSnap[2].exists) {
							results.client.tradeinProOptions = optionsSnap[2].data().options;
						}
						if (results.clients.some(a => a?.moduleSettings?.eventsPro?.enabled === true &&
							a?.moduleSettings?.eventsPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								eventsPro: {
									enabled: true,
									active: true
								}
							}
							results.client.eventsPro = await getServiceSettings(results.client, results.level, 'eventsPro')

						}
						if (results.clients.some(a => a?.moduleSettings?.fleetPro?.enabled === true &&
							a?.moduleSettings?.fleetPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								fleetPro: {
									enabled: true,
									active: true
								}
							}
							results.client.fleetPro = await getServiceSettings(results.client, results.level, 'fleetPro')

						}
					}
					else if (results.level === 'group' && !_.isEmpty(results.groupID)) {
						const usergroupSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}`).get()
						if (usergroupSnapshot.exists) {
							results.oemName = usergroupSnapshot.data().name;
							results.client.clientSettings = usergroupSnapshot.data().clientSettings;
							results.client.currency = usergroupSnapshot.data().currency;
							results.client.countryCode = usergroupSnapshot.data().countryCode;
							results.client.customerConsent = usergroupSnapshot.data().customerConsent;
							results.client.testdriveTC = usergroupSnapshot.data().testdriveTC;
							results.client.insuranceTC = usergroupSnapshot.data().insuranceTC;
							results.client.moduleSettings = usergroupSnapshot.data().moduleSettings || {};;
							results.client.timezone = usergroupSnapshot.data().timezone;
							results.client.integrationKey = usergroupSnapshot.data().integrationKey;
							results.client.projectId = usergroupSnapshot.data().projectId ? usergroupSnapshot.data().projectId : null;
							oemhiddenModels = usergroupSnapshot.data().hiddenModels;
						}
						//get enquiry options
						const optionsPromise = [];
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/enquiryOptions/${results.groupID}`).get());
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/tradeinOptions/${results.groupID}`).get());
						optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/tradeinPro/${results.groupID}/enquiryOptions/${results.groupID}`).get());
						const optionsSnap = await Promise.all(optionsPromise);
						// const enquiryOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/enquiryOptions/${results.groupID}`).get()
						// const tradeinOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/tradeinOptions/${results.groupID}`).get()
						// const tradeinProOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/groups/${results.groupID}/tradeinPro/${results.groupID}/enquiryOptions/${results.groupID}`).get()
						if (optionsSnap[0].exists) {
							results.client.enquiryOptions = optionsSnap[0].data().options;
						}
						if (optionsSnap[1].exists) {
							results.client.tradeinOptions = optionsSnap[1].data().options;
						}
						if (optionsSnap[2].exists) {
							results.client.tradeinProOptions = optionsSnap[2].data().options;
						}
						if (results.clients.some(a => a?.moduleSettings?.eventsPro?.enabled === true &&
							a?.moduleSettings?.eventsPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								eventsPro: {
									enabled: true,
									active: true
								}
							}
							results.client.eventsPro = await getServiceSettings(results.client, results.level, 'eventsPro')
						}
						if (results.clients.some(a => a?.moduleSettings?.fleetPro?.enabled === true &&
							a?.moduleSettings?.fleetPro?.active === true)) {
							results.client.moduleSettings = {
								...results.client.moduleSettings,
								fleetPro: {
									enabled: true,
									active: true
								}
							}
							results.client.fleetPro = await getServiceSettings(results.client, results.level, 'fleetPro')
						}
					}
					if (userRolesSnap[1].exists && userRolesSnap[1].data().brandIDs) {
						results.client.brands = _.filter(allMakeModels, (v) => _.indexOf(userRolesSnap[1].data().brandIDs, v.documentID) >= 0).map(rec => {
							return {
								...rec,
								'models': oemhiddenModels ? _.filter(rec.models, (v) => !_.includes(_.map(oemhiddenModels, function (e) { return e.model }), v.value)) : rec.models
							}
						})
					}
					//oem modules
					_modules = [];
					_modules.push(
						{
							name: 'oem',
							default: defaultModule === 'oem',
							sortOrder: 1
						});
					if (results.clients.some(a => a?.moduleSettings?.eventsPro?.enabled === true &&
						a?.moduleSettings?.eventsPro?.active === true)) {
						_modules.push(
							{
								name: 'oemeventsPro',
								default: defaultModule === 'eventsPro',
								sortOrder: 2
							})
					}
					if (results.clients.some(a => a?.moduleSettings?.fleetPro?.enabled === true &&
						a?.moduleSettings?.fleetPro?.active === true)) {
						_modules.push(
							{
								name: 'oemfleetPro',
								default: defaultModule === 'fleetPro',
								sortOrder: 3
							})
					}
					setModules(_modules);
				}
				else {
					results.showSwitchOEM = true
					//get enquiry options
					const optionsPromise = [];
					optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/enquiryOptions/${results.client.id}`).get());
					optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinOptions/${results.client.id}`).get());
					optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinPro/${results.client.id}/enquiryOptions/${results.client.id}`).get());
					const optionsSnap = await Promise.all(optionsPromise);
					// const enquiryOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/enquiryOptions/${results.client.id}`).get()
					// const tradeinOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinOptions/${results.client.id}`).get()
					// const tradeinProOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinPro/${results.client.id}/enquiryOptions/${results.client.id}`).get()
					if (optionsSnap[0].exists) {
						results.client.enquiryOptions = optionsSnap[0].data().options;
					}
					if (optionsSnap[1].exists) {
						results.client.tradeinOptions = optionsSnap[1].data().options;
					}
					if (optionsSnap[2].exists) {
						results.client.tradeinProOptions = optionsSnap[2].data().options;
					}
				}
			}
			else {
				results.showSwitchOEM = false
				//get enquiry options
				const optionsPromise = [];
				optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/enquiryOptions/${results.client.id}`).get());
				optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinOptions/${results.client.id}`).get());
				optionsPromise.push(window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinPro/${results.client.id}/enquiryOptions/${results.client.id}`).get());
				const optionsSnap = await Promise.all(optionsPromise);
				// 	const enquiryOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/enquiryOptions/${results.client.id}`).get()
				// const tradeinOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinOptions/${results.client.id}`).get()
				// const tradeinProOptionsSnapshot = await window.firebase.firestore().doc(`clientSettings/${results.client.settingsID}/clients/${results.client.id}/tradeinPro/${results.client.id}/enquiryOptions/${results.client.id}`).get()
				if (optionsSnap[0].exists) {
					results.client.enquiryOptions = optionsSnap[0].data().options;
				}
				if (optionsSnap[1].exists) {
					results.client.tradeinOptions = optionsSnap[1].data().options;
				}
				if (optionsSnap[2].exists) {
					results.client.tradeinProOptions = optionsSnap[2].data().options;
				}
			}
		}


		const remoteConfig = window.firebase.remoteConfig();

		try {
			await remoteConfig.fetchAndActivate()
				.then(() => {
					fetchLookups();
				})
				.catch((err) => {
					console.error(err);
				});

		} catch (error) {
			console.log('lookups', error);
		}
		async function fetchLookups() {
			let apiURL = remoteConfig.getString('apiURL');
			let eventappURL = remoteConfig.getString('eventappURL');
			let tradeinplusURL = remoteConfig.getString('tradeinplusURL');
			let financeURL = remoteConfig.getString('financeURL');
			let baseURL = window.location.origin;//remoteConfig.getString('baseURL');
			const languages = remoteConfig.getString('languages');
			const regions = remoteConfig.getString('regions');
			const countries = remoteConfig.getString('countries');
			const states = remoteConfig.getString('states');
			const nationalities = remoteConfig.getString('nationalities');
			const secondarydb = remoteConfig.getString('secondarydb');
			const aircallConfig = remoteConfig.getString('aircall');
			const ringcentralConfig = remoteConfig.getString('ringcentral');
			const showMFABanner = remoteConfig.getString('showMFABanner');
			const nylasv3Config = remoteConfig.getString('nylasv3');
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			};

			results.apiURL = apiURL ? apiURL : null;
			results.eventappURL = eventappURL ? eventappURL : null;
			results.tradeinplusURL = tradeinplusURL ? tradeinplusURL : null;
			results.financeURL = financeURL ? financeURL : null;
			results.baseURL = baseURL ? baseURL : null;
			results.aircallConfig = aircallConfig ? JSON.parse(aircallConfig) : null
			results.ringcentralConfig = ringcentralConfig ? JSON.parse(ringcentralConfig) : null
			results.showMFABanner = showMFABanner || null
			results.nylasv3Config = nylasv3Config ? JSON.parse(nylasv3Config) : null;//JSON.parse(`{"clientId":"2fca2fb8-a7d1-407b-af90-f364ec93caa3","apiKey":"nyk_v0_DCFJja0CV8TWUjZomlLrdXWQ6m741RoHAqVrJFzAcx6mlCFw3EssyJS2V7BJAlok","apiUri":"https://api.eu.nylas.com"}`)

			if (process.env.NODE_ENV === 'development')
				baseURL = 'http://localhost:3000/';

			const responseLang = await fetch(baseURL + languages, requestOptions);
			const languagejson = await responseLang.json();
			results.languages = languagejson;

			const responseReg = await fetch(baseURL + regions);
			const regjson = await responseReg.json();
			//results.regions = regjson;

			const responseCountries = await fetch(baseURL + countries);
			const countriesjson = await responseCountries.json();
			results.countries = countriesjson;

			const responseState = await fetch(baseURL + states);
			const statejson = await responseState.json();
			results.states = statejson;

			//console.log('responseNationalities', nationalities, remoteConfig);
			const responseNationalities = await fetch(baseURL + (nationalities ? nationalities : '/lookups/nationalities.json'));
			const nationalitiesjson = await responseNationalities.json();
			results.nationalities = nationalitiesjson;

			//console.log('responseNationalities', nationalities, remoteConfig);
			const responseLocales = await fetch(baseURL + `/locales/${results.language ? results.language : 'en'}.json`);
			const localesjson = await responseLocales.json();
			results.locales = localesjson;

			results.clients = results.clients.map(rec => {
				return {
					...rec,
					regionName: _.find(results.regions, { id: rec.region })?.name || null,
					groupName: _.find(results.groups, { id: rec.group })?.name || null,
					subregionName: _.find(results.subregions, { id: rec.subregion })?.name || null,
					countryName: _.find(results.countries, { code: rec.country })?.name || null
				}
			})

			//signInAnonymously for secondayDB
			if ((results?.client?.projectId === window.secondaryProjectId || _modules.some(a => a?.name?.toLowerCase()?.includes('eventspro')))
				&& _.isEmpty(window.firebase2.auth().currentUser)) {
				if (!_.isEmpty(secondarydb)) {
					window.firebase2.auth().signInWithEmailAndPassword(JSON.parse(secondarydb).user, JSON.parse(secondarydb).pass)
						.catch((error) => {
							console.log('error on secondary firestore auth user', error)
						});
				}
				else {
					window.firebase2.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
						.catch((error) => {
							console.log('error on secondary firestore auth user', error)
						});
				}
			}
		}

		var defaultPipeline = (pipeline && _.find(pipeline, { default: true })) ? _.find(pipeline, { default: true }).value : '';
		if (results?.rolePermissions?.defaultPipeline) {
			defaultPipeline = results?.rolePermissions?.defaultPipeline;
		}
		settingsDoc.pipelines = settingsDoc.pipelines.filter(a => a.active === true);
		if (settingsDoc.pipelines.some(item => item.value === 'LeadsBucket')) {
			settingsDoc.pipelines.filter(item => item.value === 'LeadsBucket')[0].stages = [
				{ name: `${!_.isEmpty(settingsDoc.inboundStageName) ? settingsDoc.inboundStageName : 'Inbound Lead'}`, value: 'InboundLead' }
				, ...settingsDoc.pipelines.filter(item => item.value === 'LeadsBucket')[0].stages
			]
		}
		results.allPipelines = settingsDoc.pipelines;
		let _allpipelines = settingsDoc.pipelines;
		if (results.afterSales) {
			_allpipelines.push({
				active: true,
				modules: {
					cafe: true,
					contact: true,
					files: true,
					requirement: true,
					finance: false,
					testDrive: false,
					tradeIn: false
				},
				value: 'AfterSales',
				name: 'After Sales',
				stages: [{
					name: 'Inbound Lead',
					value: 'inbound-lead'
				}, {
					name: 'New Enquiry',
					value: 'new-enquiry'
				},
				{
					name: 'In Progress',
					value: 'in-progress'
				},
				{
					name: 'Qualified',
					value: 'qualified'
				},
				{
					name: 'Appoitment Scheduled',
					value: 'appoitment-scheduled'
				},
				{
					name: 'Won',
					value: 'won'
				},
				{
					name: 'Lost',
					value: 'lost'
				}]
			})
			results.allPipelines = _allpipelines;
		}
		if (userDoc.isWholesaler === true && !Boolean(userDoc.wholesalerDashboard)) {
			let wsPipelines = [];
			wsPipelines.push({
				active: true,
				default: true,
				modules: {},
				value: 'Appraisal',
				name: 'Appraisal',
				stages: [{
					name: 'Pending',
					value: 'pending'
				},
				{
					name: 'Completed',
					value: 'completed'
				},
				{
					name: 'Won',
					value: 'won'
				},
				{
					name: 'Lost',
					value: 'lost'
				}]
			})
			results.client.settings.wholesalerPipelines = wsPipelines;
		}
		settingsDoc.allPipelines = settingsDoc.pipelines;
		if (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.pipeline)) {
			settingsDoc.pipelines = _.filter(settingsDoc.pipelines, (v) => _.indexOf(results.rolePermissions.pipeline, v.value) >= 0)
		}
		if (settingsDoc.pipelines.some(item => item.value === defaultPipeline)) {
			settingsDoc.pipelines.filter(e => e.value === defaultPipeline)[0].default = true;
		}
		else if (settingsDoc.pipelines.some(item => item.value === 'LeadsBucket') && settingsDoc.pipelines.length > 1) {
			settingsDoc.pipelines.filter(item => item.value !== 'LeadsBucket')[0].default = true
		}
		else if (settingsDoc.pipelines.length > 0) {
			settingsDoc.pipelines[0].default = true
		}

		if (settingsDoc.pipelines.some(item => item.value === 'LeadsBucket' && item.default) && settingsDoc.pipelines.length > 1)
			settingsDoc.pipelines.filter(item => item.value !== 'LeadsBucket')[0].default = true

		if (!_.isEmpty(results.client.settings) && !_.isEmpty(results.client.settings.pipelines)) {
			results.client.settings.pipelines = settingsDoc.pipelines;
		}
		if (!_.isEmpty(results.client.settings)) {
			results.client.settings.allPipelines = settingsDoc.allPipelines;
		}

		if (!_.isEmpty(results.client.services) && !_.isEmpty(results.client.services.pipelines)) {
			results.client.services.allPipelines = results.client.services.pipelines;
			if (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.servicePipeline)) {
				results.client.services.pipelines = _.filter(results.client.services.pipelines, (v) => _.indexOf(results.rolePermissions.servicePipeline, v.value) >= 0)
			}
			if (results.client.services.pipelines.some(item => item.value === results?.rolePermissions?.defaultservicePipeline)) {
				results.client.services.pipelines.filter(e => e.value === results?.rolePermissions?.defaultservicePipeline)[0].default = true;
			}
			else if (results.client.services.pipelines.length > 0)
				results.client.services.pipelines[0].default = true
		}

		if (!_.isEmpty(results.client.tradeinPro) && !_.isEmpty(results.client.tradeinPro.pipelines)) {
			results.client.tradeinPro.allPipelines = results.client.tradeinPro.pipelines;
			if (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.tradeinProPipeline)) {
				results.client.tradeinPro.pipelines = _.filter(results.client.tradeinPro.pipelines, (v) => _.indexOf(results.rolePermissions.tradeinProPipeline, v.value) >= 0)
			}
			if (results.client.tradeinPro.pipelines.length > 0)
				results.client.tradeinPro.pipelines[0].default = true
		}
		if (!_.isEmpty(results.client.eventsPro) && !_.isEmpty(results.client.eventsPro.pipelines)) {
			results.client.eventsPro.allPipelines = results.client.eventsPro.pipelines;
			if (!_.isEmpty(results.rolePermissions) && _.isArray(results.rolePermissions.eventsProPipeline)) {
				results.client.eventsPro.pipelines = _.filter(results.client.eventsPro.pipelines, (v) => _.indexOf(results.rolePermissions.eventsProPipeline, v.value) >= 0)
			}
			if (results.client.eventsPro.pipelines.length > 0)
				results.client.eventsPro.pipelines[0].default = true
		}
		//if(results.)
		results.tvDevices = []
		results.client.teams = []
		const integrationSnapshot = await window.firebase.firestore().collection(`integrations`).get();
		var _integrations = []
		if (integrationSnapshot.docs.length > 0) {
			integrationSnapshot.docs.forEach(rec => {
				_integrations.push({
					...rec.data(),
					id: rec.id
				})
			})
			results.integrations = _integrations;
		}
		else {
			results.integrations = [];
		}
		if (!Boolean(results.wholesalerDashboard)) {
			let refIntegrationSnapshot = `clients/${clientid}/integrations`;
			if (results.level === 'oem' && results.oemDashboard === true)
				refIntegrationSnapshot = `clientSettings/${results.oemID}/integrations`;
			else if (results?.level?.includes('region') && results.oemDashboard === true)
				refIntegrationSnapshot = `clientSettings/${results.oemID}/regions/${results.regionID}/integrations`;
			else if (results.level === 'group' && results.oemDashboard === true)
				refIntegrationSnapshot = `clientSettings/${results.oemID}/groups/${results.groupID}/integrations`;

			const clientintegrationSnapshot = await window.firebase.firestore().collection(refIntegrationSnapshot).get();
			var _clientintegrations = []
			if (clientintegrationSnapshot.docs.length > 0) {
				clientintegrationSnapshot.docs.forEach(rec => {
					if (!_clientintegrations.some(a => a.id === rec.id)) {
						_clientintegrations.push({
							...rec.data(),
							id: rec.id,
							value: rec.data().type,
							name: _.find(_integrations, { value: rec.data().type }) ? _.find(_integrations, { value: rec.data().type }).name : rec.data().type
						})
					}
				})
				const _int = _clientintegrations.map(r => {
					return {
						...r,
						value: r.type === 'googlesheet' && r?.settings?.name ? r.settings.name : r.value,
						name: r.type === 'googlesheet' && r?.settings?.name ? r.settings.name : r.name ? r.name : r.type
					}
				});
				results.client.integrations = _int;
			}
			else {
				results.client.integrations = []
			}
		}
		if (!_.isEmpty(results) &&
			!_.isEmpty(results.rolePermissions) &&
			!_.isEmpty(results.rolePermissions.salesType &&
				results &&
				results.client.settings &&
				results.client.settings.salesType)) {
			results.accessSalesType = _.filter(results.client.settings.salesType, (v) => _.indexOf(results.rolePermissions.salesType, v.value) >= 0).map(r => { return r.value })
		};
		setCurrentGroup(results.group);
		setPipeline(settingsDoc.pipelines)
		setDealersettings(results)
		setLocalClient(clientid);
		setLoadingText('')
		localStorage.setItem('settingsUpdatedOn', window.firebase.firestore.Timestamp.now().seconds)
		console.log('finish', new Date());
		if (forceUpdate && localStorage.defaultModule !== 'oem' && !_.isEmpty(results.group) && !_.isEmpty(results.group.client_ids) && results.group.client_ids.length > 0 && (Boolean(results.group.enableSharedContact) || Boolean(results.group.enableLeadTransfer))) {
			setGroupClients(results.group.client_ids.filter(a => a !== results.client.id));
		}
		setOtherSettings(!otherSettigs);
		//update all lookups in current client sub collection
		//if (localStorage.defaultModule !== 'oem') {


		try {
			if (results && results.client) {
				let current_user_email = (results.email ? results.email : results.id);
				let current_user_name = (results.name ? results.name : results.id);
				let client_user_id = (results.client.id ? results.client.id : results.id);
				let client_user_name = (results.client.name ? results.client.name : client_user_id);
				window.Intercom('boot', {
					app_id: "gh4uputt",
					name: current_user_name,
					email: current_user_email,
					user_id: current_user_email,
					company: {
						company_id: client_user_id,
						name: client_user_name,
					},
					AppName: 'AMS Pro',
					horizontal_padding: 85,
					vertical_padding: 10
				});
			}
		} catch (error) {
			console.log(error);
		}

		let changeModule = false;
		if (clientModule !== defaultModule) {
			changeModule = true;
		}
		if (!_.isEmpty(localStorage.objSales) && _modules.some(item => item.name === 'enquiry')) {
			defaultModule = 'enquiry'
			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}/userClients/${clientid}`)
				.set({ defaultModule: 'enquiry' }, { merge: true });
		}
		if (!_.isEmpty(localStorage.objService) && _modules.some(item => item.name === 'service')) {
			defaultModule = 'service'
			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}/userClients/${clientid}`)
				.set({ defaultModule: 'service' }, { merge: true });
		}
		if (!_.isEmpty(localStorage.objTradeinPro) && _modules.some(item => item.name === 'tradeinPro')) {
			defaultModule = 'tradeinPro'
			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}/userClients/${clientid}`)
				.set({ defaultModule: 'tradeinPro' }, { merge: true });
		}
		if (!_.isEmpty(localStorage.objEventsPro) && _modules.some(item => item.name === 'eventsPro')) {
			defaultModule = 'eventsPro'
			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}/userClients/${clientid}`)
				.set({ defaultModule: 'eventsPro' }, { merge: true });
		}
		if (!_.isEmpty(localStorage.objFleetPro) && _modules.some(item => item.name === 'fleetPro')) {
			defaultModule = 'fleetPro'
			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}/userClients/${clientid}`)
				.set({ defaultModule: 'fleetPro' }, { merge: true });
		}
		// if (defaultModule === 'oem') {
		// 	localStorage.defaultModule = defaultModule;
		// 	setClientModule(defaultModule)
		// 	history.push('/oem/dashboard')
		// }
		// else
		if (!defaultModule || !_modules.some(item => item.name === defaultModule)) {
			if (_modules.length > 1) {
				history.push("/");
				setShowselectmodules(true)
			}
			else if (_modules.length === 1) {
				setClientModule(_modules[0].name)
				localStorage.defaultModule = _modules[0].name;
				if (!_.isEmpty(localStorage.objNotify)) {
					let _notify = JSON.parse(localStorage.objNotify);
					handleNotifyClick(_notify.id, _notify.type, null, _notify.enquiryid, _notify.contactid, _notify, dealersettings);
				}
				if (_modules[0].name === 'enquiry' && !_.isEmpty(localStorage.objSales)) {
					history.push(`/${JSON.parse(localStorage.objNotify).type}/details/${JSON.parse(localStorage.objNotify).id}`)
				} else if (_modules[0].name === 'service' && !_.isEmpty(localStorage.objService)) {
					history.push(`/${JSON.parse(localStorage.objService).type}/details/${JSON.parse(localStorage.objService).id}`)
				}
				else if (_modules[0].name === 'tradeinPro' && !_.isEmpty(localStorage.objTradeinPro)) {
					history.push(`/${JSON.parse(localStorage.objTradeinPro).type.toLowerCase()}/details/${JSON.parse(localStorage.objTradeinPro).id}`)
				}
				else if (_modules[0].name === 'eventsPro' && !_.isEmpty(localStorage.objEventsPro)) {
					history.push(`/${JSON.parse(localStorage.eventsPro).type.toLowerCase()}/details/${JSON.parse(localStorage.eventsPro).id}`)
				}
				else if (_modules[0].name === 'fleetPro' && !_.isEmpty(localStorage.objFleetPro)) {
					history.push(`/${JSON.parse(localStorage.objFleet).type.toLowerCase()}/details/${JSON.parse(localStorage.objFleet).id}`)
				}
				else {
					localStorage.removeItem("objSales");
					localStorage.removeItem("objService");
					localStorage.removeItem("objTradeinPro");
					localStorage.removeItem("objEventsPro");
					localStorage.removeItem("objFleetPro");
					history.push(AllModules.find(item => item.module === _modules[0].name).route)
				}

			}
			else {
				setClientModule('enquiry')
				localStorage.defaultModule = 'enquiry'
				if (!_.isEmpty(localStorage.objNotify)) {
					let _notify = JSON.parse(localStorage.objNotify);
					handleNotifyClick(_notify.id, _notify.type, null, _notify.enquiryid, _notify.contactid, _notify, dealersettings);
				}
				if (!_.isEmpty(localStorage.objSales)) {
					history.push(`/${JSON.parse(localStorage.objSales).type}/details/${JSON.parse(localStorage.objSales).id}`)
				}
				else {
					localStorage.removeItem("objSales");
					history.push('/dashboard')
				}

			}
		}
		else if (defaultModule && (window.location.pathname === '/' || changeModule)) {
			setClientModule(defaultModule)
			localStorage.defaultModule = defaultModule
			if (!_.isEmpty(localStorage.objNotify)) {
				let _notify = JSON.parse(localStorage.objNotify);
				handleNotifyClick(_notify.id, _notify.type, null, _notify.enquiryid, _notify.contactid, _notify, dealersettings);
			}
			if (defaultModule === 'enquiry' && !_.isEmpty(localStorage.objSales)) {
				history.push(`/${JSON.parse(localStorage.objSales).type}/details/${JSON.parse(localStorage.objSales).id}`)
			} else if (defaultModule === 'service' && !_.isEmpty(localStorage.objService)) {
				history.push(`/${JSON.parse(localStorage.objService).type}/details/${JSON.parse(localStorage.objService).id}`)
			} else if (defaultModule === 'tradeinPro' && !_.isEmpty(localStorage.objTradeinPro)) {
				history.push(`/${JSON.parse(localStorage.objTradeinPro).type.toLowerCase()}/details/${JSON.parse(localStorage.objTradeinPro).id}`)
			} else if (defaultModule === 'eventsPro' && !_.isEmpty(localStorage.objEventsPro)) {
				history.push(`/${JSON.parse(localStorage.objEventsPro).type.toLowerCase()}/details/${JSON.parse(localStorage.objEventsPro).id}`)
			} else if (defaultModule === 'fleetPro' && !_.isEmpty(localStorage.objFleetPro)) {
				history.push(`/${JSON.parse(localStorage.objFleet).type.toLowerCase()}/details/${JSON.parse(localStorage.objFleet).id}`)
			}
			else {
				localStorage.removeItem("objSales");
				localStorage.removeItem("objService");
				localStorage.removeItem("objTradeinPro");
				localStorage.removeItem("objEventsPro");
				localStorage.removeItem("objFleetPro");
				history.push(AllModules.find(item => item.module === defaultModule).route)
			}

		}
		else {
			if (!_.isEmpty(localStorage.objNotify)) {
				let _notify = JSON.parse(localStorage.objNotify);
				handleNotifyClick(_notify.id, _notify.type, null, _notify.enquiryid, _notify.contactid, _notify, dealersettings);
			}
			if (defaultModule === 'enquiry' && !_.isEmpty(localStorage.objSales)) {
				history.push(`/${JSON.parse(localStorage.objSales).type}/details/${JSON.parse(localStorage.objSales).id}`)
			} else if (defaultModule === 'service' && !_.isEmpty(localStorage.objService)) {
				history.push(`/${JSON.parse(localStorage.objService).type}/details/${JSON.parse(localStorage.objService).id}`)
			} else if (defaultModule === 'tradeinPro' && !_.isEmpty(localStorage.objTradeinPro)) {
				history.push(`/${JSON.parse(localStorage.objTradeinPro).type.toLowerCase()}/details/${JSON.parse(localStorage.objTradeinPro).id}`)
			} else if (defaultModule === 'eventsPro' && !_.isEmpty(localStorage.objEventsPro)) {
				history.push(`/${JSON.parse(localStorage.objEventsPro).type.toLowerCase()}/details/${JSON.parse(localStorage.objEventsPro).id}`)
			} else if (defaultModule === 'fleetPro' && !_.isEmpty(localStorage.objFleetPro)) {
				history.push(`/${JSON.parse(localStorage.objFleet).type.toLowerCase()}/details/${JSON.parse(localStorage.objFleet).id}`)
			}
			else if (defaultModule === 'oem' && (Routes.some(a => a.route === window.location.pathname && a.module !== defaultModule) || window.location.pathname.includes('/pipeline/details'))) {
				history.push(AllModules.find(item => item.module === defaultModule).route)
			} else if (defaultModule !== 'oem' && window.location.pathname.includes('/oem/')) {
				history.push(AllModules.find(item => item.module === defaultModule).route)
			} else {
				localStorage.removeItem("objSales");
				localStorage.removeItem("objService");
				localStorage.removeItem("objTradeinPro");
				localStorage.removeItem("objEventsPro");
				localStorage.removeItem("objFleetPro");
			}

		}

		if (localStorage.defaultModule && localStorage.defaultModule !== 'oem' && results.client && results.client.paymentDue && !_.isEmpty(results.client.paymentDueDate)) {
			let _accountSuspended = false
			let daysText = 'Your subscription has been suspended for non-payment'
			const _paymentDueDate = moment.unix(results.client.paymentDueDate.seconds).endOf('day');
			const currentDate = moment(new Date());

			// calculate days and time 
			const daysRemaining = _paymentDueDate.diff(currentDate, 'days')
			const timeRemaining = _paymentDueDate.diff(currentDate)

			if (timeRemaining < 0) {
				daysText = 'Your subscription has been suspended for non-payment'
			} else if (daysRemaining === 0) {
				daysText = 'Your subscription will be suspended in less than a day'
			} else if (daysRemaining > 1) {
				daysText = `Your subscription will be suspended in ${daysRemaining} days`
			} else if (daysRemaining === 1) {
				daysText = `Your subscription will be suspended in ${daysRemaining} day`
			}

			if (timeRemaining < 1 && Boolean(results.superAdmin) === false) {
				_accountSuspended = true
				daysText = 'Your subscription has been suspended for non-payment'
				history.push('/subscription-ended')
			}

			// console.log('paymentDue: _accountSuspended', _accountSuspended)
			// console.log('paymentDue: daysText', daysText)
			// console.log('paymentDue: daysRemaining', daysRemaining)
			// console.log('paymentDue: timeRemaining', timeRemaining)
			// console.log('paymentDue: results.client.paymentDue', results.client.paymentDue)
			// console.log('paymentDue: localStorage.defaultModule', localStorage.defaultModule)

			setPaymentDueDateText(daysText)
			setPaymentDueDate(results.client.paymentDueDate)
			setPaymentDue(true)
			setAccountSuspended(_accountSuspended)

			if (timeRemaining < 1 && Boolean(results.superAdmin) === false) {
				history.push('/subscription-ended')
			}
		} else {
			setPaymentDueDateText(null)
			setPaymentDueDate(null)
			setPaymentDue(false)
			setAccountSuspended(false)
		}
	};

	async function getClientSettings(client, settingsDoc, level) {
		const objList = !_.isEmpty(settingsDoc) && Object.keys(settingsDoc).filter(function (e) { return _.isObject(settingsDoc[e]) })
		let settingslevel = []
		settingslevel.push({ name: 'groups', value: client.group, level: ['individual', 'group'] })
		settingslevel.push({ name: 'regions', value: client.region, level: ['individual', 'region', 'group'] })
		settingslevel.push({ name: 'clients', value: client.id, level: ['individual'] })

		let refSortOptions = ''
		if (level === 'oem') {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/enquiryOptions/${client.settingsID}`).get()
		}
		else if (level?.includes('region')) {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/regions/${client.region}/enquiryOptions/${client.region}`).get()
		}
		else if (level === 'group') {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/groups/${client.group}/enquiryOptions/${client.group}`).get()
		}
		else {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${client.id}/enquiryOptions/${client.id}`).get()
		}

		let tradeinSortOptions = ''
		if (level === 'oem') {
			tradeinSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/tradeinOptions/${client.settingsID}`).get()
		}
		else if (level?.includes('region')) {
			tradeinSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/regions/${client.region}/tradeinOptions/${client.region}`).get()
		}
		else if (level === 'group') {
			tradeinSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/groups/${client.group}/tradeinOptions/${client.group}`).get()
		}
		else {
			tradeinSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${client.id}/tradeinOptions/${client.id}`).get()
		}

		const promises = []
		promises.push(refSortOptions);
		promises.push(tradeinSortOptions);
		settingslevel.forEach(_rec => {
			if (_rec.value && _rec.level.indexOf(level) >= 0)
				promises.push(window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_rec.name}/${_rec.value}`).get())
		})

		const snapshots = await Promise.all(promises)
		for (let i = 2; i < promises.length; i++) {
			const dataDoc = snapshots[i]?.data()
			if (snapshots[i]?.exists) {

				objList.forEach((key) => {
					if (_.isArray(dataDoc[key])) {
						dataDoc[key].forEach((lookups) => {
							settingsDoc[key] = [
								...settingsDoc[key],
								lookups
							]
						})
					}
					else if (dataDoc[key]) {
						allModules.forEach(module => {
							if (dataDoc[key][module]) {
								if (module === 'pipeline' || module === 'status' || module === 'financeStatus' || module === 'afterMarketStatus') {
									dataDoc[key][module].forEach(r => {
										if (settingsDoc[key][module] && settingsDoc[key][module].some(item => item.name === r.name)) {
											allModules.forEach(m => {
												if (settingsDoc[key][module].find(item => item.name === r.name)[m] && !_.isEmpty(r[m])) {
													settingsDoc[key][module].find(item => item.name === r.name)[m] += ',' + r[m];
												}
												else if (!_.isEmpty(r[m])) {
													settingsDoc[key][module].find(item => item.name === r.name)[m] = r[m]
												}
											})
										}
										else if (settingsDoc[key][module]) {
											settingsDoc[key][module].push(r)
										}
										else {
											settingsDoc[key][module] = [r]
										}
									})
								}
								else if (settingsDoc[key][module]) {
									settingsDoc[key][module] += ',' + dataDoc[key][module];
								}
								else {
									settingsDoc[key][module] = dataDoc[key][module]
								}
							}
						})
					}
				})
			}
		}
		try {
			clientLookups.filter(a => a.sort === true).forEach(rec => {
				if (_.isArray(settingsDoc[rec.value])) {
					settingsDoc[rec.value] = [..._.sortBy(settingsDoc[rec.value].filter(a => !Boolean(a.autoSelect)), 'sortNo', 'asc'),
					..._.sortBy(settingsDoc[rec.value].filter(a => Boolean(a.autoSelect)), 'sortNo', 'asc')]
				}
			})
		} catch { }
		if (refSortOptions) {
			const docSortOptions = snapshots[0]?.data();
			if (docSortOptions) {
				let sortOptions = docSortOptions.options ? docSortOptions.options : [];
				let sortPipelines = docSortOptions.pipelines ? docSortOptions.pipelines : [];
				settingsDoc['sortEnquiryOptions'] = docSortOptions.options ? docSortOptions.options : [];
				if (!_.isEmpty(sortOptions)) {
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.enquiry) {
						let _sFields = settingsDoc.mandatoryFields.enquiry.split(',')
						_sFields.forEach(item => {
							if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
								settingsDoc.mandatoryFields.enquiry = settingsDoc.mandatoryFields.enquiry.split(',').filter(r => r !== item).join(',')
							}
						});
					}
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.pipeline) {
						settingsDoc.mandatoryFields.pipeline.forEach(pipeline => {
							if (pipeline.enquiry) {
								let _sFields = pipeline.enquiry.split(',')
								_sFields.forEach(item => {
									if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
										settingsDoc.mandatoryFields.pipeline = settingsDoc.mandatoryFields.pipeline.map(rec => {
											return rec.name === pipeline.name ?
												{
													...rec,
													enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
												} :
												{ ...rec }
										})
									}
								});
							}
						})
					}
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.status) {
						settingsDoc.mandatoryFields.status.forEach(status => {
							if (status.enquiry) {
								let _sFields = status.enquiry.split(',')
								_sFields.forEach(item => {
									if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
										settingsDoc.mandatoryFields.status = settingsDoc.mandatoryFields.status.map(rec => {
											return rec.name === status.name ?
												{
													...rec,
													enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
												} :
												{ ...rec }
										})
									}
								});
							}
						})
					}
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.financeStatus) {
						settingsDoc.mandatoryFields.financeStatus.forEach(status => {
							if (status.enquiry) {
								let _sFields = status.enquiry.split(',')
								_sFields.forEach(item => {
									if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
										settingsDoc.mandatoryFields.status = settingsDoc.mandatoryFields.status.map(rec => {
											return rec.name === status.name ?
												{
													...rec,
													enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
												} :
												{ ...rec }
										})
									}
								});
							}
						})
					}
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.afterMarketStatus) {
						settingsDoc.mandatoryFields.afterMarketStatus.forEach(status => {
							if (status.enquiry) {
								let _sFields = status.enquiry.split(',')
								_sFields.forEach(item => {
									if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
										settingsDoc.mandatoryFields.status = settingsDoc.mandatoryFields.status.map(rec => {
											return rec.name === status.name ?
												{
													...rec,
													enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
												} :
												{ ...rec }
										})
									}
								});
							}
						})
					}
					if (!_.isEmpty(settingsDoc.enquiryOptionsDF) && settingsDoc.enquiryOptionsDF.length > 0) {
						settingsDoc = {
							...settingsDoc,
							enquiryOptionsDF: settingsDoc.enquiryOptionsDF.map(rec => {
								return {
									...rec,
									active: sortOptions.some(a => a === rec.value)
								}
							})
						}
					}
				}
				if (!_.isEmpty(sortPipelines)) {
					let _pipelines = [];
					_.uniq(sortPipelines).forEach(rec => {
						if (_.find(settingsDoc.pipelines, { value: rec }))
							_pipelines.push(_.find(settingsDoc.pipelines, { value: rec }))
					})
					settingsDoc['pipelines'] = _pipelines
				}
			}
		}

		if (tradeinSortOptions) {
			const docSortOptions = snapshots[1]?.data();
			if (docSortOptions) {
				let sortOptions = docSortOptions.options ? docSortOptions.options : [];
				let sortPipelines = docSortOptions.pipelines ? docSortOptions.pipelines : [];
				settingsDoc['sortTradeinOptions'] = docSortOptions.options ? docSortOptions.options : [];
				if (!_.isEmpty(sortOptions)) {
					if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.tradein) {
						let _sFields = settingsDoc.mandatoryFields.tradein.split(',')
						_sFields.forEach(item => {
							if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
								settingsDoc.mandatoryFields.tradein = settingsDoc.mandatoryFields.tradein.split(',').filter(r => r !== item).join(',')
							}
						});
					}
					if (!_.isEmpty(settingsDoc.tradeinOptionsDF) && settingsDoc.tradeinOptionsDF.length > 0) {
						settingsDoc = {
							...settingsDoc,
							tradeinOptionsDF: settingsDoc.tradeinOptionsDF.map(rec => {
								return {
									...rec,
									active: sortOptions.some(a => a === rec.value)
								}
							})
						}
					}
				}
			}
		}


		if (!_.isEmpty(settingsDoc.pipelines)) {
			settingsDoc.pipelines = settingsDoc.pipelines.map(rec => {
				return {
					...rec,
					statusSettings: rec.statusSettings ?
						rec.statusSettings.filter(a => settingsDoc.pipelines.some(b => b.value === a.pipeline))
						: []
				}
			})
		}
		if (!_.isEmpty(settingsDoc.enquiryStatus) && settingsDoc.enquiryStatus.length > 0) {
			let sortEnqSts = [
				...settingsDoc.enquiryStatus.filter(a => a.value === 'open'),
				...settingsDoc.enquiryStatus.filter(a => !Boolean(a.afterSold) && !Boolean(a.default)),
				...settingsDoc.enquiryStatus.filter(a => Boolean(a.afterSold) && !Boolean(a.default)),
				...settingsDoc.enquiryStatus.filter(a => Boolean(a.default) && a.value !== 'open')
			]
			if (sortEnqSts.some(a => a.sortNo > 0)) {
				sortEnqSts = _.orderBy(sortEnqSts, ['sortNo'], ['asc']);
			}
			settingsDoc = {
				...settingsDoc,
				enquiryStatus: sortEnqSts.map(rec => {
					return rec.value === 'open' ? {
						...rec,
						dynamic: true
					} : {
						...rec,
						dynamic: !Boolean(rec.default)
					}
				})
			}
		}
		else {
			settingsDoc = {
				...settingsDoc,
				enquiryStatus: defaultenquiryStatus
			}
		}
		if (_.isEmpty(settingsDoc.activityTypes)) {
			settingsDoc = {
				...settingsDoc,
				activityTypes: defaultActivityTypes
			}
		}
		if (_.isEmpty(settingsDoc.enquiryLabels)) {
			settingsDoc = {
				...settingsDoc,
				enquiryLabels: defaultenquiryLabels
			}
		}
		if (_.isEmpty(settingsDoc.filesCategory)) {
			settingsDoc = {
				...settingsDoc,
				filesCategory: defaultFileCategories
			}
		}
		if (_.isEmpty(settingsDoc.afterMarketStatus)) {
			settingsDoc = {
				...settingsDoc,
				afterMarketStatus: defaultaftermarketStatus
			}
		}
		if (_.isEmpty(settingsDoc.contactTypes)) {
			settingsDoc = {
				...settingsDoc,
				contactTypes: defaultcontactTypes
			}
		}
		if (!_.isEmpty(settingsDoc.enquiryStatus)) {
			let _stockStatus = settingsDoc?.stockStatus?.length ? settingsDoc.stockStatus.map(r => { return { ...r, badge: _.find(defaultStockStatus, { value: r.value })?.badge } }) : defaultStockStatus
			_stockStatus.forEach(data => {
				if (data.value === 'sold') {
					data.name = _.find(settingsDoc.enquiryStatus, { value: 'won' }) ? _.find(settingsDoc.enquiryStatus, { value: 'won' }).name : 'Won'
				}
				else if (data.value === 'delivered') {
					data.name = _.find(settingsDoc.enquiryStatus, { value: 'delivered' }) ? _.find(settingsDoc.enquiryStatus, { value: 'delivered' }).name : 'Delivered'
				}
			})
			settingsDoc = {
				...settingsDoc,
				stockStatus: _stockStatus
			}

		}
		//change activity icon for motorcycle
		let _categories = CommonHelper.getDynamicCategories();
		if (!_.isEmpty(settingsDoc.activityTypes) && client.category && _categories.includes(client.category)) {
			settingsDoc = {
				...settingsDoc,
				activityTypes: settingsDoc.activityTypes.map(rec => {
					return ['testdrive', 'delivery'].includes(rec.value) ? {
						...rec,
						icon: `activity-${rec.value}-${client.category.toLowerCase()}`
					} : {
						...rec
					}
				})
			}
		}
		//copy lost manadatory fields to propsect lost
		if (!_.isEmpty(settingsDoc.mandatoryFields)
			&& !_.isEmpty(settingsDoc.mandatoryFields.status)
			&& settingsDoc.mandatoryFields.status.some(a => a.name === 'lost')
			&& !settingsDoc.mandatoryFields.status.some(a => a.name === 'prospectLost')
		) {
			settingsDoc.mandatoryFields.status.push({
				..._.find(settingsDoc.mandatoryFields.status, { name: 'lost' }),
				name: 'prospectLost'
			})
		}
		//END

		//uniq by static enquiry options
		if (!_.isEmpty(settingsDoc.enquiryOptionsStatic)) {
			settingsDoc = {
				...settingsDoc,
				enquiryOptionsStatic: _.uniqBy(settingsDoc.enquiryOptionsStatic, 'value')
			}
		}
		//uniq by static stock options
		if (!_.isEmpty(settingsDoc.stockOptionsStatic)) {
			settingsDoc = {
				...settingsDoc,
				stockOptionsStatic: _.uniqBy(settingsDoc.stockOptionsStatic, 'value')
			}
		}
		return settingsDoc;
	}

	async function getServiceSettings(client, level, _module) {
		//make dynamic serviceModules & serviceLookups line 2600/2556 thaha

		let settingslevel = []
		settingslevel.push({ name: 'groups', value: client.group, level: ['individual', 'group'] })
		settingslevel.push({ name: 'regions', value: client.region, level: ['individual', 'region', 'group'] })
		settingslevel.push({ name: 'clients', value: client.id, level: ['individual'] })
		let refSortOptions = ''
		if (level === 'oem') {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_module}/${client.settingsID}/enquiryOptions/${client.settingsID}`)
		}
		else if (level?.includes('region')) {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/regions/${client.region}/${_module}/${client.region}/enquiryOptions/${client.region}`)
		}
		else if (level === 'group') {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/groups/${client.group}/${_module}/${client.group}/enquiryOptions/${client.group}`)
		}
		else {
			refSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${client.id}/${_module}/${client.id}/enquiryOptions/${client.id}`)
		}

		let refAttendeeSortOptions = ''
		if (level === 'oem') {
			refAttendeeSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_module}/${client.settingsID}/attendeeOptions/${client.settingsID}`)
		}
		else if (level?.includes('region')) {
			refAttendeeSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/regions/${client.region}/${_module}/${client.region}/attendeeOptions/${client.region}`)
		}
		else if (level === 'group') {
			refAttendeeSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/groups/${client.group}/${_module}/${client.group}/attendeeOptions/${client.group}`)
		}
		else {
			refAttendeeSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${client.id}/${_module}/${client.id}/attendeeOptions/${client.id}`)
		}

		const reffleetSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_module}/${client.settingsID}/fleetOptions/${client.settingsID}`);


		const promises = []
		promises.push(window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_module}/${client.settingsID}`).get())
		promises.push(refSortOptions.get())
		promises.push(refAttendeeSortOptions.get())
		promises.push(reffleetSortOptions.get())
		settingslevel.forEach(_rec => {
			if (_rec.value && _rec.level.indexOf(level) >= 0)
				promises.push(window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_rec.name}/${_rec.value}/${_module}/${_rec.value}`).get())
		})
		const snapshots = await Promise.all(promises)

		let settingsDoc = snapshots[0].exists ? snapshots[0].data() : {};
		(_module === 'services' ? serviceLookups : _module === 'eventsPro' ? eventsProLookups : _module === 'fleetPro' ? fleetProLookups : tradeinProLookups).forEach(rec => {
			if (_.isEmpty(settingsDoc[rec.value]))
				settingsDoc = {
					...settingsDoc,
					[rec.value]: []
				}
		})
		if (_.isEmpty(settingsDoc['mandatoryFields'])) {
			settingsDoc = {
				...settingsDoc,
				['mandatoryFields']: {}
			}
		}
		if (_.isEmpty(settingsDoc['pipelines'])) {
			settingsDoc = {
				...settingsDoc,
				['pipelines']: []
			}
		}
		if (_.isEmpty(settingsDoc['enquiryOptionsDF'])) {
			settingsDoc = {
				...settingsDoc,
				['enquiryOptionsDF']: []
			}
		}
		if (_.isEmpty(settingsDoc['tradeinOptionsDF'])) {
			settingsDoc = {
				...settingsDoc,
				['tradeinOptionsDF']: []
			}
		}
		if (_.isEmpty(settingsDoc['attendeesDF'])) {
			settingsDoc = {
				...settingsDoc,
				['attendeesDF']: []
			}
		}
		const objList = !_.isEmpty(settingsDoc) && Object.keys(settingsDoc).filter(function (e) { return _.isObject(settingsDoc[e]) })
		for (let i = 4; i < promises.length; i++) {
			const dataDoc = snapshots[i]?.data()
			if (snapshots[i]?.exists) {
				objList.forEach((key) => {
					if (_.isArray(dataDoc[key])) {
						dataDoc[key].forEach((lookups) => {
							settingsDoc[key] = [
								...settingsDoc[key],
								lookups
							]
						})
					}
					else if (dataDoc[key]) {
						(_module === 'services' ? serviceModules : appraisalModules).forEach(module => {
							if (dataDoc[key][module]) {
								if (module === 'pipeline' || module === 'status') {
									dataDoc[key][module].forEach(r => {
										if (settingsDoc[key][module] && settingsDoc[key][module].some(item => item.name === r.name)) {
											(_module === 'services' ? serviceModules : appraisalModules).forEach(m => {
												if (settingsDoc[key][module].find(item => item.name === r.name)[m] && !_.isEmpty(r[m])) {
													settingsDoc[key][module].find(item => item.name === r.name)[m] += ',' + r[m];
												}
												else if (!_.isEmpty(r[m])) {
													settingsDoc[key][module].find(item => item.name === r.name)[m] = r[m]
												}
											})
										}
										else if (settingsDoc[key][module]) {
											settingsDoc[key][module].push(r)
										}
										else {
											settingsDoc[key][module] = [r]
										}
									})
								}
								else if (settingsDoc[key][module]) {
									settingsDoc[key][module] += ',' + dataDoc[key][module];
								}
								else {
									settingsDoc[key][module] = dataDoc[key][module]
								}
							}
						})
					}
				})
			}
		}


		if (refSortOptions) {
			if (snapshots[1].exists) {
				let sortPipelines = snapshots[1].data().pipelines ? snapshots[1].data().pipelines : []
				let sortOptions = snapshots[1].data().options ? snapshots[1].data().options : [];
				settingsDoc['sortEnquiryOptions'] = snapshots[1].data().options ? snapshots[1].data().options : [];
				if (!_.isEmpty(sortPipelines)) {
					let _pipelines = [];
					_.uniq(sortPipelines).forEach(rec => {

						if (_.find(settingsDoc.pipelines, { value: rec }))
							_pipelines.push(_.find(settingsDoc.pipelines, { value: rec }))
					})


					settingsDoc['pipelines'] = _pipelines
				}
				// if (!_.isEmpty(sortOptions)) {
				// 	if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.enquiry) {
				// 		let _sFields = settingsDoc.mandatoryFields.enquiry.split(',')
				// 		_sFields.forEach(item => {
				// 			if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
				// 				settingsDoc.mandatoryFields.enquiry = settingsDoc.mandatoryFields.enquiry.split(',').filter(r => r !== item).join(',')
				// 			}
				// 		});
				// 	}
				// 	if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.pipeline) {
				// 		settingsDoc.mandatoryFields.pipeline.forEach(pipeline => {
				// 			if (pipeline.enquiry) {
				// 				let _sFields = pipeline.enquiry.split(',')
				// 				_sFields.forEach(item => {
				// 					if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
				// 						settingsDoc.mandatoryFields.pipeline = settingsDoc.mandatoryFields.pipeline.map(rec => {
				// 							return rec.name === pipeline.name ?
				// 								{
				// 									...rec,
				// 									enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
				// 								} :
				// 								{ ...rec }
				// 						})
				// 					}
				// 				});
				// 			}
				// 		})
				// 	}
				// 	if (settingsDoc.mandatoryFields && settingsDoc.mandatoryFields.status) {
				// 		settingsDoc.mandatoryFields.status.forEach(status => {
				// 			if (status.enquiry) {
				// 				let _sFields = status.enquiry.split(',')
				// 				_sFields.forEach(item => {
				// 					if (_.find(enqiuryOptions, { value: item }) && !sortOptions.some(e => e === item)) {
				// 						settingsDoc.mandatoryFields.status = settingsDoc.mandatoryFields.status.map(rec => {
				// 							return rec.name === status.name ?
				// 								{
				// 									...rec,
				// 									enquiry: rec.enquiry.split(',').filter(r => r !== item).join(',')
				// 								} :
				// 								{ ...rec }
				// 						})
				// 					}
				// 				});
				// 			}
				// 		})
				// 	}
				// }
			}
		}
		if (_module === 'tradeinPro') {
			if (_.isEmpty(settingsDoc.tradeinProStatus)) {
				settingsDoc = {
					...settingsDoc,
					tradeinProStatus: defaulttipStatuses
				}
			}
			if ((_.isEmpty(client.moduleSettings)
				|| _.isEmpty(client.moduleSettings.tradeinPro)
				|| client.moduleSettings.tradeinPro.enabled === false
				|| client.moduleSettings.tradeinPro.active === false)) {
				settingsDoc = {
					...settingsDoc,
					tradeinProStatus: defaulttipStatuses
				}
			}
		}
		if (_module === 'services') {
			if (_.isEmpty(settingsDoc.serviceStatus)) {
				settingsDoc = {
					...settingsDoc,
					serviceStatus: defaultserviceStatuses
				}
			}
			if (_.isEmpty(settingsDoc.technicianStatus)) {
				settingsDoc = {
					...settingsDoc,
					technicianStatus: defaultTechnicianStatuses
				}
			}
			if ((_.isEmpty(client.moduleSettings)
				|| _.isEmpty(client.moduleSettings.service)
				|| client.moduleSettings.service.enabled === false
				|| client.moduleSettings.service.active === false)) {
				settingsDoc = {
					...settingsDoc,
					serviceStatus: defaultserviceStatuses,
					technicianStatus: defaultTechnicianStatuses
				}
			}
		}
		if (_module === 'eventsPro') {
			if (_.isEmpty(settingsDoc.eventsProStatus)) {
				settingsDoc = {
					...settingsDoc,
					eventsProStatus: defaulteventStatuses
				}
			}
			if ((_.isEmpty(client.moduleSettings)
				|| _.isEmpty(client.moduleSettings.eventsPro)
				|| client.moduleSettings.eventsPro.enabled === false
				|| client.moduleSettings.eventsPro.active === false)) {
				settingsDoc = {
					...settingsDoc,
					eventsProStatus: defaulteventStatuses
				}
			}

			if (refAttendeeSortOptions) {
				if (snapshots[2].exists) {
					let sortPipelines = snapshots[2].data().pipelines ? snapshots[2].data().pipelines : []
					let sortOptions = snapshots[2].data().options ? snapshots[2].data().options : [];
					settingsDoc['sortAttendeeOptions'] = snapshots[2].data().options ? snapshots[2].data().options : [];
					if (!_.isEmpty(sortPipelines)) {
						let _pipelines = [];
						_.uniq(sortPipelines).forEach(rec => {

							if (_.find(settingsDoc.pipelines, { value: rec }))
								_pipelines.push(_.find(settingsDoc.pipelines, { value: rec }))
						})


						settingsDoc['pipelines'] = _pipelines
					}
				}
			}
		}
		if (_module === 'fleetPro') {
			if (_.isEmpty(settingsDoc.fleetProStatus)) {
				settingsDoc = {
					...settingsDoc,
					fleetProStatus: defaultfleetStatuses
				}
			}
			if (_.isEmpty(settingsDoc.category)) {
				settingsDoc = {
					...settingsDoc,
					category: defaultfleetCategory
				}
			}
			if ((_.isEmpty(client.moduleSettings)
				|| _.isEmpty(client.moduleSettings.fleetPro)
				|| client.moduleSettings.fleetPro.enabled === false
				|| client.moduleSettings.fleetPro.active === false)) {
				settingsDoc = {
					...settingsDoc,
					fleetProStatus: defaultfleetStatuses
				}
			}

			//let reffleetSortOptions = ''
			//if (level === 'oem') {
			//reffleetSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/${_module}/${client.settingsID}/fleetOptions/${client.settingsID}`)
			//}
			//else if (level?.includes('region')) {
			//	reffleetSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/regions/${client.region}/${_module}/${client.region}/fleetOptions/${client.region}`)
			//}
			//else if (level === 'group') {
			//	reffleetSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/groups/${client.group}/${_module}/${client.group}/fleetOptions/${client.group}`)
			//}
			//else {
			//	reffleetSortOptions = window.firebase.firestore().doc(`clientSettings/${client.settingsID}/clients/${client.id}/${_module}/${client.id}/fleetOptions/${client.id}`)
			//}

			if (reffleetSortOptions) {
				if (snapshots[3].exists) {
					settingsDoc['sortFleetOptions'] = snapshots[3].data().options ? snapshots[3].data().options : [];
				}
			}
		}
		return settingsDoc;
	}

	function getWholesalerSettings(settingsDoc) {
		let _allpipelines = [];
		_allpipelines.push({
			active: true,
			default: true,
			modules: {},
			value: 'Appraisal',
			name: 'Appraisal',
			stages: [{
				name: 'Pending',
				value: 'pending'
			},
			{
				name: 'Completed',
				value: 'completed'
			},
			{
				name: 'Won',
				value: 'won'
			},
			{
				name: 'Lost',
				value: 'lost'
			}]
		})
		settingsDoc = {
			...settingsDoc,
			pipelines: _allpipelines,
			wholesalerPipelines: _allpipelines,
			name: 'Wholesaler'
		}
		return settingsDoc;
	}

	const handlePipelineChange = useCallback(
		val => {
			let _pipelines = [...pipeline];
			_pipelines.map(el => {
				el.default = false;
			});
			_pipelines.filter(e => e.value === val)[0].default = true;
			setPipeline(_pipelines);
		},
		[pipeline],
	);

	const handleClientChange = async val => {
		try {
			await window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}`)
				.set({ defaultClientID: val }, { merge: true });
			getdealerSettings(true);
		} catch (error) {
			console.log(error);
		}
	};

	const updateDealerSettings = async (name, value) => {
		//console.log('updateDealerSettings', name, value)
		if (name === 'rolePermissions') {
			let _pipelines = dealersettings.allPipelines;
			if (_.isArray(value.pipeline)) {
				_pipelines = _.filter(_pipelines, (v) => _.indexOf(value.pipeline, v.value) >= 0)
			}
			if (_pipelines.some(item => item.value === 'LeadsBucket') && _pipelines.length > 1) {
				_pipelines[1].default = true
			}
			else if (_pipelines.length > 0) {
				_pipelines[0].default = true
			}

			setDealersettings({
				...dealersettings,
				[name]: value,
				['client']: {
					...dealersettings.client,
					settings: {
						...dealersettings.client.settings,
						'pipelines': _pipelines
					}
				},
			});
			setPipeline(_pipelines);
		} else if (name === 'workingServiceID') {
			setDealersettings({
				...dealersettings,
				[name]: value
			});
		} else if (name === 'servicePipeline') {
			setDealersettings({
				...dealersettings,
				[name]: value,
				['client']: {
					...dealersettings.client,
					services: {
						...dealersettings.client.services,
						'pipelines': dealersettings.client.services.pipelines.map(rec => {
							return rec.value === value ? {
								...rec,
								default: true
							} : {
								...rec,
								default: false
							}
						})
					}
				},
			});
		}
		else if (name === 'tradeinProPipeline') {
			setDealersettings({
				...dealersettings,
				[name]: value,
				['client']: {
					...dealersettings.client,
					tradeinPro: {
						...dealersettings.client.tradeinPro,
						'pipelines': dealersettings.client.tradeinPro.pipelines.map(rec => {
							return rec.value === value ? {
								...rec,
								default: true
							} : {
								...rec,
								default: false
							}
						})
					}
				},
			});
		}
		else if (name === 'eventsProPipeline') {
			setDealersettings({
				...dealersettings,
				[name]: value,
				['client']: {
					...dealersettings.client,
					eventsPro: {
						...dealersettings.client.eventsPro,
						'pipelines': dealersettings.client.eventsPro.pipelines.map(rec => {
							return rec.value === value ? {
								...rec,
								default: true
							} : {
								...rec,
								default: false
							}
						})
					}
				},
			});
		}
		else if (name === 'moduleSettings') {
			setDealersettings({
				...dealersettings,
				[name]: value,
				['client']: {
					...dealersettings.client,
					['moduleSettings']: value
				},
				['clients']: dealersettings.clients ? dealersettings.clients.map(rec => {
					return rec.id === dealersettings.client.id ? {
						...rec,
						['moduleSettings']: value
					} : {
						...rec
					}
				}) : [{
					...dealersettings.client,
					['moduleSettings']: value
				}]
			});
		} else if (name === 'nylasAccountData') {
			// deprecated for nylas_v3 by SaMeeR
			// setDealersettings({
			// 	...dealersettings,
			// 	[name]: value,
			// 	nylas: value,
			// 	nylasAccessToken: value ? value.token : null
			// });
		} else if (name === 'nylasV3AccountData') {
			setDealersettings({
				...dealersettings,
				['nylasAccountData']: value,
				nylas_v3: value,
				//nylasAccessToken: value ? value.token : null
			});
		} else if (name === 'clients') {
			setDealersettings({
				...dealersettings,
				[name]: dealersettings.clients.some(e => e.id === value.documentID) ?
					dealersettings.clients.map(rec => {
						return rec.id === value.documentID ? {
							...value
						} : {
							...rec
						}
					}) : [...dealersettings.clients, value],
				['allClients']: dealersettings.clients.some(e => e.id === value.documentID) ?
					dealersettings.clients.map(rec => {
						return rec.id === value.documentID ? {
							...value
						} : {
							...rec
						}
					}) : [...dealersettings.clients, value]
			});
		} else if (name === 'salesPerformance') {
			setDealersettings({
				...dealersettings,
				client: {
					...dealersettings.client,
					[name]: value
				}
			});
		} else if (name === 'language') {
			localStorage.setItem('i18nextLng', value);
			const responseLocales = await fetch(dealersettings.baseURL + `/locales/${value}.json`);
			const localesjson = await responseLocales.json();
			setDealersettings({
				...dealersettings,
				[name]: value,
				['locales']: localesjson
			});
		} else if (name === 'gdpr') {
			setDealersettings({
				...dealersettings,
				client: {
					...dealersettings.client,
					gdpr: {
						...value,
					}
				}
			});
		}
		else if (name === 'projectId') {
			setDealersettings({
				...dealersettings,
				client: {
					...dealersettings.client,
					[name]: value,
				},
			});
		}
		else {
			setDealersettings({
				...dealersettings,
				[name]: value,
			});
		}
	};

	const updatePipelineSettings = (pipelines) => {
		var _pipelines = pipelines.filter(a => a.active === true)
		if (pipelines.some(item => item.value === 'LeadsBucket')) {
			pipelines.filter(item => item.value === 'LeadsBucket')[0].stages = [
				{ name: `${dealersettings.client && dealersettings.client.settings && !_.isEmpty(dealersettings.client.settings.inboundStageName) ? dealersettings.client.settings.inboundStageName : 'Inbound Lead'}`, value: 'InboundLead' }
				, ...pipelines.filter(item => item.value === 'LeadsBucket')[0].stages
			]

		}
		if (!_.isEmpty(dealersettings.rolePermissions) && _.isArray(dealersettings.rolePermissions.pipeline)) {
			_pipelines = _.filter(_pipelines, (v) => _.indexOf(dealersettings.rolePermissions.pipeline, v.value) >= 0)
		}
		if (_pipelines.some(item => item.value === 'LeadsBucket') && _pipelines.length > 1) {
			_pipelines[1].default = true
		}
		else if (_pipelines.length > 0) {
			_pipelines[0].default = true
		}
		setDealersettings({
			...dealersettings,
			['allPipelines']: pipelines.filter(a => a.active === true),
			['client']: {
				...dealersettings.client,
				settings: {
					...dealersettings.client.settings,
					'pipelines': _pipelines
				}
			},
		});
		setPipeline(_pipelines);
	};

	const handleModuleChange = async (val, route) => {
		await window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}/userClients/${currentClient}`)
			.set({ defaultModule: val }, { merge: true });
		let _modules = [...modules];
		_modules.map(el => {
			el.default = false;
		});
		_modules.filter(e => e.name === val)[0].default = true;
		setShowselectmodules(false);
		setModules(_modules);
		setClientModule(val);
		localStorage.defaultModule = val;
		if (!_.isEmpty(localStorage.objNotify)) {
			handleNotifyClick(
				JSON.parse(localStorage.objNotify).id,
				JSON.parse(localStorage.objNotify).type,
				null,
				null,
				null,
				JSON.parse(localStorage.objNotify),
				dealersettings
			);
		}
		// var _allpipelines = dealersettings.allPipelines.filter(a => a.value !== 'afterSales');
		// if (val === 'afterSales') {
		// 	_allpipelines.push({
		// 		active: true,
		// 		modules: {
		// 			cafe: true,
		// 			contact: true,
		// 			files: true,
		// 			requirement: true,
		// 			finance: false,
		// 			testDrive: false,
		// 			tradeIn: false
		// 		},
		// 		value: 'afterSales',
		// 		name: 'After Sales',
		// 		stages: [{
		// 			name: 'New Enquiry',
		// 			value: 'new-enquiry'
		// 		},
		// 		{
		// 			name: 'In Progress',
		// 			value: 'in-progress'
		// 		},
		// 		{
		// 			name: 'Qualified',
		// 			value: 'qualified'
		// 		},
		// 		{
		// 			name: 'Appoitment Scheduled',
		// 			value: 'appoitment-scheduled'
		// 		},
		// 		{
		// 			name: 'Won',
		// 			value: 'won'
		// 		},
		// 		{
		// 			name: 'Lost',
		// 			value: 'lost'
		// 		}]
		// 	})
		// }
		// setDealersettings({
		// 	...dealersettings,
		// 	['allPipelines']: _allpipelines
		// });
		if ((val === 'enquiry' || val === 'service') && !_.isEmpty(localStorage.objSales)) {
			history.push(`/${JSON.parse(localStorage.objSales).type}/details/${JSON.parse(localStorage.objSales).id}`)
		}
		else if (route) {
			localStorage.removeItem("objSales");
			history.push(route);
		}

	};

	const fnmessaging = () => {
		if (!messaging)
			return

		if (_.isEmpty(localStorage.fcmtoken)) {
			try {
				Notification.requestPermission().then(async (permission) => {
					if (permission === 'granted') {
						await messaging
							.getToken()
							.then(currentToken => {
								// console.log(currentToken);
								localStorage.setItem('fcmtoken', currentToken);
								setDeviceToken(currentToken);
							})
							.catch(err => {
								console.log('Unable to get permission to notify.', err);
							});
						console.log('Notification permission granted.');
						// TODO(developer): Retrieve a registration token for use with FCM.
						// ...
					} else {
						console.log('Unable to get permission to notify.');
					}
				}).catch(err => {
					console.log('Unable to get permission to notify.', err);
				});
			}
			catch (err) {
				console.log('Unable to get permission to notify.', err);
			}
		}
		else {
			setDeviceToken(localStorage.fcmtoken);
		}
		// messaging.onTokenRefresh(fcmToken => {
		// 	// Process your token as required
		// 	// console.log('Updated Token=' + fcmToken);
		// 	localStorage.setItem('fcmtoken', fcmToken);
		// 	setDeviceToken(fcmToken);
		// });
		messaging.onMessage(payload => {
			//console.log('pay-load', payload);

			if (payload.data.type === 'settings') {
				//handleSettingsUpdate(payload.data.clientID)
			}
			if (payload.data.type === 'email') {
				setTheardID(payload.data.recordID)
				// if (!_.isEmpty(payload.data.nylastoken))
				// 	getUnreadCount(payload.data.nylastoken, null, payload.data.last_message_after, payload.data.last_message_before)
			}
			// if (payload.data.type === 'pendingLost') {
			// 	history.push(`/enquiry/details/${payload.data.recordID}`)
			// }

			if (payload.data.type === 'kitchen' && payload.notification.title === "New Order" && localStorage.isCafeOrderNotifySound === 'true') {
				const playSound = (url) => {
					const audio = new Audio(url);
					audio.play();
				}
				playSound(kitchen_order)
			}

			toast.notify(
				({ onClose }) => (
					<NotifyToast
						payload={payload}
						onClose={onClose}
						handleNotifyClick={handleNotifyClick}
						currentClient={currentClient}
						dealersettings={dealersettings}
					/>
				),
				{
					duration: 4000,
					position: 'top-right',
				},
			);

		});
	};

	const removeDevicetoken = () => {
		setDeviceToken('');
	};

	const notifyRead = (notifyID) => {
		if (notifyID) window.firebase.firestore().doc(`/notifications/${notifyID}`).set({ isRead: true }, { merge: true });

	}
	const handleNotifyClick = useCallback((id, type, notifyID, enquiryId, contactId, _objData, _dealersettings) => {
		//console.log('handleNotifyClick', id, type, notifyID, enquiryId, contactId);
		localStorage.removeItem('objNotify');
		if (!_.isEmpty(notifyID)) {
			notifyRead(notifyID);

			if (['aircallContact', 'aircallMissed', 'callContact', 'callMissed', 'smsMissed'].includes(type)) {
				//setNotificationId(notifyID);
				setShowAircallNotifyModel({ show: true, notifyID: notifyID, recordID: id });
			}
		}

		if (!_.isEmpty(id)) {
			if (['activity', 'tradeinProactivity', 'serviceactivity', 'financeactivity', 'afterMarketactivity'].includes(type)) {
				//setRecordId(id);
				setShowActivityModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'serviceRequest') {
				//setRecordId(id);
				setShowServiceRequestModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'tradeinProRequest') {
				//setRecordId(id);
				setShowTradeinProRequestModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'engageRequest') {
				//setRecordId(id);
				setShowEngageModel({ show: true, notifyID: notifyID, recordID: id });
				setShowEngageModelClose(false);
			} else if (type === 'callLogs') {
				//setRecordId(id);
				setShowCallLogModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'enquiry') {
				//setRecordId(id);
				setShowAssignLeadModel({ show: true, notifyID: notifyID, recordID: id });

			} else if (type === 'finance') {
				//setRecordId(id);
				setShowAssignLeadModel({ show: true, notifyID: notifyID, recordID: id });
				setTitleLeadModel('Finance Enquiry')
				setFinanceModel(true)
			} else if (type === 'afterMarket') {
				//setRecordId(id);
				setShowAssignLeadModel({ show: true, notifyID: notifyID, recordID: id });
				setTitleLeadModel('Aftermarket Enquiry')
				setAfterMarketModel(true)

			} else if (type === 'inbound') {
				//setRecordId(id);
				setShowInboundLeadModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'inboundTIP') {
				//setRecordId(id);
				setShowInboundTIPModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'fleet') {
				//setRecordId(id);
				setShowFleetModel({ show: true, notifyID: notifyID, recordID: id });
			}
			else if (type === 'pendingLost' || type === 'tradein') {
				setNotifyClose(true);
				// if (localStorage.defaultModule !== 'enquiry') {
				// 	localStorage.setItem('objNotify', JSON.stringify({ id: id, type: 'enquiry' }));
				// 	localStorage.setItem('defaultModule', 'enquiry');
				// 	handleModuleChange('enquiry', null, dealersettings?.client?.id);
				// }
				// else
				history.push(`/enquiry/details/${id}`)
			}
			else if (type === 'service') {
				setNotifyClose(true);
				// if (localStorage.defaultModule !== type) {
				// 	localStorage.setItem('objNotify', JSON.stringify({ id: id, type: type }));
				// 	localStorage.setItem('defaultModule', type);
				// 	handleModuleChange(type, null, dealersettings?.client?.id);
				// }
				// else
				history.push(`/service/details/${id}`)
			}
			else if (type === 'tradeinPro' || type === 'tradeinProFinal') {
				setNotifyClose(true);
				// if (localStorage.defaultModule !== type) {
				// 	localStorage.setItem('objNotify', JSON.stringify({ id: id, type: type }));
				// 	localStorage.setItem('defaultModule', type);
				// 	handleModuleChange(type, null, dealersettings?.client?.id);
				// }
				//else
				history.push(`/tradeinpro/details/${id}`)
			}
			else if (type === 'fleetProAssign') {
				setNotifyClose(true);
				//const _permissions = _dealersettings?.rolePermissions?.fleetProPermissions;
				//const accessToFleetHistory = ((!_.isEmpty(_permissions) && _permissions.accessToFleetHistory) ? true : false);
				//if (_objData?.fleetProID && accessToFleetHistory) { history.push("/fleetpro/details/" + _objData.fleetProID + '?aid=' + id); }
				//else {
				setfleetAssignModal({ show: true, notifyID: notifyID, docID: id, fleetProID: _objData?.fleetProID })
				//}
			}
			else if (type === 'tradeinProValuer') {
				setNotifyClose(true);
				history.push(`/tradein/history`)
			}
			else if (type === 'tradeinProOffer') {
				setNotifyClose(true);
				// if (localStorage.defaultModule !== 'wholesaler') {
				// 	localStorage.setItem('objNotify', JSON.stringify({ id: id, type: 'wholesaler' }));
				// 	localStorage.setItem('defaultModule', 'wholesaler');
				// 	handleModuleChange('wholesaler', null, dealersettings?.client?.id);
				// }
				// else
				history.push(`/wholesaler/details/${id}`)
				// if (!_.isEmpty(_objData) && _objData.data)
				// 	history.push(`/tradeinpro/details/${_objData.data}?offer=${id}`)
			}
			else if (type === 'email') {
				// setTheardID(id)
				// if (!window.location.pathname.includes('/mail'))
				history.push('/mails/inbox?threadid=' + id)
			} else if (type === 'chat') {
				//console.log('we got a chat notification', id)
				let chatCloseIcon = document.querySelector('#chatApp-close-icon')
				if (chatCloseIcon) {
					// console.log(chatCloseIcon)
					chatCloseIcon.click()
				}
				document.querySelector('#chat-icon-div').click()
				setTimeout(() => {
					let ref = document.querySelector(`#chat-list-item-${id}`)
					if (ref) {
						ref.click()
					}
				}, 1000)
			} else if (type === 'log' || type === 'financelog' || type === 'afterMarketlog' || type === 'sms') {
				setNotifyClose(true);
				if (enquiryId)
					history.push("/enquiry/details/" + enquiryId + '?comments=' + id);
				else if (contactId)
					history.push("/contacts/details/" + contactId + '?comments=' + id);
			} else if (type === 'servicelog') {
				setNotifyClose(true);
				console.log(type, _objData)
				if (_objData?.serviceID)
					history.push("/service/details/" + _objData.serviceID + '?comments=' + id);
				else if (contactId)
					history.push("/contacts/details/" + contactId + '?comments=' + id);
			} else if (type === 'tradeinProlog') {
				setNotifyClose(true);
				if (_objData?.tradeinProID)
					history.push("/tradeinpro/details/" + _objData.tradeinProID + '?comments=' + id);
				else if (contactId)
					history.push("/contacts/details/" + contactId + '?comments=' + id);
			} else if (type === 'kitchen') {
				//setRecordId(id);
				setShowKitchenModel({ show: true, notifyID: notifyID, recordID: id });
			} else if (type === 'salesHub') {
				//setRecordId(id);
				setSaleshubcommentsModel({ show: true, notification: _objData });
			}
		}
		// else if(type === 'settings'){
		// 	getdealerSettings()
		// }
	}, []);

	const handleNotifySwitchDealer = (id, type, clientID, enquiryid, contactid, _objdata) => {
		if (!_.isEmpty(_objdata) && _.isObject(_objdata))
			localStorage.setItem('objNotify', JSON.stringify({ ..._objdata, id: id, type: type, enquiryid: enquiryid, contactid: contactid }));
		else
			localStorage.setItem('objNotify', JSON.stringify({ id: id, type: type, enquiryid: enquiryid, contactid: contactid }));
		localStorage.setItem('oemswitchclient', clientID)
		localStorage.setItem('defaultModule', 'enquiry');
		handleClientChange(clientID);
	};

	const handleEnquirySwitchDealer = (id, type, clientID) => {
		//	console.log('handleEnquirySwitchDealer', id, type, clientID)
		localStorage.setItem('objSales', JSON.stringify({ id: id, type: type }));
		localStorage.setItem('oemswitchclient', clientID)
		localStorage.setItem('defaultModule', 'enquiry');
		handleClientChange(clientID);
	};

	const handleServiceSwitchDealer = (id, type, clientID) => {
		//	console.log('handleServiceSwitchDealer', id, type, clientID)
		localStorage.removeItem('objSales');
		localStorage.setItem('objService', JSON.stringify({ id: id, type: type }));
		localStorage.setItem('oemswitchclient', clientID)
		localStorage.setItem('defaultModule', 'service');
		handleClientChange(clientID);
	};

	const handleTradeinProSwitchDealer = (id, type, clientID) => {
		//	console.log('handleTradeinProSwitchDealer', id, type, clientID)
		localStorage.removeItem('objSales');
		localStorage.removeItem('objService');
		localStorage.setItem('objTradeinPro', JSON.stringify({ id: id, type: type }));
		localStorage.setItem('oemswitchclient', clientID)
		localStorage.setItem('defaultModule', 'tradeinPro');
		handleClientChange(clientID);
	};

	const handleActivityModelClose = (objLog, isActivtyAlreadyDone, isClose) => {
		const enableCustShowNoShow = dealersettings?.client?.clientSettings?.enableCustShowNoShow;
		setRecordId(null);
		setNotificationId(null);
		setShowActivityModel({ show: false, notifyID: null, recordID: null });
		localStorage.removeItem('objNotify');
		if (objLog && objLog.isDone === true && !isActivtyAlreadyDone) {
			if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
				Swal.fire({
					title: CommonHelper.showLocale({ dealersettings }, 'Please select'),
					icon: 'info',
					showCancelButton: true,
					confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Customer Show'),
					cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Customer No Show'),
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then(result => {
					firestoreDB(dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
						.set({
							bdcStatus: result.value ? 'show' : 'noshow'
						}, { merge: true })
					shedulenextactivity(objLog);
					CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, dealersettings);
				});
			}
			else {
				shedulenextactivity(objLog);
			}
		}
		if (isClose)
			setNotifyClose(true);
	};

	const shedulenextactivity = async (objLog) => {
		if (dealersettings?.client?.clientSettings?.isScheduleNextActivity) {

			let _isAlreadyScheduled = false;
			if (objLog.enquiryID || objLog?.enquiry?.documentID) {
				const responseMKA = await firestoreDB(dealersettings).firestore().collection('activities')
					.where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
					.where('isDeleted', '==', false)
					.where('type', '==', 'activity')
					.where('isDone', '==', false)
					.limit(1)
					.get();
				if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
			}
			if (_isAlreadyScheduled) return;

			const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(dealersettings, objLog?.enquiry);

			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Schedule next activity?'),
				text: CommonHelper.showLocale({ dealersettings }, 'Do you want to schedule next activity?'),
				icon: 'info',
				showCancelButton: mandScheduleNextActivity ? false : true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
				allowOutsideClick: false,
				allowEscapeKey: false
			}).then((result) => {
				if (result.value) {
					setShowScheduleActivityModel({
						activityLog: {
							...objLog,
							inbound: null
						},
						show: true,
						contact: objLog.contact ? objLog.contact : null,
						enquiry: objLog.enquiry ? objLog.enquiry : null,
					})
				}
			})
		}
	}

	const handleEngageModelClose = () => {
		//	console.log('handleEngageModelClose', notificationId)
		if (showEngageModel.notifyID) {
			window.firebase.firestore()
				.doc(`/notifications/${showEngageModel.notifyID}`)
				.set({ isRead: true }, { merge: true });
		}

		setRecordId(null);
		setNotificationId(null);
		setShowEngageModel({ show: false, notifyID: null, recordID: null });
		setShowEngageModelClose(true);
	};
	const handleAssignModelClose = () => {
		//	console.log('handleAssignModelClose', notificationId)
		if (showAssignLeadModel.notifyID) {
			window.firebase.firestore()
				.doc(`/notifications/${showAssignLeadModel.notifyID}`)
				.set({ isRead: true }, { merge: true });
		}

		setRecordId(null);
		setNotificationId(null);
		setShowAssignLeadModel({ show: false, notifyID: null, recordID: null });
		setTitleLeadModel(null)
		setFinanceModel(false)
		setAfterMarketModel(false)
	};
	const handleInboundModelClose = () => {
		//	console.log('handleInboundModelClose', notificationId)
		if (showInboundLeadModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showInboundLeadModel.notifyID}`).set({ isRead: true }, { merge: true });
		}
		if (showInboundTIPModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showInboundTIPModel.notifyID}`).set({ isRead: true }, { merge: true });
		}

		setRecordId(null);
		setNotificationId(null);
		setShowInboundLeadModel({ show: false, notifyID: null, recordID: null });
		setShowInboundTIPModel({ show: false, notifyID: null, recordID: null });
	};
	const handleFleetModelClose = () => {
		//	console.log('handleFleetModelClose', notificationId)
		if (showFleetModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showFleetModel.notifyID}`).set({ isRead: true }, { merge: true });
		}

		setRecordId(null);
		setNotificationId(null);
		setShowFleetModel({ show: false, notifyID: null, recordID: null });
	};
	const handleKitchenModelClose = (id) => {
		//	console.log('handleKitchenModelClose', notificationId)
		if (showKitchenModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showKitchenModel.notifyID}`).set({ isRead: true }, { merge: true });
		}


		setRecordId(null);
		setNotificationId(null);
		setShowKitchenModel({ show: false, notifyID: null, recordID: null });
		if (id)
			setNotifyClose(true);
	};

	const handleCallLogsModelClose = () => {
		//	console.log('handleCallLogsModelClose', notificationId)
		if (showCallLogModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showCallLogModel.notifyID}`).set({ isRead: true }, { merge: true });
		}


		setRecordId(null);
		setNotificationId(null);
		setShowCallLogModel({ show: false, notifyID: null, recordID: null });
	};

	const handleSMSModelClose = () => {
		//	console.log('handleAssignModelClose', notificationId)
		if (showSMSModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showSMSModel.notifyID}`).set({ isRead: true }, { merge: true });
		}

		setRecordId(null);
		setNotificationId(null);
		setShowSMSModel({ show: false, notifyID: null, recordID: null });
	};

	const clearTheardID = () => {
		setTheardID(null)
	}

	const handleUnreadCount = (val) => {
		setUnreadCount(val)
	}
	const handlenotifyClose = (val) => {
		setNotifyClose(val)
	}

	const sleepModeClose = (isValidated) => {
		//console.log('sleepModeClose', isValidated);
		if (isValidated) {
			setSleepMode(false);
			localStorage.setItem('sleepMode', '');
		}
	}

	const handleSleepModeOpen = () => {
		//	console.log('handleSleepModeOpen');
		setSleepMode(true);
		localStorage.setItem('sleepMode', 'ON');
	}

	const handleSetCounts = (countsToSet) => {
		setUnReadChatCounts(countsToSet);
	}

	const handleServiceModelClose = () => {
		//	console.log('handleServiceModelClose', notificationId)
		if (showServiceRequestModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showServiceRequestModel.notifyID}`).set({ isRead: true }, { merge: true });
		}
		if (showTradeinProRequestModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showTradeinProRequestModel.notifyID}`).set({ isRead: true }, { merge: true });
		}
		if (showAircallNotifyModel.notifyID) {
			window.firebase.firestore().doc(`/notifications/${showAircallNotifyModel.notifyID}`).set({ isRead: true }, { merge: true });
		}
		if (showNotifyModel?.notification?.documentID)
			window.firebase.firestore().doc(`/notifications/${showNotifyModel?.notification?.documentID}`).set({ isRead: true }, { merge: true });

		if (showChatModel?.notification?.documentID)
			window.firebase.firestore().doc(`/notifications/${showChatModel?.notification?.documentID}`).set({ isRead: true }, { merge: true });

		setRecordId(null);
		setNotificationId(null);
		if (showServiceRequestModel?.show) setShowServiceRequestModel({ show: false, notifyID: null, recordID: null });
		if (showTradeinProRequestModel?.show) setShowTradeinProRequestModel({ show: false, notifyID: null, recordID: null });
		if (showAircallNotifyModel?.show) setShowAircallNotifyModel({ show: false, notifyID: null, recordID: null });
		if (showNotifyModel?.show) setShowNotifyModel({ show: false, notification: null });
		if (showChatModel?.show) setShowChatModel({ show: false, notification: null });
		if (saleshubcommentsModel?.show) setSaleshubcommentsModel({ show: false, notification: null });
	};

	const handleFleetAssignClose = () => {
		if (fleetAssignModal.notifyID) {
			window.firebase.firestore().doc(`/notifications/${fleetAssignModal.notifyID}`).set({ isRead: true }, { merge: true });
		}
		setfleetAssignModal({ show: false, notifyID: null, docID: null, fleetProID: null })
	}

	//set pending inbound lead count
	useEffect(() => {

		if (_.isEmpty(localClient))
			return
		const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions) &&
			dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
		const excludeOrigins = dealersettings?.rolePermissions?.excludeOrigins || [];

		let accessSalesType = [];
		var querySet = `clients/${localClient}`
		if (dealersettings && localStorage.defaultModule === 'oem') {
			if (dealersettings.level === 'oem' && dealersettings.oemID)
				querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
			else if (dealersettings.level === 'group' && dealersettings.groupID)
				querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
			else if (dealersettings?.level?.includes('region') && dealersettings.regionID)
				querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
		}

		if (!viewOtherEnquiry && localStorage.defaultModule !== 'oem') {
			querySet = `users/${localStorage.uid}/userClients/${localClient}`
			// if (dealersettings && localStorage.defaultModule === 'oem') {
			// 	if (dealersettings.level === 'oem' && dealersettings.oemID)
			// 		querySet = `users/${localStorage.uid}/counts/oem`
			// 	else if (dealersettings.level === 'group' && dealersettings.groupID)
			// 		querySet = `users/${localStorage.uid}/counts/group`
			// 	else if (dealersettings?.level?.includes('region') && dealersettings.regionID)
			// 		querySet = `users/${localStorage.uid}/counts/region`
			// }
		}
		if (localStorage.defaultModule !== 'oem') {
			accessSalesType = Object.assign([], dealersettings.accessSalesType);
			querySet = `clients/${localClient}/counts/${localClient}`;
		}
		const unSubPipelineCount = window.firebase.firestore().doc(querySet)
			.onSnapshot(docSnapshot => {
				var stageCount = {};
				var docStageCount = {};
				const _selectedPipeline = pipeline.find(item => item.value === 'LeadsBucket');
				// if (localStorage.defaultModule !== 'oem' && docSnapshot.exists && docSnapshot.data().counts && docSnapshot.data().counts.pipeline && docSnapshot.data().counts.pipeline['LeadsBucket'] && docSnapshot.data().counts.pipeline['LeadsBucket'].stages) {
				// 	_selectedPipeline && _selectedPipeline.stages.forEach(stage => {
				// 		stageCount = (stage.value === 'Converted' || stage.value === 'InboundLead') ? {
				// 			...stageCount, [stage.value]: { ['total']: (docSnapshot.data().counts.pipeline["LeadsBucket"].stages[stage.value] ? docSnapshot.data().counts.pipeline["LeadsBucket"].stages[stage.value] : 0) }
				// 		} : {
				// 			...stageCount,
				// 			[stage.value]: {
				// 				...docSnapshot.data().counts.pipeline['LeadsBucket'].stages[stage.value],
				// 				['total']: sumObj(docSnapshot.data().counts.pipeline['LeadsBucket'].stages[stage.value])
				// 			}
				// 		}
				// 	})
				// }
				// else
				if (docSnapshot.exists && docSnapshot.data() && docSnapshot.data().inbound && docSnapshot.data().inbound['LeadsBucket'] && docSnapshot.data().inbound['LeadsBucket'].stages) {
					_selectedPipeline && _selectedPipeline.stages.forEach(stage => {
						stageCount = {
							...stageCount,
							[stage.value]: {
								...docSnapshot.data().inbound['LeadsBucket'].stages[stage.value],
								['total']: sumObj(docSnapshot.data().inbound['LeadsBucket'].stages[stage.value], accessSalesType, stage.value, excludeOrigins)
							}
						}
					})
					docStageCount = docSnapshot.data().inbound['LeadsBucket'].stages;
				}
				if (stageCount) {
					var sum = 0;
					// 'Contacted', 
					for (let [key, value] of Object.entries(stageCount)) {
						if ((_selectedPipeline ? _selectedPipeline.stages.filter(a => a.value !== 'Archived' && a.value !== 'Converted').map(r => { return r.value }) : ['New', 'Contacted', 'InboundLead']).indexOf(key) >= 0) {
							sum += parseFloat((value && value.total) ? value.total : 0);
							if (accessSalesType.length === 0 && docStageCount && docStageCount[`stage-${key}`] && docStageCount[`stage-${key}`].lost) {
								sum -= parseFloat((docStageCount[`stage-${key}`].lost) ? docStageCount[`stage-${key}`].lost : 0);
							}
							if (accessSalesType.length === 0 && docStageCount && docStageCount[`stage-${key}`] && docStageCount[`stage-${key}`].prospectLost) {
								sum -= parseFloat((docStageCount[`stage-${key}`].prospectLost) ? docStageCount[`stage-${key}`].prospectLost : 0);
							}
							if (accessSalesType.length === 0 && docStageCount && docStageCount[`stage-${key}`] && docStageCount[`stage-${key}`].pendingLost) {
								sum -= parseFloat((docStageCount[`stage-${key}`].pendingLost) ? docStageCount[`stage-${key}`].pendingLost : 0);
							}
							if (accessSalesType.length > 0 &&
								_.find(_selectedPipeline.stages, { value: key })
								&& _.find(_selectedPipeline.stages, { value: key }).name
								&& _.find(_selectedPipeline.stages, { value: key }).name.toLowerCase().includes('lost')) {
								sum -= parseFloat((value && value.total) ? value.total : 0);
							}
						}
					}
					//console.log('Inbound Total Count', sum, stageCount);
					//if (inboundAllocation)
					//	setInboundCount(0);
					//else
					setInboundCount(sum);
				}
				else {
					setInboundCount(0);
				}
			})

		return () => {
			unSubPipelineCount && unSubPipelineCount()
		}
	}, [localClient, localStorage.defaultModule])

	//set pending inbound lead count
	useEffect(() => {
		if (_.isEmpty(localClient) || localStorage.defaultModule !== 'tradeinPro') {
			window.unSubTradeinProInboundCount && window.unSubTradeinProInboundCount();
			return
		}
		window.unSubTradeinProInboundCount = firestoreDB(dealersettings).firestore()
			.collection('tradeinProInbound')
			.where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
			.where('isDeleted', '==', false)
			.where('status', '==', 0)
			.onSnapshot(docSnapshot => {
				if (docSnapshot?.docs?.length > 0) {
					setTradeinProInboundCount(docSnapshot.docs.length)
				} else {
					setTradeinProInboundCount(0)
				}
			})

		return () => {
			window.unSubTradeinProInboundCount && window.unSubTradeinProInboundCount()
		}
	}, [localClient, localStorage.defaultModule])


	const sumObj = (obj, arrSaleType, stage, excludeOrigins) => {
		var sum = 0;
		//if (stage === 'InboundLead' && !_.isEmpty(arrSaleType)) {
		//	arrSaleType = [...arrSaleType, 'na'];
		//}
		for (var el in obj) {
			if (_.isNumber(obj[el]) && (_.isEmpty(arrSaleType) || arrSaleType.some(a => a === el))) {
				sum += parseFloat(obj[el] ? obj[el] : 0);
			}
		}
		if (obj?.origins && excludeOrigins?.length) {
			for (var el in obj.origins) {
				if (JSON.parse(obj.origins[el])?.length) {
					JSON.parse(obj.origins[el]).forEach(rec => {
						excludeOrigins.forEach(ex => {
							if (_.isNumber(rec[ex])) {
								sum -= parseFloat(rec[ex]);
							}
						})
					})
				}
			}
		}
		return sum;
	}

	const handleOEMChange = async data => {
		try {
			const result = await Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
				text: `${CommonHelper.showLocale({ dealersettings }, 'Do you want to switch to')} ${data.name}?`,
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
				allowOutsideClick: false,
				allowEscapeKey: false
			})

			if (!result.value) {
				return
			}
			const currentRole = dealersettings.roleName;
			let OEMRole = {};
			if (_.find(dealersettings?.arrayOEM, { oem: data.id })?.roleID) {
				const _rid = _.find(dealersettings?.arrayOEM, { oem: data.id })?.roleID;
				const OEMColRole = await window.firebase
					.firestore()
					.doc(`clientSettings/${data.id}/roles/${_rid}`)
					.get();
				if (OEMColRole.exists) {
					OEMRole = {
						...OEMColRole?.data(),
						id: OEMColRole?.id
					};
				}
			}
			if (_.isEmpty(OEMRole)) {
				const OEMColRole = await window.firebase
					.firestore()
					.collection(`clientSettings/${data.id}/roles`)
					.where('name', '==', currentRole)
					.get();
				if (OEMColRole?.docs?.[0]?.data()) {
					OEMRole = {
						...OEMColRole?.docs?.[0]?.data(),
						id: OEMColRole?.docs?.[0]?.id
					};
				}
			}
			if (!_.isEmpty(OEMRole)) {
				setLoadingText('Fetching oem settings...');
				let clientsCollection = [];
				const objToUpdate = {
					role: OEMRole.name,
					roleID: OEMRole.id,
					department: 'Management',
					defaultClientID: '',
					level: dealersettings.level,
					arrayClientID: []
				}
				const clientsRef = window.firebase.firestore().collection('clientSettings').doc(data.id).collection('clients')
				const clientsSnap = await clientsRef.get()
				let _clients = []
				if (clientsSnap.size > 0) {
					clientsSnap.forEach(doc => {
						_clients.push(doc.data())
					})
				}
				else {
					setLoadingText('');
					Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No clients found Please contact administrator'), '', 'info')
					return;
				}
				const selectedOEMClients = _.find(dealersettings?.arrayOEM, { oem: data.id })?.clients || [];
				_clients = _clients.filter(a => a.documentID && a.name && (selectedOEMClients?.length ? selectedOEMClients.includes(a.documentID) : true));
				objToUpdate.arrayClientID = _clients.map(client => client.documentID)
				objToUpdate.defaultClientID = objToUpdate.arrayClientID[0]
				objToUpdate.settingsID = data.id;
				// if (dealersettings?.arrayOEM?.length) {
				// 	objToUpdate.arrayOEM = dealersettings.arrayOEM.filter(a => a.oem !== data.id);
				// 	objToUpdate.arrayOEM.push({
				// 		oem: dealersettings.oemID,
				// 		clients: dealersettings?.roleName === 'Administrator' ? [] : dealersettings?.arrayClientID
				// 	})
				// 	objToUpdate.arrayOEMID = objToUpdate.arrayOEM.map(r => r.oem);
				// }

				// Set the clientCollection
				clientsCollection = _clients.map(client => {
					return {
						department: 'Management',
						defaultModule: 'enquiry',
						name: client.name ? client.name : '',
						roleID: objToUpdate.roleID,
						documentID: client.documentID ? client.documentID : ''
					}
				})

				// update the user document
				const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
				await userRef.set(objToUpdate, { merge: true })

				const batchToDelete = window.firebase.firestore().batch();
				const batchToWrite = window.firebase.firestore().batch();

				// delete the userClients
				const userClientsRef = window.firebase.firestore().doc(`users/${localStorage.uid}`).collection('userClients')
				const userClientsSnap = await userClientsRef.get()
				let _userClients = []
				if (userClientsSnap.size > 0) {
					userClientsSnap.forEach(doc => {
						_userClients.push(doc.id)
						batchToDelete.delete(userClientsRef.doc(doc.id));
					})
					await batchToDelete.commit()
				}

				// the user clients
				clientsCollection.forEach(clientObj => {
					batchToWrite.set(userClientsRef.doc(clientObj.documentID), {
						department: clientObj.department,
						name: clientObj.name,
						roleID: clientObj.roleID,
						defaultModule: clientObj.defaultModule,
					}, { merge: true });
				})

				await batchToWrite.commit();
				getdealerSettings();
			}
			else {
				setLoadingText('');
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No roles found Please contact administrator'), '', 'info')
				return;
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const interval = setInterval(async () => {

			const resp1 = await checkOnlineStatus();
			const resp2 = await checkOnlineStatus();
			const resp3 = await checkOnlineStatus();

			const result = !resp1 && !resp2 && !resp3 ? false : true

			if (result === false && isOnline !== false) {
				// console.log('SYSTEM OFFLINE', result)
				toaster.closeAll();
				toaster.notify(({ onClose }) => (
					<span className="custom-user-toaster-top">
						<div className="custom-user-toaster-message" style={{ opacity: '1' }}>
							<div className="custom-user-toaster-message-wrapper" style={{ transform: 'translateY(0%) scale(1)' }}>
								<div data-reach-alert="true">
									<div className="custom-user-toaster-alert custom-toast-cell-grey">
										<div className="flex-toast-custom">
											<div className="custom-toast-icon"> <img src={_images.wifioff} height="50" /> </div>
											<div className="custom-toast-data">
												<div className="custom-toast-item-title">You're offline now!</div>
												<div className="custom-toast-item-info">Oops! Internet is disconnected.</div>
											</div>
										</div>
										<button className="custom-user-toaster-alert-close" type="button" aria-label="Close" onClick={onClose}><span aria-hidden="true">×</span></button>
									</div>
								</div>
							</div>
						</div>
					</span>
				), {
					duration: 30000
				});
				setIsOnline(result)
			} else if (result === true && isOnline === false) {
				// console.log('SYSTEM ONLINE AGAIN', result)
				toaster.closeAll();
				toaster.notify(({ onClose }) => (
					<span className="custom-user-toaster-top ">
						<div className="custom-user-toaster-message" style={{ opacity: '1' }}>
							<div className="custom-user-toaster-message-wrapper" style={{ transform: 'translateY(0%) scale(1)' }}>
								<div data-reach-alert="true">
									<div className="custom-user-toaster-alert custom-toast-cell-green">
										<div className="flex-toast-custom">
											<div className="custom-toast-icon"> <img src={_images.online} height="50" /> </div>
											<div className="custom-toast-data">
												<div className="custom-toast-item-title custom-toast-item-title-online">You're online now!</div>
												<div className="custom-toast-item-info">Hurrah! Internet is connected.</div>
											</div>
										</div>
										<button className="custom-user-toaster-alert-close" type="button" aria-label="Close" onClick={onClose}><span aria-hidden="true">×</span></button>
									</div>
								</div>
							</div>
						</div>
					</span>
				), {
					duration: 10000
				});
				setIsOnline(result)
			} else if (result === true && isOnline === true) {
				// console.log('SYSTEM ALREADY ONLINE', result)
			}
		}, 7000);

		return () => clearInterval(interval);
	}, [isOnline]);

	const checkOnlineStatus = async () => {
		try {
			const online = await fetch("https://jsonplaceholder.typicode.com/todos/1", {
				// mode: 'no-cors',
				cache: 'no-cache'
			});
			return online.status >= 200 && online.status < 300; // either true or false
		} catch (err) {
			return false; // definitely offline
		}
	};

	const changeLanguage = async (_lng) => {
		//set language

		// window.firebase.firestore().doc(`/users/${localStorage.uid}`)
		// 	.set({ language: _lng }, { merge: true })
		// 	.then(async () => {
		updateDealerSettings('language', _lng);
		i18n.changeLanguage(_lng);
		// 	toast.notify('Settings updated successfully', {
		// 		duration: 2000
		// 	})

		// }).catch(error => {
		// 	console.error(error)
		// });

	}

	const updateAircallModel = (show) => {
		setAircallModal(show)
	}

	const refreshAircallModel = (show) => {
		setAircallPhone(null)
		setRefreshAircall(!refreshAircall);
	}

	useEffect(() => {
		if (!_.isEmpty(aircallPhone)) {
			return;
		}
		const _aircall = dealersettings?.client?.integrations?.filter(e => e.type === "aircall")[0];
		if (_aircall?.active && _aircall?.enabled) {
			const _aircallPhone = new AircallPhone({
				domToLoadPhone: '#aircall-phone',
				size: 'big',
				// onLogin: settings => {
				// 	console.log(settings)
				// },
			});
			_aircallPhone.on('incoming_call', callInfos => {
				console.log('incoming_call', callInfos);
				setAircallModal(true);
			});

			_aircallPhone.on('call_end_ringtone', callInfos => {
				console.log('call_end_ringtone', callInfos)
			});


			_aircallPhone.on('outgoing_call', callInfos => {
				console.log('outgoing_call', callInfos)
				if (!_.isEmpty(localStorage.aircallLog)) {
					const activity = firestoreDB(dealersettings).firestore().collection('activities').doc();
					firestoreDB(dealersettings).firestore().doc(`activities/${activity.id}`).set({
						...JSON.parse(localStorage.aircallLog),
						documentID: activity.id,
						addedBy: localStorage.uid,
						modifiedBy: localStorage.uid,
						owner: localStorage.uid,
						aircall: {
							id: callInfos.call_id,
							status: 'new'
						}
					}, { merge: true });
					localStorage.removeItem('aircallLog');
				}
			});

			_aircallPhone.on('outgoing_answered', callInfos => {
				console.log('outgoing_answered', callInfos)
			});
			setAircallPhone(_aircallPhone);
		}
	}, [refreshAircall])

	const dialNumber = (number, enquiryID, contactID, type, serviceID, tradeinProID) => {
		if (_.isEmpty(aircallPhone)) {
			setRefreshAircall(!refreshAircall);
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Phone is not loaded/login.'), '', 'info')
			return;
		}
		aircallPhone.isLoggedIn(response => {
			if (!response) {
				setAircallPhone(null)
				setRefreshAircall(!refreshAircall);
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Phone is not logged in.'), '', 'info')
				return;
			}
		});

		const payload = {
			phone_number: number
		};

		aircallPhone.send(
			'dial_number',
			payload,
			(success, data) => {
				if (success) {
					localStorage.setItem('aircallLog', JSON.stringify({
						enquiryID: enquiryID ? enquiryID : null,
						contactID: contactID ? contactID : null,
						serviceID: serviceID ? serviceID : null,
						tradeinProID: tradeinProID ? tradeinProID : null,
						type: type ? type : `${serviceID ? 'service' : tradeinProID ? 'tradeinPro' : ''}log`
					}))
					setAircallModal(true);
				}
			}
		);

	}

	useEffect(() => {
		window.addEventListener('message', async (e) => {
			const data = e.data;
			if (data) {
				switch (data.type) {
					case 'rc-login-popup-notify':
						// get login oAuthUri from widget
						console.log('rc-login-popup-notify:', data.oAuthUri);
						const oAuthUri = new URL(data.oAuthUri);
						// const client_id = "8TLHxYisQkCNU29dbiMRhg";
						oAuthUri.searchParams.delete('code_challenge');
						oAuthUri.searchParams.delete('code_challenge_method');
						// oAuthUri.searchParams.set('client_id', client_id);
						// oAuthUri.host = 'platform.ringcentral.com';
						window.open(oAuthUri.href, 'Ringcentral', "height=600,width=600"); // open oauth uri to login
						break;
					case 'rc-redirect-authorization-code':
						// get login oAuthUri from widget
						try {
							let apiURL = process.env.NODE_ENV === 'development'
								? 'http://localhost:5001/ams-pro-qa/us-central1/'
								: null
							const remoteConfig = window.firebase.remoteConfig();
							await remoteConfig.fetchAndActivate()
								.then(() => {
									apiURL = remoteConfig.getString('apiURL')
								})
								.catch((err) => {
									console.log('Unable to get remoteConfig' + JSON.stringify(err));
								});
							var url = new URL(data.callbackUri)
							var urlParams = new URLSearchParams(url.search);
							var code = urlParams.get('code');
							var callbackURL = `${window.location.origin}/redirect.html`;
							var state = localStorage.uid;
							axios.get(`${apiURL}ringcentral-api/api/ringcentral/callback?code=${code}&redirectURL=${callbackURL}&state=${state}&platform=web`);
						} catch (err) { console.log(err) }
						// const callback_Uri = new URL(data.callbackUri);
						// const app_client_id = "8TLHxYisQkCNU29dbiMRhg";
						// callback_Uri.searchParams.set('client_id', app_client_id);
						// callback_Uri.searchParams.set('clien_secret', 'rQDAy9pERT-Dmi_TbldtGQTPOLuQSET-2ZQditZ5D33Q');
						console.log('rc-adapter-authorization-code:', data.callbackUri);
						document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
							type: 'rc-adapter-authorization-code',
							callbackUri: data.callbackUri
						}, '*');
						break;
					case 'rc-login-status-notify':
						// get login status from widget
						setRingcentralAuth(data.loggedIn);
						console.log('rc-login-status-notify:', data.loggedIn, data.loginNumber);
						break;
					case 'rc-call-ring-notify':
						console.log('ring call:');
						setRingcentralModal(true);
						console.log(data.call);
						break;
					case 'rc-call-init-notify':
						console.log('init call:');
						console.log(data.call);
						break;
					case 'rc-call-start-notify':
						console.log('start call:');
						console.log(data.call);
						if (!_.isEmpty(localStorage.ringcentralLog)) {
							const activity = firestoreDB(dealersettings).firestore().collection('activities').doc();
							firestoreDB(dealersettings).firestore().doc(`activities/${activity.id}`).set({
								...JSON.parse(localStorage.ringcentralLog),
								clientID: null,
								documentID: activity.id,
								addedBy: localStorage.uid,
								modifiedBy: localStorage.uid,
								owner: localStorage.uid,
								ringcentral: {
									telephonySessionId: data.call.partyData.sessionId,
									status: 'new'
								}
							}, { merge: true });
							localStorage.removeItem('aircallLog');
						}
						break;
					case 'rc-call-end-notify':
						console.log('end call:');
						console.log(data.call);
						break;
					case 'rc-call-hold-notify':
						console.log('hold call:');
						console.log(data.call);
						break;
					case 'rc-call-resume-notify':
						console.log('resume call:');
						console.log(data.call);
						break;
					case 'rc-call-mute-notify':
						// get call on call muted or unmuted event
						console.log('call muted changed:');
						console.log(data.call);
						break;
					case 'rc-webphone-active-notify':
						console.log('rc-webphone-active-notify: ', data.connectionStatus);
						break;
					case 'rc-webphone-connection-status-notify':
						console.log('rc-webphone-connection-status-notify: ', data.connectionStatus);
						// if (data.connectionStatus === 'connectionStatus-disconnected') {
						// 	if (data.loggedIn === false) {
						// 		let apiURL = process.env.NODE_ENV === 'development'
						// 			? 'http://localhost:5001/ams-pro-qa/us-central1/'
						// 			: null
						// 		const remoteConfig = window.firebase.remoteConfig();
						// 		await remoteConfig.fetchAndActivate()
						// 			.then(() => {
						// 				apiURL = remoteConfig.getString('apiURL')
						// 			})
						// 			.catch((err) => {
						// 				console.log('Unable to get remoteConfig' + JSON.stringify(err));
						// 			});
						// 		if (apiURL) {
						// 			axios.post(`${apiURL}ringcentral-api/api/ringcentral/delete-webhooks`, {
						// 				userID: localStorage.uid
						// 			});
						// 		}
						// 		else {
						// 			await db.doc(`users/${localStorage.uid}/ringcentral/${localStorage.uid}`).delete();
						// 		}
						// 	}
						// }
						break;
					case 'rc-webphone-sessions-sync':
						console.log(data.calls);
						break;
					case 'rc-login-status-notify':
						console.log('rc-login-status-notify:', data.loggedIn, data.loginNumber, data.contractedCountryCode);
						break;
					case 'rc-calling-settings-notify':
						console.log('rc-calling-settings-notify:', data.callWith, data.callingMode);
						break;
					case 'rc-region-settings-notify':
						console.log('rc-region-settings-notify:', data.countryCode, data.areaCode);
						break;
					case 'rc-active-call-notify':
						console.log('rc-active-call-notify:', data.call);
						break;
					case 'rc-telephony-session-notify':
						// get telehony session on telephony session event
						console.log('telephonySession', data.telephonySession);
						break;
					case 'rc-ringout-call-notify':
						console.log('rc-ringout-call-notify:', data.call);
						break;
					case 'rc-inbound-message-notify':
						console.log('rc-inbound-message-notify:', data.message.id);
						break;
					case 'rc-message-updated-notify':
						console.log('rc-message-updated-notify:', data.message.id);
						break;
					case 'rc-route-changed-notify':
						console.log('rc-route-changed-notify:', data.path);
						break;
					case 'rc-callLogger-auto-log-notify':
						console.log('rc-callLogger-auto-log-notify:', data.autoLog);
						break;
					case 'rc-dialer-status-notify':
						console.log('rc-dialer-status-notify:', data.ready);
						break;
					case 'rc-meeting-status-notify':
						console.log('rc-meeting-status-notify:', data.ready, data.permission);
						break;
					case 'rc-brand-assets-notify':
						console.log('rc-brand-assets-notify:', data);
						break;
					default:
						break;
				}
			}
		});
	}, [])

	const updateRingcentralModel = (show) => {
		setRingcentralModal(show)
	}

	const updateJustcallModel = async (show) => {
		setJustcallModal(show)
		if (!show) {
			try {
				await window.firebase.firestore().doc(`users/${localStorage.uid}`).update({ incomingCall: null });
			} catch (err) { }
		}
	}

	const dialJustcall = (number, enquiryID, contactID, type, serviceID, tradeinProID) => {
		setJustcallModal(true);
	}

	const dialRingcentral = (number, enquiryID, contactID, type, serviceID, tradeinProID) => {
		if (!ringcentralAuth) {
			setRingcentralModal(true);
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Phone is not loaded/login.'), '', 'info')
			return;
		}
		const apiURL = process.env.NODE_ENV === 'development'
			? 'http://localhost:5001/ams-pro-qa/us-central1/'
			: dealersettings.apiURL
		setRingcentralModal(true);
		localStorage.setItem('ringcentralLog', JSON.stringify({
			enquiryID: enquiryID ? enquiryID : null,
			contactID: contactID ? contactID : null,
			serviceID: serviceID ? serviceID : null,
			tradeinProID: tradeinProID ? tradeinProID : null,
			type: type ? type : `${serviceID ? 'service' : tradeinProID ? 'tradeinPro' : ''}log`
		}))
		document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
			type: 'rc-adapter-new-call',
			phoneNumber: number,
			toCall: false,
		}, '*');

	}

	return (
		<>
			<MainContext.Provider
				value={{
					dealersettings,
					pipeline,
					handlePipelineChange,
					handleClientChange,
					currentClient,
					loadingText,
					clientUsers,
					updateDealerSettings,
					modules,
					handleModuleChange,
					clientModule,
					deviceTokens,
					notificationCount,
					removeDevicetoken,
					handleNotifyClick,
					handleNotifySwitchDealer,
					queueNum,
					regions,
					subregions,
					groups,
					updatePipelineSettings,
					handleEnquirySwitchDealer,
					handleServiceSwitchDealer,
					userNotifications,
					newTheardID,
					clearTheardID,
					nylasAccessToken,
					setNylasAccessToken,
					nylas_accountData,
					mailUnreadCount,
					setUnreadCount,
					handleUnreadCount,
					emailTemplates,
					smsTemplates,
					notifyClose,
					handlenotifyClose,
					smsNotifications,
					smsNotificationCount,
					inboundCount,
					groupUsers,
					getdealerSettings,
					handleSleepModeOpen,
					handleSetCounts,
					unReadChatCounts,
					handleOEMChange,
					hasMoreSalesNotifications,
					hasMoreServiceNotifications,
					loadMoreNotifications,
					paymentDue,
					paymentDueDateText,
					paymentDueDate,
					accountSuspended,
					tvTemplates,
					changeLanguage,
					aircallModal,
					updateAircallModel,
					dialNumber,
					refreshAircallModel,
					aircallPhone,
					tradeinProinboundCount,
					updateRingcentralModel,
					ringcentralModal,
					ringcentralAuth,
					dialRingcentral,
					justcallModal,
					updateJustcallModel,
					dialJustcall,
					history
				}}
			>
				{children}
			</MainContext.Provider>
			<PopUpModal show={showselectmodules}>
				<SelectModule
					show={showselectmodules}
					handleSelectModule={handleModuleChange}
					modules={modules}
					moduleAlert={'test'}
					dealersettings={dealersettings}
				/>
			</PopUpModal>
			<PopUpModal show={showActivityModel.show}>
				<AddActivity
					show={showActivityModel.show}
					activityID={showActivityModel.recordID}
					handleClose={handleActivityModelClose}
					handleDelete={handleActivityModelClose}
					title={'Edit Activity'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					clsActive='overlay-modal active'
					//activityID={notificationRecordId}
					isFromLayout={true}
				>
				</AddActivity>
			</PopUpModal>
			<PopUpModal show={showScheduleActivityModel.show}>
				<AddActivity
					{...showScheduleActivityModel}
					handleClose={() => {
						setShowScheduleActivityModel({
							show: false
						})
					}}
					handleDelete={() => {
						setShowScheduleActivityModel({
							show: false
						})
					}}
					title={'addNewActivity'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					clsActive='overlay-modal active'
					isNewActivity={true}
					isScheduleActivity={true}
					isFromLayout={true}
				>
				</AddActivity>
			</PopUpModal>
			<PopUpModal show={showEngageModel.show}>
				<EngageRequest
					show={showEngageModel.show}
					docID={showEngageModel.recordID}
					handleClose={handleEngageModelClose}
					showClose={showEngageModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				>
				</EngageRequest>
			</PopUpModal>
			<PopUpModal show={showCallLogModel.show}>
				<EngageLogRequest
					show={showCallLogModel.show}
					docID={showCallLogModel.recordID}
					handleClose={handleCallLogsModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				>
				</EngageLogRequest>
			</PopUpModal>
			<PopUpModal show={showAssignLeadModel.show}>
				<AssignLead
					show={showAssignLeadModel.show}
					docID={showAssignLeadModel.recordID}
					title={titleLeadModel ? titleLeadModel : 'New Lead Assigned'}
					handleClose={handleAssignModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
					finance={financeModel}
					afterMarket={afterMarketModel}
				>
				</AssignLead>
			</PopUpModal>
			<PopUpModal show={showInboundLeadModel.show}>
				<InBoundLeadConvert
					show={showInboundLeadModel.show}
					docID={showInboundLeadModel.recordID}
					handleModalClose={handleInboundModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					clientModule={clientModule}
					//loader={convertLeadLoader}
					//stage={inboundModal.stage}					
					//pipeline={pipeline}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				/>
			</PopUpModal>
			<PopUpModal show={showInboundTIPModel.show}>
				<InBoundTIPConvert
					show={showInboundTIPModel.show}
					docID={showInboundTIPModel.recordID}
					handleModalClose={handleInboundModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				/>
			</PopUpModal>
			<PopUpModal show={showFleetModel.show}>
				<AddFleet
					show={showFleetModel.show}
					docID={showFleetModel.recordID}
					clsActive='overlay-modal active'
					stock={null}
					handleClose={handleFleetModelClose}
					title={'fleet'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				>
				</AddFleet>
			</PopUpModal>
			<PopUpModal show={showSMSModel.show}>
				<NewSMS
					show={showSMSModel.show}
					docID={showSMSModel.notifyID}
					title={'You have received a new message'}
					handleClose={handleSMSModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					//docID={notificationId}
					notification={objNotification}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				>
				</NewSMS>
			</PopUpModal>
			<PopUpModal show={showKitchenModel.show}>
				<CafeOrder
					show={showKitchenModel.show}
					docID={showKitchenModel.recordID}
					handleClose={handleKitchenModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
				//docID={notificationRecordId}
				>
				</CafeOrder>
			</PopUpModal>
			<PopUpModal show={showForceAlert}>
				<ForceUpdateAlert
					show={showForceAlert}
				/>
			</PopUpModal>
			<PopUpModal show={sleepMode}>
				<SleepMode
					show={sleepMode}
					handleClose={sleepModeClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					unReadChatCounts={unReadChatCounts}
					smsNotificationCount={smsNotificationCount}
					notificationCount={notificationCount}
					history={history}
				/>
			</PopUpModal>

			<PopUpModal show={showServiceRequestModel.show}>
				<NotifyServiceRequest
					show={showServiceRequestModel.show}
					docID={showServiceRequestModel.recordID}
					title={'Service Request'}
					handleClose={handleServiceModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					//docID={notificationRecordId}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
				>
				</NotifyServiceRequest>
			</PopUpModal>

			<PopUpModal show={showTradeinProRequestModel.show}>
				<NotifyTradeinProRequest
					show={showTradeinProRequestModel.show}
					docID={showTradeinProRequestModel.recordID}
					title={'Appraisal Received'}
					handleClose={handleServiceModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					//docID={notificationRecordId}
					history={history}
					clientModule={clientModule}
					setNotifyClose={(val) => { setNotifyClose(val) }}
					handleModuleChange={handleModuleChange}
				>
				</NotifyTradeinProRequest>
			</PopUpModal>

			<PopUpModal show={showAircallNotifyModel.show}>
				<NotifyAircall
					show={showAircallNotifyModel.show}
					docID={showAircallNotifyModel.notifyID}
					handleClose={handleServiceModelClose}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					//docID={notificationId}
					history={history}
					setNotifyClose={(val) => { setNotifyClose(val) }}
					dialNumber={dialNumber}
					aircallModal={aircallModal}
					updateAircallModel={updateAircallModel}
					dialRingcentral={dialRingcentral}
					dialJustcall={dialJustcall}
					ringcentralModal={ringcentralModal}
					updateRingcentralModel={updateRingcentralModel}
				>
				</NotifyAircall>
			</PopUpModal>

			<PopUpModal show={showReminderModel}>
				<ReminderPopUp
					show={showReminderModel}
					handleClose={() => {
						setReminderActivity(null)
						setShowReminderModel(false)
					}}
					dealersettings={dealersettings}
					activity={reminderActivity}
					handleViewActivity={(rec) => {
						//setRecordId(rec.documentID);
						setShowActivityModel({ show: true, notifyID: null, recordID: rec.documentID });
						setReminderActivity(null)
						setShowReminderModel(false)
					}}
				>
				</ReminderPopUp>
			</PopUpModal>
			<PopUpModal show={showNotifyModel.show}>
				{showNotifyModel?.show && (<NotifyPopUp
					show={showNotifyModel.show}
					notification={showNotifyModel.notification}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					handleClose={handleServiceModelClose}
					handleNotifyClick={handleNotifyClick}
				>
				</NotifyPopUp>)}
			</PopUpModal>
			<PopUpModal show={showChatModel.show}>
				{showChatModel?.show && (<ChatPopUp
					show={showChatModel.show}
					notification={showChatModel.notification}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					handleClose={handleServiceModelClose}
					handleNotifyClick={handleNotifyClick}
				>
				</ChatPopUp>)}
			</PopUpModal>

			<PopUpModal show={showEmailModel.show}>
				{showEmailModel?.show && (<EmailPopUp
					{...showEmailModel}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					handleClose={() => { setShowEmailModel({ show: false }) }}
				>

				</EmailPopUp>)}
			</PopUpModal>

			<PopUpModal show={fleetAssignModal.show}>
				<AssignFleet
					docID={fleetAssignModal.docID}
					fleetProID={fleetAssignModal.fleetProID}
					show={fleetAssignModal.show}
					clsActive='overlay-modal active'
					handleClose={handleFleetAssignClose}
					title={fleetAssignModal.docID ? 'fleet' : 'assignFleet'}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
				>
				</AssignFleet>
			</PopUpModal>
			<PopUpModal show={saleshubcommentsModel.show}>
				{saleshubcommentsModel?.show && (<FieldCommentsPopUp
					show={saleshubcommentsModel.show}
					notification={saleshubcommentsModel.notification}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					history={history}
					clientModule={clientModule}
					handleClose={handleServiceModelClose}
					handleNotifyClick={handleNotifyClick}
				>
				</FieldCommentsPopUp>)}
			</PopUpModal>
		</>
	);
}
