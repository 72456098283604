import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { default as _images } from '../../images';
import { Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import FullheightIframe from './resizableIFrame';
import FileItem from './fileItem';
import Swal from 'sweetalert2';
import _ from 'lodash';
import CommonHelper from '../../services/common';

const EmailListItem = ({
	message,
	isMsgLatest,
	updateThread,
	selectedThread,
	token,
	disableLinkedinfo,
	dealersettings,
	userData,
	handleOpenComposeModal,
	history,
	selectedCustomer,
	selectedEnquiry,
	clientUsers,
	groupUsers
}) => {
	const [expand, setExpand] = useState(isMsgLatest);
	const nylasEmail = dealersettings.nylas_v3 && dealersettings.nylas_v3.email_address ? dealersettings.nylas_v3.email_address : dealersettings.email
	const _allUser = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
	const handleToggleExpand = () => {
		if (isMsgLatest) {
			return;
		}

		setExpand(!expand);
	};

	const handleReply = isReplyToAll => {
		if (_.isEmpty(dealersettings.nylas_v3?.grantId)) {
			Swal.fire({
				title: CommonHelper.showLocale({ dealersettings }, 'Please configure email settings'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Add Settings'),
				cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
			}).then(result => {
				if (result.value) {
					history.push('/settings/profile?key=emailsettings');
				}
			});
			return
		}
		let lastMessage = message;

		let replyEmailDate = lastMessage.date;
		let replyEmailName = lastMessage.from[0].name;
		let replyEmailAddress = lastMessage.from[0].email;
		let replyToMessageId = lastMessage.id;
		let replyToEmailContent = lastMessage.body;
		let replyTo;
		let replyToCC;
		let replyToAll;
		let subject = lastMessage.subject;


		if (isReplyToAll) {
			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			if (lastMessage.replyTo.length > 0) {
				replyTo = [
					{ name: lastMessage.replyTo[0].name, email: lastMessage.replyTo[0].email },
				];
			}

			replyToCC = message.cc.filter((participant, i) => {
				if (participant.email === nylasEmail) {
					return false;
				}
				if (participant.email === replyTo[0].email) {
					return false;
				}

				return true;
			});

			replyToAll = true;
		} else {
			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			replyToAll = false;
		}

		handleOpenComposeModal({
			type: 'reply',
			title: 'Reply',
			emailData: {
				replyTo,
				replyToMessageId,
				replyToEmailContent,
				replyEmailDate,
				replyEmailName,
				replyEmailAddress,
				replyToAll,
				replyToCC,
				subject
			},
			contact: selectedCustomer,
			enquiry: selectedEnquiry
		});
	}

	return (
		<div key={message.id} className={`email-loop ${isMsgLatest ? '' : 'pb-2'}`} id={`message-${message.id}`}>
			<div className={`email-details-subhead ${isMsgLatest ? '' : 'email-thread-hilight'}`}>
				<div className='email-loop-head'>
					<div className='email-loop-avatar' onClick={handleToggleExpand}>
						{/* <img src={CommonHelper.getUserImagebyId(_allUser, (_resPerson ? _resPerson : addedBy))} alt="" width="50" height="50" className="rounded-circle img-object-fit" /> */}
						<img className='bdrs-50p w-3r h-3r' alt='' src={CommonHelper.getUserImagebyId(_allUser, message.from[0].email, message.from[0]?.name)} />
					</div>
					<div className='email-loop-deatils'>
						<table width='100%' border='0' cellSpacing='0' cellPadding='0'>
							<tbody>
								<tr>
									<td onClick={handleToggleExpand}>
										<div className='email-details-username'>
											{`${message.from && message.from.length > 0 && message.from[0]?.name ? message.from[0].name : ''} `}
											<span>&lt;{message.from[0].email}&gt;</span>
										</div>
									</td>
									<td className='td-mail-controls'>
										<div className='mail-controls-inner'>
											{message?.attachments?.length > 0 ? (
												<span className='mail-attach-icon' onClick={handleToggleExpand}>
													<i className='ico icon-mail-attachment-linear'></i>
												</span>
											) : (
												<></>
											)}
											<span className='email-datetime' onClick={handleToggleExpand}>
												{`${moment
													.unix(message.date)
													.format('MMM DD, YYYY hh:mm A')} (${moment
														.unix(message.date)
														.fromNow()})`}
											</span>
											{expand ? (
												<>
													<div
														className='mail-control-icon'
														onClick={() => handleReply(false)}
													>
														<a href='#' onClick={(e) => { e.preventDefault(); }}>
															<i className='ico icon-mail-reply'></i>
														</a>
													</div>

													<Dropdown className='mail-control-more'>
														<Dropdown.Toggle as={CustomToggle} className=''>
															<i className='ico icon-more'></i>
														</Dropdown.Toggle>
														<Dropdown.Menu
															as={CustomMenu}
															ChildClass='more-dropdown'
															xplacement='bottom-end'
														>
															<Dropdown.Item
																eventKey='1'
																onClick={() => handleReply(false)}
																className='check'
															>
																<i className='ico icon-mail-reply'></i>{' '}
																<span className='pl-0'>Reply</span>{' '}
															</Dropdown.Item>
															<Dropdown.Item
																eventKey='1'
																onClick={() => handleReply(true)}
																className='check'
															>
																<i className='ico icon-mail-replyall'></i>{' '}
																<span className='pl-0'>Reply To All</span>{' '}
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</>
											) : (
												<></>
											)}
										</div>
									</td>
								</tr>
								{expand ? (
									<>
										{message.to.length > 0 && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														To: {message.to && message.to.length > 0 && message.to.map(item => item.name && item.email && item.name !== item.email ? `${item.name} <${item.email}>` : item.email ? `${item.email}` : '').join(', ')}
													</div>
												</td>
											</tr>
										)}
										{message.cc.length > 0 && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														cc: {message.cc[0].email}
													</div>
												</td>
											</tr>
										)}
										{message.bcc.length > 0 && (
											<tr onClick={handleToggleExpand}>
												<td colSpan='2'>
													<div className='email-details-secondary-name'>
														bcc: {message.bcc[0].email}
													</div>
												</td>
											</tr>
										)}
									</>
								) : (
									<></>
								)}
							</tbody>
						</table>
						{expand ? (
							<></>
						) : (
							<>
								<div
									className='email-details-secondary-name'
									style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
									onClick={handleToggleExpand}
								>
									{message.snippet.substring(0, 100) + '...'}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{expand ? (
				<>
					{
						disableLinkedinfo ? (
							<FullheightIframe
								message={message}
								accountID={message.grantId}
								threadID={message.threadId}
								messageID={message.id}
								token={selectedThread.token ? selectedThread.token : token}
								apiURL={dealersettings.apiURL}
								projectId={dealersettings?.client?.projectId}
								linkedID={selectedThread.documentID}
								nylasToken={dealersettings.nylasv3Config?.apiKey || null}
								nylasURL={dealersettings.nylasv3Config?.apiUri || null}
							/>
						) :
							(<FullheightIframe
								message={message}
								accountID={message.grantId}
								threadID={message.threadId}
								messageID={message.id}
								token={selectedThread.token ? selectedThread.token : token}
								apiURL={dealersettings.apiURL}
								projectId={dealersettings?.client?.projectId}
								nylasToken={dealersettings.nylasv3Config?.apiKey || null}
								nylasURL={dealersettings.nylasv3Config?.apiUri || null}
							/>)
					}

					{message?.attachments?.length > 0 && (
						<div className='email-details-attachment'>
							<div className="mailbox-attachments-title">Attachments</div>
							<ul className='mailbox-attachments attachment-flex-layout clearfix'>
								{message.attachments.map(file => (
									<FileItem
										file={file}
										key={file.id}
										token={token}
										apiURL={dealersettings.apiURL}
										message={message}
										nylasToken={dealersettings.nylasv3Config?.apiKey || null}
										nylasURL={dealersettings.nylasv3Config?.apiUri || null}
									/>
								))}
							</ul>
						</div>
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default EmailListItem;
