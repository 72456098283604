import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';

import { StageProvider, StageConsumer } from '../pipeline/provider'
import { ContentOverlay, SidePanel, PopUpModal, TableView } from '../../components'
import QuickView from "../pipeline/quickview";
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert'
import Translate from '../../constants/translate';
import { stockStatus, inboundLeadStatus, eventAllocationStatus, eventStatus } from '../../services/enum'
import FilterPanel from './filter'
import EnquiryListReducer from '../fleet/fleetReducer'
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import Allocation from './allocation';
import AttendeeInfo from './details'
import { allUnallocatedFields, allAllocatedFields } from './viewModel'
import ReAuthenticate from '../common/reAuthenticate';
import { levelOptions } from '../eventsProTemplates/viewModel';


const AtttListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [contacts, dispatch] = useReducer(EnquiryListReducer, { unallocated: [], allocated: [] })
    const [checkDataLoad, setDataload] = useState({ "unallocated": true, "allocated": false })
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [unallocatedAttnFields, setUnallocatedFields] = useState([])
    const [allocatedAttnFields, setAllocatedFields] = useState([])
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])

    const [activeTab, setActiveTab] = useState('unallocated')

    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.attendeeSearchText ? localStorage.attendeeSearchText : null)
    const [contactLoader, setContactLoader] = useState({ "unallocated": true, "allocated": false })

    const [defaultPipeline, setDefaultPipeline] = useState('LeadsBucket')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [pipelineCount, setPipelineCount] = useState(0)
    const [inboundCount, setInboundCount] = useState({ unallocated: 0, allocated: 0 })


    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const [events, setEvents] = useState([])
    const [filterEvent, setFilterEvent] = useState('')
    const [eventsModal, setEventsModal] = useState({ show: false, docID: null })
    const [eventSettings, setEventSettings] = useState()


    const [showReAuthentication, setReAuthentication] = useState(false);
    const [inboundModal, setInboundModal] = useState({ show: false, docID: null })
    const [showOEMLeadConvert, setshowOEMLeadConvert] = useState({ show: false, fields: null, attendees: null, isReAssign: false })

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const defaultCountry = props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const respMins = 15
    const [_allUnallocatedFields, setAllUnAllocatedFields] = useState(allUnallocatedFields)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
            }

            if (searchText === '')
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    useEffect(() => {
        const interval = setInterval((enq) => {
            if (enq.allocated && enq.allocated.length > 0) {
                let _enquiries = enq.allocated.map(rec => {
                    return rec.allocationStatusValue === 'pending' ? {
                        ...rec,
                        unActioned: diffinMins(rec)
                    } : { ...rec }
                });
                dispatch({
                    type: "UPDATE_ALL_LIST",
                    data: _enquiries,
                    activetab: 'allocated'
                });
            }
        }, 90000, contacts);
        //60000 1min
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [contacts])

    // useEffect(() => {
    //     if (isFilterApplied || props.pipeline.length === 0)
    //         return
    //     const { dealersettings } = props;
    //     const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
    //         !_.isEmpty(dealersettings.rolePermissions) &&
    //         !_.isEmpty(dealersettings.rolePermissions.permissions) &&
    //         dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
    //     const dpl = 'LeadsBucket'
    //     var querySet = `clients/${dealersettings.client.id}`
    //     let accessSalesType = [];
    //     // if (!viewOtherEnquiry) {
    //     //     querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
    //     // }
    //     if (dealersettings && localStorage.defaultModule === 'oem') {
    //         if (dealersettings.level === 'oem' && dealersettings.oemID)
    //             querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
    //         else if (dealersettings.level === 'group' && dealersettings.groupID)
    //             querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
    //         else if (dealersettings.level === 'region' && dealersettings.regionID)
    //             querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
    //     }

    //     window.unSubPipelineLstCount = window.firebase.firestore().doc(querySet)
    //         .onSnapshot(docSnapshot => {
    //             var stageCount = {};
    //             let _selectedPipeline = props.pipeline.find(item => item.value === 'LeadsBucket');
    //             if (docSnapshot.exists && docSnapshot.data() && docSnapshot.data().inbound && docSnapshot.data().inbound[dpl] && docSnapshot.data().inbound[dpl].stages) {
    //                 _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
    //                     stageCount = {
    //                         ...stageCount,
    //                         [stage.value]: {
    //                             ...docSnapshot.data().inbound[dpl].stages[stage.value],
    //                             ['total']: sumObj(docSnapshot.data().inbound[dpl].stages[stage.value], accessSalesType, stage.value)
    //                         }
    //                     }
    //                 })
    //             }
    //             if (stageCount) {
    //                 var pending = 0;
    //                 var converted = 0;
    //                 // 'Contacted', 
    //                 for (let [key, value] of Object.entries(stageCount)) {
    //                     if ((_selectedPipeline ? _selectedPipeline.stages.map(r => { return r.value }) : ['New', 'Contacted', 'InboundLead', 'Converted', 'Archived']).indexOf(key) >= 0) {
    //                         if (key === 'Converted') {
    //                             converted += parseFloat((value && value.total) ? value.total : 0);
    //                         }
    //                         else {
    //                             pending += parseFloat((value && value.total) ? value.total : 0);
    //                         }
    //                     }
    //                 }
    //                 setInboundCount({
    //                     allocated: converted,
    //                     unallocated: pending
    //                 });
    //             }
    //             setPipelineCount(stageCount)

    //         })
    // }, [props.pipeline, isFilterApplied])

    const sumObj = (obj, arrSaleType, stage) => {
        var sum = 0;
        if (stage === 'InboundLead' && !_.isEmpty(arrSaleType)) {
            arrSaleType = [...arrSaleType, 'na'];
        }
        for (var el in obj) {
            if (_.isNumber(obj[el]) && (_.isEmpty(arrSaleType) || arrSaleType.some(a => a === el))) {
                sum += parseFloat(obj[el] ? obj[el] : 0);
            }
        }
        return sum;
    }



    useEffect(() => {
        let headerFields = (activeTab === 'unallocated') ? unallocatedAttnFields : allocatedAttnFields;
        let allHeaderFields = (activeTab === 'unallocated') ? _allUnallocatedFields : allAllocatedFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [unallocatedAttnFields, activeTab])

    useEffect(() => {
        if (_.isEmpty(eventSettings?.attendeesDF)) {
            setAllUnAllocatedFields([...allUnallocatedFields]);
            return
        }
        let __allUnallocatedFields = [...allUnallocatedFields];
        eventSettings.attendeesDF.forEach(rec => {
            if (!__allUnallocatedFields.some(e => e.value === rec.value))
                __allUnallocatedFields.push({
                    name: rec.name,
                    value: rec.value,
                    flex: 1,
                    default: true,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        __allUnallocatedFields.forEach(rec => {
            if (!__allUnallocatedFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setAllUnAllocatedFields([...__allUnallocatedFields]);
        setUnallocatedFields(_.differenceBy(unallocatedAttnFields, deleteids))
        //console.log('eventSettings.attendeesDF', filterEvent, eventSettings.attendeesDF, __allUnallocatedFields, allUnallocatedFields)
    }, [filterEvent])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })

            const _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    country_code: doc.country_code
                });
            });


            setCountries(_countries);
            setStates(_states);


            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })

            refreshStageCount();
        }

        loadEvents();
    }, [])

    const loadEvents = async () => {
        // let allEventProDealers = CommonHelper.getAllEventsProDealers(props.dealersettings, true);
        // var collectionPromise = [];
        // for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {
        //     let refEventsData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
        //         .where('isDeleted', '==', false)
        //         .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
        //         .where('status', 'in', [eventStatus.LIVE, eventStatus.CLOSED])

        //     collectionPromise.push(refEventsData ? collection(refEventsData) : of([]));
        // }
        const collectionPromise = [];
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index)
        _userLevel.forEach(level => {
            let _levelIDs = CommonHelper.getAllEventsProByLevel(props.dealersettings, level.value, true, true);
            for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {
                let refEventsData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
                    .where('isDeleted', '==', false)
                    .where(`${level.value === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                    .where('status', 'in', [eventStatus.LIVE, eventStatus.CLOSED])
                collectionPromise.push(refEventsData ? collection(refEventsData) : of([]));
            }
        });
        if (collectionPromise.length > 0) {
            window.unsubrefLiveEventsData = combineLatest(collectionPromise)
                .subscribe(async allDocs => {
                    let _events = [];
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;
                    querySnapshot.forEach((rec) => {
                        const _event = rec.data();
                        let _objCurrentStatus = eventsProStatus.filter(e => e.value === _event.status)[0];
                        if (_event.pipeline)
                            _events.push({
                                label: <>
                                    {_event.title} {
                                        _objCurrentStatus
                                            ?
                                            <>
                                                <div className={`badge badge-pill badge-status-empty`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                    {_objCurrentStatus.name}
                                                </div>
                                            </>
                                            :
                                            <>{_event.status ? <div className={`badge badge-pill badge-status-${_event.status.toLowerCase()}`} >{_event.status} </div> : <></>}</>
                                    }
                                </>,
                                searchlabel: _event.title,
                                value: rec.id,
                                addedDate: _event.addedDate,
                                clientIDs: _event.clientIDs,
                                obj: _event
                            })
                    })
                    _events = _.orderBy(_.uniqBy(_events, 'value'), ["addedDate"], ["desc"])
                    setEvents(_events);
                    if (_.isEmpty(filterEvent) || _.isEmpty(eventSettings) || (filterEvent && !_.find(_events, { value: filterEvent }))) {
                        let __eventSettings = await loadEventSettings(_events[0]?.value);
                        setEventSettings(__eventSettings)
                        setFilterEvent(_events[0]?.value)
                        setDataload({
                            ...checkDataLoad,
                            [activeTab]: true
                        })
                        setContactLoader({
                            ...contactLoader,
                            [activeTab]: true
                        })
                    }

                    if (_events.length === 0) {
                        setContactLoader({
                            ...contactLoader,
                            [activeTab]: false
                        })
                        setDataload({
                            ...checkDataLoad,
                            [activeTab]: false
                        })
                    }
                    window.unsubrefLiveEventsData && window.unsubrefLiveEventsData.unsubscribe();
                });
        }

        return () => {
            window.unsubrefLiveEventsData && window.unsubrefLiveEventsData.unsubscribe();
        }
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'eventsProAttendees')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubAttnAllocList && window.unsubAttnAllocList();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        if (localStorage.attendeeFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.attendeeFilter])


    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        if (_.isEmpty(filterEvent)) {
            setContactLoader({
                ...contactLoader,
                [activeTab]: false
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            return;
        }

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }
        if (isFilterApplied && pageNum === 0) {
            setContactLoader({
                ...contactLoader,
                [activeTab]: true
            })
        }


        let searchKeywords = searchText;
        let selectEvent = filterEvent;
        let searchClients = [];


        let attlstDataRef = firestoreDB(dealersettings).firestore().collection('eventsProAttendees')
            .where('eventsProID', '==', selectEvent)
            .where('isDeleted', '==', false)

        let _filter = null;
        if (localStorage.attendeeFilter) {
            let _contactFilter = JSON.parse(localStorage.attendeeFilter);
            _filter = Object.assign({}, _contactFilter.value);

            searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
            let _allEventDealer = Object.assign([], dealersettings?.clients?.filter(c => c?.moduleSettings?.eventsPro?.enabled === true && c?.moduleSettings?.eventsPro?.active === true))
            if (_filter['group'] && !_filter['dealer']) {
                let _sub = Object.assign([], _filter['group'].split(','))
                searchClients = Object.assign([], _allEventDealer.filter(c => _sub.includes(c.group)).map(item => item.id))
            }
            else if (_filter['subregion'] && !_filter['dealer']) {
                let _sub = Object.assign([], _filter['subregion'].split(','))
                searchClients = Object.assign([], _allEventDealer.filter(c => _sub.includes(c.subregion)).map(item => item.id))
            } else if (_filter['region'] && !_filter['dealer']) {
                let _regs = Object.assign([], _filter['region'].split(','))
                searchClients = Object.assign([], _allEventDealer.filter(c => _regs.includes(c.region)).map(item => item.id))
            }
            else {
                searchClients = _filter['dealer'] ? _filter['dealer'].split(",") : searchClients;
            }

            setSearchText(searchKeywords);
            localStorage.attendeeSearchText = searchKeywords;

            if (_filter.isNew) attlstDataRef = attlstDataRef.where('isNew', '==', _filter.isNew)
            if (_filter.isCheckedIn) attlstDataRef = attlstDataRef.where('isCheckedIn', '==', _filter.isCheckedIn)
            if (_filter.isTestDrive) attlstDataRef = attlstDataRef.where('isTestDrive', '==', _filter.isTestDrive)
            if (_filter.status) attlstDataRef = attlstDataRef.where('status', 'in', _filter['status'].split(","))
            if (_filter.allocationStatus) attlstDataRef = attlstDataRef.where('allocationStatus', '==', _filter.allocationStatus)
            if (_filter.leadStatus) attlstDataRef = attlstDataRef.where('leadStatus', 'in', _filter['leadStatus'].split(","))
            if (_filter.dietaryPreference) attlstDataRef = attlstDataRef.where('dietaryPreference', 'in', _filter['dietaryPreference'].split(","))
            if (_filter.eventSlot) attlstDataRef = attlstDataRef.where('eventSlot', 'in', _filter['eventSlot'].split(","))
            if (_filter.dataLocation) attlstDataRef = attlstDataRef.where('dataLocation', 'in', _filter['dataLocation'].split(","))

            if (!_.isEmpty(_filter.dynamicFields)) {
                _filter.dynamicFields.forEach(info => {
                    if (['select'].includes(info.type) && info.value)
                        attlstDataRef = attlstDataRef.where(`dynamicFields.${info.name}`, 'in', info.value.split(","))
                    else if (['multiselect'].includes(info.type) && info.value)
                        attlstDataRef = attlstDataRef.where(`dynamicFields.${info.name}`, 'array-contains-any', info.value.split(","))
                    else if (['monthYear', 'price', 'number', 'text', 'toggle', 'switch'].includes(info.type) && info.value)
                        attlstDataRef = attlstDataRef.where(`dynamicFields.${info.name}`, '==', info.value)
                    // else if (info.type === 'date' && info.startDate && info.endDate) {
                    //     attlstDataRef = attlstDataRef.where(`dynamicFields.${info.name}`, '>=', window.firebase.firestore.Timestamp.fromDate(moment(info.startDate).startOf('day')._d))
                    //     attlstDataRef = attlstDataRef.where(`dynamicFields.${info.name}`, '<=', window.firebase.firestore.Timestamp.fromDate(moment(info.endDate).endOf('day')._d))
                    // }
                })

            }
            //console.log('search---Keywords', _filter)
        }



        if (searchKeywords) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            if (formattedSearchText.trim())
                attlstDataRef = attlstDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }

        if (!_.isEmpty(searchClients))
            attlstDataRef = attlstDataRef.where('clientID', 'in', CommonHelper.arrLimit(searchClients, 30))

        if (activeTab === 'allocated') {
            attlstDataRef = attlstDataRef
                .where('allocationStatus', 'in', [eventAllocationStatus.PENDING, eventAllocationStatus.ACCEPTED])

            if (_.isEmpty(_filter) && _.isEmpty(searchKeywords))
                attlstDataRef = attlstDataRef.orderBy('allocationDate', 'desc');
        }
        else {
            attlstDataRef = attlstDataRef.where('allocationStatus', 'in', [eventAllocationStatus.NEW])

        }

        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            attlstDataRef = attlstDataRef
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            attlstDataRef = attlstDataRef
                .limit(pageLimit)
        }

        window.unsubAttnAllocList = attlstDataRef
            .onSnapshot(onCollectionUpdate);



    }, [checkDataLoad, activeTab, filterEvent])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        let _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                _enquiries.push(convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id,
                    stageDate: doc.data().stageDate ? doc.data().stageDate : doc.data().addedDate,
                }));
            });
        }


        // if (activeTab === 'unallocated')
        //     _enquiries = _enquiries.filter(m => m.stage !== 'Archived')

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _enquiries
                    .filter(a => _.isEmpty(a.clientID) ||
                        (activeTab === 'unallocated' ? props.dealersettings?.clients?.some(b => b.documentID === a.clientID) :
                            props.dealersettings?.clients?.some(b => b.documentID === a.toClientID))),
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",//"SUCCESS_LIST",//"APPEND_LIST",
                data: actionType ? snapshotDoc : _enquiries
                    .filter(a => _.isEmpty(a.clientID) ||
                        (activeTab === 'unallocated' ? props.dealersettings?.clients?.some(b => b.documentID === a.clientID) :
                            props.dealersettings?.clients?.some(b => b.documentID === a.toClientID))),
                activetab: activeTab
            });
        }


        setContactLoader({
            ...contactLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }

    const convertEnquiryVM = (doc) => {

        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        if (!doc.allocationDate) {
            doc.allocationDate = doc.allocationDate;
        }

        const listVM = Object.assign({});
        for (let [key, value] of Object.entries(doc)) {
            //if (listVM.hasOwnProperty(key))
            listVM[key] = value;
        }

        listVM.objDoc = Object.assign({}, doc);
        listVM.displayID = listVM.displayID ? listVM.displayID.toUpperCase() : '--';
        listVM.allocationDateValue = doc.allocationDate;
        listVM.actionedOnValue = doc.actionedOn;

        let dealersettings = props.dealersettings;
        const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
        listVM.statusValue = doc.status;
        //listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : CommonHelper.getNameByValue(enquiryAllStatus, doc.status); 

        listVM.firstName = _.trim(doc.firstName);
        listVM.phone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings);
        listVM.displayPhone = CommonHelper.phoneFormat(doc.phoneCode, doc.phone, props.dealersettings, true);
        listVM.displayID = listVM.displayID ? listVM.displayID : '';
        listVM.licenseExpiry = listVM.licenseExpiry ? moment(listVM.licenseExpiry).format('DD/MM/YYYY') : '';
        listVM.createdOn = listVM.addedDate ? moment.unix(listVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = listVM.modifiedDate ? moment.unix(listVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.checkedOn = listVM.checkinDate ? moment.unix(listVM.checkinDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, listVM.addedBy);
        listVM.modifiedBy = CommonHelper.getUserNamebyId(allUsers, listVM.modifiedBy);
        listVM.owner = listVM.owner ? CommonHelper.getUserNamebyId(allUsers, listVM.owner) : '';

        listVM.country = CommonHelper.getLabelByValue(countries, listVM.country, '');
        listVM.state = CommonHelper.getLabelByValue(states.filter(a => doc?.country ? a.country_code === doc.country : true), listVM.state, '');
        listVM.licenseState = CommonHelper.getLabelByValue(states.filter(e => e.country_code === defaultCountry), doc.licenseState, '');
        listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''

        listVM.source = CommonHelper.getNameByValue(eventSettings?.source, listVM.source, '');
        listVM.dietaryPreference = CommonHelper.getNameByValue(eventSettings?.dietary, listVM.dietaryPreference, '');
        listVM.eventSlot = CommonHelper.getNameByValue(eventSettings?.slots, listVM.eventSlot, '');
        listVM.dataLocation = CommonHelper.getNameByValue(eventSettings?.dataLocation, listVM.dataLocation, '');
        if (!_.isEmpty(listVM.notes)) listVM.lastNote = _.orderBy(listVM.notes, ["addedDate"], ["desc"])[0]?.notes;

        let _setttings = listVM.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[listVM.clientID] && dealersettings.group.clients[listVM.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        listVM.fullName = CommonHelper.getFullName(null, listVM, '', true);
        listVM.gender = CommonHelper.getNameByValue(_setttings?.genders, listVM.gender, '');
        listVM.licenseType = CommonHelper.getNameByValue(_setttings?.licenseType, listVM.licenseType, '');

        if (listVM.clientID && !listVM.clientName)
            listVM.clientName = CommonHelper.getOtherDealerName(dealersettings, listVM.clientID);

        if (listVM.toClientID && !listVM.toClientName)
            listVM.toClientName = CommonHelper.getOtherDealerName(dealersettings, listVM.toClientID);

        listVM.optinPhone = doc.optinPhone === null ? '' : (doc.optinPhone ? 'Yes' : 'No');
        listVM.optinEmail = doc.optinEmail === null ? '' : (doc.optinEmail ? 'Yes' : 'No');
        listVM.optinPost = doc.optinPost === null ? '' : (doc.optinPost ? 'Yes' : 'No');
        listVM.optinSMS = doc.optinSMS === null ? '' : (doc.optinSMS ? 'Yes' : 'No');
        listVM.marketingOptinEmail = doc.marketingOptinEmail === null ? '' : (doc.marketingOptinEmail ? 'Yes' : 'No');
        listVM.marketingOptinSMS = doc.marketingOptinSMS === null ? '' : (doc.marketingOptinSMS ? 'Yes' : 'No');


        listVM.leadStatusName = CommonHelper.getNameByValue(enquiryAllStatus, doc.leadStatus, '');
        let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
        listVM.leadStatus = !_.isEmpty(doc.leadStatus) ?
            (
                _objCurrentStatus
                    ?
                    <>
                        <span key={doc.leadStatus}
                            className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                    </>
            ) : ('');
        listVM.statusName = doc.status;
        listVM.status = (listVM.status
            ?
            <div key={listVM.status} className={`badge badge-pill badge-events-${listVM.status}`}>
                {listVM.status}
            </div>
            :
            <></>);

        listVM.checkedStatusName = listVM.checkinDate ? 'CHECKED-IN' : '';
        listVM.checkedStatus = listVM.checkinDate ? <><div className="badge badge-pill badge-available-fill ">CHECKED-IN</div></> : <></>;
        listVM.guestTag = listVM.guestOf ? <><div className="badge badge-pill badge-white badge-mini">{listVM.guestOf}</div></> : <></>;

        listVM.allocationStatusValue = doc.allocationStatus;
        listVM.allocationStatusName = doc.allocationStatus;
        listVM.allocationStatus = (listVM.allocationStatus
            ?
            <div key={listVM.allocationStatus} className={`badge badge-pill status-${listVM.allocationStatus}`}>
                {listVM.allocationStatus}
            </div>
            :
            <></>);
        if (listVM.allocationStatusValue === 'pending') {
            listVM.unActioned = diffinMins(listVM);
            listVM.strunActioned = diffinMins(listVM, 'string');
        }
        else {
            listVM.unActioned = null;
            listVM.actionedOn = doc.actionedOn ? moment.unix(doc.actionedOn.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }

        doc.responseTime = getresponseTime(listVM);

        if (!_.isEmpty(doc.systemType)) {
            listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
            doc.tags = [{ type: doc.systemType }];
        }

        listVM.strResponseTime = doc.responseTime > 0 ? CommonHelper.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
        listVM.strAllocatedTime = doc.allocatedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
        listVM.strContactedTime = doc.contactedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : ''; listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        listVM.allocationDate = doc.allocationDate ? moment.unix(doc.allocationDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month').replace('an hour', '1 Hour')) : '');

        if (!_.isEmpty(doc.dynamicFields)) {

            eventSettings?.attendeesDF && eventSettings.attendeesDF.forEach(rec => {
                if (rec.type === 'toggle') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'monthYear') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment(doc.dynamicFields[rec.value]).format('MMM YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<></>)
                }
                else {
                    listVM[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type) ?
                            (<div key={index}><span>{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>)
                            : (<></>)
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
            }).join(',');
        }

        listVM.requirements = CommonHelper.getAttendeReq(eventSettings, doc);
        if (listVM?.requirements?.length > 0) {
            listVM.vehicleModel = (listVM.requirements[0].make ? listVM.requirements[0].make + ' ' : '') + listVM.requirements[0].model;
            listVM.vehicleType = listVM.requirements[0].saleType;
        }

        return listVM
    }

    const diffinMins = (enq, str) => {
        const startTime = enq.allocationDateValue ? moment.unix(enq.allocationDateValue.seconds) : moment.unix(enq.addedDate.seconds);
        const duration = moment.duration(moment().diff(startTime));
        const minutes = duration.asMinutes();
        const result = moment(startTime).fromNow().replace('ago', '').replace('a month', '1 month').replace('a minute', '1 Min').replace('minutes', 'Mins').replace('hours', 'Hours');
        if (minutes > respMins)
            return str ? result : (<span className="blinking" style={{ color: '#fe0707' }}>{result}</span>)
        else
            return str ? result : (<span>{result}</span>)
    }

    const getresponseTime = (enq) => {
        const startTime = enq.allocationDateValue ? moment.unix(enq.allocationDateValue.seconds) : null;
        const endTime = enq.actionedOnValue ? moment.unix(enq.actionedOnValue.seconds) : null;
        if (_.isEmpty(startTime) || _.isEmpty(endTime))
            return 0;
        const duration = moment.duration(endTime.diff(startTime));
        const _seconds = duration.asSeconds();
        return _seconds;
    }

    const isValidFilter = () => {
        if (localStorage.attendeeFilter) {
            //let _pipelineFilter = JSON.parse(localStorage.attendeeFilter);
            //let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            //if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
            //    return false;

            return true;
        }
        else {
            return false;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        if (activeTab === 'unallocated')
            setUnallocatedFields(fields)
        else
            setAllocatedFields(fields)
        if (showToast)
            toast.notify('Settings updated successfully', {
                duration: 2000
            })
        //setSettingsLoader(true)
        // if (activeTab === 'unallocated') {
        //     window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
        //         .set({ unallocatedAttnFields: fields }, { merge: true })
        //         .then(() => {
        //             setUnallocatedFields(fields)
        //             setSettingsLoader(false)
        //             props.updateDealerSettings('unallocatedAttnFields', fields)
        //             if (showToast)
        //                 toast.notify('Settings updated successfully', {
        //                     duration: 2000
        //                 })

        //         }).catch(error => {
        //             setSettingsLoader(false)
        //             console.error(error)
        //         });
        // }
        // else {
        //     window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
        //         .set({ allocatedAttnFields: fields }, { merge: true })
        //         .then(() => {
        //             setAllocatedFields(fields)
        //             setSettingsLoader(false)
        //             props.updateDealerSettings('allocatedAttnFields', fields)
        //             if (showToast)
        //                 toast.notify('Settings updated successfully', {
        //                     duration: 2000
        //                 })

        //         }).catch(error => {
        //             setSettingsLoader(false)
        //             console.error(error)
        //         });
        // }
    }

    const handleActionClick = (id, obj) => {
        if (activeTab === 'unallocated') {
            let _objData = obj.objDoc ? obj.objDoc : obj;
            return (<div className="fleet-btn-loan width-fix">
                <button
                    style={{ cursor: 'pointer' }}
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                        e.preventDefault();

                        setshowOEMLeadConvert({
                            show: true,
                            fields: _objData,
                            attendees: null,
                            isReAssign: false
                        })
                    }}
                >
                    <Translate text={'allocate'} />
                </button>
            </div>)
        }
        else {
            return (
                <>
                    {
                        (!_.isEmpty(obj) && obj.allocationStatusValue === eventAllocationStatus.PENDING && obj.inboundID)
                            ?
                            <div title='reassign' style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                                e.preventDefault();
                                setshowOEMLeadConvert({
                                    show: true,
                                    fields: obj.objDoc ? obj.objDoc : obj,
                                    attendees: null,
                                    isReAssign: true
                                })
                            }}>
                                <i className="ico icon-reassign"></i>
                            </div>
                            :
                            <div title='reassign' style={{ cursor: 'not-allowed' }} className="mini-button"><i className="ico icon-lock"></i></div>
                    }

                    <div title='view' style={{ cursor: 'pointer' }} className="mini-button ml-2" onClick={(e) => {
                        e.preventDefault();
                        if (!_.isEmpty(obj) && obj.allocationStatusValue === eventAllocationStatus.PENDING && obj.inboundID)
                            handleShowInboundLead(obj.inboundID);
                        else if (!_.isEmpty(obj) && obj.allocationStatusValue === eventAllocationStatus.ACCEPTED && obj.enquiryID)
                            sidepanelOpen(obj.enquiryID)
                    }}>
                        <i className="ico icon-more"></i>
                    </div>



                </>)
        }

    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        handleEventOpen(id)
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const handleShowInboundLead = (id) => {
        //console.log(id);
        setInboundModal({ show: true, docID: id });
    }

    const handleCloseInboundLead = () => {
        setInboundModal({ show: false, docID: null })
    }

    const handleOEMLeadModalClose = (enqIds) => {
        if (!_.isEmpty(enqIds)) {
            for (let [key, value] of Object.entries(enqIds)) {
                var logVM = convertEnquiryVM(value);
                dispatch({
                    type: "REMOVE_LIST",
                    data: logVM,
                    activetab: activeTab
                });
            }

            refreshStageCount();
        }
        setshowOEMLeadConvert({
            show: false,
            fields: null,
            attendees: null,
            isReAssign: false
        })
        setSelectedIds({});
    }

    const refreshStageCount = () => {


        const { dealersettings, pipeline } = props;

        // if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client)) {
        //     try {

        //         let _searchObject = {
        //             "pipeline": 'LeadsBucket',
        //             "owner": localStorage.uid,
        //             "settingsID": dealersettings.oemID ? dealersettings.oemID : null
        //         }

        //         _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, pipelineView, null, true);

        //         const stageCountsBQ = window.firebase.functions().httpsCallable('contacts-stageCountsBQ');
        //         stageCountsBQ(_searchObject).then((response) => {
        //             //console.log('stageCountsBQ', _dpl, response);
        //         });
        //     }
        //     catch (error) {
        //         console.error(error)
        //     }
        // }
    }

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.attendeeFilter) {
                let _pipelineFilter = JSON.parse(localStorage.attendeeFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineFilter.value = _filter;
                handleApplyFilter(_pipelineFilter);
            }
            else {
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleSetActiveTab = (val) => {
        if (activeTab !== val) {
            setActiveTab(val);
            setDataload({
                ...checkDataLoad,
                [val]: true
            })
            setHasMoreData([...hasMore.filter(e => e.type !== val)]);
            setPageNum(0);
            setContactLoader({
                ...contactLoader,
                [val]: true
            });
            setSelectedIds({});
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('attendeeFilter', JSON.stringify(_filter));
            //localStorage.attendeeSearchText = '';
            setFilterApplied(true);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.attendeeFilter)) {
            localStorage.removeItem('attendeeFilter');
            localStorage.attendeeSearchText = '';
            setFilterApplied(false);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = convertEnquiryVM(objEnquiry);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: enq,
                    activetab: activeTab
                });

            }
        }

    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(contacts[activeTab]) && contacts[activeTab].map(file => {
                let record = Object.assign({}, contacts[activeTab].filter(e => e.documentID === file.documentID)[0]);
                if (!_.isEmpty(record))
                    _selectedIds[file.documentID] = record.objDoc;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, contacts[activeTab].filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleOpenAllocateModal = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to allocate.'));
            return;
        }
        else {
            // const _leadIds = Object.keys(_selectedIds).slice(0, 50).reduce((result, key) => {
            //     result[key] = _selectedIds[key];
            //     return result;
            // }, {});

            setshowOEMLeadConvert({
                show: true,
                fields: null,
                attendees: _selectedIds,
                isReAssign: false
            })
        }
    }

    const changeEvent = async (eventsProID) => {
        if (eventsProID) {
            let __eventSettings = await loadEventSettings(eventsProID);
            setEventSettings(__eventSettings)
            setFilterEvent(eventsProID);
            clearAll(true);
        }
        else {
            dispatch({
                type: "SUCCESS_LIST",
                data: [],
                activetab: activeTab
            });
        }
    }

    const loadEventSettings = async (eventsProID) => {
        let _eventSettings = null;
        const refSettingsData = await firestoreDB(props.dealersettings).firestore().doc(`eventsPro/${eventsProID}/settings/${eventsProID}`).get();
        if (refSettingsData.exists)
            _eventSettings = Object.assign({}, refSettingsData.data());

        return _eventSettings;
    }

    const clearAll = (isDataLoad) => {
        if (!_.isEmpty(localStorage.attendeeFilter))
            localStorage.removeItem('attendeeFilter');
        setFilterApplied(false);
        setContactLoader({
            ...contactLoader,
            [activeTab]: true
        })

        if (isDataLoad)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        setPaging(false);
        setPageNum(0);



    }

    const handleEventOpen = (docID) => {
        setEventsModal({ show: true, docID: docID })
    }
    const handleEventClose = () => {
        setEventsModal({ show: false, docID: null })
    }


    //#region RE-AUTHENTICATION

    const excelDownload = async () => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const { dealersettings } = props;
        let searchKeywords = searchText;
        let selectEvent = filterEvent;
        let searchClients = [];


        let refDataExport = firestoreDB(dealersettings).firestore().collection('eventsProAttendees')
            .where('eventsProID', '==', selectEvent)
            .where('isDeleted', '==', false)

        let _filter = null;
        if (localStorage.attendeeFilter) {
            let _contactFilter = JSON.parse(localStorage.attendeeFilter);
            _filter = Object.assign({}, _contactFilter.value);

            searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;

            if (_filter['subregion'] && !_filter['dealer']) {
                let _sub = Object.assign([], _filter['subregion'].split(','))
                searchClients = Object.assign([], dealersettings?.clients?.filter(c =>
                    c.moduleSettings
                    && c.moduleSettings.eventsPro
                    && c.moduleSettings.eventsPro.enabled === true
                    && c.moduleSettings.eventsPro.active === true
                    && _sub.includes(c.subregion)
                ).map(item => item.id))
            } else if (_filter['region'] && !_filter['dealer']) {
                let _regs = Object.assign([], _filter['region'].split(','))
                searchClients = Object.assign([], dealersettings?.clients?.filter(c =>
                    c.moduleSettings
                    && c.moduleSettings.eventsPro
                    && c.moduleSettings.eventsPro.enabled === true
                    && c.moduleSettings.eventsPro.active === true
                    && _regs.includes(c.region)
                ).map(item => item.id))
            }
            else {
                searchClients = _filter['dealer'] ? _filter['dealer'].split(",") : searchClients;
            }

            if (_filter.isNew) refDataExport = refDataExport.where('isNew', '==', _filter.isNew)
            if (_filter.isCheckedIn) refDataExport = refDataExport.where('isCheckedIn', '==', _filter.isCheckedIn)
            if (_filter.isTestDrive) refDataExport = refDataExport.where('isTestDrive', '==', _filter.isTestDrive)
            if (_filter.status) refDataExport = refDataExport.where('status', 'in', _filter['status'].split(","))
            if (_filter.allocationStatus) refDataExport = refDataExport.where('allocationStatus', '==', _filter.allocationStatus)
            if (_filter.leadStatus) refDataExport = refDataExport.where('leadStatus', 'in', _filter['leadStatus'].split(","))
            if (_filter.dietaryPreference) refDataExport = refDataExport.where('dietaryPreference', 'in', _filter['dietaryPreference'].split(","))
            if (_filter.eventSlot) refDataExport = refDataExport.where('eventSlot', 'in', _filter['eventSlot'].split(","))
            if (_filter.dataLocation) refDataExport = refDataExport.where('dataLocation', 'in', _filter['dataLocation'].split(","))

            if (!_.isEmpty(_filter.dynamicFields)) {
                _filter.dynamicFields.forEach(info => {
                    if (['select'].includes(info.type) && info.value)
                        refDataExport = refDataExport.where(`dynamicFields.${info.name}`, 'in', info.value.split(","))
                    else if (['multiselect'].includes(info.type) && info.value)
                        refDataExport = refDataExport.where(`dynamicFields.${info.name}`, 'array-contains-any', info.value.split(","))
                    else if (['monthYear', 'price', 'number', 'text', 'toggle', 'switch'].includes(info.type) && info.value)
                        refDataExport = refDataExport.where(`dynamicFields.${info.name}`, '==', info.value)
                    // else if (info.type === 'date' && info.startDate && info.endDate) {
                    //     refDataExport = refDataExport.where(`dynamicFields.${info.name}`, '>=', window.firebase.firestore.Timestamp.fromDate(moment(info.startDate).startOf('day')._d))
                    //     refDataExport = refDataExport.where(`dynamicFields.${info.name}`, '<=', window.firebase.firestore.Timestamp.fromDate(moment(info.endDate).endOf('day')._d))
                    // }
                })

            }
            //console.log('search---Keywords', _filter)
        }



        if (searchKeywords) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            if (formattedSearchText.trim())
                refDataExport = refDataExport.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }

        if (!_.isEmpty(searchClients))
            refDataExport = refDataExport.where('clientID', 'in', CommonHelper.arrLimit(searchClients, 10))

        if (activeTab === 'allocated') {
            refDataExport = refDataExport
                .where('allocationStatus', 'in', [eventAllocationStatus.PENDING, eventAllocationStatus.ACCEPTED])

            if (_.isEmpty(_filter) && _.isEmpty(searchKeywords))
                refDataExport = refDataExport.orderBy('allocationDate', 'desc');
        }
        else {
            refDataExport = refDataExport.where('allocationStatus', 'in', [eventAllocationStatus.NEW])

        }

        refDataExport.get().then(querySnapshot => {
            let _enquiries = [];
            querySnapshot.forEach(doc => {
                var logVM = convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _enquiries.push(logVM);
            });

            //console.log('refDataExport', _enquiries, csvHeader)
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            });

            // let _objLogData = {
            //     notes: 'Excel export from attendees',
            //     type: 'excelExport',
            //     subType: 'e',
            // }
            // _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            // CommonHelper.saveAuditLog(_objLogData);
        });

    }

    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }

    //#endregion

    return (
        <div className="middle-wrapper">

            <FilterPanel
                pipeline={props.pipeline}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                stageCounts={pipelineCount}
                inboundCount={inboundCount}
                csvHeader={csvHeader}
                csvData={csvData}
                //datalist={contacts}
                view="list"
                isFilterApplied={isFilterApplied}
                searchText={searchText}
                handlesearchText={(val) => {
                    //handleSearchText(val);
                    setSearchText(val)
                    clearAll();
                }}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                filterEvent={filterEvent}
                events={events}
                handleFilterEvent={(val) => {
                    changeEvent(val);
                }}
                selectedIds={selectedIds}
                handleOpenAllocateModal={handleOpenAllocateModal}
                eventSettings={eventSettings}
                dataCount={contacts[activeTab]?.length}
            />

            <div className="calendar-panel">
                {
                    contactLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        contacts[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={false}
                                    datalist={contacts[activeTab]}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={
                                        (activeTab === 'unallocated')
                                            ?
                                            _allUnallocatedFields
                                            :
                                            allAllocatedFields
                                    }
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={
                                        (activeTab === 'unallocated')
                                            ? (unallocatedAttnFields && unallocatedAttnFields.length > 0) ? [...unallocatedAttnFields.filter(item => item !== 'checkbox'), 'checkbox'] : _allUnallocatedFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                            : (allocatedAttnFields && allocatedAttnFields.length > 0) ? [...allocatedAttnFields.filter(item => item !== 'checkbox')] : allAllocatedFields.filter(e => e.default === true && e.type !== 'checkbox').map(v => _.pick(v, ['value']).value)
                                    }
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={false}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>

            <PopUpModal show={eventsModal.show}>
                <AttendeeInfo
                    {...props}
                    attendeeid={eventsModal.docID}
                    show={eventsModal.show}
                    clsActive='overlay-modal active'
                    handleClose={handleEventClose}
                    title={'attendee'}
                >
                </AttendeeInfo>
            </PopUpModal>

            <PopUpModal show={showOEMLeadConvert.show}>
                <Allocation
                    show={showOEMLeadConvert.show}
                    handleModalClose={handleOEMLeadModalClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    attendee={showOEMLeadConvert.fields}
                    attendees={showOEMLeadConvert.attendees}
                    isReAssign={showOEMLeadConvert.isReAssign}
                    eventsProID={filterEvent}
                    eventsPro={_.find(events, { value: filterEvent })?.obj}
                    eventSettings={eventSettings}
                />
            </PopUpModal>

            <PopUpModal show={inboundModal.show}>
                <InBoundLeadConvert
                    show={inboundModal.show}
                    docID={inboundModal.docID}
                    stage={inboundModal.stage}
                    handleModalClose={handleCloseInboundLead}
                    pipeline={'LeadsBucket'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    loader={false}
                    readOnlyView={true}
                />
            </PopUpModal>



            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                <StageConsumer>
                    {() => (

                        <SidePanel
                            clsActive={showpanel.clsActive}
                            clsName={showpanel.clsName}
                            sidepanelClose={sidepanelClose}
                            title=''
                        >
                            <QuickView {...props}
                                enquiryid={showpanel.enquiryid}
                                isFilterApplied={isFilterApplied}
                                updateListEnquiry={updateListEnquiry}
                                sidepanelClose={sidepanelClose}
                            />
                        </SidePanel>
                    )}
                </StageConsumer>
            </StageProvider>

        </div>
    )
}

export default AtttListView;