/** LIBRARIES */
import React, { Component } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import moment from "moment";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import Swal from "sweetalert2";
import NumberFormat from 'react-number-format';
import { PopUpModal, PpsrScan, ReactSelect } from "../../../components";
import Translate from "../../../constants/translate";
import { default as _images } from "../../../images";
import CommonHelper from "../../../services/common";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { objTradeIn, objMark, tradeinSortOptions } from "../viewModel";
import { firestoreDB } from "../../../services/helper";

let tradeinOptionsDF = [];
//#endregion

class AutoGrab extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.isLoading = false;
    this.isContentLoader = false;
    this.ref = firestoreDB(this.props.dealersettings)
      .firestore()
      .collection("tradeins");
    this.newDocID = null;
    this.mandatoryFields = [];
    this.mandImages = 0;
    this.mandSidePhotos = [];
    this.searchTimeout = null;
    this.state = {
      sortConfig: null,
      showPpsrScan: false,
      searchQuery: "",
      searchValue: "",
      searchBySellerType: "",
      searchBySource: "",
      showActive: false,
      showDeListed: false,
      isContentLoader: false,
      isLeadsActive: false,
      priceValuationLogDate: "",
      marketOverlayLogDate: "",
      vehicle: {},
      overlay: {},
      averagePrice: 0,
      averageKm: 0,
      daysSupply: 0,
      averageAge: 0,
      valuation: {},
      leads: [],
      sources: [],
      filteredLeads: [],
      priceHistory: [],
      loading: false,
      loadingValuation: false,
      loadingValuationHistory: false,
      loadingOverlay: false,
      activeSection: "",
      fields: Object.assign({}, objTradeIn),
      mark: Object.assign({}, objMark),
      errors: {},
      errorPhotos: {},
      makes: [],
      models: [],
      types: [],
      groups: [],
      years: [],
      cropShow: {
        show: false,
        clsActive: "",
        src: null,
        id: "",
        type: "",
        ext: "",
        title: "",
      },
      showCanvas: false,
      showRegScan: false,
      showVinScan: false,
      showDamage: false,
      imageURL: null,
      imageLoader: Object.assign({}),
      imageURLs: null,
      isLoadingInspection: false,
      addMake: false,
      addType: false,
      addGroup: false,
      addModel: false,
      vehicleScan: { show: false, type: null },
      showOwnedVehicles: false,
      showPriceBreakdown: false,
    };

    const _evalexpert = Object.assign(
      {},
      props.dealersettings &&
      props.dealersettings.client &&
      props.dealersettings.client.integrations &&
      props.dealersettings.client.integrations.filter(
        (e) => e.type === "evalexpert"
      )[0]
    );

    this.evalexpertEnabled =
      localStorage.defaultModule !== "oem" &&
        _evalexpert &&
        _evalexpert.active &&
        _evalexpert.enabled
        ? true
        : false;
  }

  maxOfferPrice = (retail_price, offersBreakDown) => {
    let _totalCost = retail_price ? retail_price : 0;
    //const offersBreakDown = this.props.dealersettings?.client?.moduleSettings?.autograb?.subList;
    offersBreakDown && offersBreakDown.forEach(_data => { _totalCost += parseFloat(_data.cost); });
    return this.formatPrice(_totalCost);
  }

  formatPrice = (price) => {
    const formattedPrice = parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");

    const resPrice = formattedPrice.endsWith(".00")
      ? formattedPrice.slice(0, -3)
      : formattedPrice;
    return `${resPrice}` || 0;
  };

  getAutoGrabData = async (vehicleId, type) => {
    const ref = window.firebase
      .firestore()
      .collection("autoGrabLogs")
      .where("clientID", "==", this.props.selectedVehicle?.clientID)
      .where("type", "==", type)
      .where("vehicleID", "==", vehicleId)
      .orderBy("logDate", "desc")
      .limit(1);
    let docID = "";
    const snap = await ref.get();
    let cachedData = {};
    if (snap?.docs[0]) {
      docID = snap.docs[0].id;
      cachedData = snap.docs[0].data();
      if (type === "marketOverlay") {
        let leads = cachedData?.data?.leads || [];
        const subCollectionsSnap = await window.firebase
          .firestore()
          .collection(`autoGrabLogs/${docID}/marketOverlay`)
          .get();
        if (subCollectionsSnap.size > 0) {
          subCollectionsSnap.forEach((_doc) => {
            const data = _doc.data();
            leads = leads.concat(data?.leads || []);
          });
          cachedData = {
            ...cachedData,
            data: {
              ...cachedData?.data,
              leads: leads,
            },
          };
        }
      }
    }
    if (cachedData?.logDate?.seconds) {
      this.setState({
        [`${type}LogDate`]: moment
          .unix(cachedData?.logDate?.seconds)
          .format("DD/MM/YYYY h:mm A"),
      });
    }
    return cachedData?.data || {};
  };
  getVehicleDetail = async (fetchLatest = false, allowFetch = true) => {
    if (
      this.props.selectedVehicle?.chassisNo ||
      (this.props.selectedVehicle?.regNo &&
        this.props.selectedVehicle?.regState)
    ) {
      const thisScope = this;
      this.setState({
        loading: true,
      });
      if (!fetchLatest && this.props.selectedVehicle?.autograbID) {
        const cachedData = await this.getAutoGrabData(
          this.props.selectedVehicle?.autograbID,
          "vehicleDetail"
        );

        if (cachedData?.vehicle) {
          let vehicle = cachedData?.vehicle;
          vehicle["description"] = `${vehicle.series || ''} ${vehicle.num_gears}sp ${vehicle.num_cylinders}cyl ${vehicle.transmission_type || ''} ${vehicle.body_type || ''} ${vehicle.manufacturer_drive_type || ''} ${vehicle.capacity_cc}cc ${vehicle.power_kw}KW ${vehicle.torque_nm}nm ${vehicle.fuel_type || ''} ${vehicle.engine_type || ''} ${vehicle.engineNo || ''} ${vehicle.num_doors}DR ${vehicle.num_seats}SEAT`;
          allowFetch = false;
          this.setState(
            {
              loading: false,
              isContentLoader: true,
              vehicle: vehicle || {},
            },
            () => {
              const priceValuationEnabled =
                thisScope.props.dealersettings?.client?.moduleSettings
                  ?.autograb?.priceValuation &&
                thisScope.props.dealersettings?.client?.moduleSettings
                  ?.autograb?.enabled;
              const marketOverlayEnabled =
                thisScope.props.dealersettings?.client?.moduleSettings
                  ?.autograb?.marketOverlay &&
                thisScope.props.dealersettings?.client?.moduleSettings
                  ?.autograb?.enabled;

              const { selectedVehicle, module } = this.props;
              let _fetchLatest = module === 'stock' && _.isEmpty(selectedVehicle?.marketOverlay?.score) ? true : false;
              this.getVehicleValuationDetail(false, priceValuationEnabled || false);
              this.getVehicleMarketOverlay(_fetchLatest, marketOverlayEnabled || false);
            }
          );
          return;
        }
      }
      if (allowFetch || fetchLatest) {
        const searchVehicle = window.firebase
          .functions()
          .httpsCallable("autograb-searchVehicle");
        searchVehicle({
          clientID: this.props.selectedVehicle?.clientID,
          vinNo: this.props.selectedVehicle?.chassisNo,
          regNo: this.props.selectedVehicle?.regNo,
          state: this.props.selectedVehicle?.regState,
        })
          .then((data) => {
            let vehicle = {};
            if (data?.data?.success && !_.isEmpty(data.data?.data?.vehicle)) {
              vehicle = data?.data?.data?.vehicle;
              vehicle[
                "description"
              ] = `${vehicle.series} ${vehicle.num_gears}sp ${vehicle.num_cylinders}cyl ${vehicle.transmission_type} ${vehicle.body_type} ${vehicle.manufacturer_drive_type} ${vehicle.capacity_cc}cc ${vehicle.power_kw}KW ${vehicle.torque_nm}nm ${vehicle.fuel_type} ${vehicle.engine_type} ${vehicle.engineNo || ''} ${vehicle.num_doors}DR ${vehicle.num_seats}SEAT`;
              if (!this.props.selectedVehicle?.autograbID && this.props.docID) {
                const { dealersettings } = this.props;
                let modifiedBy = dealersettings ? dealersettings.id : "";
                let modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(dealersettings)
                  .firestore()
                  .collection("tradeins")
                  .doc(this.props.docID);
                updateRef.update({
                  autograbID: vehicle?.id,
                  modifiedBy: modifiedBy,
                  modifiedFrom: "web",
                  modifiedDate: modifiedDate,
                });
                //console.log("udpate vehicle id");
              }
            } else {
              Swal.fire(data?.data?.message, "", "error").then(function () {
                thisScope.handleModalClose(thisScope.newDocID);
              });
            }
            this.setState({
              loading: false,
              isContentLoader: true,
              vehicle: vehicle,
            });
          })
          .catch((error) => {
            Swal.fire(
              error?.message ||
              "Something went wrong. Please contact support. error",
              "",
              "error"
            ).then(function () {
              thisScope.handleModalClose(thisScope.newDocID);
            });
            this.setState({
              isContentLoader: true,
              vehicle: {},
              loading: false,
            });
            console.error(error);
          });
      }
    } else {
      Swal.fire(
        "Chassis No or Reg and state are required to fetch vehicle detail",
        "",
        "error"
      ).then(function () {
        this.handleModalClose(this.newDocID);
      });
    }
  };
  getVehicleValuationDetail = async (
    fetchLatest = false,
    allowFetch = true
  ) => {
    this.setState({
      loadingValuation: true,
    });
    const thisScope = this;
    if (
      this.props.selectedVehicle?.chassisNo &&
      this.props.selectedVehicle?.mileage
    ) {
      if (!fetchLatest && this.props.selectedVehicle?.autograbID) {
        const cachedData = await this.getAutoGrabData(
          this.props.selectedVehicle?.autograbID,
          "priceValuation"
        );
        if (cachedData?.prediction) {
          allowFetch = false;
          this.setState({
            loadingValuation: false,
            isContentLoader: true,
            valuation: cachedData?.prediction || {},
          });
          return;
        } else {
          this.setState({
            isContentLoader: true,
            valuation: {},
            loadingValuation: false,
          });
        }
      }
      if (allowFetch || fetchLatest) {
        const { selectedVehicle, module } = this.props;
        this.setState({
          loadingValuation: true,
        });
        const vehicleValuations = window.firebase
          .functions()
          .httpsCallable("autograb-vehicleValuations");
        vehicleValuations({
          clientID: selectedVehicle?.clientID,
          vinNo: selectedVehicle?.chassisNo, //"LRWYHCFS6NC428809"
          mileage: selectedVehicle?.mileage,
          vehicleId: selectedVehicle?.autograbID,
          state: selectedVehicle?.regState,
          //stockID: module === 'stock' ? selectedVehicle?.documentID : null
        })
          .then((data) => {
            let prediction = {};
            if (data?.data?.success) {
              prediction = data?.data?.data?.prediction;
              if (data?.data?.data?.logDate) {
                let logDate = moment
                  .unix(data?.data?.data?.logDate?._seconds)
                  .format("DD/MM/YYYY h:mm A");
                this.setState({
                  priceValuationLogDate: logDate,
                });
              }
            } else {
              Swal.fire(data?.data?.message, "", "error");
            }
            this.setState({
              loadingValuation: false,
              isContentLoader: true,
              valuation: prediction,
            });
          })
          .catch((error) => {
            // Handle any errors
            Swal.fire(
              error?.message ||
              "Something went wrong. Please contact support. error",
              "",
              "error"
            ).then(function () {
              thisScope.handleModalClose(thisScope.newDocID);
            });
            this.setState({
              isContentLoader: true,
              valuation: {},
              loadingValuation: false,
            });
            console.error(error);
          });
      }
    } else {
      Swal.fire(
        "Chassis No and mileage are required for the vehicle valuation price",
        "",
        "error"
      ).then(function () {
        thisScope.handleModalClose(thisScope.newDocID);
      });
    }
  };
  getVehicleMarketOverlay = async (fetchLatest = false, allowFetch = true) => {
    const thisScope = this;
    this.setState({ loadingOverlay: true, activeSection: "marketOverlay" });
    let overlay = {};
    let leads = [];
    let sources = [];
    let states = [];
    let averagePrice = 0;
    let averageKm = 0;
    let averageAge = 0;
    let totalAge = 0;
    let daysSupply = 0;
    let activeMarketOverlayCount = 0;
    let diListedMarketOverlayCount = 0;
    if (this.state.vehicle?.id) {
      if (!fetchLatest && this.props.selectedVehicle?.autograbID) {
        const cachedData = await this.getAutoGrabData(
          this.props.selectedVehicle?.autograbID,
          "marketOverlay"
        );
        if (cachedData?.leads?.length) {
          allowFetch = false;
          overlay = cachedData;
          leads = cachedData?.leads || [];
          leads = leads?.map((item) => {
            if (item?.removed_at) {
              diListedMarketOverlayCount += 1;
            } else {
              activeMarketOverlayCount += 1;
            }
            const current = item?.removed_at
              ? moment(item?.removed_at)
              : moment();
            const durationInMilliseconds = current.diff(
              moment(item?.listed_at)
            );
            const duration = moment.duration(durationInMilliseconds);
            const days = duration.days();
            let age = ``;
            let ageCount = 0;
            if (days) {
              ageCount += days;
              age = `${days}d`;
            }
            totalAge += days;
            if (item.listing_sources) {
              item.listing_sources.forEach((source) => {
                if (!sources.includes(source)) {
                  sources.push(source);
                }
              });
            }
            if (item.state && !states.includes(item.state)) {
              states.push(item.state);
            }
            return { ...item, age, ageCount };
          });
          averagePrice = this.calculateAveragePrice(leads, "price");
          averageKm = this.calculateAveragePrice(leads, "kms");
          averageAge = totalAge / leads?.length;
          daysSupply =
            (activeMarketOverlayCount / diListedMarketOverlayCount) *
            overlay?.days_checked || 0;
          this.setState({
            loadingOverlay: false,
            isContentLoader: true,
            overlay,
            leads,
            sources,
            averagePrice,
            averageKm,
            averageAge,
            daysSupply,
            states
          });
          return;
        } else {
          this.setState({
            loadingOverlay: false,
            isContentLoader: true,
            overlay: {},
            leads: [],
            averagePrice: 0,
            averageKm: 0,
          });
        }
      }
      if (allowFetch || fetchLatest) {
        this.setState({
          loadingOverlay: true,
        });
        const marketOverlay = window.firebase
          .functions()
          .httpsCallable("autograb-marketOverlay");
        marketOverlay({
          clientID: this.props.selectedVehicle?.clientID,
          vehicleId: this.state.vehicle?.id,
          state: this.props.selectedVehicle?.regState,
          stockID: this.props.module === 'stock' ? this.props.selectedVehicle?.documentID : null
        })
          .then((data) => {
            if (data?.data?.success) {
              overlay = data?.data?.data;
              leads = data?.data?.data?.leads || [];
              leads = leads?.map((item) => {
                item.listing_sources.forEach((source) => {
                  if (!sources.includes(source)) {
                    sources.push(source);
                  }
                });
                if (item?.removed_at) {
                  diListedMarketOverlayCount += 1;
                } else {
                  activeMarketOverlayCount += 1;
                }
                const current = item?.removed_at
                  ? moment(item?.removed_at)
                  : moment();
                const durationInMilliseconds = current.diff(
                  moment(item?.listed_at)
                );
                const duration = moment.duration(durationInMilliseconds);
                const days = duration.days();
                let age = ``;
                let ageCount = 0;
                if (days) {
                  age = `${days}d`;
                  ageCount += days;
                }
                totalAge += days;
                return { ...item, age, ageCount };
              });
              averagePrice = this.calculateAveragePrice(leads, "price");
              averageKm = this.calculateAveragePrice(leads, "kms");
              averageAge = leads?.length ? totalAge / leads?.length : 0;
              daysSupply =
                (activeMarketOverlayCount / diListedMarketOverlayCount) *
                overlay?.days_checked || 0;
              if (data?.data?.data?.logDate) {
                let logDate = moment
                  .unix(data?.data?.data?.logDate?._seconds)
                  .format("DD/MM/YYYY h:mm A");
                this.setState({
                  marketOverlayLogDate: logDate,
                });
              }
            } else {
              Swal.fire(data?.data?.message, "", "error").then(function () {
                thisScope.handleModalClose(thisScope.newDocID);
              });
            }
            this.setState({
              loadingOverlay: false,
              isContentLoader: true,
              overlay,
              leads,
              sources,
              averagePrice,
              averageKm,
              averageAge,
              daysSupply,
            });
          })
          .catch((error) => {
            // Handle any errors
            Swal.fire(
              error?.message ||
              "Something went wrong. Please contact support. error",
              "",
              "error"
            ).then(function () {
              thisScope.handleModalClose(thisScope.newDocID);
            });
            this.setState({
              loadingOverlay: false,
              isContentLoader: true,
              overlay: {},
              leads: [],
              averagePrice: 0,
              averageKm: 0,
            });
          });
      }
    } else {
      Swal.fire(
        "Vehicle is required for the vehicle valuation price",
        "",
        "error"
      );
    }
  };
  getMonthNameFromNumber = (monthNumber) => {
    const monthName = moment()
      .month(monthNumber - 1)
      .format("MMM");
    return monthName;
  };
  componentDidMount() {
    this._isMounted = true;
    this.setState(
      {
        loading: true,
      },
      () => {
        if (this._isMounted) this.getVehicleDetail(false, true);
      }
    );
  }
  filterData = () => {
    const {
      leads,
      showActive,
      showDeListed,
      searchBySellerType = "",
      searchBySource = "",
      searchByState = "",
      sortConfig,
    } = this.state;
    let searchValue = this.state.searchValue.toLowerCase().trim();
    let filtered = leads.filter((item) => {
      // Convert the "price" and "year" properties to strings for searching
      const priceStr = item.price ? item.price.toString() : "";
      const yearStr = item.year ? item.year.toString() : "";
      const kmsStr = item.kms ? item.kms.toString() : "";
      const color = item.color ? item.color.toLowerCase() : "";
      const date = `${this.getMonthNameFromNumber(item.release_month)}, ${item.release_year
        }`.toLowerCase();
      // Check if any of the properties match the search value
      return (
        date.includes(searchValue) ||
        priceStr.startsWith(searchValue) ||
        color.startsWith(searchValue) ||
        yearStr.startsWith(searchValue) ||
        kmsStr.startsWith(searchValue) ||
        (item.tag_ids &&
          item.tag_ids.some((tag) => {
            return tag.startsWith(searchValue);
          }))
      );
    });
    if (searchBySellerType) {
      filtered = filtered.filter((x) => x.seller_type === searchBySellerType);
    }
    if (searchBySource) {
      filtered = filtered.filter(
        (x) => x.listing_sources.indexOf(searchBySource) !== -1
      );
    }
    if (searchByState) {
      filtered = filtered.filter(
        (x) => searchByState.split(',').includes(x.state)
      );
    }
    if (showActive && !showDeListed) {
      filtered = filtered.filter((item) => item.removed_at == null);
    } else if (showDeListed && !showActive) {
      filtered = filtered.filter((item) => item.removed_at != null);
    }
    if (!_.isEmpty(sortConfig)) {
      filtered = filtered.sort((a, b) => {
        if (sortConfig.key === "date") {
          const dateA = this.formatDate(a.release_month, a.release_year);
          const dateB = this.formatDate(b.release_month, b.release_year);
          return dateA - dateB;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    const averagePrice = this.calculateAveragePrice(filtered, "price");
    const averageKm = this.calculateAveragePrice(filtered, "kms");
    let totalAge = 0;
    filtered.forEach((item) => {
      totalAge += item?.ageCount;
    });

    const averageAge = totalAge / filtered?.length || 0;
    this.setState({
      loadingOverlay: false,
      isContentLoader: true,
      averagePrice,
      averageKm,
      averageAge,
      filteredLeads: filtered,
    });
  };
  handleSearchChange = (event) => {
    const { value, id, checked } = event.target;
    if (id === "showActive" || id === "showDeListed") {
      this.setState({ [id]: checked }, this.filterData);
    } else {
      this.setState({ searchValue: value }, this.filterData);
    }
  };
  handleSellerTypeChange = (value = "") => {
    this.setState({ searchBySellerType: value }, this.filterData);
  };
  handleSourceChange = (value = "") => {
    this.setState({ searchBySource: value }, this.filterData);
  };
  handleStateChange = (value = "") => {
    this.setState({ searchByState: value }, this.filterData);
  };
  loadState = () => {
    let _states = this.state.states ? this.state.states.map(
      (item) => {
        return {
          label: item,
          value: item,
        };
      }
    ) : []
    if (!_.isEmpty(_states)) _states.push({
      label: 'Eastern Seaboard',
      value: 'VIC,NSW,QLD'
    })
    return _states
  }
  formatDate = (month, year) => {
    return new Date(`${month} 1, ${year}`);
  };
  componentWillUnmount() {
    this.setState({
      fields: Object.assign({}, objTradeIn),
      mark: Object.assign({}, objMark),
      errors: {},
      makes: [],
      models: [],
      types: [],
      years: [],
      cropShow: {
        show: false,
        clsActive: "",
        src: null,
        id: "",
        type: "",
        ext: "",
        title: "",
      },
      showCanvas: false,
      showRegScan: false,
      showVinScan: false,
      showDamage: false,
      imageURL: null,
      imageURLs: null,
    });
  }
  limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = "0" + val;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = "01";

        //this can happen when user paste number
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let errorPhotos = {};
    let formIsValid = true;
    let fields = Object.assign({}, this.state.fields);
    let errorClass = "input_error";
    const { dealersettings } = this.props;
    let dynamicDetailList =
      !_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.tradeinOptions)
        ? dealersettings.client.tradeinOptions
        : Object.assign(
          [],
          _.uniq(
            _.union(
              tradeinSortOptions,
              Object.assign(
                [],
                !_.isEmpty(tradeinOptionsDF) &&
                  !_.isEmpty(
                    tradeinOptionsDF.filter((m) => m.active === true)
                  )
                  ? Array.prototype.map
                    .call(
                      tradeinOptionsDF.filter((m) => m.active === true),
                      function (item) {
                        return item.value;
                      }
                    )
                    .join(",")
                    .split(",")
                  : []
              )
            )
          )
        );

    dynamicDetailList = _.union(dynamicDetailList, [
      "images",
      "sidePhotos",
      "marks",
    ]);
    this.mandatoryFields &&
      this.mandatoryFields.map((key) => {
        if (dynamicDetailList.includes(key)) {
          if (
            objTradeIn.hasOwnProperty(key) &&
            ((_.isObject(fields[key]) && _.isEmpty(fields[key])) ||
              (!_.isObject(fields[key]) &&
                !_.isBoolean(fields[key]) &&
                !fields[key]))
          ) {
            formIsValid = false;
            errors[key] = errorClass;
          } else if (
            !_.isEmpty(tradeinOptionsDF) &&
            !_.isEmpty(
              tradeinOptionsDF.filter(
                (m) => m.value === key && m.active === true
              )[0]
            ) &&
            (_.isEmpty(fields.dynamicFields) ||
              (!_.isEmpty(fields.dynamicFields) &&
                _.isEmpty(fields.dynamicFields[key]) &&
                !_.isBoolean(fields.dynamicFields[key]) &&
                !_.isNumber(fields.dynamicFields[key])))
          ) {
            formIsValid = false;
            errors[key] = errorClass;
          }
        }
      });

    // for (let [key] of Object.entries(fields)) {

    //     if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0 && dynamicDetailList.includes(key)) {
    //         if (objTradeIn.hasOwnProperty(key) && (_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
    //             formIsValid = false;
    //             errors[key] = errorClass;
    //         }
    //         else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
    //             (_.isEmpty(fields.dynamicFields) ||
    //                 (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
    //             )) {
    //             formIsValid = false;
    //             errors[key] = errorClass;
    //         }
    //     }
    // }

    if (this.mandImages && this.mandImages > 0) {
      let _allImages = CommonHelper.bindAllImages(
        fields.images,
        fields.sidePhotos
      );
      if (
        _.isEmpty(_allImages) ||
        (!_.isEmpty(_allImages) && _allImages.length < this.mandImages)
      ) {
        formIsValid = false;
        errors["images"] = errorClass;
      }
    }

    this.mandSidePhotos &&
      this.mandSidePhotos.forEach((key) => {
        if (
          _.isEmpty(fields.sidePhotos) ||
          (!_.isEmpty(fields.sidePhotos) && _.isEmpty(fields?.sidePhotos[key]))
        ) {
          formIsValid = false;
          errorPhotos[key] = "border-error";
        }
      });
    //console.log("errors", errors);
    //console.log("this.mandatoryFields", this.mandatoryFields);
    //console.log("dynamicDetailList", dynamicDetailList, tradeinOptionsDF);
    this.setState({ errors: errors, errorPhotos: errorPhotos });

    if (formIsValid === true) {
      this.addTradeIn(this);
    } else {
      let id =
        !_.isEmpty(errors) &&
        Object.keys(errors)[Object.keys(errors).length - 1];
      if (id && document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      return formIsValid;
    }
  };

  addTradeIn = (e) => {
    this.isLoading = true;
    let objData = Object.assign({}, this.state.fields);
    const { dealersettings, enquiryID, tradeinProID, serviceID } = this.props;
    if (_.isEmpty(objData.addedBy)) {
      objData.addedBy = this.props.dealersettings
        ? this.props.dealersettings.id
        : "";
      objData.addedDate = window.firebase.firestore.Timestamp.now();
      objData.addedFrom = "web";
    }
    objData.modifiedBy = this.props.dealersettings
      ? this.props.dealersettings.id
      : "";
    objData.modifiedDate = window.firebase.firestore.Timestamp.now();
    objData.modifiedFrom = "web";

    //Remove empty value from object
    for (var propName in objData) {
      if (
        objData[propName] === undefined ||
        objData[propName] === Object.assign([]) ||
        (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
      ) {
        if (propName !== "images") delete objData[propName];
      }
    }

    if (!objData.enquiryID && enquiryID) objData.enquiryID = enquiryID;
    if (!objData.serviceID && serviceID) objData.serviceID = serviceID;

    if (!objData.tradeinProID && tradeinProID)
      objData.tradeinProID = tradeinProID;

    if (!objData.clientID || localStorage.defaultModule === "oem")
      objData = CommonHelper.setClientOrGroupOrRegionOrOemID(
        objData,
        dealersettings,
        null,
        objData.clientID,
        true
      );

    if (!this.newDocID) {
      this.newDocID = this.ref.doc().id;
      //console.log('newly created one')
    }

    if (!objData.documentID) objData.documentID = this.newDocID;

    const updateRef = firestoreDB(dealersettings)
      .firestore()
      .collection("tradeins")
      .doc(this.newDocID);
    updateRef
      .set(objData, { merge: true })
      .then((docRef) => {
        //console.log(docRef);
        this.isLoading = false;
        toast.notify("Trade-In saved successfully", {
          duration: 2000,
        });

        let _makeModel = (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '') + (objData.regNo ? ', ' + objData.regNo : '');
        let _logNote = "Trade-In added";
        if (this.props.docID) _logNote = "Trade-In updated";

        let _ID = (objData.enquiryID ? objData.enquiryID : objData.serviceID ? objData.serviceID : objData.tradeinProID)
        let _subType = (objData.serviceID ? 'service' : objData.tradeinProID ? 'tradeinPro' : 'enquiry')

        CommonHelper.saveChangeLog(objData.clientID, objData.groupID, _ID, _logNote + (_makeModel ? " (" + _makeModel + ")" : ""), "tradein", _subType);

        this.handleModalClose(this.newDocID, objData);
      })
      .catch((error) => {
        console.error("Error updating contact_details: ", error);
      });
  };

  handleModalClose = (ID, objData) => {
    this.setState({ fields: Object.assign({}, objTradeIn) });
    this.setState({ errors: {} });
    this.props.handleClose(ID, objData);
  };

  subscribeNow = () => {
    const { dealersettings } = this.props;
    if (
      !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.rolePermissions) &&
      !_.isEmpty(dealersettings.rolePermissions.admin) &&
      Object.keys(dealersettings.rolePermissions.admin)
        .filter(function (id) {
          return dealersettings.rolePermissions.admin[id];
        })
        .some((e) => e === "modules")
    ) {
      this.handleModalClose(this.newDocID);
      this.props.history.push("/settings/modules?autograb=true");
    }
  };

  calculateAveragePrice(arr, key = "price") {
    if (arr.length === 0) {
      return 0; // To handle the case when the array is empty
    }

    const total = arr.reduce((sum, obj) => sum + obj[key], 0);
    const average = total / arr.length;
    return average;
  }
  requestSort = (key) => {
    let direction = "ascending";
    const { sortConfig } = this.state;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    this.setState({ sortConfig: { key, direction } }, this.filterData);
  };

  render() {
    const {
      searchValue,
      filteredLeads,
      leads,
      showPpsrScan,
      showActive,
      showDeListed,
      searchBySellerType,
      searchBySource,
      searchByState,
      sortConfig,
      showPriceBreakdown
    } = this.state;
    const isSearching =
      searchValue.trim() !== "" ||
      showActive ||
      showDeListed ||
      searchBySellerType ||
      searchBySource ||
      searchByState ||
      !_.isEmpty(sortConfig);
    const leadsToMap = isSearching ? filteredLeads : leads;
    const { show, selectedVehicle, dealersettings, clientUsers, groupUsers } =
      this.props;

    const ppsrEnabled =
      !_.isEmpty(dealersettings?.client?.moduleSettings?.ppsr) &&
        dealersettings.client.moduleSettings.ppsr.enabled &&
        dealersettings.client.moduleSettings.ppsr.active
        ? true
        : false;

    const autograbIntegratonEnabled =
      !_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.integrations) &&
        dealersettings.client.integrations.filter(
          (e) => e.type === "autograb"
        )[0] &&
        dealersettings.client.integrations.filter((e) => e.type === "autograb")[0]
          .enabled === true
        ? true
        : false;
    const priceValuationEnabled =
      dealersettings?.client?.moduleSettings?.autograb?.priceValuation &&
      dealersettings?.client?.moduleSettings?.autograb?.enabled;
    const marketOverlayEnabled =
      dealersettings?.client?.moduleSettings?.autograb?.marketOverlay &&
      dealersettings?.client?.moduleSettings?.autograb?.enabled;

    const currencySymbol =
      !_.isEmpty(this.props.dealersettings) &&
        !_.isEmpty(this.props.dealersettings.client) &&
        !_.isEmpty(this.props.dealersettings.client.currency)
        ? this.props.dealersettings.client.currency.symbol + " "
        : "$ ";

    const makeModels =
      !_.isEmpty(this.props.dealersettings) &&
        !_.isEmpty(this.props.dealersettings.allMakeModels)
        ? this.props.dealersettings.allMakeModels.find(
          (x) => x.name === this.state?.vehicle?.make
        )
        : {};

    let _autograb = dealersettings?.client?.integrations?.filter((e) => e.type === "autograb")[0];
    const offerLessBreakDowns = autograbIntegratonEnabled && !_.isEmpty(_autograb?.settings?.subList) ? _autograb?.settings?.subList :
      (dealersettings?.client?.moduleSettings?.autograb?.enabled ? dealersettings?.client?.moduleSettings?.autograb?.subList : []);

    return (
      <>
        <Modal
          show={show}
          // onHide={this.handleModalClose}
          backdrop="static"
          backdropClassName={show ? "overlay-modal active" : ""}
          enforceFocus={false}
          dialogClassName=" modal-dialog fullscreenmodal-lg add-triggers-fullscreen"
        >
          <Modal.Header className="modal-header-fullscreen">
            <div className="modal-title">
              {" "}
              <Translate text={this.props.title} />
            </div>
            <button
              type="button"
              className="close close-fullscreen float-right"
              onClick={(e) => { e.preventDefault(); this.handleModalClose() }}
            >
              ×
            </button>
          </Modal.Header>

          <Modal.Body className="modal-body-fullscreen">
            {this.state.loading ? (
              <div className="loader-center-screen">
                <div className="spinner-loader h-100 undefined ">
                  <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                    <div
                      role="status"
                      className="spinner-border loader-primary"
                    ></div>
                    <p className="text-muted mt-3"></p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="popup-wrap-fullscreen h-100 ">
                <div className="container-fluid pl-0 pr-0">
                  <div className="pricing-dynamicform-view-wrapper">
                    <div className="pricing-dynamicform-sub-section-div ">
                      <div className="pricing-dynamicform-right-panel">
                        <div className="card pricing-dynamicform-custom-card h-100">
                          <div className="card-header ">Vehicle Details</div>
                          <div className="card-body">
                            <div className="market-left-head">
                              {/* <div className="float-right">
                                <a
                                  href="#"
                                  className="mini-button ml-2"
                                  onClick={() => this.getVehicleDetail(true)}
                                >
                                  {" "}
                                  <i className="ico icon-refresh"></i>
                                </a>
                              </div> */}
                            </div>
                            <div className="market-vehicle-details">
                              <div className="market-vehicle-name">
                                {selectedVehicle?.modelDescription || this.state.vehicle?.title}
                              </div>
                              {/* <div className="market-vehicle-info">
                                <b>AutoGrab ID: {this.state.vehicle?.id}</b>
                              </div> */}
                              <div className="market-vehicle-manufacturer">
                                <b>
                                  Transmission:{" "}
                                  {this.state.vehicle?.transmission || this.state.vehicle?.transmission_type}
                                </b>
                              </div>
                              <div className="market-vehicle-manufacturer">
                                <b>Engine: {this.state.vehicle?.engine || this.state.vehicle?.engine_type} {this.state.vehicle?.engineNo ? ', ' + this.state.vehicle?.engineNo : ''}</b>
                              </div>
                              <div className="market-vehicle-manufacturer">
                                <b>Badge: {this.state.vehicle?.badge}</b>
                              </div>
                              {/* <div className="market-vehicle-manufacturer">
                                Retrieved at: 11/07/2023 12:10 PM
                              </div> */}
                              <div className="market-vehicle-info">
                                Released:{" "}
                                {this.state.vehicle?.release_month
                                  ? this.getMonthNameFromNumber(
                                    this.state.vehicle?.release_month
                                  )
                                  : "--"}{" "}
                                ,
                                {this.state.vehicle?.release_year
                                  ? this.state.vehicle?.release_year
                                  : ""}
                              </div>
                              {this.state.vehicle?.description && (
                                <div className="market-vehicle-info">
                                  {this.state.vehicle?.description}
                                </div>
                              )}

                              {/* <div className="market-vehicle-manufacturer">
                                Manufacturer RRP: {currencySymbol}
                                {this.state.valuation?.price
                                  ? this.formatPrice(
                                    this.state.valuation?.price
                                  )
                                  : 0}
                              </div> */}
                              <div className="market-vehicle-odometer">
                                Odometer:
                                {this.props.selectedVehicle?.mileage
                                  ? this.formatPrice(
                                    this.props.selectedVehicle?.mileage
                                  )
                                  : 0}{" "}
                                KM
                              </div>
                            </div>
                            {!priceValuationEnabled &&
                              !autograbIntegratonEnabled &&
                              !this.state.valuation?.trade_price &&
                              !this.state.loadingValuation && (
                                <div className="market-vehicle-trade ">
                                  <div className="col">
                                    <div className="vehicle-trade-counter">
                                      <div className="upgrade-market-lock">
                                        <i className="ico icon-lock"></i>
                                      </div>
                                      <p className="upgrade-market-lock-text">
                                        {" "}
                                        <b>Subscribe to view price </b>
                                      </p>
                                      <div className="upgrade-market-lock-button">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={(e) => { e.preventDefault(); this.subscribeNow() }}
                                        // data-toggle="modal"
                                        // data-target="#add-campaign"
                                        >
                                          {" "}
                                          Subscribe Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {this.state.loadingValuation ? (
                              <div className="d-flex h-20 mt-4 justify-content-center align-items-center loader-primary">
                                <div
                                  role="status"
                                  className="spinner-border loader-primary"
                                ></div>
                                <p className="text-muted mt-3"></p>
                              </div>
                            ) : (
                              <div>
                                {this.state.valuation?.trade_price &&
                                  this.state.valuation?.retail_price && (<>
                                    <div className="market-vehicle-trade ">
                                      <div className="vehicle-trade-counter-refresh">
                                        {this.state.priceValuationLogDate && (
                                          <span className="vehicle-trade-counter-refreshdate">
                                            {this.state.priceValuationLogDate}
                                          </span>
                                        )}
                                        {priceValuationEnabled ||
                                          autograbIntegratonEnabled ? (
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.getVehicleValuationDetail(true);
                                            }}
                                          >
                                            {" "}
                                            <i className="ico icon-refresh"></i>{" "}
                                            Refresh
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                        {!priceValuationEnabled &&
                                          !autograbIntegratonEnabled ? (
                                          <a
                                            href="#"
                                            onClick={(e) => { e.preventDefault(); this.subscribeNow() }}
                                          >
                                            {" "}
                                            <i className="ico icon-lock"></i>{" "}
                                            Subscribe
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="d-flex w-100">
                                        {!_.isEmpty(offerLessBreakDowns) && (<div className="col">
                                          <div className="vehicle-trade-counter trade-voilet">
                                            <p className="vehicle-trade-count-text ">
                                              {" Max Offer"}
                                            </p>
                                            <h2 className="vehicle-trade-count-title">
                                              <NumberFormat value={this.maxOfferPrice(this.state.valuation?.retail_price, offerLessBreakDowns)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                            </h2>
                                          </div>
                                        </div>)}
                                        <div className="col">
                                          <div className="vehicle-trade-counter trade-yellow">
                                            <p className="vehicle-trade-count-text ">
                                              {" "}
                                              Estimated Trade
                                            </p>
                                            <h2 className="vehicle-trade-count-title">
                                              {currencySymbol}
                                              {this.state.valuation?.trade_price
                                                ? this.formatPrice(
                                                  this.state.valuation
                                                    ?.trade_price
                                                )
                                                : 0}
                                            </h2>
                                          </div>
                                        </div>

                                        <div className="col">
                                          <div className="vehicle-trade-counter trade-green">
                                            <p className="vehicle-trade-count-text ">
                                              {" "}
                                              Estimated Retail
                                            </p>
                                            <h2 className="vehicle-trade-count-title">
                                              {currencySymbol}
                                              {this.state.valuation
                                                ?.retail_price
                                                ? this.formatPrice(
                                                  this.state.valuation
                                                    ?.retail_price
                                                )
                                                : 0}
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      !_.isEmpty(offerLessBreakDowns) && (
                                        <>

                                          {
                                            !showPriceBreakdown ? (
                                              <div className="contact-showmore-btn mt-1"><a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  showPriceBreakdown: true
                                                })
                                              }}>{'Show Price Breakdown'}   <i className="ico icon-dropdown"></i></a></div>
                                            ) : <><div className="contact-showmore-btn show-less-ico mt-1"><a href="#" onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({
                                                showPriceBreakdown: false
                                              })
                                            }}>{'Hide Price Breakdown'}  <i className="ico icon-dropdown"></i></a></div></>
                                          }

                                          {showPriceBreakdown && (<div className="popover-content mt-2">
                                            <div className="form-style popover-middle-pipeline p-0">
                                              <div className="labourcost-discountpop-head m-0">
                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                  <tbody>
                                                    <tr >
                                                      <td className="labourcost-costtxt ">  {'Estimated Retail Price (ERP)'}:</td>
                                                      <td className="labourcost-cost-box right-align"><NumberFormat value={this.state.valuation?.retail_price ? this.state.valuation?.retail_price : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                    </tr>
                                                    {
                                                      offerLessBreakDowns.map((_data, index) => {
                                                        return <tr key={index}>
                                                          <td className="labourcost-costtxt ">  {_data.name} :</td>
                                                          <td className="labourcost-cost-box right-align"><NumberFormat value={_data.cost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                        </tr>
                                                      })
                                                    }
                                                    <tr>
                                                      <td className="labourcost-costtxt-green"> {'Offer Price Ceiling'} :</td>
                                                      <td className="labourcost-cost-box-total right-align" ><span><NumberFormat value={this.maxOfferPrice(this.state.valuation?.retail_price, offerLessBreakDowns)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>)}
                                        </>
                                      )}
                                  </>
                                  )}
                              </div>
                            )}

                            {/* <div className="vehicle-trade-offer">
                        <div className="input-group ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Offer"
                          />
                          <div className="input-group-append input-search-clear">
                            <button className="btn btn-default" type="button">
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        </div>
                      </div> */}

                            {/* <div className="vehicle-trade-button">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#add-campaign"
                              >
                                {" "}
                                PPSR Check
                              </button>
                            </div> */}
                            {(priceValuationEnabled ||
                              autograbIntegratonEnabled) &&
                              !this.state.loadingValuation &&
                              !this.state.valuation?.trade_price ? (
                              <div className="vehicle-trade-button float-right">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.getVehicleValuationDetail();
                                  }}
                                >
                                  {"View Price Valuation"}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                            {(marketOverlayEnabled ||
                              autograbIntegratonEnabled) &&
                              !this.state.loadingOverlay &&
                              this.state.activeSection ===
                              "vehicleValuationsHistory" ? (
                              <div className="vehicle-trade-button float-right">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.getVehicleMarketOverlay(true);
                                  }}
                                >
                                  {"View Market Overlay"}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                            {ppsrEnabled ? (
                              <div className="vehicle-trade-button float-right">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({ showPpsrScan: true });
                                  }}
                                >
                                  <img
                                    src={_images.ppsr_logo}
                                    width="12"
                                    className="mr-1"
                                    style={{ marginTop: "-2px" }}
                                  />
                                  {"PPSR"}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pricing-dynamicform-left-panel">
                        <div className="pricing-dynamicform-left-container">
                          <div className="card pricing-dynamicform-custom-card h-100">
                            <div className="card-header">
                              <span> Market Overlay</span>
                              <div className="market-overlay-refreshbutton">
                                {(marketOverlayEnabled ||
                                  autograbIntegratonEnabled) &&
                                  this.state.overlay?.days_checked ? (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.getVehicleMarketOverlay(true)
                                    }
                                    }
                                    className="btn btn-sm btn-default"
                                  >
                                    {" "}
                                    <i className="ico icon-refresh"></i> Refresh
                                  </button>
                                ) : (
                                  ""
                                )}
                                {!marketOverlayEnabled &&
                                  !autograbIntegratonEnabled ? (
                                  <button
                                    onClick={(e) => { e.preventDefault(); this.subscribeNow() }}
                                    className="btn btn-sm btn-default"
                                  >
                                    {" "}
                                    <i className="ico icon-lock"></i> Subscribe
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              {this.state.marketOverlayLogDate &&
                                this.state.overlay?.days_checked && (
                                  <div className="market-overlay-refreshdate">
                                    {this.state.marketOverlayLogDate}
                                  </div>
                                )}
                            </div>

                            <div className="card-body position-relative custom-height-fix">
                              {!marketOverlayEnabled &&
                                !autograbIntegratonEnabled &&
                                leadsToMap?.length === 0 &&
                                !this.state.overlay?.days_checked &&
                                !this.state.loadingOverlay && (
                                  <div className="upgrade-market-overlay">
                                    <div className="spinner-loader h-100">
                                      <div className="upgrade-market-flex-wrap h-100 loader-primary">
                                        <div className="upgrade-market-center">
                                          <div className="upgrade-market-img-wrap">
                                            <img
                                              src={require("../../../images/market-overlay.svgz")}
                                              alt=""
                                              width="180"
                                            />
                                          </div>
                                          <div className="upgrade-market-txt-wrap">
                                            <div className="upgrade-market-content-head">
                                              View Market Overlay
                                            </div>
                                            <div className="upgrade-market-content">
                                              Real-Time Pricing Tool is a market
                                              leading valuation tool that
                                              outputs a Wholesale/Trade & Retail
                                              trade price.
                                            </div>
                                            <div className="upgrade-market-content-btn">
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => { e.preventDefault(); this.subscribeNow() }}
                                              >
                                                {" "}
                                                Subscribe Now
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {this.state.loadingOverlay && (
                                <div className="loader-center-screen">
                                  <div className="spinner-loader h-100 undefined ">
                                    <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                      <div
                                        role="status"
                                        className="spinner-border loader-primary"
                                      ></div>
                                      <p className="text-muted mt-3"></p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {(marketOverlayEnabled ||
                                autograbIntegratonEnabled) &&
                                !this.state.overlay?.days_checked &&
                                !this.state.loadingOverlay && (
                                  <div className="upgrade-market-overlay">
                                    <div className="spinner-loader h-100">
                                      <div className="upgrade-market-flex-wrap h-100 loader-primary">
                                        <div className="upgrade-market-center">
                                          <div className="upgrade-market-img-wrap">
                                            <img
                                              src={require("../../../images/market-overlay.svgz")}
                                              alt=""
                                              width="180"
                                            />
                                          </div>
                                          <div className="upgrade-market-txt-wrap">
                                            <div className="upgrade-market-content-head">
                                              Market Overlay
                                            </div>
                                            <div className="upgrade-market-content">
                                              Real-Time Pricing Tool is a market
                                              leading valuation tool that
                                              outputs a Wholesale/Trade & Retail
                                              trade price.
                                            </div>
                                            <div className="upgrade-market-content-btn">
                                              {(marketOverlayEnabled ||
                                                autograbIntegratonEnabled) &&
                                                !this.state.loadingOverlay &&
                                                this.state.leads.length === 0 ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.getVehicleMarketOverlay(true)
                                                  }
                                                  }
                                                >
                                                  {" "}
                                                  View Market Overlay
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {!this.state.loadingOverlay &&
                                this.state.overlay?.days_checked && (
                                  <div className="row text-center mr-0 ml-0">
                                    <div className="market-count-wrapper">
                                      <div className="col">
                                        <div className="market-counter">
                                          <p className="market-count-text ">
                                            {" "}
                                            Average KM
                                          </p>
                                          <h2 className="market-count-title">
                                            {this.formatPrice(
                                              this.state.averageKm
                                            )}{" "}
                                            KM
                                          </h2>
                                        </div>
                                      </div>

                                      <div className="col">
                                        <div className="market-counter">
                                          <p className="market-count-text ">
                                            {" "}
                                            Supply Days
                                          </p>
                                          <h2 className="market-count-title">
                                            {this.formatPrice(
                                              this.state.daysSupply
                                            )}{" "}
                                            d
                                          </h2>
                                        </div>
                                      </div>

                                      <div className="col">
                                        <div className="market-counter">
                                          <p className="market-count-text ">
                                            {" "}
                                            Avg Days to Sell
                                          </p>
                                          <h2 className="market-count-title">
                                            {this.state.overlay
                                              ?.avg_days_to_sell
                                              ? this.formatPrice(
                                                this.state.overlay
                                                  ?.avg_days_to_sell
                                              )
                                              : 0}{" "}
                                            d
                                          </h2>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="market-counter">
                                          <p className="market-count-text ">
                                            {" "}
                                            Avg Price
                                          </p>
                                          <h2 className="market-count-title">
                                            {currencySymbol}
                                            {this.formatPrice(
                                              this.state.averagePrice
                                            )}
                                          </h2>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="market-counter">
                                          <p className="market-count-text ">
                                            {" "}
                                            Avg Age
                                          </p>
                                          <h2 className="market-count-title">
                                            {this.formatPrice(
                                              this.state.averageAge
                                            )}{" "}
                                            d
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {!this.state.loadingOverlay &&
                                this.state.overlay?.days_checked && (
                                  <div className="market-filter-panel mt-4">
                                    <div className="float-left d-flex ">
                                      <div className="filter-search search-icon">
                                        <input
                                          aria-label="Search"
                                          placeholder="Search vehicles..."
                                          value={this.state.searchValue}
                                          onChange={this.handleSearchChange}
                                        />
                                      </div>
                                      <div className="market-searchform-seller-type mb-0">
                                        <ReactSelect
                                          options={[
                                            {
                                              label: "Private",
                                              value: "private",
                                            },
                                            {
                                              label: "Dealer",
                                              value: "dealer",
                                            },
                                          ]}
                                          value={this.state.searchBySellerType}
                                          placeholder={"select seller type"}
                                          onChange={(e) =>
                                            this.handleSellerTypeChange(
                                              e?.value
                                            )
                                          }
                                        ></ReactSelect>
                                      </div>
                                      <div className="market-searchform-seller-type mb-0">
                                        <ReactSelect
                                          options={this.state.sources.map(
                                            (item) => {
                                              return {
                                                label: item,
                                                value: item,
                                              };
                                            }
                                          )}
                                          value={this.state.searchBySource}
                                          placeholder={"select source"}
                                          onChange={(e) =>
                                            this.handleSourceChange(e?.value)
                                          }
                                        ></ReactSelect>
                                      </div>
                                      <div className="market-searchform-seller-type mb-0">
                                        <ReactSelect
                                          options={this.loadState()}
                                          value={this.state.searchByState}
                                          placeholder={"select state"}
                                          onChange={(e) =>
                                            this.handleStateChange(e?.value)
                                          }
                                        ></ReactSelect>
                                      </div>

                                      <div className="market-check">
                                        <div className="checkbox icheck-success">
                                          <input
                                            type="checkbox"
                                            id="showActive"
                                            name="showActive"
                                            className="uncheck-activity"
                                            checked={this.state.showActive}
                                            onChange={this.handleSearchChange}
                                          />
                                          <label htmlFor="showActive">
                                            Active
                                          </label>
                                        </div>
                                      </div>
                                      <div className="market-check">
                                        <div className="checkbox icheck-success">
                                          <input
                                            type="checkbox"
                                            id="showDeListed"
                                            name="showDeListed"
                                            className="uncheck-activity"
                                            checked={this.state.showDeListed}
                                            onChange={this.handleSearchChange}
                                          />
                                          <label htmlFor="showDeListed">
                                            Delisted
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {!this.state.loadingOverlay &&
                                this.state.overlay?.days_checked && (
                                  <div className="market-list-table">
                                    <div className="common-table">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="head-light"
                                              width="5%"
                                            >
                                              &nbsp;
                                            </th>
                                            <th
                                              className="head-light"
                                              width="20%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("year");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "year" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Year
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="20%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("date");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "date" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Release Date
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort(
                                                    "seller_type"
                                                  );
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "seller_type" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Seller Type
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("price");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "price" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Price
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("kms");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key === "kms" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                KM
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("color");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "color" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Color
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("state");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "state" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                State
                                              </div>
                                            </th>
                                            <th
                                              className="head-light"
                                              width="10%"
                                            >
                                              <div
                                                className="sort-cell cursor-pointer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.requestSort("ageCount");
                                                }}
                                              >
                                                <div className="table-sort">
                                                  <a href="#">
                                                    {sortConfig &&
                                                      sortConfig.key ===
                                                      "ageCount" ? (
                                                      <i
                                                        className={`ico icon-sort-${sortConfig.direction ===
                                                          "ascending"
                                                          ? "asc"
                                                          : "desc"
                                                          }`}
                                                      ></i>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </a>
                                                </div>
                                                Age
                                              </div>
                                            </th>
                                            <th
                                              scope="col"
                                              className="head-light"
                                              width="10%"
                                            >
                                              Sources
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {leadsToMap?.length !== 0 ? (
                                            leadsToMap?.map((lead, key) => {
                                              return (
                                                <tr
                                                  key={key}
                                                  className={`cursor-pointer ${lead?.removed_at
                                                    ? "est-trade-red"
                                                    : ""
                                                    }`}
                                                  // className="cursor-pointer"
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    if (lead?.listing_url) {
                                                      window.open(
                                                        lead?.listing_url,
                                                        "_blank"
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <td>
                                                    <div className="market-vehicle-image">
                                                      {makeModels?.logo && (
                                                        <img
                                                          src={makeModels?.logo}
                                                          alt=""
                                                          width="70"
                                                          height="70"
                                                        />
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {lead.year}
                                                    {!lead.removed_at && (
                                                      <p>
                                                        Active Since :{" "}
                                                        {moment(
                                                          lead.listed_at
                                                        ).format(
                                                          "DD/MM/YYYY h:mm A"
                                                        )}
                                                      </p>
                                                    )}
                                                    {lead.removed_at && (
                                                      <p>
                                                        Delisted Since :{" "}
                                                        {moment(
                                                          lead.removed_at
                                                        ).format(
                                                          "DD/MM/YYYY h:mm A"
                                                        )}
                                                      </p>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.getMonthNameFromNumber(
                                                      lead.release_month
                                                    )}
                                                    , {lead.release_year}
                                                  </td>
                                                  <td>
                                                    {lead?.seller_type
                                                      ?.charAt(0)
                                                      .toUpperCase() +
                                                      lead?.seller_type?.slice(
                                                        1
                                                      )}
                                                  </td>
                                                  <td>
                                                    {currencySymbol}
                                                    {this.formatPrice(
                                                      lead.price
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.formatPrice(lead.kms)}{" "}
                                                    km
                                                  </td>
                                                  <td>{lead.color}</td>
                                                  <td>{lead.state}</td>
                                                  <td>{lead.age}</td>
                                                  <td>
                                                    {lead.listing_sources.map(
                                                      (tag) => {
                                                        return (
                                                          <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                              <Tooltip>
                                                                <span>
                                                                  {tag}
                                                                </span>
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <img
                                                              src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${tag}&size=256`}
                                                              width="22"
                                                              height="22"
                                                              className="mr-2 cursor-pointer mb-2"
                                                            />
                                                          </OverlayTrigger>
                                                        );
                                                      }
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan={11}
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                No Data Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <PopUpModal show={showPpsrScan}>
          <PpsrScan
            show={showPpsrScan}
            clsActive="overlay-modal active"
            regNo={selectedVehicle?.regNo}
            enquiryID={selectedVehicle?.enquiryID}
            serviceID={selectedVehicle?.serviceID}
            clientID={selectedVehicle?.clientID}
            tradeinID={selectedVehicle?.documentID}
            chassisNo={selectedVehicle?.chassisNo}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            handleClose={() => {
              this.setState({ showPpsrScan: false });
            }}
          ></PpsrScan>
        </PopUpModal>
      </>
    );
  }
}

export default AutoGrab;
