import React, { useEffect, useRef, useState, useContext } from 'react';
import { Dropdown, Modal, Popover } from 'react-bootstrap';
import _, { round, union, isEmpty } from 'lodash';
import toast from 'toasted-notes';
import moment from 'moment';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { Progress } from 'antd';
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';
import { MainContext } from '../layout/provider';
import SummerEditor from './summereditor/summereditor';
import ReactSummernote from 'react-summernote';
import { storageBucket, validateEmail } from '../../services/helper';
import { InputTags } from "react-bootstrap-tagsinput";
import { useHistory } from 'react-router-dom';
import enquiryHelper from '../../services/enquiryHelper';
import emailHelper from '../../services/emailHelper';

function insert(str, index, value) {
	return str.substr(0, index) + value + str.substr(index);
}

const ComposeEmail = (props) => {
	const history = useHistory();
	const [sendLoader, setSendLoader] = useState(false);
	const [attachments, setAttachments] = useState(props.file ? props.file : []);
	const [progress, setProgress] = useState(null);
	const [showPop, setShowPop] = useState(false);
	const { emailTemplates, clientUsers, groupUsers } = useContext(MainContext)
	const [templates] = useState(emailTemplates ? emailTemplates : []);
	const [templateSearchText, setTemplateSearchText] = useState('');
	const node = useRef();
	const handleInitializeEmailBody = () => {
		let emailHtml;
		if (props.type === 'reply') {
			if (props.emailData.replyToEmailContent) {
				emailHtml = `<br><br><br><p>On ${moment
					.unix(props.emailData.replyEmailDate)
					.format('MMM DD, YYYY hh:mm A')}, ${props.emailData.replyEmailName
					} &#60;${props.emailData.replyEmailAddress}&#62; wrote: </p>${props.emailData.replyToEmailContent}`
			} else {
				emailHtml = '<p></p>'
			}
			return emailHtml;
		} else if (props.type === 'forward') {
			return `<p>---------- Forwarded message ----------</p>${props.emailData.replyToEmailContent}`;
		}

		if (props.userData && props.userData.emailSignature) {
			emailHtml = '<p></p>';
			emailHtml = insert(
				emailHtml,
				emailHtml.length - 4,
				props.userData.emailSignature,
			);

			return emailHtml;
		}
		return '<p></p>';
	};

	const replyToAll = !_.isEmpty(props.emailData)
		? props.emailData.replyToAll && true
		: false;
	const [errorFields] = useState({});
	const [trigger, setTrigger] = useState({
		mailTo:
			props.type === 'reply'
				? props.emailData.replyTo.map(replyObj => replyObj.email).join(',')
				: (props.type === 'compose' && !_.isEmpty(props.emailData) && !_.isEmpty(props.emailData.mailTo) ? props.emailData.mailTo.map(replyObj => replyObj.email).join(',') : ''),
		mailCc: replyToAll
			? props.emailData.replyToCC.map(replyObj => replyObj.email).join(',')
			: '',
		mailBcc: '',
		replyTo: '',
		subject: props.type === 'reply' ? `Re: ${props.emailData.subject}` : props.type === 'forward' ? `Fwd: ${props.emailData.subject}` : '',
	});
	const [email, setEmail] = useState(handleInitializeEmailBody())
	const [state, setState] = useState({
		showCc: replyToAll ? true : false,
		showBcc: false,
	});

	useEffect(() => {
		if (!_.isEmpty(props.contact) && props.title === 'New Message') {
			setTrigger({
				...trigger,
				mailTo: !_.isEmpty(props.contact) && !_.isEmpty(props.contact.email) ? props.contact.email : '',
			});
		}
	}, []);

	useEffect(() => {
		const loadMessages = async () => {
			if (!props.thread) {
				return;
			}

			let check = props.thread.latestDraftOrMessage;

			setTrigger({
				...trigger,
				mailTo: _.isEmpty(check?.to)
					? ''
					: check.to.map(replyObj => replyObj.email).join(','),
				mailCc: _.isEmpty(check?.cc)
					? ''
					: check.cc.map(replyObj => replyObj.email).join(','),
				mailBcc: _.isEmpty(check?.bcc)
					? ''
					: check.bcc.map(replyObj => replyObj.email).join(','),
				replyTo: _.isEmpty(check?.replyTo)
					? ''
					: check?.replyTo.map(replyObj => replyObj.email).join(','),
				subject: _.isEmpty(check?.subject) ? '' : check.subject,
				// email: _.isEmpty(check.body) ? '' : check.body,
			});
			setEmail(_.isEmpty(check?.body) ? '' : check.body)
			if (!_.isEmpty(check?.body)) {
				ReactSummernote.reset()
				var node = document.createElement('div');
				node.innerHTML = `${check.body}`
				ReactSummernote.insertNode(node)
			}
		};

		loadMessages();
	}, [props.thread]);

	const handleInsertSignature = () => {
		if (props.userData && props.userData.emailSignature) {
			ReactSummernote.reset()
			var node = document.createElement('div');
			node.innerHTML = `${email.replace(props.userData.emailSignature, '')}<br>${props.userData.emailSignature}`
			ReactSummernote.insertNode(node)
		}
	};

	const handleInsertTemplate = emailTemplate => {
		ReactSummernote.reset()

		var node = document.createElement('div');
		node.innerHTML = `${CommonHelper.mapTemplate(
			emailTemplate.bodyHTML,
			props.contact,
			props.enquiry,
			props.dealersettings,
			(props.dealersettings.group && props.dealersettings.group.enableSharedContact === true) ? groupUsers : clientUsers,
			props.tradeinPro,
			props.service
		)}`
		ReactSummernote.insertNode(node)
		return;
	};

	const onEditorStateChange = obj => {
		setTrigger({
			...trigger,
			[obj.name]: obj.value,
		});
	};

	const handleChange = obj => {
		setEmail(obj)
	};

	// for email
	const handleOnChangeMultipleText = (val, name) => {
		setTrigger({
			...trigger,
			[name]: !_.isEmpty(val) ? val.filter(e => validateEmail(e)).join(',') : '',
		});
	};

	const removeEmail = (index, name) => {
		let arr = trigger[name] ? trigger[name].split(',') : [];
		arr.splice(index, 1);
		setTrigger({
			...trigger,
			[name]: arr.join(','),
		});
	};

	const onSelectFile = e => {
		if (progress) {
			return;
		}

		if (e.target.files && e.target.files.length > 0) {
			var _size = e.target.files[0].size;
			var filesize = _size / 1024 / 1024;

			if (filesize > 25) {
				Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
				return;
			}

			// check if file size of all attachments is not more than 25 mb
			let totalSize = attachments.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.size;
			}, 0);

			totalSize = totalSize + _size;
			totalSize = totalSize / 1024 / 1024;

			if (totalSize > 25) {
				Swal.fire(CommonHelper.showLocale(props, 'Maximum files size exceeded.'), '', 'info');
				return;
			}

			let reader = new FileReader();
			let file = e.target.files[0];
			let uid = moment()._d.getTime().toString();
			let arrayToReturn;
			// // console.log('uid at onSelectFile', uid);
			reader.onloadend = () => {
				arrayToReturn = [
					...attachments,
					{
						uid,
						name: file.name,
						size: file.size,
						isUploaded: false,
						url: null,
						path: null,
					},
				];
				setAttachments(_.orderBy(arrayToReturn, ['uid'], ['desc']));
				saveFileToStorage(
					file,
					uid,
					_.orderBy(arrayToReturn, ['uid'], ['desc']),
				);
			};
			reader.readAsDataURL(file);
		}
	};

	const saveFileToStorage = (file, uid, attachments) => {
		let clientID = props.dealersettings ? props.dealersettings.client.id : '';
		// // console.log('uid at saveFileToStorage', uid);

		var storageRef = storageBucket(props.dealersettings).ref(`${clientID}/emailattachments/${localStorage.uid}/${uid}/${file.name}`).put(file);
		storageRef.on(
			'state_changed',
			snapshot => {
				var percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				if (percentage > 0 && percentage < 99) {
					setProgress(round(percentage));
					// console.log('percentage running', percentage);
				} else {
					// setImageLoader(false)
					// console.log('percentage done');
					// console.log('storage red get url');
					storageRef.snapshot.ref
						.getDownloadURL()
					// .then(downloadURL => console.log(downloadURL));
				}
			},
			error => {
				console.error(error);
			},
			() => {
				storageBucket(props.dealersettings).ref(`${clientID}/emailattachments/${localStorage.uid}/${uid}`)
					.child(file.name)
					.getDownloadURL()
					.then(dataURL => {
						// console.log(dataURL);
						// console.log('uid at finding', uid);

						// console.log(
						// 	' is ir running or nott',
						// 	attachments.forEach(att => // console.log(att)),
						// );
						let attachmentObj = attachments.filter(att => att.uid === uid)[0];
						let allAttachments = attachments.filter(att => att.uid !== uid);

						// console.log('attachmentObj at finding', attachmentObj);
						attachmentObj.isUploaded = true;
						attachmentObj.url = dataURL;
						attachmentObj.path = `${clientID}/emailattachments/${localStorage.uid}/${uid}/${file.name}`;
						setProgress(null);
						setAttachments(
							_.orderBy(
								union([attachmentObj], allAttachments),
								['uid'],
								['desc'],
							),
						);
						return dataURL;
					});
			},
		);
	};

	const handleRemoveAttachment = uid => {
		// console.log('handleRemove', uid);
		let allAttachments = attachments.filter(att => att.uid !== uid);
		setAttachments(_.orderBy(allAttachments, ['uid'], ['desc']));
	};

	const handleSubmit = (createDraft) => {
		if (progress !== null) {
			return;
		}

		let state = trigger;
		state.email = email;
		if (attachments.length > 0) {
			state.files = attachments.map(attachment => attachment.path);
		} else {
			state.files = [];
		}

		console.log('emailData on submut', state, props.emailData)
		if (props.type === 'reply') {
			handleReply(state, props.emailData, replyToAll);
		} else {
			handleSend(state, props.emailData, replyToAll, createDraft);
		}
	};

	//#region REPLY

	const handleReply = async (trigger, emailData, replyToAll) => {
		const { mailTo, mailCc, mailBcc } = trigger;		

		if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
			toast.notify('Please specify at least one recipient.', { duration: 2000 });			
			return;
		}

		try {
			setSendLoader(true);
			let nylasAccountID = null;
			if (props.dealersettings && !isEmpty(props.dealersettings.nylas_v3)) {
				nylasAccountID = props.dealersettings.nylas_v3.grantId
			} else if (props.userData && !isEmpty(props.userData.nylas_v3)) {
				nylasAccountID = props.userData.nylas_v3.grantId
			}
			let emailObj = {
				grantId: nylasAccountID,
				draft: {
					subject: trigger.subject,
					body: trigger.email,
				},
			};

			emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
				return {
					name: '',
					email: email,
				};
			}) : [];
			// emailObj.draft.replyTo = trigger.mailTo.split(',').map(email => {
			// 	return {
			// 		name: '',
			// 		email: email,
			// 	};
			// });
			if (replyToAll) {
				emailObj.draft.replyToCC = !_.isEmpty(trigger?.mailTo) ? trigger.mailCc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				}) : []
			}

			if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}

			emailObj.draft.body = trigger.email;
			emailObj.draft.replyToMessageId = emailData.replyToMessageId;

			console.log('handleReply', emailObj);
			toast.notify((
				<div style={{ fontSize: `14px` }}>
					<span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span>
					sending...
				</div>),
				{
					position: 'bottom-left',
					duration: null
				},
			)
			setSendLoader(false);
			props.handleClose('reply');
			await emailHelper.handleSend(emailObj, props, history)
						
		} catch (error) {
			setSendLoader(false);
			console.log(error);
		}
	};
	//#endregion
	//#region SEND
	const handleSend = async (trigger, emailData, replyToAll, createDraft) => {
		const { mailTo, mailCc, mailBcc, subject, email } = trigger;


		if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
			toast.notify('Please specify at least one recipient.', { duration: 2000 });
			return;
		}

		if (_.isEmpty(subject)) {
			toast.notify('Please write subject.', { duration: 2000 });
			return;
		}

		if (_.isEmpty(email) || email === '<p></p>\n') {
			toast.notify('Please write in email body.', { duration: 2000 });
			return;
		}

		let nylasAccountID = null;
		if (props.dealersettings && !isEmpty(props.dealersettings.nylas_v3)) {
			nylasAccountID = props.dealersettings.nylas_v3.grantId
		} else if (props.userData && !isEmpty(props.userData.nylas_v3)) {
			nylasAccountID = props.userData.nylas_v3.grantId
		}

		try {
			setSendLoader(true);

			let emailObj = {
				grantId: nylasAccountID,
				//createDraft: (createDraft || false),
				//returnThread: true,
				draft: {
					subject: trigger.subject,
					body: trigger.email,
					// metadata: {
					// 	key1: 'enquiry001',
					// 	key2: 'thread001'
					// }
				},
			};

			emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
				return {
					name: '',
					email: email,
				};
			}) : [];
			emailObj.draft.cc = !_.isEmpty(trigger?.mailCc) ? trigger.mailCc.split(',').map(email => {
				return {
					name: '',
					email: email,
				};
			}) : [];
			emailObj.draft.bcc = !_.isEmpty(trigger?.mailBcc) ? trigger.mailBcc.split(',').map(email => {
				return {
					name: '',
					email: email,
				};
			}) : [];

			if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}

			toast.notify((
				<div style={{ fontSize: `14px` }}>
					<span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span>
					sending...
				</div>),
				{
					position: 'bottom-left',
					duration: null
				},
			)
			setSendLoader(false);
			props.handleClose('send');
			await emailHelper.handleSend(emailObj, props, history)
			// console.log('response', resp);
		} catch (error) {
			Swal.fire('There was an error in sending email.', '', 'error');

			setSendLoader(false);
			console.log(error);
		}
	};
	//#endregion

	//#region DEPRECATED FOR OPTIMIZATION
	// const handleSend = async (trigger, emailData, replyToAll, createDraft) => {
	// 	const { mailTo, mailCc, mailBcc, subject, email } = trigger;
	// 	setSendLoader(true);

	// 	if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
	// 		toast.notify('Please specify at least one recipient.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(subject)) {
	// 		toast.notify('Please write subject.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	if (_.isEmpty(email) || email === '<p></p>\n') {
	// 		toast.notify('Please write in email body.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	let nylasAccountID = null;
	// 	if (props.dealersettings && !isEmpty(props.dealersettings.nylas_v3)) {
	// 		nylasAccountID = props.dealersettings.nylas_v3.grantId
	// 	} else if (props.userData && !isEmpty(props.userData.nylas_v3)) {
	// 		nylasAccountID = props.userData.nylas_v3.grantId
	// 	}

	// 	try {
	// 		let emailObj = {
	// 			grantId: nylasAccountID,
	// 			//createDraft: (createDraft || false),
	// 			//returnThread: true,
	// 			draft: {
	// 				subject: trigger.subject,
	// 				body: trigger.email,
	// 				metadata: {
	// 					key1: 'enquiry001',
	// 					key2: 'thread001'
	// 				}
	// 			},
	// 		};

	// 		emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		emailObj.draft.cc = !_.isEmpty(trigger?.mailCc) ? trigger.mailCc.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		emailObj.draft.bcc = !_.isEmpty(trigger?.mailBcc) ? trigger.mailBcc.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];

	// 		if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
	// 			emailObj.files = trigger.files;
	// 		}



	// 		console.log('nylasv3-sendEmail', emailObj);
	// 		const nylasSendEmail = window.firebase
	// 			.functions()
	// 			.httpsCallable('nylasv3-sendEmail');
	// 		const resp = await nylasSendEmail(emailObj);



	// 		let thread = resp.data.thread
	// 		let message = resp.data.data
	// 		console.log('res of email that i sent', resp);
	// 		console.log('res message', message);
	// 		console.log('res thread', thread);

	// 		// check if we are in sent 
	// 		if (window.location.pathname === '/mails/sent') {
	// 			// setMessages(
	// 			// 	_.orderBy(
	// 			// 		_.unionBy(threads, [thread], 'id'),
	// 			// 		['last_message_timestamp'],
	// 			// 		['desc'],
	// 			// 	),
	// 			// );
	// 		}


	// 		if (resp.data.success) {
	// 			enquiryHelper.linkEnquiry(resp.data, props);
	// 			setSendLoader(false);
	// 			setTimeout(() => {
	// 				toast.notify('Email has been sent.', { duration: 2000 });
	// 				props.handleClose('send');
	// 			}, 300);
	// 		} else {
	// 			setSendLoader(false);
	// 			if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
	// 				Swal.fire({
	// 					title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
	// 					icon: 'info',
	// 					showCancelButton: true,
	// 					confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
	// 					cancelButtonText: CommonHelper.showLocale(props, 'Later'),
	// 				}).then(result => {
	// 					if (result.value) {
	// 						window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
	// 							nylas: null,
	// 						}, { merge: true });
	// 						props.handleClose();
	// 						history.push('/settings/profile?key=emailsettings');
	// 					}
	// 				});
	// 				return
	// 			}
	// 			else {
	// 				Swal.fire('There was an error in sending email.', '', 'error');
	// 			}
	// 		}

	// 		// console.log('response', resp);
	// 	} catch (error) {
	// 		Swal.fire('There was an error in sending email.', '', 'error');

	// 		setSendLoader(false);
	// 		console.log(error);
	// 	}
	// };
	// const handleReply = async (trigger, emailData, replyToAll) => {
	// 	const { mailTo, mailCc, mailBcc } = trigger;

	// 	setSendLoader(true);

	// 	if (_.isEmpty(mailTo) && _.isEmpty(mailCc) && _.isEmpty(mailBcc)) {
	// 		toast.notify('Please specify at least one recipient.', { duration: 2000 });
	// 		setSendLoader(false);
	// 		return;
	// 	}

	// 	try {
	// 		let nylasAccountID = null;
	// 		if (props.dealersettings && !isEmpty(props.dealersettings.nylas_v3)) {
	// 			nylasAccountID = props.dealersettings.nylas_v3.grantId
	// 		} else if (props.userData && !isEmpty(props.userData.nylas_v3)) {
	// 			nylasAccountID = props.userData.nylas_v3.grantId
	// 		}
	// 		let emailObj = {
	// 			grantId: nylasAccountID,
	// 			draft: {
	// 				subject: trigger.subject,
	// 				body: trigger.email,
	// 			},
	// 		};

	// 		emailObj.draft.to = !_.isEmpty(trigger?.mailTo) ? trigger.mailTo.split(',').map(email => {
	// 			return {
	// 				name: '',
	// 				email: email,
	// 			};
	// 		}) : [];
	// 		// emailObj.draft.replyTo = trigger.mailTo.split(',').map(email => {
	// 		// 	return {
	// 		// 		name: '',
	// 		// 		email: email,
	// 		// 	};
	// 		// });
	// 		if (replyToAll) {
	// 			emailObj.draft.replyToCC = !_.isEmpty(trigger?.mailTo) ? trigger.mailCc.split(',').map(email => {
	// 				return {
	// 					name: '',
	// 					email: email,
	// 				};
	// 			}) : []
	// 		}

	// 		if (!_.isEmpty(trigger.files) && trigger.files.length > 0) {
	// 			emailObj.files = trigger.files;
	// 		}

	// 		emailObj.draft.body = trigger.email;
	// 		emailObj.draft.replyToMessageId = emailData.replyToMessageId;

	// 		console.log('handleReply', emailObj);

	// 		const nylasSendEmail = window.firebase.functions().httpsCallable('nylasv3-sendEmail');
	// 		const resp = await nylasSendEmail(emailObj);
	// 		console.log('handleReply_resp', resp, resp?.data?.data);

	// 		if (resp.data.success) {
	// 			setTimeout(() => {
	// 				toast.notify('Email has been sent.', { duration: 2000 });
	// 				props.handleClose('reply');
	// 			}, 300);
	// 			setSendLoader(false);
	// 		} else {
	// 			setSendLoader(false);
	// 			if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
	// 				Swal.fire({
	// 					title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
	// 					icon: 'info',
	// 					showCancelButton: true,
	// 					confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
	// 					cancelButtonText: CommonHelper.showLocale(props, 'Later'),
	// 				}).then(result => {
	// 					if (result.value) {
	// 						window.firebase.firestore().doc(`users/${localStorage.uid}`).set({ nylas: null, }, { merge: true });
	// 						props.handleClose();
	// 						history.push('/settings/profile?key=emailsettings');
	// 					}
	// 				});
	// 				return
	// 			}
	// 			else {
	// 				Swal.fire('There was an error in sending email.', '', 'error');
	// 			}
	// 		}
	// 	} catch (error) {
	// 		setSendLoader(false);
	// 		console.log(error);
	// 	}
	// };

	const filterTemplates = (template) => {
		if (!templateSearchText) {
			return true
		} else if (template.title.toLowerCase().includes(templateSearchText.toLowerCase())) {
			return true
		}

		return false
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = e => {
		if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner')) {
			return;
		}
		setShowPop(false);
	};

	return (
		<Modal
			id='compose-mail'
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			backdropClassName={props.show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-compose-mail'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title' id=''>
						{props.title}
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='compose-mail-popup form-style'>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12'>
									<div className='input-group'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id=''
												style={{ width: '50px' }}
											>
												To:
											</span>
										</div>
										<InputTags
											placeholder={`enter email`}
											//className={`form-control react-multi-tag ${errorFields['mailTo']}`}
											name='mailTo'
											onTags={emails => {
												handleOnChangeMultipleText(emails.values, 'mailTo');
											}}
											values={
												!_.isEmpty(trigger.mailTo)
													? trigger.mailTo.split(',')
													: []
											}
											elementClassName={'email-tags'}
										/>
										<div className='input-group-append' id='button-addon4'>
											{/* Dont show append button when reply is true */}
											<>
												{!state.showCc && (
													<button
														className='btn btn-outline-secondary'
														type='button'
														onClick={() => setState({ ...state, showCc: true })}
													>
														Cc
													</button>
												)}
											</>
											<>
												{!state.showBcc && (
													<button
														className='btn btn-outline-secondary'
														type='button'
														onClick={() =>
															setState({ ...state, showBcc: true })
														}
													>
														Bcc
													</button>
												)}
											</>
										</div>
									</div>
									{state.showCc && (
										<div className='input-group mt-2'>
											<div className='input-group-prepend'>
												<span
													className='input-group-text'
													id=''
													style={{ width: '50px' }}
												>
													Cc:
												</span>
											</div>
											<InputTags
												placeholder={`enter email`}
												name='mailCc'
												values={
													_.isEmpty(trigger.mailCc)
														? []
														: replyToAll === true
															? trigger.mailCc.split(',')
															: trigger.mailCc.split(',')
												}
												onTags={(value) => handleOnChangeMultipleText(value.values, 'mailCc')}
												elementClassName={'email-tags'}
											//className={`form-control react-multi-tag ${errorFields['mailCc']}`}
											/>
											{/* <InputMultipleText
												placeholder={`enter email`}
												className={`form-control react-multi-tag ${errorFields['mailCc']}`}
												name='mailCc'
												onChange={emails => {
													handleOnChangeMultipleText(emails, 'mailCc');
												}}
												value={
													_.isEmpty(trigger.mailCc)
														? []
														: replyToAll === true
															? trigger.mailCc.split(',')
															: trigger.mailCc.split(',')
												}
												validateEmail={true}
												getLabel={(email, index) => {
													return (
														<div data-tag key={index}>
															{email}
															<span
																data-tag-handle
																onClick={() => removeEmail(index, 'mailCc')}
															>
																×
															</span>
														</div>
													);
												}}
											/> */}
										</div>
									)}
									{state.showBcc && (
										<div className='input-group mt-2'>
											<div className='input-group-prepend'>
												<span
													className='input-group-text'
													id=''
													style={{ width: '50px' }}
												>
													Bcc:
												</span>
											</div>
											<InputTags
												placeholder={`enter email`}
												//className={`form-control react-multi-tag ${errorFields['mailBcc']}`}
												name='mailBcc'
												onTags={emails => {
													handleOnChangeMultipleText(emails.values, 'mailBcc');
												}}
												values={
													!_.isEmpty(trigger.mailBcc)
														? trigger.mailBcc.split(',')
														: []
												}
												elementClassName={'email-tags'}
											/>
										</div>
									)}
								</div>
							</div>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12'>
									<div className='input-group'>
										<div className='input-group-prepend'>
											<span className='input-group-text' id=''>
												Subject:
											</span>
										</div>
										<input
											type='text'
											autoComplete='off'
											className='form-control'
											name='subject'
											placeholder='Subject'
											value={trigger.subject}
											onChange={e => onEditorStateChange(e.target)}
										/>
									</div>
								</div>
							</div>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12 react-summer-editor'>
									{/* <TextEditor
										name={'email'}
										placeholder={'Write Email'}
										value={email}
										onChange={onEditorStateChange}
										toolbarOptions={[
											'fontFamily',
											'fontSize',
											'inline',
											'textAlign',
											'colorPicker',
											'list',
											'history',
										]}
										height={650}
									/> */}
									<SummerEditor
										value={email}
										height={window.innerHeight - 350 + 'px'}
										handleChange={handleChange}
										emailType={props.type}
										customFonts={props.dealersettings?.customFonts || []}
									/>
								</div>
							</div>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12 compose-mail-attach'>
									{attachments.length > 0 &&
										attachments.map(attachment => (
											<div className='mail-attach-list'>
												{attachment.isUploaded && (
													<div
														className='mail-attach-remove'
														onClick={() =>
															handleRemoveAttachment(attachment.uid)
														}
													>
														{' '}
														<i className='ico icon-remove'> </i>
													</div>
												)}
												<a id='' href='#' target='_blank' onClick={(e) => { e.preventDefault(); }}>
													<div className='mail-attach-text'>
														<span className="mailbox-compose-attach"><i className="ico icon-mail-attachment-linear"></i></span>
														{attachment.name}
													</div>
													<div className='mail-attach-size'>
														{' (' +
															CommonHelper.showFileSize(attachment.size) +
															')'}
													</div>
													{progress !== null && !attachment.isUploaded ? (
														<Progress percent={progress} />
													) : (
														<></>
													)}
												</a>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				{/* <div
					className='settings-profile-upload'
					style={{ display: 'block', marginBottom: '0px' }}
				>
					<label
						htmlFor='logoURL'
						className={`btn btn-secondary float-left ml-2 font-600 email-attachments-btn`}
					>
						<i className='ico icon-attachment'></i>
						Attach
						<input
							className='fileInput'
							type='file'
							name='testee'
							id='logoURL'
							accept='*'
							style={{ display: 'none' }}
							onChange={e => onSelectFile(e)}
							disabled={progress ? true : false}
						/>
					</label>
				</div> */}
				<button
					type='button'
					className='btn btn-secondary float-left'
				>
					<label
						htmlFor='logoURL'
						className={`comppose-attachments-btn`}
					>
						<i className='ico icon-attachment'></i>
						Attach
						<input
							className='fileInput'
							type='file'
							name='testee'
							id='logoURL'
							accept='*'
							style={{ display: 'none' }}
							onChange={e => onSelectFile(e)}
							disabled={progress ? true : false}
						/>
					</label>
				</button>
				{
					!_.isEmpty(props?.userData?.emailSignature) && (<button
						type='button'
						className='btn btn-secondary float-left ml-2'
						onClick={handleInsertSignature}
					>
						<i className='ico icon-sign'></i> Signature
					</button>)
				}

				<Dropdown
					className='mailbox-template-btn ml-2 float-left dropdown-toggle'
					alignRight
					ref={node}
					show={showPop}
				>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' onClick={() => {
						setShowPop(!showPop)
					}}>
						<i className='ico icon-template'></i> <span>Templates</span>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right template-add-dropdown'
						ChildClass='pipeline-dropdown'
						xplacement='top-end'
					>
						<Popover
							id='popover-basic-mail'
							className='popover popover-mail-templates popover-x in kv-popover-active show left left-bottom'
							style={{
								top: '-317px',
								left: '42px',
								display: 'block',
								zIndex: '1029',
								padding: '0px',
							}}

						>
							<Popover.Title>
								<h3 className='popover-header popover-title'>
									<span className='close' data-dismiss='popover-x' onClick={() => setShowPop(false)}>
										×
									</span>
									Templates{' '}
								</h3>
								<div className='email-list-search'>
									<div className='input-group template-search'>
										<input type='text' className='form-control' placeholder='Search' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} style={{ width: '100%' }} />
										<div className='input-group-append input-search-clear'>
											{templateSearchText.length > 0 && (
												<a href='#' className='input-search-clear-icon' onClick={(e) => { e.preventDefault(); setTemplateSearchText('') }}>
													<i className='ico icon-remove'></i>
												</a>
											)}
										</div>
									</div>
								</div>
							</Popover.Title>
							<Popover.Content>
								<div className='popover-body popover-content p-0'>
									<div className='mailtemplate-column-nav'>
										<ul>
											{templates && templates.length > 0 && _.orderBy(templates, ['title'], ['asc']).filter(template => filterTemplates(template)).map((template, index) => (
												<li onClick={() => {
													handleInsertTemplate(template);
													setTrigger({
														...trigger,
														subject: template.subject || template.title
													})
													setShowPop(false);
												}} key={index}>
													<a href='#' key={index} onClick={(e) => { e.preventDefault(); }}>{template.title}</a>
												</li>
											))}
										</ul>
									</div>
								</div>
							</Popover.Content>
						</Popover>

					</Dropdown.Menu>
				</Dropdown>
				<button
					type='button'
					className='btn btn-default float-right'
					onClick={props.handleClose}
				>
					{
						// loader ?
						//   (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
						//   : (<></>)
					}
					Discard
				</button>
				<button
					type='button'
					className={`btn btn-primary float-right mr-2 ${(sendLoader) ? 'form-disable' : ''}`}
					aria-label='Close'
					// disabled={type === 'add'}
					onClick={(e) => { e.preventDefault(); handleSubmit() }}
				>
					Send{' '}
					{sendLoader ? (
						<span
							className='spinner-border spinner-button mr-1'
							role='status'
							aria-hidden='true'
						></span>
					) : (
						<></>
					)}
				</button>
				{/* {
					!['reply', 'forward'].includes(type) && _.isEmpty(thread?.draftIds) && (<button
						type='button'
						className={`btn btn-secondary float-right mr-2 ${(sendLoader) ? 'form-disable' : ''}`}
						aria-label='Close'
						// disabled={type === 'add'}
						onClick={(e) => { e.preventDefault(); handleSubmit(true) }}
					>
						Draft{' '}
						{sendLoader ? (
							<span
								className='spinner-border spinner-button mr-1'
								role='status'
								aria-hidden='true'
							></span>
						) : (
							<></>
						)}
					</button>)
				} */}

			</Modal.Footer>
		</Modal>
	);
};

export default ComposeEmail;
