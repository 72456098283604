import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../../images';
import _ from 'lodash'
import RequestForm from '../../integrationSettings/request';
import { PopUpModal } from '../../../../components';
import Translate from '../../../../constants/translate';

const EmailAuth = ({
	dealersettings,
	isUserActivated,
	setShow,
	syncing,
	userData,
	unAuthenticateUser,
	loader,
	NylasEnabled,
	nylasObjectPresent,
	clientUsers,
	handleEnable
}) => {
	const [contactUs, setContactUsShow] = useState(false)
	// const [clients, setClients] = useState([])
	const [selectedClient, setSelectedClient] = useState(dealersettings.client)

	return (
		<>
			<div className='email-settings-inner form-style'>
				{(isUserActivated === false) ? (
					<div className='email-sync-wrap'>
						{
							syncing ? (<div className="alert alert-primary" role="alert">
								<span className="spinner-border spinner-button mr-2 mt-1" role="status" aria-hidden="true"></span>
								<Translate text={'We are syncing your email account with AMS Pro'} />
							</div>) : (<></>)
						}

						<h1><Translate text={'Your Account Email is Disabled'} /></h1>
						{(userData?.nylasAccessToken || userData?.nylas?.token) && (<p>
							<Translate text={'Your email account has expired and requires an update to the latest version.'} />
						</p>)
						}
						<p><Translate text={'Link or disable your email account below'} />

						</p>
						<div className='email-active-sync'>
							<div className='email-active-sync-item active-tab'>
								{(NylasEnabled || nylasObjectPresent) ? (
									<div className='email-sync-status'>
										{syncing ? (
											<button
												type='button'
												className='btn btn-common float-right'
											>
												<span
													className='spinner-border spinner-button mr-1 mt-0'
													role='status'
													aria-hidden='true'
												></span>
												<Translate text={'Syncing'} />
											</button>
										) : (
											<button
												type='button'
												className='btn btn-common float-right'
												onClick={() => {
													if (handleEnable)
														handleEnable()
													else
														setShow(true)
												}}
											>
												{loader && (
													<span
														className='spinner-border spinner-button mr-1 mt-0'
														role='status'
														aria-hidden='true'
													></span>
												)}
												<Translate text={'enable'} />
											</button>
										)}
									</div>
								) : (
									<div className='email-sync-status'>
										<button
											type='button'
											className='btn btn-common float-right btn-primary-fullscreen'
											style={{ borderRadius: '4px' }}
											onClick={() => setContactUsShow(true)}
										>
											<Translate text={'contactUs'} />
										</button>
									</div>
								)}
								<h4 className='d-flex align-items-center'>
									<span className='email-active-logo' style={{ width: '34px' }}>
										<img
											src={_images.mailsync}
											width='34'
											height='25'
											alt=''
											style={{ width: '34px', height: '25px' }}
										/>
									</span>
									{dealersettings.email ? dealersettings.email : 'User has no email'}
								</h4>
							</div>
						</div>
					</div>
				) : (
					<div className='email-sync-wrap'>
						<h1><Translate text={'Your Account Email is Enabled'} /></h1>
						<p><Translate text={'Link or disable your email account below'} />

						</p>

						<div className='email-active-sync'>
							<div className='email-active-sync-item active-tab'>
								{(NylasEnabled || nylasObjectPresent) ? (
									<div className='email-sync-status'>
										<button
											type='button'
											className='btn btn-common float-right'
											onClick={unAuthenticateUser}
										>
											{loader && (
												<span
													className='spinner-border spinner-button mr-1 mt-0'
													role='status'
													aria-hidden='true'
												></span>
											)}
											<Translate text={'disable'} />
										</button>
									</div>
								) : (
									<div className='email-sync-status'>
										<button
											type='button'
											className='btn btn-common float-right btn-primary-fullscreen'
											style={{ borderRadius: '4px' }}
											onClick={() => setContactUsShow(true)}
										>
											<Translate text={'contactUs'} />
										</button>
									</div>
								)}


								<h4 className='d-flex align-items-center'>
									<span className='email-active-logo' style={{ width: '34px' }}>
										<img
											src={_images.mailsync}
											width='34'
											height='25'
											alt=''
											style={{ width: '34px', height: '25px' }}
										/>
									</span>
									{userData.nylas_v3 ? userData.nylas_v3.email_address : dealersettings.email}
								</h4>
							</div>
						</div>
					</div>
				)}
			</div>
			<PopUpModal show={contactUs}>
				<RequestForm
					show={contactUs}
					handleClose={() => setContactUsShow(false)}
					title={'Request - Nylas'}
					requests={{
						module: 'nylas',
						image: _images.nylas,
						title: 'Nylas'
					}}
					// clients={clients}
					selectedClient={selectedClient}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={[]}
				>
				</RequestForm>
			</PopUpModal>
		</>
	);
};

export default EmailAuth;
