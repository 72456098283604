export const vehicleMakeFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Category',
        value: 'category',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const vehicleModelFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Group',
        value: 'group',
        flex: 1,
        default: true
    },
    {
        name: 'Type',
        value: 'type',
        flex: 1,
        default: true
    },

    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const makeVM = {
    documentID: null,
    name: '',
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    isDeleted: false
}

export const modelVM = {

    name: '',
    value: '',
    group: '',
    type: '',
    capID: null,
    kw: null
}


export const categories = [
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Boat', label: 'Boat' },
    { value: 'Motorcycle', label: 'Motorcycle' },
    { value: 'Portables', label: 'Portables' }
]
