import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import images from '../../../../images';
import QRCode from 'react-qr-code';
import {
    multiFactor,
    TotpMultiFactorGenerator,
    TotpSecret,
    getAuth,
} from "firebase/auth";
import { InputOTP, InputText } from '../../../../components';
import Swal from 'sweetalert2';

const TwoFactorAuthModel = (props) => {
    const [qrCode, setQRCode] = useState()
    const [key, setKey] = useState()
    const [errors, setError] = useState({})
    const [loader, setloader] = useState(false)
    const [totpSecret, settotpSecret] = useState()
    const [currentUser, setcurrentUser] = useState()
    const [showQRCode, setshowQRCode] = useState(false)
    const app_name = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? 'AMS Pro' : 'AMS Pro QA'

    useEffect(() => {
        currentauth()
        async function currentauth() {
            try {
                const _currentUser = props.currentUser;
                if (_currentUser) {
                    const multiFactorSession = await _currentUser.multiFactor.getSession();

                    if (multiFactorSession?.credential && _.isEmpty(multiFactorSession?.auth) && !_.isEmpty(multiFactorSession?.user?.auth))
                        multiFactorSession.auth = multiFactorSession?.user?.auth;

                    const _totpSecret = await TotpMultiFactorGenerator.generateSecret(
                        multiFactorSession
                    );
                    const totpUri = _totpSecret.generateQrCodeUrl(
                        _currentUser.email,
                        app_name
                    );
                    setcurrentUser(_currentUser)
                    settotpSecret(_totpSecret)
                    setQRCode(totpUri)
                }
            } catch (err) {
                console.error(err)
            }
        }
    }, [])


    const handleActivate = async () => {
        let errorClass = 'input_error';
        let _errors = {};
        let formIsValid = true;
        if (_.isEmpty(key)) {
            _errors['key'] = errorClass;
            formIsValid = false;
        }
        setError(_errors)
        if (!formIsValid) {
            return;
        }
        setloader(true)
        try {
            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
                totpSecret,
                key
            );
            await currentUser.multiFactor.enroll(multiFactorAssertion, app_name);
            setloader(false)
            if (props?.currentUser?.uid) {
                await window.firebase.firestore().doc(`/users/${props.currentUser.uid}`).update({ enableTOTP: true, enableTOTPOn: window.firebase.firestore.Timestamp.now() });
            }
            Swal.fire("You've successfully enabled two-factor authentication.", '', 'success');
            props.handleClose();
            window.firebase.auth().signOut();
        } catch (err) {
            _errors["totpmessage"] = err.message
            if (err.code === 'auth/network-request-failed') {
                _errors["totpmessage"] = 'Code is incorrect/expired.'
            }
            setloader(false)
            setError(_errors)
            console.log(err)
        }

    }

    return (<>
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-authenticator-custom"
            enforceFocus={false}
        >
            <Modal.Header>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="authenticator-step-wrap">
                    {/* <div className="authenticator-step-wrap-head">Activate the Authenticator App </div> */}
                    <div className="authenticator-step-wrap-text">You'll need to install a two-factor authentication application on your smartphone/tablet such as <strong>Google Authenticator</strong> (Android/iPhone/BlackBerry) <strong>Microsoft Authenticator</strong> (Windows Phone) </div>


                    <div className="authenticator-step-wrap-content">



                        <div className="authenticator-loop-step">
                            <div className="authenticator-loop-step-head"> <span className="badge badge-pill badge-authenticator mr-2">1</span> Install authenticator</div>
                            <div className="authenticator-loop-step-text">Install <strong>Google Authenticator</strong> or <strong>Microsoft Authenticator</strong> on your phone</div>
                            <div className="authenticator-install-buttonwrap">
                                <button type="button" className="btn btn-common float-left" onClick={() => {
                                    setshowQRCode({
                                        show: true,
                                        title: (<h5 className="modal-title" id=""><img src={images.googleauthenticator} height="40" /> Install Google Authenticator</h5>),
                                        type: 'google'
                                    })
                                }} ><img src={images.googleauthenticator} height="20" className="mr-1" /> Install Google Authenticator</button>
                                <button type="button" className="btn btn-common float-left ml-2" onClick={() => {
                                    setshowQRCode({
                                        show: true,
                                        title: (<h5 className="modal-title" id=""><img src={images.microsoftauthenticator} height="40" /> Install Microsoft Authenticator</h5>),
                                        type: 'microsoft'
                                    })
                                }}><img src={images.microsoftauthenticator} height="20" className="mr-1" /> Install Microsoft Authenticator</button>
                            </div>
                            {/* <div className="authenticator-install-wrap">

                                <div className="authenticator-install-box">

                                    <div className="authenticator-install-box-panel">
                                        <div className="authenticator-install-head"><img src={images.googleauthenticator} height="40" /> Install Google Authenticator</div>
                                        <div className="authenticator-install-qr-box">
                                            <div className="authenticator-install-qr"><img src={images.googleauthenticatorappstore} /></div>
                                            <div className="authenticator-install-download">

                                                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img src={images.appstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                            </div>


                                        </div>
                                        <div className="authenticator-install-qr-box">
                                            <div className="authenticator-install-qr"><img src={images.googleauthenticatorplaystore} /></div>
                                            <div className="authenticator-install-download">

                                                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en&amp;gl=US" target="_blank"><img src={images.playstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                            </div>


                                        </div>
                                    </div>

                                </div>


                                <div className="authenticator-install-box">

                                    <div className="authenticator-install-box-panel">
                                        <div className="authenticator-install-head"><img src={images.microsoftauthenticator} height="40" /> Install Microsoft Authenticator</div>
                                        <div className="authenticator-install-qr-box">
                                            <div className="authenticator-install-qr"><img src={images.microsoftauthenticatorappstore} /></div>
                                            <div className="authenticator-install-download">

                                                <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank"><img src={images.appstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                            </div>


                                        </div>
                                        <div className="authenticator-install-qr-box">
                                            <div className="authenticator-install-qr"><img src={images.microsoftauthenticatorplaystore} /></div>
                                            <div className="authenticator-install-download">

                                                <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&amp;hl=en_IN&amp;gl=US" target="_blank"><img src={images.playstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /> </a>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div> */}


                        </div>

                    </div>

                    <div className="authenticator-step-wrap-content">

                        <div className="authenticator-loop-step">
                            <div className="authenticator-loop-step-head"> <span className="badge badge-pill badge-authenticator mr-2">2</span> Configure the app</div>
                            <div className="authenticator-loop-step-text">Open your Two-factor authentication application and add your AMS Pro account by scanning the OR code.</div>
                            <div className="authenticator-qr-wrap"> <div className="authenticator-qr">
                                {
                                    qrCode ? (
                                        <QRCode value={qrCode} size={200} />
                                    ) : (<></>)
                                }
                            </div></div>


                        </div>
                    </div>

                    <div className="authenticator-step-wrap-content">
                        <div className="authenticator-loop-step">
                            <div className="authenticator-loop-step-head"> <span className="badge badge-pill badge-authenticator mr-2">3</span> Enter the 6-digit code</div>
                            <div className="authenticator-loop-step-text">Input the code from the <strong>Authenticator App</strong></div>
                            <div className="authenticator-input">
                                {/* <InputText
                                    autoComplete="off"
                                    placeholder={'key'}
                                    className={`form-control ${errors["key"]}`}
                                    name="key"
                                    onChange={(e) => { setKey(e.target.value) }}
                                    value={key}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleActivate(e);
                                        }
                                    }}
                                /> */}
                                <InputOTP
                                    length={6}
                                    className={`${errors["key"]}`}
                                    onChange={(value) => { setKey(value) }}
                                    onEnter={handleActivate}
                                />
                            </div>


                        </div>

                    </div>
                    {
                        errors["totpmessage"] ? (
                            <div className={`alert alert-danger mb-0 mt-4`} role="alert" >
                                <span>{errors["totpmessage"]}</span>
                            </div>
                        ) : (<></>)
                    }


                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary float-right" style={{ padding: '4px 14px 5px 14px', width: 'auto' }} onClick={handleActivate}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Activate</button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showQRCode.show}
            backdrop="static"
            onHide={() => { setshowQRCode({ show: false }) }}
            backdropClassName={showQRCode.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-authenticator-install">
            <Modal.Header closeButton>
                <Modal.Title> {showQRCode.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="authenticator-step-wrap">
                    <div className="authenticator-step-wrap-text text-center">Scan the QR code to download the app </div>
                    <div className="authenticator-install-box-panel">
                        <div className="authenticator-install-head"></div>
                        {
                            showQRCode.type === 'google' ? (<>
                                <div className="authenticator-install-qr-box">
                                    <div className="authenticator-install-qr"><img src={images.googleauthenticatorappstore} /></div>
                                    <div className="authenticator-install-download">
                                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img src={images.appstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                    </div>
                                </div>
                                <div className="authenticator-install-qr-box">
                                    <div className="authenticator-install-qr"><img src={images.googleauthenticatorplaystore} /></div>
                                    <div className="authenticator-install-download">
                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en&amp;gl=US" target="_blank"><img src={images.playstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                    </div>
                                </div>
                            </>) : (<>
                                <div className="authenticator-install-qr-box">
                                    <div className="authenticator-install-qr"><img src={images.microsoftauthenticatorappstore} /></div>
                                    <div className="authenticator-install-download">
                                        <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank"><img src={images.appstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /></a>
                                    </div>
                                </div>
                                <div className="authenticator-install-qr-box">
                                    <div className="authenticator-install-qr"><img src={images.microsoftauthenticatorplaystore} /></div>
                                    <div className="authenticator-install-download">
                                        <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&amp;hl=en_IN&amp;gl=US" target="_blank"><img src={images.playstoreIcon} alt="" style={{ width: '100%', height: 'auto' }} /> </a>
                                    </div>
                                </div>
                            </>)
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>);
}

export default TwoFactorAuthModel;