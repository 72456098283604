/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
//import NumberFormat from 'react-number-format';
//import { collectionData, docData } from 'rxfire/firestore';
//import { combineLatest } from 'rxjs';
import "react-big-calendar/lib/css/react-big-calendar.css";

import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
import CustomToolbar from './toolbar'
import CustomEvent from './eventView'
import { PopUpModal, PopoverPanel, UpgradeModal } from '../../components'
import { firestoreDB } from '../../services/helper';
import images from '../../images';
import EventView from './viewEvent';
import AddEvent from '../eventsPro/add'
import FilterPanel from './filterPanel'
import { combineLatest } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { levelOptions } from '../eventsProTemplates/viewModel';
const localizer = momentLocalizer(moment);



const EventsProCalendar = (props) => {
    const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 73)
    //const [activitylogs, dispatch] = useReducer(AcitivityReducer, [])
    const [events, setEvents] = useState([]);
    const [view, setView] = useState('day');
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
        date: moment().format('YYYY-MM')
    })
    const [eventsPopOver, setEventsPopOver] = useState({ show: false })
    const [viewstate, setViewState] = useState('month')
    const [activeTab, setActivetab] = useState(CommonHelper.isJSON(localStorage.eventFilter) && _.isArray(JSON.parse(localStorage.eventFilter)) ? JSON.parse(localStorage.eventFilter) : ['all']);
    const [slotDate, setSlotdate] = useState(null)
    const [monthData, setMonthData] = useState(moment()._d.getMonth())
    const [yearData, setYearData] = useState(moment().year())
    const [eventsHeader, setEventsHeader] = useState('Add Event')
    const [showEventsModel, setShowEventsModel] = useState(false)

    const [showUpgradeModal, setShowUpgradeModal] = useState(false)


    // const [dateRange, setDateRange] = useState({
    //     startDate: moment().startOf('day'),
    //     endDate: moment().endOf('day')
    // })
    const [loader, setLoader] = useState(true);


    const addEventAccess = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.eventsProPermissions) &&
        props.dealersettings.rolePermissions.eventsProPermissions.addEvent) ? true : false);

    useEffect(() => {
        if (!loader)
            return

        // let allEventProDealers = CommonHelper.getAllEventsProDealers(props.dealersettings, true);
        // var collectionPromise = [];
        // for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {

        //     collectionPromise.push(collection(firestoreDB(props.dealersettings).firestore().collection('eventsPro')
        //         .where('isDeleted', '==', false)
        //         .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
        //         .where('startDate', '>=', dateRange.startDate)
        //         .where('startDate', '<=', dateRange.endDate)));
        // }
        const collectionPromise = [];
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index)
        _userLevel.forEach(level => {
            let _levelIDs = CommonHelper.getAllEventsProByLevel(props.dealersettings, level.value, true, true);
            for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {                
                let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
                    .where('isDeleted', '==', false)
                    .where(`${level.value === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                    .where('startDate', '>=', dateRange.startDate)
                    .where('startDate', '<=', dateRange.endDate);
                collectionPromise.push(collection(refEnquiryData));
            }
        });
        if (collectionPromise.length > 0) {
            window.unsubEventslist = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    const _data = [];

                    querySnapshot.forEach(rec => {
                        const eventData = rec.data();
                        //if (eventDuration.as('days') <= 1) {
                        if (moment.unix(eventData.startDate.seconds).isSame(moment.unix(eventData.endDate.seconds), 'day')) {
                            // If the event duration is less than or equal to 1 day, add the original event
                            _data.push({
                                ...eventData,
                                'documentID': rec.id,
                                'id': rec.id,
                                'eventID': `${rec.id}-${moment.unix(eventData.startDate.seconds).format('YYYY-MM-DD')}`,
                                'start': moment.unix(eventData.startDate.seconds).toDate(),
                                'end': moment.unix(eventData.endDate.seconds).toDate()
                            })
                        } else {
                            // If the event duration is greater than 1 day, split it into separate events for each day
                            const currentDay = moment.unix(eventData.startDate.seconds).startOf('day');
                            const lastDay = moment.unix(eventData.endDate.seconds).endOf('day');
                            for (var m = moment.unix(eventData.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(eventData.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                                _data.push({
                                    ...eventData,
                                    'documentID': rec.id,
                                    'id': rec.id,
                                    'eventID': `${rec.id}-${m.format('YYYY-MM-DD')}`,
                                    'start': moment(`${m.format('YYYY-MM-DD')} ${moment.unix(eventData.startDate.seconds).format('hh:mm A')}`).toDate(),
                                    'end': moment(`${m.format('YYYY-MM-DD')} ${moment.unix(eventData.endDate.seconds).format('hh:mm A')}`).toDate(),
                                })
                            }
                        }
                    });
                    setLoader(false);
                    setEvents(_.uniqBy(_data, 'eventID'));
                });
        }
        else {
            setLoader(false);
        }
    }, [dateRange]);


    useEffect(() => {
        function handleResize() {
            setCaleandarHeight(window.innerHeight - 73)
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.eventscalendarunsubscribe && window.eventscalendarunsubscribe();
        }
    }, []);


    const onSelectEvent = useCallback(async (event, eventData) => {
        setEventsPopOver({
            show: true,
            event: eventData,
            title: 'Event Info'
        })

    }, [])



    const handleChangeviewstate = (view) => {
        localStorage.setItem('calendarView', view)
        setViewState(view);
        setView(view);
    }

    const handleSelectSlot = ({ start, end }) => {
        if (addEventAccess) {
            if (viewstate === 'month') {
                start = moment(moment(start).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d;
                //end = moment(moment(end).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d;

            }
            setSlotdate(start, end);
            setEventsHeader('Add Event')
            setShowEventsModel(true)
        }
    }

    return (
        <div className="middle-wrapper">


            <div className="calendar-panel">
                {
                    loader
                        ?
                        <>
                            <div className="dashboard-widget-loader h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"><Translate text={'Loading...'} /></span>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                        </>
                }

                <Calendar
                    popup={false}
                    localizer={localizer}
                    events={events}
                    style={{ height: calendarHeight }}
                    views={['day', 'week', 'month']}
                    defaultView={viewstate}
                    components={{
                        //toolbar: CustomToolbar,
                        toolbar: propstb => (<CustomToolbar {...propstb}
                            dealersettings={props.dealersettings}
                            viewstate={viewstate}
                            //activetab={activeTab}
                            handleChangeviewstate={handleChangeviewstate}
                            handlechangemonth={(month) => setMonthData(month)}
                            handlechangeyear={(year) => setYearData(year)}
                            monthData={monthData}
                            setDateRange={(_dateRange) => {
                                if (dateRange.date !== _dateRange.date) {
                                    setDateRange(_dateRange)
                                    setLoader(true)
                                }
                            }}
                        />),
                        eventWrapper: propsevt => (<CustomEvent {...propsevt}
                            dealersettings={props.dealersettings}
                            onSelectEvent={onSelectEvent}
                            viewstate={viewstate} />),

                    }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    messages={{
                        showMore: function showMore(total) {
                            return `+${total} view more`;
                        }
                    }}
                    onShowMore={(events, date) => {
                        // if (events.length > 2) {
                        //     events = events.slice(0, 2);
                        //   }
                        setDateRange({
                            //startDate: moment(date).startOf('day'),
                            //endDate: moment(date).endOf('day'),
                            startDate: window.firebase.firestore.Timestamp.fromDate(moment(date).startOf('month').add(-7, 'days').toDate()),
                            endDate: window.firebase.firestore.Timestamp.fromDate(moment(date).endOf('month').add(7, 'days').toDate()),
                        })
                        setLoader(true)
                        setViewState('day')
                    }}

                />
            </div>


            <PopUpModal show={showUpgradeModal}>
                <UpgradeModal
                    show={showUpgradeModal}
                    handleClose={() => {
                        setShowUpgradeModal(false);
                    }}
                />
            </PopUpModal>
            <PopUpModal show={eventsPopOver.show}>
                <EventView
                    {...props}
                    {...eventsPopOver}
                    handleClose={() => {
                        setEventsPopOver({ show: false });
                    }}
                />
            </PopUpModal>
            <PopUpModal show={showEventsModel}>
                <AddEvent
                    handleClose={() => {
                        setShowEventsModel(false);
                    }}
                    show={showEventsModel}
                    title={eventsHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    slotDate={slotDate}
                    history={props.history}
                />
            </PopUpModal>
        </div>
    )
}

export default EventsProCalendar;