import React, { useState, useEffect, useReducer, useCallback, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
import { PopUpModal } from '../../../../components';
import { schedulerVM } from './viewModel';
import moment from 'moment';
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from './toolbar'
import CustomEvent from './eventView'
import ScheduleContact from './scheduleContact'
import { MainContext } from '../../../layout/provider';
import CommonHelper from '../../../../services/common';
const localizer = momentLocalizer(moment);

const ScheduleListReducer = (state, action) => {
	function updateEnquiry() {
		return state.map((item) => {
			if (item.documentID === action.data.documentID) {
				return action.data;
			}
			return item;
		});
	}
	switch (action.type) {
		case "SUCCESS": {
			return action.data
		}
		case "APPEND_LIST": {
			return [...state, ..._.differenceBy(action.data, state, 'documentID')]
		}
		case "ADD_LOG_LIST": {
			return [action.data, ...state, action.data]
		}
		case "REMOVE_LOG_LIST": {
			return state.filter(item => item.documentID !== action.data.documentID)
		}
		case "REMOVE_LOG": {
			return state.filter(item => item.documentID !== action.documentID)
		}
		case "UPDATE_LOG_LIST": {
			return updateEnquiry()
		}
		default:
			return state;
	}
};

const ScheduleList = props => {
	const [schedulerList, dispatch] = useReducer(ScheduleListReducer, [])
	const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 90)
	const [viewstate, setViewState] = useState('day')
	const [loader, setLoader] = useState(false);
	const [monthData, setMonthData] = useState(moment()._d.getMonth())
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf('week').add(-1, 'day').startOf('day'),
		endDate: moment().endOf('week').add(1, 'day').endOf('day')
	})
	const [checkDataLoad, setDataload] = useState(true)
	const [schedule, setSchedule] = useState({
		show: false,
		title: '',
		slotDate: null
	});
	const [searchText, setSearchText] = useState('')
	const [selectedDevice, setSelectedDevice] = useState(null)
	const { tvTemplates } = useContext(MainContext);
	const [allUsers, setAllUsers] = useState(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers)
	const [otherUsers, setOtherUsers] = useState([])
	const workingHours = props?.dealersettings?.client?.workingHours;
	const workingStartHour = workingHours && workingHours.split(';')[0] ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[0]}`).hours() : 6
	const workingEndHour = workingHours && workingHours.split(';')[1] ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`).hours() : 22

	useEffect(() => {
		function handleResize() {
			setCaleandarHeight(window.innerHeight - 90)
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unrefSchedulatData && window.unrefSchedulatData();
		}
	}, []);

	useEffect(() => {
		let refActivitiesData = window.firebase.firestore().collection('tvScheduler')
			.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(dateRange.startDate.toDate()))
			.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(dateRange.endDate.toDate()))
			.where('oemID', '==', props.dealersettings.oemID)
			.where('isDeleted', '==', false)

		window.unrefSchedulatData = refActivitiesData
			.onSnapshot(onCollectionUpdate);

		//}

	}, [dateRange])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		const _activities = [];
		if (!actionType) {
			querySnapshot.forEach(function (doc) {
				var logVM = convertLogVM(doc.data())
				_activities.push(logVM)
			});
		}
		let _otherUsers = [];
		_activities.forEach(rec => {
			if (rec.enqOwner && !allUsers.some(a => a.id === rec.enqOwner)) {
				_otherUsers.push(rec.enqOwner)
			}
		})
		if (JSON.stringify(_otherUsers) !== JSON.stringify(otherUsers))
			setOtherUsers(_.uniq(_otherUsers))
		dispatch({
			type: "SUCCESS",
			data: _activities.filter(a => !_.isEmpty(props.device) ? a.deviceID === props.device.documentID : true)
		});

		setDataload(false)
	}

	useEffect(() => {
		if (_.isEmpty(otherUsers))
			return;

		let userPromise = [];

		otherUsers.forEach(user => {
			if (!allUsers.some(a => a.id === user))
				userPromise.push(window.firebase.firestore().doc(`users/${user}`).get());
		})

		Promise.all(userPromise)
			.then(snapshot => {
				let users = [];
				snapshot.forEach(doc => {
					users.push({
						...doc.data(),
						id: doc.id
					})
				})
				setAllUsers([
					...allUsers,
					...users
				])
			})

	}, [otherUsers])

	const convertLogVM = (doc) => {

		doc.startDate = !_.isObject(doc.startDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate)._d) : doc.startDate;
		doc.endDate = !_.isObject(doc.endDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate)._d) : doc.endDate;

		const objlogData = Object.assign({}, doc);
		const logVM = Object.assign({}, schedulerVM);
		for (let [key, value] of Object.entries(objlogData)) {
			if (logVM.hasOwnProperty(key))
				logVM[key] = value;
		}
		logVM.start = moment.unix(doc.startDate.seconds).toDate()
		logVM.end = moment.unix(doc.endDate.seconds).toDate()
		logVM.contactName = doc.enquiry && doc.enquiry.contact ? doc?.enquiry?.contact?.tvDisplayName || CommonHelper.displayContactName([], doc.enquiry.contact) : ''
		logVM.vehiclemodel = doc.enquiry && doc.enquiry.requirement ? !_.isEmpty(doc.enquiry.requirement) ? (!_.isEmpty(doc.enquiry.requirement.stock) && !_.isEmpty(doc.enquiry.requirement.stock.make)) ? (!_.isEmpty(doc.enquiry.requirement.stock.make) ? (doc.enquiry.requirement.stock.make + ' ' + doc.enquiry.requirement.stock.model) : '') : (!_.isEmpty(doc.enquiry.requirement.make) ? (doc.enquiry.requirement.make + ' ' + doc.enquiry.requirement.model) : '') : '' : '';
		logVM.regNo = doc?.enquiry?.requirement?.stock?.regNo ? doc.enquiry.requirement.stock.regNo : doc?.enquiry?.requirement?.regNo ? doc.enquiry.requirement.regNo : doc?.enquiry?.regNo ? doc.enquiry.regNo : '';
		logVM.stockNo = doc?.enquiry?.requirement?.stock?.stockNo ? doc.enquiry.requirement.stock.stockNo : doc?.enquiry?.requirement?.stockNo ? doc.enquiry.requirement.stockNo : doc?.enquiry?.stockNo ? doc.enquiry.stockNo : '';
		logVM.enqOwner = doc?.enquiry?.owner ? doc.enquiry.owner : '';
		// logVM.enqOwner = doc?.enquiry?.owner ? common.getUserNamebyId(allUsers, doc.enquiry.owner) : '';
		// logVM.enqOwnerImg = doc?.enquiry?.owner ? common.getUserImagebyId(allUsers, doc.enquiry.owner) : '';
		let saleTypeColor = null;
		if (doc?.enquiry?.requirement?.saleType) {
			const settings = props?.dealersettings?.client?.settings?.salesType?.find(a => a.value === doc?.enquiry?.requirement?.saleType)
			const make = doc?.enquiry?.requirement?.stock?.make ? doc?.enquiry?.requirement?.stock?.make.toLowerCase().trim() : doc?.enquiry?.requirement?.make ? doc?.enquiry?.requirement?.make.toLowerCase().trim() : '';
			if (settings?.colors?.find(a => a.make.toLowerCase().trim() === make)) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === make).color;
			}
			else if (settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others')) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others').color;
			}
		}
		if (_.find(props.dealersettings.tvDevices, { documentID: logVM.deviceID })) {
			logVM.resourceId = logVM.deviceID;
			logVM.resourceTitle = _.find(props.dealersettings.tvDevices, { documentID: logVM.deviceID }).name;
			logVM.deviceName = _.find(props.dealersettings.tvDevices, { documentID: logVM.deviceID }).name;
			logVM.wrapper = saleTypeColor ? saleTypeColor : _.find(props.dealersettings.tvDevices, { documentID: logVM.deviceID }).wrapper;
		}
		if (_.find(tvTemplates, { documentID: logVM.templateID })) {
			logVM.template = _.find(tvTemplates, { documentID: logVM.templateID }).title;
		}
		if (doc.clientID !== props.dealersettings.client.id) {
			logVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, doc.clientID);
		}

		return logVM
	}

	const handleSelectSlot = ({ start, end, resourceId }) => {
		let disablestart = moment('2024-08-08 02:00 PM'); // Start at midnight
		let disableend = moment('2024-08-08 06:30 PM');     // End at the last minute of the day
		if (moment(start).isBetween(disablestart, disableend) || moment(end).isBetween(disablestart, disableend)) {

		} else {
			setSchedule({
				show: true,
				slotDate: {
					start,
					end
				},
				title: 'Schedule',
				device: resourceId ? { documentID: resourceId } : props.device
			})
		}

	}

	const handleChangeviewstate = (view) => {
		setViewState(view);
	}

	const onSelectEvent = useCallback(async (eventData) => {
		setSchedule({
			show: true,
			title: 'Schedule',
			scheduleEnq: eventData,
			device: props.device
		})
	}, [])

	const getResource = () => {
		return viewstate === 'day' ? {
			resources: props.dealersettings.tvDevices.filter(a => !Boolean(a.showScheduler)),
			resourceIdAccessor: "resourceId",
			resourceTitleAccessor: "resourceTitle"
		} : {}
	}
	const CustomTimeSlotWrapper = ({ children, value }) => {
		let disablestart = moment('2024-08-08 01:45 PM'); // Start at midnight
		let disableend = moment('2024-08-08 06:30 PM');     // End at the last minute of the day
		if (moment(value).isBetween(disablestart, disableend)) {
			return <div className={children.props.className} style={{ backgroundColor: '#ddd', borderTop: '#ddd' }}>{children}</div>;
		}

		return <>{children}</>;
	};
	return (
		<>
			<div className={`${props.popupModel === true ? '' : 'middle-wrapper'}`}>
				<div className="calendar-panel">
					{
						loader
							?
							<>
								<div className="dashboard-widget-loader h-100">
									<div className="d-flex h-100 justify-content-center align-items-center loader-primary">
										<div className="spinner-border" role="status">
											<span className="sr-only"><Translate text={'Loading...'} /></span>
										</div>
									</div>

								</div>
							</>
							:
							<>
							</>
					}

					<Calendar
						className='tv-scheduler'
						popup={false}
						localizer={localizer}
						events={schedulerList.filter(({ contactName, vehiclemodel, template, deviceID, deviceName }) =>
							(!_.isEmpty(searchText) ? (
								(!_.isEmpty(contactName) ? contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
								(!_.isEmpty(vehiclemodel) ? vehiclemodel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
								(!_.isEmpty(template) ? template.toLowerCase().includes(searchText.toLowerCase()) : false) ||
								(!_.isEmpty(deviceName) ? deviceName.toLowerCase().includes(searchText.toLowerCase()) : false)

							) : true)
							&& (selectedDevice ? deviceID === selectedDevice : true)
						)}
						style={{ height: calendarHeight }}
						defaultView="day"
						views={['day', 'week']}
						min={moment().set('hour', workingStartHour).set('minute', 0).toDate()}
						max={moment().set('hour', workingEndHour).set('minute', 0).toDate()}
						components={{
							timeSlotWrapper: CustomTimeSlotWrapper,
							//toolbar: CustomToolbar,
							toolbar: propstb => (<CustomToolbar {...propstb}
								dealersettings={props.dealersettings}
								popupModel={props.popupModel}
								history={props.history}
								viewstate={viewstate}
								handleChangeviewstate={handleChangeviewstate}
								handlechangemonth={(month) => setMonthData(month)}
								monthData={monthData}
								setDateRange={(dateRange) => {
									setDateRange(dateRange)
									setDataload(true)
								}}
								searchText={searchText}
								handleSearchKeywords={(val) => {
									setSearchText(val);
								}}
								device={props.device}
								allDevice={props.dealersettings.tvDevices}
								selectedDevice={selectedDevice}
								handleSchedule={() => {
									setSchedule({
										show: true,
										slotDate: null,
										title: 'Schedule Contact',
										device: props.device
									})
								}}
								handleReactSelectChange={(e) => {
									setSelectedDevice(e ? e.value : null)
								}}
							/>),
							eventWrapper: propsevt => (<CustomEvent {...propsevt}
								dealersettings={props.dealersettings}
								onSelectEvent={onSelectEvent}
								allUsers={allUsers}
								viewstate={viewstate} />)
						}}
						selectable
						onSelectSlot={handleSelectSlot}
						dayPropGetter={(date) => {
							return {
								className: 'special-day',
							}
						}}
						{...getResource()}
					/>


				</div>
			</div>
			<PopUpModal show={schedule.show}>
				<ScheduleContact
					{...props}
					{...schedule}
					allDevice={props.dealersettings.tvDevices}
					allUsers={allUsers}
					handleClose={() => {
						setSchedule({ show: false, title: '' });
					}}

				/>
			</PopUpModal>
		</>
	);
};

export default ScheduleList;
