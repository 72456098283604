import React, { forwardRef, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import ReactTimePicker from '../reactTimePicker';


const CustomDateTimePicker = forwardRef((props, ref) => {
    const [value, setValue] = useState(null);
    const [time, setTime] = useState(props.data[props.colDef.timeDB] ? moment(props.data[props.colDef.timeDB], 'hh:mm A').toDate() : null);
    const dateInputRef = React.createRef();
    const [show, setShow] = useState(true)


    useEffect(() => {
        if (props.value) {
            let _date = props.colDef.format || 'DD/MM/YYYY';
            let _time = props.data[props.colDef.timeDB] ? ` ${props?.context?.settings?.timeFormat || 'hh:mm A'}` : ''
            setValue(moment(props.value, `${_date}${_time}`))
        }
        if (dateInputRef.current) {
            dateInputRef.current.focus();
            //dateInputRef.current.show();
        }
    }, [])

    const handleChange = (date) => {
        if (date) {
            setValue(date.toDate());
        } else {
            setValue(null);
            setTime(null);
        }
        setShow(true)
    };

    const handleTimeChange = (val) => {
        let _date = moment().format('YYYY-MM-DD')
        let _time = moment().format('hh:mm A')
        if (value) {
            _date = moment(value).format('YYYY-MM-DD')
        }
        if (val) {
            _time = moment(val).format('hh:mm A')
        }
        setTime(val || null)
        setValue(moment(`${_date} ${_time}`).toDate())
        setShow(true)
    }

    const handleSubmit = () => {
        props.api.stopEditing();
        let _time = moment().format('HH:mm');
        if (props.colDef.timeDB) {
            props.data[props.colDef.timeDB] = time ? moment(time).format('hh:mm A') : null;
            if (time) _time = moment(time).format('HH:mm')

        }
        if (props.colDef.fieldDB !== props.colDef.field)
            props.data[props.colDef.fieldDB] = (value ? window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + _time).toDate()) : null);
        props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (value ? moment(value).format(`${props.colDef.format || 'DD/MM/YYYY'} ${time ? props?.context?.settings?.timeFormat || 'hh:mm A' : ''}`) : null))

    }

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            <DatePicker
                value={value ? moment(value) : null}
                onChange={handleChange}
                ref={dateInputRef}
                open={show}
                showToday={false}
                format={`${props.colDef.format || 'DD/MM/YYYY'} ${time ? props?.context?.settings?.timeFormat || 'hh:mm A' : ''}`}
                placeholder={props?.colDef?.placeholder || 'DD/MM/YYYY'}
                className={`form-control`}
                popupClassName={`ant-datetime-picker`}
                picker={props?.colDef?.picker || 'date'}
                renderExtraFooter={() => {
                    return <>
                        <div className="antd-custom-timepicker mt-1 mb-1">
                            <div className="antd-custom-timepicker-input text-center">
                                <div className="form-style mb-0" style={{ width: '60%' }}>
                                    <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                        <ReactTimePicker
                                            value={time}
                                            name={'time'}
                                            onChange={handleTimeChange}
                                            timeIntervals={30}
                                            placeholder='HH:MM'
                                            className={`form-control`}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="antd-custom-timepicker-footer">
                            <div className="antd-custom-timepicker-today float-left">
                                <a href="#" onClick={(e) => { e.preventDefault(); setValue(new Date()) }}>Today</a>
                            </div>
                            <div className="antd-custom-timepicker-button">
                                <button type="button" className="btn btn-primary float-right ml-2" onClick={handleSubmit}> Ok</button>
                            </div>
                        </div >
                    </>
                }}
            />
        </div >
    );
});

export default CustomDateTimePicker;