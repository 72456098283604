/** LIBRARIES */
import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
/** COMPONENTS */
import PasswordSecurity from './general-settings/passwordsecurity';
import PersonalInfo from './general-settings/personalinfo';
import ActiveDevices from './general-settings/activedevices';
import EmailSettings from './general-settings/emailsettings';
import CalendarSettings from './general-settings/calendarSettings';
import _ from 'lodash'
import Translate from '../../../constants/translate';
import WholesalerDealer from './general-settings/wholesalerdealer';
import RingcentralSettings from './general-settings/ringcentralSettings';
import CustomAlerts from './general-settings/customAlerts'

const GeneralSettings = (props) => {
	const liteVersion = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.liteVersion) ? true : false);

	const ringcentralEnabled = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
		props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0] &&
		props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].active === true &&
		props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0].enabled === true
	) ? true : false);

	const getQueryparam = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let key = params.get('key');
		let state = params.get('state');
		var code = params.get('code')
		//console.log(key, "++", state)
		if (state === 'outlookcalendar' || state === 'googlecalendar') {
			key = 'calendarsettings'
		}
		if (!key && !state) {
			key = 'personel'
		}
		if (code) key = 'emailsettings'
		return key
	}

	return (
		<>
			<Tab.Container defaultActiveKey={getQueryparam()} mountOnEnter={true}>
				<div className='settings-submenu'>
					<h4><Translate text={'General Settings'} /></h4>
					<Nav as='ul' bsPrefix='ul-nav'>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='personel' bsPrefix='li-a-nav'>
								<Translate text={'personalInfo'} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='security' bsPrefix='li-a-nav'>
								<Translate text={'Security/Password'} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='devices' bsPrefix='li-a-nav'>
								<Translate text={'Active Devices'} />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item as='li' bsPrefix='li-nav'>
							<Nav.Link eventKey='alerts' bsPrefix='li-a-nav'>
								<Translate text={'alerts'} />
							</Nav.Link>
						</Nav.Item>
						{
							!Boolean(props.dealersettings.wholesalerDashboard) ? (
								<><Nav.Item as='li' bsPrefix='li-nav'>
									<Nav.Link eventKey='emailsettings' bsPrefix='li-a-nav'>
										<Translate text={'emailSettings'} /> {
											liteVersion
												?
												<><span className="tag-pro-inline ml-2 mt-0"><Translate text={'PRO'} /></span></>
												:
												<></>
										}
									</Nav.Link>
								</Nav.Item>
									<Nav.Item as='li' bsPrefix='li-nav'>
										<Nav.Link eventKey='calendarsettings' bsPrefix='li-a-nav'>
											<Translate text={'Calendar Settings'} /> {
												liteVersion
													?
													<><span className="tag-pro-inline ml-2 mt-0"><Translate text={'PRO'} /></span></>
													:
													<></>
											}
										</Nav.Link>
									</Nav.Item>
									{
										ringcentralEnabled ? (
											<Nav.Item as='li' bsPrefix='li-nav'>
												<Nav.Link eventKey='ringcentralsettings' bsPrefix='li-a-nav'>
													<Translate text={'Ringcentral Settings'} />
												</Nav.Link>
											</Nav.Item>
										) : (<></>)
									}
								</>
							) : (<>
								<Nav.Item as='li' bsPrefix='li-nav'>
									<Nav.Link eventKey='dealers' bsPrefix='li-a-nav'>
										<Translate text={'dealers'} />
									</Nav.Link>
								</Nav.Item>
							</>)
						}
					</Nav>
				</div>
				<div className='settings-container'>
					<div className='settings-container-sub'>
						<Tab.Pane eventKey='personel'>
							<PersonalInfo {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='security'>
							<PasswordSecurity {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='devices'>
							<ActiveDevices />
						</Tab.Pane>
						<Tab.Pane eventKey='emailsettings'>
							<EmailSettings />
						</Tab.Pane>
						<Tab.Pane eventKey='calendarsettings'>
							<CalendarSettings {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='dealers'>
							<WholesalerDealer {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='ringcentralsettings'>
							<RingcentralSettings {...props} />
						</Tab.Pane>
						<Tab.Pane eventKey='alerts'>
							<CustomAlerts {...props} />
						</Tab.Pane>
					</div>
				</div>
			</Tab.Container>
		</>
	);
};

export default GeneralSettings;
