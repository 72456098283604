import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';

import { StageProvider, StageConsumer } from '../pipeline/provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
import QuickView from "../pipeline/quickview";
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert'
import FilterPanel from './filterPanel'
import CommonHelper from '../../services/common';

import images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import CallLogsContainer from './callLogsContainer';
import TransferOwner from '../common/transfer'
import LeadTransfer from '../pipeline/leadTransfer'
import { enquiryStatus, enqUnActionStatus } from '../../services/enum';

import MergeEnquiry from '../pipeline/merge'

import { objEnquiryListVM, allenquiryFields, objActivitylog, objNewEnquiryVM, objEnquiry } from '../pipeline/viewModel'
import { objContact } from '../contacts/viewModel'
import { objRequirement } from '../requirement/viewModel';
import { firestoreDB } from '../../services/helper';
import enquiryHelper from '../../services/enquiryHelper';

const EnquiryListReducer = (state, action) => {
    function updateEnquiry() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "REMOVE_ENQUIRY": {
            return state.filter(item => item.documentID !== action.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateEnquiry()
        }
        default:
            return state;
    }
};


const PipelineListView = (props) => {
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [enquiryFields, setEnquiryFields] = useState(props.dealersettings.enquiryFields)
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.inBoundSearchText ? localStorage.inBoundSearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [defaultPipeline, setDefaultPipeline] = useState('LeadsBucket')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])
    const [pipelineView, setPipelineView] = useState('INBOUND')

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [modalMoveShow, setModalMoveShow] = useState(false)
    const [pipelineCount, setPipelineCount] = useState(0)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const [financeStatuses, setFinanceStatuses] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const [inboundModal, setInboundModal] = useState({ show: false, docID: null })
    const [convertLeadLoader, setConvertLeadLoader] = useState(false);
    const [showCallLogs, setShowCallLogs] = useState({ show: false, })

    const [modalMergeShow, setModalMergeShow] = useState({ show: false, mergeEnqIDs: [] })
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
        _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const enableSharedStock = ((props.dealersettings?.group?.enableSharedStock) ? true : false);

    //#region LOAD_SETTINGS
    useEffect(() => {
        let headerFields = enquiryFields;
        let allHeaderFields = allenquiryFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));

    }, [enquiryFields])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec.value))
                allenquiryFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        enquiryFields.forEach(rec => {
            if (!allenquiryFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setEnquiryFields(_.differenceBy(enquiryFields, deleteids))
    }, [enquiryOptions])


    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });


            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                _origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                _enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _enquiryOptions = [];
            setttings.enquiryOptionsDF && setttings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                _financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                _campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _pipelines = [];
            setttings.pipelines && setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            const _lostReasons = [];
            const _lostSubReasons = [];
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        label: docsub.name
                    });
                });
            });

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            allenquiryFields.forEach((obj) => {
                if (obj.value === 'wonOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'won', 'Won') + ' Date';
                }
                else if (obj.value === 'deliveredOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
                }
                else if (obj.value === 'lostOn') {
                    obj.name = CommonHelper.getNameByValue(_enquiryAllStatus, 'lost', 'Lost') + ' Date';
                }
            });

            setAllStatus(_enquiryAllStatus);
            setTitles(_titles);
            setEnquiryTypes(_enquiryTypes);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
            setCampaigns(_campaigns);
            setOrigins(_origins);
            setFinanceStatuses(_financeStatuses)
            setLanguages(_languages);
            setNationalities(_nationalities)
            setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            setCountries(_countries);
            setStates(_states);
            setDataload(true);
        }
    }, [])

    useEffect(() => {
        if (isFilterApplied || props.pipeline.length === 0)
            return
        const { dealersettings } = props;
        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
        const dpl = 'LeadsBucket'
        var querySet = `clients/${dealersettings.client.id}/counts/${dealersettings.client.id}`
        let accessSalesType = [];
        const excludeOrigins = dealersettings?.rolePermissions?.excludeOrigins || [];
        // if (!viewOtherEnquiry) {
        //     querySet = `users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`
        // }
        if (dealersettings && localStorage.defaultModule === 'oem') {
            if (dealersettings.level === 'oem' && dealersettings.oemID)
                querySet = `clientSettings/${dealersettings.oemID}/counts/${dealersettings.oemID}`
            else if (dealersettings.level === 'group' && dealersettings.groupID)
                querySet = `clientSettings/${dealersettings.oemID}/groups/${dealersettings.groupID}/counts/${dealersettings.groupID}`
            else if (dealersettings.level === 'region' && dealersettings.regionID)
                querySet = `clientSettings/${dealersettings.oemID}/regions/${dealersettings.regionID}/counts/${dealersettings.regionID}`
        }

        if (!viewOtherEnquiry) {
            querySet = `users/${localStorage.uid}/userClients/${dealersettings.client.id}`
            if (dealersettings && localStorage.defaultModule === 'oem') {
                if (dealersettings.level === 'oem' && dealersettings.oemID)
                    querySet = `users/${localStorage.uid}/counts/oem`
                else if (dealersettings.level === 'group' && dealersettings.groupID)
                    querySet = `users/${localStorage.uid}/counts/group`
                else if (dealersettings.level === 'region' && dealersettings.regionID)
                    querySet = `users/${localStorage.uid}/counts/region`
            }
        }

        if (_.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
            accessSalesType = dealersettings.accessSalesType;
            querySet = `clients/${dealersettings.client.id}/counts/${dealersettings.client.id}`;
        }

        window.unSubPipelineLstCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {
                var stageCount = {};
                if (docSnapshot.exists && docSnapshot.data() && docSnapshot.data().inbound && docSnapshot.data().inbound[dpl] && docSnapshot.data().inbound[dpl].stages) {
                    let _selectedPipeline = props.pipeline.find(item => item.value === 'LeadsBucket');
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = {
                            ...stageCount,
                            [stage.value]: {
                                ...docSnapshot.data().inbound[dpl].stages[stage.value],
                                ['total']: sumObj(docSnapshot.data().inbound[dpl].stages[stage.value], accessSalesType, stage.value, excludeOrigins)
                            }
                        }
                    })
                }
                setPipelineCount(stageCount)

            })
    }, [props.pipeline, isFilterApplied])

    const sumObj = (obj, arrSaleType, stage, excludeOrigins) => {
        var sum = 0;
        // if (stage === 'InboundLead' && arrSaleType) {
        //     arrSaleType = [...arrSaleType, 'na'];
        // }
        for (var el in obj) {
            if (_.isNumber(obj[el]) && (_.isEmpty(arrSaleType) || arrSaleType.some(a => a === el))) {
                sum += parseFloat(obj[el]);
            }
        }
        if (obj?.origins && excludeOrigins?.length) {
            for (var el in obj.origins) {
                if (JSON.parse(obj.origins[el])?.length) {
                    JSON.parse(obj.origins[el]).forEach(rec => {
                        excludeOrigins.forEach(ex => {
                            if (_.isNumber(rec[ex])) {
                                sum -= parseFloat(rec[ex]);
                            }
                        })
                    })
                }
            }
        }
        return sum;
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'inbound')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubinboundlist && window.unsubinboundlist();
            window.unsubenquirylist && window.unsubenquirylist();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.inBoundFilter', isFilterApplied, localStorage.inBoundFilter)
        if (localStorage.inBoundFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.inBoundFilter])
    //#endregion

    //#region PAGE_LOAD

    useEffect(() => {

        if (isFilterApplied || isValidFilter() || sortConfig)
            return;

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
        let searchKeywords = searchText;

        let refInboundData = firestoreDB(props.dealersettings).firestore().collection('inboundLeads')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', 0)
            .where('isDeleted', '==', false)

        refInboundData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refInboundData, dealersettings, 'INBOUND', true);

        if (searchKeywords) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            if (formattedSearchText.trim())
                refInboundData = refInboundData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }

        if (_.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
            refInboundData = refInboundData.where('requirement.saleType', 'in', [...CommonHelper.arrLimit(dealersettings.accessSalesType, 8), ''])
        }
        else if (!_.isEmpty(excludeOrigins))
            refInboundData = refInboundData.where('origin', 'in', CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true))

        window.unsubinboundlist = refInboundData
            .onSnapshot(onInboundCollectionUpdate);

    }, [checkDataLoad])

    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (props.pipeline.length === 0) {
            return
        }
        if (!checkDataLoad && defaultPipeline === 'LeadsBucket') {
            return;
        }
        if (defaultPipeline !== 'LeadsBucket') {
            setEnquiryLoader(true)
        }

        if (isFilterApplied && pageNum === 0 && _.isEmpty(sortConfig))
            setEnquiryLoader(true)

        let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)

        const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (((isFilterApplied || localStorage.inBoundFilter) && isValidFilter()) || sortConfig) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _pipelineFilter = localStorage.inBoundFilter ? JSON.parse(localStorage.inBoundFilter) : {};
            let _filter = Object.assign({}, _pipelineFilter.value);

            _filter.pipeline = 'LeadsBucket';
            _filter.inbound = true;

            if (!viewOtherEnquiry)
                _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            /* MAKE & MODEL FILTER */
            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.settings) &&
                !_.isEmpty(dealersettings.client.settings.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
            }

            if (_.isEmpty(_filter.saleType) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                _filter.saleType = dealersettings.accessSalesType.join(',');
            }

            if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
                _filter.origin = CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
            else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
                _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, 'INBOUND', null, true);
            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()

            // Region/Subregion/Group/Client filter
            if ((!_.isEmpty(_filter?.region) || !_.isEmpty(_filter?.subregion) || !_.isEmpty(_filter?.group)) && _.isEmpty(_filter?.clientID)) {
                _filter.clientID = dealersettings?.clients ? dealersettings.clients.filter(client =>
                    (_filter?.region?.length > 0 ? _filter.region.includes(client.region) : true) &&
                    (_filter?.subregion?.length > 0 ? _filter.subregion.includes(client.subregion) : true) &&
                    (_filter?.group?.length > 0 ? _filter.group.includes(client.group) : true)
                ).map(r => { return r.id }).join(',') : ''
                console.log('_filter', dealersettings.level, _filter)
            }

            let _searchObject = {
                "type": "searchEnquiries",
                "filters": JSON.stringify(_filter),
                "sortOrder": !_.isEmpty(sortConfig) ? `${sortConfig.key} ${sortConfig.sort}` : "stageDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, 'INBOUND', null, true);

            //console.log('filter-searchEnquiries', _searchObject, clientID, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);
                if (response.data.success && ((localStorage.inBoundFilter && isValidFilter()) || sortConfig)) {
                    onCollectionEnquiry(response.data);
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader(false)
                    setDataload(false)
                    setPaging(false)
                }
            });

            window.unsubenquirylist && window.unsubenquirylist();
            window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];

            if (localStorage.inBoundFilter) {
                let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                setSearchText(searchKeywords);
                localStorage.inBoundSearchText = searchKeywords;
                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let refEnquiryData = firestoreDB(dealersettings).firestore().collection('enquiries')
                //.where('clientID', '==', props.dealersettings.client.id)
                .where('pipeline', '==', 'LeadsBucket')
                .where('isDeleted', '==', false)

            refEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refEnquiryData, dealersettings, 'INBOUND', true);

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (_.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                refEnquiryData = refEnquiryData.where('requirement.saleType', 'in', CommonHelper.arrLimit(dealersettings.accessSalesType, 9))
            }
            else if (!_.isEmpty(excludeOrigins))
                refEnquiryData = refEnquiryData.where('origin', 'in', CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true))

            if (!viewOtherEnquiry)
                refEnquiryData = refEnquiryData.where('owner', '==', localStorage.uid)
            // else if (!_.isEmpty(searchOwners))
            //     refEnquiryData = refEnquiryData.where('owner', 'in', searchOwners)

            refEnquiryData = refEnquiryData
                .orderBy('stageDate', 'desc')

            if (hasMore.length > 0 && defaultPipeline === 'LeadsBucket') {
                refEnquiryData = refEnquiryData
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                refEnquiryData = refEnquiryData
                    .limit(pageLimit)
            }

            window.unsubenquirylist = refEnquiryData
                .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad, props.pipeline, props.dealersettings])

    const onInboundCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = CommonHelper.convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                }, props);
                if (change.type === 'added') {
                    actionType = "ADD_ENQUIRY_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ENQUIRY_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_ENQUIRY_LIST"
                }
            }
        })
        const _enquiries = [];
        if (!actionType) {

            querySnapshot.forEach(function (doc) {
                var logVM = CommonHelper.convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id,
                    stageDate: doc.data().stageDate ? doc.data().stageDate : doc.data().addedDate
                }, props);
                _enquiries.push(logVM)
            });
        }

        dispatch({
            type: actionType ? actionType : "APPEND_LIST",
            data: actionType ? snapshotDoc : _enquiries,
        });
        setTableLoader(false)
    }

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = CommonHelper.convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                }, props);
                if (change.type === 'added') {
                    actionType = "ADD_ENQUIRY_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ENQUIRY_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_ENQUIRY_LIST"
                }
            }
        })
        const _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach(function (doc) {
                let logVM = doc.data();
                if ((props.dealersettings.level === 'oem' && !Boolean(logVM.isConvertedOEM)) ||
                    (props.dealersettings.level === 'region' && !Boolean(logVM.isConvertedRegion)) ||
                    (props.dealersettings.level === 'group' && !Boolean(logVM.isConvertedGroup))
                ) {
                    _enquiries.push(CommonHelper.convertEnquiryVM({
                        ...logVM,
                        documentID: doc.id,
                        stageDate: logVM.stageDate ? logVM.stageDate : logVM.addedDate,
                    }, props));
                }
                else if (localStorage.defaultModule !== 'oem') {
                    _enquiries.push(CommonHelper.convertEnquiryVM({
                        ...logVM,
                        documentID: doc.id,
                        stageDate: logVM.stageDate ? logVM.stageDate : logVM.addedDate,
                    }, props));
                }
            });
        }
        dispatch({
            type: actionType ? actionType : "APPEND_LIST",
            data: actionType ? snapshotDoc : _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline('LeadsBucket')
        setTableLoader(false)
    }

    const onCollectionEnquiry = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            //console.log('doc', doc)
            var logVM = CommonHelper.convertEnquiryVM(doc, props);
            logVM.stageDate = logVM.stageDate ? logVM.stageDate : logVM.addedDate;
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline('LeadsBucket')
        setTableLoader(false)
    }

    /*const convertEnquiryVM = (doc) => {

        doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
        doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
        doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;

        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
        doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
        doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
        doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;

        doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
            return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]

        if (!_.isEmpty(doc.systemType)) {
            doc.tags = [{ type: doc.systemType }];
        }
        const objenquiryData = Object.assign({}, doc);
        const listVM = Object.assign({}, objEnquiryListVM);
        for (let [key, value] of Object.entries(objenquiryData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.objDoc = Object.assign({}, objenquiryData);
        listVM.displayID = listVM.displayID ? listVM.displayID : '--';
        listVM.originValue = doc.origin;
        listVM.enquiryTypeValue = doc.enquiryType;
        listVM.leadSourceValue = doc.leadSource;
        listVM.campaignValue = doc.campaign;
        listVM.statusValue = doc.stage === 'InboundLead' ? 'pending' : doc.status;
        listVM.labelValue = doc.label;
        listVM.pipelineValue = doc.pipeline;
        listVM.stageValue = doc.stage;
        listVM.ownerValue = doc.owner;
        listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : CommonHelper.getNameByValue(enquiryAllStatus, doc.status);
        listVM.financeStatusName = doc.financeStatus;
        listVM.labelName = doc.label;

        let dealersettings = props.dealersettings;

        listVM.pipeline = CommonHelper.getNameByValue(props.dealersettings.allPipelines, doc.pipeline, '');
        if (_.find(props.dealersettings.allPipelines, { value: doc.pipeline }) && doc.stage !== 'InboundLead') {
            listVM.stage = CommonHelper.getNameByValue(_.find(props.dealersettings.allPipelines, { value: doc.pipeline }).stages, doc.stage, '');
        }
        listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
        listVM.strResponseTime = doc.responseTime > 0 ? CommonHelper.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
        listVM.strAllocatedTime = doc.allocatedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
        listVM.strContactedTime = doc.contactedTime > 0 ? CommonHelper.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : '';  listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered') ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
        listVM.deliveryDate = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.confirmedBy = doc.confirmedBy ? CommonHelper.getUserNamebyId(allUsers, doc.confirmedBy) : '';
        listVM.confirmedDate = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.verifiedBy = doc.verifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.verifiedBy) : '';
        listVM.verifiedDate = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwner = doc.convertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.convertedBy) : '';

        listVM.convertedOnOEM = doc.oemConvertedDate ? moment.unix(doc.oemConvertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOwnerOEM = doc.oemConvertedBy ? CommonHelper.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
        listVM.convertedOwnerRegion = doc.convertedByRegion ? CommonHelper.getUserNamebyId(allUsers, doc.convertedByRegion) : '';
        listVM.convertedOwnerGroup = doc.convertedByGroup ? CommonHelper.getUserNamebyId(allUsers, doc.convertedByGroup) : '';


        let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            listVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.campaign, '');
            listVM.origin = CommonHelper.getNameByValue(_setttings.origins, doc.origin, '');

            listVM.enquiryType = CommonHelper.getNameByValue(_setttings.enquiryTypes, doc.enquiryType, '');
            listVM.leadSource = CommonHelper.getNameByValue(_setttings.leadSource, doc.leadSource, '');
            listVM.lostReason = CommonHelper.getNameByValue(_setttings.lostReasons, doc.lostReason, '');
            listVM.labelName = CommonHelper.getNameByValue(_setttings.enquiryLabels, doc.label);
            listVM.label = CommonHelper.bindEnquiryLabel(_setttings.enquiryLabels, doc.label);
        }

        //listVM.lostReason = CommonHelper.getLabelByValue(lostReasons, doc.lostReason, '');
        listVM.lostSubReason = CommonHelper.getLabelByValue(lostSubReasons, doc.lostSubReason, '');
        //listVM.origin = CommonHelper.getLabelByValue(origins, doc.origin, '');
        //listVM.enquiryType = CommonHelper.getLabelByValue(enquiryTypes, doc.enquiryType, '');
        //listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.campaign, '');
        

        if (financeEnabled)
            listVM.financeStatus = doc.financeStatus ? (<div className={`badge badge-pill badge-${doc.financeStatus.toLowerCase()} ml-1`}>{doc.financeStatus.toUpperCase()}</div>) : '';
        else
            listVM.financeStatus = CommonHelper.getLabelByValue(financeStatuses, doc.financeStatus, '');

        let _objCurrentStatus = doc.status && enquiryAllStatus.filter(e => e.value === doc.status)[0];
        if (doc.stage === 'InboundLead')
            listVM.status = <span className={`status-pending`}>{'Pending'}</span>;
        else
            listVM.status = doc.status ? (
                _objCurrentStatus
                    ?
                    <>
                        <span
                            className={`status-${doc.status === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                        
                    </>
            ) : '';

        listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.owner = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy) : '';
        listVM.overdueActivity = 0;
        listVM.todayActivity = 0;
        listVM.upcomingActivity = 0;
        listVM.doneActivity = 0;
        listVM.totalActivity = 0;
        listVM.depositAmount = doc.depositAmount ? currencySymbol + doc.depositAmount : '';
        listVM.grossAmount = doc.grossAmount ? currencySymbol + doc.grossAmount : '';
        listVM.csvQuoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? currencySymbol + doc.quotation.amount : '';

        if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.clientID);
        }
        else
            listVM.dealerName = '';

        listVM.quoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? <NumberFormat decimalScale={2}
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={doc.quotation.amount}
        /> : '';

        listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
        props.pipeline.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                    listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
                }
            }
        })

        if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
            listVM.vehicleModel = (!_.isEmpty(doc.requirement.stock) && doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : (doc.requirement.make ? doc.requirement.make + ' ' + doc.requirement.model : '')
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;

            listVM.vehicleSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.requirement.saleType, '');
            listVM.vehicleType = listVM.vehicleModel ? <>
                <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
                <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
            </> : <></>;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone);
            listVM.displayPhone = CommonHelper.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
            listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
            listVM.email = doc.contact.email;
            listVM.licenseNo = doc.contact.licenseNo;
            listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            listVM.contactType = doc.contact.contactType;
            listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            listVM.gender = doc.contact.gender;
            listVM.maritalStatus = doc.contact.maritalStatus;
            listVM.contactMethod = doc.contact.contactMethod;
            listVM.licenseType = doc.contact.licenseType;
            listVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            listVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');
            // listVM.campaign = CommonHelper.getLabelByValue(campaigns, doc.contact.campaign, '');
            // listVM.origin = CommonHelper.getLabelByValue(origins, doc.contact.origin, '');
            let _setttings = doc.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                listVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                listVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                listVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                listVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                listVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');

                listVM.contactcampaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                listVM.contactorigin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');
            }

            // if (doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            //     listVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            // }
            // else
            //     listVM.dealerName = '';

            listVM.address = doc.contact.address;
            listVM.companyName = doc.contact.companyName;
            listVM.designation = doc.contact.designation;

            listVM.licenseState = doc.contact.licenseState;
            listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            listVM.secondaryPhone = CommonHelper.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone);
            listVM.secondaryEmail = doc.contact.secondaryEmail;
            listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            listVM.optinPhone = doc.contact.optinPhone === false ? 'Yes' : '';
            listVM.optinEmail = doc.contact.optinEmail === false ? 'Yes' : '';
            listVM.optinPost = doc.contact.optinPost === false ? 'Yes' : '';
        }
        else if (!_.isEmpty(doc.engageNewContact)) {
            let _contact = Object.assign({}, doc.engageNewContact);
            listVM.contactName = CommonHelper.displayFullContact([], _contact, '', true);
            listVM.phone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone);
            listVM.contactPhone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
            listVM.email = _contact.email ? _contact.email : '';
            listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
        }

        if (!_.isEmpty(doc.dynamicFields)) {

            enquiryOptions.forEach(rec => {
                if (rec.type === 'toggle') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<></>)
                }
                else {
                    listVM[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        if (!_.isEmpty(doc.td)) {
            listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
            listVM.testdriveSaletype = CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, doc.td.saleType, '');
        }

        if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
            listVM.domleadSource = (<div className="Vehicle-availability">
                {
                    doc.tags.map((rec, index) => {
                        return <div key={index}><span>{CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type)}</span></div>
                    })
                }
            </div>);
            listVM.strleadSource = doc.tags.map((rec, index) => {
                return CommonHelper.getNameByValue(props.dealersettings.integrations, rec.type)
            }).join(',');
        }

        return listVM
    }*/
    //#endregion

    //#region CHANGE_EVENTS
    const isValidFilter = () => {
        if (localStorage.inBoundFilter) {
            //let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
            //let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            //if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
            //    return false;

            return true;
        }
        else {
            return false;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ enquiryFields: fields }, { merge: true })
            .then(() => {
                setEnquiryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('enquiryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            if (!_.isEmpty(obj) && obj.stageValue === 'InboundLead') {
                handleShowInboundLead(id);
            } else { sidepanelOpen(id) }
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, obj) => {
        if (!_.isEmpty(obj) && obj.stageValue === 'InboundLead') {
            handleShowInboundLead(id);
        } else { sidepanelOpen(id) }

    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_ENQUIRY_LIST",
                data: enq
            });

            if (enq.stageValue === 'InboundLead') {

                firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${id}`).set({
                    isFav: Boolean(val)
                }, { merge: true })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
            else {
                firestoreDB(props.dealersettings).firestore().doc(`enquiries/${id}`).set({
                    isFav: Boolean(val)
                }, { merge: true })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }

        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = CommonHelper.convertEnquiryVM(objEnquiry, props);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_ENQUIRY_LIST",
                    data: enq
                });
            }
        }

    }

    const removeEnquiryList = (_selectedIds) => {

        for (let [key, value] of Object.entries(_selectedIds)) {
            dispatch({
                type: "REMOVE_ENQUIRY",
                documentID: key
            });
        }
    }

    const handleSearchText = (val) => {
        setSearchText(val);
        if (!_.isEmpty(_.trim(val))) {
            if (localStorage.inBoundFilter) {
                let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);
                _filter['keywords'] = _.trim(val);
                _pipelineFilter.value = _filter;
                handleApplyFilter(_pipelineFilter);
            }
            else {
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': val }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else {
            handleClearFilter();
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('inBoundFilter', JSON.stringify(_filter));
            //localStorage.inBoundSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            //setSearchText('');
            setSortConfig(null)
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.inBoundFilter)) {
            localStorage.removeItem('inBoundFilter');
            localStorage.inBoundSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
            setSortConfig(null)
        }
    }

    //#endregion

    //#region EXCEL_DOWNLOAD
    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }
        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.inBoundFilter ? JSON.parse(localStorage.inBoundFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);
        _filter.pipeline = 'LeadsBucket';
        _filter.inbound = true;
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (!viewOtherEnquiry)
            _filter.owner = props.dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        /* MAKE & MODEL FILTER */
        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.settings) &&
            !_.isEmpty(props.dealersettings.client.settings.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.settings.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings, 'INBOUND', null, true);
        if (_.isEmpty(_filter.saleType) && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        if (_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        else if (!_.isEmpty(_filter.origin) && !_.isEmpty(excludeOrigins))
            _filter.origin = excludeOrigins.includes(_filter.origin) ? null : _filter.origin

        // Region/Subregion/Group/Client filter
        if ((!_.isEmpty(_filter?.region) || !_.isEmpty(_filter?.subregion) || !_.isEmpty(_filter?.group)) && _.isEmpty(_filter?.clientID)) {
            _filter.clientID = props.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
                (_filter?.region?.length > 0 ? _filter.region.includes(client.region) : true) &&
                (_filter?.subregion?.length > 0 ? _filter.subregion.includes(client.subregion) : true) &&
                (_filter?.group?.length > 0 ? _filter.group.includes(client.group) : true)
            ).map(r => { return r.id }).join(',') : ''
        }

        let _searchObject = {
            "type": "searchEnquiries",
            "filters": JSON.stringify(_filter),
            "sortOrder": !_.isEmpty(sortConfig) ? `${sortConfig.key} ${sortConfig.sort}` : "stageDate desc",
            "pageNum": 0,
            "pageLimit": total > 4999 ? 9999 : (total ? total : 9999)
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, 'INBOUND', null, true);

        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = CommonHelper.convertEnquiryVM(doc, props);
                _enquiries.push(logVM)
            });

            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props, props?.dealersettings?.client?.settings?.enquiryOptionsStatic));
            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'enquiries',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
        }
    }
    //#endregion

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }

    //#endregion

    //#region SHOW INBOUND LEAD CONVERT MODAL
    const handleShowInboundLead = (id) => {
        //console.log(id);
        setInboundModal({ show: true, docID: id });
    }

    const handleCloseInboundLead = () => {
        setInboundModal({ show: false, docID: null })
        if (!_.isEmpty(selectedIds)) setSelectedIds({});
    }
    //#endregion

    //#region SHOW CALL LOGS MODAL
    const showCallLogsClose = useCallback(() => {
        setShowCallLogs({
            ...showpanel,
            show: false,
        })
    }, [])

    const showCallLogsOpen = useCallback((val) => {
        setShowCallLogs({
            ...showpanel,
            show: true
        })
    }, [])
    //#endregion

    //#region SELECT & TRANSFER
    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0]);
                if (!_.isEmpty(record))
                    _selectedIds[file.documentID] = record.objDoc;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowTransferEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to transfer.'), '', 'info');
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }


    const handleCloseTransferEnquiries = async (owner) => {
        if (owner) {
            let ObjData = {
                owner: owner,
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                bulkDt: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let selectedLeadIds = []
            for (let [key, value] of Object.entries(selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                if (_selectedEnq.stage !== 'InboundLead') {
                    selectedLeadIds[key] = selectedIds[key]
                }
            }

            let _selectedIds = Object.keys(Object.assign({}, selectedLeadIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedLeadIds[key];
                return result;
            }, {});

            //console.log(_selectedIds, _selectedIds)
            for (let [key, value] of Object.entries(_selectedIds)) {
                //let _selectedEnq = Object.assign({}, value);
                // await window.firebase.firestore().collection('enquiries')
                //     .doc(key)
                //     .update(ObjData).then((docRef) => {
                //         for (let [key, value] of Object.entries(ObjData)) {
                //             _selectedEnq[key] = value;
                //         }
                //         updateListEnquiry(_selectedEnq);
                //     }).catch((error) => {
                //         console.error("Error updating enquiries: ", error);
                //     });

                batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${key}`), ObjData, { merge: true });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', key)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, ObjData);
                        });
                    });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', key)
                    .where('isHidden', '==', true)
                    .where('isDeleted', '==', true)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, ObjData);
                        });
                    });

            }

            batch.commit()
                .then(() => {
                    for (let [key1, value] of Object.entries(_selectedIds)) {
                        let _selectedEnq = Object.assign({}, value);
                        for (let [key, value] of Object.entries(ObjData)) {
                            _selectedEnq[key] = value;
                        }
                        updateListEnquiry(_selectedEnq);
                    }
                    setSelectedIds({});
                    setModalTransferShow(false)
                    if (localStorage.inBoundFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                        handleApplyFilter(_pipelineFilter);
                    }
                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Leads transferred successfully`, {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }

    }

    const handleShowMoveEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to allocate.'), '', 'info');
            return;
        }
        else {
            let _leadsSize = Object.keys(_selectedIds).length;
            const firstKey = Object.keys(_selectedIds)[0];
            const firstValue = _selectedIds[firstKey];
            if (_leadsSize === 1 && firstValue.stage === 'InboundLead') {
                handleShowInboundLead(firstKey);
            } else { setModalMoveShow(true) }
        }
    }

    const handleCloseMoveEnquiries = async (fields) => {
        //    console.log('handleCloseMoveEnquiries',handleCloseMoveEnquiries)
        if (!_.isEmpty(fields)) {

            let ObjData = {
                modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                stageDate: window.firebase.firestore.Timestamp.now(),
                bulkDt: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
            }

            if (fields.clientID)
                ObjData.clientID = fields.clientID;

            if (fields.pipeline)
                ObjData.pipeline = fields.pipeline;

            if (fields.stage)
                ObjData.stage = fields.stage;

            if (fields.owner)
                ObjData.owner = fields.owner;

            // if (fields.status)
            //     ObjData.status = fields.status;

            ObjData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
            const batch = firestoreDB(props.dealersettings).firestore().batch();

            let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                result[key] = selectedIds[key];
                return result;
            }, {});

            for (let [key, value] of Object.entries(_selectedIds)) {
                let _selectedEnq = Object.assign({}, value);
                if (_selectedEnq.stage === 'InboundLead')
                    await updateInboundLead(batch, key, _selectedEnq, ObjData, fields.status);
                else
                    await updateEnquiryLead(batch, key, _selectedEnq, ObjData, fields.status);
            }

            batch.commit()
                .then(() => {
                    removeEnquiryList(_selectedIds);
                    setSelectedIds({});
                    setModalMoveShow(false)
                    if (localStorage.inBoundFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                        handleApplyFilter(_pipelineFilter);
                    }
                    let _leadsSize = Object.keys(_selectedIds).length;
                    toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Leads allocated successfully`, {
                        duration: 2000
                    })
                    if (ObjData.pipeline) CommonHelper.refreshStageCount(ObjData.pipeline, props.dealersettings);
                })
                .catch((error) => {
                    console.log(error);
                    setModalMoveShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalMoveShow(false);
        }
    }
    //#endregion

    //#region ALLOCATE ENQUIRY PIPELINE

    const updateEnquiryLead = async (batch, enqID, objEnq, ObjData, _enqStatus) => {
        const { dealersettings } = props;
        let _selectedEnq = Object.assign({}, objEnq);
        let _objEnq = Object.assign({}, ObjData);

        let newstagehistory = {
            pipeline: ObjData.pipeline,
            stage: ObjData.stage,
            date: window.firebase.firestore.Timestamp.now()
        };

        if (_selectedEnq.pipeline === 'LeadsBucket' && ObjData.pipeline !== 'LeadsBucket') {
            _objEnq.status = enquiryHelper.bindDefaultStatus(_enqStatus, _selectedEnq.status);
            _objEnq.isConverted = true;
            _objEnq.unactionedNotify = enqUnActionStatus.PENDING;
            _objEnq.convertedBy = props.dealersettings ? props.dealersettings.id : '';
            _objEnq.convertedDate = window.firebase.firestore.Timestamp.now();

            _objEnq.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    date: window.firebase.firestore.Timestamp.now(),
                    status: _objEnq.status,
                    user: localStorage.uid
                }
            )

            if (_.isEmpty(_selectedEnq.tags) && _selectedEnq.pipeline === 'LeadsBucket') {
                _objEnq.tags = [{ type: 'inbound', refID: enqID, subType: '' }]
            }

            let transferHistory = Object.assign([], _selectedEnq.transferHistory);
            let _newTransferHistory = {
                by: localStorage.uid,
                date: window.firebase.firestore.Timestamp.now(),
            }

            if (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem') {
                // _objEnq.isConvertedOEM = true;
                // _objEnq.convertedByOEM = localStorage.uid;
                // _objEnq.convertedDateOEM = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedOEMID = dealersettings.oemID ? dealersettings.oemID : null;
                _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'region') {
                // _objEnq.isConvertedRegion = true;
                // _objEnq.convertedByRegion = localStorage.uid;
                // _objEnq.convertedDateRegion = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedRegionID = dealersettings.regionID ? dealersettings.regionID : null;
                _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'group') {
                // _objEnq.isConvertedGroup = true;
                // _objEnq.convertedByGroup = localStorage.uid;
                // _objEnq.convertedDateGroup = window.firebase.firestore.Timestamp.now();
                // _objEnq.convertedGroupID = dealersettings.groupID ? dealersettings.groupID : null;
                _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
            }

            if (localStorage.defaultModule === 'oem' && ObjData.clientID) {
                _newTransferHistory.toClient = ObjData.clientID;
                transferHistory.push(_newTransferHistory);
                _objEnq.transferHistory = transferHistory;
            }
        }
        else
            _objEnq.ownerInbound = _objEnq.owner ? _objEnq.owner : null;

        let _contactChange = false;
        if (_selectedEnq.isContact && !_.isEmpty(_selectedEnq.contact?.documentID) && ObjData.clientID && _selectedEnq.clientID !== ObjData.clientID) {
            if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(ObjData.clientID))) {
                let _tags = Object.assign([], _selectedEnq.tags);
                _tags.push({
                    type: 'Dealer Transfer',
                    refID: (_selectedEnq.contact.documentID || null),
                    clientID: (_selectedEnq.contact.clientID || null),
                    origin: 'inbound_list_transfer'
                })
                _objEnq.engageNewContact = Object.assign({}, {
                    ..._selectedEnq.contact,
                    documentID: null,
                    displayID: null,
                    clientID: null,
                    projectId: null,
                    groupID: null,
                    regionID: null,
                    oemID: null,
                });
                _objEnq.contact = {};
                _objEnq.contactID = null;
                _objEnq.isContact = false;
                _objEnq.tags = _objEnq.tags ? _.union(_tags, _objEnq.tags) : _tags;
                _contactChange = true;
            }
        }


        let arrstagehistory = !_.isEmpty(_selectedEnq.stageHistory) ? _selectedEnq.stageHistory : []
        if (arrstagehistory.some(e => e.stage === ObjData.stage && e.pipeline === ObjData.pipeline)) {
            newstagehistory = _.filter(arrstagehistory, { stage: ObjData.stage, pipeline: ObjData.pipeline })[0]
            newstagehistory.date = window.firebase.firestore.Timestamp.now()
        }
        arrstagehistory = [...arrstagehistory.filter(e => e.stage !== ObjData.stage), newstagehistory]
        _objEnq.stageHistory = arrstagehistory;

        //Remove empty value from object
        Object.keys(_objEnq).forEach(key => _objEnq[key] === undefined ? delete _objEnq[key] : {});
        // for (var propName in _objEnq) {
        //     if (_objEnq[propName] === null || _objEnq[propName] === undefined || _objEnq[propName] === '' || _objEnq[propName] === [] || (_.isObject(_objEnq[propName]) && _.isEmpty(_objEnq[propName]))) {
        //         delete _objEnq[propName];
        //     }
        // }

        //console.log('_objEnq', _objEnq)

        batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${enqID}`), _objEnq, { merge: true });

        if ((ObjData.owner && _selectedEnq.owner !== ObjData.owner) || (ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
            let _objAct = {}
            if (ObjData.owner) _objAct.owner = ObjData.owner;
            if (ObjData.clientID) _objAct.clientID = ObjData.clientID;
            if (_contactChange) {
                _objAct.contactID = null;
                _objAct.contact = {};
            }
            if (!_.isEmpty(_objAct)) {
                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', enqID)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, _objAct);
                        });
                    });

                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', enqID)
                    .where('isHidden', '==', true)
                    .where('isDeleted', '==', true)
                    .get().then(response => {
                        response.docs.forEach((doc) => {
                            batch.update(doc.ref, _objAct);
                        });
                    });
            }
        }

        if ((ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
            await firestoreDB(props.dealersettings).firestore().collection('tradeins')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });

            await firestoreDB(props.dealersettings).firestore().collection('testdrives')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });

            await firestoreDB(props.dealersettings).firestore().collection('cafeOrders')
                .where('enquiryID', '==', enqID)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });
            await firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enqID}/walkins`)
                .get().then(response => {
                    response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
                });
        }
    }
    //#endregion

    //#region CONVERT LEAD & ALLOCATE ENQUIRY PIPELINE

    const updateInboundLead = async (batch, inoundID, objInbound, fields, _enqStatus) => {
        const { dealersettings } = props;
        let response;
        let notes = [];
        if (!_.isEmpty(objInbound.enquiryID) && objInbound.status === 0) {
            response = await getEnquiryLead(objInbound);

        }
        else {
            if (objInbound.systemType === 'autogate')
                response = await createCarSalesLead(objInbound, fields, notes);
            else if (objInbound.systemType === 'imotor')
                response = await createIMotorLead(objInbound, fields, notes);
            else
                response = await createGenericLead(objInbound, fields, notes);
        }

        if (response) {
            if (!_.isEmpty(response.contact)) {
                // if (_.isEmpty(response.contact['documentID']))
                //     response.contact['documentID'] = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc().id;
                response.contact['isDeleted'] = false;
            }
        }

        objInbound.tags = [CommonHelper.getNameByValue(props.dealersettings.integrations, objInbound.systemType), objInbound.subType];

        let state = Object.assign({}, response);

        //if (_.isEmpty(state.documentID) && _.isEmpty(state.addedBy)) {
        state.addedBy = dealersettings ? dealersettings.id : '';
        state.addedDate = window.firebase.firestore.Timestamp.now();
        state.addedFrom = 'web';
        //}
        state.modifiedBy = dealersettings ? dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.createdDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';
        state.isNewEnquiry = true;

        const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;

        state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, fields.clientID, inboundAllocation);

        if (!_.isEmpty(state.contact)) {
            let objContactData = Object.assign({}, state.contact);
            //Remove empty value from object
            for (var propName in objContactData) {
                if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || objContactData[propName] === [] || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                    delete objContactData[propName];
                }
            }

            //save license image
            if (objInbound.request && objInbound.request.LicenseImageURL && objInbound.request.LicenseImageURL.includes('http')) {
                objContactData.licenseFrontURL = objInbound.request.LicenseImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseImageBackURL && objInbound.request.LicenseImageBackURL.includes('http')) {
                objContactData.licenseBackURL = objInbound.request.LicenseImageBackURL;
            }
            if (objInbound.request && objInbound.request.ImageURL && objInbound.request.ImageURL.includes('http')) {
                objContactData.userImageURL = objInbound.request.ImageURL;
            }
            if (objInbound.request && objInbound.request.LicenseExpiry) {
                objContactData.licenseExpiry = moment(objInbound.request.LicenseExpiry).format('YYYY-MM-DD');
            }
            if (objInbound.request && objInbound.request.LicenseNo) {
                objContactData.licenseNo = objInbound.request.LicenseNo;
            }
            //end

            const _systemType = Object.assign({}, !_.isEmpty(dealersettings?.client?.integrations) ?
                dealersettings.client.integrations.filter(e => e.type === objInbound.systemType)[0] : {});
            if (!_.isEmpty(_systemType) && _systemType?.settings?.customerConsent)
                objContactData.terms = {
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    verbalConsent: false,
                    signature: '',
                    termsAndConditions: !_.isEmpty(dealersettings?.client?.digitalConsent) ? dealersettings.client.digitalConsent : (!_.isEmpty(dealersettings?.client?.customerConsent) ? dealersettings.client.customerConsent : ''),
                    consentType: 'Digital'
                };

            state.engageNewContact = objContactData;
            state.contact = null;
        }

        if (fields.clientID) state.clientID = fields.clientID;

        if (fields.pipeline) state.pipeline = fields.pipeline;

        if (fields.stage) state.stage = fields.stage;

        if (fields.owner) state.owner = fields.owner;

        if (objInbound.campaign) state.campaign = objInbound.campaign;
        if (objInbound.origin) state.origin = objInbound.origin;
        if (objInbound.enquiryType) state.enquiryType = objInbound.enquiryType;
        if (objInbound.leadSource) state.leadSource = objInbound.leadSource;
        if (objInbound.eventsProAttendeeID) state.eventsProAttendeeID = objInbound.eventsProAttendeeID;
        if (objInbound.purchaseIntention) state.purchaseIntention = objInbound.purchaseIntention;
        if (objInbound.depositAmount) state.depositAmount = objInbound.depositAmount;
        if (objInbound.depositReciptNumber) state.depositReciptNumber = objInbound.depositReciptNumber;

        if (fields.pipeline !== 'LeadsBucket')
            state.status = _enqStatus ? _enqStatus : 'open';
        else {
            state.status = null;
            state.ownerInbound = state.owner;
        }
        state.inboundLeadID = objInbound?.documentID || null;
        if (objInbound.leadStatus) state.status = objInbound.leadStatus;

        if (state.status)
            state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    date: window.firebase.firestore.Timestamp.now(),
                    status: state.status,
                    user: localStorage.uid
                }
            )

        state.stageDate = window.firebase.firestore.Timestamp.now();
        state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
        state.isDeleted = false;
        state.tags = [
            {
                type: (objInbound.systemType ? objInbound.systemType : ''),
                refID: (objInbound.refID ? objInbound.refID : ''),
                subType: (objInbound.subType ? objInbound.subType : '')
            }
        ]

        if (state.pipeline !== 'LeadsBucket') {
            state.isConverted = true;
            state.unactionedNotify = enqUnActionStatus.PENDING;
            state.convertedBy = dealersettings ? dealersettings.id : localStorage.uid;
            state.convertedDate = window.firebase.firestore.Timestamp.now();

            let transferHistory = Object.assign([], state.transferHistory);
            let _newTransferHistory = {
                by: localStorage.uid,
                date: window.firebase.firestore.Timestamp.now(),
            }

            if (localStorage.defaultModule === 'oem' && dealersettings.level === 'oem') {
                // state.isConvertedOEM = true;
                // state.convertedByOEM = localStorage.uid;
                // state.convertedDateOEM = window.firebase.firestore.Timestamp.now();
                // state.convertedOEMID = dealersettings.oemID ? dealersettings.oemID : null;
                _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'region') {
                // state.isConvertedRegion = true;
                // state.convertedByRegion = localStorage.uid;
                // state.convertedDateRegion = window.firebase.firestore.Timestamp.now();
                // state.convertedRegionID = dealersettings.regionID ? dealersettings.regionID : null;
                _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
            }
            else if (localStorage.defaultModule === 'oem' && dealersettings.level === 'group') {
                // state.isConvertedGroup = true;
                // state.convertedByGroup = localStorage.uid;
                // state.convertedDateGroup = window.firebase.firestore.Timestamp.now();
                // state.convertedGroupID = dealersettings.groupID ? dealersettings.groupID : null;
                _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
            }

            if (localStorage.defaultModule === 'oem' && fields.clientID) {
                _newTransferHistory.toClient = fields.clientID;
                transferHistory.push(_newTransferHistory);
                state.transferHistory = transferHistory;
            }

        }

        const objData = Object.assign({}, state);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (!['origin'].includes(propName)) delete objData[propName];
            }
        }
        //    console.log('handleSaveEnquiry', objData)

        //salestype access
        if (_.isEmpty(objData.requirement)) {
            const ownerRoleID = props.clientUsers ? props.clientUsers.find(rec => rec.id === objData.owner) : null;
            if (ownerRoleID) {
                const ownerRole = props.dealersettings.client.roles ? props.dealersettings.client.roles.find(rec => rec.documentID === ownerRoleID.roleID) : null;
                if (ownerRole && _.isArray(ownerRole.salesType) && ownerRole.salesType.length > 0) {
                    objData.requirement = {
                        saleType: ownerRole.salesType[0]
                    }
                }
            }
        }
        //end

        if (!objData.documentID)
            objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        //console.log('objData', objData)
        batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objData.documentID}`), objData, { merge: true });
        let documentID = objData.documentID;

        if (_.isEmpty(state.documentID) && objData.isVehicle && !_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
            saveReqInfo(batch, documentID, objData.requirement, objData.clientID)

        if (!_.isEmpty(documentID) && !_.isEmpty(objInbound.documentID))
            await updateInBound(batch, documentID, objInbound.documentID, objData);


        if (objData.isConverted) {
            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, 'Lead converted', 'enquiry', 'updated', batch);
        }

        //add test drive when lead received is from fusion events pro
        if (!_.isEmpty(objInbound?.request?.testDrives))
            enquiryHelper.createTestdrivesFromEventsPro(objData, objInbound.request.testDrives, dealersettings, batch);

        // UPDATE EVENT ATTENDEE - ALLOCATION STATUS
        enquiryHelper.updateEventsAttendee(objInbound.eventsProAttendeeID, documentID, dealersettings, batch);

        saveNotes(batch, documentID, objData, notes);

    }

    const createIMotorLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);
        const { dealersettings } = props;

        try {
            const _objRequirement = Object.assign({}, objRequirement)

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = false;
            }
            if (viewmodel?.contact?.creditScore) {
                _objEnquiry.creditScore = viewmodel.contact.creditScore
            }
            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) {

                if (viewmodel.request.meta_data.stock_number) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.meta_data.stock_number)
                        .where('isDeleted', '==', false)


                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.meta_data.make && viewmodel.request.meta_data.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === viewmodel.request.meta_data.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.meta_data.model ? viewmodel.request.meta_data.model : '';
                        _objRequirement.saleType = viewmodel.request.meta_data.veh_type && viewmodel.request.meta_data.veh_type.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.meta_data.price ? parseInt(viewmodel.request.meta_data.price) : null;
                        _objRequirement.year = viewmodel.request.meta_data.year ? viewmodel.request.meta_data.year : '';
                        _objRequirement.stock = null;
                        if (viewmodel.request.meta_data.stock_number)
                            _objRequirement.stockNo = viewmodel.request.meta_data.stock_number;
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === viewmodel.request.meta_data.model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                        }
                    }
                }
            }


            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && !_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.meta_data)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(viewmodel.request.meta_data);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }


            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {

            errorLog(error, 'createIMotorLead');
            return _objEnquiry;
        }

    }

    const createGenericLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM);
        const { dealersettings } = props;
        try {
            const _objRequirement = Object.assign({}, objRequirement);

            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.contact = viewmodel.contact;
                _objEnquiry.isContact = false;
            }
            if (viewmodel?.contact?.creditScore) {
                _objEnquiry.creditScore = viewmodel.contact.creditScore
            }
            let _requiremntData = viewmodel.requirement ? viewmodel.requirement : (['facebook', 'eventsPro', 'tss'].includes(viewmodel.systemType)) ? null : viewmodel.request;
            if (!_.isEmpty(_requiremntData)) {

                if (_requiremntData.stockNo) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', _requiremntData.stockNo)
                        .where('isDeleted', '==', false)


                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (_requiremntData.make && _requiremntData.model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === _requiremntData.make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = _requiremntData.model ? _requiremntData.model : '';
                        _objRequirement.type = _requiremntData.type ? _requiremntData.type : '';
                        _objRequirement.saleType = _requiremntData.saleType ? _requiremntData.saleType : '';
                        _objRequirement.price = _requiremntData.price ? parseInt(_requiremntData.price) : null;
                        _objRequirement.extColor = _requiremntData.extColor ? _requiremntData.extColor : null;
                        _objRequirement.year = _requiremntData.year ? _requiremntData.year : null;
                        _objRequirement.intColor = _requiremntData.intColor ? _requiremntData.intColor : null;
                        _objRequirement.stock = null;

                        _objRequirement.stockNo = _requiremntData.stockNo ? _requiremntData.stockNo : '';
                        _objRequirement.regNo = _requiremntData.regNo ? _requiremntData.regNo : '';
                        _objRequirement.vinNo = _requiremntData.vinNo ? _requiremntData.vinNo : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === _requiremntData.model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';
                        }
                    }
                }
            }


            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && !_.isEmpty(_requiremntData)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(_requiremntData);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            //add test drive notes when lead received is from fusion events
            if (viewmodel.request && _.isArray(viewmodel.request.TestDrives) && viewmodel.request.TestDrives.length > 0) {
                let _tdNotes = 'Test Drives : ' + '\n';
                viewmodel.request.TestDrives.forEach(rec => {
                    if (rec.StartTime) _tdNotes += 'Start Time : ' + moment(rec.StartTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.EndTime) _tdNotes += 'End Time : ' + moment(rec.EndTime).format('DD/MM/YYYY hh:mm A') + '\n';
                    if (rec.VehicleModel) _tdNotes += 'Vehicle Model : ' + `${rec.VehicleMake} ${rec.VehicleModel} ${rec.VehicleYear > 0 ? rec.VehicleYear : ''}` + '\n';
                    _tdNotes += '-------' + '\n';
                })

                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.AddedDate ? window.firebase.firestore.Timestamp.fromDate(moment(viewmodel.AddedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = _tdNotes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            //add test drive notes when lead received is from fusion events pro
            // if (viewmodel.request && _.isArray(viewmodel.request.testDrives) && viewmodel.request.testDrives.length > 0) {
            //     let _tdNotes = 'Test Drives : ' + '\n';
            //     viewmodel.request.testDrives.forEach(rec => {
            //         if (rec.startDate) _tdNotes += 'Start Date : ' + moment.unix(rec.startDate.seconds).format('DD/MM/YYYY hh:mm A') + '\n';
            //         if (rec.endDate) _tdNotes += 'End Date : ' + moment.unix(rec.endDate.seconds).format('DD/MM/YYYY hh:mm A') + '\n';
            //         if (rec.model) _tdNotes += 'Vehicle Model : ' + `${rec.make} ${rec.model} ${rec.year ? rec.year : ''}` + '\n';
            //         if (rec.regNo) _tdNotes += 'Reg# : ' + `${rec.regNo}` + '\n';
            //         if (rec.vinNo) _tdNotes += 'VIN# : ' + `${rec.vinNo}` + '\n';
            //         _tdNotes += '-------' + '\n';
            //     })

            //     let newlogVM = Object.assign({}, objActivitylog);
            //     newlogVM.type = 'log';
            //     newlogVM.subType = 'note';
            //     newlogVM.isDone = true;
            //     newlogVM.addedByName = 'System';
            //     newlogVM.addedBy = 'System';
            //     newlogVM.modifiedBy = 'System';
            //     newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
            //     newlogVM.startDate = window.firebase.firestore.Timestamp.now();
            //     newlogVM.notes = _tdNotes;
            //     newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
            //     //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
            //     notes.push(newlogVM)
            // }

            if (viewmodel.request && _.isArray(viewmodel.request.Notes) && viewmodel.request.Notes.length > 0) {
                viewmodel.request.Notes.forEach(rec => {
                    if (rec.Notes) {
                        let newlogVM = Object.assign({}, objActivitylog);
                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.addedByName = CommonHelper.getUserNamebyId(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers, rec.addedBy, 'System');
                        newlogVM.addedBy = rec.addedBy ? rec.addedBy : 'System';
                        newlogVM.modifiedBy = rec.modifiedBy ? rec.modifiedBy : 'System';
                        newlogVM.addedDate = rec.addedDate ? rec.addedDate : rec.ModifiedDate ? window.firebase.firestore.Timestamp.fromDate(moment(rec.ModifiedDate).toDate()) : window.firebase.firestore.Timestamp.now();
                        newlogVM.modifiedDate = newlogVM.addedDate;
                        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                        newlogVM.startDate = newlogVM.addedDate;
                        newlogVM.notes = rec.Notes;
                        newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                        //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                        notes.push(newlogVM)
                    }
                })
            }
            //TSS create call activity
            if (viewmodel?.activity) {
                let newlogVM = Object.assign({}, viewmodel.activity);
                newlogVM.callDuration = fields?.activity?.callDuration || 0;
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                notes.push(newlogVM)
            }

            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createGenericLead');
            return _objEnquiry;
        }

    }

    const createCarSalesLead = async (viewmodel, fields, notes) => {
        const _objEnquiry = Object.assign({}, objNewEnquiryVM)
        const { dealersettings } = props;
        try {

            const _objContact = Object.assign({}, objContact)
            const _objRequirement = Object.assign({}, objRequirement)
            if (viewmodel?.contact?.creditScore) {
                _objEnquiry.creditScore = viewmodel.contact.creditScore
            }
            if (!_.isEmpty(viewmodel.contact)) {
                _objEnquiry.isContact = false;
                _objContact.firstName = viewmodel.contact.firstName ? viewmodel.contact.firstName : '';
                _objContact.middleName = viewmodel.contact.middleName ? viewmodel.contact.middleName : '';
                _objContact.lastName = viewmodel.contact.lastName ? viewmodel.contact.lastName : '';
                _objContact.phoneCode = viewmodel.contact.phoneCode ? viewmodel.contact.phoneCode : '';
                _objContact.phone = viewmodel.contact.phone ? viewmodel.contact.phone : '';
                _objContact.email = viewmodel.contact.email ? viewmodel.contact.email : '';
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Prospect)) {
                _objEnquiry.isContact = false;
                _objContact.address = viewmodel.request.Prospect.Address ? viewmodel.request.Prospect.Address : '';
                _objContact.state = viewmodel.request.Prospect.State ? viewmodel.request.Prospect.State : '';
                _objContact.areaCode = viewmodel.request.Prospect.Postcode ? viewmodel.request.Prospect.Postcode : '';
                if (_objContact.state)
                    _objContact.country = CommonHelper.getNameByValue(states, _objContact.state, '');

                if (!_objContact.phone) {
                    _objContact.phone = viewmodel.request.Prospect.WorkPhone ? viewmodel.request.Prospect.WorkPhone : (viewmodel.request.Prospect.HomePhone ? viewmodel.request.Prospect.HomePhone : '')
                }
                _objEnquiry.contact = _objContact;
            }

            if (!_.isEmpty(viewmodel.request) && !_.isEmpty(viewmodel.request.Item)) {

                if (viewmodel.request.Item.StockNumber) {
                    let stock = {};
                    let checkStock = window.firebase.firestore().collection('stock')
                        .where('stockNo', '==', viewmodel.request.Item.StockNumber)
                        .where('isDeleted', '==', false)

                    if (enableSharedStock && props.dealersettings?.client?.group)
                        checkStock = await checkStock.where('groupID', '==', props.dealersettings.client.group).limit(1).get()
                    else
                        checkStock = await checkStock.where('clientID', '==', props.dealersettings.client.id).limit(1).get()

                    if (checkStock.size === 1) {
                        checkStock.forEach(async doc => {
                            stock = doc.data();
                            let updateDoc = {};
                            if (parseFloat(viewmodel?.request?.Item?.Price)) {
                                updateDoc = {
                                    ...updateDoc,
                                    price: parseFloat(viewmodel.request.Item.Price)
                                }
                            }
                            if (parseInt(viewmodel?.request?.Item?.Odometer)) {
                                updateDoc = {
                                    ...updateDoc,
                                    miles: parseInt(viewmodel.request.Item.Odometer)
                                }
                            }
                            if (updateDoc) {
                                await doc.ref.update(updateDoc)
                            }
                            stock = {
                                ...stock,
                                ...updateDoc
                            }
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.stock = stock;
                        _objRequirement.saleType = stock.saleType ? stock.saleType : '';
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;
                    }
                }

                if (viewmodel.request.Item.Make && viewmodel.request.Item.Model && !_objEnquiry.isVehicle) {
                    let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === viewmodel.request.Item.Make.toLowerCase())[0];
                    if (!_.isEmpty(_make)) {
                        _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objRequirement.make = _make.value ? _make.value : '';
                        _objRequirement.model = viewmodel.request.Item.Model ? viewmodel.request.Item.Model : '';
                        _objRequirement.saleType = !_.isEmpty(viewmodel.request.LeadType) && viewmodel.request.LeadType.toLowerCase() == "used" ? "Preowned" : "New";
                        _objRequirement.price = viewmodel.request.Item.Price ? parseInt(viewmodel.request.Item.Price) : null;
                        _objRequirement.stock = null;
                        if (viewmodel.request.Item.StockNumber)
                            _objRequirement.stockNo = viewmodel.request.Item.StockNumber;
                        _objEnquiry.isVehicle = true;
                        _objEnquiry.requirement = _objRequirement;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === viewmodel.request.Item.Model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {

                            _objRequirement.model = _model.value ? _model.value : '';
                            _objRequirement.type = _model.type ? _model.type : '';

                        }
                    }
                }

            }

            if (!_.isEmpty(viewmodel.notes)) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = viewmodel.notes;
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }

            if ((!_objEnquiry.isVehicle || (_objEnquiry.isVehicle && !_.isEmpty(_objEnquiry.requirement) && !_.isEmpty(_objEnquiry.requirement.stockNo))) && viewmodel.request && viewmodel.request.Item) {
                let newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';
                newlogVM.isDone = true;
                newlogVM.addedByName = 'System';
                newlogVM.addedBy = 'System';
                newlogVM.modifiedBy = 'System';
                newlogVM.addedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
                newlogVM.startDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
                newlogVM.notes = getRequirementnotes(viewmodel.request.Item);
                newlogVM.tags = viewmodel.systemType ? [viewmodel.systemType] : [];
                //newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, fields.clientID);
                notes.push(newlogVM)
            }


            _objEnquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();
            return _objEnquiry;
        } catch (error) {
            errorLog(error, 'createCarSalesLead');
            return _objEnquiry;
        }

    }

    const getEnquiryLead = async (viewmodel) => {

        let _objEnquiry = Object.assign({}, objNewEnquiryVM)
        try {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(viewmodel.enquiryID).get();
            if (refData.exists) {
                _objEnquiry = Object.assign({}, refData.data());

                const _enquiry = Object.assign({}, objEnquiry);
                for (let [key, value] of Object.entries(_objEnquiry)) {
                    _enquiry[key] = value;
                }
                _enquiry.documentID = refData.id;

                _enquiry.receivedDate = viewmodel.addedDate ? viewmodel.addedDate : window.firebase.firestore.Timestamp.now();


                return _enquiry;
            }

        } catch (error) {
            errorLog(error, 'getEnquiryLead');
            return _objEnquiry;
        }

    }

    const saveReqInfo = (batch, enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID, inboundAllocation);

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${enquiryID}/requirements/${requirementID}`), objData, { merge: true });

            let _makeModel = !_.isEmpty(objData.stock) ?
                (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

            let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

            CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated', batch);


        } catch (error) {
            errorLog(error, 'saveReqInfo');
        }

    };

    const updateInBound = async (batch, enquiryID, documentID, __enquiryData) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';

            let _objReq = {
                status: 1,
                enquiryID: enquiryID,
                modifiedBy: userID,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            if (localStorage.defaultModule === 'oem' && __enquiryData.pipeline !== 'LeadsBucket' && __enquiryData.clientID) {
                _objReq.transferHistory = Object.assign([], __enquiryData.transferHistory);;
            }

            batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${documentID}`), _objReq, { merge: true });

            await firestoreDB(props.dealersettings).firestore().collection(`inboundLeads/${documentID}/notesHistory`)
                .get().then(response => {
                    response.docs.forEach((newlogVM) => {

                        newlogVM.type = 'log';
                        newlogVM.subType = 'note';
                        newlogVM.isDone = true;
                        newlogVM.isDeleted = false;
                        newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
                        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                            ? __enquiryData.clientID
                            : null));
                        newlogVM.enquiryID = enquiryID;
                        newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                        newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
                        newlogVM.contactID = __enquiryData && __enquiryData.contact && __enquiryData.contact.documentID ? __enquiryData.contact.documentID : null;
                        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

                        batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
                    });
                });

        } catch (error) {
            errorLog(error, 'updateInBound');
        }
    }

    const saveNotes = (batch, enquiryID, __enquiryData, logNotes) => {
        try {


            logNotes && logNotes.forEach(newlogVM => {
                newlogVM.enquiryID = enquiryID;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(__enquiryData, 'enquiry');
                newlogVM['contact'] = CommonHelper.getMinifiedData(__enquiryData?.contact, 'contact');
                newlogVM.contactID = __enquiryData && __enquiryData.contact && __enquiryData.contact.documentID ? __enquiryData.contact.documentID : null;
                newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(__enquiryData)
                    ? __enquiryData.clientID
                    : null));
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            })

            // notesHistory && notesHistory.forEach(newlogVM => {
            //     newlogVM.type = 'log';
            //     newlogVM.subType = 'note';
            //     newlogVM.isDone = true;
            //     newlogVM.startDate = newlogVM.addedDate ? newlogVM.addedDate : window.firebase.firestore.Timestamp.now();
            //     newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
            //     newlogVM.enquiryID = enquiryID;
            //     newlogVM.enquiry = Object.assign({}, __enquiryData);
            //     newlogVM.contact = __enquiryData && __enquiryData.contact ? Object.assign({}, __enquiryData.contact) : null;
            //     newlogVM.contactID = contact ? contact.documentID : null;
            //     batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            // })


        } catch (error) {
            errorLog(error, 'saveNotes');
        }

    }

    const getRequirementnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            for (let [key, value] of Object.entries(_requirement)) {
                if (!_.isEmpty(_requirement[key]) && _.isString(value))
                    notes = notes.concat([key] + ': ' + value + '\n')
            }
        }

        return notes;
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    //#endregion

    //#region MERGE
    const handleShowMergeEnquiries = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds) || (!_.isEmpty(_selectedIds) && Object.keys(selectedIds).length !== 2)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select two same contact lead(s) to merge'), '', 'info');
        }
        else {
            const _mergeEnqs = [];
            const _mergeEnqIDs = [];
            for (let [key, value] of Object.entries(_selectedIds)) {
                _mergeEnqIDs.push(key);
                _mergeEnqs.push(value);
            }

            let _enqA = _mergeEnqs[0];
            let _enqB = _mergeEnqs[1];
            let _contactA = _enqA.contactID ? _enqA.contactID : (_enqA.contact && _enqA.contact.documentID ? _enqA.contact.documentID : null);
            let _contactB = _enqB.contactID ? _enqB.contactID : (_enqB.contact && _enqB.contact.documentID ? _enqB.contact.documentID : null);

            if (_enqA.stage === 'InboundLead' || _enqB.stage === 'InboundLead') {
                Swal.fire(CommonHelper.showLocale(props, 'Inbound lead(s) cannot be merge'), '', 'info');
                return;
            }


            if (_.isEmpty(_contactA) || _.isEmpty(_contactB)) {
                Swal.fire(CommonHelper.showLocale(props, 'Please add contact in lead(s) to merge'), '', 'info');
                return;
            }

            if (_contactA !== _contactB) {
                Swal.fire(CommonHelper.showLocale(props, 'Only lead(s) belonging to the same contact can be merged'), '', 'info');
                return;
            }

            if (_enqA.clientID !== _enqB.clientID) {
                Swal.fire(CommonHelper.showLocale(props, 'Only lead(s) belonging to the same dealer can be merged'), '', 'info');
                return;
            }

            if (_mergeEnqs.length === 2)
                setModalMergeShow({ show: true, mergeEnqIDs: _mergeEnqIDs })
        }
    }

    const handleCloseMergeEnquiries = (objUpdatedEnq, removeEnqID) => {

        if (!_.isEmpty(objUpdatedEnq) && removeEnqID) {

            updateListEnquiry(objUpdatedEnq);

            dispatch({
                type: "REMOVE_ENQUIRY_LIST",
                data: { documentID: removeEnqID }
            });

            setSelectedIds({});

            if (localStorage.inBoundFilter) {
                let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                handleApplyFilter(_pipelineFilter);
            }

            toast.notify(`Leads merged successfully`, {
                duration: 2000
            })

        }
        setModalMergeShow({ show: false, mergeEnqIDs: [] })
    }
    //#endregion

    //#region DELETE
    const handleDeleteEnquiries = async () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more lead(s) to delete'), '', 'info');
            return;
        }
        else {
            const result = await Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to delete the lead(s).'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            })

            if (result.value) {
                let ObjData = {
                    isDeleted: true,
                    modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web',
                }

                const batch = firestoreDB(props.dealersettings).firestore().batch();
                let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
                    result[key] = selectedIds[key];
                    return result;
                }, {});

                for (let [key, value] of Object.entries(_selectedIds)) {
                    if (value && value.stage === 'InboundLead')
                        batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${key}`), { ...ObjData, 'status': 2 }, { merge: true });
                    else
                        batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${key}`), ObjData, { merge: true });

                    let logNotes = 'Lead deleted';
                    if (!_.isEmpty(value) && value.displayID)
                        logNotes = 'Lead: ' + value.displayID + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: value && value.stage === 'InboundLead' ? 'inboundLeads' : 'enquiries',
                        subType: 'delete',
                        recordId: key,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                    CommonHelper.saveAuditLog(_objLogData, batch);
                }

                batch.commit()
                    .then(() => {
                        setSelectedIds({});

                        for (let [key, value] of Object.entries(_selectedIds)) {
                            dispatch({
                                type: "REMOVE_ENQUIRY_LIST",
                                data: { documentID: key }
                            });

                            // if (value.contactID) {
                            //     const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                            //     updateRepeatCustomer({ 'contactID': value.contactID })
                            // }
                        }

                        // if (localStorage.inBoundFilter) {
                        //     let _pipelineFilter = JSON.parse(localStorage.inBoundFilter);
                        //     handleApplyFilter(_pipelineFilter);
                        // }

                        let _leadsSize = Object.keys(_selectedIds).length;
                        toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Lead(s) deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch((error) => {
                        console.log(error);

                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        }
    }
    //#endregion
    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setPageNum(0);
        setTableLoader(true)
        setPaging(false);
        setHasMoreData([]);
        setDataload(true);
    }

    return (
        <LayoutConsumer>
            {({ pipeline, handlePipelineChange, dealersettings, clientUsers, groupUsers }) => dealersettings ? (


                <div className={`middle-wrapper ${pipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        pipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={pipeline}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                handlePipelineChange(val);
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.inBoundFilter))
                                    localStorage.removeItem('inBoundFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleTransfer={handleShowTransferEnquiries}
                            handleMove={handleShowMoveEnquiries}
                            handleMerge={handleShowMergeEnquiries}
                            handleDelete={handleDeleteEnquiries}
                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                handleSearchText(val);
                                // setSearchText(val);
                                // setFilterApplied(false);
                                // setDataload(true);
                                // setPaging(false);
                                // setHasMoreData([])
                                // setPageNum(0);
                                // if (!_.isEmpty(localStorage.inBoundFilter))
                                //     localStorage.removeItem('inBoundFilter');

                            }}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}
                            pipelineView={pipelineView}
                            showCallLogsOpen={showCallLogsOpen}
                            selectedIds={selectedIds}
                        />)
                    }
                    <div className={`${pipeline.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            pipeline.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary">
                                        <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={sortConfig?.sort ? _.orderBy(enquiries, sortConfig?.key?.includes('Date') ? `${sortConfig.key}${sortConfig.key === 'stageDate' ? 'Value' : ''}.seconds` : sortConfig.key, sortConfig.sort) : enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allenquiryFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(enquiryFields && enquiryFields.length > 0) ? [...enquiryFields.filter(item => item !== 'checkbox'), 'checkbox'] : allenquiryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                        displayOptions={Object.assign([], props?.dealersettings?.client?.settings?.enquiryOptionsStatic)}
                                        displayByValue={true}
                                    />
                                </div>
                                )
                        }


                    </div>

                    <PopUpModal show={inboundModal.show}>
                        <InBoundLeadConvert
                            show={inboundModal.show}
                            docID={inboundModal.docID}
                            stage={inboundModal.stage}
                            handleModalClose={handleCloseInboundLead}
                            pipeline={'LeadsBucket'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            loader={convertLeadLoader}
                        />
                    </PopUpModal>

                    <PopUpModal show={modalTransferShow}>
                        <TransferOwner
                            show={modalTransferShow}
                            handleClose={handleCloseTransferEnquiries}
                            title={'Transfer Leads'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </TransferOwner>
                    </PopUpModal>

                    <PopUpModal show={modalMoveShow}>
                        <LeadTransfer
                            show={modalMoveShow}
                            handleModalClose={handleCloseMoveEnquiries}
                            title={'Allocate Leads'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            isList={true}
                        >
                        </LeadTransfer>
                    </PopUpModal>

                    <PopUpModal show={modalMergeShow.show}>
                        <MergeEnquiry
                            show={modalMergeShow.show}
                            enqIDA={modalMergeShow.mergeEnqIDs[0]}
                            enqIDB={modalMergeShow.mergeEnqIDs[1]}
                            isNewContact={false}
                            handleClose={handleCloseMergeEnquiries}
                            title={'Merge Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </MergeEnquiry>
                    </PopUpModal>

                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>

                    <PopUpModal show={showCallLogs.show}>
                        <CallLogsContainer
                            show={showCallLogs.show}
                            handleClose={showCallLogsClose}
                            title={showCallLogs.title}
                            history={props.history}
                        />
                    </PopUpModal>

                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                        <StageConsumer>
                            {() => (

                                <SidePanel
                                    clsActive={showpanel.clsActive}
                                    clsName={showpanel.clsName}
                                    sidepanelClose={sidepanelClose}
                                    title=''
                                >
                                    <QuickView {...props}
                                        enquiryid={showpanel.enquiryid}
                                        isFilterApplied={isFilterApplied}
                                        updateListEnquiry={updateListEnquiry}
                                        sidepanelClose={sidepanelClose}
                                    />
                                </SidePanel>
                            )}
                        </StageConsumer>
                    </StageProvider>
                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default PipelineListView;