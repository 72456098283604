/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import { InputRadio, ImageViewer, PopUpModal } from '../../../components';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
/** VIEW-MODELS */
import { objContact } from '../viewModel'


//#region  ASSIGN VARIABLES

//#endregion


var excluded = [
    "documentID",
    "displayID",
    "groupID",
    "regionID",
    "oemID",
    "clientID",
    "projectId",
    "customerNumber",
    "addedBy",
    "modifiedBy",
    "addedDate",
    "modifiedDate",
    "modifiedFrom",
    "isDeleted",
    "owner",
    "keywords",
    "terms",
    "mergeID",
    "isDLScan",
    "isRepeat",
    "meInvite",
    "amsMe"
];

var excludedDynamic = [
    "documentID",
    "displayID",
    "groupID",
    "oemID",
    "regionID",
    "clientID",
    "projectId",
    "customerNumber",
    "addedBy",
    "modifiedBy",
    "addedDate",
    "modifiedDate",
    "modifiedFrom",
    "isDeleted",
    "owner",
    "keywords",
    "terms",
    "mergeID",
    "title",
    "gender",
    "licenseType",
    "maritalStatus",
    "contactMethod",
    "interests",
    "secondaryTitle",
    "campaign",
    "origin",
    "meInvite",
    "amsMe"
];

const order = [
    "userImageURL",
    "displayID",
    "tags",
    "businessContact",
    "title",
    "firstName",
    "middleName",
    "lastName",
    "preferredName",
    "gender",
    "dob",
    "phoneCode",
    "phone",
    "phones",
    "email",
    "emails",
    "contactType",
    "licenseNo",
    "licenseExpiry",
    "licenseState",
    "licenseType",
    "licenseFrontURL",
    "licenseBackURL",
    "address",
    "areaCode",
    "state",
    "country",
    "maritalStatus",
    "nationality",
    "language",
    "contactMethod",
    "interests",
    "dependents",
    "visitingCardURL",
    "driverName",
    "isVIP",
    "company",
    "designation",
    "secondaryTitle",
    "secondaryFirstName",
    "secondaryLastName",
    "secondaryPhoneCode",
    "secondaryPhone",
    "secondaryPhones",
    "secondaryEmail",
    "secondaryEmails",
    "secondaryLicenseFrontUrl",
    "secondaryLicenseBackUrl",
    "secondaryMiddleName",
    "secondaryGender",
    "secondaryDob",
    "campaign",
    "banLoanVehicle",
    "origin",
    "optinPhone",
    "optinEmail",
    "optinPost",
    "optinSMS",
    "marketingOptinEmail",
    "marketingOptinSMS",
];


let languages = [];
let countries = [];
let states = [];

const MergeContact = (props) => {
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [selected, setSelected] = useState({})
    const [viewableKeys, setviewableKeys] = useState([])
    const [isLoading, setLoading] = useState(false)
    //const [titles, setTitles] = useState([])

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            // const _titles = [];


            // setttings.titles && setttings.titles.forEach((doc) => {
            //     _titles.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            countries = [];
            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            states = [];
            dealersettings.states && dealersettings.states.forEach((doc) => {
                states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            //dummmy load
            //setTitles(_titles);

        }

    }, [props.dealersettings])

    useEffect(() => {
        const _viewableKeys = []
        const _selected = {}
        const { contactB, contactA } = props;

        let _xcluded = contactB && contactA && contactA.clientID !== contactB.clientID ? excludedDynamic : excluded;

        for (let [key] of Object.entries(objContact)) {
            let included = _xcluded.indexOf(key) >= 0 ? false : true;
            let unmatched = contactA[key] != contactB[key];
            let hasValue1 = contactA[key] && (!_.isEmpty(contactA[key]) || contactA[key] === true) ? true : false;
            let hasValue2 = contactB[key] && (!_.isEmpty(contactB[key]) || contactB[key] === true) ? true : false;
            //console.log('setviewableKeys', key, hasValue1, hasValue2, contactA[key], contactB[key])
            if (included && (hasValue1 || hasValue2)) {
                _viewableKeys.push(key);
            }
            _selected[key] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
        }

        _viewableKeys
            .sort(function (x, y) {
                return (order.indexOf(x) < order.indexOf(y) ? -1 : 1);
            })
        setSelected(_selected);
        setviewableKeys(_viewableKeys);
    }, [])

    const convertContactVM = (doc) => {
        const contact = Object.assign({}, doc);

        let dealersettings = props.dealersettings;
        let _setttings = contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            contact.title = CommonHelper.getNameByValue(_setttings.titles, contact.title, '--');
            contact.secondaryTitle = CommonHelper.getNameByValue(_setttings.titles, contact.secondaryTitle, '--');

            contact.nationality = CommonHelper.getNameByValue(dealersettings.nationalities, contact.nationality, '--');
            contact.campaign = CommonHelper.getNameByValue(_setttings.campaigns, contact.campaign, '--');
            contact.origin = CommonHelper.getNameByValue(_setttings.origins, contact.origin, '--');
            contact.gender = CommonHelper.getNameByValue(_setttings.genders, contact.gender, '--');

            contact.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, contact.maritalStatus, '--');
            contact.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, contact.contactMethod, '--');
            contact.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, contact.interests, '--', true);
            contact.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, contact.licenseType, '--');

            contact.secondaryGender = CommonHelper.getNameByValue(_setttings.genders, contact.secondaryGender, '--');
        }

        return contact
    }

    const handleCheckChange = e => {
        const { name, value } = e.target;
        //console.log('handleCheckChange', name, value)
        let state = Object.assign({}, selected);
        state[name] = parseInt(value);
        setSelected(state);
    };


    const showDataView = (key, value) => {

        if (value || value === false) {
            if (key === 'company') {
                return <>{!_.isEmpty(value) ? value.name : '--'}</>
            }
            else if (key === 'userImageURL') {
                return <>
                    <a data-fancy-box={`mergeContacts`}
                        href={value}
                        onClick={(e) => {
                            e.preventDefault();
                            setImgViewer({
                                show: true,
                                images: value
                            })
                        }}>
                        <img src={value} width={'75px'} className={'rounded-circle img-object-fit'} />
                    </a>
                </>
            }
            else if (key === 'licenseFrontURL' || key === 'licenseBackURL' || key === 'visitingCardURL' || key === 'secondaryLicenseFrontUrl' || key === 'secondaryLicenseBackUrl' || key === 'userSignature') {
                return <>
                    <a data-fancy-box={`mergeContacts`}
                        href={value}
                        onClick={(e) => {
                            e.preventDefault();
                            setImgViewer({
                                show: true,
                                images: value
                            })
                        }}>
                        <img src={value} width={'150px'} className="img-object-fit" />
                    </a>
                </>
            }
            else if (key === 'emails' || key === 'phones' || key === 'secondaryPhones' || key === 'secondaryEmails') {
                return <>
                    {
                        !_.isEmpty(value)
                            ?
                            <>
                                {
                                    value.map((data, idx) => (
                                        data.value
                                            ?
                                            <div key={idx}>
                                                {data.value}{' [' + data.name + ']'}<br />
                                            </div>
                                            :
                                            <>{'--'}</>
                                    ))
                                }
                            </>
                            :
                            <>{'--'}</>
                    }
                </>
            }
            // else if (key === 'title' || key === 'secondaryTitle') {
            //     return <>{CommonHelper.getLabelByValue(titles, value, '--')}</>
            // }
            else if (key === 'language') {
                return <>{CommonHelper.getLabelByValue(languages, value, '--')}</>
            }
            else if (key === 'country') {
                return <>{CommonHelper.getLabelByValue(countries, value, '--')}</>
            }
            else if (key === 'state' || key === 'licenseState') {
                return <>{CommonHelper.getLabelByValue(states, value, '--')}</>
            }
            else if (key === 'businessContact' || key === 'isVIP' || key === 'banLoanVehicle' || key === 'optinPhone'
                || key === 'optinSMS' || key === 'optinEmail' || key === 'optinPost' || key === 'gdprDeleted' || key === 'marketingOptinEmail' || key === 'marketingOptinSMS') {
                return <>{value ? 'Yes' : 'No'}</>
            }
            else if (key === 'dob' || key === 'licenseExpiry' || key === 'secondaryDob') {
                return <>{value ? moment(value).format('DD MMM YYYY') : '--'}</>
            }
            else if (key === 'tags') {
                return <>{
                    !_.isEmpty(value) && value.length > 0 ?
                        (<div className="flow-notes-sub">
                            {
                                value.map((rec, index) => {
                                    return <div key={index} className="badge badge-pill badge-input-white mr-1 text-uppercase">{rec.type}</div>
                                })
                            }
                        </div>)
                        : (<></>)
                }</>
            }
            else {
                return <>{value && !_.isObject(value) ? value : '--'}</>
            }
        }
        else {
            return <>{'--'}</>
        }
    }

    const handleMerge = (e) => {
        e.preventDefault();
        setLoading(true);
        let merged = Object.assign({}, objContact)
        const { contactB, contactA } = props;
        for (let [key, value] of Object.entries(selected)) {
            merged[key] = (value == 0) ? contactA[key] : contactB[key];
        }
        merged.owner = contactB.owner;

        if (props.isNewContact) {
            merged.documentID = contactB.documentID;
            merged.addedBy = contactB.addedBy;
            merged.addedDate = contactB.addedDate;
        }
        else {
            merged['mergeID'] = contactB.documentID;
        }

        if (!_.isEmpty(contactA.amsMe)) {
            merged.amsMe = contactA.amsMe;
            merged.meInvite = contactA.meInvite ? contactA.meInvite : null;
        } else if (!_.isEmpty(contactB.amsMe)) {
            merged.amsMe = contactB.amsMe;
            merged.meInvite = contactB.meInvite ? contactB.meInvite : null;
        }

        if (contactB?.capGuid && _.isEmpty(merged.capGuid)) merged.capGuid = contactB?.capGuid
        if (contactB?.capID && _.isEmpty(merged.capID)) merged.capID = contactB?.capID
        if (contactB?.Leads && _.isEmpty(merged.Leads)) merged.Leads = contactB?.Leads
        if (contactB?.Vehicles && _.isEmpty(merged.Vehicles)) merged.Vehicles = contactB?.Vehicles
        if (contactB?.capOwner && _.isEmpty(merged.capOwner)) merged.capOwner = contactB?.capOwner
        if (contactB?.capTags && _.isEmpty(merged.capTags)) merged.capTags = contactB?.capTags

        if (_.isEmpty(contactA.secondaryFirstName) && _.isEmpty(merged.secondaryFirstName) && !_.isEmpty(contactB.firstName)) {
            merged.secondaryFirstName = contactB.firstName;
            if (contactB.middleName) merged.secondaryMiddleName = contactB.middleName;
            if (contactB.lastName) merged.secondaryLastName = contactB.lastName;
            if (contactB.preferredName) merged.secondaryPreferredName = contactB.preferredName;
            if (contactB.phoneCode) merged.secondaryPhoneCode = contactB.phoneCode;
            if (contactB.phone) merged.secondaryPhone = contactB.phone;
            if (contactB.email) merged.secondaryEmail = contactB.email;
            if (contactB.title) merged.secondaryTitle = contactB.title;
            if (contactB.dob) merged.secondaryDob = contactB.dob;
            if (contactB.gender) merged.secondaryGender = contactB.gender;
            if (contactB.licenseFrontURL) merged.secondaryLicenseFrontUrl = contactB.licenseFrontURL;
            if (contactB.licenseBackURL) merged.secondaryLicenseBackUrl = contactB.licenseBackURL;
            if (!_.isEmpty(contactB.phones)) merged.secondaryPhones = contactB.phones;
            if (!_.isEmpty(contactB.emails)) merged.secondaryEmails = contactB.emails;
        }

        props.handleClose(merged);
    }

    let { show, title, contactA, contactB } = props;

    contactB = convertContactVM(contactB);
    contactA = convertContactVM(contactA);
    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-merge-contact">
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="merge-contact-layout form-style">
                                <div className="common-table">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="20%"><Translate text={'Property'} /></th>
                                                <th scope="col" className="head-light" width="30%"><Translate text={'Current Contact'} /> </th>
                                                {
                                                    contactB.hasOwnProperty('capID') ? (
                                                        <th scope="col" className="head-light" width="30%">C@P <Translate text={'contact'} /></th>
                                                    ) : (
                                                        <th scope="col" className="head-light" width="30%"><Translate text={'Existing Contact'} />

                                                        </th>
                                                    )
                                                }

                                                {/* <th scope="col" className="head-light" width="20%">Final</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={'displayID'}>
                                                <th scope="row" className="th-active-bg"><Translate text={'contactID'} /></th>
                                                <td>
                                                    {
                                                        (!_.isEmpty(contactA['displayID']))
                                                            ?
                                                            <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{contactA['displayID']}</div></div>
                                                            :
                                                            <></>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (!_.isEmpty(contactB['displayID']))
                                                            ?
                                                            <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{contactB['displayID']}</div></div>
                                                            :
                                                            <></>
                                                    }
                                                </td>

                                            </tr>
                                            {
                                                !_.isEmpty(viewableKeys) && viewableKeys.map((key, idx) => (
                                                    <tr key={idx}>
                                                        <th scope="row" className="th-active-bg"><Translate text={key} /></th>
                                                        <td>
                                                            <div className="merge-contact-list">
                                                                <div className="radio icheck-success">
                                                                    <InputRadio
                                                                        id={key + '_0'}
                                                                        type="radio"
                                                                        value={0}
                                                                        name={key}
                                                                        checked={selected[key] === 0 ? true : false}
                                                                        onChange={handleCheckChange}
                                                                        disabled={contactA[key] != contactB[key] ? false : true}
                                                                    />
                                                                    <label htmlFor={key + '_0'}></label>
                                                                </div>
                                                                {showDataView(key, contactA[key])}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="merge-contact-list">
                                                                <div className="radio icheck-success">
                                                                    <InputRadio
                                                                        id={key + '_1'}
                                                                        type="radio"
                                                                        value={1}
                                                                        name={key}
                                                                        checked={selected[key] === 1 ? true : false}
                                                                        onChange={handleCheckChange}
                                                                        disabled={contactA[key] != contactB[key] ? false : true}
                                                                    />
                                                                    <label htmlFor={key + '_1'}></label>
                                                                </div>
                                                                {showDataView(key, contactB[key])}
                                                            </div>
                                                        </td>
                                                        {/* <td >Mark Thomson</td> */}
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                {
                    props.isReadOnly
                        ?
                        <></>
                        :
                        <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                            <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose(); }}>
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleMerge(e)} >
                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'merge'} />
                            </button>
                        </Modal.Footer>
                }

            </Modal >


            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>



        </>
    );

}
export default MergeContact;