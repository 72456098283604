import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Modal } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
import _ from 'lodash'
import { allTradeinFields } from '../tradeIn/viewModel'
import CommonHelper from '../../services/common';
import { ContentOverlay, TableView, SidePanel, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import { StageProvider, StageConsumer } from '../pipeline/provider'
import QuickView from "../pipeline/quickview";
import _images from '../../images'
import AddTradeIn from '../tradeIn/add'
import Swal from 'sweetalert2'
import ReAuthenticate from '../common/reAuthenticate';

import Filters from '../tradeIn/filters'
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TradeInList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [hasMore, setHasMore] = useState([1])
    const [alltradeinFields, setAllTradeInFields] = useState(allTradeinFields)
    const [tradeins, setTradeins] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [tradeInFields, setTradeInFields] = useState(props.dealersettings.tradeInFields)

    const [searchText, setSearchText] = useState('')
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [filterpageNum, setfilterPageNum] = useState(0);
    const [activityFilter, setactivityFilter] = useState({})
    const [searchKeywords, setSearchKeywords] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [modelShow, setModelShow] = useState({
        show: false,
        tradeinID: '',
        title: '',
        clsActive: ''
    })
    const [csvData, setCSVData] = useState([])
    const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);
    const tradeinPlusEnabled = ((!_.isEmpty(_moduleSettings) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings.tradeinPlus) &&
        props.dealersettings.client.moduleSettings.tradeinPlus.enabled) ? true : false);
    const [csvHeader, setHeader] = useState([])
    const [pageNum, setPageNum] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const fetchAct = async (type = 'generic', _pageNum, localFilter, _sortConfig) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled && type === 'excel') {
            handleReAuthOpen();
            return;
        }

        let num = _pageNum
        let pageLimit = props.counts ? props.counts > 49 ? 50 : props.counts : 50
        const filters = props.filter
        // on export fetch success csvBtn.current && csvBtn.current.link.click();
        // console.log('tradeinsList')
        // console.log('filters', props.filters)
        let _filter = {
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline) && !props.fromOEM) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        } else if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.dateRange && props.dateRange.startDate && props.dateRange.endDate) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && filters.startDate.toDate && filters.endDate.toDate) {
            _filter.startDate = moment(filters.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (filters && filters.startDate && filters.endDate && !filters.startDate.toDate) {
            _filter.startDate = moment(filters.startDate).format('YYYY-MM-DD')
            _filter.endDate = moment(filters.endDate).format('YYYY-MM-DD')
        }
        if (filters && filters.dateType) {
            if (filters.dateType.includes('Value')) {
                _filter.dateType = filters.dateType.replace('Value', '')
            } else {
                _filter.dateType = filters.dateType
            }
        }
        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.enqSaletype = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.enqSaletype = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.enqMake = props.dashboardFilter.makes.join(',')
        }
        if (filters && filters.documentIDs) {
            _filter = {
                documentIDs: filters.documentIDs.join(',')
            }
        }
        else if (props.isFromDashboard) {
            _filter.fromDashboard = 'true'
        }
        if (!_.isEmpty(localFilter)) {
            _filter = { ..._filter, ...localFilter }
            // num = 0
            pageLimit = 50
        }
        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.enqmodel = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            "type": "searchTradeins",
            "filters": JSON.stringify(_filter),
            "sortOrder": !_.isEmpty(_sortConfig) ? `${_sortConfig.key} ${_sortConfig.sort}` : `tradein."addedDate" desc`,
            "pageNum": num,
            pageLimit
        }
        if (type === 'excel') {
            _searchObject.pageLimit = (!props.counts || props.counts > 999) ? 1000 : props.counts
            _searchObject.pageNum = 0
            toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
                {
                    position: 'top',
                    duration: null
                },
            )
        }
        _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, null, true);
        if (props.fromOEM === true && props?.dealersettings?.clients?.length > 0) {
            _searchObject.clientID = props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.join(',') :
                props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(r => { return r.id }).join(',');
        }
        if (type === 'generic' && _.isEmpty(_sortConfig)) {
            setLoader(true)
        }
        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            //  console.log('resp', resp)
            let _pageNum = num + 1;
            let tradeins_ = resp.data.data.map(a => CommonHelper.convertTradeinVM(a, { ...props }))
            if (!_.isEmpty(localFilter)) {
                setfilterPageNum(_pageNum)
            } else {
                setPageNum(_pageNum)
            }
            if (type === 'excel') {
                setTradeins(tradeins_)
                setCSVData(CommonHelper.generateExcelData(tradeins_, csvHeader, props))
                setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })
                toast.closeAll();

                let _objLogData = {
                    notes: 'Excel export from dashboard - tradein',
                    type: 'excelExport',
                    subType: 'tradeins',
                    params: JSON.stringify(_filter),
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);

            } else {
                if (type === 'paging') {
                    setTradeins(_.uniqBy([...tradeins, ...tradeins_], 'documentID'))
                } else {
                    setTradeins(tradeins_)
                }
            }
            if (type === 'generic') {
                setLoader(false)
            }
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
            if (type === 'generic') {
                setLoader(false)
            }
        }
        setTableLoader(false)
    }

    const fetchDirectExcel = () => {
        console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard)
                    if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                        fetchAct('excel', filterpageNum, activityFilter.value, sortConfig)
                    } else {
                        fetchAct('excel', pageNum, null, sortConfig)
                    }
                else
                    fetchDirectExcel();
                fetchAct('excel');
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        if (props.isFromDashboard) {
            fetchAct('generic', pageNum)
        }
    }, [])

    useEffect(() => {
        let headerFields = tradeInFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = alltradeinFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(alltradeinFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(alltradeinFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [tradeInFields])

    useEffect(() => {
        setAllTradeInFields(_.map(alltradeinFields, function (obj) {
            return obj.value === 'settings' ? {
                ...obj,
                width: 80,
            } : {
                ...obj
            }
        }))
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.filter) || _.isEmpty(csvHeader) || props.isFromDashboard) {
            return;
        }
        let _tradeins = []
        var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }
        if (!_.isEmpty(props.filter.tradeinIDs)) {
            _tradeins = props.dataList.filter(a =>
                (!_.isEmpty(props.filter.tradeinIDs) ? props.filter.tradeinIDs.some(e => e === a.documentID) : true)
                && (!_.isEmpty(searchText) ?
                    (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.modelDescription) ? a.modelDescription.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.regNo) ? a.regNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.chassisNo) ? a.chassisNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.enqOwner) ? a.enqOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.pipeline) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
                    : true)
            )
        }
        else {
            _tradeins = props.dataList.filter(a =>
                (!_.isEmpty(props.filter.tradeinIDs) ? props.filter.tradeinIDs.some(e => e === a.documentID) : true)
                && (!_.isEmpty(a.enquiry) ? _pipelines.some(e => e === a.pipelineValue) : true)
                && (props.filter.dateType ? !_.isEmpty(a[props.filter.dateType]) : true)
                && (props.filter.startDate ? a[props.filter.dateType].seconds >= props.filter.startDate.seconds : true)
                && (props.filter.endDate ? a[props.filter.dateType].seconds <= props.filter.endDate.seconds : true)
                && (!_.isEmpty(props.selectedUser) ? a.addedByValue === props.selectedUser.value : true)
                && (!_.isEmpty(searchText) ?
                    (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.modelDescription) ? a.modelDescription.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.regNo) ? a.regNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.chassisNo) ? a.chassisNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.enqOwner) ? a.enqOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.pipeline) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
                    : true)
            )
        }
        setCSVData(CommonHelper.generateExcelData(_tradeins, csvHeader, props))
        setTradeins(_tradeins)

    }, [props.dataList, searchText, csvHeader])

    const filterSearch = (_tradeins) => {
        return _tradeins.filter(a =>
        (!_.isEmpty(searchText) ?
            (!_.isEmpty(a.contactName) ? a.contactName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.modelDescription) ? a.modelDescription.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.vehicleModel) ? a.vehicleModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.regNo) ? a.regNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.chassisNo) ? a.chassisNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.contactEmail) ? a.contactEmail.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.phone) ? a.phone.includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.licenseNo) ? a.licenseNo.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.reqModel) ? a.reqModel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.enqOwner) ? a.enqOwner.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.pipeline) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false) ||
            (!_.isEmpty(a.stage) ? a.stage.toLowerCase().includes(searchText.toLowerCase()) : false)
            : true)
        )
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ tradeInFields: fields }, { merge: true })
            .then(() => {
                setTradeInFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('tradeInFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div title="View Trade-In" style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    handleEditTradeIn(data)
                }}>
                    <i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-tradein")}></i>
                </div>
                <div title="View Enquiry" style={{ cursor: 'pointer' }} className="mini-button mini-tradein ml-2" onClick={(e) => {
                    sidepanelOpen(data.enquiryID)
                }}>
                    <i className="ico icon-more"></i>
                </div>
            </div>
        )
    }

    const handleDetailclick = (e, id, data) => {
        handleEditTradeIn(data)
    }



    const handleClose = (id) => {
        setModelShow({
            show: false,
            tradeinID: '',
            title: '',
            clsActive: '',
            canModifyEnquiry: false,
            isDeivered: false,
            enquiryID: ''
        });
    }

    const handleEditTradeIn = (data) => {

        setModelShow({
            show: true,
            tradeinID: data.documentID,
            title: 'tradeIn',
            clsActive: 'overlay-modal active',
            canModifyEnquiry: data.canModifyEnquiry,
            isDeivered: data.isDeivered,
            enquiryID: data.enquiryID
        });
    }

    const handlePagination = () => {
        // console.log('pagination clicked')
        // console.log('run pagination')
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('paging', filterpageNum, activityFilter.value)
        } else {
            if (tradeins.length >= props.counts) {
                return
            }
            fetchAct('paging', pageNum)
        }
    }

    const handleApplyFilter = (_filter) => {
        //  console.log('handleApplyFilter', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            fetchAct('generic', 0, _filter.value)
            setactivityFilter(_filter)
            setFilterApplied(true);
            setPageNum(0);
            setfilterPageNum(0)
            if (_filter.value.hasOwnProperty('keywords')) {
                setSearchText(_filter.value.keywords);
            } else {
                setSearchText('');
            }
            // localStorage.enquirySearchText = '';
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setSortConfig(null)
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        //   console.log('handleClearFilter')
        if (!_.isEmpty(activityFilter)) {
            setactivityFilter({})
            fetchAct('generic', pageNum)

            // localStorage.removeItem('activityFilter');
            // localStorage.enquirySearchText = '';
            setFilterApplied(false);
            // setDataload(true);
            // setPaging(false);
            // setHasMoreData([])
            setPageNum(0);
            setfilterPageNum(0)
            setSearchText('');
            setSortConfig(null)
        }
    }

    const handlesearchText = (text) => {
        //  console.log('handlesearchText', text)
        if (text) {
            fetchAct('generic', 0, { keywords: text })
            setactivityFilter({
                name: 'Adv Filter',
                type: 'filter',
                value: { keywords: text }
            })
            setPageNum(0);
            setfilterPageNum(0)
        } else {
            setPageNum(0);
            setfilterPageNum(0)
            setactivityFilter({})
            fetchAct('generic', pageNum)
        }
    }

    const handleDynamicSort = (sortConfig) => {
        setSortConfig(sortConfig);
        setTableLoader(true);
        setPageNum(0);
        if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
            fetchAct('generic', 0, activityFilter.value, sortConfig)
        } else {
            fetchAct('generic', 0, null, sortConfig)
        }
    }
    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">
                                    {!props.isFromDashboard ? (
                                        <div className="filter-search search-icon">
                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                        </div>
                                    ) : (
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="isearch"
                                                    placeholder="quick search..."
                                                    value={searchText ? searchText : ''}
                                                    onChange={(e) => {
                                                        let _val = e.target.value
                                                        setSearchText(_val);
                                                        // localStorage.contactSearchText = _val;

                                                        if (_val && _val.length > 2) {
                                                            handlesearchText(_val)
                                                            // console.log('add searchFilter');
                                                        }

                                                        if (!_val) {
                                                            handlesearchText('')
                                                            // console.log('remove searchFilter')
                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            // console.log('add searchFilter')
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        searchText && searchText.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSearchText('');
                                                                    // console.log('remove searchFilter ')
                                                                    // localStorage.contactSearchText = '';
                                                                    handlesearchText('')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                    <button
                                                        className="btn btn-default"
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // console.log('add searchFilter')
                                                            // localStorage.contactSearchText = searchText;
                                                            handlesearchText(searchText)
                                                        }}>
                                                        <i className="ico icon-search-fill"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div className="float-right">
                                    {
                                        (exportData)
                                            ?
                                            <>
                                                <div className="filter-item-btn ">
                                                    {props.isFromDashboard ? (
                                                        <>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!_.isEmpty(activityFilter) && !_.isEmpty(activityFilter.value)) {
                                                                    fetchAct('excel', filterpageNum, activityFilter.value, sortConfig)
                                                                } else {
                                                                    fetchAct('excel', pageNum, null, sortConfig)
                                                                }
                                                            }}>
                                                                <i className="ico icon-excel-download"></i>
                                                            </a>
                                                            <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                                <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                            </ExcelFile>
                                                            {/* <CSVLink
                                                                ref={csvBtn}
                                                                uFEFF={false}
                                                                data={tradeins}
                                                                headers={csvHeader}
                                                                filename={"ams-pro-download.csv"}
                                                                style={{ display: 'none' }}
                                                            >
                                                                <i className="ico icon-excel-download"></i>
                                                            </CSVLink> */}
                                                        </>
                                                    ) : (
                                                        <ExcelFile ref={csvDirectBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`ams-pro-download`}>
                                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                                        </ExcelFile>
                                                        // <CSVLink uFEFF={false} data={tradeins} headers={csvHeader} filename={"ams-pro-download.csv"}>
                                                        //     <i className="ico icon-excel-download"></i>
                                                        // </CSVLink>
                                                    )}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>
                                {props.isFromDashboard && (
                                    <Filters
                                        isFromDashboard={true}
                                        searchKeywords={searchKeywords}
                                        tradeInFilter={activityFilter}
                                        customFilters={customFilters}
                                        dealersettings={props.dealersettings}
                                        handleApplyFilter={handleApplyFilter}
                                        handleClearFilter={handleClearFilter}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        showDateFilter={props.showMore}
                                        noSaveAccess={true}
                                        isFilterApplied={isFilterApplied}

                                    >
                                    </Filters>
                                )}
                            </div>
                            {props.isFromDashboard ? loader ? (

                                <>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center loader-primary"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                            ) : (
                                <div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={tradeins}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={alltradeinFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(tradeInFields && tradeInFields.length > 0) ? tradeInFields.filter(item => item !== 'checkbox') : alltradeinFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={_.orderBy(tradeins, ['addedDateValue'], ['desc'])}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={alltradeinFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(tradeInFields && tradeInFields.length > 0) ? tradeInFields.filter(item => item !== 'checkbox') : alltradeinFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        displayByValue={true}
                                    />
                                </div>
                            )}
                            {
                                showpanel.clsActive ? (
                                    <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers} history={props.history}>
                                        <StageConsumer>
                                            {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, listenScrollEvent, stageLoading, updateEnquiry, sectionloading }) => (
                                                <SidePanel
                                                    clsActive={showpanel.clsActive}
                                                    clsName={showpanel.clsName}
                                                    sidepanelClose={sidepanelClose}
                                                    title=''
                                                >
                                                    <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={true} sidepanelClose={sidepanelClose} isReadOnlyView={props.isReadOnlyView} />
                                                </SidePanel>
                                            )}
                                        </StageConsumer>
                                    </StageProvider>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelShow.show}>
                <AddTradeIn
                    docID={modelShow.tradeinID}
                    show={modelShow.show}
                    clsActive={modelShow.clsActive}
                    handleClose={handleClose}
                    title={modelShow.title}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={modelShow.enquiryID}
                    isDeivered={modelShow.canModifyEnquiry ? modelShow.isDeivered : true}
                    tradeinPlusEnabled={tradeinPlusEnabled}
                    isReadOnlyView={props.isReadOnlyView}
                >
                </AddTradeIn>
            </PopUpModal>
            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
        </>
    );
}

export default TradeInList;