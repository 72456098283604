import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes';
import StarRatings from 'react-star-ratings'
import { default as _images } from '../../../images';
import '../../../styles/customer-feedbackform.scss';
import { InputCheckBox, InputRadio, InputTextArea, ReactSelect, InputText, ReactMultiSelect } from '../../../components';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { removeHTMLTags } from '../../../services/helper';
import CommonHelper from '../../../services/common';

const DynamicForm = props => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const _preview = params.get('preview');

	const [formFields, setFormFields] = useState();
	const [clientData, setClient] = useState();
	const [view, setView] = useState('landing');
	const [loader, setLoader] = useState(true);
	const [enquiry, setEnquiry] = useState({});
	const [contact, setContact] = useState({});
	const [errorFields, setError] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [customerReview, setCustomerReview] = useState()
	const [formFeedback, setFormFeedback] = useState()
	const [formTemplate, setFormTemplate] = useState(null)
	const [firestoreDB, setFirestoreDB] = useState()

	useEffect(() => {
		window.firebase.firestore().doc(`formFeedback/${props.id}`).get()
			.then(doc => {
				if (doc.exists) {
					setFirestoreDB(window.firebase)
				}
				else {
					setFirestoreDB(window.firebase2)
				}
			})
	}, [])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		const getData = async () => {
			if (props.id) {

				const ref = firestoreDB
					.firestore()
					.collection(`formFeedback`)
					.doc(props.id);
				const snapshots = await ref.get();

				if (snapshots.exists) {

					const clientRef = window.firebase
						.firestore()
						.collection(`clients`)
						.doc(snapshots.data().clientID)

					const docclientRef = await clientRef.get()
					setClient(docclientRef.data());
					if (snapshots.data().response) {
						setFormFeedback({
							...snapshots.data(),
							documentID: snapshots.id
						})
						setFormFields(snapshots.data().formFields);
						setCustomerReview(snapshots.data().response);
						if (_preview) {
							setView('form')
						} else {
							setView('end')
						}
						if (snapshots.data().formID) {
							const formRef = window.firebase
								.firestore()
								.collection(`formTemplates`)
								.doc(snapshots.data().formID)
							const docformRef = await formRef.get()
							setFormTemplate({ ...docformRef.data() })
						}
					}
					else {
						const formRef = window.firebase
							.firestore()
							.collection(`formTemplates`)
							.doc(snapshots.data().formID)

						const docformRef = await formRef.get()
						setFormFields(docformRef.data().fields)
						setFormFeedback({
							...snapshots.data(),
							title: docformRef.data().title,
							documentID: snapshots.id
						})
						setFormTemplate({ ...docformRef.data() })
						if (_preview) {
							setView('form')
						}
					}
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		};

		getData();
	}, [firestoreDB]);

	useEffect(() => {
		if (_.isEmpty(formTemplate) || _.isEmpty(formTemplate.backgroundColor)) {
			return
		}
		const myDiv = window.document.querySelector(".customer-feedback-page-landing");
		myDiv.setAttribute("style", `background-color: ${formTemplate.backgroundColor};`);
	}, [formTemplate])

	const changeRating = (newRating, name) => {
		if (_preview) {
			return
		}
		console.log('newRating, name', newRating, name)
		setCustomerReview({
			...customerReview,
			[name]: newRating,
		})
	}

	const handleTypeChange = (e) => {
		if (_preview) {
			return
		}
		const { name, value } = e.target;
		// console.log('handleTypeChange', name, value)
		setCustomerReview({
			...customerReview,
			[name]: value
		})
	}

	const handleCheckChange = (e) => {
		if (_preview) {
			return
		}
		const { name, checked, id } = e.target;
		// console.log('handleCheckChange', name, checked, id)
		let arrayToSet = customerReview && customerReview[name] ? [...customerReview[name]] : []
		if (checked === true) {
			arrayToSet.push(id)
		} else {
			arrayToSet = arrayToSet.filter(a => a !== id)
		}
		// console.log('arrayToSet', arrayToSet)
		setCustomerReview({
			...customerReview,
			[name]: arrayToSet
		})
	}

	const handleOnChange = (e) => {
		if (_preview) {
			return
		}
		e.preventDefault()
		const { name, value } = e.target;
		let valueToSet = ''
		if (value)
			valueToSet = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
		else
			valueToSet = ''

		setCustomerReview({
			...customerReview,
			[name]: valueToSet
		})
	}

	const handleSubmit = async (e) => {
		if (_preview) {
			return
		}
		e.preventDefault();

		let triggerLogs = null;
		const triggerLogsDocs = await firestoreDB
			.firestore()
			.collection(`triggersLog`)
			.where('formFeedbackIDs', 'array-contains', formFeedback.documentID)
			.limit(1)
			.get();
		if (triggerLogsDocs.docs && triggerLogsDocs.docs.length > 0) {
			triggerLogs = {
				...triggerLogsDocs.docs[0].data(),
				documentID: triggerLogsDocs.docs[0].id
			};
		}

		let formIsValid = true;
		let errorClass = 'feedback-invalid-red';
		let errors = {};
		let _domID = null;
		const objData = {
			contactID: enquiry.contactID,
			enquiryID: enquiry.documentID,
			clientID: enquiry.clientID,
			userID: enquiry.owner,
			customerReview: customerReview,
			addedDate: window.firebase.firestore.Timestamp.now(),
		}

		console.log('objData', objData)
		formFields.filter(a => a.required === true).forEach(rec => {
			if (_.isEmpty(customerReview) || (!_.isObject(customerReview[rec.name]) && !_.isBoolean(customerReview[rec.name]) && !_.isNumber(customerReview[rec.name]) && !customerReview[rec.name])) {
				formIsValid = false;
				errors[rec.name] = errorClass;
				if (!_domID)
					_domID = rec.name;
			}
		})
		setError(errors);
		if (!formIsValid) {
			if (_domID && document.getElementById(_domID)) {
				document.getElementById(_domID).scrollIntoView({
					behavior: "smooth",
					block: "center"
				})
			}
			return;
		}
		var regexp = /^[0-9]+$/;
		var totalScore = 0;
		var userScore = 0;
		try {
			formFields.filter(a => ((a.type === 'select' && !Boolean(a.multiple)) || a.type === 'starRating' || a.type === 'radio-group')).forEach(rec => {
				if (rec.type === 'starRating') {
					totalScore += 5;
				}
				else if (rec.values.some(r => regexp.test(r.value.toString()))) {
					totalScore += _.max(rec.values.map(r => { return parseInt(r.value) }))
				}
				if (customerReview[rec.name] && regexp.test(customerReview[rec.name].toString())) {
					userScore += parseInt(customerReview[rec.name]);
				}
			})
		}
		catch (err) { }
		setSubmitLoader(true)

		// window.firebase.firestore().doc(`formFeedback/${formFeedback.documentID}`)
		// 	.set({
		// 		...formFeedback,
		// 		response: customerReview,
		// 		formFields: formFields,
		// 		addedDate: window.firebase.firestore.Timestamp.now()
		// 	}, { merge: true })
		// 	.then(snapshot => {
		// 		setLoader(false)
		// 		setSubmitLoader(false)
		// 		setView('end')
		// 	});
		const usersSaveRating = window.firebase
			.functions()
			.httpsCallable('generic-saveData');
		setTimeout(() => {
			setSubmitLoader(false)
			setView('end')
		}, 5000);
		const resp = await usersSaveRating({
			documentID: formFeedback.documentID,
			collection: 'formFeedback',
			data: {
				//...formFeedback,
				response: customerReview,
				formFields: formFields,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				primaryColor: !_.isEmpty(formTemplate?.primaryColor) ? formTemplate.primaryColor : null,
				formColor: !_.isEmpty(formTemplate?.formColor) ? formTemplate.formColor : null,
				fontColor: !_.isEmpty(formTemplate?.fontColor) ? formTemplate.fontColor : null,
				backgroundColor: !_.isEmpty(formTemplate?.backgroundColor) ? formTemplate.backgroundColor : null,
				headerColor: !_.isEmpty(formTemplate?.headerColor) ? formTemplate.headerColor : null,
				score: totalScore > 0 ? Math.round((100 / totalScore) * userScore) : null,
				projectId: clientData?.projectId ? clientData?.projectId : null
			},
		});
		if (!_.isEmpty(triggerLogs)) {
			const responseDate = window.firebase
				.functions()
				.httpsCallable('generic-saveData');
			await responseDate({
				documentID: triggerLogs.documentID,
				collection: 'triggersLog',
				data: {
					formFeedbackResponse: window.firebase.firestore.Timestamp.now(),
					modifiedDate: triggerLogs.modifiedDate,
					projectId: clientData?.projectId ? clientData?.projectId : null
				}

			});
		}


		setSubmitLoader(false)
		setView('end')

	}

	const handleReactSelectChange = (e, data) => {
		setCustomerReview({
			...customerReview,
			[data.name]: e.value
		});
	}


	const handleReactMultiSelectChange = (selectedOptions, name) => {
		const value = [];
		!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
			value.push(data.value)
		})

		setCustomerReview({
			...customerReview,
			[name]: value
		});
	}

	const styles = {
		formAndFontColor: {
			background:
				!_.isEmpty(formTemplate) && formTemplate.formColor
					? formTemplate.formColor
					: '#FFFFFF',
			color:
				!_.isEmpty(formTemplate) && formTemplate.fontColor
					? formTemplate.fontColor
					: '#333333',
		},
		button: {
			background:
				!_.isEmpty(formTemplate) &&
					!_.isEmpty(formTemplate.primaryColor)
					? formTemplate.primaryColor
					: '#4466f2'
		},
		footer: {
			color: !_.isEmpty(formTemplate?.fontColor) ? formTemplate?.fontColor : '#545e6b'
		},
		fontColor: {
			color:
				!_.isEmpty(formTemplate) && formTemplate.fontColor
					? formTemplate.fontColor
					: '#545E6B',
		},
	}

	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div role='status' className='spinner-border' style={{ color: '#4466F2' }}></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : (
		<main role='main' className="container-fluid pl-0 pr-0">
			<div className='feedbackform-header'>
				<div className='container'>
					<div className='feedbackform-logo-wrap'>
						<span className='feedbackform-logo-img mr-3'>
							{
								clientData.logoURL ? (
									<img
										style={{ maxHeight: '50px' }}
										src={clientData.logoURL}
										alt='logo'
									/>
								) : (
									<>{clientData.name}</>
								)
							}

						</span>
					</div>
				</div>
			</div>

			{view === 'form' ? (
				<>
					<div className='container'>
						{
							formFields && formFields.some(a => a.type === 'header') ?
								(
									<div className='feedbackform-title'>
										<h1
											style={{
												color: !_.isEmpty(formTemplate) && formTemplate.headerColor
													? formTemplate.headerColor
													: '#ffffff'
											}}
										>
											{removeHTMLTags(formFields.find(a => a.type === 'header').label)}
										</h1>
									</div>
								) : (<div className='feedbackform-title'>
									<h1> </h1>
								</div>)
						}
						{/* IMAGE IN FORM <div className="feedbackform-question-loop">
							<div className="feedbackform-banner-image"> <img src="images/feedbackbanner.jpg" alt="" /> </div>
							<div className="feedbackform-sub-text"> <strong>Lorem ipsum dolor sit amet, consectetur</strong> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in <strong>reprehenderit in voluptate</strong> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </div>
						</div> */}
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap' style={styles.formAndFontColor}>
								{
									formFields && formFields.filter(a => a.type !== 'header').length > 0 ? (
										formFields.filter(a => a.type !== 'header').map((field, index) => {
											return <div key={index} className='feedbackform-question-loop' id={field.name}>
												<div className={`feedbackform-question-container ${errorFields[field.name]}`}>
													{removeHTMLTags(field.label)}
													{
														field.required === true ? (
															<span className="feedback-required-red">* </span>
														) : (<></>)
													}
													{
														!_.isEmpty(field.description) ? (
															<OverlayTrigger
																placement='bottom'
																overlay={
																	<Tooltip>{field.description}</Tooltip>
																}
															>
																<i className="ico icon-info icon-grey"></i>
															</OverlayTrigger>
														) : (<></>)
													}
												</div>
												{
													field.type === 'radio-group' ? (
														<div className='feedbackform-answer-container'>
															<div className='feedbackform-input-wrap'>
																{
																	field.values.filter(a => !_.isEmpty(a.value)).map((option, index) => {
																		return <div key={index} className='radio icheck-success'>
																			<InputRadio
																				id={`${field.name}-${index}`}
																				type="radio"
																				value={option.value}
																				name={field.name}
																				checked={customerReview && customerReview[field.name] === option.value ? true : false}
																				onChange={handleTypeChange}
																			/>
																			<label style={styles.fontColor} htmlFor={`${field.name}-${index}`}> {option.label}</label>
																		</div>
																	})
																}
															</div>
														</div>
													) : field.type === 'checkbox-group' ? (
														<div className='feedbackform-answer-container'>
															<div className='feedbackform-input-wrap'>
																<ul className={`${field.toggle === true ? 'feedback-switch-ul' : ''}`}>
																	{
																		field.values.filter(a => !_.isEmpty(a.value)).map((option, index) => {
																			return <li key={index}><div className={`${field.toggle === true ? 'switch switch-sm' : 'checkbox icheck-success'}`}>
																				<InputCheckBox
																					className="switch"
																					id={`${option.value}`}
																					name={field.name}
																					checked={customerReview && customerReview[field.name] && customerReview[field.name].includes(option.value)}
																					onChange={(e) => { handleCheckChange(e) }}
																				/>
																				<label style={styles.fontColor} htmlFor={`${option.value}`}>  {option.label}</label>
																			</div></li>
																		})
																	}
																</ul>
															</div>
														</div>
													) : field.type === 'select' ? (
														<div className='form-row'>
															<div className='form-group col-md-12'>
																{
																	field.multiple === true ? (
																		<ReactMultiSelect
																			options={field.values.filter(a => !_.isEmpty(a.value))}
																			name={field.name}
																			placeholder={'select'}
																			onChange={(option) => handleReactMultiSelectChange(option, field.name)}
																			value={customerReview ? customerReview[field.name] : ''}
																			classNamePrefix={`basic-select`}
																			isMulti={true}
																			isSearchable={true}
																		>
																		</ReactMultiSelect>
																	) : (
																		<ReactSelect
																			options={field.values.filter(a => !_.isEmpty(a.value))}
																			name={field.name}
																			placeholder={'select'}
																			onChange={(e, data) => {
																				handleReactSelectChange(e, data)
																			}}
																			value={customerReview ? customerReview[field.name] : ''}
																			classNamePrefix={`cursor-pointer basic-select`}
																		>
																		</ReactSelect>
																	)
																}

															</div>
														</div>
													) : field.type === 'text' ? (
														<div className='form-row'>
															<div className='form-group col-md-12'>
																<InputText
																	autoComplete="off"
																	className={`form-control ${errorFields[field.name]} feedbackform-no-bg-textarea`}
																	name={field.name}
																	onChange={handleOnChange}
																	value={customerReview ? customerReview[field.name] : ''}
																/>
															</div>
														</div>
													) : field.type === 'starRating' ? (
														<div className='form-row'>
															<div className='form-group col-md-12'>
																<StarRatings
																	isAggregateRating={true}
																	changeRating={changeRating}
																	numberOfStars={5}
																	starRatedColor={'#ffc107'}
																	starHoverColor={'#ffc107'}
																	starDimension="20px"
																	starSpacing="2px"
																	rating={customerReview ? customerReview[field.name] : 0}
																	name={field.name}
																/>
															</div>
														</div>
													) : field.type === 'textarea' ? (
														<div className='form-row'>
															<div className='form-group col-md-12'>
																<InputTextArea
																	className={`form-control notes feedbackform-no-bg-textarea`}
																	name={field.name}
																	onChange={handleOnChange}
																	value={customerReview ? customerReview[field.name] : ''}
																	maxLength="280"
																	rows="3">
																</InputTextArea>
															</div>
														</div>
													) : (<></>)
												}

											</div>
										})
									) : (<></>)
								}
								{_preview ? (<>

									<div className="feedbackform-question-loop feedbackform-submitwrap">
										<div className="clear"></div><footer>
											<div className="footer-cpy" style={{ background: 'inherit' }}>
												<div className="footer-bottom">

													<div className="cpy-wrap" style={styles.footer}> Powered by <a style={styles.footer} href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
													<div className="clear"></div>
												</div>
											</div>
										</footer>
									</div>
								</>) : (<div className="feedbackform-question-loop feedbackform-submitwrap">
									<div className="clear"></div>
									<div className='accordion-bottom approve-btn'>
										{' '}
										<a
											href='#'
											className='btn btn-common button-black formfeedback-submit float-right mt-3'
											onClick={handleSubmit}
											style={styles.button}
										>
											{submitLoader && (<span
												className='spinner-border spinner-button mr-1 mt-0'
												role='status'
												aria-hidden='true'
											></span>)}
											Submit
										</a>
										<div className='clear'> </div>
									</div>
									<footer>
										<div className="footer-cpy" style={{ background: 'inherit' }}>
											<div className="footer-bottom">

												<div className="cpy-wrap" style={styles.footer}> Powered by <a style={styles.footer} href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
												<div className="clear"></div>
											</div>
										</div>
									</footer>
								</div>)}
							</div>
						</div>
					</div>
				</>
			) : view === 'landing' ? (
				<>
					<div className='container'>
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap inner-position-fix'>
								<div className='feedbackform-question-loop mt-4' style={{ background: 'white' }}>
									<div className='feedbackform-landing-wrap'>
										<div className='feedbackform-landing-wrap-head'>
											<div className='feedbackform-landing-image'>
												<img src={_images.surveyImgBlue} alt='' height='200' />
											</div>
											<h2>Got a minute?</h2>
											<p className='feedbackform-landing-owner'>
												Hello {contact.firstName || contact.lastName ? CommonHelper.displayContactName([], contact) : 'customer'}!
											</p>
											<p>
												We'd appreciate if you take just a few minutes of your
												time to share your thoughts, so we can improve our
												services. Thank you for taking our quick survey!
											</p>
										</div>

										<div className='clear'></div>
										<div className='accordion-bottom approve-btn text-center'>
											<a href='#' className='btn btn-common button-black formfeedback-submit mt-3' style={styles.button} onClick={e => { e.preventDefault(); setView('form'); }}>
												Start Now
											</a>
											<div className='clear'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='container'>
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap inner-position-fix'>
								<div className='feedbackform-question-loop mt-4' style={{ background: 'white' }}>
									<div className='feedbackform-landing-wrap'>
										<div className='feedbackform-landing-wrap-head'>
											<div className='feedbackform-landing-image'>
												<img src={_images.surveyTick} alt='' height='150' />
											</div>
											<h2>We appreciate your feedback!</h2>

											<p>
												Thank you for giving us feedback on your experience with
												our service.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
			}
		</main >
	);
};

export default DynamicForm;
