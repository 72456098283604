import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect, AntDateRangePicker, PopUpModal } from "../../../components";
import { pipelineCountVMNEW } from './viewModel'
// import _images from '../../images'
import CommonHelper from '../../../services/common';
import WidgetSettings from './widgetSettingsModal'
import Translate from '../../../constants/translate';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState(pipelineCountVMNEW)
    const [pipelineOptions, setPipelineOption] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [ownerDashboard, setOwnerDashboard] = useState(false);
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate()))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day').toDate()))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [dates, setDates] = useState([]);
    const [loader, setLoader] = useState(false);

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings.client.timezone)) {
    //         setTodayStart(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).startOf('day')._d))
    //         setTodayEnd(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).endOf('day')._d))
    //     }
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true)
                let dataToSet = {}
                let objToPass = {
                    "timezone": !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                    "clientIDs": props.dealersettings.client.id,
                    "filters": {}
                }

                if (props.selectedPipeline) {
                    objToPass.filters.pipeline = props.selectedPipeline.value
                } else if (!_.isEmpty(props.pipeline)) {
                    if (inclInboundLeadsinRpt)
                        objToPass.filters.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
                    else
                        objToPass.filters.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
                }
                if (props.selectedUser) {
                    objToPass.filters.owner = props.selectedUser.value
                }
                if (props.dateRange.startDate && props.dateRange.endDate) {
                    objToPass.filters.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    objToPass.filters.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }

                if (props.ownerDashboard) {
                    objToPass.filters.owner = localStorage.uid
                }

                if (props?.dealersettings?.mvwReport) {
                    objToPass.v2 = true;
                }

                const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getOEMDashboardCounts');
                const resp = await getDashboardCounts(objToPass)

                let _filter = {
                    clientID: props.dealersettings.client.id,
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
                }
                if (props.selectedPipeline) {
                    _filter.pipeline = props.selectedPipeline.value
                } 
                // else if (!_.isEmpty(props.pipeline)) {
                //     _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket').map(item => item.value).join(',')
                // }
                if (props.selectedUser) {
                    _filter.owner = props.selectedUser.value
                }
                if (props.ownerDashboard) {
                    _filter.owner = localStorage.uid
                }
                if (props.dateRange) {
                    _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }

                if (resp.data.success) {
                    //console.log('data returned', resp.data.data)
                    const data = resp.data.data
                    dataToSet = {
                        ...dataToSet,
                        todayNew: data.todayNew,
                        monthNew: data.monthNew,
                        todayOpen: data.todayOpen,
                        monthOpen: data.monthOpen,
                        todayWon: data.todayWon,
                        monthWon: data.monthWon,
                        todayLost: data.todayLost,
                        monthLost: data.monthLost,
                        todayDelivered: data.todayDelivered,
                        monthDelivered: data.monthDelivered,
                        todayTestdrive: data.todayTestdrive,
                        monthTestdrive: data.monthTestdrive,
                        todayTradein: data.todayTradein,
                        monthTradein: data.monthTradein, 
                        todayClosed: data.todayClosed,
                        monthClosed: data.monthClosed,
                        todayActivity: data.todayActivity,
                        monthActivity: data.monthActivity,
                        overdueActivity: data.overdueActivity
                    }
                } else {
                    // console.log('data not returned')
                }
                setCounts(dataToSet)
                setLoader(false)

                let _searchObject = {
                    type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
                    params: JSON.stringify(_filter)
                }

                const getUnattendedCount = window.firebase.functions().httpsCallable('generic-getData');
                const respUnAttende = await getUnattendedCount(_searchObject)
                // console.log('respUnAttende returned', respUnAttende)
                if (respUnAttende.data.success) {
                    const data = respUnAttende.data.data[0]
                    setCounts({
                        ...dataToSet,
                        todayActivity: data.todayactivity,
                        monthActivity: data.monthactivity,
                        overdueActivity: data.overdue,
                    })
                }
            } catch (error) {
                console.error(error)
                setLoader(false)
            }
        }

        fetchData()
    }, [props.selectedPipeline, props.selectedUser, props.dateRange, props.refreshData, props.dealersettings.client.id])

    useEffect(() => {
        setOwnerDashboard((!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false)
    }, [props.dealersettings])

    useEffect(() => {
        if (_.isEmpty(props.pipeline))
            return
        let _pipeline = [];
        props.pipeline.filter(item => (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket') && !Boolean(item.disableReport)).forEach(rec => {
            _pipeline.push({
                label: rec.name,
                active: _.isBoolean(rec.active) ? rec.active : true,
                value: rec.value,
                data: rec
            })
        })
        setPipelineOption(_pipeline)
        //setPipeline(_pipeline[0])
    }, [props.pipeline])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    return (<>
        <div className="dashboard-panel-top">
            {loader && <div className='custom-counts-loader' />}
            <div className="dashboard-panel-head">
                <div className="float-left d-flex">
                    {
                        !_.isEmpty(props.selectedPipeline) ? (<h3>{props.selectedPipeline.label} Stats</h3>) : (<h3><Translate text={'allPipelineStats'} /></h3>)
                    }
                    {loader && <div className="align-items-center d-flex justify-content-center loader-primary spinner-loader-h3"><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3"></p></div>}
                </div>
                <div className="dash-widget-settings-wrap">
                    <a href="#" className="dropdown-toggle" onClick={props.handleOpenWidgetSettings}>
                        <i className="ico icon-settings"></i>
                    </a>
                </div>
                <div className="float-lg-right form-dashboard">
                    <div className="form-row justify-content-end">
                        <div className="form-group col-md-4 daterange-fix">
                            <AntDateRangePicker
                                value={props.dateRange && props.applydateRange === true ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={'dateDashboard'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { props.handleDateRangeChange(e, 'dateDashboard') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                                disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={pipelineOptions}
                                name={"pipeline"}
                                placeholder={'select pipeline'}
                                onChange={(e, data) => {
                                    props.setPipeline(e)
                                }}
                                value={!_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : ''}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect>
                        </div>
                        {
                            !ownerDashboard ? (<div className="form-group col-md-3"><ReactSelect
                                options={userOptions}
                                name={"user"}
                                placeholder={'select user'}
                                onChange={(e, data) => {
                                    props.setUser(e)
                                }}
                                value={!_.isEmpty(props.selectedUser) ? props.selectedUser.value : ''}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect> </div>) : (<></>)
                        }
                    </div>
                </div>
            </div>
            <div className="dashboard-panel-body">
                <div className=" row col-lg-3-fix">
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-new-leads"></i></div>
                                    <div className="dashboard-module-label"> New Leads</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.monthNew) {
                                                            props.handleShowEnquiryList({
                                                                status: 'all',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'addedDate',
                                                                pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                            }, 'Today New Leads', counts.todayNew)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayNew}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.monthNew) {
                                                    props.handleShowEnquiryList({
                                                        status: 'all',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'addedDate',
                                                        pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD New Leads' : 'New Leads'), counts.monthNew)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthNew}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-open-leads"></i></div>
                                    <div className="dashboard-module-label"> Open Leads</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.todayOpen) {
                                                            props.handleShowEnquiryList({
                                                                status: 'open',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'addedDate',
                                                                pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                            }, 'Today Open Leads', counts.todayOpen)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayOpen}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.monthOpen) {
                                                    props.handleShowEnquiryList({
                                                        status: 'open',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'addedDate',
                                                        pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Open Leads' : 'Open Leads'), counts.monthOpen)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthOpen}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-delivered")}></i></div>
                                    <div className="dashboard-module-label"> Close Won Leads</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.todayClosed) {
                                                            props.handleShowEnquiryList({
                                                                status: 'closeWon',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'wonDate',
                                                                pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                            }, 'Today Close Won Leads', counts.todayClosed)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayClosed}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.monthClosed) {
                                                    props.handleShowEnquiryList({
                                                        status: 'closeWon',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'wonDate',
                                                        pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Close Won Leads' : 'Close Won Leads'), counts.monthClosed)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthClosed}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-lost-leads")}></i></div>
                                    <div className="dashboard-module-label"> Lost Leads</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.todayLost) {
                                                            props.handleShowEnquiryList({
                                                                status: 'lost',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'lostDate',
                                                                pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                            }, 'Today Lost Leads', counts.todayLost)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayLost}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.monthLost) {
                                                    props.handleShowEnquiryList({
                                                        status: 'lost',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'lostDate',
                                                        pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Lost Leads' : 'Lost Leads'), counts.monthLost)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthLost}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-activities "></i></div>
                                    <div className="dashboard-module-label"> Activities </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.todayActivity > 0) {
                                                            props.handleShowActivityList({
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                isDone: true,
                                                                dateType: 'modifiedDate'
                                                            }, 'Today Activities')
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayActivity}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.monthActivity > 0) {
                                                    props.handleShowActivityList({
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        isDone: true,
                                                        dateType: 'modifiedDate'
                                                    }, (props.applydateRange === false ? 'MTD Activities' : 'Activities'))
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthActivity}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className="ico icon-overdue-activities "></i></div>
                                    <div className="dashboard-module-label"> Overdue Activities </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            <div className="col-lg-12" onClick={() => {
                                                if (counts.overdueActivity) {
                                                    props.handleShowActivityList({
                                                        isDone: false,
                                                        endDate: todayStart,
                                                        dateType: 'startDate'
                                                    }, 'Overdue Activities', counts.overdueActivity)
                                                }
                                            }}>
                                                <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.overdueActivity > 9999 ? `9999+` : counts.overdueActivity ? counts.overdueActivity : '0'}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopUpModal show={props.showWidgetSettings}>
            <WidgetSettings
                {...props}
                show={props.showWidgetSettings}
                handleClose={props.handleCloseWidgetSettings}
                title={'Sales Dashboard Settings'}
                sortedWidgets={props.sortedWidgets}
                hiddenWidgets={props.hiddenWidgets}
                removeUnAllowedWidgets={props.removeUnAllowedWidgets}
                onLayoutChange={props.onLayoutChange}
                layout={props.layout}
                setLayout={props.setLayout}
            ></WidgetSettings>
        </PopUpModal>
    </>
    );
}

export default PipelineCounts;