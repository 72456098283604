/** LIBRARIES */
import React, { useRef } from 'react';
import Viewer from 'react-viewer';
import _ from 'lodash'

function ImageViewer(props) {
    const ref = useRef(null);
    //console.log('ImageViewer_props.images', props.images)
    let _images = (!_.isEmpty(props.images) && _.isArray(props.images)) ?
        _.map(props.images, function (e) { return { src: e, downloadUrl: e, alt: '' } }) :
        (!_.isEmpty(props.images) && _.isString(props.images)) ?
            [{ src: props.images, downloadUrl: props.images, alt: (props.alt || '') }] :
            []

    const printImage = () => {
        var imagesPath = [];
        _images.forEach(function (el, item) {
            if (el.src.indexOf("static/") <= 0) {
                imagesPath.push(el.src);
            }
        });
        var imageUrl = "";
        for (let i = 0; i < imagesPath.length; i++) {
            var imageName = "Image" + (i + 1);
            var newImage = imageName + "=" + imagesPath[i];
            if (i !== (imagesPath.length - 1)) {
                newImage = newImage + "&";
                imageUrl = imageUrl + newImage;
            }
            else {
                //newImage = newImage
                imageUrl = imageUrl + newImage;
            }
        }
        window.open("/print.html?dc=" + new Date().getTime().toString() + "&" + imageUrl, "_blank");

    }

    const toolbar = [

        // Change the style of buttons existed
        {
            key: 'zoomIn',
            actionType: 1,
            render: <i class="react-viewer-icon-fix react-viewer-icon-zoomIn-fix" title={`Zoom In`}></i>,
        },
        {
            key: 'zoomOut',
            actionType: 2,
            render: <i class="react-viewer-icon-fix react-viewer-icon-zoomOut-fix" title={`Zoom Out`}></i>,
        },
        {
            key: 'prev',
            actionType: 3,
            render: <i class="react-viewer-icon-fix react-viewer-icon-prev-fix" title={`Previous`}></i>,
        },
        {
            key: 'reset',
            actionType: 7,
            render: <i class="react-viewer-icon-fix react-viewer-icon-reset-fix" title={`Reset`}></i>,
        },
        {
            key: 'next',
            actionType: 4,
            render: <i class="react-viewer-icon-fix react-viewer-icon-next-fix" title={`Next`}></i>,
        },
        {
            key: 'rotateLeft',
            actionType: 5,
            render: <i class="react-viewer-icon-fix react-viewer-icon-rotateLeft-fix" title={`Rotate Left`}></i>,
        },
        {
            key: 'rotateRight',
            actionType: 6,
            render: <i class="react-viewer-icon-fix react-viewer-icon-rotateRight-fix" title={`Rotate Right`}></i>,
        },
        {
            key: 'scaleX',
            actionType: 9,
            render: <i class="react-viewer-icon-fix react-viewer-icon-scaleX-fix" title={`Flip Vetical`}></i>,
        },
        {
            key: 'scaleY',
            actionType: 10,
            render: <i class="react-viewer-icon-fix react-viewer-icon-scaleY-fix" title={`Flip Horizontal`}></i>,
        },
        {
            key: 'download',
            actionType: 11,
            render: <i class="react-viewer-icon-fix react-viewer-icon-download-fix" title={`Download`}></i>,
        },

        // Set the style of buttons you created
        {
            key: 'customButton',
            render: <i class="react-viewer-icon-fix react-viewer-icon-print-fix" title={`Print`}></i>,
            onClick: () => {
                printImage();
            }
        },
    ];
    ///console.log('ImageViewer', _images)
    return (
        <div ref={ref}>
            <Viewer
                visible={props.show}
                customToolbar={() => toolbar}
                onClose={() => { props.handleClose(false); }}
                onMaskClick={() => props.handleClose(false)}
                activeIndex={props.index || 0}
                images={_images}
                noClose={false}
                //drag={false}
                loop={false}
                downloadable={true}
                downloadInNewWindow={true}
                zIndex={props.zIndex || 5000}
                zoomSpeed={0.25}
                minScale={0.25}
                noNavbar={_.isBoolean(props.noNavbar) ? props.noNavbar : (_images.length > 1 ? false : true)}
            />
        </div>
    );
}



export default ImageViewer;