import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import _ from 'lodash'
import Translate from '../../constants/translate';
import CommonHelper from "../../services/common";
import images from "../../images";

const EmailPopUp = (props) => {
    const [thread, setThread] = useState()
    const nylasEmail = props?.dealersettings?.nylas_v3?.email_address || props.dealersettings.email;
    const nylasAccount = props?.dealersettings?.nylas_v3?.grantId || props.dealersettings.id;
    const _allUser = !_.isEmpty(props?.groupUsers) ? props?.groupUsers : props?.clientUsers
    useEffect(() => {

        let nylasAccountID = null;
        if (props.dealersettings && !_.isEmpty(props.dealersettings.nylas_v3))
            nylasAccountID = props?.dealersettings.nylas_v3.grantId

        console.log('EmailPopUp', nylasAccountID, props.notification.recordID)
        var nylasThread = window.firebase.functions().httpsCallable('nylasv3-getThread');
        nylasThread({
            grantId: nylasAccountID,
            threadId: props.notification.recordID
        }).then(res => {
            console.log('EmailPopUp_nylasThread_response', res)
            if (res?.data?.success === true) {
                let data = res?.data?.thread;
                setThread(data)
            }
        }).catch(err => console.log(err))
        // deprecated for nylas_v3 by SaMeeR
        // window.firebase.firestore().doc(`nylas-accounts/${nylasAccount}/nylas-threads/${props.notification.recordID}`)
        //     .get()
        //     .then(rec => {
        //         setThread(rec.data())
        //     })
    }, [])

    const getNameFromParticipants = (participants, email, field) => {
        const filteredParticipants = participants.filter(
            participant => participant.email !== email,
        );

        if (filteredParticipants.length === 0) {
            if (participants && participants.length > 0 && participants[0][field]) {
                return participants[0][field] ? participants[0][field] : '';
            }
            else if (participants && participants.length > 0 && participants[0].email) {
                return participants[0].email ? participants[0].email.split('@')[0] : '';
            }
        }
        if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0][field]) {
            return filteredParticipants[0][field] ? filteredParticipants[0][field] : '';
        }
        else if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0].email) {
            return filteredParticipants[0].email ? filteredParticipants[0].email.split('@')[0] : '';
        }
    };

    return !_.isEmpty(thread) ? <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-chatnotify-custom">
        <Modal.Header >
            <Modal.Title>
                <div className="modal-title h4" id=""><i className="ico icon-Mail mailnotifiy-pop-title"></i> <Translate text={props.title} /></div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="mailnotify-custom-wrap">
                <div>
                    <div className="mailnotify-owner">
                        <div className="mailnotify-owner-view"> <img src={CommonHelper.getUserImagebyId(_allUser, thread?.latestDraftOrMessage?.from[0]?.email, thread?.latestDraftOrMessage?.from[0]?.name)} /></div>
                        <div> <div>{getNameFromParticipants((thread?.latestDraftOrMessage?.from || thread.participants), nylasEmail, 'name')} <span className="mailnotifiy-email"> ({getNameFromParticipants((thread?.latestDraftOrMessage?.from || thread.participants), nylasEmail, 'email')}) </span> </div>
                            <div className="mailnotify-time">{moment.unix(thread?.latestMessageReceivedDate).format('DD/MM/YYYY hh:mm A')}</div></div>
                    </div>
                    <div className="mailnotify-subject">{props.notification.title}</div>
                    <div className="mailnotify-text">{props.notification.body}</div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">

            <button type="button"
                className={`btn btn-default float-left`}
                onClick={(e) => { e.preventDefault(); props.handleClose(); }} >
                <Translate text={'cancel'} />
            </button>
            <button type="button"
                className={`btn btn-primary float-right`}
                onClick={async (e) => {
                    e.preventDefault();
                    props.handleClose();
                    props.history.push('/mails/inbox?threadid=' + thread.id)
                    window.firebase.firestore().doc(`/notifications/${props.notification.documentID}`).set({ isRead: true }, { merge: true });
                }}>
                <Translate text={'View'} />
            </button>


        </Modal.Footer>
    </Modal> : (<></>)
}

export default EmailPopUp;