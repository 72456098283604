import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import toast from 'toasted-notes'
// import Papa from 'papaparse'
import Swal from 'sweetalert2'
import moment from 'moment'
import { Dropdown, Popover } from 'react-bootstrap';

import Translate from '../../../constants/translate';
import { PopUpModal, TextEditor } from '../../../components';
import { TemplateContext } from '../../settings/workflow/templateContext';
import AddContact from '../addContact';
import ViewRecipients from '../viewRecipients';
import AddRecipient from '../addRecipient'
import ScheduleCampaign from '../scheduleCampaign';
import ImportCSV from '../importCSV'
import { mentions } from '../../settings/workflow/templates/viewModel';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import { default as _images } from "../../../images";
import CommonHelper from '../../../services/common';
// import axios from 'axios';

const AddMessage = props => {
	const node = useRef();
	const [data, setData] = useState({
		title: '',
		subject: '',
		template: '',
	});
	const [loader, setLoader] = useState(props.title.includes('Edit') ? true : false);
	const [saveLoader, setSaveLoader] = useState(false);
	const [scheduleLoader, setScheduleLoader] = useState(false);
	const [saveLaterLoader, setSaveLaterLoader] = useState(false);
	// const [editTitle, setEditTitle] = useState({show:false, snapshot: {title: ''}});
	const [editSubject, setEditSubject] = useState({ show: false, snapshot: { subject: '' } });
	const [recipients, setRecipients] = useState([]);
	const [errorFields, setErrorFields] = useState({});
	const { smsTemplateList } = useContext(TemplateContext);
	const [showAddContact, setShowAddContact] = useState({
		show: false,
		title: '',
	});

	const [showRecipients, setShowRecipients] = useState({
		show: false,
		title: '',
	});

	const [showAddRecipient, setShowAddRecipient] = useState({
		show: false,
		title: '',
	});

	const [showScheduleCampaign, setShowScheduleCampaign] = useState({
		show: false,
		title: '',
	});

	const [showImportCSV, setShowImportCSV] = useState({
		show: false,
		title: '',
	});

	const [template, setTemplate] = useState({
	})
	const [counter, setCounter] = useState({ sms: 0, text: 0 })
	const [templates] = useState(smsTemplateList ? smsTemplateList : []);
	const [showPop, setShowPop] = useState(false);
	const [templateSearchText, setTemplateSearchText] = useState('');

	const [dedicatedNumber, setDedicatedNumber] = useState('');
	const [fullPageLoader, setFullPageLoader] = useState({
		show: false,
		title: '',
	});
	const [hasMore, setHasMoreData] = useState([])

	useEffect(() => {
		if (props.dealersettings
			&& !_.isEmpty(props.dealersettings.client)
			&& !_.isEmpty(props.dealersettings.client.integrations)
			&& props.dealersettings.client.integrations.filter(a => a.type === 'messagemedia').length > 0) {
			let mmIntegration = props.dealersettings.client.integrations.filter(a => a.type === 'messagemedia')[0]
			setDedicatedNumber(!_.isEmpty(mmIntegration.settings) ? mmIntegration.settings.dedicatednumber : '')
		}
	}, [])


	useEffect(() => {
		if (!_.isEmpty(template) && !_.isEmpty(template.templateJSON)) {
			var data = JSON.parse(template.templateJSON);
			var text = '';
			if (!_.isEmpty(data.blocks)) {
				data.blocks.forEach(ele => {
					text += ele.text + ' ';
				});
			}
			text = text.trim()
			setCounter({
				text: text.length,
				sms: Math.ceil(text.length / 160)
			})
		}
	}, [template])

	useEffect(() => {
		const fetchCampaignData = async () => {
			let recipientsToAdd = []

			if (props.title !== 'Edit Message') {
				return;
			}

			try {
				const querySnapshot = await window.firebase.firestore().collection('smsCampaigns').doc(props.campaignID).get()
				const messageData = querySnapshot.data()

				const snapshots = await window.firebase.firestore().collection('smsCampaigns').doc(props.campaignID).collection('smsRecipients').limit(100).get()
				if (!snapshots.empty) {
					snapshots.forEach((doc) => {
						// doc.data() is never undefined for query doc snapshots
						// console.log(doc.id, " => ", doc.data());
						recipientsToAdd.push(doc.data())
					});
					setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
					// console.log('these are recs', recipientsToAdd)
					// console.log('these are messageData.recipientIDs', messageData.recipientIDs)
					// recipientsToAdd = recipientsToAdd.filter(a => messageData.recipientIDs.some(id => id === a.documentID))
				}
				setData({
					subject: messageData.title,
					template: messageData.content,
					documentID: messageData.documentID,
					recipientsCount: messageData.recipientsCount,
					failedCount: messageData.failedCount,
					deliveredCount: messageData.deliveredCount,
				})
				setTemplate({
					...template,
					bodyHTML: messageData.content,
					textMessage: messageData.content
				})
				setCounter({
					text: messageData.content.length,
					sms: Math.ceil(messageData.content.length / 160)
				})
				setRecipients(recipientsToAdd)
				setLoader(false)

				// console.log('these are recs at set', recipientsToAdd)
			} catch (error) {
				console.log(error);
				setLoader(false)
			}
		};
		fetchCampaignData();
	}, []);

	const fetchMoreData = async (notPaginate) => {
		if (recipients.length === data.recipientsCount) {
			return
		}
		let recipientsToAdd = []
		let ref
		if (false) {
			ref = window.firebase.firestore().collection('smsCampaigns').doc(props.campaignID).collection('smsRecipients').limit(100)
		} else {
			// console.log('paginate')
			ref = window.firebase.firestore().collection('smsCampaigns').doc(props.campaignID).collection('smsRecipients').startAfter(hasMore[0]).limit(100)
		}
		const snapshots = await ref.get()
		if (!snapshots.empty) {
			snapshots.forEach((doc) => {
				recipientsToAdd.push(doc.data())
			});
			setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
			// recipientsToAdd = recipientsToAdd.filter(a => data.recipientIDs.some(id => id === a.documentID))
			setRecipients(_.uniqBy([...recipients, ...recipientsToAdd], 'phone'))
		}
	}

	const filterTemplates = (template) => {
		if (!templateSearchText) {
			return true
		} else if (template.title.toLowerCase().includes(templateSearchText.toLowerCase())) {
			return true
		}

		return false
	}

	const handleOnChange = e => {
		const { name, value } = e.target;

		setData({
			...data,
			[name]: value,
		});
		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleInsertTemplate = emailTemplate => {
		let template = emailTemplate.bodyHTML ? emailTemplate.bodyHTML : ''
		if (!_.isEmpty(emailTemplate.templateJSON) && !_.isEmpty(JSON.parse(emailTemplate.templateJSON))) {
			template = JSON.parse(emailTemplate.templateJSON);
			let text = '';
			if (!_.isEmpty(template.blocks)) {
				template.blocks.forEach(ele => {
					text += ele.text + '\n';
				});
			}
			template = populateTemplateWithData(text)
		} else {
			template = populateTemplateWithData(template)
		}
		// console.log('before submitting template', template)
		setCounter({
			text: template.length,
			sms: Math.ceil(template.length / 160)
		})
		setTemplate({
			...template,
			bodyHTML: template,
			textMessage: template
		})
		return
	};


	const onEditorStateChange = (obj) => {
		const templateJSON = JSON.stringify(obj.rawData)
		let data = templateJSON ? JSON.parse(templateJSON) : '';
		let text = '';
		if (!_.isEmpty(data) && !_.isEmpty(data.blocks)) {
			data.blocks.forEach(ele => {
				text += ele.text + ' ';
			});
		}
		text = text.trim()
		const textMessage = text
		setTemplate({
			...template,
			[obj.name]: obj.value,
			templateJSON,
			textMessage
		});
	}

	const handleShowRecipientsModalClose = () => {
		setShowRecipients({
			...showRecipients,
			show: false,
			title: ''
		});
	}

	const handleShowAddRecipientOpen = () => {
		setShowAddRecipient({
			show: true,
			title: 'Add Recipient'
		})
	}

	const handleShowScheduleCampaignOpen = () => {
		if (!data.subject) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		if (_.isEmpty(template.textMessage)) {
			toast.notify('Please add a message content', {
				duration: 2000
			})
			return
		}

		setShowScheduleCampaign({
			show: true,
		})
	}

	const handleShowScheduleCampaignClose = async (scheduleDate) => {
		if (!scheduleDate) {
			setShowScheduleCampaign({
				show: false,
			})
			return
		}

		const result = await Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to schedule this message for') + ` ${moment(scheduleDate.toDate()).format('DD/MM/YYYY hh:mm A')}?`,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		})
		if (!result.value) {
			return
		}

		setShowScheduleCampaign({
			show: false,
		})

		onSaveAndSend(scheduleDate)
	}

	const handleShowImportCSVClose = async (recipientsToSet) => {
		setShowImportCSV({
			show: false,
		})
		if (recipientsToSet) {
			setFullPageLoader({
				show: true,
				title: 'Contacts are being imported...'
			})
			const all = [...recipientsToSet, ...recipients]
			setRecipients(_.uniqBy(all, 'phone'));
			let docID
			if (data.documentID) {
				docID = data.documentID
			} else {
				docID = window.firebase.firestore().collection('smsCampaigns').doc().id
			}
			let smsCampaignRef = window.firebase.firestore().collection('smsCampaigns').doc(docID)
			let recipientsToSetIDs = recipientsToSet.map(a => a.documentID)
			recipientsToSetIDs = _.uniq(recipientsToSetIDs)

			let count = 0
			if (data.recipientsCount) {
				count = data.recipientsCount + recipientsToSetIDs.length
			} else {
				count = recipientsToSetIDs.length
			}
			await smsCampaignRef.set({ modifiedDate: window.firebase.firestore.Timestamp.now(), recipientsCount: count }, { merge: true })
			setData({
				...data,
				documentID: docID,
				// recipientIDs: arrayToCalculateLength,
				recipientsCount: count
			})
			// setRecipients(all)
			// console.log('recipientsToSetIDs', recipientsToSetIDs)
			const arrayOfRecipientsToSave = []
			while (recipientsToSet.length > 0) {
				arrayOfRecipientsToSave.push(recipientsToSet.splice(0, 300));
			}
			// console.log('these are sub arrays arrayOfRecipientsToSave of 300', arrayOfRecipientsToSave)
			await Promise.all(
				arrayOfRecipientsToSave.map(async (arrayOfMessages, index) => {
					const batch = window.firebase.firestore().batch();
					arrayOfMessages.forEach(rec => {
						batch.set(window.firebase.firestore().collection(`smsCampaigns`).doc(docID).collection('smsRecipients').doc(rec.documentID), rec, { merge: true });
					})
					await batch.commit()
				})
			)
			setFullPageLoader({
				show: false,
				title: 'Contacts are being imported...'
			})

			toast.notify('Recipients imported successfully', {
				duration: 2000
			})
		}
	}

	const handleShowImportCSVOpen = () => {
		setShowImportCSV({
			show: true,
		})
	}

	const handleShowAddRecipientClose = async (recipient) => {
		setShowAddRecipient({
			show: false,
			title: ''
		})

		if (recipient) {
			const recipientObj = {
				phone: recipient.phone ? recipient.phone : '',
				phoneCode: recipient.phoneCode ? recipient.phoneCode : '',
				firstName: recipient.firstName ? recipient.firstName : '',
				middleName: recipient?.middleName || '',
				lastName: recipient.lastName ? recipient.lastName : '',
				contactID: recipient.contactID ? recipient.contactID : null,
				documentID: window.firebase.firestore().collection('smsCampaigns').doc().id,
				clientID: props.dealersettings.client.id,
				addedBy: localStorage.uid
			}
			let contactWithNoPhone = recipients.filter(contact => !contact.phone)
			let contactWithPhone = recipients.filter(contact => contact.phone)
			contactWithPhone = _.uniqBy([...[recipientObj], ...contactWithPhone], 'phone')
			// setRecipients([...contactWithPhone ,...contactWithNoPhone]);

			const all = [...contactWithPhone, ...contactWithNoPhone]
			setRecipients(_.uniqBy(all, 'phone'));
			let docID
			if (data.documentID) {
				docID = data.documentID
			} else {
				docID = window.firebase.firestore().collection('smsCampaigns').doc().id
			}
			let smsCampaignRef = window.firebase.firestore().collection('smsCampaigns').doc(docID)
			let count = 0
			if (data.recipientsCount) {
				count = data.recipientsCount + 1
			} else {
				count = 1
			}

			await smsCampaignRef.set({ modifiedDate: window.firebase.firestore.Timestamp.now(), recipientsCount: count }, { merge: true })
			setData({
				...data,
				documentID: docID,
				// recipientIDs: arrayToCalculateLength,
				recipientsCount: count
			})
			await window.firebase.firestore().collection(`smsCampaigns`).doc(docID).collection('smsRecipients').doc(recipientObj.documentID).set(recipientObj, { merge: true })
		}

	}

	const handleContactModalClose = async (selectedContacts, importingAll) => {
		// console.log('selectedContacts', selectedContacts)
		// console.log('importingAll', importingAll)

		setShowAddContact({
			...showAddContact,
			show: false,
		});
		if (_.isEmpty(selectedContacts)) {
			return;
		}
		let selectedContactsArr
		if (importingAll) {
			selectedContactsArr = selectedContacts
				.map(contact => {
					return {
						phone: contact.phone ? contact.phone : '',
						phoneCode: contact.phoneCode ? contact.phoneCode : '',
						firstName: contact.firstName ? contact.firstName : '',
						middleName: contact?.middleName || '',
						lastName: contact.lastName ? contact.lastName : '',
						documentID: window.firebase.firestore().collection('smsCampaigns').doc().id,
						contactID: contact.documentID,
						clientID: props.dealersettings.client.id,
						addedBy: localStorage.uid
					};
				});
		} else {
			selectedContactsArr = Object.entries(selectedContacts).map(e => e[1])
			// console.log('selectedContactsArr', selectedContactsArr)
			selectedContactsArr = selectedContactsArr
				// .filter(contact => contact.phone)
				.map(contact => {
					return {
						phone: contact.phone ? contact.phone : '',
						phoneCode: contact.phoneCode ? contact.phoneCode : '',
						firstName: contact.firstName ? contact.firstName : '',
						middleName: contact?.middleName || '',
						lastName: contact.lastName ? contact.lastName : '',
						documentID: window.firebase.firestore().collection('smsCampaigns').doc().id,
						contactID: contact.documentID,
						clientID: props.dealersettings.client.id,
						addedBy: localStorage.uid
					};
				});
		}

		let contactWithNoPhone = selectedContactsArr.filter(contact => !contact.phone)
		let contactWithPhone = selectedContactsArr.filter(contact => contact.phone)
		let _data = _.uniqBy([...recipients, ...contactWithPhone], 'phone')
		// setRecipients([..._data ,...contactWithNoPhone]);
		// show loader
		// check if there is already a docID
		// set the db doc and recipients
		// console.log('final _data',_data)
		let smsCampaignRef
		let docID
		if (data.documentID) {
			docID = data.documentID
		} else {
			docID = window.firebase.firestore().collection('smsCampaigns').doc().id
		}
		smsCampaignRef = window.firebase.firestore().collection('smsCampaigns').doc(docID)
		let contactWithPhoneIDs = contactWithPhone.map(a => a.documentID)
		contactWithPhoneIDs = _.uniq(contactWithPhoneIDs)

		let count = 0
		if (data.recipientsCount) {
			count = data.recipientsCount + contactWithPhoneIDs.length
		} else {
			count = contactWithPhoneIDs.length
		}

		setData({
			...data,
			documentID: docID,
			// recipientIDs: arrayToCalculateLength,
			recipientsCount: count
		})
		setRecipients(_data)
		let recipientsToSave = _data.map(recipient => {
			return {
				phone: recipient.phone ? recipient.phone : null,
				phoneCode: recipient.phoneCode ? recipient.phoneCode : null,
				firstName: recipient.firstName ? recipient.firstName : null,
				middleName: recipient?.middleName || '',
				lastName: recipient.lastName ? recipient.lastName : null,
				documentID: recipient.documentID ? recipient.documentID : window.firebase.firestore().collection('smsCampaigns').doc().id,
				contactID: recipient.contactID ? recipient.contactID : null,
				addedBy: localStorage.uid,
				clientID: props.dealersettings.client.id
			}
		})

		await smsCampaignRef.set({ modifiedDate: window.firebase.firestore.Timestamp.now(), recipientsCount: count }, { merge: true })

		// console.log('all raw recipientsToSave', recipientsToSave)
		const arrayOfRecipientsToSave = []
		while (recipientsToSave.length > 0) {
			arrayOfRecipientsToSave.push(recipientsToSave.splice(0, 300));
		}
		// console.log('these are sub arrays arrayOfRecipientsToSave of 300', arrayOfRecipientsToSave)
		await Promise.all(
			arrayOfRecipientsToSave.map(async (arrayOfMessages, index) => {
				const batch = window.firebase.firestore().batch();
				arrayOfMessages.forEach(rec => {
					batch.set(window.firebase.firestore().collection(`smsCampaigns`).doc(docID).collection('smsRecipients').doc(rec.documentID), rec, { merge: true });
				})
				await batch.commit()
			})
		)
		setFullPageLoader({
			show: false,
			title: ''
		})
		toast.notify('Recipients imported successfully', {
			duration: 2000
		})
	};

	const populateTemplateWithData = (text) => {
		const dealersettings = props.dealersettings
		const dealerName = dealersettings.client && dealersettings.client.name ? dealersettings.client.name : '<dealer_name>'
		const dealerEmail = dealersettings.client && dealersettings.client.email ? dealersettings.client.email : '<dealer_email>'
		const dealerPhone = dealersettings.client && dealersettings.client.phone ? dealersettings.client.phone : '<dealer_phone>'
		const dealerAddress = dealersettings.client && dealersettings.client.address ? dealersettings.client.address : '<dealer_address>'
		const facebookURL = dealersettings.client && dealersettings.client.facebookURL ? dealersettings.client.facebookURL : ''
		const xURL = dealersettings.client && dealersettings.client.xURL ? dealersettings.client.xURL : ''
		const instagramURL = dealersettings.client && dealersettings.client.instagramURL ? dealersettings.client.instagramURL : ''
		const youtubeURL = dealersettings.client && dealersettings.client.youtubeURL ? dealersettings.client.youtubeURL : ''
		const userName = dealersettings.name ? dealersettings.name : '<user_name>'
		const userEmail = dealersettings.email ? dealersettings.email : '<user_email>'
		const userPhone = dealersettings.phone ? dealersettings.phone : '<user_phone>'
		const userDesignation = dealersettings.designation ? dealersettings.designation : '<designation>'

		let html = text;

		html = html.replace(/@DEALER_NAME/g, dealerName)
		html = html.replace(/@DEALER_PHONE/g, dealerEmail)
		html = html.replace(/@DEALER_EMAIL/g, dealerPhone)
		html = html.replace(/@DEALER_ADDRESS/g, dealerAddress)
		html = html.replace(/@FACEBOOK_URL/g, facebookURL)
		html = html.replace(/@X_URL/g, xURL)
		html = html.replace(/@INSTAGRAM_URL/g, instagramURL)
		html = html.replace(/@YOUTUBE_URL/g, youtubeURL)
		html = html.replace(/@USER_NAME/g, userName)
		html = html.replace(/@USER_EMAIL/g, userEmail)
		html = html.replace(/@USER_PHONE/g, userPhone)
		html = html.replace(/@USER_DESIGNATION/g, userDesignation)

		html = html.replace(/@SCHEDULE_DATE/g, '<schedule date>');
		html = html.replace(/@SCHEDULE_TIME/g, '<schedule time>');
		html = html.replace(/@COMPLETED_DATE/g, '<completed date>');
		html = html.replace(/@COMPLETED_TIME/g, '<completed time>');
		html = html.replace(/@CURRENT_MONTH/g, moment().format('MMMM'));
		return html
	}

	const handleSaveSubject = () => {
		let isValid = true;
		if (data.subject === '') {
			isValid = false;
			setErrorFields({
				...errorFields,
				subject: 'input_error',
			});
		}

		if (isValid) {
			setEditSubject({ show: false, snapshot: { subject: '' } });
		}
	}

	const onFinishLater = async () => {
		if (!data.subject) {
			toast.notify('Please add a title', {
				duration: 2000
			})
			return
		}

		if (!(recipients.length > 0)) {
			toast.notify('Please add recipients', {
				duration: 2000
			})
			return
		}

		if (_.isEmpty(template.textMessage)) {
			toast.notify('Please add content for message', {
				duration: 2000
			})
			return
		}

		const result = await Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to save this message as a draft'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		})
		if (!result.value) {
			return
		}

		setSaveLaterLoader(true)

		let objToSave = {}
		// let recipientsToSave = []
		// check if its already created draft
		if (data.documentID) {
			if (props.title.includes('Edit')) {
				objToSave.documentID = data.documentID
				objToSave.status = 'draft'
				objToSave.title = data.subject
				objToSave.content = template.textMessage
				objToSave.addedDate = window.firebase.firestore.Timestamp.now();
				objToSave.modifiedDate = window.firebase.firestore.Timestamp.now();
				objToSave.modifiedBy = localStorage.uid
				objToSave.addedBy = localStorage.uid
				objToSave.modifiedFrom = 'web'
				objToSave.failedCount = 0
				objToSave.deliveredCount = 0
				objToSave.clientID = props.dealersettings.client.id
				objToSave.oemID = props.dealersettings.client.settingsID
				objToSave.isDeleted = false
			} else {
				objToSave.documentID = data.documentID
				objToSave.status = 'draft'
				objToSave.isDeleted = false
				objToSave.title = data.subject
				objToSave.content = template.textMessage
				objToSave.modifiedDate = window.firebase.firestore.Timestamp.now();
				objToSave.addedDate = window.firebase.firestore.Timestamp.now();
				objToSave.modifiedBy = localStorage.uid
				objToSave.addedBy = localStorage.uid
				objToSave.modifiedFrom = 'web'
				objToSave.failedCount = 0
				objToSave.deliveredCount = 0
				objToSave.clientID = props.dealersettings.client.id
				objToSave.oemID = props.dealersettings.client.settingsID
			}
		} else {
			objToSave.documentID = window.firebase.firestore().collection('smsCampaigns').doc().id
			objToSave.status = 'draft'
			objToSave.isDeleted = false
			objToSave.title = data.subject
			objToSave.content = template.textMessage
			objToSave.modifiedDate = window.firebase.firestore.Timestamp.now();
			objToSave.addedDate = window.firebase.firestore.Timestamp.now();
			objToSave.modifiedBy = localStorage.uid
			objToSave.addedBy = localStorage.uid
			objToSave.modifiedFrom = 'web'
			objToSave.failedCount = 0
			objToSave.deliveredCount = 0
			objToSave.clientID = props.dealersettings.client.id
			objToSave.oemID = props.dealersettings.client.settingsID
		}

		try {
			// console.log('objecttoSaveAsDraft', objToSave)
			// console.log('recipientsToSave', recipientsToSave)
			const smsRef = window.firebase.firestore().collection(`smsCampaigns`).doc(objToSave.documentID)
			await smsRef.set(objToSave, { merge: true })

			toast.notify('Message saved as draft successfully', {
				duration: 2000
			})
			props.handleClose()
			setSaveLaterLoader(false)

			//committing batches in parts end
		} catch (error) {
			setSaveLaterLoader(false)
			toast.notify('Something went wrong', {
				duration: 2000
			})
			console.log(error)
		}
	}

	const onSaveAndSend = async (scheduleDate) => {
		if (!scheduleDate) {
			if (!data.subject) {
				toast.notify('Please add a title', {
					duration: 2000
				})
				return
			}

			if (!(recipients.length > 0)) {
				toast.notify('Please add recipients', {
					duration: 2000
				})
				return
			}

			if (_.isEmpty(template.textMessage)) {
				toast.notify('Please add content for message', {
					duration: 2000
				})
				return
			}

			const result = await Swal.fire({
				title: CommonHelper.showLocale(props, 'Are you sure?'),
				text: CommonHelper.showLocale(props, 'Do you want to send this message'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
				cancelButtonText: CommonHelper.showLocale(props, 'No'),
			})
			if (!result.value) {
				return
			}
		}


		setSaveLoader(true);
		if (scheduleDate) {
			setScheduleLoader(true)
		}

		let objToSave = {}
		// let recipientsToSave = []
		// check if its already created draft
		if (data.documentID) {
			// recipientsToSave = recipients.map(recipient => {
			// 	return {
			// 		phone: recipient.phone ? recipient.phone : null, 
			// 		phoneCode: recipient.phoneCode ? recipient.phoneCode : null, 
			// 		firstName: recipient.firstName ? recipient.firstName : null,
			// 		lastName: recipient.lastName ? recipient.lastName : null,
			// 		documentID: recipient.documentID ? recipient.documentID : window.firebase.firestore().collection('smsCampaigns').doc().id,
			// 		contactID: recipient.contactID ? recipient.contactID : null,
			// 		addedBy: localStorage.uid,
			// 		clientID: props.dealersettings.client.id
			// 	}
			// })

			objToSave.documentID = data.documentID
			objToSave.status = 'sending'
			objToSave.title = data.subject
			objToSave.content = template.textMessage
			objToSave.addedDate = window.firebase.firestore.Timestamp.now();
			objToSave.modifiedDate = window.firebase.firestore.Timestamp.now();
			objToSave.modifiedBy = localStorage.uid
			objToSave.addedBy = localStorage.uid
			objToSave.modifiedFrom = 'web'
			objToSave.failedCount = 0
			objToSave.deliveredCount = 0
			objToSave.repliesCount = 0
			objToSave.isDeleted = false
			objToSave.clientID = props.dealersettings.client.id
			objToSave.oemID = props.dealersettings.client.settingsID
		} else {

			objToSave.documentID = window.firebase.firestore().collection('smsCampaigns').doc().id
			objToSave.status = 'sending'
			objToSave.isDeleted = false
			objToSave.title = data.subject
			objToSave.content = template.textMessage
			objToSave.modifiedDate = window.firebase.firestore.Timestamp.now();
			objToSave.addedDate = window.firebase.firestore.Timestamp.now();
			objToSave.modifiedBy = localStorage.uid
			objToSave.addedBy = localStorage.uid
			objToSave.modifiedFrom = 'web'
			objToSave.failedCount = 0
			objToSave.deliveredCount = 0
			objToSave.repliesCount = 0
			objToSave.clientID = props.dealersettings.client.id
			objToSave.oemID = props.dealersettings.client.settingsID
		}

		try {
			// console.log('objecttoSaveInDB', objToSave)
			// console.log('recipientsToSave', recipientsToSave)
			const smsRef = window.firebase.firestore().collection(`smsCampaigns`).doc(objToSave.documentID)
			await smsRef.set(objToSave, { merge: true })

			let objData = {
				smsCampaign: objToSave,
				// recipients: recipientsToSave,
				clientID: props.dealersettings.client.id,
			}

			if (!_.isEmpty(scheduleDate)) {
				objData.scheduleDate = moment(scheduleDate.toDate()).toISOString()
			}

			// console.log('objData to send ', objData)

			// QA
			const messagemediasendBulkSms = window.firebase.functions().httpsCallable('messagemedia-sendBulkSMS');
			props.handleClose()
			toast.notify(`${scheduleDate ? 'SMS Campaign is being scheduled...' : 'SMS Campaign is being sent...'}`, {
				duration: 2000
			})
			const _data = await messagemediasendBulkSms(objData)
			// console.log('messagemediasendBulkSms', _data);

			// LOCAL
			// let sendBulkSmsURL = `http://localhost:5001/ams-pro-qa/us-central1/messagemedia-sendBulkSMS`
			// props.handleClose()
			// toast.notify(`${scheduleDate ? 'SMS Campaign is being scheduled...' : 'SMS Campaign is being sent...'}`, {
			// 	duration: 2000
			// })
			// const _data = await axios.post(sendBulkSmsURL, objData)
			// console.log('messagemediasendBulkSms', _data);

			toast.notify(`${!scheduleDate ? 'SMS Campaign sent successfully' : 'SMS Campaign scheduled successfully'}`, {
				duration: 2000
			})
			if (scheduleDate) {
				setScheduleLoader(false)
			}
			setSaveLoader(false);
		} catch (error) {
			setSaveLoader(false);
			if (scheduleDate) {
				setScheduleLoader(false)
			}
			console.log(error);
		}
	};

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					{fullPageLoader.show && (
						<div className="lds-roller-loader text-center add-message-lds "><div className="lds-roller-loader-inner "><div role="status" className="spinner-border loader-primary"></div><p className="text-muted mt-3">{fullPageLoader.title}</p></div></div>
					)}
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className={`btn btn-default float-left ml-2 ${data.subject &&
									data.recipientsCount && data.recipientsCount > 0 &&
									!_.isEmpty(template.textMessage) ? '' : 'disabled'
									}`}
								onClick={onFinishLater}
							>
								{
									saveLaterLoader ?
										(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
										: (<></>)
								}
								Save as draft
							</button>
							<button
								type='button'
								className={`btn btn-secondary float-left ml-2 ${data.subject &&
									data.recipientsCount && data.recipientsCount > 0 &&
									!_.isEmpty(template.textMessage)
									? ''
									: 'disabled'
									}`}
								onClick={handleShowScheduleCampaignOpen}
							>
								{
									saveLoader && scheduleLoader ?
										(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
										: (<></>)
								}
								Schedule
							</button>
							<button
								type='button'
								onClick={() => onSaveAndSend(null)}
								className={`btn btn-primary float-left ml-2 mr-3 email-send-btn ${data.subject &&
									data.recipientsCount && data.recipientsCount > 0 &&
									!_.isEmpty(template.textMessage)
									? ''
									: 'disabled'
									}`}
							>
								{
									saveLoader && !scheduleLoader ?
										(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
										: (<><i className='ico icon-mail-sent'> </i></>)
								}
								Send
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={async () => {
									const result = await Swal.fire({
										title: CommonHelper.showLocale(props, 'Are you sure?'),
										text: CommonHelper.showLocale(props, 'Do you want to close this message without saving'),
										icon: 'info',
										showCancelButton: true,
										confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
										cancelButtonText: CommonHelper.showLocale(props, 'No'),
									})
									if (result.value) {
										props.handleClose()
									}
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							{loader ? (
								<div className='loader-center-screen'>
									<div className='spinner-loader h-100 undefined '>
										<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
											<div
												role='status'
												className='spinner-border loader-primary'
											></div>
											<p className='text-muted mt-3'></p>
										</div>
									</div>
								</div>
							) : (
								<div className='container-fluid pl-0 '>
									<div className="bulksms-add-wrapper-head">
										<div className="bulksms-add-title"><i className="ico icon-sms-id-icon"> </i> Sender ID :  {dedicatedNumber ? dedicatedNumber : ''}</div>
									</div>
									<div className='bulksms-add-wrapper'>
										<div className='bulksms-add-loop'>
											<div className='bulksms-check-icon'>
												<div
													className={`bulksms-check-icon-wrap ${data.subject && data.subject.length > 0 ? 'active-icon' : ''
														}`}
												>
													<i className='ico icon-sent'></i>
												</div>
											</div>
											<div className='bulksms-add-head'>Message Title</div>
											{editSubject.show ? (
												<div className='campaign-edit-view mt-3 form-style'>
													<div className='form-group'>
														<label htmlFor=''>Message Title</label>
														<input
															type='text'
															name='subject'
															className={`form-control ${errorFields['subject']}`}
															value={data.subject}
															onChange={handleOnChange}
															placeholder='subject'
															autoComplete="off"
														/>
													</div>

													<button
														type='submit'
														className='btn btn-primary float-right mt-3 ml-2'
														onClick={handleSaveSubject}
													>
														Save
													</button>
													<button
														type='submit'
														className='btn btn-default float-right mt-3'
														onClick={() => {
															setData({
																...data,
																subject: editSubject.snapshot.subject,
															});
															setEditSubject({ show: false, snapshot: { subject: '' } });
														}}
													>
														Cancel
													</button>
												</div>
											) : data.subject && data.subject.length > 0 ? (
												<div className='bulksms-add-contentwrap'>
													<div className='bulksms-add-content-info font-bold'>
														{data.subject ? data.subject : 'No Subject'}
													</div>
													<div className='bulksms-add-content-button'>
														<button
															type='button'
															className='btn btn-primary-outline '
															onClick={() => setEditSubject({ show: true, snapshot: { subject: data.subject } })}
														>
															{' '}
															Edit Subject{' '}
														</button>
													</div>
												</div>
											) : (
												<div className='bulksms-add-contentwrap'>
													<div className='bulksms-add-content-info'>
														What's the title for this message?
													</div>
													<div className='bulksms-add-content-button'>
														<button
															type='button'
															className='btn btn-primary '
															onClick={() => setEditSubject({ show: true, snapshot: { subject: data.subject } })}
														>
															{' '}
															Add Title{' '}
														</button>
													</div>
												</div>
											)}
										</div>

										<div className='bulksms-add-loop'>
											<div className='bulksms-check-icon'>
												<div
													className={`bulksms-check-icon-wrap ${data.recipientsCount && data.recipientsCount > 0 ? 'active-icon' : ''
														}`}
												>
													<i className='ico icon-sent'></i>
												</div>
											</div>
											<div className='bulksms-add-head'>To</div>
											{data.recipientsCount && data.recipientsCount > 0 ? (
												<div className='bulksms-add-contentwrap'>
													<div className='bulksms-add-content-info'>
														{data.recipientsCount && data.recipientsCount > 0 ? (
															<>
																Selected Contacts -{' '}
																<span className='font-bold'>{data.recipientsCount ? data.recipientsCount : 0}</span>{' '}
															</>
														) : (
															<>None Selected</>
														)}
													</div>
													<div className='bulksms-add-content-button'>
														<button
															type='button'
															className='btn btn-primary-outline mr-2'
															onClick={() =>
																setShowRecipients({
																	...showRecipients,
																	show: true,
																	title: 'View Recipients',
																})
															}
														>
															{' '}
															View Recipients{' '}
														</button>
														<button
															type='button'
															className='btn btn-primary '
															onClick={() =>
																setShowAddContact({
																	...showAddContact,
																	show: true,
																	title: 'Import Recipients',
																})
															}
														>
															{' '}
															Import Recipients{' '}
														</button>
														<button
															type='button'
															className='btn btn-secondary ml-2 mb-0'
															onClick={handleShowImportCSVOpen}
														>
															{' '}
															Import CSV{' '}
														</button>
														{/* <label htmlFor="logoURL" className={`btn btn-secondary ml-2 mb-0`}>
																Import CSV
																<input 
																		className="fileInput"
																		type="file"
																		name="testee"
																		id="logoURL"
																		// accept="image/*"
																		style={{ display: 'none' }}
																		onChange={(e) => onSelectFile(e)}
																		autoComplete="off"
																/>
														</label> */}
														<button
															type='button'
															className='btn btn-secondary ml-2'
															onClick={handleShowAddRecipientOpen}
														>
															{' '}
															Add Recipient{' '}
														</button>

													</div>
												</div>
											) : (
												<div className='bulksms-add-contentwrap'>
													<div className='bulksms-add-content-info'>
														Add the contacts you want to send this message to.
													</div>
													<div className='bulksms-add-content-button'>
														<button
															type='button'
															className='btn btn-primary'
															onClick={() =>
																setShowAddContact({
																	...showAddContact,
																	show: true,
																	title: 'Import Recipients',
																})
															}
														>
															{' '}
															Import Recipients{' '}
														</button>
														<button
															type='button'
															className='btn btn-secondary ml-2 mb-0'
															onClick={handleShowImportCSVOpen}
														>
															{' '}
															Import CSV{' '}
														</button>
														{/* <label htmlFor="logoURL" className={`btn btn-secondary ml-2 mb-0`}>
																Import CSV
																<input className="fileInput"
																		type="file"
																		name="testee"
																		id="logoURL"
																		style={{ display: 'none' }}
																		onChange={(e) => onSelectFile(e)} 
																		autoComplete="off"
																/>
														</label> */}
														<button
															type='button'
															className='btn btn-secondary ml-2'
															onClick={handleShowAddRecipientOpen}
														>
															{' '}
															Add Recipient{' '}
														</button>
													</div>
												</div>
											)}
										</div>

										<div className='bulksms-add-loop'>
											<div className='bulksms-check-icon'>
												<div
													className={`bulksms-check-icon-wrap ${!_.isEmpty(template.textMessage) ? 'active-icon' : ''
														}`}
												>
													<i className='ico icon-sent'></i>
												</div>
											</div>
											<div className='bulksms-add-head'>Content</div>
											<div className='bulksms-add-contentwrap'>
												<div className='bulksms-add-content-info'>
													Define the content of your message.
												</div>
												{/* <div className='bulksms-add-template-icon'>
														<img src='images/template.svgz' alt='' width='100' />
													</div> */}
												<div className='bulksms-add-textarea'>
													<TextEditor
														name={'bodyHTML'}
														placeholder={'write message...'}
														value={template.bodyHTML}
														onChange={onEditorStateChange}
														toolbarOptions={[]}
														height={350}
														enableMention={true}
														mentions={mentions}
													/>
													<div className="bulksms-textarea-controls">
														<div className="float-left">
															<Dropdown
																className='template-sms-btn ml-2 float-left dropdown-toggle'
																alignRight
																ref={node}
																show={showPop}
															>
																<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' onClick={() => {
																	setShowPop(!showPop)
																}}>
																	<i className='ico icon-template'></i> <span>Templates</span>
																</Dropdown.Toggle>
																<Dropdown.Menu
																	as={CustomMenu}
																	className='rm-pointers dropdown-menu-lg dropdown-menu-right template-add-dropdown'
																	ChildClass='pipeline-dropdown'
																	xplacement='top-end'
																>
																	<Popover
																		id='popover-basic-mail'
																		className='popover popover-mail-templates popover-x in kv-popover-active show left left-bottom'
																		style={{
																			top: '-317px',
																			left: '42px',
																			display: 'block',
																			zIndex: '1029',
																			padding: '0px',
																		}}

																	>
																		<Popover.Title>
																			<h3 className='popover-header popover-title'>
																				<span className='close' data-dismiss='popover-x' onClick={() => setShowPop(false)}>
																					×
																				</span>
																				Templates{' '}
																			</h3>
																			<div className='email-list-search'>
																				<div className='input-group template-search'>
																					<input type='text' className='form-control' placeholder='Search' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} style={{ width: '100%' }} />
																					<div className='input-group-append input-search-clear'>
																						{templateSearchText.length > 0 && (
																							<a href='#' className='input-search-clear-icon' onClick={() => setTemplateSearchText('')}>
																								<i className='ico icon-remove'></i>
																							</a>
																						)}
																					</div>
																				</div>
																			</div>
																		</Popover.Title>
																		<Popover.Content>
																			<div className='popover-body popover-content p-0'>
																				<div className='mailtemplate-column-nav'>
																					<ul>
																						{templates && templates.length > 0 ? _.orderBy(templates, ['title'], ['asc']).filter(template => filterTemplates(template)).map((template, index) => (
																							<li onClick={() => { handleInsertTemplate(template); setShowPop(false); }} key={index}>
																								<a href='#' key={index}>{template.title}</a>
																							</li>
																						)) : (
																							<>
																								<div className='no-data-flex h-100 justify-content-center align-items-center '>
																									<div className='no-data-img'>
																										{' '}
																										<img src={_images.nodata} width='60' height='60' alt='' />
																									</div>
																									<div className='no-data-txt mt-2'>
																										{' '}
																										<Translate
																											text={'No templates found'}
																										/>
																									</div>
																								</div>
																							</>
																						)}
																					</ul>
																				</div>
																			</div>
																		</Popover.Content>
																	</Popover>

																</Dropdown.Menu>
															</Dropdown>
														</div>
														<div className="float-right"><small className="form-text text-muted float-right"> Characters : {counter.text} |  SMS Counter : {counter.sms}</small></div>
													</div>
													<div className="w-100 d-inline-block">
														{/* <button className="btn btn-primary float-right mt-3 ml-2" onClick={() => handleSubmitContent(template.bodyHTML)}>Save</button> */}
														{/* <button className="btn btn-default float-right mt-3" onClick={handleClearContent}>Cancel</button> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</Modal.Body>
				</div>
				<PopUpModal show={showAddContact.show}>
					<AddContact
						show={showAddContact.show}
						handleClose={handleContactModalClose}
						title={showAddContact.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						updateDealerSettings={props.updateDealerSettings}
						history={props.history}
						apiUrl={props.apiUrl}
						API_KEY={props.API_KEY}
						SERVER_PREFIX={props.SERVER_PREFIX}
						setFullPageLoader={setFullPageLoader}
						fullPageLoader={fullPageLoader}
					/>
				</PopUpModal>
				<PopUpModal show={showRecipients.show}>
					<ViewRecipients
						data={data}
						setData={setData}
						show={showRecipients.show}
						handleClose={handleShowRecipientsModalClose}
						title={showRecipients.title}
						dealersettings={props.dealersettings}
						clientUsers={props.clientUsers}
						updateDealerSettings={props.updateDealerSettings}
						history={props.history}
						recipients={recipients}
						setRecipients={setRecipients}
						apiUrl={props.apiUrl}
						API_KEY={props.API_KEY}
						SERVER_PREFIX={props.SERVER_PREFIX}
						setFullPageLoader={setFullPageLoader}
						fullPageLoader={fullPageLoader}
						fetchMoreData={fetchMoreData}
						hasMore={hasMore}
					/>
				</PopUpModal>
				<PopUpModal show={showAddRecipient.show}>
					<AddRecipient
						show={showAddRecipient.show}
						handleClose={handleShowAddRecipientClose}
						title={showAddRecipient.title}
						dealersettings={props.dealersettings}
						setFullPageLoader={setFullPageLoader}
						fullPageLoader={fullPageLoader}
					/>
				</PopUpModal>
				<PopUpModal show={showScheduleCampaign.show}>
					<ScheduleCampaign
						show={showScheduleCampaign.show}
						handleClose={handleShowScheduleCampaignClose}
						title={showScheduleCampaign.title}
						setFullPageLoader={setFullPageLoader}
						fullPageLoader={fullPageLoader}
					/>
				</PopUpModal>
				<PopUpModal show={showImportCSV.show}>
					<ImportCSV
						show={showImportCSV.show}
						handleClose={handleShowImportCSVClose}
						title={showImportCSV.title}
						recipients={recipients}
						setRecipients={setRecipients}
						data={data}
						setData={setData}
						dealersettings={props.dealersettings}
						setFullPageLoader={setFullPageLoader}
						fullPageLoader={fullPageLoader}
					/>
				</PopUpModal>
			</Modal>
		</>
	);
};

export default AddMessage;
