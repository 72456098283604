import React, { forwardRef, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';


const CustomDatePicker = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value ? moment(props.value, (props.colDef.format || props.colDef.formatSave || 'DD/MM/YYYY hh:mm A')).toDate() : null);
    const dateInputRef = React.createRef();


    useEffect(() => {
        if (dateInputRef.current) {
            dateInputRef.current.focus();
            //dateInputRef.current.show();
        }
    }, [])

    const handleChange = (dt) => {
        const date = dt ? `${moment(dt).format('YYYY-MM-DD')} ${moment().format('hh:mm A')}` : null;
        setValue(date);
        props.api.stopEditing();
        if (props?.colDef?.formatSave) {
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (date ? moment(date).format(props?.colDef?.formatSave) : null);
            props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format(`${props.colDef.format || 'DD/MM/YYYY'} ${props?.colDef?.time ? props?.context?.settings?.timeFormat || 'hh:mm A' : ''}`) : null))
        } else {
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (date ? window.firebase.firestore.Timestamp.fromDate(moment(moment(date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).toDate()) : null);
            props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format(`${props.colDef.format || 'DD/MM/YYYY'} ${props?.colDef?.time ? props?.context?.settings?.timeFormat || 'hh:mm A' : ''}`) : null))
        }
    };

    const disabledDate = (current) => {
        if (props?.colDef?.disableDate) {
            const disDate = props?.colDef?.disableDate;
            switch (disDate.option) {
                case '<': return current < moment().startOf(disDate.day)
                case '>': return current > moment().startOf(disDate.day)
                default: return false;
            }
        }
        return false;
    }

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            <DatePicker
                value={value ? moment(value) : null}
                onChange={handleChange}
                ref={dateInputRef}
                open={true}
                format={`${props.colDef.format || 'DD/MM/YYYY'} ${props?.colDef?.time ? props?.context?.settings?.timeFormat || 'hh:mm A' : ''}`}
                placeholder={props?.colDef?.placeholder || 'DD/MM/YYYY'}
                className={`form-control`}
                picker={props?.colDef?.picker || 'date'}
                disabledDate={disabledDate}
            />
        </div>
    );
});

export default CustomDatePicker;