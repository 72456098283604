import React from 'react';
import { default as _images } from "../images";


const PageNotFound = () => {
    return (<div
        className='middle-wrapper h-100 mt-0'
        style={{
            minHeight: '90vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <div className='spinner-loader h-100'>
            <div className='no-data-flex-wrap h-100 justify-content-center align-items-center loader-primary'>
                <div className='no-data-img-wrap'>
                    <img src={_images.pagenotFound} height='190' alt='' />
                </div>
                <div className='no-data-txt-wrap'>
                    <div className='no-data-content-head'>
                        Page Not Found.
                    </div>
                    <div className='no-data-content'>
                        The page you requested could not be found. Please click the button below and continue using AMS Pro.
                    </div>
                    <div className='no-data-content-head'>
                        {
                            localStorage.defaultModule === 'oem' ? (
                                <a href='/oem/dashboard' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/oem/dashboard'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule === 'service' ? (
                                <a href='/dashboard/service' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard/service'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule === 'afterSales' ? (
                                <a href='/dashboard/afterSales' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard/afterSales'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule === 'tradeinPro' ? (
                                <a href='/dashboard/appraisal' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard/appraisal'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule?.includes('eventsPro') ? (
                                <a href='/dashboard/events' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard/events'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule?.includes('fleetPro') ? (
                                <a href='/dashboard/fleetPro' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard/fleetPro'
                                }}>
                                    Back to Dashboard
                                </a>
                            ) : localStorage.defaultModule === 'wholesaler' ? (
                                <a href='/wholesaler' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/wholesaler'
                                }}>
                                    Back to Appraisal
                                </a>
                            ) : localStorage.defaultModule === 'dealLog' ? (
                                <a href='/deallog/orders' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/deallog/orders'
                                }}>
                                    Back to Appraisal
                                </a>
                            ) : (

                                <a href='/dashboard' className='btn btn-primary' onClick={() => {
                                    window.location.pathname = '/dashboard'
                                }}>
                                    Back to Dashboard
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default PageNotFound;