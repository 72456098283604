/** LIBRARIES */
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash'
/** PROVIDERS */
import { ReactSelect, InputAutoNumber, AntDatePicker } from '../../../components';
import { LayoutConsumer } from '../../layout/provider';
import { PipelineConsumer } from './PipelineProvider';
import { StageConsumer } from '../provider';
import Translate from '../../../constants/translate';
import CommonHelper from "../../../services/common";
import { defaultFinanceTerms } from '../../../services/enum';
import CreditScore from './creditScore'
import FileDocuments from '../../files/documents';
import EnquiryInputs from './enquiryInputs';

class FinanceTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'finance'
        }

    }

    render() {
        const { activeTab } = this.state;
        return (
            <LayoutConsumer>
                {({ clientUsers, groupUsers }) => (
                    <StageConsumer>
                        {({ updateEnquiry, handleLeadConvertShow }) => (
                            <PipelineConsumer>
                                {({ enquiry, contact, handleReactSelectChange, loading, allFinanceUsers, financeAllStatues,
                                    filesCategory, handleFileOpen, files, currencySymbol, accessToFinanceTab,
                                    financeProviders, handleFirebaseDateChange, clientsettings, enquiryOptionsDF,
                                    saveEnquiryField, cancelEnquiryField, onCurrencyChange, canModifyEnquiry,
                                    dynamicFinanceDetailList,
                                    handleDynamicCheckChange,
                                    handleDynamicOnChange,
                                    saveDynamicField,
                                    cancelDynamicField,
                                    onDynamicNumberChange,
                                    handleDynamicDateChange,
                                    handleDynamicSelectSave,
                                    handleDynamicMultiSelectChange,
                                    loaderCreditScore, handleRequestCreditScore, accessToRequestFinanceCreditScore, accessToViewFinanceCreditScore
                                }) => loading ? (<div><Translate text={'Loading...'} /></div>) : (
                                    <>
                                        <div className="mid-quickview-finance-info">

                                            <div className="quickview-column-finance-data form-style">
                                                {/* <h2>Finance Details</h2> */}

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'Business Manager'} /> </label>
                                                        <ReactSelect
                                                            options={allFinanceUsers}
                                                            name={"financeBM"}
                                                            placeholder={'select business manager'}
                                                            onChange={handleReactSelectChange}
                                                            value={enquiry?.financeBM ? enquiry.financeBM : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            //removeClearable={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'financeStatus'} /></label>
                                                        <ReactSelect
                                                            options={financeAllStatues}
                                                            name={"financeStatus"}
                                                            placeholder={'select finance status'}
                                                            onChange={handleReactSelectChange}
                                                            value={enquiry?.financeStatus ? enquiry.financeStatus : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            //removeClearable={true}
                                                            isSearchable={false}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    {
                                                        accessToFinanceTab && (<>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={"financeProvider"} /> </label>
                                                                <ReactSelect
                                                                    options={_.map(financeProviders, function (e) { return { ...e, label: e.name } })}
                                                                    name={"financeProvider"}
                                                                    placeholder={"select finance provider"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={enquiry?.financeProvider ? enquiry.financeProvider : null}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Start Date </label>
                                                                <AntDatePicker
                                                                    value={enquiry?.financeStartDate ? moment.unix(enquiry?.financeStartDate.seconds).format('YYYY-MM-DD') : null}
                                                                    name={'financeStartDate'}
                                                                    onChange={(e) => { handleFirebaseDateChange(e, 'financeStartDate') }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control`}
                                                                //disableContainer={true}
                                                                />

                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label><Translate text={"financeTerms"} /> </label>
                                                                <ReactSelect
                                                                    options={defaultFinanceTerms}
                                                                    name={"financeTerms"}
                                                                    placeholder={"finance terms"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={enquiry?.financeTerms ? enquiry.financeTerms : null}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    isSearchable={false}
                                                                >
                                                                </ReactSelect>
                                                            </div>

                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={"financeAmount"} /> </label>
                                                                <InputAutoNumber
                                                                    id="financeAmount_quick"
                                                                    name="financeAmount"
                                                                    allowNegative={false}
                                                                    thousandSeparator={true}
                                                                    allowLeadingZeros={false}
                                                                    prefix={currencySymbol}
                                                                    placeholder={currencySymbol}
                                                                    className={`form-control cursor-pointer`}
                                                                    onValueChange={onCurrencyChange.bind(this, 'financeAmount')}
                                                                    value={enquiry?.financeAmount ? enquiry.financeAmount : null}
                                                                    onSaveField={saveEnquiryField}
                                                                    onCancelField={cancelEnquiryField}
                                                                />

                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={"financeRate"} /> </label>
                                                                <InputAutoNumber
                                                                    id="financeRate_quick"
                                                                    name="financeRate"
                                                                    allowNegative={false}
                                                                    thousandSeparator={true}
                                                                    allowLeadingZeros={false}
                                                                    suffix={'%'}
                                                                    placeholder={'finance interest rate'}
                                                                    className={`form-control cursor-pointer`}
                                                                    onValueChange={onCurrencyChange.bind(this, 'financeRate')}
                                                                    value={enquiry?.financeRate ? enquiry.financeRate : null}
                                                                    onSaveField={saveEnquiryField}
                                                                    onCancelField={cancelEnquiryField}
                                                                />

                                                            </div>
                                                        </>)
                                                    }

                                                    {
                                                        dynamicFinanceDetailList && dynamicFinanceDetailList.map((info, index) => (
                                                            <EnquiryInputs
                                                                key={index}
                                                                type={info}
                                                                fields={enquiry}
                                                                errors={{}}
                                                                currencySymbol={currencySymbol}
                                                                dealersettings={this.props.dealersettings}
                                                                canModifyEnquiry={canModifyEnquiry}                                                                
                                                                enquiryOptionsDF={enquiryOptionsDF}
                                                                onCurrencyChange={onCurrencyChange}
                                                                handleDynamicCheckChange={handleDynamicCheckChange}
                                                                handleDynamicOnChange={handleDynamicOnChange}
                                                                saveDynamicField={saveDynamicField}
                                                                cancelDynamicField={cancelDynamicField}
                                                                onDynamicNumberChange={onDynamicNumberChange}
                                                                handleDynamicDateChange={handleDynamicDateChange}
                                                                handleDynamicSelectSave={handleDynamicSelectSave}
                                                                handleDynamicMultiSelectChange={handleDynamicMultiSelectChange}
                                                            ></EnquiryInputs>
                                                        ))

                                                    }
                                                </div>
                                            </div>

                                            {
                                                (filesCategory.filter(e => e.module === activeTab)[0]) && (
                                                    <div className="quickview-column-finance-documents">
                                                        <h2>Documents</h2>

                                                        <div className="quickview-column-finance-documents-inner">
                                                            <FileDocuments
                                                                filesCategory={filesCategory}
                                                                activeTab={activeTab}
                                                                handleFileOpen={handleFileOpen}
                                                                files={files}
                                                            />
                                                            {/* {
                                                                filesCategory.filter(e => e.module === activeTab).map((_info, index) => (
                                                                    <div key={index} className="float-left mr-2 mb-2">
                                                                        <div className="checkbox icheck-success">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`${activeTab}${_info.value}`}
                                                                                name={`${activeTab}${_info.value}`}
                                                                                className="uncheck-activity"
                                                                                checked={files.filter(e => e.category === _info.value && !e.isDeleted)[0] ? true : false}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked)
                                                                                        handleFileOpen(null, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                                                                    else
                                                                                        handleFileOpen(e, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                                                                }} />
                                                                            <label htmlFor={`${activeTab}${_info.value}`}><Translate text={_info.name} /></label>
                                                                        </div>
                                                                    </div>))
                                                            } */}


                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {
                                                accessToViewFinanceCreditScore && (<CreditScore
                                                    originType={this.props.originType}
                                                    accessToRequestFinanceCreditScore={accessToRequestFinanceCreditScore}
                                                    contact={contact}
                                                    handleRequestCreditScore={handleRequestCreditScore}
                                                    loaderCreditScore={loaderCreditScore}
                                                    dealersettings={clientsettings}
                                                    clientUsers={clientUsers}
                                                >
                                                </CreditScore>)}


                                        </div>
                                    </>
                                )}
                            </PipelineConsumer>
                        )}
                    </StageConsumer>
                )}
            </LayoutConsumer>

        );
    }
}

export default FinanceTab;