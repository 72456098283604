/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    ImageViewer, PopUpModal, ReactTimePicker, AntDatePicker
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import StockLoanList from './loanList'

import { stockBasicVM } from '../../stock/viewModel';
import { firestoreDB } from '../../../services/helper';

const emailList = [
    { active: true, value: 'Secondary', label: 'Secondary' },
    { active: true, value: 'Other', label: 'Other' },
]

export const objAppointment = {
    documentID: null,
    stockID: null,
    startDate: null,
    expectedReturn: null,
}

export default class AddLoanAppointment extends Component {
    constructor(props) {
        super(props);
        this.newDocID = null;
        this.isLoading = false;
        this.mandatoryFields = ['stockID', 'startDate', 'expectedReturn'];
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('loanAppointments');
        this.state = {
            fields: Object.assign({}, objAppointment),
            stock: Object.assign({}, stockBasicVM),
            errors: {},
            errorList: [],
            showStocks: false,
            imageViewer: { show: false, images: null }
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, appID, appointmentDate, appStock, appData } = props;
        let fields = Object.assign({}, objAppointment);
        if (show === true && appID && appData) {
            this.newDocID = appID;
            fields = Object.assign({}, appData);
            this.setState({ fields: fields, stock: Object.assign({}, appStock) });
        }
        else if (show === true) {
            this.newDocID = this.ref.doc().id;
            fields.documentID = this.newDocID;

            if (appointmentDate)
                fields.startDate = window.firebase.firestore.Timestamp.fromDate(moment(appointmentDate)._d);
            this.setState({ fields: fields });
        }
    }

    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;

        this.setState({ fields: state }, () => { });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = window.firebase.firestore.Timestamp.fromDate(moment(date)._d);

        this.setState({ fields: state }, () => { });

    }



    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { });
        }

    }



    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        const { serviceLoanStocks } = this.props;
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }


        if (fields.startDate && fields.expectedReturn && (moment.unix(fields.startDate.seconds)._d > moment.unix(fields.expectedReturn.seconds)._d)) {
            formIsValid = false;
            errors.expectedReturn = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'Expected return date should be greater than start time'), '', 'info')
        }

        let errorList = [];
        let _stock = serviceLoanStocks && serviceLoanStocks.filter(m => m.documentID === fields.stockID)[0]
        errorList = _stock.loanAppointments.filter(a => a.documentID !== fields.documentID &&
            ((a.startDate == null || a.startDate.seconds <= fields.expectedReturn.seconds) &&
                (a.expectedReturn == null || fields.startDate.seconds <= a.expectedReturn.seconds) &&
                (a.startDate == null || a.expectedReturn == null || a.startDate.seconds <= a.expectedReturn.seconds) &&
                (fields.startDate.seconds <= fields.expectedReturn.seconds))
        );

        if (!_.isEmpty(errorList))
            formIsValid = false;


        //console.log(_stock, errorList)

        this.setState({ errors: errors, errorList: errorList });

        if (formIsValid === true) {
            this.addAppointment();
        }
        else
            return formIsValid;


    };

    addAppointment = () => {
        this.isLoading = true;
        const { serviceID, appID, dealersettings } = this.props;
        const objData = Object.assign({}, this.state.fields);

        if (!appID) {
            objData.recordID = serviceID;
            objData.type = 'serviceLoan';
            objData.clientID = dealersettings ? dealersettings.client.id : '';
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        firestoreDB(dealersettings).firestore().doc(`loanAppointments/${objData.documentID}`).set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);

            firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}`).set({
                loanAppointmentID: objData.documentID,
                modifiedBy: dealersettings ? dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }, { merge: true });

            this.isLoading = false;
            toast.notify('Appointment saved successfully', {
                duration: 2000
            })

            this.handleModalClose(this.newDocID);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });




    };


    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };

    minStartDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    handleCloseLoanAppointment = (ID, objData) => {
        const { serviceLoanStocks } = this.props;

        if (ID && !_.isEmpty(objData)) {

            const fields = Object.assign({}, this.state.fields);

            const stock = Object.assign({}, stockBasicVM);
            for (let [key, value] of Object.entries(objData)) {
                if (stock.hasOwnProperty(key))
                    stock[key] = value;
            }

            fields.stockID = ID;

            let errorList = [];
            if (fields.startDate && fields.expectedReturn) {
                let _stock = serviceLoanStocks && serviceLoanStocks.filter(m => m.documentID === fields.stockID)[0]
                errorList = _stock.loanAppointments.filter(a =>
                    (_.isEmpty(a.startDate) || _.isEmpty(a.expectedReturn)) ||
                    ((_.isEmpty(a.startDate) || a.startDate.seconds <= fields.expectedReturn.seconds) &&
                        (_.isEmpty(a.expectedReturn) || fields.startDate.seconds <= a.expectedReturn.seconds) &&
                        (_.isEmpty(a.startDate) || _.isEmpty(a.expectedReturn) || a.startDate.seconds <= a.expectedReturn.seconds) &&
                        (fields.startDate.seconds <= fields.expectedReturn.seconds))
                );
            }

            this.setState({ stock: stock, fields: fields, errorList: errorList }, () => { this.errorChange('stockID') });
        }
        else if (ID) {
            const refData = window.firebase.firestore().collection('stock').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());
                    const stock = Object.assign({}, stockBasicVM);
                    for (let [key, value] of Object.entries(objStockData)) {
                        if (stock.hasOwnProperty(key))
                            stock[key] = value;
                    }
                    stock.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.stockID = doc.id;

                    let errorList = [];
                    if (fields.startDate && fields.expectedReturn) {
                        let _stock = serviceLoanStocks && serviceLoanStocks.filter(m => m.documentID === fields.stockID)[0]
                        errorList = _stock.loanAppointments.filter(a =>
                            (_.isEmpty(a.startDate) || _.isEmpty(a.expectedReturn)) ||
                            ((_.isEmpty(a.startDate) || a.startDate.seconds <= fields.expectedReturn.seconds) &&
                                (_.isEmpty(a.expectedReturn) || fields.startDate.seconds <= a.expectedReturn.seconds) &&
                                (_.isEmpty(a.startDate) || _.isEmpty(a.expectedReturn) || a.startDate.seconds <= a.expectedReturn.seconds) &&
                                (fields.startDate.seconds <= fields.expectedReturn.seconds))
                        );
                    }

                    this.setState({ stock: stock, fields: fields, errorList: errorList }, () => { this.errorChange('stockID') });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({ showStocks: false });
    }

    handleOpenLoanAppointment = () => {
        this.setState({ showStocks: true });
    }

    render() {
        const { expectedReturn, startDate, stockID, documentID } = this.state.fields;


        const { showStocks, errorList, stock } = this.state;
        const { show, dealersettings, serviceLoanStocks } = this.props;


        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'Service Loan Appointment'} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">



                                    <div className="testdrive-search mb-2" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            showStocks: true
                                        });
                                    }}>
                                        <input placeholder="Select Vehicle" aria-label="Search" readOnly className={this.state.errors["stockID"]} />
                                    </div>

                                    {
                                        (stockID && (!_.isEmpty(stock)))
                                            ?
                                            (
                                                <div className="vehicle-item flex-vehicle mt-2 add-testdrive-list">

                                                    <div className="vehicle-item-image">
                                                        {
                                                            (!_.isEmpty(stock.images))
                                                                ?
                                                                <a data-fancy-box={`testdrive_img${documentID}`}
                                                                    href={stock.images[0]}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: stock.images
                                                                            }
                                                                        });
                                                                    }}>
                                                                    <img src={stock.images[0]} width="70" height="70" alt="" className="img-object-fit" />
                                                                </a>
                                                                :
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                    <img src={CommonHelper.showBrandLogo(dealersettings, stock.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                                </a>
                                                        }
                                                    </div>
                                                    <div className="vehicle-data">
                                                        <div className="vehicle-item-title">{stock.make} {stock.model}</div>
                                                        <div className="vehicle-item-info">{stock.extColor ? `${stock.extColor}, ` : ''} {stock.year ? stock.year : ''}</div>
                                                        <div className="vehicle-item-info">{'Reg #:'}{stock.regNo ? `${stock.regNo}, ` : '--'} {'Stock #:'}{stock.stockNo ? ` ${stock.stockNo}` : '--'} </div>
                                                        {
                                                            stock.clientID && (stock.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (<div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, stock.clientID)}</strong></div>) : (<></>)
                                                        }
                                                        <div className="vehicle-item-status">
                                                            {stock.saleType ? <div className="badge badge-pill badge-white">
                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                    stock.saleType, '')}
                                                            </div> : <></>}
                                                            {stock.status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stock.status, '', 'ml-1') : <></>}

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(errorList)
                                            ?
                                            <>
                                                <div className="service-loan-yellow-alert">
                                                    <div className="alert alert-warning" role="alert">
                                                        <div className="service-alert-flex">
                                                            <div className="service-alert-flex-icon"> <img src={_images.calendarCheckBooked} alt="" height="25" /> </div>
                                                            <div className="service-alert-flex-data">
                                                                <h4><Translate text={'Vehicle is Booked'} /></h4>
                                                                <p><Translate text={'This vehicle is not available at'} />:</p>
                                                                {
                                                                    errorList.map((data, index) => {
                                                                        return <div key={index}>
                                                                            {data.startDate ? moment.unix(data.startDate.seconds).format('DD MMM YYYY h:mm A') : '~'} -
                                                                            {data.expectedReturn ? moment.unix(data.expectedReturn.seconds).format('DD MMM YYYY h:mm A') : '~'}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            :
                                            <></>
                                    }


                                    <div className="form-row">

                                        <div className="form-group col-md-8">
                                            <label ><Translate text={'startDate'} /></label>
                                            <AntDatePicker
                                                value={startDate ? moment.unix(startDate.seconds).format('YYYY-MM-DD') : null}
                                                name={'startDate'}
                                                onChange={(e) => { this.handleDateChange(e, 'startDate') }}
                                                format='DD/MM/YYYY'
                                                placeholder='DD/MM/YYYY'
                                                className={`form-control ${this.state.errors["startDate"]}`}
                                                disabledDate={this.minStartDate}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label ><Translate text={'Time'} /></label>
                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>

                                                <ReactTimePicker
                                                    value={startDate ? moment.unix(startDate.seconds)._d : null}
                                                    name={'startDate'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["startDate"]}`}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-row">

                                        <div className="form-group col-md-8">
                                            <label ><Translate text={'expectedReturnDate'} /></label>
                                            <AntDatePicker
                                                value={expectedReturn ? moment.unix(expectedReturn.seconds).format('YYYY-MM-DD') : null}
                                                name={'expectedReturn'}
                                                onChange={(e) => { this.handleDateChange(e, 'expectedReturn') }}
                                                format='DD/MM/YYYY'
                                                placeholder='DD/MM/YYYY'
                                                className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                disabledDate={this.minStartDate}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label ><Translate text={'Time'} /></label>
                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>

                                                <ReactTimePicker
                                                    value={expectedReturn ? moment.unix(expectedReturn.seconds)._d : null}
                                                    //value={(startDate && typeof startDate === 'object' && Object.keys(startDate).length > 0) ? moment.unix(startDate.seconds)._d : null}
                                                    name={'expectedReturn'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'confirm'} />
                        </button>

                    </Modal.Footer>
                </Modal>

                <PopUpModal show={showStocks}>
                    <StockLoanList
                        show={showStocks}
                        serviceLoanStocks={serviceLoanStocks}
                        handleClose={this.handleCloseLoanAppointment}
                        dealersettings={dealersettings}
                    >
                    </StockLoanList>
                </PopUpModal>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>
        );
    }
}
