/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { ImageViewer, PopUpModal, ImageCropHook } from '../../../components';
import { default as _images } from '../../../images';
/** VIEW-MODELS */
import { objOdometerHistory } from '../viewModel'
import { firestoreDB, storageBucket } from "../../../services/helper";


class AddHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            imageURL: null,
            mandatoryfields: ['odometer', 'images'],
            previousOdometer: null,
            imageViewer: { show: false, images: null }
        }

    }

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropInsShow) => {

        const { src, id, ext } = cropInsShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            imageURL: src,
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            //var extension = file.type
            reader.onloadend = () => {

                this.setState({
                    cropInsShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        this.setState({ fields: state, imageURL: null })
                    })
            })
    };

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }


    //#endregion



    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) && this.state.mandatoryfields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    componentDidMount() {
        const { selectedData, historys, currentOdometer } = this.props;
        const fields = Object.assign({}, objOdometerHistory);
        if (!_.isEmpty(selectedData)) {
            const objData = Object.assign({}, selectedData);
            for (let [key, value] of Object.entries(objData)) {
                fields[key] = value;
            }
        }
        this.setState({ fields: fields });

        if (!_.isEmpty(historys)) {
            let _historys = Object.assign([], (!_.isEmpty(selectedData) ? historys.filter(e => e.documentID !== selectedData.documentID) : historys));
            if (!_.isEmpty(_historys)) {
                let odo = _.orderBy(_historys, ['odometer'], ['desc'])[0];
                this.setState({ previousOdometer: odo.odometer })
            }
            else if (currentOdometer) {
                this.setState({ previousOdometer: currentOdometer })
            }
        } else if (currentOdometer) {
            this.setState({ previousOdometer: currentOdometer })
        }

    }

    componentWillUnmount() {
        this.setState({
            fields: {},
            previousOdometer: null
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        const { previousOdometer } = this.state;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) && this.state.mandatoryfields.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }

        if (fields.odometer && previousOdometer && parseInt(previousOdometer) > parseInt(fields.odometer)) {
            formIsValid = false;
            errors.odometer = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'current odometer reading should be equal or greater than the previous odometer reading'), '', 'info')
        }

        this.setState({ errors: errors });
        if (formIsValid === true) {
            const objData = Object.assign({}, fields);
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === 0 || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            const { selectedData, dealersettings } = this.props;
            if (_.isEmpty(selectedData)) {
                objData.documentID = firestoreDB(dealersettings).firestore().collection('fleet').doc().id
                objData.addedBy = dealersettings ? dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                this.props.handleClose(objData, false)
            }
            else {
                objData.modifiedBy = dealersettings ? dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                this.props.handleClose(objData, true)
            }
        }
        else
            return formIsValid;
    };

    render() {
        const { odometer, images } = this.state.fields;
        const { show, selectedData } = this.props;

        return (
            <>

                <Modal
                    id="add-fieldser"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-odo-pop">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate text={_.isEmpty(selectedData) ? 'Add Odometer' : 'Edit Odometer'} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">

                                <div className="inspection-marker-wrap">
                                    <div className={`marker-image-wraper ${(images && images.length === 0 && !this.state.imageURL) ? 'inspection-none-image' : ''}`}>


                                        <div className={`marker-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                            <label htmlFor="images2">
                                                <img src={_images.addvehicle} alt="" htmlFor="images2" />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="images2"
                                                    id="images2"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => this.onSelectFile(e, 'inspection', 'images', 'Trade-In Inspection')} />
                                            </label>
                                        </div>


                                        {images && images.map((data, index) => (
                                            <div className="marker-item-image" key={index}>

                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleImageDel(index);
                                                }}>
                                                    <i className="ico icon-delete"></i>
                                                </div>


                                                <a data-fancy-box={`mark_img`}
                                                    href={data}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            imageViewer: {
                                                                show: true,
                                                                images: images,
                                                                index
                                                            }
                                                        });
                                                    }}>
                                                    <img src={data} width="70" height="70" alt="" />
                                                </a>
                                            </div>
                                        ))}
                                        {
                                            (this.state.imageURL) ?
                                                (
                                                    <div className="marker-item-image">
                                                        <div className="img-loader">
                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                            <img src={this.state.imageURL} width="70" height="70" alt="" />
                                                        </a>
                                                    </div>
                                                )
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>

                                <div className={`marker-form form-style`}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            {
                                                this.state.previousOdometer
                                                    ?
                                                    <><label className={'w-100 mb-2'}><Translate text={'Previous Odometer Reading'} />:
                                                        <strong><NumberFormat value={this.state.previousOdometer} displayType={'text'} thousandSeparator={true} /></strong>
                                                    </label></>
                                                    :
                                                    <></>
                                            }

                                            <label><Translate text={'currentOdometerReading'} /></label>
                                            <NumberFormat
                                                id={'odometer'}
                                                thousandSeparator={true}
                                                placeholder={'current odometer reading'}
                                                className={`form-control ${this.state.errors["odometer"]}`}
                                                onValueChange={this.onCurrencyChange.bind(this, 'odometer')}
                                                value={odometer ? odometer : null}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.props.handleClose()}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right ${(this.state.imageURL) ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)}>
                            <Translate text={'save'} />
                        </button>
                    </Modal.Footer>


                </Modal>

                <PopUpModal show={this.state.cropInsShow.show}>
                    <ImageCropHook
                        cropShow={this.state.cropInsShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
            </>


        );
    }
}

export default AddHistory;