/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash';
import { templates } from './viewmodel';
import Templates from './templates';
import { ContentOverlay } from '../../../../components';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';


const GenericTemplates = (props) => {
    const [state, setState] = useState({});
    const [tabLoader, setTabLoader] = useState(true);
    const [loader, setLoader] = useState();
    const [key, setKey] = useState(templates[0].value)

    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/${_.find(templates, { value: key }).collection}/${key}`)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.exists) {
                    setState(querySnapshot.data());
                }
                else {
                    setState({})
                }
                setTabLoader(false)
            });
    }, [key])


    const saveTemplate = (obj) => {
        setLoader(true);
        setState(obj);
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/${_.find(templates, { value: key }).collection}/${key}`).set({
            ...obj,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Template updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }


    return (
        <>
            <h2> <Translate text={'Templates'} /> </h2>
            <div className="divider-line"></div>
            <div className="settings-column-wraper w-100">
                <div className="settings-columns">
                    <div className="settings-column-left" style={{ width: `300px` }}>
                        <div className="settings-column-nav">
                            <ul>
                                {
                                    templates.map((rec, index) => {
                                        return <li key={index} className={`${rec.value === key ? 'active' : ''}`}>
                                            <a href="#" className="column-list-left"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setTabLoader(true)
                                                    setState({});
                                                    setKey(rec.value);
                                                }}
                                            >{CommonHelper.showLocale(props, rec.name)}
                                            </a>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="settings-column-right-large">
                        <div className="settings-inner pl-3 pr-3">
                            {
                                tabLoader ? (<div className="mt-5" style={{ height: '200px' }}><ContentOverlay active={true} /></div>) :
                                    <Templates
                                        {...props}
                                        activeTab={key}
                                        data={state}
                                        saveTemplate={saveTemplate}
                                        loader={loader}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenericTemplates;