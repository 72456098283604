import React from 'react'
//if any lookup value add or update.please do update in oemsetup/viewmodel
export const tradeinProLookups = [
    {
        name: 'Car History URL',
        value: 'carHistory'
    },
    {
        name: 'Lead Origins',
        value: 'origins'
    },,
    {
        name: 'Lost Reasons',
        value: 'lostReasons'
    },
    {
        name: 'Appraisal Status',
        value: 'tradeinProStatus'
    },
    {
        name: 'Enquiry Options',
        value: 'enquiryOptionsDF'
    },
    {
        name: 'Purchase Agreement Checklist ',
        value: 'purcAgrmtChecklist'
    },
    {
        name: 'Purchase Agreement Business',
        value: 'purcAgrmtBusiness'
    },
    {
        name: 'Purchase Agreement Intake',
        value: 'purcAgrmtIntake'
    },
    {
        name: 'Tradein Options',
        value: 'tradeinOptionsDF'
    }
]

export const lookupVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    color: '',
    dmsKeys: [],
    price: null,
    total: null,
    from: null,
    to: '',
    isExpress: null,
    url: null,
    default: null,
}

export const lookupSublistVM = {
    name: '',
    value: '',
    level: '',
    price: '',
    description: '',
    active: true,
    subList: [],
    dmsKeys: []
}

export const lookupDFVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    type: '',
    dmsKeys: []
}

export const markDownLevelsVM = {
    name: '',
    value: '',
    code: '',
    active: true,
    level: '',
    dmsKeys: [],
}

export const servicePartsVM = {
    name: "",
    partNumber: "",
    partCost: 0,
    value: '',
    active: true,
    level: '',
    dmsKeys: [],
}

export const recommendationVM = {
    title: '',
    name: '',
    type: '',
    category: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    items: [],
    dmsKeys: []
}


export const tblFieldsLookup = [
    {
        name: 'Name',
        value: 'strName',
        sortValue: 'strName',
        subText: 'strPrice',
        flex: 1,
        default: true
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',
        sortValue: 'levelName',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelValue',
        sortValue: 'levelValue',
        flex: 0,
        width: 120,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const tblFieldsLookupRecommendation = [
    {
        name: 'Name',
        value: 'strName',
        subText: 'strPrice',
        sortValue: 'strName',
        flex: 1,
        default: true
    },
    {
        name: 'Category',
        value: 'recommendationCategory',
        sortValue: 'recommendationCategory',
        flex: 1,
        default: true
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',
        sortValue: 'levelName',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelValue',
        sortValue: 'levelValue',
        flex: 0,
        width: 120,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

export const recommendationTypes = [
    { value: 'bbt', label: 'Battery, Brake & Tyre', name: 'Battery, Brake & Tyre', index: 1 },
    { value: 'checklist', label: 'Check List', name: 'Check List', index: 2 },
]

export const recommendationCategories = [
    { value: 'Tyre', label: 'Tyre', index: 1 },
    { value: 'Battery', label: 'Battery', index: 2 },
    { value: 'Brake', label: 'Brake', index: 3 },
]


export const fieldTypes = [
    { value: 'text', label: (<><i className="ico icon-dynamic-textfield mr-1"></i> Text Field</>) },
    { value: 'number', label: (<><i className="ico icon-dynamic-numberfield mr-1"></i> Number Field</>) },
    { value: 'price', label: (<><i className="ico icon-dynamic-pricefield-fix mr-1"></i> Price Field</>) },
    { value: 'toggle', label: (<><i className="ico icon-dynamic-switch mr-1"></i> Switch</>) },
    { value: 'date', label: (<><i className="ico icon-dynamic-datefield mr-1"></i> Date Field</>) },
    { value: 'select', label: (<><i className="ico icon-dynamic-radio mr-1"></i> Single Select</>) },
    { value: 'multiselect', label: (<><i className="ico icon-dynamic-checkbox mr-1"></i> Multi Select</>) }
]