import React, { useEffect, useState, useReducer, useContext } from 'react'
import _ from 'lodash'
import { clientsVM, clientlistFields, cloneClientsVM, SubClientsVM } from './viewModel'
import { ContentOverlay, TableView, PopUpModal, ReactSelect } from '../../../../components'
import AddClient from './addClient'
import { ClientContext } from '../../../layout/clientContext'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

const ClientListReducer = (state, action) => {
  function updateClient() {
    return state.map((item, index) => {
      if (item.documentID === action.data.documentID) {
        return action.data;
      }
      return item;
    });
  }
  switch (action.type) {
    case "SUCCESS": {
      return action.data
    }
    case "APPEND_LIST": {
      return [...state, ..._.differenceBy(action.data, state, 'documentID')]
    }
    case "ADD_LIST": {
      return [action.data, ...state, action.data]
    }
    case "REMOVE_LIST": {
      return state.filter(item => item.documentID !== action.data.documentID)
    }
    case "UPDATE_LIST": {
      return updateClient()
    }
    default:
      return state;
  }
};

const Clients = (props) => {
  const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 195) })
  const [clients, dispatch] = useReducer(ClientListReducer, [])
  const [checkDataLoad, setDataload] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(true)
  const [isPaging, setPaging] = useState(false)
  const [hasMore, setHasMoreData] = useState([])
  const [showclientModel, setShowClientModel] = useState({ show: false })
  const [popHeader, setPopHeader] = useState('')
  const [selectedClient, setSelectedclient] = useState(null)
  const pageLimit = 10

  const [selectedOEM, setSelectedOEM] = useState('')
  const [oemOptions, setOEMOptions] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [clientRegions, setClientRegions] = useState([]);
  const [clientSubRegions, setClientSubRegions] = useState([]);
  const [filter, setFilter] = useState({})

  const { regionLookups, countryLookups, oemSettings } = useContext(ClientContext);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
        windowHeight: (window.innerHeight - 195)
      })
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubAdminClientList && window.unSubAdminClientList();
    }
  }, [])

  useEffect(() => {
    if (_.isEmpty(oemSettings))
      return

    let _oemSettings = [];
    oemSettings.forEach((rec) => {
      _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
    })
    setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
    setSelectedOEM(_oemSettings[0])
  }, [])

  useEffect(() => {
    if (_.isEmpty(selectedOEM))
      return

    var regionCollectionzz = combineLatest(
      collection(window.firebase.firestore().collection(`clientSettings/${selectedOEM.value}/groups`).orderBy('addedDate', 'asc')),
      collection(window.firebase.firestore().collection(`clientSettings/${selectedOEM.value}/regions`).orderBy('name', 'asc')),
      collection(window.firebase.firestore().collection(`clientSettings/${selectedOEM.value}/subregions`).orderBy('name', 'asc'))
    ).pipe(
      map(([groups, regions, subregions]) => {
        return [
          _.map(groups, function (obj) { return { ...obj.data(), documentID: obj.id, id: obj.id, label: obj.data().name, value: obj.id, data: obj.data() } }),
          _.map(regions, function (obj) { return { ...obj.data(), documentID: obj.id, id: obj.id, label: obj.data().name, value: obj.id, data: obj.data() } }),
          _.map(subregions, function (obj) { return { ...obj.data(), documentID: obj.id, id: obj.id, label: obj.data().name, value: obj.id, data: obj.data() } })
        ]
      }))
      .subscribe(([groups, regions, subregions]) => {
        setClientGroups(groups)
        setClientRegions(regions)
        setClientSubRegions(subregions)
        setDataload(true)
      });
    return () => {
      regionCollectionzz && regionCollectionzz.unsubscribe();
    }
  }, [selectedOEM])

  useEffect(() => {
    if (!checkDataLoad || _.isEmpty(selectedOEM)) {
      return;
    }

    let clientDataRef = window.firebase.firestore().collection('clients')
      .where('settingsID', '==', selectedOEM.value);

    // if (searchText && searchText.trim()) {
    //   clientDataRef = clientDataRef
    //     .where('keywords', 'array-contains', searchText.toLowerCase().trim())
    // }
    // clientDataRef = clientDataRef
    //   .orderBy('addedDate', 'desc')

    // if (hasMore.length > 0) {
    //   clientDataRef = clientDataRef
    //     .startAfter(hasMore[0])
    //     .limit(pageLimit)
    // }
    // else {
    //   clientDataRef = clientDataRef
    //     .limit(pageLimit)
    // }

    window.unSubAdminClientList = clientDataRef
      .onSnapshot(onCollectionUpdate);

  }, [checkDataLoad, selectedOEM])

  const onCollectionUpdate = (querySnapshot) => {
    let actionType;
    let snapshotDoc;
    querySnapshot.docChanges().forEach(change => {
      if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
      {
        snapshotDoc = convertVM({
          ...change.doc.data(),
          documentID: change.doc.id,
          sortNo: change.doc.data().sortOrder && Number(change.doc.data().sortOrder) > 0 ? Number(change.doc.data().sortOrder) : null
        });
        if (change.type === 'added') {
          actionType = "ADD_LIST";
        }
        else if (change.type === 'modified') {
          actionType = "UPDATE_LIST"
        }
        else if (change.type === 'removed') {
          actionType = "REMOVE_LIST"
        }
      }
    })
    const contacts = [];
    if (!actionType) {
      if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
        setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
      }
      else {
        setHasMoreData([]);
      }
      let i = 0;
      querySnapshot.forEach((doc) => {
        i++;
        contacts.push(convertVM({
          ...doc.data(),
          documentID: doc.id,
          sortNo: doc.data().sortOrder && Number(doc.data().sortOrder) > 0 ? Number(doc.data().sortOrder) : i
        }));
      });
    }

    dispatch({
      type: actionType ? actionType : "SUCCESS",
      data: actionType ? snapshotDoc : contacts
    });


    setDataload(false)
    setLoader(false)
    setPaging(false)
  }

  const convertVM = (doc) => {
    const objclientData = Object.assign({}, doc);
    const listVM = Object.assign({}, clientsVM);
    for (let [key, value] of Object.entries(objclientData)) {
      if (listVM.hasOwnProperty(key))
        listVM[key] = value;
    }
    listVM.countryName = _.find(countryLookups, { 'code': listVM.country }) ? _.find(countryLookups, { 'code': listVM.country }).name : ''
    listVM.regionName = _.find(clientRegions, { 'value': listVM.region }) ? _.find(clientRegions, { 'value': listVM.region }).name : ''
    listVM.groupName = _.find(clientGroups, { 'value': listVM.group }) ? _.find(clientGroups, { 'value': listVM.group }).name : ''
    listVM.oem = _.find(oemSettings, { 'id': listVM.settingsID }) ? _.find(oemSettings, { 'id': listVM.settingsID }).name : ''
    listVM.sortNo = doc.sortNo;

    return listVM
  }

  const hadleEditClient = (id) => {
    const editClient = clients.find(item => item.documentID === id);
    const clientVM = Object.assign({}, clientsVM);
    for (let [key, value] of Object.entries(editClient)) {
      if (clientVM[key] !== undefined)
        clientVM[key] = value;
    }
    setSelectedclient(clientVM)
    setPopHeader('Edit Client')
    setShowClientModel({ show: true })
  }

  const handleCloneForm = async (id) => {
    const clientDoc = await window.firebase.firestore().doc(`clients/${id}`).get().then((doc) => { if (doc.exists) return doc.data(); else return null; });
    const roster = await window.firebase.firestore().doc(`clients/${id}/roster/${id}`).get().then((doc) => { if (doc.exists) return doc.data(); else return null; });
    const clientVM = _.pick(clientDoc, _.keys(cloneClientsVM));
    clientVM.name = clientVM.name + ' (Copy)';
    clientVM.documentID = window.firebase.firestore().collection('clients').doc().id;
    clientVM.addedBy = localStorage.uid;
    clientVM.modifiedBy = localStorage.uid;
    clientVM.addedDate = window.firebase.firestore.Timestamp.now();
    clientVM.modifiedDate = window.firebase.firestore.Timestamp.now();
    if (clientVM.clientSettings) {
      clientVM.clientSettings.awsses = null;
      clientVM.clientSettings.emailleads = null;
      clientVM.clientSettings.stockLocations = null;
      clientVM.clientSettings.stockUpdate = null;
      clientVM.clientSettings.smtpFrom = null;
      clientVM.clientSettings.smtpUri = null;
      clientVM.clientSettings.smtpVerified = null;
      clientVM.clientSettings.tdStockLocations = null;
      clientVM.clientSettings.serviceClients = null;
    }
    if (clientVM.moduleSettings) {
      clientVM.moduleSettings.mailjet = null;
    }
    //cleint settngs
    //const clientSettingsVM = _.pick(clientSub, _.keys(SubClientsVM));
    setSelectedclient(clientVM)
    setPopHeader('Add Client')
    setShowClientModel({ show: true, roster: roster, newClient: true })
  }

  const handleActionClick = (id) => {
    return (<>
      <div
        title='Clone template'
        style={{ cursor: 'pointer' }}
        className='mini-button float-left mr-2'
        onClick={e => {
          handleCloneForm(id);
        }}
      >
        <i className='ico icon-rearrange'></i>
      </div>
      <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
        hadleEditClient(id)
      }}>
        <i className="ico icon-edit"></i>
      </div>
    </>)
  }

  const handleClientdetailsClick = (e, id) => {
    e.preventDefault();
    hadleEditClient(id)
  }

  const handlePagination = () => {
    setPaging(true)
    setDataload(true)
  }


  const handleModelClose = () => {
    setSelectedclient(null)
    setShowClientModel({})
  }

  const handleAddClient = () => {
    setSelectedclient(null)
    setPopHeader('Add Client')
    setShowClientModel({ show: true })
  }

  const handleOEMChange = (e, data) => {
    setLoader(true)
    setDataload(false)
    setFilter({})
    setSelectedOEM(e)
  }

  return (
    <>
      <h2>Client Management</h2>
      <div className="divider-line"></div>
      <div className="mt-2">
        <div className="settings-head form-style">
          <div className="row">
            <div className="col-md-10">
              <div className="form-row">
                <div className="col-3">
                  <div className="form-group">
                    <ReactSelect
                      options={oemOptions}
                      name={"oem"}
                      placeholder={'select oem'}
                      onChange={handleOEMChange}
                      value={!_.isEmpty(selectedOEM) ? selectedOEM.value : ''}
                      classNamePrefix={`cursor-pointer basic-select`}
                      removeClearable={true}
                    >
                    </ReactSelect>
                  </div>
                </div>
                <div className="col-3">
                  <ReactSelect
                    options={clientRegions}
                    name={"region"}
                    placeholder={'select region'}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        region: e ? e.value : null
                      })
                    }}
                    value={!_.isEmpty(filter) ? filter.region : ''}
                    classNamePrefix={`cursor-pointer basic-select`}
                    removeClearable={true}
                  >
                  </ReactSelect>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="float-right">
                <button type="button" className="btn btn-primary" onClick={() => {
                  handleAddClient()
                }}> <i className="ico icon-add"></i> Add Client</button>              </div>
            </div>
          </div>
        </div>
        {
          loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
            clients.length > 0 ? (
              <div className="common-table">
                <TableView
                  datalist={_.orderBy(clients, ['sortNo'], ['asc']).filter(({ name, address, phone, email, region }) =>
                    (!_.isEmpty(searchText) ? (
                      (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                      (!_.isEmpty(address) ? address.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                      (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                      (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                    ) : true) &&
                    (!_.isEmpty(filter.region) ? region === filter.region : true))
                  }
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={clientlistFields}
                  dynamicFields={clientlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleClientdetailsClick}
                  isSettings={false}
                  hasMore={hasMore}
                  //handlePagination={handlePagination}
                  //isPaging={true}
                  isReorderable={false}
                />
              </div>
            ) : (
              <div className="common-table">
                <div className="text-center p-5">
                  <p>No clients found.</p>
                  <button className="btn btn-sm btn-default" onClick={() => { handleAddClient(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                    Add Client
                  </button>
                </div>
              </div>
            )
        }
      </div>
      <PopUpModal show={showclientModel.show}>
        <AddClient
          {...showclientModel}
          title={popHeader}
          handleClose={handleModelClose}
          client={selectedClient}
          selectedOEM={selectedOEM}
          groups={clientGroups}
          regions={clientRegions}
          subregions={clientSubRegions}
        />
      </PopUpModal>
    </>
  )
}

export default Clients