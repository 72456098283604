import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _, { isEmpty } from 'lodash'
import { Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import { MainContext } from '../../layout/provider';
import { truncate } from 'lodash';
import Translate from '../../../constants/translate';

const EmailList = ({
	messages,
	selectedMessage,
	setSelectedMessage,
	filterThreads,
	selectThreads,
	threadsSelected,
	searchText,
	setSearchText,
	updateMessage,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	type,
	searchThreads,
	refreshThreads,
	threadsLoader,
	isLimitReached,
	handleMarkAs,
	setThreadsSelected,
	setFilteredMessages,
	filteredMessages,
}) => {
	const [filter, setFilter] = useState(null);
	const { dealersettings, nylas_accountData } = useContext(MainContext);
	const nylasEmail = dealersettings.nylas && dealersettings.nylas.email_address ? dealersettings.nylas.email_address : dealersettings.email
	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--emailListItemWrapHeight',
				window.innerHeight - 170 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--emailListItemWrapHeight',
			window.innerHeight - 170 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// useEffect(() => {
	// 	if (messages.length <= 0) {
	// 		setSelectedMessage({})
	// 	}
	// }, [selectedMessage])

	const getNameFromParticipants = (participants, email) => {
		const filteredParticipants = participants.filter(
			participant => participant.email !== email,
		);

		if (filteredParticipants.length === 0) {
			if (participants && participants.length > 0 && participants[0].name) {
				return participants[0].name ? participants[0].name : '';
			}
		}
		if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0].name) {
			return filteredParticipants[0].name ? filteredParticipants[0].name : '';
		}
	};

	const handleFilter = type => {
		setFilter(type);
		filterThreads(type);
	};

	const handleSelectThreads = type => {
		selectThreads(
			type,
			null,
			filteredMessages,
			setFilteredMessages,
			setThreadsSelected,
		);
	};

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			if (searchText.length > 0) {
				searchThreads(searchText);
			}
		}
	};

	useEffect(() => {
		if (!threadsLoader) {
			let search = window.location.search;
			let params = new URLSearchParams(search);
			let threadid = params.get('threadid');
			if (document.getElementById(`list-${threadid}`))
				document.getElementById(`list-${threadid}`).click();
		}
	}, [threadsLoader])

	return (
		<div className='email-list-left'>
			{
				nylas_accountData?.syncLog?.syncing ? (<div className="alert alert-primary" role="alert">
					<span className="spinner-border spinner-button mr-2 mt-1" role="status" aria-hidden="true"></span>
					<Translate text={'We are syncing your email account with AMS Pro'} />
				</div>) : nylas_accountData?.syncLog?.success === false && nylas_accountData?.account_id ? (
					<div className="mfa-red mt-2">
						<div className="float-left mt-1">
							<i className="ico icon-settings-security"></i>Sync Failed </div>
						<div className="float-right ">
							<button onClick={() => {
								window.firebase.firestore().doc(`nylas-accounts/${nylas_accountData.account_id}`).update({
									syncLog: {
										syncing: true
									}
								})
								// const syncThreads = window.firebase.functions().httpsCallable('nylassync-syncThreads');
								// syncThreads({ account_id: nylas_accountData.account_id })
							}} type="button" className="btn btn-default float-left ml-2 mfa-enable-btn">Sync</button>
						</div>
					</div>
				) : (<></>)
			}
			<div className='email-list-filter'>
				<Dropdown
					className='filter-pipeline-btn email-muti-check float-left dropdown-toggle'
					alignRight
				>
					{/* Add float right and d-inline by myself */}
					<div className='checkbox icheck-success muticheck-fix'>
						<input type='checkbox' id='successaa' checked={threadsSelected} onChange={() =>
							handleSelectThreads(threadsSelected ? 'none' : 'all')
						} />
						<label
							htmlFor='successaa'
						></label>
					</div>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('all')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('none')}
						>
							<div className='pipeline-check'> </div>
							None
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unstarred')}
						>
							<div className='pipeline-check'> </div>
							Unstarred
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* <div className='filter-email-btn ml-2'>
					<a href='#'>
						<i className='ico icon-filter'></i>
						<span>Filter </span>
						<i className='ico icon-dropdown'></i>
					</a>
				</div> */}
				<Dropdown
					className='filter-pipeline-btn  ml-2 float-left dropdown-toggle custom-filter-dropdown'
					alignRight
				>
					<Dropdown.Toggle
						as={CustomToggle}
						id='dropdown-custom-components'
						className={
							filter === 'read' || filter === 'unread' || filter === 'starred' ? 'filter-active' : ''
						}
					>
						<i className='ico icon-filter'></i> <span>{`${filter === 'read' ? 'Read' : filter === 'unread' ? 'Unread' : filter === 'starred' ? "Starred" : 'All'}`}</span>
						<i className='ico icon-dropdown'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className={`current-pipeline ${(filter === 'clear' || _.isEmpty(filter)) ? 'active' : ''}`}
							onClick={() => handleFilter('clear')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'read' ? 'active' : ''
								}`}
							onClick={() => handleFilter('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'unread' ? 'active' : ''
								}`}
							onClick={() => handleFilter('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleFilter('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				{/* @info: Mark Dropdown */}
				<Dropdown
					className='filter-pipeline-btn  ml-2 float-left dropdown-toggle custom-mark-dropdown'
					alignRight
				>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i> <span>Mark</span>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('read', setThreadsSelected)}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('unread', setThreadsSelected)}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<div className='mail-refresh float-right'>
					<a href='#' onClick={() => refreshThreads(type)}>
						<i className='ico icon-refresh custom-spin'></i>
					</a>
				</div>
			</div>
			<div className='email-list-search'>
				<div className='input-group '>
					<input
						type='text'
						className='form-control'
						placeholder='Search'
						value={searchText}
						onChange={e => {
							setSearchText(e.target.value);
						}}
						onKeyPress={handleKeyPress}
					/>
					<div className='input-group-append input-search-clear'>
						{searchText.length > 0 && (
							<a
								href='#'
								className='input-search-clear-icon'
								onClick={() => {
									if (searchText.length > 0) {
										setSearchText('');
										refreshThreads(type);
									}
								}}
							>
								<i className='ico icon-remove'></i>
							</a>
						)}
						<button
							className='btn btn-default'
							type='button'
							onClick={e => {
								searchThreads(searchText);
							}}
						>
							{' '}
							<i className='ico icon-search-fill'></i>{' '}
						</button>
					</div>
				</div>
			</div>

			{threadsLoader || refreshLoader ? (
				<div
					style={{
						minHeight: '80vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div className='spinner-loader pt-5 h-100'>
						<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
							<div role='status' className='spinner-border loader-primary'></div>
							<p className='text-muted mt-3'></p>
						</div>
					</div>
				</div>
			) : (
				<div className='email-list-item-wrap'>
					{messages && messages.length > 0 ? (
						messages.map(message => (
							<div className='position-relative' key={message.id}>
								<div
									className={`email-list-item email-list-box email-list-nowrap ${!isEmpty(selectedMessage) && selectedMessage.id === message.id && 'active'
										} ${message.unread === true &&
										'unread'
										}`}
									key={message.id}

								>
									<div className='email-list-flex email-list-checkbox'>
										<div className='checkbox icheck-success'>
											<input
												type='checkbox'
												id={`success-${message.id}`}
												checked={message.selected && message.selected === true}
												onChange={() =>
													selectThreads(
														message.selected === true
															? 'single-unselect'
															: 'single',
														message.id,
														filteredMessages,
														setFilteredMessages,
														setThreadsSelected,
													)
												}
											/>
											<label
												htmlFor={`success-${message.id}`}
											></label>
										</div>
									</div>
									<div className='email-list-flex email-list-grid email-list-hidden' id={`list-${message.id}`} onClick={() => setSelectedMessage(message)}>
										<div className='email-list-item email-list-item-title'>
											<div className='email-list-flex email-list-grid'>
												<h6 className='email-list-text'>
													{type === 'sent' ? (
														<>
															{truncate(
																`To: ${message.participants
																	.filter(
																		participant =>
																			participant.email !== nylasEmail,
																	)
																	.map(participant => participant.email)
																	.join(',')}`,
																{
																	length: 28,
																},
															)}
														</>
													) : (
														truncate(
															getNameFromParticipants(
																message.participants,
																nylasEmail,
															), {
															length: 28,
														})
													)}{' '}
													<span className='mail-count'>
														{message.message_ids.length > 1 &&
															message.message_ids.length}
													</span>
												</h6>
											</div>
											{/* <span className="mail-attach-icon"><i className="ico icon-attachment"></i></span> */}
											<div className='email-list-flex email-datetime'>
												<small>
													{moment.unix(message.last_message_timestamp).fromNow()}
												</small>
											</div>
										</div>
										<h5 className='email-list-head'>{message.subject}</h5>
										<span
											className='email-list-content email-list-hidden'
											style={{ paddingRight: '20px', position: 'relative' }}
										>
											{message.snippet}
										</span>
									</div>
								</div>
								<span
									onClick={() => {
										updateMessage(
											message,
											`${message.starred ? 'unstar' : 'star'}`,
											{},
										)
									}
									}
									className='mail-icon-fav mail-icon-fav-custom'
								>
									<i
										className={`ico icon-star${message.starred ? '-active' : ''
											}`}
									></i>
								</span>
							</div>
						))
					) : (
						<h6 className='text-center pt-3'>
							We didn't find any emails to show here.
						</h6>
					)}
					{false ? (
						<></>
					) : messages && messages.length > 0 ? (
						<div className='email-load-more w-100 d-flex justify-content-center align-items-center py-3'>
							{isLimitReached ? (
								<></>
							) : (
								<button
									className='btn btn-primary'
									onClick={() => loadMoreThreads(type)}
								>
									{loadingMore && (
										<span
											className='spinner-border spinner-button mr-1'
											role='status'
											aria-hidden='true'
										></span>
									)}
									Load more
								</button>
							)}
						</div>
					) : (
						<></>
					)}
				</div>
			)}
		</div>
	);
};

export default EmailList;
