import $ from "jquery";
import React, { useState, useEffect, createRef } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
import toast from 'toasted-notes';
import "../../../../styles/formEditor.scss";
import Swal from 'sweetalert2';
import { visibilities, formVM } from './viewModel';
import { InputText, ReactSelect, PopUpModal } from '../../../../components';
// import PreviewForm from './preview';
import RightPreview from './rightPreview';
import CommonHelper from '../../../../services/common';
import PipelineRules from "./rules";
window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require("formBuilder/dist/form-render.min.js");

const AddForm = props => {
	const [feedbackForm, setFeedbackForm] = useState(props.feedbackForm);
	const [loader, setLoader] = useState(false);
	const fb = createRef();
	const [fbEditor, setFormEditor] = useState(null);
	const [errorFields, setErrorFields] = useState({});
	const [pipelineRules, setPipelineRules] = useState({ show: false })
	// const [previewForm, setPreviewForm] = useState({
	// 	show: false,
	// 	title: '',
	// 	formFields: null,
	// 	data: null
	// })


	useEffect(() => {

		if (_.isEmpty(props.feedbackForm)) {
			let newformVM = Object.assign({}, formVM);
			newformVM.documentID = window.firebase.firestore().collection(`formTemplates`).doc().id;
			newformVM.clientID = props.dealersettings.client.id;
			newformVM.oemID = props.dealersettings.oemID;
			newformVM.addedBy = localStorage.uid;
			newformVM.modifiedBy = localStorage.uid;
			newformVM.addedDate = window.firebase.firestore.Timestamp.now();
			newformVM.modifiedDate = window.firebase.firestore.Timestamp.now();
			newformVM.type = 'sales';
			newformVM.isDeleted = false;
			newformVM.visibility = 'private';
			setFeedbackForm(newformVM)
		}
	}, [])

	const handleColorChange = (color, name) => {
		// console.log('color name', color, name)
		setFeedbackForm({
			...feedbackForm,
			[name]: color.hex,
		});
	};

	const handleOnChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		var array1 = value.split(' ');
		var str = value;


		if (array1.length > 0) {
			var newarray1 = [];
			for (var x = 0; x < array1.length; x++) {
				newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
			}
			setFeedbackForm({
				...feedbackForm,
				[name]: newarray1.join(' ')
			});
		} else {
			if (str && str.length >= 1) {
				var firstChar = str.charAt(0);
				var remainingStr = str.slice(1);
				str = firstChar.toUpperCase() + remainingStr;
			}
			setFeedbackForm({
				...feedbackForm,
				[name]: str
			});
		}

		setErrorFields({
			...errorFields,
			[name]: ''
		});

	}

	const handleSelectChange = (e, data) => {

		setFeedbackForm({
			...feedbackForm,
			[data.name]: e.value,

		});
		setErrorFields({
			...errorFields,
			[data.name]: ''
		});
	}

	const handleSave = () => {
		const formFields = fbEditor.actions.save();
		if (_.isEmpty(formFields) || formFields.length === 0) {
			Swal.fire(CommonHelper.showLocale(props, 'Please add atleast one field.'), '', 'info')
			return;
		}

		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(feedbackForm['title'])) {
			formIsValid = false;
			errors['title'] = errorClass;
		}
		setErrorFields(errors)
		if (!formIsValid) {
			return;
		}

		setLoader(true);
		window.firebase.firestore().doc(`formTemplates/${feedbackForm.documentID}`)
			.set({
				..._.pick({
					...feedbackForm,
					fields: formFields,
					modifiedBy: localStorage.uid,
					modifiedDate: window.firebase.firestore.Timestamp.now()
				}, _.keys(formVM))
			}, { merge: true })
			.then(snapshot => {
				setLoader(false)
				toast.notify((_.isEmpty(props.feedbackForm) ? 'Form added successfully' : 'Form updated successfully'), {
					duration: 2000
				})
				props.handleClose();
			})
			.catch(error => {
				console.error(error);
				Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'), '', 'error');
			});
	}
	const handleClear = () => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to clear all fields.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then((result) => {
			if (result.value) {
				fbEditor.actions.clearFields();
			}
		})


	}
	useEffect(() => {
		// Update the document title using the browser API
		let options2 = {
			//append: '<button>Submit</button>',
			disableFields: ["button", "paragraph", "hidden", "file", "date", "autocomplete", "number", "makeAndModel", "phone", "email", "firstAndLastName", "pageBreak"],
			defaultFields: props.feedbackForm ? props.feedbackForm.fields : [],
			disabledActionButtons: ["save", "data", "clear"],
			fields: [
				{
					label: 'Star Rating',
					attrs: {
						type: 'starRating'
					},
					icon: '🌟'
				}
			],
			controlOrder: [
				'header',
				'radio-group',
				'checkbox-group'
			],
			onAddOption: (optionTemplate, optionIndex) => {
				optionTemplate.label = optionTemplate.label ? optionTemplate.label : `Option ${optionIndex.index + 1}`
				optionTemplate.value = optionTemplate.value ? optionTemplate.value : `option-${optionIndex.index + 1}`
				return optionTemplate
			},
			onOpenFieldEdit: function (editPanel) {
				$(editPanel).find('.close-field').html('Save');
			},
			templates: {
				starRating: function (fieldData) {
					return {
						field: '<span id="' + fieldData.name + '">',
						onRender: function () {
							for (var i = 0; i < 5; i++) {
								var klass = 'star-rating__star';

								if (i < 3) {
									klass += ' is-selected';
								}


								$(document.getElementById(fieldData.name)).append("<label class='" + klass + "' >★</label>")
							}
						}
					};
				}
			},
			// typeUserAttrs: {
			// 	text: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	}, textarea: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	}, select: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	},
			// 	starRating: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	},
			// 	['radio-group']: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	},
			// 	['checkbox-group']: {
			// 		others: {
			// 			label: 'Score',
			// 			value: '',
			// 		}
			// 	},
			// }
		};

		const formBuilder = $(fb.current).formBuilder(options2);
		setFormEditor(formBuilder);

	}, []);


	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button type="button" className="btn btn-default float-left" onClick={handleClear}><Translate text={'Clear'} /></button>
							<button
								type='button'
								className={`btn btn-default float-left d-flex align-items-center ml-2`}
								onClick={(e) => {
									e.preventDefault();
									if (fbEditor.actions.save()?.length > 0) {
										setPipelineRules({
											show: true,
											title: 'Pipeline/Stage Rule Setup',
											data: feedbackForm,
											formFields: fbEditor.actions.save()?.map(r => {
												return {
													...r,
													value: r.name
												}
											}),
										})
									}
									else {
										Swal.fire(CommonHelper.showLocale(props, 'Drag a field from the right to set rules.'), '', 'error');
									}
								}}
							>
								<i className="ico icon-settings mr-2"></i>
								Rules
							</button>
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={handleSave}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<div className='form-style w-100 pl-4 pr-4'>
									<div className="form-row">
										<div className="form-group col-md-6">
											<label ><Translate text={'title'} /></label>
											<InputText
												autoComplete="off"
												placeholder={'title'}
												className={`form-control ${errorFields["title"]}`}
												name="title"
												onChange={handleOnChange}
												value={feedbackForm ? feedbackForm.title : ''}
											/>

										</div>

										<div className="form-group col-md-6">
											<label ><Translate text={'visibility'} /></label>
											<ReactSelect
												options={visibilities}
												name={"visibility"}
												placeholder={'select visibility'}
												onChange={handleSelectChange}
												value={feedbackForm ? feedbackForm.visibility : ''}
												classNamePrefix={`basic-select`}
												removeClearable={true}
												isSearchable={false}
											>
											</ReactSelect>
										</div>
									</div>

								</div>
								<div className='dynamicform-view-wrapper'>
									<div className='dynamicform-sub-section-div'>
										{/* Left Panel */}
										<div className='dynamicform-left-panel'>
											<div className='dynamicform-left-container'>
												<div className='card dynamicform-custom-card h-100'>
													<div className='card-header'><Translate text={'Add/Edit Form'} /></div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='form-row w-100'>
																<div className="feedback-form-editor p-2 w-100">
																	<div id="fb-editor" ref={fb} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Right Panel */}
										<RightPreview
											formFields={feedbackForm?.fields ? feedbackForm?.fields : []}
											feedbackForm={feedbackForm}
											handleColorChange={(handleColorChange)}
											fbEditor={fbEditor}
										/>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={pipelineRules.show}>
				<PipelineRules
					{...props}
					{...pipelineRules}
					handleClose={() => { setPipelineRules({ show: false }) }}
				/>
			</PopUpModal>
		</>
	);
};

export default AddForm;
