import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect, PopUpModal } from '../../../components';
import Translate from '../../../constants/translate';
import Papa from 'papaparse'
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { Progress } from 'antd';
import toast from 'toasted-notes'
import CommonHelper from '../../../services/common';
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone';
import downloadFile from './import_attendees_example.csv'
import MapContact from './mapContact'

const ImportCSV = props => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
    });
    const [errorFields, setErrorFields] = useState({});
    const [file, setFile] = useState(null);
    const [attendeesToBeSet, setAttendeesToBeSet] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [data, setData] = useState({
        name: '',
        size: '',
    });
    const [dealers, setDealers] = useState([]);
    const [dealer, setDealer] = useState('');
    const [slot, setSlot] = useState('');
    const [importLoader, setimportLoader] = useState(false)
    const [mapContact, setMapContact] = useState({ show: false, data: null, selected: null })
    const [mappedKeys, setkeys] = useState({});
    useEffect(() => {
        const { dealersettings } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.clients)) {
            let _dealers = [];
            dealersettings.clients.forEach(rec => {
                _dealers.push({
                    label: rec.name,
                    value: rec.id,
                    data: rec,
                    active: true,
                })
            })
            setDealers(_dealers);
        }
    }, [])

    React.useEffect(() => {
        if (_.isEmpty(acceptedFiles))
            return

        const validateFiles = files => {
            // console.log('acceptedFiles', acceptedFiles)
            let filteredFiles = [];

            acceptedFiles.forEach(file => {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
                } else {
                    filteredFiles.push(file)
                }
            })

            if (filteredFiles.length > 0) {
                onSelectFile(filteredFiles)
            }
        }

        validateFiles(acceptedFiles)
    }, [acceptedFiles])

    const handleFileRemove = () => {
        setFile(null)
        setkeys({})
        setAttendeesToBeSet([])
        setIsLoading(false)
        setIsFileUploaded(false)
        setProgress(0)
        setData({
            name: '',
            size: '',
        })
    };

    const onSelectFile = async (files) => {
        if (!files) {
            return
        }
        if (files && files.length > 0) {
            if (!files[0].name.match(/.(csv|xlsx|xls)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported.'), 'Please upload valid file formats .csv, .xlsx, .xls only.', 'info')
                return;
            }
        }
        setIsLoading(true)
        let addedFile = files[0];
        let csvFile = files[0];

        // CONVERT EXCEL FILE TO CSV DATA
        if (addedFile.name.match(/.(xlsx|xls|xlsm)$/i)) {
            const _data = await addedFile.arrayBuffer();
            const workbook = XLSX.read(_data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            csvFile = XLSX.utils.sheet_to_csv(worksheet, {
                header: 1,
                defval: "",
            });
            //console.log("Data===>>>", csvFile, addedFile);
        }

        setFile(addedFile)
        setData({ ...data, name: addedFile.name, size: addedFile.size })
        setIsFileUploaded(true)
        Papa.parse(csvFile, {
            complete: updateRecipientsWithCSVData,
            header: true
        });
    }

    const updateRecipientsWithCSVData = (result) => {
        if (!result) {
            return
        }
        var data = result.data;
        // const selectedContactsArr = data
        //     .map(contact => {
        //         return dataMappingVM(contact)
        //     });
        // if (data.length > 2000) {
        //     setFile(null)
        //     setIsFileUploaded(false)
        //     setData({
        //         name: '',
        //         size: '',
        //     })
        //     Swal.fire(CommonHelper.showLocale(props, 'A maximum of 2000 attendee(s) can be imported at once.'), '', 'info')
        //     return;
        // }

        if (data && data[0]) showMapper(data[0]);
        setAttendeesToBeSet([...data]);
        setTimeout(() => {
            if (progress !== 100) {
                setProgress(progress + 50)
                setTimeout(() => {
                    setProgress(100)
                    setIsLoading(false)
                    //  toast.notify('File added successfully', {
                    //      duration: 2000
                    //  })
                }, 500)
            }

        }, 500)
    }

    const dataMappingVM = (contact) => {
        if (!_.isEmpty(mappedKeys)) {
            let _dynamicFields = {};
            const attendeesDF = props?.eventSettings?.attendeesDF ? props.eventSettings.attendeesDF : [];
            Object.keys(mappedKeys).forEach(key => {
                let _data = !_.isEmpty(attendeesDF) ? _.find(attendeesDF, { value: mappedKeys[key] }) : {}
                if (mappedKeys[key] === 'ownedVehicle') {
                    if (contact[key] && _.isString(contact[key])) { contact[mappedKeys[key]] = { model: contact[key] }; delete contact[key]; }
                }
                else if (!_.isEmpty(_data)) {
                    if (contact[key]) {
                        _dynamicFields[mappedKeys[key]] = _data.type === 'multiselect' ? [contact[key]] : contact[key];
                        delete contact[key];
                    }
                } else if (contact[key] && key !== mappedKeys[key]) {
                    contact[mappedKeys[key]] = contact[key];
                    delete contact[key];
                }
            })
            if (!_.isEmpty(_dynamicFields)) contact['dynamicFields'] = _dynamicFields
        }
        return contact;

        // return {
        //     email: contact['Email'] ? contact['Email'] :
        //         contact['email'] ? contact['email'] : '',
        //     phone: contact['Phone'] ? contact['Phone'] :
        //         contact['phone'] ? contact['phone'] : '',
        //     firstName: contact['FirstName'] ? contact.FirstName :
        //         contact['First Name'] ? contact['First Name'] :
        //             contact['first name'] ? contact['first name'] :
        //                 contact['first_name'] ? contact['first_name'] : '',
        //     lastName: contact['LastName'] ? contact.LastName :
        //         contact['Last Name'] ? contact['Last Name'] :
        //             contact['last name'] ? contact['last name'] :
        //                 contact['last_name'] ? contact['last_name'] : '',
        //     address: contact['Address'] ? contact['Address'] :
        //         contact['address'] ? contact['address'] : '',
        //     documentID: window.firebase.firestore().collection('campaignRecipients').doc().id,
        //     contactID: contact.contactID ? contact.contactID : '',
        // };
    }

    const showMapper = (data) => {

        let _selected = {};
        Object.keys(data).forEach(key => {
            if (['Email', 'email'].includes(key)) _selected[key] = 'email';
            else if (['Phone', 'phone'].includes(key)) _selected[key] = 'phone';
            else if (['FirstName', 'First Name', 'first name', 'first_name'].includes(key)) _selected[key] = 'firstName';
            else if (['MiddleName', 'Middle Name', 'middle name', 'middle_name'].includes(key)) _selected[key] = 'middleName';
            else if (['LastName', 'Last Name', 'last name', 'last_name'].includes(key)) _selected[key] = 'lastName';
            else if (['Address', 'address'].includes(key)) _selected[key] = 'address'

        })
        setMapContact({ show: true, data: Object.keys(data).filter(e => !_.isEmpty(e)), selected: _selected })
    }

    const handleMapClose = (_keys) => {
        try {
            if (!_.isEmpty(_keys)) setkeys(_keys);
            else handleFileRemove();
            setMapContact({ show: false, data: null, selected: null })
        } catch (error) {
            //errorLog(error, 'handleXContactClose');
        }
    }

    const importContacts = () => {
        if (_.isEmpty(attendeesToBeSet)) {
            Swal.fire(CommonHelper.showLocale(props, 'No attendees file added yet'), '', 'info')
            return;
        }

        if (_.isEmpty(mappedKeys)) {
            Swal.fire(CommonHelper.showLocale(props, 'No mapping has been set yet.'), '', 'info')
            return;
        }

        // if (attendeesToBeSet.length > 2000) {
        //     Swal.fire(CommonHelper.showLocale(props, 'A maximum of 2000 attendee(s) can be imported at once.'), '', 'info')
        //     return;
        // }

        //console.log('import contact running');
        if (attendeesToBeSet.length > 0) {
            setimportLoader(true);
            props.setFullPageLoader({
                show: true,
                title: 'Contacts are being imported...'
            })
            const all = [
                ..._.uniqBy(attendeesToBeSet.filter(a => !_.isEmpty(a.email)), 'email'),
                ...attendeesToBeSet.filter(a => _.isEmpty(a.email)),
            ]
            //console.log('b4==>>', all)
            all.map(contact => {
                return dataMappingVM(contact)
            });
            //console.log('after==>>', all)
            props.handleClose(all, slot, dealer)
        } else {
            toast.notify('No attendees file added yet', {
                duration: 2000
            })
        }
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            if (data.name === 'slot')
                setSlot(e.value)

            if (data.name === 'dealer')
                setDealer(e.value)
        }
        else {
            if (data.name === 'slot')
                setSlot('')

            if (data.name === 'dealer')
                setDealer('')
        }

    }

    const baseStyle = {
        width: '100%',
        background: '#f2f7ff',
        border: '2px dashed #a7cbff',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#4466f2',
    };
    const baseBlackStyle = {
        width: '100%',
        background: '#eeeff2',
        border: '2px dashed #808080',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#00000',
    };
    const activeStyle = {
        border: '2px dashed #2bb673',
    };

    const acceptStyle = {
        borderColor: '#2bb673',
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = React.useMemo(() => ({
        ...(props?.dealersettings?.isPorsche ? baseBlackStyle : baseStyle),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop='static'
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName='modal-dialog-centered modal-file-manager'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='modal-title'>
                            {' '}
                            <Translate text={'Import Excel'} />
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='upload-file-box'>
                                <div {...getRootProps({ style, className: 'upload-iconwrap upload-box-dragndrop' })}>
                                    <input {...getInputProps()} />
                                    <i className="ico icon-files-upload"></i>
                                    <p>Drag 'n' drop excel file here, or click to upload file</p>
                                </div>

                                {isFileUploaded ? (
                                    <div className='uploaded-list-video'>
                                        <div className='flex-upload'>
                                            <div className='upload-clear'>
                                                <a
                                                    href='#'
                                                    onClick={e => { e.preventDefault(); handleFileRemove() }}
                                                //className={`${isLoading ? 'form-disable' : ''}`}
                                                >
                                                    <i className='fa fa-times' aria-hidden='true'></i>
                                                </a>
                                            </div>
                                            <div className='file-upload-icon'>
                                                <i className="ico icon-files-excel"></i>
                                            </div>
                                            <div className='upload-data'>
                                                {data.name}
                                                {' (' + CommonHelper.showFileSize(data.size) + ')'}
                                            </div>
                                        </div>
                                        {isLoading ? <Progress percent={progress} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="pop-layout form-style mt-2">

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label > <Translate text={'dealerOpt'} /></label>
                                <ReactSelect
                                    options={dealers}
                                    name={"dealer"}
                                    placeholder={'select dealer'}
                                    onChange={handleReactSelectChange}
                                    value={dealer}
                                    classNamePrefix={`basic-select`}
                                >
                                </ReactSelect>
                            </div>

                            <div className="form-group col-md-6">
                                <label > <Translate text={'eventSlotOpt'} /></label>
                                <ReactSelect
                                    options={props.eventSlots}
                                    name={"slot"}
                                    placeholder={'select slot'}
                                    onChange={handleReactSelectChange}
                                    value={slot}
                                    classNamePrefix={`basic-select`}
                                >
                                </ReactSelect>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    <button
                        type='button'
                        className={`btn btn-primary float-right ${importLoader ? 'btn-disable' : ''}`}
                        onClick={(e) => { e.preventDefault(); importContacts(); }}
                    >
                        {
                            importLoader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'Import'} />
                    </button>
                    <a
                        href={downloadFile}
                        download='import_attendees_example.csv'
                        className='btn btn-default float-left'
                    >
                        <Translate text={'Download Sample'} />
                    </a>
                </Modal.Footer>
            </Modal>

            <PopUpModal show={mapContact.show}>
                <MapContact
                    show={mapContact.show}
                    mappingKeys={mapContact.data}
                    selectedKeys={mapContact.selected}
                    handleClose={handleMapClose}
                    eventSettings={props?.eventSettings}
                >
                </MapContact>

            </PopUpModal>
        </>
    );
};

export default ImportCSV;
