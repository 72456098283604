import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { ContentOverlay, ReactSelect, InputCheckBox, PopUpModal, UpgradePlan } from '../../../../components'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import Translate from '../../../../constants/translate';
import { TemplateContext } from '../templateContext'
import { enqiuryOptions } from "./viewModel";
import { fieldTypes, levelOptions, tradeinProLookups, lookupDFVM, lookupVM } from '../lookups/viewModel'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collection, doc } from 'rxfire/firestore';
import AddDynamicOptions from '../lookups/addDynamicOptions'
import { default as _images } from '../../../../images';
import CommonHelper from '../../../../services/common';

const SortAppraisalFields = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [loader, setLoader] = useState(true);
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const { clients, groups, regions } = useContext(TemplateContext);
    const [enqOptions, setOptions] = useState();
    const [allLookups, setAlllookups] = useState({});
    const [searchText, setSearchText] = useState('')
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [enqActiveOptions, setActiveOptions] = useState([]);
    const [selectedOEM, setSelectedOEM] = useState('')
    const [selectedLookup, setSelectedLookup] = useState(null)
    const [showDynamicOptionModel, setShowDynamicOptionModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        if (localStorage.defaultModule === "oem")
            setSelectedLevel({
                'level': props.dealersettings.level,
                'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                    props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                        props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                            props.dealersettings.client.id
            })
        else
            setSelectedLevel({
                'level': 'individual',
                'levelID': props.dealersettings.client.id
            })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
        else {
            setClientOptions([{ name: 'oem' }])
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        if (_.isEmpty(clientOptions) || _.isEmpty(selectedLevel) || (_.isEmpty(selectedLevel.levelID) && selectedLevel.level !== 'oem'))
            return

        let refRegionID = ''
        let refGroupID = ''
        let refClientID = ''
        let refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}/enquiryOptions/${props.dealersettings.client.settingsID}`)
        if (selectedLevel.level === 'region') {
            refRegionID = selectedLevel.levelID;
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${refRegionID}/tradeinPro/${refRegionID}/enquiryOptions/${refRegionID}`)
        }
        else if (selectedLevel.level === 'group') {
            if (_.find(clientOptions, { group: selectedLevel.levelID }) && _.find(clientOptions, { group: selectedLevel.levelID }).region)
                refRegionID = _.find(clientOptions, { group: selectedLevel.levelID }).region;
            refGroupID = selectedLevel.levelID;
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${refGroupID}/tradeinPro/${refGroupID}/enquiryOptions/${refGroupID}`)
        }
        else if (selectedLevel.level === 'individual') {
            if (_.find(clientOptions, { documentID: selectedLevel.levelID }) && _.find(clientOptions, { documentID: selectedLevel.levelID }).region)
                refRegionID = _.find(clientOptions, { group: selectedLevel.levelID }).region;
            if (_.find(clientOptions, { documentID: selectedLevel.levelID }) && _.find(clientOptions, { documentID: selectedLevel.levelID }).group)
                refGroupID = _.find(clientOptions, { group: selectedLevel.levelID }).group;
            refClientID = selectedLevel.levelID;
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${refClientID}/tradeinPro/${refClientID}/enquiryOptions/${refClientID}`)
        }

        var enqOptionlookupsettings = combineLatest(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`)),
            refRegionID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${refRegionID}/tradeinPro/${refRegionID}`)) : of([]),
            refGroupID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${refGroupID}/tradeinPro/${refGroupID}`)) : of([]),
            refClientID ? doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${refClientID}/tradeinPro/${refClientID}`)) : of([]),
            doc(refSortOptions)
        )
            .pipe(
                map((lookups) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups, regionLookups, groupLookups, clientLookups, enqOptions] = lookups;
                    return [
                        //spread the arrays out to combine as one array
                        { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id },
                        regionLookups.exists ? { ...regionLookups.data(), id: regionLookups.id, documentID: regionLookups.id } : {},
                        groupLookups.exists ? { ...groupLookups.data(), id: groupLookups.id, documentID: groupLookups.id } : {},
                        clientLookups.exists ? { ...clientLookups.data(), id: clientLookups.id, documentID: clientLookups.id } : {},
                        enqOptions.exists ? (!_.isEmpty(enqOptions.data().options) ? [...enqOptions.data().options] : []) : []
                    ];

                }))
            .subscribe(allDocs => {
                var lookups = [];
                for (let i = 0; i < 4; i++) {
                    lookups.push(allDocs[i])
                }
                let settingsDoc = {};
                setSelectedOEM(allDocs[0])
                lookups.forEach(rec => {
                    tradeinProLookups.filter(e => e.value === 'enquiryOptionsDF').forEach((key) => {
                        if (rec[key.value]) {
                            rec[key.value].forEach((_lookups) => {
                                if (settingsDoc[key.value]) {
                                    settingsDoc[key.value] = [
                                        ...settingsDoc[key.value],
                                        {
                                            ..._lookups,
                                            'levelID': rec.id,
                                            'levelName': rec.name,
                                            'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                            'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                            'documentID': _lookups.value,
                                            'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                                (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                        }
                                    ]
                                }
                                else {
                                    settingsDoc[key.value] = [{
                                        ..._lookups,
                                        'levelID': rec.id,
                                        'levelName': rec.name,
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                        'documentID': _lookups.value,
                                        'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                    }]
                                }
                            })
                        }
                        else if (_.isEmpty(settingsDoc[key.value])) {
                            settingsDoc[key.value] = [];
                        }
                    })
                })
                let userlevelindex = _.find(levelOptions, { value: selectedLevel.level }) ? _.find(levelOptions, { value: selectedLevel.level }).index : 1;
                let sortOptions = allDocs[4];
                let allOptions = [...enqiuryOptions.map(rec => { return { ...rec, levelName: 'Default', active: true, level: 'default' } }), ...settingsDoc.enquiryOptionsDF.filter(e => userlevelindex >= e.levelIndex)]
                if (sortOptions && sortOptions.length > 0)
                    allOptions = [...sortOptions.filter(e => allOptions.some(a => a.value === e)).map(rec => { return { ..._.find(allOptions, { value: rec }) } }), ...allOptions.filter(e => !sortOptions.some(a => a === e.value)).map(rec => { return { ...rec, active: false } })]
                setOptions(allOptions)
                setAlllookups(settingsDoc)
                setLoader(false)
            });
        return () => {
            enqOptionlookupsettings && enqOptionlookupsettings.unsubscribe()
        }
    }, [clientOptions, selectedLevel])

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = enqOptions.splice(result.source.index, 1);
        enqOptions.splice(result.destination.index, 0, removed);
        setOptions(enqOptions)
        setActiveOptions(enqOptions.filter(e => e.active === true).map(rec => { return rec.value }))
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const handleSwitchChange = (e, i, data) => {
        let _options = [];
        if (e.target.checked) {
            _options = enqOptions.map(rec => {
                return rec.value === data.value ? {
                    ...rec,
                    active: true
                } : {
                    ...rec
                }
            });
        }
        else {
            // const [removed] = enqOptions.splice(i, 1);
            // enqOptions.splice(enqOptions.length, 0, removed);
            _options = enqOptions.map(rec => {
                return rec.value === data.value ? {
                    ...rec,
                    active: false
                } : {
                    ...rec
                }
            });
        }
        setOptions(_options)
        setActiveOptions(_options.filter(e => e.active === true).map(rec => { return rec.value }))
        hadleUpdateLookup({
            ...data,
            active: e.target.checked
        })
    }

    useEffect(() => {
        if (_.isEmpty(enqActiveOptions))
            return
        let refSortOptions = ''
        if (selectedLevel.level === 'oem') {
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}/enquiryOptions/${props.dealersettings.client.settingsID}`)
        }
        else if (selectedLevel.level === 'region') {
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${selectedLevel.levelID}/tradeinPro/${selectedLevel.levelID}/enquiryOptions/${selectedLevel.levelID}`)
        }
        else if (selectedLevel.level === 'group') {
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedLevel.levelID}/tradeinPro/${selectedLevel.levelID}/enquiryOptions/${selectedLevel.levelID}`)
        }
        else if (selectedLevel.level === 'individual') {
            refSortOptions = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${selectedLevel.levelID}/tradeinPro/${selectedLevel.levelID}/enquiryOptions/${selectedLevel.levelID}`)
        }

        if (refSortOptions) {
            refSortOptions.set({
                ['options']: enqActiveOptions,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now()
            }, { merge: true })
                .then(rec => {
                    //update settings date and priority
                    if (!_.isEmpty(props.dealersettings.client)) {
                        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                            .set({
                                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                settingsUpdatePriority: 'normal',
                                settingsUpdateBy: props?.dealersettings?.name || null,
                                settingsUpdateText: 'Tradein Pro Dynamic Field Settings has been updated',
                            }, { merge: true })
                    }
                    //end 
                    toast.notify('Enquiry options updated successfully', {
                        duration: 2000
                    });
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire('Something went wrong', '', 'error')
                })
        }

    }, [enqActiveOptions])


    const hadleDeleteLookup = (data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let activeLookup = 'enquiryOptionsDF'
                const batch = window.firebase.firestore().batch();
                if (data.level === 'oem') {
                    const lookupObj = {
                        [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && item.levelID === data.levelID), function (obj) {
                            return activeLookup === 'enquiryOptionsDF' ? { ..._.pick(obj, _.keys(lookupDFVM)) } :
                                { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }

                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                        {
                            ...lookupObj,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now()
                        }, { merge: true });
                }
                else if (_.isArray(data.levelID)) {
                    data.levelID.forEach(rec => {
                        const lookupObj = {
                            [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && item.levelID === rec), function (obj) {
                                return activeLookup === 'enquiryOptionsDF' ? { ..._.pick(obj, _.keys(lookupDFVM)) } :
                                    { ..._.pick(obj, _.keys(lookupVM)) };
                            })]
                        }

                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${rec}/tradeinPro/${rec}`),
                            {
                                ...lookupObj,
                                modifiedBy: localStorage.uid,
                                modifiedDate: window.firebase.firestore.Timestamp.now()
                            }, { merge: true });
                    })
                }
                else {
                    const lookupObj = {
                        [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && item.levelID === data.levelID), function (obj) {
                            return activeLookup === 'enquiryOptionsDF' ? { ..._.pick(obj, _.keys(lookupDFVM)) } :
                                { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }

                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}/tradeinPro/${data.levelID}`),
                        {
                            ...lookupObj,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now()
                        }, { merge: true });
                }

                batch.commit()
                    .then(snapshot => {
                        setActiveOptions(enqActiveOptions.filter(e => e !== data.value));
                        //update settings date and priority
                        if (!_.isEmpty(props.dealersettings.client)) {
                            window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                                .set({
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: 'Tradein Field Settings has been updated',
                                }, { merge: true })
                        }
                        //end 
                        toast.notify('Enquiry options deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    });
            }
        })
    }

    const hadleUpdateLookup = (data, newLookup) => {
        let activeLookup = 'enquiryOptionsDF'
        const batch = window.firebase.firestore().batch();
        if (data.level === 'oem') {
            const lookupObj = {
                [activeLookup]: newLookup ? [..._.map(allLookups[activeLookup].filter(item => item.level === data.level), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(data, _.keys(lookupDFVM))] :
                    [..._.map(allLookups[activeLookup].filter(item => item.level === data.level), function (obj) {
                        return obj.value === data.value ? { ..._.pick(data, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                {
                    ...lookupObj,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }, { merge: true });
        }
        else if (_.isArray(data.levelID)) {
            data.levelID.forEach(rec => {
                const lookupObj = {
                    [activeLookup]: newLookup ? [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(data, _.keys(lookupDFVM))] :
                        [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && rec === item.levelID), function (obj) {
                            return obj.value === data.value ? { ..._.pick(data, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                        })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${rec}/tradeinPro/${rec}`),
                    {
                        ...lookupObj,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true });
            })
        }
        else {
            const lookupObj = {
                [activeLookup]: newLookup ? [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && item.levelID === data.levelID), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(data, _.keys(lookupDFVM))] :
                    [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && item.levelID === data.levelID), function (obj) {
                        return obj.value === data.value ? { ..._.pick(data, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}/tradeinPro/${data.levelID}`),
                {
                    ...lookupObj,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }, { merge: true });
        }

        batch.commit()
            .then(() => {
                if (document.getElementById('tr-' + data.value) && newLookup) {
                    setTimeout(function () {
                        document.getElementById('tr-' + data.value).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }, 1000);
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const hadleEditLookup = (data) => {
        setPopHeader('Edit Appraisal Fields')
        setSelectedLookup(data)
        setShowDynamicOptionModel(true)
    }

    const handleAddLookup = () => {
        setPopHeader('Add Appraisal Fields')
        setSelectedLookup(null)
        setShowDynamicOptionModel(true)
    }

    const handleModelClose = (data, isNew) => {
        setSelectedLookup(null)
        setShowDynamicOptionModel(false)
        if (data) {
            let _options = [];
            if (data.active === true) {
                _options = enqOptions.map(rec => {
                    return rec.value === data.value ? {
                        ...rec,
                        active: true
                    } : {
                        ...rec
                    }
                });
            }
            else {
                _options = enqOptions.map(rec => {
                    return rec.value === data.value ? {
                        ...rec,
                        active: false
                    } : {
                        ...rec
                    }
                });
            }
            setOptions(_options)
            setActiveOptions(isNew ? [..._options.filter(e => e.active === true).map(rec => { return rec.value }), data.value] : _options.filter(e => e.active === true).map(rec => { return rec.value }))
            hadleUpdateLookup(data, isNew)
        }
    }

    return (<>
        {
            liteVersion ? (<UpgradePlan />) : (<></>)
        }
        <h2> <Translate text={'Appraisal Fields Settings'} /></h2>
        <div className="divider-line"></div>
        <div className={`settings-templates-wrap ${liteVersion ? 'form-lite' : ''}`} >
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={(e, data) => {
                                        if (e) {
                                            if (e.value === 'oem')
                                                setLoader(true)
                                            setSelectedLevel({
                                                level: e.value
                                            });
                                        }
                                        else
                                            setSelectedLevel(null);
                                    }}
                                    value={selectedLevel ? selectedLevel.level : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0">
                                    <ReactSelect
                                        options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                        onChange={(e, data) => {
                                            if (e) {
                                                setLoader(true)
                                                setSelectedLevel({
                                                    ...selectedLevel,
                                                    levelID: e.value
                                                });
                                            }
                                            else
                                                setSelectedLevel(null);
                                        }}
                                        value={selectedLevel ? selectedLevel.levelID : ''}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            }

                            {/* <div className="filter-search search-icon col-md-3">
                                <input placeholder="search fields" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary float-right"
                            onClick={() => {
                                handleAddLookup();
                            }}
                        ><i className="ico icon-add mr-2"></i><Translate text={'add'} /></button>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                selectedLevel && (selectedLevel.level === 'oem' || !_.isEmpty(selectedLevel.levelID)) ? enqOptions.length === 0 ? (
                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                        <div className="text-center p-5">
                            <p> <Translate text={'noDataFound'} /></p>
                            <button className="btn btn-sm btn-default" onClick={() => {
                                handleAddLookup();
                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                <Translate text={'Add Field'} />
                            </button>
                        </div>
                    </div>
                ) : (<div className="common-table">
                    <DragDropContext onDragEnd={onDragEnd}>

                        <table
                            className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="head-light" style={{ width: '20px' }}><Translate text={'SNo'} /></th>
                                    <th scope="col" className="head-light" width="50%"><Translate text={'name'} /></th>
                                    <th scope="col" className="head-light" width="20%"><Translate text={'Field Type'} /></th>
                                    <th scope="col" className="head-light" width="20%"><Translate text={'Level'} /></th>
                                    <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'active'} /></th>
                                    <th scope="col" className="head-light border-left-0 border-right-0" style={{ width: '60px' }}>&nbsp;</th>
                                    <th scope="col" className="head-light border-left-0" style={{ width: '20px' }}>&nbsp;</th>
                                </tr>
                            </thead>
                            <Droppable droppableId="table">
                                {(provided, snapshot) => (
                                    <tbody  {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {
                                            enqOptions.map((rec, index) => {
                                                return <Draggable key={rec.value} draggableId={rec.value} index={index} isDragDisabled={!Boolean(rec.active)}>
                                                    {(provided, snapshot) => (<tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}>
                                                        <td id={`tr-${rec.value}`} style={{ width: '20px' }}>{index + 1}</td>
                                                        <td width="50%">{CommonHelper.showLocale(props, rec.value, rec.name)}</td>
                                                        <td width="20%">{_.find(fieldTypes, { value: rec.type }) ? _.find(fieldTypes, { value: rec.type }).label : rec.value}</td>
                                                        <td width="20%">
                                                            <div className="user-list-items-info pr-0 ">
                                                                <h4>{rec.levelName}</h4>
                                                                <h5>{rec.levelValue}</h5>
                                                            </div>
                                                        </td>
                                                        <td width="10%" className=" border-right-0"><div className="float-left">
                                                            <span className={`switch switch-sm mr-2 ${rec.disableEdit === true ? 'btn-disable' : ''}`}>
                                                                <InputCheckBox
                                                                    className={`switch`}
                                                                    id={`${rec.value}_${index}`}
                                                                    name={rec.value}
                                                                    checked={Boolean(rec.active)}
                                                                    onChange={(e) => { handleSwitchChange(e, index, rec) }} />
                                                                <label htmlFor={`${rec.value}_${index}`}></label>
                                                            </span>
                                                        </div></td>
                                                        <td style={{ width: '60px' }} className=" border-left-0 border-right-0">
                                                            <div className="action-fix">
                                                                {
                                                                    (rec.disableEdit === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                                                                        <i className="ico icon-lock"></i>
                                                                    </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        hadleEditLookup(rec)
                                                                    }}>
                                                                        <i className="ico icon-edit"></i>
                                                                    </div>)
                                                                }
                                                                {
                                                                    (rec.disableEdit === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                                                                        <i className="ico icon-lock"></i>
                                                                    </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        hadleDeleteLookup(rec)
                                                                    }}>
                                                                        <i className="ico icon-delete"></i>
                                                                    </div>)
                                                                }

                                                            </div>
                                                        </td>
                                                        <td style={{ width: '20px' }} className=" border-left-0"><div className="field-drag"> <i className="ico icon-drag"></i></div></td>

                                                        {provided.placeholder}
                                                    </tr>)}
                                                </Draggable>
                                            })
                                        }
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </table>
                    </DragDropContext>
                </div>) : (
                    (
                        <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                            <div className='h-100  w-100'>
                                <div className='spinner-loader h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center loader-primary' style={{ flexDirection: 'column' }}>
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2">
                                            <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )
        }
        <PopUpModal show={showDynamicOptionModel}>
            <AddDynamicOptions
                {...props}
                show={showDynamicOptionModel}
                title={popHeader}
                handleClose={handleModelClose}
                lookup={selectedLookup}
                activeLookup={'enquiryOptionsDF'}
                allLookups={allLookups}
                selectedOEM={selectedOEM}
                groupOptions={groupOptions}
                clientOptions={clientOptions}
                regionOptions={regionOptions}
                selectedLevel={selectedLevel}
            />
        </PopUpModal>
    </>);
}

export default SortAppraisalFields;