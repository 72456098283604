import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { collection, docData } from 'rxfire/firestore';

//import { StageProvider, StageConsumer } from './provider'
import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
//import QuickView from "./quickview";
import FilterPanel from './filterPanel'
//import TransferOwner from '../common/transfer'
import CommonHelper from '../../services/common';
import { allFleetProFields, userLevelOptions } from './viewModel'
import images from '../../images'
//import LeadTransfer from '../pipeline/leadTransfer'
import ReAuthenticate from '../common/reAuthenticate';
import { fleetProStatus } from '../../services/enum';
//import QuickView from "./quickview";
import AddFleet from './add'
import AssignFleet from './assign/'
import VehicleBookings from './calndar';

const ListReducer = (state, action) => {
    function updateData() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateData()
        }
        default:
            return state;
    }
};



const FleetListView = (props) => {
    const [enquiries, dispatch] = useReducer(ListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [fleetProFields, setfleetProFields] = useState(props.dealersettings.fleetProFields)
    const pageLimit = 20
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.fleetProSearchText ? localStorage.fleetProSearchText : null)
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [fleetModal, setfleetModal] = useState({ show: false, docID: null })
    const [fleetAssignModal, setfleetAssignModal] = useState({ show: false, docID: null, fleetProID: null, fleetPro: null })
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [pipelineCount, setPipelineCount] = useState(0)
    const [bookingsModal, setBookingsModal] = useState({ show: false, fleetProID: null })

    const [enquiryOptions, setEnquiryOptions] = useState([])

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const _limit = 30

    const [showReAuthentication, setReAuthentication] = useState(false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.fleetProPermissions)) ? props.dealersettings.rolePermissions.fleetProPermissions : null);

    const readOnlyFleetVehicle = ((!_.isEmpty(_permissions) && _permissions.readOnlyFleetVehicle) ? true : false);
    //const accessToAddVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToAddVehicle && !readOnlyFleetVehicle) ? true : false);
    const accessToEditVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToEditVehicle && !readOnlyFleetVehicle) ? true : false);
    const accessToDeleteVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToDeleteVehicle && !readOnlyFleetVehicle) ? true : false);
    //const accessToEditDefleetDate = ((!_.isEmpty(_permissions) && _permissions.accessToEditDefleetDate && !readOnlyFleetVehicle) ? true : false);
    const accessToVehicleScheduler = ((!_.isEmpty(_permissions) && _permissions.accessToVehicleScheduler && !readOnlyFleetVehicle) ? true : false);
    const accessToVehicleBooking = ((!_.isEmpty(_permissions) && _permissions.accessToVehicleBooking && !readOnlyFleetVehicle) ? true : false);
   // const accessToScheduleTransit = ((!_.isEmpty(_permissions) && _permissions.accessToScheduleTransit && !readOnlyFleetVehicle) ? true : false);
    const accessToAssign = ((!_.isEmpty(_permissions) && _permissions.accessToAssign && !readOnlyFleetVehicle) ? true : false);
    // const accessToRequest = ((!_.isEmpty(_permissions) && _permissions.accessToRequest && !readOnlyFleetVehicle) ? true : false);
    // const accessToApprove = ((!_.isEmpty(_permissions) && _permissions.accessToApprove && !readOnlyFleetVehicle) ? true : false);
    // const accessToReturn = ((!_.isEmpty(_permissions) && _permissions.accessToReturn && !readOnlyFleetVehicle) ? true : false);
    // const accessToReceive = ((!_.isEmpty(_permissions) && _permissions.accessToReceive && !readOnlyFleetVehicle) ? true : false);
    // const accessToMarketingFleet = ((!_.isEmpty(_permissions) && _permissions.accessToMarketingFleet) ? true : false);
    // const accessToPRFleet = ((!_.isEmpty(_permissions) && _permissions.accessToPRFleet) ? true : false);
    // const accessToMSOAssest = ((!_.isEmpty(_permissions) && _permissions.accessToMSOAssest) ? true : false);
    const accessToFleetHistory = ((!_.isEmpty(_permissions) && _permissions.accessToFleetHistory) ? true : false);
    // const accessToViewAllocatedFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewAllocatedFleet) ? true : false);
    // const accessToViewFOKFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewFOKFleet) ? true : false);
    // const accessToOnFleetingVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOnFleetingVehicle) ? true : false);
    // const accessToOperationalVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOperationalVehicle) ? true : false);
    // const accessToDeFleetVehicles = ((!_.isEmpty(_permissions) && _permissions.accessToDeFleetVehicles) ? true : false);

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         if (searchText && searchText.length > 2) {
    //             setDataload(true)
    //         }

    //         if (searchText === '')
    //             setDataload(true)
    //     }, 1000);
    //     return () => clearTimeout(timeoutId);
    // }, [searchText]);

    useEffect(() => {
        let headerFields = fleetProFields;
        let allHeaderFields = allFleetProFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [fleetProFields])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allFleetProFields.some(e => e.value === rec.value))
                allFleetProFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        fleetProFields && fleetProFields.forEach(rec => {
            if (!allFleetProFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setfleetProFields(_.differenceBy(fleetProFields, deleteids))
    }, [enquiryOptions])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;
            const tipSettings = Object.assign({}, props.dealersettings.client.fleetPro);

            let _enquiryOptions = [];
            tipSettings.fleetOptionsDF && tipSettings.fleetOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setDataload(true);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
        }
    }, [])


    const handleStatusFilter = (_value, _key) => {
        if (_value) {
            if (!_.isEmpty(localStorage.fleetProPipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.fleetProPipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value[_key] = _value
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { [_key]: _value }
                }
                handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { [_key]: _value }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.fleetProPipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.fleetProPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty(_key))
                handleClearFilter();
            else {
                delete _pipelineFilter.value[_key];
                handleApplyFilter(_pipelineFilter);
            }
        }
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'fleetPro')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            fleetproid: 0
        })
    }

    const sidepanelOpen = (documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            fleetproid: documentID
        })

    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubFleetProList && window.unsubFleetProList.unsubscribe();
            window.unSubfleetProPipelineLstCnt && window.unSubfleetProPipelineLstCnt.unsubscribe();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.fleetProPipelineFilter', isFilterApplied, localStorage.fleetProPipelineFilter)
        if (localStorage.fleetProPipelineFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.fleetProPipelineFilter])



    useEffect(() => {
        if (!checkDataLoad) {
            return
        }
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (isFilterApplied && pageNum === 0)
            setEnquiryLoader(true)

        // if ((isFilterApplied || localStorage.fleetProPipelineFilter) && isValidFilter()) {
        //     let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        //     let _pipelineFilter = JSON.parse(localStorage.fleetProPipelineFilter);
        //     let _filter = Object.assign({}, _pipelineFilter.value);



        //     /* DATE RANGE FILTER */
        //     if (!_.isEmpty(_filter.date)) {
        //         _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
        //         delete _filter['date'];
        //     }

        //     if (_filter.rottenDays &&
        //         !_.isEmpty(dealersettings) &&
        //         !_.isEmpty(dealersettings.client) &&
        //         !_.isEmpty(dealersettings.client.fleetPro) &&
        //         !_.isEmpty(dealersettings.client.fleetPro.pipelines)) {
        //         _filter.pipelineSettings = JSON.stringify(dealersettings.client.fleetPro.pipelines)
        //     }

        //     if (searchText) _filter.keywords = searchText;

        //     if (!_.isEmpty(_filter?.dealer)) {
        //         _filter.clientID = _filter.dealer;
        //     }
        //     else if (!_.isEmpty(_filter?.group)) {
        //         _filter.levelID = _filter.group;
        //         _filter.clientID = CommonHelper.getAllFleetClientsByLevel(dealersettings, null, 'group', _filter.group.split(','), true)
        //     }
        //     else if (!_.isEmpty(_filter?.subregion)) {
        //         let levelIds = _filter.subregion.split(',')
        //         let _dealers = CommonHelper.getAllFleetClientsByLevel(dealersettings, null, 'subregion', levelIds)
        //         if (!_.isEmpty(_dealers)) {
        //             _dealers.forEach(doc => {
        //                 if (!_.isEmpty(doc.group)) levelIds.push(doc.group)
        //             })
        //             _filter.clientID = _dealers.map(r => { return r.id }).join(',')
        //         }
        //         if (!_.isEmpty(levelIds)) _filter.levelID = _.uniq(levelIds).join(',');
        //     }
        //     else if (!_.isEmpty(_filter?.region)) {
        //         let levelIds = _filter.region.split(',')
        //         let _dealers = CommonHelper.getAllFleetClientsByLevel(dealersettings, null, 'region', levelIds)
        //         if (!_.isEmpty(_dealers)) {
        //             _dealers.forEach(doc => {
        //                 if (!_.isEmpty(doc.subregion)) levelIds.push(doc.subregion)
        //                 if (!_.isEmpty(doc.group)) levelIds.push(doc.group)
        //             })
        //             _filter.clientID = _dealers.map(r => { return r.id }).join(',')
        //         }
        //         if (!_.isEmpty(levelIds)) _filter.levelID = _.uniq(levelIds).join(',');
        //     }
        //     else {
        //         let _userLevel = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: dealersettings.level })?.index)
        //         let _levelIDs = [];
        //         _userLevel.forEach(level => {
        //             if (level.value === 'individual')
        //                 _filter.clientID = CommonHelper.getAllFleetProByLevel(dealersettings, level.value, true);
        //             else
        //                 _levelIDs = _.union(_levelIDs, CommonHelper.getAllFleetProByLevel(dealersettings, level.value, true, true));
        //         });
        //         if (!_.isEmpty(_levelIDs))
        //             _filter.levelID = _levelIDs.join(',')

        //     }
        //     if (_filter.hasOwnProperty('dealer')) delete _filter['dealer'];
        //     if (_filter.hasOwnProperty('group')) delete _filter['group'];
        //     if (_filter.hasOwnProperty('subregion')) delete _filter['subregion'];
        //     if (_filter.hasOwnProperty('region')) delete _filter['region'];
        //     _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
        //     let _searchObject = {
        //         "type": "searchFleetPro",
        //         "filters": JSON.stringify(_filter),
        //         "sortOrder": "addedDate desc",
        //         "pageNum": pageNum,
        //         "pageLimit": pageLimit
        //     }

        //     _searchObject.clientID = _filter.clientID ? _filter.clientID : clientID;
        //     _searchObject.levelID = _filter.levelID ? _filter.levelID : null;

        //     //console.log('filter-searchfleetPro', _searchObject, pageNum, _filter);
        //     const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        //     searchEnquiries(_searchObject).then((response) => {
        //         //console.log('generic-searchData', _rec, response);
        //         if (response.data.success && (isFilterApplied || localStorage.fleetProPipelineFilter) && isValidFilter()) {
        //             onCollectionTIP(response.data);
        //             setPipelineCount({
        //                 advance: {
        //                     total: response.data.total
        //                 }
        //             })
        //         }
        //         else {
        //             setEnquiryLoader(false)
        //             setDataload(false)
        //             setPaging(false)
        //         }
        //     });

        //     window.unsubFleetProList && window.unsubFleetProList.unsubscribe();
        //     window.unSubfleetProPipelineLstCnt && window.unSubfleetProPipelineLstCnt.unsubscribe();
        // }
        // else {

        let searchKeywords = searchText;
        let searchOwners = [];
        var collectionPromise = [];

        if (localStorage.fleetProPipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.fleetProPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
            searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

            setSearchText(searchKeywords);
            localStorage.fleetProSearchText = searchKeywords;

        }


        let _levelIDs = CommonHelper.getAllFleetProByLevel(dealersettings, dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
            let refEnquiryData = window.firebase.firestore().collection('fleetPro')
                .where('isDeleted', '==', false)
                .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
            //.orderBy('addedDate', 'desc')

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                if (formattedSearchText.trim())
                    refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (hasMore[k]) {
                refEnquiryData = refEnquiryData
                    .startAfter(hasMore[k])
                    .limit(pageLimit)
            }
            else {
                if (isPaging) {
                    refEnquiryData = '';
                } else {
                    refEnquiryData = refEnquiryData
                        .limit(pageLimit)
                }
            }
            collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
        }

        if (collectionPromise.length > 0) {
            window.unsubFleetProList = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    let i = 0;
                    let _hasMore = {};
                    allDocs.forEach(doc => {
                        if (doc.length > 0 && doc.length === pageLimit) {
                            _hasMore = {
                                ..._hasMore,
                                [i]: doc[doc.length - 1]
                            };
                        }
                        else {
                            _hasMore = {
                                ..._hasMore,
                                [i]: null
                            };
                        }
                        querySnapshot.push(...doc);
                        i++;
                    });
                    setHasMoreData(_hasMore);
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setEnquiryLoader(false)
            setDataload(false)
            setPaging(false)
        }
        //}


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = CommonHelper.convertFleetProVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         }, props);
        //         if (change.type === 'added') {
        //             actionType = "ADD_LIST";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_LIST"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVE_LIST"
        //         }
        //     }
        // })
        let _enquiries = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = CommonHelper.convertFleetProVM({
                    ...doc.data(),
                    documentID: doc.id
                }, props);
                _enquiries.push(logVM)
            });

            // if (searchText && !localStorage.defaultModule.includes('oem')) {
            //     let formattedSearchText = CommonHelper.parseSearchText(props.dealersettings, searchText)
            //     if (formattedSearchText.trim())
            //         _enquiries = _enquiries.filter(a => CommonHelper.getAllFleetProDealers(props.dealersettings, true).some(b => a.clientIDs?.includes(b)))
            // }

        }

        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _.uniqBy(_enquiries, 'documentID'),
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const onCollectionTIP = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = CommonHelper.convertFleetProVM(doc, props);
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const isValidFilter = () => {
        return true;
        // if (localStorage.fleetProPipelineFilter) {
        //     let _pipelineFilter = JSON.parse(localStorage.fleetProPipelineFilter);
        //     let _filter = Object.assign({}, _pipelineFilter.value);

        //     // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
        //     //     return false;

        //     // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
        //     //     return false;

        //     // if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
        //     //     return false;

        //     return true;
        // }
        // else {
        //     return false;
        // }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ fleetProFields: fields }, { merge: true })
            .then(() => {
                setfleetProFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('fleetProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {

        return <>
            <div style={{ width: '250px' }}>

                <div style={{ paddingTop: '5px', float: 'left' }}>
                    <a href="#" className="mini-button-fleet-green  ml-2" title={'Vehicle Scheduler'} onClick={(e) => {
                        e.preventDefault();
                        const SONO = CommonHelper.getFleetProDynamicField(obj.dynamicFields, 'sono', props.dealersettings?.client?.fleetPro?.fleetOptionsDF)
                        setBookingsModal({
                            show: true,
                            title: `Booking Calendar`,
                            subTitle: ` - ${obj?.make || ''} ${obj?.model || ''} ${SONO ? '(SONO: ' + SONO + ')' : ''}`,
                            fleetProID: id,
                            accessToVehicleScheduler,
                            accessToVehicleBooking
                        })
                    }}> <i className="ico icon-Calendar"></i></a>
                    {
                        accessToFleetHistory
                            ?
                            <a href="#" className="mini-button-fleet-orange ml-2" title={'Fleet History'} onClick={(e) => { e.preventDefault(); props.history.push("/fleetpro/details/" + id); }}> <i className="ico icon-history"></i></a>
                            :
                            <a href="#" className="mini-button-fleet-orange ml-2" title={'Fleet History'} onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }

                    {
                        accessToEditVehicle
                            ?
                            <a href="#" className="mini-button ml-2" title={'Fleet Edit'} onClick={(e) => { e.preventDefault(); handleFleetOpen(id) }}> <i className="ico icon-edit"></i></a>
                            :
                            <a href="#" className="mini-button ml-2" title={'Fleet Edit'} onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }
                    {
                        accessToDeleteVehicle
                            ?
                            <a href="#" className="mini-button ml-2" title={'Fleet Delete'} onClick={(e) => { e.preventDefault(); handleDeleteFleet(id, obj) }}> <i className="ico icon-delete"></i></a>
                            :
                            <a href="#" className="mini-button ml-2" title={'Fleet Delete'} onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }

                </div>

                <div className="service-section-tbl-button">
                    {
                        accessToAssign && [fleetProStatus.OPERATIONAL].includes(obj.statusValue)
                            ?
                            <button type="submit" className="btn btn-sm btn-primary float-left ml-2" onClick={(e) => { e.preventDefault(); handleFleetAssignOpen(id, obj?.objDoc) }}><i className="ico icon-close"></i> Assign</button>
                            :
                            <button type="submit" className="btn btn-sm btn-primary float-left ml-2 opacity-fleet-btn" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}><i className="ico icon-close"></i> Assign</button>
                    }

                </div>

            </div>

        </>
    }

    const handleDeleteFleet = async (id, data) => {
        const { dealersettings } = props;
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            html: CommonHelper.showLocale(props, `You want to delete this fleet.`),
            text: data.title ? data.title : '',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {

                const updateRef = window.firebase.firestore().collection('fleetPro').doc(id);
                updateRef.update({
                    isDeleted: true,
                    modifiedBy: dealersettings ? dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }).then((docRef) => {

                    //if (props.handleRouteRemove) props.handleRouteRemove({ id: id, clientid: data.clientID }, 'fleetPro');

                    toast.notify(`Fleet deleted successfully`, {
                        duration: 2000,
                    });

                    // if (!_.isEmpty(data)) {
                    //     dispatch({
                    //         type: "REMOVE_LIST",
                    //         data: data
                    //     });
                    // }

                    let logNotes = 'Fleet deleted';
                    if (!_.isEmpty(data) && data.title)
                        logNotes = 'Fleet: ' + data.title + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: 'fleetPro',
                        subType: 'delete',
                        recordId: id,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                    CommonHelper.saveAuditLog(_objLogData)

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    const handleDetailclick = (e, id) => {

        if (accessToFleetHistory)
            props.history.push("/fleetPro/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_LIST",
                data: enq
            });
            window.firebase.firestore().doc(`fleetPro/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('service updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const updateList = (objdata) => {
        if (!_.isEmpty(objdata) && !_.isEmpty(localStorage.fleetProPipelineFilter)) {
            let data = CommonHelper.convertFleetProVM(objdata, props);

            if (!_.isEmpty(data)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: data
                });
            }
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('fleetProPipelineFilter', JSON.stringify(_filter));
            //localStorage.fleetProSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.fleetProPipelineFilter)) {
            localStorage.removeItem('fleetProPipelineFilter');
            localStorage.fleetProSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
            setPipelineCount(0);
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }



    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.fleetProPipelineFilter ? JSON.parse(localStorage.fleetProPipelineFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.fleetPro) &&
            !_.isEmpty(props.dealersettings.client.fleetPro.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.fleetPro.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )

        if (!_filter.clientID)
            _filter.clientID = CommonHelper.getAllFleetProDealers(props.dealersettings)
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchFleetPro",
            "filters": JSON.stringify(_filter),
            "sortOrder": "addedDate desc",
            "pageNum": 0,
            "pageLimit": total > 999 ? 5000 : (total ? total : 5000)
        }

        _searchObject.clientID = _filter.clientID;

        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = CommonHelper.convertFleetProVM(doc, props);
                _enquiries.push(logVM)
            });
            setCSVData(_enquiries)
            csvBtn.current && csvBtn.current.link.click();
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'fleetPro',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleFleetOpen = (docID) => {
        setfleetModal({ show: true, docID: docID })
    }
    const handleFleetClose = (id, data) => {

        if (!_.isEmpty(data)) updateList(data);
        setfleetModal({ show: false, docID: null })
    }

    const handleFleetAssignOpen = (fleetProID, objData) => {
        setfleetAssignModal({ show: true, docID: null, fleetPro: objData, fleetProID: fleetProID })
    }
    const handleFleetAssignClose = (id, data) => {
        setfleetAssignModal({ show: false, docID: null, fleetProID: null, fleetPro: null })
    }

    const handleBookingModelClose = (id, data) => {
        setBookingsModal({ show: false })
    }

    return (
        <LayoutConsumer>
            {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => dealersettings ? (


                <div className={`middle-wrapper h-100`}>

                    <FilterPanel
                        //pipeline={_fleetProPipeline}
                        customFilters={customFilters}
                        handlePipelineChange={(val) => {
                            updateDealerSettings('fleetProPipeline', val);
                            setPageNum(0);
                            if (!_.isEmpty(localStorage.fleetProPipelineFilter))
                                localStorage.removeItem('fleetProPipelineFilter');
                        }}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        handleApplyFilter={handleApplyFilter}
                        handleClearFilter={handleClearFilter}
                        handleStatusFilter={handleStatusFilter}
                        showMore={true}
                        stageCounts={pipelineCount}
                        csvHeader={csvHeader}
                        csvData={csvData}
                        datalist={enquiries}
                        view="list"
                        isFilterApplied={isFilterApplied}
                        searchText={searchText}
                        handlesearchText={(val) => {
                            setSearchText(val);
                            setTimeout(() => {
                                setDataload(true);
                                //handleStatusFilter(val, 'keywords');
                            }, 1000);

                            setPaging(false);
                            setHasMoreData([])
                            setPageNum(0);
                        }}
                        selectedIds={selectedIds}
                        excelDownload={excelDownload}
                        csvBtn={csvBtn}
                        handleFleetOpen={handleFleetOpen}
                    />
                    <div className={`h-100`}>
                        {
                            enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={_.orderBy(enquiries, 'addedDate', 'desc')}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allFleetProFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(fleetProFields && fleetProFields.length > 0) ? fleetProFields : allFleetProFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                    />
                                </div>
                                )
                        }


                    </div>



                    {/* <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>*/}
                    <PopUpModal show={fleetModal.show}>
                        <AddFleet
                            {...props}
                            docID={fleetModal.docID}
                            show={fleetModal.show}
                            clsActive='overlay-modal active'
                            handleClose={handleFleetClose}
                            title={fleetModal.docID ? 'fleet' : 'addFleet'}
                        >
                        </AddFleet>
                    </PopUpModal>
                    <PopUpModal show={fleetAssignModal.show}>
                        <AssignFleet
                            {...props}
                            docID={fleetAssignModal.docID}
                            fleetProID={fleetAssignModal.fleetProID}
                            fleetPro={fleetAssignModal.fleetPro}
                            show={fleetAssignModal.show}
                            clsActive='overlay-modal active'
                            handleClose={handleFleetAssignClose}
                            title={fleetAssignModal.docID ? 'fleet' : 'assignFleet'}
                        >
                        </AssignFleet>
                    </PopUpModal>
                    <PopUpModal show={bookingsModal.show}>
                        <VehicleBookings
                            {...props}
                            {...bookingsModal}
                            handleClose={handleBookingModelClose}
                        />
                    </PopUpModal>

                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default FleetListView;