/** LIBRARIES */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class ReactTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    //comment by thaha - on time change full state returns empty...
    //shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.value !== this.props.value)
    //         return true;
    //     else if (nextProps.className !== this.props.className)
    //         return true;
    //     else
    //         return false;
    // }

    handleTimeChange = (time) => {
        const { name, value, onChange } = this.props;
        if (value) {
            const newDate = moment(value)._d
            newDate.setHours(time.getHours());
            newDate.setMinutes(time.getMinutes());
            //console.log('type1', newDate, name)
            onChange(newDate, name)
        }
        else {
            //console.log('type2', time, name)
            onChange(time, name)
        }

    };

    onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }
    render() {
        const { name, onChange, timeIntervals, placeholder, value, className, minTime, maxTime, excludedTimes, isClearable } = this.props;
        //console.log('RENDER - React Time Picker', name, value)
        return (
            <DatePicker
                //ref={el => this.onDatepickerRef(el)}
                selected={value ? moment(value)._d : null}
                onChange={val => { onChange(val, name) }}
                //onChange={val => { this.handleTimeChange(val) }}
                placeholderText={placeholder}
                className={className}
                showTimeSelect
                showTimeSelectOnly
                showPopperArrow={false}
                timeIntervals={timeIntervals}
                timeCaption="Time"
                dateFormat={"h:mm aa"}
                minTime={minTime}
                maxTime={maxTime}
                popperClassName="custom-time-pop"
                onChangeRaw={(e) => e.preventDefault()}
                excludeTimes={excludedTimes}
                isClearable={isClearable}
            />
        );
    }
}