import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import { TableView, PopUpModal } from '../../components'
import { collectionFields } from './viewModel'
import Translate from '../../constants/translate'
import { default as _images } from '../../images';
import SyncData from './syncCollection';
import UpdateAttributes from './updateAttributes';
import NumberFormat from 'react-number-format'


const MeilisearchIndexes = (props) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth - 30,
    windowHeight: window.innerHeight - 185,
  });
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(true)
  const [showModel, setShowModel] = useState({ show: false })
  const [updateRules, setUpdateRules] = useState({ show: false })
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth - 30,
        windowHeight: window.innerHeight - 185,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      //window.unSubscribeCompanies && window.unSubscribeCompanies();
    };
  }, []);

  useEffect(() => {
    async function getAllIndexes() {
      const allCollection = await props.client.getIndexes({ limit: 100 });
      const allStats = await props.client.getStats();
      let _collection = [];
      allCollection && allCollection.results.forEach(rec => {
        _collection.push({
          name: rec.uid,
          value: rec.uid,
          primaryKey: rec.primaryKey,
          uid: rec.uid,
          numberOfDocuments: allStats ? <>Documents : <NumberFormat value={allStats.indexes[rec.uid].numberOfDocuments} displayType={'text'} thousandSeparator={true} /></> : null
        })
      });
      for (let i = 0; i < _collection.length; i++) {
        const allSettings = await props.client.index(_collection[i].value).getSettings();
        if (allSettings) {
          _collection[i].allSettings = allSettings;
          if (allSettings.filterableAttributes.length > 0) {
            _collection[i].strfilterableAttributes = allSettings.filterableAttributes.join(',');
            _collection[i].arrFilterAttributes = allSettings.filterableAttributes;
            _collection[i].filterAttributes = (<div className="Vehicle-availability">
              {
                allSettings.filterableAttributes.map((rec, index) => {
                  return <div key={index}><span>{rec}</span></div>
                })
              }
            </div>)
          }

          if (allSettings.searchableAttributes.length > 0) {
            _collection[i].strsearchableAttributes = allSettings.searchableAttributes.join(',');
            _collection[i].arrSearchAttributes = allSettings.searchableAttributes;
            _collection[i].searchAttributes = (<div className="Vehicle-availability">
              {
                allSettings.searchableAttributes.map((rec, index) => {
                  return <div key={index}><span>{rec}</span></div>
                })
              }
            </div>)
          }

          if (allSettings.sortableAttributes.length > 0) {
            _collection[i].strsortableAttributes = allSettings.sortableAttributes.join(',');
            _collection[i].arrSortableAttributes = allSettings.sortableAttributes;
            _collection[i].sortableAttributes = (<div className="Vehicle-availability">
              {
                allSettings.sortableAttributes.map((rec, index) => {
                  return <div key={index}><span>{rec}</span></div>
                })
              }
            </div>)
          }

          if (allSettings.rankingRules.length > 0) {
            _collection[i].strrankingRules = allSettings.rankingRules.join(',');
            _collection[i].arrRankingRules = allSettings.rankingRules;
            _collection[i].rankingRules = (<div className="Vehicle-availability">
              {
                allSettings.rankingRules.map((rec, index) => {
                  return <div key={index}><span>{rec}</span></div>
                })
              }
            </div>)
          }
        }
      }
      setData(_collection);
      setLoader(false)
    }

    getAllIndexes();
  }, [refreshData])

  const handleActionClick = (id, data) => {
    return (
      <div className=''>
        <button
          type='button'
          className='btn btn-primary float-left mr-2'
          style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
          onClick={(e) => {
            e.preventDefault();
            setUpdateRules({
              show: true,
              title: 'Update Attributes',
              collection: data
            })
          }}
        >
          {' '}
          Update Rules{' '}
        </button>
        <button
          type='button'
          className='btn btn-green float-left mr-2'
          style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
          onClick={(e) => {
            e.preventDefault();
            setShowModel({
              show: true,
              title: 'Sync Data',
              collection: data
            })
          }}
        >
          {' '}
          <i
            className='ico icon-refresh'
            aria-hidden='true'
          ></i>{' '}
          Sync Data{' '}
        </button>

      </div>
    )
  }

  const handledetailsClick = (e, id, data) => {
    e.preventDefault();
  }



  const onSearchChange = (event) => {
    const text = event.target.value
    setSearchText(text)

  }

  const filterSearch = (name, filterAttributes, searchAttributes, sortableAttributes, rankingRules) => {
    let valToReturn = false
    const _searchText = searchText.toLowerCase()
    if (searchText && name) {
      if (name.toLowerCase().includes(_searchText.toLowerCase())) {
        valToReturn = true
      }
      if (filterAttributes.some(val => val.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }
      if (searchAttributes.some(val => val.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }
      if (sortableAttributes.some(val => val.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }
      if (rankingRules.some(val => val.toLowerCase().includes(_searchText))) {
        valToReturn = true
      }

    } else {
      valToReturn = true
    }

    return valToReturn
  }

  const handleModelClose = () => {
    setShowModel({
      show: false
    })
    setUpdateRules({
      show: false
    })
  }

  return (
    <>
      <div className="filter-panel ml-3">
        <div className="d-flex justify-content-between">
          <div className="filter-search search-icon">
            <div className='input-group template-search'>
              <input placeholder="Search" aria-label="Search" value={searchText} onChange={onSearchChange} />
              <div className='input-group-append input-search-clear'>
                {searchText.length > 0 && (
                  <a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
                    <i className='ico icon-remove'></i>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="float-right mr-4">
            <button type="button" className="btn btn-primary float-right mr-2" onClick={() => {
              setLoader(true);
              setRefreshData(!refreshData)
            }}><i className="ico icon-refresh"></i> Refresh</button>
          </div>
        </div>
      </div>
      {
        loader
          ?
          <>
            <div className="dashboard-widget-loader h-100">
              <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                <div className="spinner-border" role="status">
                  <span className="sr-only"><Translate text={'Loading...'} /></span>
                </div>
              </div>

            </div>
          </>
          :
          <>
          </>
      }
      <div className='contact-panel ml-3'>
        <TableView
          datalist={_.orderBy(data.map(rec => { return { ...rec, 'documentID': rec.value } }).filter(({ name, arrFilterAttributes, arrSearchAttributes, arrSortableAttributes, arrRankingRules }) => filterSearch(name, arrFilterAttributes, arrSearchAttributes, arrSortableAttributes, arrRankingRules)), ['name'], ['asc'])}
          height={windowSize.windowHeight}
          width={windowSize.windowWidth}
          columns={collectionFields}
          dynamicFields={collectionFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
          handleActionClick={handleActionClick}
          handleRowSingleClick={handledetailsClick}
          isSettings={false}
          isReorderable={false}
        />

      </div>
      <PopUpModal show={showModel.show}>
        <SyncData
          {...showModel}
          handleClose={handleModelClose}
          meiliSearchConfig={props.client}
        />
      </PopUpModal>
      <PopUpModal show={updateRules.show}>
        <UpdateAttributes
          {...updateRules}
          handleClose={handleModelClose}
          meiliSearchConfig={props.client}
        />
      </PopUpModal>
    </>
  )
}

export default MeilisearchIndexes