/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { ImageViewer, PopUpModal } from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';

/** VIEW-MODELS */
import { objStock } from '../stock/viewModel'


const TestDriveInfo = ({ testdrive, stockData, handleEditTestDrive, isDeivered, dealersettings }) => {
    const [editshow, setEditshow] = useState(false)
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const node = useRef();
    const {
        documentID, startDate, endDate, enquiryID, oemID,
        isVehicleHandOver, expectedReturn, status, notes
    } = testdrive;
    const stockLocations = dealersettings?.client?.clientSettings?.tdStockLocations || dealersettings?.client?.clientSettings?.stockLocations
    useEffect(() => {
        if (testdrive.stockID && (!_.isEmpty(stockData))) {
            const refTDStockData = window.firebase.firestore().collection('stock').doc(testdrive.stockID);
            refTDStockData.get().then((_testStock) => {
                if (_testStock.exists) {
                    const _stock = Object.assign({}, objStock);
                    const objTDStockData = Object.assign({}, _testStock.data());
                    for (let [key, value] of Object.entries(objTDStockData)) {
                        _stock[key] = value;
                    }
                    _stock.documentID = testdrive.stockID;
                    testdrive.stock = _stock;
                }
                else {
                    console.error("No such document!");
                }
            });
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleViewReport = () => {
        if (enquiryID && documentID) {
            firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/files`)
                .where('recordID', '==', documentID)
                .where('isDeleted', '==', false)
                .orderBy('modifiedDate', 'desc').limit(1).get()
                .then(response => {
                    if (response && response.size > 0) {
                        const objData = Object.assign({}, response.docs[0].data());
                        //   console.log('handleViewReport', objData)
                        if (objData.url)
                            window.open(objData.url, '_blank');
                        else {
                            Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available'), '', 'info')
                        }
                    } else {
                        Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available'), '', 'info')
                    }
                });
        }
        else {
            Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available.'), '', 'info')
        }

        setEditshow(false);
    }

    return (


        <div className="vehicle-item" id={'testD_' + documentID}>

            {
                oemID && localStorage.defaultModule !== 'oem'
                    ?
                    <></>
                    :
                    <>
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleEditTestDrive(documentID, status) }}>
                                        {
                                            (status === 'complete' || isDeivered)
                                                ?
                                                <><i className="ico icon-zoom"></i>  <Translate text={'view'} /></>
                                                :
                                                <><i className="ico icon-edit"></i>  <Translate text={'edit'} /></>
                                        }
                                    </a>
                                    </li>
                                    {
                                        status === 'complete'
                                            ?
                                            <li> <a href="#" onClick={(e) => { e.preventDefault(); handleViewReport(); }}> <i className="ico icon-waiver"></i>  <Translate text={'report'} /></a> </li>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>
            }


            <div className="testdrive-listing-tab flex-vehicle">
                <div className="vehicle-item-image">
                    {
                        (!_.isEmpty(testdrive.stock) && !_.isEmpty(testdrive.stock.images))
                            ?
                            <>
                                {
                                    testdrive.stock.images.map((data, index) => (
                                        <a key={index}
                                            data-fancy-box={`testgl_info_img_slider${documentID}`}
                                            className={index === 0 ? '' : 'hide'}
                                            href={data}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setImgViewer({
                                                    show: true,
                                                    images: testdrive.stock.images
                                                })
                                            }}
                                        >
                                            {index === 0 ?
                                                <>
                                                    <div className="zoom-ico" ><i className="ico icon-zoom"></i></div>
                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                </>
                                                : <></>}
                                        </a>
                                    ))
                                }
                            </>
                            :
                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                <img src={CommonHelper.showBrandLogo(dealersettings, testdrive?.stock?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                            </a>
                    }
                </div>
                <div className="vehicle-data" onClick={(e) => { e.preventDefault(); if (_.isEmpty(oemID) || localStorage.defaultModule === 'oem') handleEditTestDrive(documentID, status) }}>
                    <div className="vehicle-item-title">{testdrive.stock.make} {testdrive.stock.model}
                        {!_.isEmpty(testdrive.tags) &&
                            testdrive.tags.map((rec, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="badge badge-pill badge-white ml-1 badge-mini text-capitalize"
                                    >
                                        {CommonHelper.getNameByValue(dealersettings.integrations, rec.type)}
                                    </div>
                                );
                            })}
                        {
                            oemID && localStorage.defaultModule !== 'oem' ? <div className={`badge badge-pill badge-white  ml-1`}>{'OEM'}</div> : <></>
                        }
                    </div>
                    {
                        testdrive?.stock?.clientID && (!_.isEmpty(stockLocations) || testdrive.stock.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ? (<div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, testdrive.stock.clientID)}</strong></div>) : (<></>)
                    }
                    <div className="dashboard-testdrive-activity-column">
                        Stock#: <div title={CommonHelper.showLocale({ dealersettings }, 'stockNo')}>{testdrive.stock?.stockNo || '--'}</div>
                        {testdrive.stock?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'exteriorColor')}>{testdrive.stock?.extColor}</div></>)}
                        {testdrive.stock?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'year')}>{testdrive.stock?.year}</div></>)}
                        {testdrive.stock?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'registrationNumber')}>{testdrive.stock?.regNo}</div></>)}
                        {testdrive.stock?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'vinNo')}>{testdrive.stock?.vinNo}</div></>)}
                        {/* {modelPrice && {dealersettings}.displayPrice && testdrive.stock.price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={testdrive.stock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)} */}
                    </div>
                    {/* <div className="vehicle-item-info">{testdrive.stock.exteriorColor ? `${testdrive.stock.exteriorColor},` : ''} {testdrive.stock.year ? testdrive.stock.year : ''}</div> */}
                    <div className="testdrive-item-status">
                        {testdrive.stock.saleType ? <div className="badge badge-pill badge-white">
                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                testdrive.stock.saleType, '')}
                        </div> : <></>}
                        <div className={`badge badge-pill badge-testdrive-${status ? status : 'pending'}  ml-1`}>{status ? status.toUpperCase() : 'pending'}</div>

                    </div>

                </div>
            </div>
            <div className="testdrive-duration" onClick={(e) => { e.preventDefault(); if (_.isEmpty(oemID) || localStorage.defaultModule === 'oem') handleEditTestDrive(documentID, status) }}>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td width="50%"><Translate text={'startTime'} /></td>
                            <td width="50%"><Translate text={isVehicleHandOver && status === 'pending' ? 'expectedReturnTime' : 'endTime'} /></td>
                        </tr>
                        <tr>
                            <td className="label-duration">{(!_.isEmpty(startDate)) ? moment.unix(startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                            {isVehicleHandOver && status === 'pending'
                                ?
                                <td className="label-duration">{(!_.isEmpty(expectedReturn)) ? moment.unix(expectedReturn.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                :
                                <td className="label-duration">{(!_.isEmpty(endDate)) ? moment.unix(endDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                            }

                        </tr>
                    </tbody>
                </table>
            </div>
            {
                notes && (<div className="testdrive-new-info-notes">
                    <div className="testdrive-new-info-inner">{notes}</div>
                </div>)
            }

            <PopUpModal show={imageViewer.show}>
                <ImageViewer
                    {...imageViewer}
                    handleClose={() => { setImgViewer({ show: false, images: null }); }}
                />
            </PopUpModal>

        </div>


    )
};

export default TestDriveInfo;
