import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputText, ImageCropHook, ReactSelect, ImageViewer, PopUpModal } from '../../../../components'
import { makeVM, categories } from './viewModel'
import _images from '../../../../images'
import Translate from '../../../../constants/translate';

const AddMake = (props) => {
    const [make, setMake] = useState(props.make);
    const [errorFields, setErrorFields] = useState({});
    const newMake = !_.isEmpty(props.make) ? false : true
    const [loader, setLoader] = useState(false)
    const [imageLoader, setImageLoader] = useState(false)
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    useEffect(() => {
        if (_.isEmpty(props.make)) {
            const newmakeVM = Object.assign({}, makeVM);
            newmakeVM.documentID = window.firebase.firestore().collection(`brands`).doc().id;
            newmakeVM.addedDate = window.firebase.firestore.Timestamp.now();
            newmakeVM.addedBy = localStorage.uid;
            newmakeVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newmakeVM.modifiedBy = localStorage.uid;
            newmakeVM.category = 'Automotive';
            setMake(newmakeVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setMake({
                ...make,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setMake({
                ...make,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        });
    }

    const handleCropSelect = (_cropShow) => {

        const { src, id, type, ext } = _cropShow;
        setMake({
            ...make,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);

        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire('File format not supported. Please select image file.', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })

            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        setImageLoader(true)
        var storageRef = window.firebase.storage().ref(`brands/${make.documentID}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                window.firebase.storage().ref(`brands`).child(make.documentID).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setMake({
                            ...make,
                            [id]: dataURL
                        });
                    })
            })
    };

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setMake({
            ...make,
            [name]: value
        });

    }

    const saveMake = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(make['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(make['category'])) {
            formIsValid = false;
            errors['category'] = errorClass;
        }
        setErrorFields(errors)
        if (newMake && props.makes.some(item => item.name.toLowerCase() === make.name.toLowerCase() && item.category.toLowerCase() === make.category.toLowerCase())) {
            Swal.fire('Make with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        window.firebase.firestore().doc(`brands/${make.documentID}`)
            .set(make, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Make ' + (newMake ? 'added' : 'updated') + ' successfully', {
                    duration: 2000
                })
                props.handleClose(props.pipeline)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(make) ? (<></>) : (<>
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-small-popup"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100 pipeline-form-style">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > Make </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={make.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > Value </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'value'}
                                        className={`form-control ${errorFields["value"]}`}
                                        name="value"
                                        onChange={handleOnChange}
                                        value={make.value}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Category</label>
                                    <ReactSelect
                                        options={categories}
                                        name={"category"}
                                        placeholder={'select category'}
                                        onChange={(e) => {
                                            setMake({
                                                ...make,
                                                ['category']: e ? e.value : null
                                            });
                                        }}
                                        value={make.category}
                                        classNamePrefix={`${errorFields["category"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="settings-companyimage ml-0 mt-2">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (make.logo)
                                                ?
                                                <a data-fancy-box={`logo`} 
                                                href={make.logo} 
                                                onClick={(e) => { e.preventDefault(); 
                                                    setImgViewer({
                                                    show: true,
                                                    images: make.logo
                                                })}}>
                                                    <img className="img-object-fit-contain" src={make.logo} alt="" height="65" />
                                                </a>
                                                :
                                                <img src={_images.nocompany} alt="" height="65" />
                                        }
                                    </div>
                                    <div className="settings-profile-info mt-4">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="make-logo" className={`btn-common float-left mr-2 font-600`}>
                                                <Translate text={'Choose Picture'} />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="testee"
                                                    id="make-logo"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'profile', 'logo', 'Brand Logo')} />
                                            </label>
                                            <div className={`settings-delete-button ${_.isEmpty(make.logo) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setMake({
                                                        ...make,
                                                        ['logo']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a></div>
                                        </div>
                                        <div className=" settings-limit"><Translate text={'Max size 10 MB'} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveMake(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
        <ImageCropHook
            cropShow={cropShow}
            handleClose={handleCropClose}
            handleSelect={handleCropSelect}
        ></ImageCropHook>
        <PopUpModal show={imageViewer.show}>
            <ImageViewer
                {...imageViewer}
                handleClose={() => { setImgViewer({ show: false, images: null }); }}
            />
        </PopUpModal>
    </>
    );
}

export default AddMake;