import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { TableView } from '../../../../components';
import Swal from 'sweetalert2';

export const tableFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 2,
        default: true
    },
    {
        name: 'DMS Key',
        value: 'contactCode',
        sortValue: 'contactCode',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'contactCode',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

const FetchDMSKey = (props) => {
    const [searchText, setSearchText] = useState('')
    const [tableLoader, settableLoader] = useState(false)
    const [data, setData] = useState([])

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <button type="button" className="btn btn-primary" onClick={() => {
                    props.handleClose(data.contactCode)
                }}> <Translate text={'select'} /></button>
            </div>
        )
    }

    useEffect(() => {
        settableLoader(true)
        const fetchdmskey = window.firebase.functions().httpsCallable(`${props.type || 'autoit'}-getDMSKey`);
        fetchdmskey({
            "clientID": props.dealersettings.client.id,
            "name": props.user.name
        }).then(async (resp) => {
            settableLoader(false)
            if (resp?.data?.data?.length) {
                setData(resp.data.data.map(rec => {
                    return {
                        ...rec,
                        name: `${rec.name || ''} ${rec.surname || ''}`
                    }
                }))
            } else {
                Swal.fire(resp?.data?.message || 'No data found', '', 'error')
            }
        });
    }, [])

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-user"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid pr-0 pl-0">
                    <div className='settings-head form-style'>
                        <div className='row'>
                            <div className='filter-search search-icon col-md-4 pr-0'>
                                <input placeholder="search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className='common-table'>
                        {
                            tableLoader
                                ?
                                <>
                                    <div className="dashboard-widget-loader h-100">
                                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                :
                                <>
                                </>
                        }
                        <TableView
                            datalist={data.filter(({ name, displayName, surname }) =>
                            (!_.isEmpty(searchText) ? (
                                (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                (!_.isEmpty(displayName) ? displayName.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                (!_.isEmpty(surname) ? surname.toLowerCase().includes(searchText.toLowerCase()) : false)
                            ) : true)
                            )}
                            height={400}
                            width={750}
                            columns={tableFields}
                            dynamicFields={tableFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                            handleActionClick={handleActionClick}
                            handleRowSingleClick={() => { }}
                            isSorting={true}
                            isTranslate={true}
                            dealersettings={props.dealersettings}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default FetchDMSKey;