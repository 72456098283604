/** LIBRARIES */
import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";
import NumberFormat from 'react-number-format';
import { default as _images } from "../../../images";
import { firestoreDB } from "../../../services/helper";

//#endregion

class AutoGrab extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.unsubscribeAGLPV = null;
        this.state = {
            priceValuationLogDate: "",
            vehicle: {},
            valuation: {},
            loading: false,
            loadingValuation: false,
            showPriceBreakdown: false,

        };

        const _evalexpert = Object.assign(
            {},
            props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.integrations &&
            props.dealersettings.client.integrations.filter(
                (e) => e.type === "evalexpert"
            )[0]
        );

        this.evalexpertEnabled =
            localStorage.defaultModule !== "oem" &&
                _evalexpert &&
                _evalexpert.active &&
                _evalexpert.enabled
                ? true
                : false;
    }

    maxOfferPrice = (retail_price, offersBreakDown) => {
        let _totalCost = retail_price ? retail_price : 0;
        //const offersBreakDown = this.props.dealersettings?.client?.moduleSettings?.autograb?.subList;
        offersBreakDown && offersBreakDown.forEach(_data => { _totalCost += parseFloat(_data.cost); });
        return this.formatPrice(_totalCost);
    }

    formatPrice = (price) => {
        const formattedPrice = parseFloat(price)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");

        const resPrice = formattedPrice.endsWith(".00")
            ? formattedPrice.slice(0, -3)
            : formattedPrice;
        return `${resPrice}` || 0;
    };

    getAutoGrabData = async (vehicleId, type) => {
        const ref = window.firebase
            .firestore()
            .collection("autoGrabLogs")
            .where("clientID", "==", this.props.selectedVehicle?.clientID)
            .where("type", "==", type)
            .where("vehicleID", "==", vehicleId)
            .orderBy("logDate", "desc")
            .limit(1);
        let docID = "";
        const snap = await ref.get();
        let cachedData = {};
        if (snap?.docs[0]) {
            docID = snap.docs[0].id;
            cachedData = snap.docs[0].data();

        }
        if (cachedData?.logDate?.seconds) {
            this.setState({
                [`${type}LogDate`]: moment
                    .unix(cachedData?.logDate?.seconds)
                    .format("DD/MM/YYYY h:mm A"),
            });
        }
        return cachedData?.data || {};
    };


    getVehicleDetail = async (fetchLatest = false, allowFetch = true) => {


        this.setState({
            loading: true,
        });
        if (this.props.selectedVehicle?.autograbID) {
            const cachedData = await this.getAutoGrabData(this.props.selectedVehicle?.autograbID, "vehicleDetail");

            if (cachedData?.vehicle) {
                let vehicle = cachedData?.vehicle;
                vehicle[
                    "description"
                ] = `${vehicle.series || ''} ${vehicle.num_gears || ''}sp ${vehicle.num_cylinders || ''}cyl ${vehicle.transmission_type || ''} ${vehicle.body_type || ''} ${vehicle.manufacturer_drive_type || ''} ${vehicle.capacity_cc || ''}cc ${vehicle.power_kw || ''}KW ${vehicle.torque_nm}nm ${vehicle.fuel_type || ''} ${vehicle.engine_type || ''} ${vehicle.engineNo || ''} ${vehicle.num_doors || ''}DR ${vehicle.num_seats || ''}SEAT`;
                allowFetch = false;
                this.setState(
                    {
                        loading: false,
                        vehicle: vehicle || {},
                    },
                    () => {
                        this.loadPriceValuation(this.props.selectedVehicle?.autograbID);
                    }
                );
                return;
            }
        }


    };

    loadPriceValuation = (autograbID) => {
        if (!autograbID)
            return;

        this.unsubscribeAGLPV = window.firebase
            .firestore()
            .collection("autoGrabLogs")
            .where("clientID", "==", this.props.selectedVehicle?.clientID)
            .where("type", "==", 'priceValuation')
            .where("vehicleID", "==", autograbID)
            .orderBy("logDate", "desc")
            .limit(1)
            .onSnapshot((snap) => {
                let cachedData = {};
                if (snap?.docs[0]) {
                    cachedData = snap.docs[0].data();

                }
                if (cachedData?.logDate?.seconds) {
                    this.setState({
                        [`priceValuationLogDate`]: moment
                            .unix(cachedData?.logDate?.seconds)
                            .format("DD/MM/YYYY h:mm A"),
                    });
                }

                if (cachedData?.data?.prediction) {
                    this.setState({
                        loadingValuation: false,
                        valuation: cachedData.data.prediction,
                    });
                } else {
                    this.setState({
                        valuation: {},
                        loadingValuation: false,
                    });
                }

            });
    }

    getVehicleValuationDetail = async (
        fetchLatest = false,
        allowFetch = true
    ) => {


        if (
            this.props.selectedVehicle?.chassisNo &&
            this.props.selectedVehicle?.mileage
        ) {

            this.setState({
                loadingValuation: true,
            });

            if (!fetchLatest && this.props.selectedVehicle?.autograbID) {
                const cachedData = await this.getAutoGrabData(
                    this.props.selectedVehicle?.autograbID,
                    "priceValuation"
                );
                if (cachedData?.prediction) {
                    allowFetch = false;
                    this.setState({
                        loadingValuation: false,
                        valuation: cachedData?.prediction || {},
                    });
                    return;
                } else {
                    this.setState({
                        valuation: {},
                        loadingValuation: false,
                    });
                }
            }
            if (allowFetch || fetchLatest) {
                this.setState({
                    loadingValuation: true,
                });
                this.unsubscribeAGLPV && this.unsubscribeAGLPV();
                const vehicleValuations = window.firebase
                    .functions()
                    .httpsCallable("autograb-vehicleValuations");
                vehicleValuations({
                    clientID: this.props.selectedVehicle?.clientID,
                    vinNo: this.props.selectedVehicle?.chassisNo, //"LRWYHCFS6NC428809"
                    mileage: this.props.selectedVehicle?.mileage,
                })
                    .then((data) => {
                        let prediction = {};
                        if (data?.data?.success) {
                            prediction = data?.data?.data?.prediction;
                            if (data?.data?.data?.logDate) {
                                let logDate = moment
                                    .unix(data?.data?.data?.logDate?._seconds)
                                    .format("DD/MM/YYYY h:mm A");
                                this.setState({
                                    priceValuationLogDate: logDate,
                                });
                            }
                        } else {
                            Swal.fire(data?.data?.message, "", "error");
                        }
                        this.setState({
                            loadingValuation: false,
                            valuation: prediction,
                        });
                    })
                    .catch((error) => {
                        // Handle any errors
                        Swal.fire(
                            error?.message ||
                            "Something went wrong. Please contact support. error",
                            "",
                            "error"
                        )
                        this.setState({
                            valuation: {},
                            loadingValuation: false,
                        });
                        console.error(error);
                    });
            }
        }
    };

    getMonthNameFromNumber = (monthNumber) => {
        const monthName = moment()
            .month(monthNumber - 1)
            .format("MMM");
        return monthName;
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this._isMounted) this.getVehicleDetail(false, true);
            }
        );
    }
    componentWillUnmount() {
        this.unsubscribeAGLPV && this.unsubscribeAGLPV();
    }
    // subscribeNow = () => {
    //     const { dealersettings } = this.props;
    //     if (
    //         !_.isEmpty(dealersettings) &&
    //         !_.isEmpty(dealersettings.rolePermissions) &&
    //         !_.isEmpty(dealersettings.rolePermissions.admin) &&
    //         Object.keys(dealersettings.rolePermissions.admin)
    //             .filter(function (id) {
    //                 return dealersettings.rolePermissions.admin[id];
    //             })
    //             .some((e) => e === "modules")
    //     ) {
    //         this.handleModalClose();
    //         this.props.history.push("/settings/modules?autograb=true");
    //     }
    // };

    calculateAveragePrice(arr, key = "price") {
        if (arr.length === 0) {
            return 0; // To handle the case when the array is empty
        }

        const total = arr.reduce((sum, obj) => sum + obj[key], 0);
        const average = total / arr.length;
        return average;
    }

    render() {

        const { dealersettings, selectedVehicle } = this.props;
        const { vehicle, valuation, loadingValuation, priceValuationLogDate, showPriceBreakdown } = this.state;
        const autograbIntegratonEnabled =
            !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.integrations) &&
                dealersettings.client.integrations.filter(
                    (e) => e.type === "autograb"
                )[0] &&
                dealersettings.client.integrations.filter((e) => e.type === "autograb")[0]
                    .enabled === true
                ? true
                : false;
        const priceValuationEnabled =
            dealersettings?.client?.moduleSettings?.autograb?.priceValuation &&
            dealersettings?.client?.moduleSettings?.autograb?.enabled;

        let _autograb = dealersettings?.client?.integrations?.filter((e) => e.type === "autograb")[0];
        const offerLessBreakDowns = autograbIntegratonEnabled && !_.isEmpty(_autograb?.settings?.subList) ? _autograb?.settings?.subList :
            (dealersettings?.client?.moduleSettings?.autograb?.enabled ? dealersettings?.client?.moduleSettings?.autograb?.subList : []);

        const currencySymbol =
            !_.isEmpty(this.props.dealersettings) &&
                !_.isEmpty(this.props.dealersettings.client) &&
                !_.isEmpty(this.props.dealersettings.client.currency)
                ? this.props.dealersettings.client.currency.symbol + " "
                : "$ ";


        return (
            <>

                {this.state.loading ? (
                    <> </>
                ) : (

                    <div className="tradein-mini-market-section">

                        <div className="tradein-mini-market-vehicle">
                            <div className="tradein-mini-market-vehiclename">
                                {selectedVehicle?.modelDescription || vehicle?.title}
                            </div>

                            <div className="tradein-mini-market-vehicle-manufacturer">
                                <b>
                                    {"Transmission: "}
                                    {vehicle?.transmission}
                                </b>
                            </div>
                            <div className="tradein-mini-market-vehicle-manufacturer">
                                <b>Engine: {vehicle?.engine_type} {vehicle?.engineNo ? ', ' + vehicle?.engineNo : ''}</b>
                            </div>
                            <div className="tradein-mini-market-vehicle-manufacturer">
                                <b>Badge: {vehicle?.badge}</b>
                            </div>

                            <div className="tradein-mini-market-vehicle-info">
                                {"Released: "}
                                {vehicle?.release_month
                                    ? this.getMonthNameFromNumber(
                                        vehicle?.release_month
                                    )
                                    : "--"}{" "} ,{vehicle?.release_year
                                        ? vehicle?.release_year
                                        : ""}
                            </div>
                            {vehicle?.description && (
                                <div className="tradein-mini-market-vehicle-info">
                                    {vehicle?.description}
                                </div>
                            )}

                            {/* <div className="tradein-mini-market-vehicle-manufacturer">
                                {'Manufacturer RRP:'} {currencySymbol}
                                {valuation?.price
                                    ? this.formatPrice(
                                        valuation?.price
                                    )
                                    : 0}
                            </div> */}
                            <div className="tradein-mini-market-vehicle-odometer">
                                {'Odometer:'}
                                {this.props.selectedVehicle?.mileage
                                    ? this.formatPrice(
                                        this.props.selectedVehicle?.mileage
                                    )
                                    : 0}{" KM"}

                            </div>
                        </div>


                        {/* {!priceValuationEnabled &&
                                                    !autograbIntegratonEnabled &&
                                                    !valuation?.trade_price &&
                                                    !loadingValuation && (
                                                        <div className="market-vehicle-trade ">
                                                            <div className="col">
                                                                <div className="vehicle-trade-counter">
                                                                    <div className="upgrade-market-lock">
                                                                        <i className="ico icon-lock"></i>
                                                                    </div>
                                                                    <p className="upgrade-market-lock-text">
                                                                        {" "}
                                                                        <b>Subscribe to view price </b>
                                                                    </p>
                                                                    <div className="upgrade-market-lock-button">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={this.subscribeNow}
                                                                        // data-toggle="modal"
                                                                        // data-target="#add-campaign"
                                                                        >
                                                                            {" Subscribe Now"}

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )} */}


                        {loadingValuation ? (
                            <div className="d-flex h-20 mt-4 justify-content-center align-items-center loader-primary">
                                <div
                                    role="status"
                                    className="spinner-border loader-primary"
                                ></div>
                                <p className="text-muted mt-3"></p>
                            </div>
                        ) : (
                            <>
                                <div>
                                    {valuation?.trade_price &&
                                        valuation?.retail_price && (
                                            <div className="market-vehicle-trade ">
                                                <div className="tradein-mini-trade-refresh">
                                                    {priceValuationLogDate && (
                                                        <span className="tradein-mini-counter-refreshdate">
                                                            {priceValuationLogDate}
                                                        </span>
                                                    )}
                                                    {priceValuationEnabled ||
                                                        autograbIntegratonEnabled ? (
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.getVehicleValuationDetail(true);
                                                            }}
                                                        >
                                                            {" "}
                                                            <i className="ico icon-refresh"></i>{" Refresh"}
                                                        </a>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="d-flex w-100">

                                                    {!_.isEmpty(offerLessBreakDowns) && (<div className="col">
                                                        <div className="tradein-mini-counter trade-voilet">
                                                            <p className="tradein-mini-count-text ">
                                                                {" Max Offer"}
                                                            </p>
                                                            <h2 className="tradein-mini-count-title">
                                                                <NumberFormat value={this.maxOfferPrice(valuation?.retail_price, offerLessBreakDowns)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                            </h2>
                                                        </div>
                                                    </div>)}
                                                    <div className="col">
                                                        <div className="tradein-mini-counter trade-yellow">
                                                            <p className="tradein-mini-count-text ">
                                                                {" Estimated Trade"}
                                                            </p>
                                                            <h2 className="tradein-mini-count-title">
                                                                {currencySymbol}
                                                                {valuation?.trade_price
                                                                    ? this.formatPrice(
                                                                        valuation
                                                                            ?.trade_price
                                                                    )
                                                                    : 0}
                                                            </h2>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="tradein-mini-counter trade-green">
                                                            <p className="tradein-mini-count-text ">
                                                                {" Estimated Retail"}

                                                            </p>
                                                            <h2 className="tradein-mini-count-title">
                                                                {currencySymbol}
                                                                {valuation
                                                                    ?.retail_price
                                                                    ? this.formatPrice(
                                                                        valuation
                                                                            ?.retail_price
                                                                    )
                                                                    : 0}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>

                                {
                                    !_.isEmpty(offerLessBreakDowns) && (
                                        <>

                                            {
                                                !showPriceBreakdown ? (
                                                    <div className="contact-showmore-btn mt-1"><a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            showPriceBreakdown: true
                                                        })
                                                    }}>{'Show Price Breakdown'}   <i className="ico icon-dropdown"></i></a></div>
                                                ) : <><div className="contact-showmore-btn show-less-ico mt-1"><a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        showPriceBreakdown: false
                                                    })
                                                }}>{'Hide Price Breakdown'}  <i className="ico icon-dropdown"></i></a></div></>
                                            }

                                            {showPriceBreakdown && (<div className="popover-content mt-2">
                                                <div className="form-style popover-middle-pipeline p-0">
                                                    <div className="labourcost-discountpop-head m-0">
                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                            <tbody>
                                                                <tr >
                                                                    <td className="labourcost-costtxt ">  {'Estimated Retail Price (ERP)'}:</td>
                                                                    <td className="labourcost-cost-box right-align"><NumberFormat value={valuation?.retail_price ? valuation?.retail_price : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                                </tr>
                                                                {
                                                                    offerLessBreakDowns.map((_data, index) => {
                                                                        return <tr key={index}>
                                                                            <td className="labourcost-costtxt ">  {_data.name} :</td>
                                                                            <td className="labourcost-cost-box right-align"><NumberFormat value={_data.cost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                <tr>
                                                                    <td className="labourcost-costtxt-green"> {'Offer Price Ceiling'} :</td>
                                                                    <td className="labourcost-cost-box-total right-align" ><span><NumberFormat value={this.maxOfferPrice(valuation?.retail_price, offerLessBreakDowns)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </>
                                    )}
                            </>
                        )}


                        {(priceValuationEnabled ||
                            autograbIntegratonEnabled) &&
                            this.props.selectedVehicle?.chassisNo &&
                            this.props.selectedVehicle?.mileage &&
                            !loadingValuation &&
                            !valuation?.trade_price ? (
                            <div className="vehicle-trade-button float-right">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.getVehicleValuationDetail();
                                    }}
                                >
                                    {"View Price Valuation"}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}


                    </div>

                )}


            </>
        );
    }
}

export default AutoGrab;
