/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import toast from 'toasted-notes'
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { InputCheckBox } from '../../../../components';
import Swal from 'sweetalert2'
import CommonHelper from '../../../../services/common';

const CustomAlerts = (props) => {
    const [data, setData] = useState();
    const [loader, setloader] = useState(false);

    useEffect(() => {
        const userDataDoc = window.firebase
            .firestore()
            .doc(`users/${localStorage.uid}`)
            .get()
            .then(snap => {
                if (snap.exists) {
                    setData(snap.data()?.alerts)
                }
                else {
                    setData(null)
                }
            });
    }, [])

    const onSave = () => {
        setloader(true);
        window.firebase
            .firestore()
            .doc(`users/${localStorage.uid}`)
            .set({ alerts: data }, { merge: true })
            .then(snapshot => {
                toast.notify('Settings updated successfully', {
                    duration: 2000,
                });
                setloader(false);
            }).catch(err => {
                console.log(err)
                Swal.fire(CommonHelper.showLocale(props, 'Something went wring.'), '', 'error');
            })
    }

    const handleCheckChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.checked
        })
    }


    return (
        <>
            <h2><Translate text={'Custom Alerts'} /></h2>
            <div className={`security-settings-inner form-style`}>
                <p><Translate text={'Be notified even when youre not on the system.'} /></p>
                <div className='divider-line'></div>
                <div className="switch-list" style={{ minHeight: '300px' }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <h5 className="settings-subhead"><Translate text={'Email Notifications'} /></h5>
                            <ul>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-leadAssignEmail"
                                        className="switch"
                                        name={'leadAssignEmail'}
                                        checked={Boolean(data?.leadAssignEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-leadAssignEmail"></label>
                                </span> <Translate text={'Email on Lead Assign'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-financeleadAssignEmail"
                                        className="switch"
                                        name={'financeleadAssignEmail'}
                                        checked={Boolean(data?.financeleadAssignEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-financeleadAssignEmail"></label>
                                </span> <Translate text={'Email on Finance Lead Assign'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-aftermarketleadAssignEmail"
                                        className="switch"
                                        name={'aftermarketleadAssignEmail'}
                                        checked={Boolean(data?.aftermarketleadAssignEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-aftermarketleadAssignEmail"></label>
                                </span> <Translate text={'Email on After Market Lead Assign'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-onMentionEmail"
                                        className="switch"
                                        name={'onMentionEmail'}
                                        checked={Boolean(data?.onMentionEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-onMentionEmail"></label>
                                </span> <Translate text={'Email on Mention Notes/Activity'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-lostApprovalEmail"
                                        className="switch"
                                        name={'lostApprovalEmail'}
                                        checked={Boolean(data?.lostApprovalEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-lostApprovalEmail"></label>
                                </span> <Translate text={'Email on Lost Approve/Reject'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-submitTradeinEmail"
                                        className="switch"
                                        name={'submitTradeinEmail'}
                                        checked={Boolean(data?.submitTradeinEmail)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-submitTradeinEmail"></label>
                                </span> <Translate text={'Email on Tradein link respond'} /></li>
                                {
                                    props?.dealersettings?.rolePermissions?.modules?.dealLog ? (
                                        <li> <span className="switch switch-sm mr-2">
                                            <InputCheckBox
                                                id="alerts-onCommentsEmail"
                                                className="switch"
                                                name={'onCommentsEmail'}
                                                checked={Boolean(data?.onCommentsEmail)}
                                                onChange={(e) => { handleCheckChange(e) }} />
                                            <label htmlFor="alerts-onCommentsEmail"></label>
                                        </span> <Translate text={'Email on Mention Sales Hub Comments'} /></li>
                                    ) : (<></>)
                                }
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <h5 className="settings-subhead"><Translate text={'SMS Notifications'} /></h5>
                            <ul>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-leadAssignSMS"
                                        className="switch"
                                        name={'leadAssignSMS'}
                                        checked={Boolean(data?.leadAssignSMS)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-leadAssignSMS"></label>
                                </span> <Translate text={'SMS on Lead Assign'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-financeleadAssignSMS"
                                        className="switch"
                                        name={'financeleadAssignSMS'}
                                        checked={Boolean(data?.financeleadAssignSMS)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-financeleadAssignSMS"></label>
                                </span> <Translate text={'SMS on Finance Lead Assign'} /></li>
                                <li> <span className="switch switch-sm mr-2">
                                    <InputCheckBox
                                        id="alerts-aftermarketleadAssignSMS"
                                        className="switch"
                                        name={'aftermarketleadAssignSMS'}
                                        checked={Boolean(data?.aftermarketleadAssignSMS)}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor="alerts-aftermarketleadAssignSMS"></label>
                                </span> <Translate text={'SMS on After Market Lead Assign'} /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="settings-footer mt-3">
                    <button type="button" className="btn btn-primary float-right" onClick={() => onSave()}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default CustomAlerts;
