import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import _images from '../../images'
import { PieChart, NoDataPieChart } from '../../components'
import { bdcStatus } from "./viewModel";

const StatusCounts = (props) => {
    const [data, setData] = useState([])

    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate


    useEffect(() => {
        if (props.pipelineEnquiries.length === 0) {
            //setLoader(false)
            return
        }

        var monthConverted = props.inboundleadEnquiries.filter(a =>
            (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                && a.oemConvertedDate.seconds >= monthStart.seconds
                && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.isConvertedGroup === true
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                        && a.isConverted === true
                        && a.pipelineValue !== 'LeadsBucket'
                        && a.convertedDate.seconds >= monthStart.seconds
                        && a.convertedDate.seconds <= monthEnd.seconds))
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));

        var monthShow = props.inboundleadEnquiries.filter(a =>
            a.bdcStatus === 'show'
            && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                && a.oemConvertedDate.seconds >= monthStart.seconds
                && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.isConvertedGroup === true
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.firstWalkinDate)
                        && a.isConverted === true
                        && a.pipelineValue !== 'LeadsBucket'
                        && a.firstWalkinDate.seconds >= monthStart.seconds
                        && a.firstWalkinDate.seconds <= monthEnd.seconds))
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));


        var monthNoshow = props.inboundleadEnquiries.filter(a =>
            a.bdcStatus === 'noshow'
            && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                && a.oemConvertedDate.seconds >= monthStart.seconds
                && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.isConvertedGroup === true
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                        && a.isConverted === true
                        && a.pipelineValue !== 'LeadsBucket'
                        && a.convertedDate.seconds >= monthStart.seconds
                        && a.convertedDate.seconds <= monthEnd.seconds))
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));

        var monthWon = props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
            && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                    && a.isConvertedGroup === true)) : (
                !_.isEmpty(a.convertedDate)
                && a.isConverted === true
                && a.pipelineValue !== 'LeadsBucket'
            ))
            && !_.isEmpty(a.wonDate)
            && a.wonDate.seconds >= monthStart.seconds
            && a.wonDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));

        var monthLost = props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'lost'
            && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                    && a.isConvertedGroup === true)) : (
                !_.isEmpty(a.convertedDate)
                && a.isConverted === true
                && a.pipelineValue !== 'LeadsBucket'
            ))
            && !_.isEmpty(a.lostDate)
            && a.lostDate.seconds >= monthStart.seconds
            && a.lostDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));


        var monthDelivered = props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'delivered'
            && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                    && a.isConvertedGroup === true)) : (
                !_.isEmpty(a.convertedDate)
                && a.isConverted === true
                && a.pipelineValue !== 'LeadsBucket'
            ))
            && !_.isEmpty(a.deliveredDate)
            && a.deliveredDate.seconds >= monthStart.seconds
            && a.deliveredDate.seconds <= monthEnd.seconds
            && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                (props.dealersettings.level === 'region' && a.convertedByRegion === props.selectedUser.value) ||
                (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true));


        var _data = []
        var arrCounts = {
            allocated: monthConverted,
            bdcshow: monthShow,
            bdcnoshow: monthNoshow,
            won: monthWon,
            lost: monthLost,
            delivered: monthDelivered
        }
        bdcStatus.forEach(rec => {
            _data.push({
                name: rec.name,
                count: arrCounts[`${rec.value}`].length,
                color: rec.color ? rec.color : '',
                value: rec.value,
                startDate: monthStart,
                endDate: monthEnd,
                enquiryIDs: arrCounts[`${rec.value}`].map(r => { return r.documentID })
            })
        })
        setData(_data)
        //setLoader(false)

    }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    props.loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                    </div>
                    <h3>Leads By Status</h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={'status-pie-chart'}
                                    height={'350px'}
                                    data={reportData}
                                    handleShowEnquiryList={(value, startDate, endDate, data) => {
                                        props.handleShowEnquiryList({
                                            enquiryIDs: data.enquiryIDs
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={'status-pie-chart'}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div >
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default StatusCounts;