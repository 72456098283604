/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, InputText,
    AntDatePicker, AntMonthPicker,
    ImageCropHook, ImageViewer, PopUpModal
} from '../../../components';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'
import AddNotes from '../../service/details/addNotes'
import Files from '../../files';
/** VIEW-MODELS */
import { objStock } from '../../stock/viewModel'
import { stockStatus } from '../../../services/enum'
import { firestoreDB, storageBucket } from '../../../services/helper';

let sale_type = [];
let locations = [];
let usages = [];
let transmissions = [];
let transportMethods = [];
let clientID = null
let stockStatuses = [];
let license_state = [];
/*const stock_status = [
    { active: true, value: 'available', label: 'Available' },
    { active: true, value: 'sold', label: 'Sold' },
    { active: true, value: 'sold', label: 'Sold' },
    { active: true, value: 'inProduction', label: 'In Production' },
    { active: true, value: 'unavailable', label: 'Unavailable' }
]*/

// let stock_status = [
//     { active: true, value: 'available', label: <div className={`badge badge-pill badge-available-fill`}>AVAILABLE</div> },
//     { active: true, value: 'sold', label: <div className={`badge badge-pill badge-sold-fill`}>SOLD</div> },
//     { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
//     { active: true, value: 'inProduction', label: <div className={`badge badge-pill badge-inproduction-fill`}>IN PRODUCTION</div> },
//     { active: true, value: 'unavailable', label: <div className={`badge badge-pill badge-open-fill`}>UN-AVAILABLE</div> }
// ]

const stock_status_isAllocated = [
    { active: true, value: 'available', label: <div className={`badge badge-pill badge-available-fill`}>AVAILABLE</div> },
    { active: true, value: 'sold', label: <div className={`badge badge-pill badge-sold-fill`}>SOLD</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'unavailable', label: <div className={`badge badge-pill badge-open-fill`}>UN-AVAILABLE</div> }
]

// const locations = [
//     { active: true, value: 'Dubai Warehouse', label: 'Dubai Warehouse' },
//     { active: true, value: 'Sharjah Warehouse', label: 'Sharjah Warehouse' },
//     { active: true, value: 'Ajman Warehouse', label: 'Ajman Warehouse' }
// ]

export default class AddStock extends Component {
    constructor(props) {
        super(props);
        this.refStock = window.firebase.firestore().collection('stock');
        this.unsubscribeSTKADD = null;
        this.unsubscribeSTKNotes = null;
        this.mandatoryFields = [];
        //this.childRef = React.createRef();
        this.state = {
            stock: Object.assign({}, objStock),
            oldStock: Object.assign({}, objStock),
            notesHistory: Object.assign([]),
            errors: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            currentStatus: null,
            isAllocated: false,
            isLoading: false,
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            },
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            addMake: false,
            addType: false,
            addGroup: false,
            addModel: false,
            showNotes: false,
            imageViewer: {
                show: false,
                images: null
            }
        }
        //this.handleDateChange = this.handleDateChange.bind(this);

    }

    //#region  LOAD DATA
    async loadStock(props) {
        const { show, dealersettings, docID, enableSale, enableTestDrive, enableLoan, enableServiceLoan, selectedClientID, customScroll } = props;
        const stock = Object.assign({}, objStock);
        if (show === true && docID) {

            const refData = await this.refStock.doc(docID).get();
            this.onStockCollectionUpdate(refData)

            this.unsubscribeSTKNotes = window.firebase.firestore().collection(`stock/${docID}/notes`)
                .onSnapshot(this.onNotesCollectionUpdate);

        }
        else if (show === true && (!docID)) {
            const { prefillData, prefillTradeData } = this.props

            if (!_.isEmpty(prefillData) && _.isObject(prefillData)) {
                //console.log('prefillData', prefillData)
                for (let [key, value] of Object.entries(prefillData)) {
                    if (stock.hasOwnProperty(key) && value)
                        stock[key] = value;
                }

                if (!_.isEmpty(stock.regDate))
                    stock.regDate = moment.unix(stock.regDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.regExp))
                    stock.regExp = moment.unix(stock.regExp.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.warrantyStartDate))
                    stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.warrantyExp))
                    stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.inStockDate))
                    stock.inStockDate = moment.unix(stock.inStockDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.stockInDate))
                    stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.soldDate))
                    stock.soldDate = moment.unix(stock.soldDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.deliveredDate))
                    stock.deliveredDate = moment.unix(stock.deliveredDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.etaDate))
                    stock.etaDate = moment.unix(stock.etaDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(stock.fokDate))
                    stock.fokDate = moment.unix(stock.fokDate.seconds).format('YYYY-MM-DD HH:mm')

            }

            if (!_.isEmpty(prefillTradeData) && _.isObject(prefillTradeData)) {
                //console.log('prefillTradeData', prefillTradeData)
                for (let [key, value] of Object.entries(prefillTradeData)) {
                    if (stock.hasOwnProperty(key) && value)
                        stock[key] = value;
                }

                if (!_.isEmpty(prefillTradeData.regDate))
                    stock.regDate = moment(prefillTradeData.regDate).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(prefillTradeData.regExp))
                    stock.regExp = moment(prefillTradeData.regExp).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(prefillTradeData.buildDate))
                    stock.buildDate = moment(prefillTradeData.buildDate).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(prefillTradeData.warrantyExpiry))
                    stock.warrantyExp = moment(prefillTradeData.warrantyExpiry).format('YYYY-MM-DD HH:mm')

                if (prefillTradeData.offerPrice)
                    stock.purchasePrice = prefillTradeData.offerPrice;

                if (prefillTradeData.chassisNo)
                    stock.vinNo = prefillTradeData.chassisNo;
                if (prefillTradeData.exteriorColor)
                    stock.extColor = prefillTradeData.exteriorColor;
                if (prefillTradeData.interiorColor)
                    stock.intColor = prefillTradeData.interiorColor;
                if (prefillTradeData.mileage)
                    stock.miles = parseInt(prefillTradeData.mileage);

                stock.addedBy = null;
                stock.addedDate = null;
                stock.addedFrom = null;
                stock.modifiedBy = null;
                stock.modifiedDate = null;
                stock.modifiedFrom = null;
                stock.tradeinID = stock.documentID;

            }

            const brands = Object.assign([], dealersettings.client.brands);
            if (_.isEmpty(stock.make) && brands.length === 1)
                stock.make = brands[0].value

            stock.documentID = this.refStock.doc().id;
            stock.status = stockStatus.AVAILABLE;

            if (enableSale)
                stock.isSale = enableSale;
            if (enableTestDrive)
                stock.isTestDrive = enableTestDrive;
            if (enableLoan)
                stock.isLoan = enableLoan;
            if (enableServiceLoan) {
                stock.isLoan = true;
                stock.isServiceLoan = true;
            }

            if (selectedClientID) stock.clientID = selectedClientID;
            clientID = stock.clientID ? stock.clientID : localStorage.defaultModule !== 'oem' ? dealersettings.client.id : null;
            this.setState({ stock: stock, oldStock: stock });

            this.unsubscribeSTKNotes = window.firebase.firestore().collection(`stock/${stock.documentID}/notes`)
                .onSnapshot(this.onNotesCollectionUpdate);
        }

        let setttings = dealersettings.client.settings;
        let _currentSettings = null;
        if (clientID && clientID !== dealersettings.client.id) {
            _currentSettings = await CommonHelper.updateClientSettings(clientID, Object.assign({}, dealersettings));
            if (!_.isEmpty(_currentSettings?.client?.settings)) setttings = _currentSettings.client.settings;
        }
        // if (clientID && clientID !== dealersettings.client.id && dealersettings.group?.clients && dealersettings.group.clients[clientID]?.settings) {
        //     setttings = dealersettings.group.clients[clientID].settings;
        // }

        license_state = [];
        const client = _currentSettings?.client ? _currentSettings.client : dealersettings.client;
        let defaultCountry = client?.countryCode ? client.countryCode.toUpperCase() : 'AU';

        dealersettings.states && dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
            license_state.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        if (setttings) {
            locations = [];
            setttings.stockLocation && setttings.stockLocation.forEach(doc => {
                locations.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            usages = [];
            setttings.stockUsage && setttings.stockUsage.forEach(doc => {
                usages.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            transmissions = [];
            setttings.transmissions && setttings.transmissions.forEach(doc => {
                transmissions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            transportMethods = [];
            setttings.stockTransport && setttings.stockTransport.forEach(doc => {
                transportMethods.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            stockStatuses = [];
            setttings.stockStatus && setttings.stockStatus.forEach(doc => {
                stockStatuses.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color || '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.stock))
                this.mandatoryFields = setttings.mandatoryFields.stock.split(',');

            if (!_.isEmpty(this.props.mandatoryFields))
                this.mandatoryFields = _.union(this.mandatoryFields, this.props.mandatoryFields);

            const brands = dealersettings.allMakeModels;

            const years = [];
            const makes = [];

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            if (stock.make) {
                const types = [];
                const models = [];
                const groups = [];
                let _models = !_.isEmpty(makes.filter(m => m.value === stock.make)[0]) ? makes.filter(m => m.value === stock.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _models.forEach((data, index) => {
                    models.push({
                        ...data,
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });

            }

            this.setState({
                makes: makes,
                years: years
            }, () => { this.errorOnLoad() });

        }

        if (customScroll && document.getElementById(customScroll)) {
            document.getElementById(customScroll).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }

    }

    onStockCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }
        const { dealersettings } = this.props;
        let makes = !_.isEmpty(this.state.makes) ? this.state.makes : dealersettings.allMakeModels;

        const objData = Object.assign({}, docSnapshot.data());
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objData)) {
            stock[key] = value;
        }

        if (!stock.documentID)
            stock.documentID = docSnapshot.id;

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.regExp))
            stock.regExp = moment.unix(stock.regExp.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.inStockDate))
            stock.inStockDate = moment.unix(stock.inStockDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.stockInDate))
            stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.soldDate))
            stock.soldDate = moment.unix(stock.soldDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.deliveredDate))
            stock.deliveredDate = moment.unix(stock.deliveredDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.etaDate))
            stock.etaDate = moment.unix(stock.etaDate.seconds).format('YYYY-MM-DD HH:mm')

        if (!_.isEmpty(stock.fokDate))
            stock.fokDate = moment.unix(stock.fokDate.seconds).format('YYYY-MM-DD HH:mm')

        // if (enableSale)
        //     stock.isSale = enableSale;
        // if (enableTestDrive)
        //     stock.isTestDrive = enableTestDrive;
        // if (enableLoan)
        //     stock.isLoan = enableLoan;

        if (stock.make) {
            const types = [];
            const models = [];
            const groups = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === stock.make)[0]) ? makes.filter(m => m.value === stock.make)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    ...data,
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });

        }

        if (stock.isAllocated && stock.settingsID) {
            stock.isAllocated = true;
        } else {
            stock.isAllocated = false;
        }
        clientID = stock.clientID ? stock.clientID : null;
        this.setState({ stock: stock, oldStock: stock, currentStatus: stock.status, isAllocated: stock.isAllocated });
    }

    onNotesCollectionUpdate = (querySnapshot) => {

        let notesHistory = [];
        querySnapshot.forEach((_req) => {
            const req = Object.assign({}, _req.data());
            req.documentID = _req.id;
            notesHistory.push(req);
        });

        notesHistory = _.orderBy(notesHistory, ['addedDate'], ['desc'])

        this.setState({ notesHistory: notesHistory });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadStock(this.props);

    }

    componentWillUnmount() {
        this.unsubscribeSTKADD && this.unsubscribeSTKADD();
        this.unsubscribeSTKNotes && this.unsubscribeSTKNotes();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('prevState', prevState)
        // console.log('prevProps', prevProps)
        // console.log('statestock', this.state.stock)
        if (!prevState.stock.oemID && this.state.stock.oemID && this.state.stock.clientID && this.state.stock.settingsID) {
            // this.setState({
            //     isAllocated: true
            // })
        }
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let errorClass = 'input_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);


            let fields = Object.assign({}, this.state.stock);
            for (let [key] of Object.entries(fields)) {
                if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {
                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                        errors[key] = errorClass;
                    }
                }
            }

            this.setState({ errors: errors });

            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

        }

    }
    //#endregion

    //#region CHANGE EVENT STOCK
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.stock);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        let state = Object.assign({}, this.state.stock);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ stock: state }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ stock: state }, () => { this.errorChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.stock);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ stock: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.stock);
        state[name] = e.floatValue;
        this.setState({ stock: state }, () => { this.errorChange(name); });
    }

    handleSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.stock);

        if (data.name === 'status')
            state.lastStatus = state.status;

        state[data.name] = (e?.value || '');
        if (data.name === 'model' && e?.value) {
            if (e.group) state['group'] = e?.group;
            if (e.type) state['type'] = e?.type;
        }

        this.setState({ stock: state }, () => { this.errorChange(data.name); });

    }

    onCheckChange = (e, name) => {
        let state = Object.assign({}, this.state.stock);
        state[name] = e.target.checked;
        this.setState({ stock: state }, () => { this.errorChange(name); });
    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        ...data,
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        ...data,
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ stock: state, models: models }, () => { this.errorChange(data.name); });
    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        ...data,
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        ...data,
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })


        }

        state['model'] = '';
        this.setState({ stock: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    ...data,
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            stock: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e, name) => {
        const { checked } = e.target;
        //console.log('handleCheckChange', name, checked)
        let state = Object.assign({}, this.state.stock);
        state[name] = checked;

        if (name === 'isLoan' && !checked)
            state['isServiceLoan'] = checked;

        if (name === 'isServiceLoan' && checked)
            state['isLoan'] = checked;

        this.setState({ stock: state }, () => { this.errorChange(name); });

    }
    handleSaleTypeChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = value;
        this.setState({ stock: state }, () => { this.errorChange(name); });

    };

    handleStatusReload = () => {
        let state = Object.assign({}, this.state.stock);
        state.status = this.state.currentStatus;
        this.setState({ stock: state });
    }



    handleLoanVehicleClose = async (fleetID) => {
        if (fleetID) {
            const { docID } = this.props;
            if (docID) {
                const refData = await this.refStock.doc(docID).get();
                this.onStockCollectionUpdate(refData)
            }
        }
        this.setState({
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            }
        })
    }

    handleTestDriveClose = async (testdriveID) => {
        if (testdriveID) {
            const { docID } = this.props;
            if (docID) {
                const refData = await this.refStock.doc(docID).get();
                this.onStockCollectionUpdate(refData)
            }
        }
        this.setState({
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            }
        })
    }



    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            imageURL: null,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {
        const { src, id, type, ext } = cropShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            //stock: state,
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = async (e, type, id, title) => {
        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }

            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        let stockID = this.state.stock.documentID;
        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created one saveImageToStorage')
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}`).child(uid).getDownloadURL()
                    .then(dataURL => {

                        let state = Object.assign({}, this.state.stock);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        state.documentID = stockID;
                        this.setState({ stock: state, imageURL: null })
                    })
            })
    };

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure?`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.stock);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ stock: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region DATEPICKER
    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.stock);
        // if (val)
        //     state[name] = moment(val).format('YYYY-MM-DD');
        // else
        //     state[name] = null;


        if (val) {
            let date = state[name];
            if (date)
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
            else
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
        }
        else
            state[name] = null;

        if (name === 'etaDate' && _.isEmpty(state[name]))
            state['isStockArrived'] = false;

        if (name === 'stockInDate' && !_.isEmpty(state[name]) && state.status === stockStatus.INPRODUCTION)
            state.status = stockStatus.AVAILABLE;

        this.setState({ stock: state }, () => { this.errorChange(name); });

    };

    /*handleRegDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['regDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('regDate'); });

    }

    handleWarrantyDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['warrantyExp'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('warrantyExp'); });

    }

    handleInStockDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['inStockDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('inStockDate'); });

    }

    handleAgeDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['stockInDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('stockInDate'); });

    }*/

    handleValueDateChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.stock);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ stock: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.stock);
            state[name] = null;
            this.setState({ stock: state });
        }
    }

    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }
    //#endregion

    //#region SAVE INFO
    handleSaveInfo = async (e) => {
        e.preventDefault();
        let exists = false;
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.stock);

        const { dealersettings, docID } = this.props;
        const stockNo = fields.stockNo ? fields.stockNo : '';
        const vinNo = fields.vinNo ? fields.vinNo : '';
        let clientID = fields.clientID ? fields.clientID : (dealersettings ? dealersettings.client.id : '');
        let groupID = (dealersettings ? dealersettings.client.group : '');
        const enableSharedStock = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            dealersettings.group.enableSharedStock) ? true : false);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            //if (key === 'make' || key === 'model' || key === 'type' || key === 'year' || key === 'saleType' || key === 'stockNo') {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;
            }
            //}
        }

        if (fields.warrantyStartDate && fields.warrantyExp && (moment(fields.warrantyStartDate)._d > moment(fields.warrantyExp)._d)) {
            formIsValid = false;
            errors.warrantyExp = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'Warranty Expiry date should be greater than Start date'), '', 'info')
        }

        this.setState({ errors: errors });
        let checkStock = null;
        //let checkVinNo = null;

        if (enableSharedStock && groupID) {
            checkStock = this.refStock.where('groupID', '==', groupID).where('stockNo', '==', stockNo).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (snapshot.size >= 1 && !docID) {
                        formIsValid = false;
                        exists = true;
                        errors['stockNo'] = errorClass;
                        return exists;
                    } else if (snapshot.size >= 1 && docID) {
                        snapshot.forEach(doc => {
                            if ((snapshot.size > 1 && docID !== doc.id) || (snapshot.size === 1 && docID !== doc.id)) {
                                exists = true;
                                formIsValid = false;
                                errors['stockNo'] = errorClass;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });

            // checkVinNo = this.refStock.where('groupID', '==', groupID).where('vinNo', '==', vinNo).where('isDeleted', '==', false).limit(2).get()
            //     .then(snapshot => {
            //         if (snapshot.size >= 1 && !docID) {
            //             formIsValid = false;
            //             exists = true;
            //             errors['vinNo'] = errorClass;
            //             return exists;
            //         } else if (snapshot.size >= 1 && docID) {
            //             snapshot.forEach(doc => {
            //                 if ((snapshot.size > 1 && docID !== doc.id) || (snapshot.size === 1 && docID !== doc.id)) {
            //                     exists = true;
            //                     formIsValid = false;
            //                     errors['vinNo'] = errorClass;
            //                 }
            //             });
            //             return exists;
            //         }
            //         return exists;
            //     });
        }
        else {
            checkStock = this.refStock.where('clientID', '==', clientID).where('stockNo', '==', stockNo).where('isDeleted', '==', false).limit(2).get()
                .then(snapshot => {
                    if (snapshot.size >= 1 && !docID) {
                        formIsValid = false;
                        exists = true;
                        errors['stockNo'] = errorClass;
                        return exists;
                    } else if (snapshot.size >= 1 && docID) {
                        snapshot.forEach(doc => {
                            if ((snapshot.size > 1 && docID !== doc.id) || (snapshot.size === 1 && docID !== doc.id)) {
                                exists = true;
                                formIsValid = false;
                                errors['stockNo'] = errorClass;
                            }
                        });
                        return exists;
                    }
                    return exists;
                });

            // checkVinNo = this.refStock.where('clientID', '==', clientID).where('vinNo', '==', vinNo).where('isDeleted', '==', false).limit(2).get()
            //     .then(snapshot => {
            //         if (snapshot.size >= 1 && !docID) {
            //             formIsValid = false;
            //             exists = true;
            //             errors['vinNo'] = errorClass;
            //             return exists;
            //         } else if (snapshot.size >= 1 && docID) {
            //             snapshot.forEach(doc => {
            //                 if ((snapshot.size > 1 && docID !== doc.id) || (snapshot.size === 1 && docID !== doc.id)) {
            //                     exists = true;
            //                     formIsValid = false;
            //                     errors['vinNo'] = errorClass;
            //                 }
            //             });
            //             return exists;
            //         }
            //         return exists;
            //     });
        }

        if (fields.stockNo) {
            const [checkQuery] = await Promise.all([checkStock]);
            if (checkQuery) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Stock No. already exists'), '', 'info')
                return false;
            }
        }

        // if (fields.vinNo) {
        //     const [checkQuery] = await Promise.all([checkVinNo]);
        //     if (checkQuery) {
        //         Swal.fire('VIN No. already exists', '', 'info')
        //         return false;
        //     }
        // }

        if (formIsValid === true) {
            //this.saveStockInfo(this);
            const { currentStatus } = this.state;

            if (currentStatus && (currentStatus === stockStatus.ONLOAN || currentStatus === stockStatus.ONTESTDRIVE) && currentStatus !== fields.status) {
                if (currentStatus === stockStatus.ONTESTDRIVE && !_.isEmpty(fields.testDriveDetail) && fields.testDriveDetail.testdriveID) {
                    const refData = await firestoreDB(dealersettings).firestore().collection('testdrives').doc(fields.testDriveDetail.testdriveID).get();
                    if (refData.exists)
                        this.handleTestDriveStatusChange(fields.testDriveDetail);
                    else
                        this.setState({ stock: { ...fields, testDriveDetail: null } }, () => { this.saveStockInfo(this); })
                }
                else if (currentStatus === stockStatus.ONLOAN && !_.isEmpty(fields.fleetDetail) && fields.fleetDetail.fleetID) {
                    const refData = await firestoreDB(dealersettings).firestore().collection('fleet').doc(fields.fleetDetail.fleetID).get();
                    if (refData.exists)
                        this.handleLoanStatusChange(fields.fleetDetail);
                    else
                        this.setState({ stock: { ...fields, fleetDetail: null } }, () => { this.saveStockInfo(this); })
                }
                else {
                    this.saveStockInfo(this);
                }
            }
            else {
                this.saveStockInfo(this);
            }
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

            return formIsValid;
        }

    };

    handleTestDriveStatusChange = (objDetail) => {
        Swal.fire({
            //title: 'Are you sure?',
            text: CommonHelper.showLocale(this.props, 'Please return test drive vehicle to proceed with the update status'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Return'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    testDriveModal:
                    {
                        show: true,
                        ID: objDetail.testdriveID,
                        title: 'testDrive',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock),
                        contact: (!_.isEmpty(objDetail.contact)) ? objDetail.contact : null
                    }
                });
            }
            else {
                this.handleStatusReload();
                //this.saveStockInfo(this);
            }
        })
    }

    handleLoanStatusChange = (objDetail) => {
        Swal.fire({
            text: CommonHelper.showLocale(this.props, 'Please return loan vehicle to proceed with the update status'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Return'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    fleetModal:
                    {
                        show: true,
                        ID: objDetail.fleetID,
                        title: 'loanVehicle',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock)
                    }
                });

            }
            else {
                this.handleStatusReload();
                //this.saveStockInfo(this);
            }
        })
    }
    saveStockInfo = (e) => {
        //console.log('saveStockInfo');       
        this.setState({ isLoading: true });
        let objData = Object.assign({}, this.state.stock);
        objData = CommonHelper.saveModelTypeAndGroup(objData, this.props.dealersettings);
        const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
        const _currentDate = window.firebase.firestore.Timestamp.now();
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = _userID;
            objData.addedDate = _currentDate;
        }

        if (!_.isEmpty(this.state.currentStatus) && this.state.currentStatus !== objData.status) {
            objData.statusModifiedBy = _userID;
            objData.statusModifiedFrom = 'web';
        }

        // if (objData.status !== stockStatus.RESERVED)
        //     objData.enquiryID = null;

        objData.modifiedBy = _userID;
        objData.modifiedDate = _currentDate;
        let stockID = objData['documentID'];

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (propName !== 'images')
                    delete objData[propName];
            }
        }

        objData.regDate = (objData.regDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.regDate)._d) : null);
        objData.regExp = (objData.regExp ? window.firebase.firestore.Timestamp.fromDate(moment(objData.regExp)._d) : null);
        objData.warrantyStartDate = (objData.warrantyStartDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.warrantyStartDate)._d) : null);
        objData.warrantyExp = (objData.warrantyExp ? window.firebase.firestore.Timestamp.fromDate(moment(objData.warrantyExp)._d) : null);
        objData.inStockDate = (objData.inStockDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.inStockDate)._d) : null);
        objData.stockInDate = (objData.stockInDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.stockInDate)._d) : null);
        objData.etaDate = (objData.etaDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.etaDate)._d) : null);
        objData.fokDate = (objData.fokDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.fokDate)._d) : null);

        objData.soldDate = (objData.soldDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.soldDate)._d) : null);
        objData.deliveredDate = (objData.deliveredDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.deliveredDate)._d) : null);

        if (objData.hasOwnProperty('lastNote'))
            delete objData['lastNote'];

        if (objData.stockInDate && objData.status === stockStatus.INPRODUCTION)
            objData.status = stockStatus.AVAILABLE;

        if (localStorage.defaultModule !== 'oem' && !objData.clientID)
            objData.clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        if (localStorage.defaultModule === 'oem' && !objData.settingsID) {
            objData.settingsID = this.props.dealersettings ? this.props.dealersettings.client.settingsID : '';
        }

        if (objData.status !== stockStatus.SOLD && objData.status !== stockStatus.DELIVERED &&
            objData.lastStatus !== stockStatus.SOLD && objData.lastStatus !== stockStatus.DELIVERED) {
            objData.soldDate = null;
            objData.deliveredDate = null;
        }

        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created  stock')
        }
        objData.groupID = this.props.dealersettings.client.group ? this.props.dealersettings.client.group : '';

        const refStockData = this.refStock.doc(stockID);
        refStockData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.setState({ isLoading: false });
            toast.notify('Stock updated successfully', {
                duration: 2000
            })
            if (this.props.isFilterApplied)
                this.props.updateStock(objData);

            if (!this.props.docID && objData.tradeinID)
                this.handleNotesClose('Stock added from a trade-in.', null, objData.tradeinID);

            this.props.handleClose(stockID, objData);

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };
    //#endregion

    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                stock: {
                    ...this.state.stock,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            stock: {
                ...this.state.stock,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    //#region CHANGE EVENT Notes  
    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions, tradeinID) => {

        if (noteTxt) {
            const { documentID } = this.state.stock;

            const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
            const _currentDate = window.firebase.firestore.Timestamp.now();

            const objData = Object.assign({});
            objData.modifiedBy = _userID;
            objData.modifiedDate = _currentDate;
            objData.lastNoteDate = _currentDate;
            objData.lastNote = noteTxt;

            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: window.firebase.firestore().collection('stock').doc().id,
                notes: noteTxt,
                stockID: documentID,
                mentions: Object.assign([], convertedIDs),
                addedBy: _userID,
                addedDate: _currentDate,
                addedFrom: 'web'
            }

            const updateRef = window.firebase.firestore().doc(`stock/${documentID}/notes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                const refStockData = this.refStock.doc(documentID);
                refStockData.set(objData, { merge: true }).then((docRef) => {
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });

                if (tradeinID) {
                    const refTradeinData = firestoreDB(this.props.dealersettings).firestore().doc(`tradeins/${tradeinID}`);
                    refTradeinData.set({ stockID: documentID }, { merge: true })
                }
                else {
                    toast.notify('notes added successfully', {
                        duration: 2000
                    })
                }

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }
    //#endregion 

    render() {
        const { clientUsers, dealersettings, enableSale, enableTestDrive, enableLoan, enableServiceLoan, show, groupUsers, selectedClientID } = this.props;

        const { fleetModal, testDriveModal, addMake, addType, addModel, addGroup, showNotes, notesHistory, isLoading, oldStock, imageViewer } = this.state
        const {
            stockNo, documentID, isStockArrived, regState,
            make, model, modelDescription, saleType, type, group,
            year, price, regNo, vinNo, extColor, intColor, regExp,
            miles, regDate, warrantyExp, stockInDate, status, warrantyStartDate,
            purchasePrice, location, tradePlate, buildDate,
            isLoan, isServiceLoan, isTestDrive, isSale, images, clientID,
            usage, transmission, transportMethod, soldDate, deliveredDate, etaDate, fokDate,
            marginValue, marginRetail, marginOrderFill, marginPayments, marginMarketing,
            mclID, mclBuildSlot, mclSpecFreezeDate, mclCustomerName
        } = this.state.stock;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const vbmcurrencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.vbm)) ? dealersettings.client.vbm.symbol + ' ' : '$ ');

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
        const accessToStockVBM = ((!_.isEmpty(_permissions) && _permissions.accessToStockVBM) ? true : false);
        const accessToModifyVBM = ((!_.isEmpty(_permissions) && _permissions.accessToModifyVBM) ? 'input' : 'text');

        const addStock = ((!_.isEmpty(_permissions) && _permissions.addStock) ? true : false);
        const editStock = ((!_.isEmpty(_permissions) && _permissions.editStock) ? true : false);
        const updateMissing = ((!_.isEmpty(_permissions) && _permissions.updateMissingStockFields) ? true : false);
        const addUsedDemoStockOnly = ((!_.isEmpty(_permissions) && _permissions.addUsedDemoStockOnly) ? true : false);
        const hideStockPrice = ((!_.isEmpty(_permissions) && _permissions.hideStockPrice) ? true : false);

        const canModifyStock = (addStock || editStock) ? true : false;
        const stockLocations = dealersettings?.client?.clientSettings?.stockLocations;

        return (
            documentID
                ?
                <>
                    <Modal show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-stock"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title> <Translate text={this.props.title} />

                            </Modal.Title>
                            {
                                clientID && (!_.isEmpty(stockLocations) || selectedClientID || clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                    (<div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{CommonHelper.getOtherDealerName(dealersettings, clientID)}</div></div>)
                                    : (<></>)
                            }
                        </Modal.Header>

                        <Modal.Body>
                            <div className={`container-fluid ${(readOnlyEnquiryView) ? 'form-disable' : ''}`}>
                                <div className="row">
                                    <div className="pop-layout form-style">

                                        <div className="addstock-pop-head">
                                            <div className="form-row">
                                                {/* <div id="saleType" className={`form-group col-md-4 ${this.state.errors["saleType"] ? 'custom_error' : ''}`}>
                                                <label > <Translate text={'saleType'} /></label>
                                                <div>
                                                    <div className={`radio icheck-success float-left mr-3`}>
                                                        <InputRadio
                                                            id="New"
                                                            type="radio"
                                                            value="New"
                                                            name="saleType"
                                                            checked={saleType === 'New' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="New"><Translate text={'new'} /></label>
                                                    </div>
                                                    <div className={`radio icheck-success float-left mr-3`}>
                                                        <InputRadio
                                                            id="Demo"
                                                            type="radio"
                                                            value="Demo"
                                                            name="saleType"
                                                            checked={saleType === 'Demo' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="Demo"><Translate text={'demo'} /></label>
                                                    </div>
                                                    <div className={`radio icheck-success float-left`}>
                                                        <InputRadio
                                                            id="Preowned"
                                                            type="radio"
                                                            value="Preowned"
                                                            name="saleType"
                                                            checked={saleType === 'Preowned' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="Preowned"><Translate text={'preowned'} /></label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'availableFor'} /></label>
                                                    <div>
                                                        <div className={`checkbox icheck-success float-left mr-3 ${enableTestDrive || (canModifyStock ? false : (updateMissing && !oldStock['isTestDrive'] ? false : true)) ? 'form-disable' : ''}`}>
                                                            <input
                                                                type="checkbox"
                                                                id="_isTestDrive"
                                                                name="_isTestDrive"
                                                                className="uncheck-activity"
                                                                checked={isTestDrive}
                                                                onChange={(e) => {
                                                                    this.handleCheckChange(e, 'isTestDrive')
                                                                }} />
                                                            <label htmlFor="_isTestDrive"><Translate text={'testDrive'} /></label>
                                                        </div>

                                                        <div className={`checkbox icheck-success float-left mr-3 ${enableSale || (canModifyStock ? false : (updateMissing && !oldStock['isSale'] ? false : true)) ? 'form-disable' : ''}`}>
                                                            <input
                                                                type="checkbox"
                                                                id="_isSale"
                                                                name="_isSale"
                                                                className="uncheck-activity"
                                                                checked={isSale}
                                                                onChange={(e) => {
                                                                    this.handleCheckChange(e, 'isSale')
                                                                }} />
                                                            <label htmlFor="_isSale"><Translate text={'sale'} /></label>
                                                        </div>

                                                        <div className={`checkbox icheck-success float-left mr-3 ${(enableLoan || enableServiceLoan) || (canModifyStock ? false : (updateMissing && !oldStock['isLoan'] ? false : true)) ? 'form-disable' : ''}`}>
                                                            <input
                                                                type="checkbox"
                                                                id="_isLoan"
                                                                name="_isLoan"
                                                                className="uncheck-activity"
                                                                checked={isLoan}
                                                                onChange={(e) => {
                                                                    this.handleCheckChange(e, 'isLoan')
                                                                }} />
                                                            <label htmlFor="_isLoan"><Translate text={'loanVehicle'} /></label>
                                                        </div>
                                                        <div className={`checkbox icheck-success float-left ${enableServiceLoan || (canModifyStock ? false : (updateMissing && !oldStock['isServiceLoan'] ? false : true)) ? 'form-disable' : ''}`}>
                                                            <input
                                                                type="checkbox"
                                                                id="_isServiceLoan"
                                                                name="_isServiceLoan"
                                                                className="uncheck-activity"
                                                                checked={isServiceLoan}
                                                                onChange={(e) => {
                                                                    this.handleCheckChange(e, 'isServiceLoan')
                                                                }} />
                                                            <label htmlFor="_isServiceLoan"><Translate text={'isServiceLoan'} /></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="requirement-pop-middle">
                                            {/* <div className="requirement-section-head">Vehicle Information </div> */}

                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    {readOnlyEnquiryView || (canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['make']) ? false : true))
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                !addMake ? (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addMake: true
                                                                        })
                                                                        setTimeout(() => {
                                                                            let elem = document.getElementById('make')
                                                                            elem.focus()
                                                                        }, 100)
                                                                    }}>
                                                                    + <Translate text={'add'} />
                                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addMake: false,
                                                                            errors: {
                                                                                ...this.state.errors,
                                                                                ['make']: 'input_error'
                                                                            },
                                                                            stock: {
                                                                                ...this.state.stock,
                                                                                ['make']: ''
                                                                            }
                                                                        })
                                                                    }}>
                                                                    x <Translate text={'cancel'} />
                                                                </a>)
                                                            }
                                                        </>}

                                                    <label> <Translate text={'make'} /></label>
                                                    {addMake ?
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'make'}
                                                            className={`form-control ${this.state.errors["make"]}`}
                                                            name="make"
                                                            onChange={this.handleCustomOption}
                                                            value={make}
                                                            id='make'
                                                        /> :
                                                        <ReactSelect
                                                            options={this.state.makes}
                                                            name={"make"}
                                                            placeholder={'select make'}
                                                            onChange={this.handleMakeChange}
                                                            value={make}
                                                            classNamePrefix={`${this.state.errors["make"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['make'] ? false : true))}
                                                        >
                                                        </ReactSelect>
                                                    }
                                                </div>
                                                <div className="form-group col-md-4">
                                                    {readOnlyEnquiryView || (canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['group']) ? false : true))
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                !addGroup ? (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addGroup: true
                                                                        })
                                                                        setTimeout(() => {
                                                                            let elem = document.getElementById('group')
                                                                            elem.focus()
                                                                        }, 100)
                                                                    }}>
                                                                    + <Translate text={'add'} />
                                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addGroup: false,
                                                                            errors: {
                                                                                ...this.state.errors,
                                                                                ['group']: 'input_error'
                                                                            },
                                                                            stock: {
                                                                                ...this.state.stock,
                                                                                ['group']: ''
                                                                            }
                                                                        })
                                                                    }}>
                                                                    x <Translate text={'cancel'} />
                                                                </a>)
                                                            }
                                                        </>}

                                                    <label><Translate text={'group'} /></label>
                                                    {addGroup ?
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'group'}
                                                            className={`form-control ${this.state.errors["group"]}`}
                                                            name="group"
                                                            onChange={this.handleCustomOption}
                                                            value={group}
                                                            id="group"
                                                        /> :
                                                        <ReactSelect
                                                            options={this.state.groups}
                                                            name={"group"}
                                                            placeholder={'select group'}
                                                            onChange={this.handleGroupChange}
                                                            value={group}
                                                            classNamePrefix={`${this.state.errors["group"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['group'] ? false : true))}
                                                        >
                                                        </ReactSelect>}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    {readOnlyEnquiryView || (canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['type']) ? false : true))
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addType: true
                                                                        })
                                                                        setTimeout(() => {
                                                                            let elem = document.getElementById('type')
                                                                            elem.focus()
                                                                        }, 100)
                                                                    }}>
                                                                    + <Translate text={'add'} />
                                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addType: false,
                                                                            errors: {
                                                                                ...this.state.errors,
                                                                                ['type']: 'input_error'
                                                                            },
                                                                            stock: {
                                                                                ...this.state.stock,
                                                                                ['type']: ''
                                                                            }
                                                                        })
                                                                    }}>
                                                                    x <Translate text={'cancel'} />
                                                                </a>)
                                                            }
                                                        </>}

                                                    <label><Translate text={'type'} /></label>
                                                    {addType ?
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'type'}
                                                            className={`form-control ${this.state.errors["type"]}`}
                                                            name="type"
                                                            onChange={this.handleCustomOption}
                                                            value={type}
                                                            id="type"
                                                        /> :
                                                        <ReactSelect
                                                            options={this.state.types}
                                                            name={"type"}
                                                            placeholder={'select type'}
                                                            onChange={this.handleTypeChange}
                                                            value={type}
                                                            classNamePrefix={`${this.state.errors["type"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['type'] ? false : true))}
                                                        >
                                                        </ReactSelect>}
                                                </div>

                                                <div className="form-group col-md-4">
                                                    {readOnlyEnquiryView || (canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['model']) ? false : true))
                                                        ?
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                !addModel ? (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addModel: true,
                                                                        })
                                                                        setTimeout(() => {
                                                                            let elem = document.getElementById('model')
                                                                            elem.focus()
                                                                        }, 100)
                                                                    }}>
                                                                    + <Translate text={'add'} />
                                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            addModel: false,
                                                                            errors: {
                                                                                ...this.state.errors,
                                                                                ['model']: 'input_error'
                                                                            },
                                                                            stock: {
                                                                                ...this.state.stock,
                                                                                ['model']: ''
                                                                            }
                                                                        })
                                                                    }}>
                                                                    x <Translate text={'cancel'} />
                                                                </a>)
                                                            }
                                                        </>}

                                                    <label> <Translate text={'model'} /></label>
                                                    {addModel ?
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'model'}
                                                            className={`form-control ${this.state.errors["model"]}`}
                                                            name="model"
                                                            onChange={this.handleCustomOption}
                                                            value={model}
                                                            id="model"
                                                        /> :
                                                        <ReactSelect
                                                            options={this.state.models}
                                                            name={"model"}
                                                            placeholder={'select model'}
                                                            onChange={this.handleSelectChange}
                                                            value={model}
                                                            classNamePrefix={`${this.state.errors["model"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['model'] ? false : true))}
                                                        >
                                                        </ReactSelect>}
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'year'} /></label>
                                                    <ReactSelect
                                                        options={this.state.years}
                                                        name={"year"}
                                                        placeholder={'select year'}
                                                        onChange={this.handleSelectChange}
                                                        value={year}
                                                        classNamePrefix={`${this.state.errors["year"]} basic-select`}
                                                        isDisabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['year'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label> <Translate text={'modelDescription'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'model description'}
                                                        className={`form-control ${this.state.errors["modelDescription"]}`}
                                                        name="modelDescription"
                                                        onChange={this.handleOnChange}
                                                        value={modelDescription}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['description'] ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'stockNo'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'stock no'}
                                                        className={`form-control ${this.state.errors["stockNo"]}`}
                                                        name="stockNo"
                                                        onChange={this.handleOnCapChange}
                                                        value={stockNo}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['stockNo'] ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'vinNo'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'vehicle identification  number'}
                                                        className={`form-control ${this.state.errors["vinNo"]}`}
                                                        name="vinNo"
                                                        onChange={this.handleOnCapChange}
                                                        value={vinNo}
                                                        disabled={this.state.isAllocated ? true : (canModifyStock ? false : (updateMissing && !oldStock['vinNo'] ? false : true))}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'regNo'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'registration number'}
                                                        className={`form-control ${this.state.errors["regNo"]}`}
                                                        name="regNo"
                                                        onChange={this.handleOnCapChange}
                                                        value={regNo}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['regNo'] ? false : true))}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'regState'} /></label>
                                                    <ReactSelect
                                                        options={license_state}
                                                        name={"regState"}
                                                        placeholder={'select registration state'}
                                                        onChange={this.handleSelectChange}
                                                        value={regState}
                                                        classNamePrefix={`${this.state.errors["regState"]} basic-select`}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['regState'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label> <Translate text={'registrationDate'} /></label>
                                                    <AntDatePicker
                                                        value={regDate}
                                                        name={'regDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'regDate') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["regDate"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['regDate']) ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label> <Translate text={'regExp'} /></label>
                                                    <AntDatePicker
                                                        value={regExp}
                                                        name={'regExp'}
                                                        onChange={(e) => { this.handleDateChange(e, 'regExp') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["regExp"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['regExp']) ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'exteriorColor'} /></label>

                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'exterior colour'}
                                                        className={`form-control ${this.state.errors["extColor"]}`}
                                                        name="extColor"
                                                        onChange={this.handleOnChange}
                                                        value={extColor}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['extColor'] ? false : true))}
                                                    />
                                                </div>


                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'interiorColor'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'interior colour'}
                                                        className={`form-control ${this.state.errors["intColor"]}`}
                                                        name="intColor"
                                                        onChange={this.handleOnChange}
                                                        value={intColor}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['intColor'] ? false : true))}
                                                    />
                                                </div>




                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'buildDate'} /></label>
                                                    <AntMonthPicker
                                                        value={buildDate}
                                                        name={'buildDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'buildDate') }}
                                                        placeholder='MMM YYYY'
                                                        className={`form-control ${this.state.errors["buildDate"]}`}
                                                        format={'MMM YYYY'}
                                                        picker={'month'}
                                                        disabled={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['buildDate']) ? false : true))}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'warrantyStartDate'} /></label>
                                                    <AntDatePicker
                                                        value={warrantyStartDate}
                                                        name={'warrantyStartDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'warrantyStartDate') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["warrantyStartDate"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['warrantyStartDate']) ? false : true))}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'warrantyExpiry'} /></label>
                                                    <AntDatePicker
                                                        value={warrantyExp}
                                                        name={'warrantyExp'}
                                                        onChange={(e) => { this.handleDateChange(e, 'warrantyExp') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["warrantyExp"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['warrantyExp']) ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label> <Translate text={'price'} /></label>
                                                    {
                                                        hideStockPrice && price
                                                            ?
                                                            <>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    className={`form-control`}
                                                                    name="price"
                                                                    onChange={() => { }}
                                                                    value={CommonHelper.formatCurrency(currencySymbol, price, true)}
                                                                    disabled={true}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                <NumberFormat
                                                                    id={'price'}
                                                                    autoComplete="off"
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    placeholder={currencySymbol}
                                                                    className={`form-control ${this.state.errors["price"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'price')}
                                                                    value={price ? price : null}
                                                                    displayType={hideStockPrice ? 'text' : (canModifyStock ? 'input' : (updateMissing && !oldStock['price'] ? 'input' : 'text'))}
                                                                />
                                                            </>
                                                    }

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.stockOptionsStatic, 'purchasePrice')} /></label>
                                                    {
                                                        hideStockPrice && purchasePrice
                                                            ?
                                                            <>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    className={`form-control`}
                                                                    name="purchasePrice"
                                                                    onChange={() => { }}
                                                                    value={CommonHelper.formatCurrency(currencySymbol, purchasePrice, true)}
                                                                    disabled={true}
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                <NumberFormat
                                                                    id={'purchasePrice'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    placeholder={currencySymbol}
                                                                    className={`form-control ${this.state.errors["purchasePrice"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'purchasePrice')}
                                                                    value={purchasePrice ? purchasePrice : null}
                                                                    displayType={hideStockPrice ? 'text' : (canModifyStock ? 'input' : (updateMissing && !oldStock['purchasePrice'] ? 'input' : 'text'))}
                                                                />
                                                            </>
                                                    }

                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'odometerReading'} /> </label>
                                                    <NumberFormat
                                                        id={'odometerReading'}
                                                        thousandSeparator={true}
                                                        placeholder={'odometer reading'}
                                                        className={`form-control ${this.state.errors["miles"]}`}
                                                        onValueChange={this.onCurrencyChange.bind(this, 'miles')}
                                                        value={miles ? miles : null}
                                                        displayType={(canModifyStock ? 'input' : (updateMissing && !oldStock['miles'] ? 'input' : 'text'))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'Location'} /></label>
                                                    <ReactSelect
                                                        options={locations}
                                                        name={"location"}
                                                        placeholder={'select location'}
                                                        onChange={this.handleSelectChange}
                                                        value={location}
                                                        classNamePrefix={`${this.state.errors["location"]} basic-select`}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['location'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'Usage'} /></label>
                                                    <ReactSelect
                                                        options={usages}
                                                        name={"usage"}
                                                        placeholder={'select usage'}
                                                        onChange={this.handleSelectChange}
                                                        value={usage}
                                                        classNamePrefix={`${this.state.errors["usage"]} basic-select`}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['usage'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'transmission'} /></label>
                                                    <ReactSelect
                                                        options={transmissions}
                                                        name={"transmission"}
                                                        placeholder={'select transmission'}
                                                        onChange={this.handleSelectChange}
                                                        value={transmission}
                                                        classNamePrefix={`${this.state.errors["transmission"]} basic-select`}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['transmission'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'Transport Method'} /></label>
                                                    <ReactSelect
                                                        options={transportMethods}
                                                        name={"transportMethod"}
                                                        placeholder={'select transport method'}
                                                        onChange={this.handleSelectChange}
                                                        value={transportMethod}
                                                        classNamePrefix={`${this.state.errors["transportMethod"]} basic-select`}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['transportMethod'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'tradePlate'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'trade plate'}
                                                        className={`form-control ${this.state.errors["tradePlate"]}`}
                                                        name="tradePlate"
                                                        onChange={this.handleOnCapChange}
                                                        value={tradePlate}
                                                        disabled={(canModifyStock ? false : (updateMissing && !oldStock['tradePlate'] ? false : true))}
                                                    />
                                                </div>



                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'saleType'} /></label>
                                                    <ReactSelect
                                                        options={sale_type.filter(a => addUsedDemoStockOnly ? (a.value === 'Preowned' || a.value === 'Demo') : true)}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={this.handleSelectChange}
                                                        value={saleType}
                                                        classNamePrefix={`${this.state.errors["saleType"]} basic-select`}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['saleType'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={`${dealersettings && dealersettings.isMclaren === true ? 'Wholesale Date' : 'stockIntakeDate'}`} /></label>
                                                    <AntDatePicker
                                                        value={stockInDate}
                                                        name={'stockInDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'stockInDate') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["stockInDate"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['stockInDate']) ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'stockETADate'} /></label>
                                                    <AntDatePicker
                                                        value={etaDate}
                                                        name={'etaDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'etaDate') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["etaDate"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['etaDate']) ? false : true))}
                                                    />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'stockFOKDate'} /></label>
                                                    <AntDatePicker
                                                        value={fokDate}
                                                        name={'fokDate'}
                                                        onChange={(e) => { this.handleDateChange(e, 'fokDate') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["fokDate"]}`}
                                                        readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['fokDate']) ? false : true))}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label><Translate text={'status'} /></label>
                                                    <ReactSelect
                                                        options={this.state.isAllocated ? stock_status_isAllocated : stockStatuses}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={this.handleSelectChange}
                                                        value={status}
                                                        classNamePrefix={`${this.state.errors["status"]} basic-select`}
                                                        removeClearable={true}
                                                        isDisabled={(canModifyStock ? false : (updateMissing && !oldStock['status'] ? false : true))}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                                {
                                                    status === stockStatus.SOLD || status === stockStatus.DELIVERED
                                                        ?
                                                        <div className="form-group col-md-4">
                                                            <label>
                                                                {
                                                                    stockStatuses && _.find(stockStatuses, { value: 'sold' })
                                                                        ?
                                                                        <Translate text={_.find(stockStatuses, { value: 'sold' }).name + ' Date'} />
                                                                        :
                                                                        <Translate text={'soldDate'} />
                                                                }

                                                            </label>
                                                            <AntDatePicker
                                                                value={soldDate}
                                                                name={'soldDate'}
                                                                onChange={(e) => { this.handleDateChange(e, 'soldDate') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["soldDate"]}`}
                                                                readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['soldDate']) ? false : true))}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    status === stockStatus.DELIVERED
                                                        ?
                                                        <div className="form-group col-md-4">
                                                            <label>
                                                                {
                                                                    stockStatuses && _.find(stockStatuses, { value: 'delivered' })
                                                                        ?
                                                                        <Translate text={_.find(stockStatuses, { value: 'delivered' }).name + ' Date'} />
                                                                        :
                                                                        <Translate text={'deliveredDate'} />
                                                                }

                                                            </label>
                                                            <AntDatePicker
                                                                value={deliveredDate}
                                                                name={'deliveredDate'}
                                                                onChange={(e) => { this.handleDateChange(e, 'deliveredDate') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${this.state.errors["deliveredDate"]}`}
                                                                readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['deliveredDate']) ? false : true))}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    dealersettings && dealersettings.isMclaren === true ? (
                                                        <>
                                                            <div className="form-group col-md-4">
                                                                <label><Translate text={'McLaren ID'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'mclaren id'}
                                                                    className={`form-control ${this.state.errors["mclID"]}`}
                                                                    name="mclID"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={mclID}
                                                                    disabled={(canModifyStock ? false : (updateMissing && !oldStock['mclID'] ? false : true))}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label><Translate text={'Build Slot'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'build slot'}
                                                                    className={`form-control ${this.state.errors["mclBuildSlot"]}`}
                                                                    name="mclBuildSlot"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={mclBuildSlot}
                                                                    disabled={(canModifyStock ? false : (updateMissing && !oldStock['mclBuildSlot'] ? false : true))}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label><Translate text={'Spec. Freeze Date'} /></label>
                                                                <AntDatePicker
                                                                    value={mclSpecFreezeDate}
                                                                    name={'mclSpecFreezeDate'}
                                                                    onChange={(val) => {
                                                                        let fields = Object.assign({}, this.state.stock);
                                                                        fields['mclSpecFreezeDate'] = val ? val.format('YYYY-MM-DD') : null
                                                                        this.setState({ stock: fields });
                                                                    }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control ${this.state.errors["mclSpecFreezeDate"]}`}
                                                                    readOnly={(canModifyStock ? false : (updateMissing && _.isEmpty(oldStock['mclSpecFreezeDate']) ? false : true))}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label><Translate text={'Customer Name'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'customer name'}
                                                                    className={`form-control ${this.state.errors["mclCustomerName"]}`}
                                                                    name="mclCustomerName"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={mclCustomerName}
                                                                    disabled={(canModifyStock ? false : (updateMissing && !oldStock['mclCustomerName'] ? false : true))}
                                                                />
                                                            </div>

                                                        </>) : (<></>)
                                                }

                                            </div>

                                            {
                                                accessToStockVBM
                                                    ?
                                                    <>
                                                        <div className="requirement-section-head">VBM </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label> <Translate text={'marginValue'} /></label>
                                                                <NumberFormat
                                                                    id={'marginValue'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={vbmcurrencySymbol}
                                                                    placeholder={vbmcurrencySymbol}
                                                                    className={`form-control ${this.state.errors["marginValue"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'marginValue')}
                                                                    value={marginValue ? marginValue : null}
                                                                    displayType={accessToModifyVBM}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label> <Translate text={'marginRetail'} /></label>
                                                                <NumberFormat
                                                                    id={'marginRetail'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={vbmcurrencySymbol}
                                                                    placeholder={vbmcurrencySymbol}
                                                                    className={`form-control ${this.state.errors["marginRetail"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'marginRetail')}
                                                                    value={marginRetail ? marginRetail : null}
                                                                    displayType={accessToModifyVBM}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label> <Translate text={'marginOrderFill'} /></label>
                                                                <NumberFormat
                                                                    id={'marginOrderFill'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={vbmcurrencySymbol}
                                                                    placeholder={vbmcurrencySymbol}
                                                                    className={`form-control ${this.state.errors["marginOrderFill"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'marginOrderFill')}
                                                                    value={marginOrderFill ? marginOrderFill : null}
                                                                    displayType={accessToModifyVBM}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label> <Translate text={'marginPayments'} /></label>
                                                                <NumberFormat
                                                                    id={'marginPayments'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={vbmcurrencySymbol}
                                                                    placeholder={vbmcurrencySymbol}
                                                                    className={`form-control ${this.state.errors["marginPayments"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'marginPayments')}
                                                                    value={marginPayments ? marginPayments : null}
                                                                    displayType={accessToModifyVBM}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label> <Translate text={'marginMarketing'} /></label>
                                                                <NumberFormat
                                                                    id={'marginMarketing'}
                                                                    decimalScale={2}
                                                                    allowNegative={true}
                                                                    thousandSeparator={true}
                                                                    prefix={vbmcurrencySymbol}
                                                                    placeholder={vbmcurrencySymbol}
                                                                    className={`form-control ${this.state.errors["marginMarketing"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'marginMarketing')}
                                                                    value={marginMarketing ? marginMarketing : null}
                                                                    displayType={accessToModifyVBM}
                                                                />
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>

                                        <>

                                            <div className="requirement-pop-inner mt-3">
                                                <div className="linked-activities-title p-0 pb-2"> <Translate text={'Notes History'} />
                                                    <div className=" float-right linked-activities-add">
                                                        <button type="button"
                                                            className="btn btn-primary float-right"
                                                            onClick={(e) => { e.preventDefault(); this.handleNotesOpen(); }}
                                                        >
                                                            <i className="ico icon-add"></i> <Translate text={'addNote'} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    //notesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                    <div className="enquiry-flow mt-0 mb-3">
                                                        {
                                                            !_.isEmpty(notesHistory)
                                                                ?
                                                                <div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
                                                                    <div className="flow-timelinebar"></div>

                                                                    {
                                                                        notesHistory.map((obj, index) => {
                                                                            return (
                                                                                <div key={index} className="enquiry-flow-item note">
                                                                                    <div className="flow-item  note  ">
                                                                                        <div className="flow-item-content">
                                                                                            <div className="flow-text-wrap">
                                                                                                <div className="flow-text text-area-space note">{obj.notes}
                                                                                                    <div className="flow-notes-sub mt-1">
                                                                                                        <span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
                                                                                                        {
                                                                                                            obj.addedByName
                                                                                                                ?
                                                                                                                <>{' - '}{obj.addedByName}</>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        obj.addedBy
                                                                                                                            ?
                                                                                                                            <>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), obj.addedBy)}</>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span className="flow-icon"> <i className="ico icon-note"></i> </span>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }

                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="text-center p-2">
                                                                        <p><Translate text={'no notes created'} /> </p>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>
                                                    //)
                                                }
                                            </div>

                                        </>

                                        <div className="requirement-pop-inner" id='stock-add-images'>
                                            <div className="requirement-section-head"><Translate text={'vehicleImages'} /></div>
                                            <div className="requirement-image-wraper">

                                                <div className="requirement-item-image upload">
                                                    <label htmlFor="stock_images">
                                                        <img src={_images.addvehicle} alt="" htmlFor="stock_images" />
                                                        <input className="fileInput"
                                                            type="file"
                                                            name="stock_images"
                                                            id="stock_images"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => this.onSelectFile(e, 'stock', 'images', 'Stock Image')} />
                                                    </label>
                                                </div>
                                                {!_.isEmpty(images) && images.map((data, index) => (
                                                    <div className="requirement-item-image" key={index}>
                                                        {
                                                            canModifyStock
                                                                ?
                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleImageDel(index);
                                                                }}>
                                                                    <i className="ico icon-delete" ></i>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        <a data-fancy-box={`vehicle_img`}
                                                            href={data}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ imageViewer: { show: true, images: images, index } });
                                                            }}>
                                                            <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                        </a>
                                                    </div>
                                                ))}
                                                {
                                                    (this.state.imageURL) ?
                                                        (
                                                            <div className="requirement-item-image">
                                                                <div className="img-loader">
                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                    <img src={this.state.imageURL} width="70" height="70" alt="" className="img-object-fit" />
                                                                </a>
                                                            </div>
                                                        )
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>

                                        <>

                                            <div className="requirement-pop-inner mt-3">
                                                {/* <div className="linked-activities-title p-0 pb-2"> <Translate text={'Specifications'} />
                                             <div className=" float-right linked-activities-add">
                                                    <button type="button"
                                                        className="btn btn-primary float-right"
                                                        onClick={(e) => { e.preventDefault(); this.childRef.current.handleFileOpen(); }}
                                                    >
                                                        <i className="ico icon-add"></i> <Translate text={'addSpecification'} />
                                                    </button>
                                                </div> 
                                            </div> */}
                                                {
                                                    documentID
                                                        ?
                                                        <>
                                                            <div className="enquiry-flow mt-0 mb-3 position-relative">
                                                                <Files
                                                                    //ref={this.childRef}
                                                                    stockID={documentID}
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    isDeivered={canModifyStock ? readOnlyEnquiryView : true}
                                                                    filePath={`stock/${documentID}/files`}
                                                                //showAdd={readOnlyEnquiryView ? false : true}
                                                                >
                                                                </Files>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </div>

                                        </>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body >
                        {
                            readOnlyEnquiryView
                                ?
                                <>
                                    < Modal.Footer className={`form-disable`
                                    }>

                                        <div className='checkbox icheck-success  float-left checkbox-bg'>
                                            <input
                                                type='checkbox'
                                                id={`success-pop-${documentID}`}
                                                name='isStockArrived'
                                                onChange={e => {
                                                    console.log('isStockArrived')
                                                }}
                                                checked={isStockArrived}
                                                disabled={true}
                                            />
                                            <label htmlFor={`success-pop-${documentID}`}>
                                                <Translate text={'Stock Arrived'} />
                                            </label>
                                        </div>
                                    </Modal.Footer >
                                </>
                                :
                                <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>

                                    <div className='checkbox icheck-success  float-left checkbox-bg'>
                                        <input
                                            type='checkbox'
                                            id={`success-pop-${documentID}`}
                                            name='isStockArrived'
                                            onChange={e => {
                                                this.onCheckChange(e, 'isStockArrived')
                                            }}
                                            checked={isStockArrived}
                                            disabled={etaDate ? false : true}
                                        />
                                        <label htmlFor={`success-pop-${documentID}`}>
                                            <Translate text={'Stock Arrived'} />
                                        </label>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary float-right ml-2"
                                        onClick={(e) => this.handleSaveInfo(e)}
                                    >
                                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'save'} />
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-default float-right"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.handleClose();
                                        }}
                                    ><Translate text={'cancel'} />
                                    </button>
                                </Modal.Footer>
                        }


                    </Modal >

                    <ImageCropHook
                        cropShow={this.state.cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>

                    <PopUpModal show={fleetModal.show}>
                        <AddFleet
                            docID={fleetModal.ID}
                            show={fleetModal.show}
                            clsActive={fleetModal.clsActive}
                            stock={fleetModal.stock}
                            handleClose={this.handleLoanVehicleClose}
                            title={fleetModal.title}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        >
                        </AddFleet>
                    </PopUpModal>

                    <PopUpModal show={testDriveModal.show}>
                        <AddTestDrive
                            docID={testDriveModal.ID}
                            show={testDriveModal.show}
                            clsActive={testDriveModal.clsActive}
                            handleClose={this.handleTestDriveClose}
                            title={testDriveModal.title}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            isDeivered={false}
                            contact={
                                (!_.isEmpty(testDriveModal.stock) && !_.isEmpty(testDriveModal.stock.testDriveDetail))
                                    ?
                                    testDriveModal.stock.testDriveDetail.contact
                                    :
                                    testDriveModal.contact
                            }
                        >
                        </AddTestDrive>
                    </PopUpModal>

                    <PopUpModal show={showNotes}>
                        <AddNotes
                            show={showNotes}
                            handleClose={this.handleNotesClose}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        />
                    </PopUpModal>
                    <PopUpModal show={imageViewer.show}>
                        <ImageViewer
                            {...imageViewer}
                            handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                        />
                    </PopUpModal>

                </>
                :
                <></>


        );
    }
}