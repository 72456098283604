import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import _images from '../../images'
import moment from 'moment'
import { PieChart, NoDataPieChart } from '../../components'
import { colorPieArray, pipelinestatus } from './viewModel'
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import NumberFormat from 'react-number-format';

const CampaignPerformance = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);
    const [dealerCounts, setDealerCounts] = useState([])
    const [initialLoad, setInitialLoad] = useState(true)

    const [dealersData, setDealersData] = useState([])
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [refreshTime, setRefreshTime] = useState(0)
    const [oldDaterange, setOldDaterange] = useState({})

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const inclInboundBuckeinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundBuckeinRpt) ? true : false);

    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-${props.id}`);

    useEffect(() => {
        return () => {
            window[`unsub${props.id}`] && window[`unsub${props.id}`]();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0 || props.fromOEM)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        if (props.fromOEM) return; //sameer 02-10-24

        setLoader(true)

        window[`unsub${props.id}`] = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setDealerCounts(snap.data().counts);
                    setData(setChartData(snap.data().counts));
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    useEffect(() => {
        if (!props.fromOEM) return; //sameer 02-10-24

        window[`unsub${props.id}`] = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    //console.log(`unsub${props.id}`)
                    //setDealersData(props.pieChartData)
                    setLoader(false);
                    setInitialLoad(false)
                    //setDealerCounts(snap.data().counts);
                    setData(setChartData(snap.data().counts));
                    //setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    // if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                    //     fetchData(true);
                    // }
                }
                // else {
                //     fetchData();
                // }
            })
    }, [])

    //#region sameer 02-10-24
    useEffect(() => {

        if (!props.fromOEM) return

        //console.log(`01-${props.id}`, props.initialPieChartLoad, props.pieChartLoader, props.pieChartData)
        if (props.fromOEM) {
            if (props.pieChartLoader) setLoader(true)

            if (!props.pieChartLoader) {
                //console.log(`00002-${props.id}`, props.initialPieChartLoad, props.pieChartLoader, props.pieChartData)
                //setLoader(false);
                setLoader(false);
                // setTimeout(() => {
                //     setLoader(false);
                // }, 500);
                setInitialLoad(false)
                let _filter = {
                    startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                    endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
                if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
                    _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
                    _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
                }
                let _data = loadFieldData(props.pieChartData, _filter.startDate, _filter.endDate);


                if (_.isEmpty(props.dashboardFilter)) {
                    docPathRef.set({ counts: _data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
                }
                else {
                    setDealersData(props.pieChartData)
                    setData(setChartData(_data));
                }
            }
        }

    }, [props.firstTimePieChartLoaded])
    //#endregion


    const fetchData = async (hideLoader) => {
        // console.log('lead by stage')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            dmsDate: true,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            hideTradein: true,
            hideActivities: true,
            hideWalkin: true,
            scheduleActivities: false,
            inclInboundBuckeinRpt,
            inclInboundLeadsinRpt,
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else if (!_.isEmpty(props.dateRange)) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }

        let _searchObject = {
            type: "getSummaryReport",
            params: JSON.stringify(_filter),
        }

        if (!hideLoader)
            setLoader(true);

        const getData = window.firebase.functions().httpsCallable('generic-getData2');

        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp___alternate', resp);
            let _data = loadFieldData(resp.data.data, _filter.startDate, _filter.endDate);
            //console.log('resp_data', props.id, _data, _filter);

            setData(setChartData(_data));
            if (_.isEmpty(props.dashboardFilter)) {
                setDealerCounts(_data);
                setDealersData(resp.data.data)
                await docPathRef.set({ counts: _data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const loadFieldData = (data, startDate, endDate) => {
        const filterData = data.filter((a) => a.stgv !== 'Archived'
            && (props.skipEmpty === true ? !_.isEmpty(a[props.fieldName]) : true)
            && (props?.dashboardFilter?.pipelines?.length ? props.dashboardFilter.pipelines.includes(a.plnv) :
                (!_.isEmpty(props.pipeline) && !props.fromOEM ? (props.pipeline.filter(item => !Boolean(item.disableReport) && (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket')).some(b => b.value === a.plnv)) : true))
            && (props?.dashboardFilter?.makes?.length ? a.mk && props.dashboardFilter.makes.some(b => b.toLowerCase() === a.mk.toLowerCase()) : true)
            && (props?.dashboardFilter?.salesType?.length ? a.st && props.dashboardFilter.salesType.includes(a.st) :
                (!_.isEmpty(props.dealersettings?.accessSalesType) ? props.dealersettings.accessSalesType.includes(a.st) : true))
            && (props?.dashboardFilter?.user?.length ? a.st && props.dashboardFilter.user.includes(a.uid) : true)
            && (props?.dashboardFilter?.clients?.length ? props.dashboardFilter.clients.includes(a.cid) : true)
            && (props?.dashboardFilter?.models?.length ? a.ml && props.dashboardFilter.models.some(b => b.toLowerCase() === a.ml.toLowerCase()) : true)
            && ((props?.dashboardFilter?.campaigns?.length) ? a.cpgv && props.dashboardFilter.campaigns.includes(a.cpgv) : true)
            && ((props?.dashboardFilter?.origins?.length) ? a.orgv && props.dashboardFilter.origins.includes(a.orgv) : true)
            && ((props?.dashboardFilter?.enquiryTypes?.length) ? a.etv && props.dashboardFilter.enquiryTypes.includes(a.etv) : true)
            && ((props?.dashboardFilter?.leadSource?.length) ? a.lsv && props.dashboardFilter.leadSource.includes(a.lsv) : true)
        );
        let _data = [];
        if (props.filterType === 'qualified')
            _data = checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.conv === 'true'), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix())
        else if (props.filterType === 'lead')
            _data = checkDataFilter(filterData.filter((a) => a.col === 'enq' && a.tags && JSON.parse(a.tags).some(b => b.type === 'cap')), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix(), null, 'dmsdt')
        else
            _data = checkDataFilter(filterData.filter((a) => a.col === 'enq'), 'eid', moment(startDate).startOf('day').unix(), moment(endDate).endOf('day').unix())

        if (!props.skipEmpty)
            _data = _data.map(item => ({
                ...item,
                [props.fieldName]: item?.[props.fieldName] ? item[props.fieldName] : "others"
            }))

        return !_.isEmpty(_data) ? _.chain(_data).groupBy(props.fieldName)
            .map((value, key) => ({
                name: key,
                count: value.length,
                documentIDs: _.uniqBy(value, 'eid').map(r => { return r['eid'] }),
            }))
            .value() : []
    }

    const setChartData = (_data) => {
        let _list = !_.isEmpty(_data) ? _data.map((_rec, index) => {
            let name = _rec.name
            if (props.kpi === 'models') {
                if (props.make || props.defaultMake) {
                    let brands = props.dealersettings.client.brands;
                    let _make = props.make ? props.make : brands?.[0]?.value;
                    let _models = _make && !_.isEmpty(brands.filter(m => m.value === _make)[0]) ? brands.filter(m => m.value === _make)[0].models : [];

                    if (!_.isEmpty(_models) && _models.length > 0 && _models.filter(a => a.value === _rec.name).length > 0)
                        name = CommonHelper.displayModelName(_models.filter(a => a.value === _rec.name)[0])
                }
            }
            else if (props.kpi === 'pipeline') {
                if (!_.isEmpty(props.dealersettings?.allPipelines) && props.dealersettings.allPipelines.length > 0) {
                    if (props.dealersettings.allPipelines.filter(a => a.value === _rec.name).length > 0)
                        name = props.dealersettings.allPipelines.filter(a => a.value === _rec.name)[0].name
                    else if (props?.dealersettings?.client?.settings?.pipelines?.filter(a => a.value === _rec.name).length > 0)
                        name = props.dealersettings.client.settings.pipelines.filter(a => a.value === _rec.name)[0].name
                    else name = 'Other/Blank';
                }
            }
            else if (!_.isEmpty(props.dealersettings?.client?.settings?.[props.kpi]) && props.dealersettings.client.settings[props.kpi].length > 0) {
                if (props.dealersettings.client?.settings?.[props.kpi].filter(a => a.value === _rec.name).length > 0)
                    name = props.dealersettings.client.settings[props.kpi].filter(a => a.value === _rec.name)[0].name
                else name = 'Other/Blank';
            }
            return {
                ..._rec,
                name: (name === 'others' ? 'Other/Blank' : name),
                value: _rec.name,
                startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD'),
                color: props.dealersettings.isPorsche === true ? colorPieArray[index % colorPieArray.length] : null
            }
        }) : []
        _list = _.orderBy(_list, ['count'], ['desc'])
        return _list;
    }

    const checkDataFilter = (_data, fieldName, startDt, endDt, status, dateType, newlead, saleType) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return _.uniqBy(filterData, fieldName)
    }

    // useEffect(() => {
    //     if (initialLoad)
    //         return;
    //     if ((props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) || _.isEmpty(props.dashboardFilter)) {
    //         setLoader(true)
    //         setInitialLoad(true)
    //     }
    //     else {
    //         setData(setChartData(dealerCounts));
    //     }

    // }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;

        if (props.fromOEM)
            return;

        //console.log(`xxxxxxxxxxxxxxxxxxxxxx-5 ${props.id}`, props.pieChartLoader, props.pieChartData)
        if (!_.isEmpty(props.dashboardFilter)) {
            if (props.fromOEM) {
                if ((!_.isEmpty(oldDaterange) || !_.isEmpty(props?.dashboardFilter?.daterange)) && JSON.stringify(oldDaterange) !== JSON.stringify(props?.dashboardFilter?.daterange)) {
                    setFirstTimeLoaded(false);
                    //console.log(`load-5 ${props.id}`, props.pieChartLoader, props.pieChartData)
                    window[`unsub${props.id}`] && window[`unsub${props.id}`]();
                    fetchData()
                    setOldDaterange(props?.dashboardFilter?.daterange)
                }
                else if (!_.isEmpty(dealersData)) {
                    //console.log(`load-6 ${props.id}`, props.pieChartLoader, props.pieChartData)
                    let _filter = {
                        startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                        endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                    }
                    let _data = loadFieldData(dealersData, _filter.startDate, _filter.endDate);
                    setData(setChartData(_data));
                }
                else {
                    window[`unsub${props.id}`] && window[`unsub${props.id}`]();
                    setFirstTimeLoaded(false);
                    fetchData();

                }
            } else {
                window[`unsub${props.id}`] && window[`unsub${props.id}`]();
                setFirstTimeLoaded(false);
                fetchData();
            }
        }
        else {
            setInitialLoad(true)
        }


    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    const handleShowList = (value, startDate, endDate, data) => {
        //console.log(value, data)
        if (!_.isEmpty(data?.['documentIDs']) && data['documentIDs'].length > 0) {
            props.handleShowEnquiryList({ documentIDs: data?.['documentIDs'] || [] })
        }
        else {
            //  props.handleShowEnquiryList({
            //      [props.fieldFullForm]: value,
            //      'cap': props.isPorsche === true ? true : null,
            //      'status': 'all',
            //      'pipeline': _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
            //      'startDate': startDate,
            //      'endDate': endDate,
            //      'dateType': 'addedDate'
            //  })
        }
    }

    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])
    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> */}
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                    </div>
                    <h3>{props.title}</h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={`${props.id}-pie-chart`}
                                    height={'350px'}
                                    data={reportData}
                                    handleShowEnquiryList={handleShowList}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={`${props.id}-pie-chart`}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div >
        </>
    );
}
export default CampaignPerformance;