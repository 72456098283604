import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { MainContext } from '../layout/provider';

const DraftEmailListBody = ({
	selectedMessage,
	token,
	handleOpenComposeModal,
	updateMessage,
	setSelectedMessage
}) => {
	const componentRef = useRef();
	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState([]);
	const { dealersettings } = useContext(MainContext);
	const nylasEmail = dealersettings.nylas && dealersettings.nylas.email_address ? dealersettings.nylas.email_address : dealersettings.email

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--emailListBodyHeight',
				window.innerHeight - 55 + 'px',
			);
			document.documentElement.style.setProperty('overflow', 'hidden');
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--emailListBodyHeight',
			window.innerHeight - 55 + 'px',
		);
		document.documentElement.style.setProperty('overflow', 'hidden');
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const loadMessages = async () => {
      if (!selectedMessage) {
        return
      }

			setLoading(true);
			// console.log(selectedMessage);
			const messageIdList = selectedMessage.draft_ids;
			const messageIdListLength = selectedMessage.draft_ids.length;
			let arrayCompleted = false;
			// // console.log('THIS IS SELECTED MESSAGE IDS messageIdList', messageIdList);
			const nylasMessage = window.firebase
				.functions()
				.httpsCallable('nylas-getMessage');
			setMessages([]);
			const check = [];
			messageIdList.forEach((messageId, index) => {
				const dataObj = {
					token: token,
					id: messageId,
				};

				nylasMessage(dataObj).then(message => {
					check.push(message.data);
					if (messageIdListLength === check.length) {
						arrayCompleted = true;
						setMessages(_.orderBy(check, ['date'], ['asc']));
						setLoading(false);
					}
				}).catch(err => {
					console.log(err)
					setSelectedMessage(null);
				});

				if (selectedMessage.unread === true) {
					updateMessage(selectedMessage.id, 'read', {});
				}
			});
    };
    
    loadMessages()
	}, [selectedMessage, token]);

	const handleReply = isReplyToAll => {
		let lastMessage = messages[messages.length - 1];

		let replyEmailDate = lastMessage.date;
		let replyEmailName = lastMessage.from[0].name;
		let replyEmailAddress = lastMessage.from[0].email;
		let replyToMessageId = lastMessage.id;
		let replyToEmailContent = lastMessage.body;
		let replyTo;
		let replyToCC;
		let replyToAll;

		if (isReplyToAll) {
			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				if (messages[messages.length - 2]) {
					lastMessage = messages[messages.length - 2];
				}
			}

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				lastMessage = messages[0];
			}

			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				replyTo = [
					{ name: lastMessage.to[0].name, email: lastMessage.to[0].email },
				];
			}

			replyToCC = selectedMessage.participants.filter((participant, i) => {
				// // console.log(
				// 	'participant.email, nylasEmail ' + i,
				// 	participant.email,
				// 	nylasEmail,
				// 	participant.email === nylasEmail,
				// );
				if (participant.email === nylasEmail) {
					return false;
				}
				// // console.log(
				// 	'participant.email, replyTo.email ' + i,
				// 	participant.email,
				// 	replyTo.email,
				// 	participant.email === replyTo.email,
				// );
				if (participant.email === replyTo[0].email) {
					return false;
				}

				return true;
			});

			// console.log('replyToCC', replyToCC);
			replyToAll = true;
		} else {
			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				if (messages[messages.length - 2]) {
					lastMessage = messages[messages.length - 2];
				}
			}

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				lastMessage = messages[0];
			}

			replyTo = [
				{ name: lastMessage.from[0].name, email: lastMessage.from[0].email },
			];

			if (lastMessage.from[0].email === nylasEmail) {
				// if (lastMessage.from[0].email === currentUserEmail)
				replyTo = [
					{ name: lastMessage.to[0].name, email: lastMessage.to[0].email },
				];
			}
			replyToAll = false;
		}

		handleOpenComposeModal({
			type: 'reply',
			title: 'Reply',
			emailData: {
				replyTo,
				replyToMessageId,
				replyToEmailContent,
				replyEmailDate,
				replyEmailName,
				replyEmailAddress,
				replyToAll,
				replyToCC,
			},
		});
	};

	const handleForward = () => {
		let lastMessage = messages[messages.length - 1];
		const emailContent = lastMessage.body;

		handleOpenComposeModal({
			type: 'forward',
			title: 'Forward Message',
			emailData: { emailContent },
		});
	};

	return loading ? (
    <></>
		// <div className='email-list-body'>
		// <div className='h-100  w-100'>
		// 	<div className='spinner-loader pt-5 h-100'>
		// 		<div className='d-flex h-100 justify-content-center align-items-center loader-primary'>
		// 			<div role='status' className='spinner-border loader-primary'></div>
		// 			<p className='text-muted mt-3'></p>
		// 		</div>
		// 	</div>
		// </div>
		// </div>
	) : (
		<>
			{/* <div className='email-list-body'>
				<div className='email-details'>
					<div className='email-details-header'>
						<div className='float-left'>
							<div
								className='action-email-btn'
								onClick={() =>
									updateMessage(selectedMessage.id, 'moveToTrash', {})
								}
							>
								<a href='#'>
									{' '}
									<i className='ico icon-mail-delete'></i>{' '}
								</a>
							</div>
							<div
								className='action-email-btn'
								onClick={() =>
									updateMessage(selectedMessage.id, 'moveToArchive', {})
								}
							>
								<a href='#'>
									{' '}
									<i className='ico icon-mail-archive'></i>{' '}
								</a>
							</div>
							<div className='action-email-btn'>
								<ReactToPrint
									trigger={() => (
										<a href='#'>
											{' '}
											<i className='ico icon-mail-print'></i>{' '}
										</a>
									)}
									content={() => componentRef.current}
								/>
							</div>
						</div>
						<div className='float-right'>
							<div className='action-email-btn'>
								<a href='#' onClick={() => handleReply(false)}>
									{' '}
									<i className='ico icon-mail-reply'></i>{' '}
								</a>
							</div>
							<div className='action-email-btn'>
								<a href='#' onClick={() => handleReply(true)}>
									{' '}
									<i className='ico icon-mail-replyall'></i>{' '}
								</a>
							</div>
							<div className='action-email-btn' onClick={handleForward}>
								<a href='#'>
									{' '}
									<i className='ico icon-mail-forward'></i>{' '}
								</a>
							</div>
						</div>
					</div>
					<h4 className='email-details-title'>{selectedMessage.subject}</h4>

					{messages.length > 0 &&
						// sorting to show the latest at the bottom
						messages.map((message, index) => (
							<EmailListItem
								message={message}
								key={message.id}
								isMsgLatest={messages.length === index + 1}
								updateMessage={updateMessage}
								selectedMessage={selectedMessage}
								handleReply={handleReply}
							/>
						))}

					<div className='email-details-message-footer'>
						<div className='float-right'>
							<button
								type='button'
								className='btn btn-default'
								onClick={() => handleReply(false)}
							>
								<i className='ico icon-mail-reply'></i> Reply
							</button>
							<button
								type='button'
								className='btn btn-default ml-2'
								onClick={handleForward}
							>
								<i className='ico icon-mail-forward'></i> Forward
							</button>
						</div>
						<button
							type='button'
							className='btn btn-default'
							onClick={() =>
								updateMessage(selectedMessage.id, 'moveToTrash', {})
							}
						>
							<i className='ico icon-mail-delete'></i> Delete
						</button>
						<ReactToPrint
							trigger={() => (
								<button type='button' className='btn btn-default ml-2'>
									<i className='ico icon-mail-print'></i> Print
								</button>
							)}
							content={() => componentRef.current}
						/>
						
					</div>
				</div>
			</div>

			<div className='email-list-right'>
				<div className='email-link-wraper'>
					<div className='email-link-info'>
						<div className='email-link-info-avatar'>
							<img className='bdrs-50p w-3r h-3r' alt='' src={_images.nouser} />
						</div>
						Fusion SD
					</div>

					<div className='email-link-id'>
						<i className='ico icon-Mail'></i> support@fusionsd.com.au{' '}
					</div>

					<div className='email-link-buttons'>
						<button className='btn btn-sm btn-default'>
							Add to existing contact
						</button>
						<button className='btn btn-sm btn-primary'>
							Create new contact
						</button>
					</div>
				</div>

				<div className='email-link-wraper'>
					<div className='email-link-head'>Link to an Enquiry</div>

					<div className='email-link-buttons'>
						<button className='btn btn-sm btn-default'>Find an Enquiry</button>
						<button className='btn btn-sm btn-primary'>
							Create new Enquiry
						</button>
					</div>
				</div>
			</div>
			<Print ref={componentRef} /> */}
		</>
	);
};

export default DraftEmailListBody;
