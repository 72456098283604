import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';


// const CustomInput = React.forwardRef((props, ref) => (
//     <input {...props} ref={ref} />
// ));

const NumberEditor = (props, ref) => {
    const [value, setValue] = useState(props.value ? { formattedValue: props.value } : {});
    const numInputRef = useRef();

    useEffect(() => {
        if (numInputRef.current) {
            numInputRef.current.focus();
        }
    }, []);


    const handleChange = (e) => {
        setValue(e);

    };

    const handleSubmit = (e) => {
        if (e.key === 'Enter' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            props.api.stopEditing();
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (value?.[props?.colDef?.dataType === 'numberstring' ? 'formattedValue' : 'floatValue'] || null);
            props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (value?.[props?.colDef?.dataType === 'numberstring' ? 'formattedValue' : 'floatValue'] || null))
        }
    }

    const handleTextChange = (e) => {
        setValue({ formattedValue: props?.colDef?.uppercase ? e.target.value.toUpperCase() : e.target.value });

    };

    const handleTextSubmit = (e) => {
        if (e.key === 'Enter' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            props.api.stopEditing();
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (value?.formattedValue || null);
            props.api.getDisplayedRowAtIndex(props.rowIndex).setDataValue(props.colDef.field, (value?.formattedValue || null))
        }
    }

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            {
                _.isEmpty(props?.colDef?.dataType) || props?.colDef?.dataType === 'text' ? (
                    <input
                        id={props.colDef.field}
                        ref={numInputRef}
                        autoComplete={"off"}
                        name={props.colDef.field}
                        type={"text"}
                        value={value.formattedValue || ''}
                        placeholder={props?.colDef?.placeholder || ''}
                        className={'form-control'}
                        onChange={handleTextChange}
                        onKeyDown={handleTextSubmit}
                    />
                ) : (
                    <NumberFormat
                        // customInput={CustomInput}
                        id={props.colDef.field}
                        getInputRef={numInputRef}
                        decimalScale={2}
                        thousandSeparator={props?.colDef?.dataType === 'price'}
                        allowNegative={props?.colDef?.dataType === 'price'}
                        prefix={props?.colDef?.prefix || ''}
                        suffix={props?.colDef?.suffix || ''}
                        placeholder={props?.colDef?.prefix || props?.colDef?.placeholder || ''}
                        className={`form-control`}
                        onValueChange={handleChange}
                        value={value.formattedValue}
                        onKeyDown={handleSubmit}
                    />
                )
            }

        </div>
    );
};

export default NumberEditor;