/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** PROVIDERS */
import { LayoutConsumer } from './provider';
import { RouteConsumer } from './RouteProvider';
/** COMPONENTS */
import { LoaderOverlay } from '../../components'

//const Calendar = lazy(() => import('../calendar'));
//const CalendarList = lazy(() => import('../calendar/list'));
//const Contacts = lazy(() => import('../contacts'));
//const Companys = lazy(() => import('../company'));
//const Mail = lazy(() => import('../mail'));
//const Stock = lazy(() => import('../stock'));
//const Fleet = lazy(() => import('../fleet'));
//const FleetHistory = lazy(() => import('../fleet/history'));
//const Reports = lazy(() => import('../reports'));
//const Settings = lazy(() => import('../settings'));
//const EnquiryInfo = lazy(() => import("../pipeline/details"));
//const ContactInfo = lazy(() => import("../contacts/details"));
//const CafeOrders  = lazy(() => import('../cafe'));
//const Kitchen = lazy(() => import('../cafe/kitchen'));
//const CafeHistory = lazy(() => import('../cafe/history'));
//const FrontDesk = lazy(() => import('../frontdesk'));
//const CafeOrdersList = lazy(() => import('../cafe/cafeOrdersList'));
//const OEMDashboard = lazy(() => import('../oem/dashboard'));
//const OEMReport = lazy(() => import('../oem/report'));
//const Dashboard = lazy(() => import('../dashboard'));
//const Pipeline = lazy(() => import('../pipeline'));
//const PipelineList = lazy(() => import('../pipeline/list'));

import Dashboard from '../dashboard';
import Pipeline from '../pipeline';
import PipelineList from '../pipeline/list';
import PipelineOEMList from '../pipeline/oemList';
import Calendar from '../calendar';
import CalendarList from '../calendar/list';
import Contacts from '../contacts';
import Companys from '../company';
import Mail from '../mail';
import Mails from '../mails'
import Stock from '../stock';
import Fleet from '../fleet';
import FleetHistory from '../fleet/history';
import Reports from '../reports';
import Settings from '../settings'
import EnquiryInfo from "../pipeline/details";
import ContactInfo from "../contacts/details";
import ContactInfoPorsche from "../contacts/details/indexPorsche";
import CompanyInfo from "../company/details";
import CafeOrders from '../cafe'
import Kitchen from '../cafe/kitchen';
import CafeHistory from '../cafe/history'
import FrontDesk from '../frontdesk'
import CafeOrdersList from '../cafe/cafeOrdersList'
import OEMDashboard from '../oem/dashboard';
import OEMReport from '../oem/reports';
import Dealers from '../oem/dealers';
import OEMStock from '../oem/stock';
import CallLogs from '../callLogs';
import ErrorBoundary from '../../components/errorBounday';
import InBound from "../inBound";
import InBoundList from '../inBound/list';
import Campaigns from '../campaigns/container'
import BulkSMS from '../bulksms'
import BDCDashboard from '../bdcDashboard'
import TestDriveHistory from '../testdrive';
import TradeInHistory from '../tradeIn';
import ServiceInfo from "../service/details";
import ServiceCalendar from "../serviceCalendar";
import Service from '../service';
import ServiceList from '../service/list';
import BulkSMSHub from '../smsHub'
import BulkSMSPodium from '../podium'
import FeedBackForms from '../feedbackform'
import ServiceDashboard from '../dashboardService';
import AllocationList from '../oemAllocation/list'
import AfterSalesDashboard from '../dashboard/aftersales'
import TradeinProDashboard from '../dashboardTradeinPro';
import EventsProDashboard from '../dashboardEventsPro';

import AfterSales from "../afterSales";
import AfterSalesList from '../afterSales/list';
import SubscriptionEnded from "../subscriptionEnded";
import ServiceReports from '../serviceReports'
import TradeinProReports from '../tradeinproReports'

import TradeinPro from '../tradeinplus';
import TradeinProInfo from "../tradeinplus/details";
import TradeinProList from '../tradeinplus/list';

import EventsProList from '../eventsPro/list';
import EventsProInfo from "../eventsPro/details";
import EventTemplates from '../eventsProTemplates';
import EventsProAttendees from '../eventsProAttendees/listAllocation';
import EventsProCalendar from "../eventsProCalendar";

import WholesalerInfo from "../wholesaler/details/index";
import Wholesaler from '../wholesaler'
import ScheduleList from '../settings/workflow/tvDevices/scheduleList';
import DailyCheckout from '../dailyCheckout'
import AcquisitionList from '../acquisition'
import Roster from '../roster'
import InBoundTIPList from '../tradeinplusInbound/list';
import PpsrList from '../ppsr/list'
import TriggerList from '../triggersLog/list'
import OEMStockPipeline from '../oem/stock/mclarenPipeline';
import DashboardSales from '../oem/dashboardSales';
import MarketingROI from '../reports/marketingROI';

import FleetProDashboard from '../dashboardFleetPro'
import FleetProList from '../fleetPro/index';
import FleetAssignHistory from '../fleetPro/dealerHistory';
import FleetAssignCalendar from '../fleetPro/calendar';
import FleetProInfo from "../fleetPro/details";
import PorscheDashboard from '../dashboardPorsche';

import OEMStockManagement from '../oem/stock/managementList';
import YearlyPerformance from '../reports/yearlyPerformance';
import YearlySalesPerformance from '../reports/yearlySalesPerformance';
import CustomerTargetReport from '../oem/reports/customTargets';
import DealLog from '../dealsLog';
import DocumentList from '../pandadoc/list';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(location => {
            const currentURL = `${window.location.origin}${location.pathname}`
            window.firebase.analytics().logEvent('page_view', {
                location: location.pathname,
                currentURL,
                userID: localStorage.uid ? localStorage.uid : '',
                defaultModule: localStorage.defaultModule ? localStorage.defaultModule : ''
            });
            // window.document.title = `AMS Pro - Web Application - ${location.pathname}`
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const { dealersettings } = this.props;
        const _userRoles = (dealersettings && dealersettings.roleID &&
            dealersettings.client && dealersettings.client.roles &&
            dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);

        //const isInboundAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e === 'LeadsBucket') ? true : false;
        const isSalesAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e !== 'LeadsBucket') ? true : false;
        return (
            <main role="main" id="main-container-fluid" className="container-fluid">
                <RouteConsumer>
                    {({ handleRouteChange, resetnavbar, updatenavbar, handleRouteRemove }) => (
                        <LayoutConsumer>
                            {({ loadingText, dealersettings, currentClient, clientUsers, groupUsers, pipeline, updateDealerSettings, updatePipelineSettings, clientModule, handleModuleChange, paymentDue, paymentDueDate, accountSuspended, dialNumber, dialRingcentral, handleClientChange }) => (loadingText || _.isEmpty(dealersettings) || _.isEmpty(clientUsers)) ? (<LoaderOverlay text={loadingText} active={true} />) : (
                                <>
                                    <Suspense fallback={<></>}>
                                        <Switch>
                                            <ErrorBoundary dealersettings={dealersettings}>
                                                <Route path="/dashboard" exact render={() =>
                                                    <>
                                                        {
                                                            dealersettings.isPorsche === true ?
                                                                <PorscheDashboard
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    history={this.props.history}
                                                                    pipeline={pipeline}
                                                                    updateDealerSettings={updateDealerSettings}
                                                                />
                                                                : isSalesAccess
                                                                    ?
                                                                    <Dashboard
                                                                        dealersettings={dealersettings}
                                                                        clientUsers={clientUsers}
                                                                        groupUsers={groupUsers}
                                                                        history={this.props.history}
                                                                        pipeline={pipeline}
                                                                        updateDealerSettings={updateDealerSettings}
                                                                    />
                                                                    :
                                                                    <BDCDashboard
                                                                        dealersettings={dealersettings}
                                                                        clientUsers={clientUsers}
                                                                        groupUsers={groupUsers}
                                                                        history={this.props.history}
                                                                        pipeline={pipeline}
                                                                        updateDealerSettings={updateDealerSettings}
                                                                    />
                                                        }
                                                    </>

                                                } />
                                                <Route path="/pipeline" exact render={() => <Pipeline dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/pipeline/list" exact render={(match) => <PipelineList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/pipeline/oemlist" exact render={(match) => <PipelineOEMList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/calendar" exact render={(match) => <Calendar dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/calendar/list" exact render={(match) => <CalendarList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/contacts" exact component={Contacts} />
                                                <Route path="/contacts/companies" exact component={Companys} />
                                                <Route path="/mail" component={Mail} />
                                                <Route path="/mails" component={Mails} />
                                                <Route path="/cafe" exact render={(match) => <CafeOrders dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/stock" exact render={(match) => <Stock dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleet" exact render={(match) => <Fleet dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleet/history" exact render={(match) => <FleetHistory dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/testdrive/history" exact render={(match) => <TestDriveHistory dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/tradein/history" exact render={(match) => <TradeInHistory dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/reports" exact render={(match) => <Reports dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} pipeline={pipeline} />} />

                                                <Route path="/enquiry/details/:id" exact render={(match) => <EnquiryInfo enquiryid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />
                                                <Route path="/contacts/details/:id" exact render={(match) =>
                                                    <>
                                                        {
                                                            dealersettings.isPorsche === true ?
                                                                <ContactInfoPorsche key={match.match.params.id} contactid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} updatenavbar={updatenavbar} history={this.props.history} updateDealerSettings={updateDealerSettings} clientModule={clientModule} handleModuleChange={handleModuleChange} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />
                                                                :
                                                                <ContactInfo key={match.match.params.id} contactid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} updatenavbar={updatenavbar} history={this.props.history} updateDealerSettings={updateDealerSettings} clientModule={clientModule} handleModuleChange={handleModuleChange} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />
                                                        }
                                                    </>
                                                } />
                                                <Route path="/company/details/:id" exact render={(match) => <CompanyInfo key={match.match.params.id} companyid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} updatenavbar={updatenavbar} history={this.props.history} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />
                                                <Route path="/service/details/:id" exact render={(match) => <ServiceInfo serviceid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />
                                                <Route path="/tradeinpro/details/:id" exact render={(match) => <TradeinProInfo tradeinproid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />
                                                <Route path="/wholesaler/details/:id" exact render={(match) => <WholesalerInfo offerid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />

                                                <Route path="/settings" render={(match) => <Settings dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} updatePipelineSettings={updatePipelineSettings} updateDealerSettings={updateDealerSettings} pipeline={pipeline} history={this.props.history} />} />

                                                {/* <Redirect from="/" exact to="/dashboard" /> */}
                                                <Route path="/frontdesk" exact render={(match) => <FrontDesk dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/kitchen" exact render={() => <Kitchen history={this.props.history} />} />
                                                <Route path="/kitchenhistory" exact render={(match) => <CafeHistory dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/cafeorders" exact render={(match) => <CafeOrdersList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/oem/dashboard" exact render={(match) => <OEMDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} />} />
                                                <Route path="/oem/pipeline" exact render={(match) => <OEMReport dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} />} />
                                                <Route path="/oem/calendar" exact render={(match) => <OEMReport dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} />} />
                                                <Route path="/oem/dealers" exact render={(match) => <Dealers dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} />} />
                                                <Route path="/oem/mail" exact render={(match) => <OEMReport dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} />} />
                                                <Route path="/oem/stock" exact render={(match) => <OEMStock dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/oem/loan" exact render={(match) => <OEMStockManagement dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/oem/reports" exact render={(match) => <OEMReport dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/oem/targets" exact render={(match) => <CustomerTargetReport dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/logs" exact render={(match) => <CallLogs dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/inbound" exact render={() => <InBound dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/inbound/list" exact render={(match) => <InBoundList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/campaigns" exact render={() => <Campaigns dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/bulksms" exact render={() => <BulkSMS dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/dashboard/bdc" exact render={() => <BDCDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/inbound/allocation" exact render={(match) => <AllocationList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/dashboard/sales" exact render={() => <DashboardSales dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />


                                                <Route path="/appointment" exact render={() => <ServiceCalendar dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/service" exact render={() => <Service dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/service/list" exact render={() => <ServiceList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/smscampaign" exact render={() => <BulkSMSHub dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/bulksmspodium" exact render={() => <BulkSMSPodium dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/feedbackforms" exact render={() => <FeedBackForms dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/dashboard/service" exact render={() => <ServiceDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/services/reports" exact render={() => <ServiceReports dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} pipeline={pipeline} />} />

                                                <Route path="/dashboard/aftersales" exact render={() => <AfterSalesDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} pipeline={dealersettings.allPipelines.filter(a => a.value === 'AfterSales')} />} />

                                                <Route path="/aftersales" exact render={() => <AfterSales dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/aftersales/list" exact render={(match) => <AfterSalesList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/subscription-ended" exact render={(match) => <SubscriptionEnded setUser={this.props.setUser} dealersettings={dealersettings} history={this.props.history} paymentDue={paymentDue} paymentDueDate={paymentDueDate} accountSuspended={accountSuspended} handleClientChange={handleClientChange} />} />

                                                <Route path="/dashboard/appraisal" exact render={() => <TradeinProDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/tradeinpro" exact render={() => <TradeinPro dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/wholesaler" exact render={() => <Wholesaler dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/tradeinpro/list" exact render={() => <TradeinProList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/schedule/contact" exact render={() => <ScheduleList dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/tradeinpro/reports" exact render={() => <TradeinProReports dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} pipeline={pipeline} />} />

                                                <Route path="/performance/review" exact render={() => <DailyCheckout dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/acquisition" exact render={() => <AcquisitionList dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/roster" exact render={() => <Roster dealersettings={dealersettings} clientUsers={clientUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/tipinbound/list" exact render={(match) => <InBoundTIPList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/ppsr/list" exact render={(match) => <PpsrList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/trigger/list" exact render={(match) => <TriggerList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/marketing/report" exact render={(match) => <MarketingROI dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/yearly/performance/report" exact render={(match) => <YearlyPerformance dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/sales/performance/report" exact render={(match) => <YearlySalesPerformance dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} pipeline={pipeline} updateDealerSettings={updateDealerSettings} />} />

                                                {/* EVENTS PRO ROUTING */}
                                                <Route path="/dashboard/events" exact render={() => <EventsProDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/eventspro/list" exact render={() => <EventsProList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/eventspro/details/:id" exact render={(match) => <EventsProInfo eventsproid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />
                                                <Route path="/eventspro/templates" exact render={() => <EventTemplates dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/eventspro/attendees" exact render={() => <EventsProAttendees dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/eventspro/calendar" exact render={() => <EventsProCalendar dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                                <Route path="/oem/stock/pipeline" exact render={(match) => <OEMStockPipeline dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                                {/* FLEET PRO ROUTING */}
                                                <Route path="/dashboard/fleet" exact render={() => <FleetProDashboard dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleetpro/list" exact render={() => <FleetProList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleetpro/history" exact render={() => <FleetAssignHistory dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleetpro/calendar" exact render={() => <FleetAssignCalendar dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />
                                                <Route path="/fleetpro/details/:id" exact render={(match) => <FleetProInfo fleetproid={match.match.params.id} handleRouteChange={handleRouteChange} dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} currentClient={currentClient} resetnavbar={resetnavbar} history={this.props.history} updatenavbar={updatenavbar} handleRouteRemove={handleRouteRemove} updateDealerSettings={updateDealerSettings} clientModule={clientModule} dialNumber={dialNumber} dialRingcentral={dialRingcentral} />} />

                                                {/* SALES HUB ROUTING */}
                                                <Route path="/deallog/orders" exact render={() => <DealLog dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />


                                                <Route path="/document/list" exact render={(match) => <DocumentList dealersettings={dealersettings} clientUsers={clientUsers} groupUsers={groupUsers} history={this.props.history} updateDealerSettings={updateDealerSettings} />} />

                                            </ErrorBoundary>


                                        </Switch>
                                    </Suspense>
                                </>
                            )}
                        </LayoutConsumer>
                    )}
                </RouteConsumer>

            </main>
        );
    }
}

export default MainContainer;