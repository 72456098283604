import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import { PopoverwithFooter, PieChart, NoDataPieChart } from '../../components'
import FilterPanel from './filterPanel'
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { colorPieArray } from '../dashboardPorsche/viewModel';

const EnquiryTypeCounts = (props) => {
    const [data, setData] = useState([])
    const [selectedPipeline, setPipeline] = useState(null)
    const [pipelineOptions, setPipelineOption] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [selectedUser, setUser] = useState([])
    const [viewOtherEnquiry, setOtherEnquiry] = useState(false);
    //const [loader, setLoader] = useState(true)
    const [refreshData, setRefresh] = useState(true)
    const [filterPopOver, setFilterPopOver] = useState({ show: false, target: null })
    const [filter, setFilter] = useState({ pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',') });
    const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [dealersCount, setDealersCount] = useState([])
    const [oldDaterange, setOldDaterange] = useState({})

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-enquiryTypes`);

    useEffect(() => {
        return () => {
            window.unsubenquiryTypesSnap && window.unsubenquiryTypesSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        setLoader(true)
        window.unsubenquiryTypesSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setDealersCount(snap.data().counts);
                    setData(setChartData(snap.data().counts));
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader) => {
        // console.log('lead by campaigns')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            type: 'enquiryType',
            isPorsche: props.isPorsche === true ? true : null,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }
        if (props.fromOEM) {
            _filter.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
            _filter.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;
        }

        // New Filters Added for Porsche - SaMeeR - 05-09-24
        if (props?.dashboardFilter?.models?.length > 0) _filter.model = props.dashboardFilter.models.join(',')
        if (props?.dashboardFilter?.campaigns?.length > 0) _filter.campaign = props.dashboardFilter.campaigns.join(',')
        if (props?.dashboardFilter?.origins?.length > 0) _filter.origin = props.dashboardFilter.origins.join(',')
        if (props?.dashboardFilter?.enquiryTypes?.length > 0) _filter.enquiryType = props.dashboardFilter.enquiryTypes.join(',')
        if (props?.dashboardFilter?.leadSource?.length > 0) _filter.leadSource = props.dashboardFilter.leadSource.join(',')

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp', resp)

            setDealersCount(resp.data.data);
            const _data = setChartData(resp.data.data);
            setData(_data)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: resp.data.data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    const setChartData = (_data) => {
        let enquiries_ = _.chain(_data)
            .groupBy("name")
            .map((value, key) => ({
                name: key,
                count: _.sumBy(value, 'count')
            }))
            .value()
            .map((campaign, index) => {
                const objToReturn = { ...campaign }
                objToReturn.name = (campaign.name === 'others' ? 'Other/Blank' : _.startCase(_.toLower(campaign.name)))
                objToReturn.value = campaign.name
                if (!_.isEmpty(props.dealersettings.client.settings.enquiryTypes) && props.dealersettings.client.settings.enquiryTypes.length > 0) {
                    if (props.dealersettings.client.settings.enquiryTypes.filter(a => a.value === campaign.name).length > 0)
                        objToReturn.name = props.dealersettings.client.settings.enquiryTypes.filter(a => a.value === campaign.name)[0].name
                    else objToReturn.name = 'Other/Blank';
                }
                if (props.dateRange) {
                    objToReturn.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    objToReturn.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
                objToReturn.color = props.dealersettings.isPorsche === true ? colorPieArray[index % colorPieArray.length] : null
                return objToReturn
            })
        enquiries_ = _.orderBy(enquiries_, ['count'], ['desc'])
        return enquiries_;
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            if (props.fromOEM) {
                if ((!_.isEmpty(oldDaterange) || !_.isEmpty(props?.dashboardFilter?.daterange)) && JSON.stringify(oldDaterange) !== JSON.stringify(props?.dashboardFilter?.daterange)) {
                    setFirstTimeLoaded(false);
                    window.unsubenquiryTypesSnap && window.unsubenquiryTypesSnap();
                    fetchData()
                    setOldDaterange(props?.dashboardFilter?.daterange)
                }
                else
                    setData(setChartData(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true)));
            }
            else {
                window.unsubenquiryTypesSnap && window.unsubenquiryTypesSnap();
                setFirstTimeLoaded(false);
                fetchData();
            }
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])


    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center loader-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-more"></i></a>*/}
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                    </div>
                    <h3>{props.title || <Translate text={'Leads By Enquiry Type'} />}</h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={'enquiryType-pie-chart'}
                                    height={'350px'}
                                    data={reportData}
                                    handleShowEnquiryList={(value, startDate, endDate) => {
                                        props.handleShowEnquiryList({
                                            'enquiryType': value,
                                            'status': 'all',
                                            //'cap': props.isPorsche === true ? true : null,
                                            'pipeline': _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                            'startDate': startDate,
                                            'endDate': endDate,
                                            'dateType': 'addedDate'
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={'enquiryType-pie-chart'}
                                    height={'370px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div>
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default EnquiryTypeCounts;