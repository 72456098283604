/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { ImageViewer, PopUpModal } from '../../../components'


const TestDriveInfo = ({ testdrive, dealersettings, eventsProID, attendeeid }) => {
    const [imageViewer, setImgViewer] = useState({ show: false, images: null });
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        documentID, startDate, endDate
    } = testdrive;

    const _vehicle = testdrive?.stock?.make ? testdrive.stock : testdrive;

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    return (
        <div className="vehicle-item">

            <div className="quickview-activity-more">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setEditshow(!editshow)
                    if (editshow)
                        document.addEventListener('click', setEditshow(false));
                }}><i className="ico icon-more"></i></a>
                <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                    <ul className="more-dropdown">
                        <li>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                const url = `/attendee/report/testdrive/${attendeeid}?eid=${eventsProID}&tid=${documentID}`;
                                window.open(url, "_blank");
                            }}> <i className="ico icon-waiver"></i> Report</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="testdrive-listing-tab flex-vehicle">
                <div className="vehicle-item-image">
                    {
                        (!_.isEmpty(testdrive) && !_.isEmpty(testdrive.images))
                            ?
                            <>
                                {
                                    testdrive.images.map((data, index) => (
                                        <a key={index} data-fancy-box={`testgl_info_img_slider${documentID}`} 
                                        className={index === 0 ? '' : 'hide'} 
                                        href={data} 
                                        onClick={(e) => { e.preventDefault(); 
                                            setImgViewer({
                                                show: true,
                                                images: testdrive.images
                                            })
                                        }}>
                                            {index === 0 ?
                                                <>
                                                    <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                </>
                                                : <></>}
                                        </a>
                                    ))
                                }
                            </>
                            :
                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                <img src={CommonHelper.showBrandLogo(dealersettings, _vehicle.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                            </a>
                    }
                </div>
                <div className="vehicle-data" onClick={(e) => { e.preventDefault(); }}>
                    <div className="vehicle-item-title">{_vehicle.make} {_vehicle.model} </div>
                    <div className="vehicle-item-info">{_vehicle.exteriorColor ? `${_vehicle.exteriorColor},` : ''} {_vehicle.year ? _vehicle.year : ''}</div>
                    {_vehicle.regNo ? <div className="vehicle-item-info"><Translate text={'regNo'} />: {_vehicle.regNo} </div> : <></>}
                    {_vehicle.vinNo ? <div className="vehicle-item-info"><Translate text={'vinNo'} />: {_vehicle.vinNo} </div> : <></>}

                </div>
            </div>
            <div className="testdrive-duration" onClick={(e) => { e.preventDefault(); }}>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td width="50%"><Translate text={'startTime'} /></td>
                            <td width="50%"><Translate text={'endTime'} /></td>
                        </tr>
                        <tr>
                            <td className="label-duration">{(!_.isEmpty(startDate)) ? moment.unix(startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                            <td className="label-duration">{(!_.isEmpty(endDate)) ? moment.unix(endDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>  </tr>
                    </tbody>
                </table>
            </div>
            <PopUpModal show={imageViewer.show}>
				<ImageViewer
					{...imageViewer}
					handleClose={() => { setImgViewer({ show: false, images: null }); }}
				/>
			</PopUpModal>
        </div>


    )
};

export default TestDriveInfo;
