export const usersVM = {
    documentID: null,
    defaultClientID: null,
    name: '',
    email: '',
    isDeleted: false,
    isQueue: false,
    profileImage: '',
    arrayClientID: [],
    arrayRegionID: [],
    arrayGroupID: [],
    tokkens: [],
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    level: '',
    role: '',
    roleID: '',
    department: '',
    settingsID: null,
    regionID: null,
    groupID: null,
    phone: '',
    phoneCode: '',
    enableMFA: false,
    designation: '',
    arrayOEMID: [],
    arrayOEM: []

}

export const wholesalerVM = {
    documentID: null,
    defaultClientID: null,
    name: '',
    email: '',
    isDeleted: false,
    profileImage: '',
    arrayClientID: [],
    tokkens: [],
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    phone: '',
    phoneCode: '',
    enableMFA: false,
    designation: '',
    arrayOEMID: [],
    isWholesaler: false,
    settingsID: null,
    wholesalerClientID: [],
    wholesalerOEMID: [],
}

//if any change in below viewmodel do update in oemsetup/viewmodel rolevm
export const roleVM = {
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    admin: {
        clientSettings: false,
        workflowSetup: false,
        productsData: false,
        modules: false,
        integration: false,
        locationMgnt: false,
        securityMgnt: false,
        billing: false
    },
    documentID: null,
    level: '',
    modules: {
        enquiry: false,
        frontdesk: false,
        kitchen: false,
        loanVehicle: false,
        stock: false,
        oem: false
    },
    name: '',
    permissions: {
        deleteActivity: false,
        deleteEnquiry: false,
        exportData: false,
        lostApproval: false,
        mergeContact: false,
        modelPrice: false,
        modifyActivityOwner: false,
        modifyCampaign: false,
        modifyEnquiryOrigin: false,
        modifyEnquiryOwner: false,
        modifyEnquiryType: false,
        modifyLabel: false,
        modifyLostReason: false,
        modifyOtherActivity: false,
        modifyOtherEnquiry: false,
        modifyPurhaseInt: false,
        modifyRequirements: false,
        modifyStatusDate: false,
        modifyVehicleInfo: false,
        movePipeline: false,
        queueReorder: false,
        reopenEnquiry: false,
        returnVehicle: false,
        testdriveHandover: false,
        transferEnquiry: false,
        verifyEnquiry: false,
        viewOtherEnquiry: false,
        viewOtherContact: false,
        modifyContactOwner: false,
        modifyOtherContact: false,
        transferContact: false,
        deleteContact: false,
        deleteFiles: false,
        modifyNotes: false,
        ownerDashboard: false,
        unlinkEnquiry: false,
        accessOEMStock: false,
        accessDealertarget: false,
        viewOtherActivity: false,
        modifyStageAfterDeliveredLost: false,
        roNumRequired: false,
        dealerLeadTransfer: false,
        deleteStock: false,
        modifyLeadSource: false
    },
    pipeline: []
}


export const mandatoryFields = [
    'name',
    'email'
];

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]

export const rolePermissions = [
    {
        name: 'Enquiry',
        value: 'enquiry',
        type: 'modules',
        sortorder: 'b'
    },
    {
        name: 'Front Desk',
        value: 'frontdesk',
        type: 'modules',
        sortorder: 'b'
    },
    {
        name: 'Kitchen',
        value: 'kitchen',
        type: 'modules',
        sortorder: 'b'
    },
    {
        name: 'Loan Vehicle',
        value: 'loanVehicle',
        type: 'modules',
        sortorder: 'b'
    },
    {
        name: 'Stock',
        value: 'stock',
        type: 'modules',
        sortorder: 'b'
    },
    // {
    //     name: 'OEM',
    //     value: 'oem',
    //     type: 'modules',
    //     sortorder: 'b'
    // },
    {
        name: 'Access to client settings',
        value: 'clientSettings',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to workflow setup',
        value: 'workflowSetup',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to products & data',
        value: 'productsData',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to modules',
        value: 'modules',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to integrations',
        value: 'integration',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to location management',
        value: 'locationMgnt',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to security management',
        value: 'securityMgnt',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to billing',
        value: 'billing',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to OEM Dashboard',
        value: 'oemDashboard',
        type: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Delete Activity',
        value: 'deleteActivity',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modifiy other activity',
        value: 'modifyOtherActivity',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify activity owner',
        value: 'modifyActivityOwner',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Delete Enquiry',
        value: 'deleteEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to Export Data',
        value: 'exportData',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to Export Contact List',
        value: 'exportContactData',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Lost Approval',
        value: 'lostApproval',
        type: 'permissions',
        sortorder: 'd'
    },
    // {
    //     name: 'View other contact',
    //     value: 'viewOtherContact',
    //     type: 'permissions',
    //     sortorder: 'd'
    // },
    {
        name: 'Modify contact Owner',
        value: 'modifyContactOwner',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Disable access to modify contact',
        value: 'disableModifyContact',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Transfer contact',
        value: 'transferContact',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Delete contact',
        value: 'deleteContact',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to merge contacts/companies',
        value: 'mergeContact',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify enquiry owner',
        value: 'modifyEnquiryOwner',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify other enquiry',
        value: 'modifyOtherEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to delete files',
        value: 'deleteFiles',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify campaign',
        value: 'modifyCampaign',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lead origin',
        value: 'modifyEnquiryOrigin',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify enquiry type',
        value: 'modifyEnquiryType',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify label (Hot/Warm/Cold)',
        value: 'modifyLabel',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lost reason',
        value: 'modifyLostReason',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify purchase intension',
        value: 'modifyPurhaseInt',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify requirements',
        value: 'modifyRequirements',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify VIN/Reg/Warranty Expiry for the Won/Delivered',
        value: 'modifyVehicleInfo',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify won / delivered / lost date',
        value: 'modifyStatusDate',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to move pipeline',
        value: 'movePipeline',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to queue reorder',
        value: 'queueReorder',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to reopen enquiry',
        value: 'reopenEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to return vehicle',
        value: 'returnVehicle',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to test drive handover',
        value: 'testdriveHandover',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to transfer enquiry',
        value: 'transferEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to verify enquiry',
        value: 'verifyEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Allow to edit notes',
        value: 'modifyNotes',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'View others enquiry',
        value: 'viewOtherEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to own dashboard',
        value: 'ownerDashboard',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to unlink contact / enquiry',
        value: 'unlinkEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to OEM stock',
        value: 'accessOEMStock',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to dealer target',
        value: 'accessDealertarget',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to view other users calendar activity',
        value: 'viewOtherActivity',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify stage after delivered / lost',
        value: 'modifyStageAfterDeliveredLost',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to show stock price',
        value: 'modelPrice',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to delete stock',
        value: 'deleteStock',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'RO number required in loan vehicle',
        value: 'roNumRequired',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to transfer lead to other dealer',
        value: 'dealerLeadTransfer',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to readonly view for enquiry / stock',
        value: 'readOnlyEnquiryView',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to BDC Dashboard',
        value: 'bdcDashboard',
        type: 'permissions',
        sortorder: 'd'
    }, ,
    {
        name: 'Access to upload dealer documents',
        value: 'dealerFileUpload',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to add quotation',
        value: 'addQuotation',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to delete quotation',
        value: 'deleteQuotation',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to create new enquiry on lost',
        value: 'createLeadOnLost',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to static report',
        value: 'accessStaticReport',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to enquiry detail report',
        value: 'previewEnquiry',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to edit dynamic reports',
        value: 'editDynamicReports',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Mandatory Schedule Next Activity On Open Status',
        value: 'mandScheduleNextActivityOpen',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Mandatory Schedule Next Activity On Open / Won Status',
        value: 'mandScheduleNextActivity',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lead source',
        value: 'modifyLeadSource',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Separate Inbound Activities on Dashboard',
        value: 'separateInbdUserAct',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to schedule contact export',
        value: 'scheduleContactList',
        type: 'permissions',
        sortorder: 'd'
    },
    {
        name: 'Access to delete inbound lead',
        value: 'deleteInboundLead',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to frontdesk queue activation',
        value: 'frontdeskQueueActivation',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Show Trade-In Price',
        value: 'accessToShowTradeInPrice',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Delete Trade-In',
        value: 'deleteTradeIn',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Show Feedback List',
        value: 'showFormFeedback',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Add Dealers',
        value: 'addDealer',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
]

export const userlistFields = [
    {
        name: 'Name',
        value: 'userName',
        flex: 2,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Role',
        value: 'roleName',
        flex: 1,
        default: true,
    },
    {
        name: 'Last Login',
        value: 'lastlogin',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true
    },
    {
        name: 'DMS',
        value: 'dmsTags',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 150,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const wholesalerlistFields = [
    {
        name: 'Name',
        value: 'userName',
        flex: 2,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Dealer',
        value: 'dealerName',
        flex: 1,
        default: true,
    },
    {
        name: 'Last Login',
        value: 'lastlogin',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const timezone = [
    { value: 'Asia/Dubai', label: '(UTC+04:00) Abu Dhabi, Muscat' },
    { value: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi' },
    { value: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { value: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney' }
]

export const levels = [
    { value: 'oem', label: 'OEM' },
    { value: 'region', label: 'Region' },
    { value: 'group', label: 'Group' },
    { value: 'individual', label: 'Individual' }
]

export const individuallevels = [
    { value: 'oem', label: 'OEM' },
    { value: 'individual', label: 'Individual' }
]

export const multiOEMDynCols = [
    { name: 'OEM', value: 'oem', type: 'select', mandatory: true, width: '28.3' },
    { name: 'Client', value: 'clients', type: 'multi', width: '28.3', fromFieldValue: 'oem' },
    { name: 'Role', value: 'roleID', type: 'select', width: '28.3', mandatory: true }
]