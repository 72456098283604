import React, { useState, useEffect } from 'react';
import { AntDatePicker, BarChart, ReactSelect } from '../../../../components';
import _ from 'lodash'
import moment from 'moment';
import CommonHelper from '../../../../services/common';

const CampaignsReport = ({ dateRange, setDateRange, campaignStats, statsLoader }) => {
	const [data, setData] = useState([])
	const [count, setCount] = useState(0)
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});

	const reportColumns = [
		{ name: 'Sent', value: 'sent', color: '#22A877', title: 'Sent' },
		{ name: 'Opened', value: 'opened', color: '#1EA3C1', title: 'Opened' },
		{ name: 'Clicked', value: 'clicked', color: '#3B76CB', title: 'Clicked' },
		{ name: 'Unsubscribed', value: 'unsubscribed', color: '#CB4A49', title: 'Unsubscribed' },
		{ name: 'Blocked', value: 'blocked', color: '#98712D', title: 'Blocked' },
		{ name: 'Spam', value: 'spam', color: '#F06B66', title: 'Spam' },
		{ name: 'Hard-bounced', value: 'hardbounced', color: '#9585F4', title: 'Hard-bounced' },
		{ name: 'Soft-bounced', value: 'softbounced', color: '#D66EC7', title: 'Soft-bounced' },
		{ name: 'Retrying', value: 'retrying', color: '#8D377C', title: 'Retrying' },

	]

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 300,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (!_.isArray(campaignStats))
			return
		let _data = [];
		campaignStats.forEach(rec => {
			_data.push({
				name: rec.Timeslice ? moment(rec.Timeslice.replace('Z', '')).format('DD/MM/YYYY') : null,
				sent: rec.MessageSentCount,
				opened: rec.MessageOpenedCount,
				clicked: rec.MessageClickedCount,
				unsubscribed: rec.MessageUnsubscribedCount,
				blocked: rec.MessageBlockedCount,
				spam: rec.MessageSpamCount,
				hardbounced: rec.MessageHardBouncedCount,
				softbounced: rec.MessageSoftBouncedCount,
				retrying: rec.MessageQueuedCount,
			})
		});
		setData(_data)
		setCount(_.sumBy(campaignStats, 'Total'))

	}, [campaignStats])

	return (
		<div className='campaign-right-panel'>
			<div className='campaign-list-container'>
				<h2>
					<div className="float-left">
						<div className="float-left">Statistics</div>
						<div className="form-style float-left ml-3">
							<AntDatePicker
								value={dateRange ? moment.unix(dateRange.startDate.seconds) : moment()}
								name={'roster-date'}
								onChange={(val) => {
									setDateRange({
										startDate: window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month').toDate()),
										endDate: window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month').toDate())
									})
								}}
								format={'MMM YYYY'}
								allowClear={false}
								placeholder='DD/MM/YYYY'
								className={`form-control`}
								picker={'month'}
							/>
						</div>
					</div>
					{
						count > 0 ? (
							<div className="float-right campagin-total-email-wrap">Total Email Sent <span className="badge badge-pill badge-campagin-emailsent">{count}</span></div>

						) : (<></>)
					}
				</h2>
				<div className='divider-line'></div>
				<div className='campaign-list-table'>
					{
						statsLoader
							?
							<>
								<div className="dashboard-widget-loader h-100">
									<div className="d-flex h-100 justify-content-center align-items-center loader-primary">
										<div className="spinner-border" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>

								</div>
							</>
							:
							<>
							</>
					}
					<BarChart
						id={`campaign-stats-bar-chart`}
						height={`${windowSize.windowHeight}px`}
						data={data}
						columns={reportColumns.map(r => { return r.value })}
						dynamicColumns={reportColumns}
						xCategory={'name'}
						calculationType={'count'}
						dynColor={true}
						hideTooltip={true}
						handleShowEnquiryList={(val, rec) => {

						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CampaignsReport;
