import React, { useState, useEffect, Fragment } from 'react';
import { Tabs, Tab, Modal, Accordion, Card } from 'react-bootstrap';
// import { default as _images } from '../../../images';
import moment from 'moment';
import _ from 'lodash';
import Terms from '../../common/terms';
import {
	ImageViewer, PopUpModal,
} from '../../../components';
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';

const ViewFinance = ({
	show,
	handleClose,
	contact,
	dealersettings,
	enquiryBasicVM,
	currencySymbol
}) => {
	const [imageViewer, setImgViewer] = useState({ show: false, images: null });
	const [financeDetail, setFinanceDetail] = useState([]);
	const [isFetching, setIsFetching] = useState(true);
	const [activeAccordion, setActiveAccordion] = useState('0');
	const [displayTerms, setDisplayTerms] = useState(false);

	useEffect(() => {
		const fetchFinanceDetail = async () => {
			const ObjectToSend = {
				AMSProInqId: enquiryBasicVM.documentID,
				DealerCode: dealersettings.client.id,
				AMSProCustomerId: contact.documentID,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(ObjectToSend),
			};

			const response = await fetch(
				dealersettings.financeURL + 'api/AMSProFinance/Action/FinanceDetails',
				requestOptions,
			);

			const respjson = await response.json();
			//	console.log('respjson', respjson);
			if (respjson.success) {
				setFinanceDetail(respjson.data);
				setIsFetching(false);
			} else {
				handleClose();
				setIsFetching(false);
			}
		};

		fetchFinanceDetail();
	}, []);

	return (
		!isFetching && (
			<>
				<Modal
					id='add-cafe'
					show={show}
					onHide={handleClose}
					backdrop='static'
					backdropClassName={show ? 'overlay-modal active' : ''}
					enforceFocus={false}
					dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-view-finance'
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<h5 className='modal-title' >
								<Translate text={'Finance Details'} />
							</h5>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className='container-fluid'>
							<div className='row'>
								<div className=' pop-layout form-style'>
									<div className='tabs-wraper tabs finance-tab-fix'>
										{/* START HERE */}
										<nav>
											<Tabs
												defaultActiveKey='one'
												transition={false}
												className='nav-fill'
											>
												<Tab eventKey='one' title='Vehicle Info'>
													<div className='finance-accordion-wraper enquiry-sidebar'>
														<Accordion defaultActiveKey={activeAccordion}>
															{financeDetail.Requirement.map(
																(RequirementItem, index) => (
																	<Card key={RequirementItem.AMSProId}>
																		<Accordion.Toggle
																			as={Card.Header}
																			variant='link'
																			eventKey={`${index}`}
																			onClick={() =>
																				setActiveAccordion(`${index}`)
																			}
																			className='w-100'
																		>
																			<div className='mb-0'>
																				<div className='cardlink'>
																					{' '}
																					<a className='btn btn-link collapsed w-100'>
																						<div className='float-left'>
																							{' '}
																							{`${RequirementItem.Make} ${RequirementItem.Model} `}
																						</div>
																						<div className='finance-view-header'>
																							<div className='finance-car-price'>
																								<span> <Translate text={'vehiclePrice'} /></span>
																								{/* {numberWithCommas(
																								RequirementItem.CarPrice,
																							)} */}
																								<NumberFormat
																									thousandSeparator={true}
																									prefix={currencySymbol}
																									displayType={'text'}
																									className='carprice-amount'
																									value={RequirementItem.CarPrice}
																								/>
																							</div>
																							<div className='finance-dollar-status'>
																								<span className='finance-dollar-status-sign'>
																									$
																								</span>
																								{/* {numberWithCommas(
																								RequirementItem.FinanceAmount,
																							)} */}
																								<NumberFormat
																									className='text-white'
																									thousandSeparator={true}
																									// prefix={currencySymbol}
																									displayType={'text'}
																									value={RequirementItem.FinanceAmount}
																								/>
																							</div>
																						</div>
																						<i className='arrow-collapse'></i>{' '}
																					</a>
																				</div>
																			</div>
																		</Accordion.Toggle>
																		<Accordion.Collapse eventKey={`${index}`}>
																			<Card.Body>
																				<div className='common-table finance-view-table'>
																					<table className='table table-bordered'>
																						<thead>
																							<tr>
																								<th
																									scope='col'
																									className='head-light'
																									width='50%'
																								>
																									<Translate text={'Finance Providers'} />
																								</th>
																								<th
																									scope='col'
																									className='head-light'
																									width='20%'
																								>
																									<Translate text={'Requested By'} />
																								</th>
																								<th
																									scope='col'
																									className='head-light'
																									width='20%'
																								>
																									<Translate text={'Requested Date'} />
																								</th>
																								<th
																									scope='col'
																									className='head-light border-right-0'
																									width='10%'
																								>
																									<Translate text={'status'} />
																								</th>
																							</tr>
																						</thead>
																						<tbody>
																							{RequirementItem.FinanceRequest
																								.length <= 0 && (
																									<tr>
																										<td colSpan='18' style={{ textAlign: 'center' }}>
																											<span><Translate text={'No Data'} upperCase={true} /></span>
																										</td>
																									</tr>
																								)}
																							{RequirementItem.FinanceRequest.map(
																								FinanceRequestObj => (
																									<tr key={FinanceRequestObj.Id}>
																										<td>
																											<table
																												className='finance-subtable'
																												width='100%'
																												cellSpacing='0'
																												cellPadding='0'
																												border='0'
																											>
																												<tbody>
																													<tr>
																														<td
																															width='130'
																															align='center'
																														>
																															<img
																																src={
																																	FinanceRequestObj.ImageUrl
																																}
																																width='90'
																																height='40'
																															/>
																														</td>
																														<td>
																															<div className='finance-name'>
																																{
																																	FinanceRequestObj.Financier
																																}
																															</div>
																														</td>
																													</tr>
																												</tbody>
																											</table>
																										</td>
																										<td>
																											{
																												FinanceRequestObj.RequestBy
																											}
																										</td>
																										<td>
																											{moment(FinanceRequestObj.RequestDate).format('DD/MM/YYYY hh:mm A')}
																										</td>
																										<td className='border-right-0'>

																											{FinanceRequestObj.LeadStatus && (
																												<div
																													style={{
																														marginLeft: '5px',
																													}}
																													className='finance-pending'
																												>
																													<div className={`badge badge-pill badge-${FinanceRequestObj.LeadStatus.toLowerCase()}`}>
																														{
																															FinanceRequestObj.LeadStatus
																														}
																													</div>
																												</div>
																											)}
																											{FinanceRequestObj.Status && (
																												<div
																													style={{
																														marginLeft: '5px',
																													}}
																													className='finance-pending'
																												>
																													<div className={`mt-1 status-${FinanceRequestObj.Status.toLowerCase() === 'approved' ? 'won' : 'lost'}`}>{FinanceRequestObj.Status}</div>
																												</div>
																											)}
																										</td>
																									</tr>
																								),
																							)}
																						</tbody>
																					</table>
																				</div>
																			</Card.Body>
																		</Accordion.Collapse>
																	</Card>
																),
															)}
														</Accordion>
													</div>
												</Tab>
												<Tab eventKey='two' title='Personal Info'>
													<div className='form-row'>
														<div className='form-group col-md-4'>
															<div className='form-divider'>
																<label > <Translate text={'firstName'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.FirstName
																		? financeDetail.Customer.FirstName
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-4'>
															<div className='form-divider'>
																<label > <Translate text={'middleName'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.MiddleName
																		? financeDetail.Customer.MiddleName
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-4'>
															<div className='form-divider'>
																<label > <Translate text={'lastName'} /></label>
																<span className='form-span'>
																	{' '}
																	{financeDetail.Customer.LastName
																		? financeDetail.Customer.LastName
																		: '--'}
																</span>{' '}
															</div>
														</div>

														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'mobile'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.Phone
																		? financeDetail.Customer.Phone
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'email'} /></label>
																<span className='form-span'>
																	{' '}
																	{financeDetail.Customer.Email
																		? financeDetail.Customer.Email
																		: '--'}
																</span>{' '}
															</div>
														</div>

														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'licenseNo'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.LicenseNo
																		? financeDetail.Customer.LicenseNo
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'licenseExpiry'} /></label>
																<span className='form-span'>
																	{' '}
																	{financeDetail.Customer.LicenseExpiry
																		? moment(financeDetail.Customer.LicenseExpiry).format('DD/MM/YYYY')
																		: '--'}
																</span>{' '}
															</div>
														</div>

														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'licenseState'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.LicenseState
																		? financeDetail.Customer.LicenseState
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'licenseType'} /></label>
																<span className='form-span'>
																	{' '}
																	{financeDetail.Customer.LicenseType
																		? financeDetail.Customer.LicenseType
																		: '--'}
																</span>{' '}
															</div>
														</div>

														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'gender'} /></label>
																<span className='form-span'>
																	{financeDetail.Customer.Gender
																		? financeDetail.Customer.Gender
																		: '--'}
																</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'dateOfBirth'} /></label>
																<span className='form-span'>
																	{' '}
																	{financeDetail.Customer.DOB
																		? moment(financeDetail.Customer.DOB).format('DD/MM/YYYY')
																		: '--'}
																</span>{' '}
															</div>
														</div>
													</div>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<label ><Translate text={'primaryID'} /></label>
															<div className='finance-license-img-wraper'>
																<div className='finance-licenseboxwrap'>
																	<div className='finance-view-licensebox finance-licensebox'>
																		{' '}
																		<a data-fancy-box={`LicenseImageURL`}
																			href={financeDetail.Customer.LicenseImageURL}
																			onClick={(e) => {
																				e.preventDefault();
																				setImgViewer({
																					show: true,
																					images: financeDetail.Customer.LicenseImageURL
																				})
																			}}>
																			<img
																				src={financeDetail.Customer.LicenseImageURL}
																				alt=''
																				className=''
																			/>{' '}
																		</a>
																	</div>
																	<small className='form-text text-muted'>
																		<Translate text={'front'} />
																	</small>
																</div>
																<div className='finance-licenseboxwrap'>
																	<div className='finance-view-licensebox finance-licensebox'>
																		{' '}
																		<a data-fancy-box={`LicenseImageBackURL`}
																			href={financeDetail.Customer.LicenseImageBackURL}
																			onClick={(e) => {
																				e.preventDefault();
																				setImgViewer({
																					show: true,
																					images: financeDetail.Customer.LicenseImageBackURL
																				})
																			}}>
																			<img
																				src={
																					financeDetail.Customer.LicenseImageBackURL
																				}
																				alt=''
																				className=''
																			/>{' '}
																		</a>
																	</div>
																	<small className='form-text text-muted'>
																		<Translate text={'Back'} />
																	</small>{' '}
																</div>
															</div>
														</div>
														<div className='form-group col-md-6'>
															<label ><Translate text={'seconadryID'} /></label>
															<div className='finance-license-img-wraper'>
																<div className='finance-licenseboxwrap'>
																	<div className='finance-view-licensebox finance-licensebox'>
																		{' '}
																		<a data-fancy-box={`SecondaryCardFront`}
																			href={financeDetail.Customer.SecondaryCardFront}
																			onClick={(e) => {
																				e.preventDefault();
																				setImgViewer({
																					show: true,
																					images: financeDetail.Customer.SecondaryCardFront
																				})
																			}}>
																			<img
																				src={
																					financeDetail.Customer.SecondaryCardFront
																				}
																				alt=''
																				className=''
																			/>{' '}
																		</a>
																	</div>
																	<small className='form-text text-muted'>
																		<Translate text={'front'} />
																	</small>{' '}
																</div>
																<div className='finance-licenseboxwrap'>
																	<div className='finance-view-licensebox finance-licensebox'>
																		{' '}
																		<a data-fancy-box={`SecondaryCardFront`}
																			href={financeDetail.Customer.SecondaryCardFront}
																			onClick={(e) => {
																				e.preventDefault();
																				setImgViewer({
																					show: true,
																					images: financeDetail.Customer.SecondaryCardFront
																				})
																			}}>
																			<img
																				src={
																					financeDetail.Customer.SecondaryCardBack
																				}
																				alt=''
																				className=''
																			/>{' '}
																		</a>
																	</div>
																	<small className='form-text text-muted'>
																		<Translate text={'back'} />
																	</small>{' '}
																</div>
															</div>
														</div>
													</div>
												</Tab>
												<Tab eventKey='three' title='Living Situation'>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Residential Address'} /></label>
																<span className='form-span'>{financeDetail.LivingSituation.ResidenceAddress ? financeDetail.LivingSituation.ResidenceAddress : '--'}</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label ><Translate text={'Residence Status'} /></label>
																<span className='form-span'> {financeDetail.LivingSituation.ResidenceStatus ? financeDetail.LivingSituation.ResidenceStatus : '--'}</span>{' '}
															</div>
														</div>
													</div>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Residence Year'} /></label>
																<span className='form-span'>{financeDetail.LivingSituation.ResidenceYear ? financeDetail.LivingSituation.ResidenceYear : '--'}</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Residence Type'} /></label>
																<span className='form-span'> {financeDetail.LivingSituation.ResidenceType ? financeDetail.LivingSituation.ResidenceType : '--'}</span>{' '}
															</div>
														</div>
													</div>
													{financeDetail.LivingSituation.ResidenceYear === 'Less than a year' ||
														financeDetail.LivingSituation.ResidenceYear === '1 year' ||
														financeDetail.LivingSituation.ResidenceYear === '2 years' ? (
														<div className='form-row'>
															<div className='form-group col-md-6'>
																<div className='form-divider'>
																	<label >
																		<Translate text={'Previous Address'} />
																	</label>
																	<span className='form-span'>{financeDetail.LivingSituation.PreviousAddress ? financeDetail.LivingSituation.PreviousAddress : '--'}{' '}</span>{' '}
																</div>
															</div>
														</div>
													) : <></>}
												</Tab>
												<Tab eventKey='four' title='Employment Details'>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label >
																	{' '}<Translate text={'Employer Address'} />
																</label>
																<span className='form-span'>{financeDetail.EmploymentDetails.EmployerAddress ? financeDetail.EmploymentDetails.EmployerAddress : '--'}</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Employer Name'} /></label>
																<span className='form-span'> {financeDetail.EmploymentDetails.EmployerName ? financeDetail.EmploymentDetails.EmployerName : '--'}</span>{' '}
															</div>
														</div>
													</div>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Employment Period'} /></label>
																<span className='form-span'>{financeDetail.EmploymentDetails.EmploymentPeriod ? financeDetail.EmploymentDetails.EmploymentPeriod : '--'}</span>{' '}
															</div>
														</div>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Employment Status'} /></label>
																<span className='form-span'> {financeDetail.EmploymentDetails.EmploymentStatus ? financeDetail.EmploymentDetails.EmploymentStatus : '--'}</span>{' '}
															</div>
														</div>
													</div>
													<div className='form-row'>
														<div className='form-group col-md-6'>
															<div className='form-divider'>
																<label > <Translate text={'Occupation'} /></label>
																<span className='form-span'>{financeDetail.EmploymentDetails.Occupation ? financeDetail.EmploymentDetails.Occupation : '--'}</span>{' '}
															</div>
														</div>
														{financeDetail.EmploymentDetails.EmploymentPeriod === 'Less than a year' ||
															financeDetail.EmploymentDetails.EmploymentPeriod === '1 year' ||
															financeDetail.EmploymentDetails.EmploymentPeriod === '2 years' ? (
															<div className='form-group col-md-6'>
																<div className='form-divider'>
																	<label > <Translate text={'Previous Employer'} /></label>
																	<span className='form-span'> {financeDetail.EmploymentDetails.PreviousEmployer ? financeDetail.EmploymentDetails.PreviousEmployer : '--'}</span>{' '}
																</div>
															</div>
														) : <></>}

													</div>
												</Tab>
												<Tab eventKey='five' title='Financial Details'>
													<div className='finance-accordion-wraper enquiry-sidebar'>
														<Accordion defaultActiveKey='0'>
															<Card>
																<Accordion.Toggle
																	as={Card.Header}
																	variant='link'
																	eventKey='0'
																>
																	<div className='mb-0'>
																		<div className='cardlink'>
																			{' '}
																			<a className='btn btn-link collapsed'>
																				{' '}
																				{' '}<Translate text={'Income'} />
																				<i className='arrow-collapse'></i>{' '}
																			</a>
																		</div>
																	</div>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey='0'>
																	<Card.Body>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label ><Translate text={'Primary Net Income Amount'} /> </label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.IncomeDetails.PrimaryAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.IncomeDetails.PrimaryAmount}
																							/>
																							:
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Primary Frequency'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.IncomeDetails.PrimaryFrequency ? financeDetail.FinanceDetails.IncomeDetails.PrimaryFrequency : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Benefits Amount'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.IncomeDetails.BenefitsAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.IncomeDetails.BenefitsAmount}
																							/>
																							: '--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label ><Translate text={'Benefits Frequency'} /> </label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.IncomeDetails.BenefitsFrequency ? financeDetail.FinanceDetails.IncomeDetails.BenefitsFrequency : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label ><Translate text={'Other Amount'} /> </label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.IncomeDetails.OtherAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.IncomeDetails.OtherAmount}
																							/>
																							: '--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label ><Translate text={'Other Frequency'} /> </label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.IncomeDetails.OtherFrequency ? financeDetail.FinanceDetails.IncomeDetails.OtherFrequency : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Rental Income Amount'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.IncomeDetails.RentalAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.IncomeDetails.RentalAmount}
																							/>
																							: '--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Rental Frequency'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.IncomeDetails.RentalFrequency ? financeDetail.FinanceDetails.IncomeDetails.RentalFrequency : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
															<Card>
																<Accordion.Toggle
																	as={Card.Header}
																	variant='link'
																	eventKey='1'
																>
																	<div className='mb-0'>
																		<div className='cardlink'>
																			{' '}
																			<a className='btn btn-link collapsed'>
																				{' '}
																				{' '}<Translate text={'Assests'} />
																				<i className='arrow-collapse'></i>{' '}
																			</a>
																		</div>
																	</div>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey='1'>
																	<Card.Body>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Property One Value'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.AssestsDetails.PropertyOneValue ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.AssestsDetails.PropertyOneValue}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Property One Financed'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.AssestsDetails.PropertyOneFinanced ? financeDetail.FinanceDetails.AssestsDetails.PropertyOneFinanced : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Property Two Value'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.AssestsDetails.PropertyTwoValue ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.AssestsDetails.PropertyTwoValue}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Property Two Financed'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.AssestsDetails.PropertyTwoFinanced ? financeDetail.FinanceDetails.AssestsDetails.PropertyTwoFinanced : '--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Deposit Estimated Amount'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.AssestsDetails.SavingsAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.AssestsDetails.SavingsAmount}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Savings Estimated Amount'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.AssestsDetails.DepositAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.AssestsDetails.DepositAmount}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
															<Card>
																<Accordion.Toggle
																	as={Card.Header}
																	variant='link'
																	eventKey='2'
																>
																	<div className='mb-0'>
																		<div className='cardlink'>
																			{' '}
																			<a className='btn btn-link collapsed'>
																				{' '}
																				{' '}<Translate text={'Liabilities'} />
																				<i className='arrow-collapse'></i>{' '}
																			</a>
																		</div>
																	</div>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey='2'>
																	<Card.Body>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Mortgage Outstanding Amount'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.LiabilitiesDetails.MortgageAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.LiabilitiesDetails.MortgageAmount}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Personal Loan Outstanding Amount'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.LiabilitiesDetails.PersonalLoanAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.LiabilitiesDetails.PersonalLoanAmount}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Total Credit Limit'} /></label>
																					<span className='form-span'>
																						{financeDetail.FinanceDetails.LiabilitiesDetails.CreditLimit ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.LiabilitiesDetails.CreditLimit}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																			<div className='form-group col-md-6'>
																				<div className='form-divider'>
																					<label > <Translate text={'Total Outstanding Amount'} /></label>
																					<span className='form-span'>
																						{' '}
																						{financeDetail.FinanceDetails.LiabilitiesDetails.OutstandingAmount ?
																							<NumberFormat
																								thousandSeparator={true}
																								prefix={currencySymbol}
																								displayType={'text'}
																								value={financeDetail.FinanceDetails.LiabilitiesDetails.OutstandingAmount}
																							/> :
																							'--'}
																					</span>{' '}
																				</div>
																			</div>
																		</div>
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
													</div>
												</Tab>
												<Tab eventKey='six' title='Documents'>
													<div className='common-table finance-view-table'>
														<table className='table table-bordered'>
															<thead>
																<tr>
																	<th
																		scope='col'
																		className='head-light'
																		colSpan='8'
																	>
																		<Translate text={'name'} />
																	</th>
																	<th
																		scope='col'
																		className='head-light'
																		colSpan='4'
																	>
																		<Translate text={'Uploaded By'} />
																	</th>
																	<th
																		scope='col'
																		className='head-light'
																		colSpan='4'
																	>
																		<Translate text={'Uploaded On'} />
																	</th>
																	<th
																		scope='col'
																		className='head-light'
																		colSpan='1'
																	>
																		<Translate text={'Download'} />
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	{financeDetail.FinanceFile.length > 0 ? financeDetail.FinanceFile.map(file => (
																		<Fragment key={file.Id}>
																			<td colSpan='8'>
																				<span> <Translate text={file.Title} /></span>
																			</td>
																			<td colSpan='4'>
																				<span> <Translate text={file.AddedUserName} /></span>
																			</td>
																			<td colSpan='4'>
																				<span> {moment(file.AddedDate).format('DD/MM/YYYY hh:mm A')} </span>
																			</td>
																			<td colSpan='1' className='text-center'>
																				<a href={file.FilePath} target='_blank' rel="noopener noreferrer" className="common-button"><i className="fa button-action-icon fa-download"></i></a>
																			</td>
																		</Fragment>
																	)) : (
																		<td colSpan='18' style={{ textAlign: 'center' }}>
																			<span> <Translate text={'No Data'} /></span>
																		</td>
																	)}
																</tr>
															</tbody>
														</table>
													</div>
												</Tab>
											</Tabs>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className='modal-footer'>
						<button type='button' className='btn btn-default float-left' onClick={() => setDisplayTerms(true)}>
							<i className='ico icon-terms mr-1 finance-terms-icon'></i> <Translate text={'Terms'} />
						</button>
					</Modal.Footer>
				</Modal>
				<PopUpModal show={displayTerms}>
					<Terms
						show={displayTerms}
						clsActive='overlay-modal active'
						imagePath='contacts'
						title={'termsAndConditions'}
						terms={{
							signature: financeDetail.Signature
						}}
						termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'financeTC', financeDetail?.Customer?.Language)}
						isDisable={true}
						handleClose={() => setDisplayTerms(false)}
						dealersettings={dealersettings}
						shouldReturnBase64={true}
					></Terms>
				</PopUpModal>
				<PopUpModal show={imageViewer.show}>
					<ImageViewer
						{...imageViewer}
						handleClose={() => { setImgViewer({ show: false, images: null }); }}
					/>
				</PopUpModal>
			</>
		)
	);
};

export default ViewFinance;
