import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import Swal from 'sweetalert2';
import ReactExport from 'react-export-excel-xlsx-fix';

import { ContentOverlay, PopUpModal, TableView, AntDateRangePicker, ReactSelect } from '../../../components'
import Translate from '../../../constants/translate';

import FleetHistoryReducer from '../history/historyReducer'
import AddFleet from '../../fleet/add'

import CommonHelper from '../../../services/common';
import ReAuthenticate from '../../common/reAuthenticate';
import Filters from '../filters'

import { objFleetHistoryVM, allFleetHistoryFieldsVM } from '../viewModel'
import { objStock } from '../../stock/viewModel'
import { fleetStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FleetHistoryListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [fleetHistory, dispatch] = useReducer(FleetHistoryReducer, { Loan: [], TestDrive: [] })
    const [checkDataLoad, setDataload] = useState({ "Loan": false, "TestDrive": false })
    const [hasMore, setHasMoreData] = useState([])
    const [loanHistoryFields, setLoanHistoryFields] = useState(props.dealersettings.loanHistoryFields)

    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelFleetShow, setModelFleetShow] = useState(false)

    const [editDocumentID, setEditDocument] = useState()
    const [modelStock, setModelStock] = useState({})
    const [isPaging, setPaging] = useState(false)
    const [fleetHistoryLoader, setLoader] = useState({ "Loan": true, "TestDrive": true })
    const pageLimit = 50
    const [activeTab, setActiveTab] = useState('Loan')
    const [searchText, setSearchText] = useState(localStorage.fleetHistorySearchText ? localStorage.fleetHistorySearchText : '')

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const loan_type = [
        { value: 'Service', label: 'Service' },
        { value: 'Other', label: 'Other' }
    ]
    const [searchType, setSearchType] = useState(localStorage.fleetHistorySearchType ? localStorage.fleetHistorySearchType : 'Service')
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dates, setDates] = useState([]);

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [fleetFilter, setFleetFilter] = useState({})
    const [customFilters, setCustomFilters] = useState([])
    const [pageNum, setPageNum] = useState(0)

    const [showReAuthentication, setReAuthentication] = useState(false);

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    const stockLocations = props.dealersettings?.client?.clientSettings?.stockLocations

    const isOEMModule = localStorage.defaultModule === 'oem' ? true : false

    useEffect(() => {
        if (!_.isEmpty(localStorage.fleetFilter) && Object.keys(JSON.parse(localStorage.fleetFilter)).length > 0) {
            let _fleetFilter = JSON.parse(localStorage.fleetFilter);
            let _testDriveValue = _fleetFilter.value;

            if (!_.isEmpty(_testDriveValue) && _testDriveValue.hasOwnProperty('keywords'))
                setSearchText(_testDriveValue['keywords']);
            else
                setSearchText('');

            setFleetFilter(_fleetFilter);
        }
        else {
            setFleetFilter({});
            setSearchText('');
        }

    }, [localStorage.fleetFilter])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'fleet')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        let headerFields = loanHistoryFields;
        let allHeaderFields = allFleetHistoryFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));
        //setHeader(_headers);

    }, [activeTab])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            // setDataload({
            //     ...checkDataLoad,
            //     [activeTab]: false
            // })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        } else {
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubLoanList && window.unSubLoanList.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        if (activeTab === 'Loan') {

            const { dealersettings } = props;
            if (localStorage.fleetFilter || isOEMModule) {
                let _fleetFilter = localStorage.fleetFilter ? JSON.parse(localStorage.fleetFilter) : {};
                let _filter = Object.assign({}, _fleetFilter.value);
                let _isWhere = false;

                /* DATE RANGE FILTER */
                if (!_.isEmpty(_filter.date)) {
                    _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                    delete _filter['date'];
                }

                if (searchType) _filter.loanType = searchType;
                if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();

                if (_.isEmpty(_filter.clientID)) {
                    if (isOEMModule && !_.isEmpty(dealersettings.group.clients))
                        _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
                    else
                        _filter.clientID = !_.isEmpty(stockLocations) ? _.uniq(stockLocations).join(',') : dealersettings.client.id;
                }
                if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();
                _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
                //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings);
                let _searchObject = {
                    "type": "searchFleet",
                    "clientID": _filter.clientID,
                    "filters": JSON.stringify(_filter),
                    "sortOrder": `${_filter.dateType ? _filter.dateType : 'startDate'} desc`,
                    "pageNum": pageNum,
                    "pageLimit": pageLimit
                }

                //console.log('generic-searchData', pageNum, _searchObject, localStorage.fleetFilter)
                const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
                searchTestdrives(_searchObject).then((response) => {
                    //console.log('generic-searchFleet', response);
                    if (response.data.success && (localStorage.fleetFilter || isOEMModule)) {
                        onCollectionStocks(response.data);
                    }
                    else {
                        dispatch({
                            type: "SUCCESS_LIST",
                            data: [],
                            activetab: activeTab
                        });
                        setDataload({
                            ...checkDataLoad,
                            [activeTab]: false
                        })
                        setLoader({
                            ...fleetHistoryLoader,
                            [activeTab]: false
                        })
                        setPaging(false)
                    }
                });

                window.unSubLoanList && window.unSubLoanList.unsubscribe();
            }
            else {
                let refLoanData = firestoreDB(props.dealersettings).firestore().collection('fleet').where('isDeleted', '==', false)
                //.where('clientID', '==', props.dealersettings.client.id)         
                //.orderBy('startDate', 'desc')


                if (!_.isEmpty(stockLocations)) refLoanData = refLoanData.where('clientID', 'in', CommonHelper.arrLimit(_.uniq(stockLocations), 30))
                else
                    refLoanData = refLoanData.where('clientID', '==', props.dealersettings.client.id)

                if (searchType) {
                    refLoanData = refLoanData
                        .where('loanType', '==', searchType)
                }

                if (searchText && searchText.trim()) {
                    refLoanData = refLoanData
                        .where('keywords', 'array-contains', searchText.trim().toLowerCase())
                }
                refLoanData = refLoanData.orderBy('startDate', 'desc')

                let lastRecord = hasMore.filter(e => e.type === activeTab);
                if (lastRecord.length > 0) {
                    refLoanData = refLoanData
                        .startAfter(lastRecord[0].lastDoc)
                        .limit(pageLimit)
                }
                else {
                    refLoanData = refLoanData
                        .limit(pageLimit)
                }

                // window.unSubLoanList = refLoanData
                //     .onSnapshot(onFleetCollectionUpdate);

                window.unSubLoanList = collectionData(refLoanData)
                    .pipe(
                        switchMap(fleetSnapshot => {
                            const stockIds = _.uniq(fleetSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
                            return combineLatest(
                                of(fleetSnapshot),
                                combineLatest(
                                    stockIds.length > 0 ? stockIds.map(stockID =>
                                        collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                            map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                        )
                                    ) : of([])
                                ),
                            )
                        }),
                        map(([fleetSnapshot, stockSnapshot]) => {
                            return fleetSnapshot.map(snapDoc => {
                                return {
                                    ...snapDoc,
                                    //stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                                }
                            })
                        })
                    )
                    .subscribe(loanVehicles => {
                        onCollectionUpdate(loanVehicles)
                    });
                // return () => {
                //     loanVehicles.unsubscribe()
                // }
            }
        }

    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = async (fleetHistorys) => {
        //console.log('onCollectionUpdate', activeTab, fleetHistorys)
        const _fleetHistorys = [];
        if (fleetHistorys.length > 0 && fleetHistorys.length === pageLimit) {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            {
                type: activeTab,
                lastDoc: fleetHistorys[fleetHistorys.length - 1] && fleetHistorys[fleetHistorys.length - 1].documentID ?
                    await firestoreDB(props.dealersettings).firestore().doc(`fleet/${fleetHistorys[fleetHistorys.length - 1].documentID}`).get() : fleetHistorys[fleetHistorys.length - 1]

            }])
        }
        else {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        }
        fleetHistorys.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _fleetHistorys.push(dataVM)
        });
        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _fleetHistorys,
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...fleetHistoryLoader,
            [activeTab]: false
        })
        setPaging(false)
    }
    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            {
                type: activeTab,
                lastDoc: querySnapshot.data[querySnapshot.data.length - 1]

            }])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _stocks,
            activetab: activeTab
        });

        setLoader({
            ...fleetHistoryLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }
    // const onFleetCollectionUpdate = (querySnapshot) => {
    //     let __fleetHistory = [];
    //     querySnapshot.forEach((doc) => {
    //         __fleetHistory.push(doc.data());
    //     });
    //     //console.log('onFleetCollectionUpdate', __fleetHistory);
    // }
    // const onCollectionUpdate = (querySnapshot) => {
    //     let actionType;
    //     let snapshotDoc;

    //     querySnapshot.docChanges().forEach(change => {
    //         if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
    //         {
    //             snapshotDoc = dataMappingVM(change.doc.data());
    //             if (change.type === 'added') {
    //                 actionType = "ADDNEW_LIST";
    //             }
    //             else if (change.type === 'modified') {
    //                 actionType = "UPDATE_LIST"
    //             }
    //             else if (change.type === 'removed') {
    //                 actionType = "REMOVE_LIST"
    //             }
    //         }
    //     })

    //     let __fleetHistory = [];
    //     if (!actionType) {
    //         if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
    //             setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
    //             {
    //                 type: activeTab,
    //                 lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1].data()

    //             }])
    //         }
    //         else {
    //             setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
    //         }
    //         querySnapshot.forEach((doc) => {
    //             __fleetHistory.push(dataMappingVM(doc.data()));
    //         });

    //     }
    //     //console.log('__fleetHistory', __fleetHistory)
    //     if (isPaging) {
    //         dispatch({
    //             type: "APPEND_LIST",
    //             data: __fleetHistory,
    //             activetab: activeTab
    //         });
    //     }
    //     else {
    //         dispatch({
    //             type: actionType ? actionType : "SUCCESS_LIST",
    //             data: actionType ? snapshotDoc : __fleetHistory,
    //             activetab: activeTab
    //         });
    //     }

    //     setDataload({
    //         ...checkDataLoad,
    //         [activeTab]: false
    //     })
    //     setLoader({
    //         ...fleetHistoryLoader,
    //         [activeTab]: false
    //     })
    //     setPaging(false)
    // }


    // const handleDiffDays = (_date) => {
    //     const difference = moment().diff(moment.unix(_date.seconds), 'days')
    //     if (difference > 1 || difference < -1)
    //         return difference + ' Days';
    //     else
    //         return difference + ' Day';

    // }

    const dataMappingVM = (doc) => {
        return loanMappingVM(doc);
    }

    const loanMappingVM = (doc) => {
        //console.log('loanMappingVM', doc)
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
        doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
        doc.expectedReturn = doc.expectedReturn && doc.expectedReturn._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedReturn._seconds)._d) : doc.expectedReturn;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objlogData = Object.assign({}, doc);
        const fleetVM = Object.assign({}, objFleetHistoryVM);
        for (let [key, value] of Object.entries(objlogData)) {
            fleetVM[key] = value;
        }

        fleetVM.createdOn = fleetVM.addedDate ? moment.unix(fleetVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        fleetVM.updatedOn = fleetVM.modifiedDate ? moment.unix(fleetVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        fleetVM.addedBy = fleetVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.addedBy) : '--';
        fleetVM.modifiedBy = fleetVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, fleetVM.modifiedBy) : '--';

        fleetVM.endOdometer = fleetVM.endOdomter ? fleetVM.endOdomter : fleetVM.endOdometer;
        let dealersettings = props.dealersettings;
        if (!_.isEmpty(fleetVM.contact)) {
            let _contact = Object.assign({}, fleetVM.contact);
            // fleetVM.contactName = <div onClick={(e) => {
            //     e.preventDefault();
            //     const url = `/contacts/details/${_contact.documentID}`;
            //     window.open(url, '_blank');
            // }}>{CommonHelper.displayFullContact(titles, _contact)}</div>;
            fleetVM.contactName = CommonHelper.displayFullContact(titles, _contact);
            fleetVM.firstName = (_contact.firstName || '');
            fleetVM.middleName = (_contact.middleName || '');
            fleetVM.lastName = (_contact.lastName || '');
            fleetVM.contactPhone = CommonHelper.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
            fleetVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            fleetVM.contactEmail = _contact.email;
            fleetVM.displayID = _contact.displayID;
            fleetVM.licenseNo = _contact.licenseNo;
            fleetVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
            fleetVM.contactType = _contact.contactType;
            fleetVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
            fleetVM.gender = _contact.gender;
            fleetVM.maritalStatus = _contact.maritalStatus;
            fleetVM.contactMethod = _contact.contactMethod;
            fleetVM.licenseType = _contact.licenseType;
            fleetVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
            //fleetVM.campaign = CommonHelper.getLabelByValue(campaigns, _contact.campaign, '');
            //fleetVM.origin = CommonHelper.getLabelByValue(origins, _contact.origin, '');
            fleetVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');

            let _setttings = _contact.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[_contact.clientID] && dealersettings.group.clients[_contact.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                fleetVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, _contact.campaign, '');
                fleetVM.origin = CommonHelper.getNameByValue(_setttings.origins, _contact.origin, '');
                fleetVM.gender = CommonHelper.getNameByValue(_setttings.genders, _contact.gender, '');
                fleetVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, _contact.maritalStatus, '');
                fleetVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, _contact.contactMethod, '');
                fleetVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, _contact.interests, '', true);
                fleetVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, _contact.licenseType, '');
            }

            if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                fleetVM.contactdealerName = CommonHelper.getOtherDealerName(dealersettings, _contact.clientID);
            }
            else
                fleetVM.contactdealerName = '';


            fleetVM.address = _contact.address;
            fleetVM.companyName = _contact.companyName;
            fleetVM.designation = _contact.designation;

            fleetVM.licenseState = _contact.licenseState;
            fleetVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
            fleetVM.secondaryContactPhone = CommonHelper.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, dealersettings);
            fleetVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
            fleetVM.secondaryContactEmail = _contact.secondaryEmail;
            fleetVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '';
            fleetVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '';
            fleetVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            fleetVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }
        else if (!_.isEmpty(fleetVM.staff)) {
            let _staff = Object.assign({}, fleetVM.staff);
            fleetVM.contactName = _staff.name ? _staff.name : '';
            fleetVM.displayID = 'staff';
            fleetVM.phone = _staff.phone ? _staff.phone : '';
            fleetVM.contactEmail = _staff.email;
            fleetVM.dob = _staff.dob ? moment(_staff.dob).format('DD/MM/YYYY') : '';
            fleetVM.licenseNo = _staff.licenseNo ? _staff.licenseNo : '';
            fleetVM.licenseExpiry = _staff.licenseExpiry ? moment(_staff.licenseExpiry).format('DD/MM/YYYY') : '';
            fleetVM.address = _staff.address ? _staff.address : '';
        }

        if (!_.isEmpty(doc.stock)) {
            fleetVM.stockNo = doc.stock.stockNo;
            fleetVM.make = doc.stock.make;
            fleetVM.model = doc.stock.model;
            fleetVM.modelDescription = doc.stock.modelDescription;
            fleetVM.saleType = CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                doc.stock.saleType, '');
            fleetVM.type = doc.stock.type;
            fleetVM.year = doc.stock.year;
            fleetVM.regNo = doc.stock.regNo;
            fleetVM.vinNo = doc.stock.vinNo;
            fleetVM.extColor = doc.stock.extColor;
            fleetVM.intColor = doc.stock.intColor;
            fleetVM.price = doc.stock.price;
            fleetVM.purchasePrice = doc.stock.purchasePrice;
            fleetVM.location = doc.stock.location;
            fleetVM.stockStatus = doc.stock.status;

            fleetVM.statusUpdatedBy = doc.stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, doc.stock.statusModifiedBy) : '';

            if (doc.stock.clientID && (!_.isEmpty(stockLocations) || doc.stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
                fleetVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, doc.stock.clientID);
            }
            else
                fleetVM.dealerName = '';
        }

        fleetVM.startDate = fleetVM.startDate ? moment.unix(fleetVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.expectedReturn = fleetVM.expectedReturn ? moment.unix(fleetVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.endDate = fleetVM.endDate ? moment.unix(fleetVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        fleetVM.lvstatusName = fleetVM.status ? fleetVM.status : '';
        fleetVM.status = (fleetVM.status
            ?
            <div className={`badge badge-pill badge-${fleetVM.status === fleetStatus.PENDING ? 'pending' : 'active-fill'}`}>
                {fleetVM.status}
            </div>
            :
            <></>);
        fleetVM.stockStatusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, fleetVM.stockStatus, '');
        fleetVM.stockStatus = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, fleetVM.stockStatus, '');
        // fleetVM.stockStatus = (fleetVM.stockStatus
        //     ?
        //     <div className={`badge badge-pill badge-${fleetVM.stockStatus.trim().toLowerCase()}-fill `}>
        //         {fleetVM.stockStatus.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);


        return fleetVM
    }


    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        if (activeTab === 'Loan') {
            window.firebase.firestore().doc(`/users/${localStorage.uid}`)
                .set({ loanHistoryFields: fields }, { merge: true })
                .then(() => {
                    setLoanHistoryFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('loanHistoryFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }

    }
    const handleEditFleet = (id, obj) => {

        let _stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(obj.stock)) {
            if (_stock.hasOwnProperty(key))
                _stock[key] = value;
        }

        if (!readOnlyEnquiryView) {
            if (activeTab === 'Loan') {
                setEditDocument(id);
                setModelFleetShow(true);
                setModelStock(_stock);
            }
        }

    }

    const handleClose = (id) => {
        //console.log('handleClose', id)
        setModelFleetShow(false);

        setModelStock({});
        setEditDocument(null);

        if (!_.isEmpty(id)) {
            getFleetbyID(id);

        }
    }

    const getFleetbyID = (id) => {
        window.clistSubscription = collectionData(firestoreDB(props.dealersettings).firestore().collection('fleet')
            .where('documentID', '==', id))
            .pipe(
                switchMap(dataSnapshot => {
                    const stockIds = _.uniq(dataSnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
                    return combineLatest(
                        of(dataSnapshot),
                        combineLatest(
                            stockIds.length > 0 ? stockIds.map(stockID =>
                                collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                    map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([dataSnapshot, stockSnapshot]) => {
                    return dataSnapshot.map(snapDoc => {
                        return {
                            ...snapDoc,
                            stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                        }
                    })
                })
            )
            .subscribe(datas => {
                onDocUpdate(dataMappingVM(datas[0]));
                window.clistSubscription && window.clistSubscription.unsubscribe()
            });
    }

    const onDocUpdate = (_data) => {
        //console.log('onDocUpdate', data)
        if (!_.isEmpty(_data)) {
            const oldLog = fleetHistory[activeTab].find(item => item.documentID === _data.documentID);
            if (!_.isEmpty(oldLog)) {
                dispatch({
                    type: "UPDATE_LIST",
                    data: _data,
                    activetab: activeTab
                });
            }

        }
    }

    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    e.preventDefault();

                    if (!readOnlyEnquiryView)
                        handleEditFleet(id, obj);
                }}>

                {readOnlyEnquiryView ? <><i className="ico icon-lock mr-1"></i></> : <><i className="ico icon-more"></i></>}
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        if (!readOnlyEnquiryView)
            handleEditFleet(id, obj);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }
    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
        setLoader({
            ...fleetHistoryLoader,
            [activeTab]: true
        })
        setPageNum(0);
        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const excelDownload = async () => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        let _fleetFilter = localStorage.fleetFilter ? JSON.parse(localStorage.fleetFilter) : {};
        let _filter = Object.assign({}, _fleetFilter.value);

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (searchType) _filter.loanType = searchType;
        if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();

        if (_.isEmpty(_filter.clientID)) {
            if (isOEMModule && !_.isEmpty(dealersettings.group.clients))
                _filter.clientID = Object.keys(dealersettings.group.clients).map(cid => cid).join(',')
            else
                _filter.clientID = !_.isEmpty(stockLocations) ? _.uniq(stockLocations).join(',') : dealersettings.client.id;
        }
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
        if (searchText && searchText.trim()) _filter.keywords = searchText.trim().toLowerCase();
        let _searchObject = {
            "type": "searchFleet",
            "clientID": _filter.clientID,
            "filters": JSON.stringify(_filter),
            "sortOrder": `${_filter.dateType ? _filter.dateType : 'startDate'} desc`,
            "pageNum": 0,
            "pageLimit": 10000
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )

        //console.log('generic-searchData', _searchObject, localStorage.fleetFilter)
        const searchTestdrives = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchTestdrives(_searchObject);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = dataMappingVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from loan history',
                type: 'excelExport',
                subType: 'fleet',
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('fleetFilter', JSON.stringify(_filter));
            localStorage.tdHistorySearchText = '';
            setLoader({
                ...fleetHistoryLoader,
                [activeTab]: true
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.fleetFilter)) {
            localStorage.removeItem('fleetFilter');
            localStorage.tdHistorySearchText = '';
            setLoader({
                ...fleetHistoryLoader,
                [activeTab]: true
            })
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
    }

    return (
        <div className="middle-wrapper">
            {/* <FilterPanel handleAddActivity={handleAddActivity} /> */}
            <div className="filter-panel">

                <div className="float-left fleet-head">
                    <h3><Translate text={'Loan History'} /></h3>
                    {/* <div className="fleet-count-btn ml-3"> <span >  <Translate text={'available'} /> </span> <span className="fleet-count"> {availableList.length} </span></div>
                    <div className="fleet-count-btn ml-2"> <span >  <Translate text={'loaned'} /> </span> <span className="fleet-count"> {loanList.length} </span></div> */}
                </div>


            </div>
            <div className="filter-sub-panel mb-1">
                <div className="float-left">
                    {/* <div className="filter-item-btn">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                        }}>
                            <i className="ico icon-filter"></i>
                            <span>Filter </span>
                            <i className="ico icon-dropdown"></i>
                        </a>
                    </div> */}

                    {/* <div className="filter-search ml-2">
                        <input type="text" placeholder="search..." aria-label="Search"
                            value={searchText}
                            onChange={(e) => { setSearchText(e.target.value); }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    localStorage.fleetHistorySearchText = searchText;
                                    setSearchText(searchText)
                                    setDataload({
                                        ...checkDataLoad,
                                        [activeTab]: true
                                    })
                                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                }
                            }} />
                    </div> */}
                    <div className="filter-search">
                        <div className="input-group ">
                            <input
                                className="form-control"
                                type="text"
                                id="isearch"
                                placeholder="search..."
                                value={searchText ? searchText : ''}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    localStorage.fleetHistorySearchText = e.target.value;

                                    if (!e.target.value) {
                                        setSearchText('');
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setLoader({
                                            ...fleetHistoryLoader,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        setPageNum(0);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setLoader({
                                            ...fleetHistoryLoader,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        setPageNum(0);
                                    }
                                }}
                                autoComplete="off"
                            />
                            <div className="input-group-append input-search-clear">
                                {
                                    searchText && searchText.length > 0
                                        ?
                                        <a href="#"
                                            className="input-search-clear-icon"
                                            title="Clear"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSearchText('');
                                                localStorage.fleetHistorySearchText = '';
                                                setDataload({
                                                    ...checkDataLoad,
                                                    [activeTab]: true
                                                })
                                                setLoader({
                                                    ...fleetHistoryLoader,
                                                    [activeTab]: true
                                                })
                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                setPageNum(0);
                                            }}
                                        >
                                            <i className="ico icon-remove"></i>
                                        </a>
                                        :
                                        <>
                                        </>
                                }

                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setLoader({
                                            ...fleetHistoryLoader,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        setPageNum(0);
                                    }}>
                                    <i className="ico icon-search-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        activeTab === 'Loan'
                            ?
                            <>
                                <div className="form-loan-history form-row ml-2">
                                    <div className="form-group col-md-12 daterange-fix">
                                        <ReactSelect
                                            options={loan_type}
                                            name={"searchType"}
                                            placeholder={'select loan type'}
                                            onChange={(e) => {
                                                // console.log('select loan type', e)
                                                setSearchType(e.value);
                                                localStorage.fleetHistorySearchType = e.value;
                                                setDataload({
                                                    ...checkDataLoad,
                                                    [activeTab]: true
                                                })
                                                setLoader({
                                                    ...fleetHistoryLoader,
                                                    [activeTab]: true
                                                })
                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                setPageNum(0);
                                            }}
                                            value={searchType}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                    }
                    {/* <div className="form-dashboard form-row ml-2">
                        <div className="form-group col-md-4 daterange-fix">
                            <AntDateRangePicker
                                value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                id={"history-date-range"}
                                name={'date'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                            //disabledDate={disabledDate}
                            />
                        </div>
                    </div> */}
                </div>

                <div className="float-right">


                    {
                        (exportData)
                            ?
                            <>
                                <div className="filter-item-btn ml-2">
                                    <a href="#" onClick={(e) => {
                                        //e.preventDefault();
                                        excelDownload(e);
                                    }} title={'Excel Export'}>
                                        <i className="ico icon-excel-download"></i>
                                    </a>
                                    <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`${activeTab}_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                        <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                    </ExcelFile>

                                </div>

                            </>
                            :
                            <>
                            </>
                    }


                    {/* <div className="filter-view-more  ml-2">
                        <a href="#" className="common-button" onClick={(e) => { e.preventDefault(); }}><i className="ico icon-more"></i></a>
                    </div> */}

                </div>
                {/**** FILTERS ****/}
                <Filters
                    searchKeywords={searchText}
                    fleetFilter={fleetFilter}
                    customFilters={customFilters}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </Filters>

                <div className="filter-item-btn ml-2 advanced-filter-btn mr-2 float-right">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        let backURL = params.get('back-url');

                        if (backURL)
                            props.history.push(backURL);
                        else
                            props.history.push('/fleet');

                    }}>
                        <i className="fa fa-chevron-left pr-0" aria-hidden="true"></i> <Translate text={'back'} />
                    </a>
                </div>

            </div>

            <div className="calendar-panel">
                {
                    fleetHistoryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        fleetHistory[activeTab] && fleetHistory[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={fleetHistory[activeTab] ? fleetHistory[activeTab] : []}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={allFleetHistoryFieldsVM}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={(loanHistoryFields && loanHistoryFields.length > 0) ? loanHistoryFields : allFleetHistoryFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>


            <PopUpModal show={modelFleetShow}>
                <AddFleet
                    docID={editDocumentID}
                    show={modelFleetShow}
                    clsActive='overlay-modal active'
                    stock={modelStock}
                    handleClose={handleClose}
                    title={'fleet'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddFleet>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

        </div>
    )
}

export default FleetHistoryListView;