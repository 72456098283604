import React, { Component } from 'react'

export default class FullheightIframe extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: window.innerHeight - 340 + 'px',
            isLoaded: false,
            elem: null,
        }
    }

    render() {
        const { iFrameHeight } = this.state;
        const { messageID, token, linkedID, apiURL, threadID, accountID, projectId, nylasToken, nylasURL } = this.props;
        return (
            <iframe
                title={`email-${messageID}`}
                id={`email-${messageID}`}
                style={{ width: '100%', height: iFrameHeight, overflow: 'visible' }}
                onLoad={() => {
                    this.setState({
                        isLoaded: true
                    });
                }}
                ref="iframe"
                src={`${window.location.origin}/blank.html?messageID=${messageID}&nylasToken=${nylasToken}&nylasURL=${nylasURL}&token=${token}&linkedID=${linkedID}&apiURL=${apiURL}&threadID=${threadID}&accountID=${accountID}&iframeHeight=${iFrameHeight}&projectId=${projectId}`}
                width="100%"
                height={iFrameHeight}
                scrolling="no"
                frameBorder="0"
            />
        );
    }
}