/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopUpModal } from '../../components';
import AddContact from '../contacts/add';
import { firestoreDB } from '../../services/helper';

const notificationVM = {
    documentID: null,
    title: '',
    body: '',
    clientID: null,
    contactID: null,
    fromUserID: null,
    isDeleted: false,
    isRead: false,
    toUserID: null,
    type: '',
    metaData: null
};

export default class NotifyAircall extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeEngageAircall = null;
        this.state = {
            fields: Object.assign({}, notificationVM),
            showContact: false,
            isLoading: false,
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    //#region PAGE LOAD EVENTS

    async loadData(props) {
        const { show, docID } = props;
        if (show === true && docID) {
            this.newDocID = docID;
            this.unsubscribeEngageAircall = window.firebase.firestore().collection('notifications')
                .doc(docID)
                .onSnapshot(this.onCollectionUpdate);
        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadData(this.props);
    }

    componentWillUnmount() {
        this.unsubscribeEngageAircall && this.unsubscribeEngageAircall();
    }
    //#endregion

    handleContactOpen = () => {
        this.setState({ showContact: false });
    };
    handleContactClose = (id) => {
        if (id) {
            const { metaData, documentID } = this.state.fields;
            const { dealersettings } = this.props;

            window.firebase.firestore().doc(`notifications/${documentID}`).set({ contactID: id }, { merge: true });

            if (metaData?.activityID)
                firestoreDB(dealersettings).firestore().doc(`activities/${metaData?.activityID}`).set({ contactID: id }, { merge: true });

            const { setNotifyClose, history, handleClose, updateAircallModel, updateRingcentralModel } = this.props;
            updateAircallModel(false)
            updateRingcentralModel(false)
            handleClose();
            history.push("/contacts/details/" + id);
            if (setNotifyClose)
                setNotifyClose(true);
        }
        this.setState({ showContact: false });
    };

    render() {
        const { title, body, type, contactID, metaData } = this.state.fields
        const { show, dealersettings, clientUsers, groupUsers } = this.props;
        const { showContact, isLoading } = this.state;
        const _aircall = dealersettings?.client?.integrations?.filter(e => e.type === "aircall")?.[0];
        const _justcall = dealersettings?.client?.integrations?.filter(e => e.type === "justcall")?.[0];
        const _ringcentral = dealersettings?.client?.integrations?.filter(e => e.type === "ringcentral")?.[0];
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog modal-dialog-scrollable modal-dialog-centered  modal-anonymous-alert">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="anonymouscustom-body">
                        <div className="anonymouscustom-body-overlay">
                            <div className="spinner-loader h-100">
                                <div className="anonymouscustom-flex-wrap h-100 justify-content-center align-items-center loader-primary">

                                    <div className="anonymouscustom-txt-wrap">
                                        <div className="text-center mt-2">
                                            <div className={`anonymous-popalert-icon ${(type === 'aircallMissed' || type === 'callMissed' || type === 'smsMissed') ? 'anonymous-icon-red' : ''}`}>
                                                {
                                                    type === 'smsMissed'
                                                        ?
                                                        <i className="ico icon-message"></i>
                                                        : type === 'aircallMissed' || type === 'callMissed'
                                                            ?
                                                            <i className="ico icon-no-call"></i>
                                                            :
                                                            <>
                                                                {
                                                                    metaData?.callType === 'outbound'
                                                                        ?
                                                                        <i className="ico icon-call-outbound"></i>
                                                                        :
                                                                        <i className="ico icon-call-inbound"></i>
                                                                }
                                                            </>
                                                }

                                            </div>
                                        </div>

                                        <div className="anonymouscustom-content-head"> {title ? title : ''}</div>
                                        {
                                            metaData?.phoneNumber ? (
                                                <div className="anonymous-popalert-number">{metaData?.phoneNumber}</div>
                                            ) : (<></>)
                                        }
                                        <div className="anonymouscustom-content">{body ? body : ''}</div>
                                        {
                                            metaData?.notes ? (
                                                <div className="flow-activity-notes-inner ml-0 mr-2">
                                                    <div className="flow-text text-area-space note">
                                                        <div className="">{metaData?.notes}</div>
                                                    </div>
                                                </div>
                                            ) : (<></>)
                                        }
                                        {
                                            type === 'aircallContact' || type === 'callContact' || type === 'smsMissed'
                                                ?
                                                <div className={`anonymouscustom-content-btn ${showContact ? 'form-disable' : ''}`}>
                                                    <button type="button" className="btn btn-default ml-1 mr-1"
                                                        onClick={(e) => { e.preventDefault(); this.props.handleClose(); }}><Translate text={'close'} /></button>

                                                    {
                                                        contactID
                                                            ?
                                                            <button type="button"
                                                                className="btn btn-primary ml-1 mr-1"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const { setNotifyClose, history, handleClose, updateAircallModel, updateRingcentralModel } = this.props;
                                                                    updateAircallModel(false)
                                                                    updateRingcentralModel(false)
                                                                    handleClose();
                                                                    history.push("/contacts/details/" + contactID);
                                                                    if (setNotifyClose)
                                                                        setNotifyClose(true)
                                                                }}
                                                            ><Translate text={'View Contact'} /></button>
                                                            :
                                                            <button type="button"
                                                                className="btn btn-primary ml-1 mr-1"
                                                                onClick={(e) => { e.preventDefault(); this.setState({ showContact: true }) }}
                                                            >
                                                                {showContact ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'Create Contact'} /></button>

                                                    }

                                                </div>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            type === 'aircallMissed' || type === 'callMissed'
                                                ?
                                                <div className="anonymouscustom-content-btn">
                                                    <button type="button" className="btn btn-default ml-1 mr-1"
                                                        onClick={(e) => { e.preventDefault(); this.props.handleClose(); }}><Translate text={'close'} /></button>
                                                    {
                                                        contactID
                                                            ?
                                                            <button type="button"
                                                                className="btn btn-primary ml-1 mr-1"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const { setNotifyClose, history, handleClose, updateAircallModel, updateRingcentralModel } = this.props;
                                                                    updateAircallModel(false)
                                                                    updateRingcentralModel(false)
                                                                    handleClose();
                                                                    history.push("/contacts/details/" + contactID);
                                                                    if (setNotifyClose)
                                                                        setNotifyClose(true)
                                                                }}
                                                            ><Translate text={'View Contact'} /></button>
                                                            :
                                                            <></>
                                                    }
                                                    <button type="button" className="btn btn-green ml-1 mr-1" onClick={() => {
                                                        const { setNotifyClose, history, handleClose } = this.props;
                                                        handleClose();
                                                        if (setNotifyClose)
                                                            setNotifyClose(true)
                                                        if (_aircall?.active && _aircall?.enabled)
                                                            this.props.dialNumber(metaData?.contact?.phone, null, contactID)
                                                        else if (_ringcentral?.active && _ringcentral?.enabled)
                                                            this.props.dialRingcentral(metaData?.contact?.phone, null, contactID)
                                                        else if (_justcall?.active && _justcall?.enabled)
                                                            this.props.dialJustcall(metaData?.contact?.phone, null, contactID)
                                                    }}> <Translate text={'Call back'} /></button>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                <PopUpModal show={showContact}>
                    <AddContact
                        show={showContact}
                        handleClose={this.handleContactClose}
                        engageNewContact={metaData?.contact}
                        title={'addContact'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        history={this.props.history}
                        isFromLayout={true}
                    ></AddContact>
                </PopUpModal>

            </>


        );
    }
}

