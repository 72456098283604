/** LIBRARIES */
import React, { Component } from 'react';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import { OverlayTrigger, Tooltip, Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2';
import _ from 'lodash';

import { collection, doc } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';

import {
    ImageViewer, PopUpModal,
    ReactSelect,
    LoaderOverlay, DropDownMenu
} from '../../../components';

import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
import images, { default as _images } from '../../../images';
import PageNotFound from '../../../components/pagenotFound';

import AddEvent from '../add';
//import ContactInfo from '../../contacts/info'
//import Files from '../../files';
//import AddFile from '../../files/add';
import CommonHelper from '../../../services/common';


import Activity from '../../pipeline/details/activity'
import Attendees from '../attendees/index.js'
import AttendeesEOI from '../attendeesEOI/index.js'
import LinkShare from '../attendeesEOI/linkShare'
import EventReports from './reports.js'

import { objEventPro } from '../viewModel';
import { firestoreDB } from '../../../services/helper';
import ManageTemplates from './manageTemplates';
import EventSettings from './settings';
import { eventAttendeeStatus, eventStatus, userLevel } from '../../../services/enum';
import ScheduleReport from '../../reports/scheduleReport';
import htmlToText from 'html-to-text';
import Allocation from './allocation.js';

//#region  ASSIGN VARIABLES
//let enquiryOptionsDF = [];
let eventsProAllStatus = [];
let _enquiryAllStatus = [];
const fieldsData = {
    contact: '',
    eventsPro: '',
    company: ''
}

let enquirySortOptions = [
    "origin", "lostReason", "lostSubReason"
];
//#endregion

export default class TradeinPlusInfo extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('eventsPro');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.unsubscribeEventsPro = null;
        this.mandatoryFields = [];
        this.state = {
            loading: false,
            activeLeftKey: '0',
            activeCntKey: '0',
            activeVehKey: '1',
            activeTipKey: '2',
            activeRightKey: '',
            fields: Object.assign({}, objEventPro),
            tradeIn: Object.assign({}),
            eventSettings: Object.assign({}),
            eventSlots: [],
            eventsProID: null,
            _clientID: null,
            errors: {},
            pipelines: [],
            defaulStages: [],
            allUsers: [],
            rottenDays: 0,
            tipInfo:
            {
                new: false,
                lead: false
            },
            eventsModal:
            {
                show: false,
                title: '',
                clsActive: '',
                docID: null,
                mandatoryFields: null
            },
            fileModal:
            {
                show: false
            },
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
            pipelinePopOver: { showpopover: false, targetpopover: null },
            statusSettings: [],
            activeStageIndex: 0,
            isEmptyPipeline: false,
            showReAuthentication: false,
            pagenotFound: false,
            dealersettings: props.dealersettings,
            triggerModal:
            {
                show: false,
                title: ''
            },
            eventTemplates: [],
            eventTriggers: [],
            settingsModal: {
                show: false,
                title: ''
            },
            settingLoading: true,
            readMore: false,
            linkShareModal: false,
            modelShow: {
                show: false
            },
            excelDownload: false,
            modelAllocationShow: {
                show: false
            },
            imageViewer: { show: false, images: null }
        }
    }

    //#region FUNCTIONS

    //#region LOAD PAGE

    async loadEventsPro() {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        const { eventsproid, clientUsers, groupUsers } = this.props;
        if (eventsproid && this.props.dealersettings) {
            // if (dealersettings.client.settings.pipelines.length === 0) {
            //     Swal.fire('Access denied. Please contact administrator.', '', 'info')
            //     this.props.history.push('/eventspro/list');
            //     return
            // }

            const refData = await firestoreDB(this.props.dealersettings).firestore().collection('eventsPro').doc(eventsproid).get();
            var _stage = '';

            if (refData.exists) {
                const _objTIP = Object.assign({}, refData.data());
                if (!localStorage.defaultModule.includes('oem') && !_.isEmpty(_objTIP.clientIDs) && !this.props.dealersettings.clients.some(e => _objTIP.clientIDs.includes(e.id))) {
                    if (localStorage.defaultModule === 'eventsPro') {
                        Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have dealer access. Please contact administrator.'), '', 'info')
                        this.props.history.push('/eventspro/list');
                    }
                    return
                }

                const _eventspro = Object.assign({}, objEventPro);
                let _isEmptyPipeline = false;
                for (let [key, value] of Object.entries(_objTIP)) {
                    _eventspro[key] = value;
                }
                _eventspro.documentID = refData.id;

                if (_.isEmpty(_eventspro.pipeline)) {
                    _isEmptyPipeline = true;
                }

                let dealersettings = this.props.dealersettings;
                // if (_eventspro.clientID && _eventspro.clientID !== dealersettings.client.id) {
                //     dealersettings = await CommonHelper.updateClientSettings(_eventspro.clientID, Object.assign({}, this.props.dealersettings), 'eventsPro');

                // }

                // const _client = _eventspro.clientID && !_.isEmpty(dealersettings) &&
                //     !_.isEmpty(dealersettings.group) &&
                //     !_.isEmpty(dealersettings.group.clients) &&
                //     dealersettings.group.clients[_eventspro.clientID] &&
                //     dealersettings.group.clients[_eventspro.clientID].eventsPro
                //     ? dealersettings.group.clients[_eventspro.clientID] : dealersettings.client;
                const _client = dealersettings.client;
                //console.log('E              X             I                S                  T             S', _isEmptyPipeline)
                if (_client && _client.eventsPro && _eventspro.pipeline && !_client.eventsPro.pipelines.some(item => item.value === _eventspro.pipeline)) {
                    if (localStorage.defaultModule === 'eventsPro') {
                        Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have pipleine access. Please contact administrator.'), '', 'info')
                        this.props.history.push('/eventspro/list');
                    }
                    return
                }

                const pipelines = [];
                let defaultstages;
                let statusSettings;
                let stages = null;

                _stage = _eventspro['stage'];


                // //add eventspro into routes
                this.props.handleRouteChange({
                    name: _eventspro.title,
                    id: refData.id,
                    displayID: _eventspro.displayID ? _eventspro.displayID : refData.id,
                    status: _eventspro.status,
                    active: true,
                    clientid: _eventspro.clientID,
                    isNew: null,
                    params: '',
                    module: 'eventsPro'
                }, 'eventsPro');



                if (!_.isEmpty(_client) && !_.isEmpty(_client.eventsPro)) {
                    const setttings = _client.eventsPro;

                    if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.eventsPro))
                        this.mandatoryFields = setttings.mandatoryFields.eventsPro.split(',');

                    // enquiryOptionsDF = [];
                    // if (setttings.enquiryOptionsDF)
                    //     enquiryOptionsDF = setttings.enquiryOptionsDF;

                    let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
                    if (_eventspro.pipeline) {
                        let _selPipeline = allPipelines.filter(e => e.value === _eventspro.pipeline)[0];
                        defaultstages = _selPipeline && _selPipeline.stages;
                        statusSettings = _selPipeline && _selPipeline.statusSettings;

                        if (!_.isEmpty(defaultstages))
                            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')
                    }

                    eventsProAllStatus = [];
                    dealersettings?.client?.eventsPro?.eventsProStatus && dealersettings.client.eventsPro.eventsProStatus.forEach((doc) => {
                        eventsProAllStatus.push({
                            ...doc,
                            label: <div className={`badge badge-pill badge-status-${doc.value === 'New' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                        });
                    });

                    _enquiryAllStatus = [];
                    dealersettings?.client?.settings?.enquiryStatus && dealersettings.client.settings.enquiryStatus.filter(e => !_.includes(['delivered', 'pendingLost', 'prospectLost', 'pending', 'closeWon'], e.value) && !Boolean(e.afterSold)).forEach((doc) => {
                        _enquiryAllStatus.push({
                            ...doc,
                        });
                    });

                    allPipelines && allPipelines.forEach((doc) => {
                        pipelines.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });


                }



                // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                let options = [];
                if (!_.isEmpty(dealersettings.client.roles) && _eventspro.clientID) {

                    let roleIds = [..._.map(dealersettings.client.roles.filter(e => _eventspro.pipeline && e.eventsProPipeline && e.eventsProPipeline.some(m => m === _eventspro.pipeline)), function (obj) {
                        return obj.documentID;
                    })];
                    let _selectedUsers = [];
                    let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                    if (!_.isEmpty(roleIds))
                        _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                    if (_eventspro.pipeline) {
                        _selectedUsers && _selectedUsers.map(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                    else {
                        clientUsers.forEach(rec => {
                            return options.push({
                                value: rec.id,
                                active: _.isBoolean(rec.active) ? rec.active : true,
                                searchlabel: rec.name,
                                label: <div className="drop-image">
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            })
                        })
                    }
                }
                else {
                    clientUsers.map(rec => {
                        return options.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })
                }



                this.setState({
                    eventsProID: refData.id,
                    _clientID: _eventspro.clientID ? _eventspro.clientID : dealersettings.client.id,
                    fields: _eventspro,
                    pipelines: pipelines,
                    allUsers: _.uniqBy(options, 'value'),
                    defaulStages: defaultstages,
                    activeStageIndex: stages && stages.indexOf(_stage),
                    isEmptyPipeline: _isEmptyPipeline,
                    statusSettings: statusSettings,
                    dealersettings: dealersettings,
                }, () => {
                    this.unsubscribeEventsPro = this.ref
                        .doc(eventsproid)
                        .onSnapshot(this.onTIPCollectionUpdate);

                    this.loadTemplates();
                });
            }
            else {
                this.setState({
                    pagenotFound: true,
                    eventsProID: null,
                })
            }
        }
    }


    loadTemplates = () => {
        const { dealersettings } = this.props
        const { clientIDs, clientID, level, levelID } = this.state.fields;
        let templatesSnap = '';
        let triggerSnap = '';
        let _clientIDs = CommonHelper.getAllEventClientsByLevel(dealersettings, clientIDs, level, levelID)
        var templatePromise = [];
        if (_clientIDs?.length > 0) {
            for (let k = 0; k < Math.ceil(_clientIDs.length / 1); k++) {
                templatePromise.push(collection(window.firebase.firestore().collection('eventsProTemplates')
                    .where('isDeleted', '==', false)
                    .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(_clientIDs, 1, k + 1))
                ))

            }
        }
        templatePromise.push(collection(window.firebase.firestore().collection('eventsProTriggers')
            .where('eventsProID', '==', this.state.fields.documentID)));

        templatePromise.push(doc(firestoreDB(dealersettings).firestore().doc(`eventsPro/${this.state.fields.documentID}/settings/${this.state.fields.documentID}`)));
        templatePromise.push(collection(window.firebase.firestore().collection(`eventsProTPSource`)
            .where('active', '==', true)
            .where('isDeleted', '==', false)
            .where('eventsProIDs', 'array-contains', this.state.fields.documentID)
        ));
        window.refCollectionEventsData = combineLatest(
            templatePromise
        ).subscribe(async allDocs => {
            var _templates = [];
            for (let i = 0; i < allDocs.length - 3; i++) {
                _templates.push(...allDocs[i].map(r => { return { ...r.data(), documentID: r.id } }));

            }
            this.setState({
                eventTriggers: allDocs[allDocs.length - 3].map(r => { return { ...r.data(), documentID: r.id } }),
                eventTemplates: _.uniqBy(_templates, 'documentID'),
                eventSettings: {
                    ...allDocs[allDocs.length - 2].data(),
                    eventsProID: allDocs[allDocs.length - 2].id,
                    source: allDocs[allDocs.length - 1].map(r => { return { ...r.data(), documentID: r.id, value: r.id } })
                },
                settingLoading: false
            }, () => { this.loadEventSettings(); });
        });
    }


    loadEventSettings = () => {
        const { eventSettings } = this.state;
        let slots = [];
        eventSettings?.slots && eventSettings.slots.forEach((doc) => {
            slots.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        this.setState({ eventSlots: slots });
    }

    onTIPCollectionUpdate = (docSnapshot) => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');

        if (!docSnapshot.exists) {
            return;
        }

        const { dealersettings } = this.state;
        const pipelines = [];
        let rottendays = 0;
        let stages = null;
        let defaultstages;
        let statusSettings;

        const _eventspro = Object.assign({}, objEventPro);
        const _objTIP = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objTIP)) {
            _eventspro[key] = value;
        }

        _eventspro.documentID = docSnapshot.id;


        let _client = _eventspro.clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[_eventspro.clientID] &&
            dealersettings.group.clients[_eventspro.clientID].eventsPro
            ? dealersettings.group.clients[_eventspro.clientID] : dealersettings.client;

        if (!_.isEmpty(_client) && !_.isEmpty(_client.settings) && _eventspro.pipeline) {
            const setttings = _client.eventsPro;

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.eventsPro))
                this.mandatoryFields = setttings.mandatoryFields.eventsPro.split(',');


            let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
            let _selPipeline = allPipelines.filter(e => e.value === _eventspro.pipeline)[0];
            defaultstages = _selPipeline && _selPipeline.stages;
            statusSettings = _selPipeline && _selPipeline.statusSettings;

            if (!_.isEmpty(defaultstages))
                stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

            allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            allPipelines
                .filter(e => e.value === _eventspro.pipeline)[0]
                .stages.forEach(col => {
                    if (_eventspro.stage === col.value) {
                        if (col.rottenDays) {
                            var enqage = _eventspro.modifiedDate ? moment().diff(moment.unix(_eventspro.modifiedDate.seconds), 'days') : 0;
                            rottendays = enqage - col.rottenDays;
                        }
                    }
                });

        }

        const { status } = _eventspro;
        if (_eventspro.status !== this.state.fields.status) {
            this.props.updatenavbar({
                id: this.state.eventsProID,
                displayID: _eventspro.displayID,
                status: status,
                name: _eventspro.title,
                module: 'eventsPro'
            }, 'eventsPro');
        }

        this.setState({
            fields: _eventspro,
            pipelines: pipelines,
            statusSettings: statusSettings,
            defaulStages: defaultstages,
            activeStageIndex: stages && stages.indexOf(_eventspro['stage'])
        });
    };


    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    componentDidMount() {
        localStorage.removeItem("objEventPro");
        this._isMounted = true;
        if (this._isMounted) {
            this.loadEventsPro();
            setTimeout(() => this.scrollIntoComments(), 4000);
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            this.unsubscribeEventsPro && this.unsubscribeEventsPro();
            window.refCollectionEventsData?.unsubscribe && window.refCollectionEventsData.unsubscribe()
            this.loadEventsPro();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.eventsproid !== state.eventsProID && state.eventsProID) {
            return {
                loading: true,
                settingLoading: true,
                eventsProID: null,
                tipOffers: Object.assign([]),
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.unsubscribeEventsPro && this.unsubscribeEventsPro();
        window.refCollectionEventsData?.unsubscribe && window.refCollectionEventsData.unsubscribe()
        if (window.location.pathname.indexOf('eventspro/details') < 0) {
            this.props.resetnavbar();
        }
        this._isMounted = false;
    }

    scrollIntoComments = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let __comments = params.get('comments');

        if (__comments && document.getElementById('enquiry-item-' + __comments)) {
            document.getElementById('enquiry-item-' + __comments).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    //#endregion    

    //#region Vehicle CHANGES


    handleEventOpen = (id, _manFields) => {
        this.setState({
            eventsModal: {
                show: true,
                title: 'event',
                clsActive: 'overlay-modal active',
                docID: id,
                //mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
            },
        });
    }

    handleEventClose = (tradeinID, tradein, isLink) => {
        this.setState({
            eventsModal: {
                show: false,
                title: 'event',
                clsActive: 'overlay-modal active',
                mandatoryFields: null,
                docID: null
            },
        });
    }

    //#endregion

    //#region CHANGE EVENT TIP

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.saveEventsProField(name, value);
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnDateChange = (value, name) => {
        let _selectedDate = null;
        if (value) _selectedDate = window.firebase.firestore.Timestamp.fromDate(moment(moment(value).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        this.saveEventsProField(name, _selectedDate);

        setTimeout(() => this.errorChange(name), 500);
    };

    handleTimeChange = (date, name) => {

        this.saveEventsProField(name, window.firebase.firestore.Timestamp.fromDate(moment(date)._d));
        setTimeout(() => this.errorChange(name), 500);
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (key === 'status') {
                    if (!this.state.tipInfo.lead)
                        errors[key] = errorClass;
                }
                else
                    errors[key] = errorClass;
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    };

    handleSelectSave = (e, data) => {
        this.saveEventsProField(data.name, (e ? e.value : null));
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        this.saveEventsProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handleDateChange = (val, name) => {
        if (val) val = moment(val).format('YYYY-MM-DD');

        this.saveEventsProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    handlePIDateSave = (val, name) => {
        if (val) val = moment(val).format('MMM YYYY');

        this.saveEventsProField(name, val);
        setTimeout(() => this.errorChange(name), 500);
    };

    isPIValid = (date) => {
        let _date = moment(date, 'MMM YYYY');
        if (moment().isBefore(_date) || moment().isSame(_date, 'month')) return '';
        else return 'form-alert ';
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleLeftActiveKey = (index) => {
        this.setState({ activeLeftKey: index });
    }
    handleCntActiveKey = (index) => {
        this.setState({ activeCntKey: index });
    }
    handleVehActiveKey = (index) => {
        this.setState({ activeVehKey: index });
    }
    handleTipActiveKey = (index) => {
        this.setState({ activeTipKey: index });
    }

    //#endregion

    //#region  SAVE TIP INFO


    cancelEventsProField = (name, val) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    saveEventsProField = (name, val, noToast) => {

        if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
            this.errorChange(name);
        }
        else {
            let objData = {};
            let state = Object.assign({}, this.state.fields);
            if (name !== 'isFav') {
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();
            }

            if (name === 'status') {
                if (state.isNewEvent)
                    objData.isNewEvent = false;

                if (val === eventStatus.DRAFT) {
                    objData.publishedDate = null;
                    objData.liveDate = null;
                    objData.closedDate = null;
                }
                else if (val === eventStatus.PUBLISHED) {
                    objData.publishedDate = state.modifiedDate;
                    objData.liveDate = null;
                    objData.closedDate = null;
                }
                else if (val === eventStatus.LIVE) {
                    objData.liveDate = state.modifiedDate;
                    objData.closedDate = null;
                }
                else if (val === eventStatus.CLOSED) {
                    objData.closedDate = state.modifiedDate;
                }

                objData = this.handleMoveStageForStatusChange(val, objData);

            }


            state[name] = val;
            objData[name] = val;
            objData.modifiedBy = state.modifiedBy;
            objData.modifiedDate = state.modifiedDate;
            objData.modifiedFrom = 'web';

            this.setState({ fields: state });

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('eventsPro').doc(this.state.eventsProID);
            updateRef.set(objData, { merge: true }).then((docRef) => {

                if (!noToast)
                    toast.notify('Event updated successfully.', { duration: 2000 });

                if (name === 'status' || name === 'isNewEvent') {
                    this.props.updatenavbar({
                        id: this.state.eventsProID,
                        status: state.status,
                        name: state.title,
                    });
                }

                if (name === 'status' && val === eventStatus.CLOSED) {
                    window.firebase.firestore().doc(`schedular/${this.state.eventsProID}-eventsPro`)
                        .get()
                        .then(rec => {
                            if (rec.exists) {
                                window.firebase.firestore().doc(`schedular/${this.state.eventsProID}-eventsPro`).update({ enabled: false })
                            }
                        })
                    if (this.state.fields.level === 'individual' && this.state.fields?.clientIDs?.length === 1) {
                        this.setState({ modelAllocationShow: { show: true } })
                    }
                }

            }).catch(error => {
                console.error('Error updating enquiries: ', error);
            });
        }
    };

    handleDynamicStatus = (_status) => {

        let _selectedStatus = CommonHelper.getNameByValue(eventsProAllStatus, _status)
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(this.props, 'You want to change event status to')} ${_selectedStatus.toUpperCase()}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                this.saveEventsProField('status', _status);
            }
        });



    }

    handleMoveStageForStatusChange = (_status, state) => {
        const { statusSettings } = this.state;
        const { stageHistory, statusHistory } = this.state.fields;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else {
            // let _statusHistory = Object.assign([], statusHistory);
            // _statusHistory.push({
            //     date: window.firebase.firestore.Timestamp.now(),
            //     status: _status,
            //     user: localStorage.uid
            // });
            // state.statusHistory = Object.assign([], _statusHistory);
            return state;
        }

    }

    handleDeleteEvent = e => {
        e.preventDefault();
        const { clientID } = this.state.fields;
        const { dealersettings } = this.props;
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to delete this event`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then(result => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                state.isDeleted = true;
                state.modifiedBy = dealersettings ? dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(dealersettings).firestore().collection('eventsPro').doc(this.state.eventsProID);
                updateRef.update({
                    isDeleted: state.isDeleted,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    // var objRecord = {
                    //     id: this.state.eventsProID,
                    //     clientid: clientID
                    // }

                    // this.props.handleRouteRemove(objRecord, 'eventsPro');
                    toast.notify(`Event deleted successfully`, {
                        duration: 2000,
                    });
                    this.setState({ fields: state });

                    let logNotes = 'Event deleted';
                    if (!_.isEmpty(state) && state.title)
                        logNotes = 'Event: ' + state.title + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: 'eventsPro',
                        subType: 'delete',
                        recordId: this.state.eventsProID,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                    CommonHelper.saveAuditLog(_objLogData)

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    handlePipelineClose = async (data, origin) => {
        if (!_.isEmpty(data)) {

            //if (!_.isEmpty(origin) || this.isValidForStageUpdate(data.pipeline, data.stage, 'PipelineClose', data)) {
            let state = Object.assign({}, this.state.fields);

            const { dealersettings } = this.state;

            let setttings = dealersettings.client.eventsPro;

            if (state.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[state.clientID] && dealersettings.group.clients[state.clientID].eventsPro)
                setttings = dealersettings.group.clients[state.clientID].eventsPro;

            let allPipelines = setttings.allPipelines ? setttings.allPipelines : setttings.pipelines;
            let defaultstages = allPipelines.filter(e => e.value === data.pipeline)[0].stages;
            let statusSettings = allPipelines.filter(e => e.value === data.pipeline)[0].statusSettings;

            let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            if (state.stage !== data.stage || state.pipeline !== data.pipeline) {

                let stageHistory = Object.assign([], state.stageHistory);

                let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
                if (!_.isEmpty(_stageData)) {
                    stageHistory.push(_stageData);
                    state.stageHistory = Object.assign([], stageHistory);
                    state.pipeline = data.pipeline;
                    state.stage = data.stage;
                    state.stageDate = window.firebase.firestore.Timestamp.now();
                }
            }
            state.owner = data.owner ? data.owner : state.owner;
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('eventsPro').doc(this.state.eventsProID);
            let updateObj = {
                pipeline: state.pipeline,
                stage: state.stage,
                owner: state.owner,
                stageDate: state.stageDate,
                stageHistory: state.stageHistory,
                modifiedBy: state.modifiedBy,
                modifiedDate: state.modifiedDate,
                modifiedFrom: 'web'
            }

            await updateRef.update(updateObj).then((docRef) => {
                this.setState({
                    fields: state,
                    defaulStages: defaultstages,
                    statusSettings: statusSettings,
                    activeStageIndex: stages.indexOf(data.stage)
                }, () => { this.errorChange(data.name); });

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
            //}
        }
        this.setState({
            pipelinePopOver: { showpopover: false, targetpopover: '' }
        });
    }

    handleStageChange = (id, name) => {
        //e.preventDefault();

        //const { id, name } = e.target;
        const { pipeline, status } = this.state.fields;

        const { statusSettings } = this.state;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === pipeline && e.stage === name)[0];
        if (_statusMndry && status !== _statusMndry.status) {
            let __stage = this.state.defaulStages.find(x => x.value === name);
            let _status = CommonHelper.getNameByValue(eventsProAllStatus, _statusMndry.status)
            Swal.fire(`${CommonHelper.showLocale(this.props, 'Please change events status to')} ${_status.toUpperCase()} ${CommonHelper.showLocale(this.props, 'before moving to stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        //if (this.isValidForStageUpdate(pipeline, name, 'stage')) {
        this.setState({
            activeStageIndex: id
        })
        let state = Object.assign({}, this.state.fields);

        let stageHistory = Object.assign([], state.stageHistory);
        let _stageData = CommonHelper.handleStageHistory(stageHistory, state.pipeline, name);
        if (!_.isEmpty(_stageData)) {
            stageHistory.push(_stageData);
            state.stageHistory = Object.assign([], stageHistory);
            state.stage = name;
            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('eventsPro').doc(this.state.eventsProID);
            updateRef.update({
                stage: state.stage,
                stageDate: state.stageDate,
                stageHistory: state.stageHistory,
                modifiedBy: state.modifiedBy,
                modifiedDate: state.modifiedDate,
                modifiedFrom: 'web'
            }).then((docRef) => {
                this.setState({ fields: state });
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }

    }

    handleStageComplete = _stage => {
        const { stageHistory, pipeline } = this.state.fields;
        let response = 'active-disable';
        if (!_.isEmpty(stageHistory) &&
            stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
            let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
            if (!_.isEmpty(_stageDays)) {
                response = 'active';
            }
        }
        return response;
    };

    //#endregion

    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }
    //#endregion    

    //#region SHARE CHANGES
    handleSharePanelOpen = (e) => {
        e.preventDefault();
        localStorage.removeItem('showChat');
        this.setState({
            showSharePanel: {
                chatClsActive: 'active',
                chatClsName: 'sidebar',
            },
        });
    };

    handleSharePanelClose = () => {
        this.setState({
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            },
        });
    };
    //#endregion

    getEventUsers = () => {
        const { clientUsers, groupUsers } = this.props;
        const { clientIDs, level, levelID } = this.state.fields;
        let _users = clientUsers;
        if (groupUsers) {
            if (clientIDs?.length) {
                _users = groupUsers.filter(a => clientIDs.includes(a.clientID))
            } else if (level && levelID?.length) {
                _users = groupUsers.filter(a => a[`array${CommonHelper.autoCaps(level)}ID`] && levelID.some(b => a[`array${CommonHelper.autoCaps(level)}ID`].includes(b)))
            } else {
                _users = groupUsers;
            }
        }

        return _.uniqBy(_users, 'id');
    }

    render() {

        //console.log('R E - R E N D E R - E N Q U I R Y - E D I T ');
        let { documentID, clientID, displayID, clientName,
            pipeline, stage, status, owner, stageHistory,
            imageURL, enableEOI, counts,
            title, location, startDate, endDate, clientIDs, description,
            addedBy, addedDate, isDeleted, modifiedBy, modifiedDate, modifiedFrom, addedFrom,
            publishedDate, liveDate, closedDate, timezone
        } = this.state.fields;

        const { activeCntKey, activeVehKey, dealersettings, allUsers, eventsModal, triggerModal, eventTriggers,
            settingsModal, eventSlots, eventSettings, settingLoading, readMore, fields, linkShareModal, modelShow, modelAllocationShow } = this.state;

        const { groupUsers, clientUsers } = this.props;

        let _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID] &&
            dealersettings.group.clients[clientID].eventsPro ? dealersettings.group.clients[clientID] : dealersettings.client;

        // const _clientTIP = (!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.client) &&
        //     !_.isEmpty(dealersettings.client.clientSettings) &&
        //     !_.isEmpty(dealersettings.client.clientSettings.tradeinplus)) ? dealersettings.client.clientSettings._eventspro : null;

        const _settings = _client && _client.eventsPro;

        const _hasNoPipelineAccess = pipeline && _settings && !_settings.pipelines.some(item => item.value === pipeline) ? true : false;

        const _permissions = dealersettings?.rolePermissions?.eventsProPermissions

        const addEventAccess = ((!_.isEmpty(_permissions) && _permissions.addEvent) ? true : false);
        const manageTemplate = ((!_.isEmpty(_permissions) && _permissions.manageTemplate) ? true : false);
        const deleteAccess = ((!_.isEmpty(_permissions) && _permissions.deleteEvent && (status === eventStatus.CLOSED || status === eventStatus.DRAFT)) ? true : false);

        const setttings = _client && _client.settings;

        let dealerName = clientName ? clientName : (clientID ? CommonHelper.getOtherDealerName(dealersettings, clientID) : fields.level ? CommonHelper.autoCaps(fields.level) : '');

        const isDraft = status !== eventStatus.DRAFT && status !== eventStatus.PUBLISHED ? false : true;
        const isClosed = status === eventStatus.CLOSED ? true : false
        const canModifyEvent = (_hasNoPipelineAccess || isDeleted || status === eventStatus.CLOSED) ? false : true;

        // let dynamicDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.eventsProOptions))
        //     ? dealersettings.client.eventsProOptions :
        //     Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        let _objCurrentStatus = status && eventsProAllStatus.filter(e => e.value === status)[0];
        let _dynamicStatusMenu = CommonHelper.bindEventStatusMenu(eventsProAllStatus, status, _permissions, canModifyEvent);
        // let _allClientIDs = CommonHelper.getAllEventClientsByLevel(dealersettings, clientIDs, fields.level, fields.levelID)
        // console.log('_allClientIDs', _allClientIDs, clientIDs, fields.level, fields.levelID)

        return this.state.eventsProID ? (
            <>
                <div className="middle-wrapper">
                    <div className="enquiry-detailswrap">
                        <div className="enquiry-detail-head">

                            <div className="float-left enquiry-details-title-wrap">
                                <div className="enquiry-details-contact"
                                    onDoubleClick={e => {
                                        e.preventDefault();
                                        console.log('dealersettings-state', dealersettings)
                                        console.log('dealersettings-props', this.props.dealersettings)
                                        console.log('eventSettings', eventSettings)
                                    }}>
                                    <i className="ico icon-events-event mr-1"></i>
                                    {title ? title : '--'}
                                </div>

                                {/* <span className='enquiry-details-favourite-ico'>
                                    {
                                        (isFav)
                                            ?
                                            <>
                                                <a href='#' onClick={e => { e.preventDefault(); }}>
                                                    <i
                                                        className='ico icon-star-active'
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.saveEventsProField('isFav', false);
                                                        }}
                                                    ></i>
                                                </a>

                                            </>
                                            :
                                            <>
                                                <a href='#' onClick={e => { e.preventDefault(); }}>
                                                    <i
                                                        className='ico icon-star'
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.saveEventsProField('isFav', true);
                                                        }}
                                                    ></i>
                                                </a>
                                            </>
                                    }
                                </span> */}
                                <div className="enquiry-detail-bottom ">
                                    <div className="float-left mt-1">
                                        <ul className="enquiry-details">
                                            {displayID ? <li> <i className="ico icon-enquiry"></i> {displayID}</li> : <></>}
                                            {dealerName ? (
                                                <li> <span className="blue-color"><i className="ico icon-group"></i>{dealerName}</span></li>
                                            ) : (
                                                <></>
                                            )}
                                            {location ? <li> <i className="ico icon-location"></i> {location}</li> : <></>}
                                            {startDate ? <li> <i className="ico icon-date"></i> {CommonHelper.showCombineDate(startDate, endDate, timezone)}</li> : <></>}
                                            {startDate ? <li> <i className="ico icon-time"></i> {CommonHelper.showCombineTime(startDate, endDate, timezone, eventSettings)}</li> : <></>}

                                        </ul>
                                    </div>

                                </div>
                            </div>


                            <div className="float-right">

                                {
                                    (status && _objCurrentStatus)
                                        ?
                                        <div className='current-status mt-3'>
                                            <Translate text={'currentStatus'} />
                                            <div className={`badge badge-pill badge-empty ml-2`}
                                                style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                {
                                                    !_.isEmpty(_dynamicStatusMenu)
                                                        ?
                                                        <DropDownMenu
                                                            text={_objCurrentStatus ? _objCurrentStatus.name : status}
                                                            keyVal="165898"
                                                            tIndex="-13"
                                                            menu={_dynamicStatusMenu}
                                                            handleChange={this.handleDynamicStatus}
                                                        >
                                                        </DropDownMenu>
                                                        :
                                                        <Translate
                                                            text={_objCurrentStatus ? _objCurrentStatus.name : status}
                                                            upperCase={true}
                                                        />
                                                }
                                            </div>
                                            {/* <div className={`badge badge-pill badge-empty ml-2`}
                                                style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                <Translate
                                                    text={_objCurrentStatus ? _objCurrentStatus.name : status.toLowerCase()}
                                                    upperCase={true}
                                                />
                                            </div> */}
                                        </div>
                                        :
                                        <></>

                                }

                                <div className="status-btn-wrap">
                                    {
                                        manageTemplate ? (
                                            <a href="#" className="btn btn-common  button-green"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    let levelIDs = {};
                                                    if (this.state.fields.level === 'individual' || _.isEmpty(this.state.fields.level)) {
                                                        levelIDs = {
                                                            clientID: this.state.fields.clientID,
                                                            clientIDs: this.state.fields.clientIDs,
                                                            currentClientID: this?.props?.dealersettings?.clients?.some(a => a.documentID === this.state.fields.clientID) ?
                                                                this.state.fields.clientID : this.props.dealersettings.client.id
                                                        }
                                                    }
                                                    else {
                                                        levelIDs = {
                                                            [`${this.state.fields.level}ID`]: this.state.fields.levelID[0],
                                                            level: this.state.fields.level,
                                                            currentClientID: null
                                                        }
                                                    }
                                                    this.setState({
                                                        triggerModal: {
                                                            show: true,
                                                            title: this.state.fields.title,
                                                            eventsProID: this.state.fields.documentID,
                                                            trigger: this.state.eventTriggers ? this.state.eventTriggers[0] : null,
                                                            ...levelIDs,
                                                        },
                                                    });

                                                }}>MANAGE TEMPLATE </a>
                                        ) : (<></>)
                                    }
                                    {/* <a href="#"
                                        className="btn btn-common button-red">Close </a> */}
                                </div>

                                {
                                    canModifyEvent
                                        ?
                                        <div className="events-settings-item-btn">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    settingsModal: {
                                                        show: true,
                                                        title: 'Event Settings',
                                                        settings: eventSettings,
                                                        eventsProID: this.state.fields.documentID
                                                    },
                                                });
                                            }} ><div className="events-settings-logo"><i className="ico icon-settings"></i></div></a>
                                        </div>
                                        :
                                        <></>

                                }
                                {
                                    enableEOI
                                        ?
                                        <div className="events-settings-item-btn">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    linkShareModal: true
                                                });
                                            }} ><div className="events-settings-logo"><i className="ico icon-share"></i></div></a>
                                        </div>
                                        :
                                        <></>

                                }
                                <Dropdown className='enquiry-view-more'>
                                    <Dropdown.Toggle as={CustomToggle} className='common-button'>
                                        <i className='ico icon-more'></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        as={CustomMenu}
                                        ChildClass="more-dropdown dropsub-fix"
                                        xplacement="bottom-end"
                                    >
                                        {
                                            status !== eventStatus.CLOSED ? (
                                                <Dropdown.Item eventKey="1" onClick={(e) => {
                                                    e.preventDefault();
                                                    let levelIDs = {}
                                                    if (this.state.fields.level === 'individual' || _.isEmpty(this.state.fields.level)) {
                                                        levelIDs = {
                                                            clientID: this.state.fields.clientID,
                                                            timezone: this.state.fields.timezone
                                                        }
                                                    }
                                                    else {
                                                        levelIDs = {
                                                            oemID: dealersettings.oemID,
                                                            reportType: 'oem',
                                                            timezone: this.state.fields.timezone
                                                        }
                                                    }
                                                    this.setState({
                                                        modelShow: {
                                                            show: true,
                                                            report: {
                                                                documentID: `${this.state.eventsProID}-eventsPro`
                                                            },
                                                            eventsPro: true,
                                                            eventsProID: this.state.eventsProID,
                                                            hideTestEmail: true,
                                                            hideRptOption: true,
                                                            hideMultiScheduler: true,
                                                            ...levelIDs
                                                        }
                                                    })
                                                }}><i className="ico icon-email"></i><Translate text={' Daily Attendee Report'} /></Dropdown.Item>
                                            ) : (<></>)
                                        }
                                        {
                                            dealersettings.isMclaren === true && (status === eventStatus.CLOSED || status === eventStatus.LIVE) ? (
                                                <Dropdown.Item eventKey="2" onClick={(e) => {
                                                    e.preventDefault();
                                                    toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                                                        {
                                                            position: 'top',
                                                            duration: null
                                                        },
                                                    )
                                                    const searchActivities = window.firebase.functions().httpsCallable('eventsPro-eventSummaryReport');
                                                    searchActivities({
                                                        clientID,
                                                        eventsProID: this.state.eventsProID,
                                                        leadStatus: _enquiryAllStatus,
                                                        oemID: dealersettings.oemID,
                                                        users: !_.isEmpty(groupUsers) ? groupUsers.map(r => { return { id: r.id, name: r.name } }) : clientUsers.map(r => { return { id: r.id, name: r.name } }),
                                                        timezone: this.state.fields.timezone
                                                    }).then((response) => {
                                                        //console.log('generic-searchData', response);
                                                        if (response.data.success && response.data.data) {
                                                            toast.closeAll();
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: CommonHelper.showLocale(dealersettings, 'Report downloaded successfully.'),
                                                                showConfirmButton: false,
                                                                timer: 1500
                                                            })
                                                            const metadata = response.data.data;
                                                            const fileURL = `https://firebasestorage.googleapis.com/v0/b/${metadata.bucket}/o/${encodeURIComponent(
                                                                metadata.name
                                                            )}?alt=media&token=${metadata.metadata.firebaseStorageDownloadTokens}`;
                                                            window.open(fileURL, '_blank');
                                                        }
                                                        else {
                                                            Swal.fire(response.data.message, '', 'error')
                                                        }
                                                    });
                                                }}><i className="ico icon-files-excel"></i><Translate text={'Summary Report'} /></Dropdown.Item>
                                            ) : (<></>)
                                        }
                                        {
                                            (status === eventStatus.CLOSED || status === eventStatus.LIVE) ? (
                                                <Dropdown.Item eventKey="3" onClick={(e) => {
                                                    e.preventDefault();
                                                    toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border loader-primary" role="status" aria-hidden="true"></span> downloading...</div>),
                                                        {
                                                            position: 'top',
                                                            duration: null
                                                        },
                                                    )
                                                    const searchActivities = window.firebase.functions().httpsCallable('eventsPro-summaryReport');
                                                    searchActivities({
                                                        clientID,
                                                        eventsProID: this.state.eventsProID,
                                                        leadStatus: _enquiryAllStatus,
                                                        oemID: dealersettings.oemID,
                                                        users: !_.isEmpty(groupUsers) ? groupUsers.map(r => { return { id: r.id, name: r.name } }) : clientUsers.map(r => { return { id: r.id, name: r.name } }),
                                                        timezone: this.state.fields.timezone,
                                                        source: eventSettings.source,
                                                        countries: dealersettings.countries,
                                                        states: dealersettings.states,
                                                        status: Object.keys(eventAttendeeStatus).map(r => { return { name: r, value: eventAttendeeStatus[r] } })
                                                    }).then((response) => {
                                                        //console.log('generic-searchData', response);
                                                        if (response.data.success && response.data.data) {
                                                            toast.closeAll();
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: CommonHelper.showLocale(dealersettings, 'Report downloaded successfully.'),
                                                                showConfirmButton: false,
                                                                timer: 1500
                                                            })
                                                            const metadata = response.data.data;
                                                            const fileURL = `https://firebasestorage.googleapis.com/v0/b/${metadata.bucket}/o/${encodeURIComponent(
                                                                metadata.name
                                                            )}?alt=media&token=${metadata.metadata.firebaseStorageDownloadTokens}`;
                                                            window.open(fileURL, '_blank');
                                                        }
                                                        else {
                                                            Swal.fire(response.data.message, '', 'error')
                                                        }
                                                    });
                                                }}><i className="ico icon-files-excel"></i><Translate text={'Event Summary Report'} /></Dropdown.Item>
                                            ) : (<></>)
                                        }
                                        {
                                            deleteAccess
                                                ?
                                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                                    this.handleDeleteEvent(e);
                                                }}><i className="ico icon-delete"></i>
                                                    <Translate text={'delete'} />
                                                </Dropdown.Item>
                                                :
                                                <></>
                                        }
                                        {
                                            dealersettings.email === 'admin@fusionsd.com' ? (
                                                <Dropdown.Item eventKey="5" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ excelDownload: true })

                                                }}><i className="ico icon-email"></i><Translate text={'McLaren Excel Report'} /></Dropdown.Item>
                                            ) : (<></>)
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>


                        <div className="events-details-subheader-info">
                            <div className="float-left">
                                <ul className="events-stat-details">
                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-blue"> <i className="ico icon-events-invitation-fix"></i> </div>
                                            <div className="events-stat-right events-stat-blue">
                                                <div className="events-stat-title">Invitations</div>
                                                <div className="events-stat-count">{counts?.invitations > 0 ? counts.invitations : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-green"> <i className="ico icon-events-accepted"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">Accepted</div>
                                                <div className="events-stat-count">{counts?.accepted > 0 ? counts.accepted : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-orange"> <i className="ico icon-events-maybe"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">Tentative</div>
                                                <div className="events-stat-count">{counts?.tentative > 0 ? counts.tentative : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-red"> <i className="ico icon-events-rejected"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">Declined</div>
                                                <div className="events-stat-count">{counts?.rejected > 0 ? counts.rejected : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-green"> <i className="ico icon-sent"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">Checked-In</div>
                                                <div className="events-stat-count">{counts?.checkedIn > 0 ? counts.checkedIn : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-orange"> <i className="ico icon-events-new"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">New</div>
                                                <div className="events-stat-count">{counts?.newAttendees > 0 ? counts.newAttendees : 0}</div> </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="events-stat-column">
                                            <div className="events-stat-icon events-stat-skyblue"> <i className="ico icon-events-testdrive"></i> </div>
                                            <div className="events-stat-right">
                                                <div className="events-stat-title">Test Drive</div>
                                                <div className="events-stat-count">{counts?.testDrive > 0 ? counts.testDrive : 0}</div> </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            <div className="float-right">
                                <div className={`filter-item-btn filter-owner-btn ml-2`}>
                                    <ReactSelect
                                        options={allUsers}
                                        name={"owner"}
                                        placeholder={'select ownership'}
                                        onChange={this.handleSelectSave}
                                        value={owner}
                                        classNamePrefix={`cursor-pointer ${this.state.errors["owner"]} basic-select`}
                                        removeClearable={true}
                                        isSearchable={true}
                                        isDisabled={canModifyEvent ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="enquiry-stagewrap">
                        <div className="enquiry-stagewrap-head">
                            <div className="float-left">
                                {
                                    pipeline
                                        ?
                                        <>
                                            <div
                                                className={`pipeline-info-drop`}
                                                title={CommonHelper.showPSAge(stageHistory, pipeline, null, 'pipeline')}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // if (movePipeline) {
                                                    //     this.setState({
                                                    //         pipelinePopOver: { showpopover: true, targetpopover: e.target }
                                                    //     });
                                                    // }

                                                }}>
                                                <Translate text={'pipeline'} />
                                                <span className="pipline-arrow">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </span>

                                                {this.state.pipelines.find(
                                                    e => e.value === pipeline
                                                )
                                                    ? this.state.pipelines.find(
                                                        e => e.value === pipeline
                                                    ).label
                                                    : ''}
                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    !canModifyEvent ? (
                                        <div className="pipeline-enquiry-status ml-2">
                                            <div className="badge badge-pill badge-readonly">
                                                <i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    isDeleted ? (
                                        <div className="pipeline-enquiry-status ml-2">
                                            <div className="badge badge-pill badge-readonly">
                                                <i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                            </div>

                            {!_.isEmpty(stageHistory) && (<div className="float-right mt-1">
                                <ul className="enquiry-overview">
                                    <li className="overview-blue">
                                        <Translate text={"pipelineAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage, 'pipeline')}</span>
                                    </li>
                                    <li className="overview-orange">
                                        <Translate text={"stageAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage)}</span>
                                    </li>
                                </ul>
                            </div>)}


                        </div>
                        <div className="enquiry-stagewrap-stages">
                            <ul className="pipeline-step">
                                {
                                    this.state.defaulStages && this.state.defaulStages.map((_stage, index) => {
                                        let _sittingDays = this.state.activeStageIndex >= index ? CommonHelper.handleSittingDays(stageHistory, pipeline, _stage) : '';
                                        return (
                                            <OverlayTrigger
                                                key={index}
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip><>{_stage.name} {_sittingDays ? <><br />{_sittingDays}</> : ''}</></Tooltip>
                                                }
                                            >
                                                <li
                                                    key={index}
                                                    className={this.state.activeStageIndex >= index ? (this.handleStageComplete(_stage.value)) : ''}>
                                                    <a
                                                        href="#"
                                                        id={index}
                                                        className={(canModifyEvent) ? '' : 'form-disable'}
                                                        name={_stage.value}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            if (canModifyEvent)
                                                                this.handleStageChange(index, _stage.value)
                                                        }}
                                                    >
                                                        {_stage.name}
                                                    </a>
                                                </li>
                                            </OverlayTrigger>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="enquirydetail-grid flex-column mt-4">
                        {/** LEFT TAB */}
                        <aside className="small-column mr-3 ">
                            <div className="enquiry-sidebar">
                                <Accordion defaultActiveKey="0" >
                                    {/** EVENT INFO */}

                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey="0"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (activeCntKey === '0')
                                                    this.handleCntActiveKey(null);
                                                else
                                                    this.handleCntActiveKey('0');
                                            }}>
                                            <div className="mb-0">

                                                <div className="cardlink">
                                                    <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeCntKey === '0' ? '' : 'collapsed'}`}>
                                                        <i className="ico icon-events-event"></i> <Translate text={'Event Info'} /> <i className="arrow-collapse"></i>
                                                    </a></div>
                                            </div>
                                        </Accordion.Toggle>

                                        {
                                            isDraft && addEventAccess
                                                ?
                                                <>
                                                    <div className='edit-button right-icon'>
                                                        <a
                                                            href='#'
                                                            className='mini-button'
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.handleEventOpen(this.state.eventsProID);
                                                            }}
                                                        >
                                                            <i className='ico icon-edit'></i>
                                                        </a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="events-info-card">


                                                    <div className="events-info-card-image">
                                                        {
                                                            imageURL
                                                                ?
                                                                <a data-fancy-box={`event_pro_${this.state.eventsProID}`}
                                                                    href={imageURL} 
                                                                    onClick={(e) => { e.preventDefault(); 
                                                                        this.setState({
                                                                            imageViewer: {
                                                                                show: true,
                                                                                images: imageURL
                                                                            }
                                                                        });
                                                                    }}>
                                                                    {/* <img src={imageURL} width="70" height="70" alt="" className="img-object-fit" /> */}

                                                                    <img src={imageURL} />
                                                                </a>
                                                                :
                                                                <img src={_images.noEvent} />
                                                        }


                                                    </div>

                                                    {title ? <div className="events-info-card-title">{title}</div> : <></>}

                                                    {location ? <div className="events-info-card-sub"><i className="ico icon-location"></i> {location} </div> : <></>}
                                                    {startDate ? <div className="events-info-card-sub">
                                                        <i className="ico icon-date"></i>{CommonHelper.showCombineDate(startDate, endDate, timezone)}
                                                        <i className="ico icon-time ml-2"></i> {CommonHelper.showCombineTime(startDate, endDate, timezone, eventSettings)}</div> : <></>}
                                                    {!_.isEmpty(clientIDs) || (fields.level && fields.levelID) ?
                                                        <div className="events-info-card-sub">
                                                            <i className="ico icon-group mb-1"></i>
                                                            <div className="Vehicle-availability">
                                                                {
                                                                    !_.isEmpty(clientIDs)
                                                                        ?
                                                                        <>
                                                                            {
                                                                                CommonHelper.getDataByLevel(dealersettings, userLevel.INDIVIDUAL, clientIDs, 'name').map((rec, index) => {
                                                                                    return <div key={index} title={'client'}><span>{rec}</span></div>
                                                                                })
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                CommonHelper.getDataByLevel(dealersettings, fields.level, fields.levelID, 'name').map((rec, index) => {
                                                                                    return <div key={index} title={fields.level}><span>{rec}</span></div>
                                                                                })
                                                                            }
                                                                        </>
                                                                }

                                                            </div>
                                                        </div>
                                                        : <></>}
                                                    {description ?
                                                        <>
                                                            {
                                                                readMore
                                                                    ?
                                                                    <div className="events-info-card-description text-area-space">
                                                                        {htmlToText.fromString(description.replace(/<img[^>]*>/g, ""))}
                                                                        <a href="#" className="events-blue-link ml-2" onClick={e => { e.preventDefault(); this.setState({ readMore: false }) }}>{'Read Less'} </a>
                                                                    </div>
                                                                    :
                                                                    <div className="events-info-card-description text-area-space">
                                                                        {description.length > 200 ?
                                                                            <>
                                                                                {htmlToText.fromString(description.replace(/<img[^>]*>/g, "")).substr(0, 200) + '...'}
                                                                                <a href="#" className="events-blue-link ml-2" onClick={e => { e.preventDefault(); this.setState({ readMore: true }) }}>{'Read More'} </a>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {htmlToText.fromString(description.replace(/<img[^>]*>/g, ""))}
                                                                            </>
                                                                        }

                                                                    </div>
                                                            }

                                                        </>

                                                        : <></>}






                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Accordion defaultActiveKey="1" >
                                    {/** Manage Templates */}

                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={(e) => {
                                            e.preventDefault()

                                            if (activeVehKey === '1')
                                                this.handleVehActiveKey(null);
                                            else
                                                this.handleVehActiveKey('1');
                                        }}
                                        >
                                            <div className="mb-0">
                                                {/* {isTradein ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>} */}
                                                <div className="cardlink">
                                                    <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '1' ? '' : 'collapsed'}`}>
                                                        <i className="ico icon-file-logs"></i><Translate text={'Manage Templates'} />

                                                        <i className="arrow-collapse"></i>
                                                    </a></div>
                                            </div>
                                        </Accordion.Toggle>
                                        {
                                            (manageTemplate)
                                                ?
                                                <>
                                                    <div className="add-button"><a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        let levelIDs = {};
                                                        if (this.state.fields.level === 'individual' || _.isEmpty(this.state.fields.level)) {
                                                            levelIDs = {
                                                                clientID: this.state.fields.clientID,
                                                                clientIDs: this.state.fields.clientIDs,
                                                                currentClientID: this?.props?.dealersettings?.clients?.some(a => a.documentID === this.state.fields.clientID) ?
                                                                    this.state.fields.clientID : this.props.dealersettings.client.id
                                                            }
                                                        }
                                                        else {
                                                            levelIDs = {
                                                                [`${this.state.fields.level}ID`]: this.state.fields.levelID[0],
                                                                level: this.state.fields.level,
                                                                currentClientID: null
                                                            }
                                                        }
                                                        this.setState({
                                                            triggerModal: {
                                                                show: true,
                                                                title: this.state.fields.title,
                                                                eventsProID: this.state.fields.documentID,
                                                                ...levelIDs
                                                            },
                                                        });
                                                    }} className="mini-button">
                                                        <i className="ico icon-add"></i>
                                                    </a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                {
                                                    eventTriggers.length > 0 ?
                                                        _.orderBy(eventTriggers, 'addedDate.seconds', 'asc').map((info, index) => {
                                                            const category = _.find(dealersettings?.client?.eventsPro?.categories, { value: info.category }) ?
                                                                _.find(dealersettings?.client?.eventsPro?.categories, { value: info.category }).name : '';
                                                            return <div key={index} className="eventes-template-item" id={'temp_id_' + info.documentID}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    let levelIDs = {};
                                                                    if (this.state.fields.level === 'individual' || _.isEmpty(this.state.fields.level)) {
                                                                        levelIDs = {
                                                                            clientID: this.state.fields.clientID,
                                                                            clientIDs: this.state.fields.clientIDs,
                                                                            currentClientID: this?.props?.dealersettings?.clients?.some(a => a.documentID === this.state.fields.clientID) ?
                                                                                this.state.fields.clientID : this.props.dealersettings.client.id
                                                                        }
                                                                    }
                                                                    else {
                                                                        levelIDs = {
                                                                            [`${this.state.fields.level}ID`]: this.state.fields.levelID[0],
                                                                            level: this.state.fields.level,
                                                                            currentClientID: null
                                                                        }
                                                                    }
                                                                    if (manageTemplate) {
                                                                        this.setState({
                                                                            triggerModal: {
                                                                                show: true,
                                                                                title: this.state.fields.title,
                                                                                eventsProID: this.state.fields.documentID,
                                                                                clientIDs: this.state.fields.clientIDs,
                                                                                clientID: this.state.fields.clientID,
                                                                                trigger: info,
                                                                                ...levelIDs
                                                                            },
                                                                        });
                                                                    }
                                                                }}>
                                                                <div className="eventes-template-image"><img src={images.templatesIcon} alt="" width="70" height="70" /></div>
                                                                <div className="eventes-template-title">{info.subject ? info.subject : category}</div>
                                                                <div className="eventes-template-status">
                                                                    <div className="badge badge-pill badge-white">{category}</div>
                                                                    {
                                                                        info?.tasklog?.success === true ? (
                                                                            <div className="badge badge-pill  badge-available-fill ml-2">Success</div>
                                                                        ) : info?.tasklog?.success === false ? (
                                                                            <div className="badge badge-pill badge-failed-fill ml-2">Failed</div>
                                                                        ) : (<></>)
                                                                    }
                                                                </div>
                                                                {
                                                                    info?.tasklog?.dtNext ? (
                                                                        <div className="eventes-template-info mt-1">Next Execution : {moment.unix(info.tasklog.dtNext.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`)}</div>
                                                                    ) : info?.tasklog?.dt ? (
                                                                        <div className="eventes-template-info mt-1">Last Execution : {moment.unix(info.tasklog.dt.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`)}</div>
                                                                    ) : info.addedDate ? (
                                                                        <div className="eventes-template-info mt-1">Created On : {moment.unix(info.addedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`)}</div>
                                                                    ) : (<></>)
                                                                }
                                                                {/* {
                                                                    info?.tasklog?.msg ? (
                                                                        <div className="vehicle-current-status"><div className="vehicle-status-alert alert-user">{info?.tasklog?.msg}</div></div>
                                                                    ) : (<></>)
                                                                } */}
                                                            </div>
                                                        })
                                                        : (<div className="text-center p-4">
                                                            <p className="mb-2 d-block">No Data ! let's create one</p>
                                                            {
                                                                manageTemplate ? (
                                                                    <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let levelIDs = {};
                                                                        if (this.state.fields.level === 'individual' || _.isEmpty(this.state.fields.level)) {
                                                                            levelIDs = {
                                                                                clientID: this.state.fields.clientID,
                                                                                clientIDs: this.state.fields.clientIDs,
                                                                                currentClientID: this?.props?.dealersettings?.clients?.some(a => a.documentID === this.state.fields.clientID) ?
                                                                                    this.state.fields.clientID : this.props.dealersettings.client.id
                                                                            }
                                                                        }
                                                                        else {
                                                                            levelIDs = {
                                                                                [`${this.state.fields.level}ID`]: this.state.fields.levelID[0],
                                                                                level: this.state.fields.level,
                                                                                currentClientID: null
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            triggerModal: {
                                                                                show: true,
                                                                                title: this.state.fields.title,
                                                                                eventsProID: this.state.fields.documentID,
                                                                                ...levelIDs
                                                                            },
                                                                        });
                                                                    }}><i className="ico icon-add mr-1"></i> Add </button>
                                                                ) : (<></>)
                                                            }
                                                        </div>)
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>

                            <div className="enquiry-detailed-info">

                                <ul>
                                    <li> <span><Translate text={'modifiedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), modifiedBy)}</li>
                                    <li> <span><Translate text={'modifiedDate'} />:</span> {modifiedDate ? moment.unix(modifiedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '--'}</li>
                                    {
                                        !_.isEmpty(modifiedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'modifiedFrom'} />:</span> {modifiedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (status === eventStatus.CLOSED && !_.isEmpty(closedDate))
                                            ?
                                            <>
                                                <li>
                                                    <span><Translate text={CommonHelper.getNameByValue(eventsProAllStatus, 'closed', 'Closed') + ' Date'} />:</span>
                                                    {closedDate ? moment.unix(closedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '--'}
                                                </li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (status === eventStatus.LIVE && !_.isEmpty(liveDate))
                                            ?
                                            <>
                                                <li>
                                                    <span><Translate text={CommonHelper.getNameByValue(eventsProAllStatus, 'live', 'Live') + ' Date'} />:</span>
                                                    {liveDate ? moment.unix(liveDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '--'}
                                                </li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (status === eventStatus.PUBLISHED && !_.isEmpty(publishedDate))
                                            ?
                                            <>
                                                <li>
                                                    <span><Translate text={CommonHelper.getNameByValue(eventsProAllStatus, 'published', 'Published') + ' Date'} />:</span>
                                                    {publishedDate ? moment.unix(publishedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '--'}
                                                </li>
                                            </>
                                            :
                                            <></>
                                    }


                                    <li> <span><Translate text={'addedBy'} />:</span> {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), addedBy)}</li>
                                    <li> <span><Translate text={'addedDate'} />:</span> {addedDate ? moment.unix(addedDate.seconds).format(`${eventSettings?.dateFormat || 'DD/MM/YYYY'} ${eventSettings?.timeFormat || 'hh:mm A'}`) : '--'}</li>
                                    {
                                        !_.isEmpty(addedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'addedFrom'} />:</span> {addedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>


                        </aside>
                        {/** RIGHT TAB */}
                        <div className="mid-column pb-5 events-popup-tabs">
                            <Tabs
                                defaultActiveKey='Attendees'
                                className='nav-fill events-tab-nav'
                                mountOnEnter={true}
                            >
                                <Tab eventKey='Attendees' title={<>{`Attendees`}
                                    <span className="badge badge-widget-counter badge-counter ml-1 mt-1">{counts?.attendees ? counts.attendees : 0}</span>
                                </>}>
                                    <Attendees
                                        {...this.props}
                                        settingLoading={settingLoading}
                                        eventSlots={eventSlots}
                                        eventSettings={eventSettings}
                                        eventsPro={this.state.fields}
                                        clientID={this.state.fields.clientID}
                                        canModifyEvent={canModifyEvent}
                                        isDraft={isDraft}
                                        isClosed={isClosed}
                                        dataCount={counts?.attendees}
                                        trigger={eventTriggers.filter(a => a.eventsProAttendeeStatus === 'accepted' || a?.tasklog?.dt)}
                                        excelDownload={this.state.excelDownload}
                                        setExcelDownload={(val) => { this.setState({ excelDownload: val }) }}
                                        setModelAllocationShow={(val) => {
                                            this.setState({ modelAllocationShow: { show: val } })
                                        }}

                                    />
                                </Tab>

                                {
                                    enableEOI
                                        ?
                                        <Tab eventKey='AttendeesEOI' title={<>{`Expression of Interest`}
                                            <span className="badge badge-widget-counter badge-counter ml-1 mt-1">{counts?.attendeesEOI ? counts.attendeesEOI : 0}</span>
                                        </>}>
                                            <AttendeesEOI
                                                {...this.props}
                                                settingLoading={settingLoading}
                                                eventSlots={eventSlots}
                                                eventSettings={eventSettings}
                                                eventsPro={this.state.fields}
                                                clientID={this.state.fields.clientID}
                                                canModifyEvent={canModifyEvent}
                                                isDraft={isDraft}
                                                isClosed={isClosed}
                                                dataCount={counts?.attendeesEOI}
                                            />
                                        </Tab>
                                        :
                                        <></>
                                }

                                <Tab eventKey='Activity' title='Activity'>
                                    {/** ACTIVITY TAB */}
                                    <div className="mt-3">
                                        <Activity
                                            eventsPro={this.state.fields}
                                            clientUsers={clientUsers}
                                            groupUsers={groupUsers}
                                            dealersettings={dealersettings}
                                            history={this.props.history}
                                            dialNumber={this.props.dialNumber}
                                        >
                                        </Activity>
                                    </div>

                                </Tab>

                                {
                                    !_.isEmpty(eventTriggers)
                                        ?
                                        <Tab eventKey='Reports' title='Reports'>
                                            {/** REPORTS TAB */}
                                            <div className="mt-3">
                                                <EventReports
                                                    eventsProID={this.state.eventsProID}
                                                    eventsPro={this.state.fields}
                                                    clientUsers={clientUsers}
                                                    groupUsers={groupUsers}
                                                    dealersettings={dealersettings}
                                                    history={this.props.history}
                                                    trigger={eventTriggers}
                                                    eventSlots={eventSlots}
                                                    eventSettings={eventSettings}
                                                //dialNumber={this.props.dialNumber}
                                                >
                                                </EventReports>
                                            </div>

                                        </Tab>
                                        :
                                        <></>
                                }

                            </Tabs>


                        </div>


                    </div >

                </div >

                <PopUpModal show={eventsModal.show}>
                    <AddEvent
                        {...this.props}
                        docID={eventsModal.docID}
                        show={eventsModal.show}
                        clsActive='overlay-modal active'
                        handleClose={this.handleEventClose}
                        title={'event'}
                        eventsProID={this.state.eventsProID}
                        clientID={this.state.fields.clientID}
                    >
                    </AddEvent>
                </PopUpModal>
                <PopUpModal show={triggerModal.show}>
                    <ManageTemplates
                        {...this.props}
                        {...triggerModal}
                        handleClose={() => {
                            this.setState({
                                triggerModal: {
                                    show: false
                                }
                            })
                        }}
                        templates={this.state.eventTemplates}
                        triggers={this.state.eventTriggers}
                        status={eventsProAllStatus.map((rec, index) => { return { ...rec, index: (index + 1) } })}
                        event={this.state.fields}
                    />
                </PopUpModal>
                <PopUpModal show={settingsModal.show}>
                    <EventSettings
                        {...this.props}
                        {...settingsModal}
                        handleClose={() => {
                            this.setState({
                                settingsModal: {
                                    show: false
                                }
                            })
                        }}
                        event={this.state.fields}
                    />
                </PopUpModal>
                <PopUpModal show={linkShareModal}>
                    <LinkShare
                        {...this.props}
                        {...settingsModal}
                        show={linkShareModal}
                        handleClose={() => { this.setState({ linkShareModal: false }); }}
                        eventsPro={this.state.fields}
                        eventSettings={eventSettings}
                    />
                </PopUpModal>
                <PopUpModal show={modelShow.show}>
                    <ScheduleReport
                        {...this.props}
                        {...modelShow}
                        handleClose={() => {
                            this.setState({
                                modelShow: {
                                    show: false
                                }
                            })
                        }}
                        clientUsers={this.getEventUsers()}
                    ></ScheduleReport>
                </PopUpModal>
                <PopUpModal show={modelAllocationShow.show}>
                    <Allocation
                        {...this.props}
                        {...modelAllocationShow}
                        handleModalClose={() => {
                            this.setState({
                                modelAllocationShow: {
                                    show: false
                                }
                            })
                        }}
                        eventsPro={this.state.fields}
                    ></Allocation>
                </PopUpModal>
                <PopUpModal show={this.state.imageViewer.show}>
                    <ImageViewer
                        {...this.state.imageViewer}
                        handleClose={() => { this.setState({ imageViewer: { show: false, images: null } }); }}
                    />
                </PopUpModal>
                {/*
                <PopUpModal show={contactModal.show}>
                    <AddContact
                        docID={this.state.contact.documentID}
                        show={contactModal.show}
                        clsActive={contactModal.clsActive}
                        handleClose={this.handleContactClose}
                        title={contactModal.title}
                        showSearch={true}
                        mandatoryFields={contactModal.mandatoryFields}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        eventsProID={this.state.eventsProID}
                        clientID={this.state.fields.clientID}
                        engageNewContact={engageNewContact}
                    >
                    </AddContact>
                </PopUpModal>


                <PopUpModal show={fileModal.show}>
                    <AddFile
                        show={fileModal.show}
                        eventsProID={this.state.eventsProID}
                        contactID={this.state.contact.documentID}
                        handleClose={this.handleFileClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFile>
                </PopUpModal>

                

                <ChatSidePanel
                    sidepanelClose={this.handleSharePanelClose}
                    clsActive={showSharePanel.chatClsActive}
                    clsName={showSharePanel.chatClsName}
                    title='selectUser'
                    sharePanel={true}
                    sendAs='eventsPro'
                    eventsPro={this.state.fields}
                />

                <PopoverPanel
                    showpopover={pipelinePopOver.showpopover}
                    targetpopover={pipelinePopOver.targetpopover}
                    title='Pipeline'
                    position='bottom'
                    id='pipeline-switch'
                    className='popover-pipeline-switch'
                    closepopover={() => {
                        this.setState({
                            pipelinePopOver: { showpopover: false, targetpopover: '' }
                        });
                    }}>
                    <PipelineSwitch
                        pipeline={pipeline}
                        stage={stage}
                        clientID={this.state.fields.clientID}
                        dealersettings={dealersettings}
                        handleClose={this.handlePipelineClose}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        owner={owner}
                        status={status}
                    />
                </PopoverPanel>               

                
                <SidePanel
                    clsActive={showTIPpanel.clsActive}
                    clsName={showTIPpanel.clsName}
                    sidepanelClose={this.sidepanelTIPClose}
                    title=''
                >
                    <QuickView
                        {...this.props}
                        eventsproid={showTIPpanel.eventsproid}
                        sidepanelClose={this.sidepanelTIPClose}
                        history={this.props.history}
                    />
                </SidePanel> */}


            </>
        ) : this.state.pagenotFound ? (
            <PageNotFound />
        ) : (
            <LoaderOverlay text='Fetching event details...' active={true} />
        );
    }
}


